import { ActionTypeEnum, AppThunk } from ".";
import { post, getWithAuth, postWithAuth, setToken } from "./api";
import { UserDto, SignUpCredentials, UserType } from "../store/types";
import { webConfigUI } from "../constants/webConfigUI";
import { getUIConfigAction, setWebConfigUIAction } from './ui-actions'
import { getVenuesAction } from './venue-actions'

const AUTH_URL = "/api/settings";

export interface Credentials {
  username: string;
  password: string;
}

export type UserWithToken = UserDto & { token: string };

export const loginAction = (credentials: Credentials): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await post(`${AUTH_URL}/login`, credentials);
      const { token, ...user } = response.data;
      setToken(token);
      dispatch({
        type: ActionTypeEnum.AuthSuccess,
        payload: user,
      });
      if(user.type !== UserType.partner){
        dispatch(getUIConfigAction())
      }
    } catch (e) {
      console.log("login error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload: "login failure",
      });
    }
  };
};

export const signUpAction = (credentials: SignUpCredentials): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await post(`${AUTH_URL}/sign-up`, credentials);
      const { token, ...user } = response.data;
      setToken(token);
      window.history.replaceState(null, '', "?");
      dispatch({
        type: ActionTypeEnum.AuthSuccess,
        payload: user,
      });
      if(user.type !== UserType.partner){
        dispatch(getUIConfigAction())
      }
    } catch (e) {
      console.log("sign up error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload: "sign up failure",
      });
    }
  };
};

export const forgetPasswordAction = ({
  username,
}: {
  username: string;
}): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.ForgetPasswordRequest });
      const response = await post(`${AUTH_URL}/forget-password`, {
        email: username,
      });
      dispatch({
        type: ActionTypeEnum.ForgetPasswordSuccess,
      });
    } catch (e) {
      console.log("forget password error", e);
      dispatch({
        type: ActionTypeEnum.ForgetPasswordFailure,
        payload: "forget password failure",
      });
    }
  };
};

export const resetPasswordAction = ({
  resetToken,
  password,
}: {
  resetToken: string;
  password: string;
}): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await post(`${AUTH_URL}/reset-password`, {
        token: resetToken,
        password,
      });
      const { token, ...user } = response.data;
      setToken(token);
      window.history.replaceState(null, '', "?");
      dispatch({
        type: ActionTypeEnum.AuthSuccess,
        payload: user,
      });
      dispatch(getUIConfigAction())
    } catch (e) {
      console.log("reset password error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload: "reset password failure",
      });
    }
  };
};

export const getUserAction = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await getWithAuth(`${AUTH_URL}/profile`);
      const { token, ...user } = response.data;
      setToken(token);
      dispatch({
        type: ActionTypeEnum.AuthSuccess,
        payload: user,
      });
      if(user.type !== UserType.partner){
        dispatch(getUIConfigAction())
      }
    } catch (e) {
      console.log("get profile error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload: "",
      });
    }
  };
};

export const switchTenantAction = (tenantId: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.AuthRequest });
      const response = await postWithAuth(`${AUTH_URL}/switch/${tenantId}`, {});
      const { token, ...user } = response.data;
      setToken(token);
      dispatch({
        type: ActionTypeEnum.AuthSuccess,
        payload: user,
      });
      dispatch(getVenuesAction())
      dispatch(getUIConfigAction())
      dispatch(setWebConfigUIAction(webConfigUI.REPORTS))
    } catch (e) {
      console.log("get profile error", e);
      dispatch({
        type: ActionTypeEnum.AuthFailure,
        payload: "",
      });
    }
  };
};

export const logoutAction = (): AppThunk => {
  return async (dispatch) => {
    setToken("");
    dispatch({ type: ActionTypeEnum.Logout });
  };
};

export const addErrorAction = (text: string) => ({
  type: ActionTypeEnum.addError,
  payload: {
    id: Date.now(),
    text,
    timestamp: Date.now(),
  },
});

export const dismissErrorAction = (errorId: number) => ({
  type: ActionTypeEnum.dismissError,
  payload: errorId,
});

export const getAdminRoleAction = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetAdminType });
      const response = await getWithAuth(`${AUTH_URL}/admin-type`);
      dispatch({
        type: ActionTypeEnum.GetAdminTypeSuccess,
        payload: response.data,
      });
    } catch (e) {
      console.log("get admin type error", e);
      dispatch({
        type: ActionTypeEnum.GetAdminTypeFailure,
        payload: "get admin role failure",
      });
    }
  };
};

function openURL(url:string){
  var a = window.document.createElement("a");
  a.target = '_blank';
  a.href =url;
  // this is hack to make it work in ipad safari
  setTimeout(()=>{
    // Dispatch fake click
    var e = window.document.createEvent("MouseEvents");
    e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, true, false, false, false, 0, null); 
    a.dispatchEvent(e);
  },0)

}

export const goHostAction = (): AppThunk => async (dispatch) => {
  const response = await getWithAuth(`${AUTH_URL}/go-host`);

  const url = response.data.url;
  openURL(url)
  // window.open(url, "_blank")
}