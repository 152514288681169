import React, { useMemo } from "react";
import BackButton from "../common/BackButton/BackButton";
import ActionsButton from "../common/ActionsButton";
import ChevronDown from "../../assets/custom-chevron-down.svgr";
import {
  Callout,
  DefaultButton,
  DirectionalHint,
  mergeStyleSets,
} from "office-ui-fabric-react";
import "./addonsDetails.scss";
import { addonConfigUI, webConfigUI } from "../../constants/webConfigUI";
import {
  removeScheduleAction,
  removeVenueAction,
} from "../../actions/venue-actions";
import {
  setVenuePageAction,
  setWebConfigUIAction,
} from "../../actions/ui-actions";
import { removeAddonAction } from "../../actions/addon-action";
import { selectReadonly } from "../../reducers/auth-reducer";
import { selectCurrentAddon } from "../../reducers/addons";
import { State } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import AddonGeneralDetails from "./General/General";
import AddonAdvancedDetails from "./Advanced/Advanced";
import AddonPackages from "./Packages/Packages";
import AddonModifiersDetails from "./Modifiers/Modifiers";
import EditAddon from "./General/Edit/EditAddon";
import DeleteConfirmation from "../common/DeleteConfirmation/DeleteConfirmation";

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "100px",
    maxHeight: "192px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const tabs = [
  {
    text: "General",
    id: addonConfigUI.GENERAL,
  },
  {
    text: "Assign",
    id: addonConfigUI.PACKAGES,
  },
  {
    text: "Modifiers",
    id: addonConfigUI.MODIFIERS,
  },
  {
    text: "Advanced",
    id: addonConfigUI.ADVANCED,
  },
];

const mapDispatchToProps = {
  removeSchedule: removeScheduleAction,
  removeVenue: removeVenueAction,
  setWebConfigUI: setWebConfigUIAction,
  setVenuePage: setVenuePageAction,
  removeAddon: removeAddonAction,
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddon: selectCurrentAddon(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AddonDetails = ({ setWebConfigUI, removeAddon, currentAddon }: Props) => {
  const [isShowActionsMenu, setIsShowActionsMenu] = React.useState(false);
  const [addonPage, setAddonPage] = React.useState(addonConfigUI.GENERAL);
  const [isCloning, setIsCloning] = React.useState(false);
  const [isRemoveAddonOpen, setIsRemoveAddonOpen] = React.useState(false);

  const backToList = () => {
    setWebConfigUI(webConfigUI.ADDONS);
  };

  const confirmedRemoveAddon = (isConfirm: boolean) => {
    if (isConfirm && currentAddon) {
      removeAddon(currentAddon.id);
      setWebConfigUI(webConfigUI.ADDONS);
    }
    setIsRemoveAddonOpen(false);
  };

  if (!currentAddon) {
    return null;
  }

  const content = () => {
    switch (addonPage) {
      case addonConfigUI.GENERAL:
        return <AddonGeneralDetails />;
      case addonConfigUI.ADVANCED:
        return <AddonAdvancedDetails />;
      case addonConfigUI.PACKAGES:
        return <AddonPackages id={currentAddon.id} />;
      case addonConfigUI.MODIFIERS:
        return <AddonModifiersDetails id={currentAddon.id} />;
      default:
        return <AddonGeneralDetails />;
    }
  };

  return (
    <>
      <div className="addon-details-container">
        <div className="addon-details">
          <BackButton text="All Add Ons" onBack={backToList} />
          <div className="addon-details-information-container">
            <div className="addon-details-information-header">
              <div className="addon-details-title h4">{currentAddon.name}</div>
              <div className="actions-container">
                <ActionsButton
                  id="actions-button"
                  onClick={() => setIsShowActionsMenu(true)}
                >
                  <div className="actions-button-text">Actions</div>
                  <ChevronDown className="chevron" />
                </ActionsButton>
                {isShowActionsMenu && (
                  <Callout
                    className={styles.callout}
                    gapSpace={0}
                    target={`#actions-button`}
                    onDismiss={() => setIsShowActionsMenu(false)}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible={false}
                  >
                    <div className="actions-button-container">
                      <DefaultButton
                        className="actions-button"
                        onClick={() => setIsCloning(true)}
                      >
                        <div className="actions-text">Clone addon</div>
                      </DefaultButton>
                      <DefaultButton
                        className="actions-button"
                        onClick={() => setIsRemoveAddonOpen(true)}
                      >
                        <div className="actions-text">Delete addon</div>
                      </DefaultButton>
                    </div>
                  </Callout>
                )}
              </div>
            </div>
            <div className="addon-details-toggle">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  className={`addon-details-toggle-label body2 ${
                    addonPage === tab.id ? "active" : ""
                  }`}
                  onClick={() => setAddonPage(tab.id)}
                >
                  {tab.text}
                </div>
              ))}
            </div>
            {content()}
          </div>
        </div>
      </div>
      {isCloning && (
        <EditAddon isCloneAddon={true} onClose={() => setIsCloning(false)} />
      )}
      {isRemoveAddonOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this add on?"}
        ConfirmationButtonText={"Delete Add on"}
        onClose={confirmedRemoveAddon}
      />}
    </>
  );
};

export default connector(AddonDetails);
