import { ReportDeliveryType, ReportScope, ReportType } from '../entities/enums';

export class ReportSettingsDto {
  id: string;
  name?: string;
  utcMinutes: number;
  timeZone: string;
  reportType: ReportType;
  reportDeliveryType: ReportDeliveryType;
  reportScope: ReportScope;
}
