import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal } from "office-ui-fabric-react";
import { updateSchedulePackageAction } from "../../../../../actions/package-action";
import { selectIsLoadingPackage } from "../../../../../reducers/packages";
import {
  State,
  SchedulePackageSettingDto,
  CreateSchedulePackageSettingDto,
  TimeSlotPackageSettingDto,
  InventoryMethodTypes,
  ParentScheduleDto,
  Pricing,
} from "../../../../../store/types";
import { DATE_FORMAT, WeekDays } from "../../../../../constants/timedate";
import { groupBy } from "lodash";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import TimeSlotPackage, {
  GenerateSlotsParams,
} from "./TimeSlotPackage/TimeSlotPackage";
import CancelButton from "../../../../../../../common/components/CancelButton";
import FormTextField from "../../../../../../../common/components/FormTextField";
import FormDateField from "../../../../common/FormDateField";
import FormSelectField from "../../../../common/FormSelectField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../../common/components/ColorButton";
import { selectReadonly } from "../../../../../reducers/auth-reducer";
import { selectTimeSlotDuration } from "../../../../../reducers/settings";
import { inventoryTypeOptions } from "../../../../../constants/inventoryMethodTypes";
import "./editSchedulePackage.scss";
import { selectUIConfig } from "../../../../../reducers/ui-reducer";

const valueFieldStyle = {
  root: {
    width: "calc((100% - 20px)/2)",
  },
};

const overrideOptions = [
  {
    key: "No",
    text: "No",
  },
  {
    key: "Yes",
    text: "Yes",
  },
];

const weekDays: number[] = Array.apply(null, Array(7)).map((_, index) => index);
//move Sunday to the end
weekDays.push(weekDays.shift() as number);

interface OwnProps {
  schedulePackage: SchedulePackageSettingDto | CreateSchedulePackageSettingDto;
  onClose: () => void;
  timeShifting: number[];
  isPackageSchedule: boolean;
  parentScheduleParams: ParentScheduleDto;
  ageForPolicy: string;
  packageMethod?: Pricing;
}
const mapDispatchToProps = {
  updateSchedule: updateSchedulePackageAction,
};
const mapStateToProps = (state: State) => ({
  isLoadingPackage: selectIsLoadingPackage(state),
  readonly: selectReadonly(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const EditSchedulePackage = ({
  updateSchedule,
  schedulePackage,
  onClose,
  readonly,
  timeSlotDuration,
  timeShifting,
  isPackageSchedule,
  parentScheduleParams,
  ageForPolicy,
  uiConfig,
  packageMethod,
}: Props) => {
  useEffect(() => {
    setTitle(schedulePackage.title);
    setPriority(schedulePackage.priority);
    setStart(dayjs(schedulePackage.start).toDate());
    setEnd(dayjs(schedulePackage.end).toDate());
    setSlots(schedulePackage.timeSlotsPackage);
    setInventoryMethod(schedulePackage.inventoryMethod);
    setOverrideParentSchedule(schedulePackage.overrideParentSchedule);
    setStartTimeOnly(schedulePackage.startTimeOnly.split(","));
  }, [schedulePackage]);
  useEffect(() => {
    setParentSlots(parentScheduleParams.timeSlotsPackage);
  }, [parentScheduleParams]);

  const [title, setTitle] = useState(schedulePackage.title);
  const [priority, setPriority] = useState(schedulePackage.priority);
  const [start, setStart] = useState<Date | undefined>(
    dayjs(schedulePackage.start).toDate()
  );
  const [end, setEnd] = useState<Date | undefined>(
    dayjs(schedulePackage.end).toDate()
  );
  const [inventoryMethod, setInventoryMethod] = useState(
    schedulePackage.inventoryMethod
  );
  const [overrideParentSchedule, setOverrideParentSchedule] = useState(
    schedulePackage.overrideParentSchedule
  );
  const [startTimeOnly, setStartTimeOnly] = useState(
    schedulePackage.startTimeOnly.split(",")
  );
  const [slots, setSlots] = useState(schedulePackage.timeSlotsPackage);
  const timeSlotGroup = groupBy(
    slots.filter((item) => !item.isDeleted),
    "weekDay"
  );
  const [parentSlots, setParentSlots] = useState(
    parentScheduleParams.timeSlotsPackage
  );
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const parentTimeSlotGroup = groupBy(
    parentSlots ? parentSlots.filter((item) => !item.isDeleted) : [],
    "weekDay"
  );
  const endSlot = (24 * 60) / timeSlotDuration;

  const handleCopyTimeSlots = (currentDay: number, selectedDays: string[]) => {
    const currentDaySlots = slots.filter((slot) => slot.weekDay === currentDay);

    const activeCurrentDaySlots = currentDaySlots.filter(
      (slot) => !slot.isDeleted
    );

    // remove existing slots for selected days
    selectedDays.forEach((day) => {
      const weekDay = WeekDays.indexOf(day);

      // add isDeleted flag to existing slots for selected days
      setSlots((prevSlots) =>
        prevSlots.map((slot) => {
          if (slot.weekDay === weekDay) {
            return { ...slot, isDeleted: true };
          }
          return slot;
        })
      );
    });

    selectedDays.forEach((day) => {
      const weekDay = WeekDays.indexOf(day);

      activeCurrentDaySlots.forEach((slot) => {
        const newSlot: TimeSlotPackageSettingDto = {
          ...slot,
          id: nanoid(),
          weekDay,
          changed: true,
          isNew: true,
        };

        setSlots((prevSlots) => [...prevSlots, newSlot]);
      });
    });
  };

  const handleGenerateSchedule = ({
    day,
    startSlot,
    endSlot,
    interval,
    duration,
    rate,
    available,
    staffCapacity,
    guestCapacity,
  }: GenerateSlotsParams) => {
    const newSlots: TimeSlotPackageSettingDto[] = [];
    const weekDay = WeekDays.indexOf(day);
    const countNewTimeSlots = Math.floor(
      ((endSlot - startSlot) * timeSlotDuration) / interval
    );

    for (let i = 0; i < countNewTimeSlots; i++) {
      const slotOffset = Math.floor((i * interval) / timeSlotDuration);
      const start = startSlot + slotOffset;
      const end = start + Math.floor(duration / timeSlotDuration);
      newSlots.push({
        id: nanoid(),
        packageId: schedulePackage.packageId,
        schedulePackageId: "id" in schedulePackage ? schedulePackage.id : "new",
        weekDay: weekDay,
        start,
        end,
        startTime: start * timeSlotDuration,
        endTime: end * timeSlotDuration,
        is21plus: false,
        rate,
        lanesCount: available,
        staffCapacity,
        guestsCapacity: guestCapacity,
        changed: true,
        isNew: true,
      });
    }

    // Filter out existing slots for the current day and replace with new slots
    setSlots((prevSlots) => [
      ...prevSlots.filter((slot) => slot.weekDay !== weekDay),
      ...newSlots,
    ]);
  };

  const addSlot = (day: number) => {
    setSlots([
      ...slots,
      {
        id: nanoid(),
        packageId: schedulePackage.packageId,
        schedulePackageId: "id" in schedulePackage ? schedulePackage.id : "new",
        weekDay: day,
        start: 0,
        end: endSlot,
        startTime: 0,
        endTime: 1410,
        is21plus: false,
        rate: 50,
        changed: true,
        isNew: true,
        lanesCount: 0,
        staffCapacity: 1,
        guestsCapacity: 1,
      },
    ]);
  };
  const updateSlot = (slot: TimeSlotPackageSettingDto) => {
    setSlots(
      slots.map((item) =>
        slot.id === item.id
          ? {
              ...slot,
              changed: true,
            }
          : item
      )
    );
  };
  const removeSlot = (slotId: string) => {
    let updatedSlots = slots.map((item) =>
      slotId === item.id ? { ...item, isDeleted: true } : item
    );
    updatedSlots = updatedSlots.filter(
      (item) => !(item.isNew && item.isDeleted)
    );
    setSlots(updatedSlots);
  };

  const updateStartTimeOnly = (value: boolean, dayIndex: number) => {
    let newSTartTimeOnlyArray = startTimeOnly;
    newSTartTimeOnlyArray[dayIndex] = value.toString();
    setStartTimeOnly(newSTartTimeOnlyArray);
  };

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (!title || !priority || !start || !end) {
      alert("please fill in all fields");
      return;
    }

    const patch = {
      ...schedulePackage,
      title,
      priority,
      start: dayjs(start).format(DATE_FORMAT),
      end: dayjs(end).format(DATE_FORMAT),
      inventoryMethod,
      overrideParentSchedule,
      timeSlotsPackage: slots,
      startTimeOnly: startTimeOnly.join(","),
    };
    updateSchedule(patch);
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-schedule-package"
    >
      <div className="edit-header h4">
        Schedule
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="schedule" onSubmit={onUpdate}>
        <div className="row">
          <FormTextField
            label="Name"
            styles={valueFieldStyle}
            value={title}
            onChange={(_: any, text?: string) => setTitle(text || "")}
            required
            autoComplete="off"
          />
          <FormTextField
            label="Priority"
            styles={valueFieldStyle}
            value={String(priority)}
            onChange={(_: any, text?: string) =>
              setPriority(parseInt(text || "0"))
            }
            required
            autoComplete="off"
          />
        </div>
        <div className="row">
          <FormDateField
            label="Start Date"
            autoOk
            value={start}
            variant="inline"
            inputVariant="outlined"
            onChange={(data) => setStart(dayjs(data).toDate())}
            format={uiConfig?.dateFormat || "MM/DD/YYYY"}
            className="field"
          />
          <FormDateField
            label="End Date"
            autoOk
            value={end}
            variant="inline"
            inputVariant="outlined"
            onChange={(data) => setEnd(dayjs(data).toDate())}
            format={uiConfig?.dateFormat || "fMM/DD/YYYY"}
            className="field"
          />
        </div>
        <div className="row">
          <FormSelectField
            label="Schedule Type"
            options={inventoryTypeOptions}
            selectedKey={inventoryMethod}
            onChange={(_: any, option) => {
              setInventoryMethod(option?.key as InventoryMethodTypes);
            }}
            className="field"
            disabled={readonly}
            required
          />
          <FormSelectField
            label="Override Parent Schedule"
            options={overrideOptions}
            selectedKey={
              overrideParentSchedule
                ? overrideOptions[1].key
                : overrideOptions[0].key
            }
            onChange={(_: any, option) => {
              setOverrideParentSchedule(
                option?.key === overrideOptions[1].key ? true : false
              );
            }}
            className="field"
            disabled={readonly || isPackageSchedule}
            required
          />
        </div>
        {weekDays.map((day) => (
          <TimeSlotPackage
            key={day}
            day={day}
            slots={timeSlotGroup[day]}
            addSlot={() => addSlot(day)}
            updateSlot={updateSlot}
            removeSlot={removeSlot}
            timeSlotDuration={timeSlotDuration}
            timeShifting={
              timeShifting && timeShifting[day] ? +timeShifting[day] : 0
            }
            isPackageSchedule={isPackageSchedule}
            inventoryMethod={inventoryMethod}
            overrideParentSchedule={overrideParentSchedule}
            parentSlots={parentTimeSlotGroup[day]}
            ageForPolicy={ageForPolicy}
            startTimeOnly={startTimeOnly[day]}
            updateStartTimeOnly={updateStartTimeOnly}
            twelveHourClockFormat={twelveHourClockFormat}
            onCopy={(selectedDays) => handleCopyTimeSlots(day, selectedDays)}
            onGenerateSchedule={handleGenerateSchedule}
            ageGroups={schedulePackage.ageGroups}
            packageMethod={packageMethod}
          />
        ))}

        <div className="main-buttons">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditSchedulePackage);
