import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  ActionButton,
  IDropdownOption,
  IIconProps,
  Toggle,
  Checkbox,
} from "office-ui-fabric-react";
import { InventoryMethodTypes, TimeSlotDiscountDto } from "../../../../../store/types";
import { WeekDays } from "../../../../../constants/timedate";
import { TimeSlotRow } from "./TimeSlotRow/TimeSlotRow";
import { selectUIConfig } from "../../../../../reducers/ui-reducer";
import { State } from "../../../../../store/types";
import { formatTimeFromIndex } from "../../../../../utils/formats";
import { HOURS_NUMBER_FOR_DAY_SLOTS } from "../../../../../constants/timeSlots";
import "./timeSlot.scss";

const addIcon: IIconProps = { iconName: "Add" };

const mapStateToProps = (state: State) => ({
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps);

interface OwnProps {
  day: number;
  slots: TimeSlotDiscountDto[];
  addSlot: () => void;
  updateSlot: (slot: TimeSlotDiscountDto) => void;
  removeSlot: (slotId: string) => void;
  timeSlotDuration: number;
  twelveHourClockFormat: boolean;
}
type Props = ConnectedProps<typeof connector> & OwnProps;

const TimeSlot = ({
  day,
  slots = [],
  addSlot,
  updateSlot,
  removeSlot,
  timeSlotDuration,
  uiConfig,
  twelveHourClockFormat,
}: Props) => {
  const actionText = (uiConfig?.actionText || "lane").toLowerCase()

  const onChange = (
    slot: TimeSlotDiscountDto,
    field: string,
    value: string | number | boolean
  ) => {
    updateSlot({ ...slot, [field]: value });
  };
  const onToggle = () => {
    if (slots.length === 0) {
      addSlot();
    } else {
      slots.forEach(slot => {
        removeSlot(slot.id);
      })
    }
  }
  const slotsInHour = 60 / timeSlotDuration;
  const OPEN_SLOTS = Math.floor((HOURS_NUMBER_FOR_DAY_SLOTS ) * slotsInHour) + 1;

  const times: IDropdownOption[] = Array(OPEN_SLOTS).fill(null).map(
    (_, index) => ({
      key: index,
      text: formatTimeFromIndex(index, timeSlotDuration, 0, twelveHourClockFormat),
    })
  );

  return (
    <div className="time-slot">
      <div className="time-slot-header h6">
        {WeekDays[day]}
        <div className="time-slot-header-right-container">
          
          <Toggle
            className="slots-toggle"
            checked={slots.length > 0}
            onChange={onToggle}
          />
        </div>
      </div>
      {slots.length > 0 &&
        <div className="time-slot-rows">
          {slots.map(item => (
            <div key={item.id}>
              <TimeSlotRow
                slot={item}
                times={times}
                timeSlotDuration={timeSlotDuration}
                onChange={onChange}
                removeSlot={removeSlot}
              />
            </div>
          ))}
          <div className="time-slot-button">
            <ActionButton
              allowDisabledFocus
              onClick={() => addSlot()}
            >+ Add item</ActionButton>
          </div>
        </div>}
    </div>
  );
};

export default connector(TimeSlot)