import React, { useState, useEffect } from 'react';
import './index.scss'
import { Checkbox, DetailsList, DetailsListLayoutMode, ScrollablePane, SelectionMode, Spinner, SpinnerSize, } from "office-ui-fabric-react";
import ColorButton from "../../../../../common/components/ColorButton";
import { AddonModifierDto,  State } from "../../../store/types";
import { connect, ConnectedProps } from "react-redux";
import { getSettingsAction } from "../../../actions/settings-actions";
import { selectModifiers, selectIsLoadingAddon, selectAddonError } from "../../../reducers/addons";
import Panel from "../../common/Panel";
import { getAddonModifiersAction, updateAddonModifiersAction } from '../../../actions/addon-action';
import { selectReadonly } from "../../../reducers/auth-reducer";
import { useViewport } from "../../../hooks/responsive";

const mapStateToProps = (state: State) => ({
  modifiers: selectModifiers(state),
  readonly: selectReadonly(state),
  isLoading: selectIsLoadingAddon(state),
  error: selectAddonError(state),
})

const mapDispatchToProps = {
  getSettings: getSettingsAction,
  getAddonModifiers: getAddonModifiersAction,
  updateAddonModifiers: updateAddonModifiersAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & { id: string };

const AddonModifiersDetails = (
  {
    id,
    modifiers,
    isLoading,
    error,
    readonly,
    getAddonModifiers,
    updateAddonModifiers
  }: Props
) => {
  const { isMiddleScreen } = useViewport();

  useEffect(() => {
    getAddonModifiers(id);
  }, [id])

  useEffect(() => {
    setNewModifiers(modifiers);
  }, [modifiers])

  const [newModifiers, setNewModifiers] = useState(modifiers||[]);
  const allSelect = () => {
    if(newModifiers.length>0){
      setNewModifiers(newModifiers.map(m=>({...m, selected:!newModifiers.every(m=>m.selected)})))
    }
  }
  const onSelect = (id: string) => {
    if(newModifiers.length>0){
      setNewModifiers(newModifiers.map(m=>id===m.id?{...m, selected:!m.selected}:m))
    }
  }
  const modifiersColumns = [
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: AddonModifierDto) => {
        return (
          <div className="checkbox">
            <Checkbox checked={item.selected} onChange={() => onSelect(item.id)} />
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "name",
      name: "NAME",
      fieldName: "name",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,    },
    {
      key: "title",
      name: "TITLE",
      fieldName: "title",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
  ];
  
  return (<>
    <Panel className='addons-modifiers' title={<p className='h6'>Modifications</p>}>
      {isLoading && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {newModifiers.length > 0 ? (
          <div className="addons-modifiers-list">
            <ScrollablePane>
              <DetailsList
                items={newModifiers}
                columns={modifiersColumns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey={"modifiers"}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderDetailsHeader={(props, defaultRender) => {
                  if (!props) {
                    return null;
                  }
                  return (
                    <div className="header">
                      <div className="check-box checkbox">
                        <Checkbox checked={newModifiers.every(m=>m.selected)} onChange={(allSelect)} />
                      </div>
                      {defaultRender!(props)}
                    </div>
                  );
                }}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-addons-modifiers-list-container">
            <div className="empty-addons-modifiers-list">
              <div className="empty-addons-modifiers-list-message">
                No modifiers for this tenant
              </div>
            </div>
          </div>
        )}
      {error && <div className="error">{error}</div>}
      <div className={isMiddleScreen ? "mobile" : ""}>
        <ColorButton
          className="save-button"
          onClick={() => updateAddonModifiers(id, newModifiers)}
          disabled={readonly}
        >
          Save Changes
        </ColorButton>
      </div>
    </Panel>
  </>
  )
};

export default connector(AddonModifiersDetails);