import React from "react";
import LeftArrowIcon from "../../../assets/arrow-left-icon.svgr";
import "./backButton.scss";

interface BackButtonProps {
    text: string;
    onBack: () => void;
  }

const BackButton = ({ text, onBack }: BackButtonProps) => {

  return (
    <button
        className="back-button"
        onClick={onBack}
    >
        <LeftArrowIcon className="left-arrow" />
        {text}
  </button>
  );
};

export default BackButton