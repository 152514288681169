import { ActionTypeEnum, AppThunk } from "./index";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth, uploadFile } from "./api";
import { addErrorAction } from "./auth-actions";
import { AddonSettingDto, CreateAddonSettingDto, AddonsPackageVenueRelationDto } from '../store/types'
import { selectCurrentAddon } from "../reducers/addons";
import { webConfigUI } from "../constants/webConfigUI";
import { setWebConfigUIAction } from "./ui-actions";
import { AddonModifierDto } from "../../../server/src/dto/addonModifier.dto";

const GET_ADDONS_URL = "/api/addons-setting";
const GET_PACKAGES_URL = "/api/package-setting/all";

export const getAddonsAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetAddons });
    const response = await getWithAuth(GET_ADDONS_URL);
    dispatch({
      type: ActionTypeEnum.GetAddonsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetAddonsFailure,
      payload: "error getting addons",
    });
    dispatch(addErrorAction("Get addons failure"));
  }
};

export const selectAddonAction = (addon?: AddonSettingDto): AppThunk => async (
  dispatch
) => {
  try {
    if (addon) {
      dispatch({ type: ActionTypeEnum.GetAddon });
      const response = await getWithAuth(`${GET_ADDONS_URL}/${addon.id}`);
      dispatch({
        type: ActionTypeEnum.GetAddonSuccess,
        payload: response.data,
      });
    } else {
      dispatch({
        type: ActionTypeEnum.GetAddonSuccess,
        payload: undefined,
      });
    }
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetAddonFailure,
      payload: "error update addon",
    });
    dispatch(addErrorAction("Get addon data failure"));
  }
};

export const createAddonAction = (
  addon: CreateAddonSettingDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.CreateAddon });
    const response = await postWithAuth(GET_ADDONS_URL, addon);
    dispatch({
      type: ActionTypeEnum.CreateAddonSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CreateAddonFailure,
      payload: "error create addon",
    });
    dispatch(addErrorAction("Create addon failure"));
  }
};

export const createAddonAndNavigatePageAction = (
  addon: CreateAddonSettingDto
): AppThunk => async (dispatch) => {
  dispatch(createAddonAction(addon));
  dispatch(setWebConfigUIAction(webConfigUI.ADDON_DETAILS));
};

export const updateAddonAction = (addon: Partial<AddonSettingDto>): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateAddon });
    const response = await putWithAuth(GET_ADDONS_URL, addon);
    dispatch({
      type: ActionTypeEnum.UpdateAddonSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateAddonFailure,
      payload: "error update addon",
    });
    dispatch(addErrorAction("Update addon failure"));
  }
};

export const updateAddonAndNavigatePageAction = (addon: Partial<AddonSettingDto>): AppThunk => async (
  dispatch,
) => {
  dispatch(updateAddonAction(addon));
  dispatch(setWebConfigUIAction(webConfigUI.ADDON_DETAILS));
};

export const cloneAddonAction = (addon: Partial<AddonSettingDto>): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.CloneAddon });
    const response = await postWithAuth(`${GET_ADDONS_URL}/clone`, addon);
    dispatch({
      type: ActionTypeEnum.CloneAddonSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CloneAddonFailure,
      payload: "error clone addon",
    });
    dispatch(addErrorAction("Clone addon failure"));
  }
};

export const cloneAddonAndNavigatePageAction = (addon: Partial<AddonSettingDto>): AppThunk => async (
  dispatch,
) => {
  dispatch(cloneAddonAction(addon));
  dispatch(setWebConfigUIAction(webConfigUI.ADDON_DETAILS));
};

export const removeAddonAction = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveAddon });
    const response = await deleteWithAuth(`${GET_ADDONS_URL}/${id}`);
    dispatch({
      type: ActionTypeEnum.RemoveAddonSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemoveAddonFailure,
      payload: "error remove addon",
    });
    dispatch(addErrorAction("Remove addon failure"));
  }
};

export const uploadAddonImageAction = (
  file: File,
  addonId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetAddonImageUrl });
    const imageUrl = await uploadFile(file);
    const response = await postWithAuth(`${GET_ADDONS_URL}/update-image`, {
      value: imageUrl,
      id: addonId,
    });
    dispatch({
      type: ActionTypeEnum.GetAddonImageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetAddonImageFailure,
      payload: "error getting addon image url",
    });
    dispatch(addErrorAction("Get addon image url failure"));
  }
};

export const removeScheduleAddonAction = (id: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveScheduleAddon });
    await deleteWithAuth(`${GET_ADDONS_URL}/${id}`);
    const currentPackage = selectCurrentAddon(getState());
    const updatedPackage = {
      ...currentPackage,
      schedulesAddon: currentPackage?.schedulesAddon.filter((item) => item.id !== id),
    };
    dispatch({
      type: ActionTypeEnum.RemoveScheduleAddonSuccess,
      payload: updatedPackage,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemoveScheduleAddonFailure,
      payload: "error remove schedule",
    });
    dispatch(addErrorAction("Remove schedule failure"));
  }
};

export const getAddonsPackagesAction = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetAddonsRelations });
    const response = await getWithAuth(`${GET_ADDONS_URL}/relations/${id}`);
    dispatch({
      type: ActionTypeEnum.GetAddonsRelationsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetAddonsRelationsFailure,
      payload: "error getting packages",
    });
    dispatch(addErrorAction("Get packages failure"));
  }
};


export const updateAddonRelationWithPackagesAction = (id: string, changes: AddonsPackageVenueRelationDto[]): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateAddonRelations });
    const response = await postWithAuth(`${GET_ADDONS_URL}/relations/${id}`, changes);
    dispatch({
      type: ActionTypeEnum.UpdateAddonRelationsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateAddonRelationsFailure,
      payload: "error update addon relations",
    });
    dispatch(addErrorAction("Update addon relations failure"));
  }
};


export const updateAddonRelationWithVenuesAction = (addonId: string, venueIds: string[]): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateAddon });
    const response = await postWithAuth(`${GET_ADDONS_URL}/update-relations-with-venues`, {
      addonId,
      venueIds,
    });
    dispatch({
      type: ActionTypeEnum.UpdateAddonSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateAddonFailure,
      payload: "error update addon relations",
    });
    dispatch(addErrorAction("Update addon relations failure"));
  }
};

export const setFilteredAddonsAction = (
  addons: AddonSettingDto[]
): AppThunk => async (dispatch) => {
  dispatch({ type: ActionTypeEnum.SetFilteredAddons, payload: addons });
};

export const getAddonModifiersAction = (addonId: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetAddonModifiers });
    const response = await getWithAuth(`${GET_ADDONS_URL}/modifiers/${addonId}`);
    dispatch({
      type: ActionTypeEnum.GetAddonModifiersSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetAddonModifiersFailure,
      payload: "error get addon modifiers",
    });
    dispatch(addErrorAction("Get addon modifiers failure"));
  }
};

export const getModifiersAction = (): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetAddonModifiers });
    const response = await getWithAuth(`${GET_ADDONS_URL}/modifiers/all`);
    dispatch({
      type: ActionTypeEnum.GetAddonModifiersSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetAddonModifiersFailure,
      payload: "error get addon modifiers",
    });
    dispatch(addErrorAction("Get addon modifiers failure"));
  }
};

export const updateAddonModifiersAction = (id: string, modifiers: AddonModifierDto[]): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetAddonModifiers });
    const response = await postWithAuth(`${GET_ADDONS_URL}/modifiers/${id}`, modifiers);
    dispatch({
      type: ActionTypeEnum.GetAddonModifiersSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetAddonModifiersFailure,
      payload: "error get addon modifiers",
    });
    dispatch(addErrorAction("Get addon modifiers failure"));
  }
};

export const selectAddonModifierAction = (modifier?: AddonModifierDto) => ({
  type: ActionTypeEnum.SelectAddonModifier,
  payload: modifier,
});

export const createModifierAction = (modifier: Partial<AddonModifierDto>): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.CreateModifier});
    const response = await postWithAuth(`${GET_ADDONS_URL}/modifier`, modifier);
    dispatch({
      type: ActionTypeEnum.CreateModifierSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CreateModifierFailure,
      payload: "error create addon modifiers",
    });
    dispatch(addErrorAction("Create addon modifiers failure"));
  }
};

export const updateModifierAction = (modifier: Partial<AddonModifierDto>): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateModifier });
    const response = await putWithAuth(`${GET_ADDONS_URL}/modifier`, modifier);
    dispatch({
      type: ActionTypeEnum.UpdateModifierSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateModifierFailure,
      payload: "error update addon modifiers",
    });
    dispatch(addErrorAction("Update addon modifiers failure"));
  }
};

export const removeAddonModifierAction = (modifierId: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.DeleteAddonModifiers });
    await deleteWithAuth(`${GET_ADDONS_URL}/modifier/${modifierId}`);
    dispatch({
      type: ActionTypeEnum.DeleteAddonModifiersSuccess,
      payload: modifierId
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.DeleteAddonModifiersFailure,
      payload: "error Delete addon modifiers",
    });
    dispatch(addErrorAction("Delete addon modifiers failure"));
  }
};