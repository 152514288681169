import {TimeSlotAddonSettingDto} from "./timeSlotAddonSetting.dto";

export class ScheduleAddonSettingDto {
  id: string;
  addonId: string;
  title: string;
  start: string;
  end: string;
  priority: number;
  timeSlotsAddon: TimeSlotAddonSettingDto[]
}

export class CreateScheduleAddonSettingDto {
  addonId: string;
  title: string;
  start: string;
  end: string;
  priority: number;
  timeSlotsAddon: TimeSlotAddonSettingDto[]
}
