import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  Toggle,
} from "office-ui-fabric-react";
import { updateVenueAction } from "../../../../actions/venue-actions";
import { selectVenue, selectIsLoadingVenue, selectVenueError } from "../../../../reducers/venues";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import FormHtmlTextField from "../../../FormHtmlTextField/FormHtmlTextField";

import "./editVenueInfoLanguage.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  error: selectVenueError(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditVenueInfoLanguage = ({
  updateVenue,
  venue,
  readonly,
  isLoadingVenue,
  error,
  onClose,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);
  const [description, setDescription] = useState(venue?.description);
  const [descriptionTitle, setDescriptionTitle] = useState(venue?.descriptionTitle);
  const [venueInfo, setVenueInfo] = useState(venue?.venueInfo);
  const [showPolicy, setShowPolicy] = useState(venue?.showPolicy);
  const [closedMessage, setClosedMessage] = useState(venue?.closedMessage);
  const [reservationDetails, setReservationDetails] = useState(venue?.reservationDetails);
  const [reservationIsNotAvailableText, setReservationIsNotAvailableText] = useState(venue?.reservationIsNotAvailableText);

  const [validationError, setValidationError] = useState("");

  const getPatch = () => {
    return {
      description,
      descriptionTitle,
      reservationDetails,
      venueInfo,
      showPolicy,
      reservationIsNotAvailableText,
      closedMessage,
    };
  }

  const onUpdateReservationsSettings = (e: React.FormEvent) => {
    e.preventDefault();

    if(!description || description === "<p><br></p>"){
      setValidationError("General Description is required");
      return;
    }

    if (venue) {
      const patch = getPatch();
      //todo: send patch only
      updateVenue({ ...venue, ...patch });
      saving.current = true;
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-reservation-language"
    >
      <div className="title h4">
        Venue Info
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdateReservationsSettings}>
        <FormHtmlTextField setValue={setDescription} value={description} label="Venue Description" readOnly={readonly} className="row"/>
        <FormHtmlTextField setValue={setDescriptionTitle} value={descriptionTitle} label="Venue Description Title" readOnly={readonly} className="row"/>
        <FormHtmlTextField setValue={setVenueInfo} value={venueInfo} label='Venue Policies' className="row" readOnly={readonly}/>
        <Toggle
          label="Guest must agree to Venue Policies"
          className="row toggle"
          checked={showPolicy}
          onChange={(_: any, value?: boolean) => setShowPolicy(value)}
          inlineLabel
          disabled={readonly}
        />
        <FormHtmlTextField setValue={setClosedMessage} value={closedMessage} label='Venue Closed' className="row" readOnly={readonly}/>
        <FormHtmlTextField setValue={setReservationDetails} value={reservationDetails} label='Reservation Details (archived)' className="row" readOnly={readonly}/>
        <FormHtmlTextField setValue={setReservationIsNotAvailableText} value={reservationIsNotAvailableText} label='Reservations Unavailable (archived)' className="row" readOnly={readonly}/>
        {error && <div className="error">{error}</div>}
        {validationError && <div className="error">{validationError}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditVenueInfoLanguage);
