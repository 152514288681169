import { ActionTypeEnum, AppThunk } from "./index";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "./api";
import { addErrorAction } from "./auth-actions";
import { DiscountSettingDto, CreateDiscountSettingDto, DiscountsPackageVenueRelationDto, TimeSlotDiscountDto, AddonDiscountDto } from "../store/types";
import { webConfigUI } from "../constants/webConfigUI";
import { setWebConfigUIAction } from "./ui-actions";

const GET_DISCOUNTS_URL = "/api/discount-setting";

export const getDiscountsAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetDiscounts });
    const response = await getWithAuth(GET_DISCOUNTS_URL);
    dispatch({
      type: ActionTypeEnum.GetDiscountsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetDiscountsFailure,
      payload: "error getting discounts",
    });
    dispatch(addErrorAction("Get discounts failure"));
  }
};

export const selectDiscountAction =
  (discount?: DiscountSettingDto): AppThunk =>
    async (dispatch) => {
      try {
        if (discount) {
          dispatch({ type: ActionTypeEnum.GetDiscount });
          const response = await getWithAuth(
            `${GET_DISCOUNTS_URL}/${discount.id}`
          );
          dispatch({
            type: ActionTypeEnum.GetDiscountSuccess,
            payload: response.data,
          });
        } else {
          dispatch({
            type: ActionTypeEnum.GetDiscountSuccess,
            payload: undefined,
          });
        }
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetDiscountFailure,
          payload: "error update discount",
        });
        dispatch(addErrorAction("Get discount data failure"));
      }
    };

export const createDiscountAction =
  (discount: CreateDiscountSettingDto): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.CreateDiscount });
        const response = await postWithAuth(GET_DISCOUNTS_URL, discount);
        dispatch({
          type: ActionTypeEnum.CreateDiscountSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.CreateDiscountFailure,
          payload: "error create discount",
        });
        dispatch(addErrorAction("Create discount failure"));
      }
    };

export const createDiscountAndNavigatePageAction =
  (discount: CreateDiscountSettingDto): AppThunk =>
    async (dispatch) => {
      dispatch(createDiscountAction(discount));
      dispatch(setWebConfigUIAction(webConfigUI.DISCOUNT_DETAILS));
    };

export const updateDiscountAction =
  (discount: Partial<DiscountSettingDto>): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateDiscount });
        const response = await putWithAuth(GET_DISCOUNTS_URL, discount);
        dispatch({
          type: ActionTypeEnum.UpdateDiscountSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.UpdateDiscountFailure,
          payload: "error update discount",
        });
        dispatch(addErrorAction("Update discount failure"));
      }
    };

export const updateDiscountAndNavigatePageAction =
  (discount: Partial<DiscountSettingDto>): AppThunk =>
    async (dispatch) => {
      dispatch(updateDiscountAction(discount));
      dispatch(setWebConfigUIAction(webConfigUI.DISCOUNT_DETAILS));
    };

export const removeDiscountAction =
  (id: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.RemoveDiscount });
        const response = await deleteWithAuth(`${GET_DISCOUNTS_URL}/${id}`);
        dispatch({
          type: ActionTypeEnum.RemoveDiscountSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.RemoveDiscountFailure,
          payload: "error remove discount",
        });
        dispatch(addErrorAction("Remove discount failure"));
      }
    };

export const getDiscountsPackagesAction =
  (id: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetDiscountsRelations });
        const response = await getWithAuth(
          `${GET_DISCOUNTS_URL}/relations/${id}`
        );
        dispatch({
          type: ActionTypeEnum.GetDiscountsRelationsSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetDiscountsRelationsFailure,
          payload: "error getting packages",
        });
        dispatch(addErrorAction("Get packages failure"));
      }
    };

export const updateDiscountRelationWithPackagesAction =
  (id: string, changes: DiscountsPackageVenueRelationDto[]): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateDiscountRelations });
        const response = await postWithAuth(
          `${GET_DISCOUNTS_URL}/relations/${id}`,
          changes
        );
        dispatch({
          type: ActionTypeEnum.UpdateDiscountRelationsSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.UpdateDiscountRelationsFailure,
          payload: "error update discount relations",
        });
        dispatch(addErrorAction("Update discount relations failure"));
      }
    };

export const updateDiscountRelationWithVenuesAction =
  (discountId: string, venueIds: string[]): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateDiscount });
        const response = await postWithAuth(
          `${GET_DISCOUNTS_URL}/update-relations-with-venues`,
          {
            discountId,
            venueIds,
          }
        );
        dispatch({
          type: ActionTypeEnum.UpdateDiscountSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.UpdateDiscountFailure,
          payload: "error update discount relations",
        });
        dispatch(addErrorAction("Update discount relations failure"));
      }
    };

export const getDiscountScheduleAction =
  (id: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetDiscountsSchedule });
        const response = await getWithAuth(
          `${GET_DISCOUNTS_URL}/schedule/${id}`
        );
        dispatch({
          type: ActionTypeEnum.GetDiscountsScheduleSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetDiscountsScheduleFailure,
          payload: "error getting packages",
        });
        dispatch(addErrorAction("Get schedule failure"));
      }
    };

export const updateDiscountScheduleAction =
  (id: string, changes: TimeSlotDiscountDto[]): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateDiscountSchedule });
        const response = await postWithAuth(
          `${GET_DISCOUNTS_URL}/schedule/${id}`,
          changes
        );
        dispatch({
          type: ActionTypeEnum.UpdateDiscountScheduleSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.UpdateDiscountScheduleFailure,
          payload: "error update discount schedule",
        });
        dispatch(addErrorAction("Update discount relations failure"));
      }
    };

export const getDiscountAddonsAction =
  (id: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetDiscountAddons });
        const response = await getWithAuth(
          `${GET_DISCOUNTS_URL}/addons/${id}`
        );
        dispatch({
          type: ActionTypeEnum.GetDiscountAddonsSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetDiscountAddonsFailure,
          payload: "error getting addons",
        });
        dispatch(addErrorAction("Get packages failure"));
      }
    };

export const updateDiscountAddonsAction =
  (id: string, changes: AddonDiscountDto[]): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateDiscountAddons });
        const response = await postWithAuth(
          `${GET_DISCOUNTS_URL}/addons/${id}`,
          changes
        );
        dispatch({
          type: ActionTypeEnum.UpdateDiscountAddonsSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.UpdateDiscountAddonsFailure,
          payload: "error update discount relations",
        });
        dispatch(addErrorAction("Update discount relations failure"));
      }
    };