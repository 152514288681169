import React from "react";

type GoogleMaterialSymbolProps = {
  name: string;
  type?: "filled" | "outlined" | "rounded" | "sharp" | "two-tone";
  color?: string;
  fontSize?: 20 | 24 | 40 | 48;
};

export const GoogleMaterialSymbol = ({
  name,
  color,
  fontSize = 24,
  type = "filled",
}: GoogleMaterialSymbolProps) => {
  const className =
    type === "filled" ? "material-icons" : `material-icons-${type}`;
  return (
    <span
      className={className}
      style={{
        color,
        fontSize,
      }}
    >
      {name}
    </span>
  );
};
