import React from "react";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import createStore from "./store";
import { connect, ConnectedProps } from "react-redux";
import history from "./store/history";
import AuthManager from "./components/AuthManager";
import ErrorManager from "./components/ErrorManager";
import Auth from "./components/Auth/Auth";
import Home from "./components/Home/Home";
import { selectAuthenticated } from "./reducers/auth-reducer";
import { State } from "./store/types";
import { initializeIcons } from "@uifabric/icons";
import { ViewportProvider } from "./hooks/responsive";

const mapStateToProps = (state: State) => ({
  authenticated: selectAuthenticated(state),
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const App: React.FunctionComponent<Props> = ({
  authenticated,
}: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div className="root">
        <AuthManager />
          {authenticated ? <Home /> : <Auth />}
        <ErrorManager />
    </div>
    </MuiPickersUtilsProvider>
  );
};

const AppContainer = () => {
  const { store } = createStore();
  return (
    <Provider store={store}>
      <ViewportProvider>
        <ConnectedRouter history={history}>
          <Route path="/" component={connector(App)} />
        </ConnectedRouter>
      </ViewportProvider>
    </Provider>
  );
};

initializeIcons();

export default AppContainer;
