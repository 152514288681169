import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IContextualMenuProps,
  Icon,
  ScrollablePane,
  SelectionMode,
  Spinner,
  SpinnerSize,
  mergeStyleSets,
} from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
  getPlansAction,
  removePlanAction,
  selectPlansAction
} from "../../actions/membership-action";
import { setWebConfigUIAction } from "../../actions/ui-actions";
import PlusIcon from "../../assets/actions/plus.svgr";
import ThreeDots from "../../assets/three-dots.svgr";
import { webConfigUI } from "../../constants/webConfigUI";
import { selectPlans } from "../../reducers/membership";
import { selectIsSettingsRequestInProgress } from "../../reducers/settings";
import { MembershipPlanDto, ResourceSettingDto, State } from "../../store/types";
import ActionsButton from "../common/ActionsButton";
import DeleteConfirmation from "../common/DeleteConfirmation/DeleteConfirmation";
import "./plans.scss";
import { getDiscountsAction } from "../../actions/discount-action";

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "100px",
    maxHeight: "192px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

interface CellData {
  active: boolean;
  trialPeriod: string;
  monthlyRate: string;
  annualRate: string;
}

const mapDispatchToProps = {
  getPlans: getPlansAction,
  setWebConfigUI: setWebConfigUIAction,
  selectPlan: selectPlansAction,
  getDiscounts: getDiscountsAction,
  removePlan: removePlanAction
};

const mapStateToProps = (state: State) => ({
  plans: selectPlans(state),
  isRequestInProgress: selectIsSettingsRequestInProgress(state)
});


const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = { addPlan: () => void, onPlanSelect: (plan: MembershipPlanDto) => void } & ConnectedProps<typeof connector>;

const Plans = ({ getPlans, plans, setWebConfigUI, addPlan, isRequestInProgress, selectPlan, getDiscounts, removePlan }: Props) => {

  const columns: IColumn[] = [
    {
      key: "name",
      name: "NAME",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2 plan-name",
      data: "string",
      isPadded: true,
    },
    {
      key: "price",
      name: "PRICE",
      fieldName: "price",
      minWidth: 170,
      maxWidth: 300,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        const isAnualRate = (item?.annualRate && Number(item?.annualRate) !== 0)
        const isMonthlyRate = (item?.monthlyRate && Number(item?.monthlyRate) !== 0)

        if (isMonthlyRate && isAnualRate)
          return `$${item?.monthlyRate}/mo, $${item?.annualRate}/year`

        if (isAnualRate)
          return `$${item?.annualRate}/year`

        if (isMonthlyRate)
          return `$${item?.monthlyRate}/mo`

        else return ''
      }
    },
    {
      key: "trial",
      name: "TRIAL",
      fieldName: "trialPeriod",
      minWidth: 130,
      maxWidth: 200,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return Number(item.trialPeriod) === 0 ? 'No trial' : Number(item.trialPeriod) + ' days';
      }
    },
    {
      key: "priority",
      name: "PRIORITY",
      fieldName: "proirity",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
    },
    {
      key: "status",
      name: "STATUS",
      fieldName: "status",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        const statusText = item.active ? "Active" : "Inactive";

        return (
          <div className="status-container">
            <div className={`status-color ${item.active ? "green" : "red"}`} />
            <div className={`status-text body2 ${item.active ? "green" : "red"}`}>
              {statusText}
            </div>
          </div>
        );
      },

    },

    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: MembershipPlanDto) => {
        const menuProps: IContextualMenuProps = {
          shouldFocusOnMount: true,
          isBeakVisible: false,
          items: [
            { key: 'delete', text: 'Delete', onClick: () => onDeletePlan(item.id) },
          ],
        }
        return <div onClick={(e) => e.stopPropagation()}><ActionButton className="contextual-menu" menuProps={menuProps}><ThreeDots /></ActionButton></div>;
      },
      isPadded: true,
    },
  ];
  useEffect(() => {
    getPlans();
    getDiscounts();
  }, [getPlans, getDiscounts]);


  const [isRemoveOpen, setIsRemoveOpen] = React.useState(false);
  const [planRemoveId, setPlanRemoveId] = React.useState("");
  const [isAddPlanModalOpen, setIsAddPlanModalOpen] = React.useState(false);

  const [filter, setFilter] = React.useState("");

  const onItemInvoked = (plan: MembershipPlanDto): void => {
    const target = document.activeElement;
    if (!target?.closest('.contextual-menu')) {
      selectPlan(plan.id)
      setWebConfigUI(webConfigUI.MEMBERSHIP_DETAILS);
    }

  };

  const onDeletePlan = (id: string) => {
    setIsRemoveOpen(true);
    setPlanRemoveId(id);
  }
  const handleDeletePlan = () => {
    removePlan(planRemoveId);
    setPlanRemoveId("");
    setIsRemoveOpen(false);

  }
  const confirmedRemove = () => {
    setPlanRemoveId("");
    setIsRemoveOpen(false);
  };

  const filteredPlans = filter
    ? plans.filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase())
    )
    : plans;

  const handleAddPlanOpen = () => {
    setIsAddPlanModalOpen(true)
  }
  const handleAddPlanClose = () => {
    setIsAddPlanModalOpen(false)
  }
  return (
    <>
      {isRequestInProgress && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className="plans">
        <div className="plan-list-container">
          <div className="h6 plan-heading">Plans</div>
        </div>
        {filteredPlans?.length > 0 ? (
          <div className="plans-list min-height-extended">
            <ScrollablePane>
              <DetailsList
                items={filteredPlans}
                styles={{ focusZone: { cursor: "pointer" } }}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={onItemInvoked}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-plan-list-container">
            <div className="empty-plan-list">
              <PlusIcon />
              <div className="empty-plan-list-message">
                Let’s get your first plan set up!
              </div>
              <ActionsButton
                onClick={addPlan}
              >
                <Icon iconName="Add" className="add-icon" />
                <div className="add-button-text">
                  Add Plan
                </div>
              </ActionsButton>
            </div>
          </div>
        )}
      </div>
      {isRemoveOpen && (
        <DeleteConfirmation
          ConfirmationHeader={"Confirmation"}
          ConfirmationText={"Do you want to remove this plan?"}
          ConfirmationButtonText={"Delete Plan"}
          onClose={confirmedRemove}
          onDelete={handleDeletePlan}
        />
      )}
    </>
  );
};

export default connector(Plans);
