import React from "react";
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { TextField, TextFieldProps, InputAdornment } from "@material-ui/core";
import CalendarIcon from "../../../assets/calendar-icon.svgr";
import "./formDateField.scss";

const CustomizedTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CalendarIcon />
          </InputAdornment>
        )
      }}
    />
  );
};
const FormDateField = ({ label, className, ...other }: DatePickerProps & { label: string }) => {

  return <div className={className ? `form-date-field ${className}` : "form-date-field"}>
    <div className="subtitle2">{label}</div>
    <DatePicker {...other} className="date-picker" TextFieldComponent={CustomizedTextField} />
  </div>;
};

export default FormDateField