import { ActionTypeEnum, AppThunk } from ".";
import {
  getWithAuth,
  postWithAuth,
  putWithAuth,
  deleteWithAuth,
  PARTNERS_URL,
} from "./api";
import { CreatePartnerDto, PartnerDto } from "../store/types";
import { addErrorAction } from "./auth-actions";
import { setWebConfigUIAction } from "./ui-actions";
import { webConfigUI } from "../constants/webConfigUI";

export const getPartnersAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetPartners });
    const response = await getWithAuth(`${PARTNERS_URL}`);
    dispatch({
      type: ActionTypeEnum.GetPartnersSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetPartnersFailure,
      payload: "error getting partners",
    });
    dispatch(addErrorAction("Get partners failure"));
  }
};

export const createPartnerAction =
  (data: CreatePartnerDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.CreatePartner });
      const response = await postWithAuth(PARTNERS_URL, data);
      dispatch({
        type: ActionTypeEnum.CreatePartnerSuccess,
        payload: response.data,
      });
    } catch (e) {
      let errorMessage = "error create partner";
      if (!!e?.response?.data?.message) {
        errorMessage = e?.response?.data?.message;
      }
      dispatch({
        type: ActionTypeEnum.CreatePartnerFailure,
        payload: errorMessage,
      });
      dispatch(addErrorAction(errorMessage));
    }
  };
export const getPartnerAction =
  (partnerId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetPartner });
      const response = await getWithAuth(`${PARTNERS_URL}/${partnerId}`);
      dispatch({
        type: ActionTypeEnum.GetPartnerSuccess,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.GetPartnerFailure,
        payload: "error get partner",
      });
      dispatch(addErrorAction("Get partner failure"));
    }
  };

export const updatePartnerAction =
  ({ id, name }: { id: string; name: string }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.UpdatePartner });
      const response = await putWithAuth(`${PARTNERS_URL}/${id}`, { id, name });
      dispatch({
        type: ActionTypeEnum.UpdatePartnerSuccess,
        payload: response.data,
      });
      dispatch(getPartnerAction(id));
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.UpdatePartnerFailure,
        payload: "error update partner",
      });
      dispatch(addErrorAction("Update partner failure"));
    }
  };

export const removePartnerAction =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.DeletePartner });
      const response = await deleteWithAuth(`${PARTNERS_URL}/${id}`);
      dispatch({
        type: ActionTypeEnum.DeletePartnerSuccess,
        payload: id,
      });
      dispatch(setWebConfigUIAction(webConfigUI.PARTNERS));
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.DeletePartnerFailure,
        payload: "error update partner",
      });
      dispatch(addErrorAction("Update partner failure"));
    }
  };

export const selectPartnerAction = (partner: PartnerDto) => ({
  type: ActionTypeEnum.SetPartner,
  payload: partner,
});

export const setPartnerErrorAction = (error: string) => ({
  type: ActionTypeEnum.SetPartnerError,
  payload: error,
});
