import {ResourceCategorySettingDto, ResourceSettingDto, ResourcesState} from "../store/types";
import {ActionTypeEnum} from "../actions";
import {createReducer} from "./reducer-utils";
import {State} from "../store/types";
import {createSelector} from "reselect";

const initialState: ResourcesState = Object.freeze({
  resourceCategories: [],
  isLoadingResourceCategories: false,
  aboutGolfSims: [],
})

export default createReducer<ResourcesState>(initialState, {
  [ActionTypeEnum.GetResourceCategories]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
    error: '',
  }),
  [ActionTypeEnum.GetResourceCategoriesSuccess]: (resourceCategories) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      resourceCategories,
      error: '',
    };
  },
  [ActionTypeEnum.GetResourceCategoriesFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
  [ActionTypeEnum.UpdateResourceCategory]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateResourceCategorySuccess]: (newCategory) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      resourceCategories: state.resourceCategories.map(category => {
        if(category.id === newCategory.id){
          return newCategory
        }

        return category
      }),
      error: '',
    };
  },
  [ActionTypeEnum.UpdateResourceCategoryFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
  [ActionTypeEnum.DeleteResourceCategory]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
  }),
  [ActionTypeEnum.DeleteResourceCategorySuccess]: (deletedCategoryId: string) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      resourceCategories: state.resourceCategories.filter((category: ResourceCategorySettingDto) => category.id !== deletedCategoryId),
    };
  },
  [ActionTypeEnum.DeleteResourceCategoryFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
  [ActionTypeEnum.SetCurrentResource]: (currentResource) => (state) => ({
    ...state,
    currentResource,
    aboutGolfSims: [],
  }),
  [ActionTypeEnum.SetCurrentResourceCategory]: (currentResourceCategory) => (state) => ({
    ...state,
    currentResourceCategory
  }),
  [ActionTypeEnum.AddResourceCategory]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
    error: '',
  }),
  [ActionTypeEnum.AddResourceCategorySuccess]: (newCategory) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      resourceCategories: [newCategory, ...state.resourceCategories],
      error: '',
    };
  },
  [ActionTypeEnum.AddResourceCategoryFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
  [ActionTypeEnum.AddResource]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
    error: '',
  }),
  [ActionTypeEnum.AddResourceSuccess]: (newResource) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      resourceCategories: state.resourceCategories.map(category => {
        if(category.id === newResource.resourceCategoryId){
          return {
            ...category,
            resources: [newResource, ...category.resources]
          }
        }

        return category
      }),
      error: '',
    };
  },
  [ActionTypeEnum.AddResourceFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
  [ActionTypeEnum.UpdateResource]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
  }),
  [ActionTypeEnum.UpdateResourceSuccess]: (updatedResource: ResourceSettingDto) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      resourceCategories: state.resourceCategories.map((category: ResourceCategorySettingDto) => {
        if (category.id === updatedResource.resourceCategoryId) {
          return {
            ...category,
            resources: category.resources.map((resource: ResourceSettingDto) => {
              if (resource.id === updatedResource.id) {
                return updatedResource
              }

              return resource
            })
          }
        }

        return category
      }),
    };
  },
  [ActionTypeEnum.UpdateResourceFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
  [ActionTypeEnum.DeleteResource]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
  }),
  [ActionTypeEnum.DeleteResourceSuccess]: (deletedResourceId: string) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      resourceCategories: state.resourceCategories.map((category: ResourceCategorySettingDto) => {
        return {
          ...category,
          resources: category.resources.filter((resource: ResourceSettingDto) => resource.id !== deletedResourceId)
        }
      }),
    };
  },
  [ActionTypeEnum.DeleteResourceFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
  [ActionTypeEnum.GetAboutGolfSims]: () => (state) => ({
    ...state,
    isLoadingResourceCategories: true,
    error: '',
  }),
  [ActionTypeEnum.GetAboutGolfSimsSuccess]: (aboutGolfSims) => (state) => {
    return {
      ...state,
      isLoadingResourceCategories: false,
      aboutGolfSims,
      error: '',
    };
  },
  [ActionTypeEnum.GetAboutGolfSimsFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceCategories: false,
    error,
  }),
});

export const selectResourcesState = (state: State) => state.resources;

export const selectIsLoadingResourceCategories = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.isLoadingResourceCategories
);

export const selectResourceCategories = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.resourceCategories
);

export const selectCurrentResourceCategory = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.currentResourceCategory
);

export const selectCurrentResource = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.currentResource
);

export const selectAboutGolfSims = createSelector(
  selectResourcesState,
  (state: ResourcesState) => state.aboutGolfSims
);