import { InventoryMethodTypes } from '../entities/enums';
import { TimeSlotPackageSettingDto, TimeSlotParentPackageSettingDto } from './timeSlotPackageSetting.dto';
export class SchedulePackageSettingDto {
  id: string;
  packageId: string;
  title: string;
  start: string;
  end: string;
  priority: number;
  inventoryMethod: InventoryMethodTypes;
  overrideParentSchedule: boolean;
  timeSlotsPackage: TimeSlotPackageSettingDto[];
  startTimeOnly: string;
  ageGroups?: string
}

export class CreateSchedulePackageSettingDto {
  packageId: string;
  title: string;
  start: string;
  end: string;
  priority: number;
  inventoryMethod: InventoryMethodTypes;
  overrideParentSchedule: boolean;
  timeSlotsPackage: TimeSlotPackageSettingDto[];
  startTimeOnly: string;
  ageGroups?: string

}

export class ParentScheduleDto {
  start: string;
  end: string;
  priority: number;
  timeSlotsPackage: TimeSlotParentPackageSettingDto[];
}