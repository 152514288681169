import { createSelector } from "reselect";
import { TenantState, State, TenantSettingsDto } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: TenantState = Object.freeze({
  isRequestInProgress: false,
  isUserRequestInProgress: false,
  tenants: [],
  users: [],
  selectedUser: undefined,
});

export default createReducer<TenantState>(initialState, {
  [ActionTypeEnum.GetTenants]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetTenantsSuccess]: (tenants: TenantSettingsDto[]) => (state) => ({
    ...state,
    isRequestInProgress: false,
    tenants,
    error: "",
  }),
  [ActionTypeEnum.GetTenantsFailure]: (error: string) => (state) => ({
    ...state,
    isRequestInProgress: false,
    tenants: [],
    error,
  }),
  [ActionTypeEnum.CreateTenant]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.CreateTenantSuccess]: (tenant) => (state) => ({
    ...state,
    isRequestInProgress: false,
    tenant,
    tenants: [...state.tenants, tenant],
    error: "",
  }),
  [ActionTypeEnum.CreateTenantFailure]: (error: string) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UpdateTenant]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.UpdateTenantSuccess]: (tenant) => (state) => ({
    ...state,
    isRequestInProgress: false,
    tenant,
    tenants: state.tenants.map(item => item.id === tenant.id ? tenant : item),
    error: "",
  }),
  [ActionTypeEnum.UpdateTenantFailure]: (error: string) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.CloneTenant]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.CloneTenantSuccess]: (tenant) => (state) => ({
    ...state,
    isRequestInProgress: false,
    tenant,
    tenants: [...state.tenants, tenant],
    error: "",
  }),
  [ActionTypeEnum.CloneTenantFailure]: (error: string) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.DeleteTenant]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.DeleteTenantSuccess]: (id) => (state) => ({
    ...state,
    isRequestInProgress: false,
    tenants: state.tenants.filter(item => item.id !== id),
    error: "",
  }),
  [ActionTypeEnum.DeleteTenantFailure]: (error: string) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetTenant]: (tenant?: TenantSettingsDto) => (state) => ({
    ...state,
    tenant,
  }),
  [ActionTypeEnum.GetTenant]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetTenantSuccess]: (tenant) => (state) => ({
    ...state,
    isRequestInProgress: false,
    tenant,
    error: "",
  }),
  [ActionTypeEnum.GetTenantFailure]: (error: string) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.CreateUser]: () => (state) => ({
    ...state,
    isUserRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.CreateUserSuccess]: ({ user, tenantId }) => (state) => {
    let tenant = state.tenant
    if (tenant && tenant.id === tenantId) {
      tenant = { ...tenant, users: [...tenant.users, user] }
    }
    const tenants = state.tenants.map(item => item.id === tenant?.id ? tenant : item)
    return {
      ...state,
      tenant,
      tenants,
      isUserRequestInProgress: false,
      error: "",
    }
  },
  [ActionTypeEnum.CreateUserFailure]: (error: string) => (state) => ({
    ...state,
    isUserRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UpdateUser]: () => (state) => ({
    ...state,
    isUserRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.UpdateUserSuccess]: ({ user, tenantId }) => (state) => {
    let tenant = state.tenant
    if (tenant && tenant.id === tenantId) {
      tenant = { ...tenant, users: tenant.users.map(item => item.id === user?.id ? user : item) }
    }
    const tenants = state.tenants.map(item => item.id === tenant?.id ? tenant : item)
    return {
      ...state,
      tenant,
      tenants,
      isUserRequestInProgress: false,
      error: "",
    }
  },
  [ActionTypeEnum.UpdateUserFailure]: (error: string) => (state) => ({
    ...state,
    isUserRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.RemoveUser]: () => (state) => ({
    ...state,
    isUserRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.RemoveUserSuccess]: ({ id, tenantId }) => (state) => {
    let tenant = state.tenant
    if (tenant && tenant.id === tenantId) {
      tenant = { ...tenant, users: tenant.users.filter(item => item.id !== id) }
    }
    const tenants = state.tenants.map(item => item.id === tenant?.id ? tenant : item)
    return {
      ...state,
      tenant,
      tenants,
      isUserRequestInProgress: false,
      error: "",
    }
  },
  [ActionTypeEnum.RemoveUserFailure]: (error: string) => (state) => ({
    ...state,
    isUserRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.Logout]: () => (state) => ({
    ...state,
    tenant: undefined,
    tenants: [],
  }),
  [ActionTypeEnum.SetTenantError]: (error: string) => (state) => ({
    ...state,
    error,
  }),
  [ActionTypeEnum.GetUsers]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: '',
  }),
  [ActionTypeEnum.GetUsersSuccess]: (users) => (state) => {
    return {
      ...state,
      isRequestInProgress: false,
      users,
      error: '',
    };
  },
  [ActionTypeEnum.GetUsersFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SelectUser]: (user) => (state) => {
    return {
      ...state,
      selectedUser: user,
    };
  },
  [ActionTypeEnum.CreateTenantUser]: () => (state) => ({
    ...state,
    isUserRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.CreateTenantUserSuccess]: (user) => (state) => {
    return {
      ...state,
      users: [...state.users, user],
      isUserRequestInProgress: false,
      error: "",
    }
  },
  [ActionTypeEnum.CreateTenantUserFailure]: (error: string) => (state) => ({
    ...state,
    isUserRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UpdateTenantUser]: () => (state) => ({
    ...state,
    isUserRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.UpdateTenantUserSuccess]: (user) => (state) => {
    return {
      ...state,
      users: state.users.map(item => item.id === user?.id ? user : item),
      isUserRequestInProgress: false,
      error: "",
    }
  },
  [ActionTypeEnum.UpdateTenantUserFailure]: (error: string) => (state) => ({
    ...state,
    isUserRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.RemoveTenantUser]: () => (state) => ({
    ...state,
    isUserRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.RemoveTenantUserSuccess]: (id) => (state) => {
    return {
      ...state,
      users: state.users.filter(item => item.id !== id ),
      isUserRequestInProgress: false,
      error: "",
    }
  },
  [ActionTypeEnum.RemoveTenantUserFailure]: (error: string) => (state) => ({
    ...state,
    isUserRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UsersDownloadRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.UsersDownloadSuccess]: () => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.UsersDownloadFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
});

export const selectTenantsState = (state: State) => state.tenants;
export const selectTenants = createSelector(
  selectTenantsState,
  (state) => state.tenants
);

export const selectIsTenantRequestInProgress = createSelector(
  selectTenantsState,
  (state) => state.isRequestInProgress
);
export const selectIsUserRequestInProgress = createSelector(
  selectTenantsState,
  (state) => state.isUserRequestInProgress
);
export const selectTenantError = createSelector(
  selectTenantsState,
  (state) => state.error
);
export const selectTenant = createSelector(
  selectTenantsState,
  (state) => state.tenant
);
export const selectUsers = createSelector(
  selectTenantsState,
  (state) => state.users
);
export const selectSelectedUser = createSelector(
  selectTenantsState,
  (state) => state.selectedUser
);
