import React, { useEffect, useMemo, useRef, useState } from "react";
import FormSelectField from "../../../common/FormSelectField";
import { IDropdownOption, Modal, Toggle } from "office-ui-fabric-react";
import {
  AddonModifierDto,
  DepositPercentageType,
  ModifierType,
  State,
} from "../../../../store/types";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editAddonModifier.scss";
import { useViewport } from "../../../../hooks/responsive";
import {
  selectReadonly,
  selectWebConfigUI,
} from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import {
  selectAddonError,
  selectModifier,
  selectIsLoadingAddon,
} from "../../../../reducers/addons";
import { webConfigUI } from "../../../../constants/webConfigUI";
import {
  updateModifierAction,
  createModifierAction,
} from "../../../../actions/addon-action";

export const DepositPercentageTypeText = {
  [DepositPercentageType.noDeposit as string]: "No Deposit (0%)",
  [DepositPercentageType.usePackageDeposit as string]: "Use Package Deposit",
};

export const modifierTypeToText = {
  [ModifierType.singleSelect as string]: "Single Select",
  [ModifierType.multiSelect as string]: "Multi Select",
}

const modifierOptions = [
  {
    key: ModifierType.singleSelect,
    text: modifierTypeToText[ModifierType.singleSelect as string]
  },
  {
    key: ModifierType.multiSelect,
    text: modifierTypeToText[ModifierType.multiSelect as string],
  },
];

interface OwnProps {
  onClose: () => void;
  isCloneAddonModifier: boolean;
}

const mapDispatchToProps = {
  updateAddonModifier: updateModifierAction,
  createModifier: createModifierAction,
};
const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddonModifier: selectModifier(state),
  isLoadingAddonModifier: selectIsLoadingAddon(state),
  error: selectAddonError(state),
  webConfigUIPage: selectWebConfigUI(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditAddonModifier = ({
  readonly,
  onClose,
  currentAddonModifier,
  isLoadingAddonModifier,
  error,
  webConfigUIPage,
  updateAddonModifier,
  createModifier,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    if (!isLoadingAddonModifier && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingAddonModifier]);
  const { isMobile } = useViewport();
  const [values, setValues] = useState(currentAddonModifier);

  const errorMessages = useMemo(() => {
    const errors = {
      minimumQuantity: "",
      maximumQuantity: "",
    };

    return errors;
  }, [values]);

  const onValueChange = (key: keyof AddonModifierDto, value: any) => {
    setValues({ ...values, [key]: value } as AddonModifierDto);
  };

  const getPatch = () => {
    if (!values) {
      return;
    }
    return {
      name: values.name,
      priority: values.priority || 1,
      title: values.title,
      modifierType: values.modifierType,
      minimum: values.minimum,
      maximum: values.maximum,
    };
  };

  const _onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      errorMessages.minimumQuantity ||
      errorMessages.maximumQuantity ||
      !values
    ) {
      return;
    }

    if (currentAddonModifier) {
      saving.current = true;
      const patch = getPatch();
      updateAddonModifier({ ...currentAddonModifier, ...patch });
    } else {
      if (!(values?.name && values)) {
        return;
      }
      saving.current = true;
      createModifier({
        name: values.name,
        priority: values.priority || 1,
        title: values.title,
        modifierType: values.modifierType,
        minimum: values.minimum || 0,
        maximum: values.maximum || 1,
        required: values.required || false,
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-addon"
    >
      <div className="title h4">
        {currentAddonModifier ? "Update" : "Create"} Modifier
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="addon" onSubmit={_onUpdate}>
        <div className="edit-package-fields">
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Modifier Title"
              className="long-field"
              value={values?.title ? String(values?.title) : ""}
              onChange={(_: any, text?: string) =>
                onValueChange("title", text || "")
              }
              autoComplete="off"
            />
            {!isMobile && <div className="delimiter" />}
            <FormTextField
              label="Priority"
              className="short-field"
              type="number"
              value={String(values?.priority)}
              onChange={(_: any, value?: string) =>
                onValueChange("priority", parseFloat(value || "") || 0)
              }
              autoComplete="off"
              required
            />
          </div>

          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Internal Name"
              value={values?.name}
              onChange={(_: any, text?: string) => onValueChange("name", text)}
              autoComplete="off"
              required
            />
            {!isMobile && <div className="delimiter" />}
            <FormSelectField
              label="Modifier Type"
              options={modifierOptions}
              selectedKey={values?.modifierType as ModifierType}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => onValueChange("modifierType", item?.key as ModifierType)}
              required
            />
          </div>
          {values?.modifierType !== ModifierType.singleSelect ? (
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormTextField
                label="Minimum Quantity"
                type="number"
                value={String(values?.minimum)}
                onChange={(_: any, value?: string) =>
                  onValueChange("minimum", parseFloat(value || "") || 0)
                }
                autoComplete="off"
              />
              {!isMobile && <div className="delimiter" />}
              <FormTextField
                label="Maximum Quantity"
                type="number"
                value={String(values?.maximum)}
                onChange={(_: any, value?: string) =>
                  onValueChange("maximum", parseFloat(value || "") || 0)
                }
                autoComplete="off"
              />
            </div>
          ) : null}
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <Toggle
              label="Required"
              checked={values?.required}
              onChange={(_: any, isChecked?: boolean) =>
                onValueChange("required", !!isChecked)
              }
              inlineLabel
            />
          </div>
          <div className="buttons-container">
            <LinkButton onClick={onClose}>Cancel</LinkButton>
            <ColorButton
              type="submit"
              disabled={
                readonly ||
                !!errorMessages.minimumQuantity ||
                !!errorMessages.maximumQuantity
              }
            >
              Save Changes
            </ColorButton>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditAddonModifier);
