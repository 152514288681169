import { ActionTypeEnum, AppThunk } from ".";
import {deleteWithAuth, getWithAuth, postWithAuth, putWithAuth} from "./api";
import {addErrorAction} from "./auth-actions";
import {ResourceCategorySettingDto, ResourceSettingDto} from '../store/types/resources'
const GET_RESOURCE_URL = "/api/resources-setting";


export const getResourceCategoriesAction = (venueId: string): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.GetResourceCategories
  })

  try {
    const response = await getWithAuth(`${GET_RESOURCE_URL}/category/list/${venueId}`);
    dispatch({
      type: ActionTypeEnum.GetResourceCategoriesSuccess,
      payload: response.data,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.GetResourceCategoriesFailure,
      payload: error,
    });
    dispatch(addErrorAction("Get resources failure"));
  }
}

export const updateResourceCategoryAction = (category: ResourceCategorySettingDto): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.UpdateResourceCategory
  })

  try {
    const response = await putWithAuth(`${GET_RESOURCE_URL}/category`, category);
    dispatch({
      type: ActionTypeEnum.UpdateResourceCategorySuccess,
      payload: response.data,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.UpdateResourceCategoryFailure,
      payload: error,
    });
    dispatch(addErrorAction("Update resource category failure"));
  }
}

export const addResourceCategoryAction = (category: Omit<ResourceCategorySettingDto, "id">): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.AddResourceCategory
  })

  try {
    const response = await postWithAuth(`${GET_RESOURCE_URL}/category`, category);
    dispatch({
      type: ActionTypeEnum.AddResourceCategorySuccess,
      payload: response.data,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.AddResourceCategoryFailure,
      payload: error,
    });
    dispatch(addErrorAction("Add resource category failure"));
  }
}

export const deleteResourceCategoryAction = (categoryId: string): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.DeleteResourceCategory
  })

  try {
    await deleteWithAuth(`${GET_RESOURCE_URL}/category/${categoryId}`);
    dispatch({
      type: ActionTypeEnum.DeleteResourceCategorySuccess,
      payload: categoryId,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.DeleteResourceCategoryFailure,
      payload: error,
    });
    dispatch(addErrorAction("Delete category failure"));
  }
}

export const addResourceAction = (resource: Partial<ResourceSettingDto>): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.AddResource
  })

  try {
    const response = await postWithAuth(`${GET_RESOURCE_URL}/resource`, resource);
    dispatch({
      type: ActionTypeEnum.AddResourceSuccess,
      payload: response.data,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.AddResourceFailure,
      payload: error,
    });
    dispatch(addErrorAction("Add resource failure"));
  }
}

export const updateResourceAction = (resource: ResourceSettingDto): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.UpdateResource
  })

  try {
    const response = await putWithAuth(`${GET_RESOURCE_URL}/resource`, resource);
    dispatch({
      type: ActionTypeEnum.UpdateResourceSuccess,
      payload: response.data,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.UpdateResourceFailure,
      payload: error,
    });
    dispatch(addErrorAction("Update resource failure"));
  }
}

export const deleteResourceAction = (resourceId: string): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.DeleteResource
  })

  try {
    await deleteWithAuth(`${GET_RESOURCE_URL}/resource/${resourceId}`);
    dispatch({
      type: ActionTypeEnum.DeleteResourceSuccess,
      payload: resourceId,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.DeleteResourceFailure,
      payload: error,
    });
    dispatch(addErrorAction("Delete resource failure"));
  }
}

export const setCurrentResourceCategoryAction = (category?: ResourceCategorySettingDto): AppThunk => async (dispatch) => {
  dispatch({ type: ActionTypeEnum.SetCurrentResourceCategory, payload: category });
};

export const setCurrentResourceAction = (category?: ResourceSettingDto): AppThunk => async (dispatch) => {
  dispatch({ type: ActionTypeEnum.SetCurrentResource, payload: category });
};

export const getAboutGolfSimListAction = (aboutGolfFacilityId: string): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.GetAboutGolfSims
  })
  try {
    const response = await getWithAuth(`${GET_RESOURCE_URL}/resource/about-golf-sims/${aboutGolfFacilityId}`);
    dispatch({
      type: ActionTypeEnum.GetAboutGolfSimsSuccess,
      payload: response.data,
    });
  }
  catch (error) {
    dispatch({
      type: ActionTypeEnum.GetAboutGolfSimsFailure,
      payload: error,
    });
    dispatch(addErrorAction("Get About Golf Sim List failure"));
  }
}
