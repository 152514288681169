import { PackageSettingDto } from "../../../../server/src/dto/packageSetting.dto";
import { ParentScheduleDto } from "../../../../server/src/dto/schedulePackageSetting.dto";

export * from "../../../../server/src/dto/packageSetting.dto";
export * from "../../../../server/src/dto/schedulePackageSetting.dto";
export * from "../../../../server/src/dto/timeSlotPackageSetting.dto";
export { Slot } from "../../../../server/src/entities/enums";
export { customFields } from "../../../../server/src/utils/package";

export interface PackagesState {
    packages: PackageSettingDto[];
    isLoadingPackages: boolean;
    isLoadingPackage: boolean;
    isRequestInProgress: boolean;
    isImageLoadInProgress: boolean;
    currentPackage?: PackageSettingDto;
    error?: string;
    currentPackageSchedule: [][];
    bookedPackageSlots: number[];
    freePackageSlots: number[];
    filteredPackages: PackageSettingDto[];
    parentPackageSchedule: ParentScheduleDto[];
}