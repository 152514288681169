import React, { useState, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal } from "office-ui-fabric-react";
import CancelButton from "../../../../../common/components/CancelButton";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import { createTenantAction, cloneTenantAction, setTenantErrorAction } from "../../../actions/tenants-actions";
import { selectIsTenantRequestInProgress, selectTenantError } from "../../../reducers/tenants";
import { State } from "../../../store/types";
import { isValidEmail } from "../../../../../common/utils/validate";
import "./createTenant.scss";

const mapDispatchToProps = {
  createTenant: createTenantAction,
  cloneTenant: cloneTenantAction,
  setTenantError: setTenantErrorAction,
};

const mapStateToProps = (state: State) => ({
  isLoadingTenant: selectIsTenantRequestInProgress(state),
  error: selectTenantError(state),
});

interface OwnProps {
  onClose: () => void;
  tenantId?: string;
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const CreateTenant = ({
  createTenant,
  cloneTenant,
  onClose,
  setTenantError,
  tenantId,
  isLoadingTenant,
  error,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setTenantError("");
  }, []);
  useEffect(() => {
    if (!isLoadingTenant && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingTenant]);

  useEffect(() => {
    setDomain('')
    setEmail('')
    setPassword('')
    setName('')
  }, []);

  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onCreateTenant = (e: React.FormEvent) => {
    e.preventDefault();
    if(name && domain && email && isValidEmail(email) && password) {
      saving.current = true;
      if(tenantId){
        cloneTenant({ name, domain, email, password, tenantId })
      } else {
        createTenant({ name, domain, email, password })
      }
    } else {
      setTenantError(`${tenantId ? "Clone" : "Create" } tenant failure`);
    }
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="create-tenant"
    >
      <div className="title h4">
        {tenantId ? "Clone" : "Create New"} Tenant
        <CancelButton onClick={onClose} />
      </div>
      <form className="form-tenant" onSubmit={onCreateTenant}>
        <FormTextField
          label="Admin Email"
          className="row"
          value={email}
          onChange={(_: any, text?: string) => setEmail(text || "")}
          autoComplete="off"
          required={true}
        />
        <FormTextField
          label="Admin Password"
          className="row"
          type="password"
          value={password}
          onChange={(_: any, text?: string) => setPassword(text || "")}
          autoComplete="off"
          required={true}
        />
        <FormTextField
          label="Name"
          className="row"
          value={name}
          onChange={(_: any, text?: string) => setName(text || "")}
          autoComplete="off"
          required={true}
        />
        <FormTextField
          label="Domain"
          className="row"
          value={domain}
          onChange={(_: any, text?: string) => setDomain(text || "")}
          autoComplete="off"
          required={true}
        />
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" >
            Save Change
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
}

export default connector(CreateTenant);
