import { createSelector } from "reselect";
import { nanoid } from "nanoid";
import { ReservationState, State, ReservationsPeriod } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: ReservationState = Object.freeze({
  confirmed: false,
  isPaymentInProgress: false,
  isRequestInProgress: false,
  paymentCompleted: false,
  reservations: [],
  totalCount: 0,
  searchConfirmationNumber: "",
  lastLoadedPage: 0,
  periodOfReservations: ReservationsPeriod.all,
  venueIdForFilterReservations: "",
  columns: [],
  transactions: [],
  filterParameters: [{
    id: nanoid(),
    filterParameter: null,
    filterCondition: null,
    filterValue: "",
  }],
});

export default createReducer<ReservationState>(initialState, {
  [ActionTypeEnum.Logout]: () => (state) => ({
    ...initialState,
  }),
  [ActionTypeEnum.GetReservationsRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetReservationsSuccess]: (reservations) => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    reservations,
  }),
  [ActionTypeEnum.GetReservationsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetReservationsTotalCountSuccess]: (totalCount) => (
    state
  ) => ({
    ...state,
    totalCount,
  }),
  [ActionTypeEnum.SetNumberOfSearchingReservation]: (
    searchConfirmationNumber
  ) => (state) => ({
    ...state,
    searchConfirmationNumber,
  }),
  [ActionTypeEnum.SetLastLoadedPage]: (lastLoadedPage) => (state) => ({
    ...state,
    lastLoadedPage,
  }),
  [ActionTypeEnum.SetPeriodOfReservations]: (periodOfReservations) => (state) => ({
    ...state,
    periodOfReservations,
  }),
  [ActionTypeEnum.SetVenueIdFilterReservations]: (venueIdForFilterReservations) => (state) => ({
    ...state,
    venueIdForFilterReservations,
  }),
  [ActionTypeEnum.SetColumns]: (columns) => (state) => ({
    ...state,
    columns,
  }),
  [ActionTypeEnum.GetTransactions]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.GetTransactionsSuccess]: (transactions) => (state) => {
    return {
      ...state,
      isRequestInProgress: false,
      transactions,
    };
  },
  [ActionTypeEnum.GetTransactionsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.RefundTransaction]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.RefundTransactionSuccess]: (transactions) => (state) => {
    return {
      ...state,
      isRequestInProgress: false,
      transactions,
    };
  },
  [ActionTypeEnum.RefundTransactionFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetTransactionAndReservationClear]: () => (state) => {
    return {
      ...state,
      transaction: undefined,
      reservation: undefined,
    };
  },
  [ActionTypeEnum.SelectReservation]: (reservation) => (state) => {
    return {
      ...state,
      reservation,
    };
  },
  [ActionTypeEnum.CancelReservation]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.CancelReservationSuccess]: ({
    reservation,
    transactions,
  }) => (state) => {
    return {
      ...state,
      isRequestInProgress: false,
      reservation,
      transactions,
      reservations: state.reservations.map((r) =>
        r.id === reservation.id ? reservation : r
      ),
    };
  },
  [ActionTypeEnum.CancelReservationFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.RemoveReservation]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.RemoveReservationSuccess]: () => (state) => ({
    ...initialState,
  }),
  [ActionTypeEnum.RemoveReservationFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.ResyncIntegration]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.ResyncIntegrationSuccess]: () => (state) => {
    return {
      ...state,
      isRequestInProgress: false,
    };
  },
  [ActionTypeEnum.ResyncIntegrationFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.ResendConfirmationEmail]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.ResendConfirmationEmailSuccess]: () => (state) => {
    return {
      ...state,
      isRequestInProgress: false,
    };
  },
  [ActionTypeEnum.ResendConfirmationEmailFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetReservationByResNumber]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.GetReservationByResNumberSuccess]: ({
    reservation,
    transactions,
  }) => (state) => {
    return {
      ...state,
      isRequestInProgress: false,
      reservation,
      transactions,
    };
  },
  [ActionTypeEnum.GetReservationByResNumberFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetFilterParametersForReservations]: (filterParameters) => (state) => ({
    ...state,
    filterParameters,
  }),
  [ActionTypeEnum.SyncReservationsRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.SyncReservationsSuccess]: () => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.SyncReservationsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
});
export const selectReservationState = (state: State) => state.reservation;
export const selectReservation = createSelector(
  selectReservationState,
  (state) => state.reservation
);
export const selectConfirmed = createSelector(
  selectReservationState,
  (state) => state.confirmed
);
export const selectPaymentCompleted = createSelector(
  selectReservationState,
  (state) => state.paymentCompleted
);
export const selectReservations = createSelector(
  selectReservationState,
  (state) => state.reservations
);
export const selectIsRequestInProgress = createSelector(
  selectReservationState,
  (state) => state.isRequestInProgress
);
export const selectReservationsTotalCount = createSelector(
  selectReservationState,
  (state) => state.totalCount
);
export const selectLastLoadedPage = createSelector(
  selectReservationState,
  (state) => state.lastLoadedPage
);
export const selectPeriodOfReservations = createSelector(
  selectReservationState,
  (state) => state.periodOfReservations
);
export const selectNumberOfSearchingReservation = createSelector(
  selectReservationState,
  (state) => state.searchConfirmationNumber
);
export const selectVenueIdFilterReservations = createSelector(
  selectReservationState,
  (state) => state.venueIdForFilterReservations
);
export const selectColumns = createSelector(
  selectReservationState,
  (state) => state.columns
);
export const selectTransactions = createSelector(
  selectReservationState,
  (state) => state.transactions
);
export const selectFilterParameters = createSelector(
  selectReservationState,
  (state) => state.filterParameters
);
