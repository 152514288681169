import React, { useState, useEffect, useCallback } from "react";
import {
  Icon,
  DetailsList,
  ScrollablePane,
  Sticky,
  StickyPositionType,
  IRenderFunction,
  IDetailsHeaderProps,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react";
import { debounce } from "lodash";
import {
  getPartnersAction,
  selectPartnerAction,
} from "../../actions/partners-actions";
import {
  selectPartners,
  selectIsPartnerRequestInProgress,
} from "../../reducers/partners";
import { connect, ConnectedProps } from "react-redux";
import { State, PartnerDto } from "../../store/types";
import CreatePartner from "./CreatePartner/CreatePartner";
import ActionsButton from "../common/ActionsButton";
import { webConfigUI } from "../../constants/webConfigUI";
import "./partners.scss";
import FormSearchField from "../common/FormSearchField";

const mapDispatchToProps = {
  getPartners: getPartnersAction,
  setPartner: selectPartnerAction,
};
const mapStateToProps = (state: State) => ({
  partners: selectPartners(state),
  isPartnerRequestInProgress: selectIsPartnerRequestInProgress(state),
});

interface OwnProps {
  setMainContainerUI: (view: webConfigUI) => void;
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const Partners = ({
  partners,
  isPartnerRequestInProgress,
  getPartners,
  setPartner,
  setMainContainerUI,
}: Props) => {
  const onColumnClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
      const newColumns: IColumn[] = columns.slice();
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      setColumns(newColumns);
    },
    [partners]
  );

  const getSortData = (): [string, boolean] => {
    for (let col of columns) {
      if (col.isSorted) {
        return [col.fieldName || "", !!col.isSortedDescending];
      }
    }
    return ["", false];
  };

  const [columns, setColumns] = useState<IColumn[]>([
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      className: "bold-column subtitle2",
      minWidth: 180,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      data: "string",
      isPadded: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick,
    },
  ]);

  useEffect(() => {
    getPartners();
  }, []);
  useEffect(() => {
    if (!isPartnerRequestInProgress) {
      setShowCreateForm(false);
    }
  }, [isPartnerRequestInProgress]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [filter, setFilter] = useState("");

  const onItemInvoked = (item: any): void => {
    setPartner(item);
    setMainContainerUI(webConfigUI.PARTNER_DETAILS);
  };

  const onSearchBoxConfirm = (newValue: string) => {
    setFilter(newValue);
  };

  const onSearchBoxClear = () => {
    setFilter("");
  };

  const onSearchChange = (ev: any, text: string | undefined = "") => {
    onSearchBoxConfirm(text);
  };

  const _copyAndSort = (
    items: PartnerDto[],
    columnKey: string,
    isSortedDescending?: boolean
  ): PartnerDto[] => {
    const key = columnKey as keyof PartnerDto;
    return [...items].sort((a: PartnerDto, b: PartnerDto) => {
      if (!a || !b) {
        return 1;
      }
      return (
        isSortedDescending
          ? (a[key] as any) < (b[key] as any)
          : (a[key] as any) > (b[key] as any)
      )
        ? 1
        : -1;
    });
  };
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }

    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender!(props)}
      </Sticky>
    );
  };

  const [sortField, isDesc] = getSortData();
  let items = partners;
  if (filter) {
    items = items.filter((item) => item.name.includes(filter));
  }
  if (sortField) {
    items = _copyAndSort(items, sortField, isDesc);
  }

  return (
    <div className="partners">
      <div className="partners-header">
        <div className="h4 title">Partners</div>
        <ActionsButton
          onClick={() => setShowCreateForm(true)}
          allowDisabledFocus
        >
          <Icon iconName="Add" className="add-icon" />
          <div className="add-button-text">Create New Partner</div>
        </ActionsButton>
      </div>
      <div className="inputs-block">
        <div className="searchbox-block">
          <FormSearchField
            placeholder="Search..."
            onSearch={onSearchBoxConfirm}
            onChange={debounce(onSearchChange, 2000)}
            onClear={onSearchBoxClear}
            value={filter}
          />
        </div>
      </div>
      <div className="partner-list">
        <ScrollablePane>
          <DetailsList
            items={items}
            styles={{ focusZone: { cursor: "pointer" } }}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item) => item.id}
            setKey="none"
            onRenderDetailsHeader={onRenderDetailsHeader}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onActiveItemChanged={onItemInvoked}
          />
        </ScrollablePane>
      </div>
      {showCreateForm && (
        <CreatePartner onClose={() => setShowCreateForm(false)} />
      )}
    </div>
  );
};

export default connector(Partners);
