export const allIcons = [
  {
    "name": "10k",
    "version": 248,
    "popularity": 189,
    "codepoint": 59729,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "10000",
      "10K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "10k",
    "version": 10,
    "popularity": 1230,
    "codepoint": 59729,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "10000",
      "10K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "10mp",
    "version": 248,
    "popularity": 108,
    "codepoint": 59730,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "10",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "10mp",
    "version": 10,
    "popularity": 647,
    "codepoint": 59730,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "10",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "11mp",
    "version": 248,
    "popularity": 89,
    "codepoint": 59731,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "11",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "11mp",
    "version": 10,
    "popularity": 599,
    "codepoint": 59731,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "11",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "123",
    "version": 248,
    "popularity": 2141,
    "codepoint": 60301,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "1",
      "2",
      "3",
      "digit",
      "number",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "123",
    "version": 1,
    "popularity": 5374,
    "codepoint": 60301,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "1",
      "2",
      "3",
      "digit",
      "number",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "12mp",
    "version": 248,
    "popularity": 108,
    "codepoint": 59732,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "12",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "12mp",
    "version": 10,
    "popularity": 741,
    "codepoint": 59732,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "12",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "13mp",
    "version": 248,
    "popularity": 97,
    "codepoint": 59733,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "13",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "13mp",
    "version": 10,
    "popularity": 569,
    "codepoint": 59733,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "13",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "14mp",
    "version": 248,
    "popularity": 92,
    "codepoint": 59734,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "14",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "14mp",
    "version": 10,
    "popularity": 549,
    "codepoint": 59734,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "14",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "15mp",
    "version": 248,
    "popularity": 95,
    "codepoint": 59735,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "15",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "15mp",
    "version": 10,
    "popularity": 564,
    "codepoint": 59735,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "15",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "16mp",
    "version": 248,
    "popularity": 94,
    "codepoint": 59736,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "16",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "16mp",
    "version": 10,
    "popularity": 568,
    "codepoint": 59736,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "16",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "17mp",
    "version": 248,
    "popularity": 86,
    "codepoint": 59737,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "17",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "17mp",
    "version": 10,
    "popularity": 556,
    "codepoint": 59737,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "17",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "18_up_rating",
    "version": 248,
    "popularity": 429,
    "codepoint": 63741,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "18_up_rating",
    "version": 1,
    "popularity": 681,
    "codepoint": 63741,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "18mp",
    "version": 248,
    "popularity": 106,
    "codepoint": 59738,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "18",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "18mp",
    "version": 10,
    "popularity": 657,
    "codepoint": 59738,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "18",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "19mp",
    "version": 248,
    "popularity": 93,
    "codepoint": 59739,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "19",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "19mp",
    "version": 10,
    "popularity": 542,
    "codepoint": 59739,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "19",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "1k",
    "version": 248,
    "popularity": 131,
    "codepoint": 59740,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "1000",
      "1K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "1k",
    "version": 10,
    "popularity": 866,
    "codepoint": 59740,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "1000",
      "1K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "1k_plus",
    "version": 248,
    "popularity": 101,
    "codepoint": 59741,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "1000",
      "1K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "1k_plus",
    "version": 10,
    "popularity": 794,
    "codepoint": 59741,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "1000",
      "1K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "1x_mobiledata",
    "version": 248,
    "popularity": 259,
    "codepoint": 61389,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1x",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "1x_mobiledata",
    "version": 9,
    "popularity": 1679,
    "codepoint": 61389,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1x",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "1x_mobiledata_badge",
    "version": 248,
    "popularity": 8,
    "codepoint": 63473,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1x",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "20mp",
    "version": 248,
    "popularity": 96,
    "codepoint": 59742,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "20",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "20mp",
    "version": 10,
    "popularity": 598,
    "codepoint": 59742,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "20",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "21mp",
    "version": 248,
    "popularity": 87,
    "codepoint": 59743,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "21",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "21mp",
    "version": 10,
    "popularity": 565,
    "codepoint": 59743,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "21",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "22mp",
    "version": 248,
    "popularity": 113,
    "codepoint": 59744,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "22",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "22mp",
    "version": 10,
    "popularity": 546,
    "codepoint": 59744,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "22",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "23mp",
    "version": 248,
    "popularity": 93,
    "codepoint": 59745,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "23",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "23mp",
    "version": 10,
    "popularity": 642,
    "codepoint": 59745,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "23",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "24mp",
    "version": 248,
    "popularity": 123,
    "codepoint": 59746,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "24",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "24mp",
    "version": 10,
    "popularity": 976,
    "codepoint": 59746,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "24",
      "camera",
      "digits",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "numbers",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "2d",
    "version": 248,
    "popularity": 9,
    "codepoint": 61239,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "2d",
      "alphabet",
      "character",
      "digit",
      "dimensional",
      "font",
      "letter",
      "number",
      "symbol",
      "text",
      "two",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "2k",
    "version": 248,
    "popularity": 104,
    "codepoint": 59747,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "2000",
      "2K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "2k",
    "version": 10,
    "popularity": 819,
    "codepoint": 59747,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "2000",
      "2K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "2k_plus",
    "version": 248,
    "popularity": 90,
    "codepoint": 59748,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "2k",
      "alphabet",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "plus",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "2k_plus",
    "version": 10,
    "popularity": 624,
    "codepoint": 59748,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "2k",
      "alphabet",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "plus",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "2mp",
    "version": 248,
    "popularity": 93,
    "codepoint": 59749,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "2",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "2mp",
    "version": 10,
    "popularity": 562,
    "codepoint": 59749,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "2",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "30fps",
    "version": 248,
    "popularity": 160,
    "codepoint": 61390,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "30fps",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "fps",
      "frames",
      "letter",
      "number",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "30fps",
    "version": 10,
    "popularity": 1281,
    "codepoint": 61390,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "30fps",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "fps",
      "frames",
      "letter",
      "number",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "30fps_select",
    "version": 248,
    "popularity": 157,
    "codepoint": 61391,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "30",
      "camera",
      "digits",
      "fps",
      "frame",
      "frequency",
      "image",
      "numbers",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "30fps_select",
    "version": 10,
    "popularity": 1069,
    "codepoint": 61391,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "30",
      "camera",
      "digits",
      "fps",
      "frame",
      "frequency",
      "image",
      "numbers",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "360",
    "version": 248,
    "popularity": 1787,
    "codepoint": 58743,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "360",
      "arrow",
      "av",
      "camera",
      "direction",
      "rotate",
      "rotation",
      "vr"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "360",
    "version": 14,
    "popularity": 8746,
    "codepoint": 58743,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "360",
      "arrow",
      "av",
      "camera",
      "direction",
      "rotate",
      "rotation",
      "vr"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "3d_rotation",
    "version": 248,
    "popularity": 1194,
    "codepoint": 59469,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "3",
      "3d",
      "D",
      "alphabet",
      "arrow",
      "arrows",
      "av",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "rotation",
      "symbol",
      "text",
      "type",
      "vr"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "3d_rotation",
    "version": 18,
    "popularity": 14574,
    "codepoint": 59469,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "3",
      "3d",
      "D",
      "alphabet",
      "arrow",
      "arrows",
      "av",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "rotation",
      "symbol",
      "text",
      "type",
      "vr"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "3g_mobiledata",
    "version": 248,
    "popularity": 189,
    "codepoint": 61392,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "3g_mobiledata",
    "version": 9,
    "popularity": 1117,
    "codepoint": 61392,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "3g_mobiledata_badge",
    "version": 248,
    "popularity": 1,
    "codepoint": 63472,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "3k",
    "version": 248,
    "popularity": 86,
    "codepoint": 59750,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "3000",
      "3K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "3k",
    "version": 10,
    "popularity": 680,
    "codepoint": 59750,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "3000",
      "3K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "3k_plus",
    "version": 248,
    "popularity": 86,
    "codepoint": 59751,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "3000",
      "3K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "3k_plus",
    "version": 10,
    "popularity": 623,
    "codepoint": 59751,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "3000",
      "3K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "3mp",
    "version": 248,
    "popularity": 93,
    "codepoint": 59752,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "3",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "3mp",
    "version": 10,
    "popularity": 583,
    "codepoint": 59752,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "3",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "3p",
    "version": 248,
    "popularity": 1220,
    "codepoint": 61393,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "3",
      "3p",
      "account",
      "avatar",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "face",
      "human",
      "message",
      "party",
      "people",
      "person",
      "profile",
      "speech",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "3p",
    "version": 11,
    "popularity": 7521,
    "codepoint": 61393,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "3",
      "3p",
      "account",
      "avatar",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "face",
      "human",
      "message",
      "party",
      "people",
      "person",
      "profile",
      "speech",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "4g_mobiledata",
    "version": 248,
    "popularity": 330,
    "codepoint": 61394,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "4g_mobiledata",
    "version": 9,
    "popularity": 1938,
    "codepoint": 61394,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "4g_mobiledata_badge",
    "version": 248,
    "popularity": 1,
    "codepoint": 63471,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "4g_plus_mobiledata",
    "version": 248,
    "popularity": 219,
    "codepoint": 61395,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "plus",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "4g_plus_mobiledata",
    "version": 9,
    "popularity": 1312,
    "codepoint": 61395,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4g",
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "plus",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "4k",
    "version": 248,
    "popularity": 352,
    "codepoint": 57458,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "4000",
      "4K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "4k",
    "version": 11,
    "popularity": 1872,
    "codepoint": 57458,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "4000",
      "4K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "4k_plus",
    "version": 248,
    "popularity": 156,
    "codepoint": 59753,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "4000",
      "4K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "4k_plus",
    "version": 10,
    "popularity": 914,
    "codepoint": 59753,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "4000",
      "4K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "4mp",
    "version": 248,
    "popularity": 87,
    "codepoint": 59754,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "4",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "4mp",
    "version": 10,
    "popularity": 537,
    "codepoint": 59754,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "4",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "50mp",
    "version": 248,
    "popularity": 18,
    "codepoint": 63219,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "50",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "5g",
    "version": 248,
    "popularity": 575,
    "codepoint": 61240,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "5g",
      "alphabet",
      "cellular",
      "character",
      "data",
      "digit",
      "font",
      "letter",
      "mobile",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "5g",
    "version": 13,
    "popularity": 2498,
    "codepoint": 61240,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "5g",
      "alphabet",
      "cellular",
      "character",
      "data",
      "digit",
      "font",
      "letter",
      "mobile",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "5g_mobiledata_badge",
    "version": 248,
    "popularity": 6,
    "codepoint": 63470,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "5g",
      "alphabet",
      "cellular",
      "character",
      "data",
      "digit",
      "font",
      "letter",
      "mobile",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "5k",
    "version": 248,
    "popularity": 97,
    "codepoint": 59755,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "5000",
      "5K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "5k",
    "version": 10,
    "popularity": 696,
    "codepoint": 59755,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "5000",
      "5K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "5k_plus",
    "version": 248,
    "popularity": 89,
    "codepoint": 59756,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "5000",
      "5K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "5k_plus",
    "version": 10,
    "popularity": 654,
    "codepoint": 59756,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "5000",
      "5K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "5mp",
    "version": 248,
    "popularity": 96,
    "codepoint": 59757,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "5",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "5mp",
    "version": 10,
    "popularity": 570,
    "codepoint": 59757,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "5",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "60fps",
    "version": 248,
    "popularity": 188,
    "codepoint": 61396,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "60fps",
      "camera",
      "digit",
      "fps",
      "frames",
      "number",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "60fps",
    "version": 10,
    "popularity": 1073,
    "codepoint": 61396,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "60fps",
      "camera",
      "digit",
      "fps",
      "frames",
      "number",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "60fps_select",
    "version": 248,
    "popularity": 160,
    "codepoint": 61397,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "60",
      "camera",
      "digits",
      "fps",
      "frame",
      "frequency",
      "numbers",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "60fps_select",
    "version": 10,
    "popularity": 998,
    "codepoint": 61397,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "60",
      "camera",
      "digits",
      "fps",
      "frame",
      "frequency",
      "numbers",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "6_ft_apart",
    "version": 248,
    "popularity": 433,
    "codepoint": 61982,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "6",
      "apart",
      "body",
      "covid",
      "distance",
      "feet",
      "ft",
      "human",
      "people",
      "person",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "6_ft_apart",
    "version": 9,
    "popularity": 2372,
    "codepoint": 61982,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "6",
      "apart",
      "body",
      "covid",
      "distance",
      "feet",
      "ft",
      "human",
      "people",
      "person",
      "social"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "6k",
    "version": 248,
    "popularity": 91,
    "codepoint": 59758,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "6000",
      "6K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "6k",
    "version": 10,
    "popularity": 624,
    "codepoint": 59758,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "6000",
      "6K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "6k_plus",
    "version": 248,
    "popularity": 87,
    "codepoint": 59759,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "6000",
      "6K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "6k_plus",
    "version": 10,
    "popularity": 624,
    "codepoint": 59759,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "6000",
      "6K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "6mp",
    "version": 248,
    "popularity": 91,
    "codepoint": 59760,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "6",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "6mp",
    "version": 10,
    "popularity": 533,
    "codepoint": 59760,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "6",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "7k",
    "version": 248,
    "popularity": 104,
    "codepoint": 59761,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "7000",
      "7K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "7k",
    "version": 10,
    "popularity": 624,
    "codepoint": 59761,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "7000",
      "7K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "7k_plus",
    "version": 248,
    "popularity": 86,
    "codepoint": 59762,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "7000",
      "7K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "7k_plus",
    "version": 10,
    "popularity": 622,
    "codepoint": 59762,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "7000",
      "7K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "7mp",
    "version": 248,
    "popularity": 96,
    "codepoint": 59763,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "7",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "7mp",
    "version": 10,
    "popularity": 533,
    "codepoint": 59763,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "7",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "8k",
    "version": 248,
    "popularity": 134,
    "codepoint": 59764,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "8000",
      "8K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "8k",
    "version": 11,
    "popularity": 757,
    "codepoint": 59764,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "8000",
      "8K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "8k_plus",
    "version": 248,
    "popularity": 107,
    "codepoint": 59765,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "7000",
      "8K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "8k_plus",
    "version": 11,
    "popularity": 694,
    "codepoint": 59765,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "7000",
      "8K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "8mp",
    "version": 248,
    "popularity": 93,
    "codepoint": 59766,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "8",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "8mp",
    "version": 10,
    "popularity": 534,
    "codepoint": 59766,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "8",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "9k",
    "version": 248,
    "popularity": 98,
    "codepoint": 59767,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "9000",
      "9K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "9k",
    "version": 10,
    "popularity": 631,
    "codepoint": 59767,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "9000",
      "9K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "9k_plus",
    "version": 248,
    "popularity": 99,
    "codepoint": 59768,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "9000",
      "9K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "9k_plus",
    "version": 10,
    "popularity": 695,
    "codepoint": 59768,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "9000",
      "9K",
      "alphabet",
      "character",
      "digit",
      "display",
      "font",
      "letter",
      "number",
      "pixel",
      "pixels",
      "plus",
      "resolution",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "9mp",
    "version": 248,
    "popularity": 102,
    "codepoint": 59769,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "9",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "9mp",
    "version": 10,
    "popularity": 534,
    "codepoint": 59769,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "9",
      "camera",
      "digit",
      "font",
      "image",
      "letters",
      "megapixel",
      "megapixels",
      "mp",
      "number",
      "pixel",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "abc",
    "version": 248,
    "popularity": 1422,
    "codepoint": 60308,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "abc",
    "version": 1,
    "popularity": 3191,
    "codepoint": 60308,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ac_unit",
    "version": 248,
    "popularity": 3037,
    "codepoint": 60219,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "ac",
      "air",
      "cold",
      "conditioner",
      "flake",
      "snow",
      "snowflake",
      "temperature",
      "unit",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ac_unit",
    "version": 12,
    "popularity": 17913,
    "codepoint": 60219,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "ac",
      "air",
      "cold",
      "conditioner",
      "flake",
      "snow",
      "snowflake",
      "temperature",
      "unit",
      "weather",
      "winter"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "access_alarm",
    "version": 12,
    "popularity": 2150,
    "codepoint": 57744,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "access_alarms",
    "version": 12,
    "popularity": 2345,
    "codepoint": 57745,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "access_time",
    "version": 12,
    "popularity": 10101,
    "codepoint": 57746,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "access_time_filled",
    "version": 10,
    "popularity": 4781,
    "codepoint": 61398,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "accessibility",
    "version": 248,
    "popularity": 1887,
    "codepoint": 59470,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "accessibility",
    "version": 13,
    "popularity": 26521,
    "codepoint": 59470,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "accessibility_new",
    "version": 248,
    "popularity": 3354,
    "codepoint": 59692,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "new",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "accessibility_new",
    "version": 13,
    "popularity": 24546,
    "codepoint": 59692,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "new",
      "people",
      "person"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "accessible",
    "version": 248,
    "popularity": 2215,
    "codepoint": 59668,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "people",
      "person",
      "wheelchair"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "accessible",
    "version": 13,
    "popularity": 13751,
    "codepoint": 59668,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "people",
      "person",
      "wheelchair"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "accessible_forward",
    "version": 248,
    "popularity": 1076,
    "codepoint": 59700,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "forward",
      "handicap",
      "help",
      "human",
      "people",
      "person",
      "wheelchair"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "accessible_forward",
    "version": 13,
    "popularity": 6762,
    "codepoint": 59700,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "forward",
      "handicap",
      "help",
      "human",
      "people",
      "person",
      "wheelchair"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "account_balance",
    "version": 248,
    "popularity": 10919,
    "codepoint": 59471,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "account",
      "balance",
      "bank",
      "bill",
      "building",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "finance",
      "government",
      "money",
      "online",
      "pay",
      "payment"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_balance",
    "version": 19,
    "popularity": 84790,
    "codepoint": 59471,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "balance",
      "bank",
      "bill",
      "building",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "finance",
      "government",
      "money",
      "online",
      "pay",
      "payment"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "account_balance_wallet",
    "version": 248,
    "popularity": 10136,
    "codepoint": 59472,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "account",
      "balance",
      "bank",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "wallet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_balance_wallet",
    "version": 12,
    "popularity": 69410,
    "codepoint": 59472,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "balance",
      "bank",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "wallet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "account_box",
    "version": 248,
    "popularity": 5149,
    "codepoint": 59473,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "avatar",
      "box",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "square",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_box",
    "version": 14,
    "popularity": 46051,
    "codepoint": 59473,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "avatar",
      "box",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "square",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "account_child",
    "version": 248,
    "popularity": 26,
    "codepoint": 59474,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "body",
      "child",
      "children",
      "human",
      "kid",
      "people",
      "person",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_child_invert",
    "version": 248,
    "popularity": 27,
    "codepoint": 58969,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "body",
      "child",
      "children",
      "human",
      "kid",
      "people",
      "person",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_circle",
    "version": 248,
    "popularity": 65999,
    "codepoint": 59475,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "avatar",
      "circle",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_circle",
    "version": 20,
    "popularity": 589461,
    "codepoint": 59475,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "avatar",
      "circle",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "account_circle_off",
    "version": 248,
    "popularity": 17,
    "codepoint": 63411,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "avatar",
      "circle",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_tree",
    "version": 248,
    "popularity": 6934,
    "codepoint": 59770,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "account",
      "analytics",
      "chart",
      "connect",
      "data",
      "diagram",
      "flow",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "process",
      "square",
      "statistics",
      "structure",
      "tracking",
      "tree"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "account_tree",
    "version": 12,
    "popularity": 44043,
    "codepoint": 59770,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "account",
      "analytics",
      "chart",
      "connect",
      "data",
      "diagram",
      "flow",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "process",
      "square",
      "statistics",
      "structure",
      "tracking",
      "tree"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "action_key",
    "version": 248,
    "popularity": 26,
    "codepoint": 62722,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "buttons",
      "circles",
      "computer",
      "device",
      "dots",
      "filter",
      "find",
      "glass",
      "grid",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "activity_zone",
    "version": 248,
    "popularity": 385,
    "codepoint": 57830,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "activity ",
      "home",
      "nest",
      "security",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "acute",
    "version": 248,
    "popularity": 129,
    "codepoint": 58571,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "clock",
      "date",
      "fast",
      "health",
      "schedule",
      "speed",
      "time",
      "timer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ad",
    "version": 248,
    "popularity": 47,
    "codepoint": 58970,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "ad",
      "advertisement",
      "banner",
      "browser",
      "internet",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ad_group",
    "version": 248,
    "popularity": 65,
    "codepoint": 58971,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "ad",
      "advertisement",
      "banner multiple",
      "browser",
      "group",
      "internet",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ad_group_off",
    "version": 248,
    "popularity": 30,
    "codepoint": 60133,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "Ads",
      "group",
      "hidden",
      "off",
      "pause",
      "remove",
      "settings",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ad_off",
    "version": 248,
    "popularity": 15,
    "codepoint": 63410,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "ad",
      "advertisement",
      "banner",
      "browser",
      "disabled",
      "enabled",
      "internet",
      "off",
      "offline",
      "on",
      "slash",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ad_units",
    "version": 248,
    "popularity": 760,
    "codepoint": 61241,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "Android",
      "OS",
      "ad",
      "banner",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "notification",
      "notifications",
      "phone",
      "tablet",
      "top",
      "units"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ad_units",
    "version": 13,
    "popularity": 2876,
    "codepoint": 61241,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "ad",
      "banner",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "notification",
      "notifications",
      "phone",
      "tablet",
      "top",
      "units"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "adb",
    "version": 248,
    "popularity": 787,
    "codepoint": 58894,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "adb",
      "android",
      "bridge",
      "debug"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "adb",
    "version": 12,
    "popularity": 4545,
    "codepoint": 58894,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "adb",
      "android",
      "bridge",
      "debug"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add",
    "version": 248,
    "popularity": 70476,
    "codepoint": 57669,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "add",
      "new symbol",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add",
    "version": 21,
    "popularity": 329296,
    "codepoint": 57669,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "+",
      "add",
      "new symbol",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_a_photo",
    "version": 248,
    "popularity": 6169,
    "codepoint": 58425,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "a photo",
      "add",
      "camera",
      "lens",
      "new",
      "photography",
      "picture",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_a_photo",
    "version": 14,
    "popularity": 34865,
    "codepoint": 58425,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "+",
      "a photo",
      "add",
      "camera",
      "lens",
      "new",
      "photography",
      "picture",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_ad",
    "version": 248,
    "popularity": 25,
    "codepoint": 59178,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "ad",
      "add",
      "advertisement",
      "browser",
      "internet",
      "plus",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_alarm",
    "version": 13,
    "popularity": 1273,
    "codepoint": 57747,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_alert",
    "version": 248,
    "popularity": 1055,
    "codepoint": 57347,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "active",
      "add",
      "alarm",
      "alert",
      "bell",
      "chime",
      "new",
      "notifications",
      "notify",
      "plus",
      "reminder",
      "ring",
      "sound",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_alert",
    "version": 16,
    "popularity": 6515,
    "codepoint": 57347,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "alert"
    ],
    "tags": [
      "+",
      "active",
      "add",
      "alarm",
      "alert",
      "bell",
      "chime",
      "new",
      "notifications",
      "notify",
      "plus",
      "reminder",
      "ring",
      "sound",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_box",
    "version": 248,
    "popularity": 10673,
    "codepoint": 57670,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "add",
      "box",
      "new square",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_box",
    "version": 13,
    "popularity": 41571,
    "codepoint": 57670,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "add",
      "box",
      "new square",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_business",
    "version": 248,
    "popularity": 2045,
    "codepoint": 59177,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "+",
      "add",
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "credit",
      "currency",
      "dollars",
      "market",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "shop",
      "shopping",
      "store",
      "storefront",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_business",
    "version": 17,
    "popularity": 10048,
    "codepoint": 59177,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "+",
      "add",
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "credit",
      "currency",
      "dollars",
      "market",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "shop",
      "shopping",
      "store",
      "storefront",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "add_call",
    "version": 248,
    "popularity": 810,
    "codepoint": 57576,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "+",
      "add",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "new",
      "phone",
      "plus",
      "symbol",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_call",
    "version": 10,
    "popularity": 795,
    "codepoint": 57576,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "+",
      "add",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "new",
      "phone",
      "plus",
      "symbol",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_card",
    "version": 248,
    "popularity": 2010,
    "codepoint": 60294,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "+",
      "add",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_card",
    "version": 1,
    "popularity": 5960,
    "codepoint": 60294,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "add",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "add_chart",
    "version": 248,
    "popularity": 899,
    "codepoint": 59771,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "+",
      "add",
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "new",
      "plus",
      "statistics",
      "symbol",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_chart",
    "version": 11,
    "popularity": 4481,
    "codepoint": 59771,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "+",
      "add",
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "new",
      "plus",
      "statistics",
      "symbol",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_circle",
    "version": 248,
    "popularity": 40068,
    "codepoint": 57671,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "add",
      "circle",
      "counter",
      "create",
      "new",
      "plus"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_circle",
    "version": 13,
    "popularity": 124726,
    "codepoint": 57671,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "+",
      "add",
      "circle",
      "counter",
      "create",
      "new",
      "plus"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_circle_outline",
    "version": 16,
    "popularity": 140626,
    "codepoint": 57672,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "+",
      "add",
      "circle",
      "create",
      "new",
      "outline",
      "plus"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_comment",
    "version": 248,
    "popularity": 2047,
    "codepoint": 57958,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "+",
      "add",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "new",
      "plus",
      "speech",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_comment",
    "version": 11,
    "popularity": 9008,
    "codepoint": 57958,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "+",
      "add",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "new",
      "plus",
      "speech",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_home",
    "version": 248,
    "popularity": 380,
    "codepoint": 63723,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_home",
    "version": 1,
    "popularity": 1358,
    "codepoint": 63723,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "add_home_work",
    "version": 248,
    "popularity": 321,
    "codepoint": 63725,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_home_work",
    "version": 1,
    "popularity": 1303,
    "codepoint": 63725,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "add_ic_call",
    "version": 12,
    "popularity": 4820,
    "codepoint": 59772,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "+",
      "add",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "new",
      "phone",
      "plus",
      "symbol",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_link",
    "version": 248,
    "popularity": 1381,
    "codepoint": 57720,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "add",
      "attach",
      "clip",
      "link",
      "new",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_link",
    "version": 11,
    "popularity": 9450,
    "codepoint": 57720,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "add",
      "attach",
      "clip",
      "link",
      "new",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_location",
    "version": 248,
    "popularity": 2750,
    "codepoint": 58727,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "+",
      "add",
      "destination",
      "direction",
      "location",
      "maps",
      "new",
      "pin",
      "place",
      "plus",
      "stop",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_location",
    "version": 16,
    "popularity": 6159,
    "codepoint": 58727,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "+",
      "add",
      "destination",
      "direction",
      "location",
      "maps",
      "new",
      "pin",
      "place",
      "plus",
      "stop",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_location_alt",
    "version": 248,
    "popularity": 1649,
    "codepoint": 61242,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "+",
      "add",
      "alt",
      "destination",
      "direction",
      "location",
      "maps",
      "new",
      "pin",
      "place",
      "plus",
      "stop",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_location_alt",
    "version": 13,
    "popularity": 7576,
    "codepoint": 61242,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "+",
      "add",
      "alt",
      "destination",
      "direction",
      "location",
      "maps",
      "new",
      "pin",
      "place",
      "plus",
      "stop",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_moderator",
    "version": 248,
    "popularity": 601,
    "codepoint": 59773,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "+",
      "add",
      "certified",
      "moderator",
      "new",
      "plus",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "symbol",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_moderator",
    "version": 12,
    "popularity": 5563,
    "codepoint": 59773,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "add",
      "certified",
      "moderator",
      "new",
      "plus",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "symbol",
      "verified"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_notes",
    "version": 248,
    "popularity": 40,
    "codepoint": 57489,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "doc",
      "document",
      "file",
      "new",
      "note",
      "page",
      "paper",
      "plus"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_photo_alternate",
    "version": 248,
    "popularity": 3910,
    "codepoint": 58430,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "add",
      "alternate",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "new",
      "photo",
      "photography",
      "picture",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_photo_alternate",
    "version": 12,
    "popularity": 23354,
    "codepoint": 58430,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "+",
      "add",
      "alternate",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "new",
      "photo",
      "photography",
      "picture",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_reaction",
    "version": 248,
    "popularity": 2377,
    "codepoint": 57811,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "+",
      "add",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "icon",
      "icons",
      "insert",
      "like",
      "mood",
      "new",
      "person",
      "pleased",
      "plus",
      "smile",
      "smiling",
      "social",
      "survey",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_reaction",
    "version": 9,
    "popularity": 5490,
    "codepoint": 57811,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "add",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "icon",
      "icons",
      "insert",
      "like",
      "mood",
      "new",
      "person",
      "pleased",
      "plus",
      "smile",
      "smiling",
      "social",
      "survey",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_road",
    "version": 248,
    "popularity": 729,
    "codepoint": 61243,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "+",
      "add",
      "destination",
      "direction",
      "highway",
      "maps",
      "new",
      "plus",
      "road",
      "stop",
      "street",
      "symbol",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_road",
    "version": 12,
    "popularity": 4798,
    "codepoint": 61243,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "+",
      "add",
      "destination",
      "direction",
      "highway",
      "maps",
      "new",
      "plus",
      "road",
      "stop",
      "street",
      "symbol",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "add_shopping_cart",
    "version": 248,
    "popularity": 6822,
    "codepoint": 59476,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "add",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "plus",
      "shopping"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_shopping_cart",
    "version": 15,
    "popularity": 54909,
    "codepoint": 59476,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "add",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "plus",
      "shopping"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_task",
    "version": 248,
    "popularity": 4006,
    "codepoint": 62010,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "add",
      "approve",
      "check",
      "circle",
      "completed",
      "increase",
      "mark",
      "ok",
      "plus",
      "select",
      "task",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_task",
    "version": 6,
    "popularity": 25693,
    "codepoint": 62010,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "add",
      "approve",
      "check",
      "circle",
      "completed",
      "increase",
      "mark",
      "ok",
      "plus",
      "select",
      "task",
      "tick",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_to_drive",
    "version": 248,
    "popularity": 631,
    "codepoint": 58972,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "add",
      "app",
      "application",
      "backup",
      "cloud",
      "drive",
      "files",
      "folders",
      "gdrive",
      "google",
      "recovery",
      "shortcut",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_to_drive",
    "version": 18,
    "popularity": 6499,
    "codepoint": 58972,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "add",
      "app",
      "application",
      "backup",
      "cloud",
      "drive",
      "files",
      "folders",
      "gdrive",
      "google",
      "recovery",
      "shortcut",
      "storage"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "add_to_home_screen",
    "version": 248,
    "popularity": 302,
    "codepoint": 57854,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "add to",
      "arrow",
      "cell",
      "device",
      "hardware",
      "home",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "tablet",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_to_home_screen",
    "version": 12,
    "popularity": 2120,
    "codepoint": 57854,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "add to",
      "arrow",
      "cell",
      "device",
      "hardware",
      "home",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "tablet",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_to_photos",
    "version": 248,
    "popularity": 860,
    "codepoint": 58269,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "add",
      "collection",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "photos",
      "picture",
      "plus",
      "to"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_to_photos",
    "version": 12,
    "popularity": 4411,
    "codepoint": 58269,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "add",
      "collection",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "photos",
      "picture",
      "plus",
      "to"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "add_to_queue",
    "version": 248,
    "popularity": 509,
    "codepoint": 57436,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "Android",
      "OS",
      "add",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "new",
      "plus",
      "queue",
      "screen",
      "symbol",
      "to",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "add_to_queue",
    "version": 11,
    "popularity": 3622,
    "codepoint": 57436,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "Android",
      "OS",
      "add",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "new",
      "plus",
      "queue",
      "screen",
      "symbol",
      "to",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "addchart",
    "version": 13,
    "popularity": 10260,
    "codepoint": 61244,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "addchart",
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "new",
      "plus",
      "statistics",
      "symbol",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "adf_scanner",
    "version": 248,
    "popularity": 243,
    "codepoint": 60122,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "adf",
      "document",
      "feeder",
      "machine",
      "office",
      "scan",
      "scanner"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "adf_scanner",
    "version": 2,
    "popularity": 1119,
    "codepoint": 60122,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "adf",
      "document",
      "feeder",
      "machine",
      "office",
      "scan",
      "scanner"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "adjust",
    "version": 248,
    "popularity": 1598,
    "codepoint": 58270,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "alter",
      "auto click",
      "center",
      "circle",
      "circles",
      "dot",
      "fix",
      "focus",
      "image",
      "move",
      "target"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "adjust",
    "version": 12,
    "popularity": 14937,
    "codepoint": 58270,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "alter",
      "auto click",
      "center",
      "circle",
      "circles",
      "dot",
      "fix",
      "focus",
      "image",
      "move",
      "target"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "admin_meds",
    "version": 248,
    "popularity": 3,
    "codepoint": 58509,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "admin",
      "doctor",
      "health",
      "med",
      "medical",
      "medicine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "admin_panel_settings",
    "version": 248,
    "popularity": 7747,
    "codepoint": 61245,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "account",
      "admin",
      "avatar",
      "certified",
      "face",
      "human",
      "panel",
      "people",
      "person",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield",
      "user",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "admin_panel_settings",
    "version": 13,
    "popularity": 61299,
    "codepoint": 61245,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "admin",
      "avatar",
      "certified",
      "face",
      "human",
      "panel",
      "people",
      "person",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield",
      "user",
      "verified"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ads_click",
    "version": 248,
    "popularity": 6509,
    "codepoint": 59234,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "ads",
      "browser",
      "click",
      "clicks",
      "cursor",
      "internet",
      "target",
      "traffic",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ads_click",
    "version": 3,
    "popularity": 22781,
    "codepoint": 59234,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "ads",
      "browser",
      "click",
      "clicks",
      "cursor",
      "internet",
      "target",
      "traffic",
      "web"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "agender",
    "version": 248,
    "popularity": 170,
    "codepoint": 63624,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "agender",
      "female",
      "gender",
      "genderfree",
      "genderless",
      "identity",
      "lgbt",
      "male",
      "neutral",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "agriculture",
    "version": 248,
    "popularity": 1666,
    "codepoint": 60025,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "agriculture",
      "automobile",
      "car",
      "cars",
      "cultivation",
      "farm",
      "harvest",
      "maps",
      "tractor",
      "transport",
      "travel",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "agriculture",
    "version": 11,
    "popularity": 9076,
    "codepoint": 60025,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "agriculture",
      "automobile",
      "car",
      "cars",
      "cultivation",
      "farm",
      "harvest",
      "maps",
      "tractor",
      "transport",
      "travel",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "air",
    "version": 248,
    "popularity": 2683,
    "codepoint": 61400,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "air",
      "blowing",
      "breeze",
      "flow",
      "wave",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "air",
    "version": 10,
    "popularity": 13301,
    "codepoint": 61400,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "air",
      "blowing",
      "breeze",
      "flow",
      "wave",
      "weather",
      "wind"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "air_freshener",
    "version": 248,
    "popularity": 411,
    "codepoint": 58058,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air freshener",
      "bottle",
      "bottler",
      "fragnance",
      "nest",
      "scent",
      "smell"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "air_purifier",
    "version": 248,
    "popularity": 14,
    "codepoint": 59774,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air",
      "appliance",
      "clean",
      "fragrance",
      "fresh",
      "freshener",
      "home",
      "house",
      "purifier",
      "purify"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "air_purifier_gen",
    "version": 248,
    "popularity": 305,
    "codepoint": 59433,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air",
      "air purifier",
      "appliance",
      "cleaner",
      "nest",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_flat",
    "version": 248,
    "popularity": 264,
    "codepoint": 58928,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "body",
      "business",
      "class",
      "first",
      "flat",
      "human",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_flat",
    "version": 12,
    "popularity": 1360,
    "codepoint": 58928,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "body",
      "business",
      "class",
      "first",
      "flat",
      "human",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_seat_flat_angled",
    "version": 248,
    "popularity": 138,
    "codepoint": 58929,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "angled",
      "body",
      "business",
      "class",
      "first",
      "flat",
      "human",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_flat_angled",
    "version": 12,
    "popularity": 1103,
    "codepoint": 58929,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "angled",
      "body",
      "business",
      "class",
      "first",
      "flat",
      "human",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_seat_individual_suite",
    "version": 248,
    "popularity": 212,
    "codepoint": 58930,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "body",
      "business",
      "class",
      "first",
      "human",
      "individual",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "suite",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_individual_suite",
    "version": 11,
    "popularity": 1514,
    "codepoint": 58930,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "body",
      "business",
      "class",
      "first",
      "human",
      "individual",
      "people",
      "person",
      "rest",
      "seat",
      "sleep",
      "suite",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_seat_legroom_extra",
    "version": 248,
    "popularity": 153,
    "codepoint": 58931,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_legroom_extra",
    "version": 12,
    "popularity": 935,
    "codepoint": 58931,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_seat_legroom_normal",
    "version": 248,
    "popularity": 136,
    "codepoint": 58932,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "body",
      "feet",
      "human",
      "leg",
      "legroom",
      "normal",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_legroom_normal",
    "version": 12,
    "popularity": 992,
    "codepoint": 58932,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "body",
      "feet",
      "human",
      "leg",
      "legroom",
      "normal",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_seat_legroom_reduced",
    "version": 248,
    "popularity": 134,
    "codepoint": 58933,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "body",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "reduced",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_legroom_reduced",
    "version": 12,
    "popularity": 897,
    "codepoint": 58933,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "body",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "reduced",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_seat_recline_extra",
    "version": 248,
    "popularity": 549,
    "codepoint": 58934,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_recline_extra",
    "version": 12,
    "popularity": 2498,
    "codepoint": 58934,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "people",
      "person",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_seat_recline_normal",
    "version": 248,
    "popularity": 641,
    "codepoint": 58935,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "normal",
      "people",
      "person",
      "recline",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_seat_recline_normal",
    "version": 12,
    "popularity": 3718,
    "codepoint": 58935,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "airline",
      "body",
      "extra",
      "feet",
      "human",
      "leg",
      "legroom",
      "normal",
      "people",
      "person",
      "recline",
      "seat",
      "sitting",
      "space",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airline_stops",
    "version": 248,
    "popularity": 355,
    "codepoint": 59344,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "airline",
      "arrow",
      "destination",
      "direction",
      "layover",
      "location",
      "maps",
      "place",
      "stops",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airline_stops",
    "version": 2,
    "popularity": 1757,
    "codepoint": 59344,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "airline",
      "arrow",
      "destination",
      "direction",
      "layover",
      "location",
      "maps",
      "place",
      "stops",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "airlines",
    "version": 248,
    "popularity": 429,
    "codepoint": 59338,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "airlines",
      "airplane",
      "airport",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airlines",
    "version": 2,
    "popularity": 1369,
    "codepoint": 59338,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "airlines",
      "airplane",
      "airport",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "airplane_ticket",
    "version": 248,
    "popularity": 1582,
    "codepoint": 61401,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airplane",
      "airport",
      "boarding",
      "flight",
      "fly",
      "maps",
      "pass",
      "ticket",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airplane_ticket",
    "version": 11,
    "popularity": 7429,
    "codepoint": 61401,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "airplane",
      "airport",
      "boarding",
      "flight",
      "fly",
      "maps",
      "pass",
      "ticket",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airplanemode_active",
    "version": 248,
    "popularity": 1142,
    "codepoint": 57749,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "active",
      "airplane",
      "airplanemode",
      "flight",
      "mode",
      "on",
      "signal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airplanemode_active",
    "version": 16,
    "popularity": 4770,
    "codepoint": 57749,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "active",
      "airplane",
      "airplanemode",
      "flight",
      "mode",
      "on",
      "signal"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "airplanemode_inactive",
    "version": 248,
    "popularity": 248,
    "codepoint": 57748,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "airplane",
      "airplanemode",
      "airport",
      "disabled",
      "enabled",
      "flight",
      "fly",
      "inactive",
      "maps",
      "mode",
      "off",
      "offline",
      "on",
      "slash",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airplanemode_inactive",
    "version": 17,
    "popularity": 1710,
    "codepoint": 57748,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "airplane",
      "airplanemode",
      "airport",
      "disabled",
      "enabled",
      "flight",
      "fly",
      "inactive",
      "maps",
      "mode",
      "off",
      "offline",
      "on",
      "slash",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "airplay",
    "version": 248,
    "popularity": 358,
    "codepoint": 57429,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "airplay",
      "arrow",
      "connect",
      "control",
      "desktop",
      "device",
      "display",
      "monitor",
      "screen",
      "signal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airplay",
    "version": 13,
    "popularity": 3369,
    "codepoint": 57429,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "airplay",
      "arrow",
      "connect",
      "control",
      "desktop",
      "device",
      "display",
      "monitor",
      "screen",
      "signal"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airport_shuttle",
    "version": 248,
    "popularity": 2202,
    "codepoint": 60220,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "airport",
      "automobile",
      "car",
      "cars",
      "commercial",
      "delivery",
      "direction",
      "maps",
      "mini",
      "public",
      "shuttle",
      "transport",
      "transportation",
      "travel",
      "truck",
      "van",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airport_shuttle",
    "version": 12,
    "popularity": 10719,
    "codepoint": 60220,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "airport",
      "automobile",
      "car",
      "cars",
      "commercial",
      "delivery",
      "direction",
      "maps",
      "mini",
      "public",
      "shuttle",
      "transport",
      "transportation",
      "travel",
      "truck",
      "van",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "airware",
    "version": 248,
    "popularity": 421,
    "codepoint": 61780,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "airwave",
    "version": 248,
    "popularity": 587,
    "codepoint": 58012,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "air",
      "airwave",
      "blowing",
      "breeze",
      "climate",
      "flow",
      "home",
      "nest",
      "thermostat",
      "wave",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alarm",
    "version": 248,
    "popularity": 4430,
    "codepoint": 59477,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "countdown",
      "date",
      "notification",
      "schedule",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alarm",
    "version": 12,
    "popularity": 29267,
    "codepoint": 59477,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "countdown",
      "date",
      "notification",
      "schedule",
      "time"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "alarm_add",
    "version": 248,
    "popularity": 712,
    "codepoint": 59478,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "add",
      "alarm",
      "alert",
      "bell",
      "clock",
      "countdown",
      "date",
      "new",
      "notification",
      "plus",
      "schedule",
      "symbol",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alarm_add",
    "version": 12,
    "popularity": 5629,
    "codepoint": 59478,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "add",
      "alarm",
      "alert",
      "bell",
      "clock",
      "countdown",
      "date",
      "new",
      "notification",
      "plus",
      "schedule",
      "symbol",
      "time"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "alarm_off",
    "version": 248,
    "popularity": 451,
    "codepoint": 59479,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alarm_off",
    "version": 12,
    "popularity": 3696,
    "codepoint": 59479,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "alarm_on",
    "version": 248,
    "popularity": 1879,
    "codepoint": 59480,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alarm_on",
    "version": 12,
    "popularity": 11838,
    "codepoint": 59480,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "alarm_smart_wake",
    "version": 248,
    "popularity": 11,
    "codepoint": 63152,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alert",
      "clock",
      "notification",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "album",
    "version": 248,
    "popularity": 1414,
    "codepoint": 57369,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "album",
      "artist",
      "audio",
      "bvb",
      "cd",
      "computer",
      "data",
      "disk",
      "file",
      "music",
      "record",
      "sound",
      "storage",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "album",
    "version": 12,
    "popularity": 7451,
    "codepoint": 57369,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "album",
      "artist",
      "audio",
      "bvb",
      "cd",
      "computer",
      "data",
      "disk",
      "file",
      "music",
      "record",
      "sound",
      "storage",
      "track"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "align_center",
    "version": 248,
    "popularity": 2,
    "codepoint": 58198,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "center",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_end",
    "version": 248,
    "popularity": 0,
    "codepoint": 63383,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_flex_center",
    "version": 248,
    "popularity": 2,
    "codepoint": 63382,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_flex_end",
    "version": 248,
    "popularity": 0,
    "codepoint": 63381,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_flex_start",
    "version": 248,
    "popularity": 1,
    "codepoint": 63380,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_horizontal_center",
    "version": 248,
    "popularity": 333,
    "codepoint": 57359,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "center",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_horizontal_center",
    "version": 6,
    "popularity": 2113,
    "codepoint": 57359,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "center",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "align_horizontal_left",
    "version": 248,
    "popularity": 444,
    "codepoint": 57357,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "format",
      "horizontal",
      "layout",
      "left",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_horizontal_left",
    "version": 7,
    "popularity": 3193,
    "codepoint": 57357,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "format",
      "horizontal",
      "layout",
      "left",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "align_horizontal_right",
    "version": 248,
    "popularity": 297,
    "codepoint": 57360,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "right",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_horizontal_right",
    "version": 7,
    "popularity": 2000,
    "codepoint": 57360,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "right",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "align_items_stretch",
    "version": 248,
    "popularity": 0,
    "codepoint": 63379,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "rules",
      "style",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_justify_center",
    "version": 248,
    "popularity": 1,
    "codepoint": 63378,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_justify_flex_end",
    "version": 248,
    "popularity": 1,
    "codepoint": 63377,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_justify_flex_start",
    "version": 248,
    "popularity": 1,
    "codepoint": 63376,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "flexible",
      "format",
      "layout",
      "lines",
      "rule",
      "style",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_justify_space_around",
    "version": 248,
    "popularity": 4,
    "codepoint": 63375,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_justify_space_between",
    "version": 248,
    "popularity": 4,
    "codepoint": 63374,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_justify_space_even",
    "version": 248,
    "popularity": 3,
    "codepoint": 63373,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_justify_stretch",
    "version": 248,
    "popularity": 0,
    "codepoint": 63372,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "rule",
      "style"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_self_stretch",
    "version": 248,
    "popularity": 1,
    "codepoint": 63371,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "center",
      "distribute",
      "format",
      "layout",
      "lines",
      "rule",
      "rules",
      "style",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_space_around",
    "version": 248,
    "popularity": 1,
    "codepoint": 63370,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_space_between",
    "version": 248,
    "popularity": 4,
    "codepoint": 63369,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_space_even",
    "version": 248,
    "popularity": 1,
    "codepoint": 63368,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_start",
    "version": 248,
    "popularity": 0,
    "codepoint": 63367,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_stretch",
    "version": 248,
    "popularity": 0,
    "codepoint": 63366,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_vertical_bottom",
    "version": 248,
    "popularity": 312,
    "codepoint": 57365,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "bottom",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_vertical_bottom",
    "version": 6,
    "popularity": 2213,
    "codepoint": 57365,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "bottom",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "align_vertical_center",
    "version": 248,
    "popularity": 249,
    "codepoint": 57361,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "center",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_vertical_center",
    "version": 6,
    "popularity": 1623,
    "codepoint": 57361,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "center",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "align_vertical_top",
    "version": 248,
    "popularity": 245,
    "codepoint": 57356,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "top",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "align_vertical_top",
    "version": 6,
    "popularity": 1650,
    "codepoint": 57356,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "top",
      "vertical"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "all_inbox",
    "version": 248,
    "popularity": 792,
    "codepoint": 59775,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "Inbox",
      "all",
      "delivered",
      "delivery",
      "email",
      "mail",
      "message",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "all_inbox",
    "version": 11,
    "popularity": 8284,
    "codepoint": 59775,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Inbox",
      "all",
      "delivered",
      "delivery",
      "email",
      "mail",
      "message",
      "send"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "all_inclusive",
    "version": 248,
    "popularity": 2979,
    "codepoint": 60221,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "all",
      "endless",
      "forever",
      "inclusive",
      "infinity",
      "loop",
      "mobius",
      "neverending",
      "strip",
      "sustainability",
      "sustainable"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "all_inclusive",
    "version": 11,
    "popularity": 10991,
    "codepoint": 60221,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "all",
      "endless",
      "forever",
      "inclusive",
      "infinity",
      "loop",
      "mobius",
      "neverending",
      "strip",
      "sustainability",
      "sustainable"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "all_match",
    "version": 248,
    "popularity": 34,
    "codepoint": 57491,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "correct",
      "done",
      "label",
      "mark",
      "match",
      "ok",
      "select",
      "tag",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "all_out",
    "version": 248,
    "popularity": 407,
    "codepoint": 59659,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "all",
      "circle",
      "out",
      "shape"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "all_out",
    "version": 12,
    "popularity": 2617,
    "codepoint": 59659,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "all",
      "circle",
      "out",
      "shape"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "allergies",
    "version": 248,
    "popularity": 7,
    "codepoint": 57492,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "allergen",
      "allergic",
      "allergies",
      "allergy",
      "health",
      "medical",
      "pollen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "allergy",
    "version": 248,
    "popularity": 5,
    "codepoint": 58958,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "allergen",
      "allergic",
      "allergies",
      "allergy",
      "health",
      "medical",
      "pollen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alt_route",
    "version": 248,
    "popularity": 1256,
    "codepoint": 61828,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "alt",
      "alternate",
      "alternative",
      "arrows",
      "dash",
      "dashed",
      "direction",
      "maps",
      "navigation",
      "options",
      "other",
      "route",
      "routes",
      "split",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alt_route",
    "version": 9,
    "popularity": 9286,
    "codepoint": 61828,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alt",
      "alternate",
      "alternative",
      "arrows",
      "dash",
      "dashed",
      "direction",
      "maps",
      "navigation",
      "options",
      "other",
      "route",
      "routes",
      "split",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "alternate_email",
    "version": 248,
    "popularity": 7692,
    "codepoint": 57574,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "@",
      "address",
      "alternate",
      "contact",
      "email",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "alternate_email",
    "version": 13,
    "popularity": 37411,
    "codepoint": 57574,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "@",
      "address",
      "alternate",
      "contact",
      "email",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "altitude",
    "version": 248,
    "popularity": 28,
    "codepoint": 63603,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "height",
      "level",
      "mountains",
      "sea",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ambulance",
    "version": 248,
    "popularity": 12,
    "codepoint": 63491,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "approve",
      "auto",
      "automobile",
      "check",
      "checkmark",
      "complete",
      "done",
      "emergency",
      "health",
      "mark",
      "medical",
      "ok",
      "select",
      "sos",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "amend",
    "version": 248,
    "popularity": 10,
    "codepoint": 63490,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "back",
      "direction",
      "document",
      "left",
      "navigation",
      "refresh",
      "renew",
      "rotate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "amp_stories",
    "version": 248,
    "popularity": 177,
    "codepoint": 59923,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "amp",
      "stories"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "analytics",
    "version": 248,
    "popularity": 10123,
    "codepoint": 61246,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "assessment",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "analytics",
    "version": 12,
    "popularity": 64398,
    "codepoint": 61246,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "assessment",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "anchor",
    "version": 248,
    "popularity": 1208,
    "codepoint": 61901,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "anchor",
      "google",
      "logo"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "anchor",
    "version": 7,
    "popularity": 8274,
    "codepoint": 61901,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "anchor",
      "google",
      "logo"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "android",
    "version": 248,
    "popularity": 2749,
    "codepoint": 59481,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "android",
      "character",
      "logo",
      "mascot",
      "toy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "android",
    "version": 15,
    "popularity": 26557,
    "codepoint": 59481,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "android",
      "character",
      "logo",
      "mascot",
      "toy"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "animation",
    "version": 248,
    "popularity": 962,
    "codepoint": 59164,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "circles",
      "film",
      "motion",
      "movement",
      "sequence",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "animation",
    "version": 14,
    "popularity": 4268,
    "codepoint": 59164,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "animation",
      "circles",
      "film",
      "motion",
      "movement",
      "sequence",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "announcement",
    "version": 18,
    "popularity": 21772,
    "codepoint": 59482,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "!",
      "alert",
      "announcement",
      "attention",
      "bubble",
      "caution",
      "chat",
      "comment",
      "communicate",
      "danger",
      "error",
      "exclamation",
      "feedback",
      "important",
      "mark",
      "message",
      "notification",
      "speech",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "aod",
    "version": 248,
    "popularity": 313,
    "codepoint": 61402,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "always",
      "aod",
      "device",
      "display",
      "hardware",
      "homescreen",
      "iOS",
      "mobile",
      "on",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "aod",
    "version": 10,
    "popularity": 2151,
    "codepoint": 61402,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "always",
      "aod",
      "device",
      "display",
      "hardware",
      "homescreen",
      "iOS",
      "mobile",
      "on",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "aod_tablet",
    "version": 248,
    "popularity": 137,
    "codepoint": 63647,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "always",
      "aod",
      "device",
      "display",
      "hardware",
      "homescreen",
      "iOS",
      "mobile",
      "on",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "aod_watch",
    "version": 248,
    "popularity": 22,
    "codepoint": 63148,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "always on display",
      "ar",
      "clock",
      "device",
      "display",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "apartment",
    "version": 248,
    "popularity": 11033,
    "codepoint": 59968,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "accommodation",
      "apartment",
      "architecture",
      "building",
      "city",
      "company",
      "estate",
      "flat",
      "home",
      "house",
      "office",
      "places",
      "real",
      "residence",
      "residential",
      "shelter",
      "units",
      "workplace"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "apartment",
    "version": 11,
    "popularity": 43220,
    "codepoint": 59968,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "accommodation",
      "apartment",
      "architecture",
      "building",
      "city",
      "company",
      "estate",
      "flat",
      "home",
      "house",
      "office",
      "places",
      "real",
      "residence",
      "residential",
      "shelter",
      "units",
      "workplace"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "api",
    "version": 248,
    "popularity": 2476,
    "codepoint": 61879,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "api",
      "developer",
      "development",
      "enterprise",
      "software"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "api",
    "version": 7,
    "popularity": 17617,
    "codepoint": 61879,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "api",
      "developer",
      "development",
      "enterprise",
      "software"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "apk_document",
    "version": 248,
    "popularity": 130,
    "codepoint": 63630,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "android",
      "apk",
      "data",
      "document",
      "drive",
      "file",
      "page",
      "paper",
      "system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "apk_install",
    "version": 248,
    "popularity": 190,
    "codepoint": 63631,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "android",
      "apk",
      "arrow",
      "data",
      "document",
      "download",
      "drive",
      "file",
      "install",
      "page",
      "paper",
      "system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "app_badging",
    "version": 248,
    "popularity": 13,
    "codepoint": 63279,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alert",
      "alerts",
      "apps",
      "badge",
      "icon",
      "notification",
      "notifications"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "app_blocking",
    "version": 248,
    "popularity": 339,
    "codepoint": 61247,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "app",
      "application",
      "block",
      "blocking",
      "cancel",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "stop",
      "stopped",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "app_blocking",
    "version": 13,
    "popularity": 3480,
    "codepoint": 61247,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "app",
      "application",
      "block",
      "blocking",
      "cancel",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "stop",
      "stopped",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "app_promo",
    "version": 248,
    "popularity": 47,
    "codepoint": 59777,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "app",
      "arrow",
      "arrows",
      "cell",
      "dash",
      "dashed",
      "device",
      "direction",
      "down",
      "downloading",
      "hardware",
      "mobile",
      "phone",
      "promo",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "app_registration",
    "version": 248,
    "popularity": 4207,
    "codepoint": 61248,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "apps",
      "edit",
      "pencil",
      "register",
      "registration"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "app_registration",
    "version": 11,
    "popularity": 18636,
    "codepoint": 61248,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "app",
      "apps",
      "edit",
      "pencil",
      "register",
      "registration"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "app_settings_alt",
    "version": 17,
    "popularity": 5456,
    "codepoint": 61249,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "Android",
      "OS",
      "app",
      "applications",
      "cell",
      "device",
      "gear",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "setting",
      "settings",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "app_shortcut",
    "version": 248,
    "popularity": 1440,
    "codepoint": 60132,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "bookmarked",
      "favorite",
      "highlight",
      "important",
      "marked",
      "mobile",
      "save",
      "saved",
      "shortcut",
      "software",
      "special",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "app_shortcut",
    "version": 2,
    "popularity": 3235,
    "codepoint": 60132,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "bookmarked",
      "favorite",
      "highlight",
      "important",
      "marked",
      "mobile",
      "save",
      "saved",
      "shortcut",
      "software",
      "special",
      "star"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "apparel",
    "version": 248,
    "popularity": 182,
    "codepoint": 61307,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "apparel",
      "blouse",
      "clothes",
      "clothing",
      "fashion",
      "shit",
      "tee",
      "top"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "approval",
    "version": 248,
    "popularity": 1653,
    "codepoint": 59778,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "apply",
      "approval",
      "approvals",
      "approve",
      "certificate",
      "certification",
      "disapproval",
      "drive",
      "file",
      "impression",
      "ink",
      "mark",
      "postage",
      "stamp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "approval",
    "version": 11,
    "popularity": 9250,
    "codepoint": 59778,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "apply",
      "approval",
      "approvals",
      "approve",
      "certificate",
      "certification",
      "disapproval",
      "drive",
      "file",
      "impression",
      "ink",
      "mark",
      "postage",
      "stamp"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "approval_delegation",
    "version": 248,
    "popularity": 219,
    "codepoint": 63562,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "apply",
      "approval",
      "approvals",
      "approve",
      "check",
      "checkmark",
      "fingers",
      "gesture",
      "giving",
      "hand",
      "hands"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "apps",
    "version": 248,
    "popularity": 18189,
    "codepoint": 58819,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "applications",
      "apps",
      "circles",
      "collection",
      "components",
      "dots",
      "grid",
      "interface",
      "squares",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "apps",
    "version": 12,
    "popularity": 71563,
    "codepoint": 58819,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "all",
      "applications",
      "apps",
      "circles",
      "collection",
      "components",
      "dots",
      "grid",
      "interface",
      "squares",
      "ui",
      "ux"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "apps_outage",
    "version": 248,
    "popularity": 1086,
    "codepoint": 59340,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "applications",
      "apps",
      "circles",
      "collection",
      "components",
      "dots",
      "grid",
      "interface",
      "outage",
      "squares",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "apps_outage",
    "version": 2,
    "popularity": 3070,
    "codepoint": 59340,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "all",
      "applications",
      "apps",
      "circles",
      "collection",
      "components",
      "dots",
      "grid",
      "interface",
      "outage",
      "squares",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "aq",
    "version": 248,
    "popularity": 6,
    "codepoint": 62810,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "air",
      "air quality",
      "alphabet",
      "atmosphere",
      "bismuth",
      "character",
      "climate",
      "font",
      "letter",
      "pollutants",
      "pollution",
      "quality",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "aq_indoor",
    "version": 248,
    "popularity": 8,
    "codepoint": 62811,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "air",
      "air quality",
      "alphabet",
      "atmosphere",
      "bismuth",
      "character",
      "climate",
      "font",
      "letter",
      "pollutants",
      "pollution",
      "quality",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ar_on_you",
    "version": 248,
    "popularity": 72,
    "codepoint": 61308,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "ar",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "on",
      "person",
      "pleased",
      "smile",
      "smiling",
      "survey",
      "you"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ar_stickers",
    "version": 248,
    "popularity": 15,
    "codepoint": 59779,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "ar",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happy",
      "mood",
      "playground",
      "smile",
      "smiling",
      "stickers",
      "survey"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "architecture",
    "version": 248,
    "popularity": 1891,
    "codepoint": 59963,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "architecture",
      "art",
      "compass",
      "design",
      "draw",
      "drawing",
      "engineering",
      "geometric",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "architecture",
    "version": 11,
    "popularity": 10730,
    "codepoint": 59963,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "architecture",
      "art",
      "compass",
      "design",
      "draw",
      "drawing",
      "engineering",
      "geometric",
      "tool"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "archive",
    "version": 248,
    "popularity": 3424,
    "codepoint": 57673,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "archive",
      "inbox",
      "mail",
      "store"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "archive",
    "version": 16,
    "popularity": 22976,
    "codepoint": 57673,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "archive",
      "inbox",
      "mail",
      "store"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "area_chart",
    "version": 248,
    "popularity": 1011,
    "codepoint": 59248,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "area",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "area_chart",
    "version": 3,
    "popularity": 6038,
    "codepoint": 59248,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "area",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arming_countdown",
    "version": 248,
    "popularity": 172,
    "codepoint": 59274,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "arming",
      "countdown",
      "home",
      "nest",
      "security",
      "shield"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_and_edge",
    "version": 248,
    "popularity": 22,
    "codepoint": 62935,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "directions",
      "down",
      "merge",
      "navigation",
      "path",
      "route"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_back",
    "version": 248,
    "popularity": 52711,
    "codepoint": 58820,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "left",
      "navigation",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_back",
    "version": 17,
    "popularity": 234675,
    "codepoint": 58820,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "left",
      "navigation",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_back_ios",
    "version": 248,
    "popularity": 38772,
    "codepoint": 58848,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "chevron",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "ios",
      "left",
      "navigation",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_back_ios",
    "version": 14,
    "popularity": 166670,
    "codepoint": 58848,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "chevron",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "ios",
      "left",
      "navigation",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_back_ios_new",
    "version": 248,
    "popularity": 10565,
    "codepoint": 58090,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "chevron",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "ios",
      "left",
      "navigation",
      "new",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_back_ios_new",
    "version": 6,
    "popularity": 50507,
    "codepoint": 58090,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "DISABLE_IOS",
      "app",
      "application",
      "arrow",
      "back",
      "chevron",
      "components",
      "direction",
      "disable_ios",
      "interface",
      "ios",
      "left",
      "navigation",
      "new",
      "previous",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arrow_circle_down",
    "version": 248,
    "popularity": 2826,
    "codepoint": 61825,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "down",
      "navigation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_circle_down",
    "version": 8,
    "popularity": 15266,
    "codepoint": 61825,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "down",
      "navigation"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arrow_circle_left",
    "version": 248,
    "popularity": 4144,
    "codepoint": 60071,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "left",
      "navigation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_circle_left",
    "version": 2,
    "popularity": 13043,
    "codepoint": 60071,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "left",
      "navigation"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arrow_circle_right",
    "version": 248,
    "popularity": 8907,
    "codepoint": 60074,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "navigation",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_circle_right",
    "version": 2,
    "popularity": 21667,
    "codepoint": 60074,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "navigation",
      "right"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arrow_circle_up",
    "version": 248,
    "popularity": 4143,
    "codepoint": 61826,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "navigation",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_circle_up",
    "version": 8,
    "popularity": 18021,
    "codepoint": 61826,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "navigation",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arrow_downward",
    "version": 248,
    "popularity": 10964,
    "codepoint": 58843,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "down",
      "downward",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_downward",
    "version": 17,
    "popularity": 47190,
    "codepoint": 58843,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "down",
      "downward",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_downward_alt",
    "version": 248,
    "popularity": 92,
    "codepoint": 59780,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "downward"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_drop_down",
    "version": 248,
    "popularity": 32141,
    "codepoint": 58821,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "down",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_drop_down",
    "version": 16,
    "popularity": 155243,
    "codepoint": 58821,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "down",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_drop_down_circle",
    "version": 248,
    "popularity": 2509,
    "codepoint": 58822,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "circle",
      "components",
      "direction",
      "down",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_drop_down_circle",
    "version": 12,
    "popularity": 13744,
    "codepoint": 58822,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "circle",
      "components",
      "direction",
      "down",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_drop_up",
    "version": 248,
    "popularity": 6163,
    "codepoint": 58823,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "up",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_drop_up",
    "version": 12,
    "popularity": 33262,
    "codepoint": 58823,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "drop",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "up",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_forward",
    "version": 248,
    "popularity": 34467,
    "codepoint": 58824,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "arrows",
      "components",
      "direction",
      "forward",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_forward",
    "version": 13,
    "popularity": 121333,
    "codepoint": 58824,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "arrows",
      "components",
      "direction",
      "forward",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_forward_ios",
    "version": 248,
    "popularity": 44761,
    "codepoint": 58849,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "chevron",
      "components",
      "direction",
      "forward",
      "interface",
      "ios",
      "navigation",
      "next",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_forward_ios",
    "version": 16,
    "popularity": 168501,
    "codepoint": 58849,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "chevron",
      "components",
      "direction",
      "forward",
      "interface",
      "ios",
      "navigation",
      "next",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arrow_insert",
    "version": 248,
    "popularity": 65,
    "codepoint": 63543,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "insert",
      "interface",
      "left",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_left",
    "version": 248,
    "popularity": 3236,
    "codepoint": 58846,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "left",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_left",
    "version": 12,
    "popularity": 19443,
    "codepoint": 58846,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "left",
      "navigation",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_left_alt",
    "version": 248,
    "popularity": 60,
    "codepoint": 61309,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "left",
      "side"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_or_edge",
    "version": 248,
    "popularity": 14,
    "codepoint": 62934,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "directions",
      "down",
      "merge",
      "navigation",
      "path",
      "route"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_outward",
    "version": 248,
    "popularity": 2250,
    "codepoint": 63694,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "arrows",
      "components",
      "direction",
      "forward",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_outward",
    "version": 1,
    "popularity": 2505,
    "codepoint": 63694,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "arrows",
      "components",
      "direction",
      "forward",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "arrow_range",
    "version": 248,
    "popularity": 20,
    "codepoint": 63131,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "back",
      "fitbit",
      "forth",
      "scope"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_right",
    "version": 248,
    "popularity": 8732,
    "codepoint": 58847,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_right",
    "version": 12,
    "popularity": 44888,
    "codepoint": 58847,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "navigation",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_right_alt",
    "version": 248,
    "popularity": 18128,
    "codepoint": 59713,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "arrow",
      "arrows",
      "direction",
      "east",
      "navigation",
      "pointing",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_right_alt",
    "version": 13,
    "popularity": 70643,
    "codepoint": 59713,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "arrow",
      "arrows",
      "direction",
      "east",
      "navigation",
      "pointing",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_selector_tool",
    "version": 248,
    "popularity": 284,
    "codepoint": 63535,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "click",
      "direction",
      "left",
      "mouse",
      "pointer",
      "pointing",
      "select"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_split",
    "version": 248,
    "popularity": 155,
    "codepoint": 59781,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "multiple",
      "options",
      "right",
      "split",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_top_left",
    "version": 248,
    "popularity": 11,
    "codepoint": 63278,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "direction",
      "navigation",
      "turn",
      "up",
      "west"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_top_right",
    "version": 248,
    "popularity": 7,
    "codepoint": 63277,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "direction",
      "east",
      "navigation",
      "turn",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_upward",
    "version": 248,
    "popularity": 14234,
    "codepoint": 58840,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "up",
      "upward",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrow_upward",
    "version": 11,
    "popularity": 62352,
    "codepoint": 58840,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "direction",
      "interface",
      "navigation",
      "screen",
      "site",
      "ui",
      "up",
      "upward",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "arrow_upward_alt",
    "version": 248,
    "popularity": 122,
    "codepoint": 59782,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "up",
      "upward"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrows_more_down",
    "version": 248,
    "popularity": 154,
    "codepoint": 63659,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "angle",
      "arrows",
      "climate",
      "down",
      "home",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrows_more_up",
    "version": 248,
    "popularity": 332,
    "codepoint": 63660,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "angle",
      "arrows",
      "climate",
      "home",
      "nest",
      "thermostat",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "arrows_outward",
    "version": 248,
    "popularity": 12,
    "codepoint": 63276,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "double",
      "left",
      "out",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "art_track",
    "version": 248,
    "popularity": 371,
    "codepoint": 57440,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "album",
      "art",
      "artist",
      "audio",
      "image",
      "music",
      "photo",
      "photography",
      "picture",
      "sound",
      "track",
      "tracks"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "art_track",
    "version": 13,
    "popularity": 2190,
    "codepoint": 57440,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "album",
      "art",
      "artist",
      "audio",
      "image",
      "music",
      "photo",
      "photography",
      "picture",
      "sound",
      "track",
      "tracks"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "article",
    "version": 248,
    "popularity": 8362,
    "codepoint": 61250,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "doc",
      "document",
      "file",
      "page",
      "paper",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "article",
    "version": 13,
    "popularity": 100002,
    "codepoint": 61250,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "article",
      "doc",
      "document",
      "file",
      "page",
      "paper",
      "text",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "article_shortcut",
    "version": 248,
    "popularity": 31,
    "codepoint": 62855,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "article",
      "direction",
      "doc",
      "document",
      "file",
      "forward",
      "page",
      "paper",
      "query",
      "query reference",
      "reference",
      "right",
      "shortcut",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "artist",
    "version": 248,
    "popularity": 31,
    "codepoint": 57370,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "account",
      "artist",
      "face",
      "human",
      "music",
      "musical",
      "musician",
      "note",
      "people",
      "person",
      "playlist",
      "profile",
      "song",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "aspect_ratio",
    "version": 248,
    "popularity": 1585,
    "codepoint": 59483,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "aspect",
      "dash",
      "dashed",
      "expand",
      "image",
      "ratio",
      "resize",
      "scale",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "aspect_ratio",
    "version": 12,
    "popularity": 12647,
    "codepoint": 59483,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "aspect",
      "dash",
      "dashed",
      "expand",
      "image",
      "ratio",
      "resize",
      "scale",
      "size",
      "square"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assessment",
    "version": 12,
    "popularity": 40149,
    "codepoint": 59484,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "assessment",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assignment",
    "version": 248,
    "popularity": 6889,
    "codepoint": 59485,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "assignment",
      "clipboard",
      "doc",
      "document",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assignment",
    "version": 15,
    "popularity": 78781,
    "codepoint": 59485,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "assignment",
      "clipboard",
      "doc",
      "document",
      "text",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assignment_add",
    "version": 248,
    "popularity": 117,
    "codepoint": 63560,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "new",
      "note",
      "plus",
      "quick",
      "symbol",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assignment_add",
    "version": 1,
    "popularity": 3003,
    "codepoint": 63560,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "add",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "new",
      "note",
      "plus",
      "quick",
      "symbol",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "assignment_ind",
    "version": 248,
    "popularity": 2957,
    "codepoint": 59486,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "account",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "face",
      "ind",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assignment_ind",
    "version": 12,
    "popularity": 35995,
    "codepoint": 59486,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "face",
      "ind",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assignment_late",
    "version": 248,
    "popularity": 1059,
    "codepoint": 59487,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "!",
      "alert",
      "assignment",
      "attention",
      "caution",
      "clipboard",
      "danger",
      "doc",
      "document",
      "error",
      "exclamation",
      "important",
      "late",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assignment_late",
    "version": 15,
    "popularity": 8716,
    "codepoint": 59487,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "!",
      "alert",
      "assignment",
      "attention",
      "caution",
      "clipboard",
      "danger",
      "doc",
      "document",
      "error",
      "exclamation",
      "important",
      "late",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assignment_return",
    "version": 248,
    "popularity": 982,
    "codepoint": 59488,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "assignment",
      "back",
      "clipboard",
      "doc",
      "document",
      "left",
      "retun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assignment_return",
    "version": 14,
    "popularity": 8826,
    "codepoint": 59488,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "assignment",
      "back",
      "clipboard",
      "doc",
      "document",
      "left",
      "retun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assignment_returned",
    "version": 248,
    "popularity": 470,
    "codepoint": 59489,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "down",
      "returned"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assignment_returned",
    "version": 12,
    "popularity": 5904,
    "codepoint": 59489,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "assignment",
      "clipboard",
      "doc",
      "document",
      "down",
      "returned"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assignment_turned_in",
    "version": 248,
    "popularity": 2841,
    "codepoint": 59490,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "approve",
      "assignment",
      "check",
      "clipboard",
      "complete",
      "doc",
      "document",
      "done",
      "in",
      "mark",
      "ok",
      "select",
      "tick",
      "turn",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assignment_turned_in",
    "version": 16,
    "popularity": 31903,
    "codepoint": 59490,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "assignment",
      "check",
      "clipboard",
      "complete",
      "doc",
      "document",
      "done",
      "in",
      "mark",
      "ok",
      "select",
      "tick",
      "turn",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assist_walker",
    "version": 248,
    "popularity": 327,
    "codepoint": 63701,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "assist",
      "body",
      "disability",
      "handicap",
      "help",
      "human",
      "injured",
      "injury",
      "mobility",
      "person",
      "walk",
      "walker"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assist_walker",
    "version": 1,
    "popularity": 768,
    "codepoint": 63701,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "assist",
      "body",
      "disability",
      "handicap",
      "help",
      "human",
      "injured",
      "injury",
      "mobility",
      "person",
      "walk",
      "walker"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "assistant",
    "version": 12,
    "popularity": 6395,
    "codepoint": 58271,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "ai",
      "artificial",
      "assistant",
      "automatic",
      "automation",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "custom",
      "feedback",
      "genai",
      "intelligence",
      "magic",
      "message",
      "recommendation",
      "smart",
      "spark",
      "sparkle",
      "speech",
      "star",
      "suggestion",
      "twinkle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assistant_device",
    "version": 248,
    "popularity": 13,
    "codepoint": 59783,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assistant_direction",
    "version": 248,
    "popularity": 1598,
    "codepoint": 59784,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "assistant",
      "destination",
      "direction",
      "location",
      "maps",
      "navigate",
      "navigation",
      "pin",
      "place",
      "right",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assistant_direction",
    "version": 14,
    "popularity": 5371,
    "codepoint": 59784,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "assistant",
      "destination",
      "direction",
      "location",
      "maps",
      "navigate",
      "navigation",
      "pin",
      "place",
      "right",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assistant_navigation",
    "version": 248,
    "popularity": 2514,
    "codepoint": 59785,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "assistant",
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop",
      "straight",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assistant_navigation",
    "version": 10,
    "popularity": 1621,
    "codepoint": 59785,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "assistant",
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop",
      "straight",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assistant_on_hub",
    "version": 248,
    "popularity": 23,
    "codepoint": 63169,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "IoT",
      "assistant",
      "desktop",
      "device",
      "display",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "monitor",
      "nest",
      "screen",
      "smart",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assistant_photo",
    "version": 12,
    "popularity": 2920,
    "codepoint": 58272,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "assistant",
      "flag",
      "photo",
      "recommendation",
      "smart",
      "star",
      "suggestion"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "assured_workload",
    "version": 248,
    "popularity": 1859,
    "codepoint": 60271,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "assured",
      "compliance",
      "confidential",
      "federal",
      "government",
      "secure",
      "sensitive regulatory",
      "workload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "assured_workload",
    "version": 1,
    "popularity": 5033,
    "codepoint": 60271,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "assured",
      "compliance",
      "confidential",
      "federal",
      "government",
      "secure",
      "sensitive regulatory",
      "workload"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "asterisk",
    "version": 248,
    "popularity": 6,
    "codepoint": 62757,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "annotation",
      "character",
      "font",
      "mark",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "atm",
    "version": 248,
    "popularity": 495,
    "codepoint": 58739,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "alphabet",
      "atm",
      "automated",
      "bill",
      "card",
      "cart",
      "cash",
      "character",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "font",
      "letter",
      "machine",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol",
      "teller",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "atm",
    "version": 12,
    "popularity": 2905,
    "codepoint": 58739,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alphabet",
      "atm",
      "automated",
      "bill",
      "card",
      "cart",
      "cash",
      "character",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "font",
      "letter",
      "machine",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol",
      "teller",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "atr",
    "version": 248,
    "popularity": 992,
    "codepoint": 60359,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "circles",
      "collaboration",
      "dot",
      "dots",
      "group",
      "space",
      "team",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attach_email",
    "version": 248,
    "popularity": 1051,
    "codepoint": 59998,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "attach",
      "attachment",
      "clip",
      "compose",
      "email",
      "envelop",
      "letter",
      "link",
      "mail",
      "message",
      "paperclip",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attach_email",
    "version": 16,
    "popularity": 5707,
    "codepoint": 59998,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "attach",
      "attachment",
      "clip",
      "compose",
      "email",
      "envelop",
      "letter",
      "link",
      "mail",
      "message",
      "paperclip",
      "send"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "attach_file",
    "version": 248,
    "popularity": 7516,
    "codepoint": 57894,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "add",
      "attach",
      "attachment",
      "clip",
      "file",
      "link",
      "mail",
      "media",
      "paperclip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attach_file",
    "version": 16,
    "popularity": 46644,
    "codepoint": 57894,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "add",
      "attach",
      "attachment",
      "clip",
      "file",
      "link",
      "mail",
      "media",
      "paperclip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "attach_file_add",
    "version": 248,
    "popularity": 8,
    "codepoint": 63553,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "attach",
      "attachment",
      "clip",
      "file",
      "link",
      "mail",
      "media",
      "new",
      "paperclip",
      "plus"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attach_file_off",
    "version": 248,
    "popularity": 1,
    "codepoint": 62681,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "add",
      "attach",
      "attachment",
      "clip",
      "disabled",
      "enabled",
      "file",
      "link",
      "mail",
      "media",
      "off",
      "offline",
      "on",
      "paperclip",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attach_money",
    "version": 248,
    "popularity": 12594,
    "codepoint": 57895,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "attach",
      "attachment",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attach_money",
    "version": 17,
    "popularity": 84270,
    "codepoint": 57895,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "attach",
      "attachment",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "attachment",
    "version": 248,
    "popularity": 3022,
    "codepoint": 58044,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "attach",
      "attachment",
      "clip",
      "compose",
      "file",
      "image",
      "link"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attachment",
    "version": 15,
    "popularity": 22472,
    "codepoint": 58044,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "attach",
      "attachment",
      "clip",
      "compose",
      "file",
      "image",
      "link"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "attractions",
    "version": 248,
    "popularity": 842,
    "codepoint": 59986,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "amusement",
      "attractions",
      "entertainment",
      "ferris",
      "fun",
      "maps",
      "park",
      "places",
      "wheel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attractions",
    "version": 10,
    "popularity": 4104,
    "codepoint": 59986,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "amusement",
      "attractions",
      "entertainment",
      "ferris",
      "fun",
      "maps",
      "park",
      "places",
      "wheel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "attribution",
    "version": 248,
    "popularity": 708,
    "codepoint": 61403,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "attribute",
      "attribution",
      "body",
      "copyright",
      "copywriter",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "attribution",
    "version": 11,
    "popularity": 5205,
    "codepoint": 61403,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "attribute",
      "attribution",
      "body",
      "copyright",
      "copywriter",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "audio_description",
    "version": 248,
    "popularity": 16,
    "codepoint": 62860,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "accessibility",
      "alphabet",
      "character",
      "descriptive",
      "font",
      "letter",
      "movie",
      "narrated",
      "screen",
      "script",
      "symbol",
      "text",
      "tv",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "audio_file",
    "version": 248,
    "popularity": 652,
    "codepoint": 60290,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "doc",
      "document",
      "key",
      "music",
      "note",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "audio_file",
    "version": 1,
    "popularity": 2478,
    "codepoint": 60290,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "audio",
      "doc",
      "document",
      "key",
      "music",
      "note",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "audio_video_receiver",
    "version": 248,
    "popularity": 4,
    "codepoint": 62931,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "amp",
      "amplifier",
      "av",
      "avr",
      "home theater",
      "renderer",
      "stereo",
      "television",
      "theater",
      "tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "audiotrack",
    "version": 12,
    "popularity": 12090,
    "codepoint": 58273,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "audio",
      "audiotrack",
      "key",
      "music",
      "note",
      "sound",
      "track"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_awesome",
    "version": 14,
    "popularity": 36542,
    "codepoint": 58975,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "ai",
      "artificial",
      "automatic",
      "automation",
      "custom",
      "edit",
      "editing",
      "enhance",
      "genai",
      "intelligence",
      "magic",
      "smart",
      "spark",
      "sparkle",
      "star",
      "stars"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_awesome_mosaic",
    "version": 248,
    "popularity": 677,
    "codepoint": 58976,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "auto",
      "awesome",
      "collage",
      "edit",
      "editing",
      "enhance",
      "image",
      "mosaic",
      "photo"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "auto_awesome_mosaic",
    "version": 14,
    "popularity": 5468,
    "codepoint": 58976,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "auto",
      "awesome",
      "collage",
      "edit",
      "editing",
      "enhance",
      "image",
      "mosaic",
      "photo"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_awesome_motion",
    "version": 248,
    "popularity": 854,
    "codepoint": 58977,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "auto",
      "awesome",
      "collage",
      "edit",
      "editing",
      "enhance",
      "image",
      "motion",
      "photo",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "auto_awesome_motion",
    "version": 14,
    "popularity": 7063,
    "codepoint": 58977,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "auto",
      "awesome",
      "collage",
      "edit",
      "editing",
      "enhance",
      "image",
      "motion",
      "photo",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_delete",
    "version": 248,
    "popularity": 1059,
    "codepoint": 59980,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "auto",
      "bin",
      "can",
      "clock",
      "date",
      "delete",
      "garbage",
      "remove",
      "schedule",
      "time",
      "trash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "auto_delete",
    "version": 11,
    "popularity": 6415,
    "codepoint": 59980,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "alert"
    ],
    "tags": [
      "auto",
      "bin",
      "can",
      "clock",
      "date",
      "delete",
      "garbage",
      "remove",
      "schedule",
      "time",
      "trash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "auto_fix_high",
    "version": 14,
    "popularity": 16451,
    "codepoint": 58979,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "ai",
      "artificial",
      "auto",
      "automatic",
      "automation",
      "custom",
      "edit",
      "editing",
      "enhance",
      "erase",
      "fix",
      "genai",
      "high",
      "intelligence",
      "magic",
      "modify",
      "pen",
      "smart",
      "spark",
      "sparkle",
      "star",
      "tool",
      "wand"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_fix_normal",
    "version": 14,
    "popularity": 5990,
    "codepoint": 58980,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "ai",
      "artificial",
      "auto",
      "automatic",
      "automation",
      "custom",
      "edit",
      "erase",
      "fix",
      "genai",
      "intelligence",
      "magic",
      "modify",
      "smart",
      "spark",
      "sparkle",
      "star",
      "wand"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_fix_off",
    "version": 13,
    "popularity": 1401,
    "codepoint": 58981,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "ai",
      "artificial",
      "auto",
      "automatic",
      "automation",
      "custom",
      "disabled",
      "edit",
      "enabled",
      "erase",
      "fix",
      "genai",
      "intelligence",
      "magic",
      "modify",
      "off",
      "on",
      "slash",
      "smart",
      "spark",
      "sparkle",
      "star",
      "wand"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_graph",
    "version": 4,
    "popularity": 13225,
    "codepoint": 58619,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "auto",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "stars",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "auto_mode",
    "version": 1,
    "popularity": 3011,
    "codepoint": 60448,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "ai",
      "around",
      "arrow",
      "arrows",
      "artificial",
      "auto",
      "automatic",
      "automation",
      "custom",
      "direction",
      "genai",
      "inprogress",
      "intelligence",
      "load",
      "loading refresh",
      "magic",
      "mode",
      "navigation",
      "nest",
      "renew",
      "rotate",
      "smart",
      "spark",
      "sparkle",
      "star",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "auto_read_pause",
    "version": 248,
    "popularity": 278,
    "codepoint": 61977,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "auto",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "listen",
      "message",
      "pause",
      "read",
      "speech",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "auto_read_play",
    "version": 248,
    "popularity": 399,
    "codepoint": 61974,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "auto",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "listen",
      "message",
      "play",
      "read",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "auto_stories",
    "version": 248,
    "popularity": 4739,
    "codepoint": 58982,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "auto",
      "book",
      "flipping",
      "pages",
      "stories"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "auto_stories",
    "version": 13,
    "popularity": 38186,
    "codepoint": 58982,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "auto",
      "book",
      "flipping",
      "pages",
      "stories"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "auto_towing",
    "version": 248,
    "popularity": 11,
    "codepoint": 59166,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "auto",
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "tow",
      "towing",
      "transportation",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "auto_transmission",
    "version": 248,
    "popularity": 12,
    "codepoint": 62783,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automatic transmission",
      "automobile",
      "automotive",
      "car",
      "clutch",
      "gearbox",
      "gears",
      "manual transmission",
      "stick shift"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "autofps_select",
    "version": 248,
    "popularity": 141,
    "codepoint": 61404,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "A",
      "alphabet",
      "auto",
      "character",
      "font",
      "fps",
      "frame",
      "frequency",
      "letter",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "autofps_select",
    "version": 10,
    "popularity": 894,
    "codepoint": 61404,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "A",
      "alphabet",
      "auto",
      "character",
      "font",
      "fps",
      "frame",
      "frequency",
      "letter",
      "per",
      "rate",
      "second",
      "seconds",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "autopause",
    "version": 248,
    "popularity": 24,
    "codepoint": 63158,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "around",
      "arrow",
      "automatic",
      "control",
      "controls",
      "dash",
      "dashed",
      "load",
      "loading",
      "music",
      "pause",
      "refresh",
      "renew",
      "rotate",
      "sound",
      "sync"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "autoplay",
    "version": 248,
    "popularity": 25,
    "codepoint": 63157,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "around",
      "arrow",
      "automatic",
      "control",
      "controls",
      "dash",
      "dashed",
      "load",
      "loading",
      "music",
      "play",
      "refresh",
      "renew",
      "rotate",
      "sound",
      "sync"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "autorenew",
    "version": 248,
    "popularity": 12270,
    "codepoint": 59491,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "autorenew",
      "cache",
      "cached",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "navigation",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "autorenew",
    "version": 12,
    "popularity": 66702,
    "codepoint": 59491,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "autorenew",
      "cache",
      "cached",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "navigation",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "autostop",
    "version": 248,
    "popularity": 8,
    "codepoint": 63106,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "around",
      "arrow",
      "automatic",
      "control",
      "controls",
      "dash",
      "dashed",
      "load",
      "loading",
      "music",
      "refresh",
      "renew",
      "rotate",
      "sound",
      "stop",
      "sync"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "av_timer",
    "version": 248,
    "popularity": 1315,
    "codepoint": 57371,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "av",
      "clock",
      "countdown",
      "duration",
      "minutes",
      "seconds",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "av_timer",
    "version": 12,
    "popularity": 7491,
    "codepoint": 57371,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "av",
      "clock",
      "countdown",
      "duration",
      "minutes",
      "seconds",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "avg_pace",
    "version": 248,
    "popularity": 37,
    "codepoint": 63163,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "average",
      "clock",
      "dash",
      "dashed",
      "fitness",
      "rate",
      "speed",
      "stopwatch",
      "time",
      "timer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "avg_time",
    "version": 248,
    "popularity": 25,
    "codepoint": 63507,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "average",
      "fitness",
      "health",
      "measure",
      "monitor",
      "stopwatch",
      "timer",
      "wellness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "award_star",
    "version": 248,
    "popularity": 101,
    "codepoint": 62994,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "badge",
      "emblem",
      "prize",
      "reward",
      "seal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "azm",
    "version": 248,
    "popularity": 46,
    "codepoint": 63212,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "active",
      "active zone minutes",
      "exercise",
      "fitbit",
      "fitness",
      "minutes",
      "sports",
      "workout",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "baby_changing_station",
    "version": 248,
    "popularity": 618,
    "codepoint": 61851,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "babies",
      "baby",
      "bathroom",
      "body",
      "changing",
      "child",
      "children",
      "father",
      "human",
      "infant",
      "kids",
      "mother",
      "newborn",
      "people",
      "person",
      "station",
      "toddler",
      "wc",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "baby_changing_station",
    "version": 8,
    "popularity": 2902,
    "codepoint": 61851,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "babies",
      "baby",
      "bathroom",
      "body",
      "changing",
      "child",
      "children",
      "father",
      "human",
      "infant",
      "kids",
      "mother",
      "newborn",
      "people",
      "person",
      "station",
      "toddler",
      "wc",
      "young"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "back_hand",
    "version": 248,
    "popularity": 1980,
    "codepoint": 59236,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "back",
      "fingers",
      "gesture",
      "hand",
      "raised"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "back_hand",
    "version": 3,
    "popularity": 10871,
    "codepoint": 59236,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "back",
      "fingers",
      "gesture",
      "hand",
      "raised"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "back_to_tab",
    "version": 248,
    "popularity": 11,
    "codepoint": 63275,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "frame",
      "left",
      "up",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "background_dot_large",
    "version": 248,
    "popularity": 8,
    "codepoint": 63390,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "dots",
      "grid",
      "layout",
      "space",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "background_dot_small",
    "version": 248,
    "popularity": 2,
    "codepoint": 62740,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "dots",
      "grid",
      "layout",
      "space",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "background_grid_small",
    "version": 248,
    "popularity": 8,
    "codepoint": 63389,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "4",
      "4x4",
      "layout",
      "line",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "background_replace",
    "version": 248,
    "popularity": 1261,
    "codepoint": 61962,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "backdrop",
      "background",
      "camera",
      "filter",
      "human",
      "people",
      "person",
      "replace",
      "user",
      "video",
      "webcam"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backlight_high",
    "version": 248,
    "popularity": 3,
    "codepoint": 63469,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "backlit",
      "bright",
      "brightness",
      "keyboard",
      "light"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backlight_high_off",
    "version": 248,
    "popularity": 1,
    "codepoint": 62703,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "backlit",
      "bright",
      "brightness",
      "disabled",
      "enabled",
      "keyboard",
      "light",
      "off",
      "offline",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backlight_low",
    "version": 248,
    "popularity": 4,
    "codepoint": 63468,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "backlit",
      "brightness",
      "dim",
      "keyboard",
      "light"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backpack",
    "version": 248,
    "popularity": 952,
    "codepoint": 61852,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "knapsack",
      "pack",
      "storage",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backpack",
    "version": 8,
    "popularity": 3845,
    "codepoint": 61852,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "knapsack",
      "pack",
      "storage",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "backspace",
    "version": 248,
    "popularity": 8942,
    "codepoint": 57674,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backspace",
      "cancel",
      "clear",
      "correct",
      "delete",
      "erase",
      "remove"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backspace",
    "version": 13,
    "popularity": 26564,
    "codepoint": 57674,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "arrow",
      "back",
      "backspace",
      "cancel",
      "clear",
      "correct",
      "delete",
      "erase",
      "remove"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "backup",
    "version": 248,
    "popularity": 1932,
    "codepoint": 59492,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "backup",
      "cloud",
      "data",
      "drive",
      "files folders",
      "storage",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backup",
    "version": 15,
    "popularity": 16158,
    "codepoint": 59492,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "backup",
      "cloud",
      "data",
      "drive",
      "files folders",
      "storage",
      "up",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "backup_table",
    "version": 248,
    "popularity": 783,
    "codepoint": 61251,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "backup",
      "drive",
      "files folders",
      "format",
      "layout",
      "stack",
      "storage",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "backup_table",
    "version": 11,
    "popularity": 5621,
    "codepoint": 61251,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "backup",
      "drive",
      "files folders",
      "format",
      "layout",
      "stack",
      "storage",
      "table"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "badge",
    "version": 248,
    "popularity": 11306,
    "codepoint": 60007,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "account",
      "avatar",
      "badge",
      "card",
      "certified",
      "employee",
      "face",
      "human",
      "identification",
      "name",
      "people",
      "person",
      "profile",
      "security",
      "user",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "badge",
    "version": 11,
    "popularity": 56913,
    "codepoint": 60007,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "account",
      "avatar",
      "badge",
      "card",
      "certified",
      "employee",
      "face",
      "human",
      "identification",
      "name",
      "people",
      "person",
      "profile",
      "security",
      "user",
      "work"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "badge_critical_battery",
    "version": 248,
    "popularity": 332,
    "codepoint": 61767,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "badge",
      "battery",
      "cell",
      "charge",
      "critical",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bakery_dining",
    "version": 248,
    "popularity": 1311,
    "codepoint": 59987,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bakery",
      "bread",
      "breakfast",
      "brunch",
      "croissant",
      "dining",
      "food"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bakery_dining",
    "version": 11,
    "popularity": 6021,
    "codepoint": 59987,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bakery",
      "bread",
      "breakfast",
      "brunch",
      "croissant",
      "dining",
      "food"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "balance",
    "version": 248,
    "popularity": 2417,
    "codepoint": 60150,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "balance",
      "equal",
      "equity",
      "impartiality",
      "justice",
      "parity",
      "stability. equilibrium",
      "steadiness",
      "symmetry"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "balance",
    "version": 1,
    "popularity": 8672,
    "codepoint": 60150,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "balance",
      "equal",
      "equity",
      "impartiality",
      "justice",
      "parity",
      "stability. equilibrium",
      "steadiness",
      "symmetry"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "balcony",
    "version": 248,
    "popularity": 402,
    "codepoint": 58767,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "balcony",
      "doors",
      "estate",
      "home",
      "house",
      "maps",
      "out",
      "outside",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "terrace",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "balcony",
    "version": 4,
    "popularity": 2314,
    "codepoint": 58767,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "balcony",
      "doors",
      "estate",
      "home",
      "house",
      "maps",
      "out",
      "outside",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "terrace",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ballot",
    "version": 248,
    "popularity": 1398,
    "codepoint": 57714,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "ballot",
      "bullet",
      "election",
      "list",
      "point",
      "poll",
      "vote"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ballot",
    "version": 14,
    "popularity": 13813,
    "codepoint": 57714,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "ballot",
      "bullet",
      "election",
      "list",
      "point",
      "poll",
      "vote"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bar_chart",
    "version": 248,
    "popularity": 7211,
    "codepoint": 57963,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bar_chart",
    "version": 13,
    "popularity": 36851,
    "codepoint": 57963,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "bar_chart_4_bars",
    "version": 248,
    "popularity": 129,
    "codepoint": 63105,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "fitbit",
      "fitness",
      "graph",
      "health dashboard",
      "health metric dashboard",
      "health monitoring",
      "infographic",
      "measure",
      "metric",
      "metrics",
      "oxygen",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "barcode",
    "version": 248,
    "popularity": 2402,
    "codepoint": 59147,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "barcode",
      "ecommerce",
      "price",
      "scan",
      "shop",
      "shopping",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "barcode_reader",
    "version": 248,
    "popularity": 9,
    "codepoint": 63580,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "barcode",
      "ecommerce",
      "handheld",
      "machine",
      "price",
      "reader",
      "scan",
      "scanner",
      "shop",
      "shopping",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "barcode_reader",
    "version": 1,
    "popularity": 2687,
    "codepoint": 63580,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "barcode",
      "ecommerce",
      "handheld",
      "machine",
      "price",
      "reader",
      "scan",
      "scanner",
      "shop",
      "shopping",
      "tag"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "barcode_scanner",
    "version": 248,
    "popularity": 2904,
    "codepoint": 59148,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "barcode",
      "ecommerce",
      "price",
      "scan",
      "shop",
      "shopping",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "barefoot",
    "version": 248,
    "popularity": 22,
    "codepoint": 63601,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bare",
      "feet",
      "foot",
      "footmark",
      "footprint",
      "footstep",
      "footsteps",
      "ground",
      "human",
      "impression",
      "trace",
      "walking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "batch_prediction",
    "version": 248,
    "popularity": 615,
    "codepoint": 61685,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "batch",
      "bulb",
      "idea",
      "light",
      "prediction"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "batch_prediction",
    "version": 12,
    "popularity": 4543,
    "codepoint": 61685,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "batch",
      "bulb",
      "idea",
      "light",
      "prediction"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "bath_outdoor",
    "version": 248,
    "popularity": 22,
    "codepoint": 63227,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bathe",
      "bathhouse",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "onsen",
      "outside",
      "spa",
      "steam",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bath_private",
    "version": 248,
    "popularity": 28,
    "codepoint": 63226,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bathe",
      "bathhouse",
      "body",
      "couple",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "human",
      "inside",
      "onsen",
      "people",
      "person",
      "spa",
      "steam",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bath_public_large",
    "version": 248,
    "popularity": 9,
    "codepoint": 63225,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bathe",
      "bathhouse",
      "body",
      "group",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "human",
      "inside",
      "onsen",
      "people",
      "person",
      "spa",
      "steam",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bathroom",
    "version": 248,
    "popularity": 526,
    "codepoint": 61405,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bath",
      "bathroom",
      "closet",
      "home",
      "house",
      "place",
      "plumbing",
      "room",
      "shower",
      "sprinkler",
      "wash",
      "water",
      "wc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bathroom",
    "version": 10,
    "popularity": 3505,
    "codepoint": 61405,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bath",
      "bathroom",
      "closet",
      "home",
      "house",
      "place",
      "plumbing",
      "room",
      "shower",
      "sprinkler",
      "wash",
      "water",
      "wc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bathtub",
    "version": 248,
    "popularity": 1272,
    "codepoint": 59969,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bath",
      "bathing",
      "bathroom",
      "bathtub",
      "home",
      "hotel",
      "human",
      "person",
      "shower",
      "travel",
      "tub"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bathtub",
    "version": 11,
    "popularity": 6310,
    "codepoint": 59969,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bath",
      "bathing",
      "bathroom",
      "bathtub",
      "home",
      "hotel",
      "human",
      "person",
      "shower",
      "travel",
      "tub"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_0_bar",
    "version": 248,
    "popularity": 1066,
    "codepoint": 60380,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "0",
      "bar",
      "battery",
      "cell",
      "charge",
      "full",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_0_bar",
    "version": 1,
    "popularity": 2659,
    "codepoint": 60380,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "0",
      "bar",
      "battery",
      "cell",
      "charge",
      "full",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_1_bar",
    "version": 248,
    "popularity": 758,
    "codepoint": 60377,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_1_bar",
    "version": 1,
    "popularity": 2011,
    "codepoint": 60377,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_20",
    "version": 12,
    "popularity": 31,
    "codepoint": 61596,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "20",
      "battery",
      "cell",
      "charge",
      "low",
      "mobile",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_2_bar",
    "version": 248,
    "popularity": 568,
    "codepoint": 60384,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "2",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_2_bar",
    "version": 1,
    "popularity": 1693,
    "codepoint": 60384,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_30",
    "version": 12,
    "popularity": 12,
    "codepoint": 61597,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "30",
      "battery",
      "cell",
      "charge",
      "low",
      "mobile",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_3_bar",
    "version": 248,
    "popularity": 774,
    "codepoint": 60381,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_3_bar",
    "version": 1,
    "popularity": 2320,
    "codepoint": 60381,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_4_bar",
    "version": 248,
    "popularity": 1167,
    "codepoint": 60386,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "4",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_4_bar",
    "version": 1,
    "popularity": 2744,
    "codepoint": 60386,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_50",
    "version": 12,
    "popularity": 13,
    "codepoint": 61598,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "50",
      "battery",
      "cell",
      "charge",
      "half",
      "mobile",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_5_bar",
    "version": 248,
    "popularity": 1645,
    "codepoint": 60372,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "5",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_5_bar",
    "version": 1,
    "popularity": 3386,
    "codepoint": 60372,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "5",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_60",
    "version": 12,
    "popularity": 14,
    "codepoint": 61599,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "60",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_6_bar",
    "version": 248,
    "popularity": 990,
    "codepoint": 60370,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "6",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_6_bar",
    "version": 1,
    "popularity": 2456,
    "codepoint": 60370,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "6",
      "bar",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "battery_80",
    "version": 12,
    "popularity": 14,
    "codepoint": 61600,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "80",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_90",
    "version": 12,
    "popularity": 12,
    "codepoint": 61601,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "90",
      "battery",
      "cell",
      "charge",
      "mobile",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_alert",
    "version": 248,
    "popularity": 688,
    "codepoint": 57756,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "battery",
      "caution",
      "cell",
      "charge",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "mobile",
      "notification",
      "power",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_alert",
    "version": 12,
    "popularity": 4476,
    "codepoint": 57756,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "battery",
      "caution",
      "cell",
      "charge",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "mobile",
      "notification",
      "power",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_change",
    "version": 248,
    "popularity": 4,
    "codepoint": 63467,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "charge",
      "mobile",
      "power",
      "surge"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_20",
    "version": 248,
    "popularity": 410,
    "codepoint": 61602,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "20",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightning",
      "low",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_20",
    "version": 12,
    "popularity": 19,
    "codepoint": 61602,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "20",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightning",
      "low",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_charging_30",
    "version": 248,
    "popularity": 273,
    "codepoint": 61603,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "30",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "low",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_30",
    "version": 12,
    "popularity": 15,
    "codepoint": 61603,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "30",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "low",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_charging_50",
    "version": 248,
    "popularity": 372,
    "codepoint": 61604,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "50",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "half",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_50",
    "version": 11,
    "popularity": 18,
    "codepoint": 61604,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "50",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "half",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_charging_60",
    "version": 248,
    "popularity": 307,
    "codepoint": 61605,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "60",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_60",
    "version": 13,
    "popularity": 19,
    "codepoint": 61605,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "60",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_charging_80",
    "version": 248,
    "popularity": 411,
    "codepoint": 61606,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "60",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_80",
    "version": 12,
    "popularity": 20,
    "codepoint": 61606,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "60",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_charging_90",
    "version": 248,
    "popularity": 409,
    "codepoint": 61607,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "90",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_90",
    "version": 12,
    "popularity": 25,
    "codepoint": 61607,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "90",
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_charging_full",
    "version": 248,
    "popularity": 2132,
    "codepoint": 57763,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "full",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_charging_full",
    "version": 12,
    "popularity": 15008,
    "codepoint": 57763,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "battery",
      "bolt",
      "cell",
      "charge",
      "charging",
      "electric",
      "energy",
      "full",
      "instant",
      "lightening",
      "mobile",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_error",
    "version": 248,
    "popularity": 10,
    "codepoint": 63466,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alert",
      "cancel",
      "cell",
      "charge",
      "clear",
      "close",
      "exit",
      "issue",
      "mobile",
      "no",
      "power",
      "remove",
      "stop",
      "warning",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_full",
    "version": 248,
    "popularity": 2507,
    "codepoint": 57764,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "0",
      "bar",
      "battery",
      "cell",
      "charge",
      "full",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_full",
    "version": 12,
    "popularity": 20169,
    "codepoint": 57764,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "0",
      "bar",
      "battery",
      "cell",
      "charge",
      "full",
      "mobile",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_full_alt",
    "version": 248,
    "popularity": 1145,
    "codepoint": 61755,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "0",
      "bar",
      "battery",
      "cell",
      "charge",
      "full",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_horiz_000",
    "version": 248,
    "popularity": 288,
    "codepoint": 63662,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "battery ",
      "empty",
      "home",
      "horizontal",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_horiz_050",
    "version": 248,
    "popularity": 488,
    "codepoint": 63663,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "50%",
      "battery",
      "fifty",
      "home",
      "horizontal",
      "nest",
      "percent"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_horiz_075",
    "version": 248,
    "popularity": 808,
    "codepoint": 63664,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "75%",
      "battery",
      "home",
      "horizontal",
      "nest",
      "percent",
      "seventy-five"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_low",
    "version": 248,
    "popularity": 689,
    "codepoint": 61781,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bar",
      "cell",
      "charge",
      "horizontal",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_plus",
    "version": 248,
    "popularity": 10,
    "codepoint": 63465,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "battery",
      "charge",
      "charging",
      "new",
      "plus",
      "power",
      "saver",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_profile",
    "version": 248,
    "popularity": 179,
    "codepoint": 57862,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "application",
      "battery",
      "change",
      "details",
      "gear",
      "home",
      "info",
      "information",
      "nest",
      "options",
      "personal",
      "profile",
      "security",
      "service",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_saver",
    "version": 248,
    "popularity": 411,
    "codepoint": 61406,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "battery",
      "charge",
      "charging",
      "new",
      "plus",
      "power",
      "saver",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_saver",
    "version": 9,
    "popularity": 3213,
    "codepoint": 61406,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "+",
      "add",
      "battery",
      "charge",
      "charging",
      "new",
      "plus",
      "power",
      "saver",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_share",
    "version": 248,
    "popularity": 0,
    "codepoint": 63102,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrow",
      "arrows",
      "cell",
      "charge",
      "mobile",
      "power",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_status_good",
    "version": 248,
    "popularity": 15,
    "codepoint": 63101,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "approve",
      "cell",
      "charge",
      "check",
      "checkmark",
      "complete",
      "done",
      "mobile",
      "ok",
      "power",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_std",
    "version": 12,
    "popularity": 6133,
    "codepoint": 57765,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "battery",
      "cell",
      "charge",
      "mobile",
      "plus",
      "power",
      "standard",
      "std"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_unknown",
    "version": 248,
    "popularity": 386,
    "codepoint": 57766,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "?",
      "assistance",
      "battery",
      "cell",
      "charge",
      "help",
      "info",
      "information",
      "mobile",
      "power",
      "punctuation",
      "question mark",
      "support",
      "symbol",
      "unknown"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "battery_unknown",
    "version": 13,
    "popularity": 2661,
    "codepoint": 57766,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "?",
      "assistance",
      "battery",
      "cell",
      "charge",
      "help",
      "info",
      "information",
      "mobile",
      "power",
      "punctuation",
      "question mark",
      "support",
      "symbol",
      "unknown"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "battery_very_low",
    "version": 248,
    "popularity": 432,
    "codepoint": 61782,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bar",
      "battery",
      "cell",
      "charge",
      "low",
      "mobile",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "beach_access",
    "version": 248,
    "popularity": 2097,
    "codepoint": 60222,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "access",
      "beach",
      "places",
      "summer",
      "sunny",
      "umbrella"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "beach_access",
    "version": 12,
    "popularity": 9497,
    "codepoint": 60222,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "access",
      "beach",
      "places",
      "summer",
      "sunny",
      "umbrella"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bed",
    "version": 248,
    "popularity": 3206,
    "codepoint": 61407,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "full",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "size",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bed",
    "version": 10,
    "popularity": 13901,
    "codepoint": 61407,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "full",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "size",
      "sleep"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bedroom_baby",
    "version": 248,
    "popularity": 545,
    "codepoint": 61408,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "babies",
      "baby",
      "bedroom",
      "child",
      "children",
      "home",
      "horse",
      "house",
      "infant",
      "kid",
      "newborn",
      "rocking",
      "room",
      "toddler",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bedroom_baby",
    "version": 9,
    "popularity": 3569,
    "codepoint": 61408,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "babies",
      "baby",
      "bedroom",
      "child",
      "children",
      "home",
      "horse",
      "house",
      "infant",
      "kid",
      "newborn",
      "rocking",
      "room",
      "toddler",
      "young"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bedroom_child",
    "version": 248,
    "popularity": 390,
    "codepoint": 61409,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bed",
      "bedroom",
      "child",
      "children",
      "furniture",
      "home",
      "hotel",
      "house",
      "kid",
      "night",
      "pillows",
      "rest",
      "room",
      "size",
      "sleep",
      "twin",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bedroom_child",
    "version": 9,
    "popularity": 2938,
    "codepoint": 61409,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bed",
      "bedroom",
      "child",
      "children",
      "furniture",
      "home",
      "hotel",
      "house",
      "kid",
      "night",
      "pillows",
      "rest",
      "room",
      "size",
      "sleep",
      "twin",
      "young"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bedroom_parent",
    "version": 248,
    "popularity": 592,
    "codepoint": 61410,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "full",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "parent",
      "pillows",
      "queen",
      "rest",
      "room",
      "sizem master",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bedroom_parent",
    "version": 9,
    "popularity": 3648,
    "codepoint": 61410,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "full",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "parent",
      "pillows",
      "queen",
      "rest",
      "room",
      "sizem master",
      "sleep"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bedtime",
    "version": 248,
    "popularity": 1949,
    "codepoint": 61252,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bedtime",
      "nightime",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bedtime",
    "version": 15,
    "popularity": 4386,
    "codepoint": 61252,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bedtime",
      "nightime",
      "sleep"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "bedtime_off",
    "version": 248,
    "popularity": 285,
    "codepoint": 60278,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bedtime",
      "disabled",
      "lunar",
      "moon",
      "night",
      "nightime",
      "off",
      "offline",
      "slash",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bedtime_off",
    "version": 2,
    "popularity": 639,
    "codepoint": 60278,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bedtime",
      "disabled",
      "lunar",
      "moon",
      "night",
      "nightime",
      "off",
      "offline",
      "slash",
      "sleep"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "beenhere",
    "version": 248,
    "popularity": 1301,
    "codepoint": 58669,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "approve",
      "archive",
      "beenhere",
      "bookmark",
      "check",
      "complete",
      "done",
      "favorite",
      "label",
      "library",
      "mark",
      "ok",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "select",
      "tag",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "beenhere",
    "version": 12,
    "popularity": 9089,
    "codepoint": 58669,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "approve",
      "archive",
      "beenhere",
      "bookmark",
      "check",
      "complete",
      "done",
      "favorite",
      "label",
      "library",
      "mark",
      "ok",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "select",
      "tag",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bento",
    "version": 248,
    "popularity": 306,
    "codepoint": 61940,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bento",
      "box",
      "dinner",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "takeout"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bento",
    "version": 6,
    "popularity": 2154,
    "codepoint": 61940,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bento",
      "box",
      "dinner",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "takeout"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bia",
    "version": 248,
    "popularity": 13,
    "codepoint": 63211,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bodies",
      "body",
      "body composition",
      "bone",
      "fat",
      "fitbit",
      "fitness",
      "health",
      "human",
      "measurement",
      "muscle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bid_landscape",
    "version": 248,
    "popularity": 103,
    "codepoint": 58983,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "bid",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "landscape",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bid_landscape_disabled",
    "version": 248,
    "popularity": 8,
    "codepoint": 61313,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "bid",
      "chart",
      "data",
      "diagram",
      "disabled",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "off",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bigtop_updates",
    "version": 248,
    "popularity": 49,
    "codepoint": 58985,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bigtop",
      "connection",
      "internet",
      "network",
      "signal",
      "updates",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bike_scooter",
    "version": 248,
    "popularity": 259,
    "codepoint": 61253,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "maps",
      "scooter",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bike_scooter",
    "version": 11,
    "popularity": 1376,
    "codepoint": 61253,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "maps",
      "scooter",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "biotech",
    "version": 248,
    "popularity": 2593,
    "codepoint": 59962,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "biotech",
      "chemistry",
      "laboratory",
      "microscope",
      "research",
      "science",
      "technology"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "biotech",
    "version": 11,
    "popularity": 13666,
    "codepoint": 59962,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "biotech",
      "chemistry",
      "laboratory",
      "microscope",
      "research",
      "science",
      "technology"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "blanket",
    "version": 248,
    "popularity": 159,
    "codepoint": 59432,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blanket",
      "cold",
      "cover",
      "nest",
      "throw",
      "wrap"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blender",
    "version": 248,
    "popularity": 575,
    "codepoint": 61411,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "blender",
      "cook",
      "cooking",
      "electric",
      "juicer",
      "kitchen",
      "machine",
      "vitamix"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blender",
    "version": 9,
    "popularity": 4050,
    "codepoint": 61411,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "appliance",
      "blender",
      "cook",
      "cooking",
      "electric",
      "juicer",
      "kitchen",
      "machine",
      "vitamix"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "blind",
    "version": 248,
    "popularity": 431,
    "codepoint": 63702,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "assist",
      "blind",
      "body",
      "cane",
      "disability",
      "handicap",
      "help",
      "human",
      "mobility",
      "person",
      "walk",
      "walker"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blind",
    "version": 1,
    "popularity": 681,
    "codepoint": 63702,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "assist",
      "blind",
      "body",
      "cane",
      "disability",
      "handicap",
      "help",
      "human",
      "mobility",
      "person",
      "walk",
      "walker"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "blinds",
    "version": 248,
    "popularity": 230,
    "codepoint": 57990,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blinds",
    "version": 3,
    "popularity": 680,
    "codepoint": 57990,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "blinds_closed",
    "version": 248,
    "popularity": 193,
    "codepoint": 60447,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blinds_closed",
    "version": 1,
    "popularity": 637,
    "codepoint": 60447,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "block",
    "version": 248,
    "popularity": 10978,
    "codepoint": 57675,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "avoid",
      "block",
      "cancel",
      "close",
      "entry",
      "exit",
      "no",
      "prohibited",
      "quit",
      "remove",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "block",
    "version": 17,
    "popularity": 39580,
    "codepoint": 57675,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "avoid",
      "block",
      "cancel",
      "close",
      "entry",
      "exit",
      "no",
      "prohibited",
      "quit",
      "remove",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "block_flipped",
    "version": 12,
    "popularity": 1278,
    "codepoint": 61254,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "block",
      "flipped"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "blood_pressure",
    "version": 248,
    "popularity": 7,
    "codepoint": 57495,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "doctor",
      "health",
      "measure",
      "medical",
      "medicine",
      "monitor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bloodtype",
    "version": 248,
    "popularity": 1072,
    "codepoint": 61412,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "blood",
      "bloodtype",
      "donate",
      "droplet",
      "emergency",
      "hospital",
      "medicine",
      "negative",
      "positive",
      "type",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bloodtype",
    "version": 10,
    "popularity": 6091,
    "codepoint": 61412,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "blood",
      "bloodtype",
      "donate",
      "droplet",
      "emergency",
      "hospital",
      "medicine",
      "negative",
      "positive",
      "type",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bluetooth",
    "version": 248,
    "popularity": 2258,
    "codepoint": 57767,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "paring",
      "streaming",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bluetooth",
    "version": 11,
    "popularity": 11946,
    "codepoint": 57767,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "paring",
      "streaming",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bluetooth_audio",
    "version": 12,
    "popularity": 1681,
    "codepoint": 58895,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "audio",
      "bluetooth",
      "connect",
      "connection",
      "device",
      "music",
      "signal",
      "sound",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bluetooth_connected",
    "version": 248,
    "popularity": 597,
    "codepoint": 57768,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "paring",
      "streaming",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bluetooth_connected",
    "version": 12,
    "popularity": 3562,
    "codepoint": 57768,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "paring",
      "streaming",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bluetooth_disabled",
    "version": 248,
    "popularity": 493,
    "codepoint": 57769,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "paring",
      "slash",
      "streaming",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bluetooth_disabled",
    "version": 17,
    "popularity": 2826,
    "codepoint": 57769,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bluetooth",
      "cast",
      "connect",
      "connection",
      "device",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "paring",
      "slash",
      "streaming",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bluetooth_drive",
    "version": 248,
    "popularity": 231,
    "codepoint": 61413,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "automobile",
      "bluetooth",
      "car",
      "cars",
      "cast",
      "connect",
      "connection",
      "device",
      "drive",
      "maps",
      "paring",
      "streaming",
      "symbol",
      "transportation",
      "travel",
      "vehicle",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bluetooth_drive",
    "version": 9,
    "popularity": 1252,
    "codepoint": 61413,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "automobile",
      "bluetooth",
      "car",
      "cars",
      "cast",
      "connect",
      "connection",
      "device",
      "drive",
      "maps",
      "paring",
      "streaming",
      "symbol",
      "transportation",
      "travel",
      "vehicle",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bluetooth_searching",
    "version": 248,
    "popularity": 643,
    "codepoint": 57770,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bluetooth",
      "connection",
      "device",
      "paring",
      "search",
      "searching",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bluetooth_searching",
    "version": 13,
    "popularity": 2978,
    "codepoint": 57770,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bluetooth",
      "connection",
      "device",
      "paring",
      "search",
      "searching",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "blur_circular",
    "version": 248,
    "popularity": 426,
    "codepoint": 58274,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "circle",
      "circular",
      "dots",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blur_circular",
    "version": 12,
    "popularity": 2440,
    "codepoint": 58274,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "blur",
      "circle",
      "circular",
      "dots",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "blur_linear",
    "version": 248,
    "popularity": 249,
    "codepoint": 58275,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "dots",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
      "linear"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blur_linear",
    "version": 12,
    "popularity": 1738,
    "codepoint": 58275,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "blur",
      "dots",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
      "linear"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "blur_medium",
    "version": 248,
    "popularity": 234,
    "codepoint": 59468,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
      "medium",
      "motion",
      "movement"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blur_off",
    "version": 248,
    "popularity": 161,
    "codepoint": 58276,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "disabled",
      "dots",
      "edit",
      "editing",
      "effect",
      "enabled",
      "enhance",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blur_off",
    "version": 12,
    "popularity": 1162,
    "codepoint": 58276,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "blur",
      "disabled",
      "dots",
      "edit",
      "editing",
      "effect",
      "enabled",
      "enhance",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "blur_on",
    "version": 248,
    "popularity": 1291,
    "codepoint": 58277,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "disabled",
      "dots",
      "edit",
      "editing",
      "effect",
      "enabled",
      "enhance",
      "filter",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "blur_on",
    "version": 12,
    "popularity": 6882,
    "codepoint": 58277,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "blur",
      "disabled",
      "dots",
      "edit",
      "editing",
      "effect",
      "enabled",
      "enhance",
      "filter",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "blur_short",
    "version": 248,
    "popularity": 196,
    "codepoint": 59599,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "edit",
      "editing",
      "effect",
      "enhance",
      "filter",
      "motion",
      "movement",
      "short"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "body_fat",
    "version": 248,
    "popularity": 4,
    "codepoint": 57496,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "caliper",
      "fitness",
      "health",
      "measure",
      "meter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "body_system",
    "version": 248,
    "popularity": 10,
    "codepoint": 57497,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "fitness",
      "health",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bolt",
    "version": 248,
    "popularity": 14582,
    "codepoint": 59915,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bolt",
    "version": 10,
    "popularity": 48081,
    "codepoint": 59915,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "power",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bomb",
    "version": 248,
    "popularity": 13,
    "codepoint": 62824,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "atomic",
      "blast",
      "blasts",
      "boom",
      "burst",
      "bursts",
      "disaster",
      "explode",
      "explosion",
      "explosive",
      "loud",
      "mine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "book",
    "version": 248,
    "popularity": 4621,
    "codepoint": 59493,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "book",
    "version": 16,
    "popularity": 29369,
    "codepoint": 59493,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "book_2",
    "version": 248,
    "popularity": 17,
    "codepoint": 62782,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "book_3",
    "version": 248,
    "popularity": 3,
    "codepoint": 62781,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "book_4",
    "version": 248,
    "popularity": 8,
    "codepoint": 62780,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "book_5",
    "version": 248,
    "popularity": 12,
    "codepoint": 62779,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "book_online",
    "version": 248,
    "popularity": 1444,
    "codepoint": 61975,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "admission",
      "appointment",
      "book",
      "cell",
      "device",
      "event",
      "hardware",
      "iOS",
      "mobile",
      "online",
      "pass",
      "phone",
      "reservation",
      "tablet",
      "ticket"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "book_online",
    "version": 10,
    "popularity": 15521,
    "codepoint": 61975,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "admission",
      "appointment",
      "book",
      "cell",
      "device",
      "event",
      "hardware",
      "iOS",
      "mobile",
      "online",
      "pass",
      "phone",
      "reservation",
      "tablet",
      "ticket"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bookmark",
    "version": 248,
    "popularity": 12950,
    "codepoint": 59494,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "archive",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bookmark",
    "version": 17,
    "popularity": 46976,
    "codepoint": 59494,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "archive",
      "bookmark",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bookmark_add",
    "version": 248,
    "popularity": 2154,
    "codepoint": 58776,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "add",
      "bookmark",
      "favorite",
      "plus",
      "remember",
      "ribbon",
      "save",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bookmark_add",
    "version": 3,
    "popularity": 11576,
    "codepoint": 58776,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "add",
      "bookmark",
      "favorite",
      "plus",
      "remember",
      "ribbon",
      "save",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "bookmark_added",
    "version": 248,
    "popularity": 1490,
    "codepoint": 58777,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "added",
      "approve",
      "bookmark",
      "check",
      "complete",
      "done",
      "favorite",
      "mark",
      "ok",
      "remember",
      "save",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bookmark_added",
    "version": 3,
    "popularity": 9260,
    "codepoint": 58777,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "added",
      "approve",
      "bookmark",
      "check",
      "complete",
      "done",
      "favorite",
      "mark",
      "ok",
      "remember",
      "save",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bookmark_border",
    "version": 12,
    "popularity": 44919,
    "codepoint": 59495,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "archive",
      "bookmark",
      "border",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bookmark_manager",
    "version": 248,
    "popularity": 21,
    "codepoint": 63409,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "compose",
      "create",
      "data",
      "doc",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "folder",
      "input",
      "new",
      "pen",
      "pencil",
      "plus",
      "sheet",
      "slide",
      "storage",
      "symbol",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bookmark_remove",
    "version": 248,
    "popularity": 717,
    "codepoint": 58778,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "bookmark",
      "delete",
      "favorite",
      "minus",
      "remember",
      "remove",
      "ribbon",
      "save",
      "subtract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bookmark_remove",
    "version": 3,
    "popularity": 0,
    "codepoint": 58778,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bookmark",
      "delete",
      "favorite",
      "minus",
      "remember",
      "remove",
      "ribbon",
      "save",
      "subtract"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "bookmarks",
    "version": 248,
    "popularity": 3282,
    "codepoint": 59787,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "bookmark",
      "bookmarks",
      "favorite",
      "label",
      "layers",
      "library",
      "multiple",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "stack",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bookmarks",
    "version": 12,
    "popularity": 18021,
    "codepoint": 59787,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bookmark",
      "bookmarks",
      "favorite",
      "label",
      "layers",
      "library",
      "multiple",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "stack",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_all",
    "version": 248,
    "popularity": 379,
    "codepoint": 57896,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "all",
      "border",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_all",
    "version": 12,
    "popularity": 2612,
    "codepoint": 57896,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "all",
      "border",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_bottom",
    "version": 248,
    "popularity": 120,
    "codepoint": 57897,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "bottom",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_bottom",
    "version": 12,
    "popularity": 871,
    "codepoint": 57897,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "bottom",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_clear",
    "version": 248,
    "popularity": 187,
    "codepoint": 57898,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "clear",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_clear",
    "version": 12,
    "popularity": 1635,
    "codepoint": 57898,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "clear",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_color",
    "version": 248,
    "popularity": 4646,
    "codepoint": 57899,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "all",
      "border",
      "doc",
      "edit",
      "editing",
      "editor",
      "pen",
      "pencil",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_color",
    "version": 15,
    "popularity": 19821,
    "codepoint": 57899,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "all",
      "border",
      "doc",
      "edit",
      "editing",
      "editor",
      "pen",
      "pencil",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_horizontal",
    "version": 248,
    "popularity": 133,
    "codepoint": 57900,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "horizontal",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_horizontal",
    "version": 12,
    "popularity": 827,
    "codepoint": 57900,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "horizontal",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_inner",
    "version": 248,
    "popularity": 116,
    "codepoint": 57901,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "inner",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_inner",
    "version": 12,
    "popularity": 913,
    "codepoint": 57901,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "inner",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_left",
    "version": 248,
    "popularity": 116,
    "codepoint": 57902,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "left",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_left",
    "version": 12,
    "popularity": 899,
    "codepoint": 57902,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "left",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_outer",
    "version": 248,
    "popularity": 178,
    "codepoint": 57903,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "outer",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_outer",
    "version": 12,
    "popularity": 1353,
    "codepoint": 57903,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "outer",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_right",
    "version": 248,
    "popularity": 101,
    "codepoint": 57904,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "right",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_right",
    "version": 12,
    "popularity": 784,
    "codepoint": 57904,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "right",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_style",
    "version": 248,
    "popularity": 188,
    "codepoint": 57905,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "color",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_style",
    "version": 12,
    "popularity": 1476,
    "codepoint": 57905,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "color",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_top",
    "version": 248,
    "popularity": 116,
    "codepoint": 57906,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "top",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_top",
    "version": 12,
    "popularity": 801,
    "codepoint": 57906,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "top",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "border_vertical",
    "version": 248,
    "popularity": 152,
    "codepoint": 57907,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "border_vertical",
    "version": 12,
    "popularity": 844,
    "codepoint": 57907,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "border",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "stroke",
      "text",
      "type",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bottom_app_bar",
    "version": 248,
    "popularity": 5,
    "codepoint": 59184,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "bar",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bottom_drawer",
    "version": 248,
    "popularity": 19,
    "codepoint": 59181,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "drawer",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bottom_navigation",
    "version": 248,
    "popularity": 23,
    "codepoint": 59788,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "navigation",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bottom_panel_close",
    "version": 248,
    "popularity": 13,
    "codepoint": 63274,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "collapse",
      "direction",
      "down",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bottom_panel_open",
    "version": 248,
    "popularity": 27,
    "codepoint": 63273,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "direction",
      "expand",
      "layout",
      "panels",
      "spaces",
      "up",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bottom_right_click",
    "version": 248,
    "popularity": 14,
    "codepoint": 63108,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "dot",
      "east",
      "navigation",
      "south"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bottom_sheets",
    "version": 248,
    "popularity": 18,
    "codepoint": 59789,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "sheets",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "box",
    "version": 248,
    "popularity": 28,
    "codepoint": 62884,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "closed box",
      "package",
      "parcel",
      "post",
      "postal",
      "shipment",
      "shipping"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "box_add",
    "version": 248,
    "popularity": 14,
    "codepoint": 62885,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "+",
      "add",
      "closed box",
      "new",
      "package",
      "parcel",
      "plus",
      "post",
      "postal",
      "shipment",
      "shipping"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "box_edit",
    "version": 248,
    "popularity": 3,
    "codepoint": 62886,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "editing",
      "input",
      "modify",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "boy",
    "version": 248,
    "popularity": 1474,
    "codepoint": 60263,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "boy",
      "gender",
      "human",
      "male",
      "man",
      "people",
      "person",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "boy",
    "version": 1,
    "popularity": 3241,
    "codepoint": 60263,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "boy",
      "gender",
      "human",
      "male",
      "man",
      "people",
      "person",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "brand_awareness",
    "version": 248,
    "popularity": 275,
    "codepoint": 59790,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alert",
      "announcement",
      "audio",
      "awareness",
      "brand",
      "control",
      "music",
      "news",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brand_family",
    "version": 248,
    "popularity": 7,
    "codepoint": 62705,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "character",
      "font",
      "fonts",
      "format",
      "letter",
      "styles",
      "symbol",
      "tester",
      "text",
      "type",
      "typefaces"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "branding_watermark",
    "version": 248,
    "popularity": 954,
    "codepoint": 57451,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "branding",
      "components",
      "copyright",
      "design",
      "emblem",
      "format",
      "identity",
      "interface",
      "layout",
      "logo",
      "screen",
      "site",
      "stamp",
      "ui",
      "ux",
      "watermark",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "branding_watermark",
    "version": 13,
    "popularity": 6698,
    "codepoint": 57451,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "branding",
      "components",
      "copyright",
      "design",
      "emblem",
      "format",
      "identity",
      "interface",
      "layout",
      "logo",
      "screen",
      "site",
      "stamp",
      "ui",
      "ux",
      "watermark",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "breakfast_dining",
    "version": 248,
    "popularity": 531,
    "codepoint": 59988,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bakery",
      "bread",
      "breakfast",
      "butter",
      "dining",
      "food",
      "toast"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "breakfast_dining",
    "version": 11,
    "popularity": 0,
    "codepoint": 59988,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bakery",
      "bread",
      "breakfast",
      "butter",
      "dining",
      "food",
      "toast"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "breaking_news",
    "version": 248,
    "popularity": 73,
    "codepoint": 59912,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "headline",
      "important",
      "mark",
      "news",
      "newspaper",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "breaking_news_alt_1",
    "version": 248,
    "popularity": 936,
    "codepoint": 61626,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "headline",
      "important",
      "mark",
      "news",
      "newspaper",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "breastfeeding",
    "version": 248,
    "popularity": 91,
    "codepoint": 63574,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "babies",
      "baby",
      "body",
      "breast",
      "breastfed",
      "breastfeed",
      "care",
      "child",
      "children",
      "cuddle",
      "feed",
      "feeding",
      "hug",
      "human",
      "infant",
      "kids",
      "lactation",
      "mama",
      "mom",
      "mommy",
      "newborn",
      "parent",
      "people",
      "person",
      "toddler",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_1",
    "version": 248,
    "popularity": 600,
    "codepoint": 58278,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "1",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_1",
    "version": 19,
    "popularity": 5190,
    "codepoint": 58278,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "1",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_2",
    "version": 248,
    "popularity": 396,
    "codepoint": 58279,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "2",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_2",
    "version": 12,
    "popularity": 2272,
    "codepoint": 58279,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "2",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_3",
    "version": 248,
    "popularity": 463,
    "codepoint": 58280,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "3",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_3",
    "version": 16,
    "popularity": 2496,
    "codepoint": 58280,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "3",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_4",
    "version": 248,
    "popularity": 862,
    "codepoint": 58281,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "4",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_4",
    "version": 16,
    "popularity": 5999,
    "codepoint": 58281,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "4",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_5",
    "version": 248,
    "popularity": 1008,
    "codepoint": 58282,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "5",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_5",
    "version": 12,
    "popularity": 5278,
    "codepoint": 58282,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "5",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_6",
    "version": 248,
    "popularity": 1539,
    "codepoint": 58283,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "6",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_6",
    "version": 12,
    "popularity": 5367,
    "codepoint": 58283,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "6",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_7",
    "version": 248,
    "popularity": 581,
    "codepoint": 58284,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "7",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_7",
    "version": 13,
    "popularity": 3833,
    "codepoint": 58284,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "7",
      "brightness",
      "circle",
      "control",
      "crescent",
      "level",
      "moon",
      "screen",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_alert",
    "version": 248,
    "popularity": 30,
    "codepoint": 62927,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "brightness",
      "caution",
      "control",
      "danger",
      "display",
      "error",
      "exclamation",
      "feedback",
      "important",
      "level",
      "mark",
      "mobile",
      "monitor",
      "notification",
      "phone",
      "problem",
      "report",
      "screen",
      "sun",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_auto",
    "version": 248,
    "popularity": 264,
    "codepoint": 57771,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "A",
      "auto",
      "brightness",
      "control",
      "display",
      "level",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_auto",
    "version": 12,
    "popularity": 1856,
    "codepoint": 57771,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "A",
      "auto",
      "brightness",
      "control",
      "display",
      "level",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_empty",
    "version": 248,
    "popularity": 17,
    "codepoint": 63464,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "0",
      "bright",
      "level",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_high",
    "version": 248,
    "popularity": 576,
    "codepoint": 57772,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "auto",
      "brightness",
      "control",
      "high",
      "mobile",
      "monitor",
      "phone",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_high",
    "version": 13,
    "popularity": 3323,
    "codepoint": 57772,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "auto",
      "brightness",
      "control",
      "high",
      "mobile",
      "monitor",
      "phone",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_low",
    "version": 248,
    "popularity": 437,
    "codepoint": 57773,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "auto",
      "brightness",
      "control",
      "low",
      "mobile",
      "monitor",
      "phone",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_low",
    "version": 12,
    "popularity": 2984,
    "codepoint": 57773,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "auto",
      "brightness",
      "control",
      "low",
      "mobile",
      "monitor",
      "phone",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "brightness_medium",
    "version": 248,
    "popularity": 453,
    "codepoint": 57774,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "auto",
      "brightness",
      "control",
      "medium",
      "mobile",
      "monitor",
      "phone",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brightness_medium",
    "version": 12,
    "popularity": 3025,
    "codepoint": 57774,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "auto",
      "brightness",
      "control",
      "medium",
      "mobile",
      "monitor",
      "phone",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bring_your_own_ip",
    "version": 248,
    "popularity": 866,
    "codepoint": 57366,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bring",
      "globe",
      "internet",
      "ip",
      "own",
      "protocol",
      "world",
      "your"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "broadcast_on_home",
    "version": 248,
    "popularity": 179,
    "codepoint": 63736,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "broadcast_on_home",
    "version": 1,
    "popularity": 598,
    "codepoint": 63736,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "broadcast_on_personal",
    "version": 248,
    "popularity": 223,
    "codepoint": 63737,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "broadcast_on_personal",
    "version": 1,
    "popularity": 698,
    "codepoint": 63737,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "broken_image",
    "version": 248,
    "popularity": 908,
    "codepoint": 58285,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "broken",
      "corrupt",
      "error",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "torn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "broken_image",
    "version": 11,
    "popularity": 6330,
    "codepoint": 58285,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "broken",
      "corrupt",
      "error",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "torn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "browse",
    "version": 248,
    "popularity": 96,
    "codepoint": 60179,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "browse",
      "grid",
      "layout",
      "pages",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "browse_activity",
    "version": 248,
    "popularity": 1261,
    "codepoint": 63653,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "activity",
      "browse",
      "desktop",
      "device",
      "display",
      "hardware",
      "history",
      "monitor",
      "screen",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "browse_gallery",
    "version": 248,
    "popularity": 1624,
    "codepoint": 60369,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "clock",
      "collection",
      "gallery",
      "library",
      "stack",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "browse_gallery",
    "version": 1,
    "popularity": 1565,
    "codepoint": 60369,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "clock",
      "collection",
      "gallery",
      "library",
      "stack",
      "watch"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "browser_not_supported",
    "version": 12,
    "popularity": 1348,
    "codepoint": 61255,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "browser",
      "disabled",
      "enabled",
      "internet",
      "not",
      "off",
      "on",
      "page",
      "screen",
      "site",
      "slash",
      "supported",
      "web",
      "website",
      "www"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "browser_updated",
    "version": 248,
    "popularity": 635,
    "codepoint": 59343,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "browser",
      "chrome",
      "desktop",
      "device",
      "display",
      "download",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "updated",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "browser_updated",
    "version": 2,
    "popularity": 2937,
    "codepoint": 59343,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "browser",
      "chrome",
      "desktop",
      "device",
      "display",
      "download",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "updated",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "brunch_dining",
    "version": 248,
    "popularity": 444,
    "codepoint": 60019,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "champagne",
      "dining",
      "drink",
      "food",
      "lunch",
      "meal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brunch_dining",
    "version": 11,
    "popularity": 2727,
    "codepoint": 60019,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "champagne",
      "dining",
      "drink",
      "food",
      "lunch",
      "meal"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "brush",
    "version": 248,
    "popularity": 3715,
    "codepoint": 58286,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "art",
      "brush",
      "design",
      "draw",
      "edit",
      "editing",
      "paint",
      "painting",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "brush",
    "version": 13,
    "popularity": 19946,
    "codepoint": 58286,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "art",
      "brush",
      "design",
      "draw",
      "edit",
      "editing",
      "paint",
      "painting",
      "tool"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bubble",
    "version": 248,
    "popularity": 65,
    "codepoint": 61315,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "bubble",
      "circle",
      "diagonal",
      "direction",
      "right",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bubble_chart",
    "version": 248,
    "popularity": 1280,
    "codepoint": 59101,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "bubble",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bubble_chart",
    "version": 12,
    "popularity": 7580,
    "codepoint": 59101,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "bubble",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bubbles",
    "version": 248,
    "popularity": 11,
    "codepoint": 63054,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "arrow",
      "arrows",
      "close",
      "collapse",
      "direction",
      "down",
      "interface",
      "right",
      "ui",
      "ux",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bug_report",
    "version": 248,
    "popularity": 5253,
    "codepoint": 59496,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "animal",
      "bug",
      "fix",
      "insect",
      "issue",
      "problem",
      "report",
      "testing",
      "virus",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bug_report",
    "version": 12,
    "popularity": 29217,
    "codepoint": 59496,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "animal",
      "bug",
      "fix",
      "insect",
      "issue",
      "problem",
      "report",
      "testing",
      "virus",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "build",
    "version": 248,
    "popularity": 7614,
    "codepoint": 59497,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "adjust",
      "build",
      "fix",
      "home",
      "nest",
      "repair",
      "tool",
      "tools",
      "wrench"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "build",
    "version": 12,
    "popularity": 66057,
    "codepoint": 59497,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "adjust",
      "build",
      "fix",
      "home",
      "nest",
      "repair",
      "tool",
      "tools",
      "wrench"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "build_circle",
    "version": 248,
    "popularity": 2527,
    "codepoint": 61256,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "adjust",
      "build",
      "circle",
      "fix",
      "repair",
      "tool",
      "wrench"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "build_circle",
    "version": 14,
    "popularity": 17102,
    "codepoint": 61256,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "adjust",
      "build",
      "circle",
      "fix",
      "repair",
      "tool",
      "wrench"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "bungalow",
    "version": 248,
    "popularity": 262,
    "codepoint": 58769,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "bungalow",
      "cottage",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bungalow",
    "version": 4,
    "popularity": 2059,
    "codepoint": 58769,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "bungalow",
      "cottage",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "burst_mode",
    "version": 248,
    "popularity": 227,
    "codepoint": 58428,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "burst",
      "image",
      "landscape",
      "mode",
      "mountain",
      "mountains",
      "multiple",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "burst_mode",
    "version": 12,
    "popularity": 1923,
    "codepoint": 58428,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "burst",
      "image",
      "landscape",
      "mode",
      "mountain",
      "mountains",
      "multiple",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "bus_alert",
    "version": 248,
    "popularity": 447,
    "codepoint": 59791,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "automobile",
      "bus",
      "car",
      "cars",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "maps",
      "mark",
      "notification",
      "symbol",
      "transportation",
      "vehicle",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "bus_alert",
    "version": 15,
    "popularity": 2369,
    "codepoint": 59791,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "automobile",
      "bus",
      "car",
      "cars",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "maps",
      "mark",
      "notification",
      "symbol",
      "transportation",
      "vehicle",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "business",
    "version": 12,
    "popularity": 72058,
    "codepoint": 57519,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "apartment",
      "architecture",
      "building",
      "business",
      "company",
      "estate",
      "home",
      "place",
      "real",
      "residence",
      "residential",
      "shelter"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "business_center",
    "version": 248,
    "popularity": 5056,
    "codepoint": 60223,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "center",
      "places",
      "purse",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "business_center",
    "version": 15,
    "popularity": 22894,
    "codepoint": 60223,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "center",
      "places",
      "purse",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "business_chip",
    "version": 248,
    "popularity": 18,
    "codepoint": 63564,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "chip",
      "purse",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "business_messages",
    "version": 248,
    "popularity": 42,
    "codepoint": 61316,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrows",
      "bubble",
      "business",
      "chat",
      "messages",
      "speech",
      "talk",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "buttons_alt",
    "version": 248,
    "popularity": 31,
    "codepoint": 59183,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "UI",
      "action",
      "button",
      "buttons",
      "circle",
      "component",
      "floating",
      "interface",
      "plus"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cabin",
    "version": 248,
    "popularity": 491,
    "codepoint": 58761,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "cabin",
      "camping",
      "cottage",
      "estate",
      "home",
      "house",
      "log",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
      "wood"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cabin",
    "version": 4,
    "popularity": 3301,
    "codepoint": 58761,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "cabin",
      "camping",
      "cottage",
      "estate",
      "home",
      "house",
      "log",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
      "wood"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "cable",
    "version": 248,
    "popularity": 1280,
    "codepoint": 61414,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cable",
      "connect",
      "connection",
      "device",
      "electronics",
      "usb",
      "wire"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cable",
    "version": 10,
    "popularity": 7956,
    "codepoint": 61414,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cable",
      "connect",
      "connection",
      "device",
      "electronics",
      "usb",
      "wire"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cached",
    "version": 248,
    "popularity": 5318,
    "codepoint": 59498,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "around",
      "arrows",
      "cache",
      "cached",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "rotate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cached",
    "version": 13,
    "popularity": 29219,
    "codepoint": 59498,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "around",
      "arrows",
      "cache",
      "cached",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "rotate"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cake",
    "version": 248,
    "popularity": 3781,
    "codepoint": 59369,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "add",
      "baked",
      "birthday",
      "cake",
      "candles",
      "celebration",
      "dessert",
      "food",
      "frosting",
      "new",
      "party",
      "pastries",
      "pastry",
      "plus",
      "social",
      "sweet",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cake",
    "version": 13,
    "popularity": 22801,
    "codepoint": 59369,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "add",
      "baked",
      "birthday",
      "cake",
      "candles",
      "celebration",
      "dessert",
      "food",
      "frosting",
      "new",
      "party",
      "pastries",
      "pastry",
      "plus",
      "social",
      "sweet",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cake_add",
    "version": 248,
    "popularity": 89,
    "codepoint": 63579,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calculate",
    "version": 248,
    "popularity": 7343,
    "codepoint": 59999,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "+",
      "-",
      "=",
      "calculate",
      "count",
      "finance calculator",
      "math"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calculate",
    "version": 11,
    "popularity": 37835,
    "codepoint": 59999,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "+",
      "-",
      "=",
      "calculate",
      "count",
      "finance calculator",
      "math"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "calendar_add_on",
    "version": 248,
    "popularity": 2076,
    "codepoint": 61317,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "+",
      "add",
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "new symbol",
      "plus",
      "schedule",
      "symbol",
      "today"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_apps_script",
    "version": 248,
    "popularity": 313,
    "codepoint": 61627,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "schedule",
      "today"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_clock",
    "version": 248,
    "popularity": 32,
    "codepoint": 62784,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "calendar",
      "clock",
      "date",
      "day",
      "disabled",
      "duration",
      "enabled",
      "event",
      "later",
      "limit",
      "month",
      "notification",
      "schedule",
      "time",
      "timer",
      "today",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_month",
    "version": 248,
    "popularity": 37326,
    "codepoint": 60364,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "schedule",
      "today"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_month",
    "version": 1,
    "popularity": 64427,
    "codepoint": 60364,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "schedule",
      "today"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "calendar_today",
    "version": 248,
    "popularity": 11461,
    "codepoint": 59701,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "schedule",
      "today"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_today",
    "version": 12,
    "popularity": 120849,
    "codepoint": 59701,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "schedule",
      "today"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "calendar_view_day",
    "version": 248,
    "popularity": 651,
    "codepoint": 59702,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view",
      "week"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_view_day",
    "version": 15,
    "popularity": 5967,
    "codepoint": 59702,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view",
      "week"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "calendar_view_month",
    "version": 248,
    "popularity": 951,
    "codepoint": 61415,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_view_month",
    "version": 10,
    "popularity": 12014,
    "codepoint": 61415,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "calendar_view_week",
    "version": 248,
    "popularity": 716,
    "codepoint": 61416,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view",
      "week"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "calendar_view_week",
    "version": 10,
    "popularity": 6909,
    "codepoint": 61416,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "format",
      "grid",
      "layout",
      "month",
      "schedule",
      "today",
      "view",
      "week"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call",
    "version": 248,
    "popularity": 47200,
    "codepoint": 57520,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call",
    "version": 17,
    "popularity": 176321,
    "codepoint": 57520,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_end",
    "version": 248,
    "popularity": 1707,
    "codepoint": 57521,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "end",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_end",
    "version": 16,
    "popularity": 11112,
    "codepoint": 57521,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "end",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_log",
    "version": 248,
    "popularity": 12,
    "codepoint": 57486,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "list",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_made",
    "version": 248,
    "popularity": 1357,
    "codepoint": 57522,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "made",
      "mobile"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_made",
    "version": 13,
    "popularity": 7184,
    "codepoint": 57522,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "made",
      "mobile"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_merge",
    "version": 248,
    "popularity": 391,
    "codepoint": 57523,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "merge",
      "mobile"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_merge",
    "version": 13,
    "popularity": 2798,
    "codepoint": 57523,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "merge",
      "mobile"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_missed",
    "version": 248,
    "popularity": 335,
    "codepoint": 57524,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "missed",
      "mobile"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_missed",
    "version": 13,
    "popularity": 2041,
    "codepoint": 57524,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "missed",
      "mobile"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_missed_outgoing",
    "version": 248,
    "popularity": 361,
    "codepoint": 57572,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "missed",
      "mobile",
      "outgoing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_missed_outgoing",
    "version": 15,
    "popularity": 2312,
    "codepoint": 57572,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "missed",
      "mobile",
      "outgoing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_quality",
    "version": 248,
    "popularity": 16,
    "codepoint": 63058,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "call",
      "cell",
      "contact",
      "details",
      "device",
      "hardware",
      "help",
      "i",
      "info",
      "information",
      "mobile",
      "phone",
      "service",
      "support",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_received",
    "version": 248,
    "popularity": 900,
    "codepoint": 57525,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "mobile",
      "received"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_received",
    "version": 13,
    "popularity": 4511,
    "codepoint": 57525,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "mobile",
      "received"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_split",
    "version": 248,
    "popularity": 871,
    "codepoint": 57526,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "mobile",
      "split"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_split",
    "version": 13,
    "popularity": 6496,
    "codepoint": 57526,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "call",
      "device",
      "mobile",
      "split"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "call_to_action",
    "version": 248,
    "popularity": 538,
    "codepoint": 57452,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "action",
      "alert",
      "bar",
      "call",
      "components",
      "cta",
      "design",
      "info",
      "information",
      "interface",
      "layout",
      "message",
      "notification",
      "screen",
      "site",
      "to",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "call_to_action",
    "version": 11,
    "popularity": 3244,
    "codepoint": 57452,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "action",
      "alert",
      "bar",
      "call",
      "components",
      "cta",
      "design",
      "info",
      "information",
      "interface",
      "layout",
      "message",
      "notification",
      "screen",
      "site",
      "to",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera",
    "version": 248,
    "popularity": 2591,
    "codepoint": 58287,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "aperture",
      "camera",
      "lens",
      "photo",
      "photography",
      "picture",
      "shutter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "camera",
    "version": 12,
    "popularity": 12941,
    "codepoint": 58287,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "aperture",
      "camera",
      "lens",
      "photo",
      "photography",
      "picture",
      "shutter"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_alt",
    "version": 12,
    "popularity": 12117,
    "codepoint": 58288,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alt",
      "camera",
      "image",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_enhance",
    "version": 12,
    "popularity": 7100,
    "codepoint": 59644,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "ai",
      "artificial",
      "automatic",
      "automation",
      "camera",
      "custom",
      "enhance",
      "genai",
      "important",
      "intelligence",
      "lens",
      "magic",
      "photo",
      "photography",
      "picture",
      "quality",
      "smart",
      "spark",
      "sparkle",
      "special",
      "star"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_front",
    "version": 248,
    "popularity": 361,
    "codepoint": 58289,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "body",
      "camera",
      "front",
      "human",
      "lens",
      "mobile",
      "person",
      "phone",
      "photography",
      "portrait",
      "selfie"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "camera_front",
    "version": 12,
    "popularity": 2035,
    "codepoint": 58289,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "body",
      "camera",
      "front",
      "human",
      "lens",
      "mobile",
      "person",
      "phone",
      "photography",
      "portrait",
      "selfie"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_indoor",
    "version": 248,
    "popularity": 393,
    "codepoint": 61417,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "building",
      "camera",
      "estate",
      "film",
      "filming",
      "home",
      "house",
      "image",
      "indoor",
      "inside",
      "motion",
      "nest",
      "picture",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "camera_indoor",
    "version": 9,
    "popularity": 3606,
    "codepoint": 61417,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "architecture",
      "building",
      "camera",
      "estate",
      "film",
      "filming",
      "home",
      "house",
      "image",
      "indoor",
      "inside",
      "motion",
      "nest",
      "picture",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "video",
      "videography"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_outdoor",
    "version": 248,
    "popularity": 306,
    "codepoint": 61418,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "building",
      "camera",
      "estate",
      "film",
      "filming",
      "home",
      "house",
      "image",
      "motion",
      "nest",
      "outdoor",
      "outside",
      "picture",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "camera_outdoor",
    "version": 9,
    "popularity": 3296,
    "codepoint": 61418,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "architecture",
      "building",
      "camera",
      "estate",
      "film",
      "filming",
      "home",
      "house",
      "image",
      "motion",
      "nest",
      "outdoor",
      "outside",
      "picture",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "video",
      "videography"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_rear",
    "version": 248,
    "popularity": 148,
    "codepoint": 58290,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "front",
      "lens",
      "mobile",
      "phone",
      "photo",
      "photography",
      "picture",
      "portrait",
      "rear",
      "selfie"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "camera_rear",
    "version": 13,
    "popularity": 1035,
    "codepoint": 58290,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "front",
      "lens",
      "mobile",
      "phone",
      "photo",
      "photography",
      "picture",
      "portrait",
      "rear",
      "selfie"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_roll",
    "version": 248,
    "popularity": 429,
    "codepoint": 58291,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "film",
      "image",
      "library",
      "photo",
      "photography",
      "roll"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "camera_roll",
    "version": 12,
    "popularity": 1838,
    "codepoint": 58291,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "film",
      "image",
      "library",
      "photo",
      "photography",
      "roll"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camera_video",
    "version": 248,
    "popularity": 29,
    "codepoint": 63398,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "cam",
      "desk",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography",
      "webcam"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cameraswitch",
    "version": 248,
    "popularity": 1068,
    "codepoint": 61419,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrows",
      "camera",
      "cameraswitch",
      "flip",
      "rotate",
      "swap",
      "switch",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cameraswitch",
    "version": 10,
    "popularity": 7723,
    "codepoint": 61419,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "arrows",
      "camera",
      "cameraswitch",
      "flip",
      "rotate",
      "swap",
      "switch",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "campaign",
    "version": 248,
    "popularity": 9610,
    "codepoint": 61257,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "alert",
      "announcement",
      "campaign",
      "loud",
      "megaphone",
      "microphone",
      "notification",
      "speaker"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "campaign",
    "version": 12,
    "popularity": 52804,
    "codepoint": 61257,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "alert",
      "announcement",
      "campaign",
      "loud",
      "megaphone",
      "microphone",
      "notification",
      "speaker"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "camping",
    "version": 248,
    "popularity": 739,
    "codepoint": 63650,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "activity",
      "camp",
      "camping",
      "shelter",
      "tent",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cancel",
    "version": 248,
    "popularity": 39685,
    "codepoint": 58825,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "cancel",
      "circle",
      "clear",
      "close",
      "exit",
      "remove",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cancel",
    "version": 16,
    "popularity": 161563,
    "codepoint": 58825,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "cancel",
      "circle",
      "clear",
      "close",
      "exit",
      "remove",
      "stop",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cancel_presentation",
    "version": 248,
    "popularity": 1147,
    "codepoint": 57577,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "device",
      "exit",
      "no",
      "present",
      "presentation",
      "quit",
      "remove",
      "screen",
      "slide",
      "stop",
      "website",
      "window",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cancel_presentation",
    "version": 14,
    "popularity": 7541,
    "codepoint": 57577,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "device",
      "exit",
      "no",
      "present",
      "presentation",
      "quit",
      "remove",
      "screen",
      "slide",
      "stop",
      "website",
      "window",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cancel_schedule_send",
    "version": 248,
    "popularity": 562,
    "codepoint": 59961,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "cancel",
      "clear",
      "email",
      "mail",
      "no",
      "quit",
      "remove",
      "schedule",
      "send",
      "share",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cancel_schedule_send",
    "version": 12,
    "popularity": 4985,
    "codepoint": 59961,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cancel",
      "clear",
      "email",
      "mail",
      "no",
      "quit",
      "remove",
      "schedule",
      "send",
      "share",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "candle",
    "version": 248,
    "popularity": 3,
    "codepoint": 62856,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "candelabra",
      "candlestick",
      "flame",
      "ideology",
      "light",
      "lighting",
      "religion",
      "spiritual",
      "torch",
      "worship"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "candlestick_chart",
    "version": 248,
    "popularity": 672,
    "codepoint": 60116,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "candlestick",
      "chart",
      "data",
      "diagram",
      "finance",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "candlestick_chart",
    "version": 2,
    "popularity": 2174,
    "codepoint": 60116,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "candlestick",
      "chart",
      "data",
      "diagram",
      "finance",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "captive_portal",
    "version": 248,
    "popularity": 58,
    "codepoint": 63272,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "direction",
      "globe",
      "input",
      "internet",
      "language",
      "left",
      "link",
      "northwest",
      "planet",
      "upload",
      "website",
      "world",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "capture",
    "version": 248,
    "popularity": 31,
    "codepoint": 63271,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "frame",
      "picture in picture",
      "position",
      "rectangle",
      "screengrab",
      "screenshot",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "car_crash",
    "version": 248,
    "popularity": 825,
    "codepoint": 60402,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "accident",
      "automobile",
      "car",
      "cars",
      "collision",
      "crash",
      "direction",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "car_crash",
    "version": 1,
    "popularity": 1966,
    "codepoint": 60402,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "accident",
      "automobile",
      "car",
      "cars",
      "collision",
      "crash",
      "direction",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "car_rental",
    "version": 248,
    "popularity": 658,
    "codepoint": 59989,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "access",
      "automobile",
      "car",
      "cars",
      "entry",
      "key",
      "lock",
      "maps",
      "password",
      "rental",
      "transportation",
      "unlock",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "car_rental",
    "version": 10,
    "popularity": 4183,
    "codepoint": 59989,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "access",
      "automobile",
      "car",
      "cars",
      "entry",
      "key",
      "lock",
      "maps",
      "password",
      "rental",
      "transportation",
      "unlock",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "car_repair",
    "version": 248,
    "popularity": 497,
    "codepoint": 59990,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "maps",
      "repair",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "car_repair",
    "version": 10,
    "popularity": 3438,
    "codepoint": 59990,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "maps",
      "repair",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "car_tag",
    "version": 248,
    "popularity": 14,
    "codepoint": 62691,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "auto",
      "automobile",
      "bill",
      "car",
      "card",
      "cars",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "sell",
      "shopping",
      "tag",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "card_giftcard",
    "version": 14,
    "popularity": 35179,
    "codepoint": 59638,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "balance",
      "bill",
      "card",
      "cart",
      "cash",
      "certificate",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "gift",
      "giftcard",
      "money",
      "online",
      "pay",
      "payment",
      "present",
      "shopping"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "card_membership",
    "version": 248,
    "popularity": 1933,
    "codepoint": 59639,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "bookmark",
      "card",
      "cash",
      "certificate",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "loyalty",
      "membership",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "subscription"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "card_membership",
    "version": 14,
    "popularity": 15479,
    "codepoint": 59639,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "bookmark",
      "card",
      "cash",
      "certificate",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "loyalty",
      "membership",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "subscription"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "card_travel",
    "version": 248,
    "popularity": 859,
    "codepoint": 59640,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "membership",
      "miles",
      "money",
      "online",
      "pay",
      "payment",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "card_travel",
    "version": 13,
    "popularity": 6230,
    "codepoint": 59640,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "membership",
      "miles",
      "money",
      "online",
      "pay",
      "payment",
      "travel",
      "trip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cardiology",
    "version": 248,
    "popularity": 33,
    "codepoint": 57500,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "cardio",
      "health",
      "heart",
      "measure",
      "medical",
      "monitor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cards",
    "version": 248,
    "popularity": 79,
    "codepoint": 59793,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "cards",
      "components",
      "design",
      "grid",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "carpenter",
    "version": 248,
    "popularity": 510,
    "codepoint": 61944,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "building",
      "carpenter",
      "construction",
      "cutting",
      "handyman",
      "repair",
      "saw",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "carpenter",
    "version": 6,
    "popularity": 2951,
    "codepoint": 61944,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "building",
      "carpenter",
      "construction",
      "cutting",
      "handyman",
      "repair",
      "saw",
      "tool"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "carry_on_bag",
    "version": 248,
    "popularity": 3,
    "codepoint": 60168,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bag",
      "carry",
      "luggage",
      "on",
      "suitcase",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "carry_on_bag_checked",
    "version": 248,
    "popularity": 7,
    "codepoint": 60171,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "approve",
      "bag",
      "carry",
      "check",
      "checked",
      "luggage",
      "ok",
      "on",
      "select",
      "suitcase",
      "tick",
      "travel",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "carry_on_bag_inactive",
    "version": 248,
    "popularity": 0,
    "codepoint": 60170,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bag",
      "carry",
      "disabled",
      "enabled",
      "inactive",
      "luggage",
      "off",
      "on",
      "slash",
      "suitcase",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "carry_on_bag_question",
    "version": 248,
    "popularity": 13,
    "codepoint": 60169,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "?",
      "bag",
      "carry",
      "help",
      "info",
      "information",
      "luggage",
      "on",
      "question",
      "question mark",
      "suitcase",
      "support",
      "symbol",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cases",
    "version": 248,
    "popularity": 1082,
    "codepoint": 59794,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "cases",
      "purse",
      "suitcase"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cases",
    "version": 11,
    "popularity": 6270,
    "codepoint": 59794,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "cases",
      "purse",
      "suitcase"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "casino",
    "version": 248,
    "popularity": 1589,
    "codepoint": 60224,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "casino",
      "dice",
      "dots",
      "entertainment",
      "gamble",
      "gambling",
      "game",
      "games",
      "luck",
      "places"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "casino",
    "version": 13,
    "popularity": 8449,
    "codepoint": 60224,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "casino",
      "dice",
      "dots",
      "entertainment",
      "gamble",
      "gambling",
      "game",
      "games",
      "luck",
      "places"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cast",
    "version": 248,
    "popularity": 1468,
    "codepoint": 58119,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cast",
    "version": 18,
    "popularity": 7032,
    "codepoint": 58119,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cast_connected",
    "version": 248,
    "popularity": 553,
    "codepoint": 58120,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "connected",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cast_connected",
    "version": 13,
    "popularity": 3198,
    "codepoint": 58120,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "connected",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cast_for_education",
    "version": 248,
    "popularity": 1281,
    "codepoint": 61420,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "desktop",
      "device",
      "display",
      "education",
      "for",
      "hardware",
      "iOS",
      "learning",
      "lessons teaching",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cast_for_education",
    "version": 14,
    "popularity": 7768,
    "codepoint": 61420,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "desktop",
      "device",
      "display",
      "education",
      "for",
      "hardware",
      "iOS",
      "learning",
      "lessons teaching",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cast_pause",
    "version": 248,
    "popularity": 7,
    "codepoint": 62960,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "chrome",
      "connect",
      "control",
      "controls",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "media",
      "monitor",
      "music",
      "pause",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "video",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cast_warning",
    "version": 248,
    "popularity": 8,
    "codepoint": 62959,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "!",
      "Android",
      "OS",
      "airplay",
      "alert",
      "attention",
      "cast",
      "caution",
      "chrome",
      "connect",
      "danger",
      "desktop",
      "device",
      "display",
      "error",
      "exclamation",
      "hardware",
      "high",
      "iOS",
      "important",
      "mac",
      "mark",
      "monitor",
      "notification",
      "screen",
      "screencast",
      "streaming",
      "symbol",
      "television",
      "tv",
      "warning",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "castle",
    "version": 248,
    "popularity": 673,
    "codepoint": 60081,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "castle",
      "fort",
      "fortress",
      "mansion",
      "palace"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "castle",
    "version": 2,
    "popularity": 2194,
    "codepoint": 60081,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "castle",
      "fort",
      "fortress",
      "mansion",
      "palace"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "catching_pokemon",
    "version": 4,
    "popularity": 7951,
    "codepoint": 58632,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "catching",
      "go",
      "pokemon",
      "pokestop",
      "travel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "category",
    "version": 248,
    "popularity": 8935,
    "codepoint": 58740,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "categories",
      "category",
      "circle",
      "collection",
      "items",
      "product",
      "sort",
      "square",
      "triangle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "category",
    "version": 12,
    "popularity": 54242,
    "codepoint": 58740,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "categories",
      "category",
      "circle",
      "collection",
      "items",
      "product",
      "sort",
      "square",
      "triangle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "celebration",
    "version": 248,
    "popularity": 5199,
    "codepoint": 60005,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "activity",
      "birthday",
      "celebration",
      "event",
      "fun",
      "party"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "celebration",
    "version": 11,
    "popularity": 19187,
    "codepoint": 60005,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "activity",
      "birthday",
      "celebration",
      "event",
      "fun",
      "party"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "cell_merge",
    "version": 248,
    "popularity": 24,
    "codepoint": 63534,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "arrows",
      "cell",
      "cells",
      "combine",
      "inward",
      "merge",
      "sheets",
      "spreadsheet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cell_tower",
    "version": 248,
    "popularity": 1646,
    "codepoint": 60346,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "broadcast",
      "casting",
      "cell",
      "network",
      "signal",
      "tower",
      "transmitting",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cell_tower",
    "version": 1,
    "popularity": 3728,
    "codepoint": 60346,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "broadcast",
      "casting",
      "cell",
      "network",
      "signal",
      "tower",
      "transmitting",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "cell_wifi",
    "version": 248,
    "popularity": 404,
    "codepoint": 57580,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "cell",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cell_wifi",
    "version": 14,
    "popularity": 2251,
    "codepoint": 57580,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "cell",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "center_focus_strong",
    "version": 248,
    "popularity": 1278,
    "codepoint": 58292,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "center",
      "focus",
      "image",
      "lens",
      "photo",
      "photography",
      "strong",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "center_focus_strong",
    "version": 12,
    "popularity": 7003,
    "codepoint": 58292,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "center",
      "focus",
      "image",
      "lens",
      "photo",
      "photography",
      "strong",
      "zoom"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "center_focus_weak",
    "version": 248,
    "popularity": 837,
    "codepoint": 58293,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "center",
      "focus",
      "image",
      "lens",
      "photo",
      "photography",
      "weak",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "center_focus_weak",
    "version": 12,
    "popularity": 4238,
    "codepoint": 58293,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "center",
      "focus",
      "image",
      "lens",
      "photo",
      "photography",
      "weak",
      "zoom"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chair",
    "version": 248,
    "popularity": 2885,
    "codepoint": 61421,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "chair",
      "comfort",
      "couch",
      "decoration",
      "furniture",
      "home",
      "house",
      "living",
      "lounging",
      "loveseat",
      "room",
      "seat",
      "seating",
      "sofa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chair",
    "version": 10,
    "popularity": 14691,
    "codepoint": 61421,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "chair",
      "comfort",
      "couch",
      "decoration",
      "furniture",
      "home",
      "house",
      "living",
      "lounging",
      "loveseat",
      "room",
      "seat",
      "seating",
      "sofa"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chair_alt",
    "version": 248,
    "popularity": 586,
    "codepoint": 61422,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "cahir",
      "furniture",
      "home",
      "house",
      "kitchen",
      "lounging",
      "seating",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chair_alt",
    "version": 9,
    "popularity": 3393,
    "codepoint": 61422,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "cahir",
      "furniture",
      "home",
      "house",
      "kitchen",
      "lounging",
      "seating",
      "table"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chalet",
    "version": 248,
    "popularity": 190,
    "codepoint": 58757,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "chalet",
      "cottage",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chalet",
    "version": 4,
    "popularity": 1859,
    "codepoint": 58757,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "chalet",
      "cottage",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "change_circle",
    "version": 248,
    "popularity": 4977,
    "codepoint": 58087,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "around",
      "arrows",
      "change",
      "circle",
      "direction",
      "navigation",
      "rotate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "change_circle",
    "version": 8,
    "popularity": 19333,
    "codepoint": 58087,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "around",
      "arrows",
      "change",
      "circle",
      "direction",
      "navigation",
      "rotate"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "change_history",
    "version": 248,
    "popularity": 2804,
    "codepoint": 59499,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "change",
      "history",
      "shape",
      "triangle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "change_history",
    "version": 11,
    "popularity": 14303,
    "codepoint": 59499,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "change",
      "history",
      "shape",
      "triangle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "charger",
    "version": 248,
    "popularity": 936,
    "codepoint": 58030,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "adapter",
      "batteries",
      "charger",
      "device",
      "nest",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "charging_station",
    "version": 248,
    "popularity": 484,
    "codepoint": 61853,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "battery",
      "bolt",
      "cell",
      "charging",
      "device",
      "electric",
      "energy",
      "hardware",
      "iOS",
      "instant",
      "lightning",
      "mobile",
      "phone",
      "station",
      "tablet",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "charging_station",
    "version": 8,
    "popularity": 2943,
    "codepoint": 61853,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "Android",
      "OS",
      "battery",
      "bolt",
      "cell",
      "charging",
      "device",
      "electric",
      "energy",
      "hardware",
      "iOS",
      "instant",
      "lightning",
      "mobile",
      "phone",
      "station",
      "tablet",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chart_data",
    "version": 248,
    "popularity": 87,
    "codepoint": 58483,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "graph",
      "health",
      "infographic",
      "line",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "trend",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chat",
    "version": 248,
    "popularity": 17526,
    "codepoint": 57527,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chat",
    "version": 19,
    "popularity": 69677,
    "codepoint": 57527,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chat_add_on",
    "version": 248,
    "popularity": 496,
    "codepoint": 61683,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chat_apps_script",
    "version": 248,
    "popularity": 261,
    "codepoint": 61629,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chat_bubble",
    "version": 248,
    "popularity": 9632,
    "codepoint": 57546,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chat_bubble",
    "version": 15,
    "popularity": 31260,
    "codepoint": 57546,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chat_bubble_outline",
    "version": 18,
    "popularity": 39736,
    "codepoint": 57547,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "outline",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chat_error",
    "version": 248,
    "popularity": 29,
    "codepoint": 63404,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alert",
      "attention",
      "bubble",
      "cancel",
      "caution",
      "chat",
      "clear",
      "close",
      "comment",
      "communicate",
      "danger",
      "error",
      "exit",
      "feedback",
      "important",
      "mark",
      "message",
      "no",
      "notification",
      "problem",
      "remove",
      "report",
      "speech",
      "stop",
      "symbol",
      "warning",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chat_info",
    "version": 248,
    "popularity": 12,
    "codepoint": 62763,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "details",
      "feedback",
      "help",
      "i",
      "info",
      "information",
      "message",
      "service",
      "speech",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chat_paste_go",
    "version": 248,
    "popularity": 26,
    "codepoint": 63165,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "arrows",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "direction",
      "directions",
      "feedback",
      "message",
      "navigation",
      "right",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "check",
    "version": 248,
    "popularity": 27658,
    "codepoint": 58826,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "DISABLE_IOS",
      "check",
      "confirm",
      "correct",
      "disable_ios",
      "done",
      "enter",
      "mark",
      "ok",
      "okay",
      "select",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "check",
    "version": 18,
    "popularity": 103404,
    "codepoint": 58826,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "DISABLE_IOS",
      "check",
      "confirm",
      "correct",
      "disable_ios",
      "done",
      "enter",
      "mark",
      "ok",
      "okay",
      "select",
      "tick",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "check_box",
    "version": 248,
    "popularity": 24484,
    "codepoint": 59444,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approved",
      "box",
      "button",
      "check",
      "component",
      "control",
      "form",
      "mark",
      "ok",
      "select",
      "selected",
      "selection",
      "tick",
      "toggle",
      "ui",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "check_box",
    "version": 16,
    "popularity": 118533,
    "codepoint": 59444,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "approved",
      "box",
      "button",
      "check",
      "component",
      "control",
      "form",
      "mark",
      "ok",
      "select",
      "selected",
      "selection",
      "tick",
      "toggle",
      "ui",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "check_box_outline_blank",
    "version": 248,
    "popularity": 21301,
    "codepoint": 59445,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "blank",
      "box",
      "button",
      "check",
      "component",
      "control",
      "dash",
      "dashed",
      "deselected",
      "empty",
      "form",
      "outline",
      "select",
      "selection",
      "square",
      "tick",
      "toggle",
      "ui"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "check_box_outline_blank",
    "version": 16,
    "popularity": 0,
    "codepoint": 59445,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "blank",
      "box",
      "button",
      "check",
      "component",
      "control",
      "dash",
      "dashed",
      "deselected",
      "empty",
      "form",
      "outline",
      "select",
      "selection",
      "square",
      "tick",
      "toggle",
      "ui"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "check_circle",
    "version": 248,
    "popularity": 80232,
    "codepoint": 59500,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "confirm",
      "done",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "check_circle",
    "version": 19,
    "popularity": 385636,
    "codepoint": 59500,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "confirm",
      "done",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "check_circle_outline",
    "version": 12,
    "popularity": 123847,
    "codepoint": 59693,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "finished",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "check_in_out",
    "version": 248,
    "popularity": 8,
    "codepoint": 63222,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bathhouse",
      "hospitality",
      "hotel",
      "sauna",
      "spa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "check_indeterminate_small",
    "version": 248,
    "popularity": 649,
    "codepoint": 63626,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "finished",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "check_small",
    "version": 248,
    "popularity": 1684,
    "codepoint": 63627,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "finished",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "checkbook",
    "version": 248,
    "popularity": 40,
    "codepoint": 59149,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bank",
      "book",
      "business",
      "check",
      "checkbook",
      "money",
      "payment"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "checked_bag",
    "version": 248,
    "popularity": 10,
    "codepoint": 60172,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bag",
      "check",
      "luggage",
      "suitcase",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "checked_bag_question",
    "version": 248,
    "popularity": 6,
    "codepoint": 60173,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bag",
      "check",
      "luggage",
      "question",
      "suitcase",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "checklist",
    "version": 248,
    "popularity": 6498,
    "codepoint": 59057,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "approve",
      "check",
      "checklist",
      "complete",
      "doc",
      "done",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "mark",
      "notes",
      "ok",
      "select",
      "sheet",
      "spreadsheet",
      "text",
      "tick",
      "type",
      "validate",
      "verified",
      "writing",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "checklist",
    "version": 3,
    "popularity": 35080,
    "codepoint": 59057,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "approve",
      "check",
      "checklist",
      "complete",
      "doc",
      "done",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "mark",
      "notes",
      "ok",
      "select",
      "sheet",
      "spreadsheet",
      "text",
      "tick",
      "type",
      "validate",
      "verified",
      "writing",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "checklist_rtl",
    "version": 248,
    "popularity": 2079,
    "codepoint": 59059,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "approve",
      "check",
      "checklist",
      "complete",
      "doc",
      "done",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "mark",
      "notes",
      "ok",
      "rtl",
      "select",
      "sheet",
      "spreadsheet",
      "text",
      "tick",
      "type",
      "validate",
      "verified",
      "writing",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "checklist_rtl",
    "version": 3,
    "popularity": 13960,
    "codepoint": 59059,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "approve",
      "check",
      "checklist",
      "complete",
      "doc",
      "done",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "mark",
      "notes",
      "ok",
      "rtl",
      "select",
      "sheet",
      "spreadsheet",
      "text",
      "tick",
      "type",
      "validate",
      "verified",
      "writing",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "checkroom",
    "version": 248,
    "popularity": 1918,
    "codepoint": 61854,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "checkroom",
      "closet",
      "clothes",
      "coat check",
      "hanger"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "checkroom",
    "version": 8,
    "popularity": 12499,
    "codepoint": 61854,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "checkroom",
      "closet",
      "clothes",
      "coat check",
      "hanger"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cheer",
    "version": 248,
    "popularity": 26,
    "codepoint": 63144,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "applaud",
      "encourage",
      "hype"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chess",
    "version": 248,
    "popularity": 45,
    "codepoint": 62951,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "board",
      "board game",
      "game",
      "google play",
      "strategy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chevron_left",
    "version": 248,
    "popularity": 19969,
    "codepoint": 58827,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "DISABLE_IOS",
      "arrow",
      "arrows",
      "chevron",
      "direction",
      "disable_ios",
      "left"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chevron_left",
    "version": 16,
    "popularity": 121318,
    "codepoint": 58827,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "DISABLE_IOS",
      "arrow",
      "arrows",
      "chevron",
      "direction",
      "disable_ios",
      "left"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chevron_right",
    "version": 248,
    "popularity": 50660,
    "codepoint": 58828,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "direction",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chevron_right",
    "version": 16,
    "popularity": 211002,
    "codepoint": 58828,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "direction",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "child_care",
    "version": 248,
    "popularity": 2351,
    "codepoint": 60225,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "babies",
      "baby",
      "care",
      "child",
      "children",
      "face",
      "infant",
      "kids",
      "newborn",
      "toddler",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "child_care",
    "version": 12,
    "popularity": 10238,
    "codepoint": 60225,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "babies",
      "baby",
      "care",
      "child",
      "children",
      "face",
      "infant",
      "kids",
      "newborn",
      "toddler",
      "young"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "child_friendly",
    "version": 248,
    "popularity": 565,
    "codepoint": 60226,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "friendly",
      "infant",
      "kid",
      "newborn",
      "stroller",
      "toddler",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "child_friendly",
    "version": 12,
    "popularity": 6091,
    "codepoint": 60226,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "friendly",
      "infant",
      "kid",
      "newborn",
      "stroller",
      "toddler",
      "young"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chip_extraction",
    "version": 248,
    "popularity": 20,
    "codepoint": 63521,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "data",
      "direction",
      "east",
      "extract",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chips",
    "version": 248,
    "popularity": 14,
    "codepoint": 59795,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "action",
      "app",
      "application",
      "attribute",
      "buttons",
      "chips",
      "components",
      "design",
      "input",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chrome_reader_mode",
    "version": 248,
    "popularity": 931,
    "codepoint": 59501,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "chrome",
      "mode",
      "read",
      "reader",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chrome_reader_mode",
    "version": 12,
    "popularity": 5928,
    "codepoint": 59501,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "chrome",
      "mode",
      "read",
      "reader",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "chromecast_2",
    "version": 248,
    "popularity": 188,
    "codepoint": 61819,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "2",
      "airplay",
      "cast",
      "chromecast",
      "connect",
      "device",
      "google",
      "hardware",
      "nest",
      "screencast",
      "streaming",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chromecast_device",
    "version": 248,
    "popularity": 204,
    "codepoint": 59452,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "IoT",
      "airplay",
      "cast",
      "chromecast",
      "connect",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "screencast",
      "smart",
      "streaming",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "chronic",
    "version": 248,
    "popularity": 22,
    "codepoint": 60338,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alarm",
      "alert",
      "clock",
      "duration",
      "health",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "church",
    "version": 248,
    "popularity": 1163,
    "codepoint": 60078,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "christian",
      "christianity",
      "ideology",
      "religion",
      "spiritual",
      "worship"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "church",
    "version": 2,
    "popularity": 0,
    "codepoint": 60078,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "christian",
      "christianity",
      "ideology",
      "religion",
      "spiritual",
      "worship"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "cinematic_blur",
    "version": 248,
    "popularity": 33,
    "codepoint": 63571,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "account",
      "blur",
      "cinema",
      "human",
      "movie",
      "person",
      "profile",
      "user",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "circle",
    "version": 248,
    "popularity": 7861,
    "codepoint": 61258,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "circle",
      "full",
      "geometry",
      "moon"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "circle",
    "version": 11,
    "popularity": 55624,
    "codepoint": 61258,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "circle",
      "full",
      "geometry",
      "moon"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "circle_notifications",
    "version": 248,
    "popularity": 2358,
    "codepoint": 59796,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "circle",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "circle_notifications",
    "version": 11,
    "popularity": 0,
    "codepoint": 59796,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "circle",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "circles",
    "version": 248,
    "popularity": 12,
    "codepoint": 59370,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "circles",
      "drive"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "circles_ext",
    "version": 248,
    "popularity": 32,
    "codepoint": 59372,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "circles",
      "exit",
      "ext"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clarify",
    "version": 248,
    "popularity": 229,
    "codepoint": 61631,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "class",
    "version": 11,
    "popularity": 13796,
    "codepoint": 59502,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "archive",
      "book",
      "bookmark",
      "class",
      "favorite",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "clean_hands",
    "version": 248,
    "popularity": 925,
    "codepoint": 61983,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bacteria",
      "clean",
      "disinfect",
      "germs",
      "gesture",
      "hand",
      "hands",
      "sanitize",
      "sanitizer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clean_hands",
    "version": 8,
    "popularity": 4862,
    "codepoint": 61983,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bacteria",
      "clean",
      "disinfect",
      "germs",
      "gesture",
      "hand",
      "hands",
      "sanitize",
      "sanitizer"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cleaning",
    "version": 248,
    "popularity": 12,
    "codepoint": 59797,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bleach",
      "bottle",
      "cleaner",
      "cleaning",
      "home",
      "household",
      "liquid",
      "sanitize",
      "spray"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cleaning_bucket",
    "version": 248,
    "popularity": 370,
    "codepoint": 63668,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "bucket",
      "cleaning",
      "home"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cleaning_services",
    "version": 248,
    "popularity": 1811,
    "codepoint": 61695,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "clean",
      "cleaning",
      "dust",
      "services",
      "sweep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cleaning_services",
    "version": 12,
    "popularity": 13517,
    "codepoint": 61695,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "clean",
      "cleaning",
      "dust",
      "services",
      "sweep"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "clear",
    "version": 12,
    "popularity": 102222,
    "codepoint": 57676,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "back",
      "cancel",
      "clear",
      "correct",
      "delete",
      "erase",
      "exit",
      "remove",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "clear_all",
    "version": 248,
    "popularity": 2432,
    "codepoint": 57528,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "clear",
      "doc",
      "document",
      "format",
      "lines",
      "list"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clear_all",
    "version": 12,
    "popularity": 10574,
    "codepoint": 57528,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "all",
      "clear",
      "doc",
      "document",
      "format",
      "lines",
      "list"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "clear_day",
    "version": 248,
    "popularity": 1503,
    "codepoint": 61783,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "clear",
      "climate",
      "home",
      "hot",
      "nest",
      "summer",
      "sun",
      "sunny",
      "temperature",
      "thermostat",
      "warm",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clear_night",
    "version": 248,
    "popularity": 1040,
    "codepoint": 61785,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bedtime",
      "clear",
      "climate",
      "home",
      "lunar",
      "moon",
      "nest",
      "night",
      "thermostat",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "climate_mini_split",
    "version": 248,
    "popularity": 145,
    "codepoint": 63669,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "home",
      "mini",
      "split"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clinical_notes",
    "version": 248,
    "popularity": 51,
    "codepoint": 57502,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "clinic",
      "data",
      "doc",
      "doctor",
      "document",
      "file",
      "health",
      "human",
      "medic",
      "medical",
      "note",
      "nurse",
      "page",
      "paper",
      "people",
      "person",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clock_loader_10",
    "version": 248,
    "popularity": 59,
    "codepoint": 63270,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clock_loader_20",
    "version": 248,
    "popularity": 27,
    "codepoint": 63269,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clock_loader_40",
    "version": 248,
    "popularity": 45,
    "codepoint": 63268,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clock_loader_60",
    "version": 248,
    "popularity": 85,
    "codepoint": 63267,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clock_loader_80",
    "version": 248,
    "popularity": 39,
    "codepoint": 63266,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "clock_loader_90",
    "version": 248,
    "popularity": 35,
    "codepoint": 63265,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "download",
      "inprogress",
      "load",
      "loading",
      "percent",
      "percentage",
      "progress",
      "rotate",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "close",
    "version": 248,
    "popularity": 131451,
    "codepoint": 58829,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "exit",
      "remove",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "close",
    "version": 19,
    "popularity": 407081,
    "codepoint": 58829,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "exit",
      "remove",
      "stop",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "close_fullscreen",
    "version": 248,
    "popularity": 4260,
    "codepoint": 61903,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "action",
      "arrow",
      "arrows",
      "close",
      "collapse",
      "direction",
      "full",
      "fullscreen",
      "minimize",
      "screen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "close_fullscreen",
    "version": 7,
    "popularity": 22130,
    "codepoint": 61903,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "action",
      "arrow",
      "arrows",
      "close",
      "collapse",
      "direction",
      "full",
      "fullscreen",
      "minimize",
      "screen"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "close_small",
    "version": 248,
    "popularity": 12,
    "codepoint": 62728,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "exit",
      "remove",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "closed_caption",
    "version": 248,
    "popularity": 1193,
    "codepoint": 57372,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "accessible",
      "alphabet",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "subtitle",
      "subtitles",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "closed_caption",
    "version": 12,
    "popularity": 5188,
    "codepoint": 57372,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "accessible",
      "alphabet",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "subtitle",
      "subtitles",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "closed_caption_disabled",
    "version": 248,
    "popularity": 291,
    "codepoint": 61916,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "accessible",
      "alphabet",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "disabled",
      "enabled",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "off",
      "on",
      "slash",
      "subtitle",
      "subtitles",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "closed_caption_disabled",
    "version": 8,
    "popularity": 1555,
    "codepoint": 61916,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "accessible",
      "alphabet",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "disabled",
      "enabled",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "off",
      "on",
      "slash",
      "subtitle",
      "subtitles",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "closed_caption_off",
    "version": 11,
    "popularity": 2949,
    "codepoint": 59798,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "accessible",
      "alphabet",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "off",
      "outline",
      "subtitle",
      "subtitles",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloud",
    "version": 248,
    "popularity": 5765,
    "codepoint": 58045,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "climate",
      "cloud",
      "connection",
      "internet",
      "network",
      "queue",
      "sky",
      "temperature",
      "upload",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloud",
    "version": 12,
    "popularity": 30497,
    "codepoint": 58045,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "climate",
      "cloud",
      "connection",
      "internet",
      "network",
      "queue",
      "sky",
      "temperature",
      "upload",
      "weather"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloud_circle",
    "version": 248,
    "popularity": 548,
    "codepoint": 58046,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application",
      "backup",
      "circle",
      "cloud",
      "connection",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloud_circle",
    "version": 12,
    "popularity": 4177,
    "codepoint": 58046,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "app",
      "application",
      "backup",
      "circle",
      "cloud",
      "connection",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloud_done",
    "version": 248,
    "popularity": 1854,
    "codepoint": 58047,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application",
      "approve",
      "backup",
      "check",
      "cloud",
      "complete",
      "connection",
      "done",
      "drive",
      "files",
      "folders",
      "internet",
      "mark",
      "network",
      "ok",
      "select",
      "sky",
      "storage",
      "tick",
      "upload",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloud_done",
    "version": 12,
    "popularity": 11393,
    "codepoint": 58047,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "app",
      "application",
      "approve",
      "backup",
      "check",
      "cloud",
      "complete",
      "connection",
      "done",
      "drive",
      "files",
      "folders",
      "internet",
      "mark",
      "network",
      "ok",
      "select",
      "sky",
      "storage",
      "tick",
      "upload",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloud_download",
    "version": 248,
    "popularity": 3796,
    "codepoint": 58048,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "backup",
      "cloud",
      "connection",
      "down",
      "download",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloud_download",
    "version": 12,
    "popularity": 28531,
    "codepoint": 58048,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "backup",
      "cloud",
      "connection",
      "down",
      "download",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloud_off",
    "version": 248,
    "popularity": 1415,
    "codepoint": 58049,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application",
      "backup",
      "cloud",
      "connection",
      "disabled",
      "drive",
      "enabled",
      "files",
      "folders",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "sky",
      "slash",
      "storage",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloud_off",
    "version": 16,
    "popularity": 8880,
    "codepoint": 58049,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "app",
      "application",
      "backup",
      "cloud",
      "connection",
      "disabled",
      "drive",
      "enabled",
      "files",
      "folders",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "sky",
      "slash",
      "storage",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloud_queue",
    "version": 12,
    "popularity": 10391,
    "codepoint": 58050,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "climate",
      "cloud",
      "connection",
      "internet",
      "network",
      "queue",
      "sky",
      "temperature",
      "upload",
      "weather"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloud_sync",
    "version": 248,
    "popularity": 1928,
    "codepoint": 60250,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application",
      "around",
      "backup",
      "cloud",
      "connection",
      "drive",
      "files",
      "folders",
      "inprogress",
      "internet",
      "load",
      "loading refresh",
      "network",
      "renew",
      "rotate",
      "sky",
      "storage",
      "turn",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloud_sync",
    "version": 1,
    "popularity": 6481,
    "codepoint": 60250,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "app",
      "application",
      "around",
      "backup",
      "cloud",
      "connection",
      "drive",
      "files",
      "folders",
      "inprogress",
      "internet",
      "load",
      "loading refresh",
      "network",
      "renew",
      "rotate",
      "sky",
      "storage",
      "turn",
      "upload"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "cloud_upload",
    "version": 248,
    "popularity": 6125,
    "codepoint": 58051,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "backup",
      "cloud",
      "connection",
      "download",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloud_upload",
    "version": 12,
    "popularity": 37498,
    "codepoint": 58051,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "backup",
      "cloud",
      "connection",
      "download",
      "drive",
      "files",
      "folders",
      "internet",
      "network",
      "sky",
      "storage",
      "up",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cloudy_snowing",
    "version": 248,
    "popularity": 1440,
    "codepoint": 59408,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cold",
      "snow",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cloudy_snowing",
    "version": 2,
    "popularity": 1351,
    "codepoint": 59408,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "climate",
      "cloud",
      "cold",
      "snow",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "co2",
    "version": 248,
    "popularity": 1040,
    "codepoint": 59312,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "carbon",
      "chemical",
      "co2",
      "dioxide",
      "gas"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "co2",
    "version": 3,
    "popularity": 3370,
    "codepoint": 59312,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "carbon",
      "chemical",
      "co2",
      "dioxide",
      "gas"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "co_present",
    "version": 248,
    "popularity": 1810,
    "codepoint": 60144,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "co-present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "togather",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "co_present",
    "version": 1,
    "popularity": 4799,
    "codepoint": 60144,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "co-present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "togather",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "code",
    "version": 248,
    "popularity": 7863,
    "codepoint": 59503,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "html",
      "platform"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "code",
    "version": 11,
    "popularity": 56405,
    "codepoint": 59503,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "html",
      "platform"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "code_blocks",
    "version": 248,
    "popularity": 305,
    "codepoint": 63565,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "block",
      "blocks",
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "html",
      "platform"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "code_off",
    "version": 248,
    "popularity": 604,
    "codepoint": 58611,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "disabled",
      "enabled",
      "engineer",
      "engineering",
      "html",
      "off",
      "on",
      "platform",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "code_off",
    "version": 4,
    "popularity": 4409,
    "codepoint": 58611,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "brackets",
      "code",
      "css",
      "develop",
      "developer",
      "disabled",
      "enabled",
      "engineer",
      "engineering",
      "html",
      "off",
      "on",
      "platform",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "coffee",
    "version": 248,
    "popularity": 2522,
    "codepoint": 61423,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "beverage",
      "coffee",
      "cup",
      "drink",
      "mug",
      "plate",
      "set",
      "tea"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "coffee",
    "version": 9,
    "popularity": 10229,
    "codepoint": 61423,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "beverage",
      "coffee",
      "cup",
      "drink",
      "mug",
      "plate",
      "set",
      "tea"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "coffee_maker",
    "version": 248,
    "popularity": 804,
    "codepoint": 61424,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliances",
      "beverage",
      "coffee",
      "cup",
      "drink",
      "machine",
      "maker",
      "mug"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "coffee_maker",
    "version": 9,
    "popularity": 4281,
    "codepoint": 61424,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "appliances",
      "beverage",
      "coffee",
      "cup",
      "drink",
      "machine",
      "maker",
      "mug"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cognition",
    "version": 248,
    "popularity": 175,
    "codepoint": 57503,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "brain",
      "head",
      "health",
      "human",
      "idea",
      "ideas",
      "people",
      "person",
      "psychology",
      "thought",
      "thoughts"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "collapse_all",
    "version": 248,
    "popularity": 199,
    "codepoint": 59716,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "arrows",
      "cell",
      "close",
      "collapse",
      "directions",
      "expand",
      "list",
      "minimize"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "collapse_content",
    "version": 248,
    "popularity": 22,
    "codepoint": 62727,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "expansion",
      "list",
      "more",
      "navigation",
      "open"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "collections",
    "version": 12,
    "popularity": 36515,
    "codepoint": 58294,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "album",
      "collections",
      "gallery",
      "image",
      "landscape",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "stack"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "collections_bookmark",
    "version": 248,
    "popularity": 3011,
    "codepoint": 58417,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "album",
      "archive",
      "bookmark",
      "collections",
      "favorite",
      "gallery",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "stack",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "collections_bookmark",
    "version": 12,
    "popularity": 9119,
    "codepoint": 58417,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "album",
      "archive",
      "bookmark",
      "collections",
      "favorite",
      "gallery",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "stack",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "color_lens",
    "version": 16,
    "popularity": 10147,
    "codepoint": 58295,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "art",
      "color",
      "lens",
      "paint",
      "pallet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "colorize",
    "version": 248,
    "popularity": 1156,
    "codepoint": 58296,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "color",
      "colorize",
      "dropper",
      "extract",
      "eye",
      "picker",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "colorize",
    "version": 12,
    "popularity": 6216,
    "codepoint": 58296,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "color",
      "colorize",
      "dropper",
      "extract",
      "eye",
      "picker",
      "tool"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "colors",
    "version": 248,
    "popularity": 82,
    "codepoint": 59799,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bucket",
      "color",
      "doc",
      "drop",
      "edit",
      "editing",
      "editor",
      "fill",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "comedy_mask",
    "version": 248,
    "popularity": 1,
    "codepoint": 62678,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "anonymous",
      "broadway",
      "carnival",
      "comedy",
      "event",
      "masks",
      "movie",
      "musical",
      "places",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "show",
      "standup",
      "theater",
      "tour",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "comic_bubble",
    "version": 248,
    "popularity": 9,
    "codepoint": 62941,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "animation",
      "anime",
      "cartoons",
      "comic book",
      "comic strip",
      "comics",
      "google play",
      "graphic novels",
      "speech bubble"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "comment",
    "version": 248,
    "popularity": 6336,
    "codepoint": 57529,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "outline",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "comment",
    "version": 19,
    "popularity": 23587,
    "codepoint": 57529,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "outline",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "comment_bank",
    "version": 248,
    "popularity": 497,
    "codepoint": 59982,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "archive",
      "bank",
      "bookmark",
      "bubble",
      "cchat",
      "comment",
      "communicate",
      "favorite",
      "label",
      "library",
      "message",
      "remember",
      "ribbon",
      "save",
      "speech",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "comment_bank",
    "version": 15,
    "popularity": 4891,
    "codepoint": 59982,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "archive",
      "bank",
      "bookmark",
      "bubble",
      "cchat",
      "comment",
      "communicate",
      "favorite",
      "label",
      "library",
      "message",
      "remember",
      "ribbon",
      "save",
      "speech",
      "tag"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "comments_disabled",
    "version": 248,
    "popularity": 451,
    "codepoint": 59298,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "comments",
      "communicate",
      "disabled",
      "enabled",
      "feedback",
      "message",
      "off",
      "offline",
      "on",
      "slash",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "comments_disabled",
    "version": 3,
    "popularity": 1806,
    "codepoint": 59298,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "comments",
      "communicate",
      "disabled",
      "enabled",
      "feedback",
      "message",
      "off",
      "offline",
      "on",
      "slash",
      "speech"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "commit",
    "version": 248,
    "popularity": 576,
    "codepoint": 60149,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "accomplish",
      "bind",
      "circle",
      "commit",
      "dedicate",
      "execute",
      "line",
      "perform",
      "pledge"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "commit",
    "version": 1,
    "popularity": 2342,
    "codepoint": 60149,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accomplish",
      "bind",
      "circle",
      "commit",
      "dedicate",
      "execute",
      "line",
      "perform",
      "pledge"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "communication",
    "version": 248,
    "popularity": 1931,
    "codepoint": 57980,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "communication",
      "feedback",
      "message",
      "nest",
      "people",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "communities",
    "version": 248,
    "popularity": 180,
    "codepoint": 59373,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "circle",
      "communities",
      "community",
      "group",
      "groups",
      "network",
      "people",
      "share",
      "team"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "commute",
    "version": 248,
    "popularity": 938,
    "codepoint": 59712,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "commute",
      "direction",
      "maps",
      "public",
      "train",
      "transportation",
      "trip",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "commute",
    "version": 12,
    "popularity": 10313,
    "codepoint": 59712,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "automobile",
      "car",
      "commute",
      "direction",
      "maps",
      "public",
      "train",
      "transportation",
      "trip",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "compare",
    "version": 248,
    "popularity": 1235,
    "codepoint": 58297,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "adjustment",
      "compare",
      "edit",
      "editing",
      "edits",
      "enhance",
      "fix",
      "image",
      "images",
      "photo",
      "photography",
      "photos",
      "scan",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "compare",
    "version": 12,
    "popularity": 7640,
    "codepoint": 58297,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustment",
      "compare",
      "edit",
      "editing",
      "edits",
      "enhance",
      "fix",
      "image",
      "images",
      "photo",
      "photography",
      "photos",
      "scan",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "compare_arrows",
    "version": 248,
    "popularity": 3343,
    "codepoint": 59669,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "collide",
      "compare",
      "direction",
      "left",
      "pressure",
      "push",
      "right",
      "together"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "compare_arrows",
    "version": 14,
    "popularity": 19586,
    "codepoint": 59669,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "collide",
      "compare",
      "direction",
      "left",
      "pressure",
      "push",
      "right",
      "together"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "compass_calibration",
    "version": 248,
    "popularity": 269,
    "codepoint": 58748,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "calibration",
      "compass",
      "connection",
      "internet",
      "location",
      "maps",
      "network",
      "refresh",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "compass_calibration",
    "version": 12,
    "popularity": 1847,
    "codepoint": 58748,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "calibration",
      "compass",
      "connection",
      "internet",
      "location",
      "maps",
      "network",
      "refresh",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "component_exchange",
    "version": 248,
    "popularity": 1526,
    "codepoint": 61927,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "compost",
    "version": 248,
    "popularity": 3106,
    "codepoint": 59233,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bio",
      "compost",
      "compostable",
      "decomposable",
      "decompose",
      "eco",
      "green",
      "leaf",
      "leafs",
      "nature",
      "organic",
      "plant",
      "recycle",
      "sustainability",
      "sustainable"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "compost",
    "version": 3,
    "popularity": 8236,
    "codepoint": 59233,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bio",
      "compost",
      "compostable",
      "decomposable",
      "decompose",
      "eco",
      "green",
      "leaf",
      "leafs",
      "nature",
      "organic",
      "plant",
      "recycle",
      "sustainability",
      "sustainable"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "compress",
    "version": 248,
    "popularity": 1150,
    "codepoint": 59725,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "collide",
      "compress",
      "pressure",
      "push",
      "together"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "compress",
    "version": 10,
    "popularity": 5947,
    "codepoint": 59725,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "collide",
      "compress",
      "pressure",
      "push",
      "together"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "computer",
    "version": 248,
    "popularity": 8195,
    "codepoint": 58122,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "computer",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "computer",
    "version": 12,
    "popularity": 32551,
    "codepoint": 58122,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "computer",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "concierge",
    "version": 248,
    "popularity": 12,
    "codepoint": 62817,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "alert",
      "bell",
      "delivery",
      "fingers",
      "gesture",
      "hand",
      "hotel",
      "local",
      "notify",
      "room",
      "service",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "conditions",
    "version": 248,
    "popularity": 42,
    "codepoint": 57504,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "discover",
      "explore",
      "find",
      "glass",
      "health",
      "human",
      "look",
      "magnify",
      "magnifying",
      "people",
      "person",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "confirmation_number",
    "version": 248,
    "popularity": 4607,
    "codepoint": 58936,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "admission",
      "confirmation",
      "entertainment",
      "event",
      "number",
      "ticket"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "confirmation_number",
    "version": 15,
    "popularity": 23606,
    "codepoint": 58936,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "admission",
      "confirmation",
      "entertainment",
      "event",
      "number",
      "ticket"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "congenital",
    "version": 248,
    "popularity": 8,
    "codepoint": 57505,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "cleft",
      "disease",
      "health",
      "human",
      "lip",
      "lips",
      "mouth",
      "palate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "connect_without_contact",
    "version": 248,
    "popularity": 2033,
    "codepoint": 61987,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "communicating",
      "connect",
      "contact",
      "distance",
      "people",
      "signal",
      "social",
      "socialize",
      "without"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "connect_without_contact",
    "version": 7,
    "popularity": 11177,
    "codepoint": 61987,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "communicating",
      "connect",
      "contact",
      "distance",
      "people",
      "signal",
      "social",
      "socialize",
      "without"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "connected_tv",
    "version": 248,
    "popularity": 740,
    "codepoint": 59800,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "chrome",
      "connect",
      "connected",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "connected_tv",
    "version": 11,
    "popularity": 3552,
    "codepoint": 59800,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "chrome",
      "connect",
      "connected",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screencast",
      "streaming",
      "television",
      "tv",
      "web",
      "window",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "connecting_airports",
    "version": 248,
    "popularity": 830,
    "codepoint": 59337,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "airplane",
      "airplanes",
      "airport",
      "airports",
      "connecting",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "connecting_airports",
    "version": 2,
    "popularity": 2468,
    "codepoint": 59337,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "airplane",
      "airplanes",
      "airport",
      "airports",
      "connecting",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "construction",
    "version": 248,
    "popularity": 8683,
    "codepoint": 59964,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "build",
      "carpenter",
      "construction",
      "equipment",
      "fix",
      "hammer",
      "improvement",
      "industrial",
      "industry",
      "repair",
      "tools",
      "wrench"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "construction",
    "version": 11,
    "popularity": 47660,
    "codepoint": 59964,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "build",
      "carpenter",
      "construction",
      "equipment",
      "fix",
      "hammer",
      "improvement",
      "industrial",
      "industry",
      "repair",
      "tools",
      "wrench"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "contact_emergency",
    "version": 248,
    "popularity": 691,
    "codepoint": 63697,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "account",
      "avatar",
      "call",
      "cell",
      "contacts",
      "face",
      "human",
      "info",
      "information",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contact_emergency",
    "version": 1,
    "popularity": 1920,
    "codepoint": 63697,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "account",
      "avatar",
      "call",
      "cell",
      "contacts",
      "face",
      "human",
      "info",
      "information",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "contact_mail",
    "version": 248,
    "popularity": 4662,
    "codepoint": 57552,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "account",
      "address",
      "avatar",
      "communicate",
      "contact",
      "email",
      "face",
      "human",
      "info",
      "information",
      "mail",
      "message",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contact_mail",
    "version": 13,
    "popularity": 28514,
    "codepoint": 57552,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "account",
      "address",
      "avatar",
      "communicate",
      "contact",
      "email",
      "face",
      "human",
      "info",
      "information",
      "mail",
      "message",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "contact_page",
    "version": 248,
    "popularity": 3504,
    "codepoint": 61998,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "account",
      "avatar",
      "contact",
      "data",
      "doc",
      "document",
      "drive",
      "face",
      "file",
      "folder",
      "folders",
      "human",
      "page",
      "people",
      "person",
      "profile",
      "sheet",
      "slide",
      "storage",
      "user",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contact_page",
    "version": 6,
    "popularity": 29369,
    "codepoint": 61998,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "avatar",
      "contact",
      "data",
      "doc",
      "document",
      "drive",
      "face",
      "file",
      "folder",
      "folders",
      "human",
      "page",
      "people",
      "person",
      "profile",
      "sheet",
      "slide",
      "storage",
      "user",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "contact_phone",
    "version": 248,
    "popularity": 2753,
    "codepoint": 57551,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "account",
      "avatar",
      "call",
      "communicate",
      "contact",
      "face",
      "human",
      "info",
      "information",
      "message",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contact_phone",
    "version": 13,
    "popularity": 17875,
    "codepoint": 57551,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "account",
      "avatar",
      "call",
      "communicate",
      "contact",
      "face",
      "human",
      "info",
      "information",
      "message",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "contact_support",
    "version": 248,
    "popularity": 9525,
    "codepoint": 59724,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "?",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "contact",
      "help",
      "info",
      "information",
      "mark",
      "message",
      "punctuation",
      "question",
      "question mark",
      "speech",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contact_support",
    "version": 13,
    "popularity": 54678,
    "codepoint": 59724,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "?",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "contact",
      "help",
      "info",
      "information",
      "mark",
      "message",
      "punctuation",
      "question",
      "question mark",
      "speech",
      "support",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "contactless",
    "version": 248,
    "popularity": 1250,
    "codepoint": 60017,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bluetooth",
      "cash",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "contactless",
      "credit",
      "device",
      "finance",
      "pay",
      "payment",
      "signal",
      "transaction",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contactless",
    "version": 11,
    "popularity": 8557,
    "codepoint": 60017,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bluetooth",
      "cash",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "contactless",
      "credit",
      "device",
      "finance",
      "pay",
      "payment",
      "signal",
      "transaction",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "contactless_off",
    "version": 248,
    "popularity": 83,
    "codepoint": 63576,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bluetooth",
      "cash",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "contactless",
      "credit",
      "device",
      "disable",
      "disabled",
      "enabled",
      "finance",
      "off",
      "offline",
      "on",
      "pay",
      "payment",
      "signal",
      "slash",
      "transaction",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contacts",
    "version": 248,
    "popularity": 4703,
    "codepoint": 57530,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "account",
      "avatar",
      "call",
      "cell",
      "contacts",
      "face",
      "human",
      "info",
      "information",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contacts",
    "version": 11,
    "popularity": 23783,
    "codepoint": 57530,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "account",
      "avatar",
      "call",
      "cell",
      "contacts",
      "face",
      "human",
      "info",
      "information",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "contacts_product",
    "version": 248,
    "popularity": 177,
    "codepoint": 59801,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "contacts",
      "logo",
      "product"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "content_copy",
    "version": 248,
    "popularity": 19228,
    "codepoint": 57677,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "paste",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "content_copy",
    "version": 17,
    "popularity": 128151,
    "codepoint": 57677,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "paste",
      "stack"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "content_cut",
    "version": 248,
    "popularity": 1731,
    "codepoint": 57678,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "paste",
      "scissors",
      "trim"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "content_cut",
    "version": 17,
    "popularity": 16120,
    "codepoint": 57678,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "paste",
      "scissors",
      "trim"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "content_paste",
    "version": 248,
    "popularity": 3345,
    "codepoint": 57679,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clipboard",
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "multiple",
      "paste"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "content_paste",
    "version": 14,
    "popularity": 30853,
    "codepoint": 57679,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "clipboard",
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "multiple",
      "paste"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "content_paste_go",
    "version": 248,
    "popularity": 587,
    "codepoint": 60046,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clipboard",
      "content",
      "disabled",
      "doc",
      "document",
      "enabled",
      "file",
      "go",
      "on",
      "paste",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "content_paste_go",
    "version": 2,
    "popularity": 4217,
    "codepoint": 60046,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "clipboard",
      "content",
      "disabled",
      "doc",
      "document",
      "enabled",
      "file",
      "go",
      "on",
      "paste",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "content_paste_off",
    "version": 248,
    "popularity": 278,
    "codepoint": 58616,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clipboard",
      "content",
      "disabled",
      "doc",
      "document",
      "enabled",
      "file",
      "off",
      "on",
      "paste",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "content_paste_off",
    "version": 4,
    "popularity": 2963,
    "codepoint": 58616,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "clipboard",
      "content",
      "disabled",
      "doc",
      "document",
      "enabled",
      "file",
      "off",
      "on",
      "paste",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "content_paste_search",
    "version": 248,
    "popularity": 1586,
    "codepoint": 60059,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clipboard",
      "content",
      "doc",
      "document",
      "file",
      "find",
      "paste",
      "search",
      "trace",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "content_paste_search",
    "version": 2,
    "popularity": 7870,
    "codepoint": 60059,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "clipboard",
      "content",
      "doc",
      "document",
      "file",
      "find",
      "paste",
      "search",
      "trace",
      "track"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "contract",
    "version": 248,
    "popularity": 44,
    "codepoint": 62880,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "document",
      "list",
      "page",
      "paper",
      "paperwork",
      "record",
      "transaction"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contract_delete",
    "version": 248,
    "popularity": 12,
    "codepoint": 62882,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "doc",
      "document",
      "exit",
      "list",
      "no",
      "page",
      "paper",
      "paperwork",
      "quit",
      "record",
      "remove",
      "stop",
      "transaction",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contract_edit",
    "version": 248,
    "popularity": 46,
    "codepoint": 62881,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compose",
      "create",
      "doc",
      "document",
      "draft",
      "editing",
      "input",
      "list",
      "modify",
      "page",
      "paper",
      "paperwork",
      "pen",
      "pencil",
      "record",
      "transaction",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contrast",
    "version": 248,
    "popularity": 1259,
    "codepoint": 60215,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "black",
      "contrast",
      "dark theme",
      "edit",
      "editing",
      "effect",
      "filter",
      "grayscale",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "settings",
      "white"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "contrast",
    "version": 1,
    "popularity": 3050,
    "codepoint": 60215,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "black",
      "contrast",
      "dark theme",
      "edit",
      "editing",
      "effect",
      "filter",
      "grayscale",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "settings",
      "white"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "contrast_rtl_off",
    "version": 248,
    "popularity": 2,
    "codepoint": 60530,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "black",
      "contrast",
      "disabled",
      "edit",
      "editing",
      "effect",
      "enabled",
      "filter",
      "grayscale",
      "image",
      "images",
      "off",
      "offline",
      "on",
      "photography",
      "picture",
      "pictures",
      "settings",
      "white"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "control_camera",
    "version": 248,
    "popularity": 309,
    "codepoint": 57460,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "adjust",
      "arrow",
      "arrows",
      "camera",
      "center",
      "control",
      "direction",
      "left",
      "move",
      "reposition",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "control_camera",
    "version": 12,
    "popularity": 4051,
    "codepoint": 57460,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "adjust",
      "arrow",
      "arrows",
      "camera",
      "center",
      "control",
      "direction",
      "left",
      "move",
      "reposition",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "control_point",
    "version": 13,
    "popularity": 15900,
    "codepoint": 58298,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "+",
      "add",
      "circle",
      "control",
      "plus",
      "point"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "control_point_duplicate",
    "version": 248,
    "popularity": 588,
    "codepoint": 58299,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "add",
      "circle",
      "control",
      "duplicate",
      "multiple",
      "new",
      "plus",
      "point",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "control_point_duplicate",
    "version": 12,
    "popularity": 3838,
    "codepoint": 58299,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "+",
      "add",
      "circle",
      "control",
      "duplicate",
      "multiple",
      "new",
      "plus",
      "point",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "controller_gen",
    "version": 248,
    "popularity": 233,
    "codepoint": 59453,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "button",
      "circle",
      "controller",
      "nest",
      "regulator"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "conversion_path",
    "version": 248,
    "popularity": 2461,
    "codepoint": 61633,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "ads",
      "analytics",
      "attribution",
      "connecting",
      "conversion",
      "dots",
      "line",
      "path"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "conversion_path_off",
    "version": 248,
    "popularity": 6,
    "codepoint": 63412,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "ads",
      "analytics",
      "attribution",
      "connecting",
      "conversion",
      "disabled",
      "dots",
      "enabled",
      "line",
      "off",
      "offline",
      "on",
      "path",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "conveyor_belt",
    "version": 248,
    "popularity": 62,
    "codepoint": 63591,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "factory",
      "logistic",
      "logistics",
      "manufactory",
      "production",
      "supply",
      "system",
      "transport"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "conveyor_belt",
    "version": 1,
    "popularity": 1351,
    "codepoint": 63591,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "factory",
      "logistic",
      "logistics",
      "manufactory",
      "production",
      "supply",
      "system",
      "transport"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "cookie",
    "version": 248,
    "popularity": 2068,
    "codepoint": 60076,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "biscuit",
      "cookies",
      "data",
      "dessert",
      "wafer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cookie",
    "version": 2,
    "popularity": 5861,
    "codepoint": 60076,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "biscuit",
      "cookies",
      "data",
      "dessert",
      "wafer"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "cookie_off",
    "version": 248,
    "popularity": 28,
    "codepoint": 63386,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "biscuit",
      "cookies",
      "data",
      "dessert",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "slash",
      "wafer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cooking",
    "version": 248,
    "popularity": 1074,
    "codepoint": 58038,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "braiser",
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "dutch oven",
      "food",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "nest",
      "oven",
      "pan",
      "pot",
      "stockpot",
      "stove",
      "stovetop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cool_to_dry",
    "version": 248,
    "popularity": 202,
    "codepoint": 57974,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "cool ",
      "dry",
      "home",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "copy_all",
    "version": 248,
    "popularity": 1079,
    "codepoint": 58092,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "all",
      "content",
      "copy",
      "cut",
      "dash",
      "dashed",
      "doc",
      "document",
      "file",
      "multiple",
      "page",
      "paper",
      "past"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "copy_all",
    "version": 8,
    "popularity": 8977,
    "codepoint": 58092,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "all",
      "content",
      "copy",
      "cut",
      "dash",
      "dashed",
      "doc",
      "document",
      "file",
      "multiple",
      "page",
      "paper",
      "past"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "copyright",
    "version": 248,
    "popularity": 2468,
    "codepoint": 59660,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "alphabet",
      "c",
      "character",
      "copyright",
      "emblem",
      "font",
      "legal",
      "letter",
      "owner",
      "symbol",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "copyright",
    "version": 17,
    "popularity": 17780,
    "codepoint": 59660,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "c",
      "character",
      "copyright",
      "emblem",
      "font",
      "legal",
      "letter",
      "owner",
      "symbol",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "coronavirus",
    "version": 248,
    "popularity": 2376,
    "codepoint": 61985,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "19",
      "bacteria",
      "coronavirus",
      "covid",
      "disease",
      "germs",
      "illness",
      "sick",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "coronavirus",
    "version": 7,
    "popularity": 17819,
    "codepoint": 61985,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "19",
      "bacteria",
      "coronavirus",
      "covid",
      "disease",
      "germs",
      "illness",
      "sick",
      "social"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "corporate_fare",
    "version": 248,
    "popularity": 3516,
    "codepoint": 61904,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "architecture",
      "building",
      "business",
      "corporate",
      "estate",
      "fare",
      "organization",
      "place",
      "real",
      "residence",
      "residential",
      "shelter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "corporate_fare",
    "version": 7,
    "popularity": 18329,
    "codepoint": 61904,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "building",
      "business",
      "corporate",
      "estate",
      "fare",
      "organization",
      "place",
      "real",
      "residence",
      "residential",
      "shelter"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "cottage",
    "version": 248,
    "popularity": 3556,
    "codepoint": 58759,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "beach",
      "cottage",
      "estate",
      "home",
      "house",
      "lake",
      "lodge",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cottage",
    "version": 4,
    "popularity": 16489,
    "codepoint": 58759,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "beach",
      "cottage",
      "estate",
      "home",
      "house",
      "lake",
      "lodge",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "counter_0",
    "version": 248,
    "popularity": 10,
    "codepoint": 63365,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "0",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "symbol",
      "zero"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_1",
    "version": 248,
    "popularity": 268,
    "codepoint": 63364,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "1",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "one",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_2",
    "version": 248,
    "popularity": 95,
    "codepoint": 63363,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "2",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "symbol",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_3",
    "version": 248,
    "popularity": 70,
    "codepoint": 63362,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "3",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "symbol",
      "three"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_4",
    "version": 248,
    "popularity": 39,
    "codepoint": 63361,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "4",
      "circle",
      "digit",
      "four",
      "looks",
      "number",
      "numbers",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_5",
    "version": 248,
    "popularity": 19,
    "codepoint": 63360,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "5",
      "circle",
      "digit",
      "five",
      "looks",
      "number",
      "numbers",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_6",
    "version": 248,
    "popularity": 14,
    "codepoint": 63359,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "6",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "six",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_7",
    "version": 248,
    "popularity": 8,
    "codepoint": 63358,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "7",
      "circle",
      "digit",
      "looks",
      "number",
      "numbers",
      "seven",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_8",
    "version": 248,
    "popularity": 8,
    "codepoint": 63357,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "8",
      "circle",
      "digit",
      "eight",
      "looks",
      "number",
      "numbers",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "counter_9",
    "version": 248,
    "popularity": 8,
    "codepoint": 63356,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "9",
      "circle",
      "digit",
      "looks",
      "nine",
      "number",
      "numbers",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "countertops",
    "version": 248,
    "popularity": 468,
    "codepoint": 61943,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "counter",
      "countertops",
      "home",
      "house",
      "kitchen",
      "sink",
      "table",
      "tops"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "countertops",
    "version": 6,
    "popularity": 3160,
    "codepoint": 61943,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "counter",
      "countertops",
      "home",
      "house",
      "kitchen",
      "sink",
      "table",
      "tops"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "create",
    "version": 16,
    "popularity": 36916,
    "codepoint": 57680,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "compose",
      "create",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "create_new_folder",
    "version": 248,
    "popularity": 3854,
    "codepoint": 58060,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "add",
      "create",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "new",
      "plus",
      "sheet",
      "slide",
      "storage",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "create_new_folder",
    "version": 11,
    "popularity": 15430,
    "codepoint": 58060,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "+",
      "add",
      "create",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "new",
      "plus",
      "sheet",
      "slide",
      "storage",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "credit_card",
    "version": 248,
    "popularity": 14159,
    "codepoint": 59504,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "credit_card",
    "version": 12,
    "popularity": 88942,
    "codepoint": 59504,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "credit_card_gear",
    "version": 248,
    "popularity": 0,
    "codepoint": 62765,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "application",
      "bill",
      "card",
      "cash",
      "change",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "details",
      "dollars",
      "finance",
      "gear",
      "info",
      "information",
      "money",
      "online",
      "options",
      "pay",
      "payment",
      "personal",
      "price",
      "service",
      "settings",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "credit_card_heart",
    "version": 248,
    "popularity": 3,
    "codepoint": 62764,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "favorite",
      "finance",
      "heart",
      "like",
      "love",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "remember",
      "save",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "credit_card_off",
    "version": 248,
    "popularity": 745,
    "codepoint": 58612,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "card",
      "charge",
      "commerce",
      "cost",
      "credit",
      "disabled",
      "enabled",
      "finance",
      "money",
      "off",
      "online",
      "pay",
      "payment",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "credit_card_off",
    "version": 4,
    "popularity": 5525,
    "codepoint": 58612,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "card",
      "charge",
      "commerce",
      "cost",
      "credit",
      "disabled",
      "enabled",
      "finance",
      "money",
      "off",
      "online",
      "pay",
      "payment",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "credit_score",
    "version": 248,
    "popularity": 3021,
    "codepoint": 61425,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "approve",
      "bill",
      "card",
      "cash",
      "check",
      "coin",
      "commerce",
      "complete",
      "cost",
      "credit",
      "currency",
      "dollars",
      "done",
      "finance",
      "loan",
      "mark",
      "money",
      "ok",
      "online",
      "pay",
      "payment",
      "score",
      "select",
      "symbol",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "credit_score",
    "version": 10,
    "popularity": 22690,
    "codepoint": 61425,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "approve",
      "bill",
      "card",
      "cash",
      "check",
      "coin",
      "commerce",
      "complete",
      "cost",
      "credit",
      "currency",
      "dollars",
      "done",
      "finance",
      "loan",
      "mark",
      "money",
      "ok",
      "online",
      "pay",
      "payment",
      "score",
      "select",
      "symbol",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crib",
    "version": 248,
    "popularity": 480,
    "codepoint": 58760,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "babies",
      "baby",
      "bassinet",
      "bed",
      "child",
      "children",
      "cradle",
      "crib",
      "infant",
      "kid",
      "newborn",
      "sleeping",
      "toddler"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crib",
    "version": 4,
    "popularity": 1863,
    "codepoint": 58760,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "babies",
      "baby",
      "bassinet",
      "bed",
      "child",
      "children",
      "cradle",
      "crib",
      "infant",
      "kid",
      "newborn",
      "sleeping",
      "toddler"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "crisis_alert",
    "version": 248,
    "popularity": 2061,
    "codepoint": 60393,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bullseye",
      "caution",
      "crisis",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "target",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crisis_alert",
    "version": 1,
    "popularity": 2645,
    "codepoint": 60393,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bullseye",
      "caution",
      "crisis",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "target",
      "warning"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "crop",
    "version": 248,
    "popularity": 1322,
    "codepoint": 58302,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop",
    "version": 12,
    "popularity": 6908,
    "codepoint": 58302,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crop_16_9",
    "version": 248,
    "popularity": 542,
    "codepoint": 58300,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "16",
      "9",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_16_9",
    "version": 13,
    "popularity": 2750,
    "codepoint": 58300,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "16",
      "9",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "crop_3_2",
    "version": 248,
    "popularity": 302,
    "codepoint": 58301,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "2",
      "3",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_3_2",
    "version": 13,
    "popularity": 1712,
    "codepoint": 58301,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "2",
      "3",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "crop_5_4",
    "version": 248,
    "popularity": 323,
    "codepoint": 58303,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "4",
      "5",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing settings",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_5_4",
    "version": 13,
    "popularity": 2014,
    "codepoint": 58303,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "4",
      "5",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing settings",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "crop_7_5",
    "version": 248,
    "popularity": 451,
    "codepoint": 58304,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "5",
      "7",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_7_5",
    "version": 13,
    "popularity": 2217,
    "codepoint": 58304,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "5",
      "7",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "crop_9_16",
    "version": 248,
    "popularity": 4,
    "codepoint": 62793,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "16",
      "9",
      "adjust",
      "adjustments",
      "area",
      "by",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_din",
    "version": 12,
    "popularity": 4000,
    "codepoint": 58305,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "din",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crop_free",
    "version": 248,
    "popularity": 1600,
    "codepoint": 58306,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "crop",
      "display",
      "edit",
      "editing",
      "focus",
      "frame",
      "free",
      "image",
      "photo",
      "photos",
      "settings",
      "size",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_free",
    "version": 12,
    "popularity": 10031,
    "codepoint": 58306,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "crop",
      "display",
      "edit",
      "editing",
      "focus",
      "frame",
      "free",
      "image",
      "photo",
      "photos",
      "settings",
      "size",
      "zoom"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crop_landscape",
    "version": 248,
    "popularity": 346,
    "codepoint": 58307,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "landscape",
      "photo",
      "photos",
      "settings",
      "size"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_landscape",
    "version": 12,
    "popularity": 1720,
    "codepoint": 58307,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "landscape",
      "photo",
      "photos",
      "settings",
      "size"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crop_original",
    "version": 13,
    "popularity": 5995,
    "codepoint": 58308,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "original",
      "photo",
      "photos",
      "picture",
      "settings",
      "size"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crop_portrait",
    "version": 248,
    "popularity": 432,
    "codepoint": 58309,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "portrait",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_portrait",
    "version": 12,
    "popularity": 2382,
    "codepoint": 58309,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "portrait",
      "rectangle",
      "settings",
      "size",
      "square"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crop_rotate",
    "version": 248,
    "popularity": 304,
    "codepoint": 58423,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "arrow",
      "arrows",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rotate",
      "settings",
      "size",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_rotate",
    "version": 12,
    "popularity": 2144,
    "codepoint": 58423,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "arrow",
      "arrows",
      "crop",
      "edit",
      "editing",
      "frame",
      "image",
      "images",
      "photo",
      "photos",
      "rotate",
      "settings",
      "size",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crop_square",
    "version": 248,
    "popularity": 1882,
    "codepoint": 58310,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "app",
      "application",
      "area",
      "components",
      "crop",
      "design",
      "edit",
      "editing",
      "expand",
      "frame",
      "image",
      "images",
      "interface",
      "open",
      "photo",
      "photos",
      "rectangle",
      "screen",
      "settings",
      "shape",
      "shapes",
      "site",
      "size",
      "square",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crop_square",
    "version": 12,
    "popularity": 8924,
    "codepoint": 58310,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "app",
      "application",
      "area",
      "components",
      "crop",
      "design",
      "edit",
      "editing",
      "expand",
      "frame",
      "image",
      "images",
      "interface",
      "open",
      "photo",
      "photos",
      "rectangle",
      "screen",
      "settings",
      "shape",
      "shapes",
      "site",
      "size",
      "square",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "crossword",
    "version": 248,
    "popularity": 36,
    "codepoint": 62949,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "board game",
      "google play",
      "puzzle",
      "word puzzle",
      "words"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "crowdsource",
    "version": 248,
    "popularity": 108,
    "codepoint": 60184,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "business",
      "crowd",
      "crowdsource",
      "funding",
      "funds",
      "investment",
      "logo",
      "payment",
      "people"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cruelty_free",
    "version": 248,
    "popularity": 2469,
    "codepoint": 59289,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "animal",
      "bunny",
      "cruelty",
      "eco",
      "free",
      "nature",
      "rabbit",
      "social",
      "sustainability",
      "sustainable",
      "testing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cruelty_free",
    "version": 3,
    "popularity": 6402,
    "codepoint": 59289,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "animal",
      "bunny",
      "cruelty",
      "eco",
      "free",
      "nature",
      "rabbit",
      "social",
      "sustainability",
      "sustainable",
      "testing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "css",
    "version": 248,
    "popularity": 1602,
    "codepoint": 60307,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "css",
    "version": 1,
    "popularity": 2149,
    "codepoint": 60307,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "csv",
    "version": 248,
    "popularity": 56,
    "codepoint": 59087,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "comma",
      "csv",
      "file",
      "font",
      "format",
      "letter",
      "separated",
      "spreadsheets",
      "symbol",
      "text",
      "type",
      "values"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_bitcoin",
    "version": 248,
    "popularity": 1884,
    "codepoint": 60357,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "blockchain",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "digital",
      "dollars",
      "finance",
      "franc",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_bitcoin",
    "version": 1,
    "popularity": 3832,
    "codepoint": 60357,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "blockchain",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "digital",
      "dollars",
      "finance",
      "franc",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_exchange",
    "version": 248,
    "popularity": 5744,
    "codepoint": 60272,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "360",
      "around",
      "arrow",
      "arrows",
      "cash",
      "coin",
      "commerce",
      "currency",
      "direction",
      "dollars",
      "exchange",
      "inprogress",
      "money",
      "pay",
      "renew",
      "rotate",
      "sync",
      "turn",
      "universal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_exchange",
    "version": 1,
    "popularity": 18614,
    "codepoint": 60272,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "360",
      "around",
      "arrow",
      "arrows",
      "cash",
      "coin",
      "commerce",
      "currency",
      "direction",
      "dollars",
      "exchange",
      "inprogress",
      "money",
      "pay",
      "renew",
      "rotate",
      "sync",
      "turn",
      "universal"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_franc",
    "version": 248,
    "popularity": 241,
    "codepoint": 60154,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "franc",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_franc",
    "version": 1,
    "popularity": 722,
    "codepoint": 60154,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "franc",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_lira",
    "version": 248,
    "popularity": 329,
    "codepoint": 60143,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "lira",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_lira",
    "version": 1,
    "popularity": 971,
    "codepoint": 60143,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "lira",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_pound",
    "version": 248,
    "popularity": 1005,
    "codepoint": 60145,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "pound",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_pound",
    "version": 1,
    "popularity": 2953,
    "codepoint": 60145,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "pound",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_ruble",
    "version": 248,
    "popularity": 612,
    "codepoint": 60140,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "ruble",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_ruble",
    "version": 1,
    "popularity": 2091,
    "codepoint": 60140,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "ruble",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_rupee",
    "version": 248,
    "popularity": 3356,
    "codepoint": 60151,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "rupee",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_rupee",
    "version": 1,
    "popularity": 9445,
    "codepoint": 60151,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "rupee",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_yen",
    "version": 248,
    "popularity": 1027,
    "codepoint": 60155,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "yen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_yen",
    "version": 1,
    "popularity": 3500,
    "codepoint": 60155,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "yen"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "currency_yuan",
    "version": 248,
    "popularity": 303,
    "codepoint": 60153,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "yuan"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "currency_yuan",
    "version": 1,
    "popularity": 978,
    "codepoint": 60153,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "yuan"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "curtains",
    "version": 248,
    "popularity": 276,
    "codepoint": 60446,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "curtains",
    "version": 1,
    "popularity": 612,
    "codepoint": 60446,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "curtains_closed",
    "version": 248,
    "popularity": 142,
    "codepoint": 60445,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "curtains_closed",
    "version": 1,
    "popularity": 585,
    "codepoint": 60445,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "custom_typography",
    "version": 248,
    "popularity": 20,
    "codepoint": 59186,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "character",
      "custom",
      "customization",
      "font",
      "letter",
      "star",
      "style",
      "symbol",
      "text",
      "theme",
      "type",
      "typography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cut",
    "version": 248,
    "popularity": 1702,
    "codepoint": 61579,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "past",
      "scissors",
      "trim"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cycle",
    "version": 248,
    "popularity": 551,
    "codepoint": 63572,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "history",
      "load",
      "loading",
      "navigation",
      "recycle",
      "refresh",
      "renew",
      "reuse",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cyclone",
    "version": 248,
    "popularity": 694,
    "codepoint": 60373,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "weather",
      "wind",
      "winds"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "cyclone",
    "version": 1,
    "popularity": 1268,
    "codepoint": 60373,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "weather",
      "wind",
      "winds"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dangerous",
    "version": 248,
    "popularity": 3077,
    "codepoint": 59802,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "broken",
      "danger",
      "dangerous",
      "fix",
      "no",
      "sign",
      "stop",
      "update",
      "warning",
      "wrong",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dangerous",
    "version": 12,
    "popularity": 20768,
    "codepoint": 59802,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "broken",
      "danger",
      "dangerous",
      "fix",
      "no",
      "sign",
      "stop",
      "update",
      "warning",
      "wrong",
      "x"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dark_mode",
    "version": 248,
    "popularity": 11685,
    "codepoint": 58652,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "app",
      "application",
      "dark",
      "device",
      "interface",
      "mode",
      "moon",
      "night",
      "silent",
      "theme",
      "ui",
      "ux",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dark_mode",
    "version": 4,
    "popularity": 48288,
    "codepoint": 58652,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "app",
      "application",
      "dark",
      "device",
      "interface",
      "mode",
      "moon",
      "night",
      "silent",
      "theme",
      "ui",
      "ux",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dashboard",
    "version": 248,
    "popularity": 16131,
    "codepoint": 59505,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cards",
      "dashboard",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dashboard",
    "version": 13,
    "popularity": 132347,
    "codepoint": 59505,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cards",
      "dashboard",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dashboard_customize",
    "version": 248,
    "popularity": 2430,
    "codepoint": 59803,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cards",
      "customize",
      "dashboard",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dashboard_customize",
    "version": 11,
    "popularity": 22620,
    "codepoint": 59803,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cards",
      "customize",
      "dashboard",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "data_alert",
    "version": 248,
    "popularity": 89,
    "codepoint": 63478,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "!",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "list",
      "lists",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_array",
    "version": 248,
    "popularity": 475,
    "codepoint": 60113,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "array",
      "brackets",
      "code",
      "coder",
      "data",
      "parentheses"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_array",
    "version": 2,
    "popularity": 1838,
    "codepoint": 60113,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "array",
      "brackets",
      "code",
      "coder",
      "data",
      "parentheses"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "data_check",
    "version": 248,
    "popularity": 100,
    "codepoint": 63474,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "checkmark",
      "complete",
      "done",
      "list",
      "lists",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_exploration",
    "version": 248,
    "popularity": 1388,
    "codepoint": 59247,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "arrow",
      "chart",
      "data",
      "diagram",
      "exploration",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_exploration",
    "version": 3,
    "popularity": 7311,
    "codepoint": 59247,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "arrow",
      "chart",
      "data",
      "diagram",
      "exploration",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "data_info_alert",
    "version": 248,
    "popularity": 72,
    "codepoint": 63477,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "list",
      "lists",
      "service",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_loss_prevention",
    "version": 248,
    "popularity": 73,
    "codepoint": 58076,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "avatar",
      "data",
      "face",
      "find",
      "glass",
      "human",
      "look",
      "loss",
      "magnify",
      "magnifying",
      "people",
      "person",
      "prevention",
      "profile",
      "search",
      "see",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_object",
    "version": 248,
    "popularity": 1719,
    "codepoint": 60115,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "brackets",
      "code",
      "coder",
      "data",
      "object",
      "parentheses"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_object",
    "version": 2,
    "popularity": 4845,
    "codepoint": 60115,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "brackets",
      "code",
      "coder",
      "data",
      "object",
      "parentheses"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "data_saver_off",
    "version": 10,
    "popularity": 3095,
    "codepoint": 61426,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "donut",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "off",
      "on",
      "ring",
      "saver",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "data_saver_on",
    "version": 248,
    "popularity": 497,
    "codepoint": 61427,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "new",
      "on",
      "plus",
      "ring",
      "saver",
      "statistics",
      "symbol",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_saver_on",
    "version": 10,
    "popularity": 3020,
    "codepoint": 61427,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "+",
      "add",
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "new",
      "on",
      "plus",
      "ring",
      "saver",
      "statistics",
      "symbol",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "data_table",
    "version": 248,
    "popularity": 155,
    "codepoint": 59804,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "app",
      "application",
      "cells",
      "chart",
      "column",
      "columns",
      "components",
      "data",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "row",
      "screen",
      "sheet",
      "site",
      "spreadsheet",
      "table",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_thresholding",
    "version": 248,
    "popularity": 1480,
    "codepoint": 60319,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "data",
      "hidden",
      "privacy",
      "thresholding",
      "thresold"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_thresholding",
    "version": 1,
    "popularity": 2430,
    "codepoint": 60319,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "data",
      "hidden",
      "privacy",
      "thresholding",
      "thresold"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "data_usage",
    "version": 248,
    "popularity": 1226,
    "codepoint": 57775,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "usage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "data_usage",
    "version": 12,
    "popularity": 7044,
    "codepoint": 57775,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "usage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "database",
    "version": 248,
    "popularity": 11706,
    "codepoint": 61966,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "coin",
      "data",
      "database",
      "diagram",
      "graph",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dataset",
    "version": 248,
    "popularity": 4068,
    "codepoint": 63726,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dataset",
    "version": 1,
    "popularity": 1616,
    "codepoint": 63726,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dataset_linked",
    "version": 248,
    "popularity": 1409,
    "codepoint": 63727,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dataset_linked",
    "version": 1,
    "popularity": 916,
    "codepoint": 63727,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "date_range",
    "version": 248,
    "popularity": 7347,
    "codepoint": 59670,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "range",
      "remember",
      "reminder",
      "schedule",
      "time",
      "today",
      "week"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "date_range",
    "version": 12,
    "popularity": 101146,
    "codepoint": 59670,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "month",
      "range",
      "remember",
      "reminder",
      "schedule",
      "time",
      "today",
      "week"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "deblur",
    "version": 248,
    "popularity": 342,
    "codepoint": 60279,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "deblur",
      "edit",
      "editing",
      "enhance",
      "face",
      "image",
      "lines",
      "photo",
      "photography",
      "sharpen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deblur",
    "version": 1,
    "popularity": 1085,
    "codepoint": 60279,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "deblur",
      "edit",
      "editing",
      "enhance",
      "face",
      "image",
      "lines",
      "photo",
      "photography",
      "sharpen"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "deceased",
    "version": 248,
    "popularity": 27,
    "codepoint": 57509,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "dead",
      "death",
      "flower",
      "flowers",
      "garden",
      "health",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "decimal_decrease",
    "version": 248,
    "popularity": 2,
    "codepoint": 63533,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "arrows",
      "currency",
      "decimal",
      "decline",
      "decrease",
      "finance",
      "fraction",
      "fractions",
      "left",
      "less",
      "money"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "decimal_increase",
    "version": 248,
    "popularity": 14,
    "codepoint": 63532,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "arrows",
      "currency",
      "decimal",
      "finance",
      "fraction",
      "fractions",
      "growth",
      "increase",
      "money",
      "more",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deck",
    "version": 248,
    "popularity": 823,
    "codepoint": 59970,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "chairs",
      "deck",
      "home",
      "house",
      "outdoors",
      "outside",
      "patio",
      "social",
      "terrace",
      "umbrella",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deck",
    "version": 11,
    "popularity": 5417,
    "codepoint": 59970,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "chairs",
      "deck",
      "home",
      "house",
      "outdoors",
      "outside",
      "patio",
      "social",
      "terrace",
      "umbrella",
      "yard"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dehaze",
    "version": 248,
    "popularity": 726,
    "codepoint": 58311,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "dehaze",
      "edit",
      "editing",
      "enhance",
      "haze",
      "image",
      "lines",
      "photo",
      "photography",
      "remove"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dehaze",
    "version": 12,
    "popularity": 5853,
    "codepoint": 58311,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "dehaze",
      "edit",
      "editing",
      "enhance",
      "haze",
      "image",
      "lines",
      "photo",
      "photography",
      "remove"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "delete",
    "version": 248,
    "popularity": 69557,
    "codepoint": 59506,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bin",
      "can",
      "delete",
      "garbage",
      "remove",
      "trash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "delete",
    "version": 17,
    "popularity": 361112,
    "codepoint": 59506,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bin",
      "can",
      "delete",
      "garbage",
      "remove",
      "trash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "delete_forever",
    "version": 248,
    "popularity": 13026,
    "codepoint": 59691,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bin",
      "can",
      "cancel",
      "clear",
      "delete",
      "exit",
      "forever",
      "garbage",
      "remove",
      "trash",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "delete_forever",
    "version": 15,
    "popularity": 63549,
    "codepoint": 59691,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bin",
      "can",
      "cancel",
      "clear",
      "delete",
      "exit",
      "forever",
      "garbage",
      "remove",
      "trash",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "delete_history",
    "version": 248,
    "popularity": 7,
    "codepoint": 62744,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "cancel",
      "clear",
      "clock",
      "close",
      "date",
      "exit",
      "history",
      "no",
      "quit",
      "refresh",
      "remove",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "stop",
      "time",
      "turn",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "delete_outline",
    "version": 11,
    "popularity": 84627,
    "codepoint": 59694,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bin",
      "can",
      "delete",
      "garbage",
      "outline",
      "remove",
      "trash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "delete_sweep",
    "version": 248,
    "popularity": 2815,
    "codepoint": 57708,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bin",
      "can",
      "delete",
      "garbage",
      "remove",
      "sweep",
      "trash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "delete_sweep",
    "version": 12,
    "popularity": 12274,
    "codepoint": 57708,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "bin",
      "can",
      "delete",
      "garbage",
      "remove",
      "sweep",
      "trash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "delivery_dining",
    "version": 15,
    "popularity": 15999,
    "codepoint": 60018,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "delivery",
      "dining",
      "food",
      "meal",
      "restaurant",
      "scooter",
      "takeout",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "demography",
    "version": 248,
    "popularity": 63,
    "codepoint": 58505,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "data",
      "doc",
      "document",
      "file",
      "health",
      "human",
      "note",
      "page",
      "paper",
      "people",
      "person",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "density_large",
    "version": 248,
    "popularity": 571,
    "codepoint": 60329,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "density",
      "horizontal",
      "large",
      "lines",
      "rule",
      "rules"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "density_large",
    "version": 1,
    "popularity": 1300,
    "codepoint": 60329,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "density",
      "horizontal",
      "large",
      "lines",
      "rule",
      "rules"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "density_medium",
    "version": 248,
    "popularity": 2384,
    "codepoint": 60318,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "density",
      "horizontal",
      "lines",
      "medium",
      "rule",
      "rules"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "density_medium",
    "version": 1,
    "popularity": 6101,
    "codepoint": 60318,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "density",
      "horizontal",
      "lines",
      "medium",
      "rule",
      "rules"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "density_small",
    "version": 248,
    "popularity": 1598,
    "codepoint": 60328,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "density",
      "horizontal",
      "lines",
      "rule",
      "rules",
      "small"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "density_small",
    "version": 1,
    "popularity": 2558,
    "codepoint": 60328,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "density",
      "horizontal",
      "lines",
      "rule",
      "rules",
      "small"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dentistry",
    "version": 248,
    "popularity": 639,
    "codepoint": 57510,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "dentist",
      "dentistry",
      "health",
      "hygiene",
      "medical",
      "mouth",
      "oral",
      "teeth",
      "tooth"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "departure_board",
    "version": 248,
    "popularity": 697,
    "codepoint": 58742,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "board",
      "bus",
      "car",
      "cars",
      "clock",
      "departure",
      "maps",
      "public",
      "schedule",
      "time",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "departure_board",
    "version": 11,
    "popularity": 4781,
    "codepoint": 58742,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "board",
      "bus",
      "car",
      "cars",
      "clock",
      "departure",
      "maps",
      "public",
      "schedule",
      "time",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "deployed_code",
    "version": 248,
    "popularity": 151,
    "codepoint": 63264,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3d",
      "coding",
      "create",
      "cube",
      "development",
      "dimension",
      "install",
      "installed",
      "package",
      "packet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deployed_code_account",
    "version": 248,
    "popularity": 2,
    "codepoint": 62747,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3d",
      "accounts",
      "change",
      "coding",
      "create",
      "cube",
      "details",
      "development",
      "dimension",
      "face",
      "gear",
      "human",
      "install",
      "installed",
      "manage",
      "options",
      "package",
      "packet",
      "people",
      "person",
      "profile",
      "service",
      "settings",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deployed_code_alert",
    "version": 248,
    "popularity": 13,
    "codepoint": 62962,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "!",
      "3d",
      "alert",
      "attention",
      "borg",
      "caution",
      "coding",
      "create",
      "cube",
      "danger",
      "development",
      "dimension",
      "error",
      "exclamation",
      "important",
      "install",
      "installed",
      "mark",
      "notification",
      "package",
      "packet",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deployed_code_history",
    "version": 248,
    "popularity": 14,
    "codepoint": 62963,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3d",
      "borg",
      "clock",
      "coding",
      "create",
      "cube",
      "date",
      "development",
      "dimension",
      "install",
      "installed",
      "package",
      "packet",
      "pending",
      "recent",
      "schedule",
      "time",
      "updates"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deployed_code_update",
    "version": 248,
    "popularity": 33,
    "codepoint": 62964,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3d",
      "arrow",
      "arrows",
      "borg",
      "coding",
      "create",
      "cube",
      "development",
      "dimension",
      "down",
      "download",
      "install",
      "installed",
      "package",
      "packet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dermatology",
    "version": 248,
    "popularity": 13,
    "codepoint": 57511,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "derm",
      "follicle",
      "hair",
      "health",
      "human",
      "skin",
      "skincare"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "description",
    "version": 248,
    "popularity": 32803,
    "codepoint": 59507,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "data",
      "description",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "description",
    "version": 12,
    "popularity": 246268,
    "codepoint": 59507,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "article",
      "data",
      "description",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "deselect",
    "version": 248,
    "popularity": 420,
    "codepoint": 60342,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "all",
      "disabled",
      "enabled",
      "off",
      "on",
      "selection",
      "slash",
      "square",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "deselect",
    "version": 1,
    "popularity": 1687,
    "codepoint": 60342,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "all",
      "disabled",
      "enabled",
      "off",
      "on",
      "selection",
      "slash",
      "square",
      "tool"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "design_services",
    "version": 248,
    "popularity": 4582,
    "codepoint": 61706,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compose",
      "create",
      "design",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "ruler",
      "service",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "design_services",
    "version": 12,
    "popularity": 16329,
    "codepoint": 61706,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "compose",
      "create",
      "design",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "ruler",
      "service",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "desk",
    "version": 248,
    "popularity": 356,
    "codepoint": 63732,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "desk",
    "version": 1,
    "popularity": 606,
    "codepoint": 63732,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "deskphone",
    "version": 248,
    "popularity": 8,
    "codepoint": 63482,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "analog",
      "call",
      "contact",
      "desk",
      "device",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "desktop_access_disabled",
    "version": 248,
    "popularity": 254,
    "codepoint": 59805,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "access",
      "chrome",
      "desktop",
      "device",
      "disabled",
      "display",
      "enabled",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "off",
      "offline",
      "on",
      "screen",
      "slash",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "desktop_access_disabled",
    "version": 11,
    "popularity": 2255,
    "codepoint": 59805,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "access",
      "chrome",
      "desktop",
      "device",
      "disabled",
      "display",
      "enabled",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "off",
      "offline",
      "on",
      "screen",
      "slash",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "desktop_mac",
    "version": 248,
    "popularity": 1053,
    "codepoint": 58123,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "desktop_mac",
    "version": 17,
    "popularity": 5924,
    "codepoint": 58123,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "desktop_windows",
    "version": 248,
    "popularity": 5010,
    "codepoint": 58124,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "television",
      "tv",
      "web",
      "window",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "desktop_windows",
    "version": 13,
    "popularity": 27149,
    "codepoint": 58124,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "television",
      "tv",
      "web",
      "window",
      "windows"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "destruction",
    "version": 248,
    "popularity": 14,
    "codepoint": 62853,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "attack",
      "damage",
      "destroy",
      "explode",
      "explosion",
      "threat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "details",
    "version": 248,
    "popularity": 770,
    "codepoint": 58312,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "details",
      "edit",
      "editing",
      "enhance",
      "image",
      "photo",
      "photography",
      "sharpen",
      "triangle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "details",
    "version": 16,
    "popularity": 5241,
    "codepoint": 58312,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "details",
      "edit",
      "editing",
      "enhance",
      "image",
      "photo",
      "photography",
      "sharpen",
      "triangle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "detection_and_zone",
    "version": 248,
    "popularity": 288,
    "codepoint": 58015,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "activity",
      "detection",
      "home",
      "nest",
      "person",
      "security",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "detector",
    "version": 248,
    "popularity": 150,
    "codepoint": 57986,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "co",
      "detector",
      "home",
      "nest",
      "protect",
      "safety",
      "smoke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "detector_alarm",
    "version": 248,
    "popularity": 241,
    "codepoint": 57847,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alarm",
      "co",
      "detector",
      "home",
      "nest",
      "protect",
      "safety",
      "smoke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "detector_battery",
    "version": 248,
    "popularity": 119,
    "codepoint": 57860,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "battery",
      "co",
      "detector",
      "home",
      "nest",
      "safety",
      "smoke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "detector_co",
    "version": 248,
    "popularity": 123,
    "codepoint": 58031,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alert",
      "co",
      "detector",
      "home",
      "nest",
      "safety",
      "smoke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "detector_offline",
    "version": 248,
    "popularity": 95,
    "codepoint": 57891,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "detector",
      "home",
      "nest",
      "offline",
      "safety",
      "smoke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "detector_smoke",
    "version": 248,
    "popularity": 386,
    "codepoint": 57989,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alarm",
      "detector",
      "device",
      "fire",
      "home",
      "house",
      "nest",
      "smoke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "detector_status",
    "version": 248,
    "popularity": 164,
    "codepoint": 57832,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "detector",
      "home",
      "nest",
      "safety",
      "smoke",
      "status"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "developer_board",
    "version": 248,
    "popularity": 1583,
    "codepoint": 58125,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "board",
      "chip",
      "computer",
      "developer",
      "development",
      "hardware",
      "microchip",
      "processor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "developer_board",
    "version": 13,
    "popularity": 9797,
    "codepoint": 58125,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "board",
      "chip",
      "computer",
      "developer",
      "development",
      "hardware",
      "microchip",
      "processor"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "developer_board_off",
    "version": 248,
    "popularity": 169,
    "codepoint": 58623,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "board",
      "chip",
      "computer",
      "developer",
      "development",
      "disabled",
      "enabled",
      "hardware",
      "microchip",
      "off",
      "on",
      "processor",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "developer_board_off",
    "version": 4,
    "popularity": 1112,
    "codepoint": 58623,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "board",
      "chip",
      "computer",
      "developer",
      "development",
      "disabled",
      "enabled",
      "hardware",
      "microchip",
      "off",
      "on",
      "processor",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "developer_guide",
    "version": 248,
    "popularity": 185,
    "codepoint": 59806,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "agreement",
      "booklet",
      "bookmark",
      "certificate",
      "code",
      "coder",
      "contract",
      "developer",
      "doc",
      "document",
      "guide",
      "guideline",
      "instruction",
      "manual",
      "rules",
      "software"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "developer_mode",
    "version": 248,
    "popularity": 1429,
    "codepoint": 57776,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "bracket",
      "cell",
      "code",
      "developer",
      "development",
      "device",
      "engineer",
      "hardware",
      "iOS",
      "mobile",
      "mode",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "developer_mode",
    "version": 12,
    "popularity": 6466,
    "codepoint": 57776,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "bracket",
      "cell",
      "code",
      "developer",
      "development",
      "device",
      "engineer",
      "hardware",
      "iOS",
      "mobile",
      "mode",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "developer_mode_tv",
    "version": 248,
    "popularity": 44,
    "codepoint": 59508,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "brackets",
      "chrome",
      "code",
      "coding",
      "desktop",
      "developer",
      "device",
      "hardware",
      "iOS",
      "mac",
      "mode",
      "monitor",
      "tv",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "device_hub",
    "version": 248,
    "popularity": 1209,
    "codepoint": 58165,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "circle",
      "computer",
      "desktop",
      "device",
      "hardware",
      "hub",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "phone",
      "square",
      "tablet",
      "triangle",
      "watch",
      "wearable",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "device_hub",
    "version": 12,
    "popularity": 7702,
    "codepoint": 58165,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "circle",
      "computer",
      "desktop",
      "device",
      "hardware",
      "hub",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "phone",
      "square",
      "tablet",
      "triangle",
      "watch",
      "wearable",
      "web"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "device_reset",
    "version": 248,
    "popularity": 969,
    "codepoint": 57998,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "clock",
      "device",
      "home",
      "nest",
      "reset"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "device_thermostat",
    "version": 248,
    "popularity": 2603,
    "codepoint": 57855,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "celsius",
      "device",
      "fahrenheit",
      "meter",
      "temp",
      "temperature",
      "thermometer",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "device_thermostat",
    "version": 11,
    "popularity": 7285,
    "codepoint": 57855,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "celsius",
      "device",
      "fahrenheit",
      "meter",
      "temp",
      "temperature",
      "thermometer",
      "thermostat"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "device_unknown",
    "version": 248,
    "popularity": 524,
    "codepoint": 58169,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "?",
      "Android",
      "OS",
      "assistance",
      "cell",
      "device",
      "hardware",
      "help",
      "iOS",
      "info",
      "information",
      "mobile",
      "phone",
      "punctuation",
      "question mark",
      "support",
      "symbol",
      "tablet",
      "unknown"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "device_unknown",
    "version": 12,
    "popularity": 2973,
    "codepoint": 58169,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "?",
      "Android",
      "OS",
      "assistance",
      "cell",
      "device",
      "hardware",
      "help",
      "iOS",
      "info",
      "information",
      "mobile",
      "phone",
      "punctuation",
      "question mark",
      "support",
      "symbol",
      "tablet",
      "unknown"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "devices",
    "version": 248,
    "popularity": 6579,
    "codepoint": 57777,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "computer",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "phone",
      "tablet",
      "watch",
      "wearable",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "devices",
    "version": 17,
    "popularity": 31271,
    "codepoint": 57777,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "computer",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "phone",
      "tablet",
      "watch",
      "wearable",
      "web"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "devices_fold",
    "version": 248,
    "popularity": 244,
    "codepoint": 60382,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "dash",
      "dashed",
      "device",
      "fold",
      "foldable",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "devices_fold",
    "version": 1,
    "popularity": 570,
    "codepoint": 60382,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "dash",
      "dashed",
      "device",
      "fold",
      "foldable",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "devices_off",
    "version": 248,
    "popularity": 24,
    "codepoint": 63397,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "computer",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "laptop",
      "mobile",
      "monitor",
      "off",
      "offline",
      "on",
      "phone",
      "slash",
      "tablet",
      "watch",
      "wearable",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "devices_other",
    "version": 248,
    "popularity": 983,
    "codepoint": 58167,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "ar",
      "cell",
      "chrome",
      "desktop",
      "device",
      "gadget",
      "hardware",
      "iOS",
      "ipad",
      "mac",
      "mobile",
      "monitor",
      "other",
      "phone",
      "tablet",
      "vr",
      "watch",
      "wearables",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "devices_other",
    "version": 12,
    "popularity": 6164,
    "codepoint": 58167,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "ar",
      "cell",
      "chrome",
      "desktop",
      "device",
      "gadget",
      "hardware",
      "iOS",
      "ipad",
      "mac",
      "mobile",
      "monitor",
      "other",
      "phone",
      "tablet",
      "vr",
      "watch",
      "wearables",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "devices_wearables",
    "version": 248,
    "popularity": 93,
    "codepoint": 63147,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "ar",
      "call",
      "cell",
      "chat",
      "clock",
      "device",
      "gadget",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "smartphone",
      "tablet",
      "text",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dew_point",
    "version": 248,
    "popularity": 14,
    "codepoint": 63609,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "atmospheric",
      "condense",
      "dew",
      "droplets",
      "point",
      "temperature",
      "thermometer",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dew_point",
    "version": 1,
    "popularity": 1002,
    "codepoint": 63609,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "atmospheric",
      "condense",
      "dew",
      "droplets",
      "point",
      "temperature",
      "thermometer",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "diagnosis",
    "version": 248,
    "popularity": 47,
    "codepoint": 57512,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "file",
      "health",
      "heart",
      "letter",
      "note",
      "page",
      "paper",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dialer_sip",
    "version": 248,
    "popularity": 259,
    "codepoint": 57531,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alphabet",
      "call",
      "cell",
      "character",
      "contact",
      "device",
      "dialer",
      "font",
      "hardware",
      "initiation",
      "internet",
      "letter",
      "mobile",
      "over",
      "phone",
      "protocol",
      "routing",
      "session",
      "sip",
      "symbol",
      "telephone",
      "text",
      "type",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dialer_sip",
    "version": 12,
    "popularity": 1849,
    "codepoint": 57531,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "alphabet",
      "call",
      "cell",
      "character",
      "contact",
      "device",
      "dialer",
      "font",
      "hardware",
      "initiation",
      "internet",
      "letter",
      "mobile",
      "over",
      "phone",
      "protocol",
      "routing",
      "session",
      "sip",
      "symbol",
      "telephone",
      "text",
      "type",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dialogs",
    "version": 248,
    "popularity": 38,
    "codepoint": 59807,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "action",
      "app",
      "application",
      "components",
      "design",
      "dialogs",
      "information",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "squares",
      "tablet",
      "tasks",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dialpad",
    "version": 248,
    "popularity": 1385,
    "codepoint": 57532,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "buttons",
      "call",
      "contact",
      "device",
      "dial",
      "dialpad",
      "dots",
      "mobile",
      "numbers",
      "pad",
      "phone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dialpad",
    "version": 12,
    "popularity": 9283,
    "codepoint": 57532,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "buttons",
      "call",
      "contact",
      "device",
      "dial",
      "dialpad",
      "dots",
      "mobile",
      "numbers",
      "pad",
      "phone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "diamond",
    "version": 248,
    "popularity": 4258,
    "codepoint": 60117,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "diamond",
      "fashion",
      "gems",
      "jewelry",
      "logo",
      "retail",
      "valuable",
      "valuables"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "diamond",
    "version": 2,
    "popularity": 11513,
    "codepoint": 60117,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "diamond",
      "fashion",
      "gems",
      "jewelry",
      "logo",
      "retail",
      "valuable",
      "valuables"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dictionary",
    "version": 248,
    "popularity": 44,
    "codepoint": 62777,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "glossary",
      "lexicon",
      "library",
      "read",
      "reading",
      "textbook",
      "thesaurus",
      "vocabulary"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "difference",
    "version": 248,
    "popularity": 1361,
    "codepoint": 60285,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compare",
      "content",
      "copy",
      "cut",
      "diff",
      "difference",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "past",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "difference",
    "version": 1,
    "popularity": 3545,
    "codepoint": 60285,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "compare",
      "content",
      "copy",
      "cut",
      "diff",
      "difference",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "past",
      "stack"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "digital_out_of_home",
    "version": 248,
    "popularity": 276,
    "codepoint": 61918,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "digital_wellbeing",
    "version": 248,
    "popularity": 134,
    "codepoint": 61318,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "app",
      "application",
      "digital",
      "healthy",
      "heart",
      "person",
      "wellbeing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dining",
    "version": 248,
    "popularity": 871,
    "codepoint": 61428,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "cafe",
      "cafeteria",
      "cutlery",
      "diner",
      "dining",
      "eat",
      "eating",
      "fork",
      "room",
      "spoon"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dining",
    "version": 9,
    "popularity": 4760,
    "codepoint": 61428,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "cafe",
      "cafeteria",
      "cutlery",
      "diner",
      "dining",
      "eat",
      "eating",
      "fork",
      "room",
      "spoon"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dinner_dining",
    "version": 248,
    "popularity": 945,
    "codepoint": 59991,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "food",
      "fork",
      "lunch",
      "meal",
      "restaurant",
      "spaghetti",
      "utensils"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dinner_dining",
    "version": 10,
    "popularity": 6123,
    "codepoint": 59991,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "food",
      "fork",
      "lunch",
      "meal",
      "restaurant",
      "spaghetti",
      "utensils"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "directions",
    "version": 248,
    "popularity": 1575,
    "codepoint": 58670,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directions",
      "maps",
      "right",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions",
    "version": 14,
    "popularity": 10705,
    "codepoint": 58670,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "directions",
      "maps",
      "right",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_alt",
    "version": 248,
    "popularity": 231,
    "codepoint": 63616,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directions",
      "maps",
      "right",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_alt_off",
    "version": 248,
    "popularity": 41,
    "codepoint": 63617,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directions",
      "disabled",
      "enabled",
      "maps",
      "off",
      "on",
      "right",
      "route",
      "sign",
      "slash",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_bike",
    "version": 248,
    "popularity": 2910,
    "codepoint": 58671,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "bicycle",
      "bike",
      "direction",
      "directions",
      "human",
      "maps",
      "person",
      "public",
      "route",
      "transportation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_bike",
    "version": 13,
    "popularity": 14598,
    "codepoint": 58671,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bicycle",
      "bike",
      "direction",
      "directions",
      "human",
      "maps",
      "person",
      "public",
      "route",
      "transportation"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_boat",
    "version": 248,
    "popularity": 2063,
    "codepoint": 58674,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "boat",
      "car",
      "cars",
      "direction",
      "directions",
      "ferry",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_boat",
    "version": 17,
    "popularity": 9466,
    "codepoint": 58674,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "boat",
      "car",
      "cars",
      "direction",
      "directions",
      "ferry",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_boat_filled",
    "version": 16,
    "popularity": 3266,
    "codepoint": 61429,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "boat",
      "car",
      "cars",
      "direction",
      "directions",
      "ferry",
      "filled",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_bus",
    "version": 248,
    "popularity": 3318,
    "codepoint": 58672,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bus",
      "car",
      "cars",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_bus",
    "version": 12,
    "popularity": 15576,
    "codepoint": 58672,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bus",
      "car",
      "cars",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_bus_filled",
    "version": 10,
    "popularity": 6817,
    "codepoint": 61430,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bus",
      "car",
      "cars",
      "direction",
      "directions",
      "filled",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_car",
    "version": 248,
    "popularity": 9913,
    "codepoint": 58673,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_car",
    "version": 18,
    "popularity": 38989,
    "codepoint": 58673,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_car_filled",
    "version": 10,
    "popularity": 14357,
    "codepoint": 61431,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "filled",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_off",
    "version": 248,
    "popularity": 135,
    "codepoint": 61711,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directions",
      "disabled",
      "enabled",
      "maps",
      "off",
      "on",
      "right",
      "route",
      "sign",
      "slash",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_off",
    "version": 14,
    "popularity": 983,
    "codepoint": 61711,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "arrow",
      "directions",
      "disabled",
      "enabled",
      "maps",
      "off",
      "on",
      "right",
      "route",
      "sign",
      "slash",
      "traffic"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_railway",
    "version": 248,
    "popularity": 369,
    "codepoint": 58676,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "railway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_railway",
    "version": 13,
    "popularity": 1663,
    "codepoint": 58676,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "railway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_railway_filled",
    "version": 10,
    "popularity": 1148,
    "codepoint": 61432,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "filled",
      "maps",
      "public",
      "railway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_run",
    "version": 248,
    "popularity": 4626,
    "codepoint": 58726,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "body",
      "directions",
      "human",
      "jogging",
      "maps",
      "people",
      "person",
      "route",
      "run",
      "running",
      "walk"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_run",
    "version": 13,
    "popularity": 24504,
    "codepoint": 58726,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "body",
      "directions",
      "human",
      "jogging",
      "maps",
      "people",
      "person",
      "route",
      "run",
      "running",
      "walk"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_subway",
    "version": 248,
    "popularity": 542,
    "codepoint": 58675,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_subway",
    "version": 12,
    "popularity": 1896,
    "codepoint": 58675,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_subway_filled",
    "version": 10,
    "popularity": 1179,
    "codepoint": 61433,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "filled",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_transit",
    "version": 12,
    "popularity": 2543,
    "codepoint": 58677,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transit",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_transit_filled",
    "version": 10,
    "popularity": 1280,
    "codepoint": 61434,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "directions",
      "filled",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transit",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directions_walk",
    "version": 248,
    "popularity": 3884,
    "codepoint": 58678,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "body",
      "direction",
      "directions",
      "human",
      "jogging",
      "maps",
      "people",
      "person",
      "route",
      "run",
      "walk"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "directions_walk",
    "version": 13,
    "popularity": 19782,
    "codepoint": 58678,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "body",
      "direction",
      "directions",
      "human",
      "jogging",
      "maps",
      "people",
      "person",
      "route",
      "run",
      "walk"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "directory_sync",
    "version": 248,
    "popularity": 92,
    "codepoint": 58260,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "around",
      "arrows",
      "direction",
      "directory",
      "navigation",
      "rotate",
      "sync"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dirty_lens",
    "version": 248,
    "popularity": 191,
    "codepoint": 61259,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "dirty",
      "lens",
      "photo",
      "photography",
      "picture",
      "splat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dirty_lens",
    "version": 15,
    "popularity": 1067,
    "codepoint": 61259,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "dirty",
      "lens",
      "photo",
      "photography",
      "picture",
      "splat"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "disabled_by_default",
    "version": 248,
    "popularity": 4919,
    "codepoint": 62000,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "box",
      "by",
      "cancel",
      "clear",
      "close",
      "default",
      "disabled",
      "exit",
      "no",
      "quit",
      "remove",
      "square",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "disabled_by_default",
    "version": 6,
    "popularity": 19177,
    "codepoint": 62000,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "box",
      "by",
      "cancel",
      "clear",
      "close",
      "default",
      "disabled",
      "exit",
      "no",
      "quit",
      "remove",
      "square",
      "stop",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "disabled_visible",
    "version": 248,
    "popularity": 1147,
    "codepoint": 59246,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "cancel",
      "close",
      "disabled",
      "exit",
      "eye",
      "no",
      "on",
      "quit",
      "remove",
      "reveal",
      "see",
      "show",
      "stop",
      "view",
      "visibility",
      "visible"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "disabled_visible",
    "version": 3,
    "popularity": 5173,
    "codepoint": 59246,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cancel",
      "close",
      "disabled",
      "exit",
      "eye",
      "no",
      "on",
      "quit",
      "remove",
      "reveal",
      "see",
      "show",
      "stop",
      "view",
      "visibility",
      "visible"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "disc_full",
    "version": 248,
    "popularity": 338,
    "codepoint": 58896,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "cd",
      "danger",
      "disc",
      "error",
      "exclamation",
      "full",
      "important",
      "mark",
      "music",
      "notification",
      "storage",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "disc_full",
    "version": 12,
    "popularity": 1810,
    "codepoint": 58896,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "cd",
      "danger",
      "disc",
      "error",
      "exclamation",
      "full",
      "important",
      "mark",
      "music",
      "notification",
      "storage",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "discount",
    "version": 1,
    "popularity": 6835,
    "codepoint": 60361,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "discover_tune",
    "version": 248,
    "popularity": 1337,
    "codepoint": 57368,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "adjust",
      "discover",
      "edit",
      "editing",
      "music",
      "options",
      "setting",
      "settings",
      "tune"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dishwasher",
    "version": 248,
    "popularity": 19,
    "codepoint": 59808,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "clean",
      "cleaning",
      "dish",
      "dishwasher",
      "drop",
      "home",
      "house",
      "kitchen",
      "machine",
      "washer",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dishwasher_gen",
    "version": 248,
    "popularity": 414,
    "codepoint": 59442,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "clean",
      "cook",
      "cooking",
      "dishes",
      "dishwasher",
      "electric",
      "home",
      "house",
      "kitchen",
      "machine",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "display_external_input",
    "version": 248,
    "popularity": 15,
    "codepoint": 63463,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrow",
      "frame",
      "layout",
      "link",
      "move",
      "out",
      "output",
      "right",
      "screen",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "display_settings",
    "version": 248,
    "popularity": 1493,
    "codepoint": 60311,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "application",
      "change",
      "chrome",
      "desktop",
      "details",
      "device",
      "display",
      "gear",
      "hardware",
      "iOS",
      "info",
      "information",
      "mac",
      "monitor",
      "options",
      "personal",
      "screen",
      "service",
      "settings",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "display_settings",
    "version": 1,
    "popularity": 5017,
    "codepoint": 60311,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "application",
      "change",
      "chrome",
      "desktop",
      "details",
      "device",
      "display",
      "gear",
      "hardware",
      "iOS",
      "info",
      "information",
      "mac",
      "monitor",
      "options",
      "personal",
      "screen",
      "service",
      "settings",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "distance",
    "version": 248,
    "popularity": 46,
    "codepoint": 63210,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "destination",
      "direction",
      "drop",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "diversity_1",
    "version": 248,
    "popularity": 3453,
    "codepoint": 63703,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "diversity_1",
    "version": 1,
    "popularity": 4997,
    "codepoint": 63703,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "diversity_2",
    "version": 248,
    "popularity": 2662,
    "codepoint": 63704,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "diversity_2",
    "version": 1,
    "popularity": 3357,
    "codepoint": 63704,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "diversity_3",
    "version": 248,
    "popularity": 7114,
    "codepoint": 63705,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "diversity_3",
    "version": 1,
    "popularity": 7326,
    "codepoint": 63705,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "diversity_4",
    "version": 248,
    "popularity": 167,
    "codepoint": 63575,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "committee",
      "diverse",
      "diversity",
      "family",
      "friends",
      "group",
      "groups",
      "heart",
      "humans",
      "network",
      "people",
      "persons",
      "social",
      "team"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dns",
    "version": 248,
    "popularity": 4622,
    "codepoint": 59509,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "address",
      "bars",
      "dns",
      "domain",
      "information",
      "ip",
      "list",
      "lookup",
      "name",
      "server",
      "system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dns",
    "version": 12,
    "popularity": 31870,
    "codepoint": 59509,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "address",
      "bars",
      "dns",
      "domain",
      "information",
      "ip",
      "list",
      "lookup",
      "name",
      "server",
      "system"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_disturb",
    "version": 10,
    "popularity": 5131,
    "codepoint": 61580,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disturb",
      "do",
      "remove",
      "silence",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_disturb_alt",
    "version": 11,
    "popularity": 3668,
    "codepoint": 61581,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disturb",
      "do",
      "remove",
      "silence",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_disturb_off",
    "version": 10,
    "popularity": 1403,
    "codepoint": 61582,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_disturb_on",
    "version": 10,
    "popularity": 6923,
    "codepoint": 61583,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_not_disturb",
    "version": 11,
    "popularity": 8690,
    "codepoint": 58898,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disturb",
      "do",
      "remove",
      "silence",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_not_disturb_alt",
    "version": 12,
    "popularity": 3367,
    "codepoint": 58897,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disturb",
      "do",
      "remove",
      "silence",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_not_disturb_off",
    "version": 248,
    "popularity": 860,
    "codepoint": 58947,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "do_not_disturb_off",
    "version": 11,
    "popularity": 1562,
    "codepoint": 58947,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_not_disturb_on",
    "version": 248,
    "popularity": 8128,
    "codepoint": 58948,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "do_not_disturb_on",
    "version": 11,
    "popularity": 11621,
    "codepoint": 58948,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "cancel",
      "close",
      "denied",
      "deny",
      "disabled",
      "disturb",
      "do",
      "enabled",
      "off",
      "on",
      "remove",
      "silence",
      "slash",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_not_disturb_on_total_silence",
    "version": 248,
    "popularity": 310,
    "codepoint": 61435,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "busy",
      "disturb",
      "do",
      "mute",
      "no",
      "not",
      "on total",
      "quiet",
      "silence"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "do_not_disturb_on_total_silence",
    "version": 15,
    "popularity": 1757,
    "codepoint": 61435,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "busy",
      "disturb",
      "do",
      "mute",
      "no",
      "not",
      "on total",
      "quiet",
      "silence"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_not_step",
    "version": 248,
    "popularity": 267,
    "codepoint": 61855,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "boot",
      "disabled",
      "do",
      "enabled",
      "feet",
      "foot",
      "not",
      "off",
      "on",
      "shoe",
      "slash",
      "sneaker",
      "step",
      "steps"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "do_not_step",
    "version": 8,
    "popularity": 1667,
    "codepoint": 61855,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "boot",
      "disabled",
      "do",
      "enabled",
      "feet",
      "foot",
      "not",
      "off",
      "on",
      "shoe",
      "slash",
      "sneaker",
      "step",
      "steps"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "do_not_touch",
    "version": 248,
    "popularity": 416,
    "codepoint": 61872,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "disabled",
      "do",
      "enabled",
      "fingers",
      "gesture",
      "hand",
      "not",
      "off",
      "on",
      "slash",
      "touch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "do_not_touch",
    "version": 8,
    "popularity": 2455,
    "codepoint": 61872,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "disabled",
      "do",
      "enabled",
      "fingers",
      "gesture",
      "hand",
      "not",
      "off",
      "on",
      "slash",
      "touch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dock",
    "version": 248,
    "popularity": 242,
    "codepoint": 58126,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "charging",
      "connector",
      "device",
      "dock",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "power",
      "station",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dock",
    "version": 12,
    "popularity": 1398,
    "codepoint": 58126,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "charging",
      "connector",
      "device",
      "dock",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "power",
      "station",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dock_to_bottom",
    "version": 248,
    "popularity": 7,
    "codepoint": 63462,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "base",
      "layout",
      "panel",
      "panels",
      "shelf",
      "snap",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dock_to_left",
    "version": 248,
    "popularity": 86,
    "codepoint": 63461,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "layout",
      "panel",
      "panels",
      "side",
      "sidebar",
      "snap",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dock_to_right",
    "version": 248,
    "popularity": 30,
    "codepoint": 63460,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "layout",
      "panel",
      "panels",
      "side",
      "sidebar",
      "snap",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "docs_add_on",
    "version": 248,
    "popularity": 688,
    "codepoint": 61634,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "docs_apps_script",
    "version": 248,
    "popularity": 142,
    "codepoint": 61635,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "document_scanner",
    "version": 248,
    "popularity": 2212,
    "codepoint": 58874,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "scan",
      "scanner",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "document_scanner",
    "version": 3,
    "popularity": 11654,
    "codepoint": 58874,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "scan",
      "scanner",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "domain",
    "version": 248,
    "popularity": 4446,
    "codepoint": 59374,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "apartment",
      "architecture",
      "building",
      "business",
      "domain",
      "estate",
      "home",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "web",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "domain",
    "version": 16,
    "popularity": 14892,
    "codepoint": 59374,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "apartment",
      "architecture",
      "building",
      "business",
      "domain",
      "estate",
      "home",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "web",
      "www"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "domain_add",
    "version": 248,
    "popularity": 872,
    "codepoint": 60258,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "+",
      "add",
      "apartment",
      "architecture",
      "building",
      "business",
      "domain",
      "estate",
      "home",
      "new",
      "place",
      "plus",
      "real",
      "residence",
      "residential",
      "shelter",
      "symbol",
      "web",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "domain_add",
    "version": 1,
    "popularity": 3107,
    "codepoint": 60258,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "add",
      "apartment",
      "architecture",
      "building",
      "business",
      "domain",
      "estate",
      "home",
      "new",
      "place",
      "plus",
      "real",
      "residence",
      "residential",
      "shelter",
      "symbol",
      "web",
      "www"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "domain_disabled",
    "version": 248,
    "popularity": 607,
    "codepoint": 57583,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "apartment",
      "architecture",
      "building",
      "business",
      "company",
      "disabled",
      "domain",
      "enabled",
      "estate",
      "home",
      "internet",
      "maps",
      "off",
      "office",
      "offline",
      "on",
      "place",
      "real",
      "residence",
      "residential",
      "slash",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "domain_disabled",
    "version": 12,
    "popularity": 2433,
    "codepoint": 57583,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "apartment",
      "architecture",
      "building",
      "business",
      "company",
      "disabled",
      "domain",
      "enabled",
      "estate",
      "home",
      "internet",
      "maps",
      "off",
      "office",
      "offline",
      "on",
      "place",
      "real",
      "residence",
      "residential",
      "slash",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "domain_verification",
    "version": 248,
    "popularity": 1024,
    "codepoint": 61260,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "application desktop",
      "approve",
      "check",
      "complete",
      "design",
      "domain",
      "done",
      "interface",
      "internet",
      "layout",
      "mark",
      "ok",
      "screen",
      "select",
      "site",
      "tick",
      "ui",
      "ux",
      "validate",
      "verification",
      "verified",
      "web",
      "website",
      "window",
      "www",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "domain_verification",
    "version": 11,
    "popularity": 5056,
    "codepoint": 61260,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "app",
      "application desktop",
      "approve",
      "check",
      "complete",
      "design",
      "domain",
      "done",
      "interface",
      "internet",
      "layout",
      "mark",
      "ok",
      "screen",
      "select",
      "site",
      "tick",
      "ui",
      "ux",
      "validate",
      "verification",
      "verified",
      "web",
      "website",
      "window",
      "www",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "domain_verification_off",
    "version": 248,
    "popularity": 4,
    "codepoint": 63408,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "application desktop",
      "approve",
      "check",
      "complete",
      "design",
      "disabled",
      "domain",
      "done",
      "enabled",
      "interface",
      "internet",
      "layout",
      "mark",
      "off",
      "offline",
      "ok",
      "on",
      "screen",
      "select",
      "site",
      "slash",
      "tick",
      "ui",
      "ux",
      "validate",
      "verification",
      "verified",
      "web",
      "website",
      "window",
      "www",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "domino_mask",
    "version": 248,
    "popularity": 31,
    "codepoint": 62948,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "carnival",
      "costume",
      "eye mask",
      "google play",
      "masks",
      "superhero"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "done",
    "version": 248,
    "popularity": 86849,
    "codepoint": 59510,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "DISABLE_IOS",
      "approve",
      "check",
      "complete",
      "disable_ios",
      "done",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "done",
    "version": 19,
    "popularity": 492221,
    "codepoint": 59510,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "DISABLE_IOS",
      "approve",
      "check",
      "complete",
      "disable_ios",
      "done",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "done_all",
    "version": 248,
    "popularity": 8807,
    "codepoint": 59511,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "approve",
      "check",
      "complete",
      "done",
      "layers",
      "mark",
      "multiple",
      "ok",
      "select",
      "stack",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "done_all",
    "version": 12,
    "popularity": 50649,
    "codepoint": 59511,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "all",
      "approve",
      "check",
      "complete",
      "done",
      "layers",
      "mark",
      "multiple",
      "ok",
      "select",
      "stack",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "done_outline",
    "version": 248,
    "popularity": 7345,
    "codepoint": 59695,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "approve",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "done_outline",
    "version": 12,
    "popularity": 36315,
    "codepoint": 59695,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "all",
      "approve",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "outline",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "donut_large",
    "version": 248,
    "popularity": 1637,
    "codepoint": 59671,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "donut",
      "graph",
      "infographic",
      "inprogress",
      "large",
      "measure",
      "metrics",
      "pie",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "donut_large",
    "version": 13,
    "popularity": 12583,
    "codepoint": 59671,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "donut",
      "graph",
      "infographic",
      "inprogress",
      "large",
      "measure",
      "metrics",
      "pie",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "donut_small",
    "version": 248,
    "popularity": 1267,
    "codepoint": 59672,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "donut",
      "graph",
      "infographic",
      "inprogress",
      "measure",
      "metrics",
      "pie",
      "small",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "donut_small",
    "version": 12,
    "popularity": 8625,
    "codepoint": 59672,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "donut",
      "graph",
      "infographic",
      "inprogress",
      "measure",
      "metrics",
      "pie",
      "small",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "door_back",
    "version": 248,
    "popularity": 334,
    "codepoint": 61436,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "back",
      "closed",
      "door",
      "doorway",
      "entrance",
      "exit",
      "home",
      "house",
      "way"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "door_back",
    "version": 10,
    "popularity": 3263,
    "codepoint": 61436,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "back",
      "closed",
      "door",
      "doorway",
      "entrance",
      "exit",
      "home",
      "house",
      "way"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "door_front",
    "version": 248,
    "popularity": 1035,
    "codepoint": 61437,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "closed",
      "door",
      "doorway",
      "entrance",
      "exit",
      "front",
      "home",
      "house",
      "way"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "door_front",
    "version": 10,
    "popularity": 5984,
    "codepoint": 61437,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "closed",
      "door",
      "doorway",
      "entrance",
      "exit",
      "front",
      "home",
      "house",
      "way"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "door_open",
    "version": 248,
    "popularity": 1251,
    "codepoint": 59260,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "clock",
      "device",
      "home",
      "nest",
      "open"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "door_sensor",
    "version": 248,
    "popularity": 147,
    "codepoint": 57994,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "door",
      "handle",
      "home",
      "nest",
      "security",
      "sensor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "door_sliding",
    "version": 248,
    "popularity": 376,
    "codepoint": 61438,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "auto",
      "automatic",
      "door",
      "doorway",
      "double",
      "entrance",
      "exit",
      "glass",
      "home",
      "house",
      "sliding",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "door_sliding",
    "version": 10,
    "popularity": 3363,
    "codepoint": 61438,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "auto",
      "automatic",
      "door",
      "doorway",
      "double",
      "entrance",
      "exit",
      "glass",
      "home",
      "house",
      "sliding",
      "two"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "doorbell",
    "version": 248,
    "popularity": 334,
    "codepoint": 61439,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alarm",
      "bell",
      "door",
      "doorbell",
      "home",
      "house",
      "ringing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "doorbell",
    "version": 10,
    "popularity": 3056,
    "codepoint": 61439,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "alarm",
      "bell",
      "door",
      "doorbell",
      "home",
      "house",
      "ringing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "doorbell_3p",
    "version": 248,
    "popularity": 161,
    "codepoint": 57831,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "chime",
      "doorbell",
      "nest",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "doorbell_chime",
    "version": 248,
    "popularity": 127,
    "codepoint": 57843,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "chime",
      "doorbell",
      "home",
      "nest",
      "security"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "double_arrow",
    "version": 248,
    "popularity": 7753,
    "codepoint": 59984,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "double_arrow",
    "version": 11,
    "popularity": 32452,
    "codepoint": 59984,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "right"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "downhill_skiing",
    "version": 248,
    "popularity": 709,
    "codepoint": 58633,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "downhill",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "ski social",
      "skiing",
      "snow",
      "sports",
      "travel",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "downhill_skiing",
    "version": 4,
    "popularity": 3501,
    "codepoint": 58633,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "downhill",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "ski social",
      "skiing",
      "snow",
      "sports",
      "travel",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "download",
    "version": 248,
    "popularity": 18222,
    "codepoint": 61584,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "download",
    "version": 10,
    "popularity": 72165,
    "codepoint": 61584,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "download_2",
    "version": 248,
    "popularity": 9,
    "codepoint": 62755,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "download_done",
    "version": 248,
    "popularity": 2216,
    "codepoint": 61585,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "check",
      "done",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "installed",
      "ok",
      "tick",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "download_done",
    "version": 10,
    "popularity": 9254,
    "codepoint": 61585,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "arrows",
      "check",
      "done",
      "down",
      "download",
      "downloads",
      "drive",
      "install",
      "installed",
      "ok",
      "tick",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "download_for_offline",
    "version": 248,
    "popularity": 4176,
    "codepoint": 61440,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "down",
      "download",
      "for offline",
      "install",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "download_for_offline",
    "version": 9,
    "popularity": 17486,
    "codepoint": 61440,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "circle",
      "down",
      "download",
      "for offline",
      "install",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "downloading",
    "version": 248,
    "popularity": 3440,
    "codepoint": 61441,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "down",
      "download",
      "downloading",
      "downloads",
      "install",
      "pending",
      "progress",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "downloading",
    "version": 9,
    "popularity": 13307,
    "codepoint": 61441,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "circle",
      "down",
      "download",
      "downloading",
      "downloads",
      "install",
      "pending",
      "progress",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "draft",
    "version": 248,
    "popularity": 5989,
    "codepoint": 58989,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "draft",
      "drive",
      "file",
      "folder",
      "folders",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "draft_orders",
    "version": 248,
    "popularity": 32,
    "codepoint": 59315,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "compose",
      "create",
      "document",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drafts",
    "version": 248,
    "popularity": 2635,
    "codepoint": 57681,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "document",
      "draft",
      "drafts",
      "email",
      "file",
      "letter",
      "mail",
      "message",
      "read"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drafts",
    "version": 17,
    "popularity": 13763,
    "codepoint": 57681,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "document",
      "draft",
      "drafts",
      "email",
      "file",
      "letter",
      "mail",
      "message",
      "read"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "drag_click",
    "version": 248,
    "popularity": 24,
    "codepoint": 63263,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circles",
      "clicks",
      "mouse",
      "move",
      "ripples",
      "select",
      "selection",
      "selects"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drag_handle",
    "version": 248,
    "popularity": 3723,
    "codepoint": 57949,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application ui",
      "components",
      "design",
      "drag",
      "handle",
      "interface",
      "layout",
      "menu",
      "move",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drag_handle",
    "version": 14,
    "popularity": 26382,
    "codepoint": 57949,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "app",
      "application ui",
      "components",
      "design",
      "drag",
      "handle",
      "interface",
      "layout",
      "menu",
      "move",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "drag_indicator",
    "version": 248,
    "popularity": 6918,
    "codepoint": 59717,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "circles",
      "components",
      "design",
      "dots",
      "drag",
      "drop",
      "indicator",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "move",
      "phone",
      "screen",
      "shape",
      "shift",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drag_indicator",
    "version": 15,
    "popularity": 41546,
    "codepoint": 59717,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "circles",
      "components",
      "design",
      "dots",
      "drag",
      "drop",
      "indicator",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "move",
      "phone",
      "screen",
      "shape",
      "shift",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "drag_pan",
    "version": 248,
    "popularity": 50,
    "codepoint": 63262,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "clicks",
      "direction",
      "expand",
      "mouse",
      "move",
      "open",
      "select",
      "selection",
      "selects",
      "with"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "draw",
    "version": 248,
    "popularity": 5604,
    "codepoint": 59206,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compose",
      "create",
      "design",
      "draft",
      "draw",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "draw",
    "version": 6,
    "popularity": 12410,
    "codepoint": 59206,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "compose",
      "create",
      "design",
      "draft",
      "draw",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "draw_abstract",
    "version": 248,
    "popularity": 42,
    "codepoint": 63480,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "abstraction",
      "compose",
      "craft",
      "create",
      "design",
      "draft",
      "draw",
      "drawing",
      "edit",
      "editing",
      "gesture",
      "input",
      "shape",
      "shapes",
      "squiggle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "draw_collage",
    "version": 248,
    "popularity": 14,
    "codepoint": 63479,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "abstraction",
      "compose",
      "craft",
      "create",
      "design",
      "draft",
      "draw",
      "drawing",
      "edit",
      "editing",
      "gesture",
      "input",
      "shape",
      "shapes",
      "squiggle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dresser",
    "version": 248,
    "popularity": 339,
    "codepoint": 57872,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "dresser",
      "furniture",
      "home",
      "hotel",
      "house",
      "mirror",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drive_eta",
    "version": 12,
    "popularity": 14216,
    "codepoint": 58899,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "destination",
      "direction",
      "drive",
      "estimate",
      "eta",
      "maps",
      "public",
      "transportation",
      "travel",
      "trip",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "drive_file_move",
    "version": 248,
    "popularity": 1034,
    "codepoint": 58997,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "move",
      "right",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drive_file_move",
    "version": 19,
    "popularity": 8040,
    "codepoint": 58997,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "move",
      "right",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "drive_file_move_outline",
    "version": 12,
    "popularity": 883,
    "codepoint": 59809,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "move",
      "outline",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "drive_file_move_rtl",
    "version": 3,
    "popularity": 2316,
    "codepoint": 59245,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "arrows",
      "data",
      "direction",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "left",
      "move",
      "rtl",
      "sheet",
      "side",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "drive_file_rename_outline",
    "version": 12,
    "popularity": 25403,
    "codepoint": 59810,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "input",
      "marker",
      "pen",
      "pencil",
      "rename",
      "write",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "drive_folder_upload",
    "version": 248,
    "popularity": 1058,
    "codepoint": 59811,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "storage",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "drive_folder_upload",
    "version": 11,
    "popularity": 7306,
    "codepoint": 59811,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "storage",
      "up",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dropdown",
    "version": 248,
    "popularity": 26,
    "codepoint": 59812,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "dropdown",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "squares",
      "tablet",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dry",
    "version": 248,
    "popularity": 201,
    "codepoint": 61875,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air",
      "bathroom",
      "dry",
      "dryer",
      "fingers",
      "gesture",
      "hand",
      "wc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dry",
    "version": 8,
    "popularity": 1552,
    "codepoint": 61875,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "air",
      "bathroom",
      "dry",
      "dryer",
      "fingers",
      "gesture",
      "hand",
      "wc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dry_cleaning",
    "version": 248,
    "popularity": 649,
    "codepoint": 59992,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "cleaning",
      "dry",
      "hanger",
      "hotel",
      "laundry",
      "places",
      "service",
      "towel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dry_cleaning",
    "version": 10,
    "popularity": 3734,
    "codepoint": 59992,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "cleaning",
      "dry",
      "hanger",
      "hotel",
      "laundry",
      "places",
      "service",
      "towel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dual_screen",
    "version": 248,
    "popularity": 79,
    "codepoint": 63183,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "android",
      "device",
      "duo",
      "foldable",
      "folding",
      "phone",
      "screens"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "duo",
    "version": 248,
    "popularity": 697,
    "codepoint": 59813,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "chat",
      "conference",
      "device",
      "duo",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "duo",
    "version": 12,
    "popularity": 3596,
    "codepoint": 59813,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "chat",
      "conference",
      "device",
      "duo",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dvr",
    "version": 248,
    "popularity": 2031,
    "codepoint": 57778,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "Android",
      "OS",
      "audio",
      "chrome",
      "computer",
      "desktop",
      "device",
      "display",
      "dvr",
      "electronic",
      "hardware",
      "iOS",
      "list",
      "mac",
      "monitor",
      "record",
      "recorder",
      "screen",
      "tv",
      "video",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dvr",
    "version": 13,
    "popularity": 11689,
    "codepoint": 57778,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "audio",
      "chrome",
      "computer",
      "desktop",
      "device",
      "display",
      "dvr",
      "electronic",
      "hardware",
      "iOS",
      "list",
      "mac",
      "monitor",
      "record",
      "recorder",
      "screen",
      "tv",
      "video",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "dynamic_feed",
    "version": 248,
    "popularity": 1879,
    "codepoint": 59924,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "'mail_outline'",
      "'markunread'. Keep 'mail' and remove others.",
      "Duplicate of 'email'"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dynamic_feed",
    "version": 15,
    "popularity": 8977,
    "codepoint": 59924,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "'mail_outline'",
      "'markunread'. Keep 'mail' and remove others.",
      "Duplicate of 'email'"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "dynamic_form",
    "version": 248,
    "popularity": 1757,
    "codepoint": 61887,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bolt",
      "code",
      "dynamic",
      "electric",
      "fast",
      "form",
      "lightning",
      "lists",
      "questionnaire",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "dynamic_form",
    "version": 7,
    "popularity": 6998,
    "codepoint": 61887,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bolt",
      "code",
      "dynamic",
      "electric",
      "fast",
      "form",
      "lightning",
      "lists",
      "questionnaire",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "e911_avatar",
    "version": 248,
    "popularity": 220,
    "codepoint": 61722,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "e911_emergency",
    "version": 248,
    "popularity": 1061,
    "codepoint": 61721,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "e_mobiledata",
    "version": 248,
    "popularity": 128,
    "codepoint": 61442,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "data",
      "e",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "e_mobiledata",
    "version": 9,
    "popularity": 742,
    "codepoint": 61442,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alphabet",
      "data",
      "e",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "e_mobiledata_badge",
    "version": 248,
    "popularity": 4,
    "codepoint": 63459,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "data",
      "e",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "earbuds",
    "version": 248,
    "popularity": 298,
    "codepoint": 61443,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "earbuds",
      "earphone",
      "headphone",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "earbuds",
    "version": 9,
    "popularity": 2021,
    "codepoint": 61443,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "earbuds",
      "earphone",
      "headphone",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "earbuds_battery",
    "version": 248,
    "popularity": 189,
    "codepoint": 61444,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "battery",
      "charging",
      "earbuds",
      "earphone",
      "headphone",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "earbuds_battery",
    "version": 9,
    "popularity": 1285,
    "codepoint": 61444,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "battery",
      "charging",
      "earbuds",
      "earphone",
      "headphone",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "early_on",
    "version": 248,
    "popularity": 237,
    "codepoint": 58042,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "calendar",
      "climate",
      "early",
      "home",
      "nest",
      "on"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "earthquake",
    "version": 248,
    "popularity": 27,
    "codepoint": 63055,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "aftershock",
      "chart",
      "disaster",
      "measure",
      "monitor",
      "shake",
      "tremor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "east",
    "version": 248,
    "popularity": 4959,
    "codepoint": 61919,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directional",
      "east",
      "maps",
      "navigation",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "east",
    "version": 7,
    "popularity": 61662,
    "codepoint": 61919,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directional",
      "east",
      "maps",
      "navigation",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "ecg",
    "version": 248,
    "popularity": 20,
    "codepoint": 63503,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "cardio",
      "doc",
      "doctor",
      "electrocardiogram",
      "health",
      "heart",
      "medical",
      "monitor",
      "nurse",
      "rate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ecg_heart",
    "version": 248,
    "popularity": 128,
    "codepoint": 63209,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "cardio",
      "doc",
      "doctor",
      "electrocardiogram",
      "fitbit",
      "health",
      "heart",
      "heart rhythm",
      "medical",
      "monitor",
      "nurse",
      "pulse",
      "rate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "eco",
    "version": 248,
    "popularity": 6680,
    "codepoint": 59957,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "eco",
      "economical",
      "green",
      "leaf",
      "nature",
      "sustainable"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "eda",
    "version": 248,
    "popularity": 20,
    "codepoint": 63208,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "back",
      "electrodermal",
      "electrodermal activity responses",
      "electrodermal responses",
      "fingers",
      "fitbit",
      "gesture",
      "hand",
      "heart rate",
      "palm",
      "raised"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edgesensor_high",
    "version": 248,
    "popularity": 259,
    "codepoint": 61445,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "edge",
      "hardware",
      "high",
      "iOS",
      "mobile",
      "move",
      "phone",
      "sensitivity",
      "sensor",
      "tablet",
      "vibrate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edgesensor_high",
    "version": 10,
    "popularity": 0,
    "codepoint": 61445,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "edge",
      "hardware",
      "high",
      "iOS",
      "mobile",
      "move",
      "phone",
      "sensitivity",
      "sensor",
      "tablet",
      "vibrate"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "edgesensor_low",
    "version": 248,
    "popularity": 192,
    "codepoint": 61446,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "Android",
      "cell",
      "device",
      "edge",
      "hardware",
      "iOS",
      "low",
      "mobile",
      "move",
      "phone",
      "sensitivity",
      "sensor",
      "tablet",
      "vibrate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edgesensor_low",
    "version": 10,
    "popularity": 1120,
    "codepoint": 61446,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "cell",
      "device",
      "edge",
      "hardware",
      "iOS",
      "low",
      "mobile",
      "move",
      "phone",
      "sensitivity",
      "sensor",
      "tablet",
      "vibrate"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "edit",
    "version": 248,
    "popularity": 47916,
    "codepoint": 58313,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "compose",
      "create",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit",
    "version": 12,
    "popularity": 292529,
    "codepoint": 58313,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "compose",
      "create",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "edit_attributes",
    "version": 248,
    "popularity": 523,
    "codepoint": 58744,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "approve",
      "attribution",
      "check",
      "complete",
      "done",
      "edit",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_attributes",
    "version": 12,
    "popularity": 0,
    "codepoint": 58744,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "approve",
      "attribution",
      "check",
      "complete",
      "done",
      "edit",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "edit_calendar",
    "version": 248,
    "popularity": 4372,
    "codepoint": 59202,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "calendar",
      "compose",
      "create",
      "date",
      "day",
      "draft",
      "edit",
      "editing",
      "event",
      "month",
      "pen",
      "pencil",
      "schedule",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_calendar",
    "version": 5,
    "popularity": 28702,
    "codepoint": 59202,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "compose",
      "create",
      "date",
      "day",
      "draft",
      "edit",
      "editing",
      "event",
      "month",
      "pen",
      "pencil",
      "schedule",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "edit_document",
    "version": 248,
    "popularity": 1953,
    "codepoint": 63628,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compose",
      "create",
      "doc",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "folder",
      "folders",
      "input",
      "page",
      "paper",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_document",
    "version": 1,
    "popularity": 6622,
    "codepoint": 63628,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "compose",
      "create",
      "doc",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "folder",
      "folders",
      "input",
      "page",
      "paper",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "edit_location",
    "version": 248,
    "popularity": 780,
    "codepoint": 58728,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "edit",
      "location",
      "maps",
      "pen",
      "pencil",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_location",
    "version": 16,
    "popularity": 4040,
    "codepoint": 58728,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "edit",
      "location",
      "maps",
      "pen",
      "pencil",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "edit_location_alt",
    "version": 248,
    "popularity": 594,
    "codepoint": 57797,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "alt",
      "edit",
      "location",
      "pen",
      "pencil",
      "pin"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_location_alt",
    "version": 8,
    "popularity": 3647,
    "codepoint": 57797,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alt",
      "edit",
      "location",
      "pen",
      "pencil",
      "pin"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "edit_note",
    "version": 248,
    "popularity": 14409,
    "codepoint": 59205,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "lines",
      "note",
      "pen",
      "pencil",
      "text",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_note",
    "version": 5,
    "popularity": 54652,
    "codepoint": 59205,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "lines",
      "note",
      "pen",
      "pencil",
      "text",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "edit_notifications",
    "version": 248,
    "popularity": 869,
    "codepoint": 58661,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "new",
      "notifications",
      "notify",
      "pen",
      "pencil",
      "reminder",
      "ring",
      "sound",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_notifications",
    "version": 4,
    "popularity": 4249,
    "codepoint": 58661,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "new",
      "notifications",
      "notify",
      "pen",
      "pencil",
      "reminder",
      "ring",
      "sound",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "edit_off",
    "version": 248,
    "popularity": 850,
    "codepoint": 59728,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compose",
      "create",
      "disabled",
      "draft",
      "edit",
      "editing",
      "enabled",
      "input",
      "new",
      "off",
      "offline",
      "on",
      "pen",
      "pencil",
      "slash",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_off",
    "version": 15,
    "popularity": 8164,
    "codepoint": 59728,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "compose",
      "create",
      "disabled",
      "draft",
      "edit",
      "editing",
      "enabled",
      "input",
      "new",
      "off",
      "offline",
      "on",
      "pen",
      "pencil",
      "slash",
      "write",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "edit_road",
    "version": 248,
    "popularity": 441,
    "codepoint": 61261,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "edit",
      "highway",
      "maps",
      "pen",
      "pencil",
      "road",
      "street",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_road",
    "version": 11,
    "popularity": 3700,
    "codepoint": 61261,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "edit",
      "highway",
      "maps",
      "pen",
      "pencil",
      "road",
      "street",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "edit_square",
    "version": 248,
    "popularity": 4231,
    "codepoint": 63629,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "square",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "edit_square",
    "version": 1,
    "popularity": 4883,
    "codepoint": 63629,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "input",
      "pen",
      "pencil",
      "square",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "editor_choice",
    "version": 248,
    "popularity": 21,
    "codepoint": 62760,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "approve",
      "award",
      "badge",
      "champion",
      "check",
      "circle",
      "completed",
      "first",
      "increase",
      "mark",
      "ok",
      "plus",
      "prize",
      "reward",
      "ribbon",
      "select",
      "sport",
      "task",
      "tick",
      "trophy",
      "win",
      "winner",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "egg",
    "version": 248,
    "popularity": 1068,
    "codepoint": 60108,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "egg",
      "food"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "egg",
    "version": 2,
    "popularity": 2421,
    "codepoint": 60108,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "egg",
      "food"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "egg_alt",
    "version": 248,
    "popularity": 739,
    "codepoint": 60104,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "egg",
      "food"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "egg_alt",
    "version": 2,
    "popularity": 1345,
    "codepoint": 60104,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "egg",
      "food"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "eject",
    "version": 248,
    "popularity": 950,
    "codepoint": 59643,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "disc",
      "drive",
      "dvd",
      "eject",
      "remove",
      "triangle",
      "usb"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "eject",
    "version": 11,
    "popularity": 3879,
    "codepoint": 59643,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "disc",
      "drive",
      "dvd",
      "eject",
      "remove",
      "triangle",
      "usb"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "elderly",
    "version": 248,
    "popularity": 1336,
    "codepoint": 61978,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "cane",
      "elderly",
      "human",
      "old",
      "people",
      "person",
      "senior"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "elderly",
    "version": 7,
    "popularity": 5602,
    "codepoint": 61978,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "cane",
      "elderly",
      "human",
      "old",
      "people",
      "person",
      "senior"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "elderly_woman",
    "version": 248,
    "popularity": 955,
    "codepoint": 60265,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "cane",
      "elderly",
      "female",
      "gender",
      "girl",
      "human",
      "lady",
      "old",
      "people",
      "person",
      "senior",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "elderly_woman",
    "version": 1,
    "popularity": 1940,
    "codepoint": 60265,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "cane",
      "elderly",
      "female",
      "gender",
      "girl",
      "human",
      "lady",
      "old",
      "people",
      "person",
      "senior",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electric_bike",
    "version": 248,
    "popularity": 476,
    "codepoint": 60187,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "bike",
      "electric",
      "electricity",
      "maps",
      "scooter",
      "transportation",
      "travel",
      "vespa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electric_bike",
    "version": 14,
    "popularity": 2593,
    "codepoint": 60187,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bike",
      "electric",
      "electricity",
      "maps",
      "scooter",
      "transportation",
      "travel",
      "vespa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electric_bolt",
    "version": 248,
    "popularity": 2551,
    "codepoint": 60444,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bolt",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "nest",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electric_bolt",
    "version": 1,
    "popularity": 5597,
    "codepoint": 60444,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "bolt",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "nest",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electric_car",
    "version": 248,
    "popularity": 1243,
    "codepoint": 60188,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "electric",
      "electricity",
      "maps",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electric_car",
    "version": 14,
    "popularity": 4463,
    "codepoint": 60188,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "electric",
      "electricity",
      "maps",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electric_meter",
    "version": 248,
    "popularity": 710,
    "codepoint": 60443,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bolt",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "measure",
      "meter",
      "nest",
      "thunderbolt",
      "usage",
      "voltage",
      "volts"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electric_meter",
    "version": 1,
    "popularity": 1826,
    "codepoint": 60443,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "bolt",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "measure",
      "meter",
      "nest",
      "thunderbolt",
      "usage",
      "voltage",
      "volts"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electric_moped",
    "version": 248,
    "popularity": 637,
    "codepoint": 60189,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "electric",
      "maps",
      "moped",
      "scooter",
      "transportation",
      "travel",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electric_moped",
    "version": 14,
    "popularity": 1570,
    "codepoint": 60189,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "electric",
      "maps",
      "moped",
      "scooter",
      "transportation",
      "travel",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electric_rickshaw",
    "version": 248,
    "popularity": 361,
    "codepoint": 60190,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "electric",
      "india",
      "maps",
      "rickshaw",
      "transportation",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electric_rickshaw",
    "version": 15,
    "popularity": 1829,
    "codepoint": 60190,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "electric",
      "india",
      "maps",
      "rickshaw",
      "transportation",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electric_scooter",
    "version": 248,
    "popularity": 558,
    "codepoint": 60191,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "bike",
      "electric",
      "maps",
      "scooter",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electric_scooter",
    "version": 14,
    "popularity": 2457,
    "codepoint": 60191,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bike",
      "electric",
      "maps",
      "scooter",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "electrical_services",
    "version": 248,
    "popularity": 1721,
    "codepoint": 61698,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "charge",
      "cord",
      "electric",
      "electrical",
      "plug",
      "power",
      "services",
      "wire"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "electrical_services",
    "version": 12,
    "popularity": 9893,
    "codepoint": 61698,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "charge",
      "cord",
      "electric",
      "electrical",
      "plug",
      "power",
      "services",
      "wire"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "elevation",
    "version": 248,
    "popularity": 18,
    "codepoint": 63207,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "fitness",
      "health",
      "hill",
      "hills",
      "incline",
      "inclines",
      "mountain",
      "mountains"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "elevator",
    "version": 248,
    "popularity": 626,
    "codepoint": 61856,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "body",
      "down",
      "elevator",
      "human",
      "people",
      "person",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "elevator",
    "version": 8,
    "popularity": 3497,
    "codepoint": 61856,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "body",
      "down",
      "elevator",
      "human",
      "people",
      "person",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "email",
    "version": 18,
    "popularity": 259360,
    "codepoint": 57534,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "email",
      "envelop",
      "letter",
      "mail",
      "message",
      "send"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "emergency",
    "version": 248,
    "popularity": 2945,
    "codepoint": 57835,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "asterisk",
      "clinic",
      "emergency",
      "health",
      "hospital",
      "maps",
      "medical",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emergency",
    "version": 5,
    "popularity": 8602,
    "codepoint": 57835,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "asterisk",
      "clinic",
      "emergency",
      "health",
      "hospital",
      "maps",
      "medical",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emergency_heat",
    "version": 248,
    "popularity": 309,
    "codepoint": 61789,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "!",
      "911",
      "alert",
      "attention",
      "caution",
      "climate",
      "danger",
      "error",
      "exclamation",
      "fire",
      "flame",
      "heat",
      "high",
      "home",
      "hot",
      "important",
      "mark",
      "nest",
      "notification",
      "symbol",
      "thermostat",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emergency_heat_2",
    "version": 248,
    "popularity": 2,
    "codepoint": 62693,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "!",
      "air",
      "airwave",
      "alert",
      "attention",
      "bismuth",
      "blowing",
      "breeze",
      "caution",
      "climate",
      "danger",
      "error",
      "exclamation",
      "flow",
      "high",
      "home",
      "hot",
      "important",
      "mark",
      "nest",
      "notification",
      "steam",
      "symbol",
      "temperature",
      "thermostat",
      "warning",
      "wave",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emergency_home",
    "version": 248,
    "popularity": 1933,
    "codepoint": 59434,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alert",
      "attention",
      "caution",
      "danger",
      "emergency",
      "error",
      "exclamation",
      "home",
      "house",
      "important",
      "mark",
      "nest",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emergency_recording",
    "version": 248,
    "popularity": 249,
    "codepoint": 60404,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alert",
      "attention",
      "camera",
      "caution",
      "danger",
      "emergency",
      "film",
      "filming",
      "hardware",
      "image",
      "important",
      "motion",
      "notification",
      "picture",
      "record",
      "video",
      "videography",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emergency_recording",
    "version": 1,
    "popularity": 629,
    "codepoint": 60404,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alert",
      "attention",
      "camera",
      "caution",
      "danger",
      "emergency",
      "film",
      "filming",
      "hardware",
      "image",
      "important",
      "motion",
      "notification",
      "picture",
      "record",
      "video",
      "videography",
      "warning"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emergency_share",
    "version": 248,
    "popularity": 308,
    "codepoint": 60406,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alert",
      "attention",
      "caution",
      "danger",
      "emergency",
      "important",
      "notification",
      "share",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emergency_share",
    "version": 1,
    "popularity": 1029,
    "codepoint": 60406,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alert",
      "attention",
      "caution",
      "danger",
      "emergency",
      "important",
      "notification",
      "share",
      "warning"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emergency_share_off",
    "version": 248,
    "popularity": 4,
    "codepoint": 62878,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alert",
      "attention",
      "caution",
      "danger",
      "disabled",
      "emergency",
      "enabled",
      "important",
      "notification",
      "offline",
      "on",
      "share",
      "slash",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_emotions",
    "version": 11,
    "popularity": 31271,
    "codepoint": 59938,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "add",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "icon",
      "icons",
      "insert",
      "like",
      "mood",
      "new",
      "person",
      "pleased",
      "plus",
      "smile",
      "smiling",
      "social",
      "survey",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoji_events",
    "version": 248,
    "popularity": 1040,
    "codepoint": 59939,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "achievement",
      "award",
      "chalice",
      "champion",
      "cup",
      "emoji",
      "events",
      "first",
      "prize",
      "reward",
      "sport",
      "trophy",
      "winner"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_events",
    "version": 13,
    "popularity": 75607,
    "codepoint": 59939,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "achievement",
      "award",
      "chalice",
      "champion",
      "cup",
      "emoji",
      "events",
      "first",
      "prize",
      "reward",
      "sport",
      "trophy",
      "winner"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoji_flags",
    "version": 248,
    "popularity": 1691,
    "codepoint": 59930,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "country",
      "destination",
      "emoji",
      "flags",
      "landmark",
      "location",
      "mark",
      "milepost",
      "milestone",
      "nation",
      "place",
      "pole",
      "save",
      "social",
      "world"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_food_beverage",
    "version": 248,
    "popularity": 1384,
    "codepoint": 59931,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "beverage",
      "coffee",
      "cup",
      "drink",
      "emoji",
      "mug",
      "plate",
      "set",
      "tea"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_food_beverage",
    "version": 11,
    "popularity": 6225,
    "codepoint": 59931,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "beverage",
      "coffee",
      "cup",
      "drink",
      "emoji",
      "mug",
      "plate",
      "set",
      "tea"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoji_nature",
    "version": 248,
    "popularity": 2160,
    "codepoint": 59932,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "animal",
      "bee",
      "bug",
      "daisy",
      "emoji",
      "flower",
      "insect",
      "ladybug",
      "nature",
      "petals",
      "spring",
      "summer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_nature",
    "version": 11,
    "popularity": 8793,
    "codepoint": 59932,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "animal",
      "bee",
      "bug",
      "daisy",
      "emoji",
      "flower",
      "insect",
      "ladybug",
      "nature",
      "petals",
      "spring",
      "summer"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoji_objects",
    "version": 248,
    "popularity": 6913,
    "codepoint": 59940,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bulb",
      "creative",
      "emoji",
      "idea",
      "light",
      "objects",
      "solution",
      "thinking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_objects",
    "version": 11,
    "popularity": 24846,
    "codepoint": 59940,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bulb",
      "creative",
      "emoji",
      "idea",
      "light",
      "objects",
      "solution",
      "thinking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoji_people",
    "version": 248,
    "popularity": 3785,
    "codepoint": 59933,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arm",
      "body",
      "emoji",
      "greeting",
      "human",
      "people",
      "person",
      "social",
      "waving"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_people",
    "version": 11,
    "popularity": 17927,
    "codepoint": 59933,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arm",
      "body",
      "emoji",
      "greeting",
      "human",
      "people",
      "person",
      "social",
      "waving"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoji_symbols",
    "version": 248,
    "popularity": 771,
    "codepoint": 59934,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "ampersand",
      "character",
      "emoji",
      "hieroglyph",
      "music",
      "note",
      "percent",
      "sign",
      "symbols"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_symbols",
    "version": 11,
    "popularity": 6282,
    "codepoint": 59934,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "ampersand",
      "character",
      "emoji",
      "hieroglyph",
      "music",
      "note",
      "percent",
      "sign",
      "symbols"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoji_transportation",
    "version": 248,
    "popularity": 1371,
    "codepoint": 59935,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "architecture",
      "automobile",
      "building",
      "car",
      "cars",
      "direction",
      "emoji",
      "estate",
      "maps",
      "place",
      "public",
      "real",
      "residence",
      "residential",
      "shelter",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "emoji_transportation",
    "version": 11,
    "popularity": 6030,
    "codepoint": 59935,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "architecture",
      "automobile",
      "building",
      "car",
      "cars",
      "direction",
      "emoji",
      "estate",
      "maps",
      "place",
      "public",
      "real",
      "residence",
      "residential",
      "shelter",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "emoticon",
    "version": 248,
    "popularity": 23,
    "codepoint": 58867,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "+",
      "add",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "icon",
      "icons",
      "insert",
      "like",
      "mood",
      "new",
      "person",
      "pleased",
      "plus",
      "smile",
      "smiling",
      "social",
      "survey",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "empty_dashboard",
    "version": 248,
    "popularity": 142,
    "codepoint": 63556,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "cards",
      "dashboard",
      "empty",
      "format",
      "layout",
      "rectangle",
      "shapes",
      "square",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "enable",
    "version": 248,
    "popularity": 1297,
    "codepoint": 61832,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "down",
      "enable",
      "pixel",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "encrypted",
    "version": 248,
    "popularity": 561,
    "codepoint": 58771,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "certified",
      "encrypted",
      "encryption",
      "key",
      "latch",
      "password",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "secure",
      "security",
      "shield",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "endocrinology",
    "version": 248,
    "popularity": 3,
    "codepoint": 57513,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "gland",
      "glands",
      "health",
      "human",
      "medical",
      "neck",
      "throat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "energy",
    "version": 248,
    "popularity": 47,
    "codepoint": 59814,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "electricity",
      "energy",
      "environment",
      "generator",
      "green",
      "power",
      "turbine",
      "windmill"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "energy_program_saving",
    "version": 248,
    "popularity": 408,
    "codepoint": 61791,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "energy_program_time_used",
    "version": 248,
    "popularity": 358,
    "codepoint": 61793,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "energy_savings_leaf",
    "version": 248,
    "popularity": 1225,
    "codepoint": 60442,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "eco",
      "energy",
      "leaf",
      "leaves",
      "nest",
      "savings",
      "usage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "energy_savings_leaf",
    "version": 1,
    "popularity": 3482,
    "codepoint": 60442,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "eco",
      "energy",
      "leaf",
      "leaves",
      "nest",
      "savings",
      "usage"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "engineering",
    "version": 248,
    "popularity": 7868,
    "codepoint": 59965,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "cogs",
      "cogwheel",
      "construction",
      "engineering",
      "fixing",
      "gears",
      "hat",
      "helmet",
      "human",
      "maintenance",
      "people",
      "person",
      "setting",
      "worker"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "engineering",
    "version": 11,
    "popularity": 48819,
    "codepoint": 59965,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "cogs",
      "cogwheel",
      "construction",
      "engineering",
      "fixing",
      "gears",
      "hat",
      "helmet",
      "human",
      "maintenance",
      "people",
      "person",
      "setting",
      "worker"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "enhanced_encryption",
    "version": 248,
    "popularity": 972,
    "codepoint": 58943,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "+",
      "add",
      "encryption",
      "enhanced",
      "lock",
      "locked",
      "new",
      "password",
      "plus",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "enhanced_encryption",
    "version": 19,
    "popularity": 6009,
    "codepoint": 58943,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "+",
      "add",
      "encryption",
      "enhanced",
      "lock",
      "locked",
      "new",
      "password",
      "plus",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "ent",
    "version": 248,
    "popularity": 12,
    "codepoint": 57514,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "brain",
      "ear",
      "head",
      "health",
      "human",
      "idea",
      "ideas",
      "nose",
      "people",
      "person",
      "psychology",
      "thought",
      "thoughts",
      "throat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "enterprise",
    "version": 248,
    "popularity": 106,
    "codepoint": 59150,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "enterprise",
      "purse",
      "suitcase"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "enterprise_off",
    "version": 248,
    "popularity": 9,
    "codepoint": 60237,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "disabled",
      "enabled",
      "enterprise",
      "off",
      "on",
      "purse",
      "slash",
      "suitcase"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "equal",
    "version": 248,
    "popularity": 117,
    "codepoint": 63355,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "calculate",
      "calculator",
      "count",
      "equality",
      "equals",
      "equation",
      "math",
      "mathmatical",
      "operation",
      "same",
      "sum",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "equalizer",
    "version": 248,
    "popularity": 3269,
    "codepoint": 57373,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "adjustment",
      "analytics",
      "chart",
      "data",
      "equalizer",
      "graph",
      "measure",
      "metrics",
      "music",
      "noise",
      "sound",
      "static",
      "statistics",
      "tracking",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "equalizer",
    "version": 12,
    "popularity": 18658,
    "codepoint": 57373,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "adjustment",
      "analytics",
      "chart",
      "data",
      "equalizer",
      "graph",
      "measure",
      "metrics",
      "music",
      "noise",
      "sound",
      "static",
      "statistics",
      "tracking",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "error",
    "version": 248,
    "popularity": 27057,
    "codepoint": 57344,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "circle",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "error",
    "version": 20,
    "popularity": 100676,
    "codepoint": 57344,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "alert"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "circle",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "error_med",
    "version": 248,
    "popularity": 12,
    "codepoint": 58523,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "health",
      "important",
      "medicine",
      "notification",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "error_outline",
    "version": 16,
    "popularity": 80577,
    "codepoint": 57345,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "alert"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "circle",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "outline",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "escalator",
    "version": 248,
    "popularity": 215,
    "codepoint": 61857,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "down",
      "escalator",
      "staircase",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "escalator",
    "version": 8,
    "popularity": 1564,
    "codepoint": 61857,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "down",
      "escalator",
      "staircase",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "escalator_warning",
    "version": 248,
    "popularity": 1050,
    "codepoint": 61868,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "body",
      "child",
      "escalator",
      "human",
      "kid",
      "parent",
      "people",
      "person",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "escalator_warning",
    "version": 8,
    "popularity": 6285,
    "codepoint": 61868,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "body",
      "child",
      "escalator",
      "human",
      "kid",
      "parent",
      "people",
      "person",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "euro",
    "version": 248,
    "popularity": 3138,
    "codepoint": 59925,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "euro",
      "euros",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "euro",
    "version": 12,
    "popularity": 18378,
    "codepoint": 59925,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "euro",
      "euros",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "euro_symbol",
    "version": 248,
    "popularity": 2145,
    "codepoint": 59686,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "euro",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "euro_symbol",
    "version": 13,
    "popularity": 20388,
    "codepoint": 59686,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "euro",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "ev_charger",
    "version": 248,
    "popularity": 398,
    "codepoint": 57865,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "charger",
      "electric",
      "electricity",
      "ev",
      "home"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ev_mobiledata_badge",
    "version": 248,
    "popularity": 0,
    "codepoint": 63458,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "cellular",
      "character",
      "digit",
      "font",
      "letter",
      "mobile",
      "mobiledata",
      "network",
      "number",
      "phone",
      "signal",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ev_shadow",
    "version": 248,
    "popularity": 299,
    "codepoint": 61327,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "charger",
      "charging",
      "electric",
      "electricity",
      "ev",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ev_shadow_add",
    "version": 248,
    "popularity": 2,
    "codepoint": 62848,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "add",
      "charger",
      "charging",
      "electric",
      "electricity",
      "ev",
      "new",
      "plus",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ev_shadow_minus",
    "version": 248,
    "popularity": 1,
    "codepoint": 62847,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "-",
      "charger",
      "charging",
      "delete",
      "electric",
      "electricity",
      "ev",
      "minus",
      "power",
      "remove",
      "subtract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ev_station",
    "version": 248,
    "popularity": 1042,
    "codepoint": 58733,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "charging",
      "electric",
      "electricity",
      "ev",
      "maps",
      "places",
      "station",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ev_station",
    "version": 11,
    "popularity": 4614,
    "codepoint": 58733,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "charging",
      "electric",
      "electricity",
      "ev",
      "maps",
      "places",
      "station",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "event",
    "version": 248,
    "popularity": 13155,
    "codepoint": 59512,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "mark",
      "month",
      "range",
      "remember",
      "reminder",
      "today",
      "week"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "event",
    "version": 21,
    "popularity": 134525,
    "codepoint": 59512,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "mark",
      "month",
      "range",
      "remember",
      "reminder",
      "today",
      "week"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "event_available",
    "version": 248,
    "popularity": 6604,
    "codepoint": 58900,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "available",
      "calendar",
      "check",
      "complete",
      "date",
      "done",
      "event",
      "mark",
      "ok",
      "schedule",
      "select",
      "tick",
      "time",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "event_available",
    "version": 17,
    "popularity": 39205,
    "codepoint": 58900,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "approve",
      "available",
      "calendar",
      "check",
      "complete",
      "date",
      "done",
      "event",
      "mark",
      "ok",
      "schedule",
      "select",
      "tick",
      "time",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "event_busy",
    "version": 248,
    "popularity": 2108,
    "codepoint": 58901,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "busy",
      "calendar",
      "cancel",
      "clear",
      "close",
      "date",
      "event",
      "exit",
      "no",
      "remove",
      "schedule",
      "stop",
      "time",
      "unavailable",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "event_busy",
    "version": 20,
    "popularity": 12852,
    "codepoint": 58901,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "busy",
      "calendar",
      "cancel",
      "clear",
      "close",
      "date",
      "event",
      "exit",
      "no",
      "remove",
      "schedule",
      "stop",
      "time",
      "unavailable",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "event_list",
    "version": 248,
    "popularity": 32,
    "codepoint": 63107,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "boxes",
      "camerazilla",
      "checkbox",
      "checklist",
      "lists"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "event_note",
    "version": 248,
    "popularity": 4009,
    "codepoint": 58902,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "calendar",
      "date",
      "event",
      "note",
      "schedule",
      "text",
      "time",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "event_note",
    "version": 14,
    "popularity": 23806,
    "codepoint": 58902,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "calendar",
      "date",
      "event",
      "note",
      "schedule",
      "text",
      "time",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "event_repeat",
    "version": 248,
    "popularity": 1503,
    "codepoint": 60283,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "around",
      "calendar",
      "date",
      "day",
      "event",
      "inprogress",
      "load",
      "loading refresh",
      "month",
      "renew",
      "rotate",
      "schedule",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "event_repeat",
    "version": 1,
    "popularity": 5065,
    "codepoint": 60283,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "around",
      "calendar",
      "date",
      "day",
      "event",
      "inprogress",
      "load",
      "loading refresh",
      "month",
      "renew",
      "rotate",
      "schedule",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "event_seat",
    "version": 248,
    "popularity": 422,
    "codepoint": 59651,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "assign",
      "assigned",
      "chair",
      "event",
      "furniture",
      "reservation",
      "row",
      "seat",
      "section",
      "sit"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "event_seat",
    "version": 19,
    "popularity": 7483,
    "codepoint": 59651,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "assign",
      "assigned",
      "chair",
      "event",
      "furniture",
      "reservation",
      "row",
      "seat",
      "section",
      "sit"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "event_upcoming",
    "version": 248,
    "popularity": 2631,
    "codepoint": 62008,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "calendar",
      "date",
      "event",
      "month",
      "reminder",
      "right",
      "schedule",
      "time",
      "upcoming"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exclamation",
    "version": 248,
    "popularity": 2685,
    "codepoint": 61999,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "high",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exercise",
    "version": 248,
    "popularity": 94,
    "codepoint": 63206,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "center",
      "dumbbell",
      "exercise",
      "fitbit",
      "fitness",
      "gym",
      "hobby",
      "places",
      "sport",
      "sports",
      "weights",
      "workout"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exit_to_app",
    "version": 248,
    "popularity": 5738,
    "codepoint": 59513,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "exit",
      "export",
      "interface",
      "layout",
      "leave",
      "mobile",
      "monitor",
      "move",
      "output",
      "phone",
      "screen",
      "site",
      "tablet",
      "to",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exit_to_app",
    "version": 13,
    "popularity": 36140,
    "codepoint": 59513,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "exit",
      "export",
      "interface",
      "layout",
      "leave",
      "mobile",
      "monitor",
      "move",
      "output",
      "phone",
      "screen",
      "site",
      "tablet",
      "to",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "expand",
    "version": 248,
    "popularity": 2299,
    "codepoint": 59727,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "compress",
      "enlarge",
      "expand",
      "grow",
      "move",
      "push",
      "together"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand",
    "version": 11,
    "popularity": 13281,
    "codepoint": 59727,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "compress",
      "enlarge",
      "expand",
      "grow",
      "move",
      "push",
      "together"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "expand_all",
    "version": 248,
    "popularity": 256,
    "codepoint": 59718,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "arrow",
      "arrows",
      "directions",
      "down",
      "expand",
      "open",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand_circle_down",
    "version": 248,
    "popularity": 9094,
    "codepoint": 59341,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "circle",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand_circle_down",
    "version": 2,
    "popularity": 20729,
    "codepoint": 59341,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "circle",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "expand_circle_right",
    "version": 248,
    "popularity": 52,
    "codepoint": 62865,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "circle",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand_circle_up",
    "version": 248,
    "popularity": 44,
    "codepoint": 62930,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "circle",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand_content",
    "version": 248,
    "popularity": 289,
    "codepoint": 63536,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "expansion",
      "list",
      "more",
      "navigation",
      "open"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand_less",
    "version": 248,
    "popularity": 19093,
    "codepoint": 58830,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "less",
      "list",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand_less",
    "version": 12,
    "popularity": 103893,
    "codepoint": 58830,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "less",
      "list",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "expand_more",
    "version": 248,
    "popularity": 86233,
    "codepoint": 58831,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "expand_more",
    "version": 14,
    "popularity": 336762,
    "codepoint": 58831,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "experiment",
    "version": 248,
    "popularity": 950,
    "codepoint": 59014,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "beaker",
      "chemical",
      "chemistry",
      "experiment",
      "flask",
      "glass",
      "laboratory",
      "research",
      "science",
      "tube"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "explicit",
    "version": 248,
    "popularity": 367,
    "codepoint": 57374,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "adult",
      "alphabet",
      "character",
      "content",
      "e",
      "explicit",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "music",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "explicit",
    "version": 12,
    "popularity": 1991,
    "codepoint": 57374,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "adult",
      "alphabet",
      "character",
      "content",
      "e",
      "explicit",
      "font",
      "language",
      "letter",
      "media",
      "movies",
      "music",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "explore",
    "version": 248,
    "popularity": 9278,
    "codepoint": 59514,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "compass",
      "destination",
      "direction",
      "east",
      "explore",
      "location",
      "maps",
      "needle",
      "north",
      "south",
      "travel",
      "west"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "explore",
    "version": 12,
    "popularity": 47759,
    "codepoint": 59514,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "compass",
      "destination",
      "direction",
      "east",
      "explore",
      "location",
      "maps",
      "needle",
      "north",
      "south",
      "travel",
      "west"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "explore_nearby",
    "version": 248,
    "popularity": 43,
    "codepoint": 58680,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "explore",
      "locartion",
      "map",
      "marker",
      "navigation",
      "nearby",
      "pin",
      "place",
      "spot",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "explore_off",
    "version": 248,
    "popularity": 243,
    "codepoint": 59816,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "compass",
      "destination",
      "direction",
      "disabled",
      "east",
      "enabled",
      "explore",
      "location",
      "maps",
      "needle",
      "north",
      "off",
      "on",
      "slash",
      "south",
      "travel",
      "west"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "explore_off",
    "version": 12,
    "popularity": 2263,
    "codepoint": 59816,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "compass",
      "destination",
      "direction",
      "disabled",
      "east",
      "enabled",
      "explore",
      "location",
      "maps",
      "needle",
      "north",
      "off",
      "on",
      "slash",
      "south",
      "travel",
      "west"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "explosion",
    "version": 248,
    "popularity": 11,
    "codepoint": 63109,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "atomic",
      "blast",
      "blasts",
      "boom",
      "burst",
      "bursts",
      "disaster",
      "explode",
      "explosion",
      "explosive",
      "loud",
      "mine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "export_notes",
    "version": 248,
    "popularity": 86,
    "codepoint": 57516,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "export",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "right",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exposure",
    "version": 248,
    "popularity": 478,
    "codepoint": 58314,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "add",
      "brightness",
      "contrast",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "minus",
      "photo",
      "photography",
      "picture",
      "plus",
      "settings",
      "subtract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exposure",
    "version": 12,
    "popularity": 2698,
    "codepoint": 58314,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "add",
      "brightness",
      "contrast",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "minus",
      "photo",
      "photography",
      "picture",
      "plus",
      "settings",
      "subtract"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "exposure_neg_1",
    "version": 248,
    "popularity": 249,
    "codepoint": 58315,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "1",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "neg",
      "negative",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exposure_neg_1",
    "version": 13,
    "popularity": 1379,
    "codepoint": 58315,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "1",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "neg",
      "negative",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "exposure_neg_2",
    "version": 248,
    "popularity": 172,
    "codepoint": 58316,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "2",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "neg",
      "negative",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exposure_neg_2",
    "version": 13,
    "popularity": 964,
    "codepoint": 58316,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "2",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "neg",
      "negative",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "exposure_plus_1",
    "version": 248,
    "popularity": 724,
    "codepoint": 58317,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "1",
      "add",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "plus",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exposure_plus_1",
    "version": 12,
    "popularity": 2789,
    "codepoint": 58317,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "1",
      "add",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "plus",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "exposure_plus_2",
    "version": 248,
    "popularity": 251,
    "codepoint": 58318,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "2",
      "add",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "plus",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exposure_plus_2",
    "version": 12,
    "popularity": 1546,
    "codepoint": 58318,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "2",
      "add",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "plus",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "exposure_zero",
    "version": 248,
    "popularity": 288,
    "codepoint": 58319,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "0",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol",
      "zero"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "exposure_zero",
    "version": 12,
    "popularity": 2104,
    "codepoint": 58319,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "0",
      "brightness",
      "contrast",
      "digit",
      "edit",
      "editing",
      "effect",
      "exposure",
      "image",
      "number",
      "photo",
      "photography",
      "settings",
      "symbol",
      "zero"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "extension",
    "version": 248,
    "popularity": 4111,
    "codepoint": 59515,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "extended",
      "extension",
      "game",
      "jigsaw",
      "plugin add",
      "puzzle",
      "shape"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "extension",
    "version": 12,
    "popularity": 27278,
    "codepoint": 59515,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "extended",
      "extension",
      "game",
      "jigsaw",
      "plugin add",
      "puzzle",
      "shape"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "extension_off",
    "version": 248,
    "popularity": 671,
    "codepoint": 58613,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "disabled",
      "enabled",
      "extended",
      "extension",
      "jigsaw",
      "off",
      "on",
      "piece",
      "puzzle",
      "shape",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "extension_off",
    "version": 4,
    "popularity": 1974,
    "codepoint": 58613,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "disabled",
      "enabled",
      "extended",
      "extension",
      "jigsaw",
      "off",
      "on",
      "piece",
      "puzzle",
      "shape",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "eyeglasses",
    "version": 248,
    "popularity": 127,
    "codepoint": 63214,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "glasses",
      "optical",
      "specs",
      "spectacles",
      "sunglasses",
      "vision"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face",
    "version": 248,
    "popularity": 12596,
    "codepoint": 59516,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face",
    "version": 15,
    "popularity": 152970,
    "codepoint": 59516,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "face_2",
    "version": 248,
    "popularity": 841,
    "codepoint": 63706,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face_2",
    "version": 1,
    "popularity": 1593,
    "codepoint": 63706,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "face_3",
    "version": 248,
    "popularity": 1123,
    "codepoint": 63707,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face_3",
    "version": 1,
    "popularity": 2049,
    "codepoint": 63707,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "face_4",
    "version": 248,
    "popularity": 1028,
    "codepoint": 63708,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face_4",
    "version": 1,
    "popularity": 1564,
    "codepoint": 63708,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "face_5",
    "version": 248,
    "popularity": 739,
    "codepoint": 63709,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face_5",
    "version": 1,
    "popularity": 1190,
    "codepoint": 63709,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "face_6",
    "version": 248,
    "popularity": 1337,
    "codepoint": 63710,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face_6",
    "version": 1,
    "popularity": 2283,
    "codepoint": 63710,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "lock",
      "log",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "social",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "face_retouching_natural",
    "version": 16,
    "popularity": 6541,
    "codepoint": 61262,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "ai",
      "artificial",
      "automatic",
      "automation",
      "custom",
      "edit",
      "editing",
      "effect",
      "emoji",
      "emotion",
      "face",
      "faces",
      "genai",
      "image",
      "intelligence",
      "magic",
      "natural",
      "photo",
      "photography",
      "retouch",
      "retouching",
      "settings",
      "smart",
      "spark",
      "sparkle",
      "star",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "face_retouching_off",
    "version": 248,
    "popularity": 243,
    "codepoint": 61447,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "disabled",
      "edit",
      "editing",
      "effect",
      "emoji",
      "emotion",
      "enabled",
      "face",
      "faces",
      "image",
      "natural",
      "off",
      "on",
      "photo",
      "photography",
      "retouch",
      "retouching",
      "settings",
      "slash",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "face_retouching_off",
    "version": 15,
    "popularity": 1368,
    "codepoint": 61447,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "disabled",
      "edit",
      "editing",
      "effect",
      "emoji",
      "emotion",
      "enabled",
      "face",
      "faces",
      "image",
      "natural",
      "off",
      "on",
      "photo",
      "photography",
      "retouch",
      "retouching",
      "settings",
      "slash",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "face_unlock",
    "version": 11,
    "popularity": 460,
    "codepoint": 61448,
    "unsupported_families": [
      "Material Icons",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "emoji",
      "eyes",
      "face",
      "human",
      "login",
      "logout",
      "people",
      "person",
      "profile",
      "recognition",
      "security",
      "thumbnail",
      "unlock",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fact_check",
    "version": 248,
    "popularity": 7763,
    "codepoint": 61637,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "fact",
      "list",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fact_check",
    "version": 14,
    "popularity": 80300,
    "codepoint": 61637,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "fact",
      "list",
      "mark",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "factory",
    "version": 248,
    "popularity": 3802,
    "codepoint": 60348,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "factory",
      "industry",
      "manufacturing",
      "warehouse"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "factory",
    "version": 1,
    "popularity": 10420,
    "codepoint": 60348,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "factory",
      "industry",
      "manufacturing",
      "warehouse"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "falling",
    "version": 248,
    "popularity": 27,
    "codepoint": 62989,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "fall",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "familiar_face_and_zone",
    "version": 248,
    "popularity": 657,
    "codepoint": 57884,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "activity",
      "face",
      "familiar",
      "frame",
      "guided frame",
      "home",
      "nest",
      "person",
      "security",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "family_history",
    "version": 248,
    "popularity": 60,
    "codepoint": 57517,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "chart",
      "connect",
      "diagram",
      "flow",
      "genealogy",
      "graph",
      "health",
      "infographic",
      "pedigree"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "family_home",
    "version": 248,
    "popularity": 127,
    "codepoint": 60198,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "building",
      "estate",
      "family",
      "home",
      "house",
      "place",
      "real",
      "residence",
      "residential",
      "shelter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "family_link",
    "version": 248,
    "popularity": 230,
    "codepoint": 60185,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "activity",
      "family",
      "game",
      "kite",
      "kiting",
      "link"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "family_restroom",
    "version": 248,
    "popularity": 2222,
    "codepoint": 61858,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bathroom",
      "child",
      "children",
      "family",
      "father",
      "gender",
      "kids",
      "mother",
      "parents",
      "restroom",
      "wc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "family_restroom",
    "version": 9,
    "popularity": 13642,
    "codepoint": 61858,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bathroom",
      "child",
      "children",
      "family",
      "father",
      "gender",
      "kids",
      "mother",
      "parents",
      "restroom",
      "wc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "family_star",
    "version": 248,
    "popularity": 6,
    "codepoint": 62759,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "best",
      "bookmark",
      "child",
      "emoji",
      "face",
      "favorite",
      "highlight",
      "kid",
      "ranking",
      "rate",
      "rating",
      "save",
      "smile",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "farsight_digital",
    "version": 248,
    "popularity": 2,
    "codepoint": 62809,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "clock",
      "digital",
      "farsight",
      "home",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fast_forward",
    "version": 248,
    "popularity": 3061,
    "codepoint": 57375,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "control",
      "fast",
      "forward",
      "media",
      "music",
      "play",
      "speed",
      "time",
      "tv",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fast_forward",
    "version": 18,
    "popularity": 17240,
    "codepoint": 57375,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "control",
      "fast",
      "forward",
      "media",
      "music",
      "play",
      "speed",
      "time",
      "tv",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fast_rewind",
    "version": 248,
    "popularity": 1578,
    "codepoint": 57376,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "back",
      "control",
      "fast",
      "media",
      "music",
      "play",
      "rewind",
      "speed",
      "time",
      "tv",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fast_rewind",
    "version": 12,
    "popularity": 10302,
    "codepoint": 57376,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "back",
      "control",
      "fast",
      "media",
      "music",
      "play",
      "rewind",
      "speed",
      "time",
      "tv",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fastfood",
    "version": 248,
    "popularity": 2638,
    "codepoint": 58746,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "drink",
      "fastfood",
      "food",
      "hamburger",
      "maps",
      "meal",
      "places"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fastfood",
    "version": 12,
    "popularity": 14495,
    "codepoint": 58746,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "drink",
      "fastfood",
      "food",
      "hamburger",
      "maps",
      "meal",
      "places"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "faucet",
    "version": 248,
    "popularity": 415,
    "codepoint": 57976,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "faucet",
      "kitchen",
      "nest",
      "outlet",
      "tap",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "favorite",
    "version": 248,
    "popularity": 75441,
    "codepoint": 59517,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "appreciate",
      "favorite",
      "heart",
      "like",
      "love",
      "remember",
      "save",
      "shape"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "favorite",
    "version": 17,
    "popularity": 261507,
    "codepoint": 59517,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "appreciate",
      "favorite",
      "heart",
      "like",
      "love",
      "remember",
      "save",
      "shape"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "favorite_border",
    "version": 12,
    "popularity": 215815,
    "codepoint": 59518,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "border",
      "favorite",
      "heart",
      "like",
      "love",
      "outline",
      "remember",
      "save",
      "shape"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fax",
    "version": 248,
    "popularity": 1396,
    "codepoint": 60120,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "fax",
      "machine",
      "office",
      "phone",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fax",
    "version": 2,
    "popularity": 5974,
    "codepoint": 60120,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "fax",
      "machine",
      "office",
      "phone",
      "send"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "feature_search",
    "version": 248,
    "popularity": 137,
    "codepoint": 59817,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "feature",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "featured_play_list",
    "version": 248,
    "popularity": 662,
    "codepoint": 57453,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "collection",
      "featured",
      "highlighted",
      "list",
      "music",
      "play",
      "playlist",
      "recommended"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "featured_play_list",
    "version": 12,
    "popularity": 4559,
    "codepoint": 57453,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "collection",
      "featured",
      "highlighted",
      "list",
      "music",
      "play",
      "playlist",
      "recommended"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "featured_seasonal_and_gifts",
    "version": 248,
    "popularity": 427,
    "codepoint": 61329,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "and",
      "birthday",
      "bow",
      "box",
      "featured",
      "gifts",
      "holiday",
      "present",
      "ribbon",
      "seasonal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "featured_video",
    "version": 248,
    "popularity": 450,
    "codepoint": 57454,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "advertised",
      "advertisement",
      "featured",
      "highlighted",
      "recommended",
      "video",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "featured_video",
    "version": 12,
    "popularity": 2709,
    "codepoint": 57454,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "advertised",
      "advertisement",
      "featured",
      "highlighted",
      "recommended",
      "video",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "feed",
    "version": 248,
    "popularity": 5587,
    "codepoint": 61449,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "feed",
      "headline",
      "information",
      "news",
      "newspaper",
      "paper",
      "public",
      "social",
      "timeline"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "feed",
    "version": 10,
    "popularity": 39088,
    "codepoint": 61449,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "article",
      "feed",
      "headline",
      "information",
      "news",
      "newspaper",
      "paper",
      "public",
      "social",
      "timeline"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "feedback",
    "version": 248,
    "popularity": 652,
    "codepoint": 59519,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "chat",
      "chat bubble",
      "comment",
      "communicate",
      "communication",
      "conversation",
      "danger",
      "error",
      "exclamation",
      "failed",
      "feedback",
      "important",
      "mark",
      "message",
      "notification",
      "service",
      "sms",
      "speech",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "feedback",
    "version": 12,
    "popularity": 33278,
    "codepoint": 59519,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "chat",
      "chat bubble",
      "comment",
      "communicate",
      "communication",
      "conversation",
      "danger",
      "error",
      "exclamation",
      "failed",
      "feedback",
      "important",
      "mark",
      "message",
      "notification",
      "service",
      "sms",
      "speech",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "female",
    "version": 248,
    "popularity": 2247,
    "codepoint": 58768,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "female",
    "version": 3,
    "popularity": 14013,
    "codepoint": 58768,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "femur",
    "version": 248,
    "popularity": 169,
    "codepoint": 63633,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bone",
      "bones",
      "femur",
      "health",
      "hip",
      "knee",
      "leg",
      "medical",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "femur_alt",
    "version": 248,
    "popularity": 163,
    "codepoint": 63634,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bone",
      "bones",
      "femur",
      "health",
      "hip",
      "knee",
      "leg",
      "medical",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fence",
    "version": 248,
    "popularity": 351,
    "codepoint": 61942,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "backyard",
      "barrier",
      "boundaries",
      "boundary",
      "door",
      "entrance",
      "fence",
      "flowers",
      "garden",
      "gate",
      "grass",
      "home",
      "house",
      "nature",
      "nest",
      "outdoor",
      "outside",
      "protection",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fence",
    "version": 6,
    "popularity": 2261,
    "codepoint": 61942,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "backyard",
      "barrier",
      "boundaries",
      "boundary",
      "door",
      "entrance",
      "fence",
      "flowers",
      "garden",
      "gate",
      "grass",
      "home",
      "house",
      "nature",
      "nest",
      "outdoor",
      "outside",
      "protection",
      "yard"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fertile",
    "version": 248,
    "popularity": 19,
    "codepoint": 63205,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "blood",
      "cycle",
      "female",
      "fitbit",
      "health",
      "menstrual",
      "ovulation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "festival",
    "version": 248,
    "popularity": 846,
    "codepoint": 60008,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "circus",
      "event",
      "festival",
      "local",
      "maps",
      "places",
      "tent",
      "tour",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "festival",
    "version": 10,
    "popularity": 4356,
    "codepoint": 60008,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "circus",
      "event",
      "festival",
      "local",
      "maps",
      "places",
      "tent",
      "tour",
      "travel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "fiber_dvr",
    "version": 248,
    "popularity": 168,
    "codepoint": 57437,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "digital",
      "dvr",
      "electronics",
      "fiber",
      "font",
      "letter",
      "network",
      "record",
      "recorder",
      "symbol",
      "text",
      "tv",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fiber_dvr",
    "version": 13,
    "popularity": 979,
    "codepoint": 57437,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alphabet",
      "character",
      "digital",
      "dvr",
      "electronics",
      "fiber",
      "font",
      "letter",
      "network",
      "record",
      "recorder",
      "symbol",
      "text",
      "tv",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fiber_manual_record",
    "version": 248,
    "popularity": 3738,
    "codepoint": 57441,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "circle",
      "dot",
      "fiber",
      "manual",
      "play",
      "record",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fiber_manual_record",
    "version": 16,
    "popularity": 29914,
    "codepoint": 57441,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "circle",
      "dot",
      "fiber",
      "manual",
      "play",
      "record",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fiber_new",
    "version": 248,
    "popularity": 983,
    "codepoint": 57438,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "fiber",
      "font",
      "letter",
      "network",
      "new",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fiber_new",
    "version": 13,
    "popularity": 7812,
    "codepoint": 57438,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alphabet",
      "character",
      "fiber",
      "font",
      "letter",
      "network",
      "new",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fiber_pin",
    "version": 248,
    "popularity": 191,
    "codepoint": 57450,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "fiber",
      "font",
      "letter",
      "network",
      "pin",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fiber_pin",
    "version": 12,
    "popularity": 1085,
    "codepoint": 57450,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alphabet",
      "character",
      "fiber",
      "font",
      "letter",
      "network",
      "pin",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fiber_smart_record",
    "version": 248,
    "popularity": 249,
    "codepoint": 57442,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "circle",
      "dot",
      "fiber",
      "play",
      "record",
      "smart",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fiber_smart_record",
    "version": 12,
    "popularity": 1863,
    "codepoint": 57442,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "circle",
      "dot",
      "fiber",
      "play",
      "record",
      "smart",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "file_copy",
    "version": 248,
    "popularity": 4911,
    "codepoint": 57715,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "past",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_copy",
    "version": 12,
    "popularity": 28743,
    "codepoint": 57715,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "past",
      "stack"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "file_copy_off",
    "version": 248,
    "popularity": 1,
    "codepoint": 62680,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "disabled",
      "doc",
      "document",
      "duplicate",
      "enabled",
      "file",
      "multiple",
      "off",
      "offline",
      "on",
      "past",
      "slash",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_download",
    "version": 11,
    "popularity": 172970,
    "codepoint": 58052,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "arrows",
      "down",
      "download",
      "downloads",
      "drive",
      "export",
      "file",
      "install",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "file_download_done",
    "version": 248,
    "popularity": 1533,
    "codepoint": 59818,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "check",
      "done",
      "down",
      "download",
      "downloads",
      "drive",
      "file",
      "install",
      "installed",
      "tick",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_download_done",
    "version": 11,
    "popularity": 7152,
    "codepoint": 59818,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "arrows",
      "check",
      "done",
      "down",
      "download",
      "downloads",
      "drive",
      "file",
      "install",
      "installed",
      "tick",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "file_download_off",
    "version": 248,
    "popularity": 721,
    "codepoint": 58622,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "disabled",
      "down",
      "download",
      "drive",
      "enabled",
      "export",
      "file",
      "install",
      "off",
      "on",
      "save",
      "slash",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_download_off",
    "version": 4,
    "popularity": 3050,
    "codepoint": 58622,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "disabled",
      "down",
      "download",
      "drive",
      "enabled",
      "export",
      "file",
      "install",
      "off",
      "on",
      "save",
      "slash",
      "upload"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "file_map",
    "version": 248,
    "popularity": 27,
    "codepoint": 58053,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "file",
      "location",
      "map",
      "mark",
      "marker",
      "pin",
      "place",
      "sport"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_open",
    "version": 248,
    "popularity": 4107,
    "codepoint": 60147,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "doc",
      "document",
      "drive",
      "file",
      "left",
      "open",
      "page",
      "paper"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_open",
    "version": 1,
    "popularity": 7844,
    "codepoint": 60147,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "doc",
      "document",
      "drive",
      "file",
      "left",
      "open",
      "page",
      "paper"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "file_present",
    "version": 248,
    "popularity": 1483,
    "codepoint": 59918,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clip",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "note",
      "paper",
      "present",
      "reminder",
      "sheet",
      "slide",
      "storage",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_present",
    "version": 10,
    "popularity": 15306,
    "codepoint": 59918,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "clip",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "note",
      "paper",
      "present",
      "reminder",
      "sheet",
      "slide",
      "storage",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "file_save",
    "version": 248,
    "popularity": 55,
    "codepoint": 61823,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "down",
      "download",
      "drive",
      "file",
      "folder",
      "folders",
      "keep",
      "save",
      "sheet",
      "slide",
      "storage",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_save_off",
    "version": 248,
    "popularity": 7,
    "codepoint": 58629,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "data",
      "disabled",
      "doc",
      "document",
      "down",
      "download",
      "drive",
      "enabled",
      "file",
      "folder",
      "folders",
      "keep",
      "off",
      "on",
      "save",
      "sheet",
      "slash",
      "slide",
      "storage",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_upload",
    "version": 11,
    "popularity": 89311,
    "codepoint": 58054,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "arrows",
      "download",
      "drive",
      "export",
      "file",
      "up",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "file_upload_off",
    "version": 248,
    "popularity": 372,
    "codepoint": 63622,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "disabled",
      "download",
      "drive",
      "enabled",
      "export",
      "file",
      "off",
      "offline",
      "on",
      "slash",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "file_upload_off",
    "version": 1,
    "popularity": 397,
    "codepoint": 63622,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "arrows",
      "disabled",
      "download",
      "drive",
      "enabled",
      "export",
      "file",
      "off",
      "offline",
      "on",
      "slash",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "filter",
    "version": 248,
    "popularity": 598,
    "codepoint": 58323,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter",
    "version": 12,
    "popularity": 3844,
    "codepoint": 58323,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_1",
    "version": 248,
    "popularity": 652,
    "codepoint": 58320,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "1",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_1",
    "version": 12,
    "popularity": 4604,
    "codepoint": 58320,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "1",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_2",
    "version": 248,
    "popularity": 346,
    "codepoint": 58321,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "2",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_2",
    "version": 12,
    "popularity": 2676,
    "codepoint": 58321,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "2",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_3",
    "version": 248,
    "popularity": 308,
    "codepoint": 58322,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "3",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_3",
    "version": 12,
    "popularity": 2111,
    "codepoint": 58322,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "3",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_4",
    "version": 248,
    "popularity": 315,
    "codepoint": 58324,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "4",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_4",
    "version": 12,
    "popularity": 1549,
    "codepoint": 58324,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "4",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_5",
    "version": 248,
    "popularity": 235,
    "codepoint": 58325,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "5",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_5",
    "version": 12,
    "popularity": 1414,
    "codepoint": 58325,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "5",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_6",
    "version": 248,
    "popularity": 178,
    "codepoint": 58326,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "6",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_6",
    "version": 12,
    "popularity": 1165,
    "codepoint": 58326,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "6",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_7",
    "version": 248,
    "popularity": 231,
    "codepoint": 58327,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "7",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_7",
    "version": 12,
    "popularity": 1565,
    "codepoint": 58327,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "7",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_8",
    "version": 248,
    "popularity": 202,
    "codepoint": 58328,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "8",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_8",
    "version": 12,
    "popularity": 1240,
    "codepoint": 58328,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "8",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_9",
    "version": 248,
    "popularity": 191,
    "codepoint": 58329,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "9",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_9",
    "version": 12,
    "popularity": 1230,
    "codepoint": 58329,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "9",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_9_plus",
    "version": 248,
    "popularity": 207,
    "codepoint": 58330,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "9",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "plus",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_9_plus",
    "version": 13,
    "popularity": 0,
    "codepoint": 58330,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "+",
      "9",
      "digit",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "images",
      "multiple",
      "number",
      "photography",
      "picture",
      "pictures",
      "plus",
      "settings",
      "stack",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_alt",
    "version": 248,
    "popularity": 16439,
    "codepoint": 61263,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alt",
      "edit",
      "filter",
      "funnel",
      "options",
      "refine",
      "sift"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_alt",
    "version": 11,
    "popularity": 137592,
    "codepoint": 61263,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "edit",
      "filter",
      "funnel",
      "options",
      "refine",
      "sift"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_alt_off",
    "version": 248,
    "popularity": 2286,
    "codepoint": 60210,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "disabled",
      "edit",
      "filter",
      "funnel",
      "off",
      "offline",
      "options",
      "refine",
      "sift",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_alt_off",
    "version": 1,
    "popularity": 8765,
    "codepoint": 60210,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "disabled",
      "edit",
      "filter",
      "funnel",
      "off",
      "offline",
      "options",
      "refine",
      "sift",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "filter_b_and_w",
    "version": 248,
    "popularity": 254,
    "codepoint": 58331,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "and",
      "b",
      "black",
      "contrast",
      "edit",
      "editing",
      "effect",
      "filter",
      "grayscale",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "settings",
      "w",
      "white"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_b_and_w",
    "version": 13,
    "popularity": 1756,
    "codepoint": 58331,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "and",
      "b",
      "black",
      "contrast",
      "edit",
      "editing",
      "effect",
      "filter",
      "grayscale",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "settings",
      "w",
      "white"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_center_focus",
    "version": 248,
    "popularity": 876,
    "codepoint": 58332,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "center",
      "dot",
      "edit",
      "filter",
      "focus",
      "image",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_center_focus",
    "version": 12,
    "popularity": 6030,
    "codepoint": 58332,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "center",
      "dot",
      "edit",
      "filter",
      "focus",
      "image",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_drama",
    "version": 248,
    "popularity": 1076,
    "codepoint": 58333,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "cloud",
      "drama",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "photo",
      "photography",
      "picture",
      "sky camera"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_drama",
    "version": 12,
    "popularity": 7480,
    "codepoint": 58333,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "cloud",
      "drama",
      "edit",
      "editing",
      "effect",
      "filter",
      "image",
      "photo",
      "photography",
      "picture",
      "sky camera"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_frames",
    "version": 248,
    "popularity": 276,
    "codepoint": 58334,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "boarders",
      "border",
      "camera",
      "center",
      "edit",
      "editing",
      "effect",
      "filter",
      "filters",
      "focus",
      "frame",
      "frames",
      "image",
      "options",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_frames",
    "version": 13,
    "popularity": 1937,
    "codepoint": 58334,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "boarders",
      "border",
      "camera",
      "center",
      "edit",
      "editing",
      "effect",
      "filter",
      "filters",
      "focus",
      "frame",
      "frames",
      "image",
      "options",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_hdr",
    "version": 248,
    "popularity": 475,
    "codepoint": 58335,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "edit",
      "editing",
      "effect",
      "filter",
      "hdr",
      "image",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_hdr",
    "version": 13,
    "popularity": 2216,
    "codepoint": 58335,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "edit",
      "editing",
      "effect",
      "filter",
      "hdr",
      "image",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_list",
    "version": 248,
    "popularity": 13691,
    "codepoint": 57682,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "filter",
      "lines",
      "list",
      "organize",
      "sort"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_list",
    "version": 13,
    "popularity": 70805,
    "codepoint": 57682,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "filter",
      "lines",
      "list",
      "organize",
      "sort"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_list_alt",
    "version": 10,
    "popularity": 2752,
    "codepoint": 59726,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "change",
      "edit",
      "filter",
      "funnel",
      "list. alt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_list_off",
    "version": 248,
    "popularity": 1074,
    "codepoint": 60247,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "disabled",
      "edit",
      "filter",
      "list",
      "off",
      "offline",
      "options",
      "refine",
      "sift",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_list_off",
    "version": 1,
    "popularity": 3639,
    "codepoint": 60247,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "alt",
      "disabled",
      "edit",
      "filter",
      "list",
      "off",
      "offline",
      "options",
      "refine",
      "sift",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "filter_none",
    "version": 248,
    "popularity": 1135,
    "codepoint": 58336,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "filter",
      "multiple",
      "none",
      "square",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_none",
    "version": 13,
    "popularity": 6218,
    "codepoint": 58336,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "filter",
      "multiple",
      "none",
      "square",
      "stack"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_retrolux",
    "version": 248,
    "popularity": 10,
    "codepoint": 58337,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "activity",
      "app",
      "application",
      "edit",
      "editing",
      "editor",
      "filter",
      "game",
      "image",
      "kite",
      "kiting",
      "photo",
      "program",
      "retrolux",
      "software"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_tilt_shift",
    "version": 248,
    "popularity": 341,
    "codepoint": 58338,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "center",
      "dash",
      "dashed",
      "edit",
      "editing",
      "effect",
      "filter",
      "focus",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "shift",
      "tilt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_tilt_shift",
    "version": 14,
    "popularity": 2619,
    "codepoint": 58338,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "blur",
      "center",
      "dash",
      "dashed",
      "edit",
      "editing",
      "effect",
      "filter",
      "focus",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "shift",
      "tilt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "filter_vintage",
    "version": 248,
    "popularity": 1378,
    "codepoint": 58339,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "edit",
      "editing",
      "effect",
      "filter",
      "flower",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "vintage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "filter_vintage",
    "version": 13,
    "popularity": 7686,
    "codepoint": 58339,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "edit",
      "editing",
      "effect",
      "filter",
      "flower",
      "image",
      "images",
      "photography",
      "picture",
      "pictures",
      "vintage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "finance",
    "version": 248,
    "popularity": 122,
    "codepoint": 59071,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "finance",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "finance_chip",
    "version": 248,
    "popularity": 141,
    "codepoint": 63566,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bill",
      "cash",
      "chip",
      "coin",
      "commerce",
      "currency",
      "dollars",
      "money",
      "pay",
      "payment",
      "price"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "finance_mode",
    "version": 248,
    "popularity": 183,
    "codepoint": 61330,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "find_in_page",
    "version": 248,
    "popularity": 2301,
    "codepoint": 59520,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "find",
      "folder",
      "folders",
      "glass",
      "in",
      "look",
      "magnify",
      "magnifying",
      "page",
      "paper",
      "search",
      "see",
      "sheet",
      "slide",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "find_in_page",
    "version": 12,
    "popularity": 22664,
    "codepoint": 59520,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "find",
      "folder",
      "folders",
      "glass",
      "in",
      "look",
      "magnify",
      "magnifying",
      "page",
      "paper",
      "search",
      "see",
      "sheet",
      "slide",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "find_replace",
    "version": 248,
    "popularity": 858,
    "codepoint": 59521,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "around",
      "arrows",
      "find",
      "glass",
      "inprogress",
      "load",
      "loading refresh",
      "look",
      "magnify",
      "magnifying",
      "renew",
      "replace",
      "rotate",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "find_replace",
    "version": 12,
    "popularity": 5994,
    "codepoint": 59521,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "around",
      "arrows",
      "find",
      "glass",
      "inprogress",
      "load",
      "loading refresh",
      "look",
      "magnify",
      "magnifying",
      "renew",
      "replace",
      "rotate",
      "search",
      "see"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fingerprint",
    "version": 248,
    "popularity": 9223,
    "codepoint": 59661,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "finger",
      "fingerprint",
      "id",
      "identification",
      "identity",
      "print",
      "reader",
      "thumbprint",
      "verification"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fingerprint",
    "version": 12,
    "popularity": 134003,
    "codepoint": 59661,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "finger",
      "fingerprint",
      "id",
      "identification",
      "identity",
      "print",
      "reader",
      "thumbprint",
      "verification"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fire_extinguisher",
    "version": 248,
    "popularity": 452,
    "codepoint": 61912,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "emergency",
      "extinguisher",
      "fire",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fire_extinguisher",
    "version": 7,
    "popularity": 2993,
    "codepoint": 61912,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "emergency",
      "extinguisher",
      "fire",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fire_hydrant",
    "version": 248,
    "popularity": 349,
    "codepoint": 61859,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "911",
      "department",
      "emergency",
      "fire",
      "firefighter",
      "flame",
      "hot",
      "hydrant",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fire_hydrant_alt",
    "version": 1,
    "popularity": 0,
    "codepoint": 63729,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "fire_truck",
    "version": 248,
    "popularity": 702,
    "codepoint": 63730,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fire_truck",
    "version": 1,
    "popularity": 942,
    "codepoint": 63730,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "fireplace",
    "version": 248,
    "popularity": 736,
    "codepoint": 59971,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "chimney",
      "fire",
      "fireplace",
      "flame",
      "home",
      "house",
      "living",
      "pit",
      "place",
      "room",
      "warm",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fireplace",
    "version": 11,
    "popularity": 4987,
    "codepoint": 59971,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "chimney",
      "fire",
      "fireplace",
      "flame",
      "home",
      "house",
      "living",
      "pit",
      "place",
      "room",
      "warm",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "first_page",
    "version": 248,
    "popularity": 2705,
    "codepoint": 58844,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "back",
      "chevron",
      "first",
      "left",
      "page",
      "rewind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "first_page",
    "version": 11,
    "popularity": 16055,
    "codepoint": 58844,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "back",
      "chevron",
      "first",
      "left",
      "page",
      "rewind"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fit_page",
    "version": 248,
    "popularity": 2,
    "codepoint": 63354,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dash",
      "dashed",
      "enlarge",
      "fit",
      "format",
      "layout",
      "reduce",
      "scale",
      "screen",
      "size"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fit_screen",
    "version": 248,
    "popularity": 1856,
    "codepoint": 59920,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "enlarge",
      "fit",
      "format",
      "layout",
      "reduce",
      "scale",
      "screen",
      "size"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fit_screen",
    "version": 15,
    "popularity": 8065,
    "codepoint": 59920,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "enlarge",
      "fit",
      "format",
      "layout",
      "reduce",
      "scale",
      "screen",
      "size"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fit_width",
    "version": 248,
    "popularity": 7,
    "codepoint": 63353,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "enlarge",
      "fit",
      "format",
      "horizontal",
      "layout",
      "reduce",
      "scale",
      "screen",
      "size",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fitbit",
    "version": 2,
    "popularity": 0,
    "codepoint": 59435,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "exercise",
      "fitbit",
      "fitness",
      "hobby",
      "logo"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "fitness_center",
    "version": 248,
    "popularity": 4652,
    "codepoint": 60227,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "athlete",
      "center",
      "dumbbell",
      "exercise",
      "fitness",
      "gym",
      "hobby",
      "places",
      "sport",
      "weights",
      "workout"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fitness_center",
    "version": 12,
    "popularity": 26135,
    "codepoint": 60227,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "athlete",
      "center",
      "dumbbell",
      "exercise",
      "fitness",
      "gym",
      "hobby",
      "places",
      "sport",
      "weights",
      "workout"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flag",
    "version": 248,
    "popularity": 8777,
    "codepoint": 57683,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "country",
      "flag",
      "goal",
      "mark",
      "nation",
      "report",
      "start"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flag",
    "version": 18,
    "popularity": 43533,
    "codepoint": 57683,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "country",
      "flag",
      "goal",
      "mark",
      "nation",
      "report",
      "start"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flag_circle",
    "version": 248,
    "popularity": 1715,
    "codepoint": 60152,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "circle",
      "country",
      "flag",
      "goal",
      "mark",
      "nation",
      "report",
      "round",
      "start"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flag_circle",
    "version": 1,
    "popularity": 4418,
    "codepoint": 60152,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "circle",
      "country",
      "flag",
      "goal",
      "mark",
      "nation",
      "report",
      "round",
      "start"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "flaky",
    "version": 248,
    "popularity": 727,
    "codepoint": 61264,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "approve",
      "check",
      "close",
      "complete",
      "contrast",
      "done",
      "exit",
      "flaky",
      "mark",
      "no",
      "ok",
      "options",
      "select",
      "stop",
      "tick",
      "verified",
      "x",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flaky",
    "version": 13,
    "popularity": 7965,
    "codepoint": 61264,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "close",
      "complete",
      "contrast",
      "done",
      "exit",
      "flaky",
      "mark",
      "no",
      "ok",
      "options",
      "select",
      "stop",
      "tick",
      "verified",
      "x",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "flare",
    "version": 248,
    "popularity": 1147,
    "codepoint": 58340,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "bright",
      "edit",
      "editing",
      "effect",
      "flare",
      "image",
      "images",
      "light",
      "photography",
      "picture",
      "pictures",
      "sun"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flare",
    "version": 12,
    "popularity": 6438,
    "codepoint": 58340,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bright",
      "edit",
      "editing",
      "effect",
      "flare",
      "image",
      "images",
      "light",
      "photography",
      "picture",
      "pictures",
      "sun"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flash_auto",
    "version": 248,
    "popularity": 359,
    "codepoint": 58341,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "a",
      "auto",
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flash_auto",
    "version": 12,
    "popularity": 2411,
    "codepoint": 58341,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "a",
      "auto",
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flash_off",
    "version": 248,
    "popularity": 851,
    "codepoint": 58342,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "bolt",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "off",
      "on",
      "slash",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flash_off",
    "version": 12,
    "popularity": 5591,
    "codepoint": 58342,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bolt",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "off",
      "on",
      "slash",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flash_on",
    "version": 248,
    "popularity": 2685,
    "codepoint": 58343,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "bolt",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "off",
      "on",
      "slash",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flash_on",
    "version": 12,
    "popularity": 18220,
    "codepoint": 58343,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bolt",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "fast",
      "flash",
      "instant",
      "lightning",
      "off",
      "on",
      "slash",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flashlight_off",
    "version": 248,
    "popularity": 394,
    "codepoint": 61450,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "disabled",
      "enabled",
      "flash",
      "flashlight",
      "light",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flashlight_off",
    "version": 10,
    "popularity": 2113,
    "codepoint": 61450,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "disabled",
      "enabled",
      "flash",
      "flashlight",
      "light",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flashlight_on",
    "version": 248,
    "popularity": 1033,
    "codepoint": 61451,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "disabled",
      "enabled",
      "flash",
      "flashlight",
      "light",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flashlight_on",
    "version": 10,
    "popularity": 5138,
    "codepoint": 61451,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "disabled",
      "enabled",
      "flash",
      "flashlight",
      "light",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flatware",
    "version": 248,
    "popularity": 756,
    "codepoint": 61452,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "cafe",
      "cafeteria",
      "cutlery",
      "diner",
      "dining",
      "eat",
      "eating",
      "fork",
      "room",
      "spoon"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flatware",
    "version": 9,
    "popularity": 4500,
    "codepoint": 61452,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "cafe",
      "cafeteria",
      "cutlery",
      "diner",
      "dining",
      "eat",
      "eating",
      "fork",
      "room",
      "spoon"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flex_direction",
    "version": 248,
    "popularity": 5,
    "codepoint": 63352,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "down",
      "format",
      "layout",
      "layouts",
      "list",
      "move",
      "north"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flex_no_wrap",
    "version": 248,
    "popularity": 0,
    "codepoint": 63351,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "3x3",
      "format",
      "grid",
      "horizontal",
      "layout",
      "rectangle",
      "rectangles",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flex_wrap",
    "version": 248,
    "popularity": 9,
    "codepoint": 63350,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "6x6",
      "format",
      "grid",
      "horizontal",
      "layout",
      "rectangle",
      "rectangles",
      "view",
      "wraparound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flight",
    "version": 248,
    "popularity": 5640,
    "codepoint": 58681,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "air",
      "airplane",
      "airport",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flight",
    "version": 18,
    "popularity": 27231,
    "codepoint": 58681,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "air",
      "airplane",
      "airport",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flight_class",
    "version": 248,
    "popularity": 312,
    "codepoint": 59339,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "airplane",
      "business",
      "class",
      "first",
      "flight",
      "plane",
      "seat",
      "transportation",
      "travel",
      "trip",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flight_class",
    "version": 2,
    "popularity": 1161,
    "codepoint": 59339,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "airplane",
      "business",
      "class",
      "first",
      "flight",
      "plane",
      "seat",
      "transportation",
      "travel",
      "trip",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "flight_land",
    "version": 248,
    "popularity": 1144,
    "codepoint": 59652,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "airport",
      "arrival",
      "arriving",
      "flight",
      "fly",
      "land",
      "landing",
      "plane",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flight_land",
    "version": 13,
    "popularity": 9016,
    "codepoint": 59652,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "airport",
      "arrival",
      "arriving",
      "flight",
      "fly",
      "land",
      "landing",
      "plane",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flight_takeoff",
    "version": 248,
    "popularity": 3689,
    "codepoint": 59653,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "airport",
      "departed",
      "departing",
      "flight",
      "fly",
      "landing",
      "plane",
      "takeoff",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flight_takeoff",
    "version": 13,
    "popularity": 31611,
    "codepoint": 59653,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "airport",
      "departed",
      "departing",
      "flight",
      "fly",
      "landing",
      "plane",
      "takeoff",
      "transportation",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flights_and_hotels",
    "version": 248,
    "popularity": 53,
    "codepoint": 59819,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "air",
      "airplane",
      "airport",
      "and",
      "bed",
      "flight",
      "furniture",
      "hotel",
      "hotels",
      "night",
      "plane",
      "rest",
      "room",
      "sleep",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flightsmode",
    "version": 248,
    "popularity": 168,
    "codepoint": 61331,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "air",
      "airplane",
      "airport",
      "flight",
      "flightsmode",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flip",
    "version": 248,
    "popularity": 844,
    "codepoint": 58344,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "dash",
      "dashed",
      "edit",
      "editing",
      "flip",
      "image",
      "orientation",
      "scan scanning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flip",
    "version": 12,
    "popularity": 5094,
    "codepoint": 58344,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "dash",
      "dashed",
      "edit",
      "editing",
      "flip",
      "image",
      "orientation",
      "scan scanning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flip_camera_android",
    "version": 248,
    "popularity": 812,
    "codepoint": 59959,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "android",
      "camera",
      "center",
      "edit",
      "editing",
      "flip",
      "image",
      "mobile",
      "orientation",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flip_camera_android",
    "version": 12,
    "popularity": 5176,
    "codepoint": 59959,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "android",
      "camera",
      "center",
      "edit",
      "editing",
      "flip",
      "image",
      "mobile",
      "orientation",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "flip_camera_ios",
    "version": 248,
    "popularity": 648,
    "codepoint": 59960,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "DISABLE_IOS",
      "android",
      "camera",
      "disable_ios",
      "edit",
      "editing",
      "flip",
      "image",
      "ios",
      "mobile",
      "orientation",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flip_camera_ios",
    "version": 12,
    "popularity": 4728,
    "codepoint": 59960,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "DISABLE_IOS",
      "android",
      "camera",
      "disable_ios",
      "edit",
      "editing",
      "flip",
      "image",
      "ios",
      "mobile",
      "orientation",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "flip_to_back",
    "version": 248,
    "popularity": 254,
    "codepoint": 59522,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrange",
      "arrangement",
      "back",
      "dash",
      "dashed",
      "flip",
      "format",
      "front",
      "layout",
      "move",
      "order",
      "sort",
      "to"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flip_to_back",
    "version": 12,
    "popularity": 2805,
    "codepoint": 59522,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrange",
      "arrangement",
      "back",
      "dash",
      "dashed",
      "flip",
      "format",
      "front",
      "layout",
      "move",
      "order",
      "sort",
      "to"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flip_to_front",
    "version": 248,
    "popularity": 395,
    "codepoint": 59523,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrange",
      "arrangement",
      "back",
      "dash",
      "dashed",
      "flip",
      "format",
      "front",
      "layout",
      "move",
      "order",
      "sort",
      "to"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flip_to_front",
    "version": 12,
    "popularity": 4056,
    "codepoint": 59523,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrange",
      "arrangement",
      "back",
      "dash",
      "dashed",
      "flip",
      "format",
      "front",
      "layout",
      "move",
      "order",
      "sort",
      "to"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flood",
    "version": 248,
    "popularity": 752,
    "codepoint": 60390,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flood",
    "version": 1,
    "popularity": 1093,
    "codepoint": 60390,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "weather"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "floor",
    "version": 248,
    "popularity": 54,
    "codepoint": 63204,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "fitbit",
      "floor",
      "staircase",
      "stairs",
      "steps"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "floor_lamp",
    "version": 248,
    "popularity": 232,
    "codepoint": 57886,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "floor ",
      "home",
      "lamp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flowsheet",
    "version": 248,
    "popularity": 53,
    "codepoint": 57518,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "chart",
      "doc",
      "document",
      "file",
      "flow",
      "graph",
      "health",
      "infographic",
      "medical",
      "page",
      "paper",
      "sheet",
      "slide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fluid",
    "version": 248,
    "popularity": 2,
    "codepoint": 58499,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "fluids",
      "health",
      "med",
      "medical",
      "medicine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fluid_balance",
    "version": 248,
    "popularity": 3,
    "codepoint": 63501,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "chart",
      "doc",
      "document",
      "file",
      "fluids",
      "graph",
      "health",
      "infographic",
      "medical",
      "page",
      "paper",
      "sheet",
      "slide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fluid_med",
    "version": 248,
    "popularity": 2,
    "codepoint": 63500,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "chart",
      "doc",
      "document",
      "file",
      "fluids",
      "graph",
      "health",
      "infographic",
      "medical",
      "page",
      "paper",
      "sheet",
      "slide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fluorescent",
    "version": 248,
    "popularity": 294,
    "codepoint": 60465,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "bright",
      "fluorescent",
      "lamp",
      "light",
      "lightbulb"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fluorescent",
    "version": 1,
    "popularity": 447,
    "codepoint": 60465,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bright",
      "fluorescent",
      "lamp",
      "light",
      "lightbulb"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "flutter",
    "version": 248,
    "popularity": 137,
    "codepoint": 61917,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "application",
      "framework",
      "logo",
      "open source",
      "program",
      "software"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flutter_dash",
    "version": 248,
    "popularity": 1982,
    "codepoint": 57355,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "application",
      "bird",
      "dash",
      "flutter",
      "framework",
      "logo",
      "mascot",
      "open source",
      "program",
      "software"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "flutter_dash",
    "version": 5,
    "popularity": 22235,
    "codepoint": 57355,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "bird",
      "dash",
      "flutter",
      "framework",
      "logo",
      "mascot",
      "open source",
      "program",
      "software"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "fmd_bad",
    "version": 248,
    "popularity": 1462,
    "codepoint": 61454,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bad",
      "caution",
      "danger",
      "destination",
      "direction",
      "error",
      "exclamation",
      "fmd",
      "important",
      "location",
      "maps",
      "mark",
      "notification",
      "pin",
      "place",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fmd_bad",
    "version": 15,
    "popularity": 5157,
    "codepoint": 61454,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bad",
      "caution",
      "danger",
      "destination",
      "direction",
      "error",
      "exclamation",
      "fmd",
      "important",
      "location",
      "maps",
      "mark",
      "notification",
      "pin",
      "place",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fmd_good",
    "version": 15,
    "popularity": 14466,
    "codepoint": 61455,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "destination",
      "direction",
      "fmd",
      "good",
      "location",
      "maps",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "foggy",
    "version": 248,
    "popularity": 516,
    "codepoint": 59416,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "fog",
      "hazy",
      "overcast",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "foggy",
    "version": 2,
    "popularity": 694,
    "codepoint": 59416,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "fog",
      "hazy",
      "overcast",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "folded_hands",
    "version": 248,
    "popularity": 36,
    "codepoint": 62957,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "gratitude",
      "hand",
      "hope",
      "please",
      "pray",
      "prayer",
      "spirituality",
      "thank you",
      "thanks"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder",
    "version": 248,
    "popularity": 10059,
    "codepoint": 58055,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder",
    "version": 16,
    "popularity": 68116,
    "codepoint": 58055,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "folder_copy",
    "version": 248,
    "popularity": 1467,
    "codepoint": 60349,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "data",
      "doc",
      "document",
      "drive",
      "duplicate",
      "file",
      "folder",
      "folders",
      "multiple",
      "paste",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_copy",
    "version": 1,
    "popularity": 4219,
    "codepoint": 60349,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "data",
      "doc",
      "document",
      "drive",
      "duplicate",
      "file",
      "folder",
      "folders",
      "multiple",
      "paste",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "folder_data",
    "version": 248,
    "popularity": 5,
    "codepoint": 62854,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "code",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "node",
      "path",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_delete",
    "version": 248,
    "popularity": 677,
    "codepoint": 60212,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bin",
      "can",
      "data",
      "delete",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "garbage",
      "remove",
      "sheet",
      "slide",
      "storage",
      "trash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_delete",
    "version": 1,
    "popularity": 2435,
    "codepoint": 60212,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "bin",
      "can",
      "data",
      "delete",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "garbage",
      "remove",
      "sheet",
      "slide",
      "storage",
      "trash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "folder_limited",
    "version": 248,
    "popularity": 7,
    "codepoint": 62692,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "avoid",
      "block",
      "cancel",
      "close",
      "data",
      "doc",
      "document",
      "drive",
      "entry",
      "exit",
      "file",
      "folder",
      "folders",
      "limit",
      "no",
      "prohibited",
      "quit",
      "remove",
      "sheet",
      "slide",
      "stop",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_managed",
    "version": 248,
    "popularity": 35,
    "codepoint": 63349,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "application",
      "change",
      "data",
      "details",
      "doc",
      "document",
      "drive",
      "file",
      "gear",
      "info",
      "information",
      "options",
      "permission",
      "permissions",
      "personal",
      "service",
      "settings",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_off",
    "version": 248,
    "popularity": 311,
    "codepoint": 60291,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "disabled",
      "doc",
      "document",
      "drive",
      "enabled",
      "file",
      "folder",
      "folders",
      "off",
      "on",
      "online",
      "sheet",
      "slash",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_off",
    "version": 1,
    "popularity": 1492,
    "codepoint": 60291,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "data",
      "disabled",
      "doc",
      "document",
      "drive",
      "enabled",
      "file",
      "folder",
      "folders",
      "off",
      "on",
      "online",
      "sheet",
      "slash",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "folder_open",
    "version": 248,
    "popularity": 8788,
    "codepoint": 58056,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "open",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_open",
    "version": 13,
    "popularity": 30797,
    "codepoint": 58056,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "open",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "folder_shared",
    "version": 248,
    "popularity": 1575,
    "codepoint": 58057,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "account",
      "collaboration",
      "data",
      "doc",
      "document",
      "drive",
      "face",
      "file",
      "folder",
      "human",
      "people",
      "person",
      "profile",
      "share",
      "shared",
      "sheet",
      "slide",
      "storage",
      "team",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_shared",
    "version": 16,
    "popularity": 12069,
    "codepoint": 58057,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "account",
      "collaboration",
      "data",
      "doc",
      "document",
      "drive",
      "face",
      "file",
      "folder",
      "human",
      "people",
      "person",
      "profile",
      "share",
      "shared",
      "sheet",
      "slide",
      "storage",
      "team",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "folder_special",
    "version": 248,
    "popularity": 980,
    "codepoint": 58903,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "favorite",
      "file",
      "folder",
      "highlight",
      "important",
      "marked",
      "save",
      "saved",
      "shape",
      "sheet",
      "slide",
      "special",
      "star",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_special",
    "version": 11,
    "popularity": 6030,
    "codepoint": 58903,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "favorite",
      "file",
      "folder",
      "highlight",
      "important",
      "marked",
      "save",
      "saved",
      "shape",
      "sheet",
      "slide",
      "special",
      "star",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "folder_supervised",
    "version": 248,
    "popularity": 16,
    "codepoint": 63348,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "account",
      "body",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "human",
      "permission",
      "permissions",
      "person",
      "profile",
      "sheet",
      "slide",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_zip",
    "version": 248,
    "popularity": 945,
    "codepoint": 60204,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compress",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "open",
      "sheet",
      "slide",
      "storage",
      "zip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "folder_zip",
    "version": 1,
    "popularity": 4404,
    "codepoint": 60204,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "compress",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "open",
      "sheet",
      "slide",
      "storage",
      "zip"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "follow_the_signs",
    "version": 248,
    "popularity": 1020,
    "codepoint": 61986,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "body",
      "directional",
      "follow",
      "human",
      "people",
      "person",
      "right",
      "signs",
      "social",
      "the"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "follow_the_signs",
    "version": 7,
    "popularity": 5268,
    "codepoint": 61986,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arrow",
      "body",
      "directional",
      "follow",
      "human",
      "people",
      "person",
      "right",
      "signs",
      "social",
      "the"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "font_download",
    "version": 248,
    "popularity": 643,
    "codepoint": 57703,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "character",
      "download",
      "font",
      "letter",
      "square",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "font_download",
    "version": 11,
    "popularity": 6245,
    "codepoint": 57703,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "A",
      "alphabet",
      "character",
      "download",
      "font",
      "letter",
      "square",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "font_download_off",
    "version": 248,
    "popularity": 132,
    "codepoint": 58617,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "download",
      "enabled",
      "font",
      "letter",
      "off",
      "on",
      "slash",
      "square",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "font_download_off",
    "version": 4,
    "popularity": 1595,
    "codepoint": 58617,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "download",
      "enabled",
      "font",
      "letter",
      "off",
      "on",
      "slash",
      "square",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "food_bank",
    "version": 248,
    "popularity": 866,
    "codepoint": 61938,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "bank",
      "building",
      "charity",
      "eat",
      "estate",
      "food",
      "fork",
      "house",
      "knife",
      "meal",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "utensils"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "food_bank",
    "version": 7,
    "popularity": 5227,
    "codepoint": 61938,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "bank",
      "building",
      "charity",
      "eat",
      "estate",
      "food",
      "fork",
      "house",
      "knife",
      "meal",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "utensils"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "foot_bones",
    "version": 248,
    "popularity": 176,
    "codepoint": 63635,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bone",
      "bones",
      "foot",
      "health",
      "medical",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "footprint",
    "version": 248,
    "popularity": 108,
    "codepoint": 63613,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "feet",
      "foot",
      "footmark",
      "footprints",
      "footstep",
      "footsteps",
      "ground",
      "impression",
      "shoe",
      "trace",
      "walking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "for_you",
    "version": 248,
    "popularity": 46,
    "codepoint": 59820,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "account",
      "customize",
      "face",
      "for",
      "human",
      "people",
      "person",
      "profile",
      "user",
      "you"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forest",
    "version": 248,
    "popularity": 3038,
    "codepoint": 60057,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "forest",
      "jungle",
      "nature",
      "plantation",
      "plants",
      "trees",
      "woodland"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forest",
    "version": 2,
    "popularity": 6191,
    "codepoint": 60057,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "forest",
      "jungle",
      "nature",
      "plantation",
      "plants",
      "trees",
      "woodland"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "fork_left",
    "version": 248,
    "popularity": 304,
    "codepoint": 60320,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "fork",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fork_left",
    "version": 1,
    "popularity": 914,
    "codepoint": 60320,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "fork",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "fork_right",
    "version": 248,
    "popularity": 547,
    "codepoint": 60332,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "fork",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fork_right",
    "version": 1,
    "popularity": 1612,
    "codepoint": 60332,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "fork",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "forklift",
    "version": 248,
    "popularity": 20,
    "codepoint": 63592,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "carry",
      "factory",
      "industrial",
      "lift",
      "manufactory",
      "supply",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forklift",
    "version": 1,
    "popularity": 1373,
    "codepoint": 63592,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "carry",
      "factory",
      "industrial",
      "lift",
      "manufactory",
      "supply",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "format_align_center",
    "version": 248,
    "popularity": 972,
    "codepoint": 57908,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "center",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_align_center",
    "version": 12,
    "popularity": 6544,
    "codepoint": 57908,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "center",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_align_justify",
    "version": 248,
    "popularity": 721,
    "codepoint": 57909,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "density",
      "doc",
      "edit",
      "editing",
      "editor",
      "extra",
      "format",
      "justify",
      "sheet",
      "small",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_align_justify",
    "version": 12,
    "popularity": 4995,
    "codepoint": 57909,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "density",
      "doc",
      "edit",
      "editing",
      "editor",
      "extra",
      "format",
      "justify",
      "sheet",
      "small",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_align_left",
    "version": 248,
    "popularity": 1392,
    "codepoint": 57910,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "left",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_align_left",
    "version": 13,
    "popularity": 9977,
    "codepoint": 57910,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "left",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_align_right",
    "version": 248,
    "popularity": 904,
    "codepoint": 57911,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "right",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_align_right",
    "version": 13,
    "popularity": 5674,
    "codepoint": 57911,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "right",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_bold",
    "version": 248,
    "popularity": 2353,
    "codepoint": 57912,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "B",
      "alphabet",
      "bold",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "styles",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_bold",
    "version": 12,
    "popularity": 14144,
    "codepoint": 57912,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "B",
      "alphabet",
      "bold",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "styles",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_clear",
    "version": 248,
    "popularity": 311,
    "codepoint": 57913,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "T",
      "alphabet",
      "character",
      "clear",
      "disabled",
      "doc",
      "edit",
      "editing",
      "editor",
      "enabled",
      "font",
      "format",
      "letter",
      "off",
      "on",
      "sheet",
      "slash",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_clear",
    "version": 12,
    "popularity": 2372,
    "codepoint": 57913,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "T",
      "alphabet",
      "character",
      "clear",
      "disabled",
      "doc",
      "edit",
      "editing",
      "editor",
      "enabled",
      "font",
      "format",
      "letter",
      "off",
      "on",
      "sheet",
      "slash",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_color_fill",
    "version": 248,
    "popularity": 1578,
    "codepoint": 57914,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bucket",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_color_fill",
    "version": 14,
    "popularity": 9595,
    "codepoint": 57914,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "bucket",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "format_color_reset",
    "version": 248,
    "popularity": 558,
    "codepoint": 57915,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clear",
      "color",
      "disabled",
      "doc",
      "droplet",
      "edit",
      "editing",
      "editor",
      "enabled",
      "fill",
      "format",
      "off",
      "on",
      "paint",
      "reset",
      "sheet",
      "slash",
      "spreadsheet",
      "style",
      "text",
      "type",
      "water",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_color_reset",
    "version": 11,
    "popularity": 3241,
    "codepoint": 57915,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "clear",
      "color",
      "disabled",
      "doc",
      "droplet",
      "edit",
      "editing",
      "editor",
      "enabled",
      "fill",
      "format",
      "off",
      "on",
      "paint",
      "reset",
      "sheet",
      "slash",
      "spreadsheet",
      "style",
      "text",
      "type",
      "water",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_color_text",
    "version": 248,
    "popularity": 1048,
    "codepoint": 57916,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_color_text",
    "version": 17,
    "popularity": 5706,
    "codepoint": 57916,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "format_h1",
    "version": 248,
    "popularity": 107,
    "codepoint": 63581,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_h2",
    "version": 248,
    "popularity": 62,
    "codepoint": 63582,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_h3",
    "version": 248,
    "popularity": 38,
    "codepoint": 63583,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_h4",
    "version": 248,
    "popularity": 32,
    "codepoint": 63584,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_h5",
    "version": 248,
    "popularity": 35,
    "codepoint": 63585,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_h6",
    "version": 248,
    "popularity": 36,
    "codepoint": 63586,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "header",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_image_left",
    "version": 248,
    "popularity": 56,
    "codepoint": 63587,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "left",
      "paragraph",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_image_right",
    "version": 248,
    "popularity": 42,
    "codepoint": 63588,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paragraph",
      "right",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_indent_decrease",
    "version": 248,
    "popularity": 339,
    "codepoint": 57917,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "decrease",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "indent",
      "indentation",
      "paragraph",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_indent_decrease",
    "version": 13,
    "popularity": 2473,
    "codepoint": 57917,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "decrease",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "indent",
      "indentation",
      "paragraph",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_indent_increase",
    "version": 248,
    "popularity": 527,
    "codepoint": 57918,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "increase",
      "indent",
      "indentation",
      "paragraph",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_indent_increase",
    "version": 13,
    "popularity": 3528,
    "codepoint": 57918,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "increase",
      "indent",
      "indentation",
      "paragraph",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_ink_highlighter",
    "version": 248,
    "popularity": 50,
    "codepoint": 63531,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "highlighter",
      "ink",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_italic",
    "version": 248,
    "popularity": 1592,
    "codepoint": 57919,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "italic",
      "letter",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_italic",
    "version": 12,
    "popularity": 9846,
    "codepoint": 57919,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "italic",
      "letter",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_letter_spacing",
    "version": 248,
    "popularity": 19,
    "codepoint": 63347,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "arrows",
      "distribute",
      "expand",
      "left",
      "right",
      "space",
      "spread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_letter_spacing_2",
    "version": 248,
    "popularity": 14,
    "codepoint": 63000,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alphabet",
      "arrows",
      "character",
      "distribute",
      "expand",
      "font",
      "left",
      "letter",
      "right",
      "space",
      "spread",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_letter_spacing_standard",
    "version": 248,
    "popularity": 18,
    "codepoint": 62999,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alphabet",
      "character",
      "distribute",
      "expand",
      "font",
      "letter",
      "padding",
      "space",
      "spread",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_letter_spacing_wide",
    "version": 248,
    "popularity": 9,
    "codepoint": 62998,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alphabet",
      "character",
      "distribute",
      "expand",
      "font",
      "letter",
      "padding",
      "space",
      "spread",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_letter_spacing_wider",
    "version": 248,
    "popularity": 7,
    "codepoint": 62997,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alphabet",
      "character",
      "distribute",
      "expand",
      "font",
      "letter",
      "padding",
      "space",
      "spread",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_line_spacing",
    "version": 248,
    "popularity": 372,
    "codepoint": 57920,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "line",
      "sheet",
      "spacing",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_line_spacing",
    "version": 12,
    "popularity": 2274,
    "codepoint": 57920,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "line",
      "sheet",
      "spacing",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_list_bulleted",
    "version": 248,
    "popularity": 7186,
    "codepoint": 57921,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "bulleted",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_list_bulleted",
    "version": 13,
    "popularity": 52507,
    "codepoint": 57921,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "bulleted",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_list_bulleted_add",
    "version": 248,
    "popularity": 61,
    "codepoint": 63561,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "align",
      "alignment",
      "bulleted",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "new",
      "note",
      "notes",
      "plus",
      "quick",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_list_bulleted_add",
    "version": 1,
    "popularity": 1822,
    "codepoint": 63561,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "+",
      "add",
      "align",
      "alignment",
      "bulleted",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "new",
      "note",
      "notes",
      "plus",
      "quick",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "format_list_numbered",
    "version": 248,
    "popularity": 2874,
    "codepoint": 57922,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "digit",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "number",
      "numbered",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_list_numbered",
    "version": 12,
    "popularity": 21364,
    "codepoint": 57922,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "digit",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "number",
      "numbered",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_list_numbered_rtl",
    "version": 248,
    "popularity": 647,
    "codepoint": 57959,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "digit",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "number",
      "numbered",
      "rtl",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_list_numbered_rtl",
    "version": 12,
    "popularity": 5302,
    "codepoint": 57959,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "digit",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "list",
      "notes",
      "number",
      "numbered",
      "rtl",
      "sheet",
      "spreadsheet",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_overline",
    "version": 248,
    "popularity": 139,
    "codepoint": 60261,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "overline",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_overline",
    "version": 1,
    "popularity": 674,
    "codepoint": 60261,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "overline",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "format_paint",
    "version": 248,
    "popularity": 1402,
    "codepoint": 57923,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "brush",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "roller",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_paint",
    "version": 12,
    "popularity": 6032,
    "codepoint": 57923,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "brush",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paint",
      "roller",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_paragraph",
    "version": 248,
    "popularity": 58,
    "codepoint": 63589,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "paragraph",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_quote",
    "version": 248,
    "popularity": 4228,
    "codepoint": 57924,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "quotation",
      "quote",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_quote",
    "version": 12,
    "popularity": 23385,
    "codepoint": 57924,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "quotation",
      "quote",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_shapes",
    "version": 248,
    "popularity": 674,
    "codepoint": 57950,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "font",
      "format",
      "letter",
      "paint",
      "shapes",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_shapes",
    "version": 12,
    "popularity": 4175,
    "codepoint": 57950,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alphabet",
      "character",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "font",
      "format",
      "letter",
      "paint",
      "shapes",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_size",
    "version": 248,
    "popularity": 1263,
    "codepoint": 57925,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "font",
      "format",
      "letter",
      "paint",
      "sheet",
      "size",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_size",
    "version": 12,
    "popularity": 7901,
    "codepoint": 57925,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alphabet",
      "character",
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "font",
      "format",
      "letter",
      "paint",
      "sheet",
      "size",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_strikethrough",
    "version": 248,
    "popularity": 397,
    "codepoint": 57926,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "strikethrough",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_strikethrough",
    "version": 12,
    "popularity": 1984,
    "codepoint": 57926,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "strikethrough",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_text_clip",
    "version": 248,
    "popularity": 4,
    "codepoint": 63530,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "truncate",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_text_overflow",
    "version": 248,
    "popularity": 16,
    "codepoint": 63529,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "overlap",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_text_wrap",
    "version": 248,
    "popularity": 1,
    "codepoint": 63528,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "wrap",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_textdirection_l_to_r",
    "version": 248,
    "popularity": 143,
    "codepoint": 57927,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "ltr",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_textdirection_l_to_r",
    "version": 13,
    "popularity": 867,
    "codepoint": 57927,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "ltr",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_textdirection_r_to_l",
    "version": 248,
    "popularity": 131,
    "codepoint": 57928,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "rtl",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_textdirection_r_to_l",
    "version": 13,
    "popularity": 818,
    "codepoint": 57928,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "doc",
      "edit",
      "editing",
      "editor",
      "format",
      "rtl",
      "sheet",
      "spreadsheet",
      "text",
      "textdirection",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_underlined",
    "version": 248,
    "popularity": 1215,
    "codepoint": 57929,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "underlined",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "format_underlined",
    "version": 13,
    "popularity": 7692,
    "codepoint": 57929,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "underlined",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "format_underlined_squiggle",
    "version": 248,
    "popularity": 66,
    "codepoint": 63621,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "line",
      "sheet",
      "spreadsheet",
      "squiggle",
      "style",
      "symbol",
      "text",
      "type",
      "under",
      "underlined",
      "wavy",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forms_add_on",
    "version": 248,
    "popularity": 675,
    "codepoint": 61639,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forms_apps_script",
    "version": 248,
    "popularity": 156,
    "codepoint": 61640,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fort",
    "version": 248,
    "popularity": 293,
    "codepoint": 60077,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "castle",
      "fort",
      "fortress",
      "mansion",
      "palace"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fort",
    "version": 2,
    "popularity": 1124,
    "codepoint": 60077,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "castle",
      "fort",
      "fortress",
      "mansion",
      "palace"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "forum",
    "version": 248,
    "popularity": 12274,
    "codepoint": 57535,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "community",
      "conversation",
      "feedback",
      "forum",
      "hub",
      "message",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forum",
    "version": 19,
    "popularity": 31990,
    "codepoint": 57535,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "community",
      "conversation",
      "feedback",
      "forum",
      "hub",
      "message",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "forward",
    "version": 248,
    "popularity": 3479,
    "codepoint": 57684,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "forward",
      "mail",
      "message",
      "playback",
      "right",
      "sent"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forward",
    "version": 17,
    "popularity": 16468,
    "codepoint": 57684,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "arrow",
      "forward",
      "mail",
      "message",
      "playback",
      "right",
      "sent"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "forward_10",
    "version": 248,
    "popularity": 1029,
    "codepoint": 57430,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "10",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "play",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forward_10",
    "version": 13,
    "popularity": 6343,
    "codepoint": 57430,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "10",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "play",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "forward_30",
    "version": 248,
    "popularity": 351,
    "codepoint": 57431,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "30",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forward_30",
    "version": 13,
    "popularity": 2504,
    "codepoint": 57431,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "30",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "forward_5",
    "version": 248,
    "popularity": 349,
    "codepoint": 57432,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "10",
      "5",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forward_5",
    "version": 13,
    "popularity": 2128,
    "codepoint": 57432,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "10",
      "5",
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "forward_circle",
    "version": 248,
    "popularity": 10,
    "codepoint": 63221,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forward_media",
    "version": 248,
    "popularity": 23,
    "codepoint": 63220,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "digit",
      "fast",
      "forward",
      "music",
      "number",
      "seconds",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forward_to_inbox",
    "version": 248,
    "popularity": 3170,
    "codepoint": 61831,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "arrows",
      "directions",
      "email",
      "envelop",
      "forward",
      "inbox",
      "letter",
      "mail",
      "message",
      "navigation",
      "outgoing",
      "right",
      "send",
      "to"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "forward_to_inbox",
    "version": 9,
    "popularity": 17249,
    "codepoint": 61831,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "arrows",
      "directions",
      "email",
      "envelop",
      "forward",
      "inbox",
      "letter",
      "mail",
      "message",
      "navigation",
      "outgoing",
      "right",
      "send",
      "to"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "foundation",
    "version": 248,
    "popularity": 992,
    "codepoint": 61952,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "base",
      "basis",
      "building",
      "construction",
      "estate",
      "foundation",
      "home",
      "house",
      "real",
      "residential"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "foundation",
    "version": 6,
    "popularity": 5528,
    "codepoint": 61952,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "base",
      "basis",
      "building",
      "construction",
      "estate",
      "foundation",
      "home",
      "house",
      "real",
      "residential"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "frame_inspect",
    "version": 248,
    "popularity": 111,
    "codepoint": 63346,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "borders",
      "filter",
      "find",
      "frames",
      "look",
      "magnify",
      "magnifying glass",
      "mode",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "frame_person",
    "version": 248,
    "popularity": 723,
    "codepoint": 63654,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "body",
      "center",
      "frame",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "frame_person_off",
    "version": 248,
    "popularity": 2,
    "codepoint": 63441,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "body",
      "border",
      "borders",
      "center",
      "disabled",
      "enabled",
      "frames",
      "human",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "frame_reload",
    "version": 248,
    "popularity": 4,
    "codepoint": 63345,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "around",
      "arrow",
      "border",
      "borders",
      "frames",
      "inprogress",
      "load",
      "loading",
      "refresh",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "frame_source",
    "version": 248,
    "popularity": 32,
    "codepoint": 63344,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "border",
      "borders",
      "code",
      "coding",
      "create",
      "development",
      "frames"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "free_breakfast",
    "version": 11,
    "popularity": 6470,
    "codepoint": 60228,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "beverage",
      "breakfast",
      "cafe",
      "coffee",
      "cup",
      "drink",
      "free",
      "mug",
      "tea"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "free_cancellation",
    "version": 248,
    "popularity": 1302,
    "codepoint": 59208,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "calendar",
      "cancel",
      "cancellation",
      "check",
      "clear",
      "complete",
      "date",
      "day",
      "done",
      "event",
      "exit",
      "free",
      "mark",
      "month",
      "no",
      "ok",
      "remove",
      "schedule",
      "select",
      "stop",
      "tick",
      "validate",
      "verified",
      "x",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "free_cancellation",
    "version": 5,
    "popularity": 5535,
    "codepoint": 59208,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "calendar",
      "cancel",
      "cancellation",
      "check",
      "clear",
      "complete",
      "date",
      "day",
      "done",
      "event",
      "exit",
      "free",
      "mark",
      "month",
      "no",
      "ok",
      "remove",
      "schedule",
      "select",
      "stop",
      "tick",
      "validate",
      "verified",
      "x",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "front_hand",
    "version": 248,
    "popularity": 2599,
    "codepoint": 59241,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "fingers",
      "front",
      "gesture",
      "hand",
      "hello",
      "palm",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "front_hand",
    "version": 3,
    "popularity": 9832,
    "codepoint": 59241,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "fingers",
      "front",
      "gesture",
      "hand",
      "hello",
      "palm",
      "stop"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "front_loader",
    "version": 248,
    "popularity": 13,
    "codepoint": 63593,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "carry",
      "factory",
      "industrial",
      "load",
      "manufactory",
      "supply",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "front_loader",
    "version": 1,
    "popularity": 978,
    "codepoint": 63593,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "carry",
      "factory",
      "industrial",
      "load",
      "manufactory",
      "supply",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "full_coverage",
    "version": 248,
    "popularity": 84,
    "codepoint": 60178,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "breaking",
      "coverage",
      "full",
      "information",
      "magazine",
      "news",
      "newspaper",
      "paper",
      "propaganda"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "full_hd",
    "version": 248,
    "popularity": 10,
    "codepoint": 62859,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "camera",
      "character",
      "font",
      "hd",
      "high",
      "letter",
      "movie",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "full_stacked_bar_chart",
    "version": 248,
    "popularity": 615,
    "codepoint": 61970,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "full",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fullscreen",
    "version": 248,
    "popularity": 6710,
    "codepoint": 58832,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "adjust",
      "app",
      "application",
      "components",
      "full",
      "fullscreen",
      "interface",
      "screen",
      "site",
      "size",
      "ui",
      "ux",
      "view",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fullscreen",
    "version": 12,
    "popularity": 34441,
    "codepoint": 58832,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "adjust",
      "app",
      "application",
      "components",
      "full",
      "fullscreen",
      "interface",
      "screen",
      "site",
      "size",
      "ui",
      "ux",
      "view",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "fullscreen_exit",
    "version": 248,
    "popularity": 2840,
    "codepoint": 58833,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "adjust",
      "app",
      "application",
      "components",
      "exit",
      "full",
      "fullscreen",
      "interface",
      "screen",
      "site",
      "size",
      "ui",
      "ux",
      "view",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "fullscreen_exit",
    "version": 12,
    "popularity": 15448,
    "codepoint": 58833,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "adjust",
      "app",
      "application",
      "components",
      "exit",
      "full",
      "fullscreen",
      "interface",
      "screen",
      "site",
      "size",
      "ui",
      "ux",
      "view",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "function",
    "version": 248,
    "popularity": 297,
    "codepoint": 63590,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "average",
      "calculate",
      "count",
      "custom",
      "doc",
      "edit",
      "editing",
      "editor",
      "functions",
      "math",
      "sheet",
      "spreadsheet",
      "style",
      "sum",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "functions",
    "version": 248,
    "popularity": 1622,
    "codepoint": 57930,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "average",
      "calculate",
      "count",
      "custom",
      "doc",
      "edit",
      "editing",
      "editor",
      "functions",
      "math",
      "sheet",
      "spreadsheet",
      "style",
      "sum",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "functions",
    "version": 12,
    "popularity": 10906,
    "codepoint": 57930,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "average",
      "calculate",
      "count",
      "custom",
      "doc",
      "edit",
      "editing",
      "editor",
      "functions",
      "math",
      "sheet",
      "spreadsheet",
      "style",
      "sum",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "g_mobiledata",
    "version": 248,
    "popularity": 155,
    "codepoint": 61456,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "g",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "g_mobiledata",
    "version": 9,
    "popularity": 876,
    "codepoint": 61456,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "g",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "g_mobiledata_badge",
    "version": 248,
    "popularity": 2,
    "codepoint": 63457,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "g",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "g_translate",
    "version": 248,
    "popularity": 1980,
    "codepoint": 59687,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "emblem",
      "g",
      "google",
      "language",
      "logo",
      "mark",
      "speaking",
      "speech",
      "translate",
      "translator",
      "words"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "g_translate",
    "version": 15,
    "popularity": 13616,
    "codepoint": 59687,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "emblem",
      "g",
      "google",
      "language",
      "logo",
      "mark",
      "speaking",
      "speech",
      "translate",
      "translator",
      "words"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gallery_thumbnail",
    "version": 248,
    "popularity": 61,
    "codepoint": 63599,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "gallery",
      "grid",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "photos",
      "picture",
      "preview",
      "thumbnail"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gamepad",
    "version": 248,
    "popularity": 84,
    "codepoint": 58127,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "buttons",
      "console",
      "controller",
      "device",
      "game",
      "gamepad",
      "gaming",
      "playstation",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gamepad",
    "version": 12,
    "popularity": 4241,
    "codepoint": 58127,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "buttons",
      "console",
      "controller",
      "device",
      "game",
      "gamepad",
      "gaming",
      "playstation",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "games",
    "version": 12,
    "popularity": 6952,
    "codepoint": 57377,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "adjust",
      "arrow",
      "arrows",
      "control",
      "controller",
      "direction",
      "games",
      "gaming",
      "left",
      "move",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "garage",
    "version": 248,
    "popularity": 927,
    "codepoint": 61457,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "automobile",
      "automotive",
      "car",
      "cars",
      "direction",
      "garage",
      "maps",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "garage",
    "version": 10,
    "popularity": 5761,
    "codepoint": 61457,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "automobile",
      "automotive",
      "car",
      "cars",
      "direction",
      "garage",
      "maps",
      "transportation",
      "travel",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "garage_door",
    "version": 248,
    "popularity": 21,
    "codepoint": 59156,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "car",
      "door",
      "garage",
      "home",
      "house",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "garage_home",
    "version": 248,
    "popularity": 1053,
    "codepoint": 59437,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "car",
      "garage",
      "home",
      "nest",
      "parking",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "garden_cart",
    "version": 248,
    "popularity": 761,
    "codepoint": 63657,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "cart",
      "garden",
      "gardening",
      "wheelbarrow",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gas_meter",
    "version": 248,
    "popularity": 494,
    "codepoint": 60441,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "droplet",
      "energy",
      "gas",
      "measure",
      "meter",
      "nest",
      "usage",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gas_meter",
    "version": 1,
    "popularity": 1519,
    "codepoint": 60441,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "droplet",
      "energy",
      "gas",
      "measure",
      "meter",
      "nest",
      "usage",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "gastroenterology",
    "version": 248,
    "popularity": 4,
    "codepoint": 57585,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "digest",
      "digestive",
      "gastro",
      "gi",
      "gut",
      "guts",
      "health",
      "human",
      "intestine",
      "stomach",
      "tract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gate",
    "version": 248,
    "popularity": 446,
    "codepoint": 57975,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "backyard",
      "barrier",
      "boundaries",
      "boundary",
      "door",
      "entrance",
      "fence",
      "flowers",
      "garden",
      "gate",
      "grass",
      "home",
      "house",
      "nature",
      "nest",
      "outdoor",
      "outside",
      "protection",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gavel",
    "version": 248,
    "popularity": 4484,
    "codepoint": 59662,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "agreement",
      "contract",
      "court",
      "document",
      "gavel",
      "government",
      "judge",
      "law",
      "mallet",
      "official",
      "police",
      "rule",
      "rules",
      "terms"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gavel",
    "version": 14,
    "popularity": 28797,
    "codepoint": 59662,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "agreement",
      "contract",
      "court",
      "document",
      "gavel",
      "government",
      "judge",
      "law",
      "mallet",
      "official",
      "police",
      "rule",
      "rules",
      "terms"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "general_device",
    "version": 248,
    "popularity": 15,
    "codepoint": 59102,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "general",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "remote",
      "smart",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "generating_tokens",
    "version": 5,
    "popularity": 5660,
    "codepoint": 59209,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "access",
      "ai",
      "api",
      "artificial",
      "automatic",
      "automation",
      "coin",
      "custom",
      "genai",
      "generating",
      "intelligence",
      "magic",
      "smart",
      "spark",
      "sparkle",
      "star",
      "tokens"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "genetics",
    "version": 248,
    "popularity": 31,
    "codepoint": 57587,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "dna",
      "gene",
      "genetic",
      "health",
      "hereditary",
      "medical",
      "science"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "genres",
    "version": 248,
    "popularity": 37,
    "codepoint": 57378,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "circle",
      "genres",
      "music",
      "note",
      "songs",
      "themes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gesture",
    "version": 248,
    "popularity": 986,
    "codepoint": 57685,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "doodle",
      "draw",
      "drawing",
      "finger",
      "gesture",
      "gestures",
      "hand",
      "motion",
      "string",
      "thread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gesture",
    "version": 12,
    "popularity": 5675,
    "codepoint": 57685,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "doodle",
      "draw",
      "drawing",
      "finger",
      "gesture",
      "gestures",
      "hand",
      "motion",
      "string",
      "thread"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gesture_select",
    "version": 248,
    "popularity": 29,
    "codepoint": 63063,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "dash",
      "dashed",
      "finger",
      "hand",
      "pointer",
      "selection"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "get_app",
    "version": 12,
    "popularity": 26179,
    "codepoint": 59524,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "arrow",
      "arrows",
      "down",
      "download",
      "downloads",
      "export",
      "get",
      "install",
      "play",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gif",
    "version": 248,
    "popularity": 467,
    "codepoint": 59656,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "animated",
      "animation",
      "bitmap",
      "character",
      "font",
      "format",
      "gif",
      "graphics",
      "interchange",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gif",
    "version": 14,
    "popularity": 5145,
    "codepoint": 59656,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "animated",
      "animation",
      "bitmap",
      "character",
      "font",
      "format",
      "gif",
      "graphics",
      "interchange",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gif_box",
    "version": 248,
    "popularity": 736,
    "codepoint": 59299,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "animated",
      "animation",
      "bitmap",
      "character",
      "font",
      "format",
      "gif",
      "graphics",
      "interchange",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gif_box",
    "version": 3,
    "popularity": 3906,
    "codepoint": 59299,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "animated",
      "animation",
      "bitmap",
      "character",
      "font",
      "format",
      "gif",
      "graphics",
      "interchange",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "girl",
    "version": 248,
    "popularity": 665,
    "codepoint": 60264,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "female",
      "gender",
      "girl",
      "human",
      "lady",
      "people",
      "person",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "girl",
    "version": 1,
    "popularity": 2586,
    "codepoint": 60264,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "female",
      "gender",
      "girl",
      "human",
      "lady",
      "people",
      "person",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "gite",
    "version": 248,
    "popularity": 597,
    "codepoint": 58763,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "estate",
      "gite",
      "home",
      "hostel",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gite",
    "version": 4,
    "popularity": 5100,
    "codepoint": 58763,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "estate",
      "gite",
      "home",
      "hostel",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "glass_cup",
    "version": 248,
    "popularity": 10,
    "codepoint": 63203,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "drink",
      "drinking",
      "fitbit",
      "water",
      "waterglass"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "globe",
    "version": 248,
    "popularity": 125,
    "codepoint": 58956,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "global",
      "globe",
      "internet",
      "language",
      "planet",
      "website",
      "world",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "globe_asia",
    "version": 248,
    "popularity": 86,
    "codepoint": 63385,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "global",
      "globe",
      "internet",
      "language",
      "planet",
      "southeast",
      "website",
      "world",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "globe_uk",
    "version": 248,
    "popularity": 55,
    "codepoint": 63384,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "global",
      "globe",
      "internet",
      "language",
      "planet",
      "united kingdom",
      "website",
      "world",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "glucose",
    "version": 248,
    "popularity": 28,
    "codepoint": 58528,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "blood",
      "blood sugar",
      "blood test",
      "body",
      "diabetes",
      "drop",
      "droplet",
      "finger",
      "hand",
      "health",
      "human",
      "medical",
      "test"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "glyphs",
    "version": 248,
    "popularity": 311,
    "codepoint": 63651,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "ampersand",
      "character",
      "emoji",
      "hieroglyph",
      "percent",
      "sign",
      "symbols",
      "tester",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "go_to_line",
    "version": 248,
    "popularity": 20,
    "codepoint": 63261,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "dots",
      "goto",
      "jump",
      "move",
      "squares"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "golf_course",
    "version": 248,
    "popularity": 835,
    "codepoint": 60229,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "club",
      "course",
      "entertainment",
      "flag",
      "golf",
      "golfer",
      "golfing",
      "hobby",
      "hole",
      "places",
      "putt",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "golf_course",
    "version": 11,
    "popularity": 4593,
    "codepoint": 60229,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "club",
      "course",
      "entertainment",
      "flag",
      "golf",
      "golfer",
      "golfing",
      "hobby",
      "hole",
      "places",
      "putt",
      "sports"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "google_home_devices",
    "version": 248,
    "popularity": 74,
    "codepoint": 59157,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "chrome",
      "desktop",
      "device",
      "devices",
      "google",
      "hardware",
      "home",
      "monitor",
      "music",
      "sound",
      "speaker",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "google_tv_remote",
    "version": 248,
    "popularity": 9,
    "codepoint": 62939,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "controller",
      "device",
      "google",
      "hardware",
      "nest",
      "remote"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "google_wifi",
    "version": 248,
    "popularity": 284,
    "codepoint": 59456,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "device",
      "gale",
      "google",
      "hardware",
      "internet",
      "mobile",
      "nest",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gpp_bad",
    "version": 248,
    "popularity": 1249,
    "codepoint": 61458,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bad",
      "cancel",
      "certified",
      "close",
      "error",
      "exit",
      "gpp",
      "no",
      "privacy",
      "private",
      "protect",
      "protection",
      "remove",
      "security",
      "shield",
      "sim",
      "stop",
      "verified",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gpp_bad",
    "version": 10,
    "popularity": 7127,
    "codepoint": 61458,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bad",
      "cancel",
      "certified",
      "close",
      "error",
      "exit",
      "gpp",
      "no",
      "privacy",
      "private",
      "protect",
      "protection",
      "remove",
      "security",
      "shield",
      "sim",
      "stop",
      "verified",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gpp_good",
    "version": 9,
    "popularity": 16163,
    "codepoint": 61459,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "certified",
      "check",
      "good",
      "gpp",
      "ok",
      "pass",
      "security",
      "shield",
      "sim",
      "tick"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gpp_maybe",
    "version": 248,
    "popularity": 1552,
    "codepoint": 61460,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "certified",
      "danger",
      "error",
      "exclamation",
      "gpp",
      "important",
      "mark",
      "maybe",
      "notification",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "sim",
      "symbol",
      "verified",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gpp_maybe",
    "version": 10,
    "popularity": 8487,
    "codepoint": 61460,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "certified",
      "danger",
      "error",
      "exclamation",
      "gpp",
      "important",
      "mark",
      "maybe",
      "notification",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "sim",
      "symbol",
      "verified",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gps_fixed",
    "version": 12,
    "popularity": 19794,
    "codepoint": 57779,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "destination",
      "direction",
      "fixed",
      "gps",
      "location",
      "maps",
      "pin",
      "place",
      "pointer",
      "stop",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gps_not_fixed",
    "version": 12,
    "popularity": 3937,
    "codepoint": 57780,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "gps",
      "location",
      "maps",
      "not fixed",
      "off",
      "on",
      "online",
      "place",
      "pointer",
      "slash",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gps_off",
    "version": 12,
    "popularity": 2057,
    "codepoint": 57781,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "gps",
      "location",
      "maps",
      "not fixed",
      "off",
      "offline",
      "on",
      "place",
      "pointer",
      "slash",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grade",
    "version": 248,
    "popularity": 23235,
    "codepoint": 59525,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "'favorite_news'  .",
      "'star_outline'",
      "Duplicate of 'star_boarder'",
      "star_border_purple500'"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grade",
    "version": 16,
    "popularity": 57809,
    "codepoint": 59525,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "'favorite_news'  .",
      "'star_outline'",
      "Duplicate of 'star_boarder'",
      "star_border_purple500'"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "gradient",
    "version": 248,
    "popularity": 450,
    "codepoint": 58345,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "color",
      "edit",
      "editing",
      "effect",
      "filter",
      "gradient",
      "image",
      "images",
      "photography",
      "picture",
      "pictures"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "gradient",
    "version": 12,
    "popularity": 3050,
    "codepoint": 58345,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "color",
      "edit",
      "editing",
      "effect",
      "filter",
      "gradient",
      "image",
      "images",
      "photography",
      "picture",
      "pictures"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grading",
    "version": 248,
    "popularity": 889,
    "codepoint": 59983,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "'favorite'_new'.  ' Remove this icon & keep 'star'.",
      "'star_boarder'",
      "'star_border_purple500'",
      "'star_outline'",
      "'star_purple500'",
      "'star_rate'",
      "Same as 'star'"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grading",
    "version": 12,
    "popularity": 17589,
    "codepoint": 59983,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "'favorite'_new'.  ' Remove this icon & keep 'star'.",
      "'star_boarder'",
      "'star_border_purple500'",
      "'star_outline'",
      "'star_purple500'",
      "'star_rate'",
      "Same as 'star'"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "grain",
    "version": 248,
    "popularity": 778,
    "codepoint": 58346,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "dots",
      "edit",
      "editing",
      "effect",
      "filter",
      "grain",
      "image",
      "images",
      "photography",
      "picture",
      "pictures"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grain",
    "version": 12,
    "popularity": 5203,
    "codepoint": 58346,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "dots",
      "edit",
      "editing",
      "effect",
      "filter",
      "grain",
      "image",
      "images",
      "photography",
      "picture",
      "pictures"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "graphic_eq",
    "version": 248,
    "popularity": 1995,
    "codepoint": 57784,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "audio",
      "detect",
      "detection",
      "eq",
      "equalizer",
      "graphic",
      "music",
      "noise",
      "recording",
      "sound",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "graphic_eq",
    "version": 13,
    "popularity": 11874,
    "codepoint": 57784,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "audio",
      "detect",
      "detection",
      "eq",
      "equalizer",
      "graphic",
      "music",
      "noise",
      "recording",
      "sound",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grass",
    "version": 248,
    "popularity": 1866,
    "codepoint": 61957,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "backyard",
      "fodder",
      "grass",
      "ground",
      "home",
      "lawn",
      "plant",
      "turf",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grass",
    "version": 7,
    "popularity": 10940,
    "codepoint": 61957,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "backyard",
      "fodder",
      "grass",
      "ground",
      "home",
      "lawn",
      "plant",
      "turf",
      "yard"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grid_3x3",
    "version": 248,
    "popularity": 249,
    "codepoint": 61461,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3",
      "grid",
      "layout",
      "line",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_3x3",
    "version": 10,
    "popularity": 1921,
    "codepoint": 61461,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "grid",
      "layout",
      "line",
      "space"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grid_3x3_off",
    "version": 248,
    "popularity": 1,
    "codepoint": 63100,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3",
      "disabled",
      "enabled",
      "grid",
      "layout",
      "line",
      "off",
      "on",
      "slash",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_4x4",
    "version": 248,
    "popularity": 403,
    "codepoint": 61462,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "4",
      "by",
      "grid",
      "layout",
      "lines",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_4x4",
    "version": 10,
    "popularity": 2977,
    "codepoint": 61462,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4",
      "by",
      "grid",
      "layout",
      "lines",
      "space"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grid_goldenratio",
    "version": 248,
    "popularity": 159,
    "codepoint": 61463,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "golden",
      "goldenratio",
      "grid",
      "layout",
      "lines",
      "ratio",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_goldenratio",
    "version": 10,
    "popularity": 1116,
    "codepoint": 61463,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "golden",
      "goldenratio",
      "grid",
      "layout",
      "lines",
      "ratio",
      "space"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grid_guides",
    "version": 248,
    "popularity": 26,
    "codepoint": 63343,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "grids",
      "guide",
      "guideline",
      "guidelines",
      "placeholder",
      "template"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_off",
    "version": 248,
    "popularity": 213,
    "codepoint": 58347,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "collage",
      "disabled",
      "enabled",
      "grid",
      "image",
      "layout",
      "off",
      "on",
      "slash",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_off",
    "version": 12,
    "popularity": 1483,
    "codepoint": 58347,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "collage",
      "disabled",
      "enabled",
      "grid",
      "image",
      "layout",
      "off",
      "on",
      "slash",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grid_on",
    "version": 248,
    "popularity": 1889,
    "codepoint": 58348,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "background",
      "collage",
      "disabled",
      "enabled",
      "grid",
      "image",
      "layout",
      "off",
      "on",
      "slash",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_on",
    "version": 12,
    "popularity": 10082,
    "codepoint": 58348,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "background",
      "collage",
      "disabled",
      "enabled",
      "grid",
      "image",
      "layout",
      "off",
      "on",
      "slash",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grid_view",
    "version": 248,
    "popularity": 13807,
    "codepoint": 59824,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application square",
      "blocks",
      "components",
      "dashboard",
      "design",
      "grid",
      "interface",
      "layout",
      "screen",
      "site",
      "tiles",
      "ui",
      "ux",
      "view",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "grid_view",
    "version": 12,
    "popularity": 62164,
    "codepoint": 59824,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "app",
      "application square",
      "blocks",
      "components",
      "dashboard",
      "design",
      "grid",
      "interface",
      "layout",
      "screen",
      "site",
      "tiles",
      "ui",
      "ux",
      "view",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grocery",
    "version": 248,
    "popularity": 82,
    "codepoint": 61335,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "apple",
      "bottle",
      "drink",
      "food",
      "fruit",
      "grocery",
      "ingredients",
      "milk",
      "produce"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "group",
    "version": 248,
    "popularity": 35556,
    "codepoint": 59375,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "group",
    "version": 19,
    "popularity": 59018,
    "codepoint": 59375,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "group_add",
    "version": 248,
    "popularity": 8881,
    "codepoint": 59376,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "accounts",
      "add",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "increase",
      "more",
      "network",
      "people",
      "persons",
      "plus",
      "profiles",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "group_add",
    "version": 18,
    "popularity": 40506,
    "codepoint": 59376,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accounts",
      "add",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "increase",
      "more",
      "network",
      "people",
      "persons",
      "plus",
      "profiles",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "group_off",
    "version": 248,
    "popularity": 618,
    "codepoint": 59207,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "group",
      "human",
      "meeting",
      "off",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "group_off",
    "version": 5,
    "popularity": 2867,
    "codepoint": 59207,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "group",
      "human",
      "meeting",
      "off",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "group_remove",
    "version": 248,
    "popularity": 1063,
    "codepoint": 59309,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "remove",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "group_remove",
    "version": 3,
    "popularity": 3399,
    "codepoint": 59309,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "group",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "remove",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "group_work",
    "version": 248,
    "popularity": 2665,
    "codepoint": 59526,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "alliance",
      "collaboration",
      "group",
      "partnership",
      "team",
      "teamwork",
      "together",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "group_work",
    "version": 12,
    "popularity": 20109,
    "codepoint": 59526,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alliance",
      "collaboration",
      "group",
      "partnership",
      "team",
      "teamwork",
      "together",
      "work"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "grouped_bar_chart",
    "version": 248,
    "popularity": 864,
    "codepoint": 61969,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "grouped",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "groups",
    "version": 248,
    "popularity": 19889,
    "codepoint": 62003,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "groups",
    "version": 6,
    "popularity": 163636,
    "codepoint": 62003,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "groups_2",
    "version": 248,
    "popularity": 1936,
    "codepoint": 63711,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "hair",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "groups_2",
    "version": 1,
    "popularity": 3987,
    "codepoint": 63711,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "hair",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "groups_3",
    "version": 248,
    "popularity": 1083,
    "codepoint": 63712,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "abstract",
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "groups_3",
    "version": 1,
    "popularity": 2438,
    "codepoint": 63712,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "abstract",
      "body",
      "club",
      "collaboration",
      "crowd",
      "gathering",
      "groups",
      "human",
      "meeting",
      "people",
      "person",
      "social",
      "teams"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "gynecology",
    "version": 248,
    "popularity": 5,
    "codepoint": 57588,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "cervix",
      "fallopian",
      "gyno",
      "health",
      "human",
      "obgyn",
      "ovaries",
      "ovary",
      "reproductive",
      "uterus"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "h_mobiledata",
    "version": 248,
    "popularity": 119,
    "codepoint": 61464,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "h_mobiledata",
    "version": 9,
    "popularity": 809,
    "codepoint": 61464,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "h_mobiledata_badge",
    "version": 248,
    "popularity": 1,
    "codepoint": 63456,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "h_plus_mobiledata",
    "version": 248,
    "popularity": 117,
    "codepoint": 61465,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "plus",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "h_plus_mobiledata",
    "version": 9,
    "popularity": 716,
    "codepoint": 61465,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "plus",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "h_plus_mobiledata_badge",
    "version": 248,
    "popularity": 2,
    "codepoint": 63455,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "h",
      "letter",
      "mobile",
      "network",
      "plus",
      "service",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hail",
    "version": 248,
    "popularity": 1027,
    "codepoint": 59825,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "body",
      "hail",
      "human",
      "people",
      "person",
      "pick",
      "public",
      "stop",
      "taxi",
      "transportation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hail",
    "version": 11,
    "popularity": 6860,
    "codepoint": 59825,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "body",
      "hail",
      "human",
      "people",
      "person",
      "pick",
      "public",
      "stop",
      "taxi",
      "transportation"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hallway",
    "version": 248,
    "popularity": 7,
    "codepoint": 59128,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "frame",
      "hallway",
      "hanging",
      "home",
      "house",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hand_bones",
    "version": 248,
    "popularity": 296,
    "codepoint": 63636,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bone",
      "bones",
      "fingers",
      "hand",
      "health",
      "medical",
      "palm",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hand_gesture",
    "version": 248,
    "popularity": 954,
    "codepoint": 61340,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "fingers",
      "gesture",
      "hand",
      "palm"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "handshake",
    "version": 248,
    "popularity": 13279,
    "codepoint": 60363,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "agreement",
      "hand",
      "hands",
      "partnership",
      "shake"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "handshake",
    "version": 1,
    "popularity": 19761,
    "codepoint": 60363,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "agreement",
      "hand",
      "hands",
      "partnership",
      "shake"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "handyman",
    "version": 248,
    "popularity": 3904,
    "codepoint": 61707,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "build",
      "construction",
      "fix",
      "hammer",
      "handyman",
      "repair",
      "screw",
      "screwdriver",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "handyman",
    "version": 12,
    "popularity": 25260,
    "codepoint": 61707,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "build",
      "construction",
      "fix",
      "hammer",
      "handyman",
      "repair",
      "screw",
      "screwdriver",
      "tools"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hangout_video",
    "version": 248,
    "popularity": 32,
    "codepoint": 57537,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "camera",
      "film",
      "filming",
      "google",
      "hangout",
      "image",
      "motion",
      "picture",
      "rectangle",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hangout_video_off",
    "version": 248,
    "popularity": 9,
    "codepoint": 57538,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "camera",
      "disabled",
      "enabled",
      "film",
      "filming",
      "google",
      "hangout",
      "image",
      "motion",
      "off",
      "on",
      "picture",
      "rectangle",
      "slash",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hard_drive",
    "version": 248,
    "popularity": 25,
    "codepoint": 63502,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "documents",
      "health",
      "save",
      "storage",
      "store"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hard_drive_2",
    "version": 248,
    "popularity": 8,
    "codepoint": 63396,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "data",
      "documents",
      "save",
      "storage",
      "store"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hardware",
    "version": 248,
    "popularity": 523,
    "codepoint": 59993,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "break",
      "construction",
      "hammer",
      "hardware",
      "nail",
      "repair",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hardware",
    "version": 10,
    "popularity": 4828,
    "codepoint": 59993,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "break",
      "construction",
      "hammer",
      "hardware",
      "nail",
      "repair",
      "tool"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hd",
    "version": 248,
    "popularity": 584,
    "codepoint": 57426,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "definition",
      "display",
      "font",
      "hd",
      "high",
      "letter",
      "movie",
      "movies",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hd",
    "version": 12,
    "popularity": 3078,
    "codepoint": 57426,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alphabet",
      "character",
      "definition",
      "display",
      "font",
      "hd",
      "high",
      "letter",
      "movie",
      "movies",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_auto",
    "version": 248,
    "popularity": 449,
    "codepoint": 61466,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "A",
      "alphabet",
      "auto",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "photo",
      "range",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_auto",
    "version": 10,
    "popularity": 2747,
    "codepoint": 61466,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "A",
      "alphabet",
      "auto",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "photo",
      "range",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_auto_select",
    "version": 248,
    "popularity": 105,
    "codepoint": 61467,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "A",
      "alphabet",
      "auto",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "photo",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_auto_select",
    "version": 10,
    "popularity": 652,
    "codepoint": 61467,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "+",
      "A",
      "alphabet",
      "auto",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "photo",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_enhanced_select",
    "version": 248,
    "popularity": 132,
    "codepoint": 61265,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "add",
      "alphabet",
      "character",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_enhanced_select",
    "version": 11,
    "popularity": 712,
    "codepoint": 61265,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "add",
      "alphabet",
      "character",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_off",
    "version": 248,
    "popularity": 134,
    "codepoint": 58349,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "dynamic",
      "enabled",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "off",
      "on",
      "range",
      "select",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_off",
    "version": 11,
    "popularity": 619,
    "codepoint": 58349,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "dynamic",
      "enabled",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "off",
      "on",
      "range",
      "select",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_off_select",
    "version": 248,
    "popularity": 95,
    "codepoint": 61468,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "camera",
      "character",
      "circle",
      "disabled",
      "dynamic",
      "enabled",
      "font",
      "hdr",
      "high",
      "letter",
      "off",
      "on",
      "photo",
      "range",
      "select",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_off_select",
    "version": 10,
    "popularity": 618,
    "codepoint": 61468,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alphabet",
      "camera",
      "character",
      "circle",
      "disabled",
      "dynamic",
      "enabled",
      "font",
      "hdr",
      "high",
      "letter",
      "off",
      "on",
      "photo",
      "range",
      "select",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_on",
    "version": 248,
    "popularity": 228,
    "codepoint": 58350,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "add",
      "alphabet",
      "character",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "on",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_on",
    "version": 11,
    "popularity": 1121,
    "codepoint": 58350,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "add",
      "alphabet",
      "character",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "on",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_on_select",
    "version": 248,
    "popularity": 123,
    "codepoint": 61469,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "alphabet",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "on",
      "photo",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_on_select",
    "version": 10,
    "popularity": 686,
    "codepoint": 61469,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "+",
      "alphabet",
      "camera",
      "character",
      "circle",
      "dynamic",
      "font",
      "hdr",
      "high",
      "letter",
      "on",
      "photo",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_plus",
    "version": 248,
    "popularity": 107,
    "codepoint": 61470,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "add",
      "alphabet",
      "character",
      "circle",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_plus",
    "version": 10,
    "popularity": 818,
    "codepoint": 61470,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "+",
      "add",
      "alphabet",
      "character",
      "circle",
      "dynamic",
      "enhance",
      "font",
      "hdr",
      "high",
      "letter",
      "plus",
      "range",
      "select",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_plus_off",
    "version": 248,
    "popularity": 1,
    "codepoint": 58351,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "disabled",
      "enabled",
      "hdr",
      "image",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "plus",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_strong",
    "version": 248,
    "popularity": 461,
    "codepoint": 58353,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "circles",
      "dots",
      "dynamic",
      "enhance",
      "hdr",
      "high",
      "range",
      "strong"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_strong",
    "version": 12,
    "popularity": 2550,
    "codepoint": 58353,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "circles",
      "dots",
      "dynamic",
      "enhance",
      "hdr",
      "high",
      "range",
      "strong"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hdr_weak",
    "version": 248,
    "popularity": 313,
    "codepoint": 58354,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "circles",
      "dots",
      "dynamic",
      "enhance",
      "hdr",
      "high",
      "range",
      "weak"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hdr_weak",
    "version": 12,
    "popularity": 2050,
    "codepoint": 58354,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "circles",
      "dots",
      "dynamic",
      "enhance",
      "hdr",
      "high",
      "range",
      "weak"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "headphones",
    "version": 248,
    "popularity": 4188,
    "codepoint": 61471,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "headphones",
    "version": 9,
    "popularity": 16545,
    "codepoint": 61471,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "headphones_battery",
    "version": 248,
    "popularity": 243,
    "codepoint": 61472,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "battery",
      "charging",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "headphones_battery",
    "version": 9,
    "popularity": 1443,
    "codepoint": 61472,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "battery",
      "charging",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "headset",
    "version": 12,
    "popularity": 7437,
    "codepoint": 58128,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "music",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "headset_mic",
    "version": 248,
    "popularity": 2893,
    "codepoint": 58129,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "chat",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "mic",
      "music",
      "sound",
      "talk"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "headset_mic",
    "version": 12,
    "popularity": 15510,
    "codepoint": 58129,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "chat",
      "device",
      "ear",
      "earphone",
      "headphones",
      "headset",
      "listen",
      "mic",
      "music",
      "sound",
      "talk"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "headset_off",
    "version": 248,
    "popularity": 258,
    "codepoint": 58170,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "chat",
      "device",
      "disabled",
      "ear",
      "earphone",
      "enabled",
      "headphones",
      "headset",
      "listen",
      "mic",
      "music",
      "off",
      "on",
      "slash",
      "sound",
      "talk"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "headset_off",
    "version": 12,
    "popularity": 1865,
    "codepoint": 58170,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "chat",
      "device",
      "disabled",
      "ear",
      "earphone",
      "enabled",
      "headphones",
      "headset",
      "listen",
      "mic",
      "music",
      "off",
      "on",
      "slash",
      "sound",
      "talk"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "healing",
    "version": 248,
    "popularity": 1053,
    "codepoint": 58355,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "bandage",
      "edit",
      "editing",
      "emergency",
      "fix",
      "healing",
      "hospital",
      "image",
      "medicine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "healing",
    "version": 12,
    "popularity": 7217,
    "codepoint": 58355,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bandage",
      "edit",
      "editing",
      "emergency",
      "fix",
      "healing",
      "hospital",
      "image",
      "medicine"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "health_and_beauty",
    "version": 248,
    "popularity": 129,
    "codepoint": 61341,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "and",
      "beauty",
      "care",
      "comb",
      "hair",
      "health",
      "makeup",
      "nail",
      "polish",
      "self"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "health_and_safety",
    "version": 248,
    "popularity": 5613,
    "codepoint": 57813,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "+",
      "add",
      "and",
      "certified",
      "cross",
      "health",
      "home",
      "nest",
      "plus",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "security",
      "shield",
      "symbol",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "health_and_safety",
    "version": 7,
    "popularity": 33455,
    "codepoint": 57813,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "add",
      "and",
      "certified",
      "cross",
      "health",
      "home",
      "nest",
      "plus",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "security",
      "shield",
      "symbol",
      "verified"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "health_metrics",
    "version": 248,
    "popularity": 120,
    "codepoint": 63202,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "blood oxygen saturation",
      "body metrics",
      "breathing rate",
      "chart",
      "diagram",
      "fitness",
      "graph",
      "health",
      "health dashboard",
      "health metric dashboard",
      "health monitoring",
      "heart rate variability",
      "measure",
      "metric",
      "oxygen",
      "resting heart rate",
      "skin temperature"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heap_snapshot_large",
    "version": 248,
    "popularity": 5,
    "codepoint": 63342,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "file",
      "java",
      "page",
      "paper",
      "percent",
      "percentage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heap_snapshot_multiple",
    "version": 248,
    "popularity": 6,
    "codepoint": 63341,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "copy",
      "create",
      "data",
      "doc",
      "document",
      "duplicate",
      "file",
      "java",
      "page",
      "paper",
      "percent",
      "percentage",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heap_snapshot_thumbnail",
    "version": 248,
    "popularity": 7,
    "codepoint": 63340,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "gallery",
      "grid",
      "java",
      "percent",
      "percentage",
      "photo",
      "photos",
      "preview"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hearing",
    "version": 248,
    "popularity": 1923,
    "codepoint": 57379,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "aid",
      "ear",
      "handicap",
      "hearing",
      "help",
      "impaired",
      "listen",
      "mono",
      "sound",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hearing",
    "version": 12,
    "popularity": 8283,
    "codepoint": 57379,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "aid",
      "ear",
      "handicap",
      "hearing",
      "help",
      "impaired",
      "listen",
      "mono",
      "sound",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hearing_disabled",
    "version": 248,
    "popularity": 448,
    "codepoint": 61700,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "aid",
      "disabled",
      "ear",
      "enabled",
      "handicap",
      "hearing",
      "help",
      "impaired",
      "listen",
      "off",
      "on",
      "slash",
      "sound",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hearing_disabled",
    "version": 14,
    "popularity": 2134,
    "codepoint": 61700,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "aid",
      "disabled",
      "ear",
      "enabled",
      "handicap",
      "hearing",
      "help",
      "impaired",
      "listen",
      "off",
      "on",
      "slash",
      "sound",
      "volume"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "heart_broken",
    "version": 248,
    "popularity": 1533,
    "codepoint": 60098,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "break",
      "broken",
      "core",
      "crush",
      "health",
      "heart",
      "nucleus",
      "split"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heart_broken",
    "version": 2,
    "popularity": 5845,
    "codepoint": 60098,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "break",
      "broken",
      "core",
      "crush",
      "health",
      "heart",
      "nucleus",
      "split"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "heart_check",
    "version": 248,
    "popularity": 42,
    "codepoint": 62986,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "appreciate",
      "approve",
      "checkmark",
      "complete",
      "done",
      "favorite",
      "like",
      "love",
      "ok",
      "remember",
      "save",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heart_minus",
    "version": 248,
    "popularity": 775,
    "codepoint": 63619,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "-",
      "appreciate",
      "delete",
      "favorite",
      "like",
      "love",
      "minus",
      "remember",
      "remove",
      "save",
      "subtract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heart_plus",
    "version": 248,
    "popularity": 2389,
    "codepoint": 63620,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "add",
      "appreciate",
      "favorite",
      "like",
      "love",
      "new",
      "plus",
      "remember",
      "save"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heat",
    "version": 248,
    "popularity": 36,
    "codepoint": 62775,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "air",
      "airwave",
      "bismuth",
      "blowing",
      "breeze",
      "climate",
      "flow",
      "home",
      "hot",
      "nest",
      "steam",
      "temperature",
      "thermostat",
      "wave",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heat_pump",
    "version": 248,
    "popularity": 549,
    "codepoint": 60440,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air conditioner",
      "cool",
      "energy",
      "furnance",
      "heat",
      "nest",
      "pump",
      "usage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "heat_pump",
    "version": 1,
    "popularity": 1735,
    "codepoint": 60440,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "air conditioner",
      "cool",
      "energy",
      "furnance",
      "heat",
      "nest",
      "pump",
      "usage"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "heat_pump_balance",
    "version": 248,
    "popularity": 177,
    "codepoint": 57982,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "heat",
      "home",
      "nest",
      "pump",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "height",
    "version": 248,
    "popularity": 1140,
    "codepoint": 59926,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "color",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "height",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "up",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "height",
    "version": 11,
    "popularity": 7222,
    "codepoint": 59926,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "arrow",
      "color",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "fill",
      "format",
      "height",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "up",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "helicopter",
    "version": 248,
    "popularity": 14,
    "codepoint": 62988,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "aircraft",
      "chopper",
      "plane",
      "rotorcraft"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "help",
    "version": 248,
    "popularity": 30938,
    "codepoint": 59527,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "?",
      "assistance",
      "circle",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "shape",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "help",
    "version": 13,
    "popularity": 109094,
    "codepoint": 59527,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "?",
      "assistance",
      "circle",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "shape",
      "support",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "help_center",
    "version": 248,
    "popularity": 3336,
    "codepoint": 61888,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "?",
      "assistance",
      "center",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "help_center",
    "version": 9,
    "popularity": 25281,
    "codepoint": 61888,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "?",
      "assistance",
      "center",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "support",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "help_clinic",
    "version": 248,
    "popularity": 11,
    "codepoint": 63504,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "details",
      "health",
      "help",
      "home",
      "household",
      "i",
      "info",
      "information",
      "service",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "help_outline",
    "version": 12,
    "popularity": 0,
    "codepoint": 59645,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "?",
      "assistance",
      "circle",
      "help",
      "info",
      "information",
      "outline",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "shape",
      "support",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hematology",
    "version": 248,
    "popularity": 5,
    "codepoint": 57590,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "blood",
      "body",
      "cell",
      "cells",
      "health",
      "human",
      "vein",
      "veins",
      "vessel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hevc",
    "version": 248,
    "popularity": 98,
    "codepoint": 61473,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "character",
      "coding",
      "efficiency",
      "font",
      "hevc",
      "high",
      "letter",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hevc",
    "version": 10,
    "popularity": 694,
    "codepoint": 61473,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alphabet",
      "character",
      "coding",
      "efficiency",
      "font",
      "hevc",
      "high",
      "letter",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hexagon",
    "version": 248,
    "popularity": 710,
    "codepoint": 60217,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "hexagon",
      "shape",
      "six sides"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hexagon",
    "version": 1,
    "popularity": 3029,
    "codepoint": 60217,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "hexagon",
      "shape",
      "six sides"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hide",
    "version": 248,
    "popularity": 1020,
    "codepoint": 61342,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hide_image",
    "version": 248,
    "popularity": 544,
    "codepoint": 61474,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "disabled",
      "enabled",
      "hide",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hide_image",
    "version": 10,
    "popularity": 2830,
    "codepoint": 61474,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "disabled",
      "enabled",
      "hide",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hide_source",
    "version": 248,
    "popularity": 1760,
    "codepoint": 61475,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "circle",
      "disabled",
      "enabled",
      "hide",
      "off",
      "offline",
      "on",
      "shape",
      "slash",
      "source"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hide_source",
    "version": 9,
    "popularity": 0,
    "codepoint": 61475,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "disabled",
      "enabled",
      "hide",
      "off",
      "offline",
      "on",
      "shape",
      "slash",
      "source"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "high_density",
    "version": 248,
    "popularity": 5,
    "codepoint": 63388,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "dash",
      "dashed",
      "dense",
      "output",
      "quality",
      "screen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "high_quality",
    "version": 248,
    "popularity": 541,
    "codepoint": 57380,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "definition",
      "display",
      "font",
      "high",
      "hq",
      "letter",
      "movie",
      "movies",
      "quality",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "high_quality",
    "version": 12,
    "popularity": 4381,
    "codepoint": 57380,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alphabet",
      "character",
      "definition",
      "display",
      "font",
      "high",
      "hq",
      "letter",
      "movie",
      "movies",
      "quality",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "high_res",
    "version": 248,
    "popularity": 5,
    "codepoint": 62795,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "camera",
      "character",
      "font",
      "hd",
      "high",
      "letter",
      "movie",
      "resolution",
      "screen",
      "symbol",
      "text",
      "tv",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlight",
    "version": 248,
    "popularity": 764,
    "codepoint": 57951,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "emphasize",
      "fill",
      "flash",
      "format",
      "highlight",
      "light",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlight",
    "version": 14,
    "popularity": 0,
    "codepoint": 57951,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "emphasize",
      "fill",
      "flash",
      "format",
      "highlight",
      "light",
      "paint",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "highlight_alt",
    "version": 12,
    "popularity": 8528,
    "codepoint": 61266,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "arrow",
      "box",
      "click",
      "cursor",
      "draw",
      "focus",
      "highlight",
      "pointer",
      "select",
      "selection",
      "target"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "highlight_keyboard_focus",
    "version": 248,
    "popularity": 0,
    "codepoint": 62736,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "I",
      "accessibility",
      "chromeos",
      "dash",
      "letter",
      "select",
      "selected"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlight_mouse_cursor",
    "version": 248,
    "popularity": 8,
    "codepoint": 62737,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "browser",
      "chromeos",
      "circle",
      "click",
      "clicks",
      "cursor",
      "internet",
      "left",
      "mouse",
      "move",
      "select",
      "selection",
      "selects",
      "target",
      "traffic",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlight_off",
    "version": 17,
    "popularity": 115492,
    "codepoint": 59528,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "exit",
      "highlight",
      "no",
      "off",
      "quit",
      "remove",
      "stop",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "highlight_text_cursor",
    "version": 248,
    "popularity": 1,
    "codepoint": 62738,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "chromeos",
      "cursor",
      "format",
      "selection",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlighter_size_1",
    "version": 248,
    "popularity": 0,
    "codepoint": 63339,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlighter_size_2",
    "version": 248,
    "popularity": 0,
    "codepoint": 63338,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlighter_size_3",
    "version": 248,
    "popularity": 9,
    "codepoint": 63337,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlighter_size_4",
    "version": 248,
    "popularity": 1,
    "codepoint": 63336,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "highlighter_size_5",
    "version": 248,
    "popularity": 3,
    "codepoint": 63335,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "highlight",
      "line weight",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hiking",
    "version": 248,
    "popularity": 2127,
    "codepoint": 58634,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "backpacking",
      "bag",
      "climbing",
      "duffle",
      "hiking",
      "mountain",
      "social",
      "sports",
      "stick",
      "trail",
      "travel",
      "walking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hiking",
    "version": 4,
    "popularity": 0,
    "codepoint": 58634,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "backpacking",
      "bag",
      "climbing",
      "duffle",
      "hiking",
      "mountain",
      "social",
      "sports",
      "stick",
      "trail",
      "travel",
      "walking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "history",
    "version": 248,
    "popularity": 14349,
    "codepoint": 59529,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "history",
      "refresh",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "time",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "history",
    "version": 12,
    "popularity": 86891,
    "codepoint": 59529,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "history",
      "refresh",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "time",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "history_edu",
    "version": 248,
    "popularity": 4579,
    "codepoint": 59966,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "document",
      "edu",
      "education",
      "feather",
      "history",
      "letter",
      "paper",
      "pen",
      "quill",
      "school",
      "story",
      "tools",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "history_edu",
    "version": 11,
    "popularity": 23206,
    "codepoint": 59966,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "document",
      "edu",
      "education",
      "feather",
      "history",
      "letter",
      "paper",
      "pen",
      "quill",
      "school",
      "story",
      "tools",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "history_off",
    "version": 248,
    "popularity": 0,
    "codepoint": 62682,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "disabled",
      "enabled",
      "history",
      "off",
      "offline",
      "on",
      "refresh",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "slash",
      "time",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "history_toggle_off",
    "version": 248,
    "popularity": 1634,
    "codepoint": 61821,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "clock",
      "dash",
      "dashed",
      "date",
      "history",
      "off",
      "schedule",
      "time",
      "toggle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "history_toggle_off",
    "version": 9,
    "popularity": 10174,
    "codepoint": 61821,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "clock",
      "dash",
      "dashed",
      "date",
      "history",
      "off",
      "schedule",
      "time",
      "toggle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hive",
    "version": 248,
    "popularity": 1548,
    "codepoint": 60070,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bee",
      "honey",
      "honeycomb"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hive",
    "version": 2,
    "popularity": 4268,
    "codepoint": 60070,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bee",
      "honey",
      "honeycomb"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hls",
    "version": 248,
    "popularity": 405,
    "codepoint": 60298,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "character",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "hls",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hls",
    "version": 1,
    "popularity": 698,
    "codepoint": 60298,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "character",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "hls",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hls_off",
    "version": 248,
    "popularity": 402,
    "codepoint": 60300,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "character",
      "develop",
      "developer",
      "disabled",
      "enabled",
      "engineer",
      "engineering",
      "font",
      "hls",
      "letter",
      "off",
      "offline",
      "on",
      "platform",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hls_off",
    "version": 1,
    "popularity": 700,
    "codepoint": 60300,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "character",
      "develop",
      "developer",
      "disabled",
      "enabled",
      "engineer",
      "engineering",
      "font",
      "hls",
      "letter",
      "off",
      "offline",
      "on",
      "platform",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "holiday_village",
    "version": 248,
    "popularity": 1094,
    "codepoint": 58762,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "beach",
      "camping",
      "cottage",
      "estate",
      "holiday",
      "home",
      "house",
      "lake",
      "lodge",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
      "vacation",
      "village"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "holiday_village",
    "version": 4,
    "popularity": 8290,
    "codepoint": 58762,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "beach",
      "camping",
      "cottage",
      "estate",
      "holiday",
      "home",
      "house",
      "lake",
      "lodge",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling",
      "vacation",
      "village"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "home",
    "version": 248,
    "popularity": 163693,
    "codepoint": 59530,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "address",
      "app",
      "application--house",
      "architecture",
      "building",
      "components",
      "design",
      "estate",
      "home",
      "interface",
      "layout",
      "place",
      "real",
      "residence",
      "residential",
      "screen",
      "shelter",
      "site",
      "structure",
      "ui",
      "unit",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home",
    "version": 16,
    "popularity": 724251,
    "codepoint": 59530,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "address",
      "app",
      "application--house",
      "architecture",
      "building",
      "components",
      "design",
      "estate",
      "home",
      "interface",
      "layout",
      "place",
      "real",
      "residence",
      "residential",
      "screen",
      "shelter",
      "site",
      "structure",
      "ui",
      "unit",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "home_and_garden",
    "version": 248,
    "popularity": 21,
    "codepoint": 61343,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "and",
      "architecture",
      "building",
      "estate",
      "flower",
      "garden",
      "home",
      "house",
      "place",
      "real",
      "residence",
      "residential",
      "shelter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_app_logo",
    "version": 248,
    "popularity": 1857,
    "codepoint": 58005,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "home",
      "house",
      "logo",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_filled",
    "version": 17,
    "popularity": 4810,
    "codepoint": 59826,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "address",
      "app",
      "application--house",
      "architecture",
      "building",
      "components",
      "design",
      "estate",
      "filled",
      "home",
      "interface",
      "layout",
      "place",
      "real",
      "residence",
      "residential",
      "screen",
      "shelter",
      "site",
      "structure",
      "ui",
      "unit",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "home_health",
    "version": 248,
    "popularity": 29,
    "codepoint": 58553,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "care",
      "clinic",
      "health",
      "home",
      "hospital",
      "household",
      "medical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_improvement_and_tools",
    "version": 248,
    "popularity": 13,
    "codepoint": 61344,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "and",
      "home",
      "improvement",
      "nail",
      "screw",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_iot_device",
    "version": 248,
    "popularity": 454,
    "codepoint": 57987,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_max",
    "version": 248,
    "popularity": 190,
    "codepoint": 61476,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "device",
      "gadget",
      "hardware",
      "home",
      "internet",
      "iot",
      "max",
      "nest",
      "smart",
      "things"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_max",
    "version": 10,
    "popularity": 1512,
    "codepoint": 61476,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "device",
      "gadget",
      "hardware",
      "home",
      "internet",
      "iot",
      "max",
      "nest",
      "smart",
      "things"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "home_max_dots",
    "version": 248,
    "popularity": 209,
    "codepoint": 59465,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "IoT",
      "assistant",
      "device",
      "dots",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_mini",
    "version": 248,
    "popularity": 165,
    "codepoint": 61477,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Internet",
      "device",
      "gadget",
      "hardware",
      "home",
      "iot",
      "mini",
      "nest",
      "smart",
      "things"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_mini",
    "version": 10,
    "popularity": 1210,
    "codepoint": 61477,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Internet",
      "device",
      "gadget",
      "hardware",
      "home",
      "iot",
      "mini",
      "nest",
      "smart",
      "things"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "home_pin",
    "version": 248,
    "popularity": 10556,
    "codepoint": 61773,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "home",
      "house",
      "location",
      "maps",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_repair_service",
    "version": 248,
    "popularity": 2365,
    "codepoint": 61696,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "box",
      "equipment",
      "fix",
      "home",
      "kit",
      "mechanic",
      "repair",
      "repairing",
      "service",
      "tool",
      "toolbox",
      "tools",
      "workshop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_repair_service",
    "version": 12,
    "popularity": 14052,
    "codepoint": 61696,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "box",
      "equipment",
      "fix",
      "home",
      "kit",
      "mechanic",
      "repair",
      "repairing",
      "service",
      "tool",
      "toolbox",
      "tools",
      "workshop"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "home_speaker",
    "version": 248,
    "popularity": 227,
    "codepoint": 61724,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "airplay",
      "cast",
      "connect",
      "device",
      "google",
      "hardware",
      "home",
      "nest",
      "screencast",
      "speaker",
      "streaming",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_storage",
    "version": 248,
    "popularity": 326,
    "codepoint": 63596,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "file",
      "files",
      "home",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_work",
    "version": 248,
    "popularity": 3311,
    "codepoint": 59913,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "architecture",
      "building",
      "estate",
      "home",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "home_work",
    "version": 13,
    "popularity": 16185,
    "codepoint": 59913,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "architecture",
      "building",
      "estate",
      "home",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "work"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "horizontal_distribute",
    "version": 248,
    "popularity": 268,
    "codepoint": 57364,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "horizontal_distribute",
    "version": 6,
    "popularity": 2231,
    "codepoint": 57364,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alignment",
      "distribute",
      "format",
      "horizontal",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "horizontal_rule",
    "version": 248,
    "popularity": 1526,
    "codepoint": 61704,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "gmail",
      "horizontal",
      "line",
      "novitas",
      "rule"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "horizontal_rule",
    "version": 13,
    "popularity": 10011,
    "codepoint": 61704,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "gmail",
      "horizontal",
      "line",
      "novitas",
      "rule"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "horizontal_split",
    "version": 248,
    "popularity": 344,
    "codepoint": 59719,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bars",
      "format",
      "horizontal",
      "layout",
      "lines",
      "split",
      "stacked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "horizontal_split",
    "version": 15,
    "popularity": 4648,
    "codepoint": 59719,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bars",
      "format",
      "horizontal",
      "layout",
      "lines",
      "split",
      "stacked"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hot_tub",
    "version": 248,
    "popularity": 519,
    "codepoint": 60230,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bath",
      "bathing",
      "bathroom",
      "bathtub",
      "hot",
      "hotel",
      "human",
      "jacuzzi",
      "person",
      "shower",
      "spa",
      "steam",
      "travel",
      "tub",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hot_tub",
    "version": 12,
    "popularity": 3231,
    "codepoint": 60230,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bath",
      "bathing",
      "bathroom",
      "bathtub",
      "hot",
      "hotel",
      "human",
      "jacuzzi",
      "person",
      "shower",
      "spa",
      "steam",
      "travel",
      "tub",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hotel",
    "version": 248,
    "popularity": 2524,
    "codepoint": 58682,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "body",
      "hotel",
      "human",
      "people",
      "person",
      "sleep",
      "stay",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hotel",
    "version": 19,
    "popularity": 13465,
    "codepoint": 58682,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "body",
      "hotel",
      "human",
      "people",
      "person",
      "sleep",
      "stay",
      "travel",
      "trip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hotel_class",
    "version": 248,
    "popularity": 2386,
    "codepoint": 59203,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "class",
      "favorite",
      "highlight",
      "hotel",
      "important",
      "marked",
      "rank",
      "ranking",
      "rate",
      "rating",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hotel_class",
    "version": 6,
    "popularity": 8629,
    "codepoint": 59203,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "class",
      "favorite",
      "highlight",
      "hotel",
      "important",
      "marked",
      "rank",
      "ranking",
      "rate",
      "rating",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hourglass",
    "version": 248,
    "popularity": 36,
    "codepoint": 60415,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "countdown",
      "empty",
      "full",
      "hourglass",
      "loading",
      "minutes",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hourglass_bottom",
    "version": 248,
    "popularity": 2508,
    "codepoint": 59996,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bottom",
      "countdown",
      "half",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hourglass_bottom",
    "version": 11,
    "popularity": 16754,
    "codepoint": 59996,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bottom",
      "countdown",
      "half",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hourglass_disabled",
    "version": 248,
    "popularity": 557,
    "codepoint": 61267,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "clock",
      "countdown",
      "disabled",
      "empty",
      "enabled",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "off",
      "on",
      "slash",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hourglass_disabled",
    "version": 12,
    "popularity": 3634,
    "codepoint": 61267,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "clock",
      "countdown",
      "disabled",
      "empty",
      "enabled",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "off",
      "on",
      "slash",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "hourglass_empty",
    "version": 248,
    "popularity": 3857,
    "codepoint": 59531,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "countdown",
      "empty",
      "full",
      "hourglass",
      "loading",
      "minutes",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hourglass_empty",
    "version": 15,
    "popularity": 25282,
    "codepoint": 59531,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "countdown",
      "empty",
      "full",
      "hourglass",
      "loading",
      "minutes",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hourglass_full",
    "version": 15,
    "popularity": 8129,
    "codepoint": 59532,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "countdown",
      "empty",
      "full",
      "hourglass",
      "loading",
      "minutes",
      "time",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hourglass_top",
    "version": 248,
    "popularity": 2762,
    "codepoint": 59995,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "countdown",
      "half",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "time",
      "top",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hourglass_top",
    "version": 11,
    "popularity": 16209,
    "codepoint": 59995,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "countdown",
      "half",
      "hourglass",
      "loading",
      "minute",
      "minutes",
      "time",
      "top",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "house",
    "version": 248,
    "popularity": 8743,
    "codepoint": 59972,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "building",
      "estate",
      "family",
      "home",
      "homepage",
      "house",
      "place",
      "places",
      "real",
      "residence",
      "residential",
      "shelter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "house",
    "version": 11,
    "popularity": 19701,
    "codepoint": 59972,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "building",
      "estate",
      "family",
      "home",
      "homepage",
      "house",
      "place",
      "places",
      "real",
      "residence",
      "residential",
      "shelter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "house_siding",
    "version": 248,
    "popularity": 389,
    "codepoint": 61954,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "building",
      "construction",
      "estate",
      "exterior",
      "facade",
      "home",
      "house",
      "real",
      "residential",
      "siding"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "house_siding",
    "version": 6,
    "popularity": 3282,
    "codepoint": 61954,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "building",
      "construction",
      "estate",
      "exterior",
      "facade",
      "home",
      "house",
      "real",
      "residential",
      "siding"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "house_with_shield",
    "version": 248,
    "popularity": 202,
    "codepoint": 59270,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "house",
      "nest",
      "security",
      "shield"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "houseboat",
    "version": 248,
    "popularity": 410,
    "codepoint": 58756,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "beach",
      "boat",
      "estate",
      "floating",
      "home",
      "house",
      "houseboat",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "sea",
      "stay",
      "traveling",
      "vacation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "houseboat",
    "version": 4,
    "popularity": 2251,
    "codepoint": 58756,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "beach",
      "boat",
      "estate",
      "floating",
      "home",
      "house",
      "houseboat",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "sea",
      "stay",
      "traveling",
      "vacation"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "household_supplies",
    "version": 248,
    "popularity": 24,
    "codepoint": 61345,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bottle",
      "clean",
      "cleaner",
      "cleaning",
      "disinfecting",
      "household",
      "spray",
      "supplies"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "how_to_reg",
    "version": 248,
    "popularity": 4991,
    "codepoint": 57716,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "ballot",
      "check",
      "complete",
      "done",
      "election",
      "how",
      "mark",
      "ok",
      "poll",
      "register",
      "registration",
      "select",
      "tick",
      "to reg",
      "validate",
      "verified",
      "vote",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "how_to_reg",
    "version": 14,
    "popularity": 31631,
    "codepoint": 57716,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "approve",
      "ballot",
      "check",
      "complete",
      "done",
      "election",
      "how",
      "mark",
      "ok",
      "poll",
      "register",
      "registration",
      "select",
      "tick",
      "to reg",
      "validate",
      "verified",
      "vote",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "how_to_vote",
    "version": 248,
    "popularity": 987,
    "codepoint": 57717,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "ballot",
      "election",
      "how",
      "poll",
      "to",
      "vote"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "how_to_vote",
    "version": 15,
    "popularity": 5915,
    "codepoint": 57717,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "ballot",
      "election",
      "how",
      "poll",
      "to",
      "vote"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hr_resting",
    "version": 248,
    "popularity": 11,
    "codepoint": 63162,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "heart",
      "rate",
      "rest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "html",
    "version": 248,
    "popularity": 3011,
    "codepoint": 60286,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "html",
    "version": 1,
    "popularity": 4172,
    "codepoint": 60286,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "http",
    "version": 248,
    "popularity": 761,
    "codepoint": 59650,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "http",
      "letter",
      "symbol",
      "text",
      "transfer",
      "type",
      "url",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "http",
    "version": 11,
    "popularity": 5800,
    "codepoint": 59650,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "http",
      "letter",
      "symbol",
      "text",
      "transfer",
      "type",
      "url",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "https",
    "version": 16,
    "popularity": 18205,
    "codepoint": 59533,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "https",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "hub",
    "version": 248,
    "popularity": 6097,
    "codepoint": 59892,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "center",
      "connection",
      "core",
      "focal point",
      "hub",
      "network",
      "nucleus",
      "topology"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hub",
    "version": 2,
    "popularity": 12687,
    "codepoint": 59892,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "center",
      "connection",
      "core",
      "focal point",
      "hub",
      "network",
      "nucleus",
      "topology"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "humerus",
    "version": 248,
    "popularity": 183,
    "codepoint": 63637,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arm",
      "body",
      "bone",
      "bones",
      "forelimb",
      "humerus",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "humerus_alt",
    "version": 248,
    "popularity": 148,
    "codepoint": 63638,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arm",
      "body",
      "bone",
      "bones",
      "forelimb",
      "humerus",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "humidity_high",
    "version": 248,
    "popularity": 765,
    "codepoint": 61795,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "atmosphere",
      "droplet",
      "high",
      "moisture",
      "vapor",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "humidity_indoor",
    "version": 248,
    "popularity": 2,
    "codepoint": 62808,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "atmosphere",
      "bismuth",
      "climate",
      "droplet",
      "home",
      "house",
      "moisture",
      "vapor",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "humidity_low",
    "version": 248,
    "popularity": 738,
    "codepoint": 61796,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "atmosphere",
      "droplet",
      "fitbit",
      "low",
      "menstruation",
      "moisture",
      "period",
      "vapor",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "humidity_mid",
    "version": 248,
    "popularity": 670,
    "codepoint": 61797,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "atmosphere",
      "droplet",
      "mid",
      "moisture",
      "vapor",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "humidity_percentage",
    "version": 248,
    "popularity": 7,
    "codepoint": 63614,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "atmosphere",
      "droplet",
      "moisture",
      "percent",
      "vapor",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hvac",
    "version": 248,
    "popularity": 329,
    "codepoint": 61710,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air",
      "conditioning",
      "heating",
      "hvac",
      "ventilation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "hvac",
    "version": 12,
    "popularity": 2635,
    "codepoint": 61710,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "air",
      "conditioning",
      "heating",
      "hvac",
      "ventilation"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ice_skating",
    "version": 248,
    "popularity": 317,
    "codepoint": 58635,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "ice",
      "shoe",
      "skates",
      "skating",
      "social",
      "sports",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ice_skating",
    "version": 4,
    "popularity": 2015,
    "codepoint": 58635,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "ice",
      "shoe",
      "skates",
      "skating",
      "social",
      "sports",
      "travel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "icecream",
    "version": 248,
    "popularity": 1076,
    "codepoint": 60009,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "cream",
      "dessert",
      "food",
      "ice",
      "icecream",
      "snack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "icecream",
    "version": 11,
    "popularity": 5231,
    "codepoint": 60009,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "cream",
      "dessert",
      "food",
      "ice",
      "icecream",
      "snack"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ifl",
    "version": 248,
    "popularity": 158,
    "codepoint": 57381,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "dice",
      "die",
      "feeling",
      "i'm",
      "ifl",
      "lucky",
      "search"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "iframe",
    "version": 248,
    "popularity": 17,
    "codepoint": 63259,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "display",
      "frame",
      "html",
      "inline",
      "screen",
      "tag",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "iframe_off",
    "version": 248,
    "popularity": 8,
    "codepoint": 63260,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "disabled",
      "display",
      "enabled",
      "frame",
      "html",
      "inline",
      "off",
      "offline",
      "on",
      "screen",
      "slash",
      "tag",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "image",
    "version": 248,
    "popularity": 16270,
    "codepoint": 58356,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "disabled",
      "enabled",
      "hide",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "image",
    "version": 16,
    "popularity": 94473,
    "codepoint": 58356,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "disabled",
      "enabled",
      "hide",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "image_aspect_ratio",
    "version": 248,
    "popularity": 178,
    "codepoint": 58357,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "aspect",
      "image",
      "photo",
      "photography",
      "picture",
      "ratio",
      "rectangle",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "image_aspect_ratio",
    "version": 12,
    "popularity": 1070,
    "codepoint": 58357,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "aspect",
      "image",
      "photo",
      "photography",
      "picture",
      "ratio",
      "rectangle",
      "square"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "image_not_supported",
    "version": 13,
    "popularity": 4815,
    "codepoint": 61718,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "disabled",
      "enabled",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "not",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
      "supported"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "image_search",
    "version": 248,
    "popularity": 1372,
    "codepoint": 58431,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "find",
      "glass",
      "image",
      "landscape",
      "look",
      "magnify",
      "magnifying",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "image_search",
    "version": 12,
    "popularity": 7303,
    "codepoint": 58431,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "find",
      "glass",
      "image",
      "landscape",
      "look",
      "magnify",
      "magnifying",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "search",
      "see"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "imagesearch_roller",
    "version": 248,
    "popularity": 672,
    "codepoint": 59828,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "art",
      "image",
      "imagesearch",
      "paint",
      "roller",
      "search"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "imagesearch_roller",
    "version": 11,
    "popularity": 2160,
    "codepoint": 59828,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "art",
      "image",
      "imagesearch",
      "paint",
      "roller",
      "search"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "imagesmode",
    "version": 248,
    "popularity": 2998,
    "codepoint": 61346,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "image",
      "mode",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "immunology",
    "version": 248,
    "popularity": 4,
    "codepoint": 57595,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "disease",
      "health",
      "human",
      "immune",
      "infection",
      "infectious",
      "virus",
      "viruses"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "import_contacts",
    "version": 248,
    "popularity": 4859,
    "codepoint": 57568,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "address",
      "book",
      "contacts",
      "import",
      "info",
      "information",
      "open"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "import_contacts",
    "version": 14,
    "popularity": 18971,
    "codepoint": 57568,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "address",
      "book",
      "contacts",
      "import",
      "info",
      "information",
      "open"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "import_export",
    "version": 12,
    "popularity": 17686,
    "codepoint": 57539,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "explort",
      "import",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "important_devices",
    "version": 248,
    "popularity": 938,
    "codepoint": 59666,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "desktop",
      "devices",
      "hardware",
      "iOS",
      "important",
      "mobile",
      "monitor",
      "phone",
      "star",
      "tablet",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "important_devices",
    "version": 11,
    "popularity": 10900,
    "codepoint": 59666,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "desktop",
      "devices",
      "hardware",
      "iOS",
      "important",
      "mobile",
      "monitor",
      "phone",
      "star",
      "tablet",
      "web"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "in_home_mode",
    "version": 248,
    "popularity": 523,
    "codepoint": 59443,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "check",
      "home",
      "house mode",
      "mark",
      "nest",
      "ok",
      "tick",
      "validate",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "inactive_order",
    "version": 248,
    "popularity": 15,
    "codepoint": 57596,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "doc",
      "document",
      "done",
      "file",
      "health",
      "inactive",
      "mark",
      "medication",
      "ok",
      "order",
      "orders",
      "page",
      "paper",
      "pause",
      "receipt",
      "receipts",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "inbox",
    "version": 248,
    "popularity": 2707,
    "codepoint": 57686,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "archive",
      "email",
      "inbox",
      "incoming",
      "mail",
      "message"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "inbox",
    "version": 18,
    "popularity": 14863,
    "codepoint": 57686,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "archive",
      "email",
      "inbox",
      "incoming",
      "mail",
      "message"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "inbox_customize",
    "version": 248,
    "popularity": 184,
    "codepoint": 63577,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "application",
      "archive",
      "change",
      "custom",
      "details",
      "email",
      "inbox",
      "incoming",
      "info",
      "information",
      "mail",
      "message",
      "options",
      "personal",
      "service",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "incomplete_circle",
    "version": 248,
    "popularity": 921,
    "codepoint": 59291,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "chart",
      "circle",
      "incomplete"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "incomplete_circle",
    "version": 3,
    "popularity": 3744,
    "codepoint": 59291,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "chart",
      "circle",
      "incomplete"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "indeterminate_check_box",
    "version": 248,
    "popularity": 2809,
    "codepoint": 59657,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "box",
      "button",
      "check",
      "components",
      "control",
      "design",
      "form",
      "indeterminate",
      "interface",
      "screen",
      "select",
      "selected",
      "selection",
      "site",
      "square",
      "toggle",
      "ui",
      "undetermined",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "indeterminate_check_box",
    "version": 18,
    "popularity": 15203,
    "codepoint": 59657,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "app",
      "application",
      "box",
      "button",
      "check",
      "components",
      "control",
      "design",
      "form",
      "indeterminate",
      "interface",
      "screen",
      "select",
      "selected",
      "selection",
      "site",
      "square",
      "toggle",
      "ui",
      "undetermined",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "indeterminate_question_box",
    "version": 248,
    "popularity": 20,
    "codepoint": 62829,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "?",
      "assistance",
      "frame",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "info",
    "version": 248,
    "popularity": 54538,
    "codepoint": 59534,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "service",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "info",
    "version": 21,
    "popularity": 435081,
    "codepoint": 59534,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "service",
      "support"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "info_i",
    "version": 248,
    "popularity": 21,
    "codepoint": 62875,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "service",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "info_outline",
    "version": 16,
    "popularity": 13633,
    "codepoint": 59535,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "details",
      "help",
      "i",
      "info",
      "information",
      "outline",
      "service",
      "support"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "infrared",
    "version": 248,
    "popularity": 9,
    "codepoint": 63612,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "electromagnetic",
      "radiation",
      "wavelength"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ink_eraser",
    "version": 248,
    "popularity": 199,
    "codepoint": 59088,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "ink",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ink_eraser_off",
    "version": 248,
    "popularity": 5,
    "codepoint": 59363,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "ink",
      "off",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ink_highlighter",
    "version": 248,
    "popularity": 151,
    "codepoint": 59089,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "highlighter",
      "ink",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ink_highlighter_move",
    "version": 248,
    "popularity": 15,
    "codepoint": 62756,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "dash",
      "doc",
      "drag",
      "edit",
      "editing",
      "editor",
      "eraser",
      "fill",
      "highlighter",
      "highlighting",
      "ink",
      "lines",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ink_marker",
    "version": 248,
    "popularity": 43,
    "codepoint": 59090,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "ink",
      "maker",
      "paint",
      "pen",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ink_pen",
    "version": 248,
    "popularity": 205,
    "codepoint": 59091,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "ink",
      "paint",
      "pen",
      "pencil",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "inpatient",
    "version": 248,
    "popularity": 3,
    "codepoint": 57598,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "body",
      "clinic",
      "health",
      "hospital",
      "human",
      "in",
      "left",
      "medical",
      "patient",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "input",
    "version": 248,
    "popularity": 2167,
    "codepoint": 59536,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "box",
      "download",
      "input",
      "login",
      "move",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "input",
    "version": 13,
    "popularity": 17714,
    "codepoint": 59536,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "box",
      "download",
      "input",
      "login",
      "move",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "input_circle",
    "version": 248,
    "popularity": 37,
    "codepoint": 63258,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "inside",
      "install",
      "into",
      "navigation",
      "north",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "insert_chart",
    "version": 248,
    "popularity": 3395,
    "codepoint": 57931,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "insert",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "insert_chart",
    "version": 12,
    "popularity": 8407,
    "codepoint": 57931,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "insert",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_chart_outlined",
    "version": 12,
    "popularity": 12667,
    "codepoint": 57962,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "insert",
      "measure",
      "metrics",
      "outlined",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_comment",
    "version": 13,
    "popularity": 7698,
    "codepoint": 57932,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "add",
      "bubble",
      "chat",
      "comment",
      "feedback",
      "insert",
      "message"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_drive_file",
    "version": 17,
    "popularity": 25329,
    "codepoint": 57933,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "doc",
      "drive",
      "file",
      "format",
      "insert",
      "sheet",
      "slide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_emoticon",
    "version": 15,
    "popularity": 13578,
    "codepoint": 57934,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "account",
      "emoji",
      "emoticon",
      "face",
      "happy",
      "human",
      "insert",
      "people",
      "person",
      "profile",
      "sentiment",
      "smile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_invitation",
    "version": 12,
    "popularity": 10199,
    "codepoint": 57935,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "insert",
      "invitation",
      "mark",
      "month",
      "range",
      "remember",
      "reminder",
      "today",
      "week"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_link",
    "version": 12,
    "popularity": 10521,
    "codepoint": 57936,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "add",
      "attach",
      "clip",
      "file",
      "insert",
      "link",
      "mail",
      "media"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_page_break",
    "version": 248,
    "popularity": 279,
    "codepoint": 60106,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "break",
      "dash",
      "dashed",
      "doc",
      "document",
      "file",
      "page",
      "paper"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "insert_page_break",
    "version": 2,
    "popularity": 1186,
    "codepoint": 60106,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "break",
      "dash",
      "dashed",
      "doc",
      "document",
      "file",
      "page",
      "paper"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "insert_photo",
    "version": 12,
    "popularity": 13200,
    "codepoint": 57937,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "image",
      "insert",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "insert_text",
    "version": 248,
    "popularity": 25,
    "codepoint": 63527,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "format",
      "letter",
      "sheet",
      "spreadsheet",
      "style",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "insights",
    "version": 12,
    "popularity": 53415,
    "codepoint": 61586,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "ai",
      "analytics",
      "artificial",
      "automatic",
      "automation",
      "bar",
      "bars",
      "chart",
      "custom",
      "data",
      "diagram",
      "genai",
      "graph",
      "infographic",
      "insights",
      "intelligence",
      "magic",
      "measure",
      "metrics",
      "smart",
      "spark",
      "sparkle",
      "star",
      "stars",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "install_desktop",
    "version": 248,
    "popularity": 1280,
    "codepoint": 60273,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "fix",
      "hardware",
      "iOS",
      "install",
      "mac",
      "monitor",
      "place",
      "pwa",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "install_desktop",
    "version": 1,
    "popularity": 2801,
    "codepoint": 60273,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "fix",
      "hardware",
      "iOS",
      "install",
      "mac",
      "monitor",
      "place",
      "pwa",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "install_mobile",
    "version": 248,
    "popularity": 1445,
    "codepoint": 60274,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "install",
      "mobile",
      "phone",
      "pwa",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "install_mobile",
    "version": 1,
    "popularity": 2209,
    "codepoint": 60274,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "install",
      "mobile",
      "phone",
      "pwa",
      "tablet"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "instant_mix",
    "version": 248,
    "popularity": 130,
    "codepoint": 57382,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "adjust",
      "audio",
      "controls",
      "custom",
      "customize",
      "edit",
      "editing",
      "filter",
      "filters",
      "instant",
      "mix",
      "music",
      "options",
      "setting",
      "settings",
      "slider",
      "sliders",
      "switches",
      "tune"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "integration_instructions",
    "version": 248,
    "popularity": 2350,
    "codepoint": 61268,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "brackets",
      "clipboard",
      "code",
      "css",
      "develop",
      "developer",
      "doc",
      "document",
      "engineer",
      "engineering clipboard",
      "html",
      "instructions",
      "integration",
      "platform"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "integration_instructions",
    "version": 11,
    "popularity": 14327,
    "codepoint": 61268,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "brackets",
      "clipboard",
      "code",
      "css",
      "develop",
      "developer",
      "doc",
      "document",
      "engineer",
      "engineering clipboard",
      "html",
      "instructions",
      "integration",
      "platform"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "interactive_space",
    "version": 248,
    "popularity": 24,
    "codepoint": 63487,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "art",
      "body",
      "human",
      "installation",
      "interact",
      "movie",
      "people",
      "person",
      "screen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "interests",
    "version": 248,
    "popularity": 3024,
    "codepoint": 59336,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "circle",
      "heart",
      "interests",
      "shapes",
      "social",
      "square",
      "triangle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "interests",
    "version": 2,
    "popularity": 8031,
    "codepoint": 59336,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "circle",
      "heart",
      "interests",
      "shapes",
      "social",
      "square",
      "triangle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "interpreter_mode",
    "version": 248,
    "popularity": 874,
    "codepoint": 59451,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "interpreter",
      "language",
      "microphone",
      "mode",
      "person",
      "speaking",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "interpreter_mode",
    "version": 2,
    "popularity": 2204,
    "codepoint": 59451,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "interpreter",
      "language",
      "microphone",
      "mode",
      "person",
      "speaking",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "inventory",
    "version": 248,
    "popularity": 8873,
    "codepoint": 57721,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "archive",
      "box",
      "clipboard",
      "doc",
      "document",
      "file",
      "inventory",
      "organize",
      "packages",
      "product",
      "stock",
      "supply"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "inventory",
    "version": 13,
    "popularity": 55598,
    "codepoint": 57721,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "archive",
      "box",
      "clipboard",
      "doc",
      "document",
      "file",
      "inventory",
      "organize",
      "packages",
      "product",
      "stock",
      "supply"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "inventory_2",
    "version": 248,
    "popularity": 10743,
    "codepoint": 57761,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "archive",
      "box",
      "file",
      "inventory",
      "organize",
      "packages",
      "product",
      "stock",
      "storage",
      "supply"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "inventory_2",
    "version": 9,
    "popularity": 59969,
    "codepoint": 57761,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "archive",
      "box",
      "file",
      "inventory",
      "organize",
      "packages",
      "product",
      "stock",
      "storage",
      "supply"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "invert_colors",
    "version": 248,
    "popularity": 978,
    "codepoint": 59537,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "colors",
      "drop",
      "droplet",
      "edit",
      "editing",
      "hue",
      "invert",
      "inverted",
      "palette",
      "tone",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "invert_colors",
    "version": 13,
    "popularity": 10388,
    "codepoint": 59537,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "colors",
      "drop",
      "droplet",
      "edit",
      "editing",
      "hue",
      "invert",
      "inverted",
      "palette",
      "tone",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "invert_colors_off",
    "version": 248,
    "popularity": 233,
    "codepoint": 57540,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "colors",
      "disabled",
      "drop",
      "droplet",
      "enabled",
      "hue",
      "invert",
      "inverted",
      "off",
      "offline",
      "on",
      "opacity",
      "palette",
      "slash",
      "tone",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "invert_colors_off",
    "version": 12,
    "popularity": 1803,
    "codepoint": 57540,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "colors",
      "disabled",
      "drop",
      "droplet",
      "enabled",
      "hue",
      "invert",
      "inverted",
      "off",
      "offline",
      "on",
      "opacity",
      "palette",
      "slash",
      "tone",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ios",
    "version": 248,
    "popularity": 173,
    "codepoint": 57383,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "apple",
      "hardware",
      "ios",
      "iphone",
      "ipod",
      "mac",
      "operating",
      "system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ios_share",
    "version": 248,
    "popularity": 6278,
    "codepoint": 59064,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "export",
      "ios",
      "send",
      "share",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ios_share",
    "version": 13,
    "popularity": 27610,
    "codepoint": 59064,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arrow",
      "export",
      "ios",
      "send",
      "share",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "iron",
    "version": 248,
    "popularity": 366,
    "codepoint": 58755,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "clothes",
      "electric",
      "iron",
      "ironing",
      "machine",
      "object"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "iron",
    "version": 4,
    "popularity": 2082,
    "codepoint": 58755,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "appliance",
      "clothes",
      "electric",
      "iron",
      "ironing",
      "machine",
      "object"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "iso",
    "version": 11,
    "popularity": 2293,
    "codepoint": 58358,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "add",
      "edit",
      "editing",
      "effect",
      "image",
      "iso",
      "minus",
      "photography",
      "picture",
      "plus",
      "sensor",
      "shutter",
      "speed",
      "subtract"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "jamboard_kiosk",
    "version": 248,
    "popularity": 47,
    "codepoint": 59829,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "business",
      "chrome",
      "desktop",
      "device",
      "hardware",
      "jamboard",
      "kiosk",
      "monitor",
      "presentation",
      "whiteboard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "javascript",
    "version": 248,
    "popularity": 1885,
    "codepoint": 60284,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "javascript",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "javascript",
    "version": 1,
    "popularity": 3270,
    "codepoint": 60284,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "javascript",
      "letter",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "join",
    "version": 248,
    "popularity": 101,
    "codepoint": 63567,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "circle",
      "combine",
      "command",
      "join",
      "left",
      "outer",
      "overlap",
      "right",
      "sql"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "join_full",
    "version": 1,
    "popularity": 3336,
    "codepoint": 60139,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "combine",
      "command",
      "join",
      "left",
      "outer",
      "overlap",
      "right",
      "sql"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "join_inner",
    "version": 248,
    "popularity": 615,
    "codepoint": 60148,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "circle",
      "command",
      "inner",
      "join",
      "matching",
      "overlap",
      "sql",
      "values"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "join_inner",
    "version": 1,
    "popularity": 2851,
    "codepoint": 60148,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "command",
      "inner",
      "join",
      "matching",
      "overlap",
      "sql",
      "values"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "join_left",
    "version": 248,
    "popularity": 398,
    "codepoint": 60146,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "circle",
      "command",
      "join",
      "left",
      "matching",
      "overlap",
      "sql",
      "values"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "join_left",
    "version": 1,
    "popularity": 2052,
    "codepoint": 60146,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "command",
      "join",
      "left",
      "matching",
      "overlap",
      "sql",
      "values"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "join_right",
    "version": 248,
    "popularity": 336,
    "codepoint": 60138,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "circle",
      "command",
      "join",
      "matching",
      "overlap",
      "right",
      "sql",
      "values"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "join_right",
    "version": 1,
    "popularity": 1805,
    "codepoint": 60138,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "command",
      "join",
      "matching",
      "overlap",
      "right",
      "sql",
      "values"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "joystick",
    "version": 248,
    "popularity": 54,
    "codepoint": 62958,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arcade",
      "console",
      "controller",
      "device",
      "flight stick",
      "gamepad",
      "gaming"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "jump_to_element",
    "version": 248,
    "popularity": 12,
    "codepoint": 63257,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "frame",
      "left",
      "move",
      "navigation",
      "northeast",
      "position"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "kayaking",
    "version": 248,
    "popularity": 690,
    "codepoint": 58636,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "canoe",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "kayak",
      "kayaking",
      "lake",
      "paddle",
      "paddling",
      "people",
      "person",
      "rafting",
      "river",
      "row",
      "social",
      "sports",
      "summer",
      "travel",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "kayaking",
    "version": 4,
    "popularity": 3490,
    "codepoint": 58636,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "canoe",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "kayak",
      "kayaking",
      "lake",
      "paddle",
      "paddling",
      "people",
      "person",
      "rafting",
      "river",
      "row",
      "social",
      "sports",
      "summer",
      "travel",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "kebab_dining",
    "version": 248,
    "popularity": 379,
    "codepoint": 59458,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "dining",
      "dinner",
      "food",
      "kebab",
      "meal",
      "meat",
      "skewer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "kebab_dining",
    "version": 2,
    "popularity": 1139,
    "codepoint": 59458,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "dining",
      "dinner",
      "food",
      "kebab",
      "meal",
      "meat",
      "skewer"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "kettle",
    "version": 248,
    "popularity": 308,
    "codepoint": 58041,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "home",
      "house",
      "kettle",
      "kitchen",
      "nest",
      "steamer",
      "tea"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "key",
    "version": 248,
    "popularity": 11521,
    "codepoint": 59196,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "access",
      "door",
      "entry",
      "key",
      "lock",
      "password",
      "unlock"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "key",
    "version": 2,
    "popularity": 20685,
    "codepoint": 59196,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "access",
      "door",
      "entry",
      "key",
      "lock",
      "password",
      "unlock"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "key_off",
    "version": 248,
    "popularity": 929,
    "codepoint": 60292,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "access",
      "disabled",
      "door",
      "enabled",
      "entry",
      "key",
      "lock",
      "off",
      "offline",
      "on",
      "password",
      "slash",
      "unlock"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "key_off",
    "version": 1,
    "popularity": 1299,
    "codepoint": 60292,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "access",
      "disabled",
      "door",
      "enabled",
      "entry",
      "key",
      "lock",
      "off",
      "offline",
      "on",
      "password",
      "slash",
      "unlock"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "key_vertical",
    "version": 248,
    "popularity": 13,
    "codepoint": 62746,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "access",
      "door",
      "entry",
      "key",
      "lock",
      "password",
      "unlock"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "key_visualizer",
    "version": 248,
    "popularity": 453,
    "codepoint": 61849,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "data",
      "heatmap",
      "hot",
      "insight",
      "introspection",
      "key",
      "lines",
      "monitoring",
      "row",
      "visualizer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard",
    "version": 248,
    "popularity": 3102,
    "codepoint": 58130,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard",
    "version": 13,
    "popularity": 16025,
    "codepoint": 58130,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_alt",
    "version": 248,
    "popularity": 780,
    "codepoint": 61480,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "alt",
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_alt",
    "version": 15,
    "popularity": 3989,
    "codepoint": 61480,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "alt",
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_arrow_down",
    "version": 248,
    "popularity": 8520,
    "codepoint": 58131,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "down",
      "keyboard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_arrow_down",
    "version": 18,
    "popularity": 66859,
    "codepoint": 58131,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "down",
      "keyboard"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_arrow_left",
    "version": 248,
    "popularity": 2055,
    "codepoint": 58132,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "keyboard",
      "left"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_arrow_left",
    "version": 17,
    "popularity": 17859,
    "codepoint": 58132,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "keyboard",
      "left"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_arrow_right",
    "version": 248,
    "popularity": 3189,
    "codepoint": 58133,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "keyboard",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_arrow_right",
    "version": 17,
    "popularity": 32798,
    "codepoint": 58133,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "keyboard",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_arrow_up",
    "version": 248,
    "popularity": 2851,
    "codepoint": 58134,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "less",
      "list",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_arrow_up",
    "version": 19,
    "popularity": 20015,
    "codepoint": 58134,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "less",
      "list",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_backspace",
    "version": 248,
    "popularity": 4331,
    "codepoint": 58135,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "back",
      "backspace",
      "keyboard",
      "left"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_backspace",
    "version": 13,
    "popularity": 21483,
    "codepoint": 58135,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "back",
      "backspace",
      "keyboard",
      "left"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_capslock",
    "version": 248,
    "popularity": 352,
    "codepoint": 58136,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "capslock",
      "keyboard",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_capslock",
    "version": 13,
    "popularity": 2016,
    "codepoint": 58136,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "capslock",
      "keyboard",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_capslock_badge",
    "version": 248,
    "popularity": 5,
    "codepoint": 63454,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrow",
      "arrows",
      "capitalization",
      "caps",
      "format",
      "function",
      "lowercase",
      "type",
      "up",
      "uppercase"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_command_key",
    "version": 248,
    "popularity": 1261,
    "codepoint": 60135,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "button",
      "command key",
      "control",
      "keyboard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_command_key",
    "version": 1,
    "popularity": 1584,
    "codepoint": 60135,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "button",
      "command key",
      "control",
      "keyboard"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "keyboard_control_key",
    "version": 248,
    "popularity": 962,
    "codepoint": 60134,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "control key",
      "keyboard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_control_key",
    "version": 1,
    "popularity": 806,
    "codepoint": 60134,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "control key",
      "keyboard"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "keyboard_double_arrow_down",
    "version": 248,
    "popularity": 3895,
    "codepoint": 60112,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "down",
      "multiple",
      "navigation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_double_arrow_down",
    "version": 2,
    "popularity": 10782,
    "codepoint": 60112,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "down",
      "multiple",
      "navigation"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "keyboard_double_arrow_left",
    "version": 248,
    "popularity": 4008,
    "codepoint": 60099,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "left",
      "multiple",
      "navigation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_double_arrow_left",
    "version": 2,
    "popularity": 12571,
    "codepoint": 60099,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "left",
      "multiple",
      "navigation"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "keyboard_double_arrow_right",
    "version": 248,
    "popularity": 6343,
    "codepoint": 60105,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_double_arrow_right",
    "version": 2,
    "popularity": 18941,
    "codepoint": 60105,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "right"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "keyboard_double_arrow_up",
    "version": 248,
    "popularity": 2600,
    "codepoint": 60111,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_double_arrow_up",
    "version": 2,
    "popularity": 8119,
    "codepoint": 60111,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "double",
      "multiple",
      "navigation",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "keyboard_external_input",
    "version": 248,
    "popularity": 10,
    "codepoint": 63453,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrow",
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "link",
      "office",
      "output",
      "right",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_full",
    "version": 248,
    "popularity": 4,
    "codepoint": 63452,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "fullscreen",
      "layout",
      "panel",
      "panels"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_hide",
    "version": 248,
    "popularity": 370,
    "codepoint": 58138,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "computer",
      "device",
      "down",
      "hardware",
      "hide",
      "input",
      "keyboard",
      "keypad",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_hide",
    "version": 13,
    "popularity": 2707,
    "codepoint": 58138,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "computer",
      "device",
      "down",
      "hardware",
      "hide",
      "input",
      "keyboard",
      "keypad",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_keys",
    "version": 248,
    "popularity": 14,
    "codepoint": 63099,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_off",
    "version": 248,
    "popularity": 8,
    "codepoint": 63098,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "computer",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "off",
      "office",
      "on",
      "slash",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_onscreen",
    "version": 248,
    "popularity": 2,
    "codepoint": 63451,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_option_key",
    "version": 248,
    "popularity": 494,
    "codepoint": 60136,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt key",
      "key",
      "keyboard",
      "modifier key",
      "option"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_option_key",
    "version": 1,
    "popularity": 653,
    "codepoint": 60136,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "alt key",
      "key",
      "keyboard",
      "modifier key",
      "option"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "keyboard_previous_language",
    "version": 248,
    "popularity": 4,
    "codepoint": 63450,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrow",
      "back",
      "computer",
      "device",
      "hardware",
      "input",
      "keyboard",
      "keypad",
      "letter",
      "office",
      "redo",
      "refresh",
      "restore",
      "text",
      "type",
      "undo"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_return",
    "version": 248,
    "popularity": 1974,
    "codepoint": 58139,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "back",
      "keyboard",
      "left",
      "return"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_return",
    "version": 13,
    "popularity": 18803,
    "codepoint": 58139,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "back",
      "keyboard",
      "left",
      "return"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_tab",
    "version": 248,
    "popularity": 717,
    "codepoint": 58140,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "keyboard",
      "left",
      "next",
      "right",
      "tab"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_tab",
    "version": 13,
    "popularity": 5472,
    "codepoint": 58140,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "keyboard",
      "left",
      "next",
      "right",
      "tab"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "keyboard_tab_rtl",
    "version": 248,
    "popularity": 10,
    "codepoint": 60531,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "keyboard",
      "left",
      "next",
      "right",
      "tab"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_voice",
    "version": 248,
    "popularity": 3908,
    "codepoint": 58141,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "keyboard",
      "mic",
      "microphone",
      "noise",
      "record",
      "recorder",
      "speaker",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "keyboard_voice",
    "version": 17,
    "popularity": 9944,
    "codepoint": 58141,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "keyboard",
      "mic",
      "microphone",
      "noise",
      "record",
      "recorder",
      "speaker",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "kid_star",
    "version": 248,
    "popularity": 4,
    "codepoint": 62758,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "best",
      "bookmark",
      "child",
      "favorite",
      "highlight",
      "kid",
      "ranking",
      "rate",
      "rating",
      "save",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "king_bed",
    "version": 248,
    "popularity": 1263,
    "codepoint": 59973,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "king_bed",
    "version": 12,
    "popularity": 7991,
    "codepoint": 59973,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "sleep"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "kitchen",
    "version": 248,
    "popularity": 1534,
    "codepoint": 60231,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "cold",
      "food",
      "fridge",
      "home",
      "house",
      "ice",
      "kitchen",
      "places",
      "refrigerator",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "kitchen",
    "version": 12,
    "popularity": 8540,
    "codepoint": 60231,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "appliance",
      "cold",
      "food",
      "fridge",
      "home",
      "house",
      "ice",
      "kitchen",
      "places",
      "refrigerator",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "kitesurfing",
    "version": 248,
    "popularity": 422,
    "codepoint": 58637,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "kitesurfing",
      "people",
      "person",
      "social",
      "sports",
      "surf",
      "travel",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "kitesurfing",
    "version": 4,
    "popularity": 2312,
    "codepoint": 58637,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "kitesurfing",
      "people",
      "person",
      "social",
      "sports",
      "surf",
      "travel",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "lab_panel",
    "version": 248,
    "popularity": 16,
    "codepoint": 57603,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "health",
      "labs",
      "medical",
      "research",
      "sample",
      "samples",
      "test"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lab_profile",
    "version": 248,
    "popularity": 63,
    "codepoint": 57604,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "document",
      "file",
      "folder",
      "folders",
      "health",
      "labs",
      "page",
      "paper",
      "report",
      "reports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lab_research",
    "version": 248,
    "popularity": 41,
    "codepoint": 63499,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "label",
    "version": 248,
    "popularity": 4213,
    "codepoint": 59538,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "favorite",
      "indent",
      "label",
      "library",
      "mail",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "label",
    "version": 17,
    "popularity": 32195,
    "codepoint": 59538,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "favorite",
      "indent",
      "label",
      "library",
      "mail",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "label_important",
    "version": 248,
    "popularity": 1707,
    "codepoint": 59703,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "favorite",
      "important",
      "indent",
      "label",
      "library",
      "mail",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tag",
      "wing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "label_important",
    "version": 20,
    "popularity": 15476,
    "codepoint": 59703,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "favorite",
      "important",
      "indent",
      "label",
      "library",
      "mail",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tag",
      "wing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "label_important_outline",
    "version": 16,
    "popularity": 1386,
    "codepoint": 59720,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "favorite",
      "important",
      "indent",
      "label",
      "library",
      "mail",
      "outline",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tag",
      "wing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "label_off",
    "version": 248,
    "popularity": 421,
    "codepoint": 59830,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "disabled",
      "enabled",
      "favorite",
      "indent",
      "label",
      "library",
      "mail",
      "off",
      "on",
      "remember",
      "save",
      "slash",
      "stamp",
      "sticker",
      "tag",
      "wing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "label_off",
    "version": 13,
    "popularity": 2530,
    "codepoint": 59830,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "disabled",
      "enabled",
      "favorite",
      "indent",
      "label",
      "library",
      "mail",
      "off",
      "on",
      "remember",
      "save",
      "slash",
      "stamp",
      "sticker",
      "tag",
      "wing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "labs",
    "version": 248,
    "popularity": 128,
    "codepoint": 57605,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "health",
      "lab",
      "medical",
      "research",
      "sample",
      "samples",
      "test"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lan",
    "version": 248,
    "popularity": 2820,
    "codepoint": 60207,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "computer",
      "connection",
      "data",
      "internet",
      "lan",
      "network",
      "service"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lan",
    "version": 1,
    "popularity": 5302,
    "codepoint": 60207,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "computer",
      "connection",
      "data",
      "internet",
      "lan",
      "network",
      "service"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "landscape",
    "version": 248,
    "popularity": 2316,
    "codepoint": 58359,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "image",
      "landscape",
      "mountain",
      "mountains",
      "nature",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "landscape",
    "version": 12,
    "popularity": 9392,
    "codepoint": 58359,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "image",
      "landscape",
      "mountain",
      "mountains",
      "nature",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "landslide",
    "version": 248,
    "popularity": 520,
    "codepoint": 60375,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "landslide",
    "version": 1,
    "popularity": 796,
    "codepoint": 60375,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "weather"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "language",
    "version": 248,
    "popularity": 29760,
    "codepoint": 59540,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "globe",
      "internet",
      "language",
      "planet",
      "website",
      "world",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language",
    "version": 12,
    "popularity": 176115,
    "codepoint": 59540,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "globe",
      "internet",
      "language",
      "planet",
      "website",
      "world",
      "www"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "language_chinese_array",
    "version": 248,
    "popularity": 1,
    "codepoint": 63334,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_chinese_cangjie",
    "version": 248,
    "popularity": 1,
    "codepoint": 63333,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_chinese_dayi",
    "version": 248,
    "popularity": 2,
    "codepoint": 63332,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_chinese_pinyin",
    "version": 248,
    "popularity": 1,
    "codepoint": 63331,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_chinese_quick",
    "version": 248,
    "popularity": 7,
    "codepoint": 63330,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_chinese_wubi",
    "version": 248,
    "popularity": 2,
    "codepoint": 63329,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_french",
    "version": 248,
    "popularity": 4,
    "codepoint": 63328,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_gb_english",
    "version": 248,
    "popularity": 3,
    "codepoint": 63327,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_international",
    "version": 248,
    "popularity": 4,
    "codepoint": 63326,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_japanese_kana",
    "version": 248,
    "popularity": 1,
    "codepoint": 62739,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_korean_latin",
    "version": 248,
    "popularity": 11,
    "codepoint": 63325,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_pinyin",
    "version": 248,
    "popularity": 3,
    "codepoint": 63324,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_spanish",
    "version": 248,
    "popularity": 7,
    "codepoint": 62953,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_us",
    "version": 248,
    "popularity": 2,
    "codepoint": 63321,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_us_colemak",
    "version": 248,
    "popularity": 1,
    "codepoint": 63323,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "language_us_dvorak",
    "version": 248,
    "popularity": 1,
    "codepoint": 63322,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dialect",
      "format",
      "languages",
      "localization",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "laps",
    "version": 248,
    "popularity": 57,
    "codepoint": 63161,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "arrow",
      "exercise",
      "fitness",
      "lap",
      "loop",
      "run",
      "workout"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "laptop",
    "version": 13,
    "popularity": 18909,
    "codepoint": 58142,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "computer",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "monitor",
      "web",
      "windows"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "laptop_chromebook",
    "version": 248,
    "popularity": 2066,
    "codepoint": 58143,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "chromebook",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac chromebook",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "laptop_chromebook",
    "version": 12,
    "popularity": 5279,
    "codepoint": 58143,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "chromebook",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac chromebook",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "laptop_mac",
    "version": 248,
    "popularity": 2932,
    "codepoint": 58144,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "laptop_mac",
    "version": 12,
    "popularity": 0,
    "codepoint": 58144,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "laptop_windows",
    "version": 248,
    "popularity": 1658,
    "codepoint": 58145,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "monitor",
      "screen",
      "web",
      "window",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "laptop_windows",
    "version": 12,
    "popularity": 3085,
    "codepoint": 58145,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "monitor",
      "screen",
      "web",
      "window",
      "windows"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lasso_select",
    "version": 248,
    "popularity": 21,
    "codepoint": 60163,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "color",
      "dash",
      "dashed",
      "doc",
      "edit",
      "editing",
      "editor",
      "fill",
      "ink",
      "lasso",
      "paint",
      "pen",
      "pencil",
      "select",
      "sheet",
      "spreadsheet",
      "style",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "last_page",
    "version": 248,
    "popularity": 2273,
    "codepoint": 58845,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "chevron",
      "components",
      "end",
      "forward",
      "interface",
      "last",
      "page",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "last_page",
    "version": 13,
    "popularity": 13620,
    "codepoint": 58845,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "chevron",
      "components",
      "end",
      "forward",
      "interface",
      "last",
      "page",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "launch",
    "version": 17,
    "popularity": 0,
    "codepoint": 59541,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "box",
      "components",
      "interface",
      "launch",
      "new",
      "open",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "laundry",
    "version": 248,
    "popularity": 880,
    "codepoint": 58024,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "laundry",
      "shirt",
      "washer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "layers",
    "version": 248,
    "popularity": 3254,
    "codepoint": 58683,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrange",
      "disabled",
      "enabled",
      "interaction",
      "layers",
      "maps",
      "off",
      "on",
      "overlay",
      "pages",
      "slash",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "layers",
    "version": 12,
    "popularity": 21891,
    "codepoint": 58683,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrange",
      "disabled",
      "enabled",
      "interaction",
      "layers",
      "maps",
      "off",
      "on",
      "overlay",
      "pages",
      "slash",
      "stack"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "layers_clear",
    "version": 248,
    "popularity": 591,
    "codepoint": 58684,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrange",
      "clear",
      "delete",
      "disabled",
      "enabled",
      "interaction",
      "layers",
      "maps",
      "off",
      "on",
      "overlay",
      "pages",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "layers_clear",
    "version": 12,
    "popularity": 4174,
    "codepoint": 58684,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrange",
      "clear",
      "delete",
      "disabled",
      "enabled",
      "interaction",
      "layers",
      "maps",
      "off",
      "on",
      "overlay",
      "pages",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lda",
    "version": 248,
    "popularity": 2,
    "codepoint": 57606,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "airway",
      "doctor",
      "drain",
      "health",
      "line",
      "medical",
      "nurse"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "leaderboard",
    "version": 248,
    "popularity": 3820,
    "codepoint": 61964,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "leaderboard",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "leaderboard",
    "version": 6,
    "popularity": 39587,
    "codepoint": 61964,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "leaderboard",
      "measure",
      "metrics",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "leak_add",
    "version": 248,
    "popularity": 456,
    "codepoint": 58360,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "add",
      "connection",
      "data",
      "leak",
      "link",
      "network",
      "service",
      "signals",
      "synce",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "leak_add",
    "version": 12,
    "popularity": 3830,
    "codepoint": 58360,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "add",
      "connection",
      "data",
      "leak",
      "link",
      "network",
      "service",
      "signals",
      "synce",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "leak_remove",
    "version": 248,
    "popularity": 162,
    "codepoint": 58361,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "connection",
      "data",
      "disabled",
      "enabled",
      "leak",
      "link",
      "network",
      "off",
      "offline",
      "on",
      "remove",
      "service",
      "signals",
      "slash",
      "synce",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "leak_remove",
    "version": 12,
    "popularity": 1252,
    "codepoint": 58361,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "connection",
      "data",
      "disabled",
      "enabled",
      "leak",
      "link",
      "network",
      "off",
      "offline",
      "on",
      "remove",
      "service",
      "signals",
      "slash",
      "synce",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "left_click",
    "version": 248,
    "popularity": 102,
    "codepoint": 63256,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "clicks",
      "left",
      "mouse",
      "move",
      "select",
      "selection",
      "selects"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "left_panel_close",
    "version": 248,
    "popularity": 36,
    "codepoint": 63255,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "left_panel_open",
    "version": 248,
    "popularity": 30,
    "codepoint": 63254,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "legend_toggle",
    "version": 248,
    "popularity": 833,
    "codepoint": 61723,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "legend",
      "measure",
      "metrics",
      "monitoring",
      "stackdriver",
      "statistics",
      "toggle",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "legend_toggle",
    "version": 8,
    "popularity": 6048,
    "codepoint": 61723,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "legend",
      "measure",
      "metrics",
      "monitoring",
      "stackdriver",
      "statistics",
      "toggle",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "lens",
    "version": 248,
    "popularity": 953,
    "codepoint": 58362,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "circle",
      "full",
      "geometry",
      "lens",
      "moon"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lens",
    "version": 12,
    "popularity": 8853,
    "codepoint": 58362,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "circle",
      "full",
      "geometry",
      "lens",
      "moon"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lens_blur",
    "version": 248,
    "popularity": 781,
    "codepoint": 61481,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "camera",
      "dim",
      "dot",
      "effect",
      "foggy",
      "fuzzy",
      "image",
      "lens",
      "photo",
      "soften"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lens_blur",
    "version": 10,
    "popularity": 4343,
    "codepoint": 61481,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "blur",
      "camera",
      "dim",
      "dot",
      "effect",
      "foggy",
      "fuzzy",
      "image",
      "lens",
      "photo",
      "soften"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "letter_switch",
    "version": 248,
    "popularity": 3,
    "codepoint": 63320,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrows",
      "flip",
      "flips",
      "format",
      "languages",
      "localization",
      "swap",
      "swaps",
      "text",
      "translation",
      "translations",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "library_add",
    "version": 248,
    "popularity": 5338,
    "codepoint": 57390,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "add",
      "collection",
      "layers",
      "library",
      "multiple",
      "music",
      "new",
      "plus",
      "stacked",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "library_add",
    "version": 13,
    "popularity": 15046,
    "codepoint": 57390,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "add",
      "collection",
      "layers",
      "library",
      "multiple",
      "music",
      "new",
      "plus",
      "stacked",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "library_add_check",
    "version": 248,
    "popularity": 1247,
    "codepoint": 59831,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "add",
      "approve",
      "check",
      "collection",
      "complete",
      "done",
      "layers",
      "library",
      "mark",
      "multiple",
      "music",
      "ok",
      "select",
      "stacked",
      "tick",
      "validate",
      "verified",
      "video",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "library_add_check",
    "version": 17,
    "popularity": 8342,
    "codepoint": 59831,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "add",
      "approve",
      "check",
      "collection",
      "complete",
      "done",
      "layers",
      "library",
      "mark",
      "multiple",
      "music",
      "ok",
      "select",
      "stacked",
      "tick",
      "validate",
      "verified",
      "video",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "library_books",
    "version": 248,
    "popularity": 6413,
    "codepoint": 57391,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "add",
      "album",
      "audio",
      "book",
      "books",
      "collection",
      "library",
      "read",
      "reading"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "library_books",
    "version": 14,
    "popularity": 31660,
    "codepoint": 57391,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "add",
      "album",
      "audio",
      "book",
      "books",
      "collection",
      "library",
      "read",
      "reading"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "library_music",
    "version": 248,
    "popularity": 1871,
    "codepoint": 57392,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "add",
      "album",
      "collection",
      "library",
      "music",
      "song",
      "sounds"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "library_music",
    "version": 13,
    "popularity": 10103,
    "codepoint": 57392,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "add",
      "album",
      "collection",
      "library",
      "music",
      "song",
      "sounds"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "license",
    "version": 248,
    "popularity": 144,
    "codepoint": 60164,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "certification",
      "degree",
      "ecommerce",
      "guarantee",
      "license",
      "medal",
      "permit",
      "premium",
      "ribbon",
      "verification"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lift_to_talk",
    "version": 248,
    "popularity": 299,
    "codepoint": 61347,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "light",
    "version": 248,
    "popularity": 989,
    "codepoint": 61482,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bulb",
      "ceiling",
      "hanging",
      "inside",
      "interior",
      "lamp",
      "light",
      "lighting",
      "pendent",
      "room"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "light",
    "version": 9,
    "popularity": 5961,
    "codepoint": 61482,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bulb",
      "ceiling",
      "hanging",
      "inside",
      "interior",
      "lamp",
      "light",
      "lighting",
      "pendent",
      "room"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "light_group",
    "version": 248,
    "popularity": 288,
    "codepoint": 57995,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "group",
      "home",
      "lamps",
      "lighting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "light_mode",
    "version": 248,
    "popularity": 11559,
    "codepoint": 58648,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bright",
      "brightness",
      "day",
      "device",
      "light",
      "lighting",
      "mode",
      "morning",
      "sky",
      "sun",
      "sunny"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "light_mode",
    "version": 4,
    "popularity": 55121,
    "codepoint": 58648,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bright",
      "brightness",
      "day",
      "device",
      "light",
      "lighting",
      "mode",
      "morning",
      "sky",
      "sun",
      "sunny"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "light_off",
    "version": 248,
    "popularity": 45,
    "codepoint": 59832,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bulb",
      "disabled",
      "enabled",
      "idea",
      "lamp",
      "light",
      "lights",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lightbulb",
    "version": 248,
    "popularity": 9754,
    "codepoint": 57584,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alert",
      "announcement",
      "idea",
      "info",
      "information",
      "light",
      "lightbulb"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lightbulb",
    "version": 18,
    "popularity": 0,
    "codepoint": 57584,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "announcement",
      "idea",
      "info",
      "information",
      "light",
      "lightbulb"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lightbulb_circle",
    "version": 248,
    "popularity": 885,
    "codepoint": 60414,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alert",
      "announcement",
      "idea",
      "info",
      "information",
      "light",
      "lightbulb"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lightbulb_circle",
    "version": 1,
    "popularity": 3058,
    "codepoint": 60414,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "announcement",
      "idea",
      "info",
      "information",
      "light",
      "lightbulb"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "lightbulb_outline",
    "version": 20,
    "popularity": 4025,
    "codepoint": 59663,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "announcement",
      "idea",
      "info",
      "information",
      "light",
      "lightbulb",
      "outline"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lightning_stand",
    "version": 248,
    "popularity": 31,
    "codepoint": 61348,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "bolt",
      "charge",
      "charging",
      "device",
      "dock",
      "electric",
      "energy",
      "hardware",
      "instant",
      "lightning",
      "mobile",
      "stand",
      "thunderbolt",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_axis",
    "version": 248,
    "popularity": 271,
    "codepoint": 60058,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "axis",
      "dash",
      "horizontal",
      "line",
      "stroke",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_axis",
    "version": 2,
    "popularity": 0,
    "codepoint": 60058,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "axis",
      "dash",
      "horizontal",
      "line",
      "stroke",
      "vertical"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "line_curve",
    "version": 248,
    "popularity": 24,
    "codepoint": 63319,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "corner",
      "draw",
      "drawing",
      "edit",
      "editing",
      "format",
      "line",
      "round",
      "rounded",
      "vector"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_end",
    "version": 248,
    "popularity": 12,
    "codepoint": 63526,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_end_arrow",
    "version": 248,
    "popularity": 14,
    "codepoint": 63517,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_end_arrow_notch",
    "version": 248,
    "popularity": 11,
    "codepoint": 63516,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_end_circle",
    "version": 248,
    "popularity": 18,
    "codepoint": 63515,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_end_diamond",
    "version": 248,
    "popularity": 7,
    "codepoint": 63514,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_end_square",
    "version": 248,
    "popularity": 8,
    "codepoint": 63513,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "dash",
      "endcap",
      "horizontal",
      "line",
      "path",
      "right",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_start",
    "version": 248,
    "popularity": 12,
    "codepoint": 63525,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_start_arrow",
    "version": 248,
    "popularity": 5,
    "codepoint": 63512,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_start_arrow_notch",
    "version": 248,
    "popularity": 2,
    "codepoint": 63511,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_start_circle",
    "version": 248,
    "popularity": 8,
    "codepoint": 63510,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_start_diamond",
    "version": 248,
    "popularity": 8,
    "codepoint": 63509,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_start_square",
    "version": 248,
    "popularity": 3,
    "codepoint": 63508,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrowhead",
      "arrowheads",
      "begin",
      "dash",
      "endcap",
      "horizontal",
      "left",
      "line",
      "node",
      "path",
      "stroke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_style",
    "version": 248,
    "popularity": 265,
    "codepoint": 59673,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "dash",
      "dotted",
      "line",
      "rule",
      "spacing",
      "style"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_style",
    "version": 14,
    "popularity": 3387,
    "codepoint": 59673,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "dash",
      "dotted",
      "line",
      "rule",
      "spacing",
      "style"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "line_weight",
    "version": 248,
    "popularity": 366,
    "codepoint": 59674,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "height",
      "line",
      "size",
      "spacing",
      "style",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "line_weight",
    "version": 14,
    "popularity": 4779,
    "codepoint": 59674,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "height",
      "line",
      "size",
      "spacing",
      "style",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "linear_scale",
    "version": 248,
    "popularity": 855,
    "codepoint": 57952,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "linear",
      "measure",
      "menu",
      "scale",
      "screen",
      "site",
      "slider",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "linear_scale",
    "version": 17,
    "popularity": 8189,
    "codepoint": 57952,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "linear",
      "measure",
      "menu",
      "scale",
      "screen",
      "site",
      "slider",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "link",
    "version": 248,
    "popularity": 15080,
    "codepoint": 57687,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chain",
      "clip",
      "connection",
      "link",
      "linked",
      "links",
      "multimedia",
      "url"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "link",
    "version": 21,
    "popularity": 77997,
    "codepoint": 57687,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "chain",
      "clip",
      "connection",
      "link",
      "linked",
      "links",
      "multimedia",
      "url"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "link_off",
    "version": 248,
    "popularity": 2185,
    "codepoint": 57711,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "attached",
      "chain",
      "clip",
      "connection",
      "disabled",
      "enabled",
      "link",
      "linked",
      "links",
      "multimedia",
      "off",
      "on",
      "slash",
      "url"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "link_off",
    "version": 11,
    "popularity": 14189,
    "codepoint": 57711,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "attached",
      "chain",
      "clip",
      "connection",
      "disabled",
      "enabled",
      "link",
      "linked",
      "links",
      "multimedia",
      "off",
      "on",
      "slash",
      "url"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "linked_camera",
    "version": 248,
    "popularity": 551,
    "codepoint": 58424,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "connect",
      "connection",
      "lens",
      "linked",
      "network",
      "photo",
      "photography",
      "picture",
      "signal",
      "signals",
      "sync",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "linked_camera",
    "version": 15,
    "popularity": 2487,
    "codepoint": 58424,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "connect",
      "connection",
      "lens",
      "linked",
      "network",
      "photo",
      "photography",
      "picture",
      "signal",
      "signals",
      "sync",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "linked_services",
    "version": 248,
    "popularity": 32,
    "codepoint": 62773,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "chart",
      "data",
      "digital markets act",
      "dma",
      "federation",
      "group",
      "link",
      "nodes",
      "pool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "liquor",
    "version": 248,
    "popularity": 1752,
    "codepoint": 60000,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "drink",
      "food",
      "liquor",
      "party",
      "store",
      "wine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "liquor",
    "version": 10,
    "popularity": 8423,
    "codepoint": 60000,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "drink",
      "food",
      "liquor",
      "party",
      "store",
      "wine"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "list",
    "version": 248,
    "popularity": 11445,
    "codepoint": 59542,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "file",
      "format",
      "index",
      "list",
      "menu",
      "options"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "list",
    "version": 18,
    "popularity": 129420,
    "codepoint": 59542,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "file",
      "format",
      "index",
      "list",
      "menu",
      "options"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "list_alt",
    "version": 248,
    "popularity": 9394,
    "codepoint": 57582,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alt",
      "box",
      "contained",
      "form",
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked",
      "title"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "list_alt",
    "version": 13,
    "popularity": 49423,
    "codepoint": 57582,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "alt",
      "box",
      "contained",
      "form",
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked",
      "title"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "list_alt_add",
    "version": 248,
    "popularity": 19,
    "codepoint": 63318,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "doc",
      "document",
      "drive",
      "file",
      "lists",
      "new",
      "page",
      "plus"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lists",
    "version": 248,
    "popularity": 134,
    "codepoint": 59833,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "bullet",
      "check",
      "checklist",
      "directory",
      "document",
      "form",
      "list",
      "lists",
      "menu",
      "points",
      "to do"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "live_help",
    "version": 248,
    "popularity": 3616,
    "codepoint": 57542,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "?",
      "assistance",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "help",
      "info",
      "information",
      "live",
      "message",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "speech",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "live_help",
    "version": 15,
    "popularity": 17487,
    "codepoint": 57542,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "?",
      "assistance",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "help",
      "info",
      "information",
      "live",
      "message",
      "punctuation",
      "question mark",
      "recent",
      "restore",
      "speech",
      "support",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "live_tv",
    "version": 248,
    "popularity": 2994,
    "codepoint": 58937,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "antennas hardware",
      "chrome",
      "desktop",
      "device",
      "iOS",
      "live",
      "mac",
      "monitor",
      "movie",
      "play",
      "stream",
      "television",
      "tv",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "live_tv",
    "version": 11,
    "popularity": 18157,
    "codepoint": 58937,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "Android",
      "OS",
      "antennas hardware",
      "chrome",
      "desktop",
      "device",
      "iOS",
      "live",
      "mac",
      "monitor",
      "movie",
      "play",
      "stream",
      "television",
      "tv",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "living",
    "version": 248,
    "popularity": 469,
    "codepoint": 61483,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "chair",
      "comfort",
      "couch",
      "decoration",
      "furniture",
      "home",
      "house",
      "living",
      "lounging",
      "loveseat",
      "room",
      "seat",
      "seating",
      "sofa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "living",
    "version": 9,
    "popularity": 3203,
    "codepoint": 61483,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "chair",
      "comfort",
      "couch",
      "decoration",
      "furniture",
      "home",
      "house",
      "living",
      "lounging",
      "loveseat",
      "room",
      "seat",
      "seating",
      "sofa"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_activity",
    "version": 248,
    "popularity": 3194,
    "codepoint": 58687,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "activity",
      "event",
      "event ticket",
      "local",
      "star",
      "things",
      "ticket"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_activity",
    "version": 19,
    "popularity": 16798,
    "codepoint": 58687,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "activity",
      "event",
      "event ticket",
      "local",
      "star",
      "things",
      "ticket"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_airport",
    "version": 14,
    "popularity": 7737,
    "codepoint": 58685,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "air",
      "airplane",
      "airport",
      "flight",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "local_atm",
    "version": 248,
    "popularity": 1797,
    "codepoint": 58686,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "atm",
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "local",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_atm",
    "version": 12,
    "popularity": 17304,
    "codepoint": 58686,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "atm",
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "local",
      "money",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_bar",
    "version": 248,
    "popularity": 2021,
    "codepoint": 58688,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "drink",
      "food",
      "liquor",
      "local",
      "wine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_bar",
    "version": 12,
    "popularity": 9422,
    "codepoint": 58688,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "drink",
      "food",
      "liquor",
      "local",
      "wine"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_cafe",
    "version": 248,
    "popularity": 3416,
    "codepoint": 58689,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bottle",
      "cafe",
      "coffee",
      "cup",
      "drink",
      "food",
      "restaurant",
      "tea"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_cafe",
    "version": 11,
    "popularity": 16133,
    "codepoint": 58689,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bottle",
      "cafe",
      "coffee",
      "cup",
      "drink",
      "food",
      "restaurant",
      "tea"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_car_wash",
    "version": 248,
    "popularity": 524,
    "codepoint": 58690,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "local",
      "maps",
      "transportation",
      "travel",
      "vehicle",
      "wash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_car_wash",
    "version": 12,
    "popularity": 2341,
    "codepoint": 58690,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "local",
      "maps",
      "transportation",
      "travel",
      "vehicle",
      "wash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_convenience_store",
    "version": 248,
    "popularity": 1260,
    "codepoint": 58691,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "--",
      "24",
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "convenience",
      "credit",
      "currency",
      "dollars",
      "local",
      "maps",
      "market",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "shop",
      "shopping",
      "store",
      "storefront",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_convenience_store",
    "version": 16,
    "popularity": 3904,
    "codepoint": 58691,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "--",
      "24",
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "convenience",
      "credit",
      "currency",
      "dollars",
      "local",
      "maps",
      "market",
      "money",
      "new",
      "online",
      "pay",
      "payment",
      "plus",
      "shop",
      "shopping",
      "store",
      "storefront",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_dining",
    "version": 248,
    "popularity": 1119,
    "codepoint": 58710,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "dining",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "meal",
      "restaurant",
      "spoon"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_dining",
    "version": 21,
    "popularity": 7379,
    "codepoint": 58710,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "dining",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "meal",
      "restaurant",
      "spoon"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_drink",
    "version": 248,
    "popularity": 1327,
    "codepoint": 58692,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "cup",
      "drink",
      "drop",
      "droplet",
      "liquid",
      "local",
      "park",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_drink",
    "version": 12,
    "popularity": 6815,
    "codepoint": 58692,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "cup",
      "drink",
      "drop",
      "droplet",
      "liquid",
      "local",
      "park",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_fire_department",
    "version": 248,
    "popularity": 5888,
    "codepoint": 61269,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "911",
      "climate",
      "department",
      "fire",
      "firefighter",
      "flame",
      "heat",
      "home",
      "hot",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_fire_department",
    "version": 15,
    "popularity": 38201,
    "codepoint": 61269,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "911",
      "climate",
      "department",
      "fire",
      "firefighter",
      "flame",
      "heat",
      "home",
      "hot",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "local_florist",
    "version": 248,
    "popularity": 2154,
    "codepoint": 58693,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "florist",
      "flower",
      "local",
      "shop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_florist",
    "version": 12,
    "popularity": 11276,
    "codepoint": 58693,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "florist",
      "flower",
      "local",
      "shop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_gas_station",
    "version": 248,
    "popularity": 1946,
    "codepoint": 58694,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "auto",
      "car",
      "gas",
      "local",
      "oil",
      "station",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_gas_station",
    "version": 13,
    "popularity": 11793,
    "codepoint": 58694,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "auto",
      "car",
      "gas",
      "local",
      "oil",
      "station",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_grocery_store",
    "version": 12,
    "popularity": 13122,
    "codepoint": 58695,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "grocery",
      "market",
      "shop",
      "store"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_hospital",
    "version": 248,
    "popularity": 2285,
    "codepoint": 58696,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "911",
      "aid",
      "cross",
      "emergency",
      "first",
      "hospital",
      "local",
      "medicine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_hospital",
    "version": 12,
    "popularity": 19240,
    "codepoint": 58696,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "911",
      "aid",
      "cross",
      "emergency",
      "first",
      "hospital",
      "local",
      "medicine"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_hotel",
    "version": 13,
    "popularity": 2335,
    "codepoint": 58697,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "body",
      "hotel",
      "human",
      "local",
      "people",
      "person",
      "sleep",
      "stay",
      "travel",
      "trip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_laundry_service",
    "version": 248,
    "popularity": 1132,
    "codepoint": 58698,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "cleaning",
      "clothing",
      "dry",
      "dryer",
      "hotel",
      "laundry",
      "local",
      "service",
      "washer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_laundry_service",
    "version": 16,
    "popularity": 6884,
    "codepoint": 58698,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "cleaning",
      "clothing",
      "dry",
      "dryer",
      "hotel",
      "laundry",
      "local",
      "service",
      "washer"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_library",
    "version": 248,
    "popularity": 3688,
    "codepoint": 58699,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "book",
      "community learning",
      "library",
      "local",
      "read"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_library",
    "version": 12,
    "popularity": 18413,
    "codepoint": 58699,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "book",
      "community learning",
      "library",
      "local",
      "read"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_mall",
    "version": 248,
    "popularity": 5788,
    "codepoint": 58700,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "bag",
      "bill",
      "building",
      "business",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "handbag",
      "local",
      "mall",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_mall",
    "version": 12,
    "popularity": 27068,
    "codepoint": 58700,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bag",
      "bill",
      "building",
      "business",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "handbag",
      "local",
      "mall",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_movies",
    "version": 12,
    "popularity": 3389,
    "codepoint": 58701,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_offer",
    "version": 20,
    "popularity": 57209,
    "codepoint": 58702,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "deal",
      "discount",
      "offer",
      "price",
      "shop",
      "shopping",
      "store",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_parking",
    "version": 248,
    "popularity": 2366,
    "codepoint": 58703,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "alphabet",
      "auto",
      "car",
      "character",
      "font",
      "garage",
      "letter",
      "local",
      "park",
      "parking",
      "symbol",
      "text",
      "type",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_parking",
    "version": 12,
    "popularity": 11348,
    "codepoint": 58703,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alphabet",
      "auto",
      "car",
      "character",
      "font",
      "garage",
      "letter",
      "local",
      "park",
      "parking",
      "symbol",
      "text",
      "type",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_pharmacy",
    "version": 248,
    "popularity": 727,
    "codepoint": 58704,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "911",
      "aid",
      "cross",
      "emergency",
      "first",
      "hospital",
      "local",
      "medicine",
      "pharmacy",
      "places"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_pharmacy",
    "version": 12,
    "popularity": 5012,
    "codepoint": 58704,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "911",
      "aid",
      "cross",
      "emergency",
      "first",
      "hospital",
      "local",
      "medicine",
      "pharmacy",
      "places"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_phone",
    "version": 17,
    "popularity": 13188,
    "codepoint": 58705,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "booth",
      "call",
      "communication",
      "phone",
      "telecommunication"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_pizza",
    "version": 248,
    "popularity": 1322,
    "codepoint": 58706,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "drink",
      "fastfood",
      "food",
      "local",
      "meal",
      "pizza"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_pizza",
    "version": 12,
    "popularity": 6550,
    "codepoint": 58706,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "drink",
      "fastfood",
      "food",
      "local",
      "meal",
      "pizza"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_play",
    "version": 12,
    "popularity": 1503,
    "codepoint": 58707,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_police",
    "version": 248,
    "popularity": 2276,
    "codepoint": 61270,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "911",
      "badge",
      "law",
      "local",
      "officer",
      "police",
      "protect",
      "protection",
      "security",
      "shield"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_police",
    "version": 13,
    "popularity": 14765,
    "codepoint": 61270,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "911",
      "badge",
      "law",
      "local",
      "officer",
      "police",
      "protect",
      "protection",
      "security",
      "shield"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_post_office",
    "version": 248,
    "popularity": 1304,
    "codepoint": 58708,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "delivery",
      "email",
      "envelop",
      "letter",
      "local",
      "mail",
      "message",
      "office",
      "package",
      "parcel",
      "post",
      "postal",
      "send",
      "stamp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_post_office",
    "version": 12,
    "popularity": 10836,
    "codepoint": 58708,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "delivery",
      "email",
      "envelop",
      "letter",
      "local",
      "mail",
      "message",
      "office",
      "package",
      "parcel",
      "post",
      "postal",
      "send",
      "stamp"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_printshop",
    "version": 17,
    "popularity": 6779,
    "codepoint": 58709,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "draft",
      "fax",
      "ink",
      "local",
      "machine",
      "office",
      "paper",
      "print",
      "printer",
      "printshop",
      "send"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_see",
    "version": 248,
    "popularity": 343,
    "codepoint": 58711,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "camera",
      "lens",
      "local",
      "photo",
      "photography",
      "picture",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_see",
    "version": 16,
    "popularity": 4467,
    "codepoint": 58711,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "camera",
      "lens",
      "local",
      "photo",
      "photography",
      "picture",
      "see"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_shipping",
    "version": 248,
    "popularity": 19948,
    "codepoint": 58712,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "delivery",
      "letter",
      "local",
      "mail",
      "maps",
      "office",
      "package",
      "parcel",
      "post",
      "postal",
      "send",
      "shipping",
      "shopping",
      "stamp",
      "transportation",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_shipping",
    "version": 12,
    "popularity": 198396,
    "codepoint": 58712,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "delivery",
      "letter",
      "local",
      "mail",
      "maps",
      "office",
      "package",
      "parcel",
      "post",
      "postal",
      "send",
      "shipping",
      "shopping",
      "stamp",
      "transportation",
      "truck",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "local_taxi",
    "version": 248,
    "popularity": 1579,
    "codepoint": 58713,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "cab",
      "call",
      "car",
      "cars",
      "direction",
      "local",
      "lyft",
      "maps",
      "public",
      "taxi",
      "transportation",
      "uber",
      "vehicle",
      "yellow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "local_taxi",
    "version": 12,
    "popularity": 7150,
    "codepoint": 58713,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "cab",
      "call",
      "car",
      "cars",
      "direction",
      "local",
      "lyft",
      "maps",
      "public",
      "taxi",
      "transportation",
      "uber",
      "vehicle",
      "yellow"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "location_away",
    "version": 248,
    "popularity": 2111,
    "codepoint": 61776,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "account",
      "architecture",
      "avatar",
      "away",
      "building",
      "estate",
      "face",
      "home",
      "house",
      "human",
      "location",
      "people",
      "person",
      "place",
      "profile",
      "real",
      "residence",
      "residential",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_chip",
    "version": 248,
    "popularity": 30,
    "codepoint": 63568,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "chip",
      "destination",
      "direction",
      "location",
      "maps",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_city",
    "version": 248,
    "popularity": 4748,
    "codepoint": 59377,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "apartments",
      "architecture",
      "buildings",
      "business",
      "city",
      "estate",
      "home",
      "landscape",
      "location",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "town",
      "urban"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_city",
    "version": 12,
    "popularity": 27932,
    "codepoint": 59377,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "apartments",
      "architecture",
      "buildings",
      "business",
      "city",
      "estate",
      "home",
      "landscape",
      "location",
      "place",
      "real",
      "residence",
      "residential",
      "shelter",
      "town",
      "urban"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "location_disabled",
    "version": 248,
    "popularity": 275,
    "codepoint": 57782,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "location",
      "maps",
      "off",
      "on",
      "pin",
      "place",
      "pointer",
      "slash",
      "stop",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_disabled",
    "version": 12,
    "popularity": 1261,
    "codepoint": 57782,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "location",
      "maps",
      "off",
      "on",
      "pin",
      "place",
      "pointer",
      "slash",
      "stop",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "location_home",
    "version": 248,
    "popularity": 1324,
    "codepoint": 61778,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "account",
      "architecture",
      "avatar",
      "building",
      "estate",
      "face",
      "home",
      "human",
      "location",
      "people",
      "person",
      "place",
      "profile",
      "real",
      "residence",
      "residential",
      "shelter",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_off",
    "version": 248,
    "popularity": 851,
    "codepoint": 57543,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "off",
      "pin",
      "place",
      "room",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_off",
    "version": 11,
    "popularity": 3693,
    "codepoint": 57543,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "off",
      "pin",
      "place",
      "room",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "location_on",
    "version": 248,
    "popularity": 16764,
    "codepoint": 57544,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "on",
      "pin",
      "place",
      "room",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_on",
    "version": 15,
    "popularity": 197240,
    "codepoint": 57544,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "on",
      "pin",
      "place",
      "room",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "location_pin",
    "version": 7,
    "popularity": 4099,
    "codepoint": 61915,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "location_searching",
    "version": 248,
    "popularity": 2161,
    "codepoint": 57783,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "pin",
      "place",
      "pointer",
      "searching",
      "stop",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "location_searching",
    "version": 12,
    "popularity": 7905,
    "codepoint": 57783,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "pin",
      "place",
      "pointer",
      "searching",
      "stop",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lock",
    "version": 248,
    "popularity": 29282,
    "codepoint": 59543,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lock",
    "version": 18,
    "popularity": 208243,
    "codepoint": 59543,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lock_clock",
    "version": 248,
    "popularity": 1105,
    "codepoint": 61271,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "clock",
      "date",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "schedule",
      "secure",
      "security",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lock_clock",
    "version": 11,
    "popularity": 7705,
    "codepoint": 61271,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "clock",
      "date",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "schedule",
      "secure",
      "security",
      "time"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "lock_open",
    "version": 248,
    "popularity": 8098,
    "codepoint": 59544,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "lock",
      "open",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "unlocked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lock_open",
    "version": 16,
    "popularity": 59021,
    "codepoint": 59544,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "lock",
      "open",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "unlocked"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lock_open_right",
    "version": 248,
    "popularity": 55,
    "codepoint": 63062,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "lock",
      "open",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "unlocked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lock_outline",
    "version": 13,
    "popularity": 4098,
    "codepoint": 59545,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lock_person",
    "version": 248,
    "popularity": 1152,
    "codepoint": 63731,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lock_person",
    "version": 1,
    "popularity": 2168,
    "codepoint": 63731,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "lock_reset",
    "version": 248,
    "popularity": 2142,
    "codepoint": 60126,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "around",
      "inprogress",
      "load",
      "loading refresh",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "renew",
      "rotate",
      "safety",
      "secure",
      "security",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lock_reset",
    "version": 1,
    "popularity": 7323,
    "codepoint": 60126,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "around",
      "inprogress",
      "load",
      "loading refresh",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "renew",
      "rotate",
      "safety",
      "secure",
      "security",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "login",
    "version": 248,
    "popularity": 20689,
    "codepoint": 60023,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "access",
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "enter",
      "in",
      "interface",
      "left",
      "log",
      "login",
      "screen",
      "sign",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "login",
    "version": 12,
    "popularity": 124731,
    "codepoint": 60023,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "access",
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "enter",
      "in",
      "interface",
      "left",
      "log",
      "login",
      "screen",
      "sign",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "logo_dev",
    "version": 248,
    "popularity": 492,
    "codepoint": 60118,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "dev",
      "dev.to",
      "logo"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "logo_dev",
    "version": 2,
    "popularity": 1812,
    "codepoint": 60118,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "dev",
      "dev.to",
      "logo"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "logout",
    "version": 248,
    "popularity": 45249,
    "codepoint": 59834,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "exit",
      "interface",
      "leave",
      "log",
      "login",
      "logout",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "logout",
    "version": 12,
    "popularity": 243005,
    "codepoint": 59834,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "design",
      "exit",
      "interface",
      "leave",
      "log",
      "login",
      "logout",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "looks",
    "version": 248,
    "popularity": 534,
    "codepoint": 58364,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "circle",
      "half",
      "looks",
      "rainbow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "looks",
    "version": 13,
    "popularity": 2520,
    "codepoint": 58364,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "circle",
      "half",
      "looks",
      "rainbow"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "looks_3",
    "version": 248,
    "popularity": 1027,
    "codepoint": 58363,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "3",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "looks_3",
    "version": 12,
    "popularity": 7640,
    "codepoint": 58363,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "3",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "looks_4",
    "version": 248,
    "popularity": 659,
    "codepoint": 58365,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "4",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "looks_4",
    "version": 12,
    "popularity": 4246,
    "codepoint": 58365,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "4",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "looks_5",
    "version": 248,
    "popularity": 498,
    "codepoint": 58366,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "5",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "looks_5",
    "version": 12,
    "popularity": 3210,
    "codepoint": 58366,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "5",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "looks_6",
    "version": 248,
    "popularity": 374,
    "codepoint": 58367,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "6",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "looks_6",
    "version": 13,
    "popularity": 2478,
    "codepoint": 58367,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "6",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "looks_one",
    "version": 248,
    "popularity": 2472,
    "codepoint": 58368,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "1",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "looks_one",
    "version": 12,
    "popularity": 16544,
    "codepoint": 58368,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "1",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "looks_two",
    "version": 248,
    "popularity": 1176,
    "codepoint": 58369,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "2",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "looks_two",
    "version": 12,
    "popularity": 8759,
    "codepoint": 58369,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "2",
      "digit",
      "looks",
      "numbers",
      "square",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "loop",
    "version": 12,
    "popularity": 19054,
    "codepoint": 57384,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "loop",
      "music",
      "navigation",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "loupe",
    "version": 248,
    "popularity": 724,
    "codepoint": 58370,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "add",
      "details",
      "focus",
      "glass",
      "loupe",
      "magnifying",
      "new",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "loupe",
    "version": 12,
    "popularity": 4944,
    "codepoint": 58370,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "+",
      "add",
      "details",
      "focus",
      "glass",
      "loupe",
      "magnifying",
      "new",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "low_density",
    "version": 248,
    "popularity": 2,
    "codepoint": 63387,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "dash",
      "dashed",
      "dense",
      "output",
      "quality",
      "screen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "low_priority",
    "version": 248,
    "popularity": 1037,
    "codepoint": 57709,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrange",
      "arrow",
      "backward",
      "bottom",
      "list",
      "low",
      "move",
      "order",
      "priority"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "low_priority",
    "version": 18,
    "popularity": 7008,
    "codepoint": 57709,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "arrange",
      "arrow",
      "backward",
      "bottom",
      "list",
      "low",
      "move",
      "order",
      "priority"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "loyalty",
    "version": 248,
    "popularity": 2775,
    "codepoint": 59546,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "benefits",
      "card",
      "credit",
      "heart",
      "loyalty",
      "membership",
      "miles",
      "points",
      "program",
      "subscription",
      "tag",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "loyalty",
    "version": 12,
    "popularity": 21909,
    "codepoint": 59546,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "benefits",
      "card",
      "credit",
      "heart",
      "loyalty",
      "membership",
      "miles",
      "points",
      "program",
      "subscription",
      "tag",
      "travel",
      "trip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lte_mobiledata",
    "version": 248,
    "popularity": 249,
    "codepoint": 61484,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lte_mobiledata",
    "version": 9,
    "popularity": 1282,
    "codepoint": 61484,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lte_mobiledata_badge",
    "version": 248,
    "popularity": 2,
    "codepoint": 63449,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lte_plus_mobiledata",
    "version": 248,
    "popularity": 173,
    "codepoint": 61485,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "plus",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lte_plus_mobiledata",
    "version": 9,
    "popularity": 1061,
    "codepoint": 61485,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "plus",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lte_plus_mobiledata_badge",
    "version": 248,
    "popularity": 2,
    "codepoint": 63448,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "alphabet",
      "character",
      "data",
      "font",
      "internet",
      "letter",
      "lte",
      "mobile",
      "network",
      "plus",
      "speed",
      "symbol",
      "text",
      "type",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "luggage",
    "version": 248,
    "popularity": 1906,
    "codepoint": 62005,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "airport",
      "bag",
      "baggage",
      "carry",
      "flight",
      "hotel",
      "luggage",
      "on",
      "suitcase",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "luggage",
    "version": 7,
    "popularity": 10213,
    "codepoint": 62005,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "airport",
      "bag",
      "baggage",
      "carry",
      "flight",
      "hotel",
      "luggage",
      "on",
      "suitcase",
      "travel",
      "trip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "lunch_dining",
    "version": 248,
    "popularity": 3895,
    "codepoint": 60001,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "drink",
      "fastfood",
      "food",
      "hamburger",
      "lunch",
      "meal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lunch_dining",
    "version": 11,
    "popularity": 19494,
    "codepoint": 60001,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "drink",
      "fastfood",
      "food",
      "hamburger",
      "lunch",
      "meal"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "lyrics",
    "version": 248,
    "popularity": 634,
    "codepoint": 60427,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "key",
      "lyrics",
      "message",
      "music",
      "note",
      "song",
      "sound",
      "speech",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "lyrics",
    "version": 3,
    "popularity": 1259,
    "codepoint": 60427,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "audio",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "key",
      "lyrics",
      "message",
      "music",
      "note",
      "song",
      "sound",
      "speech",
      "track"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "macro_auto",
    "version": 248,
    "popularity": 4,
    "codepoint": 63218,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "automatic",
      "automation",
      "camera",
      "flower",
      "garden",
      "image",
      "macro"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "macro_off",
    "version": 248,
    "popularity": 280,
    "codepoint": 63698,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "disabled",
      "enabled",
      "flower",
      "garden",
      "image",
      "macro",
      "off",
      "offline",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "macro_off",
    "version": 1,
    "popularity": 273,
    "codepoint": 63698,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "camera",
      "disabled",
      "enabled",
      "flower",
      "garden",
      "image",
      "macro",
      "off",
      "offline",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "magnification_large",
    "version": 248,
    "popularity": 27,
    "codepoint": 63549,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "large",
      "magnify",
      "magnifying",
      "view",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "magnification_small",
    "version": 248,
    "popularity": 27,
    "codepoint": 63548,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "magnify",
      "magnifying",
      "small",
      "view",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "magnify_docked",
    "version": 248,
    "popularity": 13,
    "codepoint": 63446,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "display",
      "dock",
      "magnification",
      "new",
      "plus",
      "screen",
      "window",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "magnify_fullscreen",
    "version": 248,
    "popularity": 9,
    "codepoint": 63445,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "display",
      "dock",
      "magnification",
      "new",
      "plus",
      "screen",
      "window",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mail",
    "version": 248,
    "popularity": 64794,
    "codepoint": 57688,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "email",
      "envelop",
      "letter",
      "mail",
      "message",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mail",
    "version": 16,
    "popularity": 79971,
    "codepoint": 57688,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "email",
      "envelop",
      "letter",
      "mail",
      "message",
      "send"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mail_lock",
    "version": 248,
    "popularity": 714,
    "codepoint": 60426,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "email",
      "envelop",
      "letter",
      "lock",
      "locked",
      "mail",
      "message",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mail_lock",
    "version": 1,
    "popularity": 1517,
    "codepoint": 60426,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "email",
      "envelop",
      "letter",
      "lock",
      "locked",
      "mail",
      "message",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "send"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mail_outline",
    "version": 12,
    "popularity": 61934,
    "codepoint": 57569,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "email",
      "envelop",
      "letter",
      "mail",
      "message",
      "outline",
      "send"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "male",
    "version": 248,
    "popularity": 1964,
    "codepoint": 58766,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "male",
    "version": 3,
    "popularity": 12735,
    "codepoint": 58766,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "man",
    "version": 248,
    "popularity": 2446,
    "codepoint": 58603,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "man",
    "version": 2,
    "popularity": 6263,
    "codepoint": 58603,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "man_2",
    "version": 248,
    "popularity": 432,
    "codepoint": 63713,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "man_2",
    "version": 1,
    "popularity": 720,
    "codepoint": 63713,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "man_3",
    "version": 248,
    "popularity": 247,
    "codepoint": 63714,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "abstract",
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "man_3",
    "version": 1,
    "popularity": 383,
    "codepoint": 63714,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "abstract",
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "man_4",
    "version": 248,
    "popularity": 360,
    "codepoint": 63715,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "abstract",
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "man_4",
    "version": 1,
    "popularity": 451,
    "codepoint": 63715,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "abstract",
      "boy",
      "gender",
      "male",
      "man",
      "social",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "manage_accounts",
    "version": 248,
    "popularity": 15771,
    "codepoint": 61486,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "accounts",
      "change",
      "details",
      "face",
      "gear",
      "human",
      "manage",
      "options",
      "people",
      "person",
      "profile",
      "service",
      "settings",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "manage_accounts",
    "version": 10,
    "popularity": 148340,
    "codepoint": 61486,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accounts",
      "change",
      "details",
      "face",
      "gear",
      "human",
      "manage",
      "options",
      "people",
      "person",
      "profile",
      "service",
      "settings",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "manage_history",
    "version": 248,
    "popularity": 2315,
    "codepoint": 60391,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "application",
      "arrow",
      "back",
      "backwards",
      "change",
      "clock",
      "date",
      "details",
      "gear",
      "history",
      "options",
      "refresh",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "settings",
      "time",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "manage_history",
    "version": 1,
    "popularity": 4666,
    "codepoint": 60391,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "application",
      "arrow",
      "back",
      "backwards",
      "change",
      "clock",
      "date",
      "details",
      "gear",
      "history",
      "options",
      "refresh",
      "renew",
      "reverse",
      "rotate",
      "schedule",
      "settings",
      "time",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "manage_search",
    "version": 248,
    "popularity": 7838,
    "codepoint": 61487,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "glass",
      "history",
      "magnifying",
      "manage",
      "search",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "manage_search",
    "version": 10,
    "popularity": 41275,
    "codepoint": 61487,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "glass",
      "history",
      "magnifying",
      "manage",
      "search",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "manga",
    "version": 248,
    "popularity": 7,
    "codepoint": 62947,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "animation",
      "anime",
      "cartoons",
      "comic book",
      "comic strip",
      "comics",
      "google play",
      "graphic novels",
      "speech bubble"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "manufacturing",
    "version": 248,
    "popularity": 491,
    "codepoint": 59174,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "adjustments",
      "clockwork",
      "cog",
      "configuration",
      "factory",
      "gears",
      "industry",
      "machine",
      "manufacturing",
      "mechanical",
      "options",
      "refinery",
      "repair",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "map",
    "version": 248,
    "popularity": 12715,
    "codepoint": 58715,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "map",
      "maps",
      "pin",
      "place",
      "route",
      "stop",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "map",
    "version": 16,
    "popularity": 55881,
    "codepoint": 58715,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "map",
      "maps",
      "pin",
      "place",
      "route",
      "stop",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "maps_home_work",
    "version": 10,
    "popularity": 31358,
    "codepoint": 61488,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "building",
      "home",
      "house",
      "maps",
      "office",
      "work"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "maps_ugc",
    "version": 248,
    "popularity": 1906,
    "codepoint": 61272,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "+",
      "add",
      "bubble",
      "comment",
      "communicate",
      "feedback",
      "maps",
      "message",
      "new",
      "plus",
      "speech",
      "symbol",
      "ugc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "maps_ugc",
    "version": 13,
    "popularity": 7312,
    "codepoint": 61272,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "+",
      "add",
      "bubble",
      "comment",
      "communicate",
      "feedback",
      "maps",
      "message",
      "new",
      "plus",
      "speech",
      "symbol",
      "ugc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "margin",
    "version": 248,
    "popularity": 233,
    "codepoint": 59835,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "dots",
      "layout",
      "margin",
      "padding",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "margin",
    "version": 11,
    "popularity": 1853,
    "codepoint": 59835,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "design",
      "dots",
      "layout",
      "margin",
      "padding",
      "size",
      "square"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mark_as_unread",
    "version": 248,
    "popularity": 1100,
    "codepoint": 59836,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "as",
      "envelop",
      "letter",
      "mail",
      "mark",
      "post",
      "postal",
      "read",
      "receive",
      "send",
      "unread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mark_as_unread",
    "version": 10,
    "popularity": 9237,
    "codepoint": 59836,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "as",
      "envelop",
      "letter",
      "mail",
      "mark",
      "post",
      "postal",
      "read",
      "receive",
      "send",
      "unread"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mark_chat_read",
    "version": 248,
    "popularity": 682,
    "codepoint": 61835,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "approve",
      "bubble",
      "chat",
      "check",
      "comment",
      "communicate",
      "complete",
      "done",
      "mark",
      "message",
      "ok",
      "read",
      "select",
      "sent",
      "speech",
      "tick",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mark_chat_read",
    "version": 8,
    "popularity": 4915,
    "codepoint": 61835,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "approve",
      "bubble",
      "chat",
      "check",
      "comment",
      "communicate",
      "complete",
      "done",
      "mark",
      "message",
      "ok",
      "read",
      "select",
      "sent",
      "speech",
      "tick",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mark_chat_unread",
    "version": 248,
    "popularity": 1529,
    "codepoint": 61833,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alarm",
      "alert",
      "bubble",
      "chat",
      "circle",
      "comment",
      "communicate",
      "dot",
      "mark",
      "message",
      "notification",
      "notifications",
      "notify",
      "reminder",
      "speech",
      "unread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mark_chat_unread",
    "version": 8,
    "popularity": 7716,
    "codepoint": 61833,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "alarm",
      "alert",
      "bubble",
      "chat",
      "circle",
      "comment",
      "communicate",
      "dot",
      "mark",
      "message",
      "notification",
      "notifications",
      "notify",
      "reminder",
      "speech",
      "unread"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mark_email_read",
    "version": 248,
    "popularity": 2463,
    "codepoint": 61836,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "email",
      "envelop",
      "letter",
      "mail",
      "mark",
      "message",
      "note",
      "ok",
      "read",
      "select",
      "send",
      "sent",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mark_email_read",
    "version": 8,
    "popularity": 15155,
    "codepoint": 61836,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "email",
      "envelop",
      "letter",
      "mail",
      "mark",
      "message",
      "note",
      "ok",
      "read",
      "select",
      "send",
      "sent",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mark_email_unread",
    "version": 248,
    "popularity": 2848,
    "codepoint": 61834,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "check",
      "circle",
      "email",
      "envelop",
      "letter",
      "mail",
      "mark",
      "message",
      "note",
      "notification",
      "send",
      "unread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mark_email_unread",
    "version": 8,
    "popularity": 11270,
    "codepoint": 61834,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "check",
      "circle",
      "email",
      "envelop",
      "letter",
      "mail",
      "mark",
      "message",
      "note",
      "notification",
      "send",
      "unread"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mark_unread_chat_alt",
    "version": 248,
    "popularity": 1278,
    "codepoint": 60317,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alarm",
      "alert",
      "bubble",
      "chat",
      "circle",
      "comment",
      "communicate",
      "dot",
      "mark",
      "message",
      "notification",
      "notifications",
      "notify",
      "reminder",
      "speech",
      "unread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mark_unread_chat_alt",
    "version": 1,
    "popularity": 2063,
    "codepoint": 60317,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "alarm",
      "alert",
      "bubble",
      "chat",
      "circle",
      "comment",
      "communicate",
      "dot",
      "mark",
      "message",
      "notification",
      "notifications",
      "notify",
      "reminder",
      "speech",
      "unread"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "markdown",
    "version": 248,
    "popularity": 1,
    "codepoint": 62802,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "code",
      "coding",
      "font",
      "letter",
      "markup",
      "markup language",
      "symbol",
      "syntax",
      "text",
      "text editor",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "markdown_copy",
    "version": 248,
    "popularity": 6,
    "codepoint": 62803,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "code",
      "coding",
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "font",
      "letter",
      "markup",
      "markup language",
      "multiple",
      "paste",
      "symbol",
      "syntax",
      "text",
      "text editor",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "markdown_paste",
    "version": 248,
    "popularity": 0,
    "codepoint": 62804,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "clipboard",
      "code",
      "coding",
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "file",
      "font",
      "letter",
      "markup",
      "markup language",
      "multiple",
      "paste",
      "symbol",
      "syntax",
      "text",
      "text editor",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "markunread",
    "version": 12,
    "popularity": 11693,
    "codepoint": 57689,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "email",
      "envelop",
      "letter",
      "mail",
      "markunread",
      "message",
      "send",
      "unread"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "markunread_mailbox",
    "version": 248,
    "popularity": 799,
    "codepoint": 59547,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "deliver",
      "envelop",
      "letter",
      "mail",
      "mailbox",
      "markunread",
      "post",
      "postal",
      "postbox",
      "receive",
      "send",
      "unread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "markunread_mailbox",
    "version": 12,
    "popularity": 7034,
    "codepoint": 59547,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "deliver",
      "envelop",
      "letter",
      "mail",
      "mailbox",
      "markunread",
      "post",
      "postal",
      "postbox",
      "receive",
      "send",
      "unread"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "masked_transitions",
    "version": 248,
    "popularity": 63,
    "codepoint": 59182,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "edit",
      "editing",
      "effect",
      "film",
      "filter",
      "masked",
      "movie",
      "settings",
      "transitions",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "masks",
    "version": 248,
    "popularity": 1588,
    "codepoint": 61976,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "air",
      "cover",
      "covid",
      "face",
      "hospital",
      "masks",
      "medical",
      "pollution",
      "protection",
      "respirator",
      "sick",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "masks",
    "version": 7,
    "popularity": 10609,
    "codepoint": 61976,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "air",
      "cover",
      "covid",
      "face",
      "hospital",
      "masks",
      "medical",
      "pollution",
      "protection",
      "respirator",
      "sick",
      "social"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "match_case",
    "version": 248,
    "popularity": 22,
    "codepoint": 63217,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "a",
      "alphabet",
      "character",
      "font",
      "letter",
      "letters",
      "lowercase",
      "symbol",
      "text",
      "text transformation",
      "title case",
      "type",
      "uppercase"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "match_word",
    "version": 248,
    "popularity": 7,
    "codepoint": 63216,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "a",
      "alphabet",
      "b",
      "character",
      "font",
      "letter",
      "letters",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "matter",
    "version": 248,
    "popularity": 436,
    "codepoint": 59655,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "device",
      "matter",
      "paring",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "maximize",
    "version": 248,
    "popularity": 1879,
    "codepoint": 59696,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "line",
      "maximize",
      "screen",
      "shape",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "maximize",
    "version": 11,
    "popularity": 11721,
    "codepoint": 59696,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "line",
      "maximize",
      "screen",
      "shape",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "measuring_tape",
    "version": 248,
    "popularity": 3,
    "codepoint": 63151,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "body",
      "fitness",
      "measure",
      "tape"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "media_bluetooth_off",
    "version": 248,
    "popularity": 137,
    "codepoint": 61489,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "disabled",
      "enabled",
      "media",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "paring",
      "signal",
      "slash",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "media_bluetooth_off",
    "version": 10,
    "popularity": 756,
    "codepoint": 61489,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "disabled",
      "enabled",
      "media",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "paring",
      "signal",
      "slash",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "media_bluetooth_on",
    "version": 248,
    "popularity": 220,
    "codepoint": 61490,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "disabled",
      "enabled",
      "media",
      "music",
      "note",
      "off",
      "on",
      "online",
      "paring",
      "signal",
      "slash",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "media_bluetooth_on",
    "version": 10,
    "popularity": 1219,
    "codepoint": 61490,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "disabled",
      "enabled",
      "media",
      "music",
      "note",
      "off",
      "on",
      "online",
      "paring",
      "signal",
      "slash",
      "symbol",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "media_link",
    "version": 248,
    "popularity": 32,
    "codepoint": 63551,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "attach",
      "attachment",
      "link",
      "media",
      "paperclip",
      "play",
      "slide",
      "slideshow",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "media_output",
    "version": 248,
    "popularity": 17,
    "codepoint": 62706,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "box",
      "device",
      "ear",
      "earphone",
      "electronic",
      "headphones",
      "headset",
      "listen",
      "loud",
      "music",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "media_output_off",
    "version": 248,
    "popularity": 2,
    "codepoint": 62707,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "accessory",
      "audio",
      "box",
      "device",
      "disabled",
      "ear",
      "earphone",
      "electronic",
      "enabled",
      "headphones",
      "headset",
      "listen",
      "loud",
      "music",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mediation",
    "version": 248,
    "popularity": 737,
    "codepoint": 61351,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "dots",
      "mediation",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mediation",
    "version": 15,
    "popularity": 8161,
    "codepoint": 61351,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "dots",
      "mediation",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "medical_information",
    "version": 248,
    "popularity": 2426,
    "codepoint": 60397,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "badge",
      "card",
      "health",
      "id",
      "identification",
      "information",
      "medical",
      "services"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "medical_information",
    "version": 1,
    "popularity": 3960,
    "codepoint": 60397,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "badge",
      "card",
      "health",
      "id",
      "identification",
      "information",
      "medical",
      "services"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "medical_mask",
    "version": 248,
    "popularity": 2,
    "codepoint": 63498,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "health",
      "isolation",
      "masks",
      "medical",
      "pandemic",
      "social distance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "medical_services",
    "version": 248,
    "popularity": 3315,
    "codepoint": 61705,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "aid",
      "bag",
      "briefcase",
      "emergency",
      "first",
      "kit",
      "medical",
      "medicine",
      "services"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "medical_services",
    "version": 12,
    "popularity": 21223,
    "codepoint": 61705,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "aid",
      "bag",
      "briefcase",
      "emergency",
      "first",
      "kit",
      "medical",
      "medicine",
      "services"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "medication",
    "version": 248,
    "popularity": 2714,
    "codepoint": 61491,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "doctor",
      "drug",
      "emergency",
      "hospital",
      "medication",
      "medicine",
      "pharmacy",
      "pills",
      "prescription"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "medication",
    "version": 10,
    "popularity": 17752,
    "codepoint": 61491,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "doctor",
      "drug",
      "emergency",
      "hospital",
      "medication",
      "medicine",
      "pharmacy",
      "pills",
      "prescription"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "medication_liquid",
    "version": 248,
    "popularity": 1057,
    "codepoint": 60039,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "+",
      "bottle",
      "doctor",
      "drug",
      "health",
      "hospital",
      "liquid",
      "medications",
      "medicine",
      "pharmacy",
      "spoon",
      "vessel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "medication_liquid",
    "version": 2,
    "popularity": 1999,
    "codepoint": 60039,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "+",
      "bottle",
      "doctor",
      "drug",
      "health",
      "hospital",
      "liquid",
      "medications",
      "medicine",
      "pharmacy",
      "spoon",
      "vessel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "meeting_room",
    "version": 248,
    "popularity": 3122,
    "codepoint": 60239,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "building",
      "door",
      "doorway",
      "entrance",
      "home",
      "house",
      "interior",
      "meeting",
      "office",
      "open",
      "places",
      "room"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "meeting_room",
    "version": 17,
    "popularity": 20048,
    "codepoint": 60239,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "building",
      "door",
      "doorway",
      "entrance",
      "home",
      "house",
      "interior",
      "meeting",
      "office",
      "open",
      "places",
      "room"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "memory",
    "version": 248,
    "popularity": 3280,
    "codepoint": 58146,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "card",
      "chip",
      "digital",
      "memory",
      "micro",
      "processor",
      "sd",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "memory",
    "version": 12,
    "popularity": 16389,
    "codepoint": 58146,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "card",
      "chip",
      "digital",
      "memory",
      "micro",
      "processor",
      "sd",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "memory_alt",
    "version": 248,
    "popularity": 36,
    "codepoint": 63395,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "card",
      "chip",
      "digital",
      "micro",
      "processor",
      "sd",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "menstrual_health",
    "version": 248,
    "popularity": 47,
    "codepoint": 63201,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "blood",
      "cycle",
      "female",
      "fitbit",
      "health",
      "menstruation",
      "ovulation",
      "period",
      "pregnancy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "menu",
    "version": 248,
    "popularity": 136449,
    "codepoint": 58834,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "hamburger",
      "interface",
      "line",
      "lines",
      "menu",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "menu",
    "version": 13,
    "popularity": 353178,
    "codepoint": 58834,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "hamburger",
      "interface",
      "line",
      "lines",
      "menu",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "menu_book",
    "version": 248,
    "popularity": 14213,
    "codepoint": 59929,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "dining",
      "food",
      "meal",
      "menu",
      "restaurant"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "menu_book",
    "version": 12,
    "popularity": 68547,
    "codepoint": 59929,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "book",
      "dining",
      "food",
      "meal",
      "menu",
      "restaurant"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "menu_open",
    "version": 248,
    "popularity": 9361,
    "codepoint": 59837,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "hamburger",
      "interface",
      "left",
      "line",
      "lines",
      "menu",
      "open",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "menu_open",
    "version": 13,
    "popularity": 41682,
    "codepoint": 59837,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "components",
      "hamburger",
      "interface",
      "left",
      "line",
      "lines",
      "menu",
      "open",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "merge",
    "version": 248,
    "popularity": 738,
    "codepoint": 60312,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "merge",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "merge",
    "version": 1,
    "popularity": 2847,
    "codepoint": 60312,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "merge",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "merge_type",
    "version": 248,
    "popularity": 415,
    "codepoint": 57938,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "combine",
      "direction",
      "format",
      "merge",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "merge_type",
    "version": 13,
    "popularity": 4665,
    "codepoint": 57938,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "arrow",
      "combine",
      "direction",
      "format",
      "merge",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "message",
    "version": 13,
    "popularity": 25672,
    "codepoint": 57545,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "metabolism",
    "version": 248,
    "popularity": 14,
    "codepoint": 57611,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "body",
      "health",
      "human",
      "inprogress",
      "load",
      "loading refresh",
      "person",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mfg_nest_yale_lock",
    "version": 248,
    "popularity": 269,
    "codepoint": 61725,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "device",
      "google",
      "hardware",
      "lock",
      "mfg",
      "nest",
      "passcode",
      "password",
      "yale"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mic",
    "version": 248,
    "popularity": 11868,
    "codepoint": 57385,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "dictation",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "record",
      "sound",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mic",
    "version": 13,
    "popularity": 59764,
    "codepoint": 57385,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "dictation",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "record",
      "sound",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mic_double",
    "version": 248,
    "popularity": 23,
    "codepoint": 62929,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "dictation",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "record",
      "sound",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mic_external_off",
    "version": 248,
    "popularity": 148,
    "codepoint": 61273,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "external",
      "mic",
      "microphone",
      "off",
      "on",
      "slash",
      "sound",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mic_external_off",
    "version": 11,
    "popularity": 794,
    "codepoint": 61273,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "external",
      "mic",
      "microphone",
      "off",
      "on",
      "slash",
      "sound",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mic_external_on",
    "version": 248,
    "popularity": 789,
    "codepoint": 61274,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "external",
      "mic",
      "microphone",
      "off",
      "on",
      "slash",
      "sound",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mic_external_on",
    "version": 11,
    "popularity": 3159,
    "codepoint": 61274,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "external",
      "mic",
      "microphone",
      "off",
      "on",
      "slash",
      "sound",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mic_none",
    "version": 12,
    "popularity": 10336,
    "codepoint": 57386,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "none",
      "record",
      "sound",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mic_off",
    "version": 248,
    "popularity": 2198,
    "codepoint": 57387,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "off",
      "on",
      "record",
      "recording",
      "slash",
      "sound",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mic_off",
    "version": 14,
    "popularity": 14556,
    "codepoint": 57387,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "hear",
      "hearing",
      "mic",
      "microphone",
      "noise",
      "off",
      "on",
      "record",
      "recording",
      "slash",
      "sound",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "microbiology",
    "version": 248,
    "popularity": 21,
    "codepoint": 57612,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "disease",
      "health",
      "human",
      "immune",
      "infection",
      "infectious",
      "microbe",
      "microbes",
      "virus",
      "viruses"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "microwave",
    "version": 248,
    "popularity": 563,
    "codepoint": 61956,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "heat",
      "home",
      "house",
      "kitchen",
      "machine",
      "microwave"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "microwave",
    "version": 6,
    "popularity": 3960,
    "codepoint": 61956,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "heat",
      "home",
      "house",
      "kitchen",
      "machine",
      "microwave"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "microwave_gen",
    "version": 248,
    "popularity": 386,
    "codepoint": 59463,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "food",
      "home",
      "hot",
      "house",
      "kitchen",
      "machine",
      "meal",
      "microwave",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "military_tech",
    "version": 248,
    "popularity": 4991,
    "codepoint": 59967,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "army",
      "award",
      "badge",
      "honor",
      "medal",
      "merit",
      "military",
      "order",
      "privilege",
      "prize",
      "rank",
      "reward",
      "ribbon",
      "soldier",
      "star",
      "status",
      "tech",
      "trophy",
      "win",
      "winner"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "military_tech",
    "version": 11,
    "popularity": 25282,
    "codepoint": 59967,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "army",
      "award",
      "badge",
      "honor",
      "medal",
      "merit",
      "military",
      "order",
      "privilege",
      "prize",
      "rank",
      "reward",
      "ribbon",
      "soldier",
      "star",
      "status",
      "tech",
      "trophy",
      "win",
      "winner"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mimo",
    "version": 248,
    "popularity": 43,
    "codepoint": 59838,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "4k",
      "camera",
      "chrome",
      "device",
      "disabled",
      "display",
      "enabled",
      "google",
      "hangouts",
      "hardware",
      "hdmi",
      "meet",
      "mic",
      "mimo",
      "monitor",
      "off",
      "on",
      "slash",
      "speaker",
      "touch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mimo_disconnect",
    "version": 248,
    "popularity": 31,
    "codepoint": 59839,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "4k",
      "camera",
      "chrome",
      "device",
      "disabled",
      "disconnect",
      "display",
      "enabled",
      "google",
      "hangouts",
      "hardware",
      "hdmi",
      "meet",
      "mic",
      "mimo",
      "monitor",
      "off",
      "on",
      "slash",
      "speaker",
      "touch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mindfulness",
    "version": 248,
    "popularity": 105,
    "codepoint": 63200,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "attention",
      "fitbit",
      "focus",
      "mindfulness",
      "wellness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "minimize",
    "version": 248,
    "popularity": 4085,
    "codepoint": 59697,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "line",
      "minimize",
      "screen",
      "shape",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "minimize",
    "version": 11,
    "popularity": 27936,
    "codepoint": 59697,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "line",
      "minimize",
      "screen",
      "shape",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "minor_crash",
    "version": 248,
    "popularity": 758,
    "codepoint": 60401,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "accident",
      "auto",
      "automobile",
      "car",
      "cars",
      "collision",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "minor_crash",
    "version": 1,
    "popularity": 1151,
    "codepoint": 60401,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "accident",
      "auto",
      "automobile",
      "car",
      "cars",
      "collision",
      "directions",
      "maps",
      "public",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mintmark",
    "version": 248,
    "popularity": 26,
    "codepoint": 61353,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arrow",
      "arrows",
      "bill",
      "billing",
      "card",
      "cash",
      "cloud",
      "coin",
      "commerce",
      "credit",
      "currency",
      "diagonal",
      "direction",
      "dollars",
      "google",
      "mintmark",
      "money",
      "online",
      "pay",
      "payment",
      "service",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "miscellaneous_services",
    "version": 12,
    "popularity": 13143,
    "codepoint": 61708,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "missed_video_call",
    "version": 248,
    "popularity": 206,
    "codepoint": 57459,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "call",
      "camera",
      "film",
      "filming",
      "hardware",
      "image",
      "missed",
      "motion",
      "picture",
      "record",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "missed_video_call",
    "version": 13,
    "popularity": 1274,
    "codepoint": 57459,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "call",
      "camera",
      "film",
      "filming",
      "hardware",
      "image",
      "missed",
      "motion",
      "picture",
      "record",
      "video",
      "videography"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "missing_controller",
    "version": 248,
    "popularity": 12,
    "codepoint": 59137,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "controller",
      "device",
      "locate",
      "locating",
      "missing",
      "remote",
      "signals",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mist",
    "version": 248,
    "popularity": 19,
    "codepoint": 57736,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "fog",
      "haze",
      "misty",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mitre",
    "version": 248,
    "popularity": 16,
    "codepoint": 62791,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "cyber attacks",
      "data",
      "diagram",
      "flow",
      "framework",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "protect",
      "protection",
      "schema",
      "security",
      "squares",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mixture_med",
    "version": 248,
    "popularity": 5,
    "codepoint": 58568,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bandage",
      "bandaid",
      "booster",
      "health",
      "immune",
      "immunization",
      "shot",
      "syringe"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mms",
    "version": 248,
    "popularity": 396,
    "codepoint": 58904,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "image",
      "landscape",
      "message",
      "mms",
      "mountain",
      "mountains",
      "multimedia",
      "photo",
      "photography",
      "picture",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mms",
    "version": 12,
    "popularity": 2142,
    "codepoint": 58904,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "image",
      "landscape",
      "message",
      "mms",
      "mountain",
      "mountains",
      "multimedia",
      "photo",
      "photography",
      "picture",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mobile_friendly",
    "version": 248,
    "popularity": 1071,
    "codepoint": 57856,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "approve",
      "cell",
      "check",
      "complete",
      "device",
      "done",
      "friendly",
      "hardware",
      "iOS",
      "mark",
      "mobile",
      "ok",
      "phone",
      "select",
      "tablet",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mobile_friendly",
    "version": 12,
    "popularity": 4744,
    "codepoint": 57856,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "approve",
      "cell",
      "check",
      "complete",
      "device",
      "done",
      "friendly",
      "hardware",
      "iOS",
      "mark",
      "mobile",
      "ok",
      "phone",
      "select",
      "tablet",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mobile_off",
    "version": 248,
    "popularity": 262,
    "codepoint": 57857,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mobile",
      "off",
      "on",
      "phone",
      "silence",
      "slash",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mobile_off",
    "version": 12,
    "popularity": 1242,
    "codepoint": 57857,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mobile",
      "off",
      "on",
      "phone",
      "silence",
      "slash",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mobile_screen_share",
    "version": 248,
    "popularity": 330,
    "codepoint": 57575,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cast",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mirror",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "screencast",
      "share",
      "stream",
      "streaming",
      "tablet",
      "tv",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mobile_screen_share",
    "version": 12,
    "popularity": 2708,
    "codepoint": 57575,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cast",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mirror",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "screencast",
      "share",
      "stream",
      "streaming",
      "tablet",
      "tv",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mobiledata_off",
    "version": 248,
    "popularity": 418,
    "codepoint": 61492,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrow",
      "data",
      "disabled",
      "down",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "on",
      "slash",
      "speed",
      "up",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mobiledata_off",
    "version": 10,
    "popularity": 2903,
    "codepoint": 61492,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "arrow",
      "data",
      "disabled",
      "down",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "on",
      "slash",
      "speed",
      "up",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mode",
    "version": 10,
    "popularity": 12360,
    "codepoint": 61591,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "draw",
      "edit",
      "mode",
      "pen",
      "pencil",
      "write"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mode_comment",
    "version": 248,
    "popularity": 2743,
    "codepoint": 57939,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "mode comment",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_comment",
    "version": 12,
    "popularity": 11762,
    "codepoint": 57939,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "mode comment",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mode_cool",
    "version": 248,
    "popularity": 497,
    "codepoint": 61798,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "climate",
      "cool",
      "flame",
      "heat",
      "home",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_cool_off",
    "version": 248,
    "popularity": 123,
    "codepoint": 61799,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "climate",
      "cool",
      "disabled",
      "enabled",
      "flame",
      "heat",
      "home",
      "nest",
      "off",
      "offline",
      "on",
      "slash",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_dual",
    "version": 248,
    "popularity": 14,
    "codepoint": 62807,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "air",
      "bismuth",
      "blowing",
      "breeze",
      "climate",
      "cool",
      "flame",
      "flow",
      "home",
      "nest",
      "thermostat",
      "wave",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_edit",
    "version": 11,
    "popularity": 49633,
    "codepoint": 57940,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "draw",
      "edit",
      "mode",
      "pen",
      "pencil",
      "write"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mode_edit_outline",
    "version": 10,
    "popularity": 10312,
    "codepoint": 61493,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "compose",
      "create",
      "draft",
      "draw",
      "edit",
      "mode",
      "outline",
      "pen",
      "pencil",
      "write"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mode_fan",
    "version": 248,
    "popularity": 1701,
    "codepoint": 61800,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air conditioner",
      "cool",
      "fan",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_fan_off",
    "version": 248,
    "popularity": 330,
    "codepoint": 60439,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "air conditioner",
      "cool",
      "disabled",
      "enabled",
      "fan",
      "nest",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_fan_off",
    "version": 1,
    "popularity": 1037,
    "codepoint": 60439,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "air conditioner",
      "cool",
      "disabled",
      "enabled",
      "fan",
      "nest",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mode_heat",
    "version": 248,
    "popularity": 995,
    "codepoint": 61802,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "climate",
      "flame",
      "home",
      "hot",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_heat_cool",
    "version": 248,
    "popularity": 252,
    "codepoint": 61803,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "climate",
      "cool",
      "flame",
      "heat",
      "home",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_heat_off",
    "version": 248,
    "popularity": 142,
    "codepoint": 61805,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "climate",
      "disabled",
      "enabled",
      "flame",
      "home",
      "hot",
      "nest",
      "off",
      "on",
      "slash",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_night",
    "version": 248,
    "popularity": 714,
    "codepoint": 61494,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "dark",
      "disturb",
      "lunar",
      "mode",
      "moon",
      "night",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_night",
    "version": 10,
    "popularity": 6299,
    "codepoint": 61494,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "dark",
      "disturb",
      "lunar",
      "mode",
      "moon",
      "night",
      "sleep"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mode_of_travel",
    "version": 248,
    "popularity": 766,
    "codepoint": 59342,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "destination",
      "direction",
      "location",
      "maps",
      "mode",
      "of",
      "pin",
      "place",
      "stop",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_of_travel",
    "version": 2,
    "popularity": 2327,
    "codepoint": 59342,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "destination",
      "direction",
      "location",
      "maps",
      "mode",
      "of",
      "pin",
      "place",
      "stop",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "mode_off_on",
    "version": 248,
    "popularity": 519,
    "codepoint": 61807,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_standby",
    "version": 248,
    "popularity": 440,
    "codepoint": 61495,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "disturb",
      "mode",
      "power",
      "sleep",
      "standby",
      "target"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mode_standby",
    "version": 9,
    "popularity": 3410,
    "codepoint": 61495,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "disturb",
      "mode",
      "power",
      "sleep",
      "standby",
      "target"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "model_training",
    "version": 248,
    "popularity": 2058,
    "codepoint": 61647,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "bulb",
      "idea",
      "inprogress",
      "light",
      "load",
      "loading",
      "model",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "training"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "model_training",
    "version": 13,
    "popularity": 12635,
    "codepoint": 61647,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "bulb",
      "idea",
      "inprogress",
      "light",
      "load",
      "loading",
      "model",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "training"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "monetization_on",
    "version": 248,
    "popularity": 8076,
    "codepoint": 57955,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "circle",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "monetization",
      "money",
      "on",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monetization_on",
    "version": 12,
    "popularity": 47023,
    "codepoint": 57955,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "circle",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "monetization",
      "money",
      "on",
      "online",
      "pay",
      "payment",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "money",
    "version": 248,
    "popularity": 1215,
    "codepoint": 58749,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "100",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "digit",
      "dollars",
      "finance",
      "money",
      "number",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "money",
    "version": 11,
    "popularity": 10770,
    "codepoint": 58749,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "100",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "digit",
      "dollars",
      "finance",
      "money",
      "number",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "money_off",
    "version": 248,
    "popularity": 1115,
    "codepoint": 57948,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "disabled",
      "dollars",
      "enabled",
      "money",
      "off",
      "on",
      "online",
      "pay",
      "payment",
      "shopping",
      "slash",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "money_off",
    "version": 12,
    "popularity": 7603,
    "codepoint": 57948,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "disabled",
      "dollars",
      "enabled",
      "money",
      "off",
      "on",
      "online",
      "pay",
      "payment",
      "shopping",
      "slash",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "money_off_csred",
    "version": 11,
    "popularity": 3767,
    "codepoint": 61496,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "csred",
      "currency",
      "disabled",
      "dollars",
      "enabled",
      "money",
      "off",
      "on",
      "online",
      "pay",
      "payment",
      "shopping",
      "slash",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "monitor",
    "version": 248,
    "popularity": 992,
    "codepoint": 61275,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monitor",
    "version": 11,
    "popularity": 4951,
    "codepoint": 61275,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "monitor_heart",
    "version": 248,
    "popularity": 4044,
    "codepoint": 60066,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "baseline",
      "device",
      "ecc",
      "ecg",
      "fitness",
      "health",
      "heart",
      "medical",
      "monitor",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monitor_heart",
    "version": 2,
    "popularity": 11485,
    "codepoint": 60066,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "baseline",
      "device",
      "ecc",
      "ecg",
      "fitness",
      "health",
      "heart",
      "medical",
      "monitor",
      "track"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "monitor_weight",
    "version": 248,
    "popularity": 833,
    "codepoint": 61497,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "body",
      "device",
      "diet",
      "health",
      "monitor",
      "scale",
      "smart",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monitor_weight",
    "version": 10,
    "popularity": 6894,
    "codepoint": 61497,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "body",
      "device",
      "diet",
      "health",
      "monitor",
      "scale",
      "smart",
      "weight"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "monitor_weight_gain",
    "version": 248,
    "popularity": 6,
    "codepoint": 63199,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "+",
      "add",
      "body",
      "device",
      "diet",
      "fitbit",
      "health",
      "monitor",
      "new",
      "plus",
      "scale",
      "smart",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monitor_weight_loss",
    "version": 248,
    "popularity": 7,
    "codepoint": 63198,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "device",
      "diet",
      "fitbit",
      "health",
      "minus",
      "monitor",
      "reduce",
      "remove",
      "scale",
      "smart",
      "subtract",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monitoring",
    "version": 248,
    "popularity": 17136,
    "codepoint": 61840,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "monitoring",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monochrome_photos",
    "version": 248,
    "popularity": 332,
    "codepoint": 58371,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "black",
      "camera",
      "image",
      "monochrome",
      "photo",
      "photography",
      "photos",
      "picture",
      "white"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "monochrome_photos",
    "version": 11,
    "popularity": 1440,
    "codepoint": 58371,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "black",
      "camera",
      "image",
      "monochrome",
      "photo",
      "photography",
      "photos",
      "picture",
      "white"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mood",
    "version": 248,
    "popularity": 6167,
    "codepoint": 59378,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "smile",
      "smiling",
      "social",
      "survey"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mood",
    "version": 16,
    "popularity": 14564,
    "codepoint": 59378,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "smile",
      "smiling",
      "social",
      "survey"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mood_bad",
    "version": 248,
    "popularity": 1999,
    "codepoint": 59379,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bad",
      "disappointment",
      "dislike",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "mood",
      "person",
      "rating",
      "social",
      "survey",
      "unhappiness",
      "unhappy",
      "unpleased",
      "unsmile",
      "unsmiling"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mood_bad",
    "version": 12,
    "popularity": 9284,
    "codepoint": 59379,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bad",
      "disappointment",
      "dislike",
      "emoji",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "mood",
      "person",
      "rating",
      "social",
      "survey",
      "unhappiness",
      "unhappy",
      "unpleased",
      "unsmile",
      "unsmiling"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mop",
    "version": 248,
    "popularity": 1560,
    "codepoint": 57997,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "clean",
      "mop",
      "nest",
      "polish",
      "sweeper",
      "wash",
      "wipe"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "moped",
    "version": 14,
    "popularity": 3461,
    "codepoint": 60200,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "maps",
      "scooter",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "more",
    "version": 248,
    "popularity": 1607,
    "codepoint": 58905,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "3",
      "archive",
      "bookmark",
      "dots",
      "etc",
      "favorite",
      "indent",
      "label",
      "more",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tab",
      "tag",
      "three"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "more",
    "version": 13,
    "popularity": 9676,
    "codepoint": 58905,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "3",
      "archive",
      "bookmark",
      "dots",
      "etc",
      "favorite",
      "indent",
      "label",
      "more",
      "remember",
      "save",
      "stamp",
      "sticker",
      "tab",
      "tag",
      "three"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "more_down",
    "version": 248,
    "popularity": 609,
    "codepoint": 61846,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "more_horiz",
    "version": 248,
    "popularity": 18468,
    "codepoint": 58835,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3",
      "DISABLE_IOS",
      "app",
      "application",
      "components",
      "disable_ios",
      "dots",
      "etc",
      "horiz",
      "horizontal",
      "interface",
      "ios",
      "more",
      "screen",
      "site",
      "three",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "more_horiz",
    "version": 13,
    "popularity": 96804,
    "codepoint": 58835,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "3",
      "DISABLE_IOS",
      "app",
      "application",
      "components",
      "disable_ios",
      "dots",
      "etc",
      "horiz",
      "horizontal",
      "interface",
      "ios",
      "more",
      "screen",
      "site",
      "three",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "more_time",
    "version": 248,
    "popularity": 2009,
    "codepoint": 59997,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "add",
      "clock",
      "date",
      "more",
      "new",
      "plus",
      "schedule",
      "symbol",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "more_time",
    "version": 11,
    "popularity": 11543,
    "codepoint": 59997,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "+",
      "add",
      "clock",
      "date",
      "more",
      "new",
      "plus",
      "schedule",
      "symbol",
      "time"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "more_up",
    "version": 248,
    "popularity": 984,
    "codepoint": 61847,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "more_vert",
    "version": 248,
    "popularity": 29608,
    "codepoint": 58836,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3",
      "DISABLE_IOS",
      "android",
      "app",
      "application",
      "components",
      "disable_ios",
      "dots",
      "etc",
      "interface",
      "more",
      "screen",
      "site",
      "three",
      "ui",
      "ux",
      "vert",
      "vertical",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "more_vert",
    "version": 19,
    "popularity": 141038,
    "codepoint": 58836,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "3",
      "DISABLE_IOS",
      "android",
      "app",
      "application",
      "components",
      "disable_ios",
      "dots",
      "etc",
      "interface",
      "more",
      "screen",
      "site",
      "three",
      "ui",
      "ux",
      "vert",
      "vertical",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mosque",
    "version": 248,
    "popularity": 582,
    "codepoint": 60082,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "ideology",
      "islam",
      "islamic",
      "masjid",
      "muslim",
      "religion",
      "spiritual",
      "worship"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mosque",
    "version": 2,
    "popularity": 1884,
    "codepoint": 60082,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "ideology",
      "islam",
      "islamic",
      "masjid",
      "muslim",
      "religion",
      "spiritual",
      "worship"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "motion_blur",
    "version": 248,
    "popularity": 258,
    "codepoint": 61648,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motion",
      "motionblur",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_mode",
    "version": 248,
    "popularity": 12,
    "codepoint": 63554,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motion",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_photos_auto",
    "version": 248,
    "popularity": 430,
    "codepoint": 61498,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "A",
      "alphabet",
      "animation",
      "auto",
      "automatic",
      "character",
      "circle",
      "font",
      "gif",
      "letter",
      "live",
      "motion",
      "photos",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_photos_auto",
    "version": 15,
    "popularity": 2368,
    "codepoint": 61498,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "A",
      "alphabet",
      "animation",
      "auto",
      "automatic",
      "character",
      "circle",
      "font",
      "gif",
      "letter",
      "live",
      "motion",
      "photos",
      "symbol",
      "text",
      "type",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "motion_photos_off",
    "version": 248,
    "popularity": 252,
    "codepoint": 59840,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "circle",
      "disabled",
      "enabled",
      "motion",
      "off",
      "on",
      "photos",
      "slash",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_photos_off",
    "version": 14,
    "popularity": 1153,
    "codepoint": 59840,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "animation",
      "circle",
      "disabled",
      "enabled",
      "motion",
      "off",
      "on",
      "photos",
      "slash",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "motion_photos_on",
    "version": 248,
    "popularity": 51,
    "codepoint": 59841,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "circle",
      "disabled",
      "enabled",
      "motion",
      "off",
      "on",
      "photos",
      "play",
      "slash",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_photos_on",
    "version": 18,
    "popularity": 3299,
    "codepoint": 59841,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "animation",
      "circle",
      "disabled",
      "enabled",
      "motion",
      "off",
      "on",
      "photos",
      "play",
      "slash",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "motion_photos_pause",
    "version": 8,
    "popularity": 1134,
    "codepoint": 61991,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "animation",
      "circle",
      "motion",
      "pause",
      "paused",
      "photos",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "motion_photos_paused",
    "version": 248,
    "popularity": 325,
    "codepoint": 59842,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "circle",
      "motion",
      "pause",
      "paused",
      "photos",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_photos_paused",
    "version": 17,
    "popularity": 1451,
    "codepoint": 59842,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "animation",
      "circle",
      "motion",
      "pause",
      "paused",
      "photos",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "motion_sensor_active",
    "version": 248,
    "popularity": 205,
    "codepoint": 59282,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "active",
      "frame",
      "home",
      "motion sensor",
      "nest",
      "security"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_sensor_alert",
    "version": 248,
    "popularity": 109,
    "codepoint": 59268,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alert",
      "frame",
      "home",
      "motion ",
      "nest",
      "security",
      "sensor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_sensor_idle",
    "version": 248,
    "popularity": 88,
    "codepoint": 59267,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "frame",
      "home",
      "idle",
      "motion",
      "nest",
      "off",
      "security",
      "sensor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motion_sensor_urgent",
    "version": 248,
    "popularity": 110,
    "codepoint": 59278,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "motion",
      "nest",
      "security",
      "sensor",
      "urgent"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "motorcycle",
    "version": 248,
    "popularity": 832,
    "codepoint": 59675,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bicycle",
      "bike",
      "car",
      "cars",
      "direction",
      "maps",
      "motorcycle",
      "public",
      "sport",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mountain_flag",
    "version": 248,
    "popularity": 25,
    "codepoint": 62946,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "flag",
      "google play",
      "mountain top",
      "self help"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mouse",
    "version": 248,
    "popularity": 2408,
    "codepoint": 58147,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "click",
      "computer",
      "cursor",
      "device",
      "hardware",
      "mouse",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mouse",
    "version": 12,
    "popularity": 10418,
    "codepoint": 58147,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "click",
      "computer",
      "cursor",
      "device",
      "hardware",
      "mouse",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "move",
    "version": 248,
    "popularity": 8,
    "codepoint": 59200,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "body",
      "destination",
      "direction",
      "health",
      "human",
      "loading",
      "location",
      "maps",
      "navigation",
      "people",
      "person",
      "pin",
      "place",
      "refresh",
      "reload",
      "right",
      "rotate",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_down",
    "version": 248,
    "popularity": 1405,
    "codepoint": 60257,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "direction",
      "down",
      "jump",
      "move",
      "navigation",
      "transfer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_down",
    "version": 2,
    "popularity": 2808,
    "codepoint": 60257,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "arrow",
      "direction",
      "down",
      "jump",
      "move",
      "navigation",
      "transfer"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "move_group",
    "version": 248,
    "popularity": 35,
    "codepoint": 63253,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "copy",
      "duplicate",
      "exit",
      "export",
      "groups",
      "leave",
      "output",
      "tab",
      "tabs",
      "to",
      "window",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_item",
    "version": 248,
    "popularity": 61,
    "codepoint": 61951,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "exit",
      "item",
      "leave",
      "logout",
      "move"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_location",
    "version": 248,
    "popularity": 13,
    "codepoint": 59201,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "body",
      "destination",
      "direction",
      "east",
      "forward",
      "health",
      "human",
      "location",
      "maps",
      "navigation",
      "out",
      "people",
      "person",
      "pin",
      "place",
      "right",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_selection_down",
    "version": 248,
    "popularity": 3,
    "codepoint": 63252,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_selection_left",
    "version": 248,
    "popularity": 14,
    "codepoint": 63251,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_selection_right",
    "version": 248,
    "popularity": 8,
    "codepoint": 63250,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_selection_up",
    "version": 248,
    "popularity": 8,
    "codepoint": 63249,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "dash",
      "dashed",
      "edit",
      "position",
      "rearrange",
      "select",
      "shift"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_to_inbox",
    "version": 248,
    "popularity": 1102,
    "codepoint": 57704,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "archive",
      "arrow",
      "down",
      "email",
      "envelop",
      "inbox",
      "incoming",
      "letter",
      "mail",
      "message",
      "move to",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_to_inbox",
    "version": 15,
    "popularity": 8895,
    "codepoint": 57704,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "archive",
      "arrow",
      "down",
      "email",
      "envelop",
      "inbox",
      "incoming",
      "letter",
      "mail",
      "message",
      "move to",
      "send"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "move_up",
    "version": 248,
    "popularity": 1531,
    "codepoint": 60260,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "direction",
      "jump",
      "move",
      "navigation",
      "transfer",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "move_up",
    "version": 2,
    "popularity": 3312,
    "codepoint": 60260,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "arrow",
      "direction",
      "jump",
      "move",
      "navigation",
      "transfer",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "moved_location",
    "version": 248,
    "popularity": 16,
    "codepoint": 58772,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "destination",
      "direction",
      "location",
      "maps",
      "moved",
      "navigation",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "movie",
    "version": 248,
    "popularity": 5781,
    "codepoint": 57388,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "cinema",
      "film",
      "media",
      "movie",
      "slate",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "movie",
    "version": 12,
    "popularity": 24679,
    "codepoint": 57388,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "cinema",
      "film",
      "media",
      "movie",
      "slate",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "movie_creation",
    "version": 12,
    "popularity": 4480,
    "codepoint": 58372,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "cinema",
      "clapperboard",
      "creation",
      "film",
      "movie",
      "movies",
      "slate",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "movie_edit",
    "version": 248,
    "popularity": 27,
    "codepoint": 63552,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "cinema",
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "film",
      "input",
      "movie",
      "movies",
      "pen",
      "pencil",
      "slate",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "movie_edit",
    "version": 1,
    "popularity": 618,
    "codepoint": 63552,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "cinema",
      "compose",
      "create",
      "draft",
      "edit",
      "editing",
      "film",
      "input",
      "movie",
      "movies",
      "pen",
      "pencil",
      "slate",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "movie_filter",
    "version": 13,
    "popularity": 4181,
    "codepoint": 58426,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "ai",
      "artificial",
      "automatic",
      "automation",
      "clapperboard",
      "creation",
      "custom",
      "film",
      "filter",
      "genai",
      "intelligence",
      "magic",
      "movie",
      "movies",
      "slate",
      "smart",
      "spark",
      "sparkle",
      "star",
      "stars",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "movie_info",
    "version": 248,
    "popularity": 15,
    "codepoint": 57389,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "i",
      "info",
      "information",
      "movie",
      "screen",
      "show",
      "tv",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "moving",
    "version": 248,
    "popularity": 1000,
    "codepoint": 58625,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "direction",
      "moving",
      "navigation",
      "travel",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "moving",
    "version": 4,
    "popularity": 7858,
    "codepoint": 58625,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "direction",
      "moving",
      "navigation",
      "travel",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "moving_beds",
    "version": 248,
    "popularity": 4,
    "codepoint": 59197,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "arrows",
      "bed",
      "body",
      "clinic",
      "direction",
      "east",
      "forward",
      "health",
      "hospital",
      "human",
      "navigation",
      "out",
      "patient",
      "people",
      "person",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "moving_ministry",
    "version": 248,
    "popularity": 14,
    "codepoint": 59198,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "building",
      "clinic",
      "direction",
      "east",
      "forward",
      "health",
      "hospital",
      "navigation",
      "office",
      "out",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mp",
    "version": 248,
    "popularity": 130,
    "codepoint": 59843,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "image",
      "letter",
      "megapixel",
      "mp",
      "photo",
      "photography",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "mp",
    "version": 15,
    "popularity": 718,
    "codepoint": 59843,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "image",
      "letter",
      "megapixel",
      "mp",
      "photo",
      "photography",
      "pixels",
      "quality",
      "resolution",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "multicooker",
    "version": 248,
    "popularity": 274,
    "codepoint": 58003,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "food",
      "home",
      "house",
      "kitchen",
      "machine",
      "meal",
      "multicooker",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "multiline_chart",
    "version": 248,
    "popularity": 413,
    "codepoint": 59103,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "multiple",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "multiline_chart",
    "version": 13,
    "popularity": 2855,
    "codepoint": 59103,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "multiple",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "multiple_stop",
    "version": 248,
    "popularity": 787,
    "codepoint": 61881,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrows",
      "dash",
      "dashed",
      "directions",
      "dots",
      "left",
      "maps",
      "multiple",
      "navigation",
      "right",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "multiple_stop",
    "version": 7,
    "popularity": 5726,
    "codepoint": 61881,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrows",
      "dash",
      "dashed",
      "directions",
      "dots",
      "left",
      "maps",
      "multiple",
      "navigation",
      "right",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "museum",
    "version": 248,
    "popularity": 884,
    "codepoint": 59958,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "attraction",
      "building",
      "estate",
      "event",
      "exhibition",
      "explore",
      "local",
      "museum",
      "places",
      "real",
      "see",
      "shop",
      "store",
      "tour"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "museum",
    "version": 11,
    "popularity": 5148,
    "codepoint": 59958,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "architecture",
      "attraction",
      "building",
      "estate",
      "event",
      "exhibition",
      "explore",
      "local",
      "museum",
      "places",
      "real",
      "see",
      "shop",
      "store",
      "tour"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "music_cast",
    "version": 248,
    "popularity": 48,
    "codepoint": 60186,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "airplay",
      "audio",
      "bluetooth",
      "cast",
      "connect",
      "key",
      "music",
      "note",
      "sound",
      "stream",
      "track",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "music_note",
    "version": 248,
    "popularity": 6827,
    "codepoint": 58373,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "audiotrack",
      "key",
      "music",
      "note",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "music_note",
    "version": 12,
    "popularity": 26790,
    "codepoint": 58373,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "audio",
      "audiotrack",
      "key",
      "music",
      "note",
      "sound",
      "track"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "music_off",
    "version": 248,
    "popularity": 503,
    "codepoint": 58432,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "audiotrack",
      "disabled",
      "enabled",
      "key",
      "music",
      "note",
      "off",
      "on",
      "slash",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "music_off",
    "version": 12,
    "popularity": 2795,
    "codepoint": 58432,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "audio",
      "audiotrack",
      "disabled",
      "enabled",
      "key",
      "music",
      "note",
      "off",
      "on",
      "slash",
      "sound",
      "track"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "music_video",
    "version": 248,
    "popularity": 339,
    "codepoint": 57443,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "band",
      "music",
      "recording",
      "screen",
      "tv",
      "video",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "music_video",
    "version": 15,
    "popularity": 2434,
    "codepoint": 57443,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "band",
      "music",
      "recording",
      "screen",
      "tv",
      "video",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "my_location",
    "version": 248,
    "popularity": 7901,
    "codepoint": 58716,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "point",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "my_location",
    "version": 12,
    "popularity": 30533,
    "codepoint": 58716,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "point",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "mystery",
    "version": 248,
    "popularity": 62,
    "codepoint": 62945,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "eye",
      "eyeball",
      "find",
      "google play",
      "look",
      "magnify",
      "magnify glass",
      "magnifying",
      "search",
      "see",
      "thrillers"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nat",
    "version": 248,
    "popularity": 256,
    "codepoint": 61276,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "communication",
      "nat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nat",
    "version": 11,
    "popularity": 1384,
    "codepoint": 61276,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "communication",
      "nat"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "nature",
    "version": 248,
    "popularity": 2774,
    "codepoint": 58374,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "forest",
      "nature",
      "outdoor",
      "outside",
      "park",
      "tree",
      "wilderness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nature",
    "version": 12,
    "popularity": 3747,
    "codepoint": 58374,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "forest",
      "nature",
      "outdoor",
      "outside",
      "park",
      "tree",
      "wilderness"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "nature_people",
    "version": 248,
    "popularity": 1395,
    "codepoint": 58375,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "activity",
      "body",
      "forest",
      "human",
      "nature",
      "outdoor",
      "outside",
      "park",
      "people",
      "person",
      "tree",
      "wilderness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nature_people",
    "version": 12,
    "popularity": 5838,
    "codepoint": 58375,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "activity",
      "body",
      "forest",
      "human",
      "nature",
      "outdoor",
      "outside",
      "park",
      "people",
      "person",
      "tree",
      "wilderness"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "navigate_before",
    "version": 248,
    "popularity": 4756,
    "codepoint": 58376,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "before",
      "direction",
      "left",
      "navigate"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "navigate_before",
    "version": 13,
    "popularity": 35322,
    "codepoint": 58376,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "before",
      "direction",
      "left",
      "navigate"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "navigate_next",
    "version": 248,
    "popularity": 14247,
    "codepoint": 58377,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "navigate",
      "next",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "navigate_next",
    "version": 13,
    "popularity": 114145,
    "codepoint": 58377,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "navigate",
      "next",
      "right"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "navigation",
    "version": 248,
    "popularity": 2513,
    "codepoint": 58717,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "point",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "navigation",
    "version": 12,
    "popularity": 12025,
    "codepoint": 58717,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "point",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "near_me",
    "version": 248,
    "popularity": 5754,
    "codepoint": 58729,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "me",
      "navigation",
      "near",
      "pin",
      "place",
      "point",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "near_me",
    "version": 12,
    "popularity": 26375,
    "codepoint": 58729,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "me",
      "navigation",
      "near",
      "pin",
      "place",
      "point",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "near_me_disabled",
    "version": 248,
    "popularity": 220,
    "codepoint": 61935,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "location",
      "maps",
      "me",
      "navigation",
      "near",
      "off",
      "on",
      "pin",
      "place",
      "point",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "near_me_disabled",
    "version": 6,
    "popularity": 1329,
    "codepoint": 61935,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "disabled",
      "enabled",
      "location",
      "maps",
      "me",
      "navigation",
      "near",
      "off",
      "on",
      "pin",
      "place",
      "point",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "nearby",
    "version": 248,
    "popularity": 39,
    "codepoint": 59063,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "nearby",
      "squares"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nearby_error",
    "version": 248,
    "popularity": 301,
    "codepoint": 61499,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "nearby",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nearby_error",
    "version": 10,
    "popularity": 1703,
    "codepoint": 61499,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "nearby",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "nearby_off",
    "version": 248,
    "popularity": 121,
    "codepoint": 61500,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "disabled",
      "enabled",
      "nearby",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nearby_off",
    "version": 10,
    "popularity": 623,
    "codepoint": 61500,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "disabled",
      "enabled",
      "nearby",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "nephrology",
    "version": 248,
    "popularity": 10,
    "codepoint": 57613,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "clinical",
      "health",
      "human",
      "kidney",
      "medical",
      "organ"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_audio",
    "version": 248,
    "popularity": 213,
    "codepoint": 60351,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "IoT",
      "assistant",
      "audio",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "speaker",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_floodlight",
    "version": 248,
    "popularity": 141,
    "codepoint": 63671,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "floodlight",
      "home",
      "light",
      "nest",
      "wired"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_indoor",
    "version": 248,
    "popularity": 342,
    "codepoint": 61726,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "iq",
      "nest",
      "protext",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_iq",
    "version": 248,
    "popularity": 231,
    "codepoint": 61727,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "iq",
      "nest",
      "protext",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_iq_outdoor",
    "version": 248,
    "popularity": 237,
    "codepoint": 61728,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "iq",
      "nest",
      "outdoor",
      "protection",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_magnet_mount",
    "version": 248,
    "popularity": 104,
    "codepoint": 63672,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "home",
      "magnetic",
      "mount",
      "nest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_outdoor",
    "version": 248,
    "popularity": 290,
    "codepoint": 61729,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "camera",
      "device",
      "google",
      "hardware",
      "nest",
      "outdoor",
      "protection",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_stand",
    "version": 248,
    "popularity": 97,
    "codepoint": 63673,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "home",
      "nest",
      "stand",
      "wired"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_wall_mount",
    "version": 248,
    "popularity": 96,
    "codepoint": 63674,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cam",
      "home",
      "mount",
      "nest",
      "wall ",
      "wired"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_wired_stand",
    "version": 248,
    "popularity": 155,
    "codepoint": 60438,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "camera",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "nest",
      "picture",
      "stand",
      "video",
      "videography",
      "wired"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_cam_wired_stand",
    "version": 1,
    "popularity": 585,
    "codepoint": 60438,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "camera",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "nest",
      "picture",
      "stand",
      "video",
      "videography",
      "wired"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "nest_clock_farsight_analog",
    "version": 248,
    "popularity": 951,
    "codepoint": 63675,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "analog",
      "climate",
      "clock",
      "farsight",
      "home",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_clock_farsight_digital",
    "version": 248,
    "popularity": 231,
    "codepoint": 63676,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "clock",
      "digital",
      "farsight",
      "home",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_connect",
    "version": 248,
    "popularity": 136,
    "codepoint": 61730,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "connect",
      "device",
      "google",
      "hardware",
      "nest",
      "protection",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_detect",
    "version": 248,
    "popularity": 147,
    "codepoint": 61731,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "detect",
      "device",
      "google",
      "hardware",
      "nest",
      "protection",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_display",
    "version": 248,
    "popularity": 402,
    "codepoint": 61732,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "device",
      "display",
      "google",
      "hardware",
      "monitor",
      "nest",
      "protection",
      "screen",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_display_max",
    "version": 248,
    "popularity": 196,
    "codepoint": 61733,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "device",
      "display",
      "google",
      "hardware",
      "max",
      "monitor",
      "nest",
      "protection",
      "screen",
      "security",
      "surveillance"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_doorbell_visitor",
    "version": 248,
    "popularity": 144,
    "codepoint": 63677,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "doorbell",
      "home",
      "nest",
      "person",
      "security",
      "visitor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_eco_leaf",
    "version": 248,
    "popularity": 1044,
    "codepoint": 63678,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "eco",
      "home",
      "leaf",
      "nest",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_farsight_weather",
    "version": 248,
    "popularity": 190,
    "codepoint": 63679,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "farsight",
      "home",
      "nest",
      "thermostat",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_found_savings",
    "version": 248,
    "popularity": 171,
    "codepoint": 63680,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "eco",
      "home",
      "leaf",
      "nest",
      "savings",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_gale_wifi",
    "version": 248,
    "popularity": 0,
    "codepoint": 62841,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Brand"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_heat_link_e",
    "version": 248,
    "popularity": 315,
    "codepoint": 61734,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "device",
      "e",
      "google",
      "hardware",
      "heat",
      "link",
      "nest",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_heat_link_gen_3",
    "version": 248,
    "popularity": 410,
    "codepoint": 61735,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "3",
      "device",
      "gen",
      "google",
      "hardware",
      "heat",
      "link",
      "nest",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_hello_doorbell",
    "version": 248,
    "popularity": 226,
    "codepoint": 59436,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "chime",
      "doorbell",
      "hello",
      "nest",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_mini",
    "version": 248,
    "popularity": 232,
    "codepoint": 59273,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "google",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_multi_room",
    "version": 248,
    "popularity": 305,
    "codepoint": 63682,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "home",
      "house",
      "multi",
      "nest",
      "rooms",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_protect",
    "version": 248,
    "popularity": 12,
    "codepoint": 59022,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alarm",
      "fire",
      "google",
      "hardware",
      "home",
      "house",
      "nest",
      "protect",
      "smarthouse",
      "system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_remote",
    "version": 248,
    "popularity": 376,
    "codepoint": 61737,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "controller",
      "device",
      "google",
      "hardware",
      "nest",
      "remote"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_remote_comfort_sensor",
    "version": 248,
    "popularity": 966,
    "codepoint": 61738,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "comfort",
      "connection",
      "data",
      "google",
      "internet",
      "nest",
      "network",
      "remote",
      "scan",
      "sensor",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_secure_alarm",
    "version": 248,
    "popularity": 295,
    "codepoint": 61739,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alarm",
      "circle",
      "clock",
      "dot",
      "nest",
      "protection",
      "secure",
      "security",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_sunblock",
    "version": 248,
    "popularity": 134,
    "codepoint": 63683,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "home",
      "nest",
      "sunblock",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_tag",
    "version": 248,
    "popularity": 141,
    "codepoint": 61740,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "device",
      "google",
      "hardware",
      "nest",
      "protection",
      "security",
      "surveillance",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_thermostat",
    "version": 248,
    "popularity": 43,
    "codepoint": 59023,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "70",
      "energy",
      "fahrenheit",
      "frostat",
      "hardware",
      "heating",
      "home",
      "house",
      "nest",
      "smarthouse",
      "system",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_thermostat_e_eu",
    "version": 248,
    "popularity": 127,
    "codepoint": 61741,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "20",
      "celsius",
      "device",
      "e",
      "eu",
      "google",
      "hardware",
      "nest",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_thermostat_gen_3",
    "version": 248,
    "popularity": 270,
    "codepoint": 61742,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "3",
      "device",
      "gen",
      "google",
      "hardware",
      "nest",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_thermostat_sensor",
    "version": 248,
    "popularity": 135,
    "codepoint": 61743,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "70",
      "device",
      "fahrenheit",
      "google",
      "hardware",
      "nest",
      "sensor",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_thermostat_sensor_eu",
    "version": 248,
    "popularity": 135,
    "codepoint": 61744,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "20",
      "device",
      "eu",
      "google",
      "hardware",
      "nest",
      "sensor",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_thermostat_zirconium_eu",
    "version": 248,
    "popularity": 159,
    "codepoint": 61745,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "20",
      "celsius",
      "device",
      "eu",
      "google",
      "hardware",
      "nest",
      "sensor",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_true_radiant",
    "version": 248,
    "popularity": 151,
    "codepoint": 63684,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "home",
      "nest",
      "radiant",
      "thermostat",
      "true"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_wake_on_approach",
    "version": 248,
    "popularity": 116,
    "codepoint": 63685,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "approach",
      "climate",
      "home",
      "nest",
      "thermostat",
      "wake"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_wake_on_press",
    "version": 248,
    "popularity": 113,
    "codepoint": 63686,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "home",
      "nest",
      "press",
      "thermostat",
      "wake"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_wifi_point",
    "version": 248,
    "popularity": 144,
    "codepoint": 59455,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "connection",
      "connectivity",
      "data",
      "device",
      "google",
      "hardware",
      "home",
      "internet",
      "nest",
      "network",
      "point",
      "service",
      "signal",
      "vento",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_wifi_pro",
    "version": 248,
    "popularity": 3,
    "codepoint": 62827,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "device",
      "google",
      "hardware",
      "internet",
      "mobile",
      "nest",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_wifi_pro_2",
    "version": 248,
    "popularity": 2,
    "codepoint": 62826,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "device",
      "google",
      "hardware",
      "internet",
      "mobile",
      "nest",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nest_wifi_router",
    "version": 248,
    "popularity": 204,
    "codepoint": 59457,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "connection",
      "connectivity",
      "data",
      "device",
      "google",
      "hardware",
      "home",
      "internet",
      "mistral",
      "nest",
      "network",
      "router",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_cell",
    "version": 248,
    "popularity": 524,
    "codepoint": 57785,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_cell",
    "version": 16,
    "popularity": 2917,
    "codepoint": 57785,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "network_check",
    "version": 248,
    "popularity": 1004,
    "codepoint": 58944,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "check",
      "connect",
      "connection",
      "internet",
      "meter",
      "network",
      "signal",
      "speed",
      "tick",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_check",
    "version": 12,
    "popularity": 4940,
    "codepoint": 58944,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "check",
      "connect",
      "connection",
      "internet",
      "meter",
      "network",
      "signal",
      "speed",
      "tick",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "network_intelligence_history",
    "version": 248,
    "popularity": 8,
    "codepoint": 62966,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alert",
      "borg",
      "brain",
      "clever",
      "clock",
      "connection",
      "date",
      "intelligence",
      "internet",
      "network",
      "pending",
      "recent",
      "schedule",
      "signal",
      "smart",
      "time",
      "updates",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_intelligence_update",
    "version": 248,
    "popularity": 13,
    "codepoint": 62965,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alert",
      "arrow",
      "arrows",
      "borg",
      "brain",
      "clever",
      "connection",
      "down",
      "download",
      "install",
      "intelligence",
      "internet",
      "network",
      "signal",
      "smart",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_locked",
    "version": 248,
    "popularity": 224,
    "codepoint": 58906,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alert",
      "available",
      "cellular",
      "connection",
      "data",
      "error",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "not",
      "privacy",
      "private",
      "protection",
      "restricted",
      "safety",
      "secure",
      "security",
      "service",
      "signal",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_locked",
    "version": 17,
    "popularity": 1369,
    "codepoint": 58906,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "alert",
      "available",
      "cellular",
      "connection",
      "data",
      "error",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "not",
      "privacy",
      "private",
      "protection",
      "restricted",
      "safety",
      "secure",
      "security",
      "service",
      "signal",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "network_manage",
    "version": 248,
    "popularity": 5,
    "codepoint": 63403,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "application",
      "cell",
      "change",
      "connection",
      "data",
      "details",
      "gear",
      "info",
      "information",
      "internet",
      "mobile",
      "network",
      "options",
      "permission",
      "permissions",
      "personal",
      "phone",
      "service",
      "settings",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_node",
    "version": 248,
    "popularity": 63,
    "codepoint": 62830,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "communication",
      "data",
      "diagram",
      "endpoints",
      "flow",
      "graph",
      "infographic",
      "networking",
      "transmit"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_ping",
    "version": 248,
    "popularity": 267,
    "codepoint": 60362,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alert",
      "available",
      "cellular",
      "connection",
      "data",
      "internet",
      "ip",
      "mobile",
      "network",
      "ping",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_ping",
    "version": 1,
    "popularity": 1382,
    "codepoint": 60362,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "available",
      "cellular",
      "connection",
      "data",
      "internet",
      "ip",
      "mobile",
      "network",
      "ping",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "network_wifi",
    "version": 248,
    "popularity": 658,
    "codepoint": 57786,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_wifi",
    "version": 16,
    "popularity": 4947,
    "codepoint": 57786,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "network_wifi_1_bar",
    "version": 248,
    "popularity": 465,
    "codepoint": 60388,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_wifi_1_bar",
    "version": 1,
    "popularity": 1232,
    "codepoint": 60388,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "network_wifi_1_bar_locked",
    "version": 248,
    "popularity": 4,
    "codepoint": 62863,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_wifi_2_bar",
    "version": 248,
    "popularity": 472,
    "codepoint": 60374,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_wifi_2_bar",
    "version": 1,
    "popularity": 1013,
    "codepoint": 60374,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "network_wifi_2_bar_locked",
    "version": 248,
    "popularity": 7,
    "codepoint": 62862,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_wifi_3_bar",
    "version": 248,
    "popularity": 743,
    "codepoint": 60385,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_wifi_3_bar",
    "version": 1,
    "popularity": 1177,
    "codepoint": 60385,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "network_wifi_3_bar_locked",
    "version": 248,
    "popularity": 16,
    "codepoint": 62861,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "network_wifi_locked",
    "version": 248,
    "popularity": 6,
    "codepoint": 62770,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "neurology",
    "version": 248,
    "popularity": 145,
    "codepoint": 57614,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "brain",
      "clinical",
      "health",
      "human",
      "medical",
      "organ"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "new_label",
    "version": 248,
    "popularity": 1113,
    "codepoint": 58889,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "add",
      "archive",
      "bookmark",
      "favorite",
      "label",
      "library",
      "new",
      "plus",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "symbol",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "new_label",
    "version": 3,
    "popularity": 5635,
    "codepoint": 58889,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "add",
      "archive",
      "bookmark",
      "favorite",
      "label",
      "library",
      "new",
      "plus",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "symbol",
      "tag"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "new_releases",
    "version": 248,
    "popularity": 5414,
    "codepoint": 57393,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "award",
      "check",
      "checkmark",
      "complete",
      "done",
      "new",
      "notification",
      "ok",
      "release",
      "releases",
      "select",
      "star",
      "symbol",
      "tick",
      "verification",
      "verified",
      "warning",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "new_releases",
    "version": 13,
    "popularity": 22439,
    "codepoint": 57393,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "approve",
      "award",
      "check",
      "checkmark",
      "complete",
      "done",
      "new",
      "notification",
      "ok",
      "release",
      "releases",
      "select",
      "star",
      "symbol",
      "tick",
      "verification",
      "verified",
      "warning",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "new_window",
    "version": 248,
    "popularity": 54,
    "codepoint": 63248,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "add",
      "create",
      "frame",
      "new",
      "plus",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "news",
    "version": 248,
    "popularity": 131,
    "codepoint": 57394,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "magazine",
      "media",
      "news",
      "newspaper",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "newsmode",
    "version": 248,
    "popularity": 174,
    "codepoint": 61357,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "magazine",
      "media",
      "mode",
      "news",
      "newspaper"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "newspaper",
    "version": 248,
    "popularity": 4922,
    "codepoint": 60289,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "magazine",
      "media",
      "news",
      "newspaper",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "newspaper",
    "version": 1,
    "popularity": 16919,
    "codepoint": 60289,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "magazine",
      "media",
      "news",
      "newspaper",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "newsstand",
    "version": 248,
    "popularity": 49,
    "codepoint": 59844,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "article",
      "location",
      "magazine",
      "media",
      "news",
      "newspaper",
      "newsstand",
      "place",
      "stack",
      "stand"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "next_plan",
    "version": 248,
    "popularity": 1055,
    "codepoint": 61277,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "circle",
      "next",
      "plan",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "next_plan",
    "version": 16,
    "popularity": 7354,
    "codepoint": 61277,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "circle",
      "next",
      "plan",
      "right"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "next_week",
    "version": 248,
    "popularity": 486,
    "codepoint": 57706,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arrow",
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "next",
      "suitcase",
      "week"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "next_week",
    "version": 14,
    "popularity": 3589,
    "codepoint": 57706,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "arrow",
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "next",
      "suitcase",
      "week"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "nfc",
    "version": 248,
    "popularity": 517,
    "codepoint": 57787,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "communication",
      "data",
      "field",
      "mobile",
      "near",
      "nfc",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nfc",
    "version": 12,
    "popularity": 3457,
    "codepoint": 57787,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "communication",
      "data",
      "field",
      "mobile",
      "near",
      "nfc",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "night_shelter",
    "version": 248,
    "popularity": 865,
    "codepoint": 61937,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "bed",
      "building",
      "estate",
      "homeless",
      "house",
      "night",
      "place",
      "real",
      "shelter",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "night_shelter",
    "version": 6,
    "popularity": 4914,
    "codepoint": 61937,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "bed",
      "building",
      "estate",
      "homeless",
      "house",
      "night",
      "place",
      "real",
      "shelter",
      "sleep"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "night_sight_auto",
    "version": 248,
    "popularity": 324,
    "codepoint": 61911,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "a",
      "auto",
      "automatic",
      "camera",
      "dark",
      "feature",
      "moon",
      "night",
      "photo",
      "photography",
      "sight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "night_sight_auto_off",
    "version": 248,
    "popularity": 145,
    "codepoint": 61945,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "a",
      "auto",
      "automatic",
      "camera",
      "dark",
      "disabled",
      "enabled",
      "feature",
      "moon",
      "night",
      "off",
      "on",
      "photo",
      "photography",
      "sight",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "night_sight_max",
    "version": 248,
    "popularity": 18,
    "codepoint": 63171,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "+",
      "add",
      "auto",
      "automatic",
      "camera",
      "crescent",
      "dark",
      "enabled",
      "moon",
      "new",
      "nighttime",
      "photo",
      "photography",
      "picture",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nightlife",
    "version": 248,
    "popularity": 1167,
    "codepoint": 60002,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "dance",
      "drink",
      "food",
      "glass",
      "liquor",
      "music",
      "nightlife",
      "note",
      "wine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nightlife",
    "version": 10,
    "popularity": 5041,
    "codepoint": 60002,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alcohol",
      "bar",
      "bottle",
      "club",
      "cocktail",
      "dance",
      "drink",
      "food",
      "glass",
      "liquor",
      "music",
      "nightlife",
      "note",
      "wine"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "nightlight",
    "version": 248,
    "popularity": 1726,
    "codepoint": 61501,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "dark",
      "disturb",
      "mode",
      "moon",
      "night",
      "nightlight",
      "sleep"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nightlight",
    "version": 10,
    "popularity": 10507,
    "codepoint": 61501,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "dark",
      "disturb",
      "mode",
      "moon",
      "night",
      "nightlight",
      "sleep"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "nightlight_round",
    "version": 10,
    "popularity": 18782,
    "codepoint": 61278,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "dark",
      "half",
      "light",
      "mode",
      "moon",
      "night",
      "nightlight",
      "round"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "nights_stay",
    "version": 248,
    "popularity": 1111,
    "codepoint": 59974,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "climate",
      "cloud",
      "crescent",
      "dark",
      "lunar",
      "mode",
      "moon",
      "nights",
      "phases",
      "silence",
      "silent",
      "sky",
      "stay",
      "time",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nights_stay",
    "version": 12,
    "popularity": 8114,
    "codepoint": 59974,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "climate",
      "cloud",
      "crescent",
      "dark",
      "lunar",
      "mode",
      "moon",
      "nights",
      "phases",
      "silence",
      "silent",
      "sky",
      "stay",
      "time",
      "weather"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "no_accounts",
    "version": 248,
    "popularity": 1325,
    "codepoint": 61502,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "accounts",
      "avatar",
      "disabled",
      "enabled",
      "face",
      "human",
      "no",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "thumbnail",
      "unavailable",
      "unidentifiable",
      "unknown",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_accounts",
    "version": 10,
    "popularity": 10311,
    "codepoint": 61502,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "accounts",
      "avatar",
      "disabled",
      "enabled",
      "face",
      "human",
      "no",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "thumbnail",
      "unavailable",
      "unidentifiable",
      "unknown",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_adult_content",
    "version": 248,
    "popularity": 448,
    "codepoint": 63742,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_adult_content",
    "version": 1,
    "popularity": 598,
    "codepoint": 63742,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "no_backpack",
    "version": 248,
    "popularity": 187,
    "codepoint": 62007,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "accessory",
      "backpack",
      "bag",
      "bookbag",
      "knapsack",
      "no",
      "pack",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_backpack",
    "version": 6,
    "popularity": 840,
    "codepoint": 62007,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "accessory",
      "backpack",
      "bag",
      "bookbag",
      "knapsack",
      "no",
      "pack",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_cell",
    "version": 8,
    "popularity": 1073,
    "codepoint": 61860,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mobile",
      "no",
      "off",
      "on",
      "phone",
      "slash",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_crash",
    "version": 248,
    "popularity": 961,
    "codepoint": 60400,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "accident",
      "auto",
      "automobile",
      "car",
      "cars",
      "check",
      "collision",
      "confirm",
      "correct",
      "crash",
      "direction",
      "done",
      "enter",
      "maps",
      "mark",
      "no",
      "ok",
      "okay",
      "select",
      "tick",
      "transportation",
      "vehicle",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_crash",
    "version": 1,
    "popularity": 1432,
    "codepoint": 60400,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "accident",
      "auto",
      "automobile",
      "car",
      "cars",
      "check",
      "collision",
      "confirm",
      "correct",
      "crash",
      "direction",
      "done",
      "enter",
      "maps",
      "mark",
      "no",
      "ok",
      "okay",
      "select",
      "tick",
      "transportation",
      "vehicle",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "no_drinks",
    "version": 248,
    "popularity": 270,
    "codepoint": 61861,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "alcohol",
      "beverage",
      "bottle",
      "cocktail",
      "drink",
      "drinks",
      "food",
      "liquor",
      "no",
      "wine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_drinks",
    "version": 8,
    "popularity": 1386,
    "codepoint": 61861,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "alcohol",
      "beverage",
      "bottle",
      "cocktail",
      "drink",
      "drinks",
      "food",
      "liquor",
      "no",
      "wine"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_encryption",
    "version": 248,
    "popularity": 468,
    "codepoint": 58945,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "disabled",
      "enabled",
      "encryption",
      "lock",
      "no",
      "off",
      "on",
      "password",
      "safety",
      "security",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_encryption",
    "version": 17,
    "popularity": 2884,
    "codepoint": 58945,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "disabled",
      "enabled",
      "encryption",
      "lock",
      "no",
      "off",
      "on",
      "password",
      "safety",
      "security",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_encryption_gmailerrorred",
    "version": 10,
    "popularity": 1778,
    "codepoint": 61503,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "disabled",
      "enabled",
      "encryption",
      "error",
      "gmail",
      "lock",
      "locked",
      "no",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_flash",
    "version": 248,
    "popularity": 179,
    "codepoint": 61862,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "bolt",
      "camera",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "flash",
      "image",
      "instant",
      "lightning",
      "no",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_flash",
    "version": 8,
    "popularity": 917,
    "codepoint": 61862,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bolt",
      "camera",
      "disabled",
      "electric",
      "enabled",
      "energy",
      "flash",
      "image",
      "instant",
      "lightning",
      "no",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_food",
    "version": 248,
    "popularity": 415,
    "codepoint": 61863,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "disabled",
      "drink",
      "enabled",
      "fastfood",
      "food",
      "hamburger",
      "meal",
      "no",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_food",
    "version": 8,
    "popularity": 2276,
    "codepoint": 61863,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "disabled",
      "drink",
      "enabled",
      "fastfood",
      "food",
      "hamburger",
      "meal",
      "no",
      "off",
      "on",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_luggage",
    "version": 248,
    "popularity": 180,
    "codepoint": 62011,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bag",
      "baggage",
      "carry",
      "disabled",
      "enabled",
      "luggage",
      "no",
      "off",
      "on",
      "slash",
      "suitcase",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_luggage",
    "version": 7,
    "popularity": 1237,
    "codepoint": 62011,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bag",
      "baggage",
      "carry",
      "disabled",
      "enabled",
      "luggage",
      "no",
      "off",
      "on",
      "slash",
      "suitcase",
      "travel"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_meals",
    "version": 248,
    "popularity": 305,
    "codepoint": 61910,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "dining",
      "disabled",
      "eat",
      "enabled",
      "food",
      "fork",
      "knife",
      "meal",
      "meals",
      "no",
      "off",
      "on",
      "restaurant",
      "slash",
      "spoon",
      "utensils"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_meals",
    "version": 7,
    "popularity": 1624,
    "codepoint": 61910,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "dining",
      "disabled",
      "eat",
      "enabled",
      "food",
      "fork",
      "knife",
      "meal",
      "meals",
      "no",
      "off",
      "on",
      "restaurant",
      "slash",
      "spoon",
      "utensils"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_meals_ouline",
    "version": 6,
    "popularity": 159,
    "codepoint": 61993,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_meeting_room",
    "version": 248,
    "popularity": 280,
    "codepoint": 60238,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "building",
      "disabled",
      "door",
      "doorway",
      "enabled",
      "entrance",
      "home",
      "house",
      "interior",
      "meeting",
      "no",
      "off",
      "office",
      "on",
      "open",
      "places",
      "room",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_meeting_room",
    "version": 11,
    "popularity": 1641,
    "codepoint": 60238,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "building",
      "disabled",
      "door",
      "doorway",
      "enabled",
      "entrance",
      "home",
      "house",
      "interior",
      "meeting",
      "no",
      "off",
      "office",
      "on",
      "open",
      "places",
      "room",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_photography",
    "version": 248,
    "popularity": 784,
    "codepoint": 61864,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "disabled",
      "enabled",
      "image",
      "no",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_photography",
    "version": 8,
    "popularity": 4146,
    "codepoint": 61864,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "camera",
      "disabled",
      "enabled",
      "image",
      "no",
      "off",
      "on",
      "photo",
      "photography",
      "picture",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_sim",
    "version": 248,
    "popularity": 188,
    "codepoint": 57548,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "camera",
      "card",
      "device",
      "eject",
      "insert",
      "memory",
      "no",
      "phone",
      "sim",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_sim",
    "version": 12,
    "popularity": 1305,
    "codepoint": 57548,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "camera",
      "card",
      "device",
      "eject",
      "insert",
      "memory",
      "no",
      "phone",
      "sim",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_sound",
    "version": 248,
    "popularity": 42,
    "codepoint": 59152,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "Volume",
      "audio",
      "control",
      "error",
      "music",
      "off",
      "sound",
      "tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_stroller",
    "version": 248,
    "popularity": 122,
    "codepoint": 61871,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "disabled",
      "enabled",
      "infant",
      "kid",
      "newborn",
      "no",
      "off",
      "on",
      "parents",
      "slash",
      "stroller",
      "toddler",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_stroller",
    "version": 9,
    "popularity": 781,
    "codepoint": 61871,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "disabled",
      "enabled",
      "infant",
      "kid",
      "newborn",
      "no",
      "off",
      "on",
      "parents",
      "slash",
      "stroller",
      "toddler",
      "young"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "no_transfer",
    "version": 248,
    "popularity": 200,
    "codepoint": 61909,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bus",
      "car",
      "cars",
      "direction",
      "disabled",
      "enabled",
      "maps",
      "no",
      "off",
      "on",
      "public",
      "slash",
      "transfer",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "no_transfer",
    "version": 7,
    "popularity": 1198,
    "codepoint": 61909,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bus",
      "car",
      "cars",
      "direction",
      "disabled",
      "enabled",
      "maps",
      "no",
      "off",
      "on",
      "public",
      "slash",
      "transfer",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "noise_aware",
    "version": 248,
    "popularity": 321,
    "codepoint": 60396,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "audio",
      "aware",
      "cancellation",
      "music",
      "noise",
      "note",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "noise_aware",
    "version": 2,
    "popularity": 1909,
    "codepoint": 60396,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "audio",
      "aware",
      "cancellation",
      "music",
      "noise",
      "note",
      "sound"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "noise_control_off",
    "version": 248,
    "popularity": 457,
    "codepoint": 60403,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "audio",
      "aware",
      "cancel",
      "cancellation",
      "control",
      "disabled",
      "enabled",
      "music",
      "noise",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "noise_control_off",
    "version": 2,
    "popularity": 4880,
    "codepoint": 60403,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "audio",
      "aware",
      "cancel",
      "cancellation",
      "control",
      "disabled",
      "enabled",
      "music",
      "noise",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "noise_control_on",
    "version": 248,
    "popularity": 142,
    "codepoint": 63656,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "audio",
      "aware",
      "cancel",
      "cancellation",
      "control",
      "disabled",
      "enabled",
      "music",
      "noise",
      "note",
      "off",
      "on",
      "online",
      "slash",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nordic_walking",
    "version": 248,
    "popularity": 431,
    "codepoint": 58638,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hiking",
      "hobby",
      "human",
      "nordic",
      "people",
      "person",
      "social",
      "sports",
      "travel",
      "walker",
      "walking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "nordic_walking",
    "version": 4,
    "popularity": 2665,
    "codepoint": 58638,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hiking",
      "hobby",
      "human",
      "nordic",
      "people",
      "person",
      "social",
      "sports",
      "travel",
      "walker",
      "walking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "north",
    "version": 248,
    "popularity": 1707,
    "codepoint": 61920,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directional",
      "maps",
      "navigation",
      "north",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "north",
    "version": 7,
    "popularity": 16733,
    "codepoint": 61920,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directional",
      "maps",
      "navigation",
      "north",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "north_east",
    "version": 248,
    "popularity": 2869,
    "codepoint": 61921,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "east",
      "maps",
      "navigation",
      "noth",
      "right",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "north_east",
    "version": 7,
    "popularity": 16924,
    "codepoint": 61921,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "east",
      "maps",
      "navigation",
      "noth",
      "right",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "north_west",
    "version": 248,
    "popularity": 587,
    "codepoint": 61922,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directional",
      "left",
      "maps",
      "navigation",
      "north",
      "up",
      "west"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "north_west",
    "version": 7,
    "popularity": 5138,
    "codepoint": 61922,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directional",
      "left",
      "maps",
      "navigation",
      "north",
      "up",
      "west"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "not_accessible",
    "version": 248,
    "popularity": 390,
    "codepoint": 61694,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "not",
      "person",
      "wheelchair"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "not_accessible",
    "version": 13,
    "popularity": 2126,
    "codepoint": 61694,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "not",
      "person",
      "wheelchair"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "not_accessible_forward",
    "version": 248,
    "popularity": 29,
    "codepoint": 62794,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "disabled",
      "enabled",
      "forward",
      "handicap",
      "help",
      "human",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "slash",
      "wheelchair"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "not_interested",
    "version": 12,
    "popularity": 12944,
    "codepoint": 57395,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "dislike",
      "exit",
      "interested",
      "no",
      "not",
      "off",
      "quit",
      "remove",
      "stop",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "not_listed_location",
    "version": 248,
    "popularity": 1310,
    "codepoint": 58741,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "?",
      "assistance",
      "destination",
      "direction",
      "help",
      "info",
      "information",
      "listed",
      "location",
      "maps",
      "not",
      "pin",
      "place",
      "punctuation",
      "question mark",
      "stop",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "not_listed_location",
    "version": 18,
    "popularity": 6339,
    "codepoint": 58741,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "?",
      "assistance",
      "destination",
      "direction",
      "help",
      "info",
      "information",
      "listed",
      "location",
      "maps",
      "not",
      "pin",
      "place",
      "punctuation",
      "question mark",
      "stop",
      "support",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "not_started",
    "version": 248,
    "popularity": 1013,
    "codepoint": 61649,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "circle",
      "media",
      "not",
      "pause",
      "play",
      "started",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "not_started",
    "version": 13,
    "popularity": 9194,
    "codepoint": 61649,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "media",
      "not",
      "pause",
      "play",
      "started",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "note",
    "version": 248,
    "popularity": 2267,
    "codepoint": 57455,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "message",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "note",
    "version": 12,
    "popularity": 7634,
    "codepoint": 57455,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "message",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "note_add",
    "version": 248,
    "popularity": 4720,
    "codepoint": 59548,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "new",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "note_add",
    "version": 13,
    "popularity": 45989,
    "codepoint": 59548,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "new",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "note_alt",
    "version": 248,
    "popularity": 1973,
    "codepoint": 61504,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alt",
      "clipboard",
      "document",
      "file",
      "memo",
      "note",
      "page",
      "paper",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "note_alt",
    "version": 10,
    "popularity": 13135,
    "codepoint": 61504,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alt",
      "clipboard",
      "document",
      "file",
      "memo",
      "note",
      "page",
      "paper",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "note_stack",
    "version": 248,
    "popularity": 113,
    "codepoint": 62818,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bookmark",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "message",
      "more",
      "multiple",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "stack",
      "stacked",
      "symbol",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "note_stack_add",
    "version": 248,
    "popularity": 98,
    "codepoint": 62819,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "new",
      "note",
      "page",
      "paper",
      "plus",
      "sheet",
      "slide",
      "symbol",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notes",
    "version": 248,
    "popularity": 1703,
    "codepoint": 57964,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "comment",
      "doc",
      "document",
      "note",
      "notes",
      "text",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notes",
    "version": 12,
    "popularity": 15671,
    "codepoint": 57964,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "comment",
      "doc",
      "document",
      "note",
      "notes",
      "text",
      "write",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "notification_add",
    "version": 248,
    "popularity": 1388,
    "codepoint": 58265,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "active",
      "add",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notification",
      "notifications",
      "notify",
      "plus",
      "reminder",
      "ring",
      "sound",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notification_add",
    "version": 5,
    "popularity": 7094,
    "codepoint": 58265,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "active",
      "add",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notification",
      "notifications",
      "notify",
      "plus",
      "reminder",
      "ring",
      "sound",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "notification_important",
    "version": 248,
    "popularity": 3414,
    "codepoint": 57348,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "active",
      "alarm",
      "alert",
      "attention",
      "bell",
      "caution",
      "chime",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notification_important",
    "version": 12,
    "popularity": 0,
    "codepoint": 57348,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "alert"
    ],
    "tags": [
      "!",
      "active",
      "alarm",
      "alert",
      "attention",
      "bell",
      "caution",
      "chime",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "notification_multiple",
    "version": 248,
    "popularity": 25,
    "codepoint": 59074,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "approve",
      "check",
      "document",
      "email",
      "file",
      "letter",
      "mail",
      "multiple",
      "notification",
      "open",
      "read",
      "select",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notifications",
    "version": 248,
    "popularity": 34469,
    "codepoint": 59380,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notifications",
    "version": 18,
    "popularity": 195198,
    "codepoint": 59380,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "notifications_active",
    "version": 248,
    "popularity": 8662,
    "codepoint": 59383,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "ringing",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notifications_active",
    "version": 13,
    "popularity": 48326,
    "codepoint": 59383,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "ringing",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "notifications_none",
    "version": 12,
    "popularity": 31667,
    "codepoint": 59381,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "none",
      "notifications",
      "notify",
      "reminder",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "notifications_off",
    "version": 248,
    "popularity": 1726,
    "codepoint": 59382,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "disabled",
      "enabled",
      "notifications",
      "notify",
      "off",
      "offline",
      "on",
      "reminder",
      "ring",
      "slash",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notifications_off",
    "version": 12,
    "popularity": 10683,
    "codepoint": 59382,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "disabled",
      "enabled",
      "notifications",
      "notify",
      "off",
      "offline",
      "on",
      "reminder",
      "ring",
      "slash",
      "sound"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "notifications_paused",
    "version": 248,
    "popularity": 552,
    "codepoint": 59384,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "ignore",
      "notifications",
      "notify",
      "paused",
      "quiet",
      "reminder",
      "ring --- pause",
      "sleep",
      "snooze",
      "sound",
      "z",
      "zzz"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "notifications_paused",
    "version": 12,
    "popularity": 3290,
    "codepoint": 59384,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "ignore",
      "notifications",
      "notify",
      "paused",
      "quiet",
      "reminder",
      "ring --- pause",
      "sleep",
      "snooze",
      "sound",
      "z",
      "zzz"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "notifications_unread",
    "version": 248,
    "popularity": 15,
    "codepoint": 62718,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "dot",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "sound",
      "unread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "numbers",
    "version": 248,
    "popularity": 714,
    "codepoint": 60103,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "digit",
      "number",
      "numbers",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "numbers",
    "version": 2,
    "popularity": 4156,
    "codepoint": 60103,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "digit",
      "number",
      "numbers",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "nutrition",
    "version": 248,
    "popularity": 81,
    "codepoint": 57616,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "apple",
      "food",
      "fruit",
      "health",
      "orange",
      "produce",
      "wellness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ods",
    "version": 248,
    "popularity": 20,
    "codepoint": 59112,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "analytics",
      "data",
      "google",
      "infrastructure",
      "ods",
      "operations",
      "science"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "odt",
    "version": 248,
    "popularity": 9,
    "codepoint": 59113,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chip",
      "die",
      "odt",
      "on",
      "semiconductor",
      "technology",
      "termination"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "offline_bolt",
    "version": 248,
    "popularity": 1354,
    "codepoint": 59698,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "bolt",
      "circle",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "offline",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "offline_bolt",
    "version": 18,
    "popularity": 13254,
    "codepoint": 59698,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bolt",
      "circle",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "offline",
      "thunderbolt"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "offline_pin",
    "version": 248,
    "popularity": 804,
    "codepoint": 59658,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "check",
      "checkmark",
      "circle",
      "complete",
      "done",
      "mark",
      "offline",
      "ok",
      "pin",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "offline_pin",
    "version": 18,
    "popularity": 0,
    "codepoint": 59658,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "checkmark",
      "circle",
      "complete",
      "done",
      "mark",
      "offline",
      "ok",
      "pin",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "offline_share",
    "version": 248,
    "popularity": 248,
    "codepoint": 59845,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "cell",
      "connect",
      "device",
      "direction",
      "hardware",
      "iOS",
      "link",
      "mobile",
      "multiple",
      "offline",
      "phone",
      "right",
      "share",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "offline_share",
    "version": 12,
    "popularity": 2912,
    "codepoint": 59845,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "cell",
      "connect",
      "device",
      "direction",
      "hardware",
      "iOS",
      "link",
      "mobile",
      "multiple",
      "offline",
      "phone",
      "right",
      "share",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "oil_barrel",
    "version": 248,
    "popularity": 956,
    "codepoint": 60437,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "barrel",
      "droplet",
      "gas",
      "gasoline",
      "nest",
      "oil",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "oil_barrel",
    "version": 1,
    "popularity": 1974,
    "codepoint": 60437,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "barrel",
      "droplet",
      "gas",
      "gasoline",
      "nest",
      "oil",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "on_device_training",
    "version": 248,
    "popularity": 667,
    "codepoint": 60413,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "bulb",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "idea",
      "inprogress",
      "light",
      "load",
      "loading",
      "mobile",
      "model",
      "phone",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "telephone",
      "training"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "on_device_training",
    "version": 1,
    "popularity": 1298,
    "codepoint": 60413,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "bulb",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "idea",
      "inprogress",
      "light",
      "load",
      "loading",
      "mobile",
      "model",
      "phone",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "telephone",
      "training"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "on_hub_device",
    "version": 248,
    "popularity": 8,
    "codepoint": 59075,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "device",
      "hardware",
      "hub",
      "internet",
      "on",
      "router",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "oncology",
    "version": 248,
    "popularity": 5,
    "codepoint": 57620,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "digest",
      "digestive",
      "find",
      "gastro",
      "gi",
      "glass",
      "gut",
      "guts",
      "health",
      "human",
      "intestine",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see",
      "stomach",
      "tract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ondemand_video",
    "version": 11,
    "popularity": 16138,
    "codepoint": 58938,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "demand",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "ondemand",
      "play",
      "television",
      "tv",
      "video",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "online_prediction",
    "version": 248,
    "popularity": 920,
    "codepoint": 61675,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "bulb",
      "connection",
      "idea",
      "light",
      "network",
      "online",
      "prediction",
      "signal",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "online_prediction",
    "version": 12,
    "popularity": 7472,
    "codepoint": 61675,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bulb",
      "connection",
      "idea",
      "light",
      "network",
      "online",
      "prediction",
      "signal",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "onsen",
    "version": 248,
    "popularity": 24,
    "codepoint": 63224,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bathe",
      "bathhouse",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "spa",
      "steam",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "opacity",
    "version": 248,
    "popularity": 927,
    "codepoint": 59676,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "color",
      "drop",
      "droplet",
      "hue",
      "invert",
      "inverted",
      "opacity",
      "palette",
      "tone",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "opacity",
    "version": 15,
    "popularity": 10358,
    "codepoint": 59676,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "color",
      "drop",
      "droplet",
      "hue",
      "invert",
      "inverted",
      "opacity",
      "palette",
      "tone",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "open_in_browser",
    "version": 248,
    "popularity": 1409,
    "codepoint": 59549,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "browser",
      "in",
      "open",
      "site",
      "up",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_in_browser",
    "version": 12,
    "popularity": 10323,
    "codepoint": 59549,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "browser",
      "in",
      "open",
      "site",
      "up",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "open_in_full",
    "version": 248,
    "popularity": 8018,
    "codepoint": 61902,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "action",
      "arrow",
      "arrows",
      "expand",
      "full",
      "grow",
      "in",
      "move",
      "open"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_in_full",
    "version": 7,
    "popularity": 38379,
    "codepoint": 61902,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "action",
      "arrow",
      "arrows",
      "expand",
      "full",
      "grow",
      "in",
      "move",
      "open"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "open_in_new",
    "version": 248,
    "popularity": 23316,
    "codepoint": 59550,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "box",
      "components",
      "in",
      "interface",
      "new",
      "open",
      "right",
      "screen",
      "site",
      "ui",
      "up",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_in_new",
    "version": 21,
    "popularity": 96293,
    "codepoint": 59550,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "box",
      "components",
      "in",
      "interface",
      "new",
      "open",
      "right",
      "screen",
      "site",
      "ui",
      "up",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "open_in_new_down",
    "version": 248,
    "popularity": 17,
    "codepoint": 63247,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "box",
      "components",
      "in",
      "interface",
      "new",
      "open",
      "out",
      "right",
      "screen",
      "site",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_in_new_off",
    "version": 248,
    "popularity": 669,
    "codepoint": 58614,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "box",
      "disabled",
      "enabled",
      "export",
      "in",
      "new",
      "off",
      "on",
      "open",
      "slash",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_in_new_off",
    "version": 4,
    "popularity": 2712,
    "codepoint": 58614,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "box",
      "disabled",
      "enabled",
      "export",
      "in",
      "new",
      "off",
      "on",
      "open",
      "slash",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "open_in_phone",
    "version": 248,
    "popularity": 171,
    "codepoint": 59138,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "arrows",
      "cell",
      "device",
      "direction",
      "hardware",
      "iOS",
      "in",
      "mobile",
      "open",
      "phone",
      "right",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_jam",
    "version": 248,
    "popularity": 10,
    "codepoint": 61358,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "arrows",
      "chrome",
      "desktop",
      "device",
      "direction",
      "hardware",
      "iOS",
      "jam",
      "mac",
      "monitor",
      "open",
      "up",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_with",
    "version": 248,
    "popularity": 4047,
    "codepoint": 59551,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "expand",
      "move",
      "open",
      "pan",
      "with"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "open_with",
    "version": 12,
    "popularity": 16093,
    "codepoint": 59551,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "expand",
      "move",
      "open",
      "pan",
      "with"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "ophthalmology",
    "version": 248,
    "popularity": 6,
    "codepoint": 57621,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "eye",
      "eyes",
      "health",
      "human"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "oral_disease",
    "version": 248,
    "popularity": 9,
    "codepoint": 57622,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "dentist",
      "dentistry",
      "health",
      "teeth"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "order_approve",
    "version": 248,
    "popularity": 47,
    "codepoint": 63506,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "order_play",
    "version": 248,
    "popularity": 5,
    "codepoint": 63505,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "doc",
      "document",
      "file",
      "health",
      "orders",
      "page",
      "paper",
      "play",
      "receipt",
      "receipts"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "orders",
    "version": 248,
    "popularity": 117,
    "codepoint": 60180,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "box",
      "delivery",
      "mail",
      "open",
      "orders",
      "packaged"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "orthopedics",
    "version": 248,
    "popularity": 226,
    "codepoint": 63639,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bone",
      "bones",
      "health",
      "medical",
      "spine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "other_admission",
    "version": 248,
    "popularity": 10,
    "codepoint": 58491,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clinical",
      "data",
      "doc",
      "document",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "pend",
      "pending",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "other_houses",
    "version": 248,
    "popularity": 1985,
    "codepoint": 58764,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "cottage",
      "estate",
      "home",
      "house",
      "houses",
      "maps",
      "other",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "other_houses",
    "version": 4,
    "popularity": 12516,
    "codepoint": 58764,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "cottage",
      "estate",
      "home",
      "house",
      "houses",
      "maps",
      "other",
      "place",
      "real",
      "residence",
      "residential",
      "stay",
      "traveling"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "outbound",
    "version": 248,
    "popularity": 1002,
    "codepoint": 57802,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "directional",
      "outbound",
      "right",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outbound",
    "version": 8,
    "popularity": 5093,
    "codepoint": 57802,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "circle",
      "directional",
      "outbound",
      "right",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "outbox",
    "version": 248,
    "popularity": 601,
    "codepoint": 61279,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "box",
      "mail",
      "outbox",
      "send",
      "sent"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outbox",
    "version": 10,
    "popularity": 6246,
    "codepoint": 61279,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "box",
      "mail",
      "outbox",
      "send",
      "sent"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "outbox_alt",
    "version": 248,
    "popularity": 10,
    "codepoint": 60183,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "communicate",
      "communication",
      "email",
      "mail",
      "message",
      "outbox",
      "send",
      "sending",
      "talk"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outdoor_garden",
    "version": 248,
    "popularity": 610,
    "codepoint": 57861,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "backyard",
      "barrier",
      "boundaries",
      "boundary",
      "door",
      "entrance",
      "fence",
      "flowers",
      "garden",
      "gate",
      "grass",
      "home",
      "house",
      "nature",
      "nest",
      "outdoor",
      "outside",
      "protection",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outdoor_grill",
    "version": 248,
    "popularity": 919,
    "codepoint": 59975,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "barbecue",
      "bbq",
      "charcoal",
      "cook",
      "cooking",
      "grill",
      "home",
      "house",
      "outdoor",
      "outside"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outdoor_grill",
    "version": 11,
    "popularity": 5511,
    "codepoint": 59975,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "barbecue",
      "bbq",
      "charcoal",
      "cook",
      "cooking",
      "grill",
      "home",
      "house",
      "outdoor",
      "outside"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "outgoing_mail",
    "version": 248,
    "popularity": 2625,
    "codepoint": 61650,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "arrows",
      "directions",
      "email",
      "envelop",
      "forward",
      "inbox",
      "letter",
      "mail",
      "message",
      "navigation",
      "outgoing",
      "right",
      "send",
      "to"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outgoing_mail",
    "version": 11,
    "popularity": 3513,
    "codepoint": 61650,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "directions",
      "email",
      "envelop",
      "forward",
      "inbox",
      "letter",
      "mail",
      "message",
      "navigation",
      "outgoing",
      "right",
      "send",
      "to"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "outlet",
    "version": 248,
    "popularity": 452,
    "codepoint": 61908,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "connect",
      "connecter",
      "electricity",
      "outlet",
      "plug",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outlet",
    "version": 7,
    "popularity": 5874,
    "codepoint": 61908,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "connect",
      "connecter",
      "electricity",
      "outlet",
      "plug",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "outlined_flag",
    "version": 12,
    "popularity": 15211,
    "codepoint": 57710,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "country",
      "flag",
      "goal",
      "mark",
      "nation",
      "outlined",
      "report",
      "start"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "outpatient",
    "version": 248,
    "popularity": 6,
    "codepoint": 57624,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "clinical",
      "data",
      "doc",
      "document",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "pend",
      "pending",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "outpatient_med",
    "version": 248,
    "popularity": 2,
    "codepoint": 57625,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "clinic",
      "clinical",
      "health",
      "medical",
      "medicine",
      "out",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "output",
    "version": 248,
    "popularity": 1883,
    "codepoint": 60350,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "output",
    "version": 1,
    "popularity": 4188,
    "codepoint": 60350,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "output_circle",
    "version": 248,
    "popularity": 13,
    "codepoint": 63246,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "install",
      "navigation",
      "out",
      "outside",
      "south"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "oven",
    "version": 248,
    "popularity": 13,
    "codepoint": 59847,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "baking",
      "cook",
      "cooker",
      "cooking",
      "food",
      "furnace",
      "heet",
      "home",
      "hot",
      "house",
      "kitchen",
      "oven",
      "store"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "oven_gen",
    "version": 248,
    "popularity": 543,
    "codepoint": 59459,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "cook",
      "cooking",
      "electric",
      "home",
      "house",
      "kitchen",
      "machine",
      "nest",
      "oven"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "overview",
    "version": 248,
    "popularity": 91,
    "codepoint": 58535,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clinical",
      "clock",
      "date",
      "doc",
      "document",
      "file",
      "health",
      "note",
      "page",
      "paper",
      "schedule",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "overview_key",
    "version": 248,
    "popularity": 41,
    "codepoint": 63444,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "chrome",
      "chromebook",
      "display",
      "function",
      "layout",
      "window",
      "windows",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "oxygen_saturation",
    "version": 248,
    "popularity": 10,
    "codepoint": 58590,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "health",
      "human",
      "medical",
      "nose",
      "nostril"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "p2p",
    "version": 248,
    "popularity": 2,
    "codepoint": 62762,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "device",
      "dots",
      "hardware",
      "mobile",
      "network",
      "new",
      "p2p",
      "peer",
      "peer to peer",
      "peer-to-peer",
      "phone",
      "plus",
      "send",
      "sending",
      "share",
      "sharing",
      "symbol",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pace",
    "version": 248,
    "popularity": 47,
    "codepoint": 63160,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "clock",
      "fitness",
      "rate",
      "speed",
      "stopwatch",
      "time",
      "timer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pacemaker",
    "version": 248,
    "popularity": 7,
    "codepoint": 58966,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "cardio",
      "health",
      "heart",
      "medical",
      "monitor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "package",
    "version": 248,
    "popularity": 1781,
    "codepoint": 58511,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "box",
      "boxes",
      "delivery",
      "mail",
      "package",
      "packages",
      "parcel",
      "post",
      "postal",
      "send",
      "shipment",
      "shipping",
      "stamp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "package_2",
    "version": 248,
    "popularity": 48,
    "codepoint": 62825,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "box",
      "boxes",
      "delivery",
      "mail",
      "package",
      "packages",
      "parcel",
      "post",
      "postal",
      "send",
      "shipment",
      "shipping",
      "stamp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "padding",
    "version": 248,
    "popularity": 162,
    "codepoint": 59848,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "dots",
      "layout",
      "margin",
      "padding",
      "size",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "padding",
    "version": 11,
    "popularity": 1357,
    "codepoint": 59848,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "design",
      "dots",
      "layout",
      "margin",
      "padding",
      "size",
      "square"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "page_control",
    "version": 248,
    "popularity": 36,
    "codepoint": 59185,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "circles",
      "content",
      "control",
      "dots",
      "media",
      "more",
      "page",
      "scroll",
      "steppers",
      "swipe",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "page_info",
    "version": 248,
    "popularity": 91,
    "codepoint": 62996,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "chrome",
      "controls",
      "details",
      "filters",
      "information",
      "list",
      "options",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pageless",
    "version": 248,
    "popularity": 6,
    "codepoint": 62729,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "adjust",
      "adjustments",
      "area",
      "crop",
      "edit",
      "editing",
      "format",
      "frame",
      "image",
      "images",
      "pages",
      "photo",
      "photos",
      "rectangle",
      "settings",
      "size"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pages",
    "version": 248,
    "popularity": 739,
    "codepoint": 59385,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "gplus",
      "pages",
      "paper",
      "post",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pages",
    "version": 11,
    "popularity": 3877,
    "codepoint": 59385,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "article",
      "gplus",
      "pages",
      "paper",
      "post",
      "star"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pageview",
    "version": 248,
    "popularity": 1873,
    "codepoint": 59552,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "doc",
      "document",
      "find",
      "glass",
      "magnifying",
      "page",
      "paper",
      "search",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pageview",
    "version": 12,
    "popularity": 14962,
    "codepoint": 59552,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "doc",
      "document",
      "find",
      "glass",
      "magnifying",
      "page",
      "paper",
      "search",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "paid",
    "version": 248,
    "popularity": 10454,
    "codepoint": 61505,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "circle",
      "currency",
      "money",
      "paid",
      "payment",
      "transaction"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "paid",
    "version": 9,
    "popularity": 105915,
    "codepoint": 61505,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "currency",
      "money",
      "paid",
      "payment",
      "transaction"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "palette",
    "version": 248,
    "popularity": 7182,
    "codepoint": 58378,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "art",
      "color",
      "colors",
      "filters",
      "paint",
      "palette"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "palette",
    "version": 16,
    "popularity": 32808,
    "codepoint": 58378,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "art",
      "color",
      "colors",
      "filters",
      "paint",
      "palette"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pallet",
    "version": 248,
    "popularity": 12,
    "codepoint": 63594,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "box",
      "construction",
      "factory",
      "manufactory",
      "storage",
      "transport"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pallet",
    "version": 1,
    "popularity": 1754,
    "codepoint": 63594,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "box",
      "construction",
      "factory",
      "manufactory",
      "storage",
      "transport"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pan_tool",
    "version": 248,
    "popularity": 2430,
    "codepoint": 59685,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "fingers",
      "gesture",
      "hand",
      "hands",
      "human",
      "move",
      "pan",
      "scan",
      "stop",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pan_tool",
    "version": 14,
    "popularity": 28752,
    "codepoint": 59685,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "fingers",
      "gesture",
      "hand",
      "hands",
      "human",
      "move",
      "pan",
      "scan",
      "stop",
      "tool"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pan_tool_alt",
    "version": 248,
    "popularity": 3182,
    "codepoint": 60345,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "fingers",
      "gesture",
      "hand",
      "hands",
      "human",
      "move",
      "pan",
      "scan",
      "stop",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pan_tool_alt",
    "version": 1,
    "popularity": 4906,
    "codepoint": 60345,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "fingers",
      "gesture",
      "hand",
      "hands",
      "human",
      "move",
      "pan",
      "scan",
      "stop",
      "tool"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pan_zoom",
    "version": 248,
    "popularity": 49,
    "codepoint": 63061,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "expand",
      "fill",
      "out",
      "outward",
      "stretch",
      "zooms"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "panorama",
    "version": 248,
    "popularity": 767,
    "codepoint": 58379,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "image",
      "mountain",
      "mountains",
      "panorama",
      "photo",
      "photography",
      "picture",
      "view",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "panorama",
    "version": 17,
    "popularity": 4302,
    "codepoint": 58379,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "image",
      "mountain",
      "mountains",
      "panorama",
      "photo",
      "photography",
      "picture",
      "view",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_fish_eye",
    "version": 248,
    "popularity": 611,
    "codepoint": 58380,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "circle",
      "eye",
      "fish",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "panorama_fish_eye",
    "version": 13,
    "popularity": 8908,
    "codepoint": 58380,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "circle",
      "eye",
      "fish",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_horizontal",
    "version": 248,
    "popularity": 238,
    "codepoint": 58381,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "panorama_horizontal",
    "version": 12,
    "popularity": 1082,
    "codepoint": 58381,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_horizontal_select",
    "version": 11,
    "popularity": 829,
    "codepoint": 61280,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "select",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_photosphere",
    "version": 248,
    "popularity": 224,
    "codepoint": 59849,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "photosphere",
      "picture",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "panorama_photosphere",
    "version": 11,
    "popularity": 1265,
    "codepoint": 59849,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "photosphere",
      "picture",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_photosphere_select",
    "version": 12,
    "popularity": 821,
    "codepoint": 59850,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "horizontal",
      "image",
      "panorama",
      "photo",
      "photography",
      "photosphere",
      "picture",
      "select",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_vertical",
    "version": 248,
    "popularity": 146,
    "codepoint": 58382,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "vertical",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "panorama_vertical",
    "version": 13,
    "popularity": 741,
    "codepoint": 58382,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "vertical",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_vertical_select",
    "version": 12,
    "popularity": 804,
    "codepoint": 61281,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "select",
      "vertical",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_wide_angle",
    "version": 248,
    "popularity": 133,
    "codepoint": 58383,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "panorama_wide_angle",
    "version": 14,
    "popularity": 812,
    "codepoint": 58383,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "panorama_wide_angle_select",
    "version": 12,
    "popularity": 987,
    "codepoint": 61282,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "image",
      "panorama",
      "photo",
      "photography",
      "picture",
      "select",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "paragliding",
    "version": 248,
    "popularity": 370,
    "codepoint": 58639,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "fly",
      "gliding",
      "hobby",
      "human",
      "parachute",
      "paragliding",
      "people",
      "person",
      "sky",
      "skydiving",
      "social",
      "sports",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "paragliding",
    "version": 4,
    "popularity": 2489,
    "codepoint": 58639,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "fly",
      "gliding",
      "hobby",
      "human",
      "parachute",
      "paragliding",
      "people",
      "person",
      "sky",
      "skydiving",
      "social",
      "sports",
      "travel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "park",
    "version": 248,
    "popularity": 1930,
    "codepoint": 60003,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "attraction",
      "fresh",
      "local",
      "nature",
      "outside",
      "park",
      "plant",
      "tree"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "park",
    "version": 10,
    "popularity": 18041,
    "codepoint": 60003,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "attraction",
      "fresh",
      "local",
      "nature",
      "outside",
      "park",
      "plant",
      "tree"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "partly_cloudy_day",
    "version": 248,
    "popularity": 1836,
    "codepoint": 61810,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "sun",
      "sunny",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "partly_cloudy_night",
    "version": 248,
    "popularity": 609,
    "codepoint": 61812,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "lunar",
      "moon",
      "night",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "partner_exchange",
    "version": 248,
    "popularity": 414,
    "codepoint": 63481,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "collaborate",
      "collaboration",
      "couple",
      "diamond",
      "gem",
      "greeting",
      "group",
      "handshake",
      "human",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "partner_reports",
    "version": 248,
    "popularity": 27,
    "codepoint": 61359,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bracket",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "partner",
      "reports",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "party_mode",
    "version": 248,
    "popularity": 275,
    "codepoint": 59386,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "lens",
      "mode",
      "party",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "party_mode",
    "version": 12,
    "popularity": 2397,
    "codepoint": 59386,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "camera",
      "lens",
      "mode",
      "party",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "passkey",
    "version": 248,
    "popularity": 442,
    "codepoint": 63615,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "access",
      "body",
      "entry",
      "human",
      "key",
      "login",
      "pass",
      "password",
      "people",
      "person",
      "pin",
      "security",
      "unlock"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "password",
    "version": 248,
    "popularity": 4926,
    "codepoint": 61506,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "key",
      "login",
      "password",
      "pin",
      "security",
      "star",
      "unlock"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "password",
    "version": 10,
    "popularity": 36670,
    "codepoint": 61506,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "key",
      "login",
      "password",
      "pin",
      "security",
      "star",
      "unlock"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "patient_list",
    "version": 248,
    "popularity": 45,
    "codepoint": 58963,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "body",
      "health",
      "human",
      "list",
      "lists",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pattern",
    "version": 248,
    "popularity": 511,
    "codepoint": 61507,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "key",
      "login",
      "password",
      "pattern",
      "pin",
      "security",
      "star",
      "unlock"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pattern",
    "version": 11,
    "popularity": 3386,
    "codepoint": 61507,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "key",
      "login",
      "password",
      "pattern",
      "pin",
      "security",
      "star",
      "unlock"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pause",
    "version": 248,
    "popularity": 8218,
    "codepoint": 57396,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "control",
      "controls",
      "media",
      "music",
      "pause",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pause",
    "version": 16,
    "popularity": 51555,
    "codepoint": 57396,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "control",
      "controls",
      "media",
      "music",
      "pause",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pause_circle",
    "version": 248,
    "popularity": 4181,
    "codepoint": 57762,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "pause",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pause_circle",
    "version": 10,
    "popularity": 14444,
    "codepoint": 57762,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "pause",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pause_circle_filled",
    "version": 15,
    "popularity": 7935,
    "codepoint": 57397,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "circle",
      "control",
      "controls",
      "filled",
      "media",
      "music",
      "pause",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pause_circle_outline",
    "version": 19,
    "popularity": 7820,
    "codepoint": 57398,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "outline",
      "pause",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pause_presentation",
    "version": 248,
    "popularity": 429,
    "codepoint": 57578,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "app",
      "application desktop",
      "device",
      "pause",
      "present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "web",
      "website",
      "window",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pause_presentation",
    "version": 12,
    "popularity": 1956,
    "codepoint": 57578,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "app",
      "application desktop",
      "device",
      "pause",
      "present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "web",
      "website",
      "window",
      "www"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "payment",
    "version": 12,
    "popularity": 43232,
    "codepoint": 59553,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "payments",
    "version": 248,
    "popularity": 19929,
    "codepoint": 61283,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "layer",
      "money",
      "multiple",
      "online",
      "pay",
      "payment",
      "payments",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "payments",
    "version": 13,
    "popularity": 68759,
    "codepoint": 61283,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "layer",
      "money",
      "multiple",
      "online",
      "pay",
      "payment",
      "payments",
      "price",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pedal_bike",
    "version": 248,
    "popularity": 2192,
    "codepoint": 60201,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bicycle",
      "bike",
      "car",
      "cars",
      "direction",
      "human",
      "maps",
      "pedal",
      "public",
      "route",
      "scooter",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pedal_bike",
    "version": 14,
    "popularity": 9002,
    "codepoint": 60201,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bicycle",
      "bike",
      "car",
      "cars",
      "direction",
      "human",
      "maps",
      "pedal",
      "public",
      "route",
      "scooter",
      "transportation",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pediatrics",
    "version": 248,
    "popularity": 5,
    "codepoint": 57629,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "baby",
      "bottle",
      "health",
      "infant",
      "medical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pen_size_1",
    "version": 248,
    "popularity": 0,
    "codepoint": 63317,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pen_size_2",
    "version": 248,
    "popularity": 3,
    "codepoint": 63316,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pen_size_3",
    "version": 248,
    "popularity": 2,
    "codepoint": 63315,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pen_size_4",
    "version": 248,
    "popularity": 1,
    "codepoint": 63314,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pen_size_5",
    "version": 248,
    "popularity": 5,
    "codepoint": 63313,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "edit",
      "format",
      "line weight",
      "pencil",
      "stroke",
      "strokes",
      "thickness",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pending",
    "version": 248,
    "popularity": 5556,
    "codepoint": 61284,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "circle",
      "dots",
      "loading",
      "pending",
      "progress",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pending",
    "version": 17,
    "popularity": 41177,
    "codepoint": 61284,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "circle",
      "dots",
      "loading",
      "pending",
      "progress",
      "wait",
      "waiting"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pending_actions",
    "version": 248,
    "popularity": 4262,
    "codepoint": 61883,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "actions",
      "clipboard",
      "clock",
      "date",
      "doc",
      "document",
      "pending",
      "remember",
      "schedule",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pending_actions",
    "version": 7,
    "popularity": 44820,
    "codepoint": 61883,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "actions",
      "clipboard",
      "clock",
      "date",
      "doc",
      "document",
      "pending",
      "remember",
      "schedule",
      "time"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pentagon",
    "version": 248,
    "popularity": 419,
    "codepoint": 60240,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "five sides",
      "pentagon",
      "shape"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pentagon",
    "version": 1,
    "popularity": 1688,
    "codepoint": 60240,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "five sides",
      "pentagon",
      "shape"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "people",
    "version": 23,
    "popularity": 128766,
    "codepoint": 59387,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "people_alt",
    "version": 21,
    "popularity": 43130,
    "codepoint": 59937,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "humans",
      "network",
      "people",
      "persons",
      "profiles",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "people_outline",
    "version": 12,
    "popularity": 14292,
    "codepoint": 59388,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accounts",
      "committee",
      "face",
      "family",
      "friends",
      "humans",
      "network",
      "outline",
      "people",
      "persons",
      "profiles",
      "social",
      "team",
      "users"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "percent",
    "version": 248,
    "popularity": 3116,
    "codepoint": 60248,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "math",
      "number",
      "percent",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "percent",
    "version": 1,
    "popularity": 14043,
    "codepoint": 60248,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "math",
      "number",
      "percent",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pergola",
    "version": 248,
    "popularity": 161,
    "codepoint": 57859,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "ceiling",
      "cover",
      "garden",
      "nest",
      "outdoor",
      "pergola",
      "roof"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_camera_mic",
    "version": 248,
    "popularity": 161,
    "codepoint": 59554,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "image",
      "microphone",
      "min",
      "perm",
      "photo",
      "photography",
      "picture",
      "speaker"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_camera_mic",
    "version": 12,
    "popularity": 1962,
    "codepoint": 59554,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "camera",
      "image",
      "microphone",
      "min",
      "perm",
      "photo",
      "photography",
      "picture",
      "speaker"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "perm_contact_calendar",
    "version": 248,
    "popularity": 1687,
    "codepoint": 59555,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "calendar",
      "contact",
      "date",
      "face",
      "human",
      "information",
      "people",
      "perm",
      "person",
      "profile",
      "schedule",
      "time",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_contact_calendar",
    "version": 13,
    "popularity": 16526,
    "codepoint": 59555,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "calendar",
      "contact",
      "date",
      "face",
      "human",
      "information",
      "people",
      "perm",
      "person",
      "profile",
      "schedule",
      "time",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "perm_data_setting",
    "version": 248,
    "popularity": 245,
    "codepoint": 59556,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "data",
      "gear",
      "info",
      "information",
      "perm",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_data_setting",
    "version": 12,
    "popularity": 3687,
    "codepoint": 59556,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "data",
      "gear",
      "info",
      "information",
      "perm",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "perm_device_information",
    "version": 248,
    "popularity": 289,
    "codepoint": 59557,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "alert",
      "announcement",
      "device",
      "hardware",
      "i",
      "iOS",
      "info",
      "information",
      "mobile",
      "perm",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_device_information",
    "version": 13,
    "popularity": 3360,
    "codepoint": 59557,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "alert",
      "announcement",
      "device",
      "hardware",
      "i",
      "iOS",
      "info",
      "information",
      "mobile",
      "perm",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "perm_identity",
    "version": 12,
    "popularity": 87660,
    "codepoint": 59558,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "avatar",
      "face",
      "human",
      "identity",
      "people",
      "perm",
      "person",
      "profile",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "perm_media",
    "version": 248,
    "popularity": 1862,
    "codepoint": 59559,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "collection",
      "copy",
      "data",
      "doc",
      "document",
      "duplicate",
      "file",
      "folder",
      "folders",
      "image",
      "landscape",
      "media",
      "mountain",
      "mountains",
      "perm",
      "photo",
      "photography",
      "picture",
      "stack",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_media",
    "version": 15,
    "popularity": 17506,
    "codepoint": 59559,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "collection",
      "copy",
      "data",
      "doc",
      "document",
      "duplicate",
      "file",
      "folder",
      "folders",
      "image",
      "landscape",
      "media",
      "mountain",
      "mountains",
      "perm",
      "photo",
      "photography",
      "picture",
      "stack",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "perm_phone_msg",
    "version": 248,
    "popularity": 1917,
    "codepoint": 59560,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "call",
      "cell",
      "chat",
      "comment",
      "communicate",
      "contact",
      "device",
      "message",
      "msg",
      "perm",
      "phone",
      "recording",
      "speech",
      "telephone",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_phone_msg",
    "version": 12,
    "popularity": 15696,
    "codepoint": 59560,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bubble",
      "call",
      "cell",
      "chat",
      "comment",
      "communicate",
      "contact",
      "device",
      "message",
      "msg",
      "perm",
      "phone",
      "recording",
      "speech",
      "telephone",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "perm_scan_wifi",
    "version": 248,
    "popularity": 226,
    "codepoint": 59561,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alert",
      "announcement",
      "connection",
      "info",
      "information",
      "internet",
      "network",
      "perm",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "perm_scan_wifi",
    "version": 12,
    "popularity": 3633,
    "codepoint": 59561,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "announcement",
      "connection",
      "info",
      "information",
      "internet",
      "network",
      "perm",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "person",
    "version": 248,
    "popularity": 85984,
    "codepoint": 59389,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person",
    "version": 17,
    "popularity": 348590,
    "codepoint": 59389,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "person_2",
    "version": 248,
    "popularity": 893,
    "codepoint": 63716,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_2",
    "version": 1,
    "popularity": 1722,
    "codepoint": 63716,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_3",
    "version": 248,
    "popularity": 572,
    "codepoint": 63717,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_3",
    "version": 1,
    "popularity": 1135,
    "codepoint": 63717,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_4",
    "version": 248,
    "popularity": 656,
    "codepoint": 63718,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_4",
    "version": 1,
    "popularity": 1186,
    "codepoint": 63718,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_add",
    "version": 248,
    "popularity": 17282,
    "codepoint": 59390,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "+",
      "account",
      "add",
      "avatar",
      "face",
      "human",
      "new",
      "people",
      "person",
      "plus",
      "profile",
      "symbol",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_add",
    "version": 16,
    "popularity": 83217,
    "codepoint": 59390,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "account",
      "add",
      "avatar",
      "face",
      "human",
      "new",
      "people",
      "person",
      "plus",
      "profile",
      "symbol",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "person_add_alt",
    "version": 11,
    "popularity": 25690,
    "codepoint": 59981,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "+",
      "account",
      "add",
      "face",
      "human",
      "people",
      "person",
      "plus",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_add_alt_1",
    "version": 13,
    "popularity": 11987,
    "codepoint": 61285,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_add_disabled",
    "version": 248,
    "popularity": 452,
    "codepoint": 59851,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "account",
      "add",
      "disabled",
      "enabled",
      "face",
      "human",
      "new",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "plus",
      "profile",
      "slash",
      "symbol",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_add_disabled",
    "version": 11,
    "popularity": 2458,
    "codepoint": 59851,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "+",
      "account",
      "add",
      "disabled",
      "enabled",
      "face",
      "human",
      "new",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "plus",
      "profile",
      "slash",
      "symbol",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "person_alert",
    "version": 248,
    "popularity": 46,
    "codepoint": 62823,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "!",
      "account",
      "add",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "face",
      "feedback",
      "human",
      "important",
      "mark",
      "notification",
      "people",
      "person",
      "plus",
      "problem",
      "profile",
      "report",
      "symbol",
      "user",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_apron",
    "version": 248,
    "popularity": 18,
    "codepoint": 62883,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "employee",
      "face",
      "human",
      "people",
      "person",
      "profile",
      "user",
      "worker"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_book",
    "version": 248,
    "popularity": 6,
    "codepoint": 62952,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "account",
      "biography",
      "body",
      "book",
      "face",
      "human",
      "library",
      "memoir",
      "people",
      "person",
      "profile",
      "read",
      "reading",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_cancel",
    "version": 248,
    "popularity": 9,
    "codepoint": 62822,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "add",
      "cancel",
      "circle",
      "clear",
      "close",
      "exit",
      "face",
      "human",
      "people",
      "person",
      "plus",
      "profile",
      "stop",
      "user",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_celebrate",
    "version": 248,
    "popularity": 72,
    "codepoint": 63486,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "celebrate",
      "confetti",
      "festive",
      "happy",
      "human",
      "juggle",
      "people",
      "person",
      "play",
      "shapes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_check",
    "version": 248,
    "popularity": 26,
    "codepoint": 62821,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "add",
      "approve",
      "check",
      "checkmark",
      "complete",
      "done",
      "face",
      "human",
      "mark",
      "ok",
      "people",
      "person",
      "plus",
      "profile",
      "select",
      "tick",
      "user",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_edit",
    "version": 248,
    "popularity": 5,
    "codepoint": 62714,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "compose",
      "create",
      "edit",
      "editing",
      "face",
      "human",
      "input",
      "new",
      "pen",
      "pencil",
      "people",
      "person",
      "profile",
      "user",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_off",
    "version": 248,
    "popularity": 2070,
    "codepoint": 58640,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "avatar",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_off",
    "version": 4,
    "popularity": 13190,
    "codepoint": 58640,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "avatar",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "on",
      "people",
      "person",
      "profile",
      "slash",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_outline",
    "version": 19,
    "popularity": 92669,
    "codepoint": 59391,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "outline",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "person_pin",
    "version": 248,
    "popularity": 2453,
    "codepoint": 58714,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "account",
      "avatar",
      "destination",
      "direction",
      "face",
      "human",
      "location",
      "maps",
      "people",
      "person",
      "pin",
      "place",
      "profile",
      "stop",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_pin",
    "version": 13,
    "popularity": 16416,
    "codepoint": 58714,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "account",
      "avatar",
      "destination",
      "direction",
      "face",
      "human",
      "location",
      "maps",
      "people",
      "person",
      "pin",
      "place",
      "profile",
      "stop",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "person_pin_circle",
    "version": 248,
    "popularity": 5790,
    "codepoint": 58730,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "account",
      "circle",
      "destination",
      "direction",
      "face",
      "human",
      "location",
      "maps",
      "people",
      "person",
      "pin",
      "place",
      "profile",
      "stop",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_pin_circle",
    "version": 18,
    "popularity": 11490,
    "codepoint": 58730,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "account",
      "circle",
      "destination",
      "direction",
      "face",
      "human",
      "location",
      "maps",
      "people",
      "person",
      "pin",
      "place",
      "profile",
      "stop",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "person_play",
    "version": 248,
    "popularity": 63,
    "codepoint": 63485,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "celebrate",
      "confetti",
      "festive",
      "happy",
      "human",
      "juggle",
      "people",
      "person",
      "play",
      "shapes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_raised_hand",
    "version": 248,
    "popularity": 135,
    "codepoint": 62874,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "help",
      "human",
      "people",
      "question",
      "raise"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_remove",
    "version": 248,
    "popularity": 2868,
    "codepoint": 61286,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "account",
      "avatar",
      "delete",
      "face",
      "human",
      "minus",
      "people",
      "person",
      "profile",
      "remove",
      "unfriend",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_remove",
    "version": 12,
    "popularity": 17243,
    "codepoint": 61286,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "avatar",
      "delete",
      "face",
      "human",
      "minus",
      "people",
      "person",
      "profile",
      "remove",
      "unfriend",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_remove_alt_1",
    "version": 13,
    "popularity": 3180,
    "codepoint": 61287,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "person_search",
    "version": 248,
    "popularity": 5265,
    "codepoint": 61702,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "account",
      "avatar",
      "face",
      "find",
      "glass",
      "human",
      "look",
      "magnify",
      "magnifying",
      "people",
      "person",
      "profile",
      "search",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "person_search",
    "version": 12,
    "popularity": 28328,
    "codepoint": 61702,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "account",
      "avatar",
      "face",
      "find",
      "glass",
      "human",
      "look",
      "magnify",
      "magnifying",
      "people",
      "person",
      "profile",
      "search",
      "user"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "personal_bag",
    "version": 248,
    "popularity": 17,
    "codepoint": 60174,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "pack",
      "personal",
      "storage",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "personal_bag_off",
    "version": 248,
    "popularity": 1,
    "codepoint": 60175,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "disabled",
      "enabled",
      "off",
      "on",
      "pack",
      "personal",
      "slash",
      "storage",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "personal_bag_question",
    "version": 248,
    "popularity": 11,
    "codepoint": 60176,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "?",
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "help",
      "info",
      "information",
      "pack",
      "personal",
      "question",
      "question mark",
      "storage",
      "support",
      "symbol",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "personal_injury",
    "version": 248,
    "popularity": 1640,
    "codepoint": 59098,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "accident",
      "aid",
      "arm",
      "bandage",
      "body",
      "broke",
      "cast",
      "fracture",
      "health",
      "human",
      "injury",
      "medical",
      "patient",
      "people",
      "person",
      "personal",
      "sling",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "personal_injury",
    "version": 3,
    "popularity": 5387,
    "codepoint": 59098,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "accident",
      "aid",
      "arm",
      "bandage",
      "body",
      "broke",
      "cast",
      "fracture",
      "health",
      "human",
      "injury",
      "medical",
      "patient",
      "people",
      "person",
      "personal",
      "sling",
      "social"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "personal_places",
    "version": 248,
    "popularity": 49,
    "codepoint": 59139,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "flag",
      "location",
      "map",
      "maps",
      "mark",
      "personal",
      "places",
      "save",
      "tag",
      "territory"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "personal_video",
    "version": 13,
    "popularity": 4259,
    "codepoint": 58939,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "Android",
      "OS",
      "cam",
      "chrome",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "personal",
      "television",
      "tv",
      "video",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pest_control",
    "version": 248,
    "popularity": 896,
    "codepoint": 61690,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "bug",
      "control",
      "exterminator",
      "insects",
      "pest"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pest_control",
    "version": 12,
    "popularity": 3860,
    "codepoint": 61690,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bug",
      "control",
      "exterminator",
      "insects",
      "pest"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pest_control_rodent",
    "version": 248,
    "popularity": 241,
    "codepoint": 61693,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "control",
      "exterminator",
      "mice",
      "pest",
      "rodent"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pest_control_rodent",
    "version": 12,
    "popularity": 1348,
    "codepoint": 61693,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "control",
      "exterminator",
      "mice",
      "pest",
      "rodent"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pet_supplies",
    "version": 248,
    "popularity": 47,
    "codepoint": 61361,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "bone",
      "dog",
      "pet",
      "supplies",
      "treat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pets",
    "version": 248,
    "popularity": 6649,
    "codepoint": 59677,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "animal",
      "cat",
      "dog",
      "hand",
      "paw",
      "pet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pets",
    "version": 12,
    "popularity": 50416,
    "codepoint": 59677,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "animal",
      "cat",
      "dog",
      "hand",
      "paw",
      "pet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phishing",
    "version": 248,
    "popularity": 683,
    "codepoint": 60119,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "fish",
      "fishing",
      "fraud",
      "hook",
      "phishing",
      "scam"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phishing",
    "version": 2,
    "popularity": 1542,
    "codepoint": 60119,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "fish",
      "fishing",
      "fraud",
      "hook",
      "phishing",
      "scam"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "phone",
    "version": 12,
    "popularity": 107965,
    "codepoint": 57549,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_android",
    "version": 248,
    "popularity": 3402,
    "codepoint": 58148,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "OS",
      "android",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_android",
    "version": 12,
    "popularity": 20873,
    "codepoint": 58148,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "OS",
      "android",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_bluetooth_speaker",
    "version": 248,
    "popularity": 234,
    "codepoint": 58907,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bluetooth",
      "call",
      "cell",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "signal",
      "speaker",
      "symbol",
      "telephone",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_bluetooth_speaker",
    "version": 12,
    "popularity": 1110,
    "codepoint": 58907,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "bluetooth",
      "call",
      "cell",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "signal",
      "speaker",
      "symbol",
      "telephone",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_callback",
    "version": 248,
    "popularity": 955,
    "codepoint": 58953,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "callback",
      "cell",
      "contact",
      "device",
      "down",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_callback",
    "version": 13,
    "popularity": 4858,
    "codepoint": 58953,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "arrow",
      "call",
      "callback",
      "cell",
      "contact",
      "device",
      "down",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_disabled",
    "version": 248,
    "popularity": 612,
    "codepoint": 59852,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "mobile",
      "off",
      "offline",
      "on",
      "phone",
      "slash",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_disabled",
    "version": 12,
    "popularity": 3883,
    "codepoint": 59852,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "mobile",
      "off",
      "offline",
      "on",
      "phone",
      "slash",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_enabled",
    "version": 248,
    "popularity": 2019,
    "codepoint": 59853,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "enabled",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_enabled",
    "version": 16,
    "popularity": 8316,
    "codepoint": 59853,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "enabled",
      "hardware",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_forwarded",
    "version": 248,
    "popularity": 780,
    "codepoint": 58908,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "cell",
      "contact",
      "device",
      "direction",
      "forwarded",
      "hardware",
      "mobile",
      "phone",
      "right",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_forwarded",
    "version": 13,
    "popularity": 4495,
    "codepoint": 58908,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "arrow",
      "call",
      "cell",
      "contact",
      "device",
      "direction",
      "forwarded",
      "hardware",
      "mobile",
      "phone",
      "right",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_in_talk",
    "version": 248,
    "popularity": 9690,
    "codepoint": 58909,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "connection",
      "contact",
      "data",
      "device",
      "hardware",
      "in",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "sound",
      "speaker",
      "talk",
      "telephone",
      "waves",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_iphone",
    "version": 248,
    "popularity": 13623,
    "codepoint": 58149,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "iphone",
      "mobile",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_iphone",
    "version": 12,
    "popularity": 63983,
    "codepoint": 58149,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "iphone",
      "mobile",
      "phone",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_locked",
    "version": 248,
    "popularity": 0,
    "codepoint": 58910,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "lock",
      "locked",
      "mobile",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_locked",
    "version": 19,
    "popularity": 1367,
    "codepoint": 58910,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "lock",
      "locked",
      "mobile",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "telephone"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "phone_missed",
    "version": 248,
    "popularity": 503,
    "codepoint": 58911,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "missed",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_missed",
    "version": 13,
    "popularity": 2748,
    "codepoint": 58911,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "arrow",
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "missed",
      "mobile",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phone_paused",
    "version": 248,
    "popularity": 297,
    "codepoint": 58912,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "pause",
      "paused",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phone_paused",
    "version": 13,
    "popularity": 1513,
    "codepoint": 58912,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "pause",
      "paused",
      "phone",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phonelink",
    "version": 12,
    "popularity": 5227,
    "codepoint": 58150,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "computer",
      "connect",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "link",
      "mac",
      "mobile",
      "phone",
      "phonelink",
      "sync",
      "tablet",
      "web",
      "windows"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phonelink_erase",
    "version": 248,
    "popularity": 475,
    "codepoint": 57563,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cancel",
      "cell",
      "clear",
      "close",
      "connection",
      "device",
      "erase",
      "exit",
      "hardware",
      "iOS",
      "mobile",
      "no",
      "phone",
      "phonelink",
      "remove",
      "stop",
      "tablet",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phonelink_erase",
    "version": 12,
    "popularity": 2709,
    "codepoint": 57563,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cancel",
      "cell",
      "clear",
      "close",
      "connection",
      "device",
      "erase",
      "exit",
      "hardware",
      "iOS",
      "mobile",
      "no",
      "phone",
      "phonelink",
      "remove",
      "stop",
      "tablet",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phonelink_lock",
    "version": 248,
    "popularity": 588,
    "codepoint": 57564,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "connection",
      "device",
      "erase",
      "hardware",
      "iOS",
      "lock",
      "locked",
      "mobile",
      "password",
      "phone",
      "phonelink",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phonelink_lock",
    "version": 15,
    "popularity": 4110,
    "codepoint": 57564,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "connection",
      "device",
      "erase",
      "hardware",
      "iOS",
      "lock",
      "locked",
      "mobile",
      "password",
      "phone",
      "phonelink",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phonelink_off",
    "version": 248,
    "popularity": 166,
    "codepoint": 58151,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "computer",
      "connect",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "link",
      "mac",
      "mobile",
      "off",
      "on",
      "phone",
      "phonelink",
      "slash",
      "sync",
      "tablet",
      "web",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phonelink_off",
    "version": 12,
    "popularity": 1238,
    "codepoint": 58151,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "computer",
      "connect",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "link",
      "mac",
      "mobile",
      "off",
      "on",
      "phone",
      "phonelink",
      "slash",
      "sync",
      "tablet",
      "web",
      "windows"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phonelink_ring",
    "version": 248,
    "popularity": 1051,
    "codepoint": 57565,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "connection",
      "data",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "network",
      "phone",
      "phonelink",
      "ring",
      "service",
      "signal",
      "tablet",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phonelink_ring",
    "version": 12,
    "popularity": 0,
    "codepoint": 57565,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "connection",
      "data",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "network",
      "phone",
      "phonelink",
      "ring",
      "service",
      "signal",
      "tablet",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "phonelink_ring_off",
    "version": 248,
    "popularity": 7,
    "codepoint": 63402,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "connection",
      "data",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "phonelink",
      "ring",
      "service",
      "signal",
      "tablet",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phonelink_setup",
    "version": 248,
    "popularity": 467,
    "codepoint": 57566,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "call",
      "chat",
      "device",
      "hardware",
      "iOS",
      "info",
      "mobile",
      "phone",
      "phonelink",
      "settings",
      "setup",
      "tablet",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "phonelink_setup",
    "version": 13,
    "popularity": 3645,
    "codepoint": 57566,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "call",
      "chat",
      "device",
      "hardware",
      "iOS",
      "info",
      "mobile",
      "phone",
      "phonelink",
      "settings",
      "setup",
      "tablet",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo",
    "version": 248,
    "popularity": 1437,
    "codepoint": 58384,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "image",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo",
    "version": 12,
    "popularity": 5716,
    "codepoint": 58384,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "image",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_album",
    "version": 248,
    "popularity": 570,
    "codepoint": 58385,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "album",
      "archive",
      "bookmark",
      "image",
      "label",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_album",
    "version": 14,
    "popularity": 0,
    "codepoint": 58385,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "album",
      "archive",
      "bookmark",
      "image",
      "label",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "ribbon",
      "save",
      "tag"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "photo_auto_merge",
    "version": 248,
    "popularity": 12,
    "codepoint": 62768,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "a",
      "account",
      "alphabet",
      "automatic",
      "avatar",
      "best take",
      "blend",
      "body",
      "face",
      "group",
      "human",
      "image",
      "letter",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "profile",
      "stack",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_camera",
    "version": 248,
    "popularity": 0,
    "codepoint": 58386,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "camera",
      "image",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_camera",
    "version": 18,
    "popularity": 97960,
    "codepoint": 58386,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "camera",
      "image",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_camera_back",
    "version": 248,
    "popularity": 250,
    "codepoint": 61288,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "back",
      "camera",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "rear"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_camera_back",
    "version": 11,
    "popularity": 1435,
    "codepoint": 61288,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "back",
      "camera",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "rear"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_camera_front",
    "version": 248,
    "popularity": 580,
    "codepoint": 61289,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "account",
      "camera",
      "face",
      "front",
      "human",
      "image",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "portrait",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_camera_front",
    "version": 11,
    "popularity": 3209,
    "codepoint": 61289,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "account",
      "camera",
      "face",
      "front",
      "human",
      "image",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "portrait",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_filter",
    "version": 12,
    "popularity": 2304,
    "codepoint": 58427,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "ai",
      "artificial",
      "automatic",
      "automation",
      "custom",
      "filter",
      "filters",
      "genai",
      "image",
      "intelligence",
      "magic",
      "photo",
      "photography",
      "picture",
      "smart",
      "spark",
      "sparkle",
      "star"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_frame",
    "version": 248,
    "popularity": 289,
    "codepoint": 61657,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_library",
    "version": 248,
    "popularity": 5290,
    "codepoint": 58387,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "album",
      "image",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_library",
    "version": 12,
    "popularity": 13193,
    "codepoint": 58387,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "album",
      "image",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_prints",
    "version": 248,
    "popularity": 30,
    "codepoint": 61362,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "flower",
      "image",
      "photo",
      "photography",
      "picture",
      "prints",
      "stack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_size_select_actual",
    "version": 12,
    "popularity": 3514,
    "codepoint": 58418,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "actual",
      "dash",
      "dashed",
      "image",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "select",
      "size"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_size_select_large",
    "version": 248,
    "popularity": 284,
    "codepoint": 58419,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "album",
      "dash",
      "dashed",
      "edit",
      "editing",
      "image",
      "large",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "select",
      "size"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_size_select_large",
    "version": 12,
    "popularity": 2130,
    "codepoint": 58419,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "album",
      "dash",
      "dashed",
      "edit",
      "editing",
      "image",
      "large",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "select",
      "size"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "photo_size_select_small",
    "version": 248,
    "popularity": 395,
    "codepoint": 58420,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "album",
      "edit",
      "editing",
      "image",
      "large",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "select",
      "size",
      "small"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "photo_size_select_small",
    "version": 12,
    "popularity": 2238,
    "codepoint": 58420,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "album",
      "edit",
      "editing",
      "image",
      "large",
      "library",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "select",
      "size",
      "small"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "php",
    "version": 248,
    "popularity": 926,
    "codepoint": 60303,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "php",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "php",
    "version": 1,
    "popularity": 1556,
    "codepoint": 60303,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alphabet",
      "brackets",
      "character",
      "code",
      "css",
      "develop",
      "developer",
      "engineer",
      "engineering",
      "font",
      "html",
      "letter",
      "php",
      "platform",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "physical_therapy",
    "version": 248,
    "popularity": 5,
    "codepoint": 57630,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "health",
      "human",
      "person",
      "stretch",
      "stretching",
      "yoga"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "piano",
    "version": 248,
    "popularity": 929,
    "codepoint": 58657,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "instrument",
      "keyboard",
      "keys",
      "music",
      "musical",
      "piano",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "piano",
    "version": 4,
    "popularity": 0,
    "codepoint": 58657,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "instrument",
      "keyboard",
      "keys",
      "music",
      "musical",
      "piano",
      "social"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "piano_off",
    "version": 248,
    "popularity": 188,
    "codepoint": 58656,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "disabled",
      "enabled",
      "instrument",
      "keyboard",
      "keys",
      "music",
      "musical",
      "off",
      "on",
      "piano",
      "slash",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "piano_off",
    "version": 4,
    "popularity": 1053,
    "codepoint": 58656,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "disabled",
      "enabled",
      "instrument",
      "keyboard",
      "keys",
      "music",
      "musical",
      "off",
      "on",
      "piano",
      "slash",
      "social"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "picture_as_pdf",
    "version": 248,
    "popularity": 8775,
    "codepoint": 58389,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "as",
      "character",
      "copy",
      "document",
      "duplicate",
      "file",
      "font",
      "image",
      "letter",
      "multiple",
      "pdf",
      "photo",
      "photography",
      "picture",
      "stack",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_as_pdf",
    "version": 12,
    "popularity": 59350,
    "codepoint": 58389,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alphabet",
      "as",
      "character",
      "copy",
      "document",
      "duplicate",
      "file",
      "font",
      "image",
      "letter",
      "multiple",
      "pdf",
      "photo",
      "photography",
      "picture",
      "stack",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "picture_in_picture",
    "version": 248,
    "popularity": 478,
    "codepoint": 59562,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_in_picture",
    "version": 12,
    "popularity": 4493,
    "codepoint": 59562,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "picture_in_picture_alt",
    "version": 248,
    "popularity": 575,
    "codepoint": 59665,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_in_picture_alt",
    "version": 12,
    "popularity": 3435,
    "codepoint": 59665,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "picture_in_picture_center",
    "version": 248,
    "popularity": 6,
    "codepoint": 62800,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_in_picture_large",
    "version": 248,
    "popularity": 3,
    "codepoint": 62799,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_in_picture_medium",
    "version": 248,
    "popularity": 6,
    "codepoint": 62798,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_in_picture_mobile",
    "version": 248,
    "popularity": 1,
    "codepoint": 62743,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_in_picture_off",
    "version": 248,
    "popularity": 13,
    "codepoint": 62767,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "slash",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "picture_in_picture_small",
    "version": 248,
    "popularity": 18,
    "codepoint": 62797,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "chat",
      "crop",
      "cropped",
      "overlap",
      "photo",
      "picture",
      "pip",
      "position",
      "shape",
      "sizes",
      "talktrack"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pie_chart",
    "version": 248,
    "popularity": 2794,
    "codepoint": 59076,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "pie",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pie_chart",
    "version": 11,
    "popularity": 17447,
    "codepoint": 59076,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "pie",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pie_chart_outline",
    "version": 10,
    "popularity": 0,
    "codepoint": 61508,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "outline",
      "pie",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pie_chart_outlined",
    "version": 10,
    "popularity": 501,
    "codepoint": 59077,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "outlined",
      "pie",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pill",
    "version": 248,
    "popularity": 44,
    "codepoint": 57631,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "drug",
      "drugs",
      "health",
      "medical",
      "medicine",
      "meds",
      "pills",
      "prescription",
      "rx"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pill_off",
    "version": 248,
    "popularity": 2,
    "codepoint": 63497,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "drug",
      "drugs",
      "health",
      "illicit",
      "medical",
      "medicine",
      "meds",
      "pills",
      "prescription",
      "rx"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pin",
    "version": 248,
    "popularity": 2626,
    "codepoint": 61509,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1",
      "2",
      "3",
      "digit",
      "key",
      "login",
      "logout",
      "number",
      "password",
      "pattern",
      "pin",
      "security",
      "star",
      "symbol",
      "unlock"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pin",
    "version": 10,
    "popularity": 17365,
    "codepoint": 61509,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "2",
      "3",
      "digit",
      "key",
      "login",
      "logout",
      "number",
      "password",
      "pattern",
      "pin",
      "security",
      "star",
      "symbol",
      "unlock"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pin_drop",
    "version": 248,
    "popularity": 35601,
    "codepoint": 58718,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "drop",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pin_drop",
    "version": 18,
    "popularity": 18210,
    "codepoint": 58718,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "drop",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pin_end",
    "version": 248,
    "popularity": 325,
    "codepoint": 59239,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "action",
      "arrow",
      "dot",
      "end",
      "pin"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pin_end",
    "version": 3,
    "popularity": 1830,
    "codepoint": 59239,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "action",
      "arrow",
      "dot",
      "end",
      "pin"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pin_invoke",
    "version": 248,
    "popularity": 383,
    "codepoint": 59235,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "action",
      "arrow",
      "dot",
      "invoke",
      "pin"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pin_invoke",
    "version": 3,
    "popularity": 2060,
    "codepoint": 59235,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "action",
      "arrow",
      "dot",
      "invoke",
      "pin"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pinch",
    "version": 248,
    "popularity": 978,
    "codepoint": 60216,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "compress",
      "direction",
      "finger",
      "grasp",
      "hand",
      "navigation",
      "nip",
      "pinch",
      "squeeze",
      "tweak"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pinch",
    "version": 1,
    "popularity": 1988,
    "codepoint": 60216,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "compress",
      "direction",
      "finger",
      "grasp",
      "hand",
      "navigation",
      "nip",
      "pinch",
      "squeeze",
      "tweak"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pinch_zoom_in",
    "version": 248,
    "popularity": 584,
    "codepoint": 61946,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrows",
      "close",
      "direction",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "in",
      "pinch",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pinch_zoom_out",
    "version": 248,
    "popularity": 695,
    "codepoint": 61947,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrows",
      "direction",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "open",
      "out",
      "pinch",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pip",
    "version": 248,
    "popularity": 34,
    "codepoint": 63053,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "close",
      "frame",
      "input",
      "into",
      "move",
      "picture",
      "picture in picture",
      "right",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pip_exit",
    "version": 248,
    "popularity": 15,
    "codepoint": 63245,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "close",
      "frame",
      "input",
      "into",
      "left",
      "move",
      "out",
      "picture",
      "picture in picture",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pivot_table_chart",
    "version": 248,
    "popularity": 444,
    "codepoint": 59854,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "analytics",
      "arrow",
      "arrows",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "direction",
      "drive",
      "edit",
      "editing",
      "graph",
      "grid",
      "infographic",
      "measure",
      "metrics",
      "pivot",
      "rotate",
      "sheet",
      "statistics",
      "table",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pivot_table_chart",
    "version": 11,
    "popularity": 3962,
    "codepoint": 59854,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "analytics",
      "arrow",
      "arrows",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "direction",
      "drive",
      "edit",
      "editing",
      "graph",
      "grid",
      "infographic",
      "measure",
      "metrics",
      "pivot",
      "rotate",
      "sheet",
      "statistics",
      "table",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pix",
    "version": 2,
    "popularity": 4790,
    "codepoint": 60067,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bill",
      "brazil",
      "card",
      "cash",
      "commerce",
      "credit",
      "currency",
      "finance",
      "money",
      "payment"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "place",
    "version": 19,
    "popularity": 143566,
    "codepoint": 58719,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "navigation",
      "pin",
      "place",
      "point",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "place_item",
    "version": 248,
    "popularity": 1900,
    "codepoint": 61936,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "drop",
      "item",
      "move",
      "place",
      "put"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "plagiarism",
    "version": 248,
    "popularity": 1530,
    "codepoint": 59994,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "document",
      "find",
      "glass",
      "look",
      "magnifying",
      "page",
      "paper",
      "plagiarism",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "plagiarism",
    "version": 11,
    "popularity": 9197,
    "codepoint": 59994,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "doc",
      "document",
      "find",
      "glass",
      "look",
      "magnifying",
      "page",
      "paper",
      "plagiarism",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "planner_banner_ad_pt",
    "version": 248,
    "popularity": 21,
    "codepoint": 59026,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "ad",
      "banner",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "planner",
      "portrait",
      "pt",
      "shot"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "planner_review",
    "version": 248,
    "popularity": 56,
    "codepoint": 59028,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "data",
      "graph",
      "lines",
      "planner",
      "review"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "play_arrow",
    "version": 248,
    "popularity": 25364,
    "codepoint": 57399,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "play_arrow",
    "version": 16,
    "popularity": 145675,
    "codepoint": 57399,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "play_circle",
    "version": 248,
    "popularity": 22958,
    "codepoint": 57796,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "play_circle",
    "version": 9,
    "popularity": 63690,
    "codepoint": 57796,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "play_circle_filled",
    "version": 14,
    "popularity": 75285,
    "codepoint": 57400,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "play_circle_outline",
    "version": 19,
    "popularity": 42614,
    "codepoint": 57401,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "circle",
      "control",
      "controls",
      "media",
      "music",
      "outline",
      "play",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "play_disabled",
    "version": 248,
    "popularity": 239,
    "codepoint": 61290,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "control",
      "controls",
      "disabled",
      "enabled",
      "media",
      "music",
      "off",
      "on",
      "play",
      "slash",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "play_disabled",
    "version": 10,
    "popularity": 1475,
    "codepoint": 61290,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "control",
      "controls",
      "disabled",
      "enabled",
      "media",
      "music",
      "off",
      "on",
      "play",
      "slash",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "play_for_work",
    "version": 248,
    "popularity": 384,
    "codepoint": 59654,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "circle",
      "down",
      "google",
      "half",
      "play",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "play_for_work",
    "version": 12,
    "popularity": 5459,
    "codepoint": 59654,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "circle",
      "down",
      "google",
      "half",
      "play",
      "work"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "play_lesson",
    "version": 248,
    "popularity": 590,
    "codepoint": 61511,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "book",
      "bookmark",
      "digital",
      "ebook",
      "lesson",
      "multimedia",
      "play",
      "play lesson",
      "read",
      "reading",
      "ribbon"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "play_lesson",
    "version": 10,
    "popularity": 3179,
    "codepoint": 61511,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "audio",
      "book",
      "bookmark",
      "digital",
      "ebook",
      "lesson",
      "multimedia",
      "play",
      "play lesson",
      "read",
      "reading",
      "ribbon"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "play_pause",
    "version": 248,
    "popularity": 1436,
    "codepoint": 61751,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "media",
      "music",
      "pause",
      "play",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "play_shapes",
    "version": 248,
    "popularity": 27,
    "codepoint": 63484,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "circle",
      "cross",
      "interests",
      "shape",
      "social",
      "square",
      "triangle",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playing_cards",
    "version": 248,
    "popularity": 18,
    "codepoint": 62940,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "card deck",
      "games",
      "google play"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playlist_add",
    "version": 248,
    "popularity": 2896,
    "codepoint": 57403,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "add",
      "collection",
      "list",
      "music",
      "new",
      "playlist",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playlist_add",
    "version": 16,
    "popularity": 22361,
    "codepoint": 57403,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "add",
      "collection",
      "list",
      "music",
      "new",
      "playlist",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "playlist_add_check",
    "version": 248,
    "popularity": 1838,
    "codepoint": 57445,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "add",
      "approve",
      "check",
      "collection",
      "complete",
      "done",
      "list",
      "mark",
      "music",
      "ok",
      "playlist",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playlist_add_check",
    "version": 17,
    "popularity": 16427,
    "codepoint": 57445,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "add",
      "approve",
      "check",
      "collection",
      "complete",
      "done",
      "list",
      "mark",
      "music",
      "ok",
      "playlist",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "playlist_add_check_circle",
    "version": 248,
    "popularity": 711,
    "codepoint": 59366,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "add",
      "album",
      "artist",
      "audio",
      "cd",
      "check",
      "circle",
      "collection",
      "list",
      "mark",
      "music",
      "playlist",
      "record",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playlist_add_check_circle",
    "version": 2,
    "popularity": 4210,
    "codepoint": 59366,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "add",
      "album",
      "artist",
      "audio",
      "cd",
      "check",
      "circle",
      "collection",
      "list",
      "mark",
      "music",
      "playlist",
      "record",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "playlist_add_circle",
    "version": 248,
    "popularity": 459,
    "codepoint": 59365,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "add",
      "album",
      "artist",
      "audio",
      "cd",
      "check",
      "circle",
      "collection",
      "list",
      "mark",
      "music",
      "playlist",
      "record",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playlist_add_circle",
    "version": 2,
    "popularity": 2454,
    "codepoint": 59365,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "add",
      "album",
      "artist",
      "audio",
      "cd",
      "check",
      "circle",
      "collection",
      "list",
      "mark",
      "music",
      "playlist",
      "record",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "playlist_play",
    "version": 248,
    "popularity": 972,
    "codepoint": 57439,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "collection",
      "list",
      "music",
      "play",
      "playlist"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playlist_play",
    "version": 14,
    "popularity": 7069,
    "codepoint": 57439,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "collection",
      "list",
      "music",
      "play",
      "playlist"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "playlist_remove",
    "version": 248,
    "popularity": 908,
    "codepoint": 60288,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "-",
      "collection",
      "list",
      "minus",
      "music",
      "playlist",
      "remove"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "playlist_remove",
    "version": 1,
    "popularity": 3777,
    "codepoint": 60288,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "-",
      "collection",
      "list",
      "minus",
      "music",
      "playlist",
      "remove"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "plumbing",
    "version": 248,
    "popularity": 776,
    "codepoint": 61703,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "build",
      "construction",
      "fix",
      "handyman",
      "plumbing",
      "repair",
      "tools",
      "wrench"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "plumbing",
    "version": 12,
    "popularity": 4317,
    "codepoint": 61703,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "build",
      "construction",
      "fix",
      "handyman",
      "plumbing",
      "repair",
      "tools",
      "wrench"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "plus_one",
    "version": 17,
    "popularity": 5185,
    "codepoint": 59392,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "1",
      "add",
      "digit",
      "increase",
      "number",
      "one",
      "plus",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "podcasts",
    "version": 248,
    "popularity": 1842,
    "codepoint": 61512,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "broadcast",
      "casting",
      "network",
      "podcasts",
      "signal",
      "transmitting",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "podcasts",
    "version": 9,
    "popularity": 10861,
    "codepoint": 61512,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "broadcast",
      "casting",
      "network",
      "podcasts",
      "signal",
      "transmitting",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "podiatry",
    "version": 248,
    "popularity": 17,
    "codepoint": 57632,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "foot",
      "health",
      "human",
      "shoe",
      "step",
      "steps",
      "walk"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "podium",
    "version": 248,
    "popularity": 68,
    "codepoint": 63483,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "conference",
      "keynote",
      "platform",
      "presentation",
      "presenter",
      "pulpit",
      "speech",
      "stage",
      "stand"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "point_of_sale",
    "version": 248,
    "popularity": 2260,
    "codepoint": 61822,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "checkout",
      "cost",
      "machine",
      "merchant",
      "money",
      "of",
      "pay",
      "payment",
      "point",
      "pos",
      "retail",
      "sale",
      "system",
      "transaction"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "point_of_sale",
    "version": 8,
    "popularity": 15848,
    "codepoint": 61822,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "checkout",
      "cost",
      "machine",
      "merchant",
      "money",
      "of",
      "pay",
      "payment",
      "point",
      "pos",
      "retail",
      "sale",
      "system",
      "transaction"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "point_scan",
    "version": 248,
    "popularity": 73,
    "codepoint": 63244,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "chrome",
      "locate",
      "pointer",
      "scanning",
      "target"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "policy",
    "version": 248,
    "popularity": 3928,
    "codepoint": 59927,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "certified",
      "find",
      "glass",
      "legal",
      "look",
      "magnify",
      "magnifying",
      "policy",
      "privacy",
      "private",
      "protect",
      "protection",
      "search",
      "security",
      "see",
      "shield",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "policy",
    "version": 12,
    "popularity": 20875,
    "codepoint": 59927,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "certified",
      "find",
      "glass",
      "legal",
      "look",
      "magnify",
      "magnifying",
      "policy",
      "privacy",
      "private",
      "protect",
      "protection",
      "search",
      "security",
      "see",
      "shield",
      "verified"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "poll",
    "version": 13,
    "popularity": 16410,
    "codepoint": 59393,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "poll",
      "statistics",
      "survey",
      "tracking",
      "vote"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "polyline",
    "version": 248,
    "popularity": 942,
    "codepoint": 60347,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "compose",
      "create",
      "design",
      "draw",
      "line",
      "polyline",
      "vector"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "polyline",
    "version": 1,
    "popularity": 2375,
    "codepoint": 60347,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "compose",
      "create",
      "design",
      "draw",
      "line",
      "polyline",
      "vector"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "polymer",
    "version": 248,
    "popularity": 509,
    "codepoint": 59563,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "emblem",
      "logo",
      "mark",
      "polymer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "polymer",
    "version": 12,
    "popularity": 4557,
    "codepoint": 59563,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "emblem",
      "logo",
      "mark",
      "polymer"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pool",
    "version": 248,
    "popularity": 2002,
    "codepoint": 60232,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "ocean",
      "people",
      "person",
      "places",
      "pool",
      "sea",
      "sports",
      "swim",
      "swimming",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pool",
    "version": 12,
    "popularity": 9129,
    "codepoint": 60232,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "ocean",
      "people",
      "person",
      "places",
      "pool",
      "sea",
      "sports",
      "swim",
      "swimming",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "portable_wifi_off",
    "version": 248,
    "popularity": 150,
    "codepoint": 57550,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "portable",
      "service",
      "signal",
      "slash",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "portable_wifi_off",
    "version": 12,
    "popularity": 1702,
    "codepoint": 57550,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "portable",
      "service",
      "signal",
      "slash",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "portrait",
    "version": 12,
    "popularity": 12405,
    "codepoint": 58390,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "account",
      "face",
      "human",
      "people",
      "person",
      "photo",
      "picture",
      "portrait",
      "profile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "position_bottom_left",
    "version": 248,
    "popularity": 5,
    "codepoint": 63243,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "position_bottom_right",
    "version": 248,
    "popularity": 8,
    "codepoint": 63242,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "position_top_right",
    "version": 248,
    "popularity": 8,
    "codepoint": 63241,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "post",
    "version": 248,
    "popularity": 7,
    "codepoint": 59141,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "document",
      "memo",
      "note",
      "post",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "post_add",
    "version": 248,
    "popularity": 4005,
    "codepoint": 59936,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "plus",
      "post",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "post_add",
    "version": 11,
    "popularity": 37996,
    "codepoint": 59936,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "+",
      "add",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "plus",
      "post",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "potted_plant",
    "version": 248,
    "popularity": 2192,
    "codepoint": 63658,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "houseplant",
      "leaf",
      "leaves",
      "plant",
      "pot",
      "potted"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "power",
    "version": 248,
    "popularity": 1751,
    "codepoint": 58940,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "charge",
      "cord",
      "electric",
      "electrical",
      "outlet",
      "plug",
      "power"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "power",
    "version": 12,
    "popularity": 9194,
    "codepoint": 58940,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "charge",
      "cord",
      "electric",
      "electrical",
      "outlet",
      "plug",
      "power"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "power_input",
    "version": 248,
    "popularity": 189,
    "codepoint": 58166,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "input",
      "lines",
      "power",
      "supply"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "power_input",
    "version": 12,
    "popularity": 1411,
    "codepoint": 58166,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "input",
      "lines",
      "power",
      "supply"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "power_off",
    "version": 248,
    "popularity": 706,
    "codepoint": 58950,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "charge",
      "cord",
      "disabled",
      "electric",
      "electrical",
      "enabled",
      "off",
      "on",
      "outlet",
      "plug",
      "power",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "power_off",
    "version": 12,
    "popularity": 4421,
    "codepoint": 58950,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "charge",
      "cord",
      "disabled",
      "electric",
      "electrical",
      "enabled",
      "off",
      "on",
      "outlet",
      "plug",
      "power",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "power_settings_new",
    "version": 248,
    "popularity": 6208,
    "codepoint": 59564,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "info",
      "information",
      "off",
      "on",
      "power",
      "save",
      "settings",
      "shutdown"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "power_settings_new",
    "version": 12,
    "popularity": 53136,
    "codepoint": 59564,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "info",
      "information",
      "off",
      "on",
      "power",
      "save",
      "settings",
      "shutdown"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "prayer_times",
    "version": 248,
    "popularity": 17,
    "codepoint": 63544,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "islam",
      "muslim",
      "prayer",
      "salah"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "precision_manufacturing",
    "version": 248,
    "popularity": 3874,
    "codepoint": 61513,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arm",
      "automatic",
      "chain",
      "conveyor",
      "crane",
      "factory",
      "industry",
      "machinery",
      "manufacturing",
      "mechanical",
      "precision",
      "production",
      "repairing",
      "robot",
      "supply",
      "warehouse"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "precision_manufacturing",
    "version": 10,
    "popularity": 26904,
    "codepoint": 61513,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arm",
      "automatic",
      "chain",
      "conveyor",
      "crane",
      "factory",
      "industry",
      "machinery",
      "manufacturing",
      "mechanical",
      "precision",
      "production",
      "repairing",
      "robot",
      "supply",
      "warehouse"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "pregnancy",
    "version": 248,
    "popularity": 6,
    "codepoint": 62961,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "baby",
      "birth",
      "body",
      "dad",
      "father",
      "female",
      "human",
      "lady",
      "male",
      "maternity",
      "men",
      "mom",
      "mother",
      "parent",
      "paternity",
      "people",
      "person",
      "pregnant",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pregnant_woman",
    "version": 248,
    "popularity": 1103,
    "codepoint": 59678,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "baby",
      "birth",
      "body",
      "female",
      "human",
      "lady",
      "maternity",
      "mom",
      "mother",
      "people",
      "person",
      "pregnant",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pregnant_woman",
    "version": 14,
    "popularity": 7251,
    "codepoint": 59678,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "baby",
      "birth",
      "body",
      "female",
      "human",
      "lady",
      "maternity",
      "mom",
      "mother",
      "people",
      "person",
      "pregnant",
      "women"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "preliminary",
    "version": 248,
    "popularity": 64,
    "codepoint": 59352,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "health",
      "load",
      "loading",
      "mark",
      "ok",
      "pend",
      "pending",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "prescriptions",
    "version": 248,
    "popularity": 33,
    "codepoint": 57633,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "clipboard",
      "doc",
      "document",
      "drug",
      "drugs",
      "illicit",
      "medical",
      "medicine",
      "meds",
      "pills",
      "prescription",
      "rx"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "present_to_all",
    "version": 248,
    "popularity": 863,
    "codepoint": 57567,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "all",
      "arrow",
      "present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "to",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "present_to_all",
    "version": 11,
    "popularity": 5034,
    "codepoint": 57567,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "all",
      "arrow",
      "present",
      "presentation",
      "screen",
      "share",
      "site",
      "slides",
      "to",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "preview",
    "version": 248,
    "popularity": 5543,
    "codepoint": 61893,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "design",
      "eye",
      "layout",
      "preview",
      "reveal",
      "screen",
      "see",
      "show",
      "site",
      "view",
      "web",
      "website",
      "window",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "preview",
    "version": 7,
    "popularity": 36454,
    "codepoint": 61893,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "eye",
      "layout",
      "preview",
      "reveal",
      "screen",
      "see",
      "show",
      "site",
      "view",
      "web",
      "website",
      "window",
      "www"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "preview_off",
    "version": 248,
    "popularity": 5,
    "codepoint": 63407,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "design",
      "disabled",
      "enabled",
      "eye",
      "layout",
      "off",
      "offline",
      "on",
      "preview",
      "reveal",
      "screen",
      "see",
      "show",
      "site",
      "view",
      "web",
      "website",
      "window",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "price_change",
    "version": 248,
    "popularity": 1735,
    "codepoint": 61514,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arrows",
      "bill",
      "card",
      "cash",
      "change",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "down",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "price_change",
    "version": 10,
    "popularity": 15197,
    "codepoint": 61514,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "arrows",
      "bill",
      "card",
      "cash",
      "change",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "down",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "shopping",
      "symbol",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "price_check",
    "version": 248,
    "popularity": 2184,
    "codepoint": 61515,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "approve",
      "bill",
      "card",
      "cash",
      "check",
      "coin",
      "commerce",
      "complete",
      "cost",
      "credit",
      "currency",
      "dollars",
      "done",
      "finance",
      "mark",
      "money",
      "ok",
      "online",
      "pay",
      "payment",
      "price",
      "select",
      "shopping",
      "symbol",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "price_check",
    "version": 10,
    "popularity": 18414,
    "codepoint": 61515,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "approve",
      "bill",
      "card",
      "cash",
      "check",
      "coin",
      "commerce",
      "complete",
      "cost",
      "credit",
      "currency",
      "dollars",
      "done",
      "finance",
      "mark",
      "money",
      "ok",
      "online",
      "pay",
      "payment",
      "price",
      "select",
      "shopping",
      "symbol",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "print",
    "version": 248,
    "popularity": 8813,
    "codepoint": 59565,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "draft",
      "fax",
      "ink",
      "machine",
      "office",
      "paper",
      "print",
      "printer",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "print",
    "version": 16,
    "popularity": 64943,
    "codepoint": 59565,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "draft",
      "fax",
      "ink",
      "machine",
      "office",
      "paper",
      "print",
      "printer",
      "send"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "print_add",
    "version": 248,
    "popularity": 1,
    "codepoint": 63394,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "+",
      "create",
      "draft",
      "fax",
      "ink",
      "machine",
      "new",
      "office",
      "paper",
      "plus",
      "print",
      "printer",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "print_connect",
    "version": 248,
    "popularity": 2,
    "codepoint": 63393,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "approve",
      "check",
      "checkmark",
      "complete",
      "done",
      "draft",
      "fax",
      "ink",
      "machine",
      "mark",
      "office",
      "ok",
      "paper",
      "print",
      "printer",
      "select",
      "send",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "print_disabled",
    "version": 248,
    "popularity": 375,
    "codepoint": 59855,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "disabled",
      "enabled",
      "off",
      "on",
      "paper",
      "print",
      "printer",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "print_disabled",
    "version": 12,
    "popularity": 2113,
    "codepoint": 59855,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "disabled",
      "enabled",
      "off",
      "on",
      "paper",
      "print",
      "printer",
      "slash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "print_error",
    "version": 248,
    "popularity": 2,
    "codepoint": 63392,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "draft",
      "error",
      "exclamation",
      "fax",
      "feedback",
      "important",
      "ink",
      "machine",
      "mark",
      "notification",
      "office",
      "paper",
      "print",
      "printer",
      "problem",
      "report",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "print_lock",
    "version": 248,
    "popularity": 1,
    "codepoint": 63057,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "draft",
      "fax",
      "ink",
      "lock",
      "locked",
      "machine",
      "office",
      "paper",
      "password",
      "print",
      "printer",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "secure",
      "security",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "priority",
    "version": 248,
    "popularity": 2822,
    "codepoint": 57759,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "filled",
      "ok",
      "priority",
      "select",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "priority_high",
    "version": 248,
    "popularity": 8054,
    "codepoint": 58949,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "high",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "priority_high",
    "version": 19,
    "popularity": 40070,
    "codepoint": 58949,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "high",
      "important",
      "mark",
      "notification",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "privacy",
    "version": 248,
    "popularity": 128,
    "codepoint": 61768,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "cam",
      "camera",
      "conference",
      "dictation",
      "disabled",
      "enabled",
      "film",
      "filming",
      "hardware",
      "hear",
      "hearing",
      "image",
      "mic",
      "microphone",
      "motion",
      "noise",
      "off",
      "offline",
      "on",
      "picture",
      "record",
      "slash",
      "sound",
      "video",
      "videography",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "privacy_tip",
    "version": 248,
    "popularity": 1927,
    "codepoint": 61660,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "certified",
      "details",
      "help",
      "i",
      "info",
      "information",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "service",
      "shield",
      "support",
      "tip",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "privacy_tip",
    "version": 12,
    "popularity": 18919,
    "codepoint": 61660,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "certified",
      "details",
      "help",
      "i",
      "info",
      "information",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "service",
      "shield",
      "support",
      "tip",
      "verified"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "private_connectivity",
    "version": 248,
    "popularity": 683,
    "codepoint": 59204,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "connectivity",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "private_connectivity",
    "version": 5,
    "popularity": 2992,
    "codepoint": 59204,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "connectivity",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "problem",
    "version": 248,
    "popularity": 133,
    "codepoint": 57634,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "card",
      "caution",
      "danger",
      "error",
      "exclamation",
      "health",
      "important",
      "mark",
      "notification",
      "report",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "procedure",
    "version": 248,
    "popularity": 3,
    "codepoint": 58961,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "health",
      "medical",
      "surgery"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "process_chart",
    "version": 248,
    "popularity": 42,
    "codepoint": 63573,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "chart",
      "data",
      "diagram",
      "forward",
      "graph",
      "infographic",
      "line",
      "lines",
      "measure",
      "metrics",
      "slant",
      "slants",
      "slash",
      "slashes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "production_quantity_limits",
    "version": 248,
    "popularity": 1627,
    "codepoint": 57809,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bill",
      "card",
      "cart",
      "cash",
      "caution",
      "coin",
      "commerce",
      "credit",
      "currency",
      "danger",
      "dollars",
      "error",
      "exclamation",
      "important",
      "limits",
      "mark",
      "money",
      "notification",
      "online",
      "pay",
      "payment",
      "production",
      "quantity",
      "shopping",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "production_quantity_limits",
    "version": 7,
    "popularity": 13163,
    "codepoint": 57809,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bill",
      "card",
      "cart",
      "cash",
      "caution",
      "coin",
      "commerce",
      "credit",
      "currency",
      "danger",
      "dollars",
      "error",
      "exclamation",
      "important",
      "limits",
      "mark",
      "money",
      "notification",
      "online",
      "pay",
      "payment",
      "production",
      "quantity",
      "shopping",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "productivity",
    "version": 248,
    "popularity": 536,
    "codepoint": 58006,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "nest",
      "productivity"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "progress_activity",
    "version": 248,
    "popularity": 543,
    "codepoint": 59856,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "activity",
      "circle",
      "duration",
      "loading",
      "progress",
      "time",
      "turning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "prompt_suggestion",
    "version": 248,
    "popularity": 17,
    "codepoint": 62710,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "ai",
      "arrow",
      "artificial",
      "automatic",
      "automation",
      "backward",
      "custom",
      "genai",
      "intelligence",
      "left",
      "magic",
      "mail",
      "message",
      "reply",
      "send",
      "share",
      "smart",
      "spark",
      "sparkle",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "propane",
    "version": 248,
    "popularity": 184,
    "codepoint": 60436,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "gas",
      "nest",
      "propane"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "propane",
    "version": 1,
    "popularity": 694,
    "codepoint": 60436,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "gas",
      "nest",
      "propane"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "propane_tank",
    "version": 248,
    "popularity": 472,
    "codepoint": 60435,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bbq",
      "gas",
      "grill",
      "nest",
      "propane",
      "tank"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "propane_tank",
    "version": 1,
    "popularity": 1177,
    "codepoint": 60435,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "bbq",
      "gas",
      "grill",
      "nest",
      "propane",
      "tank"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "psychiatry",
    "version": 248,
    "popularity": 131,
    "codepoint": 57635,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "garden",
      "health",
      "leaf",
      "leaves",
      "plant",
      "sprout",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "psychology",
    "version": 248,
    "popularity": 7585,
    "codepoint": 59978,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "behavior",
      "body",
      "brain",
      "cognitive",
      "function",
      "gear",
      "head",
      "human",
      "intellectual",
      "mental",
      "mind",
      "people",
      "person",
      "preferences",
      "psychiatric",
      "psychology",
      "science",
      "settings",
      "social",
      "therapy",
      "thinking",
      "thoughts"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "psychology",
    "version": 11,
    "popularity": 35910,
    "codepoint": 59978,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "behavior",
      "body",
      "brain",
      "cognitive",
      "function",
      "gear",
      "head",
      "human",
      "intellectual",
      "mental",
      "mind",
      "people",
      "person",
      "preferences",
      "psychiatric",
      "psychology",
      "science",
      "settings",
      "social",
      "therapy",
      "thinking",
      "thoughts"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "psychology_alt",
    "version": 248,
    "popularity": 2570,
    "codepoint": 63722,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "?",
      "assistance",
      "behavior",
      "body",
      "brain",
      "cognitive",
      "function",
      "gear",
      "head",
      "help",
      "human",
      "info",
      "information",
      "intellectual",
      "mental",
      "mind",
      "people",
      "person",
      "preferences",
      "psychiatric",
      "psychology",
      "punctuation",
      "question mark",
      "science",
      "settings",
      "social",
      "support",
      "symbol",
      "therapy",
      "thinking",
      "thoughts"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "psychology_alt",
    "version": 1,
    "popularity": 3762,
    "codepoint": 63722,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "?",
      "assistance",
      "behavior",
      "body",
      "brain",
      "cognitive",
      "function",
      "gear",
      "head",
      "help",
      "human",
      "info",
      "information",
      "intellectual",
      "mental",
      "mind",
      "people",
      "person",
      "preferences",
      "psychiatric",
      "psychology",
      "punctuation",
      "question mark",
      "science",
      "settings",
      "social",
      "support",
      "symbol",
      "therapy",
      "thinking",
      "thoughts"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "public",
    "version": 248,
    "popularity": 17397,
    "codepoint": 59403,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "earth",
      "global",
      "globe",
      "map",
      "network",
      "planet",
      "public",
      "social",
      "space",
      "web",
      "world"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "public",
    "version": 13,
    "popularity": 82566,
    "codepoint": 59403,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "earth",
      "global",
      "globe",
      "map",
      "network",
      "planet",
      "public",
      "social",
      "space",
      "web",
      "world"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "public_off",
    "version": 248,
    "popularity": 854,
    "codepoint": 61898,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "disabled",
      "earth",
      "enabled",
      "global",
      "globe",
      "map",
      "network",
      "off",
      "on",
      "planet",
      "public",
      "slash",
      "social",
      "space",
      "web",
      "world"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "public_off",
    "version": 8,
    "popularity": 3421,
    "codepoint": 61898,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "disabled",
      "earth",
      "enabled",
      "global",
      "globe",
      "map",
      "network",
      "off",
      "on",
      "planet",
      "public",
      "slash",
      "social",
      "space",
      "web",
      "world"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "publish",
    "version": 248,
    "popularity": 3264,
    "codepoint": 57941,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "cloud",
      "file",
      "import",
      "publish",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "publish",
    "version": 12,
    "popularity": 13710,
    "codepoint": 57941,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "arrow",
      "cloud",
      "file",
      "import",
      "publish",
      "up",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "published_with_changes",
    "version": 248,
    "popularity": 3727,
    "codepoint": 62002,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "arrow",
      "arrows",
      "changes",
      "check",
      "complete",
      "done",
      "inprogress",
      "load",
      "loading",
      "mark",
      "ok",
      "published",
      "refresh",
      "renew",
      "replace",
      "rotate",
      "select",
      "tick",
      "validate",
      "verified",
      "with",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "published_with_changes",
    "version": 6,
    "popularity": 35592,
    "codepoint": 62002,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "arrow",
      "arrows",
      "changes",
      "check",
      "complete",
      "done",
      "inprogress",
      "load",
      "loading",
      "mark",
      "ok",
      "published",
      "refresh",
      "renew",
      "replace",
      "rotate",
      "select",
      "tick",
      "validate",
      "verified",
      "with",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "pulmonology",
    "version": 248,
    "popularity": 12,
    "codepoint": 57636,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "airways",
      "body",
      "health",
      "human",
      "lung",
      "lungs",
      "organ",
      "respiratory"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "pulse_alert",
    "version": 248,
    "popularity": 13,
    "codepoint": 62721,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "cardio",
      "caution",
      "danger",
      "doc",
      "doctor",
      "electrocardiogram",
      "error",
      "exclamation",
      "health",
      "heart",
      "heart issue",
      "heart rhythm",
      "high",
      "important",
      "loss of pulse",
      "mark",
      "medical",
      "monitor",
      "notification",
      "nurse",
      "rate",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "punch_clock",
    "version": 248,
    "popularity": 446,
    "codepoint": 60072,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "clock",
      "date",
      "punch",
      "schedule",
      "time",
      "timer",
      "timesheet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "punch_clock",
    "version": 2,
    "popularity": 1941,
    "codepoint": 60072,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "clock",
      "date",
      "punch",
      "schedule",
      "time",
      "timer",
      "timesheet"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "push_pin",
    "version": 248,
    "popularity": 4629,
    "codepoint": 61709,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "location",
      "marker",
      "pin",
      "place",
      "push",
      "remember",
      "save"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "push_pin",
    "version": 13,
    "popularity": 39769,
    "codepoint": 61709,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "location",
      "marker",
      "pin",
      "place",
      "push",
      "remember",
      "save"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "qr_code",
    "version": 248,
    "popularity": 3839,
    "codepoint": 61291,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "qr_code",
    "version": 12,
    "popularity": 27772,
    "codepoint": 61291,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "qr_code_2",
    "version": 248,
    "popularity": 4746,
    "codepoint": 57354,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "qr_code_2",
    "version": 6,
    "popularity": 27164,
    "codepoint": 57354,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "qr_code_2_add",
    "version": 248,
    "popularity": 16,
    "codepoint": 63064,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "+",
      "add",
      "barcode",
      "camera",
      "code",
      "media",
      "new",
      "plus",
      "product",
      "qr",
      "quick",
      "response",
      "smartphone",
      "url",
      "urls"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "qr_code_scanner",
    "version": 248,
    "popularity": 7226,
    "codepoint": 61958,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "scanner",
      "smartphone",
      "url",
      "urls"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "qr_code_scanner",
    "version": 7,
    "popularity": 47430,
    "codepoint": 61958,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "barcode",
      "camera",
      "code",
      "media",
      "product",
      "qr",
      "quick",
      "response",
      "scanner",
      "smartphone",
      "url",
      "urls"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "query_builder",
    "version": 13,
    "popularity": 17460,
    "codepoint": 59566,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "builder",
      "clock",
      "date",
      "query",
      "schedule",
      "time"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "query_stats",
    "version": 248,
    "popularity": 9258,
    "codepoint": 58620,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "find",
      "glass",
      "graph",
      "infographic",
      "line",
      "look",
      "magnify",
      "magnifying",
      "measure",
      "metrics",
      "query",
      "search",
      "see",
      "statistics",
      "stats",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "query_stats",
    "version": 4,
    "popularity": 21420,
    "codepoint": 58620,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "find",
      "glass",
      "graph",
      "infographic",
      "line",
      "look",
      "magnify",
      "magnifying",
      "measure",
      "metrics",
      "query",
      "search",
      "see",
      "statistics",
      "stats",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "question_answer",
    "version": 12,
    "popularity": 104879,
    "codepoint": 59567,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "answer",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "conversation",
      "feedback",
      "message",
      "question",
      "speech",
      "talk"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "question_exchange",
    "version": 248,
    "popularity": 71,
    "codepoint": 63475,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "question_mark",
    "version": 248,
    "popularity": 9720,
    "codepoint": 60299,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "?",
      "assistance",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "question_mark",
    "version": 1,
    "popularity": 26918,
    "codepoint": 60299,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "?",
      "assistance",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "queue",
    "version": 12,
    "popularity": 7028,
    "codepoint": 57404,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "add",
      "collection",
      "layers",
      "list",
      "multiple",
      "music",
      "playlist",
      "queue",
      "stack",
      "stream",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "queue_music",
    "version": 248,
    "popularity": 1536,
    "codepoint": 57405,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "collection",
      "list",
      "music",
      "playlist",
      "queue"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "queue_music",
    "version": 18,
    "popularity": 8254,
    "codepoint": 57405,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "collection",
      "list",
      "music",
      "playlist",
      "queue"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "queue_play_next",
    "version": 248,
    "popularity": 398,
    "codepoint": 57446,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "add",
      "arrow",
      "desktop",
      "device",
      "display",
      "hardware",
      "monitor",
      "new",
      "next",
      "play",
      "plus",
      "queue",
      "screen",
      "stream",
      "symbol",
      "tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "queue_play_next",
    "version": 14,
    "popularity": 2382,
    "codepoint": 57446,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "add",
      "arrow",
      "desktop",
      "device",
      "display",
      "hardware",
      "monitor",
      "new",
      "next",
      "play",
      "plus",
      "queue",
      "screen",
      "stream",
      "symbol",
      "tv"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "quick_phrases",
    "version": 248,
    "popularity": 839,
    "codepoint": 59345,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "phrases",
      "quick",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quick_reference",
    "version": 248,
    "popularity": 90,
    "codepoint": 58478,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alert",
      "announcement",
      "assistance",
      "details",
      "doc",
      "document",
      "file",
      "find",
      "glass",
      "help",
      "i",
      "info",
      "information",
      "look",
      "magnify",
      "magnifying",
      "page",
      "paper",
      "search",
      "see",
      "service",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quick_reference_all",
    "version": 248,
    "popularity": 188,
    "codepoint": 63489,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "doc",
      "document",
      "file",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "page",
      "paper",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quick_reorder",
    "version": 248,
    "popularity": 55,
    "codepoint": 60181,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "box",
      "delivery",
      "fast",
      "mail",
      "packaged",
      "quick",
      "reorder"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quickreply",
    "version": 248,
    "popularity": 544,
    "codepoint": 61292,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bolt",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "message",
      "quick",
      "quickreply",
      "reply",
      "speech",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quickreply",
    "version": 12,
    "popularity": 4952,
    "codepoint": 61292,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bolt",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "electric",
      "energy",
      "fast",
      "instant",
      "lightning",
      "message",
      "quick",
      "quickreply",
      "reply",
      "speech",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "quiet_time",
    "version": 248,
    "popularity": 142,
    "codepoint": 57849,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "nest",
      "quiet ",
      "security ",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quiet_time_active",
    "version": 248,
    "popularity": 101,
    "codepoint": 58001,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "active",
      "home",
      "nest",
      "quiet ",
      "security ",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quiz",
    "version": 248,
    "popularity": 5633,
    "codepoint": 61516,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "?",
      "assistance",
      "faq",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "quiz",
      "support",
      "symbol",
      "test"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "quiz",
    "version": 10,
    "popularity": 28608,
    "codepoint": 61516,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "?",
      "assistance",
      "faq",
      "help",
      "info",
      "information",
      "punctuation",
      "question mark",
      "quiz",
      "support",
      "symbol",
      "test"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "r_mobiledata",
    "version": 248,
    "popularity": 118,
    "codepoint": 61517,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "letter",
      "mobile",
      "r",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "r_mobiledata",
    "version": 9,
    "popularity": 736,
    "codepoint": 61517,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alphabet",
      "character",
      "data",
      "font",
      "letter",
      "mobile",
      "r",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "radar",
    "version": 248,
    "popularity": 1163,
    "codepoint": 61518,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "detect",
      "military",
      "near",
      "network",
      "position",
      "radar",
      "scan"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "radar",
    "version": 10,
    "popularity": 7217,
    "codepoint": 61518,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "detect",
      "military",
      "near",
      "network",
      "position",
      "radar",
      "scan"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "radio",
    "version": 248,
    "popularity": 1341,
    "codepoint": 57406,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "antenna",
      "audio",
      "device",
      "frequency",
      "hardware",
      "listen",
      "media",
      "music",
      "player",
      "radio",
      "signal",
      "tune"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "radio",
    "version": 12,
    "popularity": 6861,
    "codepoint": 57406,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "antenna",
      "audio",
      "device",
      "frequency",
      "hardware",
      "listen",
      "media",
      "music",
      "player",
      "radio",
      "signal",
      "tune"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "radio_button_checked",
    "version": 248,
    "popularity": 18033,
    "codepoint": 59447,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "bullet",
      "button",
      "checked",
      "circle",
      "components",
      "design",
      "form",
      "interface",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "screen",
      "select",
      "selected",
      "site",
      "toggle",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "radio_button_checked",
    "version": 13,
    "popularity": 64154,
    "codepoint": 59447,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "app",
      "application",
      "bullet",
      "button",
      "checked",
      "circle",
      "components",
      "design",
      "form",
      "interface",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "screen",
      "select",
      "selected",
      "site",
      "toggle",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "radio_button_partial",
    "version": 248,
    "popularity": 27,
    "codepoint": 62816,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "bullet",
      "button",
      "checked",
      "circle",
      "components",
      "design",
      "form",
      "interface",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "screen",
      "select",
      "selected",
      "site",
      "toggle",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "radio_button_unchecked",
    "version": 248,
    "popularity": 18697,
    "codepoint": 59446,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bullet",
      "button",
      "circle",
      "deselected",
      "form",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "select",
      "toggle",
      "unchecked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "radio_button_unchecked",
    "version": 13,
    "popularity": 80211,
    "codepoint": 59446,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "bullet",
      "button",
      "circle",
      "deselected",
      "form",
      "off",
      "on",
      "point",
      "radio",
      "record",
      "select",
      "toggle",
      "unchecked"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "radiology",
    "version": 248,
    "popularity": 347,
    "codepoint": 57637,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bone",
      "bones",
      "health",
      "medical",
      "radiologists",
      "radiology",
      "scan"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "railway_alert",
    "version": 248,
    "popularity": 300,
    "codepoint": 59857,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "automobile",
      "bike",
      "car",
      "cars",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "maps",
      "mark",
      "notification",
      "public",
      "railway",
      "scooter",
      "subway",
      "symbol",
      "train",
      "transportation",
      "vehicle",
      "vespa",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "railway_alert",
    "version": 10,
    "popularity": 1588,
    "codepoint": 59857,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "automobile",
      "bike",
      "car",
      "cars",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "maps",
      "mark",
      "notification",
      "public",
      "railway",
      "scooter",
      "subway",
      "symbol",
      "train",
      "transportation",
      "vehicle",
      "vespa",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rainy",
    "version": 248,
    "popularity": 2139,
    "codepoint": 61814,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "snow",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rainy_heavy",
    "version": 248,
    "popularity": 3,
    "codepoint": 63007,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "snow",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rainy_light",
    "version": 248,
    "popularity": 5,
    "codepoint": 63006,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "snow",
      "temperature",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rainy_snow",
    "version": 248,
    "popularity": 10,
    "codepoint": 63005,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "rain",
      "rainfall",
      "showers",
      "sleet",
      "snow",
      "snowing",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ramen_dining",
    "version": 248,
    "popularity": 1222,
    "codepoint": 60004,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "drink",
      "fastfood",
      "food",
      "lunch",
      "meal",
      "noodles",
      "ramen",
      "restaurant"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ramen_dining",
    "version": 10,
    "popularity": 7009,
    "codepoint": 60004,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "drink",
      "fastfood",
      "food",
      "lunch",
      "meal",
      "noodles",
      "ramen",
      "restaurant"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ramp_left",
    "version": 248,
    "popularity": 149,
    "codepoint": 60316,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "ramp",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ramp_left",
    "version": 1,
    "popularity": 496,
    "codepoint": 60316,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "ramp",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ramp_right",
    "version": 248,
    "popularity": 181,
    "codepoint": 60310,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "ramp",
      "right",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ramp_right",
    "version": 1,
    "popularity": 474,
    "codepoint": 60310,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "ramp",
      "right",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "range_hood",
    "version": 248,
    "popularity": 166,
    "codepoint": 57834,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "cook",
      "cooking",
      "device",
      "home",
      "house",
      "kitchen",
      "nest",
      "range hood",
      "restaurants"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rate_review",
    "version": 248,
    "popularity": 3945,
    "codepoint": 58720,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "comment",
      "feedback",
      "pen",
      "pencil",
      "rate",
      "review",
      "stars",
      "write"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rate_review",
    "version": 11,
    "popularity": 16033,
    "codepoint": 58720,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "comment",
      "feedback",
      "pen",
      "pencil",
      "rate",
      "review",
      "stars",
      "write"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "raven",
    "version": 248,
    "popularity": 21,
    "codepoint": 62805,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "animal",
      "bird",
      "creature",
      "pet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "raw_off",
    "version": 248,
    "popularity": 103,
    "codepoint": 61519,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "enabled",
      "font",
      "image",
      "letter",
      "off",
      "on",
      "original",
      "photo",
      "photography",
      "raw",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "raw_off",
    "version": 10,
    "popularity": 643,
    "codepoint": 61519,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "enabled",
      "font",
      "image",
      "letter",
      "off",
      "on",
      "original",
      "photo",
      "photography",
      "raw",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "raw_on",
    "version": 248,
    "popularity": 269,
    "codepoint": 61520,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "enabled",
      "font",
      "image",
      "letter",
      "off",
      "on",
      "original",
      "photo",
      "photography",
      "raw",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "raw_on",
    "version": 10,
    "popularity": 1151,
    "codepoint": 61520,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alphabet",
      "character",
      "disabled",
      "enabled",
      "font",
      "image",
      "letter",
      "off",
      "on",
      "original",
      "photo",
      "photography",
      "raw",
      "slash",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "read_more",
    "version": 248,
    "popularity": 1347,
    "codepoint": 61293,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "more",
      "read",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "read_more",
    "version": 13,
    "popularity": 0,
    "codepoint": 61293,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "more",
      "read",
      "text"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "readiness_score",
    "version": 248,
    "popularity": 92,
    "codepoint": 63197,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "azm",
      "control",
      "controls",
      "fast",
      "fitbit",
      "fitness",
      "gauge",
      "health",
      "heart rate variability",
      "hrv",
      "meter",
      "motion",
      "music",
      "score",
      "sleep",
      "slow",
      "speed",
      "speedometer",
      "velocity",
      "video",
      "wellness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "real_estate_agent",
    "version": 248,
    "popularity": 4070,
    "codepoint": 59194,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "agent",
      "architecture",
      "broker",
      "estate",
      "hand",
      "home",
      "house",
      "loan",
      "mortgage",
      "property",
      "real",
      "residence",
      "residential",
      "sales",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "real_estate_agent",
    "version": 3,
    "popularity": 10385,
    "codepoint": 59194,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "agent",
      "architecture",
      "broker",
      "estate",
      "hand",
      "home",
      "house",
      "loan",
      "mortgage",
      "property",
      "real",
      "residence",
      "residential",
      "sales",
      "social"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "rear_camera",
    "version": 248,
    "popularity": 56,
    "codepoint": 63170,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "back",
      "backside",
      "cell",
      "device",
      "fold",
      "foldable",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "photo",
      "photography",
      "picture",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rebase",
    "version": 248,
    "popularity": 253,
    "codepoint": 63557,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chart",
      "combine",
      "direction",
      "navigation",
      "path",
      "process",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rebase_edit",
    "version": 248,
    "popularity": 179,
    "codepoint": 63558,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chart",
      "combine",
      "compose",
      "create",
      "direction",
      "draft",
      "edit",
      "editing",
      "input",
      "navigation",
      "path",
      "pen",
      "pencil",
      "process",
      "workflow",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rebase_edit",
    "version": 1,
    "popularity": 1666,
    "codepoint": 63558,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chart",
      "combine",
      "compose",
      "create",
      "direction",
      "draft",
      "edit",
      "editing",
      "input",
      "navigation",
      "path",
      "pen",
      "pencil",
      "process",
      "workflow",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "receipt",
    "version": 248,
    "popularity": 6623,
    "codepoint": 59568,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "receipt",
    "version": 12,
    "popularity": 54389,
    "codepoint": 59568,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "receipt_long",
    "version": 248,
    "popularity": 13063,
    "codepoint": 61294,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "check",
      "document",
      "list",
      "long",
      "paper",
      "paperwork",
      "receipt",
      "record",
      "store",
      "transaction"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "receipt_long",
    "version": 12,
    "popularity": 57811,
    "codepoint": 61294,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "bill",
      "check",
      "document",
      "list",
      "long",
      "paper",
      "paperwork",
      "receipt",
      "record",
      "store",
      "transaction"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "recent_actors",
    "version": 248,
    "popularity": 911,
    "codepoint": 57407,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "account",
      "actors",
      "avatar",
      "card",
      "cards",
      "carousel",
      "face",
      "human",
      "layers",
      "list",
      "people",
      "person",
      "profile",
      "recent",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "recent_actors",
    "version": 12,
    "popularity": 8950,
    "codepoint": 57407,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "account",
      "actors",
      "avatar",
      "card",
      "cards",
      "carousel",
      "face",
      "human",
      "layers",
      "list",
      "people",
      "person",
      "profile",
      "recent",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "recent_patient",
    "version": 248,
    "popularity": 21,
    "codepoint": 63496,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bolt",
      "electric",
      "energy",
      "fast",
      "flash",
      "health",
      "human",
      "instant",
      "lightning",
      "person",
      "thunderbolt"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "recommend",
    "version": 248,
    "popularity": 3027,
    "codepoint": 59858,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "approved",
      "circle",
      "confirm",
      "favorite",
      "gesture",
      "hand",
      "like",
      "reaction",
      "recommend",
      "social",
      "support",
      "thumbs",
      "up",
      "well"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "recommend",
    "version": 14,
    "popularity": 14616,
    "codepoint": 59858,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "approved",
      "circle",
      "confirm",
      "favorite",
      "gesture",
      "hand",
      "like",
      "reaction",
      "recommend",
      "social",
      "support",
      "thumbs",
      "up",
      "well"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "record_voice_over",
    "version": 248,
    "popularity": 3976,
    "codepoint": 59679,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "dictation",
      "face",
      "human",
      "over",
      "people",
      "person",
      "profile",
      "record",
      "recording",
      "speak",
      "speaking",
      "speech",
      "transcript",
      "user",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "record_voice_over",
    "version": 12,
    "popularity": 25764,
    "codepoint": 59679,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "dictation",
      "face",
      "human",
      "over",
      "people",
      "person",
      "profile",
      "record",
      "recording",
      "speak",
      "speaking",
      "speech",
      "transcript",
      "user",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rectangle",
    "version": 248,
    "popularity": 563,
    "codepoint": 60244,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "four sides",
      "parallelograms",
      "polygons",
      "quadrilaterals",
      "recangle",
      "shape"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rectangle",
    "version": 1,
    "popularity": 2938,
    "codepoint": 60244,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "four sides",
      "parallelograms",
      "polygons",
      "quadrilaterals",
      "recangle",
      "shape"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "recycling",
    "version": 248,
    "popularity": 4393,
    "codepoint": 59232,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bio",
      "eco",
      "green",
      "loop",
      "recyclable",
      "recycle",
      "recycling",
      "rotate",
      "sustainability",
      "sustainable",
      "trash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "recycling",
    "version": 4,
    "popularity": 13397,
    "codepoint": 59232,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bio",
      "eco",
      "green",
      "loop",
      "recyclable",
      "recycle",
      "recycling",
      "rotate",
      "sustainability",
      "sustainable",
      "trash"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "redeem",
    "version": 248,
    "popularity": 6621,
    "codepoint": 59569,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cart",
      "cash",
      "certificate",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "gift",
      "giftcard",
      "money",
      "online",
      "pay",
      "payment",
      "present",
      "redeem",
      "shopping"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "redeem",
    "version": 12,
    "popularity": 22141,
    "codepoint": 59569,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "card",
      "cart",
      "cash",
      "certificate",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "gift",
      "giftcard",
      "money",
      "online",
      "pay",
      "payment",
      "present",
      "redeem",
      "shopping"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "redo",
    "version": 248,
    "popularity": 3272,
    "codepoint": 57690,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "backward",
      "forward",
      "next",
      "redo",
      "repeat",
      "rotate",
      "undo"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "redo",
    "version": 13,
    "popularity": 17020,
    "codepoint": 57690,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "arrow",
      "backward",
      "forward",
      "next",
      "redo",
      "repeat",
      "rotate",
      "undo"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "reduce_capacity",
    "version": 248,
    "popularity": 1199,
    "codepoint": 61980,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "body",
      "capacity",
      "covid",
      "decrease",
      "down",
      "human",
      "people",
      "person",
      "reduce",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reduce_capacity",
    "version": 6,
    "popularity": 6233,
    "codepoint": 61980,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arrow",
      "body",
      "capacity",
      "covid",
      "decrease",
      "down",
      "human",
      "people",
      "person",
      "reduce",
      "social"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "refresh",
    "version": 248,
    "popularity": 21400,
    "codepoint": 58837,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "navigation",
      "refresh",
      "renew",
      "right",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "refresh",
    "version": 16,
    "popularity": 91288,
    "codepoint": 58837,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "navigation",
      "refresh",
      "renew",
      "right",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "regular_expression",
    "version": 248,
    "popularity": 25,
    "codepoint": 63312,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "algorithm",
      "algorithms",
      "code",
      "coding",
      "find and replace",
      "pattern",
      "patterns",
      "string",
      "strings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "relax",
    "version": 248,
    "popularity": 44,
    "codepoint": 63196,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "calm",
      "chill",
      "chillout",
      "de-stress",
      "destress",
      "fitbit",
      "heart",
      "lax",
      "loosen up",
      "mood",
      "relax",
      "repose",
      "rest",
      "selfcare",
      "slow down",
      "stay loose",
      "unwind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "release_alert",
    "version": 248,
    "popularity": 80,
    "codepoint": 63060,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "award",
      "burst",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "new",
      "notification",
      "release",
      "releases",
      "seal",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remember_me",
    "version": 248,
    "popularity": 680,
    "codepoint": 61521,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "avatar",
      "device",
      "hardware",
      "human",
      "iOS",
      "identity",
      "me",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "remember",
      "tablet",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remember_me",
    "version": 10,
    "popularity": 0,
    "codepoint": 61521,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "avatar",
      "device",
      "hardware",
      "human",
      "iOS",
      "identity",
      "me",
      "mobile",
      "people",
      "person",
      "phone",
      "profile",
      "remember",
      "tablet",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "reminder",
    "version": 248,
    "popularity": 134,
    "codepoint": 59029,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alert",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "hint",
      "notice",
      "notification",
      "remember",
      "reminder",
      "reminders"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remote_gen",
    "version": 248,
    "popularity": 447,
    "codepoint": 59454,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "TV",
      "control",
      "device",
      "hardware",
      "monitor",
      "nest",
      "remote",
      "television"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove",
    "version": 248,
    "popularity": 16413,
    "codepoint": 57691,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "can",
      "delete",
      "minus",
      "negative",
      "remove",
      "substract",
      "trash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove",
    "version": 16,
    "popularity": 73874,
    "codepoint": 57691,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "can",
      "delete",
      "minus",
      "negative",
      "remove",
      "substract",
      "trash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "remove_circle",
    "version": 19,
    "popularity": 40473,
    "codepoint": 57692,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "block",
      "can",
      "circle",
      "delete",
      "minus",
      "negative",
      "remove",
      "substract",
      "trash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "remove_circle_outline",
    "version": 12,
    "popularity": 0,
    "codepoint": 57693,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "block",
      "can",
      "circle",
      "delete",
      "minus",
      "negative",
      "outline",
      "remove",
      "substract",
      "trash"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "remove_done",
    "version": 248,
    "popularity": 1088,
    "codepoint": 59859,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "disabled",
      "done",
      "enabled",
      "finished",
      "mark",
      "multiple",
      "off",
      "ok",
      "on",
      "remove",
      "select",
      "slash",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove_done",
    "version": 10,
    "popularity": 6645,
    "codepoint": 59859,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "disabled",
      "done",
      "enabled",
      "finished",
      "mark",
      "multiple",
      "off",
      "ok",
      "on",
      "remove",
      "select",
      "slash",
      "tick",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "remove_from_queue",
    "version": 248,
    "popularity": 185,
    "codepoint": 57447,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "desktop",
      "device",
      "display",
      "from",
      "hardware",
      "monitor",
      "queue",
      "remove",
      "screen",
      "stream"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove_from_queue",
    "version": 13,
    "popularity": 1537,
    "codepoint": 57447,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "desktop",
      "device",
      "display",
      "from",
      "hardware",
      "monitor",
      "queue",
      "remove",
      "screen",
      "stream"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "remove_moderator",
    "version": 248,
    "popularity": 441,
    "codepoint": 59860,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "certified",
      "disabled",
      "enabled",
      "moderator",
      "off",
      "on",
      "privacy",
      "private",
      "protect",
      "protection",
      "remove",
      "security",
      "shield",
      "slash",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove_moderator",
    "version": 11,
    "popularity": 3362,
    "codepoint": 59860,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "certified",
      "disabled",
      "enabled",
      "moderator",
      "off",
      "on",
      "privacy",
      "private",
      "protect",
      "protection",
      "remove",
      "security",
      "shield",
      "slash",
      "verified"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "remove_red_eye",
    "version": 12,
    "popularity": 30902,
    "codepoint": 58391,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "eye",
      "iris",
      "look",
      "looking",
      "preview",
      "red",
      "remove",
      "see",
      "sight",
      "vision"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "remove_road",
    "version": 248,
    "popularity": 311,
    "codepoint": 60412,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "-",
      "cancel",
      "clear",
      "close",
      "destination",
      "direction",
      "exit",
      "highway",
      "maps",
      "minus",
      "new",
      "no",
      "remove",
      "road",
      "stop",
      "street",
      "symbol",
      "traffic",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove_road",
    "version": 1,
    "popularity": 884,
    "codepoint": 60412,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "-",
      "cancel",
      "clear",
      "close",
      "destination",
      "direction",
      "exit",
      "highway",
      "maps",
      "minus",
      "new",
      "no",
      "remove",
      "road",
      "stop",
      "street",
      "symbol",
      "traffic",
      "x"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "remove_selection",
    "version": 248,
    "popularity": 46,
    "codepoint": 59861,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "all",
      "clear",
      "dash",
      "dashed",
      "delete",
      "remove",
      "select",
      "selection",
      "square",
      "tool",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove_shopping_cart",
    "version": 248,
    "popularity": 963,
    "codepoint": 59688,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "delete",
      "dollars",
      "minus",
      "online",
      "pay",
      "payment",
      "remember",
      "remove",
      "ribbon",
      "save",
      "shopping",
      "subtract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "remove_shopping_cart",
    "version": 12,
    "popularity": 8005,
    "codepoint": 59688,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "delete",
      "dollars",
      "minus",
      "online",
      "pay",
      "payment",
      "remember",
      "remove",
      "ribbon",
      "save",
      "shopping",
      "subtract"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "reopen_window",
    "version": 248,
    "popularity": 13,
    "codepoint": 63240,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "auto",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "mode",
      "navigation",
      "nest",
      "renew",
      "rotate",
      "screen",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reorder",
    "version": 248,
    "popularity": 2334,
    "codepoint": 59646,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reorder",
    "version": 16,
    "popularity": 45395,
    "codepoint": 59646,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "repartition",
    "version": 248,
    "popularity": 592,
    "codepoint": 63720,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "data",
      "partition",
      "refresh",
      "renew",
      "repartition",
      "restore",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "repartition",
    "version": 1,
    "popularity": 872,
    "codepoint": 63720,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "data",
      "partition",
      "refresh",
      "renew",
      "repartition",
      "restore",
      "table"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "repeat",
    "version": 248,
    "popularity": 2476,
    "codepoint": 57408,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "loop",
      "media",
      "music",
      "repeat",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "repeat",
    "version": 12,
    "popularity": 13620,
    "codepoint": 57408,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "loop",
      "media",
      "music",
      "repeat",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "repeat_on",
    "version": 248,
    "popularity": 412,
    "codepoint": 59862,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "loop",
      "media",
      "music",
      "on",
      "repeat",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "repeat_on",
    "version": 12,
    "popularity": 2939,
    "codepoint": 59862,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "loop",
      "media",
      "music",
      "on",
      "repeat",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "repeat_one",
    "version": 248,
    "popularity": 553,
    "codepoint": 57409,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "1",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "loop",
      "media",
      "music",
      "number",
      "one",
      "repeat",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "repeat_one",
    "version": 12,
    "popularity": 3395,
    "codepoint": 57409,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "1",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "loop",
      "media",
      "music",
      "number",
      "one",
      "repeat",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "repeat_one_on",
    "version": 248,
    "popularity": 233,
    "codepoint": 59863,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "loop",
      "media",
      "music",
      "number",
      "on",
      "one",
      "repeat",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "repeat_one_on",
    "version": 12,
    "popularity": 1628,
    "codepoint": 59863,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "loop",
      "media",
      "music",
      "number",
      "on",
      "one",
      "repeat",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "replay",
    "version": 248,
    "popularity": 4808,
    "codepoint": 57410,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "replay",
    "version": 13,
    "popularity": 32156,
    "codepoint": 57410,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "replay_10",
    "version": 248,
    "popularity": 984,
    "codepoint": 57433,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "10",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "ten",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "replay_10",
    "version": 14,
    "popularity": 6059,
    "codepoint": 57433,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "10",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "ten",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "replay_30",
    "version": 248,
    "popularity": 332,
    "codepoint": 57434,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "30",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "thirty",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "replay_30",
    "version": 14,
    "popularity": 2949,
    "codepoint": 57434,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "30",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "thirty",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "replay_5",
    "version": 248,
    "popularity": 435,
    "codepoint": 57435,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "5",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "five",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "replay_5",
    "version": 14,
    "popularity": 2342,
    "codepoint": 57435,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "5",
      "arrow",
      "arrows",
      "control",
      "controls",
      "digit",
      "five",
      "music",
      "number",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "symbol",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "replay_circle_filled",
    "version": 12,
    "popularity": 6798,
    "codepoint": 59864,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "arrows",
      "circle",
      "control",
      "controls",
      "filled",
      "music",
      "refresh",
      "renew",
      "repeat",
      "replay",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "reply",
    "version": 248,
    "popularity": 6125,
    "codepoint": 57694,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "backward",
      "left",
      "mail",
      "message",
      "reply",
      "send",
      "share"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reply",
    "version": 20,
    "popularity": 42650,
    "codepoint": 57694,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "arrow",
      "backward",
      "left",
      "mail",
      "message",
      "reply",
      "send",
      "share"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "reply_all",
    "version": 248,
    "popularity": 1222,
    "codepoint": 57695,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "arrow",
      "backward",
      "group",
      "left",
      "mail",
      "message",
      "multiple",
      "reply",
      "send",
      "share"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reply_all",
    "version": 17,
    "popularity": 7609,
    "codepoint": 57695,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "all",
      "arrow",
      "backward",
      "group",
      "left",
      "mail",
      "message",
      "multiple",
      "reply",
      "send",
      "share"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "report",
    "version": 248,
    "popularity": 7591,
    "codepoint": 57696,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "octagon",
      "report",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "report",
    "version": 18,
    "popularity": 29151,
    "codepoint": 57696,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "octagon",
      "report",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "report_gmailerrorred",
    "version": 11,
    "popularity": 13106,
    "codepoint": 61522,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "gmail",
      "gmailerrorred",
      "important",
      "mark",
      "notification",
      "octagon",
      "report",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "report_off",
    "version": 248,
    "popularity": 409,
    "codepoint": 57712,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "disabled",
      "enabled",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "octagon",
      "off",
      "offline",
      "on",
      "report",
      "slash",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "report_off",
    "version": 12,
    "popularity": 2222,
    "codepoint": 57712,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "disabled",
      "enabled",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "octagon",
      "off",
      "offline",
      "on",
      "report",
      "slash",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "report_problem",
    "version": 18,
    "popularity": 79803,
    "codepoint": 59570,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "feedback",
      "important",
      "mark",
      "notification",
      "problem",
      "report",
      "symbol",
      "triangle",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "request_page",
    "version": 248,
    "popularity": 770,
    "codepoint": 61996,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "request",
      "sheet",
      "slide",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "request_page",
    "version": 7,
    "popularity": 6818,
    "codepoint": 61996,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "request",
      "sheet",
      "slide",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "request_quote",
    "version": 248,
    "popularity": 3985,
    "codepoint": 61878,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "quote",
      "request",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "request_quote",
    "version": 7,
    "popularity": 30032,
    "codepoint": 61878,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "quote",
      "request",
      "shopping",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "reset_image",
    "version": 248,
    "popularity": 47,
    "codepoint": 63524,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "arrows",
      "back",
      "image",
      "photo",
      "recover",
      "redo",
      "refresh",
      "undo"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reset_tv",
    "version": 248,
    "popularity": 248,
    "codepoint": 59865,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "device",
      "hardware",
      "monitor",
      "reset",
      "television",
      "tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reset_tv",
    "version": 10,
    "popularity": 1900,
    "codepoint": 59865,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "arrow",
      "device",
      "hardware",
      "monitor",
      "reset",
      "television",
      "tv"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "reset_wrench",
    "version": 248,
    "popularity": 15,
    "codepoint": 62828,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "adjust",
      "around",
      "build",
      "fix",
      "inprogress",
      "load",
      "loading refresh",
      "modem",
      "renew",
      "repair",
      "rotate",
      "tool",
      "tools",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "resize",
    "version": 248,
    "popularity": 28,
    "codepoint": 63239,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "dash",
      "dashes",
      "frame",
      "sizing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "respiratory_rate",
    "version": 248,
    "popularity": 4,
    "codepoint": 57639,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "doctor",
      "health",
      "lung",
      "lungs",
      "measure",
      "medical",
      "medicine",
      "monitor"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "responsive_layout",
    "version": 248,
    "popularity": 43,
    "codepoint": 59866,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "orientation",
      "phone",
      "rectangles",
      "responsive",
      "screen",
      "site",
      "size",
      "tablet",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "restart_alt",
    "version": 248,
    "popularity": 10207,
    "codepoint": 61523,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "around",
      "arrow",
      "inprogress",
      "load",
      "loading refresh",
      "reboot",
      "renew",
      "repeat",
      "reset",
      "restart"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "restart_alt",
    "version": 10,
    "popularity": 51245,
    "codepoint": 61523,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alt",
      "around",
      "arrow",
      "inprogress",
      "load",
      "loading refresh",
      "reboot",
      "renew",
      "repeat",
      "reset",
      "restart"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "restaurant",
    "version": 248,
    "popularity": 9054,
    "codepoint": 58732,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "lunch",
      "meal",
      "places",
      "restaurant",
      "spoon",
      "utensils"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "restaurant",
    "version": 16,
    "popularity": 42935,
    "codepoint": 58732,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "dining",
      "dinner",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "lunch",
      "meal",
      "places",
      "restaurant",
      "spoon",
      "utensils"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "restaurant_menu",
    "version": 248,
    "popularity": 4139,
    "codepoint": 58721,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "book",
      "dining",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "meal",
      "menu",
      "restaurant",
      "spoon"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "restaurant_menu",
    "version": 12,
    "popularity": 23400,
    "codepoint": 58721,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "book",
      "dining",
      "eat",
      "food",
      "fork",
      "knife",
      "local",
      "meal",
      "menu",
      "restaurant",
      "spoon"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "restore",
    "version": 12,
    "popularity": 20943,
    "codepoint": 59571,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "history",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "schedule",
      "time",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "restore_from_trash",
    "version": 248,
    "popularity": 1401,
    "codepoint": 59704,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "history",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "schedule",
      "time",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "restore_from_trash",
    "version": 12,
    "popularity": 7213,
    "codepoint": 59704,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "history",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "schedule",
      "time",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "restore_page",
    "version": 248,
    "popularity": 578,
    "codepoint": 59689,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "file",
      "page",
      "paper",
      "refresh",
      "restore",
      "rotate",
      "sheet",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "restore_page",
    "version": 12,
    "popularity": 5552,
    "codepoint": 59689,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "file",
      "page",
      "paper",
      "refresh",
      "restore",
      "rotate",
      "sheet",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "resume",
    "version": 248,
    "popularity": 25,
    "codepoint": 63440,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "continue",
      "control",
      "controls",
      "media",
      "music",
      "play",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reviews",
    "version": 248,
    "popularity": 2871,
    "codepoint": 61524,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "rate",
      "rating",
      "recommendation",
      "reviews",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "reviews",
    "version": 10,
    "popularity": 14360,
    "codepoint": 61524,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "rate",
      "rating",
      "recommendation",
      "reviews",
      "speech"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rewarded_ads",
    "version": 248,
    "popularity": 184,
    "codepoint": 61366,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "achievement",
      "ads",
      "award",
      "chalice",
      "champion",
      "cup",
      "first",
      "prize",
      "reward",
      "rewarded",
      "sport",
      "trophy",
      "winner"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rheumatology",
    "version": 248,
    "popularity": 272,
    "codepoint": 57640,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arthritis",
      "bone",
      "bones",
      "health",
      "joints",
      "medical",
      "rheumatic",
      "rheumatism",
      "rheumatologists",
      "rheumatology"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rib_cage",
    "version": 248,
    "popularity": 316,
    "codepoint": 63640,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bone",
      "bones",
      "chest",
      "health",
      "medical",
      "ribs",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rice_bowl",
    "version": 248,
    "popularity": 388,
    "codepoint": 61941,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bowl",
      "dinner",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "rice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rice_bowl",
    "version": 6,
    "popularity": 2428,
    "codepoint": 61941,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bowl",
      "dinner",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "rice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "right_click",
    "version": 248,
    "popularity": 20,
    "codepoint": 63238,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "circle",
      "clicks",
      "mouse",
      "move",
      "select",
      "selection",
      "selects"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "right_panel_close",
    "version": 248,
    "popularity": 54,
    "codepoint": 63237,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "right_panel_open",
    "version": 248,
    "popularity": 48,
    "codepoint": 63236,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ring_volume",
    "version": 248,
    "popularity": 553,
    "codepoint": 57553,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "calling",
      "cell",
      "contact",
      "device",
      "hardware",
      "incoming",
      "mobile",
      "phone",
      "ring",
      "ringer",
      "sound",
      "telephone",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ring_volume",
    "version": 13,
    "popularity": 3840,
    "codepoint": 57553,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "calling",
      "cell",
      "contact",
      "device",
      "hardware",
      "incoming",
      "mobile",
      "phone",
      "ring",
      "ringer",
      "sound",
      "telephone",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "ripples",
    "version": 248,
    "popularity": 15,
    "codepoint": 59867,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "components",
      "design",
      "function",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "ripples",
      "screen",
      "site",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "robot",
    "version": 248,
    "popularity": 97,
    "codepoint": 63618,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "bot",
      "droid",
      "games",
      "robot",
      "smart",
      "toy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "robot_2",
    "version": 248,
    "popularity": 80,
    "codepoint": 62928,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "bot",
      "droid",
      "games",
      "robot",
      "smart",
      "toy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rocket",
    "version": 248,
    "popularity": 2368,
    "codepoint": 60325,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "rocket",
      "space",
      "spaceship"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rocket",
    "version": 1,
    "popularity": 8115,
    "codepoint": 60325,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "rocket",
      "space",
      "spaceship"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "rocket_launch",
    "version": 248,
    "popularity": 10193,
    "codepoint": 60315,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "launch",
      "rocket",
      "space",
      "spaceship",
      "takeoff"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rocket_launch",
    "version": 1,
    "popularity": 27097,
    "codepoint": 60315,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "launch",
      "rocket",
      "space",
      "spaceship",
      "takeoff"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "roller_shades",
    "version": 248,
    "popularity": 220,
    "codepoint": 60434,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "roller",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "roller_shades",
    "version": 1,
    "popularity": 861,
    "codepoint": 60434,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "roller",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "roller_shades_closed",
    "version": 248,
    "popularity": 191,
    "codepoint": 60433,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "roller",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "roller_shades_closed",
    "version": 1,
    "popularity": 664,
    "codepoint": 60433,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "roller",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "roller_skating",
    "version": 248,
    "popularity": 423,
    "codepoint": 60365,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "roller",
      "shoe",
      "skate",
      "skates",
      "skating",
      "social",
      "sports",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "roller_skating",
    "version": 1,
    "popularity": 893,
    "codepoint": 60365,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "roller",
      "shoe",
      "skate",
      "skates",
      "skating",
      "social",
      "sports",
      "travel"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "roofing",
    "version": 248,
    "popularity": 1244,
    "codepoint": 61953,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "building",
      "chimney",
      "construction",
      "estate",
      "home",
      "house",
      "real",
      "residence",
      "residential",
      "roof",
      "roofing",
      "service",
      "shelter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "roofing",
    "version": 6,
    "popularity": 7611,
    "codepoint": 61953,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "building",
      "chimney",
      "construction",
      "estate",
      "home",
      "house",
      "real",
      "residence",
      "residential",
      "roof",
      "roofing",
      "service",
      "shelter"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "room",
    "version": 19,
    "popularity": 56560,
    "codepoint": 59572,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "destination",
      "direction",
      "location",
      "maps",
      "pin",
      "place",
      "room",
      "stop"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "room_preferences",
    "version": 248,
    "popularity": 948,
    "codepoint": 61880,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "building",
      "door",
      "doorway",
      "entrance",
      "gear",
      "home",
      "house",
      "interior",
      "office",
      "open",
      "preferences",
      "room",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "room_preferences",
    "version": 7,
    "popularity": 7010,
    "codepoint": 61880,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "building",
      "door",
      "doorway",
      "entrance",
      "gear",
      "home",
      "house",
      "interior",
      "office",
      "open",
      "preferences",
      "room",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "room_service",
    "version": 248,
    "popularity": 1494,
    "codepoint": 60233,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "alert",
      "bell",
      "delivery",
      "hotel",
      "notify",
      "room",
      "service"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "room_service",
    "version": 11,
    "popularity": 7643,
    "codepoint": 60233,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "alert",
      "bell",
      "delivery",
      "hotel",
      "notify",
      "room",
      "service"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rotate_90_degrees_ccw",
    "version": 248,
    "popularity": 476,
    "codepoint": 58392,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "90",
      "arrow",
      "arrows",
      "ccw",
      "degrees",
      "direction",
      "edit",
      "editing",
      "image",
      "photo",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rotate_90_degrees_ccw",
    "version": 12,
    "popularity": 3242,
    "codepoint": 58392,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "90",
      "arrow",
      "arrows",
      "ccw",
      "degrees",
      "direction",
      "edit",
      "editing",
      "image",
      "photo",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rotate_90_degrees_cw",
    "version": 248,
    "popularity": 418,
    "codepoint": 60075,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "90",
      "arrow",
      "arrows",
      "ccw",
      "degrees",
      "direction",
      "edit",
      "editing",
      "image",
      "photo",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rotate_90_degrees_cw",
    "version": 2,
    "popularity": 2199,
    "codepoint": 60075,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "90",
      "arrow",
      "arrows",
      "ccw",
      "degrees",
      "direction",
      "edit",
      "editing",
      "image",
      "photo",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "rotate_left",
    "version": 248,
    "popularity": 1153,
    "codepoint": 58393,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "around",
      "arrow",
      "dash",
      "dashed",
      "direction",
      "inprogress",
      "left",
      "load",
      "loading refresh",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rotate_left",
    "version": 13,
    "popularity": 7109,
    "codepoint": 58393,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "around",
      "arrow",
      "dash",
      "dashed",
      "direction",
      "inprogress",
      "left",
      "load",
      "loading refresh",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rotate_right",
    "version": 248,
    "popularity": 1888,
    "codepoint": 58394,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "around",
      "arrow",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "right",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rotate_right",
    "version": 13,
    "popularity": 10169,
    "codepoint": 58394,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "around",
      "arrow",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "right",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "roundabout_left",
    "version": 248,
    "popularity": 177,
    "codepoint": 60313,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "roundabout",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "roundabout_left",
    "version": 1,
    "popularity": 638,
    "codepoint": 60313,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "roundabout",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "roundabout_right",
    "version": 248,
    "popularity": 237,
    "codepoint": 60323,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "roundabout",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "roundabout_right",
    "version": 1,
    "popularity": 852,
    "codepoint": 60323,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "roundabout",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "rounded_corner",
    "version": 248,
    "popularity": 426,
    "codepoint": 59680,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "adjust",
      "corner",
      "dash",
      "dashed",
      "edit",
      "rounded",
      "shape",
      "square",
      "transform"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rounded_corner",
    "version": 15,
    "popularity": 2419,
    "codepoint": 59680,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "adjust",
      "corner",
      "dash",
      "dashed",
      "edit",
      "rounded",
      "shape",
      "square",
      "transform"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "route",
    "version": 248,
    "popularity": 2343,
    "codepoint": 60109,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "directions",
      "maps",
      "path",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "route",
    "version": 2,
    "popularity": 9608,
    "codepoint": 60109,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "directions",
      "maps",
      "path",
      "route",
      "sign",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "router",
    "version": 248,
    "popularity": 1577,
    "codepoint": 58152,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "box",
      "cable",
      "connection",
      "hardware",
      "internet",
      "network",
      "router",
      "signal",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "router",
    "version": 13,
    "popularity": 9131,
    "codepoint": 58152,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "box",
      "cable",
      "connection",
      "hardware",
      "internet",
      "network",
      "router",
      "signal",
      "wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "routine",
    "version": 248,
    "popularity": 816,
    "codepoint": 57868,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "lunar",
      "moon",
      "nest",
      "order",
      "pattern",
      "routine",
      "schedule",
      "sun",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rowing",
    "version": 248,
    "popularity": 417,
    "codepoint": 59681,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "activity",
      "boat",
      "body",
      "canoe",
      "human",
      "people",
      "person",
      "row",
      "rowing",
      "sport",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rowing",
    "version": 14,
    "popularity": 5941,
    "codepoint": 59681,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "activity",
      "boat",
      "body",
      "canoe",
      "human",
      "people",
      "person",
      "row",
      "rowing",
      "sport",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rss_feed",
    "version": 248,
    "popularity": 2538,
    "codepoint": 57573,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "application",
      "blog",
      "connection",
      "data",
      "feed",
      "internet",
      "network",
      "rss",
      "service",
      "signal",
      "website",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rss_feed",
    "version": 12,
    "popularity": 15996,
    "codepoint": 57573,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "application",
      "blog",
      "connection",
      "data",
      "feed",
      "internet",
      "network",
      "rss",
      "service",
      "signal",
      "website",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rsvp",
    "version": 248,
    "popularity": 396,
    "codepoint": 61525,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "invitation",
      "invite",
      "letter",
      "plaît",
      "respond",
      "rsvp",
      "répondez",
      "sil",
      "symbol",
      "text",
      "type",
      "vous"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rsvp",
    "version": 10,
    "popularity": 1137,
    "codepoint": 61525,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "invitation",
      "invite",
      "letter",
      "plaît",
      "respond",
      "rsvp",
      "répondez",
      "sil",
      "symbol",
      "text",
      "type",
      "vous"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rtt",
    "version": 248,
    "popularity": 292,
    "codepoint": 59821,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "real",
      "rrt",
      "text",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rtt",
    "version": 15,
    "popularity": 1737,
    "codepoint": 59821,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "real",
      "rrt",
      "text",
      "time"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rubric",
    "version": 248,
    "popularity": 47,
    "codepoint": 60199,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "check",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "ok",
      "rubric",
      "sheet",
      "spreadsheet",
      "statistics",
      "tick",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rule",
    "version": 248,
    "popularity": 2895,
    "codepoint": 61890,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "incomplete",
      "line",
      "mark",
      "missing",
      "no",
      "ok",
      "rule",
      "select",
      "tick",
      "validate",
      "verified",
      "wrong",
      "x",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rule",
    "version": 8,
    "popularity": 24465,
    "codepoint": 61890,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "incomplete",
      "line",
      "mark",
      "missing",
      "no",
      "ok",
      "rule",
      "select",
      "tick",
      "validate",
      "verified",
      "wrong",
      "x",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rule_folder",
    "version": 248,
    "popularity": 553,
    "codepoint": 61897,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "approve",
      "cancel",
      "check",
      "close",
      "complete",
      "data",
      "doc",
      "document",
      "done",
      "drive",
      "exit",
      "file",
      "folder",
      "mark",
      "no",
      "ok",
      "remove",
      "rule",
      "select",
      "sheet",
      "slide",
      "storage",
      "tick",
      "validate",
      "verified",
      "x",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rule_folder",
    "version": 7,
    "popularity": 4802,
    "codepoint": 61897,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "approve",
      "cancel",
      "check",
      "close",
      "complete",
      "data",
      "doc",
      "document",
      "done",
      "drive",
      "exit",
      "file",
      "folder",
      "mark",
      "no",
      "ok",
      "remove",
      "rule",
      "select",
      "sheet",
      "slide",
      "storage",
      "tick",
      "validate",
      "verified",
      "x",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "rule_settings",
    "version": 248,
    "popularity": 58,
    "codepoint": 63052,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "360",
      "application",
      "around",
      "arrow",
      "arrows",
      "change",
      "custom",
      "customize",
      "details",
      "direction",
      "gear",
      "info",
      "information",
      "inprogress",
      "load",
      "loading refresh",
      "options",
      "renew",
      "rotate",
      "service",
      "setting",
      "sync",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "run_circle",
    "version": 248,
    "popularity": 508,
    "codepoint": 61295,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "body",
      "circle",
      "exercise",
      "human",
      "people",
      "person",
      "run",
      "running"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "run_circle",
    "version": 11,
    "popularity": 3738,
    "codepoint": 61295,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "body",
      "circle",
      "exercise",
      "human",
      "people",
      "person",
      "run",
      "running"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "running_with_errors",
    "version": 248,
    "popularity": 1269,
    "codepoint": 58653,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "duration",
      "error",
      "errors",
      "exclamation",
      "important",
      "mark",
      "notification",
      "process",
      "processing",
      "running",
      "symbol",
      "time",
      "warning",
      "with"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "running_with_errors",
    "version": 4,
    "popularity": 6219,
    "codepoint": 58653,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "duration",
      "error",
      "errors",
      "exclamation",
      "important",
      "mark",
      "notification",
      "process",
      "processing",
      "running",
      "symbol",
      "time",
      "warning",
      "with"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "rv_hookup",
    "version": 248,
    "popularity": 461,
    "codepoint": 58946,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "attach",
      "automobile",
      "automotive",
      "back",
      "car",
      "cars",
      "connect",
      "direction",
      "hookup",
      "left",
      "maps",
      "public",
      "right",
      "rv",
      "trailer",
      "transportation",
      "travel",
      "truck",
      "van",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "rv_hookup",
    "version": 12,
    "popularity": 2923,
    "codepoint": 58946,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "arrow",
      "attach",
      "automobile",
      "automotive",
      "back",
      "car",
      "cars",
      "connect",
      "direction",
      "hookup",
      "left",
      "maps",
      "public",
      "right",
      "rv",
      "trailer",
      "transportation",
      "travel",
      "truck",
      "van",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "safety_check",
    "version": 248,
    "popularity": 1094,
    "codepoint": 60399,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "certified",
      "check",
      "clock",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "schedule",
      "security",
      "shield",
      "time",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "safety_check",
    "version": 1,
    "popularity": 2214,
    "codepoint": 60399,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "certified",
      "check",
      "clock",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "schedule",
      "security",
      "shield",
      "time",
      "verified"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "safety_check_off",
    "version": 248,
    "popularity": 8,
    "codepoint": 62877,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "certified",
      "check",
      "clock",
      "disabled",
      "enabled",
      "offline",
      "on",
      "privacy",
      "private",
      "protect",
      "protection",
      "safety",
      "schedule",
      "security",
      "shield",
      "slash",
      "time",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "safety_divider",
    "version": 248,
    "popularity": 533,
    "codepoint": 57804,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "apart",
      "distance",
      "divider",
      "safety",
      "separate",
      "social",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "safety_divider",
    "version": 7,
    "popularity": 3157,
    "codepoint": 57804,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "apart",
      "distance",
      "divider",
      "safety",
      "separate",
      "social",
      "space"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sailing",
    "version": 248,
    "popularity": 1533,
    "codepoint": 58626,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "boat",
      "entertainment",
      "fishing",
      "hobby",
      "ocean",
      "sailboat",
      "sailing",
      "sea",
      "social sports",
      "travel",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sailing",
    "version": 4,
    "popularity": 7669,
    "codepoint": 58626,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "boat",
      "entertainment",
      "fishing",
      "hobby",
      "ocean",
      "sailboat",
      "sailing",
      "sea",
      "social sports",
      "travel",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "salinity",
    "version": 248,
    "popularity": 3,
    "codepoint": 63606,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "droplet",
      "saline",
      "salt",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sanitizer",
    "version": 248,
    "popularity": 931,
    "codepoint": 61981,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bacteria",
      "bottle",
      "clean",
      "covid",
      "disinfect",
      "germs",
      "pump",
      "sanitizer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sanitizer",
    "version": 6,
    "popularity": 4640,
    "codepoint": 61981,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bacteria",
      "bottle",
      "clean",
      "covid",
      "disinfect",
      "germs",
      "pump",
      "sanitizer"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "satellite",
    "version": 248,
    "popularity": 459,
    "codepoint": 58722,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "data",
      "device",
      "image",
      "internet",
      "landscape",
      "location",
      "maps",
      "mountain",
      "mountains",
      "network",
      "photo",
      "photography",
      "picture",
      "satellite",
      "scan",
      "service",
      "signal",
      "symbol",
      "wireless-- wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "satellite",
    "version": 12,
    "popularity": 3298,
    "codepoint": 58722,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "data",
      "device",
      "image",
      "internet",
      "landscape",
      "location",
      "maps",
      "mountain",
      "mountains",
      "network",
      "photo",
      "photography",
      "picture",
      "satellite",
      "scan",
      "service",
      "signal",
      "symbol",
      "wireless-- wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "satellite_alt",
    "version": 248,
    "popularity": 1333,
    "codepoint": 60218,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alternative",
      "artificial",
      "communication",
      "satellite",
      "space",
      "space station",
      "television"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "satellite_alt",
    "version": 1,
    "popularity": 4694,
    "codepoint": 60218,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alternative",
      "artificial",
      "communication",
      "satellite",
      "space",
      "space station",
      "television"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sauna",
    "version": 248,
    "popularity": 6,
    "codepoint": 63223,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "bathe",
      "bathhouse",
      "body",
      "hot",
      "hot spring",
      "hot springs",
      "hot tub",
      "human",
      "people",
      "person",
      "spa",
      "steam",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "save",
    "version": 248,
    "popularity": 11520,
    "codepoint": 57697,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "data",
      "disk",
      "document",
      "drive",
      "file",
      "floppy",
      "multimedia",
      "save",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "save",
    "version": 12,
    "popularity": 81887,
    "codepoint": 57697,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "data",
      "disk",
      "document",
      "drive",
      "file",
      "floppy",
      "multimedia",
      "save",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "save_alt",
    "version": 12,
    "popularity": 22390,
    "codepoint": 57713,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "alt",
      "arrow",
      "disk",
      "document",
      "down",
      "file",
      "floppy",
      "multimedia",
      "save"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "save_as",
    "version": 248,
    "popularity": 1945,
    "codepoint": 60256,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "compose",
      "create",
      "data",
      "disk",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "floppy",
      "input",
      "multimedia",
      "pen",
      "pencil",
      "save",
      "storage",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "save_as",
    "version": 2,
    "popularity": 6491,
    "codepoint": 60256,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "compose",
      "create",
      "data",
      "disk",
      "document",
      "draft",
      "drive",
      "edit",
      "editing",
      "file",
      "floppy",
      "input",
      "multimedia",
      "pen",
      "pencil",
      "save",
      "storage",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "saved_search",
    "version": 248,
    "popularity": 1973,
    "codepoint": 59921,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "find",
      "glass",
      "important",
      "look",
      "magnify",
      "magnifying",
      "marked",
      "saved",
      "search",
      "see",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "saved_search",
    "version": 11,
    "popularity": 8574,
    "codepoint": 59921,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "find",
      "glass",
      "important",
      "look",
      "magnify",
      "magnifying",
      "marked",
      "saved",
      "search",
      "see",
      "star"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "savings",
    "version": 248,
    "popularity": 8562,
    "codepoint": 58091,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bank",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "pig",
      "piggy",
      "savings",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "savings",
    "version": 5,
    "popularity": 56828,
    "codepoint": 58091,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bank",
      "bill",
      "card",
      "cash",
      "coin",
      "commerce",
      "cost",
      "credit",
      "currency",
      "dollars",
      "finance",
      "money",
      "online",
      "pay",
      "payment",
      "pig",
      "piggy",
      "savings",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "scale",
    "version": 248,
    "popularity": 1471,
    "codepoint": 60255,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "measure",
      "monitor",
      "scale",
      "weight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scale",
    "version": 1,
    "popularity": 5097,
    "codepoint": 60255,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "measure",
      "monitor",
      "scale",
      "weight"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "scan",
    "version": 248,
    "popularity": 15,
    "codepoint": 63310,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "article",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "notes",
      "page",
      "paper",
      "scan",
      "scanner",
      "sheet",
      "slide",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scan_delete",
    "version": 248,
    "popularity": 34,
    "codepoint": 63311,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "data",
      "doc",
      "document",
      "drive",
      "exit",
      "file",
      "folder",
      "folders",
      "no",
      "notes",
      "off",
      "page",
      "paper",
      "remove",
      "scan",
      "scanner",
      "sheet",
      "slide",
      "stop",
      "text",
      "writing",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scanner",
    "version": 248,
    "popularity": 421,
    "codepoint": 58153,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "copy",
      "device",
      "hardware",
      "machine",
      "scan",
      "scanner"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scanner",
    "version": 12,
    "popularity": 2578,
    "codepoint": 58153,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "copy",
      "device",
      "hardware",
      "machine",
      "scan",
      "scanner"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "scatter_plot",
    "version": 248,
    "popularity": 825,
    "codepoint": 57960,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "circles",
      "data",
      "diagram",
      "dot",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "plot",
      "scatter",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scatter_plot",
    "version": 12,
    "popularity": 5524,
    "codepoint": 57960,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "circles",
      "data",
      "diagram",
      "dot",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "plot",
      "scatter",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "scene",
    "version": 248,
    "popularity": 575,
    "codepoint": 58023,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "IoT",
      "couch",
      "home",
      "house",
      "light",
      "nest",
      "room",
      "scene",
      "sofa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "schedule",
    "version": 248,
    "popularity": 34972,
    "codepoint": 59573,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "clock",
      "date",
      "history",
      "recent",
      "schedule",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "schedule",
    "version": 17,
    "popularity": 193514,
    "codepoint": 59573,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "clock",
      "date",
      "history",
      "recent",
      "schedule",
      "time"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "schedule_send",
    "version": 248,
    "popularity": 1451,
    "codepoint": 59914,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "calendar",
      "clock",
      "date",
      "email",
      "letter",
      "mail",
      "remember",
      "schedule",
      "send",
      "share",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "schedule_send",
    "version": 14,
    "popularity": 11831,
    "codepoint": 59914,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "clock",
      "date",
      "email",
      "letter",
      "mail",
      "remember",
      "schedule",
      "send",
      "share",
      "time"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "schema",
    "version": 248,
    "popularity": 2120,
    "codepoint": 58621,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "flow",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "schema",
      "squares",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "schema",
    "version": 4,
    "popularity": 6874,
    "codepoint": 58621,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "flow",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "schema",
      "squares",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "school",
    "version": 248,
    "popularity": 19028,
    "codepoint": 59404,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "academy",
      "achievement",
      "cap",
      "class",
      "college",
      "education",
      "graduation",
      "hat",
      "knowledge",
      "learning",
      "school",
      "university"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "school",
    "version": 13,
    "popularity": 93117,
    "codepoint": 59404,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "academy",
      "achievement",
      "cap",
      "class",
      "college",
      "education",
      "graduation",
      "hat",
      "knowledge",
      "learning",
      "school",
      "university"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "science",
    "version": 248,
    "popularity": 5477,
    "codepoint": 59979,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "beaker",
      "chemical",
      "chemistry",
      "experiment",
      "flask",
      "glass",
      "laboratory",
      "research",
      "science",
      "tube"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "science",
    "version": 17,
    "popularity": 25180,
    "codepoint": 59979,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "beaker",
      "chemical",
      "chemistry",
      "experiment",
      "flask",
      "glass",
      "laboratory",
      "research",
      "science",
      "tube"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "science_off",
    "version": 248,
    "popularity": 11,
    "codepoint": 62786,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "beaker",
      "chemical",
      "chemistry",
      "disabled",
      "enabled",
      "experiment",
      "flask",
      "glass",
      "laboratory",
      "off",
      "offline",
      "on",
      "research",
      "science",
      "slash",
      "tube"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "score",
    "version": 248,
    "popularity": 434,
    "codepoint": 57961,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "2k",
      "alphabet",
      "analytics",
      "bar",
      "bars",
      "character",
      "chart",
      "data",
      "diagram",
      "digit",
      "font",
      "graph",
      "infographic",
      "letter",
      "measure",
      "metrics",
      "number",
      "score",
      "statistics",
      "symbol",
      "text",
      "tracking",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "score",
    "version": 12,
    "popularity": 2652,
    "codepoint": 57961,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "2k",
      "alphabet",
      "analytics",
      "bar",
      "bars",
      "character",
      "chart",
      "data",
      "diagram",
      "digit",
      "font",
      "graph",
      "infographic",
      "letter",
      "measure",
      "metrics",
      "number",
      "score",
      "statistics",
      "symbol",
      "text",
      "tracking",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "scoreboard",
    "version": 248,
    "popularity": 700,
    "codepoint": 60368,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "board",
      "points",
      "score",
      "scoreboard",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scoreboard",
    "version": 1,
    "popularity": 1741,
    "codepoint": 60368,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "board",
      "points",
      "score",
      "scoreboard",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "screen_lock_landscape",
    "version": 248,
    "popularity": 178,
    "codepoint": 57790,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "lock",
      "mobile",
      "phone",
      "rotate",
      "screen",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_lock_landscape",
    "version": 16,
    "popularity": 1065,
    "codepoint": 57790,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "lock",
      "mobile",
      "phone",
      "rotate",
      "screen",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "screen_lock_portrait",
    "version": 248,
    "popularity": 253,
    "codepoint": 57791,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "lock",
      "mobile",
      "phone",
      "portrait",
      "rotate",
      "screen",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_lock_portrait",
    "version": 17,
    "popularity": 1549,
    "codepoint": 57791,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "lock",
      "mobile",
      "phone",
      "portrait",
      "rotate",
      "screen",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "screen_lock_rotation",
    "version": 248,
    "popularity": 148,
    "codepoint": 57792,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "lock",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_lock_rotation",
    "version": 16,
    "popularity": 1095,
    "codepoint": 57792,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "lock",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "screen_record",
    "version": 248,
    "popularity": 28,
    "codepoint": 63097,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "app",
      "application",
      "circle",
      "components",
      "interface",
      "record",
      "recorder",
      "screen",
      "select",
      "selected",
      "site",
      "toggle",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_rotation",
    "version": 248,
    "popularity": 833,
    "codepoint": 57793,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_rotation",
    "version": 12,
    "popularity": 4057,
    "codepoint": 57793,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "screen_rotation_alt",
    "version": 248,
    "popularity": 345,
    "codepoint": 60398,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_rotation_alt",
    "version": 1,
    "popularity": 863,
    "codepoint": 60398,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "screen_rotation_up",
    "version": 248,
    "popularity": 9,
    "codepoint": 63096,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "rotate",
      "rotation",
      "screen",
      "tablet",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_search_desktop",
    "version": 248,
    "popularity": 1072,
    "codepoint": 61296,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "lock",
      "monitor",
      "rotate",
      "screen",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_search_desktop",
    "version": 10,
    "popularity": 4305,
    "codepoint": 61296,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "desktop",
      "device",
      "hardware",
      "iOS",
      "lock",
      "monitor",
      "rotate",
      "screen",
      "web"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "screen_share",
    "version": 248,
    "popularity": 832,
    "codepoint": 57570,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "cast",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "mirror",
      "monitor",
      "screen",
      "share",
      "stream",
      "streaming",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screen_share",
    "version": 12,
    "popularity": 6924,
    "codepoint": 57570,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "cast",
      "chrome",
      "device",
      "display",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "mirror",
      "monitor",
      "screen",
      "share",
      "stream",
      "streaming",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "screenshot",
    "version": 248,
    "popularity": 431,
    "codepoint": 61526,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "crop",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "screenshot",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screenshot",
    "version": 10,
    "popularity": 2918,
    "codepoint": 61526,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "crop",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "screenshot",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "screenshot_frame",
    "version": 248,
    "popularity": 18,
    "codepoint": 63095,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "area",
      "capture",
      "crop",
      "frame",
      "screen",
      "screengrab"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screenshot_keyboard",
    "version": 248,
    "popularity": 13,
    "codepoint": 63443,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "capture",
      "screen",
      "screengrab"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screenshot_monitor",
    "version": 248,
    "popularity": 684,
    "codepoint": 60424,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screengrab",
      "screenshot",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screenshot_monitor",
    "version": 1,
    "popularity": 1615,
    "codepoint": 60424,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screengrab",
      "screenshot",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "screenshot_region",
    "version": 248,
    "popularity": 29,
    "codepoint": 63442,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "area",
      "capture",
      "crop",
      "frame",
      "new",
      "plus",
      "screen",
      "screengrab"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "screenshot_tablet",
    "version": 248,
    "popularity": 8,
    "codepoint": 63127,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "display",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "screen",
      "screengrab",
      "screenshot",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scrollable_header",
    "version": 248,
    "popularity": 9,
    "codepoint": 59868,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "design",
      "function",
      "header",
      "scrollable",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scuba_diving",
    "version": 248,
    "popularity": 405,
    "codepoint": 60366,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "diving",
      "entertainment",
      "exercise",
      "hobby",
      "scuba",
      "social",
      "swim",
      "swimming"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "scuba_diving",
    "version": 1,
    "popularity": 1044,
    "codepoint": 60366,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "diving",
      "entertainment",
      "exercise",
      "hobby",
      "scuba",
      "social",
      "swim",
      "swimming"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sd",
    "version": 248,
    "popularity": 224,
    "codepoint": 59869,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "camera",
      "card",
      "character",
      "data",
      "device",
      "digital",
      "drive",
      "flash",
      "font",
      "image",
      "letter",
      "memory",
      "photo",
      "sd",
      "secure",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sd",
    "version": 10,
    "popularity": 1332,
    "codepoint": 59869,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alphabet",
      "camera",
      "card",
      "character",
      "data",
      "device",
      "digital",
      "drive",
      "flash",
      "font",
      "image",
      "letter",
      "memory",
      "photo",
      "sd",
      "secure",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sd_card",
    "version": 248,
    "popularity": 566,
    "codepoint": 58915,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "camera",
      "card",
      "digital",
      "memory",
      "photos",
      "sd",
      "secure",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sd_card",
    "version": 12,
    "popularity": 2809,
    "codepoint": 58915,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "camera",
      "card",
      "digital",
      "memory",
      "photos",
      "sd",
      "secure",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sd_card_alert",
    "version": 248,
    "popularity": 315,
    "codepoint": 61527,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "camera",
      "card",
      "caution",
      "danger",
      "digital",
      "error",
      "exclamation",
      "important",
      "mark",
      "memory",
      "notification",
      "photos",
      "sd",
      "secure",
      "storage",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sd_card_alert",
    "version": 11,
    "popularity": 1657,
    "codepoint": 61527,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "camera",
      "card",
      "caution",
      "danger",
      "digital",
      "error",
      "exclamation",
      "important",
      "mark",
      "memory",
      "notification",
      "photos",
      "sd",
      "secure",
      "storage",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sd_storage",
    "version": 12,
    "popularity": 1979,
    "codepoint": 57794,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "camera",
      "card",
      "data",
      "digital",
      "memory",
      "sd",
      "secure",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sdk",
    "version": 248,
    "popularity": 94,
    "codepoint": 59168,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "applications",
      "apps",
      "build",
      "create",
      "development",
      "kit",
      "sdk",
      "software",
      "websites"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "search",
    "version": 248,
    "popularity": 176483,
    "codepoint": 59574,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "filter",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "search",
    "version": 17,
    "popularity": 806243,
    "codepoint": 59574,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "filter",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "search",
      "see"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "search_check",
    "version": 248,
    "popularity": 70,
    "codepoint": 63488,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "done",
      "find",
      "glass",
      "health",
      "look",
      "magnify",
      "magnifying",
      "mark",
      "ok",
      "search",
      "see",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "search_hands_free",
    "version": 248,
    "popularity": 48,
    "codepoint": 59030,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "free",
      "hands",
      "search",
      "steering",
      "wheel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "search_off",
    "version": 248,
    "popularity": 1885,
    "codepoint": 60022,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "disabled",
      "enabled",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "off",
      "on",
      "search",
      "see",
      "slash",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "search_off",
    "version": 12,
    "popularity": 9220,
    "codepoint": 60022,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "disabled",
      "enabled",
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "off",
      "on",
      "search",
      "see",
      "slash",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "security",
    "version": 248,
    "popularity": 5214,
    "codepoint": 58154,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "certified",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "security",
    "version": 12,
    "popularity": 30342,
    "codepoint": 58154,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "certified",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "security_key",
    "version": 248,
    "popularity": 14,
    "codepoint": 62723,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "2-step",
      "authentication",
      "keys",
      "ldap",
      "pin",
      "reauth",
      "tag",
      "thumb drive",
      "verification",
      "yubi",
      "yubi key"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "security_update",
    "version": 11,
    "popularity": 2078,
    "codepoint": 61528,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "down",
      "download",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "security",
      "tablet",
      "update"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "security_update_good",
    "version": 248,
    "popularity": 617,
    "codepoint": 61529,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "checkmark",
      "device",
      "good",
      "hardware",
      "iOS",
      "mobile",
      "ok",
      "phone",
      "security",
      "tablet",
      "tick",
      "update"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "security_update_good",
    "version": 10,
    "popularity": 2707,
    "codepoint": 61529,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "checkmark",
      "device",
      "good",
      "hardware",
      "iOS",
      "mobile",
      "ok",
      "phone",
      "security",
      "tablet",
      "tick",
      "update"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "security_update_warning",
    "version": 248,
    "popularity": 372,
    "codepoint": 61530,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "!",
      "Android",
      "OS",
      "alert",
      "attention",
      "caution",
      "danger",
      "device",
      "download",
      "error",
      "exclamation",
      "hardware",
      "iOS",
      "important",
      "mark",
      "mobile",
      "notification",
      "phone",
      "security",
      "symbol",
      "tablet",
      "update",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "security_update_warning",
    "version": 10,
    "popularity": 1662,
    "codepoint": 61530,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "Android",
      "OS",
      "alert",
      "attention",
      "caution",
      "danger",
      "device",
      "download",
      "error",
      "exclamation",
      "hardware",
      "iOS",
      "important",
      "mark",
      "mobile",
      "notification",
      "phone",
      "security",
      "symbol",
      "tablet",
      "update",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "segment",
    "version": 248,
    "popularity": 975,
    "codepoint": 59723,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "fonts",
      "format",
      "lines",
      "list",
      "paragraph",
      "part",
      "piece",
      "rule",
      "rules",
      "segment",
      "style",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "segment",
    "version": 11,
    "popularity": 12415,
    "codepoint": 59723,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alignment",
      "fonts",
      "format",
      "lines",
      "list",
      "paragraph",
      "part",
      "piece",
      "rule",
      "rules",
      "segment",
      "style",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "select",
    "version": 248,
    "popularity": 41,
    "codepoint": 63309,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "all",
      "dash",
      "dashed",
      "selection",
      "square",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "select_all",
    "version": 248,
    "popularity": 1859,
    "codepoint": 57698,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "all",
      "dash",
      "dashed",
      "select",
      "selection",
      "square",
      "tool"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "select_all",
    "version": 12,
    "popularity": 7488,
    "codepoint": 57698,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "all",
      "dash",
      "dashed",
      "select",
      "selection",
      "square",
      "tool"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "select_check_box",
    "version": 248,
    "popularity": 5125,
    "codepoint": 61950,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approved",
      "box",
      "button",
      "check",
      "component",
      "form",
      "mark",
      "ok",
      "select",
      "selected",
      "selection",
      "tick",
      "toggle",
      "ui",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "select_to_speak",
    "version": 248,
    "popularity": 13,
    "codepoint": 63439,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "control",
      "frame",
      "music",
      "selection",
      "sound",
      "speaker",
      "tv",
      "up",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "select_window",
    "version": 248,
    "popularity": 64,
    "codepoint": 59130,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "multiple",
      "pages",
      "select",
      "web",
      "webpages",
      "websites",
      "window",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "select_window_off",
    "version": 248,
    "popularity": 14,
    "codepoint": 58630,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "browser",
      "disabled",
      "enabled",
      "internet",
      "multiple",
      "off",
      "on",
      "pages",
      "screen",
      "select",
      "slash",
      "web",
      "webpages",
      "websites",
      "window",
      "windows",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "self_care",
    "version": 248,
    "popularity": 170,
    "codepoint": 63597,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "brush",
      "calm",
      "care",
      "chi",
      "comb",
      "improvement",
      "mirror",
      "relax",
      "self"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "self_improvement",
    "version": 248,
    "popularity": 3406,
    "codepoint": 60024,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "calm",
      "care",
      "chi",
      "human",
      "improvement",
      "meditate",
      "meditation",
      "people",
      "person",
      "relax",
      "self",
      "sitting",
      "wellbeing",
      "yoga",
      "zen"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "self_improvement",
    "version": 11,
    "popularity": 17523,
    "codepoint": 60024,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "calm",
      "care",
      "chi",
      "human",
      "improvement",
      "meditate",
      "meditation",
      "people",
      "person",
      "relax",
      "self",
      "sitting",
      "wellbeing",
      "yoga",
      "zen"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sell",
    "version": 248,
    "popularity": 11445,
    "codepoint": 61531,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "sell",
      "shopping",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sell",
    "version": 10,
    "popularity": 34738,
    "codepoint": 61531,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bill",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "price",
      "sell",
      "shopping",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "send",
    "version": 248,
    "popularity": 21176,
    "codepoint": 57699,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "email",
      "mail",
      "message",
      "paper",
      "plane",
      "reply",
      "right",
      "send",
      "share"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "send",
    "version": 20,
    "popularity": 121225,
    "codepoint": 57699,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "email",
      "mail",
      "message",
      "paper",
      "plane",
      "reply",
      "right",
      "send",
      "share"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "send_and_archive",
    "version": 248,
    "popularity": 493,
    "codepoint": 59916,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "archive",
      "arrow",
      "down",
      "download",
      "email",
      "letter",
      "mail",
      "save",
      "send",
      "share"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "send_and_archive",
    "version": 11,
    "popularity": 4527,
    "codepoint": 59916,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "archive",
      "arrow",
      "down",
      "download",
      "email",
      "letter",
      "mail",
      "save",
      "send",
      "share"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "send_money",
    "version": 248,
    "popularity": 38,
    "codepoint": 59575,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arrow",
      "card",
      "cash",
      "coin",
      "coins",
      "commerce",
      "credit",
      "currency",
      "direction",
      "dollars",
      "export",
      "money",
      "online",
      "pay",
      "payment",
      "right",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "send_time_extension",
    "version": 248,
    "popularity": 842,
    "codepoint": 60123,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "deliver",
      "dispatch",
      "envelop",
      "extension",
      "mail",
      "message",
      "schedule",
      "send",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "send_time_extension",
    "version": 2,
    "popularity": 1593,
    "codepoint": 60123,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "deliver",
      "dispatch",
      "envelop",
      "extension",
      "mail",
      "message",
      "schedule",
      "send",
      "time"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "send_to_mobile",
    "version": 248,
    "popularity": 950,
    "codepoint": 61532,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "export",
      "forward",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "right",
      "send",
      "share",
      "tablet",
      "to"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "send_to_mobile",
    "version": 11,
    "popularity": 4564,
    "codepoint": 61532,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "device",
      "export",
      "forward",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "right",
      "send",
      "share",
      "tablet",
      "to"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sensor_door",
    "version": 248,
    "popularity": 424,
    "codepoint": 61877,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alarm",
      "security",
      "security system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sensor_door",
    "version": 8,
    "popularity": 0,
    "codepoint": 61877,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "alarm",
      "security",
      "security system"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sensor_occupied",
    "version": 248,
    "popularity": 840,
    "codepoint": 60432,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "body",
      "body response",
      "connection",
      "fitbit",
      "human",
      "network",
      "people",
      "person",
      "scan",
      "sensors",
      "signal",
      "smart body scan sensor",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sensor_occupied",
    "version": 1,
    "popularity": 1882,
    "codepoint": 60432,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "body",
      "body response",
      "connection",
      "fitbit",
      "human",
      "network",
      "people",
      "person",
      "scan",
      "sensors",
      "signal",
      "smart body scan sensor",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sensor_window",
    "version": 248,
    "popularity": 178,
    "codepoint": 61876,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "alarm",
      "security",
      "security system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sensor_window",
    "version": 8,
    "popularity": 3622,
    "codepoint": 61876,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "alarm",
      "security",
      "security system"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sensors",
    "version": 248,
    "popularity": 2379,
    "codepoint": 58654,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "connection",
      "network",
      "scan",
      "sensors",
      "signal",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sensors",
    "version": 4,
    "popularity": 20079,
    "codepoint": 58654,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "connection",
      "network",
      "scan",
      "sensors",
      "signal",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sensors_krx",
    "version": 248,
    "popularity": 79,
    "codepoint": 62806,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alarm",
      "bismuth",
      "detect",
      "detector",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sensors_krx_off",
    "version": 248,
    "popularity": 12,
    "codepoint": 62741,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alarm",
      "bismuth",
      "detect",
      "detector",
      "disabled",
      "enabled",
      "off",
      "offline",
      "on",
      "slash"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sensors_off",
    "version": 248,
    "popularity": 265,
    "codepoint": 58655,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "connection",
      "disabled",
      "enabled",
      "network",
      "off",
      "on",
      "scan",
      "sensors",
      "signal",
      "slash",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sensors_off",
    "version": 5,
    "popularity": 3525,
    "codepoint": 58655,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "connection",
      "disabled",
      "enabled",
      "network",
      "off",
      "on",
      "scan",
      "sensors",
      "signal",
      "slash",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sentiment_calm",
    "version": 248,
    "popularity": 47,
    "codepoint": 63143,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "face",
      "mood",
      "peaceful",
      "response"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_content",
    "version": 248,
    "popularity": 21,
    "codepoint": 63142,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "face",
      "mood",
      "response",
      "satisfied",
      "satisfy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_dissatisfied",
    "version": 248,
    "popularity": 5592,
    "codepoint": 59409,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "angry",
      "disappointed",
      "dislike",
      "dissatisfied",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "frown",
      "mood",
      "person",
      "sad",
      "sentiment",
      "survey",
      "unhappy",
      "unsatisfied",
      "upset"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_dissatisfied",
    "version": 11,
    "popularity": 21043,
    "codepoint": 59409,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "angry",
      "disappointed",
      "dislike",
      "dissatisfied",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "frown",
      "mood",
      "person",
      "sad",
      "sentiment",
      "survey",
      "unhappy",
      "unsatisfied",
      "upset"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sentiment_excited",
    "version": 248,
    "popularity": 47,
    "codepoint": 63141,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "excite",
      "excitement",
      "face",
      "mood",
      "passionate",
      "thrilled"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_extremely_dissatisfied",
    "version": 248,
    "popularity": 1542,
    "codepoint": 61844,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "angry",
      "dissatisfied",
      "emotions",
      "expressions",
      "extremely",
      "face",
      "feelings",
      "furious",
      "mad",
      "mood",
      "person",
      "sentiment",
      "survey",
      "unhappy",
      "upset"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_frustrated",
    "version": 248,
    "popularity": 19,
    "codepoint": 63140,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "disappointed",
      "face",
      "frustration",
      "mood"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_neutral",
    "version": 248,
    "popularity": 2731,
    "codepoint": 59410,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "emotionless",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "fine",
      "indifference",
      "mood",
      "neutral",
      "okay",
      "person",
      "sentiment",
      "survey"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_neutral",
    "version": 11,
    "popularity": 11177,
    "codepoint": 59410,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "emotionless",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "fine",
      "indifference",
      "mood",
      "neutral",
      "okay",
      "person",
      "sentiment",
      "survey"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sentiment_sad",
    "version": 248,
    "popularity": 62,
    "codepoint": 63139,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "face",
      "mood",
      "unhappy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_satisfied",
    "version": 248,
    "popularity": 11026,
    "codepoint": 59411,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "satisfied",
      "sentiment",
      "smile",
      "smiling",
      "survey"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_satisfied",
    "version": 11,
    "popularity": 26250,
    "codepoint": 59411,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "satisfied",
      "sentiment",
      "smile",
      "smiling",
      "survey"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sentiment_satisfied_alt",
    "version": 11,
    "popularity": 27885,
    "codepoint": 57581,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "account",
      "alt",
      "emoji",
      "face",
      "happy",
      "human",
      "people",
      "person",
      "profile",
      "satisfied",
      "sentiment",
      "smile",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sentiment_stressed",
    "version": 248,
    "popularity": 42,
    "codepoint": 63138,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "face",
      "mood",
      "stress",
      "tense"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_very_dissatisfied",
    "version": 248,
    "popularity": 2679,
    "codepoint": 59412,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "angry",
      "disappointed",
      "dislike",
      "dissatisfied",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "mood",
      "person",
      "sad",
      "sentiment",
      "sorrow",
      "survey",
      "unhappy",
      "unsatisfied",
      "upset",
      "very"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_very_dissatisfied",
    "version": 11,
    "popularity": 0,
    "codepoint": 59412,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "angry",
      "disappointed",
      "dislike",
      "dissatisfied",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "mood",
      "person",
      "sad",
      "sentiment",
      "sorrow",
      "survey",
      "unhappy",
      "unsatisfied",
      "upset",
      "very"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sentiment_very_satisfied",
    "version": 248,
    "popularity": 5417,
    "codepoint": 59413,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "satisfied",
      "sentiment",
      "smile",
      "smiling",
      "survey",
      "very"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sentiment_very_satisfied",
    "version": 11,
    "popularity": 28602,
    "codepoint": 59413,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "emotions",
      "expressions",
      "face",
      "feelings",
      "glad",
      "happiness",
      "happy",
      "like",
      "mood",
      "person",
      "pleased",
      "satisfied",
      "sentiment",
      "smile",
      "smiling",
      "survey",
      "very"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sentiment_worried",
    "version": 248,
    "popularity": 13,
    "codepoint": 63137,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "anxious",
      "face",
      "mood",
      "worry"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "service_toolbox",
    "version": 248,
    "popularity": 90,
    "codepoint": 59159,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "box",
      "equipment",
      "kit",
      "mechanic",
      "repairing",
      "service",
      "tool",
      "toolbox",
      "tools",
      "workshop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "set_meal",
    "version": 248,
    "popularity": 638,
    "codepoint": 61930,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "chopsticks",
      "dinner",
      "fish",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "set",
      "teishoku"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "set_meal",
    "version": 6,
    "popularity": 4770,
    "codepoint": 61930,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "chopsticks",
      "dinner",
      "fish",
      "food",
      "lunch",
      "meal",
      "restaurant",
      "set",
      "teishoku"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings",
    "version": 248,
    "popularity": 116237,
    "codepoint": 59576,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "application",
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "service",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings",
    "version": 19,
    "popularity": 0,
    "codepoint": 59576,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "application",
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "service",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_accessibility",
    "version": 248,
    "popularity": 6272,
    "codepoint": 61533,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "accessibility",
      "body",
      "details",
      "human",
      "information",
      "people",
      "person",
      "personal",
      "preferences",
      "profile",
      "settings",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_accessibility",
    "version": 10,
    "popularity": 13515,
    "codepoint": 61533,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accessibility",
      "body",
      "details",
      "human",
      "information",
      "people",
      "person",
      "personal",
      "preferences",
      "profile",
      "settings",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_account_box",
    "version": 248,
    "popularity": 58,
    "codepoint": 63541,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "application",
      "avatar",
      "box",
      "custom",
      "customize",
      "details",
      "face",
      "gear",
      "human",
      "info",
      "information",
      "options",
      "people",
      "person",
      "personal",
      "portrait",
      "preferences",
      "profile",
      "service",
      "square",
      "thumbnail",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_alert",
    "version": 248,
    "popularity": 360,
    "codepoint": 61763,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "alert",
      "gear",
      "home",
      "nest",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_applications",
    "version": 248,
    "popularity": 3388,
    "codepoint": 59577,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "application",
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "service",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_applications",
    "version": 11,
    "popularity": 22791,
    "codepoint": 59577,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "application",
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "service",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_b_roll",
    "version": 248,
    "popularity": 52,
    "codepoint": 63013,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "application",
      "arrow",
      "custom",
      "customize",
      "details",
      "forward",
      "gear",
      "info",
      "information",
      "options",
      "personal",
      "preferences",
      "right",
      "service",
      "setting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_backup_restore",
    "version": 248,
    "popularity": 2522,
    "codepoint": 59578,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backup",
      "backwards",
      "refresh",
      "restore",
      "reverse",
      "rotate",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_backup_restore",
    "version": 11,
    "popularity": 13194,
    "codepoint": 59578,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "back",
      "backup",
      "backwards",
      "refresh",
      "restore",
      "reverse",
      "rotate",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_bluetooth",
    "version": 248,
    "popularity": 470,
    "codepoint": 59579,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "settings",
      "signal",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_bluetooth",
    "version": 11,
    "popularity": 4320,
    "codepoint": 59579,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bluetooth",
      "connect",
      "connection",
      "connectivity",
      "device",
      "settings",
      "signal",
      "symbol"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_brightness",
    "version": 248,
    "popularity": 687,
    "codepoint": 59581,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "brightness",
      "dark",
      "filter",
      "light",
      "mode",
      "setting",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_brightness",
    "version": 12,
    "popularity": 5737,
    "codepoint": 59581,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "brightness",
      "dark",
      "filter",
      "light",
      "mode",
      "setting",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_cell",
    "version": 248,
    "popularity": 194,
    "codepoint": 59580,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "settings",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_cell",
    "version": 11,
    "popularity": 3494,
    "codepoint": 59580,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "settings",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_cinematic_blur",
    "version": 248,
    "popularity": 12,
    "codepoint": 63012,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "account",
      "application",
      "blur",
      "cinema",
      "custom",
      "customize",
      "details",
      "gear",
      "human",
      "info",
      "information",
      "movie",
      "options",
      "person",
      "personal",
      "preferences",
      "profile",
      "service",
      "setting",
      "user",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_ethernet",
    "version": 248,
    "popularity": 1032,
    "codepoint": 59582,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrows",
      "computer",
      "connect",
      "connection",
      "connectivity",
      "dots",
      "ethernet",
      "internet",
      "network",
      "settings",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_ethernet",
    "version": 11,
    "popularity": 11501,
    "codepoint": 59582,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrows",
      "computer",
      "connect",
      "connection",
      "connectivity",
      "dots",
      "ethernet",
      "internet",
      "network",
      "settings",
      "wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_heart",
    "version": 248,
    "popularity": 8,
    "codepoint": 62754,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "application",
      "change",
      "details",
      "favorite",
      "gear",
      "heart",
      "info",
      "information",
      "like",
      "love",
      "options",
      "personal",
      "remember",
      "save",
      "service",
      "settings",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_input_antenna",
    "version": 248,
    "popularity": 1044,
    "codepoint": 59583,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "airplay",
      "antenna",
      "arrows",
      "cast",
      "computer",
      "connect",
      "connection",
      "connectivity",
      "dots",
      "input",
      "internet",
      "network",
      "screencast",
      "settings",
      "stream",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_input_antenna",
    "version": 11,
    "popularity": 9183,
    "codepoint": 59583,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "airplay",
      "antenna",
      "arrows",
      "cast",
      "computer",
      "connect",
      "connection",
      "connectivity",
      "dots",
      "input",
      "internet",
      "network",
      "screencast",
      "settings",
      "stream",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_input_component",
    "version": 248,
    "popularity": 772,
    "codepoint": 59584,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "audio",
      "av",
      "cable",
      "cables",
      "component",
      "connect",
      "connection",
      "connectivity",
      "input",
      "internet",
      "plug",
      "points",
      "settings",
      "video",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_input_component",
    "version": 11,
    "popularity": 8432,
    "codepoint": 59584,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "audio",
      "av",
      "cable",
      "cables",
      "component",
      "connect",
      "connection",
      "connectivity",
      "input",
      "internet",
      "plug",
      "points",
      "settings",
      "video",
      "wifi"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_input_composite",
    "version": 11,
    "popularity": 4973,
    "codepoint": 59585,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "component",
      "composite",
      "connection",
      "connectivity",
      "input",
      "plug",
      "points",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_input_hdmi",
    "version": 248,
    "popularity": 413,
    "codepoint": 59586,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "cable",
      "connection",
      "connectivity",
      "definition",
      "hdmi",
      "high",
      "input",
      "plug",
      "plugin",
      "points",
      "settings",
      "video",
      "wire"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_input_hdmi",
    "version": 11,
    "popularity": 3660,
    "codepoint": 59586,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cable",
      "connection",
      "connectivity",
      "definition",
      "hdmi",
      "high",
      "input",
      "plug",
      "plugin",
      "points",
      "settings",
      "video",
      "wire"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_input_svideo",
    "version": 248,
    "popularity": 228,
    "codepoint": 59587,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "cable",
      "connection",
      "connectivity",
      "definition",
      "input",
      "plug",
      "plugin",
      "points",
      "settings",
      "standard",
      "svideo",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_input_svideo",
    "version": 11,
    "popularity": 2866,
    "codepoint": 59587,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cable",
      "connection",
      "connectivity",
      "definition",
      "input",
      "plug",
      "plugin",
      "points",
      "settings",
      "standard",
      "svideo",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_motion_mode",
    "version": 248,
    "popularity": 13,
    "codepoint": 63539,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "application",
      "blur",
      "camera",
      "custom",
      "customize",
      "details",
      "feature",
      "gear",
      "image",
      "info",
      "information",
      "mode",
      "motion",
      "options",
      "personal",
      "photo",
      "photography",
      "picture",
      "preferences",
      "service",
      "setting",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_night_sight",
    "version": 248,
    "popularity": 18,
    "codepoint": 63538,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "application",
      "camera",
      "custom",
      "customize",
      "dark",
      "details",
      "feature",
      "gear",
      "info",
      "information",
      "moon",
      "night",
      "options",
      "personal",
      "photo",
      "photography",
      "preferences",
      "service",
      "setting",
      "settings",
      "sight"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_overscan",
    "version": 248,
    "popularity": 523,
    "codepoint": 59588,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrows",
      "expand",
      "image",
      "photo",
      "picture",
      "scan",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_overscan",
    "version": 11,
    "popularity": 5265,
    "codepoint": 59588,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrows",
      "expand",
      "image",
      "photo",
      "picture",
      "scan",
      "settings"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_panorama",
    "version": 248,
    "popularity": 18,
    "codepoint": 63537,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "application",
      "custom",
      "customize",
      "details",
      "gear",
      "image",
      "info",
      "information",
      "landscape",
      "mountain",
      "mountains",
      "options",
      "panorama",
      "personal",
      "photo",
      "photography",
      "picture",
      "preferences",
      "service",
      "setting",
      "settings",
      "view",
      "vrpano",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_phone",
    "version": 248,
    "popularity": 891,
    "codepoint": 59589,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "settings",
      "telephone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_phone",
    "version": 15,
    "popularity": 16307,
    "codepoint": 59589,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "settings",
      "telephone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_photo_camera",
    "version": 248,
    "popularity": 30,
    "codepoint": 63540,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "application",
      "camera",
      "custom",
      "customize",
      "details",
      "gear",
      "info",
      "information",
      "lens",
      "options",
      "personal",
      "photo",
      "photography",
      "picture",
      "preferences",
      "service",
      "setting",
      "settings"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_power",
    "version": 248,
    "popularity": 926,
    "codepoint": 59590,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "info",
      "information",
      "off",
      "on",
      "power",
      "save",
      "settings",
      "shutdown"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_power",
    "version": 11,
    "popularity": 6569,
    "codepoint": 59590,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "info",
      "information",
      "off",
      "on",
      "power",
      "save",
      "settings",
      "shutdown"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_remote",
    "version": 248,
    "popularity": 876,
    "codepoint": 59591,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "bluetooth",
      "connection",
      "connectivity",
      "device",
      "remote",
      "settings",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_remote",
    "version": 11,
    "popularity": 8228,
    "codepoint": 59591,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bluetooth",
      "connection",
      "connectivity",
      "device",
      "remote",
      "settings",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_slow_motion",
    "version": 248,
    "popularity": 5,
    "codepoint": 63011,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "application",
      "arrow",
      "control",
      "controls",
      "custom",
      "customize",
      "dashed",
      "details",
      "gear",
      "info",
      "information",
      "motion",
      "music",
      "options",
      "personal",
      "play",
      "preferences",
      "service",
      "setting",
      "slow",
      "speed",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_suggest",
    "version": 20,
    "popularity": 29113,
    "codepoint": 61534,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "ai",
      "artificial",
      "automatic",
      "automation",
      "change",
      "custom",
      "details",
      "gear",
      "genai",
      "intelligence",
      "magic",
      "options",
      "recommendation",
      "service",
      "settings",
      "smart",
      "spark",
      "sparkle",
      "star",
      "suggest",
      "suggestion",
      "system"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_system_daydream",
    "version": 248,
    "popularity": 366,
    "codepoint": 57795,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "backup",
      "cloud",
      "daydream",
      "drive",
      "settings",
      "storage",
      "system"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_system_daydream",
    "version": 12,
    "popularity": 2482,
    "codepoint": 57795,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "backup",
      "cloud",
      "daydream",
      "drive",
      "settings",
      "storage",
      "system"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settings_timelapse",
    "version": 248,
    "popularity": 20,
    "codepoint": 63010,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "application",
      "control",
      "custom",
      "customize",
      "details",
      "fast",
      "forward",
      "gear",
      "info",
      "information",
      "media",
      "music",
      "options",
      "personal",
      "play",
      "preferences",
      "service",
      "setting",
      "speed",
      "time",
      "tv",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_video_camera",
    "version": 248,
    "popularity": 26,
    "codepoint": 63009,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "application",
      "cam",
      "camera",
      "conference",
      "custom",
      "customize",
      "details",
      "film",
      "filming",
      "gear",
      "hardware",
      "image",
      "info",
      "information",
      "motion",
      "options",
      "personal",
      "picture",
      "preferences",
      "service",
      "setting",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_voice",
    "version": 248,
    "popularity": 690,
    "codepoint": 59592,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "mic",
      "microphone",
      "record",
      "recorder",
      "settings",
      "speaker",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "settings_voice",
    "version": 11,
    "popularity": 7560,
    "codepoint": 59592,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "mic",
      "microphone",
      "record",
      "recorder",
      "settings",
      "speaker",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "settop_component",
    "version": 248,
    "popularity": 234,
    "codepoint": 58028,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "interface",
      "nest",
      "screen",
      "settop",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "severe_cold",
    "version": 248,
    "popularity": 605,
    "codepoint": 60371,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "climate",
      "cold",
      "crisis",
      "danger",
      "disaster",
      "error",
      "exclamation",
      "important",
      "notification",
      "severe",
      "snow",
      "snowflake",
      "warning",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "severe_cold",
    "version": 1,
    "popularity": 973,
    "codepoint": 60371,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "climate",
      "cold",
      "crisis",
      "danger",
      "disaster",
      "error",
      "exclamation",
      "important",
      "notification",
      "severe",
      "snow",
      "snowflake",
      "warning",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "shadow",
    "version": 248,
    "popularity": 26,
    "codepoint": 59871,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "adjustment",
      "edit",
      "editing",
      "effect",
      "image",
      "photo",
      "photography",
      "picture",
      "shadow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shadow_add",
    "version": 248,
    "popularity": 4,
    "codepoint": 62852,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "+",
      "add",
      "adjustment",
      "edit",
      "editing",
      "effect",
      "image",
      "new",
      "photo",
      "photography",
      "picture",
      "plus",
      "shadow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shadow_minus",
    "version": 248,
    "popularity": 1,
    "codepoint": 62851,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "-",
      "adjustment",
      "delete",
      "edit",
      "editing",
      "effect",
      "image",
      "minus",
      "photo",
      "photography",
      "picture",
      "remove",
      "shadow",
      "subtract"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shape_line",
    "version": 248,
    "popularity": 376,
    "codepoint": 63699,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "circle",
      "draw",
      "edit",
      "editing",
      "line",
      "shape",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shape_line",
    "version": 1,
    "popularity": 823,
    "codepoint": 63699,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "circle",
      "draw",
      "edit",
      "editing",
      "line",
      "shape",
      "square"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "shapes",
    "version": 248,
    "popularity": 37,
    "codepoint": 58882,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "circle",
      "draw",
      "edit",
      "editing",
      "shape",
      "shapes",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "share",
    "version": 248,
    "popularity": 21249,
    "codepoint": 59405,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "DISABLE_IOS",
      "android",
      "connect",
      "contect",
      "disable_ios",
      "link",
      "media",
      "multimedia",
      "multiple",
      "network",
      "options",
      "share",
      "shared",
      "sharing",
      "social"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "share",
    "version": 18,
    "popularity": 117157,
    "codepoint": 59405,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "DISABLE_IOS",
      "android",
      "connect",
      "contect",
      "disable_ios",
      "link",
      "media",
      "multimedia",
      "multiple",
      "network",
      "options",
      "share",
      "shared",
      "sharing",
      "social"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "share_location",
    "version": 248,
    "popularity": 2979,
    "codepoint": 61535,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "dash",
      "dashed",
      "destination",
      "direction",
      "gps",
      "location",
      "maps",
      "pin",
      "place",
      "share",
      "stop",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "share_location",
    "version": 17,
    "popularity": 8745,
    "codepoint": 61535,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "dash",
      "dashed",
      "destination",
      "direction",
      "gps",
      "location",
      "maps",
      "pin",
      "place",
      "share",
      "stop",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "share_off",
    "version": 248,
    "popularity": 26,
    "codepoint": 63179,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "android",
      "cancel",
      "clear",
      "close",
      "connect",
      "contect",
      "disabled",
      "link",
      "media",
      "multimedia",
      "multiple",
      "network",
      "off",
      "options",
      "remove",
      "share",
      "shared",
      "sharing",
      "social",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "share_reviews",
    "version": 248,
    "popularity": 767,
    "codepoint": 63652,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "feedback",
      "message",
      "review",
      "reviews",
      "share",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "share_windows",
    "version": 248,
    "popularity": 47,
    "codepoint": 62995,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "export",
      "move",
      "right",
      "send"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sheets_rtl",
    "version": 248,
    "popularity": 3,
    "codepoint": 63523,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "arrows",
      "back",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "left",
      "notes",
      "page",
      "paper",
      "sheet",
      "slide",
      "switch",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shelf_auto_hide",
    "version": 248,
    "popularity": 8,
    "codepoint": 63235,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "close",
      "dots",
      "dotted",
      "layout",
      "ui",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shelf_position",
    "version": 248,
    "popularity": 19,
    "codepoint": 63234,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic grid",
      "measure",
      "metrics",
      "statistics",
      "table",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shelves",
    "version": 248,
    "popularity": 23,
    "codepoint": 63598,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "box",
      "factory",
      "furniture",
      "home",
      "manufactory",
      "shelf",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shelves",
    "version": 1,
    "popularity": 1960,
    "codepoint": 63598,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "box",
      "factory",
      "furniture",
      "home",
      "manufactory",
      "shelf",
      "storage"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "shield",
    "version": 248,
    "popularity": 4006,
    "codepoint": 59872,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "certified",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shield",
    "version": 11,
    "popularity": 25730,
    "codepoint": 59872,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "certified",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shield_lock",
    "version": 248,
    "popularity": 143,
    "codepoint": 63110,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "locked",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shield_locked",
    "version": 248,
    "popularity": 33,
    "codepoint": 62866,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "locked",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shield_moon",
    "version": 248,
    "popularity": 264,
    "codepoint": 60073,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "certified",
      "do not disturb",
      "moon",
      "night",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shield_moon",
    "version": 2,
    "popularity": 2779,
    "codepoint": 60073,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "certified",
      "do not disturb",
      "moon",
      "night",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "shield",
      "verified"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "shield_person",
    "version": 248,
    "popularity": 114,
    "codepoint": 63056,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "account",
      "admin",
      "avatar",
      "certified",
      "face",
      "human",
      "panel",
      "people",
      "person",
      "privacy",
      "private",
      "profile",
      "protect",
      "protection",
      "security",
      "settings",
      "shield",
      "user",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shield_question",
    "version": 248,
    "popularity": 13,
    "codepoint": 62761,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "?",
      "certified",
      "encryption",
      "help",
      "info",
      "information",
      "privacy",
      "private",
      "protect",
      "protection",
      "question",
      "question mark",
      "safe search",
      "search",
      "security",
      "shield",
      "support",
      "symbol",
      "verified"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shield_with_heart",
    "version": 248,
    "popularity": 745,
    "codepoint": 59279,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "heart",
      "home",
      "nest",
      "security",
      "shield"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shield_with_house",
    "version": 248,
    "popularity": 192,
    "codepoint": 59277,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "house",
      "nest",
      "security",
      "shield"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shift",
    "version": 248,
    "popularity": 19,
    "codepoint": 58866,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "function",
      "key",
      "type",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shift_lock",
    "version": 248,
    "popularity": 3,
    "codepoint": 63406,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "function",
      "key",
      "type",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shop",
    "version": 248,
    "popularity": 892,
    "codepoint": 59593,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bag",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "google",
      "money",
      "online",
      "pay",
      "payment",
      "play",
      "shop",
      "shopping",
      "store"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shop",
    "version": 12,
    "popularity": 9218,
    "codepoint": 59593,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bag",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "google",
      "money",
      "online",
      "pay",
      "payment",
      "play",
      "shop",
      "shopping",
      "store"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shop_2",
    "version": 9,
    "popularity": 2596,
    "codepoint": 57758,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "2",
      "add",
      "arrow",
      "buy",
      "cart",
      "google",
      "play",
      "purchase",
      "shop",
      "shopping"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "shop_two",
    "version": 248,
    "popularity": 504,
    "codepoint": 59594,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "add",
      "arrow",
      "buy",
      "cart",
      "google",
      "play",
      "purchase",
      "shop",
      "shopping",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shop_two",
    "version": 11,
    "popularity": 4101,
    "codepoint": 59594,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "add",
      "arrow",
      "buy",
      "cart",
      "google",
      "play",
      "purchase",
      "shop",
      "shopping",
      "two"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shopping_bag",
    "version": 248,
    "popularity": 18054,
    "codepoint": 61900,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bag",
      "bill",
      "business",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shopping_bag",
    "version": 9,
    "popularity": 93985,
    "codepoint": 61900,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bag",
      "bill",
      "business",
      "buy",
      "card",
      "cart",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shopping_basket",
    "version": 248,
    "popularity": 5288,
    "codepoint": 59595,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "add",
      "basket",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shopping"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shopping_basket",
    "version": 12,
    "popularity": 43667,
    "codepoint": 59595,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "add",
      "basket",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shopping"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shopping_cart",
    "version": 248,
    "popularity": 42939,
    "codepoint": 59596,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "add",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shopping"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shopping_cart",
    "version": 18,
    "popularity": 298387,
    "codepoint": 59596,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "add",
      "bill",
      "buy",
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "shopping"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shopping_cart_checkout",
    "version": 248,
    "popularity": 9388,
    "codepoint": 60296,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "right",
      "shopping"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shopping_cart_checkout",
    "version": 1,
    "popularity": 9702,
    "codepoint": 60296,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "right",
      "shopping"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "shopping_cart_off",
    "version": 248,
    "popularity": 1,
    "codepoint": 62711,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "card",
      "cart",
      "cash",
      "checkout",
      "coin",
      "commerce",
      "credit",
      "currency",
      "disabled",
      "dollars",
      "enabled",
      "off",
      "on",
      "online",
      "pay",
      "payment",
      "remove",
      "shopping",
      "slash",
      "tick"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shoppingmode",
    "version": 248,
    "popularity": 255,
    "codepoint": 61367,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "clothing",
      "ecommerce",
      "mode",
      "price",
      "shopping",
      "shoppingmode",
      "tag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "short_stay",
    "version": 248,
    "popularity": 4,
    "codepoint": 58576,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bed",
      "body",
      "clinic",
      "clock",
      "date",
      "health",
      "hospital",
      "human",
      "patient",
      "people",
      "person",
      "schedule",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "short_text",
    "version": 248,
    "popularity": 401,
    "codepoint": 57953,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "brief",
      "comment",
      "doc",
      "document",
      "note",
      "short",
      "text",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "short_text",
    "version": 15,
    "popularity": 3519,
    "codepoint": 57953,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "brief",
      "comment",
      "doc",
      "document",
      "note",
      "short",
      "text",
      "write",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shortcut",
    "version": 10,
    "popularity": 7532,
    "codepoint": 61536,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "arrow",
      "direction",
      "forward",
      "right",
      "shortcut"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "show_chart",
    "version": 248,
    "popularity": 3271,
    "codepoint": 59105,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "presentation",
      "show chart",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "show_chart",
    "version": 13,
    "popularity": 25415,
    "codepoint": 59105,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "presentation",
      "show chart",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shower",
    "version": 248,
    "popularity": 1765,
    "codepoint": 61537,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bath",
      "bathroom",
      "closet",
      "home",
      "house",
      "place",
      "plumbing",
      "room",
      "shower",
      "sprinkler",
      "wash",
      "water",
      "wc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shower",
    "version": 9,
    "popularity": 9943,
    "codepoint": 61537,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bath",
      "bathroom",
      "closet",
      "home",
      "house",
      "place",
      "plumbing",
      "room",
      "shower",
      "sprinkler",
      "wash",
      "water",
      "wc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shuffle",
    "version": 248,
    "popularity": 2187,
    "codepoint": 57411,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "random",
      "shuffle",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shuffle",
    "version": 14,
    "popularity": 12603,
    "codepoint": 57411,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "random",
      "shuffle",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shuffle_on",
    "version": 248,
    "popularity": 466,
    "codepoint": 59873,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "on",
      "random",
      "shuffle",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shuffle_on",
    "version": 12,
    "popularity": 2595,
    "codepoint": 59873,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "arrows",
      "control",
      "controls",
      "music",
      "on",
      "random",
      "shuffle",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shutter_speed",
    "version": 248,
    "popularity": 376,
    "codepoint": 58429,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "aperture",
      "camera",
      "duration",
      "image",
      "lens",
      "photo",
      "photography",
      "photos",
      "picture",
      "setting",
      "shutter",
      "speed",
      "stop",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shutter_speed",
    "version": 11,
    "popularity": 2686,
    "codepoint": 58429,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "aperture",
      "camera",
      "duration",
      "image",
      "lens",
      "photo",
      "photography",
      "photos",
      "picture",
      "setting",
      "shutter",
      "speed",
      "stop",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "shutter_speed_add",
    "version": 248,
    "popularity": 5,
    "codepoint": 62846,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "add",
      "aperture",
      "camera",
      "duration",
      "image",
      "lens",
      "new",
      "photo",
      "photography",
      "photos",
      "picture",
      "plus",
      "setting",
      "shutter",
      "speed",
      "stop",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "shutter_speed_minus",
    "version": 248,
    "popularity": 2,
    "codepoint": 62845,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "-",
      "aperture",
      "camera",
      "delete",
      "duration",
      "image",
      "lens",
      "minus",
      "photo",
      "photography",
      "photos",
      "picture",
      "remove",
      "setting",
      "shutter",
      "speed",
      "stop",
      "subtract",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sick",
    "version": 248,
    "popularity": 1144,
    "codepoint": 61984,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "covid",
      "discomfort",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "fever",
      "flu",
      "ill",
      "mood",
      "pain",
      "person",
      "sick",
      "survey",
      "upset"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sick",
    "version": 6,
    "popularity": 6183,
    "codepoint": 61984,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "covid",
      "discomfort",
      "emotions",
      "expressions",
      "face",
      "feelings",
      "fever",
      "flu",
      "ill",
      "mood",
      "pain",
      "person",
      "sick",
      "survey",
      "upset"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "side_navigation",
    "version": 248,
    "popularity": 79,
    "codepoint": 59874,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "components",
      "design",
      "half",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "navigation",
      "phone",
      "screen",
      "side",
      "site",
      "tablet",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sign_language",
    "version": 248,
    "popularity": 812,
    "codepoint": 60389,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "communication",
      "deaf",
      "fingers",
      "gesture",
      "hand",
      "language",
      "sign"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sign_language",
    "version": 1,
    "popularity": 1434,
    "codepoint": 60389,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "communication",
      "deaf",
      "fingers",
      "gesture",
      "hand",
      "language",
      "sign"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "signal_cellular_0_bar",
    "version": 248,
    "popularity": 283,
    "codepoint": 61608,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "0",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_0_bar",
    "version": 15,
    "popularity": 2113,
    "codepoint": 61608,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "0",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_1_bar",
    "version": 248,
    "popularity": 333,
    "codepoint": 61609,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_1_bar",
    "version": 11,
    "popularity": 15,
    "codepoint": 61609,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_2_bar",
    "version": 248,
    "popularity": 346,
    "codepoint": 61610,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_2_bar",
    "version": 12,
    "popularity": 18,
    "codepoint": 61610,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_3_bar",
    "version": 248,
    "popularity": 481,
    "codepoint": 61611,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_3_bar",
    "version": 12,
    "popularity": 15,
    "codepoint": 61611,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_4_bar",
    "version": 248,
    "popularity": 1167,
    "codepoint": 57800,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_4_bar",
    "version": 12,
    "popularity": 5453,
    "codepoint": 57800,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_add",
    "version": 248,
    "popularity": 10,
    "codepoint": 63401,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "+",
      "add",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "new",
      "phone",
      "plus",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_alt",
    "version": 248,
    "popularity": 5534,
    "codepoint": 57858,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "alt",
      "analytics",
      "bar",
      "cell",
      "cellular",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "internet",
      "measure",
      "metrics",
      "mobile",
      "network",
      "phone",
      "signal",
      "statistics",
      "tracking",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_alt",
    "version": 12,
    "popularity": 32197,
    "codepoint": 57858,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "alt",
      "analytics",
      "bar",
      "cell",
      "cellular",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "internet",
      "measure",
      "metrics",
      "mobile",
      "network",
      "phone",
      "signal",
      "statistics",
      "tracking",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_alt_1_bar",
    "version": 248,
    "popularity": 192,
    "codepoint": 60383,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_alt_1_bar",
    "version": 1,
    "popularity": 947,
    "codepoint": 60383,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "signal_cellular_alt_2_bar",
    "version": 248,
    "popularity": 242,
    "codepoint": 60387,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_alt_2_bar",
    "version": 1,
    "popularity": 938,
    "codepoint": 60387,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "signal_cellular_connected_no_internet_0_bar",
    "version": 248,
    "popularity": 313,
    "codepoint": 61612,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "!",
      "0",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_connected_no_internet_0_bar",
    "version": 13,
    "popularity": 1612,
    "codepoint": 61612,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "0",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "signal_cellular_connected_no_internet_1_bar",
    "version": 12,
    "popularity": 3,
    "codepoint": 61613,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "1",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_connected_no_internet_2_bar",
    "version": 12,
    "popularity": 1,
    "codepoint": 61614,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "2",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_connected_no_internet_3_bar",
    "version": 12,
    "popularity": 2,
    "codepoint": 61615,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "3",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_connected_no_internet_4_bar",
    "version": 248,
    "popularity": 270,
    "codepoint": 57805,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "!",
      "4",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_connected_no_internet_4_bar",
    "version": 13,
    "popularity": 1804,
    "codepoint": 57805,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "4",
      "alert",
      "attention",
      "bar",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "signal_cellular_no_sim",
    "version": 11,
    "popularity": 979,
    "codepoint": 57806,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "camera",
      "card",
      "cellular",
      "chip",
      "device",
      "disabled",
      "enabled",
      "memory",
      "no",
      "off",
      "offline",
      "on",
      "phone",
      "signal",
      "sim",
      "slash",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_nodata",
    "version": 248,
    "popularity": 256,
    "codepoint": 61538,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "clear",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "nodata",
      "offline",
      "phone",
      "quit",
      "remove",
      "signal",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_nodata",
    "version": 10,
    "popularity": 1306,
    "codepoint": 61538,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "clear",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "nodata",
      "offline",
      "phone",
      "quit",
      "remove",
      "signal",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_null",
    "version": 248,
    "popularity": 177,
    "codepoint": 57807,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "null",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_null",
    "version": 12,
    "popularity": 1237,
    "codepoint": 57807,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "null",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_off",
    "version": 248,
    "popularity": 217,
    "codepoint": 57808,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "signal",
      "slash",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_cellular_off",
    "version": 12,
    "popularity": 1289,
    "codepoint": 57808,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "signal",
      "slash",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_cellular_pause",
    "version": 248,
    "popularity": 0,
    "codepoint": 62887,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "end",
      "internet",
      "mobile",
      "network",
      "phone",
      "rest",
      "signal",
      "speed",
      "stop",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_disconnected",
    "version": 248,
    "popularity": 1026,
    "codepoint": 62009,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "connection",
      "disconnected",
      "internet",
      "network",
      "signal",
      "updates",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_wifi_0_bar",
    "version": 248,
    "popularity": 418,
    "codepoint": 61616,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "0",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_wifi_0_bar",
    "version": 15,
    "popularity": 2944,
    "codepoint": 61616,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "0",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_1_bar",
    "version": 12,
    "popularity": 10,
    "codepoint": 61617,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_1_bar_lock",
    "version": 12,
    "popularity": 4,
    "codepoint": 61618,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_2_bar",
    "version": 12,
    "popularity": 23,
    "codepoint": 61619,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_2_bar_lock",
    "version": 12,
    "popularity": 3,
    "codepoint": 61620,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_3_bar",
    "version": 12,
    "popularity": 14,
    "codepoint": 61621,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_3_bar_lock",
    "version": 12,
    "popularity": 3,
    "codepoint": 61622,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_4_bar",
    "version": 248,
    "popularity": 1590,
    "codepoint": 57816,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_wifi_4_bar",
    "version": 12,
    "popularity": 6790,
    "codepoint": 57816,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_4_bar_lock",
    "version": 13,
    "popularity": 1282,
    "codepoint": 57817,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "phone",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "signal_wifi_bad",
    "version": 248,
    "popularity": 475,
    "codepoint": 61539,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "bad",
      "bar",
      "cancel",
      "cell",
      "cellular",
      "clear",
      "close",
      "data",
      "exit",
      "internet",
      "mobile",
      "network",
      "no",
      "phone",
      "quit",
      "remove",
      "signal",
      "stop",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_wifi_bad",
    "version": 10,
    "popularity": 2389,
    "codepoint": 61539,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "bad",
      "bar",
      "cancel",
      "cell",
      "cellular",
      "clear",
      "close",
      "data",
      "exit",
      "internet",
      "mobile",
      "network",
      "no",
      "phone",
      "quit",
      "remove",
      "signal",
      "stop",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_connected_no_internet_0",
    "version": 10,
    "popularity": 13,
    "codepoint": 61682,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "0",
      "cell",
      "cellular",
      "connected",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "offline",
      "phone",
      "signal",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_connected_no_internet_1",
    "version": 10,
    "popularity": 4,
    "codepoint": 61678,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "cell",
      "cellular",
      "connected",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "offline",
      "phone",
      "signal",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_connected_no_internet_2",
    "version": 10,
    "popularity": 2,
    "codepoint": 61681,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "cell",
      "cellular",
      "connected",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "offline",
      "phone",
      "signal",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_connected_no_internet_3",
    "version": 11,
    "popularity": 2,
    "codepoint": 61677,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "cell",
      "cellular",
      "connected",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "offline",
      "phone",
      "signal",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_connected_no_internet_4",
    "version": 10,
    "popularity": 2231,
    "codepoint": 61540,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4",
      "cell",
      "cellular",
      "connected",
      "data",
      "internet",
      "mobile",
      "network",
      "no",
      "offline",
      "phone",
      "signal",
      "wifi",
      "wireless",
      "x"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_off",
    "version": 248,
    "popularity": 443,
    "codepoint": 57818,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "on",
      "phone",
      "signal",
      "slash",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_wifi_off",
    "version": 17,
    "popularity": 2565,
    "codepoint": 57818,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "on",
      "phone",
      "signal",
      "slash",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_1_bar",
    "version": 9,
    "popularity": 25,
    "codepoint": 61670,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_2_bar",
    "version": 9,
    "popularity": 14,
    "codepoint": 61680,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_3_bar",
    "version": 10,
    "popularity": 31,
    "codepoint": 61674,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_4_bar",
    "version": 9,
    "popularity": 4182,
    "codepoint": 61541,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "4",
      "bar",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_connected_no_internet",
    "version": 9,
    "popularity": 4,
    "codepoint": 61688,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_connected_no_internet_1",
    "version": 9,
    "popularity": 10,
    "codepoint": 61673,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "1",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_connected_no_internet_2",
    "version": 9,
    "popularity": 6,
    "codepoint": 61687,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "2",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_connected_no_internet_3",
    "version": 9,
    "popularity": 6,
    "codepoint": 61672,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "3",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_connected_no_internet_4",
    "version": 11,
    "popularity": 3996,
    "codepoint": 61542,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "4",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connected",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "no",
      "notification",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "symbol",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_not_connected",
    "version": 248,
    "popularity": 486,
    "codepoint": 61679,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "?",
      "assistance",
      "cell",
      "cellular",
      "connected",
      "data",
      "help",
      "info",
      "information",
      "internet",
      "mobile",
      "network",
      "no",
      "not",
      "phone",
      "punctuation",
      "question mark",
      "signal",
      "speed",
      "statusbar",
      "support",
      "symbol",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_wifi_statusbar_not_connected",
    "version": 9,
    "popularity": 8,
    "codepoint": 61679,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "?",
      "assistance",
      "cell",
      "cellular",
      "connected",
      "data",
      "help",
      "info",
      "information",
      "internet",
      "mobile",
      "network",
      "no",
      "not",
      "phone",
      "punctuation",
      "question mark",
      "signal",
      "speed",
      "statusbar",
      "support",
      "symbol",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signal_wifi_statusbar_null",
    "version": 248,
    "popularity": 381,
    "codepoint": 61543,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "null",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signal_wifi_statusbar_null",
    "version": 9,
    "popularity": 2017,
    "codepoint": 61543,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "null",
      "phone",
      "signal",
      "speed",
      "statusbar",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "signature",
    "version": 248,
    "popularity": 84,
    "codepoint": 63308,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "authorize",
      "autograph",
      "cursive",
      "name",
      "sign",
      "signatory",
      "signing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signpost",
    "version": 248,
    "popularity": 1263,
    "codepoint": 60305,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "direction",
      "left",
      "maps",
      "right",
      "signal",
      "signs",
      "street",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "signpost",
    "version": 1,
    "popularity": 3112,
    "codepoint": 60305,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "direction",
      "left",
      "maps",
      "right",
      "signal",
      "signs",
      "street",
      "traffic"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sim_card",
    "version": 248,
    "popularity": 749,
    "codepoint": 58155,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "camera",
      "card",
      "chip",
      "device",
      "memory",
      "phone",
      "sim",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sim_card",
    "version": 12,
    "popularity": 4077,
    "codepoint": 58155,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "camera",
      "card",
      "chip",
      "device",
      "memory",
      "phone",
      "sim",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sim_card_alert",
    "version": 11,
    "popularity": 1615,
    "codepoint": 58916,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "camera",
      "card",
      "caution",
      "danger",
      "digital",
      "error",
      "exclamation",
      "important",
      "mark",
      "memory",
      "notification",
      "photos",
      "sd",
      "secure",
      "storage",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sim_card_download",
    "version": 248,
    "popularity": 860,
    "codepoint": 61544,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "arrow",
      "camera",
      "card",
      "chip",
      "device",
      "down",
      "download",
      "memory",
      "phone",
      "sim",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sim_card_download",
    "version": 9,
    "popularity": 4935,
    "codepoint": 61544,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "arrow",
      "camera",
      "card",
      "chip",
      "device",
      "down",
      "download",
      "memory",
      "phone",
      "sim",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "single_bed",
    "version": 248,
    "popularity": 544,
    "codepoint": 59976,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "single",
      "sleep",
      "twin"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "single_bed",
    "version": 11,
    "popularity": 3391,
    "codepoint": 59976,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bed",
      "bedroom",
      "double",
      "furniture",
      "home",
      "hotel",
      "house",
      "king",
      "night",
      "pillows",
      "queen",
      "rest",
      "room",
      "single",
      "sleep",
      "twin"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sip",
    "version": 248,
    "popularity": 261,
    "codepoint": 61545,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alphabet",
      "call",
      "character",
      "dialer",
      "font",
      "initiation",
      "internet",
      "letter",
      "over",
      "phone",
      "protocol",
      "routing",
      "session",
      "sip",
      "symbol",
      "text",
      "type",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sip",
    "version": 10,
    "popularity": 1413,
    "codepoint": 61545,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "alphabet",
      "call",
      "character",
      "dialer",
      "font",
      "initiation",
      "internet",
      "letter",
      "over",
      "phone",
      "protocol",
      "routing",
      "session",
      "sip",
      "symbol",
      "text",
      "type",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "skateboarding",
    "version": 248,
    "popularity": 579,
    "codepoint": 58641,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "skate",
      "skateboarder",
      "skateboarding",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "skateboarding",
    "version": 4,
    "popularity": 3317,
    "codepoint": 58641,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "skate",
      "skateboarder",
      "skateboarding",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "skeleton",
    "version": 248,
    "popularity": 206,
    "codepoint": 63641,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bone",
      "bones",
      "cage",
      "health",
      "hip",
      "medical",
      "rib"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "skillet",
    "version": 248,
    "popularity": 15,
    "codepoint": 62787,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "food",
      "fry pan",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "oven",
      "pan",
      "pot",
      "saucepan",
      "stockpot",
      "stove",
      "stovetop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "skillet_cooktop",
    "version": 248,
    "popularity": 2,
    "codepoint": 62788,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "food",
      "fry pan",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "oven",
      "pan",
      "pot",
      "saucepan",
      "stockpot",
      "stove",
      "stovetop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "skip_next",
    "version": 248,
    "popularity": 5471,
    "codepoint": 57412,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "music",
      "next",
      "play",
      "previous",
      "skip",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "skip_next",
    "version": 16,
    "popularity": 31674,
    "codepoint": 57412,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "music",
      "next",
      "play",
      "previous",
      "skip",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "skip_previous",
    "version": 248,
    "popularity": 3418,
    "codepoint": 57413,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "music",
      "next",
      "play",
      "previous",
      "skip",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "skip_previous",
    "version": 16,
    "popularity": 22985,
    "codepoint": 57413,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "music",
      "next",
      "play",
      "previous",
      "skip",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "skull",
    "version": 248,
    "popularity": 1077,
    "codepoint": 63642,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "bone",
      "bones",
      "cranium",
      "head",
      "health",
      "medical",
      "skeleton"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sledding",
    "version": 248,
    "popularity": 247,
    "codepoint": 58642,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "sled",
      "sledding",
      "sledge",
      "snow",
      "social",
      "sports",
      "travel",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sledding",
    "version": 5,
    "popularity": 1796,
    "codepoint": 58642,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "sled",
      "sledding",
      "sledge",
      "snow",
      "social",
      "sports",
      "travel",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sleep_score",
    "version": 248,
    "popularity": 6,
    "codepoint": 63159,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "crescent",
      "dark",
      "lunar",
      "moon",
      "night",
      "nighttime",
      "stat",
      "stats"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "slide_library",
    "version": 248,
    "popularity": 68,
    "codepoint": 63522,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "rectangle",
      "slides"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sliders",
    "version": 248,
    "popularity": 25,
    "codepoint": 59875,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "button",
      "half",
      "sliders"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "slideshow",
    "version": 248,
    "popularity": 1995,
    "codepoint": 58395,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "movie",
      "photos",
      "play",
      "slideshow",
      "square",
      "video",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "slideshow",
    "version": 12,
    "popularity": 9378,
    "codepoint": 58395,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "movie",
      "photos",
      "play",
      "slideshow",
      "square",
      "video",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "slow_motion_video",
    "version": 248,
    "popularity": 756,
    "codepoint": 57448,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "dash",
      "dashed",
      "motion",
      "music",
      "play",
      "slow",
      "speed",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "slow_motion_video",
    "version": 11,
    "popularity": 3620,
    "codepoint": 57448,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "dash",
      "dashed",
      "motion",
      "music",
      "play",
      "slow",
      "speed",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "smart_button",
    "version": 7,
    "popularity": 6597,
    "codepoint": 61889,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "action",
      "ai",
      "artificial",
      "automatic",
      "automation",
      "button",
      "components",
      "composer",
      "custom",
      "function",
      "genai",
      "intelligence",
      "interface",
      "magic",
      "site",
      "smart",
      "spark",
      "sparkle",
      "special",
      "star",
      "stars",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "smart_display",
    "version": 248,
    "popularity": 4749,
    "codepoint": 61546,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "airplay",
      "cast",
      "chrome",
      "connect",
      "device",
      "display",
      "play",
      "screen",
      "screencast",
      "smart",
      "stream",
      "television",
      "tv",
      "video",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smart_display",
    "version": 9,
    "popularity": 26423,
    "codepoint": 61546,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "airplay",
      "cast",
      "chrome",
      "connect",
      "device",
      "display",
      "play",
      "screen",
      "screencast",
      "smart",
      "stream",
      "television",
      "tv",
      "video",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "smart_outlet",
    "version": 248,
    "popularity": 237,
    "codepoint": 59460,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "electronic",
      "home",
      "house",
      "nest",
      "outlet",
      "power",
      "smart",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smart_screen",
    "version": 248,
    "popularity": 221,
    "codepoint": 61547,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "cell",
      "connect",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "screencast",
      "smart",
      "stream",
      "tablet",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smart_screen",
    "version": 9,
    "popularity": 1395,
    "codepoint": 61547,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "airplay",
      "cast",
      "cell",
      "connect",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "screen",
      "screencast",
      "smart",
      "stream",
      "tablet",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "smart_toy",
    "version": 248,
    "popularity": 3591,
    "codepoint": 61548,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "bot",
      "droid",
      "games",
      "robot",
      "smart",
      "toy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smart_toy",
    "version": 9,
    "popularity": 17955,
    "codepoint": 61548,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "bot",
      "droid",
      "games",
      "robot",
      "smart",
      "toy"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "smartphone",
    "version": 248,
    "popularity": 10667,
    "codepoint": 58156,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "call",
      "cell",
      "chat",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "smartphone",
      "tablet",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smartphone",
    "version": 16,
    "popularity": 44282,
    "codepoint": 58156,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "call",
      "cell",
      "chat",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "smartphone",
      "tablet",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "smb_share",
    "version": 248,
    "popularity": 25,
    "codepoint": 63307,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cloud",
      "connection",
      "content",
      "copy",
      "cut",
      "data",
      "doc",
      "document",
      "drive",
      "duplicate",
      "file",
      "folder",
      "folders",
      "internet",
      "multiple",
      "network",
      "paste",
      "server",
      "sheet",
      "sky",
      "slide",
      "stack",
      "storage",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smoke_free",
    "version": 248,
    "popularity": 681,
    "codepoint": 60234,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "cigarette",
      "disabled",
      "enabled",
      "free",
      "never",
      "no",
      "off",
      "on",
      "places",
      "prohibited",
      "slash",
      "smoke",
      "smoking",
      "tobacco",
      "warning",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smoke_free",
    "version": 12,
    "popularity": 2678,
    "codepoint": 60234,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "cigarette",
      "disabled",
      "enabled",
      "free",
      "never",
      "no",
      "off",
      "on",
      "places",
      "prohibited",
      "slash",
      "smoke",
      "smoking",
      "tobacco",
      "warning",
      "zone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "smoking_rooms",
    "version": 248,
    "popularity": 660,
    "codepoint": 60235,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "allowed",
      "cigarette",
      "places",
      "rooms",
      "smoke",
      "smoking",
      "tobacco",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "smoking_rooms",
    "version": 12,
    "popularity": 3340,
    "codepoint": 60235,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "allowed",
      "cigarette",
      "places",
      "rooms",
      "smoke",
      "smoking",
      "tobacco",
      "zone"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sms",
    "version": 248,
    "popularity": 7268,
    "codepoint": 58917,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "3",
      "bubble",
      "chat",
      "communication",
      "conversation",
      "dots",
      "message",
      "more",
      "service",
      "sms",
      "speech",
      "three"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sms",
    "version": 12,
    "popularity": 20837,
    "codepoint": 58917,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "3",
      "bubble",
      "chat",
      "communication",
      "conversation",
      "dots",
      "message",
      "more",
      "service",
      "sms",
      "speech",
      "three"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sms_failed",
    "version": 13,
    "popularity": 4969,
    "codepoint": 58918,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "!",
      "alert",
      "announcement",
      "attention",
      "caution",
      "chat",
      "chat bubble",
      "comment",
      "communicate",
      "communication",
      "conversation",
      "danger",
      "error",
      "exclamation",
      "failed",
      "feedback",
      "important",
      "mark",
      "message",
      "notification",
      "service",
      "sms",
      "speech",
      "symbol",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "snippet_folder",
    "version": 248,
    "popularity": 324,
    "codepoint": 61895,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "snippet",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "snippet_folder",
    "version": 7,
    "popularity": 3734,
    "codepoint": 61895,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "snippet",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "snooze",
    "version": 248,
    "popularity": 627,
    "codepoint": 57414,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alarm",
      "bell",
      "clock",
      "duration",
      "notification",
      "snooze",
      "time",
      "timer",
      "watch",
      "z"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "snooze",
    "version": 12,
    "popularity": 3177,
    "codepoint": 57414,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alarm",
      "bell",
      "clock",
      "duration",
      "notification",
      "snooze",
      "time",
      "timer",
      "watch",
      "z"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "snowboarding",
    "version": 248,
    "popularity": 341,
    "codepoint": 58643,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "snow",
      "snowboarding",
      "social",
      "sports",
      "travel",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "snowboarding",
    "version": 4,
    "popularity": 2377,
    "codepoint": 58643,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "snow",
      "snowboarding",
      "social",
      "sports",
      "travel",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "snowing",
    "version": 248,
    "popularity": 585,
    "codepoint": 59407,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cold",
      "snow",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "snowing",
    "version": 3,
    "popularity": 712,
    "codepoint": 59407,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "climate",
      "cold",
      "snow",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "snowing_heavy",
    "version": 248,
    "popularity": 6,
    "codepoint": 63004,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cold",
      "snow",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "snowmobile",
    "version": 248,
    "popularity": 196,
    "codepoint": 58627,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "direction",
      "skimobile",
      "snow",
      "snowmobile",
      "social",
      "sports",
      "transportation",
      "travel",
      "vehicle",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "snowmobile",
    "version": 4,
    "popularity": 1316,
    "codepoint": 58627,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "direction",
      "skimobile",
      "snow",
      "snowmobile",
      "social",
      "sports",
      "transportation",
      "travel",
      "vehicle",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "snowshoeing",
    "version": 248,
    "popularity": 289,
    "codepoint": 58644,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "body",
      "human",
      "people",
      "person",
      "snow",
      "snowshoe",
      "snowshoeing",
      "sports",
      "travel",
      "walking",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "snowshoeing",
    "version": 4,
    "popularity": 2102,
    "codepoint": 58644,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "body",
      "human",
      "people",
      "person",
      "snow",
      "snowshoe",
      "snowshoeing",
      "sports",
      "travel",
      "walking",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "soap",
    "version": 248,
    "popularity": 469,
    "codepoint": 61874,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bathroom",
      "clean",
      "fingers",
      "gesture",
      "hand",
      "soap",
      "wash",
      "wc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "soap",
    "version": 8,
    "popularity": 2853,
    "codepoint": 61874,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bathroom",
      "clean",
      "fingers",
      "gesture",
      "hand",
      "soap",
      "wash",
      "wc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "social_distance",
    "version": 248,
    "popularity": 820,
    "codepoint": 57803,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "6",
      "apart",
      "body",
      "distance",
      "ft",
      "human",
      "people",
      "person",
      "social",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "social_distance",
    "version": 7,
    "popularity": 5262,
    "codepoint": 57803,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "6",
      "apart",
      "body",
      "distance",
      "ft",
      "human",
      "people",
      "person",
      "social",
      "space"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "social_leaderboard",
    "version": 248,
    "popularity": 96,
    "codepoint": 63136,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "award",
      "medallion",
      "medals",
      "reward",
      "ribbon",
      "ribbons",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "solar_power",
    "version": 248,
    "popularity": 1915,
    "codepoint": 60431,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "eco",
      "energy",
      "heat",
      "nest",
      "power",
      "solar",
      "sun",
      "sunny"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "solar_power",
    "version": 1,
    "popularity": 3063,
    "codepoint": 60431,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "eco",
      "energy",
      "heat",
      "nest",
      "power",
      "solar",
      "sun",
      "sunny"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sort",
    "version": 248,
    "popularity": 10850,
    "codepoint": 57700,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "filter",
      "find",
      "lines",
      "list",
      "organize",
      "sort"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sort",
    "version": 13,
    "popularity": 49215,
    "codepoint": 57700,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "filter",
      "find",
      "lines",
      "list",
      "organize",
      "sort"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sort_by_alpha",
    "version": 248,
    "popularity": 2819,
    "codepoint": 57427,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alphabet",
      "alphabetize",
      "az",
      "by alpha",
      "character",
      "font",
      "letter",
      "list",
      "order",
      "organize",
      "sort",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sort_by_alpha",
    "version": 11,
    "popularity": 11397,
    "codepoint": 57427,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alphabet",
      "alphabetize",
      "az",
      "by alpha",
      "character",
      "font",
      "letter",
      "list",
      "order",
      "organize",
      "sort",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sos",
    "version": 248,
    "popularity": 463,
    "codepoint": 60407,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "font",
      "help",
      "letters",
      "save",
      "sos",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sos",
    "version": 1,
    "popularity": 1131,
    "codepoint": 60407,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "font",
      "help",
      "letters",
      "save",
      "sos",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sound_detection_dog_barking",
    "version": 248,
    "popularity": 1528,
    "codepoint": 61769,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "animal",
      "barking",
      "detection",
      "dog",
      "hound",
      "loyalty",
      "pet",
      "protection",
      "security",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sound_detection_glass_break",
    "version": 248,
    "popularity": 188,
    "codepoint": 61770,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "break",
      "broken",
      "detection",
      "glass",
      "shatter",
      "sound"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sound_detection_loud_sound",
    "version": 248,
    "popularity": 660,
    "codepoint": 61771,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "detection",
      "loud",
      "music",
      "sound",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sound_sampler",
    "version": 248,
    "popularity": 3,
    "codepoint": 63156,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "control",
      "controls",
      "device",
      "music",
      "play"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "soup_kitchen",
    "version": 248,
    "popularity": 917,
    "codepoint": 59347,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "dining",
      "food",
      "kitchen",
      "lunch",
      "meal",
      "soup"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "soup_kitchen",
    "version": 2,
    "popularity": 3128,
    "codepoint": 59347,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "breakfast",
      "brunch",
      "dining",
      "food",
      "kitchen",
      "lunch",
      "meal",
      "soup"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "source",
    "version": 7,
    "popularity": 22755,
    "codepoint": 61892,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "code",
      "composer",
      "content",
      "creation",
      "data",
      "doc",
      "document",
      "file",
      "folder",
      "mode",
      "source",
      "storage",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "source_environment",
    "version": 248,
    "popularity": 0,
    "codepoint": 58663,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "apartment",
      "architecture",
      "building",
      "business",
      "company",
      "environment",
      "estate",
      "home",
      "house",
      "maps",
      "office",
      "place",
      "real",
      "residence",
      "residential",
      "source"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "source_notes",
    "version": 248,
    "popularity": 0,
    "codepoint": 57645,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "direction",
      "doc",
      "document",
      "file",
      "health",
      "inprogress",
      "left",
      "load",
      "loading refresh",
      "navigation",
      "note",
      "page",
      "paper",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "south",
    "version": 248,
    "popularity": 2262,
    "codepoint": 61923,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directional",
      "down",
      "maps",
      "navigation",
      "south"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "south",
    "version": 7,
    "popularity": 19456,
    "codepoint": 61923,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directional",
      "down",
      "maps",
      "navigation",
      "south"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "south_america",
    "version": 248,
    "popularity": 811,
    "codepoint": 59364,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "continent",
      "landscape",
      "place",
      "region",
      "south america"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "south_america",
    "version": 2,
    "popularity": 2005,
    "codepoint": 59364,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "continent",
      "landscape",
      "place",
      "region",
      "south america"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "south_east",
    "version": 248,
    "popularity": 742,
    "codepoint": 61924,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directional",
      "down",
      "east",
      "maps",
      "navigation",
      "right",
      "south"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "south_east",
    "version": 7,
    "popularity": 5626,
    "codepoint": 61924,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directional",
      "down",
      "east",
      "maps",
      "navigation",
      "right",
      "south"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "south_west",
    "version": 248,
    "popularity": 0,
    "codepoint": 61925,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directional",
      "down",
      "left",
      "maps",
      "navigation",
      "south",
      "west"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "south_west",
    "version": 7,
    "popularity": 4199,
    "codepoint": 61925,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directional",
      "down",
      "left",
      "maps",
      "navigation",
      "south",
      "west"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "spa",
    "version": 248,
    "popularity": 3737,
    "codepoint": 60236,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "aromatherapy",
      "flower",
      "healthcare",
      "leaf",
      "massage",
      "meditation",
      "nature",
      "petals",
      "places",
      "relax",
      "spa",
      "wellbeing",
      "wellness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spa",
    "version": 12,
    "popularity": 21969,
    "codepoint": 60236,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "aromatherapy",
      "flower",
      "healthcare",
      "leaf",
      "massage",
      "meditation",
      "nature",
      "petals",
      "places",
      "relax",
      "spa",
      "wellbeing",
      "wellness"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "space_bar",
    "version": 248,
    "popularity": 414,
    "codepoint": 57942,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bar",
      "keyboard",
      "line",
      "space"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "space_bar",
    "version": 11,
    "popularity": 2350,
    "codepoint": 57942,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "bar",
      "keyboard",
      "line",
      "space"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "space_dashboard",
    "version": 248,
    "popularity": 3396,
    "codepoint": 58987,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cards",
      "dashboard",
      "format",
      "grid",
      "layout",
      "rectangle",
      "shapes",
      "space",
      "squares",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "space_dashboard",
    "version": 3,
    "popularity": 29413,
    "codepoint": 58987,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cards",
      "dashboard",
      "format",
      "grid",
      "layout",
      "rectangle",
      "shapes",
      "space",
      "squares",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "spatial_audio",
    "version": 248,
    "popularity": 384,
    "codepoint": 60395,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "music",
      "note",
      "sound",
      "spatial"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spatial_audio",
    "version": 1,
    "popularity": 1162,
    "codepoint": 60395,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "audio",
      "music",
      "note",
      "sound",
      "spatial"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "spatial_audio_off",
    "version": 248,
    "popularity": 484,
    "codepoint": 60392,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
      "spatial"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spatial_audio_off",
    "version": 1,
    "popularity": 2064,
    "codepoint": 60392,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
      "spatial"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "spatial_tracking",
    "version": 248,
    "popularity": 322,
    "codepoint": 60394,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
      "spatial",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spatial_tracking",
    "version": 1,
    "popularity": 1214,
    "codepoint": 60394,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "audio",
      "disabled",
      "enabled",
      "music",
      "note",
      "off",
      "offline",
      "on",
      "slash",
      "sound",
      "spatial",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "speaker",
    "version": 248,
    "popularity": 749,
    "codepoint": 58157,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "box",
      "electronic",
      "loud",
      "music",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speaker",
    "version": 16,
    "popularity": 4483,
    "codepoint": 58157,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "box",
      "electronic",
      "loud",
      "music",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "speaker_group",
    "version": 248,
    "popularity": 340,
    "codepoint": 58158,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "box",
      "electronic",
      "group",
      "loud",
      "multiple",
      "music",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speaker_group",
    "version": 12,
    "popularity": 2080,
    "codepoint": 58158,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "box",
      "electronic",
      "group",
      "loud",
      "multiple",
      "music",
      "sound",
      "speaker",
      "stereo",
      "system",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "speaker_notes",
    "version": 248,
    "popularity": 1955,
    "codepoint": 59597,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "format",
      "list",
      "message",
      "notes",
      "speaker",
      "speech",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speaker_notes",
    "version": 13,
    "popularity": 15596,
    "codepoint": 59597,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "format",
      "list",
      "message",
      "notes",
      "speaker",
      "speech",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "speaker_notes_off",
    "version": 248,
    "popularity": 402,
    "codepoint": 59690,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "disabled",
      "enabled",
      "format",
      "list",
      "message",
      "notes",
      "off",
      "on",
      "slash",
      "speaker",
      "speech",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speaker_notes_off",
    "version": 13,
    "popularity": 3861,
    "codepoint": 59690,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "disabled",
      "enabled",
      "format",
      "list",
      "message",
      "notes",
      "off",
      "on",
      "slash",
      "speaker",
      "speech",
      "text"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "speaker_phone",
    "version": 248,
    "popularity": 408,
    "codepoint": 57554,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "sound",
      "speaker",
      "tablet",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speaker_phone",
    "version": 15,
    "popularity": 2300,
    "codepoint": 57554,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "sound",
      "speaker",
      "tablet",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "special_character",
    "version": 248,
    "popularity": 5,
    "codepoint": 63306,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "characters",
      "symbol",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "specific_gravity",
    "version": 248,
    "popularity": 124,
    "codepoint": 63602,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "cup",
      "density",
      "gravity",
      "specific",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speech_to_text",
    "version": 248,
    "popularity": 394,
    "codepoint": 63655,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "doc",
      "document",
      "feedback",
      "file",
      "message",
      "mic",
      "microphone",
      "page",
      "paper",
      "speech",
      "text"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speed",
    "version": 248,
    "popularity": 5537,
    "codepoint": 59876,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "fast",
      "gauge",
      "meter",
      "motion",
      "music",
      "slow",
      "speed",
      "speedometer",
      "velocity",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speed",
    "version": 12,
    "popularity": 29398,
    "codepoint": 59876,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "control",
      "controls",
      "fast",
      "gauge",
      "meter",
      "motion",
      "music",
      "slow",
      "speed",
      "speedometer",
      "velocity",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "speed_0_5",
    "version": 248,
    "popularity": 4,
    "codepoint": 62690,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "playback",
      "replay",
      "speed",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speed_1_2",
    "version": 248,
    "popularity": 1,
    "codepoint": 62689,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "playback",
      "replay",
      "speed",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speed_1_5",
    "version": 248,
    "popularity": 1,
    "codepoint": 62688,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "playback",
      "replay",
      "speed",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "speed_2x",
    "version": 248,
    "popularity": 2,
    "codepoint": 62699,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alphabet",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "playback",
      "replay",
      "speed",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spellcheck",
    "version": 248,
    "popularity": 523,
    "codepoint": 59598,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "a",
      "alphabet",
      "approve",
      "character",
      "check",
      "font",
      "letter",
      "mark",
      "ok",
      "processor",
      "select",
      "spell",
      "spellcheck",
      "symbol",
      "text",
      "tick",
      "type",
      "word",
      "write",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spellcheck",
    "version": 12,
    "popularity": 5594,
    "codepoint": 59598,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "a",
      "alphabet",
      "approve",
      "character",
      "check",
      "font",
      "letter",
      "mark",
      "ok",
      "processor",
      "select",
      "spell",
      "spellcheck",
      "symbol",
      "text",
      "tick",
      "type",
      "word",
      "write",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "splitscreen",
    "version": 248,
    "popularity": 813,
    "codepoint": 61549,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "splitscreen",
    "version": 11,
    "popularity": 5096,
    "codepoint": 61549,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "splitscreen_add",
    "version": 248,
    "popularity": 1,
    "codepoint": 62717,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "column",
      "grid",
      "layout",
      "multitasking",
      "new symbol",
      "plus",
      "row",
      "screen",
      "split",
      "splitscreen",
      "symbol",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "splitscreen_bottom",
    "version": 248,
    "popularity": 5,
    "codepoint": 63094,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "splitscreen_left",
    "version": 248,
    "popularity": 16,
    "codepoint": 63093,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "splitscreen_right",
    "version": 248,
    "popularity": 13,
    "codepoint": 63092,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "splitscreen_top",
    "version": 248,
    "popularity": 5,
    "codepoint": 63091,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "column",
      "grid",
      "layout",
      "multitasking",
      "row",
      "screen",
      "split",
      "splitscreen",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "splitscreen_vertical_add",
    "version": 248,
    "popularity": 4,
    "codepoint": 62716,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "+",
      "add",
      "column",
      "grid",
      "layout",
      "multitasking",
      "new symbol",
      "plus",
      "row",
      "screen",
      "split",
      "splitscreen",
      "symbol",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spo2",
    "version": 248,
    "popularity": 19,
    "codepoint": 63195,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "blood",
      "fitbit",
      "health",
      "oxygen",
      "oxygen saturation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spoke",
    "version": 248,
    "popularity": 582,
    "codepoint": 59815,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "connection",
      "network",
      "radius",
      "spoke"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "spoke",
    "version": 2,
    "popularity": 2293,
    "codepoint": 59815,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "connection",
      "network",
      "radius",
      "spoke"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports",
    "version": 248,
    "popularity": 874,
    "codepoint": 59952,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "blowing",
      "coach",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "instrument",
      "referee",
      "social",
      "sound",
      "sports",
      "warning",
      "whistle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports",
    "version": 11,
    "popularity": 6358,
    "codepoint": 59952,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "blowing",
      "coach",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "instrument",
      "referee",
      "social",
      "sound",
      "sports",
      "warning",
      "whistle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_and_outdoors",
    "version": 248,
    "popularity": 63,
    "codepoint": 61368,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "and outdoors",
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "hobby",
      "soccer",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_bar",
    "version": 248,
    "popularity": 1472,
    "codepoint": 61939,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "alcohol",
      "bar",
      "beer",
      "drink",
      "liquor",
      "pint",
      "places",
      "pub",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_bar",
    "version": 7,
    "popularity": 6643,
    "codepoint": 61939,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "alcohol",
      "bar",
      "beer",
      "drink",
      "liquor",
      "pint",
      "places",
      "pub",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_baseball",
    "version": 248,
    "popularity": 737,
    "codepoint": 59985,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "baseball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_baseball",
    "version": 11,
    "popularity": 4339,
    "codepoint": 59985,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "baseball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_basketball",
    "version": 248,
    "popularity": 1631,
    "codepoint": 59942,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "basketball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_basketball",
    "version": 11,
    "popularity": 7323,
    "codepoint": 59942,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "basketball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_cricket",
    "version": 248,
    "popularity": 439,
    "codepoint": 59943,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "bat",
      "cricket",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_cricket",
    "version": 11,
    "popularity": 2873,
    "codepoint": 59943,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "bat",
      "cricket",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_esports",
    "version": 248,
    "popularity": 5054,
    "codepoint": 59944,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "controller",
      "entertainment",
      "esports",
      "game",
      "gamepad",
      "gaming",
      "hobby",
      "online",
      "social",
      "sports",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_esports",
    "version": 11,
    "popularity": 30108,
    "codepoint": 59944,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "controller",
      "entertainment",
      "esports",
      "game",
      "gamepad",
      "gaming",
      "hobby",
      "online",
      "social",
      "sports",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_football",
    "version": 248,
    "popularity": 640,
    "codepoint": 59945,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "football",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_football",
    "version": 12,
    "popularity": 3739,
    "codepoint": 59945,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "football",
      "game",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_golf",
    "version": 248,
    "popularity": 480,
    "codepoint": 59946,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "club",
      "entertainment",
      "exercise",
      "game",
      "golf",
      "golfer",
      "golfing",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_golf",
    "version": 11,
    "popularity": 2646,
    "codepoint": 59946,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "club",
      "entertainment",
      "exercise",
      "game",
      "golf",
      "golfer",
      "golfing",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_gymnastics",
    "version": 248,
    "popularity": 1060,
    "codepoint": 60356,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "gymnastics",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_gymnastics",
    "version": 1,
    "popularity": 2398,
    "codepoint": 60356,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "gymnastics",
      "hobby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_handball",
    "version": 248,
    "popularity": 642,
    "codepoint": 59955,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "body",
      "entertainment",
      "exercise",
      "game",
      "handball",
      "hobby",
      "human",
      "people",
      "person",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_handball",
    "version": 11,
    "popularity": 4441,
    "codepoint": 59955,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "body",
      "entertainment",
      "exercise",
      "game",
      "handball",
      "hobby",
      "human",
      "people",
      "person",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_hockey",
    "version": 248,
    "popularity": 339,
    "codepoint": 59947,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "hockey",
      "social",
      "sports",
      "sticks"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_hockey",
    "version": 11,
    "popularity": 2030,
    "codepoint": 59947,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "hockey",
      "social",
      "sports",
      "sticks"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_kabaddi",
    "version": 248,
    "popularity": 941,
    "codepoint": 59956,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "combat",
      "entertainment",
      "exercise",
      "fighting",
      "game",
      "hobby",
      "human",
      "kabaddi",
      "people",
      "person",
      "social",
      "sports",
      "wrestle",
      "wrestling"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_kabaddi",
    "version": 11,
    "popularity": 6402,
    "codepoint": 59956,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "body",
      "combat",
      "entertainment",
      "exercise",
      "fighting",
      "game",
      "hobby",
      "human",
      "kabaddi",
      "people",
      "person",
      "social",
      "sports",
      "wrestle",
      "wrestling"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_martial_arts",
    "version": 248,
    "popularity": 737,
    "codepoint": 60137,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "arts",
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "karate",
      "martial",
      "people",
      "person",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_martial_arts",
    "version": 1,
    "popularity": 2727,
    "codepoint": 60137,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arts",
      "athlete",
      "athletic",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "karate",
      "martial",
      "people",
      "person",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_mma",
    "version": 248,
    "popularity": 390,
    "codepoint": 59948,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "arts",
      "athlete",
      "athletic",
      "boxing",
      "combat",
      "entertainment",
      "exercise",
      "fighting",
      "game",
      "glove",
      "hobby",
      "martial",
      "mixed",
      "mma",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_mma",
    "version": 11,
    "popularity": 2767,
    "codepoint": 59948,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arts",
      "athlete",
      "athletic",
      "boxing",
      "combat",
      "entertainment",
      "exercise",
      "fighting",
      "game",
      "glove",
      "hobby",
      "martial",
      "mixed",
      "mma",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_motorsports",
    "version": 248,
    "popularity": 951,
    "codepoint": 59949,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "automobile",
      "bike",
      "drive",
      "driving",
      "entertainment",
      "helmet",
      "hobby",
      "motorcycle",
      "motorsports",
      "protect",
      "social",
      "sports",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_motorsports",
    "version": 11,
    "popularity": 4648,
    "codepoint": 59949,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "automobile",
      "bike",
      "drive",
      "driving",
      "entertainment",
      "helmet",
      "hobby",
      "motorcycle",
      "motorsports",
      "protect",
      "social",
      "sports",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_rugby",
    "version": 248,
    "popularity": 271,
    "codepoint": 59950,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "rugby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_rugby",
    "version": 11,
    "popularity": 1963,
    "codepoint": 59950,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "rugby",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_score",
    "version": 248,
    "popularity": 1714,
    "codepoint": 61550,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "destination",
      "flag",
      "goal",
      "score",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_score",
    "version": 10,
    "popularity": 10231,
    "codepoint": 61550,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "destination",
      "flag",
      "goal",
      "score",
      "sports"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sports_soccer",
    "version": 248,
    "popularity": 3353,
    "codepoint": 59951,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "football",
      "game",
      "hobby",
      "soccer",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_soccer",
    "version": 11,
    "popularity": 15222,
    "codepoint": 59951,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "football",
      "game",
      "hobby",
      "soccer",
      "social",
      "sports"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_tennis",
    "version": 248,
    "popularity": 1309,
    "codepoint": 59954,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "bat",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "racket",
      "social",
      "sports",
      "tennis"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_tennis",
    "version": 11,
    "popularity": 5819,
    "codepoint": 59954,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "bat",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "racket",
      "social",
      "sports",
      "tennis"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sports_volleyball",
    "version": 248,
    "popularity": 756,
    "codepoint": 59953,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports",
      "volleyball"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sports_volleyball",
    "version": 11,
    "popularity": 4052,
    "codepoint": 59953,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "ball",
      "entertainment",
      "exercise",
      "game",
      "hobby",
      "social",
      "sports",
      "volleyball"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sprinkler",
    "version": 248,
    "popularity": 500,
    "codepoint": 58010,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "drizzle",
      "drops",
      "irrigation",
      "nest",
      "spray",
      "sprinkler",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sprint",
    "version": 248,
    "popularity": 137,
    "codepoint": 63519,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "activity",
      "athlete",
      "athletic",
      "exercise",
      "game",
      "hobby",
      "run",
      "running",
      "social",
      "sport",
      "sports",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "square",
    "version": 248,
    "popularity": 953,
    "codepoint": 60214,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "draw",
      "four",
      "shape quadrangle",
      "sides",
      "square"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "square",
    "version": 1,
    "popularity": 6082,
    "codepoint": 60214,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "draw",
      "four",
      "shape quadrangle",
      "sides",
      "square"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "square_foot",
    "version": 248,
    "popularity": 1648,
    "codepoint": 59977,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "construction",
      "feet",
      "foot",
      "inches",
      "length",
      "measurement",
      "ruler",
      "school",
      "set",
      "square",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "square_foot",
    "version": 11,
    "popularity": 11499,
    "codepoint": 59977,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "construction",
      "feet",
      "foot",
      "inches",
      "length",
      "measurement",
      "ruler",
      "school",
      "set",
      "square",
      "tools"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ssid_chart",
    "version": 248,
    "popularity": 735,
    "codepoint": 60262,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "chart",
      "graph",
      "lines",
      "network",
      "ssid",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ssid_chart",
    "version": 1,
    "popularity": 2788,
    "codepoint": 60262,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "chart",
      "graph",
      "lines",
      "network",
      "ssid",
      "wifi"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "stack",
    "version": 248,
    "popularity": 137,
    "codepoint": 62985,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "multiple",
      "square",
      "stacked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stack_off",
    "version": 248,
    "popularity": 26,
    "codepoint": 62984,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "disabled",
      "enabled",
      "multiple",
      "off",
      "offline",
      "slash",
      "square",
      "stacked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stack_star",
    "version": 248,
    "popularity": 57,
    "codepoint": 62983,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "bookmark",
      "favorite",
      "highlight",
      "important",
      "marked",
      "multiple",
      "save",
      "special",
      "square",
      "stacked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stacked_bar_chart",
    "version": 248,
    "popularity": 1127,
    "codepoint": 59878,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "chart-chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stacked_bar_chart",
    "version": 12,
    "popularity": 11888,
    "codepoint": 59878,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "analytics",
      "bar",
      "chart-chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "stacked_email",
    "version": 248,
    "popularity": 97,
    "codepoint": 59079,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "archive",
      "email",
      "mail",
      "send",
      "stacked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stacked_inbox",
    "version": 248,
    "popularity": 6,
    "codepoint": 59081,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "box",
      "email",
      "in",
      "inbox",
      "mail",
      "stacked"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stacked_line_chart",
    "version": 248,
    "popularity": 1158,
    "codepoint": 61995,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stacked_line_chart",
    "version": 6,
    "popularity": 7915,
    "codepoint": 61995,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "line",
      "measure",
      "metrics",
      "stacked",
      "statistics",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stacks",
    "version": 248,
    "popularity": 46,
    "codepoint": 62720,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrange",
      "layers",
      "moma search",
      "stack",
      "teamgraph"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stadia_controller",
    "version": 248,
    "popularity": 4958,
    "codepoint": 61749,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "console",
      "control",
      "controller",
      "device",
      "esports",
      "game",
      "gaming",
      "google",
      "handheld",
      "hardware",
      "playstation",
      "remote",
      "stadia",
      "video",
      "xbox"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stadium",
    "version": 248,
    "popularity": 862,
    "codepoint": 60304,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "activity",
      "amphitheater",
      "arena",
      "coliseum",
      "event",
      "local",
      "stadium",
      "star",
      "things",
      "ticket"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stadium",
    "version": 1,
    "popularity": 2238,
    "codepoint": 60304,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "activity",
      "amphitheater",
      "arena",
      "coliseum",
      "event",
      "local",
      "stadium",
      "star",
      "things",
      "ticket"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "stairs",
    "version": 248,
    "popularity": 788,
    "codepoint": 61865,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "down",
      "staircase",
      "stairs",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stairs",
    "version": 8,
    "popularity": 3996,
    "codepoint": 61865,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "down",
      "staircase",
      "stairs",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star",
    "version": 248,
    "popularity": 51410,
    "codepoint": 59448,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "best",
      "bookmark",
      "favorite",
      "highlight",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
      "toggle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "star",
    "version": 22,
    "popularity": 133654,
    "codepoint": 59448,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "best",
      "bookmark",
      "favorite",
      "highlight",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star_border",
    "version": 19,
    "popularity": 52085,
    "codepoint": 59450,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "best",
      "bookmark",
      "border",
      "favorite",
      "highlight",
      "outline",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star_border_purple500",
    "version": 10,
    "popularity": 7041,
    "codepoint": 61593,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "500",
      "best",
      "bookmark",
      "border",
      "favorite",
      "highlight",
      "outline",
      "purple",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star_half",
    "version": 248,
    "popularity": 6310,
    "codepoint": 59449,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "favorite",
      "half",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star",
      "toggle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "star_half",
    "version": 22,
    "popularity": 16911,
    "codepoint": 59449,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "favorite",
      "half",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star_outline",
    "version": 11,
    "popularity": 35952,
    "codepoint": 61551,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "bookmark",
      "favorite",
      "half",
      "highlight",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star_purple500",
    "version": 10,
    "popularity": 9496,
    "codepoint": 61594,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "500",
      "best",
      "bookmark",
      "favorite",
      "highlight",
      "purple",
      "ranking",
      "rate",
      "rating",
      "save",
      "star",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star_rate",
    "version": 248,
    "popularity": 1067,
    "codepoint": 61676,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "star_rate",
    "version": 12,
    "popularity": 74240,
    "codepoint": 61676,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "star_rate_half",
    "version": 248,
    "popularity": 61,
    "codepoint": 60485,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stars",
    "version": 248,
    "popularity": 5472,
    "codepoint": 59600,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "circle",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stars",
    "version": 12,
    "popularity": 31947,
    "codepoint": 59600,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "achievement",
      "bookmark",
      "circle",
      "favorite",
      "highlight",
      "important",
      "marked",
      "ranking",
      "rate",
      "rating rank",
      "reward",
      "save",
      "saved",
      "shape",
      "special",
      "star"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "start",
    "version": 248,
    "popularity": 3936,
    "codepoint": 57481,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "keyboard",
      "next",
      "right",
      "start"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "start",
    "version": 1,
    "popularity": 8763,
    "codepoint": 57481,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "arrow",
      "keyboard",
      "next",
      "right",
      "start"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "stat_0",
    "version": 248,
    "popularity": 163,
    "codepoint": 59031,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "0",
      "diamond",
      "square",
      "stat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stat_1",
    "version": 248,
    "popularity": 26,
    "codepoint": 59032,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "1 arrow",
      "arrows",
      "direction",
      "stat",
      "up",
      "upward"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stat_2",
    "version": 248,
    "popularity": 32,
    "codepoint": 59033,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "2",
      "arrow",
      "arrows",
      "direction",
      "stat",
      "two",
      "up",
      "upward"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stat_3",
    "version": 248,
    "popularity": 58,
    "codepoint": 59034,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3",
      "arrow",
      "arrows",
      "direction",
      "stat",
      "three",
      "up",
      "upward"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stat_minus_1",
    "version": 248,
    "popularity": 37,
    "codepoint": 59035,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "1",
      "arrow",
      "arrows",
      "direction",
      "down",
      "downward",
      "minus",
      "stat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stat_minus_2",
    "version": 248,
    "popularity": 34,
    "codepoint": 59036,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "2",
      "arrow",
      "arrows",
      "direction",
      "down",
      "downward",
      "minus",
      "stat",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stat_minus_3",
    "version": 248,
    "popularity": 27,
    "codepoint": 59037,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "3",
      "arrow",
      "arrows",
      "direction",
      "doward",
      "down",
      "minus",
      "stat",
      "three"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stay_current_landscape",
    "version": 248,
    "popularity": 0,
    "codepoint": 57555,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "mobile",
      "phone",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stay_current_landscape",
    "version": 12,
    "popularity": 1421,
    "codepoint": 57555,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "mobile",
      "phone",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stay_current_portrait",
    "version": 248,
    "popularity": 390,
    "codepoint": 57556,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "portrait",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stay_current_portrait",
    "version": 12,
    "popularity": 4541,
    "codepoint": 57556,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "portrait",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stay_primary_landscape",
    "version": 248,
    "popularity": 170,
    "codepoint": 57557,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "mobile",
      "phone",
      "primary",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stay_primary_landscape",
    "version": 12,
    "popularity": 1329,
    "codepoint": 57557,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "landscape",
      "mobile",
      "phone",
      "primary",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stay_primary_portrait",
    "version": 248,
    "popularity": 176,
    "codepoint": 57558,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "portrait",
      "primary",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stay_primary_portrait",
    "version": 12,
    "popularity": 3429,
    "codepoint": 57558,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "current",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "portrait",
      "primary",
      "stay",
      "tablet"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "step",
    "version": 248,
    "popularity": 47,
    "codepoint": 63230,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "east",
      "left",
      "navigation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "step_into",
    "version": 248,
    "popularity": 0,
    "codepoint": 63233,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "navigation",
      "south"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "step_out",
    "version": 248,
    "popularity": 14,
    "codepoint": 63232,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "north",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "step_over",
    "version": 248,
    "popularity": 30,
    "codepoint": 63231,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "refresh",
      "renew",
      "rotate",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "steppers",
    "version": 248,
    "popularity": 64,
    "codepoint": 59879,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "circles",
      "content",
      "control",
      "dots",
      "media",
      "more",
      "page",
      "scroll",
      "steppers",
      "swipe",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "steps",
    "version": 248,
    "popularity": 0,
    "codepoint": 63194,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "fitbit",
      "shoe",
      "sneaker",
      "step"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stethoscope",
    "version": 248,
    "popularity": 54,
    "codepoint": 63493,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "admission",
      "doctor",
      "health",
      "med",
      "medical",
      "medicine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stethoscope_arrow",
    "version": 248,
    "popularity": 6,
    "codepoint": 63495,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "admission",
      "arrow",
      "doctor",
      "health",
      "med",
      "medical",
      "medicine",
      "out",
      "poa",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stethoscope_check",
    "version": 248,
    "popularity": 8,
    "codepoint": 63494,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "admission",
      "approve",
      "check",
      "complete",
      "doctor",
      "done",
      "health",
      "mark",
      "med",
      "medical",
      "medicine",
      "ok",
      "select",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sticky_note",
    "version": 248,
    "popularity": 31,
    "codepoint": 59880,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "note",
      "paper",
      "post",
      "sticky",
      "t",
      "write"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sticky_note_2",
    "version": 248,
    "popularity": 2430,
    "codepoint": 61948,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "2",
      "bookmark",
      "mark",
      "message",
      "note",
      "paper",
      "sticky",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sticky_note_2",
    "version": 7,
    "popularity": 23150,
    "codepoint": 61948,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "2",
      "bookmark",
      "mark",
      "message",
      "note",
      "paper",
      "sticky",
      "text",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stock_media",
    "version": 248,
    "popularity": 24,
    "codepoint": 62832,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "audio",
      "audiotrack",
      "clip",
      "clip art",
      "image",
      "key",
      "landscape",
      "mountain",
      "mountains",
      "music",
      "note",
      "photo",
      "photography",
      "picture",
      "sound",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stockpot",
    "version": 248,
    "popularity": 6,
    "codepoint": 62789,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "braiser",
      "cast iron",
      "cook",
      "cooking",
      "cooktop",
      "cookware",
      "dutch oven",
      "food",
      "home",
      "house",
      "induction",
      "kitchen",
      "meals",
      "nest",
      "oven",
      "pan",
      "pot",
      "stockpot",
      "stove",
      "stovetop"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stop",
    "version": 248,
    "popularity": 3375,
    "codepoint": 57415,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "control",
      "controls",
      "music",
      "pause",
      "play",
      "square",
      "stop",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stop",
    "version": 12,
    "popularity": 28470,
    "codepoint": 57415,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "control",
      "controls",
      "music",
      "pause",
      "play",
      "square",
      "stop",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stop_circle",
    "version": 248,
    "popularity": 3373,
    "codepoint": 61297,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "circle",
      "control",
      "controls",
      "music",
      "pause",
      "play",
      "square",
      "stop",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stop_circle",
    "version": 15,
    "popularity": 13865,
    "codepoint": 61297,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "circle",
      "control",
      "controls",
      "music",
      "pause",
      "play",
      "square",
      "stop",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "stop_screen_share",
    "version": 248,
    "popularity": 270,
    "codepoint": 57571,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "cast",
      "chrome",
      "device",
      "disabled",
      "display",
      "enabled",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "mirror",
      "monitor",
      "off",
      "offline",
      "on",
      "screen",
      "share",
      "slash",
      "stop",
      "stream",
      "streaming",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stop_screen_share",
    "version": 13,
    "popularity": 2161,
    "codepoint": 57571,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "cast",
      "chrome",
      "device",
      "disabled",
      "display",
      "enabled",
      "hardware",
      "iOS",
      "laptop",
      "mac",
      "mirror",
      "monitor",
      "off",
      "offline",
      "on",
      "screen",
      "share",
      "slash",
      "stop",
      "stream",
      "streaming",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "storage",
    "version": 248,
    "popularity": 2654,
    "codepoint": 57819,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "computer",
      "data",
      "drive",
      "memory",
      "storage"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "storage",
    "version": 13,
    "popularity": 25039,
    "codepoint": 57819,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "computer",
      "data",
      "drive",
      "memory",
      "storage"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "store",
    "version": 248,
    "popularity": 8555,
    "codepoint": 59601,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "credit",
      "currency",
      "dollars",
      "market",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "store",
    "version": 11,
    "popularity": 65072,
    "codepoint": 59601,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "building",
      "business",
      "card",
      "cash",
      "coin",
      "commerce",
      "company",
      "credit",
      "currency",
      "dollars",
      "market",
      "money",
      "online",
      "pay",
      "payment",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "store_mall_directory",
    "version": 12,
    "popularity": 5298,
    "codepoint": 58723,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "directory",
      "mall",
      "store"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "storefront",
    "version": 248,
    "popularity": 11668,
    "codepoint": 59922,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "business",
      "buy",
      "cafe",
      "commerce",
      "front",
      "market",
      "places",
      "restaurant",
      "retail",
      "sell",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "storefront",
    "version": 17,
    "popularity": 49821,
    "codepoint": 59922,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "business",
      "buy",
      "cafe",
      "commerce",
      "front",
      "market",
      "places",
      "restaurant",
      "retail",
      "sell",
      "shop",
      "shopping",
      "store",
      "storefront"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "storm",
    "version": 248,
    "popularity": 398,
    "codepoint": 61552,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "forecast",
      "hurricane",
      "storm",
      "temperature",
      "twister",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "storm",
    "version": 10,
    "popularity": 3108,
    "codepoint": 61552,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "forecast",
      "hurricane",
      "storm",
      "temperature",
      "twister",
      "weather",
      "wind"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "straight",
    "version": 248,
    "popularity": 0,
    "codepoint": 60309,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "straight",
      "traffic",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "straight",
    "version": 1,
    "popularity": 2856,
    "codepoint": 60309,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "straight",
      "traffic",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "straighten",
    "version": 248,
    "popularity": 2459,
    "codepoint": 58396,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "length",
      "measure",
      "measurement",
      "ruler",
      "size",
      "straighten"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "straighten",
    "version": 12,
    "popularity": 14217,
    "codepoint": 58396,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "length",
      "measure",
      "measurement",
      "ruler",
      "size",
      "straighten"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "strategy",
    "version": 248,
    "popularity": 83,
    "codepoint": 62943,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "flag",
      "games",
      "golf",
      "google play"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stream",
    "version": 248,
    "popularity": 1074,
    "codepoint": 59881,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "cast",
      "connected",
      "feed",
      "live",
      "network",
      "signal",
      "stream",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stream",
    "version": 11,
    "popularity": 9234,
    "codepoint": 59881,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "cast",
      "connected",
      "feed",
      "live",
      "network",
      "signal",
      "stream",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stream_apps",
    "version": 248,
    "popularity": 17,
    "codepoint": 63391,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "app",
      "bubble",
      "cell",
      "chat",
      "comment",
      "communicate",
      "device",
      "feedback",
      "hardware",
      "message",
      "mobile",
      "phone",
      "speech"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "streetview",
    "version": 248,
    "popularity": 386,
    "codepoint": 58734,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "maps",
      "street",
      "streetview",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "streetview",
    "version": 12,
    "popularity": 2061,
    "codepoint": 58734,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "maps",
      "street",
      "streetview",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stress_management",
    "version": 248,
    "popularity": 58,
    "codepoint": 63193,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "emotional",
      "fitbit",
      "mental",
      "mood",
      "physical",
      "psychological",
      "response",
      "stress",
      "wellness"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "strikethrough_s",
    "version": 248,
    "popularity": 503,
    "codepoint": 57943,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "alphabet",
      "character",
      "cross",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "letter",
      "out",
      "s",
      "sheet",
      "spreadsheet",
      "strikethrough",
      "styles",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "strikethrough_s",
    "version": 14,
    "popularity": 3281,
    "codepoint": 57943,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alphabet",
      "character",
      "cross",
      "doc",
      "edit",
      "editing",
      "editor",
      "font",
      "letter",
      "out",
      "s",
      "sheet",
      "spreadsheet",
      "strikethrough",
      "styles",
      "symbol",
      "text",
      "type",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "stroke_full",
    "version": 248,
    "popularity": 11,
    "codepoint": 63305,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "diagonal",
      "lines",
      "pattern",
      "shade",
      "stripes",
      "strokes",
      "texture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stroke_partial",
    "version": 248,
    "popularity": 12,
    "codepoint": 63304,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "diagonal",
      "lines",
      "pattern",
      "shade",
      "stripes",
      "strokes",
      "texture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stroller",
    "version": 248,
    "popularity": 282,
    "codepoint": 61870,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "infant",
      "kid",
      "newborn",
      "stroller",
      "toddler",
      "young"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stroller",
    "version": 8,
    "popularity": 1492,
    "codepoint": 61870,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "baby",
      "care",
      "carriage",
      "child",
      "children",
      "infant",
      "kid",
      "newborn",
      "stroller",
      "toddler",
      "young"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "style",
    "version": 248,
    "popularity": 1593,
    "codepoint": 58397,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "booklet",
      "cards",
      "filters",
      "options",
      "style",
      "tags"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "style",
    "version": 12,
    "popularity": 15442,
    "codepoint": 58397,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "booklet",
      "cards",
      "filters",
      "options",
      "style",
      "tags"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "styler",
    "version": 248,
    "popularity": 1429,
    "codepoint": 57971,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "clothes",
      "fashion",
      "hairdresser",
      "hanger",
      "nest",
      "organization",
      "style",
      "styler"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stylus",
    "version": 248,
    "popularity": 46,
    "codepoint": 62980,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "compose",
      "create",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stylus_laser_pointer",
    "version": 248,
    "popularity": 30,
    "codepoint": 63303,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "beam",
      "glow",
      "point",
      "ray"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "stylus_note",
    "version": 248,
    "popularity": 68,
    "codepoint": 62979,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "compose",
      "create",
      "design",
      "draft",
      "draw",
      "drawing",
      "edit",
      "editing",
      "input",
      "new",
      "pen",
      "pencil",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subdirectory_arrow_left",
    "version": 248,
    "popularity": 913,
    "codepoint": 58841,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "directory",
      "down",
      "left",
      "navigation",
      "sub",
      "subdirectory"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subdirectory_arrow_left",
    "version": 11,
    "popularity": 5332,
    "codepoint": 58841,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directory",
      "down",
      "left",
      "navigation",
      "sub",
      "subdirectory"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "subdirectory_arrow_right",
    "version": 248,
    "popularity": 2256,
    "codepoint": 58842,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "directory",
      "down",
      "navigation",
      "right",
      "sub",
      "subdirectory"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subdirectory_arrow_right",
    "version": 11,
    "popularity": 11170,
    "codepoint": 58842,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directory",
      "down",
      "navigation",
      "right",
      "sub",
      "subdirectory"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "subheader",
    "version": 248,
    "popularity": 15,
    "codepoint": 59882,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "subheader",
      "tablet",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subject",
    "version": 248,
    "popularity": 1637,
    "codepoint": 59602,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "doc",
      "document",
      "email",
      "full",
      "justify",
      "list",
      "note",
      "subject",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subject",
    "version": 13,
    "popularity": 19284,
    "codepoint": 59602,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alignment",
      "doc",
      "document",
      "email",
      "full",
      "justify",
      "list",
      "note",
      "subject",
      "text",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "subscript",
    "version": 248,
    "popularity": 221,
    "codepoint": 61713,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "2",
      "doc",
      "edit",
      "editing",
      "editor",
      "gmail",
      "novitas",
      "sheet",
      "spreadsheet",
      "style",
      "subscript",
      "symbol",
      "text",
      "writing",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subscript",
    "version": 13,
    "popularity": 1622,
    "codepoint": 61713,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "2",
      "doc",
      "edit",
      "editing",
      "editor",
      "gmail",
      "novitas",
      "sheet",
      "spreadsheet",
      "style",
      "subscript",
      "symbol",
      "text",
      "writing",
      "x"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "subscriptions",
    "version": 248,
    "popularity": 3235,
    "codepoint": 57444,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "enroll",
      "list",
      "media",
      "order",
      "play",
      "signup",
      "subscribe",
      "subscriptions"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subscriptions",
    "version": 11,
    "popularity": 13948,
    "codepoint": 57444,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "enroll",
      "list",
      "media",
      "order",
      "play",
      "signup",
      "subscribe",
      "subscriptions"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "subtitles",
    "version": 248,
    "popularity": 1493,
    "codepoint": 57416,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "accessible",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "language",
      "live caption",
      "media",
      "movies",
      "subtitle",
      "subtitles",
      "tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subtitles",
    "version": 12,
    "popularity": 7904,
    "codepoint": 57416,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "accessible",
      "caption",
      "cc",
      "character",
      "closed",
      "decoder",
      "language",
      "live caption",
      "media",
      "movies",
      "subtitle",
      "subtitles",
      "tv"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "subtitles_off",
    "version": 248,
    "popularity": 326,
    "codepoint": 61298,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "caption",
      "cc",
      "closed",
      "disabled",
      "enabled",
      "language",
      "live caption",
      "off",
      "on",
      "slash",
      "subtitle",
      "subtitles",
      "translate",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subtitles_off",
    "version": 11,
    "popularity": 3248,
    "codepoint": 61298,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "caption",
      "cc",
      "closed",
      "disabled",
      "enabled",
      "language",
      "live caption",
      "off",
      "on",
      "slash",
      "subtitle",
      "subtitles",
      "translate",
      "video"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "subway",
    "version": 248,
    "popularity": 677,
    "codepoint": 58735,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "maps",
      "rail",
      "scooter",
      "subway",
      "train",
      "transportation",
      "travel",
      "tunnel",
      "underground",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "subway",
    "version": 11,
    "popularity": 0,
    "codepoint": 58735,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "maps",
      "rail",
      "scooter",
      "subway",
      "train",
      "transportation",
      "travel",
      "tunnel",
      "underground",
      "vehicle",
      "vespa"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "summarize",
    "version": 248,
    "popularity": 5751,
    "codepoint": 61553,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "doc",
      "document",
      "form",
      "list",
      "menu",
      "note",
      "report",
      "summary"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "summarize",
    "version": 10,
    "popularity": 39597,
    "codepoint": 61553,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "doc",
      "document",
      "form",
      "list",
      "menu",
      "note",
      "report",
      "summary"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sunny",
    "version": 248,
    "popularity": 5933,
    "codepoint": 59418,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "hot",
      "summer",
      "sun",
      "sunny",
      "temperature",
      "warm",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sunny",
    "version": 2,
    "popularity": 0,
    "codepoint": 59418,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "climate",
      "hot",
      "summer",
      "sun",
      "sunny",
      "temperature",
      "warm",
      "weather"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sunny_snowing",
    "version": 248,
    "popularity": 801,
    "codepoint": 59417,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cold",
      "snow",
      "sun",
      "sunny",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sunny_snowing",
    "version": 3,
    "popularity": 0,
    "codepoint": 59417,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "climate",
      "cold",
      "snow",
      "sun",
      "sunny",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "superscript",
    "version": 248,
    "popularity": 269,
    "codepoint": 61714,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "2",
      "doc",
      "edit",
      "editing",
      "editor",
      "gmail",
      "novitas",
      "sheet",
      "spreadsheet",
      "style",
      "superscript",
      "symbol",
      "text",
      "writing",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "superscript",
    "version": 12,
    "popularity": 1948,
    "codepoint": 61714,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "2",
      "doc",
      "edit",
      "editing",
      "editor",
      "gmail",
      "novitas",
      "sheet",
      "spreadsheet",
      "style",
      "superscript",
      "symbol",
      "text",
      "writing",
      "x"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "supervised_user_circle",
    "version": 248,
    "popularity": 3039,
    "codepoint": 59705,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "avatar",
      "circle",
      "control",
      "face",
      "human",
      "parental",
      "parents",
      "people",
      "person",
      "profile",
      "supervised",
      "supervisor",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "supervised_user_circle",
    "version": 12,
    "popularity": 28376,
    "codepoint": 59705,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "avatar",
      "circle",
      "control",
      "face",
      "human",
      "parental",
      "parents",
      "people",
      "person",
      "profile",
      "supervised",
      "supervisor",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "supervised_user_circle_off",
    "version": 248,
    "popularity": 7,
    "codepoint": 62990,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "avatar",
      "circle",
      "control",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "offline",
      "on",
      "parental",
      "parents",
      "people",
      "person",
      "profile",
      "slash",
      "supervised",
      "supervisor",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "supervisor_account",
    "version": 248,
    "popularity": 7050,
    "codepoint": 59603,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "avatar",
      "control",
      "face",
      "human",
      "parental",
      "parental control",
      "parents",
      "people",
      "person",
      "profile",
      "supervised",
      "supervisor",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "supervisor_account",
    "version": 13,
    "popularity": 0,
    "codepoint": 59603,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "avatar",
      "control",
      "face",
      "human",
      "parental",
      "parental control",
      "parents",
      "people",
      "person",
      "profile",
      "supervised",
      "supervisor",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "support",
    "version": 248,
    "popularity": 3498,
    "codepoint": 61299,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "assist",
      "buoy",
      "help",
      "life",
      "lifebuoy",
      "rescue",
      "safe",
      "safety",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "support",
    "version": 11,
    "popularity": 22393,
    "codepoint": 61299,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "assist",
      "buoy",
      "help",
      "life",
      "lifebuoy",
      "rescue",
      "safe",
      "safety",
      "support"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "support_agent",
    "version": 248,
    "popularity": 12781,
    "codepoint": 61666,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "agent",
      "care",
      "customer",
      "face",
      "headphone",
      "person",
      "representative",
      "service",
      "support"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "support_agent",
    "version": 12,
    "popularity": 88581,
    "codepoint": 61666,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "agent",
      "care",
      "customer",
      "face",
      "headphone",
      "person",
      "representative",
      "service",
      "support"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "surfing",
    "version": 248,
    "popularity": 940,
    "codepoint": 58645,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "sea",
      "social sports",
      "sports",
      "summer",
      "surfing",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "surfing",
    "version": 4,
    "popularity": 0,
    "codepoint": 58645,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "athlete",
      "athletic",
      "beach",
      "body",
      "entertainment",
      "exercise",
      "hobby",
      "human",
      "people",
      "person",
      "sea",
      "social sports",
      "sports",
      "summer",
      "surfing",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "surgical",
    "version": 248,
    "popularity": 3,
    "codepoint": 57649,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "health",
      "knife",
      "medical",
      "procedure",
      "surgery"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "surround_sound",
    "version": 248,
    "popularity": 204,
    "codepoint": 57417,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "circle",
      "signal",
      "sound",
      "speaker",
      "surround",
      "system",
      "volumn",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "surround_sound",
    "version": 13,
    "popularity": 1527,
    "codepoint": 57417,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "circle",
      "signal",
      "sound",
      "speaker",
      "surround",
      "system",
      "volumn",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "swap_calls",
    "version": 248,
    "popularity": 602,
    "codepoint": 57559,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "arrow",
      "arrows",
      "calls",
      "device",
      "direction",
      "mobile",
      "share",
      "swap"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swap_calls",
    "version": 12,
    "popularity": 3395,
    "codepoint": 57559,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "arrow",
      "arrows",
      "calls",
      "device",
      "direction",
      "mobile",
      "share",
      "swap"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "swap_driving_apps",
    "version": 248,
    "popularity": 37,
    "codepoint": 59038,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "applications",
      "apps",
      "automobile",
      "car",
      "cars",
      "change",
      "direction",
      "directions",
      "driving",
      "maps",
      "swap",
      "switch",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swap_driving_apps_wheel",
    "version": 248,
    "popularity": 70,
    "codepoint": 59039,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "apps",
      "automobile",
      "car",
      "cars",
      "direction",
      "driving",
      "maps",
      "speedometer",
      "swap",
      "vehicle",
      "wheel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swap_horiz",
    "version": 248,
    "popularity": 4816,
    "codepoint": 59604,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "back",
      "forward",
      "horizontal",
      "swap"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swap_horiz",
    "version": 12,
    "popularity": 32414,
    "codepoint": 59604,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "back",
      "forward",
      "horizontal",
      "swap"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "swap_horizontal_circle",
    "version": 248,
    "popularity": 1480,
    "codepoint": 59699,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "back",
      "circle",
      "forward",
      "horizontal",
      "swap"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swap_horizontal_circle",
    "version": 12,
    "popularity": 0,
    "codepoint": 59699,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "back",
      "circle",
      "forward",
      "horizontal",
      "swap"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "swap_vert",
    "version": 248,
    "popularity": 4639,
    "codepoint": 59605,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "navigation",
      "sort",
      "sorting",
      "swap",
      "up",
      "vert",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swap_vert",
    "version": 12,
    "popularity": 21954,
    "codepoint": 59605,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "down",
      "navigation",
      "sort",
      "sorting",
      "swap",
      "up",
      "vert",
      "vertical"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "swap_vertical_circle",
    "version": 248,
    "popularity": 1089,
    "codepoint": 59606,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "circle",
      "down",
      "swap",
      "up",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swap_vertical_circle",
    "version": 13,
    "popularity": 5004,
    "codepoint": 59606,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "circle",
      "down",
      "swap",
      "up",
      "vertical"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sweep",
    "version": 248,
    "popularity": 45,
    "codepoint": 59052,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "approve",
      "check",
      "select",
      "sweep",
      "tick",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe",
    "version": 248,
    "popularity": 1598,
    "codepoint": 59884,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "swipe",
      "touch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe",
    "version": 12,
    "popularity": 13846,
    "codepoint": 59884,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "swipe",
      "touch"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_down",
    "version": 248,
    "popularity": 1106,
    "codepoint": 60243,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "direction",
      "disable",
      "down",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_down",
    "version": 1,
    "popularity": 2121,
    "codepoint": 60243,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrows",
      "direction",
      "disable",
      "down",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_down_alt",
    "version": 248,
    "popularity": 0,
    "codepoint": 60208,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "arrows",
      "direction",
      "disable",
      "down",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_down_alt",
    "version": 1,
    "popularity": 0,
    "codepoint": 60208,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "arrows",
      "direction",
      "disable",
      "down",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_left",
    "version": 248,
    "popularity": 0,
    "codepoint": 60249,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "finger",
      "hand",
      "hit",
      "left",
      "navigation",
      "reject",
      "strike",
      "swing",
      "swipe",
      "take"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_left",
    "version": 1,
    "popularity": 0,
    "codepoint": 60249,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "finger",
      "hand",
      "hit",
      "left",
      "navigation",
      "reject",
      "strike",
      "swing",
      "swipe",
      "take"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_left_alt",
    "version": 248,
    "popularity": 569,
    "codepoint": 60211,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "arrow",
      "arrows",
      "finger",
      "hand",
      "hit",
      "left",
      "navigation",
      "reject",
      "strike",
      "swing",
      "swipe",
      "take"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_left_alt",
    "version": 1,
    "popularity": 1240,
    "codepoint": 60211,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "arrow",
      "arrows",
      "finger",
      "hand",
      "hit",
      "left",
      "navigation",
      "reject",
      "strike",
      "swing",
      "swipe",
      "take"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_right",
    "version": 248,
    "popularity": 1697,
    "codepoint": 60242,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "accept",
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "right",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_right",
    "version": 1,
    "popularity": 2817,
    "codepoint": 60242,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accept",
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "right",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_right_alt",
    "version": 248,
    "popularity": 541,
    "codepoint": 60246,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "accept",
      "alt",
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "right",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_right_alt",
    "version": 1,
    "popularity": 1708,
    "codepoint": 60246,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "accept",
      "alt",
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "right",
      "strike",
      "swing",
      "swpie",
      "take"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_up",
    "version": 248,
    "popularity": 1870,
    "codepoint": 60206,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "direction",
      "disable",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_up",
    "version": 1,
    "popularity": 2747,
    "codepoint": 60206,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrows",
      "direction",
      "disable",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_up_alt",
    "version": 248,
    "popularity": 542,
    "codepoint": 60213,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "arrows",
      "direction",
      "disable",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_up_alt",
    "version": 1,
    "popularity": 1184,
    "codepoint": 60213,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "arrows",
      "direction",
      "disable",
      "enable",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "swipe_vertical",
    "version": 248,
    "popularity": 992,
    "codepoint": 60241,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "verticle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swipe_vertical",
    "version": 1,
    "popularity": 1937,
    "codepoint": 60241,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrows",
      "direction",
      "finger",
      "hands",
      "hit",
      "navigation",
      "strike",
      "swing",
      "swpie",
      "take",
      "verticle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "switch",
    "version": 248,
    "popularity": 387,
    "codepoint": 57844,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "button",
      "key",
      "nest",
      "off",
      "on",
      "rectangle",
      "switch",
      "transition"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_access",
    "version": 248,
    "popularity": 13,
    "codepoint": 63229,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "abstract",
      "select",
      "squares"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_access_2",
    "version": 248,
    "popularity": 9,
    "codepoint": 62726,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "arrow",
      "arrows",
      "chromeos",
      "components",
      "control",
      "design",
      "device",
      "direction",
      "exit",
      "interface",
      "navigation",
      "right",
      "screen",
      "select",
      "site",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_access_shortcut",
    "version": 248,
    "popularity": 2934,
    "codepoint": 59361,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "access",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "new",
      "north",
      "shortcut",
      "switch",
      "symbol",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_access_shortcut",
    "version": 3,
    "popularity": 4912,
    "codepoint": 59361,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "access",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "new",
      "north",
      "shortcut",
      "switch",
      "symbol",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "switch_access_shortcut_add",
    "version": 248,
    "popularity": 1632,
    "codepoint": 59362,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "+",
      "access",
      "add",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "new",
      "north",
      "plus",
      "shortcut",
      "switch",
      "symbol",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_access_shortcut_add",
    "version": 3,
    "popularity": 3590,
    "codepoint": 59362,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "+",
      "access",
      "add",
      "arrow",
      "arrows",
      "direction",
      "navigation",
      "new",
      "north",
      "plus",
      "shortcut",
      "switch",
      "symbol",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "switch_account",
    "version": 248,
    "popularity": 1258,
    "codepoint": 59885,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "account",
      "choices",
      "face",
      "human",
      "multiple",
      "options",
      "people",
      "person",
      "profile",
      "social",
      "switch",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_account",
    "version": 11,
    "popularity": 8721,
    "codepoint": 59885,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "account",
      "choices",
      "face",
      "human",
      "multiple",
      "options",
      "people",
      "person",
      "profile",
      "social",
      "switch",
      "user"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "switch_camera",
    "version": 248,
    "popularity": 291,
    "codepoint": 58398,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "camera",
      "photo",
      "photography",
      "picture",
      "switch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_camera",
    "version": 12,
    "popularity": 1632,
    "codepoint": 58398,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "camera",
      "photo",
      "photography",
      "picture",
      "switch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "switch_left",
    "version": 248,
    "popularity": 1181,
    "codepoint": 61905,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "directional",
      "left",
      "navigation",
      "switch",
      "toggle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_left",
    "version": 8,
    "popularity": 4997,
    "codepoint": 61905,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrows",
      "directional",
      "left",
      "navigation",
      "switch",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "switch_right",
    "version": 248,
    "popularity": 999,
    "codepoint": 61906,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrows",
      "directional",
      "navigation",
      "right",
      "switch",
      "toggle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_right",
    "version": 9,
    "popularity": 3868,
    "codepoint": 61906,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrows",
      "directional",
      "navigation",
      "right",
      "switch",
      "toggle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "switch_video",
    "version": 248,
    "popularity": 239,
    "codepoint": 58399,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "camera",
      "photography",
      "switch",
      "video",
      "videos"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "switch_video",
    "version": 12,
    "popularity": 1617,
    "codepoint": 58399,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "arrow",
      "arrows",
      "camera",
      "photography",
      "switch",
      "video",
      "videos"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "switches",
    "version": 248,
    "popularity": 103,
    "codepoint": 59187,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "activated",
      "button",
      "deactivated",
      "design",
      "disabled",
      "enabled",
      "off",
      "on",
      "switch",
      "switches",
      "toggle",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sword_rose",
    "version": 248,
    "popularity": 17,
    "codepoint": 62942,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "blade",
      "dagger",
      "flower",
      "google play",
      "young adult"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "swords",
    "version": 248,
    "popularity": 717,
    "codepoint": 63625,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "battle",
      "combat",
      "duo",
      "fencing",
      "fight",
      "sword",
      "two"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "symptoms",
    "version": 248,
    "popularity": 5,
    "codepoint": 57650,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "drop",
      "droplet",
      "eye",
      "eyes",
      "health",
      "symptom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "synagogue",
    "version": 248,
    "popularity": 300,
    "codepoint": 60080,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "ideology",
      "jew",
      "jewish",
      "religion",
      "shul",
      "spiritual",
      "temple",
      "worship"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "synagogue",
    "version": 2,
    "popularity": 929,
    "codepoint": 60080,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "ideology",
      "jew",
      "jewish",
      "religion",
      "shul",
      "spiritual",
      "temple",
      "worship"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sync",
    "version": 248,
    "popularity": 11008,
    "codepoint": 58919,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "360",
      "around",
      "arrow",
      "arrows",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "rotate",
      "sync",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sync",
    "version": 17,
    "popularity": 42861,
    "codepoint": 58919,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "360",
      "around",
      "arrow",
      "arrows",
      "direction",
      "inprogress",
      "load",
      "loading refresh",
      "renew",
      "rotate",
      "sync",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sync_alt",
    "version": 248,
    "popularity": 7501,
    "codepoint": 59928,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "arrow",
      "arrows",
      "horizontal",
      "internet",
      "sync",
      "technology",
      "up",
      "update",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sync_alt",
    "version": 12,
    "popularity": 31806,
    "codepoint": 59928,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "arrow",
      "arrows",
      "horizontal",
      "internet",
      "sync",
      "technology",
      "up",
      "update",
      "wifi"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sync_disabled",
    "version": 248,
    "popularity": 984,
    "codepoint": 58920,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "360",
      "around",
      "arrow",
      "arrows",
      "direction",
      "disabled",
      "enabled",
      "inprogress",
      "load",
      "loading refresh",
      "off",
      "on",
      "renew",
      "rotate",
      "slash",
      "sync",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sync_disabled",
    "version": 16,
    "popularity": 3154,
    "codepoint": 58920,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "360",
      "around",
      "arrow",
      "arrows",
      "direction",
      "disabled",
      "enabled",
      "inprogress",
      "load",
      "loading refresh",
      "off",
      "on",
      "renew",
      "rotate",
      "slash",
      "sync",
      "turn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sync_lock",
    "version": 248,
    "popularity": 460,
    "codepoint": 60142,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "renew",
      "rotate",
      "safety",
      "secure",
      "security",
      "sync",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sync_lock",
    "version": 1,
    "popularity": 1744,
    "codepoint": 60142,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "lock",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "renew",
      "rotate",
      "safety",
      "secure",
      "security",
      "sync",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "sync_problem",
    "version": 248,
    "popularity": 2501,
    "codepoint": 58921,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "!",
      "360",
      "alert",
      "around",
      "arrow",
      "arrows",
      "attention",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "inprogress",
      "load",
      "loading refresh",
      "mark",
      "notification",
      "problem",
      "renew",
      "rotate",
      "symbol",
      "sync",
      "turn",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "sync_problem",
    "version": 21,
    "popularity": 8284,
    "codepoint": 58921,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "!",
      "360",
      "alert",
      "around",
      "arrow",
      "arrows",
      "attention",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "inprogress",
      "load",
      "loading refresh",
      "mark",
      "notification",
      "problem",
      "renew",
      "rotate",
      "symbol",
      "sync",
      "turn",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "sync_saved_locally",
    "version": 248,
    "popularity": 86,
    "codepoint": 63520,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "OS",
      "accept",
      "android",
      "approve",
      "checkmark",
      "chrome",
      "complete",
      "computer",
      "device",
      "display",
      "done",
      "hardware",
      "ios",
      "laptop",
      "mac",
      "monitor",
      "ok",
      "screen",
      "select",
      "tick",
      "validate",
      "verified",
      "web",
      "window",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "syringe",
    "version": 248,
    "popularity": 4,
    "codepoint": 57651,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "booster",
      "health",
      "med",
      "medicine",
      "shot",
      "syringe",
      "vaccine",
      "vaccines"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "system_security_update",
    "version": 10,
    "popularity": 1472,
    "codepoint": 61554,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "cell",
      "device",
      "down",
      "hardware",
      "iOS",
      "mobile",
      "phone",
      "security",
      "system",
      "tablet",
      "update"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "system_security_update_good",
    "version": 10,
    "popularity": 3816,
    "codepoint": 61555,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "Android",
      "OS",
      "approve",
      "cell",
      "check",
      "complete",
      "device",
      "done",
      "good",
      "hardware",
      "iOS",
      "mark",
      "mobile",
      "ok",
      "phone",
      "security",
      "select",
      "system",
      "tablet",
      "tick",
      "update",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "system_security_update_warning",
    "version": 10,
    "popularity": 1483,
    "codepoint": 61556,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "Android",
      "OS",
      "alert",
      "attention",
      "caution",
      "cell",
      "danger",
      "device",
      "error",
      "exclamation",
      "hardware",
      "iOS",
      "important",
      "mark",
      "mobile",
      "notification",
      "phone",
      "security",
      "symbol",
      "system",
      "tablet",
      "update",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "system_update",
    "version": 248,
    "popularity": 907,
    "codepoint": 58922,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "arrows",
      "cell",
      "device",
      "direction",
      "down",
      "download",
      "hardware",
      "iOS",
      "install",
      "mobile",
      "phone",
      "system",
      "tablet",
      "update"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "system_update",
    "version": 12,
    "popularity": 4468,
    "codepoint": 58922,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "Android",
      "OS",
      "arrow",
      "arrows",
      "cell",
      "device",
      "direction",
      "down",
      "download",
      "hardware",
      "iOS",
      "install",
      "mobile",
      "phone",
      "system",
      "tablet",
      "update"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "system_update_alt",
    "version": 248,
    "popularity": 1930,
    "codepoint": 59607,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "down",
      "download",
      "export",
      "system",
      "update"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "system_update_alt",
    "version": 13,
    "popularity": 10380,
    "codepoint": 59607,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "down",
      "download",
      "export",
      "system",
      "update"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tab",
    "version": 248,
    "popularity": 949,
    "codepoint": 59608,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "browser",
      "computer",
      "document",
      "documents",
      "folder",
      "internet",
      "tab",
      "tabs",
      "web",
      "website",
      "window",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab",
    "version": 12,
    "popularity": 6957,
    "codepoint": 59608,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "browser",
      "computer",
      "document",
      "documents",
      "folder",
      "internet",
      "tab",
      "tabs",
      "web",
      "website",
      "window",
      "windows"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tab_close",
    "version": 248,
    "popularity": 18,
    "codepoint": 63301,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "exit",
      "file",
      "multiple",
      "no",
      "off",
      "remove",
      "stack",
      "stop",
      "tabs",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_close_right",
    "version": 248,
    "popularity": 4,
    "codepoint": 63302,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "cancel",
      "clear",
      "close",
      "doc",
      "document",
      "duplicate",
      "exit",
      "file",
      "move",
      "off",
      "out",
      "stop",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_duplicate",
    "version": 248,
    "popularity": 27,
    "codepoint": 63300,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "dash",
      "dashed",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "select",
      "selection",
      "tabs"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_group",
    "version": 248,
    "popularity": 17,
    "codepoint": 63299,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "copy",
      "cut",
      "doc",
      "document",
      "duplicate",
      "file",
      "multiple",
      "stack",
      "tabs"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_move",
    "version": 248,
    "popularity": 11,
    "codepoint": 63298,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "exit",
      "move",
      "out",
      "right",
      "tabs",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_new_right",
    "version": 248,
    "popularity": 6,
    "codepoint": 63297,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "add",
      "arrow",
      "box",
      "exit",
      "move",
      "new square",
      "out",
      "plus",
      "right",
      "symbol",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_recent",
    "version": 248,
    "popularity": 3,
    "codepoint": 63296,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "clock",
      "date",
      "schedule",
      "tabs",
      "time"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_unselected",
    "version": 248,
    "popularity": 403,
    "codepoint": 59609,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "browser",
      "computer",
      "dash",
      "dashed",
      "document",
      "documents",
      "folder",
      "internet",
      "tab",
      "tabs",
      "unselected",
      "web",
      "website",
      "window",
      "windows"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tab_unselected",
    "version": 12,
    "popularity": 2458,
    "codepoint": 59609,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "browser",
      "computer",
      "dash",
      "dashed",
      "document",
      "documents",
      "folder",
      "internet",
      "tab",
      "tabs",
      "unselected",
      "web",
      "website",
      "window",
      "windows"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "table",
    "version": 248,
    "popularity": 3561,
    "codepoint": 61841,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "database",
      "grid",
      "layout",
      "squares",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_bar",
    "version": 248,
    "popularity": 495,
    "codepoint": 60114,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bar",
      "cafe",
      "round",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_bar",
    "version": 2,
    "popularity": 2897,
    "codepoint": 60114,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bar",
      "cafe",
      "round",
      "table"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "table_chart",
    "version": 248,
    "popularity": 2700,
    "codepoint": 57957,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic grid",
      "measure",
      "metrics",
      "statistics",
      "table",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_chart",
    "version": 12,
    "popularity": 19888,
    "codepoint": 57957,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic grid",
      "measure",
      "metrics",
      "statistics",
      "table",
      "tracking"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "table_chart_view",
    "version": 248,
    "popularity": 26,
    "codepoint": 63215,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "analytics",
      "bar",
      "bars",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic grid",
      "measure",
      "metrics",
      "statistics",
      "table",
      "tracking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_lamp",
    "version": 248,
    "popularity": 121,
    "codepoint": 57842,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "lamp",
      "light",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_restaurant",
    "version": 248,
    "popularity": 882,
    "codepoint": 60102,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bar",
      "dining",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_restaurant",
    "version": 2,
    "popularity": 3940,
    "codepoint": 60102,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "bar",
      "dining",
      "table"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "table_rows",
    "version": 248,
    "popularity": 1871,
    "codepoint": 61697,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "background",
      "grid",
      "layout",
      "lines",
      "rows",
      "stacked",
      "table"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_rows",
    "version": 17,
    "popularity": 13769,
    "codepoint": 61697,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "background",
      "grid",
      "layout",
      "lines",
      "rows",
      "stacked",
      "table"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "table_rows_narrow",
    "version": 248,
    "popularity": 22,
    "codepoint": 63295,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "background",
      "bars",
      "columns",
      "design",
      "format",
      "grid",
      "layout",
      "row",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_view",
    "version": 248,
    "popularity": 1736,
    "codepoint": 61886,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "format",
      "grid",
      "group",
      "layout",
      "multiple",
      "table",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "table_view",
    "version": 8,
    "popularity": 19397,
    "codepoint": 61886,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "format",
      "grid",
      "group",
      "layout",
      "multiple",
      "table",
      "view"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tablet",
    "version": 248,
    "popularity": 726,
    "codepoint": 58159,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tablet",
    "version": 12,
    "popularity": 2498,
    "codepoint": 58159,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet",
      "web"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tablet_android",
    "version": 248,
    "popularity": 508,
    "codepoint": 58160,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "OS",
      "android",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tablet_android",
    "version": 14,
    "popularity": 3292,
    "codepoint": 58160,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "OS",
      "android",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet",
      "web"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tablet_mac",
    "version": 248,
    "popularity": 926,
    "codepoint": 58161,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet mac",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tablet_mac",
    "version": 12,
    "popularity": 6304,
    "codepoint": 58161,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "device",
      "hardware",
      "iOS",
      "ipad",
      "mobile",
      "tablet mac",
      "web"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tabs",
    "version": 248,
    "popularity": 21,
    "codepoint": 59886,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "tabs",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "websites",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tactic",
    "version": 248,
    "popularity": 94,
    "codepoint": 62820,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "gameplan",
      "games",
      "method",
      "plan",
      "project",
      "right",
      "scheme",
      "strategy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tag",
    "version": 248,
    "popularity": 2759,
    "codepoint": 59887,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "hash",
      "hashtag",
      "key",
      "media",
      "number",
      "pound",
      "social",
      "tag",
      "trend"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tag",
    "version": 10,
    "popularity": 19314,
    "codepoint": 59887,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "hash",
      "hashtag",
      "key",
      "media",
      "number",
      "pound",
      "social",
      "tag",
      "trend"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tag_faces",
    "version": 19,
    "popularity": 5436,
    "codepoint": 58400,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "emoji",
      "emotion",
      "faces",
      "happy",
      "satisfied",
      "smile",
      "tag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "takeout_dining",
    "version": 248,
    "popularity": 1039,
    "codepoint": 60020,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "box",
      "container",
      "delivery",
      "dining",
      "food",
      "meal",
      "restaurant",
      "takeout"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "takeout_dining",
    "version": 16,
    "popularity": 5419,
    "codepoint": 60020,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "box",
      "container",
      "delivery",
      "dining",
      "food",
      "meal",
      "restaurant",
      "takeout"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "tamper_detection_off",
    "version": 248,
    "popularity": 83,
    "codepoint": 59438,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "device",
      "nest",
      "off",
      "smart",
      "tamper detection"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tamper_detection_on",
    "version": 248,
    "popularity": 108,
    "codepoint": 63688,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "camera",
      "detection",
      "home",
      "nest",
      "on",
      "security",
      "tamper"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tap_and_play",
    "version": 248,
    "popularity": 377,
    "codepoint": 58923,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS wifi",
      "cell",
      "connection",
      "device",
      "hardware",
      "iOS",
      "internet",
      "mobile",
      "network",
      "phone",
      "play",
      "signal",
      "tablet",
      "tap",
      "to",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tap_and_play",
    "version": 12,
    "popularity": 2977,
    "codepoint": 58923,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "Android",
      "OS wifi",
      "cell",
      "connection",
      "device",
      "hardware",
      "iOS",
      "internet",
      "mobile",
      "network",
      "phone",
      "play",
      "signal",
      "tablet",
      "tap",
      "to",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tapas",
    "version": 248,
    "popularity": 390,
    "codepoint": 61929,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "appetizer",
      "brunch",
      "dinner",
      "food",
      "lunch",
      "restaurant",
      "snack",
      "tapas"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tapas",
    "version": 6,
    "popularity": 2466,
    "codepoint": 61929,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "appetizer",
      "brunch",
      "dinner",
      "food",
      "lunch",
      "restaurant",
      "snack",
      "tapas"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "target",
    "version": 248,
    "popularity": 637,
    "codepoint": 59161,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "aim",
      "average",
      "bulls",
      "eye",
      "target"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "task",
    "version": 248,
    "popularity": 6671,
    "codepoint": 61557,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "data",
      "doc",
      "document",
      "done",
      "drive",
      "file",
      "folder",
      "folders",
      "mark",
      "ok",
      "page",
      "paper",
      "select",
      "sheet",
      "slide",
      "task",
      "tick",
      "validate",
      "verified",
      "writing",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "task",
    "version": 10,
    "popularity": 42209,
    "codepoint": 61557,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "approve",
      "check",
      "complete",
      "data",
      "doc",
      "document",
      "done",
      "drive",
      "file",
      "folder",
      "folders",
      "mark",
      "ok",
      "page",
      "paper",
      "select",
      "sheet",
      "slide",
      "task",
      "tick",
      "validate",
      "verified",
      "writing",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "task_alt",
    "version": 248,
    "popularity": 14179,
    "codepoint": 58086,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "mark",
      "ok",
      "select",
      "task",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "task_alt",
    "version": 6,
    "popularity": 105594,
    "codepoint": 58086,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "done",
      "mark",
      "ok",
      "select",
      "task",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "taunt",
    "version": 248,
    "popularity": 0,
    "codepoint": 63135,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "gesture",
      "human",
      "person",
      "tease"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "taxi_alert",
    "version": 248,
    "popularity": 385,
    "codepoint": 61300,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "automobile",
      "cab",
      "car",
      "cars",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "lyft",
      "maps",
      "mark",
      "notification",
      "public",
      "symbol",
      "taxi",
      "transportation",
      "uber",
      "vehicle",
      "warning",
      "yellow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "taxi_alert",
    "version": 11,
    "popularity": 3082,
    "codepoint": 61300,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "automobile",
      "cab",
      "car",
      "cars",
      "caution",
      "danger",
      "direction",
      "error",
      "exclamation",
      "important",
      "lyft",
      "maps",
      "mark",
      "notification",
      "public",
      "symbol",
      "taxi",
      "transportation",
      "uber",
      "vehicle",
      "warning",
      "yellow"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "team_dashboard",
    "version": 248,
    "popularity": 1395,
    "codepoint": 57363,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cards",
      "dashboard",
      "shapes",
      "square",
      "team"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "temp_preferences_eco",
    "version": 248,
    "popularity": 675,
    "codepoint": 63690,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "eco",
      "energy",
      "home",
      "leaf",
      "nest",
      "preferences",
      "temperature",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "temple_buddhist",
    "version": 248,
    "popularity": 275,
    "codepoint": 60083,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "buddha",
      "buddhism",
      "buddhist",
      "ideology",
      "monastery",
      "religion",
      "spiritual",
      "temple",
      "worship"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "temple_buddhist",
    "version": 2,
    "popularity": 1133,
    "codepoint": 60083,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "buddha",
      "buddhism",
      "buddhist",
      "ideology",
      "monastery",
      "religion",
      "spiritual",
      "temple",
      "worship"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "temple_hindu",
    "version": 248,
    "popularity": 254,
    "codepoint": 60079,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "hindu",
      "hinduism",
      "hindus",
      "ideology",
      "mandir",
      "religion",
      "spiritual",
      "temple",
      "worship"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "temple_hindu",
    "version": 3,
    "popularity": 960,
    "codepoint": 60079,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "hindu",
      "hinduism",
      "hindus",
      "ideology",
      "mandir",
      "religion",
      "spiritual",
      "temple",
      "worship"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "tenancy",
    "version": 248,
    "popularity": 1476,
    "codepoint": 61667,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "connect",
      "data",
      "tenancy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "terminal",
    "version": 248,
    "popularity": 5136,
    "codepoint": 60302,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "application",
      "code",
      "emulator",
      "program",
      "software",
      "terminal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "terminal",
    "version": 1,
    "popularity": 7471,
    "codepoint": 60302,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "application",
      "code",
      "emulator",
      "program",
      "software",
      "terminal"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "terrain",
    "version": 12,
    "popularity": 5767,
    "codepoint": 58724,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "geography",
      "landscape",
      "mountain",
      "terrain"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_ad",
    "version": 248,
    "popularity": 27,
    "codepoint": 59176,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "ad",
      "doc",
      "document",
      "file",
      "page",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_decrease",
    "version": 248,
    "popularity": 440,
    "codepoint": 60125,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "-",
      "alphabet",
      "character",
      "decrease",
      "font",
      "letter",
      "minus",
      "remove",
      "resize",
      "subtract",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_decrease",
    "version": 1,
    "popularity": 1490,
    "codepoint": 60125,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "-",
      "alphabet",
      "character",
      "decrease",
      "font",
      "letter",
      "minus",
      "remove",
      "resize",
      "subtract",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "text_fields",
    "version": 248,
    "popularity": 2439,
    "codepoint": 57954,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "T",
      "add",
      "alphabet",
      "character",
      "field",
      "fields",
      "font",
      "input",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_fields",
    "version": 14,
    "popularity": 14074,
    "codepoint": 57954,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "T",
      "add",
      "alphabet",
      "character",
      "field",
      "fields",
      "font",
      "input",
      "letter",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_fields_alt",
    "version": 248,
    "popularity": 0,
    "codepoint": 59889,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "alt",
      "character",
      "cursor",
      "field",
      "font",
      "letter",
      "symbol",
      "text",
      "type",
      "typing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_format",
    "version": 248,
    "popularity": 742,
    "codepoint": 57701,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "format",
      "letter",
      "square A",
      "style",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_format",
    "version": 12,
    "popularity": 6673,
    "codepoint": 57701,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "alphabet",
      "character",
      "font",
      "format",
      "letter",
      "square A",
      "style",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_increase",
    "version": 248,
    "popularity": 0,
    "codepoint": 60130,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "alphabet",
      "character",
      "font",
      "increase",
      "letter",
      "new",
      "plus",
      "resize",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_increase",
    "version": 1,
    "popularity": 2477,
    "codepoint": 60130,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "+",
      "add",
      "alphabet",
      "character",
      "font",
      "increase",
      "letter",
      "new",
      "plus",
      "resize",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "text_rotate_up",
    "version": 248,
    "popularity": 107,
    "codepoint": 59706,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_rotate_up",
    "version": 12,
    "popularity": 1619,
    "codepoint": 59706,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_rotate_vertical",
    "version": 248,
    "popularity": 162,
    "codepoint": 59707,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "down",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_rotate_vertical",
    "version": 12,
    "popularity": 2343,
    "codepoint": 59707,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "down",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type",
      "vertical"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_rotation_angledown",
    "version": 248,
    "popularity": 112,
    "codepoint": 59708,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "angledown",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_rotation_angledown",
    "version": 12,
    "popularity": 1544,
    "codepoint": 59708,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "A",
      "alphabet",
      "angledown",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_rotation_angleup",
    "version": 248,
    "popularity": 122,
    "codepoint": 59709,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "angleup",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_rotation_angleup",
    "version": 12,
    "popularity": 1568,
    "codepoint": 59709,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "A",
      "alphabet",
      "angleup",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_rotation_down",
    "version": 248,
    "popularity": 117,
    "codepoint": 59710,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "dow",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_rotation_down",
    "version": 12,
    "popularity": 1570,
    "codepoint": 59710,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "dow",
      "field",
      "font",
      "letter",
      "move",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_rotation_none",
    "version": 248,
    "popularity": 150,
    "codepoint": 59711,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "none",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_rotation_none",
    "version": 12,
    "popularity": 2193,
    "codepoint": 59711,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "A",
      "alphabet",
      "arrow",
      "character",
      "field",
      "font",
      "letter",
      "move",
      "none",
      "rotate",
      "symbol",
      "text",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_select_end",
    "version": 248,
    "popularity": 4,
    "codepoint": 63294,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cursor",
      "dash",
      "dashed",
      "format",
      "selection",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_jump_to_beginning",
    "version": 248,
    "popularity": 8,
    "codepoint": 63293,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "back",
      "dash",
      "dashes",
      "format",
      "left",
      "selection",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_jump_to_end",
    "version": 248,
    "popularity": 4,
    "codepoint": 63292,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "dash",
      "dashes",
      "format",
      "right",
      "selection",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_move_back_character",
    "version": 248,
    "popularity": 1,
    "codepoint": 63291,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "cursor",
      "dash",
      "dashed",
      "dashes",
      "format",
      "left",
      "selection",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_move_back_word",
    "version": 248,
    "popularity": 3,
    "codepoint": 63290,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "back",
      "format",
      "left",
      "selection"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_move_down",
    "version": 248,
    "popularity": 4,
    "codepoint": 63289,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "down",
      "format",
      "selection"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_move_forward_character",
    "version": 248,
    "popularity": 17,
    "codepoint": 63288,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "cursor",
      "dash",
      "dashes",
      "format",
      "right",
      "selection",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_move_forward_word",
    "version": 248,
    "popularity": 4,
    "codepoint": 63287,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "format",
      "move",
      "right",
      "selection"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_move_up",
    "version": 248,
    "popularity": 4,
    "codepoint": 63286,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "format",
      "selection",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_select_start",
    "version": 248,
    "popularity": 14,
    "codepoint": 63285,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cursor",
      "dash",
      "dashed",
      "format",
      "selection",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_snippet",
    "version": 248,
    "popularity": 2254,
    "codepoint": 61894,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "file",
      "note",
      "notes",
      "snippet",
      "storage",
      "text",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "text_snippet",
    "version": 8,
    "popularity": 30642,
    "codepoint": 61894,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "file",
      "note",
      "notes",
      "snippet",
      "storage",
      "text",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "text_to_speech",
    "version": 248,
    "popularity": 935,
    "codepoint": 61884,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "doc data",
      "document",
      "file",
      "listen",
      "speak",
      "speaker",
      "speech",
      "talk",
      "text",
      "tts",
      "volume",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "textsms",
    "version": 12,
    "popularity": 30301,
    "codepoint": 57560,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "bubble",
      "chat",
      "comment",
      "communicate",
      "dots",
      "feedback",
      "message",
      "speech",
      "textsms"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "texture",
    "version": 248,
    "popularity": 660,
    "codepoint": 58401,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "diagonal",
      "lines",
      "pattern",
      "stripes",
      "texture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "texture",
    "version": 12,
    "popularity": 3636,
    "codepoint": 58401,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "diagonal",
      "lines",
      "pattern",
      "stripes",
      "texture"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "texture_add",
    "version": 248,
    "popularity": 7,
    "codepoint": 62844,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "add",
      "diagonal",
      "lines",
      "new",
      "pattern",
      "plus",
      "stripes",
      "texture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "texture_minus",
    "version": 248,
    "popularity": 5,
    "codepoint": 62843,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "-",
      "delete",
      "diagonal",
      "lines",
      "pattern",
      "remove",
      "stripes",
      "subtract",
      "texture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "theater_comedy",
    "version": 248,
    "popularity": 1446,
    "codepoint": 60006,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "broadway",
      "comedy",
      "event",
      "mask",
      "masks",
      "movie",
      "musical",
      "places",
      "show",
      "standup",
      "theater",
      "tour",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "theater_comedy",
    "version": 11,
    "popularity": 6691,
    "codepoint": 60006,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "broadway",
      "comedy",
      "event",
      "mask",
      "masks",
      "movie",
      "musical",
      "places",
      "show",
      "standup",
      "theater",
      "tour",
      "watch"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "theaters",
    "version": 248,
    "popularity": 1360,
    "codepoint": 59610,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "film",
      "movie",
      "movies",
      "show",
      "showtimes",
      "theater",
      "theaters",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "theaters",
    "version": 12,
    "popularity": 11391,
    "codepoint": 59610,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "film",
      "movie",
      "movies",
      "show",
      "showtimes",
      "theater",
      "theaters",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thermometer",
    "version": 248,
    "popularity": 1457,
    "codepoint": 59462,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "celsius",
      "fahrenheit",
      "meter",
      "nest",
      "temp",
      "temperature",
      "thermometer",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thermometer_add",
    "version": 248,
    "popularity": 17,
    "codepoint": 62850,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "+",
      "add",
      "celsius",
      "fahrenheit",
      "meter",
      "nest",
      "new",
      "plus",
      "temp",
      "temperature",
      "thermometer",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thermometer_gain",
    "version": 248,
    "popularity": 41,
    "codepoint": 63192,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "+",
      "add",
      "body",
      "celsius",
      "fahrenheit",
      "fitbit",
      "health",
      "high",
      "meter",
      "nest",
      "new",
      "plus",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
      "warm"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thermometer_loss",
    "version": 248,
    "popularity": 11,
    "codepoint": 63191,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "body",
      "celsius",
      "cold",
      "cool",
      "fahrenheit",
      "fitbit",
      "health",
      "high",
      "low",
      "meter",
      "minus",
      "nest",
      "remove",
      "subtract",
      "temp",
      "temperature",
      "thermometer",
      "thermostat",
      "warm"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thermometer_minus",
    "version": 248,
    "popularity": 5,
    "codepoint": 62849,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "-",
      "celsius",
      "delete",
      "fahrenheit",
      "meter",
      "minus",
      "nest",
      "remove",
      "subtract",
      "temp",
      "temperature",
      "thermometer",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thermostat",
    "version": 248,
    "popularity": 2041,
    "codepoint": 61558,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "climate",
      "forecast",
      "temperature",
      "thermostat",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thermostat",
    "version": 11,
    "popularity": 21902,
    "codepoint": 61558,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "climate",
      "forecast",
      "temperature",
      "thermostat",
      "weather"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thermostat_auto",
    "version": 248,
    "popularity": 246,
    "codepoint": 61559,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "A",
      "auto",
      "celsius",
      "fahrenheit",
      "meter",
      "temp",
      "temperature",
      "thermometer",
      "thermostat"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thermostat_auto",
    "version": 10,
    "popularity": 2714,
    "codepoint": 61559,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "A",
      "auto",
      "celsius",
      "fahrenheit",
      "meter",
      "temp",
      "temperature",
      "thermometer",
      "thermostat"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thermostat_carbon",
    "version": 248,
    "popularity": 172,
    "codepoint": 61816,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "things_to_do",
    "version": 248,
    "popularity": 73,
    "codepoint": 60202,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "architecture",
      "building",
      "capital",
      "do",
      "estate",
      "flag",
      "important",
      "landmark",
      "real",
      "things",
      "to"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thread_unread",
    "version": 248,
    "popularity": 17,
    "codepoint": 62713,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alarm",
      "alert",
      "doodle",
      "dot",
      "draw",
      "drawing",
      "finger",
      "gesture",
      "gestures",
      "hand",
      "motion",
      "notifications",
      "notify",
      "reminder",
      "string",
      "thread",
      "unread"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thumb_down",
    "version": 248,
    "popularity": 4693,
    "codepoint": 59611,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "ate",
      "dislike",
      "down",
      "favorite",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "rank",
      "ranking",
      "rating",
      "thumb"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thumb_down",
    "version": 19,
    "popularity": 24177,
    "codepoint": 59611,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "ate",
      "dislike",
      "down",
      "favorite",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "rank",
      "ranking",
      "rating",
      "thumb"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thumb_down_alt",
    "version": 11,
    "popularity": 10776,
    "codepoint": 59414,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bad",
      "decline",
      "disapprove",
      "dislike",
      "down",
      "feedback",
      "hate",
      "negative",
      "no",
      "reject",
      "social",
      "thumb",
      "veto",
      "vote"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thumb_down_off_alt",
    "version": 13,
    "popularity": 11196,
    "codepoint": 59890,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "disabled",
      "dislike",
      "down",
      "enabled",
      "favorite",
      "filled",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "off",
      "offline",
      "on",
      "rank",
      "ranking",
      "rate",
      "rating",
      "slash",
      "thumb"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thumb_up",
    "version": 248,
    "popularity": 20490,
    "codepoint": 59612,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "favorite",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "rank",
      "ranking",
      "rate",
      "rating",
      "thumb",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thumb_up",
    "version": 19,
    "popularity": 137019,
    "codepoint": 59612,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "favorite",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "rank",
      "ranking",
      "rate",
      "rating",
      "thumb",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thumb_up_alt",
    "version": 11,
    "popularity": 33628,
    "codepoint": 59415,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "agreed",
      "approved",
      "confirm",
      "correct",
      "favorite",
      "feedback",
      "good",
      "happy",
      "like",
      "okay",
      "positive",
      "satisfaction",
      "social",
      "thumb",
      "up",
      "vote",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thumb_up_off_alt",
    "version": 13,
    "popularity": 39199,
    "codepoint": 59891,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "disabled",
      "enabled",
      "favorite",
      "fingers",
      "gesture",
      "hand",
      "hands",
      "like",
      "off",
      "offline",
      "on",
      "rank",
      "ranking",
      "rate",
      "rating",
      "slash",
      "thumb",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thumbnail_bar",
    "version": 248,
    "popularity": 16,
    "codepoint": 63284,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "layout",
      "sidebar",
      "ui",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thumbs_up_down",
    "version": 248,
    "popularity": 1875,
    "codepoint": 59613,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "dislike",
      "down",
      "favorite",
      "fingers",
      "gesture",
      "hands",
      "like",
      "rate",
      "rating",
      "thumbs",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thumbs_up_down",
    "version": 12,
    "popularity": 11511,
    "codepoint": 59613,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "dislike",
      "down",
      "favorite",
      "fingers",
      "gesture",
      "hands",
      "like",
      "rate",
      "rating",
      "thumbs",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "thunderstorm",
    "version": 248,
    "popularity": 1902,
    "codepoint": 60379,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bolt",
      "climate",
      "cloud",
      "cloudy",
      "lightning",
      "rain",
      "rainfall",
      "rainstorm",
      "storm",
      "thunder",
      "thunderstorm",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "thunderstorm",
    "version": 1,
    "popularity": 2649,
    "codepoint": 60379,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bolt",
      "climate",
      "cloud",
      "cloudy",
      "lightning",
      "rain",
      "rainfall",
      "rainstorm",
      "storm",
      "thunder",
      "thunderstorm",
      "weather"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "tibia",
    "version": 248,
    "popularity": 210,
    "codepoint": 63643,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "ankle",
      "body",
      "bone",
      "bones",
      "health",
      "knee",
      "leg",
      "medical",
      "skeleton",
      "tibia"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tibia_alt",
    "version": 248,
    "popularity": 169,
    "codepoint": 63644,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "ankle",
      "body",
      "bone",
      "bones",
      "health",
      "knee",
      "leg",
      "medical",
      "skeleton",
      "tibia"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "time_auto",
    "version": 248,
    "popularity": 458,
    "codepoint": 61668,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "alarm",
      "auto timer",
      "automatic",
      "duration",
      "stop",
      "time",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "time_to_leave",
    "version": 12,
    "popularity": 7902,
    "codepoint": 58924,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "destination",
      "direction",
      "drive",
      "estimate",
      "eta",
      "maps",
      "public",
      "transportation",
      "travel",
      "trip",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "timelapse",
    "version": 248,
    "popularity": 2096,
    "codepoint": 58402,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "duration",
      "motion",
      "photo",
      "time",
      "timelapse",
      "timer",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timelapse",
    "version": 12,
    "popularity": 9954,
    "codepoint": 58402,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "duration",
      "motion",
      "photo",
      "time",
      "timelapse",
      "timer",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "timeline",
    "version": 248,
    "popularity": 3510,
    "codepoint": 59682,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "data",
      "history",
      "line",
      "movement",
      "point",
      "points",
      "timeline",
      "tracking",
      "trending",
      "zigzag"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timeline",
    "version": 13,
    "popularity": 32479,
    "codepoint": 59682,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "data",
      "history",
      "line",
      "movement",
      "point",
      "points",
      "timeline",
      "tracking",
      "trending",
      "zigzag"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "timer",
    "version": 248,
    "popularity": 11268,
    "codepoint": 58405,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "stop",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer",
    "version": 13,
    "popularity": 46172,
    "codepoint": 58405,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "stop",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "timer_10",
    "version": 248,
    "popularity": 206,
    "codepoint": 58403,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "10",
      "digits",
      "duration",
      "number",
      "numbers",
      "seconds",
      "time",
      "timer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer_10",
    "version": 12,
    "popularity": 1176,
    "codepoint": 58403,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "10",
      "digits",
      "duration",
      "number",
      "numbers",
      "seconds",
      "time",
      "timer"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "timer_10_alt_1",
    "version": 248,
    "popularity": 443,
    "codepoint": 61375,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer_10_select",
    "version": 248,
    "popularity": 199,
    "codepoint": 61562,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "10",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "seconds",
      "select",
      "symbol",
      "text",
      "timer",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer_10_select",
    "version": 15,
    "popularity": 1018,
    "codepoint": 61562,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "10",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "seconds",
      "select",
      "symbol",
      "text",
      "timer",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "timer_3",
    "version": 248,
    "popularity": 167,
    "codepoint": 58404,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "3",
      "digits",
      "duration",
      "number",
      "numbers",
      "seconds",
      "time",
      "timer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer_3",
    "version": 12,
    "popularity": 998,
    "codepoint": 58404,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "3",
      "digits",
      "duration",
      "number",
      "numbers",
      "seconds",
      "time",
      "timer"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "timer_3_alt_1",
    "version": 248,
    "popularity": 411,
    "codepoint": 61376,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer_3_select",
    "version": 248,
    "popularity": 136,
    "codepoint": 61563,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "seconds",
      "select",
      "symbol",
      "text",
      "timer",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer_3_select",
    "version": 15,
    "popularity": 836,
    "codepoint": 61563,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "alphabet",
      "camera",
      "character",
      "digit",
      "font",
      "letter",
      "number",
      "seconds",
      "select",
      "symbol",
      "text",
      "timer",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "timer_off",
    "version": 248,
    "popularity": 642,
    "codepoint": 58406,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "stop",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "timer_off",
    "version": 13,
    "popularity": 3559,
    "codepoint": 58406,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "alarm",
      "alert",
      "bell",
      "clock",
      "disabled",
      "duration",
      "enabled",
      "notification",
      "off",
      "on",
      "slash",
      "stop",
      "time",
      "timer",
      "watch"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "tips_and_updates",
    "version": 3,
    "popularity": 31926,
    "codepoint": 59290,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "ai",
      "alert",
      "and",
      "announcement",
      "artificial",
      "automatic",
      "automation",
      "custom",
      "electricity",
      "genai",
      "idea",
      "info",
      "information",
      "intelligence",
      "light",
      "lightbulb",
      "magic",
      "smart",
      "spark",
      "sparkle",
      "star",
      "tips",
      "updates"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "tire_repair",
    "version": 248,
    "popularity": 482,
    "codepoint": 60360,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "auto",
      "automobile",
      "car",
      "cars",
      "gauge",
      "mechanic",
      "pressure",
      "repair",
      "tire",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tire_repair",
    "version": 1,
    "popularity": 1233,
    "codepoint": 60360,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "auto",
      "automobile",
      "car",
      "cars",
      "gauge",
      "mechanic",
      "pressure",
      "repair",
      "tire",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "title",
    "version": 248,
    "popularity": 1811,
    "codepoint": 57956,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "T",
      "alphabet",
      "character",
      "font",
      "header",
      "letter",
      "subject",
      "symbol",
      "text",
      "title",
      "type"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "title",
    "version": 11,
    "popularity": 13663,
    "codepoint": 57956,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "T",
      "alphabet",
      "character",
      "font",
      "header",
      "letter",
      "subject",
      "symbol",
      "text",
      "title",
      "type"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "toast",
    "version": 248,
    "popularity": 15,
    "codepoint": 61377,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "bottom",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "toast",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "toc",
    "version": 248,
    "popularity": 1202,
    "codepoint": 59614,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "content",
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked",
      "table",
      "title",
      "titles",
      "toc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "toc",
    "version": 13,
    "popularity": 18080,
    "codepoint": 59614,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "content",
      "format",
      "lines",
      "list",
      "order",
      "reorder",
      "stacked",
      "table",
      "title",
      "titles",
      "toc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "today",
    "version": 248,
    "popularity": 6059,
    "codepoint": 59615,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "mark",
      "month",
      "remember",
      "reminder",
      "schedule",
      "time",
      "today"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "today",
    "version": 17,
    "popularity": 62455,
    "codepoint": 59615,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "calendar",
      "date",
      "day",
      "event",
      "mark",
      "month",
      "remember",
      "reminder",
      "schedule",
      "time",
      "today"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "toggle_off",
    "version": 248,
    "popularity": 14297,
    "codepoint": 59893,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "active",
      "app",
      "application",
      "components",
      "configuration",
      "control",
      "design",
      "disable",
      "inable",
      "inactive",
      "interface",
      "off",
      "on",
      "selection",
      "settings",
      "site",
      "slider",
      "switch",
      "toggle",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "toggle_off",
    "version": 12,
    "popularity": 29750,
    "codepoint": 59893,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "active",
      "app",
      "application",
      "components",
      "configuration",
      "control",
      "design",
      "disable",
      "inable",
      "inactive",
      "interface",
      "off",
      "on",
      "selection",
      "settings",
      "site",
      "slider",
      "switch",
      "toggle",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "toggle_on",
    "version": 248,
    "popularity": 23336,
    "codepoint": 59894,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "active",
      "app",
      "application",
      "components",
      "configuration",
      "control",
      "design",
      "disable",
      "inable",
      "inactive",
      "interface",
      "off",
      "on",
      "selection",
      "settings",
      "site",
      "slider",
      "switch",
      "toggle",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "toggle_on",
    "version": 12,
    "popularity": 48909,
    "codepoint": 59894,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "toggle"
    ],
    "tags": [
      "active",
      "app",
      "application",
      "components",
      "configuration",
      "control",
      "design",
      "disable",
      "inable",
      "inactive",
      "interface",
      "off",
      "on",
      "selection",
      "settings",
      "site",
      "slider",
      "switch",
      "toggle",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "token",
    "version": 248,
    "popularity": 2991,
    "codepoint": 59941,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "badge",
      "hexagon",
      "mark",
      "shield",
      "sign",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "token",
    "version": 2,
    "popularity": 6799,
    "codepoint": 59941,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "badge",
      "hexagon",
      "mark",
      "shield",
      "sign",
      "symbol"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "toll",
    "version": 248,
    "popularity": 1122,
    "codepoint": 59616,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bill",
      "booth",
      "car",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "highway",
      "money",
      "online",
      "pay",
      "payment",
      "ticket",
      "toll"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "toll",
    "version": 12,
    "popularity": 7879,
    "codepoint": 59616,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bill",
      "booth",
      "car",
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "highway",
      "money",
      "online",
      "pay",
      "payment",
      "ticket",
      "toll"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tonality",
    "version": 248,
    "popularity": 301,
    "codepoint": 58407,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "circle",
      "edit",
      "editing",
      "filter",
      "image",
      "photography",
      "picture",
      "tonality"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tonality",
    "version": 12,
    "popularity": 2239,
    "codepoint": 58407,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "circle",
      "edit",
      "editing",
      "filter",
      "image",
      "photography",
      "picture",
      "tonality"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "toolbar",
    "version": 248,
    "popularity": 51,
    "codepoint": 59895,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "app",
      "application",
      "components",
      "design",
      "interface",
      "layout",
      "mobile",
      "monitor",
      "phone",
      "screen",
      "site",
      "tablet",
      "toolbar",
      "top",
      "ui",
      "ux",
      "web",
      "website",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tools_flat_head",
    "version": 248,
    "popularity": 121,
    "codepoint": 63691,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "flat",
      "head",
      "home",
      "nest",
      "screwdriver",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tools_installation_kit",
    "version": 248,
    "popularity": 138,
    "codepoint": 58027,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "installation",
      "kit",
      "nest",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tools_ladder",
    "version": 248,
    "popularity": 237,
    "codepoint": 58059,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "ladder",
      "nest",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tools_level",
    "version": 248,
    "popularity": 162,
    "codepoint": 59259,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "level",
      "nest",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tools_phillips",
    "version": 248,
    "popularity": 132,
    "codepoint": 63692,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "nest",
      "phillips",
      "screwdriver",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tools_pliers_wire_stripper",
    "version": 248,
    "popularity": 270,
    "codepoint": 58026,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "nest",
      "pliers",
      "stripper",
      "tools",
      "wire"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tools_power_drill",
    "version": 248,
    "popularity": 614,
    "codepoint": 57833,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "drill",
      "home",
      "nest",
      "power",
      "tools"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tooltip",
    "version": 248,
    "popularity": 44,
    "codepoint": 59896,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubbletool",
      "chat",
      "comment",
      "information",
      "say",
      "speech",
      "talk",
      "tip",
      "tooltip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "top_panel_close",
    "version": 248,
    "popularity": 10,
    "codepoint": 63283,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "collapse",
      "direction",
      "layout",
      "panels",
      "spaces",
      "up",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "top_panel_open",
    "version": 248,
    "popularity": 5,
    "codepoint": 63282,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "direction",
      "down",
      "expand",
      "layout",
      "panels",
      "spaces",
      "window",
      "workflow"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "topic",
    "version": 248,
    "popularity": 1697,
    "codepoint": 61896,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "storage",
      "topic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "topic",
    "version": 7,
    "popularity": 9929,
    "codepoint": 61896,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "sheet",
      "slide",
      "storage",
      "topic"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tornado",
    "version": 248,
    "popularity": 565,
    "codepoint": 57753,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "tornado",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tornado",
    "version": 1,
    "popularity": 857,
    "codepoint": 57753,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "natural",
      "rain",
      "storm",
      "tornado",
      "weather",
      "wind"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "total_dissolved_solids",
    "version": 248,
    "popularity": 2,
    "codepoint": 63607,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "dissolved",
      "liquid",
      "organic",
      "substances",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "touch_app",
    "version": 248,
    "popularity": 6005,
    "codepoint": 59667,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "app",
      "command",
      "fingers",
      "gesture",
      "hand",
      "long press",
      "press",
      "tap",
      "touch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "touch_app",
    "version": 13,
    "popularity": 41286,
    "codepoint": 59667,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "app",
      "command",
      "fingers",
      "gesture",
      "hand",
      "long press",
      "press",
      "tap",
      "touch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "touchpad_mouse",
    "version": 248,
    "popularity": 46,
    "codepoint": 63111,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "click",
      "computer",
      "cursor",
      "device",
      "hardware",
      "mouse",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "touchpad_mouse_off",
    "version": 248,
    "popularity": 11,
    "codepoint": 62694,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "click",
      "computer",
      "cursor",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "mouse",
      "off",
      "offline",
      "on",
      "slash",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tour",
    "version": 248,
    "popularity": 1206,
    "codepoint": 61301,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "flag",
      "places",
      "tour",
      "travel",
      "visit"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tour",
    "version": 12,
    "popularity": 9690,
    "codepoint": 61301,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "destination",
      "flag",
      "places",
      "tour",
      "travel",
      "visit"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "toys",
    "version": 248,
    "popularity": 822,
    "codepoint": 58162,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "car",
      "games",
      "kids",
      "toy",
      "toys",
      "windmill"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "toys",
    "version": 14,
    "popularity": 4288,
    "codepoint": 58162,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "car",
      "games",
      "kids",
      "toy",
      "toys",
      "windmill"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "toys_and_games",
    "version": 248,
    "popularity": 106,
    "codepoint": 61378,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "and",
      "games",
      "piece",
      "puzzle",
      "puzzles",
      "toy",
      "toys"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "toys_fan",
    "version": 248,
    "popularity": 447,
    "codepoint": 63623,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "fan",
      "games",
      "kids",
      "pinwheel",
      "toy",
      "toys"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "track_changes",
    "version": 248,
    "popularity": 2278,
    "codepoint": 59617,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bullseye",
      "changes",
      "circle",
      "evolve",
      "lines",
      "movement",
      "rotate",
      "shift",
      "target",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "track_changes",
    "version": 11,
    "popularity": 18007,
    "codepoint": 59617,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bullseye",
      "changes",
      "circle",
      "evolve",
      "lines",
      "movement",
      "rotate",
      "shift",
      "target",
      "track"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "traffic",
    "version": 248,
    "popularity": 1186,
    "codepoint": 58725,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "direction",
      "light",
      "maps",
      "signal",
      "street",
      "traffic"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "traffic",
    "version": 12,
    "popularity": 9862,
    "codepoint": 58725,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "direction",
      "light",
      "maps",
      "signal",
      "street",
      "traffic"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "trail_length",
    "version": 248,
    "popularity": 177,
    "codepoint": 60254,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motion",
      "motionblur",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trail_length_medium",
    "version": 248,
    "popularity": 149,
    "codepoint": 60259,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "camera",
      "feature",
      "image",
      "medium",
      "mode",
      "motionblur",
      "photo",
      "photography",
      "picture"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trail_length_short",
    "version": 248,
    "popularity": 148,
    "codepoint": 60269,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "blur",
      "camera",
      "feature",
      "image",
      "mode",
      "motionblur",
      "photo",
      "photography",
      "picture",
      "short"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "train",
    "version": 248,
    "popularity": 2955,
    "codepoint": 58736,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "train",
    "version": 11,
    "popularity": 11690,
    "codepoint": 58736,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tram",
    "version": 248,
    "popularity": 711,
    "codepoint": 58737,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "tram",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tram",
    "version": 11,
    "popularity": 3121,
    "codepoint": 58737,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "public",
      "rail",
      "subway",
      "train",
      "tram",
      "transportation",
      "vehicle"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "transcribe",
    "version": 248,
    "popularity": 885,
    "codepoint": 63724,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transcribe",
    "version": 1,
    "popularity": 764,
    "codepoint": 63724,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "transfer_within_a_station",
    "version": 248,
    "popularity": 857,
    "codepoint": 58738,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "a",
      "arrow",
      "arrows",
      "body",
      "direction",
      "human",
      "left",
      "maps",
      "people",
      "person",
      "public",
      "right",
      "route",
      "station",
      "stop",
      "transfer",
      "transportation",
      "vehicle",
      "walk",
      "within"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transfer_within_a_station",
    "version": 12,
    "popularity": 6160,
    "codepoint": 58738,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "a",
      "arrow",
      "arrows",
      "body",
      "direction",
      "human",
      "left",
      "maps",
      "people",
      "person",
      "public",
      "right",
      "route",
      "station",
      "stop",
      "transfer",
      "transportation",
      "vehicle",
      "walk",
      "within"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "transform",
    "version": 248,
    "popularity": 571,
    "codepoint": 58408,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "crop",
      "edit",
      "editing",
      "image",
      "photo",
      "picture",
      "transform"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transform",
    "version": 12,
    "popularity": 3418,
    "codepoint": 58408,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "crop",
      "edit",
      "editing",
      "image",
      "photo",
      "picture",
      "transform"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "transgender",
    "version": 248,
    "popularity": 950,
    "codepoint": 58765,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "female",
      "gender",
      "lgbt",
      "male",
      "neutral",
      "social",
      "symbol",
      "transgender"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transgender",
    "version": 3,
    "popularity": 5639,
    "codepoint": 58765,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "female",
      "gender",
      "lgbt",
      "male",
      "neutral",
      "social",
      "symbol",
      "transgender"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "transit_enterexit",
    "version": 248,
    "popularity": 495,
    "codepoint": 58745,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "direction",
      "enterexit",
      "maps",
      "navigation",
      "route",
      "transit",
      "transportation"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transit_enterexit",
    "version": 12,
    "popularity": 2817,
    "codepoint": 58745,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "direction",
      "enterexit",
      "maps",
      "navigation",
      "route",
      "transit",
      "transportation"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "transition_chop",
    "version": 248,
    "popularity": 0,
    "codepoint": 62734,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "director's cut",
      "film cut",
      "flix",
      "presentation",
      "scenes",
      "slides",
      "timeline"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transition_dissolve",
    "version": 248,
    "popularity": 6,
    "codepoint": 62733,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "circles",
      "collection",
      "components",
      "dots",
      "flix",
      "grid",
      "interface",
      "presentation",
      "scenes",
      "slides",
      "timeline",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transition_fade",
    "version": 248,
    "popularity": 5,
    "codepoint": 62732,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "circles",
      "collection",
      "components",
      "dots",
      "fade to black",
      "flix",
      "grid",
      "interface",
      "presentation",
      "scenes",
      "slides",
      "timeline",
      "ui",
      "ux"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transition_push",
    "version": 248,
    "popularity": 1,
    "codepoint": 62731,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "app",
      "application",
      "arrow",
      "arrows",
      "components",
      "design",
      "direction",
      "flix",
      "interface",
      "presentation",
      "right",
      "scenes",
      "screen",
      "site",
      "slides",
      "timeline",
      "ui",
      "ux",
      "web",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "transition_slide",
    "version": 248,
    "popularity": 2,
    "codepoint": 62730,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "animation",
      "column",
      "design",
      "flix",
      "format",
      "grid",
      "layout",
      "presentation",
      "scenes",
      "slides",
      "timeline",
      "vertical",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "translate",
    "version": 248,
    "popularity": 5062,
    "codepoint": 59618,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "language",
      "speaking",
      "speech",
      "translate",
      "translator",
      "words"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "translate",
    "version": 16,
    "popularity": 28811,
    "codepoint": 59618,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "language",
      "speaking",
      "speech",
      "translate",
      "translator",
      "words"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "transportation",
    "version": 248,
    "popularity": 436,
    "codepoint": 57885,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "car",
      "cars",
      "direction",
      "maps",
      "nest",
      "public",
      "transportation",
      "trip",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "travel",
    "version": 248,
    "popularity": 179,
    "codepoint": 59082,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "air",
      "airplane",
      "airport",
      "plane",
      "transportation",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "travel_explore",
    "version": 248,
    "popularity": 6643,
    "codepoint": 58075,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "browser",
      "earth",
      "explore",
      "find",
      "glass",
      "global",
      "globe",
      "look",
      "magnify",
      "magnifying",
      "map",
      "network",
      "planet",
      "search",
      "see",
      "social",
      "space",
      "travel",
      "web",
      "world"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "travel_explore",
    "version": 8,
    "popularity": 0,
    "codepoint": 58075,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "browser",
      "earth",
      "explore",
      "find",
      "glass",
      "global",
      "globe",
      "look",
      "magnify",
      "magnifying",
      "map",
      "network",
      "planet",
      "search",
      "see",
      "social",
      "space",
      "travel",
      "web",
      "world"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "travel_luggage_and_bags",
    "version": 248,
    "popularity": 24,
    "codepoint": 61379,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "and",
      "bag",
      "bags",
      "carry",
      "luggage",
      "on",
      "suitcase",
      "travel"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trending_down",
    "version": 248,
    "popularity": 2301,
    "codepoint": 59619,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "down",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "movement",
      "rate",
      "rating",
      "statistics",
      "tracking",
      "trending"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trending_down",
    "version": 18,
    "popularity": 14275,
    "codepoint": 59619,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "down",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "movement",
      "rate",
      "rating",
      "statistics",
      "tracking",
      "trending"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "trending_flat",
    "version": 248,
    "popularity": 5373,
    "codepoint": 59620,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arrow",
      "change",
      "data",
      "flat",
      "metric",
      "movement",
      "rate",
      "right",
      "track",
      "tracking",
      "trending"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trending_flat",
    "version": 19,
    "popularity": 25242,
    "codepoint": 59620,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "change",
      "data",
      "flat",
      "metric",
      "movement",
      "rate",
      "right",
      "track",
      "tracking",
      "trending"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "trending_up",
    "version": 248,
    "popularity": 11549,
    "codepoint": 59621,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "movement",
      "rate",
      "rating",
      "statistics",
      "tracking",
      "trending",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trending_up",
    "version": 19,
    "popularity": 85402,
    "codepoint": 59621,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "arrow",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "movement",
      "rate",
      "rating",
      "statistics",
      "tracking",
      "trending",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "trip",
    "version": 248,
    "popularity": 32,
    "codepoint": 59131,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "bag",
      "briefcase",
      "purse",
      "suitcase",
      "travel",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trip_origin",
    "version": 248,
    "popularity": 1174,
    "codepoint": 58747,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "circle",
      "departure",
      "origin",
      "trip"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trip_origin",
    "version": 12,
    "popularity": 6960,
    "codepoint": 58747,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "circle",
      "departure",
      "origin",
      "trip"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "trolley",
    "version": 248,
    "popularity": 13,
    "codepoint": 63595,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "carry",
      "factory",
      "manufactory",
      "move",
      "transport"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "trolley",
    "version": 1,
    "popularity": 1223,
    "codepoint": 63595,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "carry",
      "factory",
      "manufactory",
      "move",
      "transport"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "trophy",
    "version": 248,
    "popularity": 551,
    "codepoint": 59162,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "achievement",
      "award",
      "chalice",
      "champion",
      "cup",
      "first",
      "prize",
      "reward",
      "sport",
      "trophy",
      "winner"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "troubleshoot",
    "version": 248,
    "popularity": 293,
    "codepoint": 57810,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "find",
      "glass",
      "graph",
      "infographic",
      "line",
      "look",
      "magnify",
      "magnifying",
      "measure",
      "metrics",
      "search",
      "see",
      "statistics",
      "tracking",
      "troubleshoot"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "troubleshoot",
    "version": 10,
    "popularity": 3883,
    "codepoint": 57810,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "analytics",
      "chart",
      "data",
      "diagram",
      "find",
      "glass",
      "graph",
      "infographic",
      "line",
      "look",
      "magnify",
      "magnifying",
      "measure",
      "metrics",
      "search",
      "see",
      "statistics",
      "tracking",
      "troubleshoot"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "try",
    "version": 9,
    "popularity": 6220,
    "codepoint": 61564,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bookmark",
      "bubble",
      "chat",
      "comment",
      "communicate",
      "favorite",
      "feedback",
      "highlight",
      "important",
      "marked",
      "message",
      "save",
      "saved",
      "shape",
      "special",
      "speech",
      "star",
      "try"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tsunami",
    "version": 248,
    "popularity": 627,
    "codepoint": 60376,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "flood",
      "rain",
      "storm",
      "tsunami",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tsunami",
    "version": 1,
    "popularity": 928,
    "codepoint": 60376,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "flood",
      "rain",
      "storm",
      "tsunami",
      "weather"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "tsv",
    "version": 248,
    "popularity": 5,
    "codepoint": 59094,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "data",
      "file",
      "information",
      "separated",
      "tab",
      "text",
      "tsv",
      "values"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tty",
    "version": 248,
    "popularity": 304,
    "codepoint": 61866,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "deaf",
      "device",
      "hardware",
      "impaired",
      "mobile",
      "phone",
      "speech",
      "talk",
      "telephone",
      "text",
      "tty"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tty",
    "version": 8,
    "popularity": 2356,
    "codepoint": 61866,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "call",
      "cell",
      "contact",
      "deaf",
      "device",
      "hardware",
      "impaired",
      "mobile",
      "phone",
      "speech",
      "talk",
      "telephone",
      "text",
      "tty"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tune",
    "version": 248,
    "popularity": 13753,
    "codepoint": 58409,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "adjust",
      "audio",
      "controls",
      "custom",
      "customize",
      "edit",
      "editing",
      "filter",
      "filters",
      "instant",
      "mix",
      "music",
      "options",
      "setting",
      "settings",
      "slider",
      "sliders",
      "switches",
      "tune"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tune",
    "version": 12,
    "popularity": 63997,
    "codepoint": 58409,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "adjust",
      "audio",
      "controls",
      "custom",
      "customize",
      "edit",
      "editing",
      "filter",
      "filters",
      "instant",
      "mix",
      "music",
      "options",
      "setting",
      "settings",
      "slider",
      "sliders",
      "switches",
      "tune"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tungsten",
    "version": 10,
    "popularity": 14425,
    "codepoint": 61565,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "electricity",
      "indoor",
      "lamp",
      "light",
      "lightbulb",
      "setting",
      "tungsten"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "turn_left",
    "version": 248,
    "popularity": 453,
    "codepoint": 60326,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "turn_left",
    "version": 1,
    "popularity": 0,
    "codepoint": 60326,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "turn_right",
    "version": 248,
    "popularity": 603,
    "codepoint": 60331,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "turn_right",
    "version": 1,
    "popularity": 0,
    "codepoint": 60331,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "turn_sharp_left",
    "version": 248,
    "popularity": 196,
    "codepoint": 60327,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sharp",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "turn_sharp_left",
    "version": 1,
    "popularity": 632,
    "codepoint": 60327,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sharp",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "turn_sharp_right",
    "version": 248,
    "popularity": 247,
    "codepoint": 60330,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sharp",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "turn_sharp_right",
    "version": 1,
    "popularity": 888,
    "codepoint": 60330,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sharp",
      "sign",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "turn_slight_left",
    "version": 248,
    "popularity": 245,
    "codepoint": 60324,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "slight",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "turn_slight_left",
    "version": 1,
    "popularity": 797,
    "codepoint": 60324,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "slight",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "turn_slight_right",
    "version": 248,
    "popularity": 328,
    "codepoint": 60314,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sharp",
      "sign",
      "slight",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "turn_slight_right",
    "version": 1,
    "popularity": 821,
    "codepoint": 60314,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sharp",
      "sign",
      "slight",
      "traffic",
      "turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "turned_in",
    "version": 11,
    "popularity": 9133,
    "codepoint": 59622,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "archive",
      "bookmark",
      "favorite",
      "in",
      "label",
      "library",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
      "turned"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "turned_in_not",
    "version": 11,
    "popularity": 8195,
    "codepoint": 59623,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "archive",
      "bookmark",
      "favorite",
      "in",
      "label",
      "library",
      "not",
      "read",
      "reading",
      "remember",
      "ribbon",
      "save",
      "tag",
      "turned"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tv",
    "version": 248,
    "popularity": 2912,
    "codepoint": 58163,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "device",
      "display",
      "monitor",
      "screen",
      "screencast",
      "stream",
      "television",
      "tv",
      "video",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv",
    "version": 16,
    "popularity": 14858,
    "codepoint": 58163,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "device",
      "display",
      "monitor",
      "screen",
      "screencast",
      "stream",
      "television",
      "tv",
      "video",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tv_gen",
    "version": 248,
    "popularity": 691,
    "codepoint": 59440,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "device",
      "electronic",
      "hardware",
      "home",
      "house",
      "monitor",
      "nest",
      "tv",
      "videos"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv_guide",
    "version": 248,
    "popularity": 20,
    "codepoint": 57820,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "device",
      "guide",
      "hardware",
      "television",
      "tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv_off",
    "version": 248,
    "popularity": 168,
    "codepoint": 58951,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "off",
      "on",
      "slash",
      "television",
      "tv",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv_off",
    "version": 11,
    "popularity": 1123,
    "codepoint": 58951,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "Android",
      "OS",
      "chrome",
      "desktop",
      "device",
      "disabled",
      "enabled",
      "hardware",
      "iOS",
      "mac",
      "monitor",
      "off",
      "on",
      "slash",
      "television",
      "tv",
      "web",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "tv_options_edit_channels",
    "version": 248,
    "popularity": 48,
    "codepoint": 57821,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "approve",
      "bullet",
      "channel",
      "check",
      "do",
      "edit",
      "lists",
      "notes",
      "ok",
      "options",
      "select",
      "to",
      "tv",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv_options_input_settings",
    "version": 248,
    "popularity": 31,
    "codepoint": 57822,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "adjustments",
      "clockwork",
      "cog",
      "configuration",
      "factory",
      "gear",
      "gears",
      "industry",
      "input",
      "machine",
      "mechanical",
      "options",
      "refinery",
      "repair",
      "settings",
      "tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv_remote",
    "version": 248,
    "popularity": 10,
    "codepoint": 62937,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "connect",
      "google connect",
      "google tv"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv_signin",
    "version": 248,
    "popularity": 32,
    "codepoint": 59163,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "account",
      "chrome",
      "desktop",
      "device",
      "face",
      "hardware",
      "human",
      "iOS",
      "login",
      "mac",
      "monitor",
      "people",
      "person",
      "profile",
      "signin",
      "tv",
      "user",
      "web",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "tv_with_assistant",
    "version": 248,
    "popularity": 161,
    "codepoint": 59269,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "IoT",
      "assistant",
      "device",
      "electronic",
      "hardware",
      "home",
      "house",
      "internet",
      "nest",
      "smart",
      "tv",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "two_pager",
    "version": 248,
    "popularity": 13,
    "codepoint": 62751,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "book",
      "data",
      "doc",
      "document",
      "drive",
      "file",
      "folder",
      "folders",
      "library",
      "page",
      "paper",
      "read",
      "reading",
      "sheet",
      "slide",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "two_wheeler",
    "version": 248,
    "popularity": 1704,
    "codepoint": 59897,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "direction",
      "maps",
      "motorcycle",
      "public",
      "scooter",
      "sport",
      "transportation",
      "travel",
      "two wheeler",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "two_wheeler",
    "version": 14,
    "popularity": 10565,
    "codepoint": 59897,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "automobile",
      "bike",
      "car",
      "cars",
      "direction",
      "maps",
      "motorcycle",
      "public",
      "scooter",
      "sport",
      "transportation",
      "travel",
      "two wheeler",
      "vehicle"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "type_specimen",
    "version": 248,
    "popularity": 169,
    "codepoint": 63728,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "type_specimen",
    "version": 1,
    "popularity": 0,
    "codepoint": 63728,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "u_turn_left",
    "version": 248,
    "popularity": 380,
    "codepoint": 60321,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
      "u-turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "u_turn_left",
    "version": 1,
    "popularity": 1334,
    "codepoint": 60321,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "left",
      "maps",
      "navigation",
      "path",
      "route",
      "sign",
      "traffic",
      "u-turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "u_turn_right",
    "version": 248,
    "popularity": 305,
    "codepoint": 60322,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Transportation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic",
      "u-turn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "u_turn_right",
    "version": 1,
    "popularity": 987,
    "codepoint": 60322,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "direction",
      "directions",
      "maps",
      "navigation",
      "path",
      "right",
      "route",
      "sign",
      "traffic",
      "u-turn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ulna_radius",
    "version": 248,
    "popularity": 138,
    "codepoint": 63645,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arm",
      "body",
      "bone",
      "bones",
      "forearm",
      "health",
      "medical",
      "radius",
      "skeleton",
      "ulna"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ulna_radius_alt",
    "version": 248,
    "popularity": 130,
    "codepoint": 63646,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arm",
      "body",
      "bone",
      "bones",
      "forearm",
      "health",
      "medical",
      "radius",
      "skeleton",
      "ulna"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "umbrella",
    "version": 248,
    "popularity": 355,
    "codepoint": 61869,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "beach",
      "protection",
      "rain",
      "sun",
      "sunny",
      "umbrella"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "umbrella",
    "version": 8,
    "popularity": 2657,
    "codepoint": 61869,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "beach",
      "protection",
      "rain",
      "sun",
      "sunny",
      "umbrella"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "unarchive",
    "version": 248,
    "popularity": 1265,
    "codepoint": 57705,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "archive",
      "arrow",
      "inbox",
      "mail",
      "store",
      "unarchive",
      "undo",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unarchive",
    "version": 13,
    "popularity": 7932,
    "codepoint": 57705,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "archive",
      "arrow",
      "inbox",
      "mail",
      "store",
      "unarchive",
      "undo",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "undo",
    "version": 248,
    "popularity": 8950,
    "codepoint": 57702,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "backward",
      "mail",
      "previous",
      "redo",
      "repeat",
      "rotate",
      "undo"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "undo",
    "version": 13,
    "popularity": 34410,
    "codepoint": 57702,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "arrow",
      "backward",
      "mail",
      "previous",
      "redo",
      "repeat",
      "rotate",
      "undo"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "unfold_less",
    "version": 248,
    "popularity": 2239,
    "codepoint": 58838,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "inward",
      "less",
      "list",
      "navigation",
      "unfold",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unfold_less",
    "version": 12,
    "popularity": 12613,
    "codepoint": 58838,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "expand",
      "expandable",
      "inward",
      "less",
      "list",
      "navigation",
      "unfold",
      "up"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "unfold_less_double",
    "version": 248,
    "popularity": 530,
    "codepoint": 63695,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "double",
      "expand",
      "expandable",
      "inward",
      "less",
      "list",
      "navigation",
      "unfold",
      "up"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unfold_less_double",
    "version": 1,
    "popularity": 877,
    "codepoint": 63695,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "double",
      "expand",
      "expandable",
      "inward",
      "less",
      "list",
      "navigation",
      "unfold",
      "up"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "unfold_more",
    "version": 248,
    "popularity": 5610,
    "codepoint": 58839,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
      "navigation",
      "unfold"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unfold_more",
    "version": 12,
    "popularity": 33019,
    "codepoint": 58839,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
      "navigation",
      "unfold"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "unfold_more_double",
    "version": 248,
    "popularity": 725,
    "codepoint": 63696,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "double",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
      "navigation",
      "unfold"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unfold_more_double",
    "version": 1,
    "popularity": 1319,
    "codepoint": 63696,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "arrows",
      "chevron",
      "collapse",
      "direction",
      "double",
      "down",
      "expand",
      "expandable",
      "list",
      "more",
      "navigation",
      "unfold"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "ungroup",
    "version": 248,
    "popularity": 19,
    "codepoint": 63281,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "export",
      "external",
      "out",
      "right"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "universal_currency",
    "version": 248,
    "popularity": 37,
    "codepoint": 59898,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "universal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "universal_currency_alt",
    "version": 248,
    "popularity": 66,
    "codepoint": 59188,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "card",
      "cash",
      "coin",
      "commerce",
      "credit",
      "currency",
      "dollars",
      "money",
      "online",
      "pay",
      "payment",
      "universal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "universal_local",
    "version": 248,
    "popularity": 32,
    "codepoint": 59899,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "destination",
      "direction",
      "local",
      "location",
      "map",
      "mark",
      "marker",
      "pin",
      "place",
      "spot",
      "universal"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unknown_2",
    "version": 248,
    "popularity": 20,
    "codepoint": 59042,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "+",
      "2",
      "add",
      "adjust",
      "adjustments",
      "decrease",
      "edit",
      "editier",
      "editing",
      "filters",
      "increases",
      "plus",
      "subtract",
      "unknown"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unknown_5",
    "version": 248,
    "popularity": 17,
    "codepoint": 59045,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "5",
      "adjust",
      "adjustments",
      "circle",
      "decrease",
      "edit",
      "editing",
      "filters",
      "minus",
      "remove",
      "subtract",
      "unknown"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unknown_document",
    "version": 248,
    "popularity": 40,
    "codepoint": 63492,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "?",
      "assistance",
      "doc",
      "document",
      "file",
      "health",
      "help",
      "info",
      "information",
      "page",
      "paper",
      "punctuation",
      "question mark",
      "support",
      "symbol"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unknown_med",
    "version": 248,
    "popularity": 61,
    "codepoint": 60093,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "dash",
      "dashes",
      "health",
      "miss",
      "missing",
      "unavailable"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unlicense",
    "version": 248,
    "popularity": 14,
    "codepoint": 60165,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "certification",
      "degree",
      "disabled",
      "ecommerce",
      "enabled",
      "guarantee",
      "license",
      "medal",
      "off",
      "on",
      "permit",
      "premium",
      "ribbon",
      "slash",
      "verification"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unpublished",
    "version": 248,
    "popularity": 1414,
    "codepoint": 62006,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "disabled",
      "done",
      "enabled",
      "mark",
      "off",
      "ok",
      "on",
      "select",
      "slash",
      "tick",
      "unpublished",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unpublished",
    "version": 7,
    "popularity": 10685,
    "codepoint": 62006,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "check",
      "circle",
      "complete",
      "disabled",
      "done",
      "enabled",
      "mark",
      "off",
      "ok",
      "on",
      "select",
      "slash",
      "tick",
      "unpublished",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "unsubscribe",
    "version": 248,
    "popularity": 1023,
    "codepoint": 57579,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "cancel",
      "close",
      "email",
      "envelop",
      "letter",
      "mail",
      "message",
      "newsletter",
      "off",
      "remove",
      "send",
      "subscribe",
      "unsubscribe"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "unsubscribe",
    "version": 12,
    "popularity": 5592,
    "codepoint": 57579,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "cancel",
      "close",
      "email",
      "envelop",
      "letter",
      "mail",
      "message",
      "newsletter",
      "off",
      "remove",
      "send",
      "subscribe",
      "unsubscribe"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "upcoming",
    "version": 248,
    "popularity": 628,
    "codepoint": 61566,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "alarm",
      "calendar",
      "mail",
      "message",
      "notification",
      "upcoming"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "upcoming",
    "version": 9,
    "popularity": 5778,
    "codepoint": 61566,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "alarm",
      "calendar",
      "mail",
      "message",
      "notification",
      "upcoming"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "update",
    "version": 248,
    "popularity": 8433,
    "codepoint": 59683,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "forward",
      "history",
      "load",
      "refresh",
      "reverse",
      "schedule",
      "time",
      "update"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "update",
    "version": 13,
    "popularity": 57269,
    "codepoint": 59683,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "forward",
      "history",
      "load",
      "refresh",
      "reverse",
      "schedule",
      "time",
      "update"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "update_disabled",
    "version": 248,
    "popularity": 448,
    "codepoint": 57461,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "disabled",
      "enabled",
      "forward",
      "history",
      "load",
      "off",
      "on",
      "refresh",
      "reverse",
      "rotate",
      "schedule",
      "slash",
      "time",
      "update"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "update_disabled",
    "version": 8,
    "popularity": 3223,
    "codepoint": 57461,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "clock",
      "date",
      "disabled",
      "enabled",
      "forward",
      "history",
      "load",
      "off",
      "on",
      "refresh",
      "reverse",
      "rotate",
      "schedule",
      "slash",
      "time",
      "update"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "upgrade",
    "version": 248,
    "popularity": 2205,
    "codepoint": 61691,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "export",
      "instal",
      "line",
      "replace",
      "up",
      "update",
      "upgrade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "upgrade",
    "version": 12,
    "popularity": 14437,
    "codepoint": 61691,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "export",
      "instal",
      "line",
      "replace",
      "up",
      "update",
      "upgrade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "upload",
    "version": 248,
    "popularity": 3380,
    "codepoint": 61595,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "download",
      "drive",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "upload",
    "version": 10,
    "popularity": 22995,
    "codepoint": 61595,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "arrows",
      "download",
      "drive",
      "up",
      "upload"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "upload_2",
    "version": 248,
    "popularity": 3,
    "codepoint": 62753,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "arrows",
      "download",
      "drive",
      "up",
      "upload"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "upload_file",
    "version": 248,
    "popularity": 7054,
    "codepoint": 59900,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "download",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "sheet",
      "slide",
      "up",
      "upload",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "upload_file",
    "version": 10,
    "popularity": 44420,
    "codepoint": 59900,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "arrow",
      "data",
      "doc",
      "document",
      "download",
      "drive",
      "file",
      "folder",
      "folders",
      "page",
      "paper",
      "sheet",
      "slide",
      "up",
      "upload",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "urology",
    "version": 248,
    "popularity": 2,
    "codepoint": 57655,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "body",
      "health",
      "human",
      "medical",
      "organ",
      "organs",
      "tract",
      "urinary"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "usb",
    "version": 248,
    "popularity": 857,
    "codepoint": 57824,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cable",
      "connection",
      "device",
      "usb",
      "wire"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "usb",
    "version": 12,
    "popularity": 4606,
    "codepoint": 57824,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cable",
      "connection",
      "device",
      "usb",
      "wire"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "usb_off",
    "version": 248,
    "popularity": 187,
    "codepoint": 58618,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cable",
      "connection",
      "device",
      "off",
      "usb",
      "wire"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "usb_off",
    "version": 4,
    "popularity": 1097,
    "codepoint": 58618,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cable",
      "connection",
      "device",
      "off",
      "usb",
      "wire"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "user_attributes",
    "version": 248,
    "popularity": 116,
    "codepoint": 59144,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "attributes",
      "avatar",
      "face",
      "human",
      "list",
      "lists",
      "people",
      "person",
      "profile",
      "user"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vaccines",
    "version": 248,
    "popularity": 3193,
    "codepoint": 57656,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "aid",
      "covid",
      "doctor",
      "drug",
      "emergency",
      "hospital",
      "immunity",
      "injection",
      "medical",
      "medication",
      "medicine",
      "needle",
      "pharmacy",
      "sick",
      "syringe",
      "vaccination",
      "vaccines",
      "vial"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vaccines",
    "version": 2,
    "popularity": 9514,
    "codepoint": 57656,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "aid",
      "covid",
      "doctor",
      "drug",
      "emergency",
      "hospital",
      "immunity",
      "injection",
      "medical",
      "medication",
      "medicine",
      "needle",
      "pharmacy",
      "sick",
      "syringe",
      "vaccination",
      "vaccines",
      "vial"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "vacuum",
    "version": 248,
    "popularity": 24,
    "codepoint": 61381,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "canister",
      "clean",
      "cleaner",
      "cleaning",
      "home",
      "housekeeping",
      "machine",
      "upright",
      "vacuum",
      "vacuuming"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "valve",
    "version": 248,
    "popularity": 1088,
    "codepoint": 57892,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "controller",
      "faucet",
      "gas",
      "lid",
      "nest",
      "pipe",
      "valve",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vape_free",
    "version": 248,
    "popularity": 382,
    "codepoint": 60358,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "disabled",
      "e-cigarette",
      "enabled",
      "free",
      "never",
      "no",
      "off",
      "on",
      "places",
      "prohibited",
      "slash",
      "smoke",
      "smoking",
      "tobacco",
      "vape",
      "vaping",
      "vapor",
      "warning",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vape_free",
    "version": 1,
    "popularity": 532,
    "codepoint": 60358,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "disabled",
      "e-cigarette",
      "enabled",
      "free",
      "never",
      "no",
      "off",
      "on",
      "places",
      "prohibited",
      "slash",
      "smoke",
      "smoking",
      "tobacco",
      "vape",
      "vaping",
      "vapor",
      "warning",
      "zone"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "vaping_rooms",
    "version": 248,
    "popularity": 524,
    "codepoint": 60367,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "allowed",
      "e-cigarette",
      "never",
      "no",
      "places",
      "prohibited",
      "smoke",
      "smoking",
      "tobacco",
      "vape",
      "vaping",
      "vapor",
      "warning",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vaping_rooms",
    "version": 1,
    "popularity": 672,
    "codepoint": 60367,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "allowed",
      "e-cigarette",
      "never",
      "no",
      "places",
      "prohibited",
      "smoke",
      "smoking",
      "tobacco",
      "vape",
      "vaping",
      "vapor",
      "warning",
      "zone"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "variable_add",
    "version": 248,
    "popularity": 2,
    "codepoint": 62750,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "add",
      "building block",
      "chips",
      "data",
      "docs",
      "dynamic",
      "form",
      "new symbol",
      "plus",
      "rectangle",
      "symbol",
      "text fields",
      "variables"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "variable_insert",
    "version": 248,
    "popularity": 1,
    "codepoint": 62749,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "arrows",
      "building block",
      "chips",
      "direction",
      "docs",
      "dynamic",
      "form",
      "insert",
      "left",
      "navigation",
      "rectangle",
      "text fields",
      "variables",
      "west"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "variable_remove",
    "version": 248,
    "popularity": 1,
    "codepoint": 62748,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "-",
      "building block",
      "chips",
      "data",
      "delete",
      "docs",
      "dynamic",
      "form",
      "minus",
      "rectangle",
      "remove",
      "subtract",
      "text fields",
      "variables"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "variables",
    "version": 248,
    "popularity": 58,
    "codepoint": 63569,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "building block",
      "chips",
      "data",
      "docs",
      "dynamic",
      "form",
      "rectangle",
      "text fields"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ventilator",
    "version": 248,
    "popularity": 1,
    "codepoint": 57657,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "health",
      "lung",
      "lungs",
      "medical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "verified",
    "version": 248,
    "popularity": 16576,
    "codepoint": 61302,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "approve",
      "badge",
      "burst",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "select",
      "star",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "verified",
    "version": 11,
    "popularity": 145570,
    "codepoint": 61302,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "badge",
      "burst",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "select",
      "star",
      "tick",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "verified_user",
    "version": 248,
    "popularity": 10622,
    "codepoint": 59624,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "approve",
      "certified",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "select",
      "shield",
      "tick",
      "user",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "verified_user",
    "version": 18,
    "popularity": 80173,
    "codepoint": 59624,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "approve",
      "certified",
      "check",
      "complete",
      "done",
      "mark",
      "ok",
      "privacy",
      "private",
      "protect",
      "protection",
      "security",
      "select",
      "shield",
      "tick",
      "user",
      "validate",
      "verified",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vertical_align_bottom",
    "version": 248,
    "popularity": 1087,
    "codepoint": 57944,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "arrow",
      "bottom",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vertical_align_bottom",
    "version": 12,
    "popularity": 7548,
    "codepoint": 57944,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "arrow",
      "bottom",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vertical_align_center",
    "version": 248,
    "popularity": 420,
    "codepoint": 57945,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "arrow",
      "center",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "up",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vertical_align_center",
    "version": 12,
    "popularity": 3233,
    "codepoint": 57945,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "arrow",
      "center",
      "doc",
      "down",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "up",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vertical_align_top",
    "version": 248,
    "popularity": 1200,
    "codepoint": 57946,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "align",
      "alignment",
      "arrow",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "top",
      "type",
      "up",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vertical_align_top",
    "version": 12,
    "popularity": 7053,
    "codepoint": 57946,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "align",
      "alignment",
      "arrow",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "top",
      "type",
      "up",
      "vertical",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vertical_distribute",
    "version": 248,
    "popularity": 281,
    "codepoint": 57462,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "alignment",
      "distribute",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vertical_distribute",
    "version": 6,
    "popularity": 2018,
    "codepoint": 57462,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "alignment",
      "distribute",
      "format",
      "layout",
      "lines",
      "paragraph",
      "rule",
      "rules",
      "style",
      "text",
      "vertical"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vertical_shades",
    "version": 248,
    "popularity": 146,
    "codepoint": 60430,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade",
      "vertical"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vertical_shades",
    "version": 1,
    "popularity": 605,
    "codepoint": 60430,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "cover",
      "curtains",
      "nest",
      "open",
      "shade",
      "shutter",
      "sunshade",
      "vertical"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "vertical_shades_closed",
    "version": 248,
    "popularity": 148,
    "codepoint": 60429,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "roller",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vertical_shades_closed",
    "version": 1,
    "popularity": 588,
    "codepoint": 60429,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "blinds",
      "closed",
      "cover",
      "curtains",
      "nest",
      "roller",
      "shade",
      "shutter",
      "sunshade"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "vertical_split",
    "version": 248,
    "popularity": 556,
    "codepoint": 59721,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "paragraph",
      "split",
      "text",
      "vertical",
      "website",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vertical_split",
    "version": 15,
    "popularity": 7440,
    "codepoint": 59721,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "paragraph",
      "split",
      "text",
      "vertical",
      "website",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vibration",
    "version": 248,
    "popularity": 716,
    "codepoint": 58925,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "alert",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "mode",
      "motion",
      "notification",
      "phone",
      "silence",
      "silent",
      "tablet",
      "vibrate",
      "vibration"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vibration",
    "version": 12,
    "popularity": 3756,
    "codepoint": 58925,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "Android",
      "OS",
      "alert",
      "cell",
      "device",
      "hardware",
      "iOS",
      "mobile",
      "mode",
      "motion",
      "notification",
      "phone",
      "silence",
      "silent",
      "tablet",
      "vibrate",
      "vibration"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "video_call",
    "version": 248,
    "popularity": 2661,
    "codepoint": 57456,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "+",
      "add",
      "call",
      "camera",
      "chat",
      "conference",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "new",
      "picture",
      "plus",
      "symbol",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_call",
    "version": 11,
    "popularity": 13856,
    "codepoint": 57456,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "+",
      "add",
      "call",
      "camera",
      "chat",
      "conference",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "new",
      "picture",
      "plus",
      "symbol",
      "video",
      "videography"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "video_camera_back",
    "version": 248,
    "popularity": 498,
    "codepoint": 61567,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "back",
      "camera",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "rear",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_camera_back",
    "version": 10,
    "popularity": 3505,
    "codepoint": 61567,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "back",
      "camera",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "rear",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "video_camera_front",
    "version": 248,
    "popularity": 1512,
    "codepoint": 61568,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "account",
      "camera",
      "face",
      "front",
      "human",
      "image",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "profile",
      "user",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_camera_front",
    "version": 10,
    "popularity": 10160,
    "codepoint": 61568,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "account",
      "camera",
      "face",
      "front",
      "human",
      "image",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "profile",
      "user",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "video_camera_front_off",
    "version": 248,
    "popularity": 33,
    "codepoint": 63547,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "account",
      "camera",
      "disabled",
      "enabled",
      "face",
      "front",
      "human",
      "image",
      "off",
      "offline",
      "on",
      "people",
      "person",
      "photo",
      "photography",
      "picture",
      "profile",
      "slash",
      "user",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_chat",
    "version": 248,
    "popularity": 446,
    "codepoint": 63648,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "cam",
      "camera",
      "chat",
      "comment",
      "communicate",
      "facetime",
      "feedback",
      "message",
      "speech",
      "video",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_chat",
    "version": 1,
    "popularity": 569,
    "codepoint": 63648,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "bubble",
      "cam",
      "camera",
      "chat",
      "comment",
      "communicate",
      "facetime",
      "feedback",
      "message",
      "speech",
      "video",
      "voice"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "video_file",
    "version": 248,
    "popularity": 653,
    "codepoint": 60295,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "camera",
      "doc",
      "document",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_file",
    "version": 1,
    "popularity": 2221,
    "codepoint": 60295,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "camera",
      "doc",
      "document",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "video_label",
    "version": 248,
    "popularity": 251,
    "codepoint": 57457,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "label",
      "screen",
      "video",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_label",
    "version": 11,
    "popularity": 1876,
    "codepoint": 57457,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "label",
      "screen",
      "video",
      "window"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "video_library",
    "version": 248,
    "popularity": 3204,
    "codepoint": 57418,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "arrow",
      "collection",
      "library",
      "play",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_library",
    "version": 13,
    "popularity": 16566,
    "codepoint": 57418,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "arrow",
      "collection",
      "library",
      "play",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "video_search",
    "version": 248,
    "popularity": 38,
    "codepoint": 61382,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "find",
      "glass",
      "look",
      "magnifying",
      "play",
      "search",
      "see",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_settings",
    "version": 248,
    "popularity": 679,
    "codepoint": 60021,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "play",
      "screen",
      "service",
      "setting",
      "settings",
      "video",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_settings",
    "version": 12,
    "popularity": 4976,
    "codepoint": 60021,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "change",
      "details",
      "gear",
      "info",
      "information",
      "options",
      "play",
      "screen",
      "service",
      "setting",
      "settings",
      "video",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "video_stable",
    "version": 248,
    "popularity": 138,
    "codepoint": 61569,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "film",
      "filming",
      "recording",
      "setting",
      "stability",
      "stable",
      "taping",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "video_stable",
    "version": 10,
    "popularity": 1193,
    "codepoint": 61569,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "film",
      "filming",
      "recording",
      "setting",
      "stability",
      "stable",
      "taping",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "videocam",
    "version": 248,
    "popularity": 10710,
    "codepoint": 57419,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "cam",
      "camera",
      "conference",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "videocam",
    "version": 16,
    "popularity": 65323,
    "codepoint": 57419,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "cam",
      "camera",
      "conference",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "picture",
      "video",
      "videography"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "videocam_off",
    "version": 248,
    "popularity": 1175,
    "codepoint": 57420,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "cam",
      "camera",
      "conference",
      "disabled",
      "enabled",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "off",
      "offline",
      "on",
      "picture",
      "slash",
      "video",
      "videography"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "videocam_off",
    "version": 12,
    "popularity": 9232,
    "codepoint": 57420,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "cam",
      "camera",
      "conference",
      "disabled",
      "enabled",
      "film",
      "filming",
      "hardware",
      "image",
      "motion",
      "off",
      "offline",
      "on",
      "picture",
      "slash",
      "video",
      "videography"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "videogame_asset",
    "version": 248,
    "popularity": 1296,
    "codepoint": 58168,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "asset",
      "console",
      "controller",
      "device",
      "game",
      "gamepad",
      "gaming",
      "playstation",
      "video"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "videogame_asset",
    "version": 11,
    "popularity": 8888,
    "codepoint": 58168,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "asset",
      "console",
      "controller",
      "device",
      "game",
      "gamepad",
      "gaming",
      "playstation",
      "video"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "videogame_asset_off",
    "version": 248,
    "popularity": 140,
    "codepoint": 58624,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "asset",
      "console",
      "controller",
      "device",
      "disabled",
      "enabled",
      "game",
      "gamepad",
      "gaming",
      "off",
      "on",
      "playstation",
      "slash",
      "video",
      "videogame"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "videogame_asset_off",
    "version": 4,
    "popularity": 1015,
    "codepoint": 58624,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "asset",
      "console",
      "controller",
      "device",
      "disabled",
      "enabled",
      "game",
      "gamepad",
      "gaming",
      "off",
      "on",
      "playstation",
      "slash",
      "video",
      "videogame"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_agenda",
    "version": 248,
    "popularity": 1309,
    "codepoint": 59625,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "agenda",
      "cards",
      "design",
      "format",
      "grid",
      "layout",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_agenda",
    "version": 14,
    "popularity": 11288,
    "codepoint": 59625,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "agenda",
      "cards",
      "design",
      "format",
      "grid",
      "layout",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_array",
    "version": 248,
    "popularity": 220,
    "codepoint": 59626,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "array",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_array",
    "version": 13,
    "popularity": 2748,
    "codepoint": 59626,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "array",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_carousel",
    "version": 248,
    "popularity": 814,
    "codepoint": 59627,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cards",
      "carousel",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_carousel",
    "version": 13,
    "popularity": 8357,
    "codepoint": 59627,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cards",
      "carousel",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_column",
    "version": 248,
    "popularity": 1382,
    "codepoint": 59628,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "column",
      "design",
      "format",
      "grid",
      "layout",
      "vertical",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_column",
    "version": 13,
    "popularity": 12578,
    "codepoint": 59628,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "column",
      "design",
      "format",
      "grid",
      "layout",
      "vertical",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_column_2",
    "version": 248,
    "popularity": 91,
    "codepoint": 63559,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "column",
      "design",
      "format",
      "grid",
      "layout",
      "vertical",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_comfy",
    "version": 248,
    "popularity": 561,
    "codepoint": 58410,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "comfy",
      "grid",
      "layout",
      "pattern",
      "squares",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_comfy",
    "version": 14,
    "popularity": 4765,
    "codepoint": 58410,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "comfy",
      "grid",
      "layout",
      "pattern",
      "squares",
      "view"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_comfy_alt",
    "version": 248,
    "popularity": 788,
    "codepoint": 60275,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "comfy",
      "cozy",
      "design",
      "format",
      "layout",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_comfy_alt",
    "version": 1,
    "popularity": 1504,
    "codepoint": 60275,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "comfy",
      "cozy",
      "design",
      "format",
      "layout",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_compact",
    "version": 248,
    "popularity": 595,
    "codepoint": 58411,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "compact",
      "grid",
      "layout",
      "pattern",
      "squares",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_compact",
    "version": 13,
    "popularity": 3648,
    "codepoint": 58411,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "compact",
      "grid",
      "layout",
      "pattern",
      "squares",
      "view"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_compact_alt",
    "version": 248,
    "popularity": 715,
    "codepoint": 60276,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "alt",
      "compact",
      "design",
      "format",
      "layout dense",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_compact_alt",
    "version": 1,
    "popularity": 1418,
    "codepoint": 60276,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "alt",
      "compact",
      "design",
      "format",
      "layout dense",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_cozy",
    "version": 248,
    "popularity": 891,
    "codepoint": 60277,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "comfy",
      "cozy",
      "design",
      "format",
      "layout",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_cozy",
    "version": 1,
    "popularity": 1827,
    "codepoint": 60277,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "comfy",
      "cozy",
      "design",
      "format",
      "layout",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_day",
    "version": 248,
    "popularity": 396,
    "codepoint": 59629,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "cards",
      "carousel",
      "day",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_day",
    "version": 13,
    "popularity": 4903,
    "codepoint": 59629,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "cards",
      "carousel",
      "day",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "view_headline",
    "version": 248,
    "popularity": 864,
    "codepoint": 59630,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "headline",
      "layout",
      "paragraph",
      "text",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_headline",
    "version": 11,
    "popularity": 21326,
    "codepoint": 59630,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "headline",
      "layout",
      "paragraph",
      "text",
      "view",
      "website"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "view_in_ar",
    "version": 248,
    "popularity": 4130,
    "codepoint": 59902,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "3d",
      "ar",
      "augmented",
      "cube",
      "daydream",
      "headset",
      "in",
      "reality",
      "square",
      "view",
      "vr"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_in_ar",
    "version": 11,
    "popularity": 34358,
    "codepoint": 59902,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "3d",
      "ar",
      "augmented",
      "cube",
      "daydream",
      "headset",
      "in",
      "reality",
      "square",
      "view",
      "vr"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "view_in_ar_off",
    "version": 248,
    "popularity": 5,
    "codepoint": 63003,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "3d",
      "ar",
      "augmented",
      "cube",
      "daydream",
      "disabled",
      "enabled",
      "headset",
      "in",
      "off",
      "offline",
      "on",
      "reality",
      "slash",
      "square",
      "view",
      "vr"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_kanban",
    "version": 248,
    "popularity": 1253,
    "codepoint": 60287,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "grid",
      "kanban",
      "layout",
      "pattern",
      "squares",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_kanban",
    "version": 1,
    "popularity": 2208,
    "codepoint": 60287,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "grid",
      "kanban",
      "layout",
      "pattern",
      "squares",
      "view"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_list",
    "version": 248,
    "popularity": 4909,
    "codepoint": 59631,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "lines",
      "list",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_list",
    "version": 14,
    "popularity": 66065,
    "codepoint": 59631,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "lines",
      "list",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_module",
    "version": 248,
    "popularity": 1026,
    "codepoint": 59632,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "module",
      "square",
      "squares",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_module",
    "version": 13,
    "popularity": 17597,
    "codepoint": 59632,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "module",
      "square",
      "squares",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_quilt",
    "version": 248,
    "popularity": 841,
    "codepoint": 59633,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "quilt",
      "square",
      "squares",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_quilt",
    "version": 14,
    "popularity": 8316,
    "codepoint": 59633,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "quilt",
      "square",
      "squares",
      "stacked",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_sidebar",
    "version": 248,
    "popularity": 748,
    "codepoint": 61716,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "sidebar",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_sidebar",
    "version": 13,
    "popularity": 7564,
    "codepoint": 61716,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "sidebar",
      "view",
      "web"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_stream",
    "version": 248,
    "popularity": 548,
    "codepoint": 59634,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "lines",
      "list",
      "stacked",
      "stream",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_stream",
    "version": 13,
    "popularity": 6929,
    "codepoint": 59634,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "design",
      "format",
      "grid",
      "layout",
      "lines",
      "list",
      "stacked",
      "stream",
      "view",
      "website"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_timeline",
    "version": 248,
    "popularity": 2619,
    "codepoint": 60293,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "grid",
      "layout",
      "pattern",
      "squares",
      "timeline",
      "view"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_timeline",
    "version": 1,
    "popularity": 4926,
    "codepoint": 60293,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "grid",
      "layout",
      "pattern",
      "squares",
      "timeline",
      "view"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "view_week",
    "version": 248,
    "popularity": 1073,
    "codepoint": 59635,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "bars",
      "columns",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website",
      "week"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "view_week",
    "version": 13,
    "popularity": 10033,
    "codepoint": 59635,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bars",
      "columns",
      "design",
      "format",
      "grid",
      "layout",
      "view",
      "website",
      "week"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "vignette",
    "version": 248,
    "popularity": 170,
    "codepoint": 58421,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "border",
      "edit",
      "editing",
      "filter",
      "gradient",
      "image",
      "photo",
      "photography",
      "setting",
      "vignette"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vignette",
    "version": 12,
    "popularity": 1102,
    "codepoint": 58421,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "border",
      "edit",
      "editing",
      "filter",
      "gradient",
      "image",
      "photo",
      "photography",
      "setting",
      "vignette"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "villa",
    "version": 248,
    "popularity": 750,
    "codepoint": 58758,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "architecture",
      "beach",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "traveling",
      "vacation stay",
      "villa"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "villa",
    "version": 4,
    "popularity": 5056,
    "codepoint": 58758,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "beach",
      "estate",
      "home",
      "house",
      "maps",
      "place",
      "real",
      "residence",
      "residential",
      "traveling",
      "vacation stay",
      "villa"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "visibility",
    "version": 248,
    "popularity": 46516,
    "codepoint": 59636,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "eye",
      "on",
      "reveal",
      "see",
      "show",
      "view",
      "visibility"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "visibility",
    "version": 16,
    "popularity": 305381,
    "codepoint": 59636,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "eye",
      "on",
      "reveal",
      "see",
      "show",
      "view",
      "visibility"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "visibility_lock",
    "version": 248,
    "popularity": 82,
    "codepoint": 63059,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "eye",
      "lock",
      "locked",
      "on",
      "password",
      "privacy",
      "private",
      "protect",
      "protection",
      "reveal",
      "safety",
      "secure",
      "security",
      "see",
      "show",
      "view",
      "visibility"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "visibility_off",
    "version": 248,
    "popularity": 19486,
    "codepoint": 59637,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "disabled",
      "enabled",
      "eye",
      "off",
      "on",
      "reveal",
      "see",
      "show",
      "slash",
      "view",
      "visibility"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "visibility_off",
    "version": 12,
    "popularity": 124107,
    "codepoint": 59637,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "disabled",
      "enabled",
      "eye",
      "off",
      "on",
      "reveal",
      "see",
      "show",
      "slash",
      "view",
      "visibility"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vital_signs",
    "version": 248,
    "popularity": 91,
    "codepoint": 58960,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "baseline",
      "device",
      "ecc",
      "ecg",
      "fitness",
      "health",
      "heart",
      "medical",
      "monitor",
      "track"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "voice_chat",
    "version": 248,
    "popularity": 787,
    "codepoint": 58926,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "bubble",
      "cam",
      "camera",
      "chat",
      "comment",
      "communicate",
      "facetime",
      "feedback",
      "message",
      "speech",
      "video",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "voice_chat",
    "version": 13,
    "popularity": 3882,
    "codepoint": 58926,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "bubble",
      "cam",
      "camera",
      "chat",
      "comment",
      "communicate",
      "facetime",
      "feedback",
      "message",
      "speech",
      "video",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "voice_over_off",
    "version": 248,
    "popularity": 455,
    "codepoint": 59722,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "account",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "on",
      "over",
      "people",
      "person",
      "profile",
      "recording",
      "slash",
      "speak",
      "speaking",
      "speech",
      "transcript",
      "user",
      "voice"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "voice_over_off",
    "version": 11,
    "popularity": 3073,
    "codepoint": 59722,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "account",
      "disabled",
      "enabled",
      "face",
      "human",
      "off",
      "on",
      "over",
      "people",
      "person",
      "profile",
      "recording",
      "slash",
      "speak",
      "speaking",
      "speech",
      "transcript",
      "user",
      "voice"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "voice_selection",
    "version": 248,
    "popularity": 98,
    "codepoint": 62858,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "chat",
      "face",
      "human",
      "mouth",
      "person",
      "read",
      "read aloud",
      "social",
      "talk",
      "talking"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "voicemail",
    "version": 248,
    "popularity": 706,
    "codepoint": 57561,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "device",
      "message",
      "missed",
      "mobile",
      "phone",
      "recording",
      "voice",
      "voicemail"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "voicemail",
    "version": 12,
    "popularity": 3173,
    "codepoint": 57561,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "device",
      "message",
      "missed",
      "mobile",
      "phone",
      "recording",
      "voice",
      "voicemail"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "volcano",
    "version": 248,
    "popularity": 444,
    "codepoint": 60378,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "eruption",
      "lava",
      "magma",
      "natural",
      "volcano"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "volcano",
    "version": 1,
    "popularity": 686,
    "codepoint": 60378,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "crisis",
      "disaster",
      "eruption",
      "lava",
      "magma",
      "natural",
      "volcano"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "volume_down",
    "version": 248,
    "popularity": 1444,
    "codepoint": 57421,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "control",
      "down",
      "music",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "volume_down",
    "version": 13,
    "popularity": 9262,
    "codepoint": 57421,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "audio",
      "control",
      "down",
      "music",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "volume_down_alt",
    "version": 248,
    "popularity": 455,
    "codepoint": 59292,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "alt",
      "audio",
      "control",
      "down",
      "music",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "volume_down_alt",
    "version": 3,
    "popularity": 616,
    "codepoint": 59292,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "alt",
      "audio",
      "control",
      "down",
      "music",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "volume_mute",
    "version": 248,
    "popularity": 2369,
    "codepoint": 57422,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "control",
      "music",
      "mute",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "volume_mute",
    "version": 13,
    "popularity": 13854,
    "codepoint": 57422,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "audio",
      "control",
      "music",
      "mute",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "volume_off",
    "version": 248,
    "popularity": 4542,
    "codepoint": 57423,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "control",
      "disabled",
      "enabled",
      "low",
      "music",
      "off",
      "on",
      "slash",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "volume_off",
    "version": 17,
    "popularity": 31969,
    "codepoint": 57423,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "audio",
      "control",
      "disabled",
      "enabled",
      "low",
      "music",
      "off",
      "on",
      "slash",
      "sound",
      "speaker",
      "tv",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "volume_up",
    "version": 248,
    "popularity": 10157,
    "codepoint": 57424,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Audio&Video"
    ],
    "tags": [
      "audio",
      "control",
      "music",
      "sound",
      "speaker",
      "tv",
      "up",
      "volume"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "volume_up",
    "version": 14,
    "popularity": 58280,
    "codepoint": 57424,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "audio",
      "control",
      "music",
      "sound",
      "speaker",
      "tv",
      "up",
      "volume"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "volunteer_activism",
    "version": 248,
    "popularity": 7422,
    "codepoint": 60016,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "activism",
      "donation",
      "fingers",
      "gesture",
      "giving",
      "hand",
      "hands",
      "heart",
      "love",
      "sharing",
      "volunteer"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "volunteer_activism",
    "version": 10,
    "popularity": 35651,
    "codepoint": 60016,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "activism",
      "donation",
      "fingers",
      "gesture",
      "giving",
      "hand",
      "hands",
      "heart",
      "love",
      "sharing",
      "volunteer"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "voting_chip",
    "version": 248,
    "popularity": 35,
    "codepoint": 63570,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "+",
      "1",
      "add",
      "chip",
      "new",
      "one",
      "plus",
      "vote"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vpn_key",
    "version": 248,
    "popularity": 4206,
    "codepoint": 57562,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "access",
      "code",
      "door",
      "entry",
      "key",
      "lock",
      "network",
      "passcode",
      "password",
      "unlock",
      "vpn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vpn_key",
    "version": 12,
    "popularity": 46753,
    "codepoint": 57562,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "access",
      "code",
      "door",
      "entry",
      "key",
      "lock",
      "network",
      "passcode",
      "password",
      "unlock",
      "vpn"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vpn_key_alert",
    "version": 248,
    "popularity": 8,
    "codepoint": 63180,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "!",
      "access",
      "alert",
      "attention",
      "caution",
      "code",
      "danger",
      "door",
      "entry",
      "error",
      "exclamation",
      "feedback",
      "important",
      "key",
      "lock",
      "mark",
      "network",
      "notification",
      "passcode",
      "password",
      "problem",
      "report",
      "unlock",
      "vpn",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vpn_key_off",
    "version": 248,
    "popularity": 637,
    "codepoint": 60282,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "access",
      "code",
      "disabled",
      "door",
      "enabled",
      "entry",
      "key",
      "lock",
      "network",
      "off",
      "offline",
      "on",
      "passcode",
      "password",
      "slash",
      "unlock",
      "vpn"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vpn_key_off",
    "version": 1,
    "popularity": 1060,
    "codepoint": 60282,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "access",
      "code",
      "disabled",
      "door",
      "enabled",
      "entry",
      "key",
      "lock",
      "network",
      "off",
      "offline",
      "on",
      "passcode",
      "password",
      "slash",
      "unlock",
      "vpn"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "vpn_lock",
    "version": 248,
    "popularity": 1250,
    "codepoint": 58927,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "earth",
      "globe",
      "lock",
      "locked",
      "network",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "virtual",
      "vpn",
      "world"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vpn_lock",
    "version": 17,
    "popularity": 6771,
    "codepoint": 58927,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "earth",
      "globe",
      "lock",
      "locked",
      "network",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "virtual",
      "vpn",
      "world"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "vr180_create2d",
    "version": 248,
    "popularity": 43,
    "codepoint": 61386,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "180",
      "2d",
      "artificial",
      "circle",
      "create",
      "create2d",
      "disabled",
      "enabled",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "offline",
      "on",
      "photo",
      "photography",
      "picture",
      "reality",
      "slash",
      "vertical",
      "vr",
      "vr180"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vr180_create2d_off",
    "version": 248,
    "popularity": 11,
    "codepoint": 62833,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "180",
      "2d",
      "artificial",
      "circle",
      "create",
      "create2d",
      "disabled",
      "enabled",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "off",
      "offline",
      "on",
      "photo",
      "photography",
      "picture",
      "reality",
      "slash",
      "vertical",
      "vr",
      "vr180"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vrpano",
    "version": 248,
    "popularity": 437,
    "codepoint": 61570,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "angle",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "panorama",
      "photo",
      "photography",
      "picture",
      "view",
      "vrpano",
      "wide"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "vrpano",
    "version": 10,
    "popularity": 2498,
    "codepoint": 61570,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "angle",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "panorama",
      "photo",
      "photography",
      "picture",
      "view",
      "vrpano",
      "wide"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wall_art",
    "version": 248,
    "popularity": 21,
    "codepoint": 61387,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "art",
      "frame",
      "image",
      "landscape",
      "mountain",
      "mountains",
      "photo",
      "photography",
      "picture",
      "wall"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wall_lamp",
    "version": 248,
    "popularity": 96,
    "codepoint": 58036,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "lamp",
      "light",
      "sconce",
      "wall"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wallet",
    "version": 248,
    "popularity": 3309,
    "codepoint": 63743,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wallet",
    "version": 1,
    "popularity": 5226,
    "codepoint": 63743,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wallpaper",
    "version": 248,
    "popularity": 1050,
    "codepoint": 57788,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "background",
      "dash",
      "dashed",
      "image",
      "landscape",
      "photo",
      "photography",
      "picture",
      "wallpaper"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wallpaper",
    "version": 13,
    "popularity": 7328,
    "codepoint": 57788,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "background",
      "dash",
      "dashed",
      "image",
      "landscape",
      "photo",
      "photography",
      "picture",
      "wallpaper"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wallpaper_slideshow",
    "version": 248,
    "popularity": 8,
    "codepoint": 63090,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "background",
      "image",
      "landscape",
      "layers",
      "multiple",
      "photo",
      "photography",
      "picture",
      "slides",
      "stack",
      "wallpaper"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "ward",
    "version": 248,
    "popularity": 9,
    "codepoint": 57660,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bed",
      "body",
      "clinic",
      "health",
      "hospital",
      "human",
      "patient",
      "people",
      "person"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "warehouse",
    "version": 248,
    "popularity": 2501,
    "codepoint": 60344,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "garage",
      "industry",
      "manufacturing",
      "storage",
      "warehouse"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "warehouse",
    "version": 1,
    "popularity": 8969,
    "codepoint": 60344,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "garage",
      "industry",
      "manufacturing",
      "storage",
      "warehouse"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "warning",
    "version": 248,
    "popularity": 30243,
    "codepoint": 57346,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "triangle",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "warning",
    "version": 17,
    "popularity": 118006,
    "codepoint": 57346,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "alert"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "triangle",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "warning_amber",
    "version": 16,
    "popularity": 51631,
    "codepoint": 61571,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "alert"
    ],
    "tags": [
      "!",
      "alert",
      "amber",
      "attention",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "symbol",
      "triangle",
      "warning"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "warning_off",
    "version": 248,
    "popularity": 8,
    "codepoint": 63405,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "danger",
      "disabled",
      "enabled",
      "error",
      "exclamation",
      "important",
      "mark",
      "notification",
      "off",
      "offline",
      "on",
      "slash",
      "symbol",
      "triangle",
      "warning"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wash",
    "version": 248,
    "popularity": 396,
    "codepoint": 61873,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "bathroom",
      "clean",
      "fingers",
      "gesture",
      "hand",
      "wash",
      "wc"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wash",
    "version": 8,
    "popularity": 3373,
    "codepoint": 61873,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "bathroom",
      "clean",
      "fingers",
      "gesture",
      "hand",
      "wash",
      "wc"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "watch",
    "version": 248,
    "popularity": 1946,
    "codepoint": 58164,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "ar",
      "clock",
      "gadget",
      "iOS",
      "time",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "watch",
    "version": 12,
    "popularity": 6598,
    "codepoint": 58164,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "ar",
      "clock",
      "gadget",
      "iOS",
      "time",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "watch_button_press",
    "version": 248,
    "popularity": 1,
    "codepoint": 63146,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "ar",
      "clock",
      "device",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "watch_later",
    "version": 17,
    "popularity": 58255,
    "codepoint": 59684,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "clock",
      "date",
      "later",
      "schedule",
      "time",
      "watch"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "watch_off",
    "version": 248,
    "popularity": 234,
    "codepoint": 60131,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "ar",
      "clock",
      "close",
      "gadget",
      "iOS",
      "off",
      "shut",
      "time",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "watch_off",
    "version": 1,
    "popularity": 669,
    "codepoint": 60131,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "hardware"
    ],
    "tags": [
      "Android",
      "OS",
      "ar",
      "clock",
      "close",
      "gadget",
      "iOS",
      "off",
      "shut",
      "time",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "watch_screentime",
    "version": 248,
    "popularity": 21,
    "codepoint": 63150,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "ar",
      "clock",
      "device",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "watch_wake",
    "version": 248,
    "popularity": 6,
    "codepoint": 63145,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Hardware"
    ],
    "tags": [
      "ar",
      "awaken",
      "clock",
      "device",
      "gadget",
      "iOS",
      "screen",
      "time",
      "tracker",
      "vr",
      "watch",
      "wearables",
      "web",
      "wristwatch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water",
    "version": 248,
    "popularity": 2255,
    "codepoint": 61572,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "aqua",
      "beach",
      "lake",
      "ocean",
      "river",
      "water",
      "waves",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water",
    "version": 11,
    "popularity": 9165,
    "codepoint": 61572,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "aqua",
      "beach",
      "lake",
      "ocean",
      "river",
      "water",
      "waves",
      "weather"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "water_bottle",
    "version": 248,
    "popularity": 17,
    "codepoint": 63133,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "hydrate",
      "hydration"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_bottle_large",
    "version": 248,
    "popularity": 5,
    "codepoint": 63134,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "hydrate",
      "hydration"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_damage",
    "version": 248,
    "popularity": 401,
    "codepoint": 61955,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "architecture",
      "building",
      "damage",
      "drop",
      "droplet",
      "estate",
      "house",
      "leak",
      "plumbing",
      "real",
      "residence",
      "residential",
      "shelter",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_damage",
    "version": 6,
    "popularity": 3025,
    "codepoint": 61955,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "architecture",
      "building",
      "damage",
      "drop",
      "droplet",
      "estate",
      "house",
      "leak",
      "plumbing",
      "real",
      "residence",
      "residential",
      "shelter",
      "water"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "water_do",
    "version": 248,
    "popularity": 5,
    "codepoint": 63600,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "dissolved",
      "droplets",
      "oxygen",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_drop",
    "version": 248,
    "popularity": 6705,
    "codepoint": 59288,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "drink",
      "drop",
      "droplet",
      "eco",
      "liquid",
      "nature",
      "ocean",
      "rain",
      "social",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_drop",
    "version": 3,
    "popularity": 32243,
    "codepoint": 59288,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "drink",
      "drop",
      "droplet",
      "eco",
      "liquid",
      "nature",
      "ocean",
      "rain",
      "social",
      "water"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "water_ec",
    "version": 248,
    "popularity": 3,
    "codepoint": 63605,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "current",
      "droplet",
      "electrical",
      "electricity",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_full",
    "version": 248,
    "popularity": 25,
    "codepoint": 63190,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "drink",
      "drinking",
      "fitbit",
      "water",
      "waterglass"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_heater",
    "version": 248,
    "popularity": 351,
    "codepoint": 57988,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "appliance",
      "device",
      "home",
      "hot",
      "house",
      "nest",
      "water heater"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_lock",
    "version": 248,
    "popularity": 11,
    "codepoint": 63149,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "device",
      "droplet",
      "fitbit",
      "locked",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "tracker",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_loss",
    "version": 248,
    "popularity": 9,
    "codepoint": 63189,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "drink",
      "drinking",
      "fitbit",
      "water",
      "waterglass"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_lux",
    "version": 248,
    "popularity": 10,
    "codepoint": 63604,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "droplets",
      "lighting",
      "reflection",
      "refraction",
      "sun",
      "sunny",
      "sunrise",
      "water",
      "waves"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_medium",
    "version": 248,
    "popularity": 25,
    "codepoint": 63188,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "drink",
      "drinking",
      "fitbit",
      "water",
      "waterglass"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_orp",
    "version": 248,
    "popularity": 4,
    "codepoint": 63608,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "droplet",
      "oxidation",
      "oxygen",
      "potential",
      "reduction",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_ph",
    "version": 248,
    "popularity": 8,
    "codepoint": 63610,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "acidic",
      "droplet",
      "hydrogen",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_pump",
    "version": 248,
    "popularity": 53,
    "codepoint": 62936,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "fluids",
      "groundwater intake",
      "heating",
      "pumping",
      "rainwater harvesting",
      "wastewater",
      "water pressure",
      "water pressure boosting"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "water_voc",
    "version": 248,
    "popularity": 6,
    "codepoint": 63611,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "chemicals",
      "compounds",
      "droplet",
      "organic",
      "vapor",
      "voc",
      "volatile",
      "water"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "waterfall_chart",
    "version": 248,
    "popularity": 634,
    "codepoint": 59904,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "analytics",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "waterfall"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "waterfall_chart",
    "version": 11,
    "popularity": 5020,
    "codepoint": 59904,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "analytics",
      "bar",
      "chart",
      "data",
      "diagram",
      "graph",
      "infographic",
      "measure",
      "metrics",
      "statistics",
      "tracking",
      "waterfall"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "waves",
    "version": 248,
    "popularity": 1511,
    "codepoint": 57718,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Activities"
    ],
    "tags": [
      "beach",
      "lake",
      "ocean",
      "pool",
      "river",
      "sea",
      "swim",
      "water",
      "wave",
      "waves"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "waves",
    "version": 11,
    "popularity": 8423,
    "codepoint": 57718,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "beach",
      "lake",
      "ocean",
      "pool",
      "river",
      "sea",
      "swim",
      "water",
      "wave",
      "waves"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "waving_hand",
    "version": 248,
    "popularity": 2919,
    "codepoint": 59238,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bye",
      "fingers",
      "gesture",
      "goodbye",
      "greetings",
      "hand",
      "hello",
      "palm",
      "wave",
      "waving"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "waving_hand",
    "version": 3,
    "popularity": 10889,
    "codepoint": 59238,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "bye",
      "fingers",
      "gesture",
      "goodbye",
      "greetings",
      "hand",
      "hello",
      "palm",
      "wave",
      "waving"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wb_auto",
    "version": 248,
    "popularity": 104,
    "codepoint": 58412,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "A",
      "W",
      "alphabet",
      "auto",
      "automatic",
      "balance",
      "character",
      "edit",
      "editing",
      "font",
      "image",
      "letter",
      "photo",
      "photography",
      "symbol",
      "text",
      "type",
      "white",
      "wp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wb_auto",
    "version": 12,
    "popularity": 817,
    "codepoint": 58412,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "A",
      "W",
      "alphabet",
      "auto",
      "automatic",
      "balance",
      "character",
      "edit",
      "editing",
      "font",
      "image",
      "letter",
      "photo",
      "photography",
      "symbol",
      "text",
      "type",
      "white",
      "wp"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wb_cloudy",
    "version": 12,
    "popularity": 6357,
    "codepoint": 58413,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "balance",
      "cloud",
      "cloudy",
      "edit",
      "editing",
      "white",
      "wp"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wb_incandescent",
    "version": 248,
    "popularity": 1127,
    "codepoint": 58414,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "balance",
      "bright",
      "edit",
      "editing",
      "incandescent",
      "light",
      "lighting",
      "setting",
      "settings",
      "white",
      "wp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wb_incandescent",
    "version": 12,
    "popularity": 0,
    "codepoint": 58414,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "balance",
      "bright",
      "edit",
      "editing",
      "incandescent",
      "light",
      "lighting",
      "setting",
      "settings",
      "white",
      "wp"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wb_iridescent",
    "version": 248,
    "popularity": 246,
    "codepoint": 58422,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "balance",
      "bright",
      "edit",
      "editing",
      "iridescent",
      "light",
      "lighting",
      "setting",
      "settings",
      "white",
      "wp"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wb_iridescent",
    "version": 11,
    "popularity": 1479,
    "codepoint": 58422,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "balance",
      "bright",
      "edit",
      "editing",
      "iridescent",
      "light",
      "lighting",
      "setting",
      "settings",
      "white",
      "wp"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wb_shade",
    "version": 248,
    "popularity": 186,
    "codepoint": 59905,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "balance",
      "house",
      "light",
      "lighting",
      "shade",
      "wb",
      "white"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wb_shade",
    "version": 10,
    "popularity": 1162,
    "codepoint": 59905,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "balance",
      "house",
      "light",
      "lighting",
      "shade",
      "wb",
      "white"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wb_sunny",
    "version": 248,
    "popularity": 2642,
    "codepoint": 58416,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "balance",
      "bright",
      "light",
      "lighting",
      "sun",
      "sunny",
      "wb",
      "white"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wb_sunny",
    "version": 12,
    "popularity": 0,
    "codepoint": 58416,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "balance",
      "bright",
      "light",
      "lighting",
      "sun",
      "sunny",
      "wb",
      "white"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wb_twighlight",
    "version": 10,
    "popularity": 659,
    "codepoint": 59906,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "balance",
      "light",
      "lighting",
      "noon",
      "sun",
      "sunset",
      "twilight",
      "wb",
      "white"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wb_twilight",
    "version": 248,
    "popularity": 1048,
    "codepoint": 57798,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "balance",
      "light",
      "lighting",
      "noon",
      "sun",
      "sunset",
      "twilight",
      "wb",
      "white"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wb_twilight",
    "version": 9,
    "popularity": 2977,
    "codepoint": 57798,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "image"
    ],
    "tags": [
      "balance",
      "light",
      "lighting",
      "noon",
      "sun",
      "sunset",
      "twilight",
      "wb",
      "white"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wc",
    "version": 248,
    "popularity": 2856,
    "codepoint": 58941,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "bathroom",
      "closet",
      "female",
      "gender",
      "male",
      "man",
      "restroom",
      "room",
      "wash",
      "water",
      "wc",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wc",
    "version": 11,
    "popularity": 10865,
    "codepoint": 58941,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "bathroom",
      "closet",
      "female",
      "gender",
      "male",
      "man",
      "restroom",
      "room",
      "wash",
      "water",
      "wc",
      "women"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "weather_hail",
    "version": 248,
    "popularity": 24,
    "codepoint": 63103,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "hailstones",
      "hailstorm",
      "home",
      "nest",
      "sleet",
      "snow",
      "snowy",
      "thermostat",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "weather_mix",
    "version": 248,
    "popularity": 23,
    "codepoint": 62987,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "climate",
      "cloud",
      "cloudy",
      "cold",
      "hail",
      "mixed conditions",
      "rain",
      "snow",
      "temperature",
      "weather",
      "winter"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "weather_snowy",
    "version": 248,
    "popularity": 402,
    "codepoint": 58061,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "climate",
      "cloud",
      "home",
      "nest",
      "snow",
      "thermostat",
      "weather"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "web",
    "version": 248,
    "popularity": 3948,
    "codepoint": 57425,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "browser",
      "internet",
      "page",
      "screen",
      "site",
      "web",
      "website",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "web",
    "version": 13,
    "popularity": 16902,
    "codepoint": 57425,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "browser",
      "internet",
      "page",
      "screen",
      "site",
      "web",
      "website",
      "www"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "web_asset",
    "version": 248,
    "popularity": 2257,
    "codepoint": 57449,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "-website",
      "app",
      "application desktop",
      "asset",
      "browser",
      "design",
      "download",
      "image",
      "interface",
      "internet",
      "layout",
      "screen",
      "site",
      "ui",
      "ux",
      "video",
      "web",
      "website",
      "window",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "web_asset",
    "version": 12,
    "popularity": 8375,
    "codepoint": 57449,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "-website",
      "app",
      "application desktop",
      "asset",
      "browser",
      "design",
      "download",
      "image",
      "interface",
      "internet",
      "layout",
      "screen",
      "site",
      "ui",
      "ux",
      "video",
      "web",
      "website",
      "window",
      "www"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "web_asset_off",
    "version": 248,
    "popularity": 394,
    "codepoint": 58615,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "asset",
      "browser",
      "disabled",
      "enabled",
      "internet",
      "off",
      "on",
      "page",
      "screen",
      "slash",
      "web",
      "webpage",
      "website",
      "windows",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "web_asset_off",
    "version": 4,
    "popularity": 1447,
    "codepoint": 58615,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "av"
    ],
    "tags": [
      "asset",
      "browser",
      "disabled",
      "enabled",
      "internet",
      "off",
      "on",
      "page",
      "screen",
      "slash",
      "web",
      "webpage",
      "website",
      "windows",
      "www"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "web_stories",
    "version": 248,
    "popularity": 781,
    "codepoint": 58773,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Photo&Image"
    ],
    "tags": [
      "google",
      "images",
      "logo",
      "stories",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "web_stories",
    "version": 4,
    "popularity": 2257,
    "codepoint": 58773,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "google",
      "images",
      "logo",
      "stories",
      "web"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "web_traffic",
    "version": 248,
    "popularity": 396,
    "codepoint": 59907,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "browser",
      "click",
      "clicks",
      "cursor",
      "internet",
      "traffic",
      "web"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "webhook",
    "version": 248,
    "popularity": 1221,
    "codepoint": 60306,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "api",
      "developer",
      "development",
      "enterprise",
      "software",
      "webhook"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "webhook",
    "version": 1,
    "popularity": 4686,
    "codepoint": 60306,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "api",
      "developer",
      "development",
      "enterprise",
      "software",
      "webhook"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "weekend",
    "version": 248,
    "popularity": 809,
    "codepoint": 57707,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "chair",
      "couch",
      "furniture",
      "home",
      "living",
      "lounge",
      "relax",
      "room",
      "weekend"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "weekend",
    "version": 12,
    "popularity": 6103,
    "codepoint": 57707,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "chair",
      "couch",
      "furniture",
      "home",
      "living",
      "lounge",
      "relax",
      "room",
      "weekend"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "weight",
    "version": 248,
    "popularity": 1025,
    "codepoint": 57661,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "grams",
      "kg",
      "lbs",
      "measure",
      "pounds",
      "stones",
      "tonne"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "west",
    "version": 248,
    "popularity": 1981,
    "codepoint": 61926,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "directional",
      "left",
      "maps",
      "navigation",
      "west"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "west",
    "version": 7,
    "popularity": 0,
    "codepoint": 61926,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "navigation"
    ],
    "tags": [
      "arrow",
      "directional",
      "left",
      "maps",
      "navigation",
      "west"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "whatshot",
    "version": 248,
    "popularity": 1453,
    "codepoint": 59406,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "fire",
      "frames",
      "hot",
      "round",
      "whatshot"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "whatshot",
    "version": 17,
    "popularity": 15386,
    "codepoint": 59406,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "arrow",
      "circle",
      "direction",
      "fire",
      "frames",
      "hot",
      "round",
      "whatshot"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wheelchair_pickup",
    "version": 248,
    "popularity": 332,
    "codepoint": 61867,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "person",
      "pickup",
      "wheelchair"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wheelchair_pickup",
    "version": 8,
    "popularity": 2151,
    "codepoint": 61867,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "places"
    ],
    "tags": [
      "accessibility",
      "accessible",
      "body",
      "handicap",
      "help",
      "human",
      "person",
      "pickup",
      "wheelchair"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "where_to_vote",
    "version": 248,
    "popularity": 5319,
    "codepoint": 57719,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "approve",
      "ballot",
      "check",
      "complete",
      "destination",
      "direction",
      "done",
      "location",
      "maps",
      "mark",
      "ok",
      "pin",
      "place",
      "poll",
      "select",
      "stop",
      "tick",
      "to",
      "validate election",
      "verified",
      "vote",
      "where",
      "yes"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "where_to_vote",
    "version": 16,
    "popularity": 9006,
    "codepoint": 57719,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "content"
    ],
    "tags": [
      "approve",
      "ballot",
      "check",
      "complete",
      "destination",
      "direction",
      "done",
      "location",
      "maps",
      "mark",
      "ok",
      "pin",
      "place",
      "poll",
      "select",
      "stop",
      "tick",
      "to",
      "validate election",
      "verified",
      "vote",
      "where",
      "yes"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "widgets",
    "version": 248,
    "popularity": 4442,
    "codepoint": 57789,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "app",
      "box",
      "menu",
      "setting",
      "squares",
      "ui",
      "widgets"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "widgets",
    "version": 13,
    "popularity": 27711,
    "codepoint": 57789,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "app",
      "box",
      "menu",
      "setting",
      "squares",
      "ui",
      "widgets"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "width",
    "version": 248,
    "popularity": 63,
    "codepoint": 63280,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow",
      "arrows",
      "double",
      "expand",
      "resize",
      "size"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "width_full",
    "version": 248,
    "popularity": 754,
    "codepoint": 63733,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "width_full",
    "version": 1,
    "popularity": 906,
    "codepoint": 63733,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "width_normal",
    "version": 248,
    "popularity": 822,
    "codepoint": 63734,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "width_normal",
    "version": 1,
    "popularity": 0,
    "codepoint": 63734,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "width_wide",
    "version": 248,
    "popularity": 599,
    "codepoint": 63735,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "width_wide",
    "version": 1,
    "popularity": 749,
    "codepoint": 63735,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi",
    "version": 248,
    "popularity": 10490,
    "codepoint": 58942,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "connection",
      "data",
      "internet",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi",
    "version": 12,
    "popularity": 50406,
    "codepoint": 58942,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "connection",
      "data",
      "internet",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wifi_1_bar",
    "version": 248,
    "popularity": 191,
    "codepoint": 58570,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_1_bar",
    "version": 1,
    "popularity": 866,
    "codepoint": 58570,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "bar",
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_2_bar",
    "version": 248,
    "popularity": 253,
    "codepoint": 58585,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_2_bar",
    "version": 1,
    "popularity": 983,
    "codepoint": 58585,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "bar",
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_add",
    "version": 248,
    "popularity": 5,
    "codepoint": 63400,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "+",
      "add",
      "connection",
      "data",
      "internet",
      "network",
      "new symbol",
      "plus",
      "scan",
      "service",
      "signal",
      "symbol",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_calling",
    "version": 248,
    "popularity": 267,
    "codepoint": 61303,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "call",
      "calling",
      "cell",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "signal",
      "telephone",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_calling",
    "version": 11,
    "popularity": 1750,
    "codepoint": 61303,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "communication"
    ],
    "tags": [
      "call",
      "calling",
      "cell",
      "connect",
      "connection",
      "connectivity",
      "contact",
      "device",
      "hardware",
      "mobile",
      "phone",
      "signal",
      "telephone",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_calling_1",
    "version": 248,
    "popularity": 258,
    "codepoint": 61671,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "1",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_calling_1",
    "version": 10,
    "popularity": 1,
    "codepoint": 61671,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "1",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wifi_calling_2",
    "version": 248,
    "popularity": 219,
    "codepoint": 61686,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "2",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_calling_2",
    "version": 10,
    "popularity": 3,
    "codepoint": 61686,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "2",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wifi_calling_3",
    "version": 248,
    "popularity": 334,
    "codepoint": 61573,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "3",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_calling_3",
    "version": 10,
    "popularity": 2671,
    "codepoint": 61573,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "3",
      "calling",
      "cell",
      "cellular",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "speed",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wifi_channel",
    "version": 248,
    "popularity": 332,
    "codepoint": 60266,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "cellular",
      "channel",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_channel",
    "version": 1,
    "popularity": 852,
    "codepoint": 60266,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cellular",
      "channel",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_find",
    "version": 248,
    "popularity": 575,
    "codepoint": 60209,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "detect",
      "discover",
      "find",
      "internet",
      "look",
      "magnifying glass",
      "mobile",
      "network",
      "notice",
      "scan",
      "search",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_find",
    "version": 1,
    "popularity": 1216,
    "codepoint": 60209,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "detect",
      "discover",
      "find",
      "internet",
      "look",
      "magnifying glass",
      "mobile",
      "network",
      "notice",
      "scan",
      "search",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_home",
    "version": 248,
    "popularity": 4,
    "codepoint": 63089,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "architecture",
      "building",
      "connection",
      "data",
      "estate",
      "home",
      "house",
      "internet",
      "location",
      "network",
      "place",
      "residence",
      "residential",
      "scan",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_lock",
    "version": 248,
    "popularity": 332,
    "codepoint": 57825,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_lock",
    "version": 13,
    "popularity": 1785,
    "codepoint": 57825,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "internet",
      "lock",
      "locked",
      "mobile",
      "network",
      "password",
      "privacy",
      "private",
      "protection",
      "safety",
      "secure",
      "security",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_notification",
    "version": 248,
    "popularity": 3,
    "codepoint": 63088,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "active",
      "alarm",
      "alert",
      "bell",
      "chime",
      "connection",
      "data",
      "internet",
      "network",
      "notifications",
      "notify",
      "reminder",
      "ring",
      "scan",
      "service",
      "signal",
      "sound",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_off",
    "version": 248,
    "popularity": 2328,
    "codepoint": 58952,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "scan",
      "service",
      "signal",
      "slash",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_off",
    "version": 12,
    "popularity": 13623,
    "codepoint": 58952,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "notification"
    ],
    "tags": [
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "network",
      "off",
      "offline",
      "on",
      "scan",
      "service",
      "signal",
      "slash",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wifi_password",
    "version": 248,
    "popularity": 452,
    "codepoint": 60267,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Privacy&Security"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "internet",
      "lock",
      "mobile",
      "network",
      "password",
      "scan",
      "secure",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_password",
    "version": 1,
    "popularity": 1515,
    "codepoint": 60267,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cellular",
      "connection",
      "data",
      "internet",
      "lock",
      "mobile",
      "network",
      "password",
      "scan",
      "secure",
      "service",
      "signal",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_protected_setup",
    "version": 248,
    "popularity": 759,
    "codepoint": 61692,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "protected",
      "rotate",
      "setup",
      "wifi"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_protected_setup",
    "version": 12,
    "popularity": 5452,
    "codepoint": 61692,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "around",
      "arrow",
      "arrows",
      "protected",
      "rotate",
      "setup",
      "wifi"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_proxy",
    "version": 248,
    "popularity": 4,
    "codepoint": 63399,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Communication"
    ],
    "tags": [
      "apartment",
      "architecture",
      "building",
      "business",
      "connection",
      "data",
      "domain",
      "estate",
      "home",
      "internet",
      "network",
      "place",
      "real",
      "residence",
      "residential",
      "scan",
      "service",
      "shelter",
      "signal",
      "web",
      "wifi",
      "wireless",
      "www"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_tethering",
    "version": 248,
    "popularity": 892,
    "codepoint": 57826,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "speed",
      "tethering",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_tethering",
    "version": 12,
    "popularity": 6229,
    "codepoint": 57826,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "connection",
      "data",
      "internet",
      "mobile",
      "network",
      "phone",
      "scan",
      "service",
      "signal",
      "speed",
      "tethering",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wifi_tethering_error",
    "version": 248,
    "popularity": 263,
    "codepoint": 60121,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connection",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "notification",
      "phone",
      "rounded",
      "scan",
      "service",
      "signal",
      "speed",
      "symbol",
      "tethering",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_tethering_error",
    "version": 3,
    "popularity": 946,
    "codepoint": 60121,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "caution",
      "cell",
      "cellular",
      "connection",
      "danger",
      "data",
      "error",
      "exclamation",
      "important",
      "internet",
      "mark",
      "mobile",
      "network",
      "notification",
      "phone",
      "rounded",
      "scan",
      "service",
      "signal",
      "speed",
      "symbol",
      "tethering",
      "warning",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wifi_tethering_off",
    "version": 248,
    "popularity": 194,
    "codepoint": 61575,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Android"
    ],
    "tags": [
      "cell",
      "cellular",
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "scan",
      "service",
      "signal",
      "slash",
      "speed",
      "tethering",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wifi_tethering_off",
    "version": 10,
    "popularity": 1443,
    "codepoint": 61575,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "device"
    ],
    "tags": [
      "cell",
      "cellular",
      "connection",
      "data",
      "disabled",
      "enabled",
      "internet",
      "mobile",
      "network",
      "off",
      "offline",
      "on",
      "phone",
      "scan",
      "service",
      "signal",
      "slash",
      "speed",
      "tethering",
      "wifi",
      "wireless"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wind_power",
    "version": 248,
    "popularity": 1381,
    "codepoint": 60428,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "eco",
      "energy",
      "nest",
      "power",
      "wind",
      "windy"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wind_power",
    "version": 1,
    "popularity": 1980,
    "codepoint": 60428,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "home"
    ],
    "tags": [
      "eco",
      "energy",
      "nest",
      "power",
      "wind",
      "windy"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "window",
    "version": 248,
    "popularity": 1097,
    "codepoint": 61576,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "close",
      "glass",
      "grid",
      "home",
      "house",
      "interior",
      "layout",
      "outside",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "window",
    "version": 10,
    "popularity": 7398,
    "codepoint": 61576,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "close",
      "glass",
      "grid",
      "home",
      "house",
      "interior",
      "layout",
      "outside",
      "window"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "window_closed",
    "version": 248,
    "popularity": 178,
    "codepoint": 59262,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "closed",
      "home",
      "nest",
      "security",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "window_open",
    "version": 248,
    "popularity": 164,
    "codepoint": 59276,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "nest",
      "open",
      "security",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "window_sensor",
    "version": 248,
    "popularity": 110,
    "codepoint": 58043,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "home",
      "nest",
      "security",
      "sensor",
      "window"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wine_bar",
    "version": 248,
    "popularity": 1301,
    "codepoint": 61928,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "alcohol",
      "bar",
      "cocktail",
      "cup",
      "drink",
      "glass",
      "liquor",
      "wine"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wine_bar",
    "version": 6,
    "popularity": 5768,
    "codepoint": 61928,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "alcohol",
      "bar",
      "cocktail",
      "cup",
      "drink",
      "glass",
      "liquor",
      "wine"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "woman",
    "version": 248,
    "popularity": 2025,
    "codepoint": 57662,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "woman",
    "version": 2,
    "popularity": 5508,
    "codepoint": 57662,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "woman_2",
    "version": 248,
    "popularity": 495,
    "codepoint": 63719,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "woman_2",
    "version": 1,
    "popularity": 746,
    "codepoint": 63719,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "female",
      "gender",
      "girl",
      "lady",
      "social",
      "symbol",
      "woman",
      "women"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "work",
    "version": 248,
    "popularity": 10629,
    "codepoint": 59641,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "job",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "work",
    "version": 12,
    "popularity": 64971,
    "codepoint": 59641,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "job",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "work_alert",
    "version": 248,
    "popularity": 10,
    "codepoint": 62967,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "!",
      "alert",
      "attention",
      "bag",
      "baggage",
      "borg",
      "briefcase",
      "business",
      "case",
      "caution",
      "danger",
      "error",
      "exclamation",
      "important",
      "job",
      "mark",
      "notification",
      "suitcase",
      "symbol",
      "warning",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "work_history",
    "version": 248,
    "popularity": 3153,
    "codepoint": 60425,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "back",
      "backwards",
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "clock",
      "date",
      "history",
      "job",
      "pending",
      "recent",
      "schedule",
      "suitcase",
      "time",
      "updates",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "work_history",
    "version": 1,
    "popularity": 5496,
    "codepoint": 60425,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "back",
      "backwards",
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "clock",
      "date",
      "history",
      "job",
      "pending",
      "recent",
      "schedule",
      "suitcase",
      "time",
      "updates",
      "work"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "work_off",
    "version": 12,
    "popularity": 3494,
    "codepoint": 59714,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "disabled",
      "enabled",
      "job",
      "off",
      "on",
      "slash",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "work_outline",
    "version": 12,
    "popularity": 32658,
    "codepoint": 59715,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "bag",
      "baggage",
      "briefcase",
      "business",
      "case",
      "job",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "work_update",
    "version": 248,
    "popularity": 8,
    "codepoint": 62968,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Business&Payments"
    ],
    "tags": [
      "arrow",
      "arrows",
      "bag",
      "baggage",
      "borg",
      "briefcase",
      "business",
      "case",
      "down",
      "download",
      "install",
      "job",
      "suitcase",
      "work"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "workspace_premium",
    "version": 248,
    "popularity": 8455,
    "codepoint": 59311,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "certification",
      "degree",
      "ecommerce",
      "guarantee",
      "medal",
      "permit",
      "premium",
      "ribbon",
      "verification",
      "workspace"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "workspace_premium",
    "version": 3,
    "popularity": 27129,
    "codepoint": 59311,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "social"
    ],
    "tags": [
      "certification",
      "degree",
      "ecommerce",
      "guarantee",
      "medal",
      "permit",
      "premium",
      "ribbon",
      "verification",
      "workspace"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "workspaces",
    "version": 248,
    "popularity": 3271,
    "codepoint": 57760,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "circles",
      "collaboration",
      "dot",
      "filled",
      "group",
      "outline",
      "space",
      "team",
      "work",
      "workspaces"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "workspaces",
    "version": 9,
    "popularity": 10374,
    "codepoint": 57760,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "circles",
      "collaboration",
      "dot",
      "filled",
      "group",
      "outline",
      "space",
      "team",
      "work",
      "workspaces"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "workspaces_filled",
    "version": 13,
    "popularity": 1374,
    "codepoint": 59917,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "circles",
      "collaboration",
      "dot",
      "filled",
      "group",
      "space",
      "team",
      "work",
      "workspaces"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "workspaces_outline",
    "version": 13,
    "popularity": 1120,
    "codepoint": 59919,
    "unsupported_families": [
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone",
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "file"
    ],
    "tags": [
      "circles",
      "collaboration",
      "dot",
      "group",
      "outline",
      "space",
      "team",
      "work",
      "workspaces"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wounds_injuries",
    "version": 248,
    "popularity": 5,
    "codepoint": 57663,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "health",
      "medical",
      "staples",
      "stitch",
      "stitches"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wrap_text",
    "version": 248,
    "popularity": 221,
    "codepoint": 57947,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Text Formatting"
    ],
    "tags": [
      "arrow writing",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "wrap",
      "write",
      "writing"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wrap_text",
    "version": 13,
    "popularity": 1654,
    "codepoint": 57947,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "editor"
    ],
    "tags": [
      "arrow writing",
      "doc",
      "edit",
      "editing",
      "editor",
      "sheet",
      "spreadsheet",
      "text",
      "type",
      "wrap",
      "write",
      "writing"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "wrist",
    "version": 248,
    "popularity": 27,
    "codepoint": 63132,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Social"
    ],
    "tags": [
      "device",
      "hand",
      "tracker",
      "watch"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wrong_location",
    "version": 248,
    "popularity": 790,
    "codepoint": 61304,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "destination",
      "direction",
      "exit",
      "location",
      "maps",
      "no",
      "pin",
      "place",
      "quit",
      "remove",
      "stop",
      "wrong",
      "x"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wrong_location",
    "version": 17,
    "popularity": 3455,
    "codepoint": 61304,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "cancel",
      "clear",
      "close",
      "destination",
      "direction",
      "exit",
      "location",
      "maps",
      "no",
      "pin",
      "place",
      "quit",
      "remove",
      "stop",
      "wrong",
      "x"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "wysiwyg",
    "version": 248,
    "popularity": 2442,
    "codepoint": 61891,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "composer",
      "mode",
      "screen",
      "site",
      "software",
      "system",
      "text",
      "view",
      "visibility",
      "web",
      "website",
      "window",
      "wysiwyg"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "wysiwyg",
    "version": 8,
    "popularity": 15464,
    "codepoint": 61891,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "composer",
      "mode",
      "screen",
      "site",
      "software",
      "system",
      "text",
      "view",
      "visibility",
      "web",
      "website",
      "window",
      "wysiwyg"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "yard",
    "version": 248,
    "popularity": 1311,
    "codepoint": 61577,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Household"
    ],
    "tags": [
      "backyard",
      "flower",
      "garden",
      "home",
      "house",
      "nature",
      "pettle",
      "plants",
      "yard"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "yard",
    "version": 9,
    "popularity": 9698,
    "codepoint": 61577,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "search"
    ],
    "tags": [
      "backyard",
      "flower",
      "garden",
      "home",
      "house",
      "nature",
      "pettle",
      "plants",
      "yard"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "your_trips",
    "version": 248,
    "popularity": 7,
    "codepoint": 60203,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Travel"
    ],
    "tags": [
      "back",
      "backpack",
      "bag",
      "book",
      "bookbag",
      "pack",
      "personal",
      "star",
      "storage",
      "travel",
      "trips",
      "your"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "youtube_activity",
    "version": 248,
    "popularity": 502,
    "codepoint": 63578,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Common actions"
    ],
    "tags": [
      "activity",
      "add",
      "plus",
      "youtube"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "youtube_searched_for",
    "version": 248,
    "popularity": 1524,
    "codepoint": 59642,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "find",
      "glass",
      "history",
      "inprogress",
      "load",
      "loading",
      "look",
      "magnify",
      "magnifying",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "search",
      "see",
      "youtube"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "youtube_searched_for",
    "version": 12,
    "popularity": 9849,
    "codepoint": 59642,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "arrow",
      "back",
      "backwards",
      "find",
      "glass",
      "history",
      "inprogress",
      "load",
      "loading",
      "look",
      "magnify",
      "magnifying",
      "refresh",
      "renew",
      "restore",
      "reverse",
      "rotate",
      "search",
      "see",
      "youtube"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "zone_person_alert",
    "version": 248,
    "popularity": 157,
    "codepoint": 59265,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "activity",
      "alert",
      "home",
      "nest",
      "person",
      "security",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "zone_person_idle",
    "version": 248,
    "popularity": 103,
    "codepoint": 59258,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "activity",
      "home",
      "idle",
      "nest",
      "person",
      "security",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "zone_person_urgent",
    "version": 248,
    "popularity": 206,
    "codepoint": 59272,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Home"
    ],
    "tags": [
      "activity",
      "home",
      "nest",
      "person",
      "security",
      "urgent",
      "zone"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "zoom_in",
    "version": 248,
    "popularity": 7357,
    "codepoint": 59647,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "big",
      "bigger",
      "find",
      "glass",
      "grow",
      "in",
      "look",
      "magnify",
      "magnifying",
      "plus",
      "scale",
      "search",
      "see",
      "size",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "zoom_in",
    "version": 11,
    "popularity": 40827,
    "codepoint": 59647,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "big",
      "bigger",
      "find",
      "glass",
      "grow",
      "in",
      "look",
      "magnify",
      "magnifying",
      "plus",
      "scale",
      "search",
      "see",
      "size",
      "zoom"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "zoom_in_map",
    "version": 248,
    "popularity": 998,
    "codepoint": 60205,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "destination",
      "in",
      "location",
      "maps",
      "move",
      "place",
      "stop",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "zoom_in_map",
    "version": 1,
    "popularity": 3913,
    "codepoint": 60205,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "destination",
      "in",
      "location",
      "maps",
      "move",
      "place",
      "stop",
      "zoom"
    ],
    "sizes_px": [
      20,
      24
    ]
  },
  {
    "name": "zoom_out",
    "version": 248,
    "popularity": 3247,
    "codepoint": 59648,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "UI actions"
    ],
    "tags": [
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "minus",
      "negative",
      "out",
      "scale",
      "search",
      "see",
      "size",
      "small",
      "smaller",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "zoom_out",
    "version": 11,
    "popularity": 16470,
    "codepoint": 59648,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "action"
    ],
    "tags": [
      "find",
      "glass",
      "look",
      "magnify",
      "magnifying",
      "minus",
      "negative",
      "out",
      "scale",
      "search",
      "see",
      "size",
      "small",
      "smaller",
      "zoom"
    ],
    "sizes_px": [
      24
    ]
  },
  {
    "name": "zoom_out_map",
    "version": 248,
    "popularity": 2181,
    "codepoint": 58731,
    "unsupported_families": [
      "Material Icons",
      "Material Icons Outlined",
      "Material Icons Round",
      "Material Icons Sharp",
      "Material Icons Two Tone"
    ],
    "categories": [
      "Maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "destination",
      "location",
      "maps",
      "move",
      "out",
      "place",
      "stop",
      "zoom"
    ],
    "sizes_px": [
      20,
      24,
      40,
      48
    ]
  },
  {
    "name": "zoom_out_map",
    "version": 17,
    "popularity": 13898,
    "codepoint": 58731,
    "unsupported_families": [
      "Material Symbols Outlined",
      "Material Symbols Rounded",
      "Material Symbols Sharp"
    ],
    "categories": [
      "maps"
    ],
    "tags": [
      "arrow",
      "arrows",
      "destination",
      "location",
      "maps",
      "move",
      "out",
      "place",
      "stop",
      "zoom"
    ],
    "sizes_px": [
      24
    ]
  }
]