import { InventoryMethodTypes } from '../entities/enums';
import { TimeSlotSettingDto } from './timeSlotSetting.dto';
export class ScheduleSettingDto {
  id: string;
  venueId: string;
  title: string;
  start: string;
  end: string;
  priority: number;
  inventoryMethod: InventoryMethodTypes;
  timeSlots: TimeSlotSettingDto[];
  startTimeOnly: string;
}

export class CreateScheduleSettingDto {
  venueId: string;
  title: string;
  start: string;
  end: string;
  priority: number;
  inventoryMethod: InventoryMethodTypes;
  timeSlots: TimeSlotSettingDto[];
  startTimeOnly: string;
}