import { Toggle, Icon, IDropdownOption } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import FormTextField from "../../../../../common/components/FormTextField";
import FormSelectField from "../../common/FormSelectField";
import { openPopup } from "../../../../../common/utils/popup";
import {
  disconnectMailchimpAction,
  disconnectSquareAction,
  getMailchimpOAuth2UrlAction,
  getReportSettingsAction,
  getSettingsAction,
  getSquareOAuth2UrlAction,
  saveReportSettingsAction,
  updateSettingsAction,
} from "../../../actions/settings-actions";
import { useViewport } from "../../../hooks/responsive";
import { selectReadonly } from "../../../reducers/auth-reducer";
import {
  selectDynamicBodyScripts,
  selectDynamicScripts,
  selectIsSettingsRequestInProgress,
  selectMailgunAdminEmail,
  selectMailgunDomain,
  selectMailgunKey,
  selectMchApiKey,
  selectMchAudienceId,
  selectPosApiAuth,
  selectPosApiKey,
  selectPosApiUrl,
  selectQsrAccessKey,
  selectQsrSecretKey,
  selectReportSettings,
  selectSeatNinjaApiKey,
  selectSeatNinjaUrl,
  selectSettingsState,
  selectSevenRoomsId,
  selectSevenRoomsSecret,
  selectSfEmail,
  selectSfPassword,
  selectSfSecureToken,
  selectSftpName,
  selectSftpPassword,
  selectSftpUrl,
  selectSftpUseAllDataReport,
  selectTripleSeatAccountId,
  selectTripleSeatCFLanesCount,
  selectTripleSeatContactId,
  selectTripleSeatEventTypeId,
  selectTripleSeatFormId,
  selectTripleSeatKey,
  selectTripleSeatLeadSourceId,
  selectTripleSeatOwnerId,
  selectTripleSeatPublicKey,
  selectTripleSeatReservationUrl,
  selectTripleSeatSecret,
  selectTripleSeatSiteId,
} from "../../../reducers/settings";
import {
  ReportDeliveryType,
  ReportScope,
  ReportType,
  State,
} from "../../../store/types";
import IntegrationButtonBlock from "../../IntegrationButtonBlock";
import ModalConfirm from "../../ModalConfirm";
import Panel from "../../common/Panel";

import deleteIcon from "../../../assets/delete.svg";
import MailchimpLogo from "../../../assets/mailchimp-logo.svg";
import SquareLogo from "../../../assets/square-logo.png";
import { timeZones } from "../../../constants/timeZones";
import "./integrationsSettings.scss";

const minutesOptions = _.times(48).map((d, index) => ({
  key: index * 30,
  text: `${Math.floor(index / 2)}:${index % 2 ? "30" : "00"}`,
}));
const reportTypeOptions = [
  {
    key: ReportType.ManagersReport,
    text: "Managers Report",
  },
  {
    key: ReportType.TaxReport,
    text: "Tax Report",
  },
  {
    key: ReportType.RevenueExport,
    text: "Revenue Export",
  },
  {
    key: ReportType.ReservationWithTransactions,
    text: "Reservation with Transactions",
  },
];
const reportScopeOptions = [
  {
    key: ReportScope.prevDayReservations,
    text: "Previous Day",
  },
  {
    key: ReportScope.allReservations,
    text: "All time",
  },
];
const timeZoneOptions = timeZones.map((key) => ({
  key,
  text: key,
}));

const mapDispatchToProps = {
  getSettings: getSettingsAction,
  updateSettings: updateSettingsAction,
  disconnectSquare: disconnectSquareAction,
  getSquareOAuth2Url: getSquareOAuth2UrlAction,
  getMailchimpOAuth2Url: getMailchimpOAuth2UrlAction,
  disconnectMailchimp: disconnectMailchimpAction,
  getReportSettings: getReportSettingsAction,
  saveReportSettings: saveReportSettingsAction,
};

const mapStateToProps = (state: State) => ({
  settings: selectSettingsState(state),
  readonly: selectReadonly(state),
  mchAudienceId: selectMchAudienceId(state),
  mchApiKey: selectMchApiKey(state),
  tripleSeatKey: selectTripleSeatKey(state),
  tripleSeatSecret: selectTripleSeatSecret(state),
  tripleSeatPublicKey: selectTripleSeatPublicKey(state),
  tripleSeatFormId: selectTripleSeatFormId(state),
  tripleSeatOwnerId: selectTripleSeatOwnerId(state),
  tripleSeatSiteId: selectTripleSeatSiteId(state),
  tripleSeatAccountId: selectTripleSeatAccountId(state),
  tripleSeatContactId: selectTripleSeatContactId(state),
  tripleSeatEventTypeId: selectTripleSeatEventTypeId(state),
  tripleSeatLeadSourceId: selectTripleSeatLeadSourceId(state),
  tripleSeatCFLanesCount: selectTripleSeatCFLanesCount(state),
  tripleSeatReservationUrl: selectTripleSeatReservationUrl(state),
  sfEmail: selectSfEmail(state),
  sfPassword: selectSfPassword(state),
  sfSecureToken: selectSfSecureToken(state),
  posApiKey: selectPosApiKey(state),
  posApiAuth: selectPosApiAuth(state),
  posApiUrl: selectPosApiUrl(state),
  mailgunKey: selectMailgunKey(state),
  mailgunDomain: selectMailgunDomain(state),
  mailgunAdminEmail: selectMailgunAdminEmail(state),
  dynamicScripts: selectDynamicScripts(state),
  qsrAccessKey: selectQsrAccessKey(state),
  qsrSecretKey: selectQsrSecretKey(state),
  sevenRoomsId: selectSevenRoomsId(state),
  sevenRoomsSecret: selectSevenRoomsSecret(state),
  seatNinjaUrl: selectSeatNinjaUrl(state),
  seatNinjaApiKey: selectSeatNinjaApiKey(state),
  sftpUrl: selectSftpUrl(state),
  sftpName: selectSftpName(state),
  sftpUseAllDataReport: selectSftpUseAllDataReport(state),
  sftpPassword: selectSftpPassword(state),
  dynamicBodyScripts: selectDynamicBodyScripts(state),
  isRequestInProgress: selectIsSettingsRequestInProgress(state),
  reportSettings: selectReportSettings(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const IntegrationsSettings = ({
  getSettings,
  updateSettings,
  disconnectSquare,
  getSquareOAuth2Url,
  getMailchimpOAuth2Url,
  disconnectMailchimp,
  getReportSettings,
  saveReportSettings,
  settings,
  reportSettings,
  readonly,
  mchAudienceId,
  mchApiKey,
  tripleSeatKey,
  tripleSeatSecret,
  tripleSeatPublicKey,
  tripleSeatFormId,
  tripleSeatOwnerId,
  tripleSeatSiteId,
  tripleSeatAccountId,
  tripleSeatContactId,
  tripleSeatEventTypeId,
  tripleSeatLeadSourceId,
  tripleSeatCFLanesCount,
  tripleSeatReservationUrl,
  sfEmail,
  sfPassword,
  sfSecureToken,
  posApiKey,
  posApiAuth,
  posApiUrl,
  mailgunKey,
  mailgunDomain,
  mailgunAdminEmail,
  dynamicScripts,
  qsrAccessKey,
  qsrSecretKey,
  sevenRoomsId,
  sevenRoomsSecret,
  seatNinjaUrl,
  seatNinjaApiKey,
  sftpUrl,
  sftpName,
  sftpUseAllDataReport,
  sftpPassword,
  dynamicBodyScripts,
  isRequestInProgress,
}: Props) => {
  useEffect(() => {
    getSettings();
    getReportSettings();
  }, [getSettings]);
  useEffect(() => {
    setMailChimpId(mchAudienceId);
  }, [mchAudienceId]);
  useEffect(() => {
    setMailChimpKey(mchApiKey);
  }, [mchApiKey]);
  useEffect(() => {
    setTsKey(tripleSeatKey);
  }, [tripleSeatKey]);
  useEffect(() => {
    setTsSecret(tripleSeatSecret);
  }, [tripleSeatSecret]);
  useEffect(() => {
    setTsPublicKey(tripleSeatPublicKey);
  }, [tripleSeatPublicKey]);
  useEffect(() => {
    setTsFormId(tripleSeatFormId);
  }, [tripleSeatFormId]);
  useEffect(() => {
    setTsOwnerId(tripleSeatOwnerId);
  }, [tripleSeatOwnerId]);
  useEffect(() => {
    setTsSiteId(tripleSeatSiteId);
  }, [tripleSeatSiteId]);
  useEffect(() => {
    setTsAccountId(tripleSeatAccountId);
  }, [tripleSeatAccountId]);
  useEffect(() => {
    setTsContactId(tripleSeatContactId);
  }, [tripleSeatContactId]);
  useEffect(() => {
    setTsEventTypeId(tripleSeatEventTypeId);
  }, [tripleSeatEventTypeId]);
  useEffect(() => {
    setTsLeadSourceId(tripleSeatLeadSourceId);
  }, [tripleSeatLeadSourceId]);
  useEffect(() => {
    setTsCFLanesCount(tripleSeatCFLanesCount);
  }, [tripleSeatCFLanesCount]);
  useEffect(() => {
    setTsReservationUrl(tripleSeatReservationUrl);
  }, [tripleSeatReservationUrl]);
  useEffect(() => {
    setSalesForceEmail(sfEmail);
  }, [sfEmail]);
  useEffect(() => {
    setSalesForcePassword(sfPassword);
  }, [sfPassword]);
  useEffect(() => {
    setSalesForceToken(sfSecureToken);
  }, [sfSecureToken]);
  useEffect(() => {
    setPosKey(posApiKey);
  }, [posApiKey]);
  useEffect(() => {
    setPosAuth(posApiAuth);
  }, [posApiAuth]);
  useEffect(() => {
    setPosUrl(posApiUrl);
  }, [posApiUrl]);
  useEffect(() => {
    setMgKey(mailgunKey);
  }, [mailgunKey]);
  useEffect(() => {
    setMgDomain(mailgunDomain);
  }, [mailgunDomain]);
  useEffect(() => {
    setMgAdminEmail(mailgunAdminEmail);
  }, [mailgunAdminEmail]);
  useEffect(() => {
    setHeaderScripts(dynamicScripts);
  }, [dynamicScripts]);
  useEffect(() => {
    setQAccessKey(qsrAccessKey);
  }, [qsrAccessKey]);
  useEffect(() => {
    setQSecretKey(qsrSecretKey);
  }, [qsrSecretKey]);
  useEffect(() => {
    setSevenRoomsId(sevenRoomsId);
  }, [sevenRoomsId]);
  useEffect(() => {
    setSevenRoomsSecret(sevenRoomsSecret);
  }, [sevenRoomsSecret]);
  useEffect(() => {
    setSeatninjaUrl(seatNinjaUrl);
  }, [seatNinjaUrl]);
  useEffect(() => {
    setSeatninjaApiKey(seatNinjaApiKey);
  }, [seatNinjaApiKey]);
  useEffect(() => {
    setReportingSftpUrl(sftpUrl);
  }, [sftpUrl]);
  useEffect(() => {
    setReportingSftpName(sftpName);
  }, [sftpName]);
  useEffect(() => {
    setReportingSftpUseAllDataReport(sftpUseAllDataReport);
  }, [sftpUseAllDataReport]);
  useEffect(() => {
    setReportingSftpPassword(sftpPassword);
  }, [sftpPassword]);
  useEffect(() => {
    setBodyScripts(dynamicBodyScripts);
  }, [dynamicBodyScripts]);
  useEffect(() => {
    setReportSettingItems(reportSettings);
  }, [reportSettings]);
  useEffect(() => {
    setEnableTwilioIntegration(settings?.enableTwilioIntegration);
    setTwilioAccountId(settings?.twilioAccountId);
    setTwilioAuthToken(settings?.twilioAuthToken);
    setTwilioApiKey(settings?.twilioApiKey);
    setTwilioApiSecret(settings?.twilioApiSecret);
    setTwilioServiceId(settings?.twilioServiceId);
    setTwilioChatServiceId(settings?.twilioChatServiceId);
    setConfirmationPageScript(settings?.confirmationPageScript);
  }, [settings]);

  const { isMiddleScreen } = useViewport();
  const [mailChimpId, setMailChimpId] = useState(mchAudienceId);
  const [mailChimpKey, setMailChimpKey] = useState(mchApiKey);
  const [tsKey, setTsKey] = useState(tripleSeatKey);
  const [tsSecret, setTsSecret] = useState(tripleSeatSecret);
  const [tsPublicKey, setTsPublicKey] = useState(tripleSeatPublicKey);
  const [tsFormId, setTsFormId] = useState(tripleSeatFormId);
  const [tsSiteId, setTsSiteId] = useState(tripleSeatSiteId);
  const [tsAccountId, setTsAccountId] = useState(tripleSeatAccountId);
  const [tsContactId, setTsContactId] = useState(tripleSeatContactId);
  const [tsOwnerId, setTsOwnerId] = useState(tripleSeatOwnerId);
  const [tsEventTypeId, setTsEventTypeId] = useState(tripleSeatEventTypeId);
  const [tsLeadSourceId, setTsLeadSourceId] = useState(tripleSeatLeadSourceId);
  const [tsCFLanesCount, setTsCFLanesCount] = useState(tripleSeatCFLanesCount);
  const [tsReservationUrl, setTsReservationUrl] = useState(
    tripleSeatReservationUrl
  );
  const [salesForceEmail, setSalesForceEmail] = useState(sfEmail);
  const [salesForcePassword, setSalesForcePassword] = useState(sfPassword);
  const [salesForceToken, setSalesForceToken] = useState(sfSecureToken);
  const [posKey, setPosKey] = useState(posApiKey);
  const [posAuth, setPosAuth] = useState(posApiAuth);
  const [posUrl, setPosUrl] = useState(posApiUrl);
  const [mgKey, setMgKey] = useState(mailgunKey);
  const [mgDomain, setMgDomain] = useState(mailgunDomain);
  const [mgAdminEmail, setMgAdminEmail] = useState(mailgunAdminEmail);
  const [qAccessKey, setQAccessKey] = useState(qsrAccessKey);
  const [qSecretKey, setQSecretKey] = useState(qsrSecretKey);
  const [nSevenRoomsId, setSevenRoomsId] = useState(sevenRoomsId);
  const [nSevenRoomsSecret, setSevenRoomsSecret] = useState(sevenRoomsSecret);
  const [seatninjaUrl, setSeatninjaUrl] = useState(seatNinjaUrl);
  const [seatninjaApiKey, setSeatninjaApiKey] = useState(seatNinjaApiKey);
  const [reportingSftpUrl, setReportingSftpUrl] = useState(sftpUrl);
  const [reportingSftpName, setReportingSftpName] = useState(sftpName);
  const [reportingSftpUseAllDataReport, setReportingSftpUseAllDataReport] =
    useState(sftpUseAllDataReport);
  const [reportingSftpPassword, setReportingSftpPassword] =
    useState(sftpPassword);
  const [headerScripts, setHeaderScripts] = useState(dynamicScripts);
  const [bodyScripts, setBodyScripts] = useState(dynamicBodyScripts);
  const [enableTwilioIntegration, setEnableTwilioIntegration] = useState(
    settings?.enableTwilioIntegration
  );
  const [twilioAccountId, setTwilioAccountId] = useState(
    settings?.twilioAccountId
  );
  const [twilioAuthToken, setTwilioAuthToken] = useState(
    settings?.twilioAuthToken
  );
  const [twilioApiKey, setTwilioApiKey] = useState(settings?.twilioApiKey);
  const [twilioApiSecret, setTwilioApiSecret] = useState(
    settings?.twilioApiSecret
  );
  const [twilioServiceId, setTwilioServiceId] = useState(
    settings?.twilioServiceId
  );

  const [twilioChatServiceId, setTwilioChatServiceId] = useState(
    settings?.twilioChatServiceId
  );

  const [confirmationPageScript, setConfirmationPageScript] = useState(
    settings?.confirmationPageScript
  );

  const isSquareConnected =
    Boolean(settings.squareIsEnable) ||
    Boolean(settings.squareAccessToken !== "") ||
    readonly;
  const isMailchimpConnected = Boolean(settings.mchIsEnable);
  const [isSquareDisconnectModalOpen, setIsSquareDisconnectModalOpen] =
    useState(false);
  const [reportSettingItems, setReportSettingItems] = useState(
    settings.reportSettings || []
  );

  const isStripeV2Connected = Boolean(
    settings.stripeAccountId && settings.stripeConnectionStatus === "ok"
  );

  const onUpdateMailChimp = () => {
    updateSettings({
      mchAudienceId: mailChimpId,
      mchApiKey: mailChimpKey,
    });
  };


  const onEnableMailChimp = async () => {
    const url = await getMailchimpOAuth2Url();
    openPopup(url, getSettings);
  };

  const onUpdateSquare = (_: any, value?: boolean) => {
    if (isSquareConnected) {
      updateSettings({ squareIsEnable: value });
    }
  };

  const handleDisconnectSquare = (isConfirm: boolean) => {
    if (isConfirm) {
      disconnectSquare();
    }
    setIsSquareDisconnectModalOpen(false);
  };

  const onEnableDisableSquare = async () => {
    try {
      if (isSquareConnected) {
        return setIsSquareDisconnectModalOpen(true);
      }
      const url = await getSquareOAuth2Url();
      openPopup(url, getSettings);
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdateTripleSeat = () => {
    updateSettings({
      tripleSeatKey: tsKey,
      tripleSeatSecret: tsSecret,
      tripleSeatPublicKey: tsPublicKey,
      tripleSeatFormId: tsFormId,
      tripleSeatOwnerId: tsOwnerId,
      tripleSeatSiteId: tsSiteId,
      tripleSeatAccountId: tsAccountId,
      tripleSeatContactId: tsContactId,
      tripleSeatEventTypeId: tsEventTypeId,
      tripleSeatLeadSourceId: tsLeadSourceId,
      tripleSeatCFLanesCount: tsCFLanesCount,
      tripleSeatReservationUrl: tsReservationUrl,
    });
  };
  const onUpdateSalesForce = () => {
    updateSettings({
      sfEmail: salesForceEmail,
      sfPassword: salesForcePassword,
      sfSecureToken: salesForceToken,
    });
  };
  const onUpdatePos = () => {
    updateSettings({
      posApiKey: posKey,
      posApiAuth: posAuth,
      posApiUrl: posUrl,
    });
  };
  const onUpdateMailgun = () => {
    updateSettings({
      mailgunKey: mgKey,
      mailgunDomain: mgDomain,
      mailgunAdminEmail: mgAdminEmail,
    });
  };
  const onUpdateQSR = () => {
    updateSettings({
      qsrAccessKey: qAccessKey,
      qsrSecretKey: qSecretKey,
    });
  };
  const onUpdateSevenRooms = () => {
    updateSettings({
      sevenRoomsId: nSevenRoomsId,
      sevenRoomsSecret: nSevenRoomsSecret,
    });
  };
  const onUpdateSeatninja = () => {
    updateSettings({
      seatNinjaUrl: seatninjaUrl,
      seatNinjaApiKey: seatninjaApiKey,
    });
  };
  const onUpdateReportingSftp = () => {
    updateSettings({
      sftpUrl: reportingSftpUrl,
      sftpName: reportingSftpName,
      sftpPassword: reportingSftpPassword,
      sftpUseAllDataReport: reportingSftpUseAllDataReport,
    });
  };
  const onUpdateScripts = () => {
    updateSettings({
      dynamicScripts: headerScripts,
    });
  };
  const onUpdateSecondScripts = () => {
    updateSettings({
      dynamicBodyScripts: bodyScripts,
    });
  };
  const onUpdateConfirmationPageScript = () => {
    updateSettings({
      confirmationPageScript,
    });
  };

  const onUpdateTwilio = () => {
    updateSettings({
      enableTwilioIntegration: !!enableTwilioIntegration,
      twilioAccountId: twilioAccountId || "",
      twilioAuthToken: twilioAuthToken || "",
      twilioApiKey: twilioApiKey || "",
      twilioApiSecret: twilioApiSecret || "",
      twilioServiceId: twilioServiceId || "",
      twilioChatServiceId: twilioChatServiceId || "",
    });
  };


  const onChangeReportSettingField = (
    index: number,
    field: string,
    value: any
  ) => {
    setReportSettingItems(
      reportSettingItems.map((item, i) =>
        i === index
          ? {
            ...item,
            [field]: value,
          }
          : item
      )
    );
  };
  const onAddReportSettingItem = () => {
    setReportSettingItems([
      ...reportSettingItems,
      {
        id: uuid(),
        utcMinutes: 0,
        timeZone: "UTC",
        reportType: ReportType.ManagersReport,
        reportDeliveryType: ReportDeliveryType.sftp,
        reportScope: ReportScope.prevDayReservations,
      },
    ]);
  };
  const onDeleteReportSettingItem = (id: string) => {
    setReportSettingItems(reportSettingItems.filter((item) => item.id !== id));
  };
  const onSaveReportSettings = () => {
    saveReportSettings(reportSettingItems);
  };

  const mailChimpTitle = (
    <>
      <div className="h6">MailChimp</div>
    </>
  );
  const mailChimpTitleV2 = (
    <>
      <div className="h6">MailChimp 2.0</div>
    </>
  );
  const tripleSeatTitle = (
    <>
      <div className="h6">Triple Seat</div>
    </>
  );
  const salesForceTitle = (
    <>
      <div className="h6">Sales Force</div>
    </>
  );
  const posTitle = (
    <>
      <div className="h6">OneDine POS</div>
    </>
  );
  const mailgunTitle = (
    <>
      <div className="h6">Mailgun</div>
    </>
  );
  const qsrTitle = (
    <>
      <div className="h6">QSR</div>
    </>
  );
  const sevenRoomsTitle = (
    <>
      <div className="h6">Seven Rooms</div>
    </>
  );
  const seatninjaTitle = (
    <>
      <div className="h6">Seatninja</div>
    </>
  );
  const sftpTitle = (
    <>
      <div className="h6">Reporting SFTP</div>
    </>
  );
  const reportsTitle = (
    <>
      <div className="h6">Daily Reports</div>
    </>
  );
  const twilioTitle = (
    <>
      <div className="h6">Twilio</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableTwilioIntegration}
          onChange={(_: any, value?: boolean) =>
            setEnableTwilioIntegration(!!value)
          }
          disabled={readonly}
        />
      </div>
    </>
  );
  const headerScriptsTitle = <div className="h6">Header scripts</div>;
  const bodyScriptsTitle = <div className="h6">Body scripts</div>;
  const confirmationPageScriptTitle = (
    <div className="h6">Confirmation Page Script</div>
  );
  const squareTitle = (
    <>
      <div className="h6">Square</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={settings.squareIsEnable}
          onChange={onUpdateSquare}
          disabled={readonly}
        />
      </div>
    </>
  );

  return (
    <div className={`integrations-settings ${isMiddleScreen ? "mobile" : ""}`}>
      <div className="left-panel">
        <Panel className="panel-shift" title={squareTitle}>
          <IntegrationButtonBlock
            srcLogo={SquareLogo}
            title="Connect Square"
            subtitle="Connect Rex with your Square POS to sync your products and
            reservations"
            isConnected={isSquareConnected}
            onConnect={onEnableDisableSquare}
            onDisconnect={onEnableDisableSquare}
          />
        </Panel>
        <Panel className="panel-shift" title={mailChimpTitleV2}>
          <IntegrationButtonBlock
            srcLogo={MailchimpLogo}
            title="Connect Mailchimp"
            subtitle="Connect Rex with your Mailchimp"
            isConnected={isMailchimpConnected}
            onConnect={onEnableMailChimp}
            onDisconnect={disconnectMailchimp}
          />
        </Panel>

        <Panel className="panel-shift" title={mailChimpTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Audience ID"
              className="integrations-field"
              value={mailChimpId}
              onChange={(_: any, value?: string) => setMailChimpId(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Key"
              className="integrations-field"
              value={mailChimpKey}
              onChange={(_: any, value?: string) =>
                setMailChimpKey(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateMailChimp}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={tripleSeatTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Key"
              className="integrations-field"
              value={tsKey}
              onChange={(_: any, value?: string) => setTsKey(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Secret"
              className="integrations-field"
              value={tsSecret}
              onChange={(_: any, value?: string) => setTsSecret(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Leads Public Key"
              className="integrations-field"
              value={tsPublicKey}
              onChange={(_: any, value?: string) => setTsPublicKey(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Lead Form Id"
              className="integrations-field"
              value={tsFormId}
              onChange={(_: any, value?: string) => setTsFormId(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Site ID"
              type="number"
              className="integrations-field"
              value={tsSiteId.toString()}
              onChange={(_: any, value?: string) =>
                setTsSiteId(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Account ID"
              type="number"
              className="integrations-field"
              value={tsAccountId.toString()}
              onChange={(_: any, value?: string) =>
                setTsAccountId(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Contact ID"
              type="number"
              className="integrations-field"
              value={tsContactId.toString()}
              onChange={(_: any, value?: string) =>
                setTsContactId(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Owner ID"
              type="number"
              className="integrations-field"
              value={tsOwnerId.toString()}
              onChange={(_: any, value?: string) =>
                setTsOwnerId(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Event Type ID"
              type="number"
              className="integrations-field"
              value={tsEventTypeId.toString()}
              onChange={(_: any, value?: string) =>
                setTsEventTypeId(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Lead Source ID"
              type="number"
              className="integrations-field"
              value={tsLeadSourceId.toString()}
              onChange={(_: any, value?: string) =>
                setTsLeadSourceId(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Custom Fields for Lanes Count"
              type="number"
              className="integrations-field"
              value={tsCFLanesCount.toString()}
              onChange={(_: any, value?: string) =>
                setTsCFLanesCount(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Reservation Url"
              className="integrations-field"
              value={tsReservationUrl}
              onChange={(_: any, value?: string) =>
                setTsReservationUrl(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateTripleSeat}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={salesForceTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Email"
              className="integrations-field"
              value={salesForceEmail}
              onChange={(_: any, value?: string) =>
                setSalesForceEmail(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Password"
              className="integrations-field"
              value={salesForcePassword}
              onChange={(_: any, value?: string) =>
                setSalesForcePassword(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Secure Token"
              className="integrations-field"
              value={salesForceToken}
              onChange={(_: any, value?: string) =>
                setSalesForceToken(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateSalesForce}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={posTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="API key"
              className="integrations-field"
              value={posKey}
              onChange={(_: any, value?: string) => setPosKey(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="API auth"
              className="integrations-field"
              value={posAuth}
              onChange={(_: any, value?: string) => setPosAuth(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="API URL"
              className="integrations-field"
              value={posUrl}
              onChange={(_: any, value?: string) => setPosUrl(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdatePos}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={mailgunTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Key"
              className="integrations-field"
              value={mgKey}
              onChange={(_: any, value?: string) => setMgKey(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Domain"
              className="integrations-field"
              value={mgDomain}
              onChange={(_: any, value?: string) => setMgDomain(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Error Notifications"
              className="integrations-field"
              value={mgAdminEmail}
              onChange={(_: any, value?: string) =>
                setMgAdminEmail(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateMailgun}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={qsrTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="QSR Access Key"
              className="integrations-field"
              value={qAccessKey}
              onChange={(_: any, value?: string) => setQAccessKey(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="QSR Secret Key"
              className="integrations-field"
              value={qSecretKey}
              onChange={(_: any, value?: string) => setQSecretKey(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateQSR}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={sevenRoomsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Seven Rooms ID"
              className="integrations-field"
              value={nSevenRoomsId}
              onChange={(_: any, value?: string) =>
                setSevenRoomsId(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Seven Rooms Secret Key"
              className="integrations-field"
              value={nSevenRoomsSecret}
              onChange={(_: any, value?: string) =>
                setSevenRoomsSecret(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateSevenRooms}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={seatninjaTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Url"
              className="integrations-field"
              value={seatninjaUrl}
              onChange={(_: any, value?: string) =>
                setSeatninjaUrl(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="API Key"
              className="integrations-field"
              value={seatninjaApiKey}
              onChange={(_: any, value?: string) =>
                setSeatninjaApiKey(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateSeatninja}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={twilioTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Account ID"
              className="integrations-field"
              value={twilioAccountId || ""}
              onChange={(_: any, text?: string) =>
                setTwilioAccountId(text || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Auth token"
              className="integrations-field"
              value={twilioAuthToken || ""}
              onChange={(_: any, text?: string) =>
                setTwilioAuthToken(text || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Api key"
              className="integrations-field"
              value={twilioApiKey || ""}
              onChange={(_: any, text?: string) => setTwilioApiKey(text || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Api secret"
              className="integrations-field"
              value={twilioApiSecret || ""}
              onChange={(_: any, text?: string) =>
                setTwilioApiSecret(text || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Service ID"
              className="integrations-field"
              value={twilioServiceId || ""}
              onChange={(_: any, text?: string) =>
                setTwilioServiceId(text || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Chat SID"
              className="integrations-field"
              value={twilioChatServiceId || ""}
              onChange={(_: any, text?: string) =>
                setTwilioChatServiceId(text || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateTwilio}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={sftpTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Url"
              className="integrations-field"
              value={reportingSftpUrl}
              onChange={(_: any, value?: string) =>
                setReportingSftpUrl(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Name"
              className="integrations-field"
              value={reportingSftpName}
              onChange={(_: any, value?: string) =>
                setReportingSftpName(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Password"
              className="integrations-field"
              value={reportingSftpPassword}
              onChange={(_: any, value?: string) =>
                setReportingSftpPassword(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              className="integrations-field"
              label="Use All Data Report"
              checked={reportingSftpUseAllDataReport}
              onChange={(_: any, value?: boolean) =>
                setReportingSftpUseAllDataReport(!!value)
              }
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateReportingSftp}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={reportsTitle}>
          <div>
            <div>
              {reportSettingItems.map((rs, index) => (
                <div className="report-settings-row" key={rs.id}>
                  <FormSelectField
                    label="Time"
                    className="report-settings-item-time"
                    options={minutesOptions}
                    selectedKey={rs.utcMinutes}
                    onChange={(
                      _: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) =>
                      onChangeReportSettingField(
                        index,
                        "utcMinutes",
                        item?.key as number
                      )
                    }
                    required
                  />
                  <FormSelectField
                    label="Time Zone"
                    className="report-settings-item"
                    options={timeZoneOptions}
                    selectedKey={rs.timeZone}
                    onChange={(
                      _: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) =>
                      onChangeReportSettingField(
                        index,
                        "timeZone",
                        item?.key as string
                      )
                    }
                    required
                  />
                  <FormSelectField
                    label="Report Type"
                    className="report-settings-item"
                    options={reportTypeOptions}
                    selectedKey={rs.reportType}
                    onChange={(
                      _: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) =>
                      onChangeReportSettingField(
                        index,
                        "reportType",
                        item?.key as ReportType
                      )
                    }
                    required
                  />
                  <FormSelectField
                    label="Scope"
                    className="report-settings-item"
                    options={reportScopeOptions}
                    selectedKey={rs.reportScope}
                    onChange={(
                      _: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) =>
                      onChangeReportSettingField(
                        index,
                        "reportScope",
                        item?.key as ReportScope
                      )
                    }
                    required
                  />
                  <button
                    className="delete-button"
                    onClick={() => onDeleteReportSettingItem(rs.id)}
                  >
                    <img src={deleteIcon} alt="Delete Row" />
                  </button>
                </div>
              ))}
            </div>
            <LinkButton
              className="revenue-categories-add-item"
              onClick={onAddReportSettingItem}
            >
              <Icon iconName="Add" className="add-icon" />
              Add item
            </LinkButton>
          </div>
          <div>
            <ColorButton onClick={onSaveReportSettings}>
              Save Changes
            </ColorButton>
          </div>
        </Panel>
      </div>
      <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
        <Panel className="panel-shift" title={headerScriptsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Scripts"
              className="integrations-field"
              value={headerScripts}
              multiline
              rows={15}
              onChange={(_: any, value?: string) =>
                setHeaderScripts(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateScripts}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={bodyScriptsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Scripts"
              className="integrations-field"
              value={bodyScripts}
              multiline
              rows={15}
              onChange={(_: any, value?: string) => setBodyScripts(value || "")}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateSecondScripts}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={confirmationPageScriptTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Confirmation Page Script"
              className="integrations-field"
              value={confirmationPageScript}
              multiline
              rows={15}
              onChange={(_: any, value?: string) =>
                setConfirmationPageScript(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateConfirmationPageScript}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
      </div>

      {isSquareDisconnectModalOpen && (
        <ModalConfirm
          confirmationButtonText="Disable Square"
          confirmationText="Disabling Square will revoke current access token and remove all synchronized data"
          confirmationHeader="Square will be disconnected"
          onClose={handleDisconnectSquare}
        />
      )}
    </div>
  );
};

export default connector(IntegrationsSettings);

const availableOrigins = [
  "http://127.0.0.1:8081",
  "https://app.reservewithrex.com",
  "https://dev.reservewithrex.com",
];

function openWindowInIframe(
  url: string,
  onCloseCb?: CallableFunction,
  windowWidth = 1280,
  windowHeight = 800
): Window | null {
  const {
    screen: { width, height },
  } = window;
  const left = (width - windowWidth) / 2;
  const top = (height - windowHeight) / 2;
  const newWindow = window.open(
    undefined,
    "_blank",
    `width=${windowWidth},height=${windowHeight},left=${left}, top=${top}`
  );
  if (newWindow) {
    const iframe = document.createElement("iframe");
    iframe.src = url;
    iframe.width = "100%";
    iframe.height = "100%";

    newWindow.document.body.appendChild(iframe);
    newWindow.addEventListener("message", (event) => {
      if (!availableOrigins.includes(event.origin)) return;
      onCloseCb?.();
      newWindow.close();
    });
  }
  return newWindow;
}
