import { IColumn } from "office-ui-fabric-react";
import { FilterReservationParameters } from "../../../../server/src/dto/reservationQuery.dto";
import { ReservationSettingsDto } from "../../../../server/src/dto/reservationSettings.dto";
import { TransactionSettingDto } from "../../../../server/src/dto/transactionSettings.dto";
import  { ReservationsPeriod } from "../../../../server/src/entities/enums";

export * from "../../../../server/src/dto/reservationSettings.dto";
export * from "../../../../server/src/dto/transactionSettings.dto";
export * from "../../../../server/src/dto/reservationIntegration.dto";
export * from "../../../../server/src/dto/reservationQuery.dto";
export { ResyncIntegrationType, CancelType, ReservationsPeriod } from "../../../../server/src/entities/enums";

export interface ReservationState {
  confirmed: boolean;
  isPaymentInProgress: boolean;
  isRequestInProgress: boolean;
  paymentCompleted: boolean;
  reservation?: ReservationSettingsDto;
  reservations: ReservationSettingsDto[];
  error?: string;
  totalCount: number;
  lastLoadedPage: number;
  periodOfReservations: ReservationsPeriod;
  searchConfirmationNumber: string;
  venueIdForFilterReservations: string;
  columns: IColumn[];
  transactions: TransactionSettingDto[];
  filterParameters: FilterReservationParameters[];
}
