import { AddonsPackageVenueRelationDto, CurrencyType, ReservationSettingsDto, UserType } from "../store/types";
import { SCHEDULE_DATE_FORMAT } from "../constants/timedate";
import { bookingDurations } from "../constants/bookingDurationOptions";
import { formatPrice } from "../../../common/utils/formats";
import dayjs from 'dayjs';

export { formatPrice } from "../../../common/utils/formats";

export const formatReservationDate = (reservation: ReservationSettingsDto, format: string) => {
  return formatSlotDate(reservation.date, reservation.slots, reservation.timeSlotDuration, format);
};

export const formatSlotDate = (rDate: string, slots: number, timeSlotDuration: number, format: string) => {
  let date = dayjs(rDate)
  if (slots * timeSlotDuration >= 24 * 60) {
    date = date.add(1, 'day')
  }
  return date.format(format);
};

export const formatDuration = (duration: number, timeSlotDuration: number) => {
  const allMinutes = duration * timeSlotDuration;
  const hours = Math.floor(allMinutes / 60);
  const minutes = allMinutes % 60;
  return `${hours}${minutes !== 0
    ? minutes >= 10
      ? ":" + minutes
      : `:0${minutes}`
    : ""} ${hours === 1 && minutes === 0 ? "Hour" : "Hours"}`
};

export const formatGuests = (guests: number) => {
  return `${guests} ${guests === 1 ? "guest" : "guests"}`;
};

export const formatGuestDetails = (guestDetails?: string) => {
  if (!guestDetails) {
    return ''
  }
  const guestDetailsArray = guestDetails.trim().split(';');
  return guestDetailsArray.map((guestDetail, index) => `Guest ${index + 1}: ${guestDetail}\n`)
};

export const formatName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`
}

export const formatTotal = (price: number, tax: number, serviceFee: number, addonsPrice: number, discount: number, currency: CurrencyType) => {
  const total = (+price + +tax + +serviceFee + +addonsPrice - +discount).toFixed(2);
  return formatPrice(+total, currency);
}

export const durationSlotsToTime = (durations?: string, timeSlotDuration?: number): string[] => {
  if (!durations || !timeSlotDuration) {
    return [""];
  }
  return durations.split(",").map(duration => formatDuration(Number(duration.trim()), timeSlotDuration));
}

export const formatScheduleDate = (date: string) => {
  return dayjs(date).format(SCHEDULE_DATE_FORMAT);
}
export const getTime = (minutes?: number) => {
  return minutes + ` minute${minutes != 1 ? "s" : ""}`;
}

export const getTimeExtended = (minutes?: number): string => {
  if (typeof minutes !== 'number' || isNaN(minutes) || minutes < 0) {
    return '-';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const hourText = hours === 1 ? 'hour' : 'hours';
  const minuteText = remainingMinutes === 1 ? 'minute' : 'minutes';

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} ${hourText} and ${remainingMinutes} ${minuteText}`;
  } else if (hours > 0) {
    return `${hours} ${hourText}`;
  } else {
    return `${remainingMinutes} ${minuteText}`;
  }
}


export const formatTimeFromIndex = (index: number, timeSlotDuration: number, timeShifting: number, twelveHourClockFormat: boolean) => {
  const minutesFromIndex = (index: number) => Math.floor((index * timeSlotDuration + timeShifting))
  const time = dayjs('01/01/1970').add(minutesFromIndex(index), 'minute')
  const formatTime = twelveHourClockFormat ? 'hh:mm A' : 'HH:mm';
  const timeString = time.format(formatTime)
  const dayDiff = time.diff(dayjs('01/01/1970'), 'hour');
  return dayDiff < 24 ? timeString : `${timeString} + 1`
}

export const formatLabel = (label?: string) => {
  if (!label) {
    return '';
  }
  return label[0].toUpperCase() + label.slice(1).toLowerCase();
}

export const formatIntegerNumber = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format;

export const formatReadStatus = (statuses?: string) => {
  if (!statuses) {
    return '-';
  }
  return statuses.split(",").map(status => status[0].toUpperCase() + status.slice(1).toLowerCase()).join(",");
}

export const formatGiftCardNumbers = (giftCardNumbers: string) => {
  if (!giftCardNumbers) {
    return '-';
  }
  return giftCardNumbers.replace(/,/g, ", ")
}

export const getAddonRelationDurations = (relation?: AddonsPackageVenueRelationDto): number[] => {
  if (!relation) {
    return [];
  }
  const durationInSlots = relation.durationInSlots;
  const durationSlotsString = durationInSlots.split(",");
  const durationSlots = durationSlotsString.map((slot) =>
    parseInt(slot.trim())
  );
  return durationSlots;
};

export const getBookingDuration = (days?: number): string => {
  let bookingDurationText = "";
  if (!!days || days === 0) {
    bookingDurations.forEach(item => {
      if (item.key === days) {
        bookingDurationText = item.text;
      }
    })
  };
  return bookingDurationText;
}

export const bufferSlots = (timeSlotDuration?: number) => {
  return Array(25).fill(null).map((_, key) => ({
    key, text: `${key} slot${key === 1 ? "" : "s"} (${(durationSlotsToTime(key.toString(), timeSlotDuration))[0].toLowerCase()})`
  }));
}

export const formatTimeForCalendar = (twelveHourClockFormat: boolean, index?: number) => {
  if (!!index || index === 0) {
    const time = dayjs('01/01/1970').add(index, 'hours');
    const formatTime = twelveHourClockFormat ? 'hh:mm A' : 'HH:mm';
    const timeString = time.format(formatTime);
    const dayDiff = time.diff(dayjs('01/01/1970'), 'hour');
    return dayDiff < 24 ? timeString : `${timeString} + 1`;
  }
  return '-';
}
export const formatTimeWindowForCalendar = (value: number) => {
  const hours = Math.floor(value / 60)
  const min = value % 60
  return `${hours ? hours > 1 ? `${hours} hours` : `${hours} hour` : ''}${min ? ' ' : ''}${min ? min > 1 ? `${min} minutes` : `${min} minute` : ''}`;
}

export const getIsVenueUser = (type: UserType) => {
  return type === UserType.user || type === UserType.userLead;
}