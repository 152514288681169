import React, { useEffect } from 'react';
import ActionsButton from "../common/ActionsButton";
import { DetailsList, DetailsListLayoutMode, IColumn, Icon, ScrollablePane, SelectionMode } from "office-ui-fabric-react";
import './addons.scss'
import FormSearchField from "../common/FormSearchField";
import LocationIcon from "../../assets/locations-icon.svgr";
import ColorButton from "../../../../common/components/ColorButton";
import { webConfigUI } from "../../constants/webConfigUI";
import { getModifiersAction, selectAddonModifierAction } from "../../actions/addon-action";
import { selectModifiers } from "../../reducers/addons";
import { AddonModifierDto, State } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import EditAddonModifier from '../AddonModifierDetails/General/Edit/EditAddonModifier';
import { selectReadonly } from '../../reducers/auth-reducer';


const mapDispatchToProps = {
  getAddonModifiers: getModifiersAction,
  selectAddonModifier: selectAddonModifierAction,
}

const mapStateToProps = (state: State) => ({
  modifiers: selectModifiers(state),
  readonly: selectReadonly(state),
})

const columns: IColumn[] = [
  {
    key: "name",
    name: "Modifier",
    fieldName: "name",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "bold-column subtitle2",
    data: "string",
    isPadded: true,
  },
  {
    key: "title",
    name: "Title",
    fieldName: "title",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "bold-column subtitle2",
    data: "string",
    isPadded: true,
  },
  {
    key: "priority",
    name: "PRIORITY",
    fieldName: "priority",
    minWidth: 45,
    maxWidth: 100,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: "required",
    name: "REQUIRED",
    fieldName: "required",
    minWidth: 25,
    maxWidth: 50,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
    onRender: (item: {required: boolean}) => {
      const statusText = item.required ? "Yes" : "No";
      return (
        <div className="status-container">
            {statusText}
        </div>
      );
    },
  },
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  setMainContainerUI: (view: webConfigUI) => void;
};

const AddonModifiers = (
  {
    getAddonModifiers,
    modifiers,
    setMainContainerUI,
    selectAddonModifier,
    readonly,
  }: Props
) => {
  useEffect(() => {
    getAddonModifiers()
  }, [getAddonModifiers]);

  const [isCreating, setIsCreating] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const onItemInvoked = (item: any): void => {
    selectAddonModifier(item as AddonModifierDto);
    setMainContainerUI(webConfigUI.ADDON_MODIFIERS_DETAILS);
  };

  const onFilter = (ev: any, value: string | undefined = ""): void => {
    setSearchText(value)
  };

  const addAddonModifier = () => {
    selectAddonModifier();
    setIsCreating(true);
  };

  const filteredModifiers = searchText
  ? modifiers.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  )
  : modifiers

  return (
    <>
      <div className="addons">
        <div className="addons-header">
          <h1 className="h4 title">
            Add on Modifiers
          </h1>
          <ActionsButton
            onClick={addAddonModifier}
            disabled={readonly}
          >
            <Icon iconName="Add" className="add-icon" />
            <div className="add-button-text">
              Add on Modifier
            </div>
          </ActionsButton>
        </div>
        <div className="addon-list-container">
          <div className="searchbox-block">
            <FormSearchField
              placeholder="Search..."
              onChange={onFilter}
            />
          </div>
        </div>
        {filteredModifiers?.length > 0 ? (
          <div className="addons-list">
            <ScrollablePane>
              <DetailsList
                items={filteredModifiers}
                styles={{ focusZone: { cursor: "pointer" } }}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={onItemInvoked}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-addon-list-container">
            <div className="empty-addon-list">
              <LocationIcon className="location-icon" />
              <div className="empty-addon-list-message">
                Let’s get your first add on modifier set up!
              </div>
              <ColorButton
                className="empty-addon-list-button"
                onClick={addAddonModifier}
                disabled={readonly}
              >
                <Icon iconName="Add" className="add-icon" />
                <div className="add-button-text">
                  Add on Modifier
                </div>
              </ColorButton>
            </div>
          </div>
        )}
      </div>
      {isCreating && <EditAddonModifier isCloneAddonModifier={false} onClose={() => setIsCreating(false)} />}
    </>
  );
};

export default connector(AddonModifiers);