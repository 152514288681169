import { createSelector } from "reselect";
import {
  SettingsState,
  State,
  Pricing,
  ReservationModeType,
  CurrencyType,
  DateFormat,
  SettingsDto,
  ReportSettingsDto,
} from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: SettingsState = Object.freeze({
  id: "",
  pricing: Pricing.perLane,
  hideDuration: false,
  hideNumberOfLines: false,
  hideCreateAccount: false,
  subtractMultiLanes: false,
  showReservationTime: false,
  disableGuestCheckout: false,
  defaultDuration: 1,
  timeSlotDuration: 30,
  isRequestInProgress: false,
  isImageLoadInProgress: false,
  isLogoLoadInProgress: false,
  isLogoEmailLoadInProgress: false,
  isFaviconLoadInProgress: false,
  homeBackgroundImage: "",
  homeTitle: "",
  homeLogo: "",
  homeEmailLogo: "",
  homeFavicon: "",
  homeBackgroundColor: "",
  headerBackgroundColor: "",
  footerBackgroundColor: "",
  headerForegroundColor: "",
  footerForegroundColor: "",
  homeLink: "",
  bodyBackgroundColor: "",
  mainButtonColor: "",
  mainButtonTextColor: "",
  htmlTitle: "",
  mchAudienceId: "",
  mchApiKey: "",
  mchAccessToken: "",
  mchServerPrefix: "",
  mchIsEnable: false,
  tripleSeatKey: "",
  tripleSeatSecret: "",
  tripleSeatPublicKey: "",
  tripleSeatFormId: "",
  tripleSeatOwnerId: 0,
  tripleSeatSiteId: 0,
  tripleSeatAccountId: 0,
  tripleSeatContactId: 0,
  tripleSeatEventTypeId: 0,
  tripleSeatLeadSourceId: 0,
  tripleSeatCFLanesCount: 0,
  tripleSeatReservationUrl: "",
  sfEmail: "",
  sfPassword: "",
  sfSecureToken: "",
  posApiKey: "",
  posApiAuth: "",
  posApiUrl: "",
  mailgunKey: "",
  mailgunDomain: "",
  mailgunAdminEmail: "",
  companyName: "",
  hideCompanyNameFromEmails: false,
  instagramUrl: "",
  facebookUrl: "",
  dynamicScripts: "",
  qsrAccessKey: "",
  qsrSecretKey: "",
  sevenRoomsId: "",
  sevenRoomsSecret: "",
  seatNinjaUrl: "",
  seatNinjaApiKey: "",
  sftpUrl: "",
  sftpName: "",
  sftpUseAllDataReport: false,
  sftpPassword: "",
  actionIcon: "",
  actionText: "",
  partyTitle: "",
  groupTitle: "",
  occasions: "",
  cancellationReasons: "",
  cancellationReasonsActive: false,
  categories: "",
  paymentUrl: "",
  occasionTitle: "",
  privacyPolicy: "",
  termsOfUse: "",
  showGuestDetails: false,
  guestDetails: "",
  guestDetailsTitle: "",
  reservationMode: ReservationModeType.Default,
  currency: CurrencyType.USD,
  scrollToTimeSlotAfterSelect: false,
  dynamicBodyScripts: "",
  confirmationIcon: "",
  twelveHourClockFormat: true,
  dateFormat: DateFormat.MMDDYYYY,
  hideAllPackageTab: false,
  enableTwilioIntegration: false,
  twilioAccountId: "",
  twilioAuthToken: "",
  twilioServiceId: "",
  twilioApiKey: "",
  twilioApiSecret: "",
  twilioChatServiceId: "",
  squareAccessToken: "",
  squareRefreshToken: "",
  squareMerchantId: "",
  squareIsEnable: false,
  newDesign: false,
  showReportV2Page: false,
  stripeAccountId: "",
  stripeConnectionStatus: "",
  confirmationPageScript: "",
  reportSettings: [],
  membershipTitle: '',
  membershipDescription: '',
  noPlansAvailableMessage: '',
  howDidYouHearAboutUs: '',
});

export default createReducer<SettingsState>(initialState, {
  [ActionTypeEnum.Logout]: () => (state: SettingsState) => ({
    ...initialState,
  }),
  [ActionTypeEnum.GetSettings]: () => (state: SettingsState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.GetSettingsSuccess]:
    (settings: SettingsDto) => (state: SettingsState) => {
      return {
        ...state,
        isRequestInProgress: false,
        ...settings,
      };
    },
  [ActionTypeEnum.GetSettingsFailure]:
    (error?: string) => (state: SettingsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),

  [ActionTypeEnum.UpdateSettings]: () => (state: SettingsState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.UpdateSettingsSuccess]:
    (updated: Partial<SettingsDto>) => (state: SettingsState) => {
      return {
        ...state,
        isRequestInProgress: false,
        ...updated,
      };
    },
  [ActionTypeEnum.UpdateSettingsFailure]:
    (error: string) => (state: SettingsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.GetHomeImageUrl]: () => (state: SettingsState) => ({
    ...state,
    isRequestInProgress: true,
    isImageLoadInProgress: true,
  }),
  [ActionTypeEnum.GetHomeImageUrlSuccess]: () => (state: SettingsState) => {
    return {
      ...state,
      isRequestInProgress: false,
      isImageLoadInProgress: false,
    };
  },
  [ActionTypeEnum.GetHomeImageUrlFailure]:
    (error?: string) => (state: SettingsState) => ({
      ...state,
      isRequestInProgress: false,
      isImageLoadInProgress: false,
      error,
    }),
  [ActionTypeEnum.GetHomeLogoUrl]: () => (state: SettingsState) => ({
    ...state,
    isRequestInProgress: true,
    isLogoLoadInProgress: true,
  }),
  [ActionTypeEnum.GetHomeLogoUrlSuccess]: () => (state: SettingsState) => {
    return {
      ...state,
      isRequestInProgress: false,
      isLogoLoadInProgress: false,
    };
  },
  [ActionTypeEnum.GetHomeLogoUrlFailure]:
    (error?: string) => (state: SettingsState) => ({
      ...state,
      isRequestInProgress: false,
      isLogoLoadInProgress: false,
      error,
    }),
  [ActionTypeEnum.GetHomeEmailLogoUrl]: () => (state: SettingsState) => ({
    ...state,
    isRequestInProgress: true,
    isLogoEmailLoadInProgress: true,
  }),
  [ActionTypeEnum.GetHomeEmailLogoUrlSuccess]: () => (state: SettingsState) => {
    return {
      ...state,
      isRequestInProgress: false,
      isLogoEmailLoadInProgress: false,
    };
  },
  [ActionTypeEnum.GetHomeEmailLogoUrlFailure]:
    (error?: string) => (state: SettingsState) => ({
      ...state,
      isRequestInProgress: false,
      isLogoEmailLoadInProgress: false,
      error,
    }),
  [ActionTypeEnum.GetHomeFaviconUrl]: () => (state: SettingsState) => ({
    ...state,
    isRequestInProgress: true,
    isFaviconLoadInProgress: true,
  }),
  [ActionTypeEnum.GetHomeFaviconUrlSuccess]: () => (state: SettingsState) => {
    return {
      ...state,
      isRequestInProgress: false,
      isFaviconLoadInProgress: false,
    };
  },
  [ActionTypeEnum.GetHomeFaviconUrlFailure]:
    (error: string) => (state: SettingsState) => ({
      ...state,
      isRequestInProgress: false,
      isFaviconLoadInProgress: false,
      error,
    }),
  [ActionTypeEnum.SetError]: (error: string) => (state: SettingsState) => ({
    ...state,
    error,
  }),
  [ActionTypeEnum.DisableSquare]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.DisableSquareSuccess]:
    (settings: SettingsDto) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        ...settings,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.DisableSquareFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.GetSquareOAuthUrl]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.GetSquareOAuthUrlSuccess]:
    (url: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.GetSquareOAuthUrlFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.GetMailchimpOAuthUrl]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.GetMailchimpOAuthUrlSuccess]:
    (url: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.GetMailchimpOAuthUrlFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.DisableMailchimp]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.DisableMailchimpSuccess]:
    (settings: SettingsDto) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        ...settings,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.DisableMailchimpFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.ConnectStripe]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.ConnectStripeSuccess]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        stripeConnectionStatus: "pending",
      }),
  [ActionTypeEnum.ConnectStripeFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.DisconnectStripe]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.DisconnectStripeSuccess]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        stripeAccountId: "",
        stripeConnectionStatus: "",
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.DisconnectStripeFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.CheckStripe]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.CheckStripeWithoutRequestInProgress]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
      }),
  [ActionTypeEnum.CheckStripeSuccess]:
    ({ stripeConnectionStatus }: { stripeConnectionStatus: string }) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        stripeConnectionStatus,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.CheckStripeFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.GetReportSettingsRequest]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.GetReportSettingsSuccess]:
    (reportSettings: ReportSettingsDto[]) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        reportSettings,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.GetReportSettingsFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.SaveReportSettingsRequest]:
    () =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.SaveReportSettingsSuccess]:
    (reportSettings: ReportSettingsDto[]) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        reportSettings: reportSettings,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.SaveReportSettingsFailure]:
    (error?: string) =>
      (state: SettingsState): SettingsState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
});

export const selectSettingsState = (state: State) => state.settings;
export const selectActionIcon = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.actionIcon
);
export const selectActionText = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.actionText
);
export const selectPartyTitle = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.partyTitle
);
export const selectGroupTitle = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.groupTitle
);
export const selectOccasions = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.occasions
);
export const selectCategories = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.categories
);
export const selectOccasionTitle = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.occasionTitle
);
export const selectCancellationReasons = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.cancellationReasons
);
export const selectCancellationReasonsActive = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.cancellationReasonsActive
);
export const selectShowGuestDetails = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.showGuestDetails
);
export const selectGuestDetails = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.guestDetails
);
export const selectGuestDetailsTitle = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.guestDetailsTitle
);
export const selectPricing = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.pricing
);
export const selectHideDuration = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hideDuration
);
export const selectHideNumberOfLines = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hideNumberOfLines
);
export const selectHideCreateAccount = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hideCreateAccount
);
export const selectSubtractMultiLanes = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.subtractMultiLanes
);
export const selectShowReservationTime = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.showReservationTime
);
export const selectDisableGuestCheckout = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.disableGuestCheckout
);
export const selectDefaultDuration = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.defaultDuration
);
export const selectTimeSlotDuration = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.timeSlotDuration
);
export const selectIsSettingsRequestInProgress = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isRequestInProgress
);
export const selectHomeTitle = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.homeTitle
);
export const selectHomeBackgroundImage = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.homeBackgroundImage
);
export const selectHomeLogo = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.homeLogo
);
export const selectHomeEmailLogo = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.homeEmailLogo
);
export const selectHomeFavicon = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.homeFavicon
);
export const selectHeaderBackgroundColor = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.headerBackgroundColor
);
export const selectFooterBackgroundColor = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.footerBackgroundColor
);
export const selectHeaderForegroundColor = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.headerForegroundColor
);
export const selectFooterForegroundColor = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.footerForegroundColor
);
export const selectHomeLink = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.homeLink
);
export const selectBodyBackgroundColor = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.bodyBackgroundColor
);
export const selectMainButtonColor = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.mainButtonColor
);
export const selectMainButtonTextColor = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.mainButtonTextColor
);
export const selectHtmlTitle = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.htmlTitle
);
export const selectMchAudienceId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.mchAudienceId
);
export const selectMchApiKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.mchApiKey
);
export const selectTripleSeatKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatKey
);
export const selectTripleSeatSecret = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatSecret
);
export const selectTripleSeatPublicKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatPublicKey
);
export const selectTripleSeatFormId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatFormId
);
export const selectTripleSeatOwnerId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatOwnerId
);
export const selectTripleSeatSiteId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatSiteId
);
export const selectTripleSeatAccountId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatAccountId
);
export const selectTripleSeatContactId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatContactId
);
export const selectTripleSeatEventTypeId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatEventTypeId
);
export const selectTripleSeatLeadSourceId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatLeadSourceId
);
export const selectTripleSeatCFLanesCount = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatCFLanesCount
);
export const selectTripleSeatReservationUrl = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.tripleSeatReservationUrl
);
export const selectSfEmail = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sfEmail
);
export const selectSfPassword = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sfPassword
);
export const selectSfSecureToken = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sfSecureToken
);
export const selectPosApiKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.posApiKey
);
export const selectPosApiAuth = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.posApiAuth
);
export const selectPosApiUrl = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.posApiUrl
);
export const selectMailgunKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.mailgunKey
);
export const selectMailgunDomain = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.mailgunDomain
);
export const selectMailgunAdminEmail = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.mailgunAdminEmail
);
export const selectCompanyName = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.companyName
);
export const selectHideCompanyNameFromEmails = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hideCompanyNameFromEmails
);
export const selectInstagramUrl = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.instagramUrl
);
export const selectFacebookUrl = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.facebookUrl
);
export const selectDynamicScripts = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.dynamicScripts
);
export const selectQsrAccessKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.qsrAccessKey
);
export const selectQsrSecretKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.qsrSecretKey
);
export const selectPaymentUrl = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.paymentUrl
);
export const selectSevenRoomsId = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sevenRoomsId
);
export const selectSevenRoomsSecret = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sevenRoomsSecret
);
export const selectSeatNinjaUrl = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.seatNinjaUrl
);
export const selectSeatNinjaApiKey = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.seatNinjaApiKey
);
export const selectSftpUrl = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sftpUrl
);
export const selectSftpName = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sftpName
);
export const selectSftpUseAllDataReport = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sftpUseAllDataReport
);
export const selectSftpPassword = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.sftpPassword
);
export const selectError = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.error
);
export const selectIsImageLoadInProgress = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isImageLoadInProgress
);
export const selectIsLogoLoadInProgress = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isLogoLoadInProgress
);
export const selectIsEmailLogoLoadInProgress = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isLogoEmailLoadInProgress
);
export const selectIsFaviconLoadInProgress = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.isFaviconLoadInProgress
);
export const selectReservationMode = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.reservationMode
);
export const selectCurrency = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.currency
);
export const selectScrollToTimeSlotAfterSelect = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.scrollToTimeSlotAfterSelect
);
export const selectDynamicBodyScripts = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.dynamicBodyScripts
);
export const selectPrivacyPolicy = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.privacyPolicy
);
export const selectTermsOfUse = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.termsOfUse
);
export const selectConfirmationIcon = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.confirmationIcon
);
export const selectTwelveHourClockFormat = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.twelveHourClockFormat
);
export const selectDateFormat = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.dateFormat
);
export const selectHideAllPackageTab = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.hideAllPackageTab
);
export const selectNewDesign = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.newDesign
);
export const selectShowReportV2Page = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.showReportV2Page
);
export const selectReportSettings = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.reportSettings
);

export const selectMembershipTitle = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.membershipTitle
);

export const selectMembershipDescription = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.membershipDescription
);

export const selectNoPlansAvailableMessage = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.noPlansAvailableMessage
);

export const selectHowDidYouHearAboutUs = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.howDidYouHearAboutUs
);

