import React from "react";
import { Modal } from "office-ui-fabric-react";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import CancelButton from "../../../../../common/components/CancelButton";
import "./deleteConfirmation.scss";

type Props = {
  ConfirmationHeader: string;
  ConfirmationText: string;
  ConfirmationButtonText: string;
  onClose: (isConfirm: boolean) => void;
  onDelete?: () => void;
};

const DeleteConfirmation = ({
  ConfirmationHeader,
  ConfirmationText,
  ConfirmationButtonText,
  onClose,
  onDelete
}: Props) => {

  const onCancel = () => {
    onDelete && onDelete();
    onClose(true);
  };
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose(false)}
      isBlocking={false}
      containerClassName="delete-confirmation"
    >
      <div className="container">
        <div className="header h4">
          <div>{ConfirmationHeader}</div>
          <CancelButton onClick={() => onClose(false)} />
        </div>
        <div className="subtitle2 delete-confirmation-text">{ConfirmationText}</div>
        <div className="buttons-container">
          <LinkButton onClick={() => onClose(false)}>Cancel</LinkButton>
          <ColorButton onClick={() => onCancel()}>{ConfirmationButtonText}</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
