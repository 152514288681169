import { ActionTypeEnum, AppThunk } from ".";
import { getWithAuth, postWithAuth } from "./api";
import {
  selectVenueIdFilterReservations,
  selectFilterParameters,
  selectNumberOfSearchingReservation,
  selectReservations,
} from "../reducers/reservation";
import { IColumn } from "office-ui-fabric-react";
import {
  CancelType,
  FilterReservationParameters,
  ReservationQueryParams,
  ReservationSettingsDto,
  ReservationsPeriod,
  TimeInterval,
} from "../store/types";
import { addErrorAction } from "./auth-actions";
import { setWebConfigUIAction } from "./ui-actions";
import { webConfigUI } from "../constants/webConfigUI";
import { getCurrentScheduleAction } from "./venue-actions";
import { getCurrentPackageScheduleAction } from "./package-action";

const RESERVATION_URL = "/api/reservation/settings";
const TRANSACTION_URL = "/api/reservation/settings/transaction";

export const getReservationsAction = (params: any): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const reservations = selectReservations(getState());
    dispatch({ type: ActionTypeEnum.GetReservationsRequest });
    const response = await getWithAuth(`${RESERVATION_URL}`, params);
    const newReservations = response.data.results.filter((item: ReservationSettingsDto) => reservations.findIndex(res => res.id === item.id) === -1);
    const sumReservationsAndResponse = [
      ...reservations,
      ...newReservations,
    ];

    dispatch({
      type: ActionTypeEnum.GetReservationsTotalCountSuccess,
      payload: response.data.totalCount,
    });
    dispatch({
      type: ActionTypeEnum.GetReservationsSuccess,
      payload: sumReservationsAndResponse,
    });
  } catch (e) {
    console.log("get reservation error", e);
    dispatch({
      type: ActionTypeEnum.GetReservationsFailure,
      payload: "GetReservations failure",
    });
    dispatch(addErrorAction("GetReservations failure"));
  }
};

export const clearReservationsAction = (): AppThunk => async (dispatch) => {
  dispatch({ type: ActionTypeEnum.GetReservationsSuccess, payload: [] });
};

export const setConfirmationNumberSearchAction = (
  searchText: string
): AppThunk => async (dispatch) => {
  dispatch({
    type: ActionTypeEnum.SetNumberOfSearchingReservation,
    payload: searchText,
  });
};
export const setLastLoadedPageAction = (page: number): AppThunk => async (
  dispatch
) => {
  dispatch({ type: ActionTypeEnum.SetLastLoadedPage, payload: page });
};
export const setPeriodOfReservationsAction = (periodOfReservations: ReservationsPeriod): AppThunk => async (
  dispatch
) => {
  dispatch({ type: ActionTypeEnum.SetPeriodOfReservations, payload: periodOfReservations });
}
export const setVenueIdFilterAction = (venueId: string): AppThunk => async (
  dispatch
) => {
  dispatch({ type: ActionTypeEnum.SetVenueIdFilterReservations, payload: venueId });
};

export const setColumnsAction = (columns: IColumn[]): AppThunk => async (
  dispatch
) => {
  dispatch({ type: ActionTypeEnum.SetColumns, payload: columns });
};

export const selectReservationAndTransactionsAction = (
  reservationId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetTransactions });
    const response = await getWithAuth(`${RESERVATION_URL}/get-selected-reservation/${reservationId}`);
    dispatch({ type: ActionTypeEnum.SelectReservation, payload: response.data });
    dispatch({
      type: ActionTypeEnum.GetTransactionsSuccess,
      payload: response.data.transactions,
    });
    dispatch(setWebConfigUIAction(webConfigUI.RESERVATION_DETAILS));
  } catch (e) {
    console.log("get transactions by reservation id error", e);
    dispatch({
      type: ActionTypeEnum.GetTransactionsFailure,
      payload: "GetTransactions failure",
    });
    dispatch(addErrorAction("Get transactions by reservation id error"));
  }
};

export const setTransactionAndReservationClearAction = (): AppThunk => async (dispatch) => {
  dispatch({ type: ActionTypeEnum.SetTransactionAndReservationClear });
};

export const resyncIntegrationAction = (
  reservation: ReservationSettingsDto,
  integration: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.ResyncIntegration });
    const response = await postWithAuth(`${RESERVATION_URL}/resync/${reservation.id}`, { integration, venueId: reservation.venueId });
    dispatch({
      type: ActionTypeEnum.ResyncIntegrationSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("resync integration error", e);
    dispatch({
      type: ActionTypeEnum.ResyncIntegrationFailure,
      payload: "ResyncIntegration failure",
    });
    dispatch(addErrorAction("Resync integration error"));
  }
};

export const refundTransactionAction = (
  reservation: ReservationSettingsDto,
  amount: number
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.RefundTransaction });
    const response = await postWithAuth(`${TRANSACTION_URL}/${reservation.id}`, { amount, venueId: reservation.venueId });

    dispatch({
      type: ActionTypeEnum.RefundTransactionSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch(selectReservationAndTransactionsAction(reservation.id));
    console.log("refund transactions by reservation id error", e);
    dispatch({
      type: ActionTypeEnum.RefundTransactionFailure,
      payload: "RefundTransaction failure",
    });
    dispatch(addErrorAction("Refund transactions by reservation id error"));
  }
};

export const cancelReservationAction = (
  reservation: ReservationSettingsDto,
  cancelCondition: CancelType
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.CancelReservation });
    const response = await postWithAuth(`${RESERVATION_URL}/cancel/${reservation.id}`, { cancelCondition, venueId: reservation.venueId });

    dispatch({
      type: ActionTypeEnum.CancelReservationSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch(selectReservationAndTransactionsAction(reservation.id));
    console.log("cancel reservation error", e);
    dispatch({
      type: ActionTypeEnum.CancelReservationFailure,
      payload: "CancelReservation failure",
    });
    dispatch(addErrorAction("Cancel reservation error"));
  }
};

export const removeReservationAction = (
  reservation: ReservationSettingsDto,
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveReservation });
    const response = await postWithAuth(`${RESERVATION_URL}/remove/${reservation.id}`, { venueId: reservation.venueId });

    dispatch({
      type: ActionTypeEnum.RemoveReservationSuccess,
      payload: response.data,
    });
    dispatch(setWebConfigUIAction(webConfigUI.RESERVATION));
  } catch (e) {
    console.log("remove reservation error", e);
    dispatch({
      type: ActionTypeEnum.RemoveReservationFailure,
      payload: "RemoveReservation failure",
    });
    dispatch(addErrorAction("Remove reservation error"));
  }
};

export const resendConfirmationEmailAction = (
  reservation: ReservationSettingsDto,
  email: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.ResendConfirmationEmail });
    const response = await postWithAuth(`${RESERVATION_URL}/resend/${reservation.id}`, { email, venueId: reservation.venueId });
    dispatch({
      type: ActionTypeEnum.ResendConfirmationEmailSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("resend confirmation email error", e);
    dispatch({
      type: ActionTypeEnum.ResendConfirmationEmailFailure,
      payload: "ResendConfirmationEmail failure",
    });
    dispatch(addErrorAction("Resend email error"));
  }
};

export const getReservationByResNumberAction = (
  resNumber: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetReservationByResNumber });
    const response = await getWithAuth(`${RESERVATION_URL}/reservation`, { resNumber });
    dispatch({
      type: ActionTypeEnum.GetReservationByResNumberSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get reservation error", e);
    dispatch({
      type: ActionTypeEnum.GetReservationByResNumberFailure,
      payload: "GetReservationByResNumber failure",
    });
    dispatch(addErrorAction("Get reservation error"));
  }
}

export const setFilterParametersForReservationsAction = (
  filterParameters: FilterReservationParameters[],
): AppThunk => async (
  dispatch
) => {
    dispatch({ type: ActionTypeEnum.SetFilterParametersForReservations, payload: filterParameters });
  };

export const downloadReservationsReportAction = (
  reservationsPeriod: ReservationsPeriod | TimeInterval,
  venueIds?: string[],
): AppThunk => async (dispatch, getState ) => {
  dispatch({ type: ActionTypeEnum.ReportDownloadRequest });
  try {
    const filter = selectFilterParameters(getState());
    const search = selectNumberOfSearchingReservation(getState());
    const venueId = selectVenueIdFilterReservations(getState());
    const query: ReservationQueryParams = {
      venueId: venueIds !== undefined ? venueIds.join(',') : venueId,
      search,
      reservationsPeriod,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
    const response = await getWithAuth(`${RESERVATION_URL}/reservations-list-report`, {
      ...query,
      responseType: "blob",
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch({ type: ActionTypeEnum.ReportDownloadSuccess });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.ReportDownloadFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};

export const downloadReservationsTaxesReportAction = (
  reservationsPeriod: ReservationsPeriod | TimeInterval,
  venueIds?: string[],
): AppThunk => async (dispatch, getState ) => {
  dispatch({ type: ActionTypeEnum.ReportDownloadRequest });
  try {
    const filter = selectFilterParameters(getState());
    const search = selectNumberOfSearchingReservation(getState());
    const venueId = selectVenueIdFilterReservations(getState());
    const query: ReservationQueryParams = {
      venueId: venueIds !== undefined ? venueIds.join(',') : venueId,
      search,
      reservationsPeriod,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
    const response = await getWithAuth(`${RESERVATION_URL}/reservations-taxes-list-report`, {
      ...query,
      responseType: "blob",
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch({ type: ActionTypeEnum.ReportDownloadSuccess });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.ReportDownloadFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};

export const downloadReservationsRevenuesReportAction = (
  reservationsPeriod: ReservationsPeriod | TimeInterval,
  venueIds?: string[],
): AppThunk => async (dispatch, getState ) => {
  dispatch({ type: ActionTypeEnum.ReportDownloadRequest });
  try {
    const filter = selectFilterParameters(getState());
    const search = selectNumberOfSearchingReservation(getState());
    const venueId = selectVenueIdFilterReservations(getState());
    const query: ReservationQueryParams = {
      venueId: venueIds !== undefined ? venueIds.join(',') : venueId,
      search,
      reservationsPeriod,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
    const response = await getWithAuth(`${RESERVATION_URL}/reservations-revenues-list-report`, {
      ...query,
      responseType: "blob",
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch({ type: ActionTypeEnum.ReportDownloadSuccess });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.ReportDownloadFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};
export const downloadReservationsWithTransactionReportAction = (
  reservationsPeriod: ReservationsPeriod | TimeInterval,
  venueIds?: string[],
): AppThunk => async (dispatch, getState) => {
  dispatch({ type: ActionTypeEnum.ReportDownloadRequest });
  try {
    const filter = selectFilterParameters(getState());
    const search = selectNumberOfSearchingReservation(getState());
    const venueId = selectVenueIdFilterReservations(getState());
    const query: ReservationQueryParams = {
      venueId: venueIds !== undefined ? venueIds.join(',') : venueId,
      search,
      reservationsPeriod,
      filter: encodeURIComponent(JSON.stringify(filter)),
    };
    const response = await getWithAuth(`${RESERVATION_URL}/reservations-with-transactions-report`, {
      ...query,
      responseType: "blob",
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch({ type: ActionTypeEnum.ReportDownloadSuccess });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.ReportDownloadFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};

export const syncReservationsAction = (venueId: string, date: string, packageId?: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.SyncReservationsRequest });
    await getWithAuth(`${RESERVATION_URL}/sync-tripleseat-reservation-resources`, { venueId, date });
    dispatch({ type: ActionTypeEnum.SyncReservationsSuccess });
    if (packageId) {
      dispatch(getCurrentPackageScheduleAction(packageId, date));
    } else {
      dispatch(getCurrentScheduleAction(venueId, date));
    }
  } catch (e) {
    console.log("sync reservations error", e);
    dispatch({
      type: ActionTypeEnum.SyncReservationsFailure,
      payload: "SyncReservations failure",
    });
    dispatch(addErrorAction("Sync reservation error"));
  }
};