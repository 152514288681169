export const bookingDurations = [
    {
        key: 0,
        text: "Same day",
    },
    {
        key: 1,
        text: "1 day",
    },
    {
        key: 2,
        text: "2 days",
    },
    {
        key: 3,
        text: "3 days",
    },
    {
        key: 4,
        text: "4 days",
    },
    {
        key: 5,
        text: "5 days",
    },
    {
        key: 10,
        text: "10 days",
    },
    {
        key: 7,
        text: "1 week",
    },
    {
        key: 14,
        text: "2 weeks",
    },
    {
        key: 21,
        text: "3 weeks",
    },
    {
        key: 28,
        text: "4 weeks",
    },
    {
        key: 35,
        text: "5 weeks",
    },
    {
        key: 42,
        text: "6 weeks",
    },
    {
        key: 56,
        text: "8 weeks",
    },
    {
        key: 84,
        text: "12 weeks",
    },
    {
        key: 112,
        text: "16 weeks",
    },
    {
        key: 183,
        text: "6 months",
    },
    {
        key: 366,
        text: "1 year",
    },
    {
        key: 548,
        text: "1.5 years",
    },
    {
        key: 731,
        text: "2 years",
    },
];