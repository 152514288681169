import React, { useEffect } from "react";
import ActionsButton from "../common/ActionsButton";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  ScrollablePane,
  SelectionMode,
} from "office-ui-fabric-react";
import "./discounts.scss";
import FormSearchField from "../common/FormSearchField";
import LocationIcon from "../../assets/locations-icon.svgr";
import ColorButton from "../../../../common/components/ColorButton";
import { webConfigUI } from "../../constants/webConfigUI";
import {
  getDiscountsAction,
  selectDiscountAction,
} from "../../actions/discount-action";
import { selectDiscounts } from "../../reducers/discounts";
import { CouponStatus, DiscountSettingDto, State } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import EditDiscount from "./EditDiscount/EditDiscount";
import { selectReadonly } from "../../reducers/auth-reducer";

interface CellData {
  status: CouponStatus;
}

const mapDispatchToProps = {
  getDiscounts: getDiscountsAction,
  selectDiscount: selectDiscountAction,
};

const mapStateToProps = (state: State) => ({
  discounts: selectDiscounts(state),
  readonly: selectReadonly(state),
});

const columns: IColumn[] = [
  {
    key: "name",
    name: "DISCOUNT",
    fieldName: "name",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "bold-column subtitle2",
    data: "string",
    isPadded: true,
  },
  // {
  //   key: "type",
  //   name: "TYPE",
  //   fieldName: "type",
  //   minWidth: 80,
  //   maxWidth: 150,
  //   isResizable: true,
  //   className: "column body2",
  //   data: "string",
  //   isPadded: true,
  // },
  {
    key: "couponCode",
    name: "Coupon code",
    fieldName: "couponCode",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
  },
  {
    key: "amount",
    name: "Amount",
    fieldName: "amount",
    minWidth: 45,
    maxWidth: 100,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: "type",
    name: "Type",
    fieldName: "type",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
  },
  {
    key: "status",
    name: "STATUS",
    fieldName: "status",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
    onRender: (item: CellData) => {
      const statusText =
        item.status === CouponStatus.active ? "Active" : "Inactive";

      return (
        <div className="status-container">
          <div
            className={`status-color ${
              item.status === CouponStatus.active ? "green" : "red"
            }`}
          />
          <div
            className={`status-text body2 ${
              item.status === CouponStatus.active ? "green" : "red"
            }`}
          >
            {statusText}
          </div>
        </div>
      );
    },
  },
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  setMainContainerUI: (view: webConfigUI) => void;
};

const Discounts = ({
  getDiscounts,
  discounts,
  setMainContainerUI,
  selectDiscount,
  readonly,
}: Props) => {
  useEffect(() => {
    getDiscounts();
  }, [getDiscounts]);
  const [isCreating, setIsCreating] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const onItemInvoked = (item: any): void => {
    selectDiscount(item as DiscountSettingDto);
    setMainContainerUI(webConfigUI.DISCOUNT_DETAILS);
  };

  const onFilter = (ev: any, searchText: string | undefined = ""): void => {
    setSearch(searchText);
  };

  const addDiscount = () => {
    selectDiscount();
    setIsCreating(true);
  };

  const filteredDiscounts = search
    ? discounts.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    : discounts;

  return (
    <>
      <div className="discounts">
        <div className="discounts-header">
          <h1 className="h4 title">Discounts</h1>
          <ActionsButton onClick={addDiscount} disabled={readonly}>
            <Icon iconName="Add" className="add-icon" />
            <div className="add-button-text">Discount</div>
          </ActionsButton>
        </div>
        <div className="discount-list-container">
          <div className="searchbox-block">
            <FormSearchField placeholder="Search..." onChange={onFilter} />
          </div>
        </div>
        {discounts?.length > 0 ? (
          <div className="discounts-list">
            <ScrollablePane>
              <DetailsList
                items={filteredDiscounts}
                styles={{ focusZone: { cursor: "pointer" } }}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={onItemInvoked}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-discount-list-container">
            <div className="empty-discount-list">
              <LocationIcon className="location-icon" />
              <div className="empty-discount-list-message">
                Let’s get your first discount set up!
              </div>
              <ColorButton
                className="empty-discount-list-button"
                onClick={addDiscount}
                disabled={readonly}
              >
                <Icon iconName="Add" className="add-icon" />
                <div className="add-button-text">Discount</div>
              </ColorButton>
            </div>
          </div>
        )}
      </div>
      {isCreating && <EditDiscount onClose={() => setIsCreating(false)} />}
    </>
  );
};

export default connector(Discounts);
