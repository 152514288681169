import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  IDropdownOption,
  Modal,
} from "office-ui-fabric-react";
import {
  updatePackageAction,
  updatePackageAndNavigatePageAction,
} from "../../../../../actions/package-action";
import { selectVenuePage } from "../../../../../reducers/auth-reducer";
import { selectPackage, selectIsLoadingPackage, selectPackageError } from "../../../../../reducers/packages";
import { venueConfigUI } from "../../../../../constants/webConfigUI";
import { State } from "../../../../../store/types";
import { selectReadonly } from "../../../../../reducers/auth-reducer";
import { bookingDurations } from "../../../../../constants/bookingDurationOptions";
import FormTextField from "../../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../../common/components/CancelButton";
import FormSelectField from "../../../../common/FormSelectField";
import "./editAdminPackageReservation.scss";

const mapDispatchToProps = {
  updatePackage: updatePackageAction,
  updatePackageAndNavigatePage: updatePackageAndNavigatePageAction,
};
const mapStateToProps = (state: State) => ({
  currentPackage: selectPackage(state),
  readonly: selectReadonly(state),
  venuePage: selectVenuePage(state),
  isLoadingPackage: selectIsLoadingPackage(state),
  error: selectPackageError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditAdminPackageReservation = ({
  updatePackage,
  currentPackage,
  readonly,
  onClose,
  venuePage,
  isLoadingPackage,
  error,
  updatePackageAndNavigatePage,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    if (!isLoadingPackage && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingPackage]);
  useEffect(() => {
    setMaxGuestsPerLaneForAdmin(currentPackage?.maxGuestsPerLaneForAdmin);
    setMinGuestsForAdmin(currentPackage?.minGuestsForAdmin || 0);
    setMaxGuestsForAdmin(currentPackage?.maxGuestsForAdmin || 0);
    setAdminCutOffTime(currentPackage?.adminCutOffTime);
    setDurationInSlotsForAdmin(currentPackage?.durationInSlotsForAdmin);
    setDaysForBookingForAdmin(currentPackage?.daysForBookingForAdmin);
  }, [currentPackage]);
  const [maxGuestsPerLaneForAdmin, setMaxGuestsPerLaneForAdmin] = useState(currentPackage?.maxGuestsPerLaneForAdmin);
  const [minGuestsForAdmin, setMinGuestsForAdmin] = useState(currentPackage?.minGuestsForAdmin || 0);
  const [maxGuestsForAdmin, setMaxGuestsForAdmin] = useState(currentPackage?.maxGuestsForAdmin || 0);
  const [adminCutOffTime, setAdminCutOffTime] = useState(currentPackage?.adminCutOffTime);
  const [durationInSlotsForAdmin, setDurationInSlotsForAdmin] = useState(currentPackage?.durationInSlotsForAdmin);
  const [daysForBookingForAdmin, setDaysForBookingForAdmin] = useState(currentPackage?.daysForBookingForAdmin);

  const [errorMinGuestsForAdmin, setErrorMinGuestForAdmin] = useState("");
  const [errorMaxGuestsForAdmin, setErrorMaxGuestForAdmin] = useState("");

  const isValidPackage = () => {
    if (!(maxGuestsForAdmin >= minGuestsForAdmin)) {
      setErrorMinGuestForAdmin("Maximum guests for admin should be more than Minimum guests for admin")
      setErrorMaxGuestForAdmin("Maximum guests for admin should be more than Minimum guests for admin")
    }
    return (
      maxGuestsForAdmin >= minGuestsForAdmin
    )
  }

  const getPatch = () => {
    return {
      maxGuestsPerLaneForAdmin: maxGuestsPerLaneForAdmin || 0,
      minGuestsForAdmin: minGuestsForAdmin || 0,
      maxGuestsForAdmin: maxGuestsForAdmin || 0,
      adminCutOffTime: adminCutOffTime || 0,
      durationInSlotsForAdmin: durationInSlotsForAdmin || "",
      daysForBookingForAdmin: daysForBookingForAdmin || undefined,
    };
  }

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    setErrorMinGuestForAdmin("");
    setErrorMaxGuestForAdmin("");

    if (!isValidPackage()) {
      return;
    }

    saving.current = true;
    const patch = getPatch();
    venuePage === venueConfigUI.PACKAGE_DETAILS
      ? updatePackage({ ...currentPackage, ...patch })
      : updatePackageAndNavigatePage({ ...currentPackage, ...patch });

  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-admin-package"
    >
      <div className="title h4">
        Host Application Package Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="admin-package" onSubmit={onUpdate}>
        <div className="edit-package-fields">
          <div className="row">
            <FormTextField
              label="Minimum Guests for admin"
              type="number"
              errorMessage={errorMinGuestsForAdmin}
              value={minGuestsForAdmin.toString()}
              onChange={(_: any, value?: string) =>
                setMinGuestsForAdmin(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Maximum Guests for admin"
              type="number"
              errorMessage={errorMaxGuestsForAdmin}
              value={maxGuestsForAdmin.toString()}
              onChange={(_: any, value?: string) =>
                setMaxGuestsForAdmin(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Max number of guests on one lane for admin"
              type="number"
              value={maxGuestsPerLaneForAdmin?.toString() || ""}
              onChange={(_: any, value?: string) =>
                setMaxGuestsPerLaneForAdmin(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly || !currentPackage?.countLanesByGuest}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Admin cut off time"
              type="number"
              value={adminCutOffTime?.toString()}
              onChange={(_: any, value?: string) =>
                setAdminCutOffTime(parseInt(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Duration for admin"
              value={durationInSlotsForAdmin || ""}
              onChange={(_: any, value?: string) =>
                setDurationInSlotsForAdmin(value || "")
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className="row">
            <FormSelectField
              label="How far in advance can admins book?"
              options={bookingDurations}
              selectedKey={daysForBookingForAdmin}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => setDaysForBookingForAdmin(!!option ? +option?.key : undefined)}
            />
          </div>
        </div>
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditAdminPackageReservation);
