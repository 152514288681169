import React from "react";
import "./panel.scss";

interface Props {
  title?: React.ReactNode;
  className: string;
}
const Panel = ({
  children,
  title,
  className = '',
}: React.PropsWithChildren<Props>) => (
  <div className={`panel ${className}`}>
    {title && <div className="panel-header">{title}</div>}
    <div className="panel-content">{children}</div>
  </div>
);

export default Panel;
