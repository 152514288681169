import {
    Checkbox,
    IDropdownOption,
    Modal,
    Toggle
} from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import ColorButton from "../../../../../../../common/components/ColorButton";
import FormTextField from "../../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import { rem } from "../../../../../../../common/utils/formats";
import {
    updatePackageAction,
    updatePackageAndNavigatePageAction
} from "../../../../../actions/package-action";
import { bookingDurations } from "../../../../../constants/bookingDurationOptions";
import { selectReadonly, selectVenuePage } from "../../../../../reducers/auth-reducer";
import { selectIsLoadingPackage, selectPackage, selectPackageError } from "../../../../../reducers/packages";
import { State } from "../../../../../store/types";
import FormSelectField from "../../../../common/FormSelectField";
import "./editMembershipSettings.scss";
import { getPlansAction } from "../../../../../actions/membership-action";
import membership, { selectPlans } from "../../../../../reducers/membership";

const checkboxStyle = {
    root: {
        margin: "0 0 20px",
    },
    checkbox: {
        width: rem(20),
        height: rem(20),
    },
    text: {
        fontSize: rem(13),
        lineHeight: "1.5",
    },
    checkmark: {
        fontSize: rem(13),
    },
};

const mapDispatchToProps = {
    updatePackage: updatePackageAction,
    updatePackageAndNavigatePage: updatePackageAndNavigatePageAction,
};
const mapStateToProps = (state: State) => ({
    currentPackage: selectPackage(state),
    readonly: selectReadonly(state),
    venuePage: selectVenuePage(state),
    isLoadingPackage: selectIsLoadingPackage(state),
    error: selectPackageError(state),
    plans: selectPlans(state),

});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
    onClose: () => void;
};

const EditMembershipsSettings = ({
    readonly,
    onClose,
    updatePackage,
    currentPackage,
    isLoadingPackage,
    error,
    plans
}: Props) => {
    const saving = useRef(false);
    useEffect(() => {
        if (!isLoadingPackage && !error && saving.current) {
            saving.current = false;
            onClose();
        }
    }, [isLoadingPackage]);


    const [isMemberOnlyPackage, setMemberOnlyPackages] = useState<boolean>(currentPackage?.isMemberOnlyPackage || false);
    const [daysForBookingForMember, setDaysForBookingForMember] = useState<number | undefined>(currentPackage?.daysForBookingForMember || undefined);
    const [memberLeadTime, setMemberLeadTime] = useState<number>(currentPackage?.memberLeadTime || 0);
    const [durationInSlotsForMember, setDurationInSlotsForMember] = useState<string>(currentPackage?.durationInSlotsForMember || '');
    const [assignedPlans, setAssignedPlans] = useState<string[]>(currentPackage?.membershipPlanIds || []);


    const handlePlanChange = (planId: string, isChecked: boolean) => {
        setAssignedPlans(prevPlans =>
            isChecked ? [...prevPlans, planId] : prevPlans.filter(id => id !== planId)
        );
    };

    const getPatch = () => {
        return {
            isMemberOnlyPackage,
            memberLeadTime,
            durationInSlotsForMember,
            daysForBookingForMember,
            membershipPlanIds: assignedPlans
        };
    }

    const onUpdate = (e: React.FormEvent) => {
        e.preventDefault();

        saving.current = true;
        const patch = getPatch();
        updatePackage({ ...currentPackage, ...patch })
    };

    return (
        <Modal
            isOpen={true}
            onDismiss={onClose}
            isBlocking={false}
            containerClassName="edit-package"
        >
            <div className="title h4">
                Membership Settings
            </div>
            <form className="package" onSubmit={onUpdate}>
                <div className="row">
                    <Toggle
                        inlineLabel
                        checked={isMemberOnlyPackage}
                        onChange={(_, checked) => setMemberOnlyPackages(checked || false)}
                        disabled={readonly}
                        label={
                            <span className='body2 semibold'>Member Only Packages</span>
                        }
                    />
                </div>
                {plans?.length > 0 ?
                    <div className="row row-plan">
                        <span className="body2 semibold">Assign Plans</span>
                        <div className="assign-plans">
                            {plans.map((plan) => (
                                <Checkbox
                                    key={plan.id}
                                    label={plan.name}
                                    checked={assignedPlans.includes(plan.id)}
                                    onChange={(_, checked) => handlePlanChange(plan.id, checked || false)}
                                    styles={checkboxStyle}
                                />
                            ))}

                        </div>
                    </div>
                    : null}

                <div className="row">
                    <FormSelectField
                        label="How far in advance can members book?"
                        options={bookingDurations}
                        selectedKey={daysForBookingForMember}
                        onChange={(
                            _: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption
                        ) =>
                            setDaysForBookingForMember(!!option ? +option?.key : undefined)
                        }
                    />
                </div>
                <div className="row">
                    <FormTextField
                        label="Member lead time"
                        type="number"
                        value={memberLeadTime.toString()}
                        onChange={
                            (_, value) => setMemberLeadTime(value ? parseInt(value) : 0)
                        }
                        required
                        min={0}
                        disabled={readonly}
                    />
                </div>
                <div className="row">
                    <FormTextField
                        label="Duration for member"
                        value={durationInSlotsForMember || ""}
                        onChange={(_: any, value?: string) =>
                            setDurationInSlotsForMember(value || "")
                        }
                        autoComplete="off"
                        disabled={readonly}
                    />
                </div>
                <div className="buttons-container">
                    <LinkButton onClick={onClose}>Cancel</LinkButton>
                    <ColorButton
                        type="submit"
                        disabled={readonly}
                    >
                        Save Changes
                    </ColorButton>
                </div>
            </form>
        </Modal>
    );
};

export default connector(EditMembershipsSettings);
