export class TimeSlotPackageSettingDto {
  id: string;
  packageId: string;
  schedulePackageId: string;
  weekDay: number;
  start: number;
  end: number;
  is21plus: boolean;
  rate: number;
  changed?: boolean;
  isNew?: boolean;
  isDeleted?: boolean;
  startTime: number;
  endTime: number;
  lanesCount: number;
  staffCapacity: number;
  guestsCapacity: number;
  ageGroupPricing?: string;
}

export class CreateTimeSlotPackageSettingDto {
  packageId: string;
  schedulePackageId: string;
  weekDay: number;
  start: number;
  end: number;
  is21plus: boolean;
  rate: number;
  startTime: number;
  endTime: number;
  lanesCount: number;
  staffCapacity: number;
  guestsCapacity: number;
  ageGroupPricing?: string
}

export class TimeSlotParentPackageSettingDto {
  id: string;
  weekDay: number;
  start: number;
  end: number;
  is21plus: boolean;
  rate: number;
  changed?: boolean;
  isNew?: boolean;
  isDeleted?: boolean;
  startTime: number;
  endTime: number;
  lanesCount: number;
  staffCapacity: number;
  guestsCapacity: number;
  ageGroupPricing?: string

}
