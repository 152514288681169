import React, { useEffect, useState } from "react";
import Panel from "../../common/Panel";
import {
  getResourceRelationsForVenueAction,
  getResourceRelationsForPackageAction,
  updateResourceRelationsForVenueAction,
  updateResourceRelationsForPackageAction,
} from "../../../actions/resource-relations-actions";
import {
  selectReadonly,
  selectVenuePage,
} from "../../../reducers/auth-reducer";
import {
  ResourceCategoriesPackageVenueRelationDto,
  ResourceCategoryAssigningMethodType,
  ResourceDto,
  ResourceSettingDto,
  State,
} from "../../../store/types";
import { selectPackage } from "../../../reducers/packages";
import { selectResourceRelations } from "../../../reducers/resource-relations";
import { selectVenue } from "../../../reducers/venues";
import { connect, ConnectedProps } from "react-redux";
import {
  ChoiceGroup,
  IChoiceGroupOption,
  Icon,
  IDropdownOption,
  Toggle,
} from "office-ui-fabric-react";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import "./assignResource.scss";
import FormSelectField from "../../common/FormSelectField";
import FormTextField from "../../../../../common/components/FormTextField";
import deleteIcon from "../../../assets/delete.svg";
import { selectResourceCategories } from "../../../reducers/resources";
import { getResourceCategoriesAction } from "../../../actions/resources-action";
import { nanoid } from "nanoid";
import { venueConfigUI } from "../../../constants/webConfigUI";
import { SelectCustomResources } from "./SelectCustomResources";

const mapDispatchToProps = {
  getResourceRelationsForVenue: getResourceRelationsForVenueAction,
  updateResourceRelationsForVenue: updateResourceRelationsForVenueAction,
  getResourceRelationsForPackage: getResourceRelationsForPackageAction,
  updateResourceRelationsForPackage: updateResourceRelationsForPackageAction,
  getResourceCategories: getResourceCategoriesAction,
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentPackage: selectPackage(state),
  resourceRelations: selectResourceRelations(state),
  currentVenue: selectVenue(state),
  resourceCategories: selectResourceCategories(state),
  venuePage: selectVenuePage(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const assigningMethodOptions = [
  {
    text: "Flexible Based on Guests",
    key: ResourceCategoryAssigningMethodType.flexibleBasedOnGuests,
  },
  {
    text: "Match Guest Count",
    key: ResourceCategoryAssigningMethodType.matchGuestCount,
  },
  {
    text: "Custom",
    key: ResourceCategoryAssigningMethodType.custom,
  },
];

const AssignResource = ({
  readonly,
  currentPackage,
  currentVenue,
  resourceRelations,
  resourceCategories,
  venuePage,
  getResourceRelationsForVenue,
  getResourceCategories,
  updateResourceRelationsForVenue,
  getResourceRelationsForPackage,
  updateResourceRelationsForPackage,
}: Props) => {
  const [relationsState, setRelationsState] = useState<
    ResourceCategoriesPackageVenueRelationDto[]
  >([]);
  const [showDuplicateResourceError, setShowDuplicateResourceError] =
    useState(false);
  const [selectResourceCategory, selectResourcesCategory] = useState<
    ResourceCategoriesPackageVenueRelationDto | undefined
  >();

  useEffect(() => {
    if (currentVenue) {
      if (currentPackage && venuePage === venueConfigUI.PACKAGE_DETAILS) {
        getResourceRelationsForPackage({
          venueId: currentVenue.id,
          packageId: currentPackage.id,
        });
      } else {
        getResourceRelationsForVenue(currentVenue.id);
      }
      getResourceCategories(currentVenue.id);
    }
  }, [currentVenue, currentPackage, venuePage]);

  useEffect(() => {
    setRelationsState(resourceRelations);
  }, [resourceRelations]);

  const primaryRelation = relationsState.find(
    (relation) => relation.primary
  )?.id;

  const onAddItem = () => {
    setRelationsState([
      ...relationsState,
      {
        id: nanoid(),
        count: 0,
        categoryId: "",
        categoryName: "",
        assigningMethod: ResourceCategoryAssigningMethodType.custom,
        categoryActive: true,
        priority: 0,
        venueId: currentVenue?.id || "",
        packageId: currentPackage?.id || "",
        primary: relationsState.length === 0,
        resources: [],
        resourceName: "",
      },
    ]);
  };

  const onChangePrimaryRelation = (event: any, option?: IChoiceGroupOption) => {
    setRelationsState(
      relationsState.map((relation) => {
        if (relation.id === option?.key) {
          return {
            ...relation,
            primary: true,
          };
        } else {
          return {
            ...relation,
            primary: false,
          };
        }
      })
    );
  };

  const onChangeResourceCategory =
    (index: number) => (_: any, option?: IDropdownOption) => {
      setRelationsState((prevRelations) => {
        const newRelations = [...prevRelations];

        const selectedCategory = resourceCategories?.find(({id}) => id===option?.key)
        const resources = selectedCategory?.resources||[] as any[]

        newRelations[index] = {
          ...newRelations[index],
          categoryId: option?.key as string,
          categoryName: option?.text as string,
          resources,
          resourceCategoriesCustomRelations:[]
        };

        return newRelations;
      });
    };

  const onChangeAssigningMethod =
    (index: number) => (_: any, option?: IDropdownOption) => {
      setRelationsState((prevRelations) => {
        const newRelations = [...prevRelations];

        newRelations[index] = {
          ...newRelations[index],
          assigningMethod: option?.key as ResourceCategoryAssigningMethodType,
        };

        return newRelations;
      });
    };

  const onChangeGuestCount = (index: number) => (_: any, text?: string) => {
    setRelationsState((prevRelations) => {
      const newRelations = [...prevRelations];

      newRelations[index] = {
        ...newRelations[index],
        count: isNaN(Number(text)) ? 0 : Number(text),
      };

      return newRelations;
    });
  };

  const onDeleteRow =
    (index: number) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      setRelationsState((prevRelations) => {
        const newRelations = [...prevRelations];

        newRelations.splice(index, 1);

        return newRelations;
      });
    };

  const onToggleCustomResources = (index: number) => () => {
    setRelationsState((prevRelations) =>
      prevRelations.map((item, i) => {
        if (i !== index) {
          return item;
        }
        // modify
        if (!!item?.resourceCategoriesCustomRelations?.length) {
          item.resourceCategoriesCustomRelations = [];
        } else {
          item.resourceCategoriesCustomRelations = item.resources || [];
        }
        return item;
      })
    );
  };

  const onSaveCustomResources = (
    selectResourceCategory: ResourceCategoriesPackageVenueRelationDto,
    newSelectedResources: ResourceDto[]
  ) => {
    setRelationsState((relationsState) =>
      relationsState.map((r) => {
        if (r.id === selectResourceCategory.id) {
          return {
            ...r,
            resourceCategoriesCustomRelations: newSelectedResources,
          };
        }
        return r;
      })
    );
    selectResourcesCategory(undefined)
  };

  const onSaveChanges = () => {
    const assignedResources = new Set();

    const hasDuplicate = relationsState.find((relation) => {
      if (assignedResources.has(relation.categoryId)) return true;

      assignedResources.add(relation.categoryId);

      return false;
    });

    if (hasDuplicate) {
      setShowDuplicateResourceError(true);
      return;
    } else {
      setShowDuplicateResourceError(false);
    }

    if (currentVenue) {
      if (currentPackage && venuePage === venueConfigUI.PACKAGE_DETAILS) {
        updateResourceRelationsForPackage({
          venueId: currentVenue.id,
          packageId: currentPackage.id,
          relations: relationsState,
        });
      } else {
        updateResourceRelationsForVenue({
          venueId: currentVenue.id,
          relations: relationsState,
        });
      }
    }
  };

  const resourceCategoryOptions =
    resourceCategories?.map((category) => ({
      key: category.id,
      text: category.name,
    })) || [];

  const options: IChoiceGroupOption[] = relationsState.map(
    (relation, relationIndex) => {
      const hasCustomResources =
        !!relation?.resourceCategoriesCustomRelations?.length;
      return {
        key: relation.id,
        text: "",
        onRenderField: (props, render) => {
          return (
            <div className="resource-row">
              <div>
                <p
                  className={`resource-row-label ${
                    relation.primary ? "" : "is-hidden"
                  }`}
                >
                  Primary
                </p>
                <div className="resource-row-radio-wrapper">
                  {render!(props)}
                </div>
              </div>
              <div>
                <p className="resource-row-label">Resource</p>
                <FormSelectField
                  className="resource-row-dropdown"
                  options={resourceCategoryOptions}
                  selectedKey={relation.categoryId}
                  onChange={onChangeResourceCategory(relationIndex)}
                  disabled={readonly}
                />
              </div>
              <div>
                <p className="resource-row-label">Assigning Method</p>
                <FormSelectField
                  className="resource-row-dropdown"
                  options={assigningMethodOptions}
                  selectedKey={relation.assigningMethod}
                  onChange={onChangeAssigningMethod(relationIndex)}
                  disabled={readonly || relation.primary}
                />
              </div>
              <div>
                <p className="resource-row-label">
                  {relation.assigningMethod ===
                  ResourceCategoryAssigningMethodType.flexibleBasedOnGuests
                    ? "Max guests on one resource"
                    : "Count"}
                </p>
                <FormTextField
                  value={String(relation.count)}
                  onChange={onChangeGuestCount(relationIndex)}
                  disabled={
                    readonly ||
                    relation.primary ||
                    relation.assigningMethod ===
                      ResourceCategoryAssigningMethodType.matchGuestCount
                  }
                  className="resource-row-input"
                />
              </div>
              <div className="resource-row-2-places">
                <div>
                  <p className="resource-row-label">Custom</p>
                  <Toggle
                    className="inline-toggle"
                    checked={!!hasCustomResources}
                    onChange={onToggleCustomResources(relationIndex)}
                  />
                </div>
                <div>
                  <p className="resource-row-label is-hidden">E</p>
                  <button
                    onClick={() => selectResourcesCategory(relation)}
                    className="resource-row-edit-button"
                    disabled={!hasCustomResources}
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div>
                <p className="resource-row-label is-hidden">D</p>
                <button
                  onClick={onDeleteRow(relationIndex)}
                  className="resource-row-delete-button"
                >
                  <img src={deleteIcon} alt="Delete row" />
                </button>
              </div>
            </div>
          );
        },
      };
    }
  );

  return (
    <Panel
      className="panel-shift"
      title={<div className="h6">Assign Resource Category</div>}
    >
      <div className="assign-resource">
        <ChoiceGroup
          options={options}
          selectedKey={primaryRelation}
          onChange={onChangePrimaryRelation}
        />
        {resourceCategoryOptions.length > 0 && (
          <LinkButton
            className="add-button"
            disabled={readonly}
            onClick={onAddItem}
          >
            <Icon iconName="Add" className="add" />
            Add item
          </LinkButton>
        )}
        {showDuplicateResourceError && (
          <p className="error-text">
            You have a resource category assigned to multiple methods. Please
            remove the duplicate assignments.
          </p>
        )}
        {selectResourceCategory && (
          <SelectCustomResources
            onClose={() => selectResourcesCategory(undefined)}
            onSelect={(newSelectedResources) =>
              onSaveCustomResources(
                selectResourceCategory,
                newSelectedResources
              )
            }
            resources={selectResourceCategory.resources}
            selectedResources={
              selectResourceCategory.resourceCategoriesCustomRelations
            }
          />
        )}
        <ColorButton
          className="save-button"
          disabled={readonly}
          onClick={onSaveChanges}
        >
          Save Changes
        </ColorButton>
      </div>
    </Panel>
  );
};

export default connector(AssignResource);
