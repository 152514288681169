import React from "react";
import { DefaultButton, IButtonProps, IFontWeight } from "office-ui-fabric-react";
import { color } from "../../../constants/styles";

const ActionsButton = ({
  children,
  ...other
}: IButtonProps) => {
  const actionsButtonStyle = {
    root: {
      height: 40,
      background: color.blue,
      color: "white",
      border: "transparent",
      borderRadius: "6px",
      margin: "0px 3px",
    },
    rootHovered: {
      border: "transparent",
      outline: "none",
      background: color.blue,
      color: "white",
    },
    rootFocused: {
      border: "transparent",
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: color.blue,
      color: "white",
    },
    rootPressed: {
      border: "transparent",
      outline: "none",
      boxShadow: "rgba(80, 70, 228, 0.25) 0px 0px 0px 0.2rem",
      background: color.blue,
      color: "white",
    },
    textContainer: {
      fontSize: "13px",
      fontWeight: '500' as IFontWeight,
      lineHeight: 22,
      letterSpacing: '0.15px',
      fontFamily: "Inter, sans-serif",
    },
  };
  return (
    <DefaultButton styles={actionsButtonStyle} {...other}>
      {children}
    </DefaultButton>
  );
};

export default ActionsButton