import React from "react";
import {
    ActionButton,
    IDropdownOption,
    Checkbox,
} from "office-ui-fabric-react";
import FormTextField from "../../../../../../../../common/components/FormTextField";
import FormSelectField from "../../../../../common/FormSelectField";
import { InventoryMethodTypes, TimeSlotDiscountDto } from "../../../../../../store/types";
import { useViewport } from "../../../../../../hooks/responsive";
import TrashIcon from "../../../../../../assets/trash-icon.svgr";
import "./timeSlotRow.scss";



interface OwnProps {
    slot: TimeSlotDiscountDto;
    times: IDropdownOption[];
    onChange: (slot: TimeSlotDiscountDto, field: string, value: string | number | boolean) => void;
    removeSlot: (slotId: string) => void;
    timeSlotDuration: number;
}

export const TimeSlotRow = ({
    slot,
    times,
    onChange,
    removeSlot,
    timeSlotDuration,
}: OwnProps) => {
    const { isMobile } = useViewport();
    let slotsInHour = 60 / timeSlotDuration;
    let startTimeKey =  slot.start;
    let endTimeKey = slot.end;
    return (
        <div className={`timeSlotRow ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
                label="Start time"
                options={times}
                className={`dropdown ${isMobile ? "mobile" : ""}`}
                selectedKey={startTimeKey}
                onChange={(_: any, option) => {
                    onChange(slot, "start", option?.key || 0);
                }}
            />
            <div className="divider" />
            <FormSelectField
                label="End time"
                options={times}
                className={`dropdown ${isMobile ? "mobile" : ""}`}
                selectedKey={endTimeKey}
                onChange={(_: any, option) =>
                    onChange(slot, "end", option?.key || 0)
                }
            />
            
            <ActionButton onClick={() => removeSlot(slot.id)} styles={{ root: { height: 20, marginBottom: "10px" } }}><TrashIcon /></ActionButton>
        </div>
    );
};
