import React from 'react';
import './info.scss'
import Panel from "../../../common/Panel";
import LinkButton from "../../../../../../common/components/LinkButton";
import HtmlRenderer from "../../../../../../common/components/HTMLRenderer/HTMLRenderer";

interface Props {
  items: { name: string, value: string | number, isHTML?: boolean }[];
  isGrid?: boolean;
  title: string;
  onEdit: () => void;
}

const AddonInfoSection = (
  {
    items,
    isGrid,
    title,
    onEdit
  } : Props
) => {
  return (
    <Panel
      className='addon-info panel-shift'
      title={
        <>
          <div className="h6">{title}</div>
          <LinkButton
            onClick={onEdit}
          >
            Edit
          </LinkButton></>
      }
    >
      <div
        className={isGrid ? 'grid' : ''}
      >
        {
          items.map((item, index) => (
            <div key={index} className="package-item">
              <div className="package-item-name subtitle2">{item.name}</div>
              <div className="package-item-value body2">{item.isHTML ? <HtmlRenderer html={item.value as string}/> : item.value}</div>
            </div>
          ))
        }
      </div>
    </Panel>
  );
};

export default AddonInfoSection;