import { PaymentType } from '../entities/enums';
export class TransactionSettingDto {
  id: string;
  date: Date;
  amount: number;
  referenceNumber: string;
  transactionId: string;
  isRefund: boolean;
  isCancelled: boolean;
  isIncomplete: boolean;
  isUncaptured: boolean;
  isModified: boolean;
  isFailed: boolean;
  card: string;
  cardType: string;
  paymentType: PaymentType;
  info?: string;
  markAsPaidSource?: string
  managersCompensation?: number;
  stripeTerminal?: string;
}
