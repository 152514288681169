import { createSelector } from "reselect";
import { PartnerState, State, PartnerDto, TenantUserDto } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: PartnerState = Object.freeze({
  isRequestInProgress: false,
  partners: [],
  users: [],
  selectedUser: undefined,
});

export default createReducer<PartnerState>(initialState, {
  [ActionTypeEnum.GetPartners]: () => (state: PartnerState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetPartnersSuccess]:
    (partners: PartnerDto[]) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      partners,
      error: "",
    }),
  [ActionTypeEnum.GetPartnersFailure]:
    (error: string) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      partners: [],
      error,
    }),
  [ActionTypeEnum.CreatePartner]: () => (state: PartnerState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.CreatePartnerSuccess]:
    (partner: PartnerDto) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      partner,
      partners: [...state.partners, partner],
      error: "",
    }),
  [ActionTypeEnum.CreatePartnerFailure]:
    (error: string) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.UpdatePartner]: () => (state: PartnerState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.UpdatePartnerSuccess]:
    (partner: PartnerDto) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      partner,
      partners: state.partners.map((item) =>
        item.id === partner.id ? partner : item
      ),
      error: "",
    }),
  [ActionTypeEnum.UpdatePartnerFailure]:
    (error: string) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.DeletePartner]: () => (state: PartnerState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.DeletePartnerSuccess]:
    (id: string) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      partners: state.partners.filter((item) => item.id !== id),
      error: "",
    }),
  [ActionTypeEnum.DeletePartnerFailure]:
    (error: string) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.SetPartner]:
    (partner?: PartnerDto) => (state: PartnerState) => ({
      ...state,
      partner,
    }),
  [ActionTypeEnum.GetPartner]: () => (state: PartnerState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetPartnerSuccess]:
    (partner: PartnerDto) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      partner,
      error: "",
    }),
  [ActionTypeEnum.GetPartnerFailure]:
    (error: string) => (state: PartnerState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.CreateUserSuccess]:
    ({ user }: { user: TenantUserDto }) =>
    (state: PartnerState) => {
      let partner = state.partner;
      if (partner && partner.id === user.partnerId) {
        partner = { ...partner, users: [...partner.users, user] };
      }
      const partners = state.partners.map((item) =>
        item.id === partner?.id ? partner : item
      );
      return {
        ...state,
        partner,
        partners,
        isRequestInProgress: false,
        error: "",
      };
    },
  
  [ActionTypeEnum.UpdateUserSuccess]:
    ({ user }: { user: TenantUserDto}) =>
    (state: PartnerState) => {
      let partner = state.partner;
      if (partner && partner.id === user.partnerId) {
        partner = {
          ...partner,
          users: partner.users.map((item) =>
            item.id === user?.id ? user : item
          ),
        };
      }
      const partners = state.partners.map((item) =>
        item.id === partner?.id ? partner : item
      );
      return {
        ...state,
        partner,
        partners,
        isRequestInProgress: false,
        error: "",
      };
    },
  
  [ActionTypeEnum.RemoveUserSuccess]:
    ({ id }: { id: string }) =>
    (state: PartnerState) => {
      if (!state.partner) {
        return state
      }
      const partner = {
        ...state.partner,
        users: state.partner.users.filter((item) => item.id !== id),
      };
      
      const partners = state.partners.map((item) =>
        item.id === partner?.id ? partner : item
      );
      return {
        ...state,
        partner,
        partners,
        isRequestInProgress: false,
        error: "",
      };
    },
  [ActionTypeEnum.SetPartnerError]:
    (error: string) => (state: PartnerState) => ({
      ...state,
      error,
    }),
});

export const selectPartnersState = (state: State) => state.partners;
export const selectPartners = createSelector(
  selectPartnersState,
  (state) => state.partners
);

export const selectIsPartnerRequestInProgress = createSelector(
  selectPartnersState,
  (state) => state.isRequestInProgress
);
export const selectPartnerError = createSelector(
  selectPartnersState,
  (state) => state.error
);
export const selectPartner = createSelector(
  selectPartnersState,
  (state) => state.partner
);
export const selectPartnerUsers = createSelector(
  selectPartnersState,
  (state) => state.users
);
export const selectSelectedPartnerUser = createSelector(
  selectPartnersState,
  (state) => state.selectedUser
);
