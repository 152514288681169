import { ActionTypeEnum, AppThunk } from ".";
import { postWithAuth, getWithAuth } from "./api";
import {
  selectVenueIdFilterReport,
  selectTimeInterval,
  selectStartDate,
  selectEndDate,
  selectReportFilterParameters,
  selectVenueIdsForFilterReportV2,
} from "../reducers/reports";
import { TimeInterval, ReportQueryParams, FilterReservationParameters, ReportV2Dto, ReportV2QueryParams } from "../store/types";
import { addErrorAction } from "./auth-actions";

export const REPORTS_URL = "/api/reservation/settings/reports";

export interface TimeIntervalRequest {
  timeInterval: TimeInterval;
  startDate?: string;
  endDate?: string;
}

export const setTimeIntervalAction = ({
  timeInterval,
  startDate,
  endDate,
}: TimeIntervalRequest): AppThunk => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionTypeEnum.GetReportsRequest });
    dispatch({
      type: ActionTypeEnum.SetReportInterval,
      payload: { timeInterval, startDate, endDate },
    });
    const venueId = selectVenueIdFilterReport(getState());
    const filterParameters = selectReportFilterParameters(getState());
    const query: ReportQueryParams = {
      venueId: venueId && venueId !== "all" ? venueId : undefined,
      interval: timeInterval,
      startDate,
      endDate,
      filterParameters,
    };
    const response = await postWithAuth(`${REPORTS_URL}`, query);
    dispatch({
      type: ActionTypeEnum.GetReportsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.GetReportsFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};

export const setVenueFilterAction = (venueId?: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetReportsRequest });
    dispatch({ type: ActionTypeEnum.SetVenueIdFilterReports, payload: venueId });
    const interval = selectTimeInterval(getState());
    const startDate = selectStartDate(getState());
    const endDate = selectEndDate(getState());
    const filterParameters = selectReportFilterParameters(getState());
    const query: ReportQueryParams = {
      venueId: venueId && venueId !== "all" ? venueId : undefined,
      interval,
      startDate,
      endDate,
      filterParameters,
    };
    const response = await postWithAuth(`${REPORTS_URL}`, query);
    dispatch({
      type: ActionTypeEnum.GetReportsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.GetReportsFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};

export const downloadReportAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch({ type: ActionTypeEnum.ReportDownloadRequest });
  try {
    const interval = selectTimeInterval(getState());
    const startDate = selectStartDate(getState());
    const endDate = selectEndDate(getState());
    const venueId = selectVenueIdFilterReport(getState());
    const filterParameters = selectReportFilterParameters(getState());
    const query: ReportQueryParams = {
      venueId: venueId && venueId !== "all" ? venueId : undefined,
      interval,
      startDate,
      endDate,
      filterParameters,
    };
    const response = await getWithAuth(`${REPORTS_URL}`, {
      ...query,
      responseType: "blob",
    });
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch({ type: ActionTypeEnum.ReportDownloadSuccess });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.ReportDownloadFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};

export const setFilterParametersForReportAction = (
  filterParameters: FilterReservationParameters[],
): AppThunk => async (
  dispatch,
  getState
) => {
  try {
  dispatch({ type: ActionTypeEnum.GetReportsRequest });
  dispatch({ type: ActionTypeEnum.SetFilterParametersForReport, payload: filterParameters});
  const interval = selectTimeInterval(getState());
    const startDate = selectStartDate(getState());
    const endDate = selectEndDate(getState());
    const venueId = selectVenueIdFilterReport(getState());
    const query: ReportQueryParams = {
      venueId: venueId && venueId !== "all" ? venueId : undefined,
      interval,
      startDate,
      endDate,
      filterParameters, 
    };
    const response = await postWithAuth(`${REPORTS_URL}`, query);
    dispatch({
      type: ActionTypeEnum.GetReportsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get report error", e);
    dispatch({
      type: ActionTypeEnum.GetReportsFailure,
      payload: "GetReports failure",
    });
    dispatch(addErrorAction("GetReports failure"));
  }
};

export const getReportsV2Action = ({
  timeInterval,
  startDate,
  endDate,
}: TimeIntervalRequest): AppThunk => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionTypeEnum.GetReportsV2Request });
    dispatch({
      type: ActionTypeEnum.SetReportInterval,
      payload: { timeInterval, startDate, endDate },
    });
    const venueId = selectVenueIdFilterReport(getState());
    const filterParameters = selectReportFilterParameters(getState());
    const query: ReportQueryParams = {
      venueId: venueId && venueId !== "all" ? venueId : undefined,
      interval: timeInterval,
      startDate,
      endDate,
      filterParameters,
    };
    const response = await postWithAuth<ReportV2Dto>(`${REPORTS_URL}-v2`, query);
    dispatch({
      type: ActionTypeEnum.GetReportsV2Success,
      payload: response.data,
    });
  } catch (e) {
    console.log("get report v2 error", e);
    dispatch({
      type: ActionTypeEnum.GetReportsV2Failure,
      payload: "Get Reports V2 failure",
    });
    dispatch(addErrorAction("Get Reports V2 failure"));
  }
};

export const setVenueFilterV2Action = (venueIds?: string[]): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetReportsV2Request });
    dispatch({ type: ActionTypeEnum.SetVenueIdsFilterReportsV2, payload: venueIds });
    const interval = selectTimeInterval(getState());
    const startDate = selectStartDate(getState());
    const endDate = selectEndDate(getState());
    const query: ReportV2QueryParams = {
      venueIds: venueIds ? venueIds : undefined,
      interval,
      startDate,
      endDate,
    };
    const response = await postWithAuth<ReportV2Dto>(`${REPORTS_URL}-v2`, query);
    dispatch({
      type: ActionTypeEnum.GetReportsV2Success,
      payload: response.data,
    });
  } catch (e) {
    console.log("get report v2 error", e);
    dispatch({
      type: ActionTypeEnum.GetReportsFailure,
      payload: "Get Reports v2 failure",
    });
    dispatch(addErrorAction("Get Reports v2 failure"));
  }
};

export const setTimeIntervalV2Action = ({
  timeInterval,
  startDate,
  endDate,
}: TimeIntervalRequest): AppThunk => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionTypeEnum.GetReportsV2Request });
    dispatch({
      type: ActionTypeEnum.SetReportInterval,
      payload: { timeInterval, startDate, endDate },
    });
    const venueIds = selectVenueIdsForFilterReportV2(getState());
    const query: ReportV2QueryParams = {
      venueIds,
      interval: timeInterval,
      startDate,
      endDate,
    };
    const response = await postWithAuth(`${REPORTS_URL}-v2`, query);
    dispatch({
      type: ActionTypeEnum.GetReportsV2Success,
      payload: response.data,
    });
  } catch (e) {
    console.log("get report v2 error", e);
    dispatch({
      type: ActionTypeEnum.GetReportsV2Failure,
      payload: "Get Reports v2 failure",
    });
    dispatch(addErrorAction("Get Reports v2 failure"));
  }
};