import React, { useEffect } from 'react';
import ActionsButton from "../common/ActionsButton";
import { DetailsList, DetailsListLayoutMode, IColumn, Icon, ScrollablePane, SelectionMode } from "office-ui-fabric-react";
import './addons.scss'
import FormSearchField from "../common/FormSearchField";
import LocationIcon from "../../assets/locations-icon.svgr";
import ColorButton from "../../../../common/components/ColorButton";
import { webConfigUI } from "../../constants/webConfigUI";
import { getAddonsAction, selectAddonAction, setFilteredAddonsAction } from "../../actions/addon-action";
import { selectAddons, selectFilteredAddons } from "../../reducers/addons";
import { AddonSettingDto, State } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import EditAddon from '../AddonsDetails/General/Edit/EditAddon';
import { selectReadonly } from '../../reducers/auth-reducer';

interface CellData {
  status: boolean;
}

const mapDispatchToProps = {
  getAddons: getAddonsAction,
  selectAddon: selectAddonAction,
  setFilteredAddons: setFilteredAddonsAction,
}

const mapStateToProps = (state: State) => ({
  addons: selectAddons(state),
  filteredAddons: selectFilteredAddons(state),
  readonly: selectReadonly(state),
})

const columns: IColumn[] = [
  {
    key: "internalName",
    name: "ADDON INTERNAL",
    fieldName: "internalName",
    minWidth: 130,
    maxWidth: 200,
    isResizable: true,
    className: "bold-column subtitle2",
    data: "string",
    isPadded: true,
  },
  {
    key: "name",
    name: "ADDON",
    fieldName: "name",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "bold-column subtitle2",
    data: "string",
    isPadded: true,
  },
  // {
  //   key: "type",
  //   name: "TYPE",
  //   fieldName: "type",
  //   minWidth: 80,
  //   maxWidth: 150,
  //   isResizable: true,
  //   className: "column body2",
  //   data: "string",
  //   isPadded: true,
  // },
  {
    key: "method",
    name: "PRICING METHOD",
    fieldName: "method",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
  },
  {
    key: "priority",
    name: "PRIORITY",
    fieldName: "priority",
    minWidth: 45,
    maxWidth: 100,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
    isSorted: true,
    isSortedDescending: false,
  },
  {
    key: "status",
    name: "STATUS",
    fieldName: "status",
    minWidth: 130,
    maxWidth: 250,
    isResizable: true,
    className: "column body2",
    data: "string",
    isPadded: true,
    onRender: (item: CellData) => {
      const statusText = item.status ? "Active" : "Inactive";

      return (
        <div className="status-container">
          <div className={`status-color ${item.status ? "green" : "red"}`} />
          <div className={`status-text body2 ${item.status ? "green" : "red"}`}>
            {statusText}
          </div>
        </div>
      );
    },
  },
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  setMainContainerUI: (view: webConfigUI) => void;
};

const Addons = (
  {
    getAddons,
    addons,
    filteredAddons,
    setMainContainerUI,
    selectAddon,
    setFilteredAddons,
    readonly,
  }: Props
) => {
  useEffect(() => {
    getAddons()
  }, [getAddons]);

  useEffect(() => {
    addons && setFilteredAddons(addons);
  }, [addons]);
  const [isCreating, setIsCreating] = React.useState(false);

  const onItemInvoked = (item: any): void => {
    selectAddon(item as AddonSettingDto);
    setMainContainerUI(webConfigUI.ADDON_DETAILS);
  };

  const onFilter = (ev: any, searchText: string | undefined = ""): void => {
    setFilteredAddons(
      searchText
        ? addons.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase())
        )
        : addons
    );
  };

  const addAddon = () => {
    selectAddon();
    setIsCreating(true);
  };

  return (
    <>
      <div className="addons">
        <div className="addons-header">
          <h1 className="h4 title">
            Add Ons
          </h1>
          <ActionsButton
            onClick={addAddon}
            disabled={readonly}
          >
            <Icon iconName="Add" className="add-icon" />
            <div className="add-button-text">
              Add on
            </div>
          </ActionsButton>
        </div>
        <div className="addon-list-container">
          <div className="searchbox-block">
            <FormSearchField
              placeholder="Search..."
              onChange={onFilter}
            />
          </div>
        </div>
        {addons?.length > 0 ? (
          <div className="addons-list">
            <ScrollablePane>
              <DetailsList
                items={filteredAddons}
                styles={{ focusZone: { cursor: "pointer" } }}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={onItemInvoked}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-addon-list-container">
            <div className="empty-addon-list">
              <LocationIcon className="location-icon" />
              <div className="empty-addon-list-message">
                Let’s get your first add on set up!
              </div>
              <ColorButton
                className="empty-addon-list-button"
                onClick={addAddon}
                disabled={readonly}
              >
                <Icon iconName="Add" className="add-icon" />
                <div className="add-button-text">
                  Add on
                </div>
              </ColorButton>
            </div>
          </div>
        )}
      </div>
      {isCreating && <EditAddon isCloneAddon={false} onClose={() => setIsCreating(false)} />}
    </>
  );
};

export default connector(Addons);