import React from "react";
import "./buttonsRow.scss";

const ButtonsRow = ({
  children
}: React.PropsWithChildren<{}>) => (
  <div className="buttons-row">
    {children}
  </div>
);

export default ButtonsRow;
