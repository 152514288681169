import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    IDropdownOption,
    Toggle,
    IconButton,
    Icon,
} from "office-ui-fabric-react";
import { updateVenueAction } from "../../../actions/venue-actions";
import { selectVenue } from "../../../reducers/venues";
import { selectReadonly } from "../../../reducers/auth-reducer";
import { State } from "../../../store/types";
import { useViewport } from "../../../hooks/responsive";
import { getTime } from "../../../utils/formats";
import TrashIcon from "../../../assets/trash-icon.svgr";
import Panel from '../../common/Panel';
import FormSelectField from "../../common/FormSelectField";
import LinkButton from '../../../../../common/components/LinkButton';
import ColorButton from "../../../../../common/components/ColorButton";
import VenueMainActions from '../VenueMainActions';
import EditTimeSlotShifting from "./EditTimeSlotShifting";
import "./venueAdvanced.scss";

const mapStateToProps = (state: State) => ({
    venue: selectVenue(state),
    readonly: selectReadonly(state),
});

const mapDispatchToProps = {
    updateVenue: updateVenueAction,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>

interface LanguageItem {
    name: string;
    value: string;
}
interface AssignDuration { id: number, guests: string, duration: string }

const renderListItem = (item: LanguageItem, index: number) => (<div key={index} className="advanced-item"><div className="advanced-item-name subtitle2">{item.name}</div><div className="advanced-item-value body2">{item.value}</div></div>);

const durationOptions: IDropdownOption[] = Array(8).fill(null).map(
    (_, index) => ({
      key: index + 1,
      text: `${index + 1} time slot${index !== 0 ? "s" : ""}`,
    })
);

const guestOptions: IDropdownOption[] = Array(20).fill(null).map(
    (_, index) => ({
      key: index + 1,
      text: `${index + 1} guest${index !== 0 ? "s" : ""}`,
    })
);

const VenueAdvanced = ({
    venue,
    readonly,
    updateVenue,
}: Props) => {
    const expendAssignDuration = (o: string): AssignDuration[] => {
        return o.split(';').map((item, index) => ({ id: index, guests: item.split(':')[0] || '', duration: item.split(':')[1] || '' }))
    }
    const foldAssignDuration = (o: AssignDuration[]) => {
        return o.map(item => `${item.guests}:${item.duration}`).join(';')
    }
    const onUpdateAssignDuration = (o: AssignDuration) => {
        const newValue = newAssignDuration.map(item => item.id === o.id ? o : item)
        setNewAssignDuration(newValue)
    }
    const onAddAssignDuration = () => {
        setNewAssignDuration([...newAssignDuration, { id: newAssignDuration.length, guests: '', duration: '' }])
    }
    const onRemoveAssignDuration = (o: AssignDuration) => {
        const newValue = newAssignDuration.filter(item => item.id !== o.id).map((item, index) => ({ ...item, id: index }))
        setNewAssignDuration(newValue)
    }
    const onUpdateAssignDurationForAdmin = (o: AssignDuration) => {
        const newValue = newAssignDurationForAdmin.map(item => item.id === o.id ? o : item)
        setNewAssignDurationForAdmin(newValue)
    }
    const onAddAssignDurationForAdmin = () => {
        setNewAssignDurationForAdmin([...newAssignDurationForAdmin, { id: newAssignDurationForAdmin.length, guests: '', duration: '' }])
    }
    const onRemoveAssignDurationForAdmin = (o: AssignDuration) => {
        const newValue = newAssignDurationForAdmin.filter(item => item.id !== o.id).map((item, index) => ({ ...item, id: index }))
        setNewAssignDurationForAdmin(newValue)
    }
    const { isMiddleScreen } = useViewport();
    const [isOpenScheduleShifting, setIsOpenScheduleShifting] = useState(false);
    const [enableAssignDuration, setEnableAssignDuration] = useState(venue?.enableAssignDuration || false);
    const [newAssignDuration, setNewAssignDuration] = useState<AssignDuration[]>(expendAssignDuration(venue?.assignDuration || ''));
    const [newAssignDurationForAdmin, setNewAssignDurationForAdmin] = useState<AssignDuration[]>(expendAssignDuration(venue?.assignDurationForAdmin || ''));

    const scheduleShifting: LanguageItem[] = [
        { name: 'Monday', value: getTime(venue?.timeSlotShifting[1]) },
        { name: 'Tuesday', value: getTime(venue?.timeSlotShifting[2]) },
        { name: 'Wednesday', value: getTime(venue?.timeSlotShifting[3]) },
        { name: 'Thursday', value: getTime(venue?.timeSlotShifting[4]) },
        { name: 'Friday', value: getTime(venue?.timeSlotShifting[5]) },
        { name: 'Saturday', value: getTime(venue?.timeSlotShifting[6]) },
        { name: 'Sunday', value: getTime(venue?.timeSlotShifting[0]) },
    ]

    const onSaveAssignDuration = () => {
        const patch = {
            enableAssignDuration: !!enableAssignDuration,
            assignDuration: foldAssignDuration(newAssignDuration),
            assignDurationForAdmin: foldAssignDuration(newAssignDurationForAdmin),
        };
        updateVenue({ ...venue, ...patch });
    };

    const scheduleShiftingTitle = (<><div className="h6">Schedule Shifting</div><LinkButton onClick={() => setIsOpenScheduleShifting(true)}>Edit</LinkButton></>);
    const assignDurationSettingsTitle = (
        <>
            <div className="h6">Assign Duration</div>
            <div className="title-buttons">
                <Toggle
                    className="toggle"
                    checked={enableAssignDuration}
                    onChange={(_: any, value?: boolean) => setEnableAssignDuration(!!value)}
                    disabled={readonly}
                />
            </div>
        </>
    );
    const assignDurationForAdminSettingsTitle = (<><div className="h6">Assign Duration for admin</div></>);
    const filterGuestOption = (id: number) => {
        if(id === 0) {
            return guestOptions.filter(option => option.key === 1)
        }
        return guestOptions.filter(option => option.key !== 1)
    } 
    return (
        <div className={`venue-advanced ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="left-panel">
                <Panel className="panel-shift" title={scheduleShiftingTitle}>
                    {scheduleShifting.map((item, index) => renderListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift" title={assignDurationSettingsTitle}>
                    {newAssignDuration.map(item => (
                        <div className={`row assign-duration-fields ${isMiddleScreen ? "mobile" : ""}`} key={item.id}>
                            <FormSelectField
                                label="Number of guest"
                                className="integrations-field"
                                options={filterGuestOption(item.id)}
                                selectedKey={+item.guests}
                                onChange={(_: any, option) => {
                                    onUpdateAssignDuration({ ...item, guests: option?.key ? (+option?.key).toString() : '' })
                                }}
                                disabled={readonly || !enableAssignDuration}
                            />
                            <FormSelectField
                                label="Duration in slot"
                                className="integrations-field"
                                options={durationOptions}
                                selectedKey={+item.duration}
                                onChange={(_: any, option) => {
                                    onUpdateAssignDuration({ ...item, duration: option?.key ? (+option?.key).toString() : '' })
                                }}
                                disabled={readonly || !enableAssignDuration}
                            />
                            <IconButton
                                className="delete-button"
                                ariaLabel="Remove duration"
                                onClick={() => onRemoveAssignDuration(item)}
                                disabled={readonly || !enableAssignDuration}
                            >
                                <TrashIcon />
                            </IconButton>
                        </div>
                    ))}
                    <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                        <LinkButton
                            className="add-button"
                            onClick={onAddAssignDuration}
                            disabled={readonly || !enableAssignDuration}
                        >
                            <Icon iconName="Add" className="add" />
                            Add item
                        </LinkButton>
                    </div>
                    <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                        <ColorButton
                            className="save-button"
                            onClick={onSaveAssignDuration}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                </Panel>
                <Panel className="panel-shift" title={assignDurationForAdminSettingsTitle}>
                    {newAssignDurationForAdmin.map(item => (
                        <div className={`row assign-duration-fields ${isMiddleScreen ? "mobile" : ""}`} key={item.id}>
                            <FormSelectField
                                label="Number of guest"
                                className="integrations-field"
                                options={filterGuestOption(item.id)}
                                selectedKey={+item.guests}
                                onChange={(_: any, option) => {
                                    onUpdateAssignDurationForAdmin({ ...item, guests: option?.key ? (+option?.key).toString() : '' })
                                }}
                                disabled={readonly || !enableAssignDuration}
                            />
                            <FormSelectField
                                label="Duration in slot"
                                className="integrations-field"
                                options={durationOptions}
                                selectedKey={+item.duration}
                                onChange={(_: any, option) => {
                                    onUpdateAssignDurationForAdmin({ ...item, duration: option?.key ? (+option?.key).toString() : '' })
                                }}
                                disabled={readonly || !enableAssignDuration}
                            />
                            <IconButton
                                className="delete-button"
                                ariaLabel="Remove duration"
                                onClick={() => onRemoveAssignDurationForAdmin(item)}
                                disabled={readonly || !enableAssignDuration}
                            >
                                <TrashIcon />
                            </IconButton>
                        </div>
                    ))}
                    <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                        <LinkButton
                            className="add-button"
                            onClick={onAddAssignDurationForAdmin}
                            disabled={readonly || !enableAssignDuration}
                        >
                            <Icon iconName="Add" className="add" />
                            Add item
                        </LinkButton>
                    </div>
                    <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                        <ColorButton
                            className="save-button"
                            onClick={onSaveAssignDuration}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                </Panel>
            </div>
            <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
                <VenueMainActions />
            </div>
            {isOpenScheduleShifting && <EditTimeSlotShifting onClose={() => setIsOpenScheduleShifting(false)} />}
        </div>
    );
};

export default connector(VenueAdvanced);