import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import {
  DefaultButton,
  Callout,
  mergeStyleSets,
  DirectionalHint,
} from "office-ui-fabric-react";
import {
  removeScheduleAction,
  removeVenueAction,
} from "../../actions/venue-actions";
import { setVenuePageAction, setWebConfigUIAction } from "../../actions/ui-actions";
import { venueConfigUI, webConfigUI } from "../../constants/webConfigUI";
import { selectCurrentSchedule, selectIsLoadingVenue, selectVenue } from "../../reducers/venues";
import {
  State,
  ScheduleSettingDto,
  CreateScheduleSettingDto,
  InventoryMethodTypes,
} from "../../store/types";
import VenueInfo from "./VenueInfo";
import VenueLanguage from "./VenueLanguage";
import VenueAdvanced from "./VenueAdvanced";
import VenueIntegrations from "./VenueIntegrations";
import EditVenueInfo from "./EditVenueInfo/EditVenueInfo";
import EditSchedule from "./EditSchedule/EditSchedule";
import CurrentSchedule from "../common/CurrentSchedule/CurrentSchedule";
import DeleteConfirmation from "../common/DeleteConfirmation/DeleteConfirmation";
import BackButton from "../common/BackButton/BackButton";
import ActionsButton from "../common/ActionsButton";
import ChevronDown from "../../assets/custom-chevron-down.svgr";
import { selectReadonly, selectVenuePage } from "../../reducers/auth-reducer";
import { useViewport } from "../../hooks/responsive";
import "./venueDetails.scss";
import Packages from "./Packages";
import PackageDetails from "./Packages/PackageDetails/PackageDetails";
import Resources from "./Resources";
import EditResourceCategory from "./Resources/EditResourceCategory";
import HostSettings from "./HostSettings";


const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "150px",
    maxHeight: "192px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const DATE_FORMAT = "MM/DD/YYYY";
const newSchedule = {
  title: "",
  priority: 1,
  start: dayjs().format(DATE_FORMAT),
  end: dayjs().add(10, "d").format(DATE_FORMAT),
  inventoryMethod: InventoryMethodTypes.byLanes,
  timeSlots: [],
  startTimeOnly: Array(7).fill(false).join(','),
};

const mapDispatchToProps = {
  removeSchedule: removeScheduleAction,
  removeVenue: removeVenueAction,
  setWebConfigUI: setWebConfigUIAction,
  setVenuePage: setVenuePageAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  readonly: selectReadonly(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  currentSchedule: selectCurrentSchedule(state),
  venuePage: selectVenuePage(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const VenueDetails = ({
  removeSchedule,
  removeVenue,
  setWebConfigUI,
  setVenuePage,
  venue,
  readonly,
  isLoadingVenue,
  currentSchedule,
  venuePage,
}: Props) => {
  const { isMiddleScreen } = useViewport();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenResourceCategoryEdit, setIsOpenResourceCategoryEdit] = useState(false);
  const [isOpenScheduleEdit, setIsOpenScheduleEdit] = useState(false);
  const [schedule, setSchedule] = useState<
    ScheduleSettingDto | CreateScheduleSettingDto
  >({ ...newSchedule, venueId: venue?.id || "" });
  const [isCloneVenue, setIsCloneVenue] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isRemoveVenueOpen, setIsRemoveVenueOpen] = useState(false);
  const [scheduleId, setScheduleId] = useState("");
  const [isOpenCurrentSchedule, setIsOpenCurrentSchedule] = useState(false);
  const [isShowActionsMenu, setIsShowActionsMenu] = useState<boolean>(false);
  const editVenue = () => {
    setIsCloneVenue(false);
    setIsOpenEdit(true);
  };
  const onRemoveVenue = () => setIsRemoveVenueOpen(true);
  const addSchedule = () => {
    setSchedule({ ...newSchedule, venueId: venue?.id || "" });
    setIsOpenScheduleEdit(true);
  };
  const cloneVenue = () => {
    setIsCloneVenue(true);
    setIsOpenEdit(true);
  };
  const cloneSchedule = (schedule: ScheduleSettingDto) => {
    const { id, ...withoutId } = schedule;
    const newSchedule = {
      ...withoutId,
      title: "",
      changed: true,
      isNew: true,
      priority: schedule.priority + 1,
      timeSlots: schedule.timeSlots.map((item) => ({
        ...item,
        id: nanoid(),
        venueId: schedule.venueId,
        scheduleId: "new",
        changed: true,
        isNew: true,
      })),
    };
    setSchedule(newSchedule);
    setIsOpenScheduleEdit(true);
  };
  const editSchedule = (schedule: ScheduleSettingDto) => {
    setSchedule(schedule);
    setIsOpenScheduleEdit(true);
  };
  const removeVenueSchedule = (id: string) => {
    setScheduleId(id);
    setIsRemoveOpen(true);
  };
  const confirmedRemoveVenueSchedule = (isConfirm: boolean) => {
    if (isConfirm) {
      removeSchedule(scheduleId);
    }
    closeRemoveDialog();
  }
  const closeRemoveDialog = () => {
    setScheduleId("");
    setIsRemoveOpen(false);
  };
  const closeRemoveVenueDialog = () => {
    setIsRemoveVenueOpen(false);
  };
  const confirmedRemoveVenue = (isConfirm: boolean) => {
    if (isConfirm && venue) {
      removeVenue(venue?.id);
      setWebConfigUI(webConfigUI.VENUES);
    }
    closeRemoveVenueDialog();
  }
  const backToList = () => {
    if (venuePage === venueConfigUI.PACKAGE_DETAILS) {
      setVenuePage(venueConfigUI.PACKAGES);
    } else {
      setWebConfigUI(webConfigUI.VENUES)
    }
  }

  const onAddCategory = () => {
    setVenuePage(venueConfigUI.RESOURCES);
    setIsOpenResourceCategoryEdit(true)
  }

  const renderContent = () => {
    switch (venuePage) {
      case venueConfigUI.GENERAL:
        return (
          <VenueInfo
            isMiddleScreen={isMiddleScreen}
            readonly={readonly}
            venue={venue}
            editVenue={editVenue}
            addSchedule={addSchedule}
            editSchedule={editSchedule}
            cloneSchedule={cloneSchedule}
            removeVenueSchedule={removeVenueSchedule}
          />
        );

      case venueConfigUI.LANGUAGE:
        return (
          <VenueLanguage />
        );

      case venueConfigUI.INTEGRATIONS:
        return (
          <VenueIntegrations />
        );

      case venueConfigUI.ADVANCED:
        return (
          <VenueAdvanced />
        );
      case venueConfigUI.PACKAGES:
        return (
          <Packages
            venueId={venue?.id}
            timeSlotShifting={venue?.timeSlotShifting}
          />
        );
      case venueConfigUI.PACKAGE_DETAILS:
        return (
          <PackageDetails
            readonly={readonly}
            timeSlotShifting={venue?.timeSlotShifting}
          />
        );
      case venueConfigUI.RESOURCES:
        return (
          <Resources
            venueId={venue?.id}
          />
        );
      case venueConfigUI.HOST:
        return (
          <HostSettings
            isMiddleScreen={isMiddleScreen}
            venue={venue}
            editVenue={editVenue}
          />
        );
      default:
        return (
          <div />
        );
    }
  }
  const content = renderContent()

  return (
    <div className="venue-details-container">
      <div className="venue-details">
        <BackButton
          text={venuePage === venueConfigUI.PACKAGE_DETAILS ? "All Packages" : "Venues"}
          onBack={backToList}
        />
        <div className="venue-details-information-container">
          <div className="venue-details-information-header">
            <div className="venue-details-title h4">
              {venue?.name}
            </div>
            <div className="actions-container">
              <ActionsButton
                id="actions-button"
                onClick={() => setIsShowActionsMenu(true)}
              >
                <div className="actions-button-text">
                  Actions
                </div>
                <ChevronDown className="chevron" />
              </ActionsButton>
              {isShowActionsMenu && (
                <Callout
                  className={styles.callout}
                  gapSpace={0}
                  target={`#actions-button`}
                  onDismiss={() => setIsShowActionsMenu(false)}
                  directionalHint={DirectionalHint.bottomRightEdge}
                  isBeakVisible={false}
                >
                  <div className="actions-button-container">
                    <DefaultButton
                      className="actions-button"
                      onClick={() => setIsOpenCurrentSchedule(true)}
                    >
                      <div className="actions-text">
                        View Calendar
                      </div>
                    </DefaultButton>
                    <DefaultButton
                      className="actions-button"
                      onClick={cloneVenue}
                    >
                      <div className="actions-text">
                        Clone Venue
                      </div>
                    </DefaultButton>
                    <DefaultButton
                      className="actions-button"
                      onClick={onRemoveVenue}
                    >
                      <div className="actions-text">
                        Delete Venue
                      </div>
                    </DefaultButton>
                    <DefaultButton
                      className="actions-button"
                      onClick={onAddCategory}
                    >
                      <div className="actions-text">
                        Add Resource Category
                      </div>
                    </DefaultButton>
                  </div>
                </Callout>
              )}
            </div>
          </div>
          <div className="venue-details-toggle">
            <div
              className={`venue-details-toggle-label body2 ${venuePage === venueConfigUI.GENERAL ? "active" : ""}`}
              onClick={() => setVenuePage(venueConfigUI.GENERAL)}
            >
              General
            </div>
            <div
              className={`venue-details-toggle-label body2 ${venuePage === venueConfigUI.PACKAGES || venuePage === venueConfigUI.PACKAGE_DETAILS ? "active" : ""
                }`}
              onClick={() => setVenuePage(venueConfigUI.PACKAGES)}
            >
              Packages
            </div>
            <div
              className={`venue-details-toggle-label body2 ${venuePage === venueConfigUI.HOST ? "active" : ""}`}
              onClick={() => setVenuePage(venueConfigUI.HOST)}
            >
              Host
            </div>
            <div
              className={`venue-details-toggle-label body2 ${venuePage === venueConfigUI.RESOURCES ? "active" : ""}`}
              onClick={() => setVenuePage(venueConfigUI.RESOURCES)}
            >
              Resources
            </div>
            <div
              className={`venue-details-toggle-label body2 ${venuePage === venueConfigUI.LANGUAGE ? "active" : ""}`}
              onClick={() => setVenuePage(venueConfigUI.LANGUAGE)}
            >
              Language
            </div>
            <div
              className={`venue-details-toggle-label body2 ${venuePage === venueConfigUI.INTEGRATIONS ? "active" : ""}`}
              onClick={() => setVenuePage(venueConfigUI.INTEGRATIONS)}
            >
              Integrations
            </div>
            <div
              className={`venue-details-toggle-label body2 ${venuePage === venueConfigUI.ADVANCED ? "active" : ""}`}
              onClick={() => setVenuePage(venueConfigUI.ADVANCED)}
            >
              Advanced
            </div>
          </div>
          {content}
        </div>
      </div>
      {isOpenEdit && <EditVenueInfo isCloneVenue={isCloneVenue} onClose={() => setIsOpenEdit(false)} />}
      {isOpenScheduleEdit && <EditSchedule
        onClose={() => setIsOpenScheduleEdit(false)}
        schedule={schedule}
        timeShifting={venue?.timeSlotShifting || []}
        ageForPolicy={venue?.ageForPolicy || '21'}
      />}
      {isOpenCurrentSchedule && <CurrentSchedule
        onClose={() => setIsOpenCurrentSchedule(false)}
        isLoadingVenue={isLoadingVenue}
        currentSchedule={currentSchedule}
      />}
      {isRemoveOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this schedule?"}
        ConfirmationButtonText={"Delete Schedule"}
        onClose={confirmedRemoveVenueSchedule}
      />}
      {isRemoveVenueOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this venue?"}
        ConfirmationButtonText={"Delete Venue"}
        onClose={confirmedRemoveVenue}
      />}
      {isOpenResourceCategoryEdit && <EditResourceCategory venueId={venue?.id} onClose={() => setIsOpenResourceCategoryEdit(false)} />}
    </div>
  );
};

export default connector(VenueDetails);
