import { useState } from "react"
import { getWithAuth, postWithAuth } from "../actions/api"

const SYNC_DB_URL = '/api/sync-db'


const useTransferSync = ({ successFunc, errorFunc }: { successFunc?: () => void, errorFunc?: () => void }) => {
    const [isSyncLoading, setIsSyncLoading] = useState(false)
    const [syncLog, setSyncLog] = useState([])


    const getHistory = async (tenantId: number | string) => {
        setIsSyncLoading(true)

        const res = await getWithAuth(`${SYNC_DB_URL}/${tenantId}`)
        setIsSyncLoading(false)

        setSyncLog(res.data || [])
        return res
    }


    const sync = async (tenantId: number | string) => {
        setIsSyncLoading(true);

        try {
            const res = await postWithAuth(`${SYNC_DB_URL}/sync/${tenantId}`, {});

            if (res.data) {
                setIsSyncLoading(false);
                setSyncLog(res.data || [])
                if (successFunc) successFunc();
            } else {
                if (errorFunc) errorFunc();
            }
        } catch (error) {
            console.error("An error occurred during synchronization:", error);

            // You can choose to set an error state or call a specific error function here if needed
            if (errorFunc) errorFunc();
        }
    };


    return { sync, isSyncLoading, getHistory, syncLog }

}
export default useTransferSync