export class ReservationIntegrationDto {
  id: string;
  integrationName: string;
  info: string;
  email: string;
  role: string;
  status: string;
  reservationId: string;
  message: string;
  date: number;
  deleted: boolean;
  counter: number;
  markAsPaidSource?: string;
}