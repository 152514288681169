import {UserType} from '../entities/enums';

export class UserDto {
    username: string;
    isAdmin: boolean;
    role?: string;
    type: string;
    token: string;
    venueId?: string;
}

export class TenantUserDto {
    id: string;
    username: string;
    type?: UserType;
    venueId?: string;
    partnerId?: string;
}

export class TempTenantUserDto {
    id?: string;
    username: string;
    password: string;
    tenantId?: string;
    type?: UserType;
    venueId?: string;
    partnerId?: string;
}

export class NewTenantUserDto {
    id?: string;
    username: string;
    password: string;
    type?: UserType;
    venueId?: string;
    partnerId?: string;
}
