import React, { useState } from "react";
import { Checkbox, Modal } from "office-ui-fabric-react";
import _ from "lodash";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import { ResourceDto } from "../../../../store/types";
import "./selectCustomResources.scss";

type Props = {
  resources?: ResourceDto[];
  selectedResources?: ResourceDto[];
  onSelect: (resources: ResourceDto[]) => void;
  onClose: () => void;
};

interface LightResource {
  selected: boolean;
  id: string;
  name: string;
}

export const SelectCustomResources = ({
  resources =[],
  selectedResources=[],
  onSelect,
  onClose,
}: Props) => {
  const [items, setItems] = useState<LightResource[]>(
    _(resources).map(({ id, name }) => {
      return {
        id,
        name,
        selected: !!_.find(selectedResources, (r) => r.id === id),
      };
    })
    .orderBy('name')
    .value()
  );

  const onCheck = (id: string) => {
    setItems((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const selected = resources.filter(
      ({ id }) => !!_.find(items, (r) => r.id === id && r.selected)
    );
    onSelect(selected);
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="select-custom-resources"
    >
      <div className="title h4">
        Select custom resources
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onSubmit}>
        {items.map(({ id, name, selected }) => (
          <div className="resource-line">
            <Checkbox
              checked={selected}
              onChange={() => onCheck(id)}
              className="resource-checkbox"
            />
            <div className="check-label body2">{name}</div>
          </div>
        ))}

        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit">Save Changes</ColorButton>
        </div>
      </form>
    </Modal>
  );
};
