import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Icon, IconButton, Modal } from "office-ui-fabric-react";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { State } from "../../../../store/types";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import {
  selectIsLoadingVenue,
  selectVenue,
  selectVenueError,
} from "../../../../reducers/venues";
import {
  setVenueErrorAction,
  updateVenueAction,
} from "../../../../actions/venue-actions";
import IconSelect from "../../../common/IconSelect";

import TrashIcon from "../../../../assets/trash-icon.svgr";

import "./editSidebarLinks.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setError: setVenueErrorAction,
};

const mapStateToProps = (state: State) => ({
  error: selectVenueError(state),
  readonly: selectReadonly(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  venue: selectVenue(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditSidebarLinks = ({
  updateVenue,
  onClose,
  setError,
  error,
  readonly,
  isLoadingVenue,
  venue,
}: Props) => {
  const saving = useRef(false);

  useEffect(() => {
    setError("");
  }, []);

  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);

  const [title, setTitle] = useState(venue?.sidebarTitle || "");
  const [description, setDescription] = useState(
    venue?.sidebarDescription || ""
  );
  const [icons, setIcons] = useState(venue?.sidebarIcons || []);
  const [links, setLinks] = useState(venue?.sidebarLinks || []);
  const [rows, setRows] = useState(venue?.sidebarLinks || []);
  const [urls, setUrls] = useState(venue?.sidebarUrls || []);

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    saving.current = true;
    if (
      rows.length > 1 &&
      (urls.length !== rows.length || links.length !== rows.length)
    ) {
      setError("All links field is required");
      return;
    }

    updateVenue({
      ...venue,
      sidebarTitle: title,
      sidebarDescription: description,
      sidebarLinks: links,
      sidebarIcons: icons,
      sidebarUrls: urls,
    });
  };

  const onUpdateLink = (
    value: string,
    index: number,
    func: typeof setLinks
  ) => {
    func((prev) => {
      if (prev.length && index < prev.length) {
        return prev.map((item, i) => (i === index ? value : item));
      }
      if (index >= prev.length) {
        return [...prev, value];
      }
      return [value];
    });
  };

  const onUpdateIcon = (index: number) => (value: string) =>
    onUpdateLink(value, index, setIcons);

  const onRemoveLink = (index: number) => {
    setRows((prev) => prev.filter((_, i) => i !== index));
    setLinks((prev) => prev.filter((_, i) => i !== index));
    setIcons((prev) => prev.filter((_, i) => i !== index));
    setUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const onAdd = () => {
    setRows(prev => [...prev, ""]);
    setIcons(prev => [...prev, "link"]);
  };

  const errorMessage = (arr: string[], index: number) => {
    if (!error) return "";
    if (rows?.length <= 1) return "";
    if (arr[index] !== undefined && arr[index] !== "") return "";
    return "Field is required";
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-sidebar-links"
    >
      <div className="title h4">
        Sidebar Links
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdate}>
        <FormTextField
          label="Title"
          className="row"
          value={title}
          onChange={(_, text) => setTitle(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Description"
          className="row"
          value={description}
          onChange={(_, text) => setDescription(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        {rows.map((_, index) => (
          <div key={`name-${index}`}>
            <div className="flex-container">
              <FormTextField
                label={`Link Text ${index + 1}`}
                className="field flex-item"
                value={links[index]}
                onChange={(_, value) =>
                  onUpdateLink(value || "", index, setLinks)
                }
                errorMessage={errorMessage(links, index)}
                autoComplete="off"
                disabled={readonly}
              />
              <IconSelect
                className="flex-item"
                icon={icons[index] || "link"}
                onChange={onUpdateIcon(index)}
              />
              <IconButton
                className="delete-button"
                ariaLabel="Remove Tax"
                onClick={() => onRemoveLink(index)}
              >
                <TrashIcon />
              </IconButton>
            </div>
            <FormTextField
              label={`Link URL ${index + 1}`}
              className="field flex-item"
              value={urls[index]}
              onChange={(_, value) => onUpdateLink(value || "", index, setUrls)}
              errorMessage={errorMessage(urls, index)}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
        ))}

        <div>
          <LinkButton className="add-button" onClick={onAdd}>
            <Icon iconName="Add" className="add" />
            Add item
          </LinkButton>
        </div>
        {error && <div className="error">{error}</div>}

        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditSidebarLinks);
