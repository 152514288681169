import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import { selectErrors } from "../../reducers/auth-reducer";
import { dismissErrorAction } from "../../actions/auth-actions";
import { State } from "../../store/types";
import "./ErrorManager.scss";

const ERROR_MESSAGE_TIMEOUT = 500000;

const mapStateToProps = (state: State) => ({
  errors: selectErrors(state),
});
const mapDispatchToProps = {
  dismissError: dismissErrorAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ErrorManager: React.FunctionComponent<Props> = ({
  errors,
  dismissError,
}: Props) => {
  useEffect(() => {
    const watch = setInterval(() => {
      const now = Date.now();
      for (let error of errors) {
        if (error.timestamp + ERROR_MESSAGE_TIMEOUT > now) {
          dismissError(error.id);
        }
      }
    }, 1000);
    return () => clearInterval(watch);
  }, []);
  return (
    <div className="errors">
      {errors.map((error) => (
        <div className="error" key={error.id}>
        <MessageBar
          messageBarType={MessageBarType.severeWarning}
          isMultiline={false}
          onDismiss={() => dismissError(error.id)}
          dismissButtonAriaLabel="Close"
        >
          {error.text}
        </MessageBar>
        </div>
      ))}
    </div>
  );
};

export default connector(ErrorManager);
