import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    getSettingsAction,
    uploadImageAction,
    uploadLogoAction,
    uploadEmailLogoAction,
    uploadFaviconAction,
    updateSettingsAction,
} from "../../../actions/settings-actions";
import {
    selectHomeBackgroundImage,
    selectHomeTitle,
    selectHomeLogo,
    selectHomeEmailLogo,
    selectHomeFavicon,
    selectHeaderBackgroundColor,
    selectFooterBackgroundColor,
    selectHeaderForegroundColor,
    selectFooterForegroundColor,
    selectHomeLink,
    selectBodyBackgroundColor,
    selectMainButtonColor,
    selectMainButtonTextColor,
    selectHtmlTitle,
    selectCompanyName,
    selectInstagramUrl,
    selectFacebookUrl,
    selectIsImageLoadInProgress,
    selectIsLogoLoadInProgress,
    selectIsEmailLogoLoadInProgress,
    selectIsFaviconLoadInProgress,
    selectPrivacyPolicy,
    selectTermsOfUse,
    selectConfirmationIcon,
    selectNewDesign,
    selectMembershipTitle,
    selectMembershipDescription,
    selectNoPlansAvailableMessage,
} from "../../../reducers/settings";
import { selectReadonly } from "../../../reducers/auth-reducer";
import { State } from "../../../store/types";
import { useViewport } from "../../../hooks/responsive";
import Panel from "../../common/Panel";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import SettingsImage from "../../common/Image";
import EditReservationSettings from "./EditReservationSettings";
import EditImages from "./EditImages";
import EditSocialMedia from "./EditSocialMedia";
import "./themeDesign.scss";
import EditPrivacyLinks from "./EditPrivacyLinks";
import FormSelectField from "../../common/FormSelectField";
import { confirmationPageIcons } from "../../../../../common/constants/confirmationPageIcons";
import { IDropdownOption } from "office-ui-fabric-react";
import FormTextField from "../../../../../common/components/FormTextField";
import { selectUIConfig } from "../../../reducers/ui-reducer";

const mapStateToProps = (state: State) => ({
    readonly: selectReadonly(state),
    homeBackgroundImage: selectHomeBackgroundImage(state),
    homeTitle: selectHomeTitle(state),
    homeLogo: selectHomeLogo(state),
    homeEmailLogo: selectHomeEmailLogo(state),
    homeFavicon: selectHomeFavicon(state),
    headerBackgroundColor: selectHeaderBackgroundColor(state),
    footerBackgroundColor: selectFooterBackgroundColor(state),
    headerForegroundColor: selectHeaderForegroundColor(state),
    footerForegroundColor: selectFooterForegroundColor(state),
    homeLink: selectHomeLink(state),
    bodyBackgroundColor: selectBodyBackgroundColor(state),
    mainButtonColor: selectMainButtonColor(state),
    mainButtonTextColor: selectMainButtonTextColor(state),
    htmlTitle: selectHtmlTitle(state),
    companyName: selectCompanyName(state),
    instagramUrl: selectInstagramUrl(state),
    facebookUrl: selectFacebookUrl(state),
    isImageLoadInProgress: selectIsImageLoadInProgress(state),
    isLogoLoadInProgress: selectIsLogoLoadInProgress(state),
    isEmailLogoLoadInProgress: selectIsEmailLogoLoadInProgress(state),
    isFaviconLoadInProgress: selectIsFaviconLoadInProgress(state),
    privacyPolicy: selectPrivacyPolicy(state),
    termsOfUse: selectTermsOfUse(state),
    confirmationIcon: selectConfirmationIcon(state),
    newDesign: selectNewDesign(state),
    uiConfig: selectUIConfig(state),
    membershipTitle: selectMembershipTitle(state),
    membershipDescription: selectMembershipDescription(state),
    noPlansAvailableMessage: selectNoPlansAvailableMessage(state),
});

const mapDispatchToProps = {
    getSettings: getSettingsAction,
    updateSettings: updateSettingsAction,
    uploadImage: uploadImageAction,
    uploadLogo: uploadLogoAction,
    uploadEmailLogo: uploadEmailLogoAction,
    uploadFavicon: uploadFaviconAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

interface ThemeItem {
    name: string;
    value: string;
}
interface ThemeColor {
    color: string;
    name: string;
    setColor: (color: string) => void;
}
const renderListItem = (item: ThemeItem, index: number) => (<div key={index} className="theme-item"><div className="theme-item-name subtitle2">{item.name}</div><div className="theme-item-value body2">{item.value}</div></div>);

const confirmationIconOptions = Object.keys(confirmationPageIcons).map((key) => {
    return {
        key: key,
        text: key
    }
})

type ConfirmationKey = (keyof typeof confirmationPageIcons)

const VenueInfo = ({
    getSettings,
    updateSettings,
    uploadImage,
    uploadLogo,
    uploadEmailLogo,
    uploadFavicon,
    homeTitle,
    homeBackgroundImage,
    homeLogo,
    homeEmailLogo,
    homeFavicon,
    headerBackgroundColor,
    footerBackgroundColor,
    headerForegroundColor,
    footerForegroundColor,
    homeLink,
    bodyBackgroundColor,
    mainButtonColor,
    mainButtonTextColor,
    htmlTitle,
    companyName,
    instagramUrl,
    facebookUrl,
    isImageLoadInProgress,
    isLogoLoadInProgress,
    isEmailLogoLoadInProgress,
    isFaviconLoadInProgress,
    readonly,
    privacyPolicy,
    termsOfUse,
    confirmationIcon,
    newDesign,
    membershipTitle,
    membershipDescription,
    noPlansAvailableMessage,
    uiConfig
}: Props) => {
    useEffect(() => {
        getSettings()
    }, [getSettings]);
    const { isMiddleScreen, isMaxScreen } = useViewport();
    const [isOpenEditReservationSettings, setIsOpenEditReservationSettings] = useState(false);
    const [isOpenEditImages, setIsOpenEditImages] = useState(false);
    const [isOpenEditSocialMedia, setIsOpenEditSocialMedia] = useState(false);
    const [isOpenEditPrivacyPolicy, setIsOpenEditPrivacyPolicy] = useState(false);

    const [bodyBackground, setBodyBackground] = useState(bodyBackgroundColor);
    const [headerBackground, setHeaderBackground] = useState(headerBackgroundColor);
    const [footerBackground, setFooterBackground] = useState(footerBackgroundColor);
    const [mainButton, setMainButton] = useState(mainButtonColor);
    const [headerForeground, setHeaderForeground] = useState(headerForegroundColor);
    const [footerForeground, setFooterForeground] = useState(footerForegroundColor);
    const [mainButtonText, setMainButtonText] = useState(mainButtonTextColor);
    const [confirmationIconState, setConfirmationIconState] = useState(confirmationIcon);
    const [membershipTitleState, setMembershipTitle] = useState(membershipTitle || "");
    const [membershipDescriptionState, setMembershipDescription] = useState(membershipDescription || "");
    const [noPlansAvailableMessageState, setNoPlansAvailableMessage] = useState(noPlansAvailableMessage || "");
    useEffect(() => {
        setBodyBackground(bodyBackgroundColor);
        setHeaderBackground(headerBackgroundColor);
        setFooterBackground(footerBackgroundColor);
        setMainButton(mainButtonColor);
        setHeaderForeground(headerForegroundColor);
        setFooterForeground(footerForegroundColor);
        setMainButtonText(mainButtonTextColor);
        setConfirmationIconState(confirmationIcon);
        setMembershipTitle(membershipTitle || "");
        setMembershipDescription(membershipDescription || "");
        setNoPlansAvailableMessage(noPlansAvailableMessage || "");
    }, [bodyBackgroundColor, headerBackgroundColor, footerBackgroundColor, mainButtonColor, headerForegroundColor, footerForegroundColor, mainButtonTextColor, confirmationIcon, uiConfig?.membershipTitle, uiConfig?.membershipDescription, uiConfig?.noPlansAvailableMessage]);

    const renderListColor = (item: ThemeColor, index: number) => (
        <div key={index} className="theme-color">
            <div>
                <input
                    type="color"
                    className="theme-color-value"
                    value={item.color}
                    disabled={readonly}
                    onChange={(e) => item.setColor(e.target.value)}
                />
            </div>
            <div className="theme-color-name subtitle2">{item.name}</div>
        </div>
    );
    const renderListSocialMediaItem = (item: ThemeItem, index: number) => (
        <div key={index} className={`theme-item ${index == socialMedia.length - 1 ? "last-item" : ""}`}>
            <div className="theme-item-name subtitle2">{item.name}</div>
            <div className={`theme-item-value body2 ${isMiddleScreen ? "mobile" : ""}`}>{item.value}</div>
        </div>
    );

    const renderListPrivacyLinkItem = (item: ThemeItem, index: number) => (
        <div key={index} className={`theme-item ${index == privacyLinks.length - 1 ? "last-item" : ""}`}>
            <div className="theme-item-name subtitle2">{item.name}</div>
            <div className={`theme-item-value body2 ${isMiddleScreen ? "mobile" : ""}`}>{item.value}</div>
        </div>
    );

    const uploadImageFile = (files: FileList | null) => {
        if (files) {
            uploadImage(files[0]);
        }
    }
    const uploadLogoFile = (files: FileList | null) => {
        if (files) {
            uploadLogo(files[0]);
        }
    }
    const uploadEmailLogoFile = (files: FileList | null) => {
        if (files) {
            uploadEmailLogo(files[0]);
        }
    }
    const uploadFaviconFile = (files: FileList | null) => {
        if (files) {
            uploadFavicon(files[0]);
        }
    }

    const onUpdateColor = () => {
        updateSettings({
            bodyBackgroundColor: bodyBackground,
            headerBackgroundColor: headerBackground,
            footerBackgroundColor: footerBackground,
            mainButtonColor: mainButton,
            headerForegroundColor: headerForeground,
            footerForegroundColor: footerForeground,
            mainButtonTextColor: mainButtonText,
        })
    };

    const onUpdateConfirmationIcon = () => {
        updateSettings({
            confirmationIcon: confirmationIconState
        })
    }

    const onUpdateMembershipSettings = () => {
        updateSettings({
            membershipTitle: membershipTitleState,
            membershipDescription: membershipDescriptionState,
            noPlansAvailableMessage: noPlansAvailableMessageState
        })
    }

    const reservationSettings: ThemeItem[] = [
        { name: 'HTML Title', value: htmlTitle || '-' },
        { name: 'Homepage Title', value: homeTitle || '-' },
        { name: 'Home Link', value: homeLink || '-' },
        { name: 'Company Name', value: companyName || '-' },
        { name: 'New Design', value: newDesign ? 'On' : 'Off' },
    ]
    const colors: ThemeColor[] = [
        { name: 'Body background', color: bodyBackground, setColor: setBodyBackground },
        { name: 'Header', color: headerBackground, setColor: setHeaderBackground },
        { name: 'Footer', color: footerBackground, setColor: setFooterBackground },
        { name: 'Main button', color: mainButton, setColor: setMainButton },
        { name: 'Header text', color: headerForeground, setColor: setHeaderForeground },
        { name: 'Footer text', color: footerForeground, setColor: setFooterForeground },
        { name: 'Main button text', color: mainButtonText, setColor: setMainButtonText },
    ]
    const socialMedia: ThemeItem[] = [
        { name: 'Facebook', value: facebookUrl || '-' },
        { name: 'Instagram', value: instagramUrl || '-' },
    ]
    const privacyLinks: ThemeItem[] = [
        { name: 'Privacy Policy', value: privacyPolicy || '-' },
        { name: 'Terms of Use', value: termsOfUse || '-' },
    ]
    const reservationSettingsTitle = (<><div className="h6">Page Settings</div><LinkButton onClick={() => setIsOpenEditReservationSettings(true)}>Edit</LinkButton></>);
    const colorsTitle = (<><div className="h6">Colors</div></>);
    const confirmationPageTitle = (<><div className="h6">Confirmation Page</div></>);
    const membershipPageTitle = (<><div className="h6">Membership Page</div></>);
    const imagesTitle = (<><div className="h6">Images</div><LinkButton onClick={() => setIsOpenEditImages(true)}>Edit</LinkButton></>);
    const socialMediaTitle = (<><div className="h6">Social Media</div><LinkButton onClick={() => setIsOpenEditSocialMedia(true)}>Edit</LinkButton></>);
    const privacyLinksTitle = (<><div className="h6">Privacy Links</div><LinkButton onClick={() => setIsOpenEditPrivacyPolicy(true)}>Edit</LinkButton></>);

    const onRenderIconOption = (option: IDropdownOption | undefined): JSX.Element => {
        const key = option?.key as ConfirmationKey;

        if (key === confirmationPageIcons.Default) {
            return <div className="dropdown-image-container">{option?.text}</div>;
        }

        return (
            <div className="dropdown-image-container">
                {option?.key && confirmationPageIcons[key] && (
                    <img className="option-icon" style={{ width: '30px' }} src={confirmationPageIcons[key]} />
                )}
            </div>
        );
    };
    const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
        if (!options) {
            return <div></div>;
        }
        const option = options[0];
        const key = option?.key as ConfirmationKey;

        if (key === confirmationPageIcons.Default) {
            return <div className="dropdown-image-container">{option?.text}</div>;
        }

        return (
            <div className="dropdown-image-container">
                {option?.key && confirmationPageIcons[key] && (
                    <img className="option-icon" style={{ width: '30px' }} src={confirmationPageIcons[key]} />
                )}
            </div>
        );
    }

    return (
        <div className={`theme-design ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="left-panel">
                <Panel className={`panel-shift ${isMiddleScreen ? "" : "panel-with-two-columns"}`} title={reservationSettingsTitle}>
                    {reservationSettings.map((item, index) => renderListItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className={`panel-shift ${isMaxScreen ? "panel-with-three-columns" : ""}`} title={colorsTitle}>
                    {colors.map((item, index) => renderListColor(item, index))}
                    <div className="save-color-button">
                        <ColorButton
                            onClick={onUpdateColor}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className='panel-shift' title={confirmationPageTitle}>
                    <FormSelectField
                        label="Confirmation Icon"
                        options={confirmationIconOptions}
                        selectedKey={confirmationIconState}
                        onRenderOption={onRenderIconOption}
                        onRenderTitle={onRenderTitle}
                        onChange={(_: any, option) => {
                            setConfirmationIconState(option?.key ? option?.key + "" : "");
                        }}
                        className="confirmation-select"
                        disabled={readonly}
                    />
                    <div className="save-confirmation-icon">
                        <ColorButton
                            onClick={onUpdateConfirmationIcon}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                </Panel>
                {uiConfig?.hasMemberships && (
                    <Panel className='panel-shift' title={membershipPageTitle}>
                        <FormTextField
                            label="Membership Page Title"
                            className="row"
                            value={membershipTitleState}
                            onChange={(_: any, text?: string) => setMembershipTitle(text || "")}
                            autoComplete="off"
                            disabled={readonly}
                        />
                        <FormTextField
                            label="Membership Page Description"
                            className="row"
                            value={membershipDescriptionState}
                            onChange={(_: any, text?: string) => setMembershipDescription(text || "")}
                            autoComplete="off"
                            disabled={readonly}
                        />
                        <FormTextField
                            label="No Plans Available Message"
                            className="row"
                            value={noPlansAvailableMessageState}
                            onChange={(_: any, text?: string) => setNoPlansAvailableMessage(text || "")}
                            autoComplete="off"
                            disabled={readonly}
                        />
                        <div className="save-confirmation-icon">
                            <ColorButton
                                onClick={onUpdateMembershipSettings}
                                disabled={readonly}
                            >
                                Save Changes
                            </ColorButton>
                        </div>
                    </Panel>
                )}
            </div>

            <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
                <Panel className="panel-shift" title={imagesTitle}>
                    <div className={`image-fields-container single-image ${isMiddleScreen ? "mobile" : ""}`}>
                        <div className={`image-field ${isMiddleScreen ? "mobile" : ""}`}>
                            <div className="detail-title">
                                Homepage Banner
                            </div>
                            <SettingsImage image={homeBackgroundImage} loading={isImageLoadInProgress} uploadImage={uploadImageFile} subTitle="1440x820 pixels" />
                        </div>
                    </div>
                    <div className={`image-fields-container two-images ${isMiddleScreen ? "mobile" : ""}`}>
                        <div className={`image-field ${isMiddleScreen ? "mobile" : ""}`}>
                            <div className="detail-title">
                                Logo
                            </div>
                            <SettingsImage image={homeLogo} loading={isLogoLoadInProgress} uploadImage={uploadLogoFile} subTitle="70px height (.png)" />
                        </div>
                        <div className="image-delimiter" />
                        <div className={`image-field ${isMiddleScreen ? "mobile" : ""}`}>
                            <div className="detail-title">
                                Logo for Emails
                            </div>
                            <SettingsImage image={homeEmailLogo} loading={isEmailLogoLoadInProgress} uploadImage={uploadEmailLogoFile} subTitle="70px height (.png)" />
                        </div>
                    </div>

                    <div className={`image-fields-container single-image ${isMiddleScreen ? "mobile" : ""}`}>
                        <div className={`image-field ${isMiddleScreen ? "mobile" : ""}`}>
                            <div className="detail-title">
                                Favicon
                            </div>
                            <SettingsImage image={homeFavicon} loading={isFaviconLoadInProgress} uploadImage={uploadFaviconFile} subTitle="200x200 pixels" />
                        </div>
                    </div>
                </Panel>
                <Panel className="panel-shift panel-with-lines" title={socialMediaTitle}>
                    {socialMedia.map((item, index) => renderListSocialMediaItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
                <Panel className="panel-shift panel-with-lines" title={privacyLinksTitle}>
                    {privacyLinks.map((item, index) => renderListPrivacyLinkItem(item, index))}
                    <div className="panel-padding"></div>
                </Panel>
            </div>
            {isOpenEditReservationSettings && <EditReservationSettings onClose={() => setIsOpenEditReservationSettings(false)} />}
            {isOpenEditImages &&
                <EditImages
                    isImageLoadInProgress={isImageLoadInProgress}
                    isLogoLoadInProgress={isLogoLoadInProgress}
                    isEmailLogoLoadInProgress={isEmailLogoLoadInProgress}
                    isFaviconLoadInProgress={isFaviconLoadInProgress}
                    uploadImageFile={uploadImageFile}
                    uploadLogoFile={uploadLogoFile}
                    uploadEmailLogoFile={uploadEmailLogoFile}
                    uploadFaviconFile={uploadFaviconFile}
                    homeBackgroundImage={homeBackgroundImage}
                    homeLogo={homeLogo}
                    homeEmailLogo={homeEmailLogo}
                    homeFavicon={homeFavicon}
                    onClose={() => setIsOpenEditImages(false)}
                />}
            {isOpenEditSocialMedia && <EditSocialMedia onClose={() => setIsOpenEditSocialMedia(false)} />}
            {isOpenEditPrivacyPolicy && <EditPrivacyLinks onClose={() => setIsOpenEditPrivacyPolicy(false)} />}
        </div>
    );
};

export default connector(VenueInfo);