import React from "react";
import FormTextField from "../../../../common/components/FormTextField";
import ActionsButton from "../common/ActionsButton";

interface Props {
  stripeAccountId?: string;
  currency?: string;
  onCheckStripe: () => void;
  disabledCheck?: boolean;
  isFromSuperAdmin?: boolean;
  updateStripeAccount: (stripeAccountId: string) => void;
}

const StripeFields = ({
  currency,
  stripeAccountId,
  onCheckStripe,
  disabledCheck,
  isFromSuperAdmin,
  updateStripeAccount,
}: Props) => {
  const [accountId, setAccountId] = React.useState<string | undefined>(
    stripeAccountId
  );
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onCheckStripe();
  };

  return (
    <form className="stripe-field" onSubmit={onSubmit}>
      <div className="row">
        <FormTextField
          value={accountId}
          onChange={(_: any, value?: string) => setAccountId(value || "")}
          disabled={!isFromSuperAdmin}
          className={!stripeAccountId ? "empty-field" : ""}
          placeholder="Stripe account ID"
        />
      </div>
      <div className="row">
        <FormTextField
          value={currency || "$USD"}
          disabled
          className={!currency ? "empty-field" : ""}
        />
      </div>

      <div className="buttons-container row">
        {isFromSuperAdmin && (
          <ActionsButton onClick={() => updateStripeAccount(accountId || "")}>
            Save
          </ActionsButton>
        )}
        <ActionsButton type="submit" disabled={disabledCheck}>
          Check Status
        </ActionsButton>
      </div>
    </form>
  );
};

export default StripeFields;
