export enum webConfigUI {
  RESERVATION,
  RESERVATION_DETAILS,
  VENUES,
  VENUE_DETAILS,
  SETTINGS,
  REPORTS,
  REPORTSV2,
  TENANTS,
  PARTNERS,
  PARTNER_DETAILS,
  TENANT_DETAILS,
  EXEC_DASHBOARD,
  USERS,
  MONITORING_APP,
  ADDONS,
  ADDON_DETAILS,
  ADDON_MODIFIERS,
  ADDON_MODIFIERS_DETAILS,
  DISCOUNTS,
  DISCOUNT_DETAILS,
  HOST_APP,
  SUPER_ADMIN_SETTINGS,
  TRANSFERS,
  MEMBERSHIP,
  MEMBERSHIP_DETAILS,
  QUESTIONS,

}

export enum venueConfigUI {
  GENERAL,
  LANGUAGE,
  INTEGRATIONS,
  ADVANCED,
  PACKAGES,
  PACKAGE_DETAILS,
  RESOURCES,
  HOST,
}

export enum addonConfigUI {
  GENERAL,
  ADVANCED,
  PACKAGES,
  MODIFIERS,
}

export enum discountConfigUI {
  GENERAL,
  SCHEDULE,
  ASSIGN,
  ADDONS,
}

export enum settingsConfigUI {
  THEME_DESIGN,
  GLOBAL,
  INTEGRATIONS,
}

export enum addonSettingNameUI {
  TAX,
  FEE,
  MAPPING,
}

export enum membershipUI {
  SetUp,
  Plans,
}
