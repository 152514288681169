import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal, IDropdownOption } from "office-ui-fabric-react";
import { updateVenueAction } from "../../../../actions/venue-actions";
import { selectVenue, selectIsLoadingVenue } from "../../../../reducers/venues";
import { FeeType, State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import FormSelectField from "../../../common/FormSelectField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editPaymentSettings.scss";

export const depositTypeList: IDropdownOption[] = [{
  key: FeeType.PERCENT,
  text: FeeType.PERCENT,
},
{
  key: FeeType.FLAT_RATE,
  text: FeeType.FLAT_RATE,
}]

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditPaymentSettings = ({
  updateVenue,
  venue,
  readonly,
  onClose,
}: Props) => {
  useEffect(() => {
    setDeposit(venue?.deposit.toString() || "0.00");
    setDepositType(venue?.depositType || FeeType.PERCENT);
  }, [venue]);
  const [deposit, setDeposit] = useState(venue?.deposit.toString() || "0.00");
  const [depositType, setDepositType] = useState(venue?.depositType || FeeType.PERCENT);

  const closed = venue?.closed || false;

  const getPatch = () => {
    const newDeposit = deposit ? parseFloat(deposit) : 100;
    return {
      deposit: newDeposit,
      depositType: depositType || FeeType.PERCENT,
    };
  }

  const onUpdatePaymentSettings = (e: React.FormEvent) => {
    e.preventDefault();

    if (venue) {
      const patch = getPatch();
      updateVenue({ ...venue, ...patch });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-payment-settings"
    >
      <div className="title h4">
        Payment Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="payment-setting" onSubmit={onUpdatePaymentSettings}>
        <div className="payment-settings-fields">
          <FormTextField
            label="Deposit"
            type="number"
            className="row"
            value={deposit}
            onChange={(_: any, text?: string) => setDeposit(text || "0.00")}
            autoComplete="off"
            max={100}
            required={!closed}
            disabled={readonly}
          />
          <FormSelectField
            label="Deposit Type"
            options={depositTypeList}
            selectedKey={depositType}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => setDepositType(item?.key as FeeType)}
            required={!closed}
            disabled={readonly}
          />
        </div>
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditPaymentSettings);
