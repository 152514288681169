import React, { useState } from 'react';
import { Modal, IconButton, Toggle } from "office-ui-fabric-react";
import { connect, ConnectedProps } from "react-redux";
import { State } from "../../../../store/types";
import CancelButton from "../../../../../../common/components/CancelButton";
import { useViewport } from "../../../../hooks/responsive";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import TrashIcon from "../../../../assets/trash-icon.svgr";
import { selectCurrentResourceCategory } from "../../../../reducers/resources";
import {
  addResourceCategoryAction,
  deleteResourceCategoryAction,
  updateResourceCategoryAction
} from "../../../../actions/resources-action";
import './index.scss'
import FormSelectField from '../../../common/FormSelectField';
import { actionTexts } from '../../../Settings/GlobalSettings';

const mapStateToProps = (state: State) => ({
  currentResourceCategory: selectCurrentResourceCategory(state)
});

const mapDispatchToProps = {
  updateResourceCategory: updateResourceCategoryAction,
  addResourceCategory: addResourceCategoryAction,
  deleteResourceCategory: deleteResourceCategoryAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
  venueId?: string
}

const EditResourceCategory = (
  {
    onClose,
    currentResourceCategory,
    updateResourceCategory,
    addResourceCategory,
    deleteResourceCategory,
    venueId = ""
  }: Props
) => {
  const { isMobile } = useViewport();

  const [values, setValues] = useState({
    name: currentResourceCategory?.name || "",
    priority: currentResourceCategory ? String(currentResourceCategory.priority) : "0",
    enableSyncToSteltronicBlock: !!currentResourceCategory?.enableSyncToSteltronicBlock,
    resourceName: currentResourceCategory?.resourceName || "",
  })

  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({})

  const setValue = (key: keyof typeof values, value: any) => {
    setValues({ ...values, [key]: value })
  }

  const getErrorMessages = () => {
    const errors: Record<string, string> = {}

    if (!values.name) {
      errors.name = "Please input category name"
    }

    if (!values.resourceName) {
      errors.resourceName = "Please chose language"
    }

    if (!values.priority) {
      errors.priority = "Please input category priority"
    } else if (isNaN(+values.priority)) {
      errors.priority = "Please input a valid number"
    }

    return errors
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const errorMessages = getErrorMessages();
    setErrorMessages(errorMessages)

    if (Object.values(errorMessages).length > 0) return;

    if (currentResourceCategory) {
      updateResourceCategory({
        ...currentResourceCategory,
        name: values.name,
        priority: +values.priority,
        enableSyncToSteltronicBlock: !!values.enableSyncToSteltronicBlock,
        resourceName: values.resourceName,
      })
    } else {
      addResourceCategory({
        name: values.name,
        priority: +values.priority,
        active: true,
        resources: [],
        venueId,
        enableSyncToSteltronicBlock: !!values.enableSyncToSteltronicBlock,
        resourceName: values.resourceName,
      })
      setValues({
        name: "",
        priority: "0",
        enableSyncToSteltronicBlock: false,
        resourceName: "",
      })
    }

    onClose();
  }

  const onDeleteResourceCategory = (categoryId: string) => {
    deleteResourceCategory(categoryId);
    onClose();
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-resource-category"
    >
      <div className="title h4">
        {currentResourceCategory?.id ? "Edit" : "Create New"} Resource Category
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="resource-category " onSubmit={onSubmit}>
        <div className={`row ${isMobile ? "mobile" : ""} with-error-message`}>
          <FormTextField
            label="Resource Category Name"
            value={values.name}
            onChange={(_, text) => setValue('name', text)}
            autoComplete="off"
            className="long-field"
            errorMessage={errorMessages.name}
          />
          {!isMobile && (<div className="delimiter" />)}
          <FormTextField
            label="Priority"
            value={String(values.priority)}
            onChange={(_, text) => setValue('priority', text)}
            autoComplete="off"
            className="short-field"
            errorMessage={errorMessages.priority}
          />
        </div>
        <div className={`row ${isMobile ? "mobile" : ""} with-error-message`}>
          <FormSelectField
              label="Language"
              options={actionTexts}
              selectedKey={values.resourceName}
              onChange={(_, option) => setValue('resourceName', option?.key)}
              errorMessage={errorMessages.resourceName}
              required
            />
        </div>
        <div className={`row ${isMobile ? "mobile" : ""}`}>
          <Toggle
            label="Enable Sync to Steltronic Block"
            checked={!!values.enableSyncToSteltronicBlock}
            onChange={(_, value) => setValue('enableSyncToSteltronicBlock', value)}
            inlineLabel
          />
        </div>
        <div className={`buttons-container ${ currentResourceCategory?.id ? 'with-delete-button' : ''}`}>
          {currentResourceCategory?.id && (
            <IconButton
            className="delete-button"
            ariaLabel="Remove Category"
            onClick={() => onDeleteResourceCategory(currentResourceCategory.id)}
          >
            <TrashIcon />
            <div className='delete-button-text'>Delete</div>
          </IconButton>
          )}
          <div className='buttons'>
            <LinkButton onClick={onClose}>Cancel</LinkButton>
            <ColorButton
              type="submit"
            >
              Save
            </ColorButton>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditResourceCategory);