import React from "react";
import { ISearchBoxProps, ISearchBoxStyles, SearchBox, mergeStyleSets } from "office-ui-fabric-react";
import { color } from "../../../constants/styles";
import "./formSearchField.scss";

export const searchFieldStyle: ISearchBoxStyles = {
  root: {
    width: "100%",
    height: "40px",
    border: "1px solid #D5D8E1",
    borderRadius: "6px",
    fontFamily: "Inter, sans-serif",
  },
  icon: {
    color: color.grey,
  },
  field: {
    fontSize: "16px",
    lineHeight: "150%",
  }
};

const FormSearchField = ({ styles, className, ...other }: ISearchBoxProps) => {
  const textFieldStyles = mergeStyleSets(searchFieldStyle, styles) as ISearchBoxStyles;

  return (
    <div className={className ? `search-field-container ${className}` : "search-field-container"}>
      <SearchBox styles={textFieldStyles} {...other} />
    </div>
)};

export default FormSearchField