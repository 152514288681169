import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { DefaultButton, IButtonProps } from "office-ui-fabric-react";
import { State } from "../../../store/types";
import { selectUIConfig } from "../../../reducers/ui-reducer";

const mapStateToProps = (state: State) => ({
  uiConfig: selectUIConfig(state)
});
const connector = connect(mapStateToProps);

interface OwnProps {
  backgroundColor?: string;
  textColor?: string;
}
type Props = ConnectedProps<typeof connector> & OwnProps & IButtonProps;

const PrimaryButton = ({
  children,
  backgroundColor,
  textColor,
  uiConfig,
  ...other
}: Props) => {
  const bgColor = backgroundColor ? backgroundColor : (uiConfig?.mainButtonColor || '#000');
  const txtColor = textColor ? textColor : (uiConfig?.mainButtonTextColor || '#fff');
  const primaryButtonStyle = {
    root: {
      height: 40,
      minWidth: 80,
      padding: 0,
      background: bgColor,
      color: txtColor,
      border: "0",
    },
    textContainer: {
      fontSize: 14,
      fontFamily: "Roboto, bold",
    },
    rootHovered: {
      background: bgColor,
      color: txtColor,
      boxShadow:
        "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
      border: "0",
    },
    rootPressed: {
      border: "0",
    },
    rootDisabled: {
      opacity: 0.5,
    },
  };
  return (
    <DefaultButton styles={primaryButtonStyle} {...other}>
      {children}
    </DefaultButton>
  );
};

export default connector(PrimaryButton)