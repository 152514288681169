export class TimeSlotAddonSettingDto {
  id: string;
  addonId: string;
  scheduleAddonId: string;
  weekDay: number;
  start: number;
  end: number;
  is21plus: boolean;
  rate: number;
  changed?: boolean;
  isNew?: boolean;
  isDeleted?: boolean;
  startTime: number;
  endTime: number;
  lanesCount: number;
  staffCapacity: number;
  guestsCapacity: number;
}

export class CreateTimeSlotAddonSettingDto {
  addonId: string;
  scheduleAddonId: string;
  weekDay: number;
  start: number;
  end: number;
  is21plus: boolean;
  rate: number;
  changed?: boolean;
  isNew?: boolean;
  isDeleted?: boolean;
  startTime: number;
  endTime: number;
  lanesCount: number;
  staffCapacity: number;
  guestsCapacity: number;
}

