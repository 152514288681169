import React, { useState, useEffect } from "react";
import {
  Modal,
  Checkbox,
  Icon,
  IconButton,
  IDropdownOption,
} from "office-ui-fabric-react";
import { connect, ConnectedProps } from "react-redux";
import {
  AddonsPackageVenueRelationDto,
  FeeType,
  ReservationTaxType,
  SquareCatalogDto,
  State,
} from "../../../../store/types";
import CancelButton from "../../../../../../common/components/CancelButton";
import { useViewport } from "../../../../hooks/responsive";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { feeTypeOptions } from "../../../VenueDetails/VenueInfo";
import Panel from "../../../common/Panel";
import FormTextField from "../../../../../../common/components/FormTextField";
import FormSelectField from "../../../common/FormSelectField";
import TrashIcon from "../../../../assets/trash-icon.svgr";
import { addonSettingNameUI } from "../../../../constants/webConfigUI";
import { RelationTaxAndFees } from "../Packages";
import "./index.scss";

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
  currentRelation: AddonsPackageVenueRelationDto;
  onChangeTaxAndFeesForAddonRelation: (taxAndFees: RelationTaxAndFees) => void;
  onGetGoTabProductList: () => void;
  onSquareItemList: () => void;
  goTabProductList: IDropdownOption[];
  squareItemList: SquareCatalogDto[];
};

const EditRelationTaxAndFees = ({
  onClose,
  currentRelation,
  readonly,
  goTabProductList,
  squareItemList,
  onChangeTaxAndFeesForAddonRelation,
  onGetGoTabProductList,
  onSquareItemList,
}: Props) => {
  useEffect(() => {
    setReservationTaxes(currentRelation?.reservationTaxes || "");
    setServiceFees(currentRelation?.serviceFee ? currentRelation.serviceFee.split(',') : []);
    setServiceFeeNames(currentRelation?.serviceFeeName ? currentRelation.serviceFeeName.split(',') : new Array(currentRelation?.serviceFee.split(',').length).fill(''));
    setServiceFeeTypes(currentRelation?.serviceFeeType ? currentRelation.serviceFeeType.split(',') : new Array(currentRelation?.serviceFee.split(',').length).fill(''));
    setTaxForServiceFees(currentRelation?.taxForServiceFee ? currentRelation.taxForServiceFee.split(',') : new Array(currentRelation?.serviceFee.split(',').length).fill(''));
    setGoTabProductId(currentRelation?.goTabProductId);
    setSquareItemIds(currentRelation.squareItemIds)
  }, [currentRelation]);

  const { isMobile } = useViewport();
  const [reservationTaxes, setReservationTaxes] = useState<string>(currentRelation?.reservationTaxes || "");
  const [serviceFees, setServiceFees] = useState(currentRelation?.serviceFee ? currentRelation.serviceFee.split(',') : []);
  const [serviceFeeNames, setServiceFeeNames] = useState(currentRelation?.serviceFeeName ? currentRelation.serviceFeeName.split(',') : new Array(currentRelation?.serviceFee.split(',').length).fill(''));
  const [serviceFeeTypes, setServiceFeeTypes] = useState(currentRelation?.serviceFeeType ? currentRelation.serviceFeeType.split(',') : new Array(currentRelation?.serviceFee.split(',').length).fill(''));
  const [taxForServiceFees, setTaxForServiceFees] = useState(currentRelation?.taxForServiceFee ? currentRelation.taxForServiceFee.split(',') : new Array(currentRelation?.serviceFee.split(',').length).fill(''));
  const [goTabProductId, setGoTabProductId] = useState(currentRelation?.goTabProductId);
  const [addonSettingName, setAddonSettingName] = useState<addonSettingNameUI>(addonSettingNameUI.TAX);
  const [squareItemIds, setSquareItemIds] = useState<string[]>(currentRelation.squareItemIds);

  let reservationTaxSelectors = !!currentRelation?.taxes && currentRelation?.taxes.length > 0 ? currentRelation.taxes.map((tax) => {
    let isSelected = reservationTaxes.split(';').includes(tax.name);
    const taxAmount = tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
    const taxLabel = `${tax.name} ${taxAmount}`;
    return (
      <Checkbox
        key={tax.id}
        className="tax-selector"
        label={taxLabel}
        checked={isSelected}
        onChange={(_: any, checked?: boolean) =>
          onSelectReservationTax(tax.name, !!checked)
        }
        disabled={readonly || reservationTaxes === ReservationTaxType.noTax}
      />
    );
  }) : null;

  let taxOptions = !!currentRelation?.taxes && currentRelation?.taxes.length > 0 ? currentRelation.taxes.map((tax) => {
    const taxAmount = tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
    const taxLabel = `${tax.name} ${taxAmount}`;
    return {
      key: tax.name,
      text: taxLabel,
    };
  }) : [];

  const feeTaxOptions: IDropdownOption[] = [{
    key: ReservationTaxType.noTax,
    text: ReservationTaxType.noTax,
  },
  {
    key: ReservationTaxType.venueTax,
    text: `${ReservationTaxType.venueTax} (${currentRelation?.venueTax || 0}%)`,
  },
  ]

  let customFeeTaxOptions = feeTaxOptions.concat(taxOptions);

  const onSelectReservationTax = (taxId: string, checked: boolean) => {
    let newReservationTaxes = reservationTaxes;
    if (taxId === ReservationTaxType.noTax) {
      if (checked) {
        newReservationTaxes = taxId;
      } else {
        newReservationTaxes = '';
      }
    } else {
      if (checked) {
        newReservationTaxes = `${newReservationTaxes}${newReservationTaxes.length > 0 ? ';' : ''}${taxId}`;
      } else {
        const selectedTaxes = newReservationTaxes.split(';');
        const newTaxesArray = selectedTaxes.filter(tax => tax !== taxId);
        newReservationTaxes = newTaxesArray.length > 0 ? newTaxesArray.join(';') : '';
      }
    }
    setReservationTaxes(newReservationTaxes);
  }

  const onUpdateServiceFees = (value: string, index: number) => {
    setServiceFees(serviceFees.map((item, i) => {
      if (i === index) {
        return value
      }
      return item
    }));
  }
  const onUpdateServiceFeeNames = (value: string, index: number) => {
    setServiceFeeNames(serviceFeeNames.map((item, i) => {
      if (i === index) {
        return value
      }
      return item
    }));
  }
  const onUpdateServiceFeeTypes = (value: string, index: number) => {
    setServiceFeeTypes(serviceFeeTypes.map((item, i) => {
      if (i === index) {
        return value
      }
      return item
    }));
  }
  const onUpdateTaxForServiceFees = (value: string, index: number) => {
    setTaxForServiceFees(taxForServiceFees.map((item, i) => {
      if (i === index) {
        return value;
      }
      return item
    }));
  }
  const onRemoveServiceFee = (index: number) => {
    setServiceFees(serviceFees.filter((_, i) => i !== index));
    setServiceFeeNames(serviceFeeNames.filter((_, i) => i !== index));
    setServiceFeeTypes(serviceFeeTypes.filter((_, i) => i !== index));
    setTaxForServiceFees(taxForServiceFees.filter((_, i) => i !== index));
  }
  const onAddServiceFee = () => {
    setServiceFees([...serviceFees, '']);
    setServiceFeeNames([...serviceFeeNames, '']);
    setServiceFeeTypes([...serviceFeeTypes, '']);
    setTaxForServiceFees([...taxForServiceFees, '']);
  }


  const onUpdateTaxAndSerivceFees = () => {
    if (currentRelation && reservationTaxes) {
      const newServiceFee = serviceFees.length > 0 ? serviceFees.map(item => (+item).toFixed(2)).join(',') : "";
      const newServiceFeeName = serviceFeeNames.length > 0 ? serviceFeeNames.join(',') : "";
      const newServiceFeeType = serviceFeeTypes.length > 0 ? serviceFeeTypes.join(',') : "";
      let newTaxForServiceFee = "";
      if (taxForServiceFees.length > 0) {
        const taxes = taxForServiceFees.map(tax => tax || ReservationTaxType.noTax);
        newTaxForServiceFee = taxes.join(',');
      }
      onChangeTaxAndFeesForAddonRelation({
        id: currentRelation.id,
        reservationTaxes,
        serviceFee: newServiceFee,
        serviceFeeName: newServiceFeeName,
        serviceFeeType: newServiceFeeType,
        taxForServiceFee: newTaxForServiceFee,
        goTabProductId: goTabProductId || '',
        squareItemIds,
      });
      onClose();
    }
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-tax-and-fees"
    >
      <div className="title h4">
        Edit Addon For {currentRelation.packageId ? currentRelation.packageName : currentRelation.venueName}
        <CancelButton onClick={() => onClose()} />
      </div>
      <div className="rules-toggle">
        <div
          className={`rules-toggle-label body2 ${addonSettingName === addonSettingNameUI.TAX ? "active" : ""}`}
          onClick={() => setAddonSettingName(addonSettingNameUI.TAX)}
        >
          Modify Tax
        </div>
        <div
          className={`rules-toggle-label body2 ${addonSettingName === addonSettingNameUI.FEE ? "active" : ""
            }`}
          onClick={() => setAddonSettingName(addonSettingNameUI.FEE)}
        >
          Modify Fees
        </div>
        <div
          className={`rules-toggle-label body2 ${addonSettingName === addonSettingNameUI.MAPPING ? "active" : ""
            }`}
          onClick={() => setAddonSettingName(addonSettingNameUI.MAPPING)}
        >
          Mapping
        </div>
      </div>
      <form className={`edit-container ${isMobile ? "mobile" : ""}`} onSubmit={onUpdateTaxAndSerivceFees}>
        {addonSettingName === addonSettingNameUI.TAX && (
          <>
            <Checkbox
              key='noTax'
              className="tax-selector"
              label='No Tax'
              checked={reservationTaxes === ReservationTaxType.noTax}
              onChange={(_: any, checked?: boolean) =>
                onSelectReservationTax(ReservationTaxType.noTax, !!checked)
              }
              disabled={readonly || reservationTaxes.split(';').includes(ReservationTaxType.venueTax) || reservationTaxes.split(';').some(item => currentRelation?.taxes?.map(t => t.name).includes(item))}
            />
            <Checkbox
              key='venueTax'
              className="tax-selector"
              label={`${ReservationTaxType.venueTax} (${currentRelation.venueTax}%)`}
              checked={reservationTaxes.split(';').includes(ReservationTaxType.venueTax)}
              onChange={(_: any, checked?: boolean) =>
                onSelectReservationTax(ReservationTaxType.venueTax, !!checked)
              }
              disabled={readonly || reservationTaxes === ReservationTaxType.noTax}
            />
            {reservationTaxSelectors}
          </>
        )}
        {addonSettingName === addonSettingNameUI.FEE && (
          <Panel className="panel-shift panel-info" title={<><div className="h6">Custom Fees</div></>}>
            {serviceFees.length > 0 && serviceFees.map((_, index) => (
              <div className={`fields-mapping ${isMobile ? "mobile" : ""}`} key={index}>
                <FormTextField
                  label="Fee Name"
                  className="field fee-field"
                  value={serviceFeeNames[index] || ""}
                  onChange={(_: any, value?: string) =>
                    onUpdateServiceFeeNames(value || "", index)
                  }
                  autoComplete="off"
                  disabled={readonly}
                />
                <FormSelectField
                  label="Fee Type"
                  className="field fee-field small"
                  options={feeTypeOptions}
                  selectedKey={serviceFeeTypes[index] || ''}
                  onChange={(_: any, option) => {
                    onUpdateServiceFeeTypes(option?.key ? option?.key.toString() : "", index)
                  }}
                  disabled={readonly}
                />
                <FormTextField
                  label="Fee"
                  className="field fee-field small"
                  type="number"
                  value={serviceFees[index] || ""}
                  onChange={(_: any, value?: string) =>
                    onUpdateServiceFees(value || "", index)
                  }
                  autoComplete="off"
                  disabled={readonly}
                />
                <FormSelectField
                  label="Tax"
                  className="field fee-field small"
                  options={customFeeTaxOptions}
                  selectedKey={taxForServiceFees[index] || ReservationTaxType.noTax}
                  onChange={(_: any, option) => {
                    onUpdateTaxForServiceFees(option?.key ? option?.key.toString() : ReservationTaxType.noTax, index)
                  }}
                  disabled={readonly}
                />
                <IconButton
                  className="delete-button"
                  ariaLabel="Remove Service Fee"
                  onClick={() => onRemoveServiceFee(index)}
                >
                  <TrashIcon />
                </IconButton>
              </div>
            ))}
            <div className={isMobile ? "mobile" : ""}>
              <LinkButton className="add-button" onClick={() => onAddServiceFee()}>
                <Icon iconName="Add" className="add" />
                Add item
              </LinkButton>
            </div>
          </Panel>
        )}
        {addonSettingName === addonSettingNameUI.MAPPING && (
          <>
            <Panel className="panel-shift panel-info" title="GoTab">
              <div className={`row ${isMobile ? "mobile" : ""}`}>
                <ColorButton
                  className="save-button go-tab-button"
                  onClick={onGetGoTabProductList}
                  disabled={readonly || !currentRelation?.goTabLocation}
                >
                  Get Product Lists
                </ColorButton>
              </div>
              <div className={`row ${isMobile ? "mobile" : ""}`}>
                <FormSelectField
                  label="Product"
                  className="field"
                  options={goTabProductList}
                  selectedKey={goTabProductId}
                  onChange={(
                    _: React.FormEvent<HTMLDivElement>,
                    item?: IDropdownOption
                  ) => setGoTabProductId(item?.key as string)}
                  disabled={readonly || goTabProductList.length === 0}
                />
              </div>
            </Panel>
            <Panel className="panel-info" title="Square">
              <div className={`row ${isMobile ? "mobile" : ""}`}>
                <ColorButton
                  className="save-button square-button"
                  onClick={onSquareItemList}
                  disabled={readonly || !currentRelation?.squareLocationId}
                >
                  Get Item List
                </ColorButton>
              </div>
              <div className={`row ${isMobile ? "mobile" : ""}`}>
                <FormSelectField
                  label="Item"
                  className="field"
                  options={squareItemList?.map((item) => ({
                    key: item.id,
                    text: item.name,
                  }))}
                  selectedKey={squareItemIds}
                  onChange={(_, item) => {
                    if (!item) return;
                    setSquareItemIds([item.key.toString()]);
                  }}
                  disabled={
                    readonly ||
                    !currentRelation?.squareLocationId ||
                    squareItemList.length === 0
                  }
                />
              </div>
            </Panel>
          </>
        )}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            className="save-button"
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditRelationTaxAndFees);
