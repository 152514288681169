import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  Toggle,
} from "office-ui-fabric-react";
import { updatePackageAction } from "../../../../../actions/package-action";
import { selectPackage } from "../../../../../reducers/packages";
import { selectVenue } from "../../../../../reducers/venues";
import { State } from "../../../../../store/types";
import { selectReadonly } from "../../../../../reducers/auth-reducer";
import { useViewport } from "../../../../../hooks/responsive";
import FormTextField from "../../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../../common/components/CancelButton";
import "./editSeatNinjaForPackage.scss";

const mapDispatchToProps = {
  updatePackage: updatePackageAction,
};
const mapStateToProps = (state: State) => ({
  currentPackage: selectPackage(state),
  venue: selectVenue(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditSeatNinjaForPackage = ({
  updatePackage,
  currentPackage,
  readonly,
  onClose,
  venue,
}: Props) => {
  const { isMobile } = useViewport();
  useEffect(() => {
    setPackageSpecificSeatNinjasIntegration(currentPackage?.packageSpecificSeatNinjasIntegration || false);
    setSplitSeatNinjaReservations(currentPackage?.splitSeatNinjaReservations || false);
    setSeatNinjaRestId(currentPackage?.seatNinjaRestId || '');
    setSeatNinjaPartyType(currentPackage?.seatNinjaPartyType || '');
    setSeatNinjaPartyFlags(currentPackage?.seatninjaPartyFlags || undefined);
  }, [currentPackage]);

  const [packageSpecificSeatNinjasIntegration, setPackageSpecificSeatNinjasIntegration] = useState(currentPackage?.packageSpecificSeatNinjasIntegration);
  const [splitSeatNinjaReservations, setSplitSeatNinjaReservations] = useState(currentPackage?.splitSeatNinjaReservations);
  const [seatNinjaRestId, setSeatNinjaRestId] = useState(currentPackage?.seatNinjaRestId);
  const [seatNinjaPartyType, setSeatNinjaPartyType] = useState(currentPackage?.seatNinjaPartyType);
  const [seatNinjaPartyFlags, setSeatNinjaPartyFlags] = useState(currentPackage?.seatninjaPartyFlags);

  const [errorSeatNinjaRestId, setErrorSeatNinjaRestId] = useState("");
  const [errorSeatNinjaPartyType, setErrorSeatNinjaPartyType] = useState("");

  const isValidPackage = () => {
    if (packageSpecificSeatNinjasIntegration && !seatNinjaRestId) {
      setErrorSeatNinjaRestId("This field is required.");
      return false
    }
    if (packageSpecificSeatNinjasIntegration && !seatNinjaPartyType) {
      setErrorSeatNinjaPartyType("This field is required.");
      return false
    }
    return (
      ((packageSpecificSeatNinjasIntegration &&
        seatNinjaRestId &&
        seatNinjaPartyType) || !packageSpecificSeatNinjasIntegration) &&
      venue
    )
  }

  const getPatch = () => {
    if (!venue) {
      return
    }
    return {
      packageSpecificSeatNinjasIntegration: packageSpecificSeatNinjasIntegration || false,
      seatNinjaRestId: seatNinjaRestId || null,
      seatNinjaPartyType: seatNinjaPartyType || null,
      splitSeatNinjaReservations: splitSeatNinjaReservations || false,
      seatninjaPartyFlags: seatNinjaPartyFlags || null,
    };
  }

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    setErrorSeatNinjaRestId("");
    setErrorSeatNinjaPartyType("");

    if (!isValidPackage()) {
      return;
    }

    if (currentPackage) {
      const patch = getPatch();
      updatePackage({ ...currentPackage, ...patch });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-seat-ninja-for-package"
    >
      <div className="title h4">
        Custom SeatNinja Settings
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="seat-ninja" onSubmit={onUpdate}>
        <div className="edit-seat-ninja-for-package-fields">
          <div className="row">
            <Toggle
              label="Specific SeatNinjas Integration"
              checked={packageSpecificSeatNinjasIntegration}
              onChange={(_: any, value?: boolean) => setPackageSpecificSeatNinjasIntegration(value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className="row">
            <Toggle
              label="Split Reservations (only for SpotOn integration)"
              checked={splitSeatNinjaReservations}
              onChange={(_: any, value?: boolean) => setSplitSeatNinjaReservations(!!value)}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Restaurant ID"
              className="integrations-field"
              value={seatNinjaRestId || ""}
              errorMessage={errorSeatNinjaRestId}
              onChange={(_: any, text?: string) =>
                setSeatNinjaRestId(text ? text : null)
              }
              autoComplete="off"
              required={!!packageSpecificSeatNinjasIntegration}
              disabled={readonly}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Party type"
              className="integrations-field"
              value={seatNinjaPartyType || ""}
              errorMessage={errorSeatNinjaPartyType}
              onChange={(_: any, text?: string) =>
                setSeatNinjaPartyType(text ? text : null)
              }
              autoComplete="off"
              required={!!packageSpecificSeatNinjasIntegration}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Party flags"
              type="number"
              className="integrations-field"
              value={seatNinjaPartyFlags?.toString() || ''}
              onChange={(_: any, text?: string) =>
                setSeatNinjaPartyFlags(text ? parseInt(text) : null)
              }
              autoComplete="off"
              disabled={readonly}
            />
          </div>
        </div>
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditSeatNinjaForPackage);
