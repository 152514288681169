import React, { useState, useCallback } from "react";
import { Modal } from "office-ui-fabric-react";
import { debounce } from "lodash";
import { allIcons } from "./allIcons";
import CancelButton from "../../../../../../common/components/CancelButton";
import { GoogleMaterialSymbol } from "../../GoogleMaterialSymbol";
import FormSearchField from "../../FormSearchField";

import "./iconsModal.scss";

const defaultIcons = allIcons.filter(
  ({ unsupported_families }) =>
    !unsupported_families.includes("Material Icons")
);

type Props = {
  onClose: () => void;
  onChange: (value: string) => void;
};

const IconsModal = ({ onChange, onClose }: Props) => {
  const [icons, setIcons] = useState(defaultIcons);

  const onSearch = (value: any) => {
    setIcons(
      defaultIcons.filter(
        ({ name, tags, categories }) =>
          name.includes(value) ||
          tags.includes(value || categories.includes(value))
      )
    );
  };

  const debouncedOnChange = useCallback(
    debounce((value: string) => {
      onSearch(value);
    }, 500),
    []
  );

  const onIconClick = (iconName: string) => {
    onChange(iconName);
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-event"
    >
      <div className="title h4">
        Icons
        <CancelButton onClick={() => onClose()} />
      </div>

      <FormSearchField
        placeholder="Search symbols"
        className="icons-search"
        onChange={(_, newValue) => debouncedOnChange(newValue || "")}
      />

      <div className="icons-container">
        {icons.map(({ name }) => (
          <span
            key={name}
            className="icon-item"
            onClick={() => onIconClick(name)}
          >
            <GoogleMaterialSymbol name={name} fontSize={48} />
          </span>
        ))}
      </div>
    </Modal>
  );
};

export default IconsModal;
