import React, { useEffect } from "react";
import FormSearchField from "../../../common/FormSearchField";
import FormSelectField from "../../../common/FormSelectField";
import {
  DetailsList,
  DetailsListLayoutMode,
  IDropdownOption,
  ScrollablePane,
  SelectionMode,
  Checkbox,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import _ from "lodash";
import {
  State,
  DiscountsPackageVenueRelationDto,
} from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import "./index.scss";
import LocationIcon from "../../../../assets/locations-icon.svgr";
import {
  getDiscountsPackagesAction,
  updateDiscountRelationWithPackagesAction,
} from "../../../../actions/discount-action";
import {
  selectDiscountsRelations,
  selectIsRequestInProgress,
} from "../../../../reducers/discounts";
import { selectVenues } from "../../../../reducers/venues";
import { selectTimeSlotDuration } from "../../../../reducers/settings";
import { useViewport } from "../../../../hooks/responsive";

const baseOptions = [
  {
    key: "packages",
    text: "All Packages",
  },
  {
    key: "venues",
    text: "General Reservations",
  },
];

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  relations: selectDiscountsRelations(state),
  venues: selectVenues(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  isLoadingDiscount: selectIsRequestInProgress(state),
});

const mapDispatchToProps = {
  getDiscountsPackages: getDiscountsPackagesAction,
  updateDiscountRelationWithPackages: updateDiscountRelationWithPackagesAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OwnPros {
  id: string;
}

type Props = ConnectedProps<typeof connector> & OwnPros;

const DiscountPackages = ({
  id,
  readonly,
  relations,
  isLoadingDiscount,
  getDiscountsPackages,
  updateDiscountRelationWithPackages,
}: Props) => {
  const { isMobile } = useViewport();
  const [filterTable, setFilterTable] = React.useState(baseOptions[0].key);
  const [search, setSearch] = React.useState("");

  const [tableOptions, setTableOptions] =
    React.useState<IDropdownOption[]>(baseOptions);

  useEffect(() => {
    getDiscountsPackages(id);
  }, [id]);

  useEffect(() => {
    const r = relations || [];
    setTableOptions([
      ...baseOptions,
      ...r
        .filter((item) => !item?.packageId)
        .map(({ venueId, venueName }) => ({ key: venueId, text: venueName })),
    ]);
  }, [relations]);

  const isShowVenueTable = filterTable === "venues";
  let relationItems: DiscountsPackageVenueRelationDto[] = [];
  if (relations) {
    if (isShowVenueTable) {
      relationItems = relations.filter((item) => !item?.packageId);
    } else if (filterTable === "packages") {
      relationItems = relations.filter(
        (item) => item?.packageId && item?.packageName
      );
    } else {
      relationItems = relations.filter(
        (item) =>
          item?.packageId && item?.packageName && item?.venueId === filterTable
      );
    }
    if (search) {
      relationItems = relationItems.filter(({ packageName, venueName }) =>
        isShowVenueTable
          ? venueName.toLowerCase().includes(search.toLowerCase())
          : (packageName || "").toLowerCase().includes(search.toLowerCase())
      );
    }
  }

  const onSelect = (id: string) => {
    const updated = relationItems.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    onUpdateRelations(updated);
  };

  const allSelect = () => {
    const isAllSelected = relationItems.every((item) => item.selected)
    const updated = relationItems.map((item) => ({ ...item, selected: !isAllSelected }))
    onUpdateRelations(updated);
  }


  const packageColumns = [
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: DiscountsPackageVenueRelationDto) => {
        const discountRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        let checked = discountRelation && discountRelation.selected;
        return (
          <div className="checkbox">
            <Checkbox checked={checked} onChange={() => onSelect(item.id)} />
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "packageName",
      name: "PACKAGE NAME",
      fieldName: "packageName",
      minWidth: 160,
      maxWidth: 300,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "venueName",
      name: "VENUE",
      fieldName: "venueName",
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "status",
      name: "STATUS",
      fieldName: "status",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: DiscountsPackageVenueRelationDto) => {
        const isActive = true;
        const statusText = isActive ? "Active" : "Inactive";
        return (
          <div className="status-container">
            <div className={`status-color ${isActive ? "green" : "red"}`} />
            <div className={`status-text body2 ${isActive ? "green" : "red"}`}>
              {statusText}
            </div>
          </div>
        );
      },
    },

  ];

  const venueColumns = [
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: DiscountsPackageVenueRelationDto) => {
        const discountRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        let checked = discountRelation && discountRelation.selected;
        return (
          <div className="checkbox">
            <Checkbox checked={checked} onChange={() => onSelect(item.id)} />
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "venueName",
      name: "VENUE NAME",
      fieldName: "venueName",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: DiscountsPackageVenueRelationDto) => {
        const venueName = `${item.venueName} - General Reservations`;
        return <span>{venueName}</span>;
      },
    },

    {
      key: "status",
      name: "STATUS",
      fieldName: "status",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: DiscountsPackageVenueRelationDto) => {
        const isActive = true;
        const statusText = isActive ? "Opened" : "Closed";
        return (
          <div className="status-container">
            <div className={`status-color ${isActive ? "green" : "red"}`} />
            <div className={`status-text body2 ${isActive ? "green" : "red"}`}>
              {statusText}
            </div>
          </div>
        );
      },
    },

  ];


  const onSearch = (ev: any, searchText: string | undefined = ""): void => {
    setSearch(searchText);
  };

  const onFilterByType = (_: any, option?: IDropdownOption) => {
    const key = (option?.key as string) || baseOptions[0].key;
    setFilterTable(key);
    setSearch("");
  };

  const onUpdateRelations = (
    updatedRelations: DiscountsPackageVenueRelationDto[]
  ) => {
    const changedValues = updatedRelations.filter((item) => {
      const prevValue = _.find(relations, { id: item.id });
      return (prevValue && prevValue.selected !== item.selected)
    });
    if (changedValues.length === 0) {
      return;
    }
    //todo update this api
    updateDiscountRelationWithPackages(id, changedValues);
  };

  return (
    <>
      {isLoadingDiscount && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <section className="discounts-relations">
        <div className={`controls-block ${isMobile ? "mobile" : ""}`}>
          <FormSelectField
            options={tableOptions}
            className={`control-filed ${isMobile ? "mobile" : ""}`}
            selectedKey={filterTable}
            onChange={onFilterByType}
          />
          <FormSearchField
            placeholder="Search..."
            className={`control-filed ${isMobile ? "mobile" : ""}`}
            value={search}
            onChange={onSearch}
          />
        </div>
        {relations.length > 0 ? (
          <div className="packages-list">
            <ScrollablePane>
              <DetailsList
                items={relationItems}
                columns={isShowVenueTable ? venueColumns : packageColumns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey={isShowVenueTable ? "venues" : "packages"}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderDetailsHeader={(props, defaultRender) => {
                  if (!props) {
                    return null;
                  }

                  return (
                    <div className="header">
                      <div className="check-box checkbox">
                        <Checkbox checked={false} onChange={allSelect} />
                      </div>
                      {defaultRender!(props)}
                    </div>
                  );
                }}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-package-list-container">
            <div className="empty-package-list">
              <LocationIcon className="location-icon" />
              <div className="empty-package-list-message">
                No packages for this tenant
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default connector(DiscountPackages);
