import React from 'react';
import Panel from "../../../common/Panel";
import {Spinner, SpinnerSize, Toggle} from "office-ui-fabric-react";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import {
  selectModifier,
  selectIsLoadingAddon,
} from "../../../../reducers/addons";
import { updateModifierAction } from '../../../../actions/addon-action';
import './controls.scss'

const mapDispatchToProps = {
  updateModifier: updateModifierAction,
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentModifier: selectModifier(state),
  isLoadingModifier: selectIsLoadingAddon(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ModifiersControls = (
  {
    readonly,
    currentModifier,
    updateModifier,
    isLoadingModifier
  }: Props
) => {
  const setModifierStatus = () => {
    if (currentModifier) {
      updateModifier({ ...currentModifier, required: !currentModifier.required })
    }
  }
  const setModifierRequired = () => {
    if (currentModifier) {
      updateModifier({ ...currentModifier, required: !currentModifier.required })
    }
  }
  return (
    <Panel
      title={
        <div className='h6'>Modifier Controls</div>
      }
      className='addon-controls panel-shift'
    >
      {isLoadingModifier && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <Toggle
        inlineLabel
        checked={currentModifier?.required}
        onChange={setModifierStatus}
        disabled={readonly}
        label={
          <span className='body2'>Required</span>
        }
      />
    </Panel>
  );
};

export default connector(ModifiersControls);