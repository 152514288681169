import { IDropdownOption } from "office-ui-fabric-react";
import { UserType } from "../store/types";

export const userTypes: IDropdownOption[] = [
    {
        key: UserType.admin,
        text: "Tenant Admin",
    },
    {
        key: UserType.venueAdmin,
        text: "Admin",
    },
    {
        key: UserType.manager,
        text: "Manager",
    },
    {
        key: UserType.userLead,
        text: "Staff (Lead)",
    },
    {
        key: UserType.user,
        text: "Staff (Entry)",
    },
];