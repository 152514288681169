import React from 'react';
import Panel from "../../../common/Panel";
import {Spinner, SpinnerSize, Toggle} from "office-ui-fabric-react";
import FormSelectField from "../../../common/FormSelectField";
import { State } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import {selectCurrentAddon, selectIsLoadingAddon} from "../../../../reducers/addons";
import { updateAddonAction } from '../../../../actions/addon-action';
import './controls.scss'

const mapDispatchToProps = {
  updateAddon: updateAddonAction,
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddon: selectCurrentAddon(state),
  isLoadingAddon: selectIsLoadingAddon(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AddonsControls = (
  {
    readonly,
    currentAddon,
    updateAddon,
    isLoadingAddon
  }: Props
) => {
  const setAddonStatus = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, status: !currentAddon.status })
    }
  }
  const setAddonRequired = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, required: !currentAddon.required })
    }
  }
  const setAddonAllowCustomerNotes = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, allowCustomerNotes: !currentAddon.allowCustomerNotes })
    }
  }

  const setMatchGuestCount = () => {
    if (currentAddon) {
      updateAddon({ ...currentAddon, matchGuestCount: !currentAddon.matchGuestCount })
    }
  }
  return (
    <Panel
      title={
        <div className='h6'>Add on Controls</div>
      }
      className='addon-controls panel-shift'
    >
      {isLoadingAddon && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <Toggle
        inlineLabel
        checked={currentAddon?.status}
        onChange={setAddonStatus}
        disabled={readonly}
        label={
          <span className='body2'>Add on is Active</span>
        }
      />
      <Toggle
        inlineLabel
        checked={currentAddon?.required}
        onChange={setAddonRequired}
        disabled={readonly}
        label={
          <span className='body2'>Add on is Required</span>
        }
      />
      <Toggle
        inlineLabel
        checked={currentAddon?.allowCustomerNotes}
        onChange={setAddonAllowCustomerNotes}
        disabled={readonly}
        label={
          <span className='body2'>Allow Customer Notes</span>
        }
      />
      <Toggle
        inlineLabel
        checked={currentAddon?.matchGuestCount}
        onChange={setMatchGuestCount}
        disabled={readonly}
        label={
          <span className='body2'>Match Guest Count</span>
        }
      />
      {/* <div className="body2">
        <FormSelectField
          label="Add on Type"
          defaultSelectedKey={currentAddon?.type}
          options={['Food', 'Drink'].map((item) => ({key: item, text: item}))}
          disabled={readonly}
        />
      </div> */}
    </Panel>
  );
};

export default connector(AddonsControls);