import React, { useState } from "react";
import IconsModal from "./IconsModal";
import { GoogleMaterialSymbol } from "../GoogleMaterialSymbol";

import "./iconSelect.scss";

interface IconSelectProps {
  icon: string;
  className?: string;
  onChange: (value: string) => void;
}

const IconSelect = ({ className, icon, onChange }: IconSelectProps) => {
  const [isOpenIconsModal, setIsOpenIconsModal] = useState(false);

  return (
    <div className={className}>
      <div className="selected-icon" onClick={() => setIsOpenIconsModal(true)}>
        <GoogleMaterialSymbol name={icon} fontSize={48} />
      </div>

      {isOpenIconsModal && <IconsModal onClose={() => setIsOpenIconsModal(false)} onChange={onChange}/>}

    </div>
  );
};

export default IconSelect;