import React, { useState } from "react";
import FormSelectField from "../../../common/FormSelectField";
import { IDropdownOption } from "office-ui-fabric-react";
import {
  CouponStatus,
  CouponType,
  DiscountSettingDto,
  CreateDiscountSettingDto,
  State,
} from "../../../../store/types";
import FormTextField from "../../../../../../common/components/FormTextField";
import ColorButton from "../../../../../../common/components/ColorButton";
import { useViewport } from "../../../../hooks/responsive";
import {
  selectReadonly,
  selectWebConfigUI,
} from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import {
  selectDiscountError,
  selectCurrentDiscount,
  selectIsRequestInProgress,
} from "../../../../reducers/discounts";
import {
  createDiscountAndNavigatePageAction,
  updateDiscountAction,
} from "../../../../actions/discount-action";
import "./generalDetails.scss";

const mapDispatchToProps = {
  createDiscountAndNavigatePage: createDiscountAndNavigatePageAction,
  updateDiscount: updateDiscountAction,
};
const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentDiscount: selectCurrentDiscount(state),
  isLoadingDiscount: selectIsRequestInProgress(state),
  error: selectDiscountError(state),
  webConfigUIPage: selectWebConfigUI(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const GeneralDetails = ({
  readonly,
  currentDiscount,
  isLoadingDiscount,
  error,
  webConfigUIPage,
  createDiscountAndNavigatePage,
  updateDiscount,
}: Props) => {
  const { isMobile } = useViewport();
  const [values, setValues] = useState(currentDiscount);

  const onValueChange = (key: keyof DiscountSettingDto, value: any) => {
    setValues({ ...values, [key]: value } as DiscountSettingDto);
  };

  const getPatch = (): CreateDiscountSettingDto | undefined => {
    if (!values) {
      return;
    }
    return {
      name: values.name,
      couponCode: values.couponCode,
      amount: values.amount,
      status: values.status,
      type: values.type,
      start: values.start,
      end: values.end,
    };
  };

  const _onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    if (currentDiscount) {
      const patch = getPatch();
      updateDiscount({ ...currentDiscount, ...patch });
    } else {
      if (!values?.name) {
        return;
      }
      const patch = getPatch();
      if (patch) {
        createDiscountAndNavigatePage(patch);
      }
    }
  };

  return (
    <form className="discount" onSubmit={_onUpdate}>
      <div className="edit-package-fields">
        <div className={`row ${isMobile ? "mobile" : ""}`}>
          <FormTextField
            label="Discount Name"
            className="long-field"
            value={values?.name}
            onChange={(_: any, text?: string) => onValueChange("name", text)}
            autoComplete="off"
            disabled={readonly}
            required
          />
          {!isMobile && <div className="delimiter" />}
          <FormTextField
            label="Coupon code"
            className="short-field"
            value={values?.couponCode ? String(values?.couponCode) : ""}
            onChange={(_: any, text?: string) =>
              onValueChange("couponCode", text || "")
            }
            autoComplete="off"
            disabled={readonly}
          />
        </div>

        <div className={`row ${isMobile ? "mobile" : ""}`}>
          <FormSelectField
            label="Discount Type"
            options={Object.entries(CouponType).map(([key, value]) => ({
              key,
              text: value,
            }))}
            selectedKey={values?.type as CouponType}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => onValueChange("type", item?.key as CouponType)}
            disabled={readonly}
            required
          />
          {!isMobile && <div className="delimiter" />}
          <FormTextField
            label="Amount"
            type="number"
            value={String(values?.amount)}
            onChange={(_: any, value?: string) =>
              onValueChange("amount", parseFloat(value || "") || 0)
            }
            autoComplete="off"
            disabled={readonly}
            required
          />
        </div>
        <div className={`row ${isMobile ? "mobile" : ""}`}>
          <FormSelectField
            label="Status"
            options={Object.entries(CouponStatus).map(([key, value]) => ({
              key,
              text: value,
            }))}
            selectedKey={values?.status as CouponStatus}
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              item?: IDropdownOption
            ) => onValueChange("status", item?.key as CouponStatus)}
            disabled={readonly}
            required
          />
        </div>

        <div className="buttons-container">
          <ColorButton type="submit" disabled={readonly}>
            Save Changes
          </ColorButton>
        </div>
      </div>
    </form>
  );
};

export default connector(GeneralDetails);
