import React from "react";
import {
  ActionButton,
  IDropdownOption,
  Toggle,
  Checkbox,
} from "office-ui-fabric-react";
import {
  InventoryMethodTypes,
  Pricing,
  TimeSlotPackageSettingDto,
  TimeSlotParentPackageSettingDto,
} from "../../../../../../store/types";
import { WeekDays } from "../../../../../../constants/timedate";
import { TimeSlotPackageRow } from "./TimeSlotPackageRow/TimeSlotPackageRow";
import { formatTimeFromIndex } from "../../../../../../utils/formats";
import { HOURS_NUMBER_FOR_DAY_SLOTS } from "../../../../../../constants/timeSlots";
import "./timeSlotPackage.scss";
import StartTimeSettings from "../../../../EditSchedule/TimeSlot/StartTimeSettings";
import CopyTimeSlots from "../../../../EditSchedule/TimeSlot/CopyTimeSlots";

export interface GenerateSlotsParams {
  day: string;
  startSlot: number;
  endSlot: number;
  interval: number;
  duration: number;
  rate: number;
  available: number;
  staffCapacity: number;
  guestCapacity: number;
}

interface Props {
  day: number;
  slots: TimeSlotPackageSettingDto[];
  addSlot: () => void;
  updateSlot: (slot: TimeSlotPackageSettingDto) => void;
  removeSlot: (slotId: string) => void;
  timeSlotDuration: number;
  timeShifting: number;
  isPackageSchedule: boolean;
  inventoryMethod: InventoryMethodTypes;
  overrideParentSchedule: boolean;
  parentSlots: TimeSlotParentPackageSettingDto[];
  ageForPolicy: string;
  startTimeOnly: string;
  updateStartTimeOnly: (value: boolean, dayIndex: number) => void;
  twelveHourClockFormat: boolean;
  onGenerateSchedule: (data: GenerateSlotsParams) => void;
  onCopy: (selectedDays: string[]) => void;
  ageGroups?: string;
  packageMethod?: Pricing;
}

const TimeSlotPackage = ({
  day,
  slots = [],
  addSlot,
  updateSlot,
  removeSlot,
  timeSlotDuration,
  timeShifting,
  isPackageSchedule,
  inventoryMethod,
  overrideParentSchedule,
  parentSlots = [],
  ageForPolicy,
  startTimeOnly,
  updateStartTimeOnly,
  twelveHourClockFormat,
  onGenerateSchedule,
  onCopy,
  ageGroups,
  packageMethod,
}: Props) => {
  const onChange = (
    slot: TimeSlotPackageSettingDto,
    record: { [key: string]: string | number | boolean }
  ) => {
    updateSlot({ ...slot, ...record });
  };
  const onToggle = () => {
    if (slots.length === 0) {
      addSlot();
    } else {
      slots.forEach((slot) => {
        removeSlot(slot.id);
      });
    }
  };
  const slotsInHour = 60 / timeSlotDuration;
  const hoursShifting = Math.floor(timeShifting / 60);
  const OPEN_SLOTS =
    Math.floor((HOURS_NUMBER_FOR_DAY_SLOTS - hoursShifting) * slotsInHour) + 1;

  const times: IDropdownOption[] = Array(OPEN_SLOTS)
    .fill(null)
    .map((_, index) => ({
      key: index,
      text: formatTimeFromIndex(
        index,
        timeSlotDuration,
        timeShifting,
        twelveHourClockFormat
      ),
    }));

  const handleGenerateClick = (data: GenerateSlotsParams) => {
    onGenerateSchedule({
      ...data,
      day: WeekDays[day],
    });
  };

  return (
    <div className="time-slot-package">
      <div className="time-slot-header h6">
        <div className="time-slot-head">
          <div className="time-slot-heading">{WeekDays[day]}</div>
          <CopyTimeSlots currentDay={WeekDays[day]} onCopy={onCopy} />
        </div>
        <div className="time-slot-header-right-container">
          <Checkbox
            className="weekday-checkbox"
            label="Start time only"
            defaultChecked={startTimeOnly == "true"}
            onChange={(_, isChecked) => updateStartTimeOnly(!!isChecked, day)}
          />
          <StartTimeSettings
            times={times}
            timeSlotDuration={timeSlotDuration}
            inventoryMethod={inventoryMethod}
            currentDay={WeekDays[day]}
            onGenerateSchedule={handleGenerateClick} // Pass the function
          />
          <Toggle
            className="slots-toggle"
            checked={slots.length > 0}
            onChange={onToggle}
          />
        </div>
      </div>
      {slots.length > 0 && (
        <div className="time-slot-rows">
          {slots.map((item) => (
            <div key={item.id}>
              <TimeSlotPackageRow
                slot={item}
                times={times}
                timeSlotDuration={timeSlotDuration}
                onChange={onChange}
                removeSlot={removeSlot}
                isPackageSchedule={isPackageSchedule}
                inventoryMethod={inventoryMethod}
                overrideParentSchedule={overrideParentSchedule}
                parentSlots={parentSlots}
                ageForPolicy={ageForPolicy}
                ageGroups={ageGroups}
                packageMethod={packageMethod}
              />
            </div>
          ))}
          <div className="time-slot-button">
            <ActionButton allowDisabledFocus onClick={() => addSlot()}>
              + Add item
            </ActionButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSlotPackage;
