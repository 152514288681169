import { Modal, Spinner, SpinnerSize, Toggle } from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import CancelButton from "../../../../../common/components/CancelButton";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import { createPlanAction, updatePlanAction } from "../../../actions/membership-action";
import AddIcon from '../../../assets/actions/add.svgr';
import { useViewport } from "../../../hooks/responsive";
import { selectReadonly } from "../../../reducers/auth-reducer";
import {
  selectError,
  selectIsMembershipRequestInProgress,
} from "../../../reducers/membership";
import { CurrencyType, MembershipPlanDto, State, } from "../../../store/types";
import FormHtmlTextField from "../../FormHtmlTextField/FormHtmlTextField";
import ActionsButton from "../../common/ActionsButton";
import "./editPlan.scss";

const currencyList = Object.values(CurrencyType).map((item) => ({
  key: item,
  text: item,
}));
interface OwnProps {
  onClose: () => void;
  plan?: MembershipPlanDto;
}

const mapDispatchToProps = {
  createPlan: createPlanAction,
  updatePlan: updatePlanAction,
};
const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  isLoadingPlan: selectIsMembershipRequestInProgress(state),
  error: selectError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditPlan = ({
  readonly,
  onClose,
  plan,
  isLoadingPlan,
  error,
  createPlan,
  updatePlan,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    if (!isLoadingPlan && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingPlan]);
  const { isMobile } = useViewport();
  const [values, setValues] = useState<MembershipPlanDto>(
    plan || ({} as MembershipPlanDto)
  );
  const [showAnualPrice, setShowAnualPrice] = useState<boolean>(!!plan?.annualRate && Number(plan?.annualRate) !== 0);
  const [showFreeTrial, setShowFreeTrial] = useState<boolean>(!!plan?.trialPeriod && Number(plan?.trialPeriod) !== 0);

  const onValueChange = (key: keyof MembershipPlanDto, value: any) => {
    setValues({ ...values, [key]: value } as MembershipPlanDto);
  };

  const getPatch = () => {
    if (!values) {
      return;
    }
    const patch: any = {};
    if (values.name !== plan?.name) {
      patch.name = values.name;
    }
    if (values.shortDescription !== plan?.shortDescription) {
      patch.shortDescription = values.shortDescription;
    }
    if (values.longDescription !== plan?.longDescription) {
      patch.longDescription = values.longDescription;
    }
    if (values.active !== plan?.active) {
      patch.active = values.active;
    }
    if (values.trialPeriod !== plan?.trialPeriod && values.trialPeriod) {
      patch.trialPeriod = +values.trialPeriod;
    }
    if (values.priority !== plan?.priority) {
      patch.priority = values.priority;
    }
    return patch;
  };

  const _onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    if (plan) {
      saving.current = true;
      const patch = getPatch();
      console.log(patch, 'patch')
      updatePlan({ ...plan, ...patch });
    } else {
      if (!(values?.name && values)) {
        return;
      }
      saving.current = true;
      createPlan({
        name: values.name,
        shortDescription: values.shortDescription,
        longDescription: values.longDescription,
        active: true,
        monthlyRate: +values.monthlyRate,
        annualRate: +(values.annualRate || 0),
        trialPeriod: showFreeTrial ? +(values.trialPeriod || 0) : 0,
        currency: CurrencyType.USD,
        priority: values.priority || 1,
      });
    }
  };

  const handleAddAnualPrice = () => {
    setShowAnualPrice(true);
  }


  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-plan"
    >
      {isLoadingPlan && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className="title h4">
        {plan ? "Update" : "Create"} Plan
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="plan" onSubmit={_onUpdate}>
        <div className="row">

          <FormTextField
            label="Plan Name"
            value={values?.name}
            onChange={(_: any, text?: string) => onValueChange("name", text)}
            autoComplete="off"
            required
            className="long-field"

          />
          <FormTextField
            label="Priority"
            className="short-field"
            type="number"
            value={values?.priority?.toString()}
            onChange={(_: any, value?: string) => onValueChange("priority", value ? +value : 0)}
            autoComplete="off"
          />
        </div>
        <div className="row">
          <FormTextField
            value={values.shortDescription} readOnly={readonly} label="Short Description" placeholder=""
            onChange={(_: any, text?: string) => onValueChange("shortDescription", text)} autoComplete="off"
          />
        </div>
        <div className="row">
          <FormHtmlTextField setValue={(text) =>
            onValueChange("longDescription", text)} value={values.longDescription} readOnly={readonly} label="Long Description" placeholder="Compose an epic.." />
        </div>


        <hr className="delimiter-plan first-section" />

        <div className={`row ${isMobile ? "mobile" : ""}`}>
          <Toggle
            label="Free Trial"
            checked={showFreeTrial}
            onChange={() =>
              setShowFreeTrial(!showFreeTrial)
            }
            inlineLabel
          />
        </div>
        {showFreeTrial && <div className="row edit-plan-col">
          <FormTextField
            label="Trial Period"
            value={values?.trialPeriod?.toString() || ""}
            onChange={(_: any, text?: string) => onValueChange("trialPeriod", text)}
            autoComplete="off"
            prefix="Days:"
            required
          />
        </div>}

        <hr className={`delimiter-plan second-section ${showFreeTrial ? '' : 'delimiter-free'}`} />
        <div className={`row ${isMobile ? "mobile" : ""}`}>
          <Toggle
            label="One-time setup fee"
            checked={values?.active}
            onChange={(_: any, isChecked?: boolean) =>
              onValueChange("active", !!isChecked)
            }
            inlineLabel
          />
        </div>

        <div className="caption charge-text">Charge customers an additional, one-time fee when they first sign up</div>

        <div className="row edit-plan-col">

          <FormTextField
            label="Setup fee amount"
            value={values?.setUpFee?.toString() || ""}
            onChange={(_: any, text?: string) => onValueChange("setUpFee", text)}
            autoComplete="off"
            prefix="$"
            required
            disabled={!!plan}


          />
        </div>
        <hr className="delimiter-plan second-section" />

        <div className="row edit-plan-col">
          <FormTextField
            label="Monthly Rate"
            value={values?.monthlyRate ? values?.monthlyRate.toString() : ""}
            type="number"
            onChange={(_: any, text?: string) =>
              onValueChange("monthlyRate", text)
            }
            autoComplete="off"
            required
            prefix="$"
            disabled={!!plan}

          />
        </div>
        {!showAnualPrice ? <button disabled={isLoadingPlan || !!plan} className="button-small button-anual" onClick={handleAddAnualPrice}>
          <AddIcon />
          Add anual price
        </button> :
          <div className="row edit-plan-col">
            <FormTextField
              label="Anual Rate"
              value={values?.annualRate ? values?.annualRate.toString() : ""}
              type="number"
              onChange={(_: any, text?: string) =>
                onValueChange("annualRate", text)
              }
              autoComplete="off"
              required
              prefix="$"
              disabled={!!plan}

            />
          </div>}


        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ActionsButton type="submit" disabled={readonly}>
            {plan ? 'Update' : 'Add'}
          </ActionsButton>
        </div>
      </form>
    </Modal>
  );
};


export default connector(EditPlan);
