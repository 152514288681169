import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getUserAction } from "../../actions/auth-actions";

const mapDispatchToProps = {
  getUser: getUserAction,
};
const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AuthManager: React.FunctionComponent<Props> = ({
  getUser,
}: Props) => {
  useEffect(() => {
    getUser();
  }, [getUser]);
  return null;
};

export default connector(AuthManager);
