import { Fade, IconButton, Popper, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import ActionsButton from '../../../common/ActionsButton';
import { Checkbox } from 'office-ui-fabric-react';
import ContentCopyIcon from '../../../../assets/content-copy.svgr'
import { WeekDays } from '../../../../constants/timedate';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            border: '1px solid #C5C5C5',
            backgroundColor: '#FFF',
            zIndex: 1000,
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
            padding: '30px 22px',
            borderRadius: '8px',
            position: 'relative', // for arrow positioning

        },
        checkboxContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '24px',
            marginTop: '19px',
        },
        button: {
            width: '100%',
        },
    })
);

interface Props {
    currentDay: string;
    onCopy: (selectedDays: string[]) => void;
}

const CopyTimeSlots: React.FC<Props> = ({ currentDay, onCopy }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const popperRef = useRef<HTMLDivElement | null>(null); // for tracking popper ref

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleCheckboxChange = (day: string) => {
        setSelectedDays((prevSelectedDays) =>
            prevSelectedDays.includes(day)
                ? prevSelectedDays.filter((d) => d !== day)
                : [...prevSelectedDays, day]
        );
    };

    const handleApply = () => {
        onCopy(selectedDays);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper-' + currentDay : undefined;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
                setAnchorEl(null);
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    return (
        <div>
            <IconButton className="time-slot-icon-button" aria-describedby={id} onClick={handleClick}>
                <ContentCopyIcon />
            </IconButton>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                transition
                disablePortal
                placement='right'
                style={{ zIndex: 10000 }}
                ref={popperRef}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div className={classes.paper}>
                            <div className="h6">Copy times to...</div>
                            <div className={classes.checkboxContainer}>
                                {WeekDays.map((day) => (
                                    <Checkbox
                                        key={day}
                                        label={day}
                                        checked={selectedDays.includes(day)}
                                        onChange={() => handleCheckboxChange(day)}
                                        disabled={day === currentDay}
                                        className='time-slot-checkbox'
                                    />
                                ))}
                            </div>
                            <div className='time-slot-apply-button'>
                                <ActionsButton onClick={handleApply}>Apply</ActionsButton>
                            </div>
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};

export default CopyTimeSlots;
