import { CouponType, CouponStatus, WeekDay } from '../entities/enums';
export class DiscountSettingDto {
  id: string;
  name: string;
  couponCode: string;
  type: CouponType;
  amount: number;
  status: CouponStatus;
  start: string;
  end: string;
}

export class CreateDiscountSettingDto {
  name: string;
  couponCode: string;
  type: CouponType;
  amount: number;
  status: CouponStatus;
  start: string;
  end: string;
}

export class DiscountsPackageVenueRelationDto {
  id: string;
  selected: boolean;
  venueName: string;
  venueId: string;
  packageName?: string;
  packageId?: string;
}

export class TimeSlotDiscountDto {
  id: string;
  weekDay: WeekDay;
  start: number;
  end: number;
}

export class AddonDiscountDto {
  id: string;
  name: string;
  selected: boolean;
}
