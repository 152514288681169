import React, { useEffect } from "react";
import FormSearchField from "../../../common/FormSearchField";
import {
  DetailsList,
  DetailsListLayoutMode,
  IDropdownOption,
  ScrollablePane,
  SelectionMode,
  Checkbox,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import _ from "lodash";
import {
  State,
  AddonDiscountDto,
} from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import "./index.scss";
import LocationIcon from "../../../../assets/locations-icon.svgr";
import {
  getDiscountAddonsAction,
  updateDiscountAddonsAction,
} from "../../../../actions/discount-action";
import {
  selectDiscountAddons,
  selectIsRequestInProgress,
} from "../../../../reducers/discounts";
import { useViewport } from "../../../../hooks/responsive";

const baseOptions = [
  {
    key: "packages",
    text: "All Addons",
  },
  {
    key: "venues",
    text: "General Reservations",
  },
];

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  relations: selectDiscountAddons(state),
  isLoadingDiscount: selectIsRequestInProgress(state),
});

const mapDispatchToProps = {
  getDiscountAddons: getDiscountAddonsAction,
  updateDiscountAddons: updateDiscountAddonsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OwnPros {
  id: string;
}

type Props = ConnectedProps<typeof connector> & OwnPros;

const DiscountAddons = ({
  id,
  readonly,
  relations,
  isLoadingDiscount,
  getDiscountAddons,
  updateDiscountAddons,
}: Props) => {
  const { isMobile } = useViewport();
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    getDiscountAddons(id);
  }, [id]);


  let relationItems: AddonDiscountDto[] = relations;
  if (relations && search) {
      relationItems = relationItems.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase()));
  }

  const onSelect = (id: string) => {
    const updated = relations.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    onUpdateRelations(updated);
  };

  const allSelect = () => {
    const isAllSelected = relations.every((item) => item.selected)
    const updated = relations.map((item) => ({ ...item, selected: !isAllSelected }))
    onUpdateRelations(updated);
  }

  const onSearch = (ev: any, searchText: string | undefined = ""): void => {
    setSearch(searchText);
  };

  const onUpdateRelations = (
    updatedRelations: AddonDiscountDto[]
  ) => {

    updateDiscountAddons(id, updatedRelations);
  };

  const columns = [
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: AddonDiscountDto) => {
        return (
          <div className="checkbox">
            <Checkbox checked={item.selected} onChange={() => onSelect(item.id)} />
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "name",
      name: "NAME",
      fieldName: "name",
      minWidth: 160,
      maxWidth: 300,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
  ];
  
  return (
    <>
      {isLoadingDiscount && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <section className="discounts-relations">
        <div className={`controls-block ${isMobile ? "mobile" : ""}`}>
          <FormSearchField
            placeholder="Search..."
            className={`control-filed ${isMobile ? "mobile" : ""}`}
            value={search}
            onChange={onSearch}
          />
        </div>
        {relations.length > 0 ? (
          <div className="packages-list">
            <ScrollablePane>
              <DetailsList
                items={relationItems}
                columns={ columns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey={"addons"}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderDetailsHeader={(props, defaultRender) => {
                  if (!props) {
                    return null;
                  }

                  return (
                    <div className="header">
                      <div className="check-box checkbox">
                        <Checkbox checked={false} onChange={allSelect} />
                      </div>
                      {defaultRender!(props)}
                    </div>
                  );
                }}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-package-list-container">
            <div className="empty-package-list">
              <LocationIcon className="location-icon" />
              <div className="empty-package-list-message">
                No addons for this tenant
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default connector(DiscountAddons);
