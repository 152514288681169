import React, { useEffect } from "react";
import FormSearchField from "../../common/FormSearchField";
import {
  DetailsList,
  DetailsListLayoutMode,
  IDropdownOption,
  ScrollablePane,
  SelectionMode,
  Checkbox,
  ActionButton,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import _ from "lodash";
import FormSelectField from "../../common/FormSelectField";
import {
  State,
  AddonsPackageVenueRelationDto,
  Pricing,
  VenueTaxDto,
  ReservationTaxType,
  FeeType,
} from "../../../store/types";
import { connect, ConnectedProps } from "react-redux";
import "./index.scss";
import LocationIcon from "../../../assets/locations-icon.svgr";
import {
  getAddonsPackagesAction,
  updateAddonRelationWithPackagesAction,
} from "../../../actions/addon-action";
import {
  selectAddonsRelations,
  selectIsLoadingAddon,
} from "../../../reducers/addons";
import {
  selectGoTabProducts,
  selectSquare,
  selectVenues,
} from "../../../reducers/venues";
import {
  formatDuration,
  formatDurationRange,
} from "../../../../../server/src/utils/formats";
import {
  selectSettingsState,
  selectTimeSlotDuration,
} from "../../../reducers/settings";
import { getAddonRelationDurations } from "../../../utils/formats";
import { useViewport } from "../../../hooks/responsive";
import EditRelationTaxAndFees from "./EditRelationTaxAndFees";
import {
  getGoTabProductsAndSpotsForVenueAction,
  getSquareCatalogsAction,
} from "../../../actions/venue-actions";

const baseOptions = [
  {
    key: "packages",
    text: "All Packages",
  },
  {
    key: "venues",
    text: "General Reservations",
  },
];

const mapStateToProps = (state: State) => ({
  relations: selectAddonsRelations(state),
  venues: selectVenues(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  isLoadingAddon: selectIsLoadingAddon(state),
  goTabProducts: selectGoTabProducts(state),
  settings: selectSettingsState(state),
  square: selectSquare(state),
});

const mapDispatchToProps = {
  getAddonsPackages: getAddonsPackagesAction,
  updateAddonRelationWithPackages: updateAddonRelationWithPackagesAction,
  getGoTabProducts: getGoTabProductsAndSpotsForVenueAction,
  getSquareItems: getSquareCatalogsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface RelationTaxAndFees {
  id: string;
  reservationTaxes: string;
  serviceFee: string;
  serviceFeeName: string;
  serviceFeeType: string;
  taxForServiceFee: string;
  goTabProductId: string;
  squareItemIds: string[];
}
interface OwnPros {
  id: string;
}

type Props = ConnectedProps<typeof connector> & OwnPros;

interface RelationCellData {
  id: string;
  selected: boolean;
  venueName: string;
  venueId: string;
  packageName?: string;
  packageId?: string;
  status?: boolean;
  numberOfLanes?: number;
  method?: Pricing;
  minGuests?: number;
  maxGuests?: number;
  duration: number;
  durationInSlots: string;
  timeSlotDuration: number;
  enableDurationChoice: boolean;
  reservationTaxes: string;
  venueTax: number;
  taxes: VenueTaxDto[];
  serviceFee: string;
  serviceFeeName: string;
  serviceFeeType: string;
  taxForServiceFee: string;
  goTabProductId: string;
  squareItemIds: string[];
}

const AddonPackages = ({
  id,
  relations,
  isLoadingAddon,
  timeSlotDuration,
  goTabProducts,
  settings,
  venues,
  square,
  getAddonsPackages,
  updateAddonRelationWithPackages,
  getGoTabProducts,
  getSquareItems,
}: Props) => {
  const { isMobile } = useViewport();
  const [filterTable, setFilterTable] = React.useState(baseOptions[0].key);
  const [search, setSearch] = React.useState("");
  const [currentRelation, setCurrentRelation] =
    React.useState<AddonsPackageVenueRelationDto>();
  const [goTabProductList, setGoTabProductList] = React.useState<
    IDropdownOption[]
  >([]);
  const [tableOptions, setTableOptions] =
    React.useState<IDropdownOption[]>(baseOptions);

  const currentVenue = venues.find(
    (venue) => venue.id === currentRelation?.venueId
  );
  const isSquareConnected =
    settings.squareIsEnable &&
    currentVenue?.squareIsEnable &&
    !!currentRelation?.squareLocationId;

  useEffect(() => {
    if (isSquareConnected && currentRelation?.squareLocationId) {
      getSquareItems(currentRelation.squareLocationId);
    }
  }, [isSquareConnected, currentRelation?.squareLocationId]);

  useEffect(() => {
    if (goTabProducts.length > 0) {
      const productList = goTabProducts.map((item) => ({
        key: item.productUuid,
        text: item.name,
      }));
      setGoTabProductList(productList);
    }
  }, [goTabProducts]);

  useEffect(() => {
    getAddonsPackages(id);
  }, [id]);

  useEffect(() => {
    const r = relations || [];
    setTableOptions([
      ...baseOptions,
      ...r
        .filter((item) => !item?.packageId)
        .map(({ venueId, venueName }) => ({ key: venueId, text: venueName })),
    ]);
  }, [relations]);

  const isShowVenueTable = filterTable === "venues";
  let relationItems: AddonsPackageVenueRelationDto[] = [];
  if (relations) {
    if (isShowVenueTable) {
      relationItems = relations.filter((item) => !item?.packageId);
    } else if (filterTable === "packages") {
      relationItems = relations.filter(
        (item) => item?.packageId && item?.packageName
      );
    } else {
      relationItems = relations.filter(
        (item) =>
          item?.packageId && item?.packageName && item?.venueId === filterTable
      );
    }
    if (search) {
      relationItems = relationItems.filter(({ packageName, venueName }) =>
        isShowVenueTable
          ? venueName.toLowerCase().includes(search.toLowerCase())
          : (packageName || "").toLowerCase().includes(search.toLowerCase())
      );
    }
  }

  const onSelect = (id: string) => {
    const updated = relationItems.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    onUpdateRelations(updated);
  };
  const allSelect = () => {
    const isAllSelected = relationItems.every((item) => item.selected);
    const updated = relationItems.map((item) => ({
      ...item,
      selected: !isAllSelected,
    }));
    onUpdateRelations(updated);
  };

  const onGetGoTabProductList = () => {
    if (!!currentRelation?.goTabLocation) {
      getGoTabProducts(currentRelation.goTabLocation);
    }
  };

  const onSquareItemList = () => {
    if (isSquareConnected && currentRelation?.squareLocationId) {
      getSquareItems(currentRelation.squareLocationId);
    }
  };

  const onChangeTaxAndFeesForAddonRelation = ({
    id,
    reservationTaxes,
    serviceFee,
    serviceFeeName,
    serviceFeeType,
    taxForServiceFee,
    goTabProductId,
    squareItemIds,
  }: RelationTaxAndFees) => {
    const updated = relationItems.map((item) =>
      item.id === id
        ? {
            ...item,
            reservationTaxes,
            serviceFee,
            serviceFeeName,
            serviceFeeType,
            taxForServiceFee,
            goTabProductId,
            squareItemIds,
          }
        : item
    );
    onUpdateRelations(updated);
  };

  const packageColumns = [
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: AddonsPackageVenueRelationDto) => {
        const addonRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        let checked = addonRelation && addonRelation.selected;
        return (
          <div className="checkbox">
            <Checkbox checked={checked} onChange={() => onSelect(item.id)} />
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "packageName",
      name: "PACKAGE NAME",
      fieldName: "packageName",
      minWidth: 160,
      maxWidth: 300,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "venueName",
      name: "VENUE",
      fieldName: "venueName",
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "method",
      name: "PRICING METHOD",
      fieldName: "method",
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "guests",
      name: "GUESTS",
      fieldName: "guests",
      minWidth: 90,
      maxWidth: 130,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: RelationCellData) => (
        <>
          {item.minGuests} - {item.maxGuests}
        </>
      ),
    },
    {
      key: "numberOfLanes",
      name: "LANES",
      fieldName: "numberOfLanes",
      minWidth: 30,
      maxWidth: 50,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "duration",
      name: "DURATION",
      fieldName: "duration",
      minWidth: 80,
      maxWidth: 200,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: RelationCellData) => {
        let content = formatDuration(item.duration, timeSlotDuration);
        if (item.enableDurationChoice) {
          const durationList = getAddonRelationDurations(
            item as AddonsPackageVenueRelationDto
          );
          const minimumDuration = Math.min(...durationList);
          const maximumDuration = Math.max(...durationList);
          content = formatDurationRange(
            [minimumDuration, maximumDuration],
            timeSlotDuration
          );
        }
        return content;
      },
    },
    {
      key: "status",
      name: "STATUS",
      fieldName: "status",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: RelationCellData) => {
        const isActive = item.status;
        const statusText = isActive ? "Active" : "Inactive";
        return (
          <div className="status-container">
            <div className={`status-color ${isActive ? "green" : "red"}`} />
            <div className={`status-text body2 ${isActive ? "green" : "red"}`}>
              {statusText}
            </div>
          </div>
        );
      },
    },
    {
      key: "reservationTaxes",
      name: "Reservation Taxes",
      minWidth: 200,
      maxWidth: 250,
      className: "column body2 tax-information",
      onRender: (item: RelationCellData) => {
        const addonRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        return (
          <div className="tax-selector-container detail-title">
            {getReservationTaxesList(
              addonRelation as AddonsPackageVenueRelationDto
            )}
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "reservationTaxesActions",
      name: "",
      minWidth: 90,
      maxWidth: 90,
      className: "column button-column body2",
      onRender: (item: RelationCellData) => {
        const addonRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        let checked = addonRelation && addonRelation.selected;
        return (
          <ActionButton
            className="contextual-menu"
            onClick={() => onOpenEditReservationTaxes(item)}
            disabled={!checked}
          >
            Edit Tax and Fees
          </ActionButton>
        );
      },
      isPadded: true,
    },
  ];

  const venueColumns = [
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: AddonsPackageVenueRelationDto) => {
        const addonRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        let checked = addonRelation && addonRelation.selected;
        return (
          <div className="checkbox">
            <Checkbox checked={checked} onChange={() => onSelect(item.id)} />
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "venueName",
      name: "VENUE NAME",
      fieldName: "venueName",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: RelationCellData) => {
        const venueName = `${item.venueName} - General Reservations`;
        return <span>{venueName}</span>;
      },
    },
    {
      key: "maxGuests",
      name: "MAX GUESTS",
      fieldName: "maxGuests",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "numberOfLanes",
      name: "TOTAL COUNT OF LANES",
      fieldName: "numberOfLanes",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "duration",
      name: "DURATION",
      fieldName: "duration",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: RelationCellData) => {
        const durationList = getAddonRelationDurations(
          item as AddonsPackageVenueRelationDto
        );
        const minimumDuration = Math.min(...durationList);
        const maximumDuration = Math.max(...durationList);
        let content = formatDurationRange(
          [minimumDuration, maximumDuration],
          timeSlotDuration
        );
        return content;
      },
    },
    {
      key: "status",
      name: "STATUS",
      fieldName: "status",
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
      onRender: (item: RelationCellData) => {
        const isActive = item.status;
        const statusText = isActive ? "Opened" : "Closed";
        return (
          <div className="status-container">
            <div className={`status-color ${isActive ? "green" : "red"}`} />
            <div className={`status-text body2 ${isActive ? "green" : "red"}`}>
              {statusText}
            </div>
          </div>
        );
      },
    },
    {
      key: "reservationTaxes",
      name: "Reservation Taxes",
      minWidth: 200,
      maxWidth: 250,
      className: "column body2 tax-information",
      onRender: (item: RelationCellData) => {
        const addonRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        return (
          <div className="tax-selector-container detail-title">
            {getReservationTaxesList(
              addonRelation as AddonsPackageVenueRelationDto
            )}
          </div>
        );
      },
      isPadded: true,
    },
    {
      key: "reservationTaxesActions",
      name: "",
      minWidth: 90,
      maxWidth: 90,
      className: "column button-column body2",
      onRender: (item: RelationCellData) => {
        const addonRelation = relationItems.find(
          (relation) => relation.id === item.id
        );
        let checked = addonRelation && addonRelation.selected;
        return (
          <ActionButton
            className="contextual-menu"
            onClick={() => onOpenEditReservationTaxes(item)}
            disabled={!checked}
          >
            Edit Tax and Fees
          </ActionButton>
        );
      },
      isPadded: true,
    },
  ];

  const getReservationTaxesList = (relation: AddonsPackageVenueRelationDto) => {
    if (
      relation?.reservationTaxes.split(";").includes(ReservationTaxType.noTax)
    ) {
      return ReservationTaxType.noTax;
    }
    let reservationTaxList = "";
    if (
      relation?.reservationTaxes
        .split(";")
        .includes(ReservationTaxType.venueTax)
    ) {
      reservationTaxList = `${ReservationTaxType.venueTax} (${relation.venueTax}%)`;
    }
    if (!!relation?.taxes && relation?.taxes.length > 0) {
      relation.taxes.forEach((tax) => {
        if (relation?.reservationTaxes.split(";").includes(tax.name)) {
          const taxAmount =
            tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
          reservationTaxList = `${reservationTaxList}${
            !!reservationTaxList ? "; " : ""
          }${tax.name} ${taxAmount}`;
        }
      });
    }
    return reservationTaxList;
  };

  const onOpenEditReservationTaxes = (
    relation: AddonsPackageVenueRelationDto
  ) => {
    setCurrentRelation(relation);
    setGoTabProductList([]);
  };

  const onCloseEditReservationTaxes = () => {
    setCurrentRelation(undefined);
    setGoTabProductList([]);
  };

  const onSearch = (ev: any, searchText: string | undefined = ""): void => {
    setSearch(searchText);
  };

  const onFilterByType = (_: any, option?: IDropdownOption) => {
    const key = (option?.key as string) || baseOptions[0].key;
    setFilterTable(key);
    setSearch("");
  };

  const onUpdateRelations = (
    updatedRelations: AddonsPackageVenueRelationDto[]
  ) => {
    const changedValues = updatedRelations.filter((item) => {
      const prevValue = _.find(relations, { id: item.id });
      return (
        prevValue &&
        (prevValue.selected !== item.selected ||
          prevValue.reservationTaxes !== item.reservationTaxes ||
          prevValue.serviceFee !== item.serviceFee ||
          prevValue.serviceFeeName !== item.serviceFeeName ||
          prevValue.serviceFeeType !== item.serviceFeeType ||
          prevValue.taxForServiceFee !== item.taxForServiceFee ||
          prevValue.goTabProductId !== item.goTabProductId ||
          !_.isEqual(prevValue.squareItemIds, item.squareItemIds))
      );
    });
    if (changedValues.length === 0) {
      return;
    }
    //todo update this api
    updateAddonRelationWithPackages(id, changedValues);
  };

  return (
    <>
      {isLoadingAddon && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <section className="addons-relations">
        <div className={`controls-block ${isMobile ? "mobile" : ""}`}>
          <FormSelectField
            options={tableOptions}
            className={`control-filed ${isMobile ? "mobile" : ""}`}
            selectedKey={filterTable}
            onChange={onFilterByType}
          />
          <FormSearchField
            placeholder="Search..."
            className={`control-filed ${isMobile ? "mobile" : ""}`}
            value={search}
            onChange={onSearch}
          />
        </div>
        {relations.length > 0 ? (
          <div className="packages-list">
            <ScrollablePane>
              <DetailsList
                items={relationItems}
                columns={isShowVenueTable ? venueColumns : packageColumns}
                selectionMode={SelectionMode.none}
                getKey={(item) => item.id}
                setKey={isShowVenueTable ? "venues" : "packages"}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderDetailsHeader={(props, defaultRender) => {
                  if (!props) {
                    return null;
                  }
                  return (
                    <div className="header">
                      <div className="check-box checkbox">
                        <Checkbox checked={false} onChange={allSelect} />
                      </div>
                      {defaultRender!(props)}
                    </div>
                  );
                }}
              />
            </ScrollablePane>
          </div>
        ) : (
          <div className="empty-package-list-container">
            <div className="empty-package-list">
              <LocationIcon className="location-icon" />
              <div className="empty-package-list-message">
                No packages for this tenant
              </div>
            </div>
          </div>
        )}
        {!!currentRelation && (
          <EditRelationTaxAndFees
            currentRelation={currentRelation}
            onChangeTaxAndFeesForAddonRelation={
              onChangeTaxAndFeesForAddonRelation
            }
            onClose={onCloseEditReservationTaxes}
            onGetGoTabProductList={onGetGoTabProductList}
            goTabProductList={goTabProductList}
            onSquareItemList={onSquareItemList}
            squareItemList={square.catalogs}
          />
        )}
      </section>
    </>
  );
};

export default connector(AddonPackages);
