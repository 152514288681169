import React from 'react';
import Image from "../../../common/Image";
import Panel from "../../../common/Panel";
import './image.scss'
import {State} from "../../../../store/types";
import {selectReadonly} from "../../../../reducers/auth-reducer";
import {selectCurrentAddon, selectIsImageLoadInProgress} from "../../../../reducers/addons";
import {connect, ConnectedProps} from "react-redux";
import { uploadAddonImageAction } from '../../../../actions/addon-action';

const mapDispatchToProps = {
  uploadAddonImage: uploadAddonImageAction,
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddon: selectCurrentAddon(state),
  isImageLoadInProgress: selectIsImageLoadInProgress(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AddonImage = (
  {
    currentAddon,
    uploadAddonImage,
    isImageLoadInProgress,
    readonly,
  } : Props
) => {

  const uploadAddonImageFile = (files: FileList | null) => {
    if (files && currentAddon?.id && !readonly) {
        uploadAddonImage(files[0], currentAddon?.id);
    }
};
  return (
    <Panel
      className="panel-shift addon-image"
      title={<div className="h6">Images</div>}
    >
      <div className="image-fields-container">
        <div className="image-field">
          <div className="detail-title">
            Image
          </div>
          <Image image={currentAddon?.image} loading={isImageLoadInProgress} uploadImage={uploadAddonImageFile} subTitle="400x400 pixels" />
        </div>
      </div>
    </Panel>
  );
};

export default connector(AddonImage);