import { ActionTypeEnum, AppThunk } from ".";
import { postWithAuth, getWithAuth } from "./api";
import { ConfigsDto } from "../store/types";
import { addErrorAction } from "./auth-actions";

export const CONFIGS_URL = "/api/configs";

export const getConfigsAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetConfigsRequest });
    const response = await getWithAuth(CONFIGS_URL);
    dispatch({
      type: ActionTypeEnum.GetConfigsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get configs error", e);
    dispatch({
      type: ActionTypeEnum.GetConfigsFailure,
      payload: "GetConfigs failure",
    });
    dispatch(addErrorAction("GetConfigs failure"));
  }
};


export const updateConfigsAction = (configs: Partial<ConfigsDto>): AppThunk => async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.UpdateConfigsRequest });
      const response = await postWithAuth(CONFIGS_URL, {configs});
      dispatch({
        type: ActionTypeEnum.UpdateConfigsSuccess,
        payload: response.data,
      });
    } catch (e) {
      console.log("update configs error", e);
      dispatch({
        type: ActionTypeEnum.UpdateConfigsFailure,
        payload: "UpdateConfigs failure",
      });
      dispatch(addErrorAction("UpdateConfigs failure"));
    }
  };
