import { Pricing } from "../store/types";

export const pricingModelTypes = [
    {
        key: Pricing.perLane,
        text: "Per Lane per hour",
    },
    {
        key: Pricing.perPerson,
        text: "Per Person per hour",
    },
    {
        key: Pricing.flatRate,
        text: "Flat Rate",
    },
    {
        key: Pricing.flatRatePerLane,
        text: "Flat Rate per lane",
    },
    {
        key: Pricing.perPersonFlat,
        text: "Flat Rate per person",
    },
    {
        key: Pricing.flatRatePerHour,
        text: "Flat Rate per hour",
    },
    {
        key: Pricing.ageGroupsPerHour,
        text: "Age Groups (per hour)",
    },
    {
        key: Pricing.ageGroupsFlatRate,
        text: "Age Groups (flat rate)",
    },
];