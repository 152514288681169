import React, { useRef } from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { nanoid } from "nanoid";
import UploadIcon from "../../../assets/upload-icon.svgr";
import "./venueImage.scss";

interface VenueImageProps {
  subTitle: string;
  image?: string;
  loading: boolean;
  uploadImage: (files: FileList | null) => void;
  multiple?: boolean;
}

const VenueImage = ({
  subTitle,
  image,
  loading,
  uploadImage,
  multiple = false,
}: VenueImageProps) => {
  const ref = useRef<string>(nanoid());

  if (loading) {
    return (
      <div className="venue-image spinner">
        <Spinner size={SpinnerSize.large} />
      </div>
    );
  }
  if (image) {
    return (
      <>
        <input
          id={ref.current}
          type="file"
          onChange={(e) => uploadImage(e.target.files)}
          hidden
          multiple={multiple}
        />
        <label className="venue-image" htmlFor={ref.current}>
          <img className="venue-image" src={image} />
        </label>
      </>
    );
  }
  return (
    <>
      <input
        id={ref.current}
        type="file"
        onChange={(e) => uploadImage(e.target.files)}
        hidden
        multiple={multiple}
      />
      <label className="venue-image empty" htmlFor={ref.current}>
        <div className="image-field-icon">
          <UploadIcon className="upload-icon" />
        </div>
        <div className="detail-title browse-text">Browse</div>
        <div className="detail-text">{subTitle}</div>
      </label>
    </>
  );
};

export default VenueImage;
