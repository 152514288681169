import React, { useState } from "react";
import { Modal } from "office-ui-fabric-react";
import { DatePicker } from '@material-ui/pickers';
import dayjs from "dayjs";
import {UIConfigDto} from "../../../store/types";
import CancelButton from "../../../../../common/components/CancelButton";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import "./selectDateRange.scss";

type Props = {
  onClose: (sDate?: Date | null, eDate?: Date | null) => void;
  uiConfig?: UIConfigDto
};

const SelectDateRange = ({ onClose, uiConfig }: Props) => {

  const [startDate, setStartDate] = useState<Date | null | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date());
  
  const onCancel = () => {
    if (startDate && endDate) {
      onClose(startDate, endDate);
      return;
    }
  };
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="select-date-range"
    >
      <div className="container">
        <div className="header h4">
          <div>Select Date Range</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="select-date-row">
          <div className="select-date">
            <div className="subtitle2">Start date</div>
            <DatePicker
              autoOk
              value={startDate}
              variant="inline"
              inputVariant="outlined"
              onChange={data => setStartDate(dayjs(data).toDate())}
              format={uiConfig?.dateFormat || "MM/DD/YYYY"}
              className="datePicker"
            />
          </div>
          <div className="select-date">
            <div className="subtitle2">End date</div>
            <DatePicker
              autoOk
              value={endDate}
              variant="inline"
              inputVariant="outlined"
              onChange={data => setEndDate(dayjs(data).toDate())}
              format={uiConfig?.dateFormat || "MM/DD/YYYY"}
              className="datePicker"
            />  
          </div>
        </div>
        <div className="buttons-container">
          <LinkButton onClick={() => onClose()}>Cancel</LinkButton>
          <ColorButton
            disabled={!startDate || !endDate}
            onClick={() => onCancel()}
          >
            Select
          </ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default SelectDateRange;
