import React from "react";
import BackButton from "../../common/BackButton/BackButton";
import ActionsButton from "../../common/ActionsButton";
import ChevronDown from "../../../assets/custom-chevron-down.svgr";
import {
  Callout,
  DefaultButton,
  DirectionalHint,
  mergeStyleSets,
} from "office-ui-fabric-react";
import { setWebConfigUIAction } from "../../../actions/ui-actions";
import { discountConfigUI, webConfigUI } from "../../../constants/webConfigUI";
import { removeDiscountAction } from "../../../actions/discount-action";
import { selectReadonly } from "../../../reducers/auth-reducer";
import { selectCurrentDiscount } from "../../../reducers/discounts";
import { State } from "../../../store/types";
import { connect, ConnectedProps } from "react-redux";
import DeleteConfirmation from "../../common/DeleteConfirmation/DeleteConfirmation";
import "./discountDetails.scss";
import GeneralDetails from "./GeneralDetails/GeneralDetails";
import DiscountPackages from "./Packages/Packages";
import EditSchedule from "./EditSchedule/EditSchedule";
import Addons from "./Addons/Addons";

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "100px",
    maxHeight: "192px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const tabs = [
  {
    text: "Discount Details",
    id: discountConfigUI.GENERAL,
  },
  {
    text: "Schedule",
    id: discountConfigUI.SCHEDULE,
  },
  {
    text: "Assign",
    id: discountConfigUI.ASSIGN,
  },
  {
    text: "Addons",
    id: discountConfigUI.ADDONS,
  },
];

const mapDispatchToProps = {
  removeDiscount: removeDiscountAction,
  setWebConfigUI: setWebConfigUIAction,
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentDiscount: selectCurrentDiscount(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const DiscountDetails = ({
  setWebConfigUI,
  removeDiscount,
  currentDiscount,
}: Props) => {
  const [isShowActionsMenu, setIsShowActionsMenu] = React.useState(false);
  const [discountPage, setDiscountPage] = React.useState(
    discountConfigUI.GENERAL
  );
  const [isRemoveDiscountOpen, setIsRemoveDiscountOpen] = React.useState(false);

  const backToList = () => {
    setWebConfigUI(webConfigUI.DISCOUNTS);
  };

  const confirmedRemoveDiscount = (isConfirm: boolean) => {
    if (isConfirm && currentDiscount) {
      removeDiscount(currentDiscount.id);
      setWebConfigUI(webConfigUI.DISCOUNTS);
    }
    setIsRemoveDiscountOpen(false);
  };

  if (!currentDiscount) {
    return null;
  }

  const content = () => {
    switch (discountPage) {
      case discountConfigUI.GENERAL:
        return <GeneralDetails />;
      case discountConfigUI.SCHEDULE:
        return <EditSchedule discount={currentDiscount} />;
      case discountConfigUI.ASSIGN:
        return <DiscountPackages id={currentDiscount.id} />;
      case discountConfigUI.ADDONS:
        return <Addons id={currentDiscount.id} />;
      default:
        return <div>select tab</div>;
    }
  };

  return (
    <>
      <div className="discount-details-container">
        <div className="discount-details">
          <BackButton text="All Discounts" onBack={backToList} />
          <div className="discount-details-information-container">
            <div className="discount-details-information-header">
              <div className="discount-details-title h4">
                {currentDiscount.name}
              </div>
              <div className="actions-container">
                <ActionsButton
                  id="actions-button"
                  onClick={() => setIsShowActionsMenu(true)}
                >
                  <div className="actions-button-text">Actions</div>
                  <ChevronDown className="chevron" />
                </ActionsButton>
                {isShowActionsMenu && (
                  <Callout
                    className={styles.callout}
                    gapSpace={0}
                    target={`#actions-button`}
                    onDismiss={() => setIsShowActionsMenu(false)}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible={false}
                  >
                    <div className="actions-button-container">
                      <DefaultButton
                        className="actions-button"
                        onClick={() => setIsRemoveDiscountOpen(true)}
                      >
                        <div className="actions-text">Delete discount</div>
                      </DefaultButton>
                    </div>
                  </Callout>
                )}
              </div>
            </div>
            <div className="discount-details-toggle">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  className={`discount-details-toggle-label body2 ${discountPage === tab.id ? "active" : ""
                    }`}
                  onClick={() => setDiscountPage(tab.id)}
                >
                  {tab.text}
                </div>
              ))}
            </div>
            {content()}
          </div>
        </div>
      </div>
      {isRemoveDiscountOpen && (
        <DeleteConfirmation
          ConfirmationHeader={"Confirmation"}
          ConfirmationText={"Do you want to remove this discount?"}
          ConfirmationButtonText={"Delete Discount"}
          onClose={confirmedRemoveDiscount}
        />
      )}
    </>
  );
};

export default connector(DiscountDetails);
