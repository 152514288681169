import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { InputField } from "../../common/InputField/InputField";
import PrimaryButton  from "../../common/PrimaryButton/PrimaryButton";
import { signUpAction } from "../../../actions/auth-actions";
import { State } from "../../../store/types";
import { selectAuthError } from "../../../reducers/auth-reducer";
import { parseUrlQuery } from "../../../utils/urlSearchQuery";
import RexIcon from "../../../assets/rex-black.png";
import { isValidEmail } from "../../../../../common/utils/validate";
import "./signUp.scss";

const mapDispatchToProps = {
  signUp: signUpAction,
};
const mapStateToProps = (state: State) => ({
  error: selectAuthError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const SignUp = ({ signUp, error }: Props) => {
  const [username, setUserName] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [repeatePassword, setRepeatePassword] = useState<string | undefined>("");
  const [domain, setDomain] = useState<string | undefined>("");
  const [errorUsername, setErrorUserName] = useState<string>("");

  const onUserNameChange = (_: any, text?: string) => {
    setErrorUserName("");
    setUserName(text);
  };
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (username && isValidEmail(username) && password && password === repeatePassword && domain) {
      const { purchaseToken } = parseUrlQuery(location.search)
      signUp({ username, password, token: purchaseToken, domain });
    }
    if (!username || !isValidEmail(username)) {
      setErrorUserName('Please enter a valid email address.')
    }
  };
  return (
    <form className="sign-up-registrationInfo" onSubmit={onSubmit}>
      <img className="logo-icon" src={RexIcon} />
      <div className="title">Welcome to REX!</div>
      <div className="sub-title">Start by creating an account so you can access to your admin dashboard.</div>
      <InputField
        label="Email"
        autoComplete="email"
        value={username}
        onChange={onUserNameChange}
        errorMessage={errorUsername}
      />
      <InputField
        type="password"
        label="Password"
        autoComplete="current-password"
        value={password}
        onChange={(_: any, value: string) => setPassword(value)}
      />
      <InputField
        type="password"
        label="Confirm Password"
        value={repeatePassword}
        onChange={(_: any, value: string) => setRepeatePassword(value)}
      />
      <InputField
        label="Company Website"
        value={domain}
        onChange={(_: any, value: string) => setDomain(value)}
      />
      {error && <div className="error">{error}</div>}
      <PrimaryButton type="submit" backgroundColor="#00f">Create my account</PrimaryButton>
    </form>
  );
};

export default connector(SignUp);
