
import { TenantSettingsDto } from '../../../../server/src/dto/tenantSettings.dto';
import { TenantUserDto } from './auth';

export * from "../../../../server/src/dto/tenantSettings.dto";
export * from "../../../../server/src/dto/createTenant.dto";
export * from "../../../../server/src/entities/enums";

export interface TenantState {
  isRequestInProgress: boolean;
  isUserRequestInProgress: boolean;
  tenants: TenantSettingsDto[];
  tenant?: TenantSettingsDto;
  error?: string;
  users: TenantUserDto[];
  selectedUser?: TenantUserDto;
}
