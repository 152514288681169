import { VenueDto, Availability } from "../../../../server/src/dto/venue.dto";
import { PackageNameDto as PackageName } from "../../../../server/src/dto/packageName.dto";

export { VenueDto, Availability } from "../../../../server/src/dto/venue.dto";
export { PaymentType as PMethod, Pricing, CustomFieldsType } from "../../../../server/src/entities/enums";
export { PackageNameDto as PackageName } from "../../../../server/src/dto/packageName.dto";

export interface BookingVenueState {
  availability: Availability;
  isLoadingVenue: boolean;
  isLoadingAvailability: boolean;
  venue?: VenueDto;
  error?: string;
  currentPackage?: PackageName;
}
