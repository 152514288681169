import { createSelector } from "reselect";
import { DiscountsState, State, DiscountSettingDto } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: DiscountsState = Object.freeze({
  discounts: [],
  filteredDiscounts: [],
  isRequestInProgress: false,
  error: "",
  currentDiscount: undefined,
  relations: [],
  slots: [],
  addons: [],
});

export default createReducer<DiscountsState>(initialState, {
  [ActionTypeEnum.GetDiscounts]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetDiscountsSuccess]:
    (discounts: DiscountSettingDto[]) => (state: DiscountsState) => {
      return {
        ...state,
        isRequestInProgress: false,
        discounts,
        currentDiscount: undefined,
        error: "",
      };
    },
  [ActionTypeEnum.GetDiscountsFailure]:
    (error: string) => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.GetDiscount]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetDiscountSuccess]:
    (currentDiscount?: DiscountSettingDto) => (state: DiscountsState) => {
      return {
        ...state,
        isRequestInProgress: false,
        currentDiscount,
        error: "",
      };
    },
  [ActionTypeEnum.GetDiscountFailure]: (error) => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.RemoveScheduleDiscount]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.RemoveScheduleDiscountSuccess]:
    () => (state: DiscountsState) => {
      return {
        ...state,
        isRequestInProgress: false,
        currentDiscount: undefined,
      };
    },
  [ActionTypeEnum.RemoveScheduleDiscountFailure]:
    (error) => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.GetDiscountsRelations]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.GetDiscountsRelationsSuccess]:
    (relations) => (state: DiscountsState) => {
      return {
        ...state,
        isRequestInProgress: false,
        relations,
      };
    },
  [ActionTypeEnum.GetDiscountsRelationsFailure]:
    (error) => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.UpdateDiscountRelations]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.UpdateDiscountRelationsSuccess]:
    (relations) => (state: DiscountsState) => {
      return {
        ...state,
        isRequestInProgress: false,
        relations,
        error: "",
      };
    },
  [ActionTypeEnum.UpdateDiscountRelationsFailure]:
    (error) => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.CreateDiscount]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.CreateDiscountSuccess]:
    (discount) => (state: DiscountsState) => {
      const discounts = [...state.discounts, discount];
      return {
        ...state,
        isRequestInProgress: false,
        discounts,
        currentDiscount: discount,
        error: "",
      };
    },
  [ActionTypeEnum.CreateDiscountFailure]:
    (error) => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.UpdateDiscount]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.UpdateDiscountSuccess]:
    (discount: DiscountSettingDto) => (state: DiscountsState) => {
      const discounts = state.discounts.map((item) =>
        item.id === discount.id ? discount : item
      );
      return {
        ...state,
        isRequestInProgress: false,
        discounts,
        currentDiscount: discount,
        error: "",
      };
    },
  [ActionTypeEnum.UpdateDiscountFailure]:
    (error) => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.RemoveDiscount]: () => (state: DiscountsState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.RemoveDiscountSuccess]:
    (id: string) => (state: DiscountsState) => {
      const discounts = state.discounts.filter((item) => item.id === id);
      return {
        ...state,
        isRequestInProgress: false,
        discounts,
        currentDiscount: undefined,
        error: "",
      };
    },
  [ActionTypeEnum.RemoveDiscountFailure]:
    (error) => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
    [ActionTypeEnum.GetDiscountsSchedule]: () => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: true,
    }),
    [ActionTypeEnum.GetDiscountsScheduleSuccess]:
      (slots) => (state: DiscountsState) => {
        return {
          ...state,
          isRequestInProgress: false,
          slots,
        };
      },
    [ActionTypeEnum.GetDiscountsScheduleFailure]:
      (error) => (state: DiscountsState) => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
    [ActionTypeEnum.UpdateDiscountSchedule]: () => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: true,
      error: "",
    }),
    [ActionTypeEnum.UpdateDiscountScheduleSuccess]:
      (slots) => (state: DiscountsState) => {
        return {
          ...state,
          isRequestInProgress: false,
          slots,
          error: "",
        };
      },
    [ActionTypeEnum.UpdateDiscountScheduleFailure]:
      (error) => (state: DiscountsState) => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
    [ActionTypeEnum.GetDiscountAddons]: () => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: true,
    }),
    [ActionTypeEnum.GetDiscountAddonsSuccess]:
      (addons) => (state: DiscountsState) => {
        return {
          ...state,
          isRequestInProgress: false,
          addons,
        };
      },
    [ActionTypeEnum.GetDiscountAddonsFailure]:
      (error) => (state: DiscountsState) => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
    [ActionTypeEnum.UpdateDiscountAddons]: () => (state: DiscountsState) => ({
      ...state,
      isRequestInProgress: true,
      error: "",
    }),
    [ActionTypeEnum.UpdateDiscountAddonsSuccess]:
      (addons) => (state: DiscountsState) => {
        return {
          ...state,
          isRequestInProgress: false,
          addons,
          error: "",
        };
      },
    [ActionTypeEnum.UpdateDiscountAddonsFailure]:
      (error) => (state: DiscountsState) => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
});

export const selectDiscountsState = (state: State) => state.discounts;
export const selectDiscounts = createSelector(
  selectDiscountsState,
  (state: DiscountsState) => state.discounts
);
export const selectDiscountsRelations = createSelector(
  selectDiscountsState,
  (state: DiscountsState) => state.relations
);
export const selectDiscountsSlots = createSelector(
  selectDiscountsState,
  (state: DiscountsState) => state.slots
);
export const selectIsRequestInProgress = createSelector(
  selectDiscountsState,
  (state: DiscountsState) => state.isRequestInProgress
);
export const selectCurrentDiscount = createSelector(
  selectDiscountsState,
  (state: DiscountsState) => state.currentDiscount
);
export const selectDiscountAddons = createSelector(
  selectDiscountsState,
  (state: DiscountsState) => state.addons
);
export const selectDiscountError = createSelector(
  selectDiscountsState,
  (state: DiscountsState) => state.error
);
