import React, { useState, useEffect } from "react";
import { orderBy } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,
    Icon,
    ActionButton,
    DetailsRow,
    IconButton,
    Toggle,
    IDropdownOption,
    Checkbox,
} from "office-ui-fabric-react";
import dayjs from "dayjs";
import { uploadVenueImageAction, uploadVenueMapImageAction, updateVenueAction, updateVenueTaxesAction, uploadVenueImagesAction, deleteVenueImageAction } from "../../../actions/venue-actions";
import { changeActiveCustomFields } from "../../../actions/package-action";
import { selectIsImageLoadInProgress, selectIsMapImageLoadInProgress, selectSocial, selectVenueError } from "../../../reducers/venues";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { State, ScheduleSettingDto, VenueSettingDto, FeeType, customFields, VenueTaxDto, ReservationTaxType, VenuesState, VenueDto } from "../../../store/types";
import { durationSlotsToTime, formatScheduleDate, getBookingDuration } from "../../../utils/formats";
import EditVenueImages from "./EditVenueImages/EditVenueImages";
import EditPaymentSettings from "./EditPaymentSettings/EditPaymentSettings";
import EditCancelAndModifySettings from "./EditCancelAndModifySettings/EditCancelAndModifySettings";
import EditReservationsSettings from "./EditReservationsSettings/EditReservationsSettings";
import VenueMainActions from "../VenueMainActions";
import CalendarIcon from "../../../assets/calendar-icon.svgr";
import VertIcon from "../../../assets/more-vert.svgr";
import TrashIcon from "../../../assets/trash-icon.svgr";
import ColorButton from "../../../../../common/components/ColorButton";
import Panel from "../../common/Panel";
import LinkButton from "../../../../../common/components/LinkButton";
import FormTextField from "../../../../../common/components/FormTextField";
import FormSelectField from "../../common/FormSelectField";
import VenueImage from "../../common/Image";
import AssignResource from "../AssignResource/AssignResource";
import { CustomFieldsMapping, reservationFieldList } from "../Packages/PackageDetails/PackageDetails";
import { DEFAULT_CARD_COLOR } from "../../../hooks/responsive";
import { activeCustomFieldsToUIModel } from "../../../../../common/utils/formats";
import EditVenueSocialMedia from "../../Settings/ThemeDesign/EditVenueSocialMedia";
import EditSidebarLinks from "./EditSidebarLinks";
import { GoogleMaterialSymbol } from "../../common/GoogleMaterialSymbol";
import { loadStylesheet } from "../../../../../common/utils/loader";

import "./venueInfo.scss";
import { getQuestionsAction } from "../../../actions/question-action";
import { selectQuestions } from "../../../reducers/questions";

interface CellData {
    id: string;
    start: string;
    end: string;
}
interface VenueItem {
    name: string;
    value: string;
}

const scheduleListStyles = {
    focusZone: { cursor: "pointer" }
}

export const feeTypeOptions: IDropdownOption[] = [
    {
        key: FeeType.PERCENT,
        text: FeeType.PERCENT,
    },
    {
        key: FeeType.FLAT_RATE,
        text: FeeType.FLAT_RATE,
    }
];

export const cardColorTypeOptions: IDropdownOption[] = [
    { key: '#FF9C72', text: 'Coral', data: { background: '#FF9C72' } },
    { key: '#10D8AE', text: 'Emerald', data: { background: '#10D8AE' } },
    { key: '#BFB5FF', text: 'Lavender', data: { background: '#BFB5FF' } },
    { key: '#FE6D79', text: 'Punch', data: { background: '#FE6D79' } },
    { key: '#5FAAFF', text: 'Azure', data: { background: '#5FAAFF' } },
    { key: '#FFA2C6', text: 'Bubblegum', data: { background: '#FFA2C6' } },
    { key: '#FFD669', text: 'Sunshine', data: { background: '#FFD669' } },
    { key: '#E7EFFF', text: 'Cloud', data: { background: '#E7EFFF' } },
];

const mapStateToProps = (state: State) => ({
    error: selectVenueError(state),
    isImageLoadInProgress: selectIsImageLoadInProgress(state),
    isMapImageLoadInProgress: selectIsMapImageLoadInProgress(state),
    uiConfig: selectUIConfig(state),
    social: selectSocial(state),
    questions: selectQuestions(state),
});

const mapDispatchToProps = {
    updateVenue: updateVenueAction,
    uploadVenueImage: uploadVenueImageAction,
    uploadVenueImages: uploadVenueImagesAction,
    uploadVenueMapImage: uploadVenueMapImageAction,
    updateVenueTaxes: updateVenueTaxesAction,
    deleteVenueImage: deleteVenueImageAction,
    getQuestions: getQuestionsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
    isMiddleScreen: boolean;
    readonly: boolean;
    venue?: VenueSettingDto;
    editVenue: () => void;
    addSchedule: () => void;
    editSchedule: (schedule: ScheduleSettingDto) => void;
    cloneSchedule: (schedule: ScheduleSettingDto) => void;
    removeVenueSchedule: (scheduleId: string) => void;
};

const renderListItem = (item: VenueItem, index: number) => (<div key={index} className="venue-item"><div className="venue-item-name subtitle2">{item.name}</div><div className="venue-item-value body2">{item.value}</div></div>);

const VenueInfo = ({
    isMiddleScreen,
    readonly,
    venue,
    error,
    isImageLoadInProgress,
    isMapImageLoadInProgress,
    uiConfig,
    social,
    editVenue,
    addSchedule,
    editSchedule,
    cloneSchedule,
    removeVenueSchedule,
    uploadVenueImage,
    uploadVenueImages,
    uploadVenueMapImage,
    updateVenue,
    updateVenueTaxes,
    deleteVenueImage,
    questions,
    getQuestions
}: Props) => {
    const resFieldOptions = [...reservationFieldList, ...questions?.map(question => ({ key: question.id, text: question.displayName })) || []];

    useEffect(() => {
        setColumns(initialColumns);
        setSchedules(orderBy(venue?.schedules, ['priority'], ['desc']) || []);
        setServiceFees(venue?.serviceFee ? venue.serviceFee.split(',') : []);
        setServiceFeeNames(venue?.serviceFeeName ? venue.serviceFeeName.split(',') : new Array(venue?.serviceFee.split(',').length).fill(''));
        setServiceFeeTypes(venue?.serviceFeeType ? venue.serviceFeeType.split(',') : new Array(venue?.serviceFee.split(',').length).fill(''));
        setTaxForServiceFees(venue?.taxForServiceFee ? venue.taxForServiceFee.split(',') : new Array(venue?.serviceFee.split(',').length).fill(''));
        setActiveCustomFields(venue?.activeCustomFields || '');
        setMandatoryCustomFields(venue?.mandatoryCustomFields || '');
        setNewCustomFieldsMapping(expendCustomFieldsMapping(venue?.tripleSeatCustomFieldsMapping || ''));
        setVenueTaxes(venue && venue?.taxes && venue.taxes.length > 0 ? venue.taxes : []);
        setReservationTaxes(venue?.reservationTaxes || '');
        setIsOpenReservationTaxes(false);
        setCardColor(venue?.cardColor || DEFAULT_CARD_COLOR);
        loadStylesheet('https://fonts.googleapis.com/icon?family=Material+Icons');
        getQuestions()
    }, [venue]);
    const initialColumns: IColumn[] = [
        {
            key: "name",
            name: "NAME",
            fieldName: "title",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            className: "bold-column subtitle2",
            data: "string",
            isPadded: true,
        },
        {
            key: "startDate",
            name: "START DATE",
            fieldName: "start",
            minWidth: 90,
            maxWidth: 150,
            isResizable: true,
            className: "column body2",
            data: "string",
            isPadded: true,
            onRender: (item: CellData) => {
                return <span>{formatScheduleDate(item.start)}</span>;
            },
        },
        {
            key: "endDate",
            name: "END DATE",
            fieldName: "end",
            minWidth: 90,
            maxWidth: 150,
            isResizable: true,
            className: "column body2",
            data: "string",
            isPadded: true,
            onRender: (item: CellData) => {
                return <span>{formatScheduleDate(item.end)}</span>;
            },
        },
        {
            key: "priority",
            name: "PRIORITY",
            fieldName: "priority",
            minWidth: 45,
            maxWidth: 100,
            isResizable: true,
            className: "column body2",
            data: "string",
            isPadded: true,
            isSorted: true,
            isSortedDescending: false,
        },
        {
            key: "action",
            name: "",
            minWidth: 25,
            maxWidth: 25,
            className: "column body2",
            onRender: (item: CellData) => {
                const menuProps = {
                    shouldFocusOnMount: true,
                    isBeakVisible: false,
                    items: [
                        { key: 'newItem', text: 'Edit', onClick: () => editSchedule(item as ScheduleSettingDto) },
                        { key: 'newItem', text: 'Clone', onClick: () => cloneSchedule(item as ScheduleSettingDto) },
                        { key: 'newItem', text: 'Delete', onClick: () => removeVenueSchedule(item.id) },
                    ],
                }
                return <ActionButton className="contextual-menu" menuProps={menuProps}> <VertIcon /></ActionButton>;
            },
            isPadded: true,
        },
    ];

    const socialMedia: VenueItem[] = [
        { name: 'Facebook', value: social?.facebookUrl || '-' },
        { name: 'Instagram', value: social?.instagramUrl || '-' },
        { name: 'X (Twitter)', value: social?.twitterUrl || '-' },
        { name: 'TikTok', value: social?.tikTokUrl || '-' },
        { name: 'View Menu', value: social?.viewMenuUrl || '-' },
    ]

    const expendCustomFieldsMapping = (fields: string): CustomFieldsMapping[] => {
        return fields.split(';').map((item, index) => ({
            id: index,
            tsId: item.split(':')[0] || '',
            name: item.split(':')[1] || '',
            param: item.split(':')[2] || '',
        }))
    }
    const foldCustomFieldsMapping = (fields: CustomFieldsMapping[]) => {
        return fields.map(item => `${item.tsId}:${item.name}:${item.param}`).join(';')
    }
    const onUpdateCustomFieldsMapping = (field: CustomFieldsMapping) => {
        const newValue = newCustomFieldsMapping.map(item => item.id === field.id ? field : item)
        setNewCustomFieldsMapping(newValue)
    }
    const onAddCustomFieldsMapping = () => {
        setNewCustomFieldsMapping([...newCustomFieldsMapping, { id: newCustomFieldsMapping.length, tsId: '', name: '', param: '' }])
    }
    const onRemoveCustomFieldsMapping = (field: CustomFieldsMapping) => {
        const newValue = newCustomFieldsMapping.filter(item => item.id !== field.id).map((item, index) => ({ ...item, id: index }))
        setNewCustomFieldsMapping(newValue)
    }

    const actionText = uiConfig?.actionText || "Lane";
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const formatTime = twelveHourClockFormat ? 'hh:mm A' : 'HH:mm';
    const [isOpenEditImages, setIsOpenEditImages] = useState(false);
    const [isOpenEditPaymentSettings, setIsOpenEditPaymentSettings] = useState(false);
    const [isOpenEditCancelAndModifySettings, setIsOpenEditCancelAndModifySettings] = useState(false);
    const [isOpenEditReservationsSettings, setIsOpenEditReservationsSettings] = useState(false);
    const [isOpenEditSocialMedia, setIsOpenEditSocialMedia] = useState(false);
    const [isOpenEditSidebarLinks, setIsOpenEditSidebarLinks] = useState(false);
    const [columns, setColumns] = useState<IColumn[]>(initialColumns);
    const [schedules, setSchedules] = useState<ScheduleSettingDto[] | undefined>(orderBy(venue?.schedules, ['priority'], ['desc']) || []);
    const [serviceFees, setServiceFees] = useState(venue?.serviceFee ? venue.serviceFee.split(',') : []);
    const [serviceFeeNames, setServiceFeeNames] = useState(venue?.serviceFeeName ? venue.serviceFeeName.split(',') : new Array(venue?.serviceFee.split(',').length).fill(''));
    const [serviceFeeTypes, setServiceFeeTypes] = useState(venue?.serviceFeeType ? venue.serviceFeeType.split(',') : new Array(venue?.serviceFee.split(',').length).fill(''));
    const [taxForServiceFees, setTaxForServiceFees] = useState(venue?.taxForServiceFee ? venue.taxForServiceFee.split(',') : new Array(venue?.serviceFee.split(',').length).fill(''));
    const [activeCustomFields, setActiveCustomFields] = useState<string>(venue?.activeCustomFields || "");
    const [mandatoryCustomFields, setMandatoryCustomFields] = useState<string>(venue?.mandatoryCustomFields || "");
    const [newCustomFieldsMapping, setNewCustomFieldsMapping] = useState<CustomFieldsMapping[]>(expendCustomFieldsMapping(venue?.tripleSeatCustomFieldsMapping || ''));
    const [venueTaxes, setVenueTaxes] = useState(venue && venue?.taxes && venue.taxes.length > 0 ? venue.taxes : []);
    const [reservationTaxes, setReservationTaxes] = useState<string>(venue?.reservationTaxes || "");
    const [isOpenReservationTaxes, setIsOpenReservationTaxes] = useState<boolean>(false);
    const [cardColor, setCardColor] = useState<string>(venue?.cardColor || DEFAULT_CARD_COLOR);

    const [venueTaxesError, setVenueTaxesError] = useState("");

    let taxOptions = !!venue?.taxes && venue?.taxes.length > 0 ? venue.taxes.map((tax) => {
        const taxAmount = tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
        const taxLabel = `${tax.name} ${taxAmount}`;
        return {
            key: tax.name,
            text: taxLabel,
        };
    }) : [];

    const feeTaxOptions: IDropdownOption[] = [{
        key: ReservationTaxType.noTax,
        text: ReservationTaxType.noTax,
    },
    {
        key: ReservationTaxType.venueTax,
        text: `${ReservationTaxType.venueTax} (${venue?.tax || 0}%)`,
    },
    ]

    let customFeeTaxOptions = feeTaxOptions.concat(taxOptions);

    const getPatch = () => {
        const newServiceFee = serviceFees.length > 0 ? serviceFees.map(item => (+item).toFixed(2)).join(',') : "";
        const newServiceFeeName = serviceFeeNames.length > 0 ? serviceFeeNames.join(',') : "";
        const newServiceFeeType = serviceFeeTypes.length > 0 ? serviceFeeTypes.join(',') : "";
        let newTaxForServiceFee = "";
        if (taxForServiceFees.length > 0) {
            const taxes = taxForServiceFees.map(tax => tax || ReservationTaxType.noTax);
            newTaxForServiceFee = taxes.join(',');
        }
        return {
            serviceFee: newServiceFee,
            serviceFeeName: newServiceFeeName,
            serviceFeeType: newServiceFeeType,
            taxForServiceFee: newTaxForServiceFee,
        };
    }

    const onUpdateServiceFees = (value: string, index: number) => {
        setServiceFees(serviceFees.map((item, i) => {
            if (i === index) {
                return value
            }
            return item
        }));
    }
    const onUpdateServiceFeeNames = (value: string, index: number) => {
        setServiceFeeNames(serviceFeeNames.map((item, i) => {
            if (i === index) {
                return value
            }
            return item
        }));
    }
    const onUpdateServiceFeeTypes = (value: string, index: number) => {
        setServiceFeeTypes(serviceFeeTypes.map((item, i) => {
            if (i === index) {
                return value
            }
            return item
        }));
    }
    const onUpdateTaxForServiceFees = (value: string, index: number) => {
        setTaxForServiceFees(taxForServiceFees.map((item, i) => {
            if (i === index) {
                return value;
            }
            return item
        }));
    }
    const onRemoveServiceFee = (index: number) => {
        setServiceFees(serviceFees.filter((_, i) => i !== index));
        setServiceFeeNames(serviceFeeNames.filter((_, i) => i !== index));
        setServiceFeeTypes(serviceFeeTypes.filter((_, i) => i !== index));
        setTaxForServiceFees(taxForServiceFees.filter((_, i) => i !== index));
    }
    const onAddServiceFee = () => {
        setServiceFees([...serviceFees, '']);
        setServiceFeeNames([...serviceFeeNames, '']);
        setServiceFeeTypes([...serviceFeeTypes, '']);
        setTaxForServiceFees([...taxForServiceFees, '']);
    }
    const onUpdateServiceFee = () => {
        const patch = getPatch();
        updateVenue({ ...venue, ...patch });
    }


    const onUpdateVenueTaxValue = (value: string, index: number) => {
        setVenueTaxesError('');
        setVenueTaxes(venueTaxes.map((item, i) => {
            if (i === index) {
                item.tax = value;
            }
            return item
        }));
    }
    const onUpdateVenueTaxName = (value: string, index: number) => {
        setVenueTaxesError('');
        setVenueTaxes(venueTaxes.map((item, i) => {
            if (i === index) {
                item.name = value;
            }
            return item
        }));
    }
    const onUpdateVenueTaxType = (value: string, index: number) => {
        setVenueTaxesError('');
        setVenueTaxes(venueTaxes.map((item, i) => {
            if (i === index) {
                item.type = value;
            }
            return item
        }));
    }
    const onRemoveVenueTax = (index: number) => {
        setVenueTaxesError('');
        setVenueTaxes(venueTaxes.filter((_, i) => i !== index));
    }
    const onAddVenueTax = () => {
        setVenueTaxesError('');
        if (venue) {
            const newTax: VenueTaxDto = {
                id: Date.now().toString(),
                tax: '0',
                name: '',
                type: '',
                venueId: venue.id,
            }
            setVenueTaxes([...venueTaxes, newTax]);
        }
    }
    const onUpdateVenueTaxes = () => {
        let isValidVenueTaxes = true;
        venueTaxes.forEach(tax => {
            if (!tax.name || !tax.tax || !tax.type || !tax.venueId) {
                setVenueTaxesError('All fields are required');
                isValidVenueTaxes = false;
            }
            if (isNaN(+tax.tax)) {
                setVenueTaxesError('Taxes can only be numbers');
                isValidVenueTaxes = false;
            }
            if (+tax.tax < 0) {
                setVenueTaxesError('Taxes cannot be negative');
                isValidVenueTaxes = false;
            }
            const sameNameTaxes = venueTaxes.filter(item => item.name === tax.name);
            if (sameNameTaxes.length > 1) {
                setVenueTaxesError('Taxes cannot have the same name');
                isValidVenueTaxes = false;
            }
        });
        if (!!venue && isValidVenueTaxes) {
            updateVenueTaxes(venue.id, venueTaxes);
        }
    }

    const renderRow = (props: any) => (
        <DetailsRow
            {...props}
            onClick={() => onItemInvoked(props?.item)}
        />
    );
    const onItemInvoked = (item: any): void => {
        editSchedule(item as ScheduleSettingDto);
    };
    const uploadVenueImageFile = (files: FileList | null) => {
        if (files && venue?.id) {
            uploadVenueImage(files[0], venue?.id);
        }
    };
    const uploadSecondaryVenueImageFiles = (files: FileList | null) => {
        if (files && venue?.id) {
            uploadVenueImages(files, venue?.id);
        }
    };
    const uploadVenueMapImageFile = (files: FileList | null) => {
        if (files && venue?.id) {
            uploadVenueMapImage(files[0], venue?.id);
        }
    };

    const onUpdateVenueCustomFieldsMapping = () => {
        if (newCustomFieldsMapping.length > 0) {
            updateVenue({ ...venue, tripleSeatCustomFieldsMapping: foldCustomFieldsMapping(newCustomFieldsMapping) });
        } else {
            updateVenue({ ...venue, tripleSeatCustomFieldsMapping: null });
        }
    };
    const weeksForBooking = getBookingDuration(venue?.daysForBooking);
    const dailyReportTime = venue?.dayReportTime ? dayjs().startOf('day').add(venue?.dayReportTime, 'hours').format(formatTime) : "";
    const durationTimes = durationSlotsToTime(venue?.durationInSlots, venue?.timeSlotDuration) || [];
    const durations = `${venue?.durationInSlots} (${durationTimes.join(',')})`;
    const bufferTime = `${durationSlotsToTime(venue?.bufferTimeForReservation.toString(), venue?.timeSlotDuration)[0].toLowerCase()}`
    const preBufferTime = `${durationSlotsToTime(venue?.preBufferTimeForReservation.toString(), venue?.timeSlotDuration)[0].toLowerCase()}`

    const renderVenueInfoListItem = (item: VenueItem, index: number) => (
        <div key={index} className="venue-item">
            <div className="venue-item-info">
                <div className="venue-item-name subtitle2">
                    {item.name}
                </div>
                <div className="venue-item-value body2">
                    {item.value}
                </div>
            </div>
            {index < venueInfo.length - 1 && (<div className="line" />)}
        </div>
    );


    const skipColumn = (column: IColumn) => {
        return column.key === "action";
    };

    const onColumnClick = (ev: any, column: IColumn | undefined) => {
        if (!column || skipColumn(column)) return;
        const newColumns: IColumn[] = [...columns];
        const currColumn: IColumn = newColumns.filter(
            (currCol) => column.key === currCol.key
        )[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                if (currColumn.isSortedDescending === undefined) {
                    currColumn.isSortedDescending = false;
                } else {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                }
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const sortType = column && column.isSortedDescending ? "desc" : "asc";
        const fieldName = column && column.fieldName ? column.fieldName : ''
        newColumns.length && setColumns(newColumns);
        setSchedules(orderBy(venue?.schedules, [fieldName], [sortType]) || []);
    };

    const customFieldsList = Object.keys(customFields).map((customField: string, index) => {
        let isSelected = activeCustomFieldsToUIModel(activeCustomFields).includes(customField);
        let isMandatory = activeCustomFieldsToUIModel(mandatoryCustomFields).includes(customField);
        const checkboxLabel = customFields[customField].fieldsName;
        return (
            <div key={customField}>
                {index === 0 && <div className="mandatory">Mandatory</div>}
                <div className="custom-field-container">
                    <Checkbox
                        className="custom-field"
                        label={checkboxLabel}
                        checked={isSelected}
                        onChange={(_: any, checked?: boolean) =>
                            setActiveCustomFields(changeActiveCustomFields(customField, activeCustomFields, checked))
                        }
                        disabled={readonly}
                    />
                    <Toggle
                        className="row toggle"
                        checked={isMandatory}
                        onChange={(_: any, checked?: boolean) =>
                            setMandatoryCustomFields(changeActiveCustomFields(customField, mandatoryCustomFields, checked))
                        }
                        disabled={readonly || !isSelected}
                    />
                </div>
            </div>
        );
    });
    const onSaveCustomFields = () => {
        if (venue) {
            updateVenue({ ...venue, activeCustomFields, mandatoryCustomFields })
        }
    }


    const onSelectReservationTax = (taxId: string, checked: boolean) => {
        if (venue) {
            let newReservationTaxes = reservationTaxes;
            if (taxId === ReservationTaxType.noTax) {
                if (checked) {
                    newReservationTaxes = taxId;
                } else {
                    newReservationTaxes = '';
                }
            } else {
                if (checked) {
                    newReservationTaxes = `${newReservationTaxes}${newReservationTaxes.length > 0 ? ';' : ''}${taxId}`;
                } else {
                    const selectedTaxes = newReservationTaxes.split(';');
                    const newTaxesArray = selectedTaxes.filter(tax => tax !== taxId);
                    newReservationTaxes = newTaxesArray.length > 0 ? newTaxesArray.join(';') : '';
                }
            }
            setReservationTaxes(newReservationTaxes);
        }
    }
    const onUpdateReservationTaxes = () => {
        if (venue && reservationTaxes) {
            updateVenue({ ...venue, reservationTaxes });
            setIsOpenReservationTaxes(false);
        }
    }

    let reservationTaxSelectors = !!venue?.taxes && venue?.taxes.length > 0 ? venue.taxes.map((tax) => {
        let isSelected = reservationTaxes.split(';').includes(tax.name);
        const taxAmount = tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
        const taxLabel = `${tax.name} ${taxAmount}`;
        return (
            <Checkbox
                key={tax.id}
                className="tax-selector"
                label={taxLabel}
                checked={isSelected}
                onChange={(_: any, checked?: boolean) =>
                    onSelectReservationTax(tax.name, !!checked)
                }
                disabled={readonly || reservationTaxes === ReservationTaxType.noTax}
            />
        );
    }) : null;

    const getReservationTaxesList = () => {
        if (venue?.reservationTaxes.split(';').includes(ReservationTaxType.noTax)) {
            return ReservationTaxType.noTax;
        }
        let reservationTaxList = '';
        if (venue?.reservationTaxes.split(';').includes(ReservationTaxType.venueTax)) {
            reservationTaxList = `${ReservationTaxType.venueTax} ${venue?.tax ? `(${venue.tax}%)` : '(0%)'}`
        }
        if (!!venue?.taxes && venue?.taxes.length > 0) {
            venue.taxes.forEach(tax => {
                if (venue?.reservationTaxes.split(';').includes(tax.name)) {
                    const taxAmount = tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`
                    reservationTaxList = `${reservationTaxList}${!!reservationTaxList ? '; ' : ''}${tax.name} ${taxAmount}`
                }
            })
        }
        return reservationTaxList;
    }
    const onUpdateCardColor = () => {
        if (venue) {
            updateVenue({ ...venue, cardColor });
        }
    }

    const onRenderOption = (option: IDropdownOption | undefined): JSX.Element => {
        if (!option) {
            return <div />;
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {option.data && option.data.background && (
                    <div style={{ width: '20px', height: '20px', background: option.data.background, marginRight: '20px' }} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    const onRenderTitle = (options: IDropdownOption[] | undefined): JSX.Element => {
        if (!options || options.length === 0) {
            return <div />;
        }
        const option = options[0];
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {option.data && option.data.background && (
                    <div style={{ width: '20px', height: '20px', background: option.data.background, marginRight: '20px' }} />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    const reservationSettingsTitle = (<><div className="h6">Reservation Settings</div><LinkButton onClick={() => setIsOpenEditReservationsSettings(true)}>Edit</LinkButton></>);
    const paymentSettingsTitle = (<><div className="h6">Payment Settings</div><LinkButton onClick={() => setIsOpenEditPaymentSettings(true)}>Edit</LinkButton></>);
    const cancelAndModifySettingsTitle = (<><div className="h6">Cancel and Modify Rules</div><LinkButton onClick={() => setIsOpenEditCancelAndModifySettings(true)}>Edit</LinkButton></>);
    const venueInfoTitle = (<><div className="h6">Venue Info</div><LinkButton onClick={editVenue}>Edit</LinkButton></>);
    const serviceFeesTitle = (<><div className="h6">Custom Fees</div></>);
    const venueTaxesTitle = (<><div className="h6">Additional Taxes</div></>);
    const customFieldsTitle = (<><div className="h6">Custom Checkout Fields</div>  </>);
    const customFieldsMappingTitle = (<><div className="h6">Custom Fields Mapping</div></>);
    const colorTitle = (<><div className="h6">Host App Colors</div></>);
    const socialTitle = (<><div className="h6">Social Media</div><LinkButton onClick={() => setIsOpenEditSocialMedia(true)}>Edit</LinkButton></>);
    const sidebarLinksTitle = (<><div className="h6">Sidebar Links</div><LinkButton onClick={() => setIsOpenEditSidebarLinks(true)}>Edit</LinkButton></>);

    const reservationSettings: VenueItem[] = [
        { name: 'Cut off time', value: venue?.allowedReservationInterval.toString() || '-' },
        { name: 'Duration Options', value: durations || '-' },
        { name: `Max guests on one ${actionText.toLowerCase()}`, value: venue?.guestSplit.toString() || '-' },
        { name: 'Max guests per reservation', value: venue?.maxGuests.toString() || '-' },
        { name: `Total # of ${actionText}s`, value: venue?.totalCountOfLanes.toString() || '-' },
        { name: 'How far in advance can users book?', value: weeksForBooking || '-' },
        { name: 'Buffer time before reservation', value: preBufferTime || '-' },
        { name: 'Buffer time after reservation', value: bufferTime || '-' },
        { name: 'Daily Report', value: venue?.enableDayReport ? "Yes" : "No" },
        { name: 'Daily Report Emails', value: venue?.dayReportEmails || '-' },
        { name: 'Daily Report Time', value: dailyReportTime || '-' },
        { name: 'Error notification emails', value: venue?.errorNotificationEmails || '-' },
        { name: 'Link skip ahead (in days)', value: venue?.shiftingForReservationStart.toString() || '0' },
        { name: 'Sales Tax', value: `${venue?.tax}%` || '-' },
    ];
    const paymentSettings: VenueItem[] = [
        { name: 'Deposit', value: venue?.deposit.toString() || '-' },
        { name: 'Deposit Type', value: venue?.depositType || '-' },
    ];
    const cancelAndModifySettings: VenueItem[] = [
        { name: 'Allow Cancellations', value: venue?.allowCancellation ? "Yes" : "No" },
        { name: 'Cancellation fee type', value: venue?.cancellationFeeType || '-' },
        { name: 'Cancellation fee', value: venue?.cancellationFee.toFixed(2) || '-' },
        { name: 'Cancellation Cut Off (hours)', value: `${venue?.cancellationCutOff} hours` || '-' },
        { name: 'Allow Modifications', value: venue?.allowModification ? "Yes" : "No" },
        { name: 'Modification fee type', value: venue?.modificationFeeType || '-' },
        { name: 'Modification fee', value: venue?.modificationFee.toFixed(2) || '-' },
        { name: 'Modification Cut Off (hours)', value: `${venue?.modificationCutOff} hours` || '-' },
    ];
    const venueInfo: VenueItem[] = [
        { name: 'Venue Name', value: venue?.name || '-' },
        { name: 'Venue Prefix', value: venue?.prefix || '-' },
        { name: 'Address', value: `${venue?.address} ${venue?.address2}` || '-' },
        { name: 'City', value: venue?.city || '-' },
        { name: 'State', value: venue?.state || '-' },
        { name: 'Zip Code', value: venue?.zipCode || '-' },
        { name: 'Phone Number', value: venue?.phone || '-' },
        { name: 'Time Zone', value: venue?.timeZone || '-' },
        { name: 'Events Email', value: venue?.eventsEmail || '-' },
        { name: 'Reservations Email', value: venue?.email || '-' },
        { name: 'Location Page URL', value: venue?.locationUrl || '-' },
        { name: 'Google Map URL', value: venue?.mapUrl || '-' },
        { name: 'Age for Policy', value: venue?.ageForPolicy || '-' },
    ];
    return (
        <div className={`venue-info ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="left-panel">
                {isMiddleScreen && (
                    <VenueMainActions />
                )}
                <Panel className="panel-shift panel-schedule" title={<div className="h6">Schedule</div>}>
                    {schedules && schedules.length > 0 ? (
                        <>
                            <div className="schedule-list">
                                <DetailsList
                                    items={schedules}
                                    styles={scheduleListStyles}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item) => item.id}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    className="list"
                                    onRenderRow={renderRow}
                                    onColumnHeaderClick={onColumnClick}
                                />
                            </div>
                            <div className="add-schedule-button-container">
                                <ColorButton
                                    className="empty-schedule-list-button"
                                    onClick={addSchedule}
                                    disabled={readonly}
                                >
                                    <Icon iconName="Add" className="add-icon" />
                                    <div className="add-button-text">
                                        Add Schedule
                                    </div>
                                </ColorButton>
                            </div>
                        </>
                    ) : (
                        <div className="empty-schedule-list-container">
                            <div className="empty-schedule-list">
                                <CalendarIcon className="calendar-icon" />
                                <div className="empty-schedule-list-message">
                                    There are no schedules here yet.
                                </div>
                                <ColorButton
                                    className="empty-schedule-list-button"
                                    onClick={addSchedule}
                                    disabled={readonly}
                                >
                                    <Icon iconName="Add" className="add-icon" />
                                    <div className="add-button-text">
                                        Add Schedule
                                    </div>
                                </ColorButton>
                            </div>
                        </div>
                    )}
                </Panel>
                {isMiddleScreen && (
                    <Panel className="panel-shift panel-with-lines" title={venueInfoTitle}>
                        {venueInfo.map((item, index) => renderVenueInfoListItem(item, index))}
                    </Panel>
                )}
                <Panel className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"}`} title={reservationSettingsTitle}>
                    {reservationSettings.map((item, index) => renderListItem(item, index))}
                </Panel>
                <Panel className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"}`} title={paymentSettingsTitle}>
                    {paymentSettings.map((item, index) => renderListItem(item, index))}
                </Panel>
                <AssignResource />
                <Panel className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"}`} title={cancelAndModifySettingsTitle}>
                    {cancelAndModifySettings.map((item, index) => renderListItem(item, index))}
                </Panel>
                <Panel className="panel-shift panel-info" title={serviceFeesTitle}>
                    {serviceFees.length > 0 && serviceFees.map((_, index) => (
                        <div className={`fields-mapping ${isMiddleScreen ? "mobile" : ""}`} key={index}>
                            <FormTextField
                                label="Fee Name"
                                className="field fee-field"
                                value={serviceFeeNames[index] || ""}
                                onChange={(_: any, value?: string) =>
                                    onUpdateServiceFeeNames(value || "", index)
                                }
                                autoComplete="off"
                                disabled={readonly}
                            />
                            <FormSelectField
                                label="Fee Type"
                                className="field fee-field small"
                                options={feeTypeOptions}
                                selectedKey={serviceFeeTypes[index] || ''}
                                onChange={(_: any, option) => {
                                    onUpdateServiceFeeTypes(option?.key ? option?.key.toString() : "", index)
                                }}
                                disabled={readonly}
                            />
                            <FormTextField
                                label="Fee"
                                className="field fee-field small"
                                type="number"
                                value={serviceFees[index] || ""}
                                onChange={(_: any, value?: string) =>
                                    onUpdateServiceFees(value || "", index)
                                }
                                autoComplete="off"
                                disabled={readonly}
                            />
                            <FormSelectField
                                label="Tax"
                                className="field fee-field small"
                                options={customFeeTaxOptions}
                                selectedKey={taxForServiceFees[index] || ReservationTaxType.noTax}
                                onChange={(_: any, option) => {
                                    onUpdateTaxForServiceFees(option?.key ? option?.key.toString() : ReservationTaxType.noTax, index)
                                }}
                                disabled={readonly}
                            />
                            <IconButton
                                className="delete-button"
                                ariaLabel="Remove Service Fee"
                                onClick={() => onRemoveServiceFee(index)}
                            >
                                <TrashIcon />
                            </IconButton>
                        </div>
                    ))}
                    <div className={isMiddleScreen ? "mobile" : ""}>
                        <LinkButton className="add-button" onClick={() => onAddServiceFee()}>
                            <Icon iconName="Add" className="add" />
                            Add item
                        </LinkButton>
                    </div>
                    <div className={isMiddleScreen ? "mobile" : ""}>
                        <ColorButton
                            className="save-button"
                            onClick={onUpdateServiceFee}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                </Panel>
                <Panel className="panel-shift panel-info" title={venueTaxesTitle}>
                    {venueTaxes.length > 0 && venueTaxes.map((_, index) => (
                        <div className={`fields-mapping ${isMiddleScreen ? "mobile" : ""}`} key={index}>
                            <FormTextField
                                label="Tax Name"
                                className="field"
                                value={venueTaxes[index].name || ""}
                                onChange={(_: any, value?: string) =>
                                    onUpdateVenueTaxName(value || "", index)
                                }
                                autoComplete="off"
                                disabled={readonly}
                            />
                            <FormSelectField
                                label="Tax Type"
                                className="field"
                                options={feeTypeOptions}
                                selectedKey={venueTaxes[index].type || ''}
                                onChange={(_: any, option) => {
                                    onUpdateVenueTaxType(option?.key ? option?.key.toString() : "", index)
                                }}
                                disabled={readonly}
                            />
                            <FormTextField
                                label="Tax Amount"
                                className="field"
                                type="number"
                                step='0.01'
                                value={venueTaxes[index].tax.toString() || "0"}
                                onChange={(_: any, value?: string) =>
                                    onUpdateVenueTaxValue(value || "0", index)
                                }
                                autoComplete="off"
                                disabled={readonly}
                            />
                            <IconButton
                                className="delete-button"
                                ariaLabel="Remove Tax"
                                onClick={() => onRemoveVenueTax(index)}
                            >
                                <TrashIcon />
                            </IconButton>
                        </div>
                    ))}
                    <div className={isMiddleScreen ? "mobile" : ""}>
                        <LinkButton className="add-button" onClick={() => onAddVenueTax()}>
                            <Icon iconName="Add" className="add" />
                            Add item
                        </LinkButton>
                    </div>
                    {venueTaxesError && <div className="error">{venueTaxesError}</div>}
                    <div className={isMiddleScreen ? "mobile" : ""}>
                        <ColorButton
                            className="save-button"
                            onClick={onUpdateVenueTaxes}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                </Panel>
                <Panel className="panel-shift panel-info" title={customFieldsTitle}>
                    <div className="row">{customFieldsList}</div>
                    <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                        <ColorButton
                            className="save-button"
                            onClick={onSaveCustomFields}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                </Panel>
                <Panel className="panel-shift panel-info" title={customFieldsMappingTitle}>
                    {newCustomFieldsMapping.map(item => (
                        <div className={`fields-mapping ${isMiddleScreen ? "mobile" : ""}`} key={item.id}>
                            <FormTextField
                                label="TS Custom Fields ID"
                                className="field"
                                value={item.tsId}
                                onChange={(_: any, value?: string) =>
                                    onUpdateCustomFieldsMapping({ ...item, tsId: value || "" })
                                }
                                autoComplete="off"
                                disabled={readonly}
                            />
                            <FormSelectField
                                label="Reservation Field"
                                className="field"
                                options={resFieldOptions}
                                selectedKey={item.name}
                                onChange={(_: any, option) => {
                                    onUpdateCustomFieldsMapping({
                                        ...item,
                                        name: option?.key ? option?.key.toString() : "",
                                        param: "",
                                    })
                                }}
                                disabled={readonly}
                            />
                            {item.name === 'Custom' ? (
                                <FormTextField
                                    label="Custom Text"
                                    className="field"
                                    value={item.param || ''}
                                    onChange={(_: any, value?: string) =>
                                        onUpdateCustomFieldsMapping({
                                            ...item,
                                            param: value || "",
                                        })
                                    }
                                    autoComplete="off"
                                    disabled={readonly}
                                />
                            ) : null}
                            <IconButton
                                className="delete-button"
                                ariaLabel="Remove Custom Field"
                                onClick={() => onRemoveCustomFieldsMapping(item)}
                            >
                                <TrashIcon />
                            </IconButton>
                        </div>
                    ))}
                    <div className={isMiddleScreen ? "mobile" : ""}>
                        <LinkButton className="add-button" onClick={onAddCustomFieldsMapping}>
                            <Icon iconName="Add" className="add" />
                            Add item
                        </LinkButton>
                    </div>
                    <div className={isMiddleScreen ? "mobile" : ""}>
                        <ColorButton
                            className="save-button"
                            onClick={onUpdateVenueCustomFieldsMapping}
                            disabled={readonly}
                        >
                            Save Changes
                        </ColorButton>
                    </div>
                </Panel>
            </div>
            <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
                {!isMiddleScreen && (
                    <>
                        <VenueMainActions />
                        <Panel className="panel-shift panel-with-lines" title={venueInfoTitle}>
                            {venueInfo.map((item, index) => renderVenueInfoListItem(item, index))}
                        </Panel>
                    </>
                )}
                <Panel className="panel-shift" title={<><div className="h6">Reservation Taxes</div><LinkButton onClick={() => setIsOpenReservationTaxes(!isOpenReservationTaxes)}>Edit</LinkButton></>}>
                    {isOpenReservationTaxes ? (
                        <div className={`tax-selector-container ${isMiddleScreen ? "mobile" : ""}`}>
                            <Checkbox
                                key='noTax'
                                className="tax-selector"
                                label='No Tax'
                                checked={reservationTaxes === ReservationTaxType.noTax}
                                onChange={(_: any, checked?: boolean) =>
                                    onSelectReservationTax(ReservationTaxType.noTax, !!checked)
                                }
                                disabled={readonly || reservationTaxes.split(';').includes(ReservationTaxType.venueTax) || reservationTaxes.split(';').some(item => venue?.taxes?.map(t => t.name).includes(item))}
                            />
                            <Checkbox
                                key='venueTax'
                                className="tax-selector"
                                label={`${ReservationTaxType.venueTax} ${venue?.tax ? `(${venue.tax}%)` : '(0%)'}`}
                                checked={reservationTaxes.split(';').includes(ReservationTaxType.venueTax)}
                                onChange={(_: any, checked?: boolean) =>
                                    onSelectReservationTax(ReservationTaxType.venueTax, !!checked)
                                }
                                disabled={readonly || reservationTaxes === ReservationTaxType.noTax}
                            />
                            {reservationTaxSelectors}
                            <div className={isMiddleScreen ? "mobile" : ""}>
                                <ColorButton
                                    className="save-button"
                                    onClick={onUpdateReservationTaxes}
                                    disabled={readonly}
                                >
                                    Save Changes
                                </ColorButton>
                            </div>
                        </div>
                    ) : (
                        <div className='tax-selector-container detail-title' >
                            {getReservationTaxesList()}
                        </div>
                    )}
                </Panel>
                <Panel className="panel-shift" title={<><div className="h6">Images</div><LinkButton onClick={() => setIsOpenEditImages(true)}>Edit</LinkButton></>}>
                    <div className={`image-fields-container ${isMiddleScreen ? "mobile" : ""}`}>
                        <div className="image-field">
                            <div className="detail-title">
                                Venue Image {venue?.secondaryVenueImages && venue?.secondaryVenueImages.length > 1 ? `+${venue?.secondaryVenueImages.length - 1}` : ''}
                            </div>
                            <VenueImage image={venue?.venueImage} loading={isImageLoadInProgress} uploadImage={uploadVenueImageFile} subTitle="1000x600 pixels" />
                        </div>
                        <div className="image-delimiter" />
                        <div className="image-field">
                            <div className="detail-title">
                                Map Image
                            </div>
                            <VenueImage image={venue?.mapImage} loading={isMapImageLoadInProgress} uploadImage={uploadVenueMapImageFile} subTitle="300x300 pixels" />
                        </div>
                    </div>
                </Panel>
                <Panel className="panel-shift panel-with-lines" title={socialTitle}>
                    {renderListSocialMediaItem(socialMedia, isMiddleScreen)}
                </Panel>

                <Panel className="panel-shift panel-with-lines" title={sidebarLinksTitle}>
                    {renderSideBarLinks({
                        sidebarTitle: venue?.sidebarTitle || '',
                        sidebarDescription: venue?.sidebarDescription || '',
                        sidebarIcons: venue?.sidebarIcons || [],
                        sidebarLinks: venue?.sidebarLinks || [],
                        sidebarUrls: venue?.sidebarUrls || [],
                    }, isMiddleScreen)}
                </Panel>

                <Panel className="panel-shift" title={colorTitle}>
                    <div className={`color-fields-container ${isMiddleScreen ? "mobile" : ""}`}>
                        <FormSelectField
                            label="Card Color"
                            className="field color"
                            options={cardColorTypeOptions}
                            selectedKey={cardColor}
                            onChange={(_: any, option) => {
                                setCardColor(option?.key ? option?.key.toString() : DEFAULT_CARD_COLOR)
                            }}
                            onRenderOption={onRenderOption}
                            onRenderTitle={onRenderTitle}
                            disabled={readonly}
                        />
                        <div className={isMiddleScreen ? "mobile" : ""}>
                            <ColorButton
                                className="save-color-button"
                                onClick={onUpdateCardColor}
                                disabled={readonly}
                            >
                                Save Changes
                            </ColorButton>
                        </div>
                    </div>
                </Panel>
            </div >
            {isOpenEditImages &&
                <EditVenueImages
                    venue={venue}
                    isImageLoadInProgress={isImageLoadInProgress}
                    isMapImageLoadInProgress={isMapImageLoadInProgress}
                    uploadVenueImageFile={uploadVenueImageFile}
                    uploadSecondaryVenueImageFiles={uploadSecondaryVenueImageFiles}
                    uploadVenueMapImageFile={uploadVenueMapImageFile}
                    onClose={() => setIsOpenEditImages(false)}
                    deleteVenueImage={deleteVenueImage}
                />
            }
            {isOpenEditPaymentSettings && <EditPaymentSettings onClose={() => setIsOpenEditPaymentSettings(false)} />}
            {isOpenEditCancelAndModifySettings && <EditCancelAndModifySettings onClose={() => setIsOpenEditCancelAndModifySettings(false)} />}
            {isOpenEditReservationsSettings && <EditReservationsSettings onClose={() => setIsOpenEditReservationsSettings(false)} />}
            {isOpenEditSocialMedia && <EditVenueSocialMedia onClose={() => setIsOpenEditSocialMedia(false)} />}
            {isOpenEditSidebarLinks && <EditSidebarLinks onClose={() => setIsOpenEditSidebarLinks(false)} />}
        </div >
    );
};

export default connector(VenueInfo);

function renderListSocialMediaItem(socialMedia: VenueItem[], isMiddleScreen: boolean) {
    return socialMedia.map((item, index) => (
        <div key={index} className="venue-item">
            <div className="venue-item-info">
                <div className="venue-item-name subtitle2">{item.name}</div>
                <div className={`venue-item-value body2 ${isMiddleScreen ? "mobile" : ""}`}>{item.value}</div>
            </div>
            {index < socialMedia.length - 1 && (<div className="line" />)}
        </div>
    ))
}

type Sidebar = {
    sidebarTitle: VenueDto['sidebarTitle'];
    sidebarDescription: VenueDto['sidebarDescription'];
    sidebarLinks: VenueDto['sidebarLinks'];
    sidebarIcons: VenueDto['sidebarIcons'];
    sidebarUrls: VenueDto['sidebarUrls'];
}

function renderSideBarLinks(data: Sidebar, isMiddleScreen: boolean) {
    return (
        <>
            <div className="venue-item">
                <div className="venue-item-info">
                    <div className="venue-item-name subtitle2">{data.sidebarTitle}</div>
                    <div className={`venue-item-value body2 ${isMiddleScreen ? "mobile" : ""}`}>Title</div>
                </div>
                <div className="line" />
            </div>

            <div className="venue-item">
                <div className="venue-item-info">
                    <div className="venue-item-name subtitle2">{data.sidebarDescription}</div>
                    <div className={`venue-item-value body2 ${isMiddleScreen ? "mobile" : ""}`}>Description</div>
                </div>
                {data.sidebarLinks.length !== 0 && (<div className="line" />)}
            </div>

            {data.sidebarLinks.map((link, index, arr) => (
                <React.Fragment key={`sidebar - ${index}`}>
                    <div className="venue-item">
                        <div className="venue-item-info">
                            <div className="venue-item-name subtitle2">{`Link ${index + 1}`}</div>
                            <div className={`venue-item-value flex body2 ${isMiddleScreen ? "mobile" : ""}`}>
                                <GoogleMaterialSymbol name={data.sidebarIcons[index] || 'link'} />
                                {link}
                            </div>
                        </div>
                        <div className="line" />
                    </div>

                    <div className="venue-item">
                        <div className="venue-item-info">
                            <div className="venue-item-name subtitle2">{`Link URL ${index + 1}`}</div>
                            <div className={`venue-item-value body2 ${isMiddleScreen ? "mobile" : ""}`}>{data.sidebarUrls[index]}</div>
                        </div>
                        {index < arr.length - 1 && (<div className="line" />)}
                    </div>
                </React.Fragment>
            ))}
        </>
    )
}