import { PromotionType } from '../entities/enums'; 

export class PromotionDto {
    id: string;
    type: PromotionType;
    enablePromotion: boolean;
    name: string;
    displayName: string;
    discount?: string;
    location?: string;
}

export class CreatePromotionDto {
    type: PromotionType;
    name: string;
    tenantId?:string;
    displayName: string;
}