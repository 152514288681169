import React from "react";
import {ActionButton, Checkbox, IDropdownOption,} from "office-ui-fabric-react";
import FormTextField from "../../../../../../../../common/components/FormTextField";
import FormSelectField from "../../../../../common/FormSelectField";
import {TimeSlotAddonSettingDto} from "../../../../../../store/types";
import {useViewport} from "../../../../../../hooks/responsive";
import TrashIcon from "../../../../../../assets/trash-icon.svgr";
import "./timeSlotAddonRow.scss";

const toggleStyle = {
  root: {
    margin: 0,
  },
};

interface OwnProps {
  slot: TimeSlotAddonSettingDto;
  times: IDropdownOption[];
  onChange: (slot: TimeSlotAddonSettingDto, field: string, value: string | number | boolean) => void;
  removeSlot: (slotId: string) => void;
  timeSlotDuration: number;
}

export const TimeSlotAddonRow = (
  {
    slot,
    times,
    onChange,
    removeSlot,
    timeSlotDuration,
  }: OwnProps
) => {
  const {isMobile} = useViewport();
  let slotsInHour = 60 / timeSlotDuration;
  let startTimeKey = slot.startTime ? Math.floor(slot.startTime / timeSlotDuration) : slot.start * slotsInHour / 2;
  let endTimeKey = slot.endTime ? Math.floor(slot.endTime / timeSlotDuration) : slot.end * slotsInHour / 2;
  return (
    <div className={`timeSlotAddonRow ${isMobile ? "mobile" : ""}`}>
      <FormSelectField
        label="Start time"
        options={times}
        className={`dropdown ${isMobile ? "mobile" : ""}`}
        selectedKey={startTimeKey}
        onChange={(_: any, option) => {
          onChange(slot, "start", option?.key || 0);
        }}
      />
      <div className="divider"/>
      <FormSelectField
        label="End time"
        options={times}
        className={`dropdown ${isMobile ? "mobile" : ""}`}
        selectedKey={endTimeKey}
        onChange={(_: any, option) =>
          onChange(slot, "end", option?.key || 0)
        }
      />
      {!isMobile && <div className="divider"/>}
      <FormTextField
        label="Rate"
        className={isMobile ? "mobile" : ""}
        value={String(slot.rate)}
        onChange={(_: any, text?: string) =>
          onChange(slot, "rate", text || "")
        }
        autoComplete="off"
      />
      <div className="divider"/>
      <FormTextField
        label="Available"
        className={isMobile ? "mobile" : ""}
        value={''}
        onChange={(_: any, text?: string) =>
          onChange(slot, "lanesCount", text || "")
        }
        autoComplete="off"
      />
      <div className="divider"/>
      <div className="check-21">
        <div className="check-label body2">21+</div>
        <Checkbox
          checked={slot.is21plus}
          onChange={(_, isChecked) =>
            onChange(slot, "is21plus", !!isChecked)
          }
          styles={toggleStyle}
        />

      </div>
      <ActionButton onClick={() => removeSlot(slot.id)} styles={{root: {height: 20, marginBottom: "10px"}}}><TrashIcon/></ActionButton>
    </div>
  );
};
