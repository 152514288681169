import { createSelector } from "reselect";
import { ActionTypeEnum } from "../actions";
import {
  GoTabProduct,
  GoTabSpot,
  ListsInfo,
  MergeField,
  SquareCatalogDto,
  SquareLocationDto,
  State,
  VenueSettingDto,
  VenuesState,
} from "../store/types";
import { createReducer } from "./reducer-utils";
import { MailchimpTagDto } from "../../../server/src/dto/mailchimpTag.dto";

const initialState: VenuesState = Object.freeze({
  venues: [],
  filteredVenues: [],
  currentSchedule: [],
  bookedSlots: [],
  freeSlots: [],
  isLoadingVenues: false,
  isLoadingVenue: false,
  isRequestInProgress: false,
  isImageLoadInProgress: false,
  isMapImageLoadInProgress: false,
  goTabProducts: [],
  goTabSpots: [],
  mailchimp: {
    listsInfo: [],
    mergeFields: [],
    tags: [],
  },
  square: {
    locations: [],
    catalogs: [],
  },
  social: {
    instagramUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    tikTokUrl: '',
    viewMenuUrl: '',
  },
  stripeConnectionStatus: 'disconnected',
});

export default createReducer<VenuesState>(initialState, {
  [ActionTypeEnum.Logout]: () => (state) => ({
    ...initialState,
  }),
  [ActionTypeEnum.GetVenues]: () => (state) => ({
    ...state,
    isLoadingVenues: true,
    error: '',
  }),
  [ActionTypeEnum.GetVenuesSuccess]: (venues) => (state) => {
    return {
      ...state,
      isLoadingVenues: false,
      venues,
      error: '',
    };
  },
  [ActionTypeEnum.GetVenuesFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenues: false,
    error,
  }),
  [ActionTypeEnum.CreateVenue]: () => (state) => ({
    ...state,
    isLoadingVenues: true,
    error: '',
  }),
  [ActionTypeEnum.CreateVenueSuccess]: (venue) => (state) => {
    return {
      ...state,
      isLoadingVenues: false,
      venue,
      error: '',
    };
  },
  [ActionTypeEnum.CreateVenueFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenues: false,
    error,
  }),
  [ActionTypeEnum.RemoveVenue]: () => (state) => ({
    ...state,
    isLoadingVenues: true,
    error: '',
  }),
  [ActionTypeEnum.RemoveVenueSuccess]: (venues) => (state) => {
    return {
      ...state,
      isLoadingVenues: false,
      venues,
      error: '',
    };
  },
  [ActionTypeEnum.RemoveVenueFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenues: false,
    error,
  }),
  [ActionTypeEnum.GetVenue]: () => (state) => ({
    ...state,
    isLoadingVenue: true,
    error: '',
  }),
  [ActionTypeEnum.GetVenueSuccess]: (venue: VenueSettingDto) => (state: VenuesState): VenuesState => {
    return {
      ...state,
      isLoadingVenue: false,
      venue,
      social: {
        ...state.social,
        facebookUrl: venue.facebookUrl,
        instagramUrl: venue.instagramUrl,
        twitterUrl: venue.twitterUrl,
        tikTokUrl: venue.tikTokUrl,
        viewMenuUrl: venue.viewMenuUrl,
      },
      goTabProducts: [],
      goTabSpots: [],
      error: '',
    };
  },
  [ActionTypeEnum.GetVenueFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenue: false,
    error,
  }),
  [ActionTypeEnum.UpdateVenue]: () => (state) => ({
    ...state,
    isLoadingVenue: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateVenueSuccess]: (venue: VenueSettingDto) => (state: VenuesState): VenuesState => {
    return {
      ...state,
      isLoadingVenue: false,
      venue,
      venues: state.venues.map((v) =>
        v.id === venue.id ? venue : v
      ),
      social: {
        ...state.social,
        facebookUrl: venue.facebookUrl,
        instagramUrl: venue.instagramUrl,
        twitterUrl: venue.twitterUrl,
        tikTokUrl: venue.tikTokUrl,
        viewMenuUrl: venue.viewMenuUrl,
      },
      error: '',
    };
  },
  [ActionTypeEnum.UpdateVenueFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenue: false,
    error,
  }),
  [ActionTypeEnum.CloneVenue]: () => (state) => ({
    ...state,
    isLoadingVenues: true,
    error: '',
  }),
  [ActionTypeEnum.CloneVenueSuccess]: (venue) => (state) => {
    return {
      ...state,
      isLoadingVenues: false,
      venue,
      goTabProducts: [],
      goTabSpots: [],
      error: '',
    };
  },
  [ActionTypeEnum.CloneVenueFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenues: false,
    error,
  }),
  [ActionTypeEnum.SetFilteredVenues]: (filteredVenues) => (state) => ({
    ...state,
    filteredVenues,
  }),
  [ActionTypeEnum.UpdateSchedule]: () => (state) => ({
    ...state,
    isLoadingVenue: true,
  }),
  [ActionTypeEnum.UpdateScheduleSuccess]: (venue) => (state) => {
    return {
      ...state,
      isLoadingVenue: false,
      venue,
    };
  },
  [ActionTypeEnum.UpdateScheduleFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenue: false,
    error,
  }),
  [ActionTypeEnum.RemoveSchedule]: () => (state) => ({
    ...state,
    isLoadingVenue: true,
  }),
  [ActionTypeEnum.RemoveScheduleSuccess]: (venue) => (state) => {
    return {
      ...state,
      isLoadingVenue: false,
      venue,
    };
  },
  [ActionTypeEnum.RemoveScheduleFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenue: false,
    error,
  }),
  [ActionTypeEnum.GetCurrentSchedule]: () => (state) => ({
    ...state,
    isLoadingVenue: true,
  }),
  [ActionTypeEnum.GetCurrentScheduleSuccess]: ({ currentSchedule, bookedSlots, freeSlots }: { currentSchedule: [][], bookedSlots: number[], freeSlots: number[] }) => (state) => {
    return {
      ...state,
      isLoadingVenue: false,
      currentSchedule,
      bookedSlots,
      freeSlots,
    };
  },
  [ActionTypeEnum.GetCurrentScheduleFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenue: false,
    currentSchedule: [],
    bookedSlots: [],
    freeSlots: [],
    error,
  }),
  [ActionTypeEnum.GetVenueImageUrl]: () => (state: VenuesState): VenuesState => ({
    ...state,
    isImageLoadInProgress: true,
    error: '',
  }),
  [ActionTypeEnum.GetVenueImageSuccess]: (venue: VenueSettingDto) => (state: VenuesState): VenuesState => {
    return {
      ...state,
      isImageLoadInProgress: false,
      venue,
      error: '',
    };
  },
  [ActionTypeEnum.GetVenueImageFailure]: (error = '') => (state: VenuesState): VenuesState => ({
    ...state,
    isImageLoadInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetVenueMapImageUrl]: () => (state) => ({
    ...state,
    isMapImageLoadInProgress: true,
  }),
  [ActionTypeEnum.GetVenueMapImageSuccess]: (venue) => (state) => {
    return {
      ...state,
      isMapImageLoadInProgress: false,
      venue,
    };
  },
  [ActionTypeEnum.GetVenueMapImageFailure]: (error) => (state) => ({
    ...state,
    isMapImageLoadInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetVenueError]: (error) => (state) => ({
    ...state,
    error,
  }),
  [ActionTypeEnum.UpdateVenueTaxes]: () => (state) => ({
    ...state,
    isLoadingVenue: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateVenueTaxesSuccess]: (venue) => (state) => {
    return {
      ...state,
      isLoadingVenue: false,
      venue,
      venues: state.venues.map((v) =>
        v.id === venue.id ? venue : v
      ),
      error: '',
    };
  },
  [ActionTypeEnum.UpdateVenueTaxesFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenue: false,
    error,
  }),
  [ActionTypeEnum.GetGoTabProducts]: () => (state) => ({
    ...state,
    isLoadingVenue: true,
    error: '',
  }),
  [ActionTypeEnum.GetGoTabProductsSuccess]: ({ goTabProducts, goTabSpots }: { goTabProducts: GoTabProduct[], goTabSpots: GoTabSpot[] }) => (state) => {
    return {
      ...state,
      isLoadingVenue: false,
      goTabProducts,
      goTabSpots,
      error: '',
    };
  },
  [ActionTypeEnum.GetGoTabProductsFailure]: (error) => (state) => ({
    ...state,
    isLoadingVenue: false,
    error,
  }),
  [ActionTypeEnum.GetListsInfo]: () => (state: VenuesState) => ({
    ...state,
    isRequestInProgress: true,
    error: '',
  }),
  [ActionTypeEnum.GetListsInfoSuccess]: (listsInfo: ListsInfo[]) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    mailchimp: {
      ...state.mailchimp,
      listsInfo,
    },
    error: '',
  }),
  [ActionTypeEnum.GetMailchimpTags]: () => (state: VenuesState) => ({
    ...state,
    isRequestInProgress: true,
    error: '',
  }),
  [ActionTypeEnum.GetMailchimpTagsSuccess]: (mailchimpTags: MailchimpTagDto[]) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    mailchimp: {
      ...state.mailchimp,
      tags: mailchimpTags,
    },
    error: '',
  }),
  [ActionTypeEnum.GetListsInfoFailure]: (error = '') => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetMergeFields]: () => (state: VenuesState) => ({
    ...state,
    isRequestInProgress: true,
    error: '',
  }),
  [ActionTypeEnum.GetMergeFieldsSuccess]: (mergeFields: MergeField[]) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    mailchimp: {
      ...state.mailchimp,
      mergeFields,
    },
    error: '',
  }),
  [ActionTypeEnum.GetMergeFieldsFailure]: (error = '') => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.CreateMailchimpMapping]: () => (state: VenuesState): VenuesState => ({
    ...state,
    isLoadingVenue: true,
    error: '',
  }),
  [ActionTypeEnum.CreateMailchimpMappingSuccess]: (venue: VenueSettingDto) => (state: VenuesState): VenuesState => ({
    ...state,
    isLoadingVenue: false,
    venue,
    error: '',
  }),
  [ActionTypeEnum.CreateMailchimpMappingFailure]: (error = '') => (state: VenuesState): VenuesState => ({
    ...state,
    isLoadingVenue: false,
    error,
  }),
  [ActionTypeEnum.UpdateMailchimpMapping]: () => (state: VenuesState): VenuesState => ({
    ...state,
    isLoadingVenue: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateMailchimpMappingSuccess]: (venue: VenueSettingDto) => (state: VenuesState): VenuesState => ({
    ...state,
    isLoadingVenue: false,
    venue,
    error: '',
  }),
  [ActionTypeEnum.UpdateMailchimpMappingFailure]: (error = '') => (state: VenuesState): VenuesState => ({
    ...state,
    isLoadingVenue: false,
  }),
  [ActionTypeEnum.CreateMailchimpTag]: () => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.CreateMailchimpTagSuccess]: (tags: MailchimpTagDto[]) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    mailchimp: {
      ...state.mailchimp,
      tags: [...state.mailchimp.tags, ...tags],
    },
  }),
  [ActionTypeEnum.CreateMailchimpTagFailure]: (error?: string) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UpdateMailchimpTag]: () => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.UpdateMailchimpTagSuccess]: (tags: MailchimpTagDto[]) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    mailchimp: {
      ...state.mailchimp,
      tags
    },
  }),
  [ActionTypeEnum.UpdateMailchimpTagFailure]: (error?: string) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetSquareLocations]: () => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.GetSquareLocationsSuccess]: (locations: SquareLocationDto[]) => (state: VenuesState): VenuesState => ({
    ...state,
    square: {
      ...state.square,
      locations,
    },
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.GetSquareLocationsFailure]: (error?: string) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetSquareCatalogs]: () => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.GetSquareCatalogsSuccess]: (catalogs: SquareCatalogDto[]) => (state: VenuesState): VenuesState => ({
    ...state,
    square: {
      ...state.square,
      catalogs,
    },
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.GetSquareCatalogsFailure]: (error?: string) => (state: VenuesState): VenuesState => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.ClearSquareCatalogs]: () => (state: VenuesState): VenuesState => ({
    ...state,
    square: {
      ...state.square,
      catalogs: [],
    },
  }),
  [ActionTypeEnum.VenueConnectStripe]:
    () =>
      (state: VenuesState): VenuesState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.VenueConnectStripeSuccess]:
    () =>
      (state: VenuesState): VenuesState => ({
        ...state,
        isRequestInProgress: false,
        stripeConnectionStatus: "pending",
      }),
  [ActionTypeEnum.VenueConnectStripeFailure]:
    (error?: string) =>
      (state: VenuesState): VenuesState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.VenueDisconnectStripe]:
    () =>
      (state: VenuesState): VenuesState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.VenueDisconnectStripeSuccess]:
    () =>
      (state: VenuesState): VenuesState => ({
        ...state,
        venue: !state.venue ? undefined : {
          ...state.venue,
          stripeAccountId: "",
        },
        stripeConnectionStatus: "",
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.VenueDisconnectStripeFailure]:
    (error?: string) =>
      (state: VenuesState): VenuesState => ({
        ...state,
        isRequestInProgress: false,
        error,
      }),
  [ActionTypeEnum.VenueCheckStripe]:
    () =>
      (state: VenuesState): VenuesState => ({
        ...state,
        isRequestInProgress: true,
      }),
  [ActionTypeEnum.VenueCheckStripeWithoutRequestInProgress]:
    () =>
      (state: VenuesState): VenuesState => ({
        ...state,
      }),
  [ActionTypeEnum.VenueCheckStripeSuccess]:
    ({ stripeConnectionStatus }: { stripeConnectionStatus: string }) =>
      (state: VenuesState): VenuesState => ({
        ...state,
        stripeConnectionStatus,
        isRequestInProgress: false,
      }),
  [ActionTypeEnum.VenueCheckStripeFailure]:
    (error?: string) =>
      (state: VenuesState): VenuesState => ({
        ...state,
        isRequestInProgress: false,
        stripeConnectionStatus: 'disconnected',
        error,
      }),
});

export const selectVenuesState = (state: State) => state.venues;
export const selectVenues = createSelector(
  selectVenuesState,
  (state) => state.venues
);
export const selectIsLoadingVenues = createSelector(
  selectVenuesState,
  (state) => state.isLoadingVenues
);

export const selectVenue = createSelector(
  selectVenuesState,
  (state) => state.venue
);
export const selectIsLoadingVenue = createSelector(
  selectVenuesState,
  (state) => state.isLoadingVenue
);
export const selectVenueError = createSelector(
  selectVenuesState,
  (state) => state.error
);

export const selectFilteredVenues = createSelector(
  selectVenuesState,
  (state) => state.filteredVenues
);

export const selectCurrentSchedule = createSelector(
  selectVenuesState,
  (state) => state.currentSchedule
);
export const selectBookedSlots = createSelector(
  selectVenuesState,
  (state) => state.bookedSlots
);
export const selectFreeSlots = createSelector(
  selectVenuesState,
  (state) => state.freeSlots
);
export const selectIsImageLoadInProgress = createSelector(
  selectVenuesState,
  (state) => state.isImageLoadInProgress
);
export const selectIsMapImageLoadInProgress = createSelector(
  selectVenuesState,
  (state) => state.isMapImageLoadInProgress
);
export const selectGoTabProducts = createSelector(
  selectVenuesState,
  (state) => state.goTabProducts
);
export const selectGoTabSpots = createSelector(
  selectVenuesState,
  (state) => state.goTabSpots
);
export const selectMailchimp = createSelector(
  selectVenuesState,
  (state) => state.mailchimp
);
export const selectIsRequestInProgress = createSelector(
  selectVenuesState,
  (state) => state.isRequestInProgress
);
export const selectSquare = createSelector(
  selectVenuesState,
  (state) => state.square
);
export const selectSocial = createSelector(
  selectVenuesState,
  (state) => state.social
);
export const selectStripeConnectionStatus = createSelector(
  selectVenuesState,
  (state) => state.stripeConnectionStatus
);