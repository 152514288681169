import { createSelector } from "reselect";
import { SuperAdminReportState, State, TimeInterval, TenantPlan, CurrencyType } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: SuperAdminReportState = Object.freeze({
  isRequestInProgress: false,
  timeInterval: TimeInterval.ALL_TIME,
  tenantPlanForFilterReport: `${TenantPlan.core},${TenantPlan.enterprise}`,
  currency: CurrencyType.USD,
});

export default createReducer<SuperAdminReportState>(initialState, {
  [ActionTypeEnum.GetSuperAdminReportsRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetSuperAdminReportsSuccess]: (superAdminReport) => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    superAdminReport,
  }),
  [ActionTypeEnum.GetSuperAdminReportsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetTenantIdFilterReports]: (tenantIdForFilterReport) => (state) => ({
    ...state,
    tenantIdForFilterReport,
  }),
  [ActionTypeEnum.SetSuperAdminReportInterval]: ({
    timeInterval,
    startDate,
    endDate,
  }) => (state) => ({
    ...state,
    timeInterval,
    startDate,
    endDate,
  }),
  [ActionTypeEnum.SetTenantPlanFilterReports]: (tenantPlanForFilterReport) => (state) => ({
    ...state,
    tenantPlanForFilterReport,
  }),
  [ActionTypeEnum.SetSuperAdminReportCurrencyFilter]: (currency) => (state) => ({
    ...state,
    currency,
  }),
  [ActionTypeEnum.GetAppStatusRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetAppStatusSuccess]: (appStatus) => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    appStatus,
  }),
  [ActionTypeEnum.GetAppStatusFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
});
export const selectSuperAdminReportsState = (state: State) => state.superAdminReports;
export const selectSuperAdminReport = createSelector(
  selectSuperAdminReportsState,
  (state) => state.superAdminReport
);
export const selectIsSuperAdminReportRequestInProgress = createSelector(
  selectSuperAdminReportsState,
  (state) => state.isRequestInProgress
);
export const selectTenantIdFilterReport = createSelector(
  selectSuperAdminReportsState,
  (state) => state.tenantIdForFilterReport
);
export const selectSuperAdminTimeInterval = createSelector(
  selectSuperAdminReportsState,
  (state) => state.timeInterval
);
export const selectSuperAdminStartDate = createSelector(
  selectSuperAdminReportsState,
  (state) => state.startDate
);
export const selectSuperAdminEndDate = createSelector(
  selectSuperAdminReportsState,
  (state) => state.endDate
);
export const selectTenantPlanFilterReport = createSelector(
  selectSuperAdminReportsState,
  (state) => state.tenantPlanForFilterReport
);
export const selectSuperAdminCurrencyFilter = createSelector(
  selectSuperAdminReportsState,
  (state) => state.currency
);
export const selectAppStatus = createSelector(
  selectSuperAdminReportsState,
  (state) => state.appStatus
);
