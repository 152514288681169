import { AgeGroup } from '../dto/ageGroup.dto';
import { GuestsWithAgeGroup } from '../dto/guestsWithAgeGroup';


export type AgeGroupPricing = {
  [key: string]: { rate: number; isOn: boolean };
};

export const parseGuestsWithAgeGroup = (
  guestsWithAgeGroups: string,
): GuestsWithAgeGroup[] => {
  return guestsWithAgeGroups
    ? guestsWithAgeGroups.split(';').map(guest => {
      const [ageGroup, count] = guest.split(',');
      return {
        ageGroup,
        count: +count,
      };
    })
    : [];
};

export const serializeGuestsWithAgeGroup = (guests: GuestsWithAgeGroup[]) => {
  return guests.map(({ ageGroup, count }) => `${ageGroup},${count}`).join(';');
};

export const parseAgeGroups = (ageGroups: string): AgeGroup[] => {
  return ageGroups
    ? ageGroups.split(';').map(group => {
      const [groupName, abbreviation, minQty, maxQty, count] = group.split(
        ',',
      );
      return {
        groupName,
        abbreviation,
        minQty: parseInt(minQty, 10),
        maxQty: parseInt(maxQty, 10),
        count: count === 'true',
        minPrice: 0,
      };
    })
    : [];
};

export const serializeAgeGroups = (groups: AgeGroup[]) => {
  return groups
    .map(
      ({ groupName, abbreviation, minQty, maxQty, count }) =>
        `${groupName},${abbreviation},${minQty},${maxQty},${count ? 'true' : 'false'
        }`,
    )
    .join(';');
};

export const parseAgeGroupPricing = (
  ageGroupPricing?: string,
): AgeGroupPricing => {
  const ageGroupEntries = ageGroupPricing
    ? ageGroupPricing.split(';').map(entry => entry.split(','))
    : [];
  const result: AgeGroupPricing = {};
  ageGroupEntries.forEach(([groupName, rate, isOn]) => {
    result[groupName] = { rate: +rate, isOn: isOn === 'true' };
  });
  return result;
};

export const serializeAgeGroupPricing = (ageGroupPricing: AgeGroupPricing) => {
  return ageGroupPricing
    ? Object.entries(ageGroupPricing)
      .map(([groupName, { rate, isOn }]) => `${groupName},${rate},${isOn}`)
      .join(';')
    : '';
};
