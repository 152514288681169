import { Icon } from 'office-ui-fabric-react'
import React from 'react'
import ActionsButton from '../common/ActionsButton'

interface Props {
    onAddPlan: () => void

}

const MembershipsEmptyPlaceholder = ({ onAddPlan }: Props) => {
    return (
        <div className="membership-information-header">
            <div className="membership-title h4">Memberships</div>
            <div className="actions-container">
                <ActionsButton
                    onClick={onAddPlan}
                >
                    <Icon iconName="Add" className="add-icon" />
                    <div className="add-button-text">
                        Add Plan
                    </div>
                </ActionsButton>
            </div>
        </div>
    )
}

export default MembershipsEmptyPlaceholder