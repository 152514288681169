import React from 'react';
import ReactQuill, { ReactQuillProps } from "react-quill";
import './index.scss'

interface Props extends ReactQuillProps {
  setValue: (value: string) => void;
  className?: string;
  label?: string;
  disabled?: boolean;
}

const FormHtmlTextField = ({ value, setValue, className, id, label, disabled, ...props }: Props) => {
  return (
    <div className={`html-editor ${className}`}>
      {
        label && (
          <label htmlFor={id} className='html-editor-label'>
            {label}
          </label>
        )
      }
      <ReactQuill
        id={id}
        value={value}
        onChange={setValue}
        preserveWhitespace
        readOnly={disabled}
        modules={{
          toolbar: {
            container: [
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              ['bold', 'italic', 'underline'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{'align': []}],
              ['link'],
              ['clean'],
              [{'color': []}]
            ],
          },
          clipboard: {
            matchVisual: false
          }
        }}
        {...props}
      />
    </div>
  );
};

export default FormHtmlTextField;