export const color = {
  mainDark: "#03060B",
  white: "#FFFFFF",
  dark: "#212831",
  dark1: "#1E212A",
  grey: "#506176",
  grey1: "#E1E3EA",
  blue: "#0624FF",
  blueHover: "#061bb9",
  greyPanelMainText: "#2B2F3C",
  greyPanelBorder: "#3F455A",
  greyPanelBackground: "#383D4D",
  greyPanelText: "#B2B7C8",
  error: "#A4262C",
  red: "#EC4C47",
  green: "#27AB6E",
  greyHeader: "#F3F4F7",
};
