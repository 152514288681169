export class VenueTaxDto {
    id: string;
    name: string;
    type: string;
    tax: string;
    venueId: string;
}

export class CreateVenueTaxDto {
    name: string;
    type: string;
    tax: string;
    venueId: string;
}