import { createSelector } from "reselect";
import { PromotionState, State } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: PromotionState = Object.freeze({
  isRequestInProgress: false,
  promotions: [],
  promotion: undefined,
});

export default createReducer<PromotionState>(initialState, {
  [ActionTypeEnum.GetPromotionsRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetPromotionsSuccess]: (promotions) => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    promotions,
  }),
  [ActionTypeEnum.GetPromotionsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.CreatePromotionRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.CreatePromotionSuccess]: (promotions) => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    promotions,
  }),
  [ActionTypeEnum.CreatePromotionFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.RemovePromotion]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.RemovePromotionSuccess]: (promotions) => (state) => ({
    ...state,
    isRequestInProgress: false,
    promotions,
  }),
  [ActionTypeEnum.RemovePromotionFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UpdatePromotion]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.UpdatePromotionSuccess]: (promotions) => (state) => ({
      ...state,
      isRequestInProgress: false,
      promotions,
  }),
  [ActionTypeEnum.UpdatePromotionFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetPromotion]: (promotion) => (state) => ({
    ...state,
    isRequestInProgress: false,
    promotion,
  }),
});
export const selectPromotionsState = (state: State) => state.promotions;
export const selectPromotions = createSelector(
  selectPromotionsState,
  (state) => state.promotions
);
export const selectPromotion = createSelector(
  selectPromotionsState,
  (state) => state.promotion
);
export const selectIsPromotionRequestInProgress = createSelector(
  selectPromotionsState,
  (state) => state.isRequestInProgress
);