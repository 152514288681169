import { createSelector } from "reselect";
import { AddonSettingDto, AddonModifierDto, AddonsState, State } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";


const initialState: AddonsState = Object.freeze({
  addons: [],
  filteredAddons: [],
  isRequestInProgress: false,
  error: '',
  currentAddon: undefined,
  isLoadingAddon: false,
  isLoadingAddons: false,
  isImageLoadInProgress: false,
  isLoadingPackages: false,
  relations: [],
  modifiers: [],
});

export default createReducer<AddonsState>(initialState, {
  [ActionTypeEnum.GetAddons]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddons: true,
    error: '',
  }),
  [ActionTypeEnum.GetAddonsSuccess]: (addons: AddonSettingDto[]) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddons: false,
      addons,
      currentAddon: undefined,
      error: '',
    };
  },
  [ActionTypeEnum.GetAddonsFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddons: false,
    error,
  }),
  [ActionTypeEnum.GetAddon]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.GetAddonSuccess]: (currentAddon: AddonSettingDto) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      currentAddon,
      error: '',
    };
  },
  [ActionTypeEnum.GetAddonFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.RemoveScheduleAddon]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
  }),
  [ActionTypeEnum.RemoveScheduleAddonSuccess]: (currentAddon: AddonSettingDto) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      currentAddon,
    };
  },
  [ActionTypeEnum.RemoveScheduleAddonFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.GetAddonsRelations]: () => (state: AddonsState) => ({
    ...state,
    isLoadingPackages: true,
  }),
  [ActionTypeEnum.GetAddonsRelationsSuccess]: (relations) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingPackages: false,
      relations,
    };
  },
  [ActionTypeEnum.GetAddonsRelationsFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingPackages: false,
    error,
  }),
  [ActionTypeEnum.UpdateAddonRelations]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateAddonRelationsSuccess]: (relations) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      relations,
      error: '',
    };
  },
  [ActionTypeEnum.UpdateAddonRelationsFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.SetFilteredAddons]: (filteredAddons) => (state: AddonsState) => ({
    ...state,
    filteredAddons,
  }),
  [ActionTypeEnum.CreateAddon]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddons: true,
    error: '',
  }),
  [ActionTypeEnum.CreateAddonSuccess]: ({ addons, addon }:{addons: AddonSettingDto[], addon: AddonSettingDto}) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddons: false,
      addons,
      currentAddon: addon,
      error: '',
    };
  },
  [ActionTypeEnum.CreateAddonFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddons: false,
    error,
  }),
  [ActionTypeEnum.UpdateAddon]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateAddonSuccess]: ({ addons, addon }:{addons: AddonSettingDto[], addon: AddonSettingDto}) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      addons,
      currentAddon: addon,
      error: '',
    };
  },
  [ActionTypeEnum.UpdateAddonFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.CloneAddon]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.CloneAddonSuccess]: ({ addons, addon }:{addons: AddonSettingDto[], addon: AddonSettingDto}) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      addons,
      currentAddon: addon,
      error: '',
    };
  },
  [ActionTypeEnum.CloneAddonFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.RemoveAddon]: () => (state: AddonsState) => ({
      ...state,
      isLoadingAddons: true,
      error: '',
  }),
  [ActionTypeEnum.RemoveAddonSuccess]: (addons: AddonSettingDto[]) => (state: AddonsState) => {
      return {
          ...state,
          isLoadingAddons: false,
          addons,
          currentAddon: undefined,
          error: '',
      };
  },
  [ActionTypeEnum.RemoveAddonFailure]: (error: string) => (state: AddonsState) => ({
      ...state,
      isLoadingAddons: false,
      error,
  }),

  [ActionTypeEnum.GetAddonImageUrl]: () => (state: AddonsState) => ({
    ...state,
    isImageLoadInProgress: true,
    error: '',
  }),
  [ActionTypeEnum.GetAddonImageSuccess]: (addon: AddonSettingDto) => (state: AddonsState) => {
    return {
      ...state,
      isImageLoadInProgress: false,
      currentAddon: addon,
      error: '',
    };
  },
  [ActionTypeEnum.GetAddonImageFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isImageLoadInProgress: false,
    error,
  }),
  [ActionTypeEnum.GetAddonModifiers]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.GetAddonModifiersSuccess]: (modifiers: AddonModifierDto[]) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      modifiers,
      error: '',
    };
  },
  [ActionTypeEnum.GetAddonModifiersFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.UpdateAddonModifiers]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateAddonModifiersSuccess]: (modifiers: AddonModifierDto[]) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      modifiers,
      error: '',
    };
  },
  [ActionTypeEnum.UpdateAddonModifiersFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.CreateModifier]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.CreateModifierSuccess]: (modifier: AddonModifierDto) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      modifiers: [...state.modifiers, modifier],
      error: '',
    };
  },
  [ActionTypeEnum.CreateModifierFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.UpdateModifier]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateModifierSuccess]: (modifier: AddonModifierDto) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      modifiers: state.modifiers.map(item => item.id === modifier.id ? modifier : item),
      modifier: state.modifier?.id === modifier.id ? modifier : state.modifier,
      error: '',
    };
  },
  [ActionTypeEnum.UpdateModifierFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.DeleteAddonModifiers]: () => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: true,
    error: '',
  }),
  [ActionTypeEnum.DeleteAddonModifiersSuccess]: (modifierId: string) => (state: AddonsState) => {
    return {
      ...state,
      isLoadingAddon: false,
      modifiers: state.modifiers.filter(modifier => modifier.id !== modifierId),
      error: '',
    };
  },
  [ActionTypeEnum.DeleteAddonModifiersFailure]: (error: string) => (state: AddonsState) => ({
    ...state,
    isLoadingAddon: false,
    error,
  }),
  [ActionTypeEnum.SelectAddonModifier]: (modifier?: AddonModifierDto) => (state: AddonsState) => ({
    ...state,
    modifier,
  }),
});

export const selectAddonsState = (state: State) => state.addons;
export const selectAddons = createSelector(
  selectAddonsState,
  (state) => state.addons
);
export const selectAddonsRelations = createSelector(
  selectAddonsState,
  (state) => state.relations
);
export const selectIsLoadingAddons = createSelector(
  selectAddonsState,
  (state) => state.isLoadingAddons
);
export const selectCurrentAddon = createSelector(
  selectAddonsState,
  (state) => state.currentAddon
);
export const selectIsLoadingAddon = createSelector(
  selectAddonsState,
  (state) => state.isLoadingAddon
);
export const selectAddonError = createSelector(
  selectAddonsState,
  (state) => state.error
);
export const selectIsImageLoadInProgress = createSelector(
  selectAddonsState,
  (state) => state.isImageLoadInProgress
);
export const selectFilteredAddons = createSelector(
  selectAddonsState,
  (state) => state.filteredAddons
);
export const selectModifiers = createSelector(
  selectAddonsState,
  (state) => state.modifiers
);
export const selectModifier = createSelector(
  selectAddonsState,
  (state) => state.modifier
);