import { ActionTypeEnum, AppThunk } from ".";
import {
  getWithAuth,
  postWithAuth,
  uploadFile,
  SETTINGS_URL,
  SQUARE_URL,
  MAILCHIMP_URL,
} from "./api";
import { SettingsDto } from "../store/types";
import { addErrorAction } from "./auth-actions";
import { selectIsPartnerAdmin } from "../reducers/auth-reducer";
import { openPopup } from "../../../common/utils/popup";

export const getSettingsAction = (): AppThunk => async (dispatch, getState) => {
  try {
    const isPartnerAdmin = selectIsPartnerAdmin(getState());
    if (isPartnerAdmin) {
      // skip this action for partners
      return;
    }
    dispatch({ type: ActionTypeEnum.GetSettings });
    const response = await getWithAuth(SETTINGS_URL);
    dispatch({
      type: ActionTypeEnum.GetSettingsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetSettingsFailure,
      payload: "error getting settings",
    });
    dispatch(addErrorAction("Get settings failure"));
  }
};

export const updateSettingsAction =
  (settings: Partial<SettingsDto>): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateSettings });
        const response = await postWithAuth(SETTINGS_URL, settings);
        dispatch({
          type: ActionTypeEnum.UpdateSettingsSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.UpdateSettingsFailure,
          payload: "error update settings",
        });
        dispatch(addErrorAction("Update settings failure"));
      }
    };

const uploadFileAndUpdateSetting = async (file: File): Promise<string> => {
  const imageUrl = await uploadFile(file);
  return imageUrl;
};

export const uploadImageAction =
  (file: File): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetHomeImageUrl });
        const imageUrl = await uploadFileAndUpdateSetting(file);
        dispatch({ type: ActionTypeEnum.GetHomeImageUrlSuccess });
        dispatch(updateSettingsAction({ homeBackgroundImage: imageUrl }));
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetHomeImageUrlFailure,
          payload: "error getting image url",
        });
        dispatch(addErrorAction("Get image url failure"));
      }
    };

export const uploadLogoAction =
  (file: File): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetHomeLogoUrl });
        const imageUrl = await uploadFileAndUpdateSetting(file);
        dispatch({ type: ActionTypeEnum.GetHomeLogoUrlSuccess });
        dispatch(updateSettingsAction({ homeLogo: imageUrl }));
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetHomeLogoUrlFailure,
          payload: "error getting logo url",
        });
        dispatch(addErrorAction("Get logo url failure"));
      }
    };

export const uploadEmailLogoAction =
  (file: File): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetHomeEmailLogoUrl });
        const imageUrl = await uploadFileAndUpdateSetting(file);
        dispatch({ type: ActionTypeEnum.GetHomeEmailLogoUrlSuccess });
        dispatch(updateSettingsAction({ homeEmailLogo: imageUrl }));
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetHomeEmailLogoUrlFailure,
          payload: "error getting email logo url",
        });
        dispatch(addErrorAction("Get email logo url failure"));
      }
    };

export const uploadFaviconAction =
  (file: File): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetHomeFaviconUrl });
        const imageUrl = await uploadFileAndUpdateSetting(file);
        dispatch({ type: ActionTypeEnum.GetHomeFaviconUrlSuccess });
        dispatch(updateSettingsAction({ homeFavicon: imageUrl }));
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetHomeFaviconUrlFailure,
          payload: "error getting favicon url",
        });
        dispatch(addErrorAction("Get favicon url failure"));
      }
    };

export const setErrorAction = (error: string) => ({
  type: ActionTypeEnum.SetError,
  payload: error,
});

export const disconnectSquareAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.DisableSquare });
    const response = await getWithAuth<SettingsDto>(`${SQUARE_URL}/disable`);

    dispatch({
      type: ActionTypeEnum.DisableSquareSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.DisableSquareFailure,
      payload: "error disconnect Square",
    });
    dispatch(addErrorAction("Disconnect Square failure"));
  }
};

export const getSquareOAuth2UrlAction =
  (): AppThunk<Promise<string>> => async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetSquareOAuthUrl });
      const response = await getWithAuth<string>(`${SQUARE_URL}/oauth2`);
      dispatch({
        type: ActionTypeEnum.GetSquareOAuthUrlSuccess,
        payload: response.data,
      });
      return response.data;
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.GetSquareOAuthUrlFailure,
        payload: "error get OAuth2 url Square",
      });
      dispatch(addErrorAction("Get OAuth2 url Square failure"));
      throw new Error("error get OAuth2 url Square");
    }
  };

export const getMailchimpOAuth2UrlAction =
  (): AppThunk<Promise<string>> => async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetMailchimpOAuthUrl });
      const response = await getWithAuth<string>(MAILCHIMP_URL);
      dispatch({
        type: ActionTypeEnum.GetMailchimpOAuthUrlSuccess,
        payload: response.data,
      });
      return response.data;
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.GetMailchimpOAuthUrlFailure,
        payload: "error get OAuth2 url Mailchimp",
      });
      dispatch(addErrorAction("Get OAuth2 url Mailchimp failure"));
      throw new Error("error get OAuth2 url Mailchimp");
    }
  };

export const disconnectMailchimpAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.DisableMailchimp });
    const response = await getWithAuth<SettingsDto>(`${MAILCHIMP_URL}/disable`);
    dispatch({
      type: ActionTypeEnum.DisableMailchimpSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.DisableMailchimpFailure,
      payload: "error disconnect Mailchimp",
    });
    dispatch(addErrorAction("Disconnect Mailchimp failure"));
  }
};

export const connectStripeAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.ConnectStripe });
    const response = await postWithAuth(`${SETTINGS_URL}/connect-stripe`);

    window.location.href = response.data;
    dispatch({
      type: ActionTypeEnum.ConnectStripeSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.ConnectStripeFailure,
      payload: "error connect Stripe",
    });
    dispatch(addErrorAction("Connect Stripe failure"));
  }
};
export const disConnectStripeAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.DisconnectStripe });
    const response = await postWithAuth(`${SETTINGS_URL}/disconnect-stripe`);

    dispatch({
      type: ActionTypeEnum.DisconnectStripeSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.DisconnectStripeFailure,
      payload: "error disconnect Stripe",
    });
    dispatch(addErrorAction("Disconnect Stripe failure"));
  }
};
export const checkStripeAction = (disableLoading?: boolean): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: Boolean(disableLoading) ? ActionTypeEnum.CheckStripeWithoutRequestInProgress : ActionTypeEnum.CheckStripe });
    const response = await postWithAuth(`${SETTINGS_URL}/check-stripe`);

    dispatch({
      type: ActionTypeEnum.CheckStripeSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CheckStripeFailure,
      payload: "error check Stripe",
    });
    dispatch(addErrorAction("Check Stripe failure"));
  }
};
export const getReportSettingsAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetReportSettingsRequest });
    const response = await getWithAuth(`${SETTINGS_URL}/report-settings`);

    dispatch({
      type: ActionTypeEnum.GetReportSettingsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetReportSettingsFailure,
      payload: "error report settings",
    });
  }
};
export const saveReportSettingsAction = (data: any): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.SaveReportSettingsRequest });
    const response = await postWithAuth(`${SETTINGS_URL}/report-settings`, data);

    dispatch({
      type: ActionTypeEnum.SaveReportSettingsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.SaveReportSettingsFailure,
      payload: "error report settings",
    });
  }
};
