
import { MembershipPlanDto } from '../../../../server/src/dto/membershipPlan.dto';

export * from "../../../../server/src/dto/membershipPlan.dto";

export interface MembershipState {
  stripeClientSecret: string;
  stripePublishableKey: string;
  isRequestInProgress: boolean;
  plans: MembershipPlanDto[];
  selectedPlan?: MembershipPlanDto;
  error?: string;
}
