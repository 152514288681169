import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { State } from "../../store/types";
import {
  selectIsAuthRequestInProgress,
} from "../../reducers/auth-reducer";
import Login from "./Login/Login";
import SignUp from "./SignUp/SignUp";
import Recover from "./Recover/Recover";
import ResetPassword from "./ResetPassword/ResetPassword";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import "./auth.scss";

enum UI {
  LOGIN = 'login',
  SIGN_UP = 'signup',
  RECOVER_PASSWORD = 'recover',
  RESET_PASSWORD = 'resetPassword',
}
const mapStateToProps = (state: State) => ({
  isAuthRequestInProgress: selectIsAuthRequestInProgress(state),
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const Auth = ({ isAuthRequestInProgress }: Props) => {
  const [state, setAuthState] = useState<UI>(UI.LOGIN);
  useEffect(() => {
    const { purchaseToken, resetToken } = parseUrlQuery(location.search)
    if (purchaseToken) {
      setAuthState(UI.SIGN_UP)
    }else if(resetToken){
      setAuthState(UI.RESET_PASSWORD)
    }
  }, [])

  const route = (state: UI) => {
    switch (state) {
      case UI.LOGIN:
        return <Login toResetPassword={()=>setAuthState(UI.RECOVER_PASSWORD)}/>
      case UI.SIGN_UP:
        return <SignUp/>
      case UI.RECOVER_PASSWORD:
        return <Recover toLogin={()=>setAuthState(UI.LOGIN)} />
      case UI.RESET_PASSWORD:
        return <ResetPassword/>
    }
  }

  return (
    <div className="auth-modal">
      {isAuthRequestInProgress && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {route(state)}
    </div>
  );
};

export default connector(Auth);
