import { createSelector } from "reselect";
import { ConfigsState, State } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: ConfigsState = Object.freeze({
  configs: undefined,
  isRequestInProgress: false,
  error: undefined,
});

export default createReducer<ConfigsState>(initialState, {
  [ActionTypeEnum.GetConfigsRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetConfigsSuccess]: (configs) => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    configs,
  }),
  [ActionTypeEnum.GetConfigsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.UpdateConfigsRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.UpdateConfigsSuccess]: (configs) => (state) => ({
      ...state,
      isRequestInProgress: false,
      configs,
  }),
  [ActionTypeEnum.UpdateConfigsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
});
export const selectConfigsState = (state: State) => state.configs;
export const selectConfigs = createSelector(
  selectConfigsState,
  (state) => state.configs
);
export const selectConfigsError = createSelector(
  selectConfigsState,
  (state) => state.error
);
export const selectIsConfigsRequestInProgress = createSelector(
  selectConfigsState,
  (state) => state.isRequestInProgress
);