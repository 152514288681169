import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ActionButton } from "office-ui-fabric-react";
import { InputField } from "../../common/InputField/InputField";
import PrimaryButton  from "../../common/PrimaryButton/PrimaryButton";
import ButtonsRow from "../../common/ButtonsRow";
import { loginAction } from "../../../actions/auth-actions";
import { State } from "../../../store/types";
import {
  selectAuthError,
  selectIsAuthRequestInProgress,
} from "../../../reducers/auth-reducer";
import RexIcon from "../../../assets/rex-black.png";
import "./login.scss";

const mapDispatchToProps = {
  login: loginAction,
};
const mapStateToProps = (state: State) => ({
  error: selectAuthError(state),
  isAuthRequestInProgress: selectIsAuthRequestInProgress(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & { toResetPassword: () => void };

const Login = ({ login, error, toResetPassword }: Props) => {
  const [username, setUserName] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (username && password) {
      login({ username, password });
    }
  };
  return (
    <form className="login-registrationInfo" onSubmit={onSubmit}>
      <img className="logo-icon" src={RexIcon} />
      <div className="title">Sign in to your dashboard</div>
      <InputField
        label="Email"
        autoComplete="email"
        value={username}
        onChange={(_: any, value: string) => setUserName(value)}
      />
      <InputField
        type="password"
        label="Password"
        autoComplete="current-password"
        value={password}
        onChange={(_: any, value: string) => setPassword(value)}
      />
      {error && <div className="error">{error}</div>}
      <ButtonsRow>
        <PrimaryButton type="submit" backgroundColor="#00f">
          Log in
        </PrimaryButton>
        <ActionButton onClick={() => toResetPassword()}>
          Forgot Password
        </ActionButton>
      </ButtonsRow>

    </form>
  );
};

export default connector(Login);
