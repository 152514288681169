import React from "react";
import { Modal } from "office-ui-fabric-react";
import { VenueSettingDto } from "../../../../store/types";
import VenueImage from "../../../common/Image";
import CancelButton from "../../../../../../common/components/CancelButton";

import TrashIcon from "../../../../assets/trash-icon.svgr";

import "./editVenueImages.scss";

type Props = {
  venue?: VenueSettingDto;
  isImageLoadInProgress: boolean;
  isMapImageLoadInProgress: boolean;
  uploadVenueImageFile: (files: FileList | null) => void;
  uploadSecondaryVenueImageFiles: (files: FileList | null) => void;
  uploadVenueMapImageFile: (files: FileList | null) => void;
  deleteVenueImage: (id: string, imageUrl: string) => void;
  onClose: (email?: string) => void;
};

const EditVenueImages = ({
  isImageLoadInProgress,
  isMapImageLoadInProgress,
  uploadVenueImageFile,
  uploadSecondaryVenueImageFiles,
  uploadVenueMapImageFile,
  venue,
  onClose,
  deleteVenueImage,
}: Props) => {

  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="venue-images"
    >
      <div className="container">
        <div className="header h4">
          <div>Venue Images</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="image-fields-container">
          <div className="image-field">
            <div className="subtitle2">Venue Image</div>
            <VenueImage
              image={venue?.venueImage}
              loading={isImageLoadInProgress}
              uploadImage={uploadVenueImageFile}
              subTitle="1000x600 pixels"
            />
          </div>
          <div className="image-field">
            <div className="subtitle2">Map Image</div>
            <VenueImage
              image={venue?.mapImage}
              loading={isMapImageLoadInProgress}
              uploadImage={uploadVenueMapImageFile}
              subTitle="300x300 pixels"
            />
          </div>
        </div>

        <div className="subtitle1 text-center">More Venue Images</div>
        <div className="venue-image-container">
          <VenueImage
            loading={isImageLoadInProgress}
            uploadImage={uploadSecondaryVenueImageFiles}
            subTitle="1000x600 pixels"
            multiple
          />
          {venue?.secondaryVenueImages.map((imageUrl, i) => (
            <div className="delete-button" key={`${imageUrl}-${i}`}>
              <img src={imageUrl} alt={`venue-image-${i}`} />
              <TrashIcon
                className="trash-icon"
                onClick={() => deleteVenueImage(venue.id, imageUrl)}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default EditVenueImages;
