import React, { createContext, useState, useEffect, useContext } from "react";

export const MAX_MOBILE_WIDTH = 768
export const MAX_MIDDLE_WIDTH = 992
export const MAX_WIDTH = 1248
export const ViewportContext = createContext({});

export const DEFAULT_CARD_COLOR = "#E7EFFF";
export const DEFAULT_TEXT_CARD_COLOR = "#282A30";

export const ViewportProvider = ({ children }: any): React.ReactElement => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width, height }: any = useContext(ViewportContext);
  const isPhone = /iPhone|iPod|Android/i.test(navigator.userAgent)
  const isTablet = /iPad/i.test(navigator.userAgent)
  const isMobile = width < MAX_MOBILE_WIDTH || isPhone;
  const isMiddleScreen = width < MAX_MIDDLE_WIDTH || isTablet;
  const isMaxScreen = width > MAX_WIDTH && (!isMobile || !isMiddleScreen);
  return { isMobile, isMiddleScreen, isMaxScreen };
};
