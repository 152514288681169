import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Modal} from "office-ui-fabric-react";
import {
  CreateScheduleAddonSettingDto,
  ScheduleAddonSettingDto,
  State,
  TimeSlotAddonSettingDto,
} from "../../../../../store/types";
import {DATE_FORMAT} from "../../../../../constants/timedate";
import {groupBy} from "lodash";
import dayjs from "dayjs";
import {nanoid} from "nanoid";
import TimeSlotAddon from "../TimeSlotAddon/TimeSlotAddon";
import CancelButton from "../../../../../../../common/components/CancelButton";
import FormTextField from "../../../../../../../common/components/FormTextField";
import FormDateField from "../../../../common/FormDateField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../../common/components/ColorButton";
import {selectReadonly} from "../../../../../reducers/auth-reducer";
import { selectUIConfig } from "../../../../../reducers/ui-reducer";
import "./editScheduleAddon.scss";

const valueFieldStyle = {
  root: {
    width: "calc((100% - 20px)/2)",
  },
};

const weekDays: number[] = Array.apply(null, Array(7)).map((_, index) => index);
//move Sunday to the end
weekDays.push(weekDays.shift() as number);

interface OwnProps {
  scheduleAddon: ScheduleAddonSettingDto | CreateScheduleAddonSettingDto;
  onClose: () => void;
  timeShifting: number[];
  timeSlotDuration: number;
}

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const EditScheduleAddon = (
  {
    scheduleAddon,
    onClose,
    readonly,
    timeSlotDuration,
    timeShifting,
    uiConfig,
  }: Props
) => {
  useEffect(() => {
    setTitle(scheduleAddon.title);
    setPriority(scheduleAddon.priority);
    setStart(dayjs(scheduleAddon.start).toDate());
    setEnd(dayjs(scheduleAddon.end).toDate());
    setSlots(scheduleAddon.timeSlotsAddon);
  }, [scheduleAddon]);
  const [title, setTitle] = useState(scheduleAddon.title);
  const [priority, setPriority] = useState(scheduleAddon.priority);
  const [start, setStart] = useState<Date | undefined>(
    dayjs(scheduleAddon.start).toDate()
  );
  const [end, setEnd] = useState<Date | undefined>(
    dayjs(scheduleAddon.end).toDate()
  );
  const [slots, setSlots] = useState(scheduleAddon.timeSlotsAddon);
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const timeSlotGroup = groupBy(
    slots.filter((item) => !item.isDeleted),
    "weekDay"
  );
  const endSlot = 24 * 60 / timeSlotDuration;
  const addSlot = (day: number) => {
    setSlots([
      ...slots,
      {
        id: nanoid(),
        addonId: scheduleAddon.addonId,
        scheduleAddonId: "id" in scheduleAddon ? scheduleAddon.id : "new",
        weekDay: day,
        start: 0,
        end: endSlot,
        startTime: 0,
        endTime: 1410,
        is21plus: false,
        rate: 50,
        changed: true,
        isNew: true,
        lanesCount: 0,
        staffCapacity: 1,
        guestsCapacity: 1,
      },
    ]);
  };
  const updateSlot = (slot: TimeSlotAddonSettingDto) => {
    setSlots(
      slots.map((item) =>
        slot.id === item.id ? {
          ...slot,
          changed: true,
          startTime: (slot.start * timeSlotDuration),
          endTime: (slot.end * timeSlotDuration),
        } : item
      )
    );
  };
  const removeSlot = (slotId: string) => {
    let updatedSlots = slots.map((item) =>
      slotId === item.id ? {...item, isDeleted: true} : item
    );
    updatedSlots = updatedSlots.filter(
      (item) => !(item.isNew && item.isDeleted)
    );
    setSlots(updatedSlots);
  };

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (!title || !priority || !start || !end) {
      alert("please fill in all fields");
      return;
    }
    const patch = {
      ...scheduleAddon,
      title,
      priority,
      start: dayjs(start).format(DATE_FORMAT),
      end: dayjs(end).format(DATE_FORMAT),
      timeSlotsAddon: slots,
    };

    //todo: call api to schedule addon

    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-schedule-addon"
    >
      <div className="edit-header h4">
        Schedule
        <CancelButton onClick={() => onClose()}/>
      </div>
      <form className="schedule" onSubmit={onUpdate}>
        <div className="row">
          <FormTextField
            label="Name"
            styles={valueFieldStyle}
            value={title}
            onChange={(_: any, text?: string) => setTitle(text || "")}
            required
            autoComplete="off"
          />
          <FormTextField
            label="Priority"
            styles={valueFieldStyle}
            value={String(priority)}
            onChange={(_: any, text?: string) =>
              setPriority(parseInt(text || "0"))
            }
            required
            autoComplete="off"
          />
        </div>
        <div className="row">
          <FormDateField
            label="Start Date"
            autoOk
            value={start}
            variant="inline"
            inputVariant="outlined"
            onChange={data => setStart(dayjs(data).toDate())}
            format={uiConfig?.dateFormat || "MM/DD/YYYY"}
            className="date"
          />
          <FormDateField
            label="End Date"
            autoOk
            value={end}
            variant="inline"
            inputVariant="outlined"
            onChange={data => setEnd(dayjs(data).toDate())}
            format={uiConfig?.dateFormat || "MM/DD/YYYY"}
            className="date"
          />
        </div>
        {weekDays.map((day) => (
          <TimeSlotAddon
            key={day}
            day={day}
            slots={timeSlotGroup[day]}
            addSlot={() => addSlot(day)}
            updateSlot={updateSlot}
            removeSlot={removeSlot}
            timeSlotDuration={timeSlotDuration}
            timeShifting={timeShifting && timeShifting[day] ? +timeShifting[day] : 0}
            twelveHourClockFormat={twelveHourClockFormat}
          />
        ))}

        <div className="main-buttons">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditScheduleAddon);
