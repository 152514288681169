import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  Toggle,
} from "office-ui-fabric-react";
import {
  updatePackageAction,
  updatePackageAndNavigatePageAction,
} from "../../../../../actions/package-action";
import { selectVenuePage } from "../../../../../reducers/auth-reducer";
import { selectPackage, selectIsLoadingPackage, selectPackageError } from "../../../../../reducers/packages";
import { venueConfigUI } from "../../../../../constants/webConfigUI";
import { FeeType, State } from "../../../../../store/types";
import { selectReadonly } from "../../../../../reducers/auth-reducer";
import { useViewport } from "../../../../../hooks/responsive";
import FormTextField from "../../../../../../../common/components/FormTextField";
import FormSelectField from "../../../../../../../common/components/FormSelectField";
import LinkButton from "../../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../../common/components/CancelButton";
import { feeTypeOptions } from "../../../VenueInfo";
import "./editPackageCancellation.scss";

const mapDispatchToProps = {
  updatePackage: updatePackageAction,
  updatePackageAndNavigatePage: updatePackageAndNavigatePageAction,
};
const mapStateToProps = (state: State) => ({
  currentPackage: selectPackage(state),
  readonly: selectReadonly(state),
  venuePage: selectVenuePage(state),
  isLoadingPackage: selectIsLoadingPackage(state),
  error: selectPackageError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditPackageCancellation = ({
  updatePackage,
  currentPackage,
  readonly,
  onClose,
  venuePage,
  isLoadingPackage,
  error,
  updatePackageAndNavigatePage,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    if (!isLoadingPackage && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingPackage]);
  const { isMobile } = useViewport();
  useEffect(() => {
    setAllowCancellation(currentPackage?.allowCancellation);
    setCancellationFeeType(currentPackage?.cancellationFeeType || FeeType.PERCENT);
    setCancellationFee(currentPackage?.cancellationFee.toString() || "0.00");
    setCancellationCutOff(currentPackage?.cancellationCutOff);
    setAllowModification(currentPackage?.allowModification);
    setModificationFeeType(currentPackage?.modificationFeeType || FeeType.PERCENT);
    setModificationFee(currentPackage?.modificationFee.toString() || "0.00");
    setModificationCutOff(currentPackage?.modificationCutOff);
  }, [currentPackage]);
  const [showCancellationRules, setShowCancellationRules] = useState(true);
  const [allowCancellation, setAllowCancellation] = useState(currentPackage?.allowCancellation);
  const [cancellationFeeType, setCancellationFeeType] = useState(currentPackage?.cancellationFeeType || FeeType.PERCENT);
  const [cancellationFee, setCancellationFee] = useState(currentPackage?.cancellationFee.toString() || "0.00");
  const [cancellationCutOff, setCancellationCutOff] = useState(currentPackage?.cancellationCutOff);
  const [allowModification, setAllowModification] = useState(currentPackage?.allowModification);
  const [modificationFeeType, setModificationFeeType] = useState(currentPackage?.modificationFeeType || FeeType.PERCENT);
  const [modificationFee, setModificationFee] = useState(currentPackage?.modificationFee.toString() || "0.00");
  const [modificationCutOff, setModificationCutOff] = useState(currentPackage?.modificationCutOff);

  const [cancellationFeeError, setCancellationFeeError] = useState("");

  const getPatch = () => {
    const newCancellationFee = cancellationFee ? parseFloat(cancellationFee.toString()) : 0;
    const newModificationFee = modificationFee ? parseFloat(modificationFee.toString()) : 0;
    return {
      allowCancellation: !!allowCancellation,
      cancellationFeeType,
      cancellationFee: newCancellationFee,
      cancellationCutOff: cancellationCutOff || 0,
      allowModification: !!allowModification,
      modificationFeeType,
      modificationFee: newModificationFee,
      modificationCutOff: modificationCutOff || 0,
    };
  }

  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    setCancellationFeeError("");
    if(cancellationFeeType === FeeType.PERCENT && parseFloat(cancellationFee) > 100) {
      setCancellationFeeError("fee cannot be more than 100%");
      setShowCancellationRules(true);
      return;
    }
    saving.current = true;
    const patch = getPatch();
    venuePage === venueConfigUI.PACKAGE_DETAILS
      ? updatePackage({ ...currentPackage, ...patch })
      : updatePackageAndNavigatePage({ ...currentPackage, ...patch });
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-package-cancellation"
    >
      <div className="title h4">
        Cancel and Modify Rules
        <CancelButton onClick={() => onClose()} />
      </div>
      <div className="rules-toggle">
        <div
          className={`rules-toggle-label body2 ${showCancellationRules ? "active" : ""}`}
          onClick={() => setShowCancellationRules(true)}
        >
          Cancel Rules
        </div>
        <div
          className={`rules-toggle-label body2 ${!showCancellationRules ? "active" : ""
            }`}
          onClick={() => setShowCancellationRules(false)}
        >
          Modify Rules
        </div>
      </div>
      <form className="package-cancellation" onSubmit={onUpdate}>
        {showCancellationRules && (
          <>
            <div className="row">
              <Toggle
                label="Allow Cancellations"
                className="toggle"
                checked={allowCancellation}
                onChange={(_: any, value?: boolean) => setAllowCancellation(value)}
                inlineLabel
                disabled={readonly}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormSelectField
                label="Fee Type"
                options={feeTypeOptions}
                selectedKey={cancellationFeeType || ''}
                onChange={(_: any, option) => {
                  setCancellationFeeType(option?.key as FeeType)
                }}
                disabled={!allowCancellation || readonly}
              />
              {!isMobile && (<div className="delimiter" />)}
              <FormTextField
                label="Cancellation fee"
                type="number"
                value={cancellationFee?.toString()}
                onChange={(_: any, text?: string) => setCancellationFee(text || "0.00")}
                autoComplete="off"
                errorMessage={cancellationFeeError}
                disabled={!allowCancellation || readonly}
              />
              {!isMobile && (<div className="delimiter" />)}
              <FormTextField
                label="Cut off time (hours)"
                type="number"
                value={cancellationCutOff?.toString()}
                onChange={(_: any, text?: string) => setCancellationCutOff(Number(text))}
                autoComplete="off"
                disabled={!allowCancellation || readonly}
              />
            </div>
          </>
        )}
        {!showCancellationRules && (
          <>
            <div className="row">
              <Toggle
                label="Allow Modifications"
                className="toggle"
                checked={allowModification}
                onChange={(_: any, value?: boolean) => setAllowModification(value)}
                inlineLabel
                disabled={readonly}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormSelectField
                label="Fee Type"
                options={feeTypeOptions}
                selectedKey={modificationFeeType || ''}
                onChange={(_: any, option) => {
                  setModificationFeeType(option?.key as FeeType)
                }}
                disabled={!allowModification || readonly}
              />
              {!isMobile && (<div className="delimiter" />)}
              <FormTextField
                label="Modification fee"
                type="number"
                value={modificationFee?.toString()}
                onChange={(_: any, text?: string) => setModificationFee(text || "0.00")}
                autoComplete="off"
                disabled={!allowModification || readonly}
              />
              {!isMobile && (<div className="delimiter" />)}
              <FormTextField
                label="Cut off time (hours)"
                type="number"
                value={modificationCutOff?.toString()}
                onChange={(_: any, text?: string) => setModificationCutOff(Number(text))}
                autoComplete="off"
                disabled={!allowModification || readonly}
              />
            </div>
          </>
        )}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditPackageCancellation);
