import { webConfigUI } from "../constants/webConfigUI";
import { AddonSettingDto, CreateQuestionSettingDto, QuestionSettingDto } from '../store/types';
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "./api";
import { addErrorAction } from "./auth-actions";
import { ActionTypeEnum, AppThunk } from "./index";
import { setWebConfigUIAction } from "./ui-actions";

const GET_QUESTIONS_URL = "/api/questions-setting";

export const getQuestionsAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: ActionTypeEnum.GetQuestions });
        const response = await getWithAuth(GET_QUESTIONS_URL);
        dispatch({
            type: ActionTypeEnum.GetQuestionsSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.GetQuestionFailure,
            payload: "error getting questions",
        });
        dispatch(addErrorAction("Get questions failure"));
    }
};

export const selectQuestionAction = (question?: QuestionSettingDto): AppThunk => async (
    dispatch
) => {
    try {
        if (question) {
            dispatch({ type: ActionTypeEnum.GetQuestion });
            const response = await getWithAuth(`${GET_QUESTIONS_URL}/${question.id}`);
            dispatch({
                type: ActionTypeEnum.GetQuestionSuccess,
                payload: response.data,
            });
        } else {
            dispatch({
                type: ActionTypeEnum.GetQuestionSuccess,
                payload: undefined,
            });
        }
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.GetQuestionFailure,
            payload: "error update question",
        });
        dispatch(addErrorAction("Get question data failure"));
    }
};

export const createQuestionAction = (
    question: CreateQuestionSettingDto
): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: ActionTypeEnum.CreateQuestion });
        const response = await postWithAuth(GET_QUESTIONS_URL, question);
        dispatch({
            type: ActionTypeEnum.CreateQuestionSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.CreateQuestionFailure,
            payload: "error create question",
        });
        dispatch(addErrorAction("Create question failure"));
    }
};

export const createQuestionAndNavigatePageAction = (
    question: CreateQuestionSettingDto
): AppThunk => async (dispatch) => {
    dispatch(createQuestionAction(question));
    dispatch(setWebConfigUIAction(webConfigUI.ADDON_DETAILS));
};

export const updateQuestionAction = (question: Partial<QuestionSettingDto>): AppThunk => async (
    dispatch,
) => {
    try {
        dispatch({ type: ActionTypeEnum.UpdateQuestion });
        const response = await putWithAuth(GET_QUESTIONS_URL, question);
        dispatch({
            type: ActionTypeEnum.UpdateQuestionSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.UpdateQuestionFailure,
            payload: "error update question",
        });
        dispatch(addErrorAction("Update question failure"));
    }
};

export const updateAddonAndNavigatePageAction = (addon: Partial<AddonSettingDto>): AppThunk => async (
    dispatch,
) => {
    dispatch(updateQuestionAction(addon));
    dispatch(setWebConfigUIAction(webConfigUI.ADDON_DETAILS));
};

export const removeQuestionAction = (id: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: ActionTypeEnum.RemoveQuestion });
        const response = await deleteWithAuth(`${GET_QUESTIONS_URL}/${id}`);
        dispatch({
            type: ActionTypeEnum.RemoveQuestionSuccess,
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: ActionTypeEnum.RemoveQuestionFailure,
            payload: "error remove question",
        });
        dispatch(addErrorAction("Remove question failure"));
    }
};

export const setFilteredQuestionAction = (filteredQuestions: any): AppThunk => async (dispatch) => {
    dispatch({
        type: ActionTypeEnum.SetFilteredQuestions,
        payload: filteredQuestions,
    });
}