import axios, { AxiosResponse } from "axios";

const TOKEN_ID = "settings-auth"; //todo temp solution, move token to http cookies

export const SETTINGS_URL = "/api/settings";
export const TENANTS_URL = "/api/settings/tenant";
export const PARTNERS_URL = "/api/settings/partner";
export const USERS_URL = "/api/settings/user";
export const VENUE_SETTINGS_URL = "/api/venue-setting";
export const MAILCHIMP_URL = "/api/oauth/mailchimp";
export const SQUARE_URL = "/api/square";
export const IMAGE_URL = "https://assets.reservewithrex.com";

const getToken = () => {
  const token = localStorage.getItem(TOKEN_ID);
  return token;
};
export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_ID, token);
};

export const get = <ResType = any>(url: string, params?: any) =>
  axios.get<any, AxiosResponse<ResType>>(url, params);
export const post = <ResType = any>(url: string, data?: any) =>
  axios.post<any, AxiosResponse<ResType>>(url, data);

export const getWithAuth = <ResType = any>(url: string, params?: any) =>
  axios.get<any, AxiosResponse<ResType>>(url, {
    params,
    headers: {
      authorization: `bearer ${getToken()}`,
    },
  });
export const postWithAuth = <ResType = any>(url: string, body?: any) =>
  axios.post<any, AxiosResponse<ResType>>(url, body, {
    headers: {
      authorization: `bearer ${getToken()}`,
    },
  });
export const putWithAuth = <ResType = any>(url: string, body?: any) =>
  axios.put<any, AxiosResponse<ResType>>(url, body, {
    headers: {
      authorization: `bearer ${getToken()}`,
    },
  });
export const deleteWithAuth = <ResType = any>(url: string, params?: any) =>
  axios.delete<any, AxiosResponse<ResType>>(url, {
    params,
    headers: {
      authorization: `bearer ${getToken()}`,
    },
  });

export const uploadFile = async (file: File): Promise<string> => {
  const type = file.type;
  const ext = type.split("/")[1] || "jpg";
  const fileName = `${Date.now()}.${ext}`;
  const options = {
    headers: {
      "x-amz-acl": "public-read",
      "Content-Type": type,
    },
  };

  const responseFile = await postWithAuth(`${SETTINGS_URL}/upload-url`, {
    fileName: fileName,
    contentType: type,
  });
  const uploadUrl = responseFile.data;
  await axios.put(uploadUrl, file, options);
  return `${IMAGE_URL}/${fileName}`;
};

export const uploadFiles = async (files: File[]): Promise<string[]> => {
  const contentType = [];
  const body = [];
  const urls = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const type = file.type;
    const ext = type.split("/")[1] || "jpg";
    const fileName = `${Date.now()}${i}.${ext}`;
    body.push({ fileName, contentType: type });
    contentType.push(type);
    urls.push(`${IMAGE_URL}/${fileName}`);
  }

  const responseFile = await postWithAuth<string[]>(`${SETTINGS_URL}/upload-urls`, body);
  const uploadUrls = responseFile.data;
  let i = 0;
  for await (const uploadUrl of uploadUrls) {
    await axios.put(uploadUrl, files[i], {
      headers: {
        "x-amz-acl": "public-read",
        "Content-Type": contentType[i],
      },
    });
    i++;
  }
  return urls;
};
