import dayjs from "dayjs";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { updatePlanAction } from "../../actions/membership-action";
import { setVenuePageAction, setWebConfigUIAction } from "../../actions/ui-actions";
import {
    removeScheduleAction,
    removeVenueAction,
} from "../../actions/venue-actions";
import CashIcon from '../../assets/cash.svgr';
import ShoppingCartIcon from '../../assets/shopping-cart.svgr';
import { webConfigUI } from "../../constants/webConfigUI";
import { useViewport } from "../../hooks/responsive";
import { selectReadonly, selectVenuePage } from "../../reducers/auth-reducer";
import { selectPlan } from "../../reducers/membership";
import { selectCurrentSchedule, selectIsLoadingVenue, selectVenue } from "../../reducers/venues";
import {
    CreateScheduleSettingDto,
    InventoryMethodTypes,
    ScheduleSettingDto,
    State,
} from "../../store/types";
import "../VenueDetails/venueDetails.scss";
import BackButton from "../common/BackButton/BackButton";
import MembershipsInfo from "./MembershipsInfo";
import "./plans.scss";

const DATE_FORMAT = "MM/DD/YYYY";
const newSchedule = {
    title: "",
    priority: 1,
    start: dayjs().format(DATE_FORMAT),
    end: dayjs().add(10, "d").format(DATE_FORMAT),
    inventoryMethod: InventoryMethodTypes.byLanes,
    timeSlots: [],
    startTimeOnly: Array(7).fill(false).join(','),
};

const mapDispatchToProps = {
    removeSchedule: removeScheduleAction,
    removeVenue: removeVenueAction,
    setWebConfigUI: setWebConfigUIAction,
    setVenuePage: setVenuePageAction,
    updatePlan: updatePlanAction,
};
const mapStateToProps = (state: State) => ({
    venue: selectVenue(state),
    readonly: selectReadonly(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    currentSchedule: selectCurrentSchedule(state),
    venuePage: selectVenuePage(state),
    currentPlan: selectPlan(state),

});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const MembershupsDetails = ({
    setWebConfigUI,
    venue,
    readonly,
    currentPlan,
    updatePlan
}: Props) => {
    const { isMiddleScreen } = useViewport();
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenResourceCategoryEdit, setIsOpenResourceCategoryEdit] = useState(false);
    const [isOpenScheduleEdit, setIsOpenScheduleEdit] = useState(false);
    const [schedule, setSchedule] = useState<
        ScheduleSettingDto | CreateScheduleSettingDto
    >({ ...newSchedule, venueId: venue?.id || "" });
    const [isCloneVenue, setIsCloneVenue] = useState(false);
    const [isRemoveOpen, setIsRemoveOpen] = useState(false);
    const [isRemoveVenueOpen, setIsRemoveVenueOpen] = useState(false);
    const [scheduleId, setScheduleId] = useState("");
    const editVenue = () => {

        setIsCloneVenue(false);
        setIsOpenEdit(true);
    };
    const onRemoveVenue = () => setIsRemoveVenueOpen(true);
    const addSchedule = () => {
        setSchedule({ ...newSchedule, venueId: venue?.id || "" });
        setIsOpenScheduleEdit(true);
    };
    const cloneSchedule = (schedule: ScheduleSettingDto) => {
        const { id, ...withoutId } = schedule;
        const newSchedule = {
            ...withoutId,
            title: "",
            changed: true,
            isNew: true,
            priority: schedule.priority + 1,
            timeSlots: schedule.timeSlots.map((item) => ({
                ...item,
                id: nanoid(),
                venueId: schedule.venueId,
                scheduleId: "new",
                changed: true,
                isNew: true,
            })),
        };
        setSchedule(newSchedule);
        setIsOpenScheduleEdit(true);
    };
    const editSchedule = (schedule: ScheduleSettingDto) => {
        setSchedule(schedule);
        setIsOpenScheduleEdit(true);
    };
    const removeVenueSchedule = (id: string) => {
        setScheduleId(id);
        setIsRemoveOpen(true);
    };
    const backToList = () => {
        setWebConfigUI(webConfigUI.MEMBERSHIP);
    }

    const renderContent = () => {
        return (
            <MembershipsInfo
                isMiddleScreen={isMiddleScreen}
                readonly={readonly}
                venue={venue}
                editVenue={editVenue}
                addSchedule={addSchedule}
                editSchedule={editSchedule}
                cloneSchedule={cloneSchedule}
                removeVenueSchedule={removeVenueSchedule}
            />
        );



    }
    const content = renderContent()
    if (!selectPlan) {
        return null;
    }
    return (
        <div className="venue-details-container">
            <div className="venue-details">
                <BackButton
                    text='Memberships'
                    onBack={backToList}
                />
                <div className="venue-details-information-container">
                    <div className="venue-details-information-header memberships-details-information-header">
                        <div className="venue-details-title h4">
                            {currentPlan?.name}
                        </div>
                        <div className="memberships-details-information-details">
                            <div className="">
                                <ShoppingCartIcon />
                                <div className="body2">Subscribers: </div>
                            </div>
                            <div className="">
                                <CashIcon />
                                <div className="body2">MRR: ${currentPlan?.monthlyRate}/mo</div>
                            </div>
                        </div>
                    </div>
                    <hr className="memberships-delimiter" />
                    {content}
                </div>
            </div>

        </div>
    );
};

export default connector(MembershupsDetails);
