import React, {useState, useEffect} from "react";
import {
  Modal,
} from "office-ui-fabric-react";
import { nanoid } from "nanoid";
import { color } from "../../../constants/styles";
import { FilterReservationParameters } from "../../../store/types";
import ColorButton from "../../../../../common/components/ColorButton";
import LinkButton from "../../../../../common/components/LinkButton";
import CancelButton from "../../../../../common/components/CancelButton";
import Filter from "./Filter/Filter";
import "./filterReservations.scss";
type Props = {
  onClose: () => void;
  filterParametersForReservations: FilterReservationParameters[];
  setFilterParametersForReservations: (filterParameters: FilterReservationParameters[]) => void;
};

const FilterReservations = ({
  filterParametersForReservations,
  setFilterParametersForReservations,
  onClose,
}: Props) => {
  useEffect(() => {
    setFilterParameterGroup(filterParametersForReservations);
  }, [filterParametersForReservations]);
  const [filterParameterGroup, setFilterParameterGroup] = useState<FilterReservationParameters[]>(filterParametersForReservations);

  const addFilterParameter = () => {
    setFilterParameterGroup([
      ...filterParameterGroup,
      {
        id: nanoid(),
        filterParameter: null,
        filterCondition: null,
        filterValue: "",
      },
    ]);
  };

  const updateFilterParameter = (parameter: FilterReservationParameters) => {
    setFilterParameterGroup(
      filterParameterGroup.map((item) =>
        parameter.id === item.id ? {
          ...parameter,
          filterParameter: parameter.filterParameter,
          filterCondition: parameter.filterCondition,
          filterValue: parameter.filterValue,
        } : item
      )
    );
  };

  const removeFilterParameter = (parameterId: string) => {
    let updatedSlots = filterParameterGroup.filter(
      (item) => parameterId !== item.id
    );
    setFilterParameterGroup(updatedSlots);
  };

  const onReset = () => {
    setFilterParametersForReservations([{
      id: nanoid(),
      filterParameter: null,
      filterCondition: null,
      filterValue: "",
    }]);
    onClose();
  }

  const onFilter = () => {
    setFilterParametersForReservations(filterParameterGroup);
    onClose();
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="filter-reservations"
    >
      <div className="container">
        <div className="header">
          <div>Filters</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <Filter
          filterParameter={filterParameterGroup}
          addFilterParameter={addFilterParameter}
          updateFilterParameter={updateFilterParameter}
          removeFilterParameter={removeFilterParameter}
        />
        <div className="buttons-container">
          <LinkButton
            backgroundColor={"white"}
            hoverColor={"rgba(236, 76, 71, 0.04)"}
            textColor={color.red}
            onClick={onReset}
          >
            Reset
          </LinkButton>
          <ColorButton onClick={onFilter}>Filter</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default FilterReservations;
