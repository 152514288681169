import React from "react";
import {
  ActionButton,
  IDropdownOption,
  Toggle,
} from "office-ui-fabric-react";
import {TimeSlotAddonSettingDto} from "../../../../../store/types";
import { WeekDays } from "../../../../../constants/timedate";
import { TimeSlotAddonRow } from "./TimeSlotAddonRow/TimeSlotAddonRow";
import { formatTimeFromIndex } from "../../../../../utils/formats";
import { HOURS_NUMBER_FOR_DAY_SLOTS } from "../../../../../constants/timeSlots";
import "./timeSlotAddon.scss";

interface Props {
  day: number;
  slots: TimeSlotAddonSettingDto[];
  addSlot: () => void;
  updateSlot: (slot: TimeSlotAddonSettingDto) => void;
  removeSlot: (slotId: string) => void;
  timeSlotDuration: number;
  timeShifting: number;
  twelveHourClockFormat: boolean;
}

const TimeSlotAddon = ({
  day,
  slots = [],
  addSlot,
  updateSlot,
  removeSlot,
  timeSlotDuration,
  timeShifting,
  twelveHourClockFormat,
}: Props) => {

  const onChange = (
    slot: TimeSlotAddonSettingDto,
    field: string,
    value: string | number | boolean
  ) => {
    slot.start = slot.startTime ? Math.floor(slot.startTime / timeSlotDuration) : slot.start * slotsInHour / 2;
    slot.end = slot.endTime ? Math.floor(slot.endTime / timeSlotDuration) : slot.end * slotsInHour / 2;
    updateSlot({ ...slot, [field]: value });
  };
  const onToggle = () => {
    if (slots.length === 0) {
      addSlot();
    } else {
      slots.forEach(slot => {
        removeSlot(slot.id);
      })
    }
  }
  const slotsInHour = 60 / timeSlotDuration;
  const hoursShifting = Math.floor(timeShifting / 60);
  const OPEN_SLOTS = Math.floor((HOURS_NUMBER_FOR_DAY_SLOTS - hoursShifting) * slotsInHour) + 1;

  const times: IDropdownOption[] = Array(OPEN_SLOTS).fill(null).map(
    (_, index) => ({
      key: index,
      text: formatTimeFromIndex(index, timeSlotDuration, timeShifting, twelveHourClockFormat),
    })
  );

  return (
    <div className="time-slot-addon">
      <div className="time-slot-header h6">
        {WeekDays[day]}
        <Toggle
          checked={slots.length > 0}
          onChange={onToggle}
        />
      </div>
      {slots.length > 0 &&
        <div className="time-slot-rows">
          {slots.map(item => (
            <div key={item.id}>
              <TimeSlotAddonRow
                slot={item}
                times={times}
                timeSlotDuration={timeSlotDuration}
                onChange={onChange}
                removeSlot={removeSlot}
              />
            </div>
          ))}
          <div className="time-slot-button">
            <ActionButton
              allowDisabledFocus
              onClick={() => addSlot()}
            >+ Add item</ActionButton>
          </div>
        </div>}
    </div>
  );
};

export default TimeSlotAddon