import { IDatePickerStrings } from "office-ui-fabric-react";
import dayjs from "dayjs";

export const DATE_FORMAT_US = "MM/DD/YYYY";
export const DATE_FORMAT = "YYYY-MM-DD";
export const FULL_DATE_FORMAT = "dddd, MMMM D, YYYY";
export const SCHEDULE_DATE_FORMAT = "MMM D, YYYY";

export const WeekDays =  [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ]

export const DayPickerStrings: IDatePickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],

  shortDays: ["S", "M", "T", "W", "T", "F", "S"],

  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker"
};

export const onFormatUSDate = (date?: Date): string => {
  return date?dayjs(date).format(DATE_FORMAT_US):'';
};

export const onParseDateFromUSString = (val: string): Date => {
  return val?dayjs(val, DATE_FORMAT_US).toDate():(new Date());
}

export const onFormatMUiDate = (date?: Date): string => {
  return date?dayjs(date).format(DATE_FORMAT_US):'';
};

export const onFormatUSTime = (date?: Date): string => {
  if(date) {
    const hours = dayjs(date).get('hour');
    const allMinutes = dayjs(date).get('minute') + (hours * 60);
    return allMinutes.toString();
  }
  return '';
};