export class TimeSlotSettingDto {
  id: string;
  venueId: string;
  scheduleId: string;
  weekDay: number;
  start: number;
  end: number;
  is21plus: boolean;
  rate: number;
  lanesCount: number;
  staffCapacity: number;
  guestsCapacity: number;
  changed?: boolean;
  isNew?: boolean;
  isDeleted?: boolean;
  startTime: number;
  endTime: number;
}

export class CreateTimeSlotSettingDto {
  venueId: string;
  scheduleId: string;
  weekDay: number;
  start: number;
  end: number;
  is21plus: boolean;
  rate: number;
  lanesCount: number;
  staffCapacity: number;
  guestsCapacity: number;
  startTime: number;
  endTime: number;
}
