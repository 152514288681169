import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { InputField } from "../../common/InputField/InputField";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import ButtonsRow from "../../common/ButtonsRow";
import { resetPasswordAction } from "../../../actions/auth-actions";
import { State } from "../../../store/types";
import { selectAuthError } from "../../../reducers/auth-reducer";
import { parseUrlQuery } from "../../../utils/urlSearchQuery";
import RexIcon from "../../../assets/rex-black.png";
import "./resetPassword.scss";

const mapDispatchToProps = {
  resetPassword: resetPasswordAction,
};
const mapStateToProps = (state: State) => ({
  error: selectAuthError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ResetPassword = ({ resetPassword, error }: Props) => {
  const [password, setPassword] = useState<string | undefined>("");
  const [repeatPassword, setRepeatPassword] = useState<string | undefined>("");
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password && password === repeatPassword) {
      const { resetToken } = parseUrlQuery(location.search);
      resetPassword({ password, resetToken });
    }
  };
  return (
    <form className="sign-up-registrationInfo" onSubmit={onSubmit}>
      <img className="logo-icon" src={RexIcon} />
      <div className="title">Welcome to REX!</div>
      <div className="sub-title">Enter new password for your account.</div>
      <InputField
        type="password"
        label="Password"
        autoComplete="current-password"
        value={password}
        onChange={(_: any, value: string) => setPassword(value)}
      />
      <InputField
        type="password"
        label="Confirm Password"
        value={repeatPassword}
        onChange={(_: any, value: string) => setRepeatPassword(value)}
      />
      {error && <div className="error">{error}</div>}
      <ButtonsRow>
        <PrimaryButton type="submit" backgroundColor="#00f">
          Reset Password
      </PrimaryButton>
      </ButtonsRow>
    </form>
  );
};

export default connector(ResetPassword);
