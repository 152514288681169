
import { PartnerDto } from '../../../../server/src/dto/partner.dto'
import { TenantUserDto } from '../../../../server/src/dto/user.dto';

export * from "../../../../server/src/dto/partner.dto";
export * from "../../../../server/src/entities/enums";

export interface PartnerState {
  isRequestInProgress: boolean;
  partners: PartnerDto[];
  partner?: PartnerDto;
  error?: string;
  users: TenantUserDto[];
  selectedUser?: TenantUserDto;
}
