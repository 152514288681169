import { createSelector } from "reselect";
import { AuthState, State, UserType } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";
import { UserDto } from "../store/types";
import { webConfigUI, venueConfigUI } from "../constants/webConfigUI";

const initialState: AuthState = Object.freeze({
  isRequestInProgress: false,
  isResetPasswordSent: false,
  authenticated: false,
  readonly: true,
  superAdmin: false,
  venueAdmin: false,
  fromSuperAdmin: false,
  partnerAdmin: false,
  steltronicAdmin: false,
  goTabAdmin: false,
  bowlingMarketingAdmin: false,
  errors: [],
  ui: webConfigUI.REPORTS,
  venuePage: venueConfigUI.GENERAL,
  adminType: undefined,
});

export default createReducer<AuthState>(initialState, {
  [ActionTypeEnum.AuthRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.AuthSuccess]: (user: UserDto) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    authenticated: true,
    readonly:
      user.type !== UserType.admin &&
      user.type !== UserType.superAdmin &&
      user.type !== UserType.adminSteltronic &&
      user.type !== UserType.adminGoTab &&
      user.type !== UserType.adminBowlingMarketing &&
      user.type !== UserType.venueAdmin,
    superAdmin: user.type === UserType.superAdmin,
    fromSuperAdmin:
      !state.authenticated &&
      (user.type === UserType.superAdmin || user.type === UserType.partner)
        ? true
        : state.fromSuperAdmin,
    partnerAdmin:
      user.type === UserType.partner ||
      user.type === UserType.adminSteltronic ||
      user.type === UserType.adminGoTab ||
      user.type === UserType.adminBowlingMarketing,
    steltronicAdmin: user.type === UserType.adminSteltronic,
    goTabAdmin: user.type === UserType.adminGoTab,
    bowlingMarketingAdmin: user.type === UserType.adminBowlingMarketing,
    venueAdmin: user.type === UserType.venueAdmin,
    user,
    error: "",
  }),
  [ActionTypeEnum.AuthFailure]: (error: string) => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    authenticated: false,
    readonly: true,
    superAdmin: false,
    partnerAdmin: false,
    steltronicAdmin: false,
    goTabAdmin: false,
    bowlingMarketingAdmin: false,
    user: undefined,
    error,
  }),
  [ActionTypeEnum.ForgetPasswordRequest]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    isResetPasswordSent: false,
    error: "",
  }),
  [ActionTypeEnum.ForgetPasswordSuccess]:
    (user: UserDto) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      isResetPasswordSent: true,
      error: "",
    }),
  [ActionTypeEnum.ForgetPasswordFailure]:
    (error: string) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      isResetPasswordSent: false,
      error,
    }),
  [ActionTypeEnum.Logout]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: false,
    authenticated: false,
    readonly: true,
    superAdmin: false,
    partnerAdmin: false,
    steltronicAdmin: false,
    goTabAdmin: false,
    bowlingMarketingAdmin: false,
    user: undefined,
    error: "",
  }),
  [ActionTypeEnum.addError]: (error) => (state: AuthState) => ({
    ...state,
    errors: [...state.errors, error],
  }),
  [ActionTypeEnum.dismissError]: (id: number) => (state: AuthState) => ({
    ...state,
    errors: state.errors.filter((error) => error.id !== id),
  }),
  [ActionTypeEnum.setWebConfigUI]: (ui) => (state: AuthState) => ({
    ...state,
    ui,
    venuePage: venueConfigUI.GENERAL,
  }),
  [ActionTypeEnum.setVenuePage]: (venuePage) => (state: AuthState) => ({
    ...state,
    venuePage,
  }),
  [ActionTypeEnum.GetAdminType]: () => (state: AuthState) => ({
    ...state,
    isRequestInProgress: true,
    error: "",
  }),
  [ActionTypeEnum.GetAdminTypeSuccess]:
    (adminType: UserType) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      adminType,
      error: "",
    }),
  [ActionTypeEnum.GetAdminTypeFailure]:
    (error: string) => (state: AuthState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
});

export const selectAuth = (state: State) => state.auth;
export const selectAuthenticated = createSelector(
  selectAuth,
  (state: AuthState) => state.authenticated
);
export const selectReadonly = createSelector(
  selectAuth,
  (state: AuthState) => state.readonly
);
export const selectIsSuperAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.superAdmin
);
export const selectIsFromSuperAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.fromSuperAdmin
);
export const selectIsPartnerAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.partnerAdmin
);
export const selectIsSteltronicAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.steltronicAdmin
);
export const selectIsGoTabAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.goTabAdmin
);
export const selectIsBowlingMarketingAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.bowlingMarketingAdmin
);
export const selectIsVenueAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.venueAdmin
);
export const selectIsAuthRequestInProgress = createSelector(
  selectAuth,
  (state: AuthState) => state.isRequestInProgress
);
export const selectIsResetPasswordSent = createSelector(
  selectAuth,
  (state: AuthState) => state.isResetPasswordSent
);
export const selectUser = createSelector(
  selectAuth,
  (state: AuthState) => state.user
);
export const selectAuthError = createSelector(
  selectAuth,
  (state: AuthState) => state.error
);
export const selectErrors = createSelector(
  selectAuth,
  (state: AuthState) => state.errors
);
export const selectWebConfigUI = createSelector(
  selectAuth,
  (state: AuthState) => state.ui
);
export const selectVenuePage = createSelector(
  selectAuth,
  (state: AuthState) => state.venuePage
);
export const selectAdminType = createSelector(
  selectAuth,
  (state: AuthState) => state.adminType
);
