import { createSelector } from "reselect";
import { PackagesState, State } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: PackagesState = Object.freeze({
    packages: [],
    currentPackageSchedule: [],
    bookedPackageSlots: [],
    freePackageSlots: [],
    isLoadingPackages: false,
    isLoadingPackage: false,
    isRequestInProgress: false,
    isImageLoadInProgress: false,
    error: '',
    filteredPackages: [],
    parentPackageSchedule: [],
});

export default createReducer<PackagesState>(initialState, {
    [ActionTypeEnum.Logout]: () => (state) => ({
        ...initialState,
    }),
    [ActionTypeEnum.GetPackages]: () => (state) => ({
        ...state,
        isLoadingPackages: true,
        error: '',
    }),
    [ActionTypeEnum.GetPackagesSuccess]: (packages) => (state) => {
        return {
            ...state,
            isLoadingPackages: false,
            packages,
            currentPackage: undefined,
            parentPackageSchedule: [],
            error: '',
        };
    },
    [ActionTypeEnum.GetPackagesFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackages: false,
        error,
    }),
    [ActionTypeEnum.SetFilteredPackages]: (filteredPackages) => (state) => ({
        ...state,
        filteredPackages,
    }),
    [ActionTypeEnum.CreatePackage]: () => (state) => ({
        ...state,
        isLoadingPackages: true,
        error: '',
    }),
    [ActionTypeEnum.CreatePackageSuccess]: ({ packages, currentPackage }) => (state) => {
        return {
            ...state,
            isLoadingPackages: false,
            packages,
            currentPackage,
            error: '',
        };
    },
    [ActionTypeEnum.CreatePackageFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackages: false,
        error,
    }),
    [ActionTypeEnum.RemovePackage]: () => (state) => ({
        ...state,
        isLoadingPackages: true,
        error: '',
    }),
    [ActionTypeEnum.RemovePackageSuccess]: (packages) => (state) => {
        return {
            ...state,
            isLoadingPackages: false,
            packages,
            currentPackage: undefined,
            error: '',
        };
    },
    [ActionTypeEnum.RemovePackageFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackages: false,
        error,
    }),
    [ActionTypeEnum.GetPackage]: () => (state) => ({
        ...state,
        isLoadingPackage: true,
        error: '',
    }),
    [ActionTypeEnum.GetPackageSuccess]: (currentPackage) => (state) => {
        return {
            ...state,
            isLoadingPackage: false,
            currentPackage,
            parentPackageSchedule: [],
            error: '',
        };
    },
    [ActionTypeEnum.GetPackageFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackage: false,
        error,
    }),
    [ActionTypeEnum.UpdatePackage]: () => (state) => ({
        ...state,
        isLoadingPackage: true,
        error: '',
    }),
    [ActionTypeEnum.UpdatePackageSuccess]: ({ packages, currentPackage }) => (state) => {
        return {
            ...state,
            isLoadingPackage: false,
            packages,
            currentPackage,
            error: '',
        };
    },
    [ActionTypeEnum.UpdatePackageFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackage: false,
        error,
    }),
    [ActionTypeEnum.ClonePackage]: () => (state) => ({
        ...state,
        isLoadingPackage: true,
        error: '',
    }),
    [ActionTypeEnum.ClonePackageSuccess]: ({ packages, currentPackage }) => (state) => {
        return {
            ...state,
            isLoadingPackage: false,
            packages,
            currentPackage,
            error: '',
        };
    },
    [ActionTypeEnum.ClonePackageFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackage: false,
        error,
    }),
    [ActionTypeEnum.GetPackageImageUrl]: () => (state) => ({
        ...state,
        isImageLoadInProgress: true,
        error: '',
    }),
    [ActionTypeEnum.GetPackageImageSuccess]: (currentPackage) => (state) => {
        return {
            ...state,
            isImageLoadInProgress: false,
            currentPackage,
            error: '',
        };
    },
    [ActionTypeEnum.GetPackageImageFailure]: (error) => (state) => ({
        ...state,
        isImageLoadInProgress: false,
        error,
    }),
    [ActionTypeEnum.UpdateSchedulePackage]: () => (state) => ({
        ...state,
        isLoadingPackage: true,
    }),
    [ActionTypeEnum.UpdateSchedulePackageSuccess]: (currentPackage) => (state) => {
        return {
            ...state,
            isLoadingPackage: false,
            currentPackage,
        };
    },
    [ActionTypeEnum.UpdateSchedulePackageFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackage: false,
        error,
    }),
    [ActionTypeEnum.RemoveSchedulePackage]: () => (state) => ({
        ...state,
        isLoadingPackage: true,
    }),
    [ActionTypeEnum.RemoveSchedulePackageSuccess]: (currentPackage) => (state) => {
        return {
            ...state,
            isLoadingPackage: false,
            currentPackage,
        };
    },
    [ActionTypeEnum.RemoveSchedulePackageFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackage: false,
        error,
    }),
    [ActionTypeEnum.SetPackageError]: (error) => (state) => ({
        ...state,
        error,
    }),
    [ActionTypeEnum.GetCurrentPackageSchedule]: () => (state) => ({
        ...state,
        isLoadingPackage: true,
    }),
    [ActionTypeEnum.GetCurrentPackageScheduleSuccess]: ({ currentPackageSchedule, bookedSlots, freeSlots }: {currentPackageSchedule:[][], bookedSlots: number[], freeSlots: number[]}) => (state) => {
        return {
            ...state,
            isLoadingPackage: false,
            currentPackageSchedule,
            bookedPackageSlots: bookedSlots,
            freePackageSlots: freeSlots,
        };
    },
    [ActionTypeEnum.GetCurrentPackageScheduleFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackage: false,
        currentPackageSchedule: [],
        bookedPackageSlots: [],
        freePackageSlots: [],
        error,
    }),
    [ActionTypeEnum.GetParentPackageSchedule]: () => (state) => ({
        ...state,
        isLoadingPackage: true,
        error: '',
    }),
    [ActionTypeEnum.GetParentPackageScheduleSuccess]: (parentPackageSchedule) => (state) => {
        return {
            ...state,
            isLoadingPackage: false,
            parentPackageSchedule,
            error: '',
        };
    },
    [ActionTypeEnum.GetParentPackageScheduleFailure]: (error) => (state) => ({
        ...state,
        isLoadingPackage: false,
        error,
    }),
});

export const selectPackagesState = (state: State) => state.packages;
export const selectPackages = createSelector(
    selectPackagesState,
    (state) => state.packages
);
export const selectIsLoadingPackages = createSelector(
    selectPackagesState,
    (state) => state.isLoadingPackages
);
export const selectPackage = createSelector(
    selectPackagesState,
    (state) => state.currentPackage
);
export const selectIsLoadingPackage = createSelector(
    selectPackagesState,
    (state) => state.isLoadingPackage
);
export const selectPackageError = createSelector(
    selectPackagesState,
    (state) => state.error
);
export const selectIsImageLoadInProgress = createSelector(
    selectPackagesState,
    (state) => state.isImageLoadInProgress
);
export const selectCurrentPackageSchedule = createSelector(
    selectPackagesState,
    (state) => state.currentPackageSchedule
);
export const selectBookedPackageSlots = createSelector(
    selectPackagesState,
  (state) => state.bookedPackageSlots
);
export const selectFreePackageSlots = createSelector(
    selectPackagesState,
  (state) => state.freePackageSlots
);
export const selectFilteredPackages = createSelector(
    selectPackagesState,
  (state) => state.filteredPackages
);
export const selectParentPackageSchedule = createSelector(
    selectPackagesState,
  (state) => state.parentPackageSchedule
);