import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  Toggle,
} from "office-ui-fabric-react";
import { updateVenueAction } from "../../../../actions/venue-actions";
import { selectVenue, selectIsLoadingVenue } from "../../../../reducers/venues";
import { State, FeeType } from "../../../../store/types";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import FormTextField from "../../../../../../common/components/FormTextField";
import FormSelectField from "../../../../../../common/components/FormSelectField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import { useViewport } from "../../../../hooks/responsive";
import { feeTypeOptions } from "../../VenueInfo";
import "./editCancelAndModifySettings.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  readonly: selectReadonly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditCancelAndModifySettings = ({
  updateVenue,
  venue,
  readonly,
  onClose,
}: Props) => {
  useEffect(() => {
    setAllowCancellation(venue?.allowCancellation);
    setCancellationFeeType(venue?.cancellationFeeType || FeeType.PERCENT);
    setCancellationFee(venue?.cancellationFee.toString() || "0.00");
    setCancellationCutOff(venue?.cancellationCutOff);
    setAllowModification(venue?.allowModification);
    setModificationFeeType(venue?.modificationFeeType || FeeType.PERCENT);
    setModificationFee(venue?.modificationFee.toString() || "0.00");
    setModificationCutOff(venue?.modificationCutOff);
  }, [venue]);
  const { isMobile } = useViewport();
  const [showCancellationRules, setShowCancellationRules] = useState(true);
  const [allowCancellation, setAllowCancellation] = useState(venue?.allowCancellation);
  const [cancellationFeeType, setCancellationFeeType] = useState(venue?.cancellationFeeType || FeeType.PERCENT);
  const [cancellationFee, setCancellationFee] = useState(venue?.cancellationFee.toString() || "0.00");
  const [cancellationCutOff, setCancellationCutOff] = useState(venue?.cancellationCutOff);
  const [allowModification, setAllowModification] = useState(venue?.allowModification);
  const [modificationFeeType, setModificationFeeType] = useState(venue?.modificationFeeType || FeeType.PERCENT);
  const [modificationFee, setModificationFee] = useState(venue?.modificationFee.toString() || "0.00");
  const [modificationCutOff, setModificationCutOff] = useState(venue?.modificationCutOff);

  const [cancellationFeeError, setCancellationFeeError] = useState("");

  const getPatch = () => {
    const newCancellationFee = cancellationFee ? parseFloat(cancellationFee) : 0;
    const newModificationFee = modificationFee ? parseFloat(modificationFee) : 0;
    return {
      allowCancellation: !!allowCancellation,
      cancellationFeeType,
      cancellationFee: newCancellationFee,
      cancellationCutOff: cancellationCutOff || 0,
      allowModification: !!allowModification,
      modificationFeeType,
      modificationFee: newModificationFee,
      modificationCutOff: modificationCutOff || 0,
    };
  }

  const onUpdateCancelAndModifySettings = (e: React.FormEvent) => {
    e.preventDefault();
    setCancellationFeeError("");
    if(cancellationFeeType === FeeType.PERCENT && parseFloat(cancellationFee) > 100) {
      setCancellationFeeError("fee cannot be more than 100%");
      setShowCancellationRules(true);
      return;
    }
    if (venue) {
      const patch = getPatch();
      updateVenue({ ...venue, ...patch });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-cancel-and-modify-settings"
    >
      <div className="title h4">
        Cancel and Modify Rules
        <CancelButton onClick={() => onClose()} />
      </div>
      <div className="rules-toggle">
        <div
          className={`rules-toggle-label body2 ${showCancellationRules ? "active" : ""}`}
          onClick={() => setShowCancellationRules(true)}
        >
          Cancel Rules
        </div>
        <div
          className={`rules-toggle-label body2 ${!showCancellationRules ? "active" : ""
            }`}
          onClick={() => setShowCancellationRules(false)}
        >
          Modify Rules
        </div>
      </div>
      <form className="cancel-and-modify-setting" onSubmit={onUpdateCancelAndModifySettings}>
        {showCancellationRules && (
          <>
            <div className="cancel-and-modify-settings-fields">
              <div className="row">
                <Toggle
                  label="Allow Cancellations"
                  className="toggle"
                  checked={allowCancellation}
                  onChange={(_: any, value?: boolean) => setAllowCancellation(value)}
                  inlineLabel
                  disabled={readonly}
                />
              </div>
              <div className={`row ${isMobile ? "mobile" : ""}`}>
                <FormSelectField
                  label="Fee Type"
                  options={feeTypeOptions}
                  selectedKey={cancellationFeeType || ''}
                  onChange={(_: any, option) => {
                    setCancellationFeeType(option?.key as FeeType)
                  }}
                  disabled={!allowCancellation || readonly}
                />
                {!isMobile && (<div className="delimiter" />)}
                <FormTextField
                  label="Cancellation fee"
                  type="number"
                  value={cancellationFee}
                  onChange={(_: any, text?: string) => setCancellationFee(text || "0.00")}
                  autoComplete="off"
                  errorMessage={cancellationFeeError}
                  disabled={!allowCancellation || readonly}
                />
                {!isMobile && (<div className="delimiter" />)}
                <FormTextField
                  label="Cut off time (hours)"
                  type="number"
                  value={cancellationCutOff?.toString()}
                  onChange={(_: any, text?: string) => setCancellationCutOff(Number(text))}
                  autoComplete="off"
                  disabled={!allowCancellation || readonly}
                />
              </div>
            </div>
          </>
        )}
        {!showCancellationRules && (
          <>
            <div className="row">
              <Toggle
                label="Allow Modifications"
                className="toggle"
                checked={allowModification}
                onChange={(_: any, value?: boolean) => setAllowModification(value)}
                inlineLabel
                disabled={readonly}
              />
            </div>
            <div className={`row ${isMobile ? "mobile" : ""}`}>
              <FormSelectField
                label="Fee Type"
                options={feeTypeOptions}
                selectedKey={modificationFeeType || ''}
                onChange={(_: any, option) => {
                  setModificationFeeType(option?.key as FeeType)
                }}
                disabled={!allowModification || readonly}
              />
              {!isMobile && (<div className="delimiter" />)}
              <FormTextField
                label="Modification fee"
                type="number"
                value={modificationFee?.toString()}
                onChange={(_: any, text?: string) => setModificationFee(text || "0.00")}
                autoComplete="off"
                disabled={!allowModification || readonly}
              />
              {!isMobile && (<div className="delimiter" />)}
              <FormTextField
                label="Cut off time (hours)"
                type="number"
                value={modificationCutOff?.toString()}
                onChange={(_: any, text?: string) => setModificationCutOff(Number(text))}
                autoComplete="off"
                disabled={!allowModification || readonly}
              />
            </div>
          </>
        )}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditCancelAndModifySettings);
