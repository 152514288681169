import { ReportDto, ReportV2Dto } from "../../../../server/src/dto/report.dto";
import { TimeInterval } from "../../../../server/src/dto/reportQuery.dto";
import { FilterReservationParameters } from "./reservation";

export * from "../../../../server/src/dto/report.dto";
export * from "../../../../server/src/dto/reportQuery.dto";

export interface ReportState {
  isRequestInProgress: boolean;
  report?: ReportDto;
  error?: string;
  venueIdForFilterReport?: string;
  timeInterval: TimeInterval;
  startDate?: string;
  endDate?: string;
  filterParameters: FilterReservationParameters[];
  reportV2?: ReportV2Dto;
  venueIdsForFilterReportV2: string[];
}
