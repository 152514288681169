import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Fabric, mergeStyles, IDropdownOption } from "office-ui-fabric-react";
import {
  setTimeIntervalAction,
  setTenantFilterAction,
  setTenantPlanFilterAction,
  setCurrencyFilterAction,
} from "../../actions/super-admin-reports-actions";
import { getTenantsAction } from "../../actions/tenants-actions";
import {
  selectTenantIdFilterReport,
  selectSuperAdminTimeInterval,
  selectSuperAdminStartDate,
  selectSuperAdminEndDate,
  selectSuperAdminReport,
  selectTenantPlanFilterReport,
  selectSuperAdminCurrencyFilter,
} from "../../reducers/superAdminReports";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { connect, ConnectedProps } from "react-redux";
import {
  State,
  TimeInterval,
  TenantSettingsDto,
  TenantPlan,
  CurrencyType,
} from "../../store/types";
import { selectTenants } from "../../reducers/tenants";
import { DATE_FORMAT, DATE_FORMAT_US } from "../../constants/timedate";
import {
  formatLabel,
  formatIntegerNumber,
  formatPrice,
} from "../../utils/formats";
import ReservationsReportIcon from "../../assets/shoping-cart-icon.svgr";
import RevenueChargedIcon from "../../assets/cube-icon.svgr";
import RevenueBookedIcon from "../../assets/cards-icon.svgr";
import LocationIcon from "../../assets/locations-icon.svgr";
import TenantIcon from "../../assets/tenants-icon.svgr";
import SelectDateRange from "../common/SelectDateRange/SelectDateRange";
import FormSelectField from "../common/FormSelectField";
import { useViewport } from "../../hooks/responsive";
import "./execDashboard.scss";

const wrapperClassName = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
});

const currencyFilterList = Object.values(CurrencyType).map((item) => ({
  key: item,
  text: item,
}));

const mapDispatchToProps = {
  setTimeFilterInterval: setTimeIntervalAction,
  setTenantFilter: setTenantFilterAction,
  setTenantPlanFilter: setTenantPlanFilterAction,
  setCurrencyFilter: setCurrencyFilterAction,
  getTenants: getTenantsAction,
};
const mapStateToProps = (state: State) => ({
  superAdminReport: selectSuperAdminReport(state),
  tenantId: selectTenantIdFilterReport(state),
  timeInterval: selectSuperAdminTimeInterval(state),
  startDate: selectSuperAdminStartDate(state),
  endDate: selectSuperAdminEndDate(state),
  tenants: selectTenants(state),
  tenantPlan: selectTenantPlanFilterReport(state),
  currency: selectSuperAdminCurrencyFilter(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ExecDashboard = ({
  superAdminReport,
  tenantId,
  timeInterval,
  startDate,
  endDate,
  tenants,
  tenantPlan,
  currency,
  uiConfig,
  setTimeFilterInterval,
  setTenantFilter,
  setTenantPlanFilter,
  setCurrencyFilter,
  getTenants,
}: Props) => {
  const { isMiddleScreen } = useViewport();
  useEffect(() => {
    if (tenants && !tenants.length) {
      getTenants("");
    }
    setTenantFilter(tenantId);
  }, []);

  const [showRangePicker, setShowRangePicker] = useState(false);
  const [tenantPlanArray, setTenantPlanArray] = useState(
    tenantPlan ? tenantPlan.split(",") : []
  );

  // it's necessary that when clicking on the dropdown for changing the filter of the time interval, the modal window is not shown each time
  const dropdownDismissTimer = useRef<any>(null);
  const reportCurrency = currency || CurrencyType.USD;

  useEffect(() => {
    return () => {
      if (dropdownDismissTimer.current) {
        clearTimeout(dropdownDismissTimer.current);
      }
    };
  }, []);

  const onDismissed = () => {
    dropdownDismissTimer.current = setTimeout(() => {
      if (timeInterval === TimeInterval.CUSTOM && !showRangePicker)
        setShowRangePicker(true);
    }, 200);
  };

  const composeTenantsForDropDown = (
    tenants: TenantSettingsDto[]
  ): IDropdownOption[] => {
    const validTenants = tenants.filter(function (tenant) {
      return tenantPlanArray.includes(tenant.plan);
    });
    return [
      { key: "all", text: "All tenants" },
      ...validTenants.map((item) => ({ key: item.id, text: item.name })),
    ];
  };
  const tenantsForDropdown = composeTenantsForDropDown(tenants);
  const custom =
    startDate && endDate
      ? `${dayjs(startDate).format(
        uiConfig?.dateFormat || DATE_FORMAT_US
      )}-${dayjs(endDate).format(uiConfig?.dateFormat || DATE_FORMAT_US)}`
      : "custom";
  const timeIntervalOptions = [
    { key: TimeInterval.TODAY, text: "Today" },
    { key: TimeInterval.THIS_MONTH, text: "This month" },
    { key: TimeInterval.LAST_MONTH, text: "Last month" },
    { key: TimeInterval.YEAR_TO_DATE, text: "Year to date" },
    { key: TimeInterval.ALL_TIME, text: "All" },
    { key: TimeInterval.CUSTOM, text: custom },
  ];
  let tenantsPlanForDropdown = Object.values(TenantPlan)
    .filter((item) => item !== TenantPlan.spotonPartner)
    .map((item) => ({ key: item, text: formatLabel(item) }));
  const onTenantChange = (event: any, option: IDropdownOption | undefined) => {
    if (!option || !option.key) return null;
    const tenantId =
      String(option.key) === "all" ? undefined : String(option.key);
    setTenantFilter(tenantId);
  };

  tenantsPlanForDropdown = tenantsPlanForDropdown.filter((item: { key: string }) => item.key !== 'Steltronic' && item.key !== 'GoTab' && item.key !== 'Bowling Marketing')

  console.log(tenantsForDropdown)
  const onIntervalChange = (
    event: any,
    option: IDropdownOption | undefined
  ) => {
    if (!option || !option.key) return null;
    if (dropdownDismissTimer.current) {
      clearTimeout(dropdownDismissTimer.current);
    }
    if (option.key === TimeInterval.CUSTOM) {
      setShowRangePicker(true);
      return;
    }
    setTimeFilterInterval({
      timeInterval: option.key as TimeInterval,
      startDate: "",
      endDate: "",
    });
  };
  const onSetRange = (sDate?: Date | null, eDate?: Date | null) => {
    if (sDate && eDate) {
      setTimeFilterInterval({
        timeInterval: TimeInterval.CUSTOM,
        startDate: dayjs(sDate).format(DATE_FORMAT),
        endDate: dayjs(eDate).format(DATE_FORMAT),
      });
    }
    setShowRangePicker(false);
  };

  const onTenantPlanChange = (
    event: any,
    option: IDropdownOption | undefined
  ) => {
    if (!option || !option.key) return null;
    let newTenantPlan = tenantPlanArray;
    if (option.selected) {
      if (tenantPlanArray) {
        tenantPlanArray?.push(String(option.key));
        newTenantPlan = tenantPlanArray;
      } else {
        newTenantPlan = [String(option.key)];
      }
    } else {
      newTenantPlan = tenantPlanArray?.filter(
        (item) => item !== String(option.key)
      );
      if (newTenantPlan && !newTenantPlan[0]) {
        newTenantPlan = [];
      }
    }
    setTenantPlanFilter(newTenantPlan?.join());
    setTenantPlanArray(newTenantPlan);
  };

  const onCurrencyChange = (
    event: any,
    option: IDropdownOption | undefined
  ) => {
    if (!option || !option.key) return null;
    let currencyFilter = CurrencyType.USD;
    switch (option.key) {
      case CurrencyType.CAD:
        currencyFilter = CurrencyType.CAD;
        break;
      case CurrencyType.NZD:
        currencyFilter = CurrencyType.NZD;
        break;
      case CurrencyType.GBP:
        currencyFilter = CurrencyType.GBP;
        break;
      case CurrencyType.EUR:
        currencyFilter = CurrencyType.EUR;
        break;
      case CurrencyType.AUD:
        currencyFilter = CurrencyType.AUD;
        break;
      default:
        currencyFilter = CurrencyType.USD;
    }
    setCurrencyFilter(currencyFilter);
  };

  return (
    <div className="exec-dashboard">
      <div className="title h4">
        <div>Exec Dashboard</div>
      </div>
      <div className="inputs-block">
        <Fabric
          className={`${wrapperClassName} ${isMiddleScreen ? "mobile" : ""}`}
        >
          <div className={`filter-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="subtitle2">Filter by tenant</div>
            <FormSelectField
              options={tenantsForDropdown}
              onChange={onTenantChange}
              defaultSelectedKey={tenantId || "all"}
            />
          </div>
          <div className={`filter-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="subtitle2">Filter by date</div>
            <FormSelectField
              options={timeIntervalOptions}
              onChange={onIntervalChange}
              defaultSelectedKey={timeInterval}
              onDismiss={onDismissed}
            />
          </div>
          <div className={`filter-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="subtitle2">Filter by tenant plan</div>
            <FormSelectField
              options={tenantsPlanForDropdown}
              onChange={onTenantPlanChange}
              multiSelect
              defaultSelectedKeys={
                tenantPlanArray ||
                tenantsPlanForDropdown.map((item) => item.key)
              }
            />
          </div>
          <div className={`filter-block ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="subtitle2">Filter by currency</div>
            <FormSelectField
              options={currencyFilterList}
              onChange={onCurrencyChange}
              defaultSelectedKey={currency || CurrencyType.USD}
            />
          </div>
        </Fabric>
      </div>
      <div className={`exec-dashboard-list ${isMiddleScreen ? "mobile" : ""}`}>
        <div
          className={`exec-dashboard-block ${isMiddleScreen ? "mobile" : ""}`}
        >
          <div className="exec-dashboard-info">
            <div className="icon-container">
              <RevenueChargedIcon className="exec-dashboard-icon" />
            </div>
            <div className="exec-dashboard-text">
              <div className="label overline">Total GPV</div>
              <div className="value h6">
                {superAdminReport
                  ? formatPrice(
                    superAdminReport.grossPaymentVolume,
                    reportCurrency
                  )
                  : formatPrice(0, reportCurrency)}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`exec-dashboard-block ${isMiddleScreen ? "mobile" : ""}`}
        >
          <div className="exec-dashboard-info">
            <div className="icon-container">
              <RevenueBookedIcon className="exec-dashboard-icon" />
            </div>
            <div className="exec-dashboard-text">
              <div className="label overline">Total GBV</div>
              <div className="value h6">
                {superAdminReport
                  ? formatPrice(
                    superAdminReport.grossBookingVolume,
                    reportCurrency
                  )
                  : formatPrice(0, reportCurrency)}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`exec-dashboard-block ${isMiddleScreen ? "mobile" : ""}`}
        >
          <div className="exec-dashboard-info">
            <div className="icon-container">
              <LocationIcon className="exec-dashboard-icon" />
            </div>
            <div className="exec-dashboard-text">
              <div className="label overline">Total number of sites</div>
              <div className="value h6">
                {superAdminReport
                  ? formatIntegerNumber(superAdminReport?.totalNumberOfVenues)
                  : 0}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`exec-dashboard-block ${isMiddleScreen ? "mobile" : ""}`}
        >
          <div className="exec-dashboard-info">
            <div className="icon-container">
              <ReservationsReportIcon className="exec-dashboard-icon" />
            </div>
            <div className="exec-dashboard-text">
              <div className="label overline">Number of reservations</div>
              <div className="value h6">
                {superAdminReport
                  ? formatIntegerNumber(
                    superAdminReport?.totalNumberOfReservations
                  )
                  : 0}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`exec-dashboard-block ${isMiddleScreen ? "mobile" : ""}`}
        >
          <div className="exec-dashboard-info">
            <div className="icon-container">
              <TenantIcon className="exec-dashboard-icon" />
            </div>
            <div className="exec-dashboard-text">
              <div className="label overline">Number of guests</div>
              <div className="value h6">
                {superAdminReport
                  ? formatIntegerNumber(superAdminReport?.totalNumberOfGuests)
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRangePicker && (
        <SelectDateRange onClose={onSetRange} uiConfig={uiConfig} />
      )}
    </div>
  );
};

export default connector(ExecDashboard);
