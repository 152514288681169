import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
  checkStripeAction,
  connectStripeAction,
  disConnectStripeAction,
  getSettingsAction,
  updateSettingsAction,
} from "../../actions/settings-actions";
import {
  selectIsSettingsRequestInProgress,
  selectSettingsState,
} from "../../reducers/settings";
import { State } from "../../store/types";
import ConnectStripeComponent from "./ConnectStripeComponent";
import StripeFields from "./StripeFields";
import "./paymentSetUp.scss";
import { selectIsFromSuperAdmin } from "../../reducers/auth-reducer";

const mapDispatchToProps = {
  getSettings: getSettingsAction,
  updateSettings: updateSettingsAction,
  connectStripe: connectStripeAction,
  disConnectStripe: disConnectStripeAction,
  checkStripe: checkStripeAction,
};

const mapStateToProps = (state: State) => ({
  isRequestInProgress: selectIsSettingsRequestInProgress(state),
  settings: selectSettingsState(state),
  isFromSuperAdmin: selectIsFromSuperAdmin(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const PaymentSetUp = ({
  isRequestInProgress,
  getSettings,
  updateSettings,
  connectStripe,
  disConnectStripe,
  settings,
  checkStripe,
  isFromSuperAdmin,
}: Props) => {
  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const updateStripeAccount = (stripeAccountId: string) => {
    updateSettings({ 
        stripeAccountId,
        stripeConnectionStatus: stripeAccountId?'pending':'',
     });
  };

  return (
    <>
      {isRequestInProgress && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className="plans">
        <div className="plan-list-container">
          <div className="h6 plan-heading">Payments Set Up</div>
        </div>
        <div className="plans-list cols-2 memberships-connect">
          <ConnectStripeComponent
            onConnect={connectStripe}
            onDisconnect={disConnectStripe}
            status={settings?.stripeConnectionStatus}
            disabledConnect={
              settings?.stripeConnectionStatus === "pending" ||
              settings?.stripeConnectionStatus === "ok"
            }
            disabledDisconnect={
              !(
                settings?.stripeConnectionStatus === "pending" ||
                settings?.stripeConnectionStatus === "ok"
              )
            }
            isRequestInProgress={isRequestInProgress}
          />
          <StripeFields
            onCheckStripe={checkStripe}
            stripeAccountId={settings?.stripeAccountId}
            currency={settings?.currency}
            isFromSuperAdmin={isFromSuperAdmin}
            updateStripeAccount={updateStripeAccount}
            disabledCheck={
              !(
                settings?.stripeConnectionStatus === "pending" ||
                settings?.stripeConnectionStatus === "ok"
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default connector(PaymentSetUp);
