import { InventoryMethodTypes } from "../store/types";

export const inventoryTypeOptions = [
    {
        key: InventoryMethodTypes.byLanes,
        text: 'Custom Availability',
    },
    {
        key: InventoryMethodTypes.byGuests,
        text: 'Based on Guest Count',
    },
    {
        key: InventoryMethodTypes.byLanesAndGuests,
        text: 'Custom Availability + Guest Count',
    },
    {
        key: InventoryMethodTypes.byResources,
        text: 'Based on Resource Manager',
    },
]