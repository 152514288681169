import React from "react";
import { TextField, ITextFieldStyles } from "office-ui-fabric-react";

export const inputFieldStyle: ITextFieldStyles = {
  root: {
    width: "100%",
    padding: "20px 0 0"
  },
  fieldGroup: {
    height: "50px",
    background: "#f9f9f9",
    paddingLeft: 10,
    color: "#7a7a7a",
    border: "0.5px solid #bfbfbf",
    borderRadius: 2,
    outline: 0,
  },
  errorMessage: {
    position: "absolute"
  },
  prefix: {},
  suffix: {},
  field: {
    fontSize: 16,
  },
  icon: {},
  description: {},
  wrapper: {},
  subComponentStyles: {
    label: {
      root: {
        padding: "8px 0",
        fontSize: 16,
        color: "#2A2A2A"
      }
    }
  }
};

export const InputField = ({ ...other }) => {
  return <TextField styles={inputFieldStyle} {...other} />;
};
