import { Icon } from "office-ui-fabric-react";
import React, { useState } from "react";
import { membershipUI } from "../../constants/webConfigUI";
import { MembershipPlanDto } from "../../store/types";
import ActionsButton from "../common/ActionsButton";
import EditPlan from "./Edit/EditPlan";
import MembershipsEmptyPlaceholder from "./MembershipsEmptyPlaceholder";
import PaymentSetUp from "./PaymentSetUp";
import Plans from "./Plans";
import "./membership.scss";


const Membership = () => {
  const [page, setPage] = React.useState(membershipUI.Plans);
  const [plan, setPlan] = useState<MembershipPlanDto | undefined>(undefined)

  const [isCreating, setIsCreating] = React.useState(false);
  const content = () => {
    switch (page) {
      case membershipUI.Plans:
        return <><Plans onPlanSelect={(plan) => setPlan(plan)} addPlan={addPlan} /> <PaymentSetUp /></>;
      default:
        return <MembershipsEmptyPlaceholder onAddPlan={addPlan} />;
    }
  };

  const addPlan = () => {
    setIsCreating(true);
  };

  return (
    <>
      <div className="membership-container">
        <div className="membership">
          <div className="membership-information-header">
            <div className="membership-title h4">Memberships</div>
            <div className="actions-container">
              <ActionsButton
                onClick={addPlan}
              >
                <Icon iconName="Add" className="add-icon" />
                <div className="add-button-text">
                  Add Plan
                </div>
              </ActionsButton>
            </div>
          </div>
          <div className="membership-information-container">
            {content()}
          </div>
        </div>
      </div>
      {isCreating && <EditPlan onClose={() => setIsCreating(false)} plan={plan} />}

    </>
  );
};

export default Membership;
