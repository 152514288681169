import { TenantUserDto } from './user.dto'
import { TenantPlan } from '../entities/enums'
import { PartnerDto } from './partner.dto';
export class TenantSettingsDto {
  id: string;
  name: string;
  email: string;
  domain: string;
  plan: TenantPlan;
  isHideDemoMessage: boolean;
  isOnboarding: boolean;
  mixpanelToken: string;
  users: TenantUserDto[];
  venueCount?: number;
  stripeCustomerId?: string;
  partner?: PartnerDto;
  hasMemberships: boolean;
  membershipFee: number;
  bookingFee: number;
}
