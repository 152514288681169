import React, { useEffect, useRef, useState } from 'react';
import { Icon, IconButton, IDropdownOption, Modal, Spinner, SpinnerSize } from "office-ui-fabric-react";
import { createQuestionAction, updateQuestionAction } from '../../../actions/question-action';
import { selectReadonly, selectWebConfigUI } from '../../../reducers/auth-reducer';
import { selectCurrentQuestion, selectError, selectIsLoadingQuestion } from '../../../reducers/questions';
import { connect, ConnectedProps } from 'react-redux';
import { CreateQuestionSettingDto, QuestionType, State } from '../../../store/types';
import CancelButton from '../../../../../common/components/CancelButton';
import ColorButton from '../../../../../common/components/ColorButton';
import FormTextField from '../../../../../common/components/FormTextField';
import LinkButton from '../../../../../common/components/LinkButton';
import FormSelectField from '../../common/FormSelectField';
import './editQuestion.scss';
import { useViewport } from '../../../hooks/responsive';
import Panel from '../../common/Panel';
import TrashIcon from "../../../assets/trash-icon.svgr";

interface OwnProps {
    onClose: () => void;
    selectedQuestion?: CreateQuestionSettingDto
}

const mapDispatchToProps = {
    createQuestion: createQuestionAction,
    updateQuestion: updateQuestionAction,
};

const mapStateToProps = (state: State) => ({
    readonly: selectReadonly(state),
    currentQuestion: selectCurrentQuestion(state),
    isLoadingQuestion: selectIsLoadingQuestion(state),
    error: selectError(state),
    webConfigUIPage: selectWebConfigUI(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditQuestion = ({
    readonly,
    onClose,
    currentQuestion,
    isLoadingQuestion,
    error,
    webConfigUIPage,
    createQuestion,
    updateQuestion,
    selectedQuestion
}: Props) => {
    const saving = useRef(false);
    const { isMobile } = useViewport();

    const [values, setValues] = useState<CreateQuestionSettingDto | undefined>(selectedQuestion);
    const [displayNameError, setDisplayNameError] = useState<string>("");
    const [optionsError, setOptionsError] = useState<string>("");
    const [priorityError, setPriorityError] = useState<string>("");

    const isOptionsDisplaying = values?.questionType !== QuestionType.string && values?.questionType !== QuestionType.date && values?.questionType !== QuestionType.dateMonth;
    useEffect(() => {
        if (!isLoadingQuestion && !error && saving.current) {
            saving.current = false;
            onClose();
        }
    }, [isLoadingQuestion, error, onClose]);

    useEffect(() => {
        if (selectedQuestion) {
            setValues(selectedQuestion);
        }
    }, [selectedQuestion]);

    const handleValidation = () => {
        const displayName = values?.displayName ? "" : "Display name is required";
        const options = values?.questionType !== QuestionType.string && !values?.options ? "Options are required" : "";
        const priority = values?.priority !== undefined && (isNaN(values.priority) || values.priority < 0)
            ? "Priority must be a non-negative number"
            : "";

        setDisplayNameError(displayName);
        setOptionsError(options);
        setPriorityError(priority);
    };

    const onValueChange = (key: keyof CreateQuestionSettingDto, value: any) => {
        setValues({
            ...values,
            [key]: value
        } as CreateQuestionSettingDto);
    };

    const getPatch = (): CreateQuestionSettingDto | undefined => {
        if (!values) return;

        const patch = {
            internalName: values.internalName,
            priority: values.priority || 1,
            displayName: values.displayName,
            options: values.questionType === QuestionType.string ? "" : values.options,
            questionType: values.questionType,
            tenantId: values.tenantId,
        };

        return patch;
    };

    const _onUpdate = (e: React.FormEvent) => {
        e.preventDefault();

        handleValidation();
        if (displayNameError || optionsError || priorityError || !values) {
            return;
        }

        saving.current = true;
        const patch = getPatch();
        if (selectedQuestion) {
            updateQuestion({ ...selectedQuestion, ...patch });
        } else {
            if (patch) createQuestion(patch);
        }
    };
    const onAddOption = () => {
        if (values) {
            setValues({
                ...values,
                options: values.options ? values.options + ',' : '',
            });
        }
    };

    const onRemoveOption = (index: number) => {
        if (values) {
            const optionsArray = values.options.split(',');
            optionsArray.splice(index, 1);
            setValues({
                ...values,
                options: optionsArray.join(','),
            });
        }
    };

    return (
        <>
            {isLoadingQuestion && (
                <div className="loading">
                    <Spinner size={SpinnerSize.large} />
                </div>
            )}
            <Modal
                isOpen={true}
                onDismiss={onClose}
                isBlocking={false}
                containerClassName="edit-question"
            >
                <div className="title h4">
                    {selectedQuestion ? 'Update' : 'Create'} Question
                    <CancelButton onClick={() => onClose()} />
                </div>
                <form className="question-form" onSubmit={_onUpdate}>
                    <div className="edit-question-fields">
                        <div className="row">
                            <FormTextField
                                label="Display Name"
                                value={values?.displayName}
                                onChange={(_, text) => onValueChange('displayName', text)}
                                autoComplete="off"
                                disabled={readonly}
                                required
                                errorMessage={displayNameError}
                                className='long-field'
                            />
                            {!isMobile && (<div className="delimiter" />)}
                            <FormTextField
                                label="Question Priority"
                                className="short-field"
                                value={values?.priority ? String(values?.priority) : ""}
                                onChange={(_, text) => onValueChange('priority', parseFloat(text || "") || 0)}
                                autoComplete="off"
                                disabled={readonly}
                                errorMessage={priorityError}
                            />
                        </div>
                        <div className="row">
                            <FormTextField
                                label="Internal Name"
                                value={values?.internalName}
                                onChange={(_, text) => onValueChange('internalName', text)}
                                autoComplete="off"
                                disabled={readonly}
                                required
                            />
                        </div>
                        <div className="row">
                            <FormSelectField
                                label="Question Type"
                                options={[
                                    { key: QuestionType.string, text: "Single line text" },
                                    { key: QuestionType.dropdown, text: "Dropdown" },
                                    { key: QuestionType.multiSelect, text: "Multiselect" },
                                    { key: QuestionType.date, text: "Date (MM/DD/YY)" },
                                    { key: QuestionType.dateMonth, text: "Date (MM/DD)" },
                                ]}
                                selectedKey={values?.questionType as QuestionType}
                                onChange={(_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) =>
                                    onValueChange("questionType", item?.key as QuestionType)
                                }
                                disabled={readonly}
                                required
                            />
                        </div>
                        {isOptionsDisplaying && values?.questionType && (
                            <div className="row column">
                                <div className="ms-Label">Options</div>
                                {(values?.options || '').split(',').map((item, index) => (
                                    <div className="row occasion-fields" key={index}>
                                        <FormTextField
                                            className="integrations-field"
                                            value={item}
                                            onChange={(_, value?: string) =>
                                                onValueChange('options', (values?.options || '').split(',').map((opt, i) => i === index ? value : opt).join(','))
                                            }
                                            autoComplete="off"
                                        />
                                        <IconButton
                                            className="delete-button"
                                            ariaLabel="Remove option"
                                            onClick={() => onRemoveOption(index)}
                                        >
                                            <TrashIcon />
                                        </IconButton>
                                    </div>
                                ))}
                                <div className="row">
                                    <LinkButton className="add-button" onClick={onAddOption}>
                                        <Icon iconName="Add" className="add" />
                                        Add option
                                    </LinkButton>
                                </div>
                            </div>
                        )}
                        <div className="buttons-container">
                            <LinkButton onClick={onClose}>Cancel</LinkButton>
                            <ColorButton
                                type="submit"
                                disabled={readonly || !!displayNameError || !!priorityError || (values?.questionType !== QuestionType.string && !!optionsError)}
                            >
                                Save Changes
                            </ColorButton>
                        </div>
                    </div>
                </form>
            </Modal >
        </>
    );
};

export default connector(EditQuestion);
