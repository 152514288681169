import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    DefaultButton,
    Toggle,
} from "office-ui-fabric-react";
import { updateVenueAction } from "../../../actions/venue-actions";
import { openTenantUrlAction } from "../../../actions/tenants-actions";
import { selectCurrentSchedule, selectIsLoadingVenue, selectVenue } from "../../../reducers/venues";
import { State } from "../../../store/types";
import Panel from '../../common/Panel'
import CurrentSchedule from "../../common/CurrentSchedule/CurrentSchedule";
import OpenIcon from '../../../assets/open-in-new.svgr'
import CalendarIcon from '../../../assets/calendar-icon.svgr'
import "./venueMainActions.scss";

const mapDispatchToProps = {
    updateVenue: updateVenueAction,
    openTenantUrl: openTenantUrlAction,
};

const mapStateToProps = (state: State) => ({
    venue: selectVenue(state),
    isLoadingVenue: selectIsLoadingVenue(state),
    currentSchedule: selectCurrentSchedule(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>

const VenueIntegrations = ({
    venue,
    isLoadingVenue,
    currentSchedule,
    updateVenue,
    openTenantUrl,
}: Props) => {
    const [isOpenCurrentSchedule, setIsOpenCurrentSchedule] = useState(false);
    const onOpenCloseVenue = () => {
        if (venue) {
            updateVenue({ ...venue, closed: !venue.closed })
        }
    }
    const onVenueComingSoonChange = () => {
        if (venue) {
            updateVenue({ ...venue, isComingSoon: !venue.isComingSoon })
        }
    }
    const onVenueEnableCheckInChange = () => {
        if (venue) {
            updateVenue({ ...venue, enableCheckIn: !venue.enableCheckIn })
        }
    }
    const onVenueEnableDiscountChange = () => {
        if (venue) {
            updateVenue({ ...venue, useDiscount: !venue.useDiscount })
        }
    }
    const onOpenMainApp = () => {
        if (venue) {
            openTenantUrl(venue)
        }
    }
    return (<>
        <Panel className='venue-main-actions' title={(<div className="h6">Venue Info</div>)} >
            <>
                <div className="venue-toggles-container">
                    <div className="venue-toggle-status body2">
                        <Toggle
                            checked={!venue?.closed}
                            onChange={onOpenCloseVenue}
                        />
                        <div className="label">{!!venue?.closed ? 'Venue is Closed' : 'Venue is Open'}</div>
                    </div>
                    <div className="venue-toggle-status body2">
                        <Toggle
                            checked={venue?.isComingSoon}
                            onChange={onVenueComingSoonChange}
                        />
                        <div className="label">Venue Coming Soon</div>
                    </div>
                    <div className="venue-toggle-status body2">
                        <Toggle
                            checked={venue?.enableCheckIn}
                            onChange={onVenueEnableCheckInChange}
                        />
                        <div className="label">Enable Check In</div>
                    </div>
                    <div className="venue-toggle-status body2">
                        <Toggle
                            checked={venue?.useDiscount}
                            onChange={onVenueEnableDiscountChange}
                        />
                        <div className="label">Enable Discounts</div>
                    </div>
                </div>
                <div className="splitter"></div>
                <DefaultButton
                    className="actions-button"
                    onClick={() => setIsOpenCurrentSchedule(true)}
                >
                    <CalendarIcon />
                    <div className="actions-text">View Calendar</div>
                </DefaultButton>
                <div className="splitter"></div>
                <DefaultButton
                    className="actions-button bottom-button"
                    onClick={onOpenMainApp}
                >
                    <OpenIcon />
                    <div className="actions-text">View Reservation Page</div>
                </DefaultButton>
            </>
        </Panel>
        {isOpenCurrentSchedule && <CurrentSchedule
            onClose={() => setIsOpenCurrentSchedule(false)}
            isLoadingVenue={isLoadingVenue}
            currentSchedule={currentSchedule}
        />}</>
    );
};

export default connector(VenueIntegrations);