import React, { useState, useEffect } from 'react';
import './index.scss'
import FormSelectField from "../../common/FormSelectField";
import { Icon, IconButton, Spinner, SpinnerSize, } from "office-ui-fabric-react";
import ColorButton from "../../../../../common/components/ColorButton";
import { selectCategories } from "../../../reducers/settings";
import { State } from "../../../store/types";
import { connect, ConnectedProps } from "react-redux";
import { getSettingsAction } from "../../../actions/settings-actions";
import { selectCurrentAddon, selectIsLoadingAddon } from "../../../reducers/addons";
import Panel from "../../common/Panel";
import LinkButton from '../../../../../common/components/LinkButton';
import { updateAddonAction } from '../../../actions/addon-action';
import { RevenueCategoriesMapping } from '../../VenueDetails/Packages/PackageDetails/PackageDetails';
import FormTextField from '../../../../../common/components/FormTextField';
import { selectReadonly } from "../../../reducers/auth-reducer";
import { useViewport } from "../../../hooks/responsive";
import TrashIcon from "../../../assets/trash-icon.svgr";

const mapStateToProps = (state: State) => ({
  revenueCategories: selectCategories(state),
  currentAddon: selectCurrentAddon(state),
  readonly: selectReadonly(state),
  isLoadingAddon: selectIsLoadingAddon(state)
})

const mapDispatchToProps = {
  getSettings: getSettingsAction,
  updateAddon: updateAddonAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>

const AddonAdvancedDetails = (
  {
    revenueCategories,
    readonly,
    currentAddon,
    isLoadingAddon,
    updateAddon,
  }: Props
) => {
  const { isMiddleScreen } = useViewport();

  const expendRevenueCategoriesMapping = (fields: string): RevenueCategoriesMapping[] => {
    return fields.split(';').map((item, index) => ({ id: index, category: item.split(':')[0] || '', value: item.split(':')[1] || '' }))
  }
  const foldRevenueCategoriesMapping = (fields: RevenueCategoriesMapping[]) => {
    return fields.map(item => `${item.category}:${item.value || 0}`).join(';')
  }
  const onUpdateRevenueCategoriesMapping = (field: RevenueCategoriesMapping) => {
    setErrorRevenueCategoriesMapping('');
    const newValue = newRevenueCategoriesMapping.map(item => item.id === field.id ? field : item)
    setNewRevenueCategoriesMapping(newValue)
  }
  const onAddRevenueCategoriesMapping = () => {
    setErrorRevenueCategoriesMapping('');
    setNewRevenueCategoriesMapping([...newRevenueCategoriesMapping, { id: newRevenueCategoriesMapping.length, category: '', value: '' }])
  }
  const onRemoveRevenueCategoriesMapping = (field: RevenueCategoriesMapping) => {
    setErrorRevenueCategoriesMapping('');
    const newValue = newRevenueCategoriesMapping.filter(item => item.id !== field.id).map((item, index) => ({ ...item, id: index }))
    setNewRevenueCategoriesMapping(newValue)
  }

  useEffect(() => {
    setNewRevenueCategoriesMapping(expendRevenueCategoriesMapping(currentAddon?.attributions || ''))
  }, [currentAddon])

  const splitCategories = revenueCategories.split(';').filter(string => !!string);
  const revenueCategoriesOptions = splitCategories.map(category => {
    const key = category.split(':')[0];
    return {
      key,
      text: key,
    }
  });

  const [newRevenueCategoriesMapping, setNewRevenueCategoriesMapping] = useState<RevenueCategoriesMapping[]>(expendRevenueCategoriesMapping(currentAddon?.attributions || ''));
  const [errorRevenueCategoriesMapping, setErrorRevenueCategoriesMapping] = useState<string>("");

  const onUpdateAddonRevenueCategoriesMapping = () => {
    if (newRevenueCategoriesMapping.length > 0) {
      let isError = false;
      //check duplicate categories
      revenueCategoriesOptions.forEach(category => {
        const revenueCategories = newRevenueCategoriesMapping.filter(revenue => revenue.category === category.key);
        if (revenueCategories.length > 1) {
          setErrorRevenueCategoriesMapping("You have duplicate categories");
          isError = true;
        }
      });
      //check empty values
      let totalPercentage = 0;
      newRevenueCategoriesMapping.forEach(revenue => {
        const localPercentage = !!revenue.value && !isNaN(+revenue.value) ? +revenue.value : 0;
        totalPercentage = totalPercentage + localPercentage;
        if (!revenue.category || !revenue.value) {
          setErrorRevenueCategoriesMapping("All fields are required");
          isError = true;
        }
      });
      //check total percentage
      if (totalPercentage !== 100) {
        setErrorRevenueCategoriesMapping(
          `Total percentage should be equal 100. Current percent is ${totalPercentage}`
        );
        isError = true;
      }
      if (!isError) {
        updateAddon({ ...currentAddon, attributions: foldRevenueCategoriesMapping(newRevenueCategoriesMapping) });
      }
    } else {
      updateAddon({ ...currentAddon, attributions: '' });
    }
  };

  return (
    <Panel className='addons-advanced' title={<p className='h6'>Revenue Attribution</p>}>
      {isLoadingAddon && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {newRevenueCategoriesMapping.map(item => (
        <div className={`fields-mapping ${isMiddleScreen ? "mobile" : ""}`} key={item.id}>
          <FormSelectField
            label="Revenue Category"
            className="field"
            options={revenueCategoriesOptions}
            selectedKey={item.category}
            onChange={(_: any, option) => {
              onUpdateRevenueCategoriesMapping({ ...item, category: option?.key ? option?.key.toString() : "" })
            }}
            disabled={readonly}
          />
          <FormTextField
            label="Revenue Percentage"
            className="field"
            type="number"
            value={item.value || '0'}
            onChange={(_: any, value?: string) =>
              onUpdateRevenueCategoriesMapping({ ...item, value: value || '0' })
            }
            autoComplete="off"
            disabled={readonly}
          />
          <IconButton
            className="delete-button"
            ariaLabel="Remove Revenue Category"
            onClick={() => onRemoveRevenueCategoriesMapping(item)}
          >
            <TrashIcon />
          </IconButton>
        </div>
      ))}
      <div className={isMiddleScreen ? "mobile" : ""}>
        <LinkButton className="add-button" onClick={onAddRevenueCategoriesMapping}>
          <Icon iconName="Add" className="add" />
          Add item
        </LinkButton>
      </div>
      {errorRevenueCategoriesMapping && <div className="error">{errorRevenueCategoriesMapping}</div>}
      <div className={isMiddleScreen ? "mobile" : ""}>
        <ColorButton
          className="save-button"
          onClick={onUpdateAddonRevenueCategoriesMapping}
          disabled={readonly}
        >
          Save Changes
        </ColorButton>
      </div>
    </Panel>
  )
};

export default connector(AddonAdvancedDetails);