import { ActionTypeEnum, AppThunk } from ".";
import {
  getWithAuth,
  postWithAuth,
  putWithAuth,
  deleteWithAuth,
  uploadFile,
  MAILCHIMP_URL,
  VENUE_SETTINGS_URL,
  SQUARE_URL,
  uploadFiles,
} from "./api";
import {
  VenueSettingDto,
  ScheduleSettingDto,
  CreateVenueSettingDto,
  CreateScheduleSettingDto,
  TripleSeatBlockedStatus,
  CreateVenueTaxDto,
  ListsInfo,
  MergeField,
  SquareLocationDto,
  SquareCatalogDto,
} from "../store/types";
import { selectVenue } from "../reducers/venues";
import { addErrorAction } from "./auth-actions";
import { setWebConfigUIAction } from "./ui-actions";
import { webConfigUI } from "../constants/webConfigUI";
import { CreateMailchimpMappingDto, UpdateMailchimpMappingDto } from "../../../server/src/dto/mailchimpMapping.dto";
import { CreateMailchimpTagDto, MailchimpTagDto, UpdateMailchimpTagDto } from "../../../server/src/dto/mailchimpTag.dto";
import { openPopup } from "../../../common/utils/popup";

const GET_VENUES_URL = "/api/venue-setting";
const GET_SCHEDULE_URL = "/api/venue-setting/schedule";
const GET_CURRENT_SCHEDULE_URL = "/api/venue-setting/current-schedule";
const MAILCHIMP_MAPPING_URL = `${VENUE_SETTINGS_URL}/mailchimp-mapping`;
const MAILCHIMP_TAG_URL = `${VENUE_SETTINGS_URL}/mailchimp-tag`;
const FIVE_MB = 5 * 1024 * 1024;

export const getVenuesAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetVenues });
    const response = await getWithAuth(GET_VENUES_URL);
    dispatch({
      type: ActionTypeEnum.GetVenuesSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetVenuesFailure,
      payload: "error getting venues",
    });
    dispatch(addErrorAction("Get venues failure"));
  }
};

export const getVenuesForTenantAction = (tenantId: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetVenues });
    const response = await getWithAuth(`${GET_VENUES_URL}/for-tenant/${tenantId}`);
    dispatch({
      type: ActionTypeEnum.GetVenuesSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetVenuesFailure,
      payload: "error getting venues for tenant",
    });
    dispatch(addErrorAction("Get venues for tenant failure"));
  }
};

export const createVenueAction = (
  venue: CreateVenueSettingDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.CreateVenue });
    const response = await postWithAuth(GET_VENUES_URL, venue);
    dispatch({
      type: ActionTypeEnum.CreateVenueSuccess,
      payload: response.data,
    });
    dispatch(setWebConfigUIAction(webConfigUI.VENUE_DETAILS));
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CreateVenueFailure,
      payload: "error create Venue",
    });
    dispatch(addErrorAction("Create Venue failure"));
  }
};

export const removeVenueAction = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveVenue });
    const response = await deleteWithAuth(`${GET_VENUES_URL}/${id}`);
    dispatch({
      type: ActionTypeEnum.RemoveVenueSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemoveVenueFailure,
      payload: "error remove Venue",
    });
    dispatch(addErrorAction("Remove Venue failure"));
  }
};

export const selectVenueAction = (venue?: VenueSettingDto): AppThunk => async (
  dispatch
) => {
  try {
    if (venue) {
      dispatch({ type: ActionTypeEnum.GetVenue });
      const response = await getWithAuth(`${GET_VENUES_URL}/${venue.id}`);
      dispatch({
        type: ActionTypeEnum.GetVenueSuccess,
        payload: response.data,
      });
    } else {
      dispatch({
        type: ActionTypeEnum.GetVenueSuccess,
        payload: undefined,
      });
    }
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetVenueFailure,
      payload: "error update venue",
    });
    dispatch(addErrorAction("Get venue data failure"));
  }
};

export const updateVenueAction = (venue: Partial<VenueSettingDto>): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateVenue });
    const response = await putWithAuth(GET_VENUES_URL, venue);
    dispatch({
      type: ActionTypeEnum.UpdateVenueSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateVenueFailure,
      payload: "error update venue",
    });
    dispatch(addErrorAction("Update venue failure"));
  }
};

export const cloneVenueAction = (venue: Partial<VenueSettingDto>): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.CloneVenue });
    const response = await postWithAuth(`${GET_VENUES_URL}/clone`, venue);
    dispatch({
      type: ActionTypeEnum.CloneVenueSuccess,
      payload: response.data,
    });
    dispatch(setWebConfigUIAction(webConfigUI.VENUE_DETAILS));
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CloneVenueFailure,
      payload: "error clone venue",
    });
    dispatch(addErrorAction("Clone venue failure"));
  }
};

export const uploadVenueImageAction = (
  file: File,
  venueId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetVenueImageUrl });
    const imageUrl = await uploadFile(file);
    const response = await postWithAuth(`${GET_VENUES_URL}/update-image`, {
      value: imageUrl,
      id: venueId,
    });
    dispatch({
      type: ActionTypeEnum.GetVenueImageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetVenueImageFailure,
      payload: "error getting venue image url",
    });
    dispatch(addErrorAction("Get venue image url failure"));
  }
};

const getFilesLessThan = (files: FileList, size: number): { filtered: File[], skipped: File[] } => {
  const filtered = [];
  const skipped = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    file.size <= size ? filtered.push(file) : skipped.push(file);
  }
  return { filtered, skipped };
}

export const uploadVenueImagesAction = (
  files: FileList,
  venueId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetVenueImageUrl });

    const { filtered: filesLess5Mb, skipped } = getFilesLessThan(files, FIVE_MB);
    const imageUrls = await uploadFiles(filesLess5Mb);
    const response = await postWithAuth<VenueSettingDto>(`${GET_VENUES_URL}/update-images`, {
      values: imageUrls,
      id: venueId,
    });
    dispatch({
      type: ActionTypeEnum.GetVenueImageSuccess,
      payload: response.data,
    });
    if (skipped.length) {
      dispatch(addErrorAction(`Next Files didn't uploaded: ${skipped.map(file => file.name).join(', ')}. Size greater than 5 MB`));
    }

  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetVenueImageFailure,
      payload: "error getting venue images url",
    });
    dispatch(addErrorAction("Get venue images url failure"));
  }
};

export const deleteVenueImageAction = (
  venueId: string,
  imageUrl: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetVenueImageUrl });
    const response = await postWithAuth<VenueSettingDto>(`${GET_VENUES_URL}/delete-image`, {
      id: venueId,
      imageUrl,
    });
    dispatch({
      type: ActionTypeEnum.GetVenueImageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetVenueImageFailure,
      payload: "error deleting venue image url",
    });
    dispatch(addErrorAction("Delete venue image url failure"));
  }
};

export const uploadVenueMapImageAction = (
  file: File,
  venueId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetVenueMapImageUrl });
    const mapImageUrl = await uploadFile(file);
    const response = await postWithAuth(`${GET_VENUES_URL}/update-map-image`, {
      value: mapImageUrl,
      id: venueId,
    });
    dispatch({
      type: ActionTypeEnum.GetVenueMapImageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetVenueMapImageFailure,
      payload: "error getting venue map image url",
    });
    dispatch(addErrorAction("Get venue map image url failure"));
  }
};

export const updateScheduleAction = (
  schedule: ScheduleSettingDto | CreateScheduleSettingDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateSchedule });
    const response = schedule.hasOwnProperty("id")
      ? await putWithAuth(GET_SCHEDULE_URL, schedule)
      : await postWithAuth(GET_SCHEDULE_URL, schedule);

    dispatch({
      type: ActionTypeEnum.UpdateScheduleSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateScheduleFailure,
      payload: "error update schedule",
    });
    dispatch(addErrorAction("Update schedule failure"));
  }
};

export const removeScheduleAction = (id: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveSchedule });
    await deleteWithAuth(`${GET_SCHEDULE_URL}/${id}`);
    const venue = selectVenue(getState());
    const updatedVenue = {
      ...venue,
      schedules: venue?.schedules.filter((item) => item.id !== id),
    };
    dispatch({
      type: ActionTypeEnum.RemoveScheduleSuccess,
      payload: updatedVenue,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemoveScheduleFailure,
      payload: "error remove schedule",
    });
    dispatch(addErrorAction("Remove schedule failure"));
  }
};

export const setFilteredVenuesAction = (
  venues: VenueSettingDto[]
): AppThunk => async (dispatch) => {
  dispatch({ type: ActionTypeEnum.SetFilteredVenues, payload: venues });
};

export const getCurrentScheduleAction = (
  venueId: string,
  date: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetCurrentSchedule });
    const response = await getWithAuth(
      `${GET_CURRENT_SCHEDULE_URL}/${venueId}/${date}`
    );
    const { schedule, bookedSlots, freeSlots } = response.data;
    dispatch({
      type: ActionTypeEnum.GetCurrentScheduleSuccess,
      payload: { currentSchedule: schedule, bookedSlots, freeSlots },
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetCurrentScheduleFailure,
      payload: "error get current schedule",
    });
    dispatch(addErrorAction("Get current schedule failure"));
  }
};

export const setVenueErrorAction = (error: string) => ({
  type: ActionTypeEnum.SetVenueError,
  payload: error,
})

export const tripleSeatStatusToUIModel = (tripleSeatStatus: string): string[] => {
  const pairs = tripleSeatStatus ? tripleSeatStatus.split(",") : [];
  return pairs || "";
};

export const uiModelToTripleSeatStatus = (tripleSeatStatus: string[]): string => {
  const result = tripleSeatStatus.map((status) => `${status}`).join(",");
  return result;
};

export const changeTripleSeatStatus = (
  status: TripleSeatBlockedStatus,
  tripleSeatStatus: string[],
  isSelect?: boolean
) => {
  if (isSelect) {
    const newStatus = tripleSeatStatus.concat(status);
    return newStatus;
  } else {
    const indexLocation = tripleSeatStatus.findIndex((index) => index === status);
    const deleteLocations = tripleSeatStatus.splice(indexLocation, 1);
    return tripleSeatStatus;
  }
};

export const updateVenueTaxesAction = (venueId: string, newVenueTaxes: CreateVenueTaxDto[]): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateVenueTaxes });
    const response = await postWithAuth(`${GET_VENUES_URL}/venue-taxes/${venueId}`, newVenueTaxes);
    dispatch({
      type: ActionTypeEnum.UpdateVenueTaxesSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateVenueTaxesFailure,
      payload: "error updating venue taxes",
    });
    dispatch(addErrorAction("Update venue taxes failure"));
  }
}

export const getGoTabProductsAndSpotsForVenueAction = (location: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetGoTabProducts });
    const response = await getWithAuth(`${GET_VENUES_URL}/go-tab-products-and-spots-for-venue/${location}`);
    dispatch({
      type: ActionTypeEnum.GetGoTabProductsSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetGoTabProductsFailure,
      payload: "error getting GoTab products",
    });
    dispatch(addErrorAction("Get GoTab products failure"));
  }
}

export const getMailchimpListsInfoAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetListsInfo });
    const response = await getWithAuth<ListsInfo[]>(
      `${MAILCHIMP_URL}/lists-info`
    );
    dispatch({
      type: ActionTypeEnum.GetListsInfoSuccess,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ActionTypeEnum.GetListsInfoFailure,
      payload: "error getting Mailchimp lists info",
    });
    dispatch(addErrorAction("Get Mailchimp lists info failure"));
  }
};
export const getMailchimpTagsAction = (venueId: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetMailchimpTags });
    const response = await getWithAuth<MailchimpTagDto[]>(
      `${MAILCHIMP_URL}/tags/${venueId}`
    );
    dispatch({
      type: ActionTypeEnum.GetMailchimpTagsSuccess,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ActionTypeEnum.GetMailchimpTagsFailure,
      payload: "error getting Mailchimp tags",
    });
    dispatch(addErrorAction("Get Mailchimp tags failure"));
  }
}

export const getMailchimpMergeFieldsAction =
  (listId: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetMergeFields });
        const response = await getWithAuth<MergeField[]>(
          `${MAILCHIMP_URL}/lists/${listId}/merge-fields`
        );
        dispatch({
          type: ActionTypeEnum.GetMergeFieldsSuccess,
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: ActionTypeEnum.GetMergeFieldsFailure,
          payload: "error getting Mailchimp merge fields",
        });
        dispatch(addErrorAction("Get Mailchimp merge fields failure"));
      }
    };

export const createMailchimpMappingAction =
  (venueId: string, mchMapping: CreateMailchimpMappingDto): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.CreateMailchimpMapping });
        const response = await postWithAuth<VenueSettingDto>(
          `${MAILCHIMP_MAPPING_URL}/${venueId}`,
          mchMapping
        );
        dispatch({
          type: ActionTypeEnum.CreateMailchimpMappingSuccess,
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: ActionTypeEnum.CreateMailchimpMappingFailure,
          payload: "error create Mailchimp mapping",
        });
        dispatch(addErrorAction("Create Mailchimp mapping failure"));
      }
    };

type UpdateMailchimpMappingArgs = {
  venueId: string;
  listId: string;
  mchMapping: UpdateMailchimpMappingDto;
  onSuccess?: () => void;
};
export const updateMailchimpMappingAction =
  ({ venueId, listId, mchMapping, onSuccess }: UpdateMailchimpMappingArgs): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateMailchimpMapping });
        const response = await putWithAuth<VenueSettingDto>(
          `${MAILCHIMP_MAPPING_URL}/${venueId}/${listId}`,
          mchMapping
        );
        dispatch({
          type: ActionTypeEnum.UpdateMailchimpMappingSuccess,
          payload: response.data,
        });
        onSuccess && onSuccess();
      } catch (error) {
        dispatch({
          type: ActionTypeEnum.UpdateMailchimpMappingFailure,
          payload: "error update Mailchimp mapping",
        });
        dispatch(addErrorAction("Update Mailchimp mapping failure"));
      }
    };

export const createMailchimpTagsAction =
  (venueId: string, tags: MailchimpTagDto[]): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.CreateMailchimpTag });
        const response = await postWithAuth<VenueSettingDto>(
          `${MAILCHIMP_TAG_URL}/${venueId}`,
          { tags }
        );
        dispatch({
          type: ActionTypeEnum.CreateMailchimpTagSuccess,
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: ActionTypeEnum.CreateMailchimpTagFailure,
          payload: "Error creating Mailchimp tags",
        });
        dispatch(addErrorAction("Create Mailchimp tags failure"));
      }
    };

export const updateMailchimpTagsAction =
  (venueId: string, tags: MailchimpTagDto[]): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.UpdateMailchimpTag });
        const response = await putWithAuth<VenueSettingDto>(
          `${MAILCHIMP_TAG_URL}/${venueId}`,
          { tags }
        );
        console.log('response', response.data);
        dispatch({
          type: ActionTypeEnum.UpdateMailchimpTagSuccess,
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: ActionTypeEnum.UpdateMailchimpTagFailure,
          payload: "Error updating Mailchimp tags",
        });
        dispatch(addErrorAction("Update Mailchimp tags failure"));
      }
    };




export const getSquareLocationsAction =
  (): AppThunk<Promise<SquareLocationDto[]>> => async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetSquareLocations });
      const response = await getWithAuth<SquareLocationDto[]>(
        `${SQUARE_URL}/locations`
      );
      dispatch({
        type: ActionTypeEnum.GetSquareLocationsSuccess,
        payload: response.data,
      });
      return response.data;
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.GetSquareLocationsFailure,
        payload: "error get Square Locations",
      });
      dispatch(addErrorAction("Get Square Locations failure"));
      throw new Error("error Get Square Locations");
    }
  };

export const getSquareCatalogsAction =
  (locationId: string): AppThunk<Promise<SquareCatalogDto[]>> =>
    async (dispatch) => {
      try {
        const searchParams = new URLSearchParams({ locationId });
        dispatch({ type: ActionTypeEnum.GetSquareCatalogs });
        const response = await getWithAuth<SquareCatalogDto[]>(
          `${SQUARE_URL}/items?${searchParams.toString()}`
        );
        dispatch({
          type: ActionTypeEnum.GetSquareCatalogsSuccess,
          payload: response.data,
        });
        return response.data;
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetSquareCatalogsFailure,
          payload: "error get Square Catalogs",
        });
        dispatch(addErrorAction("Get Square Catalogs failure"));
        throw new Error("error Get Square Catalogs");
      }
    };

export const ClearSquareCatalogsAction = (): AppThunk => (dispatch) => {
  dispatch({ type: ActionTypeEnum.ClearSquareCatalogs });
};

export const connectStripeVenueAction = (venueId: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.VenueConnectStripe });
    const response = await postWithAuth(`${GET_VENUES_URL}/connect-stripe/${venueId}`);
    window.location.href = response.data;
    dispatch({
      type: ActionTypeEnum.VenueConnectStripeSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.VenueConnectStripeFailure,
      payload: "error connect Stripe",
    });
    dispatch(addErrorAction("Connect Stripe failure"));
  }
};

export const disconnectStripeVenueAction = (venueId: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.VenueDisconnectStripe });
    const response = await postWithAuth(`${GET_VENUES_URL}/disconnect-stripe/${venueId}`);

    dispatch({
      type: ActionTypeEnum.VenueDisconnectStripeSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.VenueDisconnectStripeFailure,
      payload: "error disconnect Stripe",
    });
    dispatch(addErrorAction("Disconnect Stripe failure"));
  }
};

export const checkStripeVenueAction =
  (venueId: string, disableLoading?: boolean): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({
          type: Boolean(disableLoading)
            ? ActionTypeEnum.VenueCheckStripeWithoutRequestInProgress
            : ActionTypeEnum.VenueCheckStripe,
        });
        const response = await postWithAuth(
          `${GET_VENUES_URL}/check-stripe/${venueId}`
        );

        dispatch({
          type: ActionTypeEnum.VenueCheckStripeSuccess,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.VenueCheckStripeFailure,
          payload: "error check Stripe",
        });
        dispatch(addErrorAction("Check Stripe failure"));
      }
    };