import { QuestionType } from "../../../../server/src/dto/questionSetting.dto";

export class QuestionSettingDto {
    id: string;
    internalName: string;
    priority: number;
    displayName: string;
    options: string;
    questionType: QuestionType;
    includeOnConfirmationEmails: boolean;
    tenantId: string;
    createdAt: Date;
}
export interface QuestionsState {
    questions: QuestionSettingDto[];
    isLoadingQuestions: boolean;
    isLoadingQuestion: boolean;
    isRequestInProgress: boolean;
    currentQuestion?: QuestionSettingDto;
    error?: string;
    filteredQuestions: QuestionSettingDto[];
}
export class CreateQuestionSettingDto {
    internalName: string;
    priority: number;
    displayName: string;
    options: string;
    questionType: QuestionType;
    tenantId: string;
    includeOnConfirmationEmails: boolean;
}

