import React from "react";
import BackButton from "../common/BackButton/BackButton";
import ActionsButton from "../common/ActionsButton";
import ChevronDown from "../../assets/custom-chevron-down.svgr";
import {
  Callout,
  DefaultButton,
  DirectionalHint,
  mergeStyleSets,
} from "office-ui-fabric-react";
import "./addonsDetails.scss";
import { addonConfigUI, webConfigUI } from "../../constants/webConfigUI";
import {
  removeScheduleAction,
  removeVenueAction,
} from "../../actions/venue-actions";
import {
  setVenuePageAction,
  setWebConfigUIAction,
} from "../../actions/ui-actions";
import { removeAddonModifierAction } from "../../actions/addon-action";
import { selectReadonly } from "../../reducers/auth-reducer";
import { selectModifier } from "../../reducers/addons";
import { State } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import AddonModifierGeneralDetails from "./General/General";
import EditAddonModifier from "./General/Edit/EditAddonModifier";
import DeleteConfirmation from "../common/DeleteConfirmation/DeleteConfirmation";

const styles = mergeStyleSets({
  callout: {
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "100px",
    maxHeight: "192px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "6px",
  },
});

const tabs = [
  {
    text: "General",
    id: addonConfigUI.GENERAL,
  },
  {
    text: "Assign",
    id: addonConfigUI.PACKAGES,
  },
];

const mapDispatchToProps = {
  removeSchedule: removeScheduleAction,
  removeVenue: removeVenueAction,
  setWebConfigUI: setWebConfigUIAction,
  setVenuePage: setVenuePageAction,
  removeAddonModifier: removeAddonModifierAction,
};

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddonModifier: selectModifier(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AddonModifierDetails = ({ setWebConfigUI, removeAddonModifier, currentAddonModifier }: Props) => {
  const [isShowActionsMenu, setIsShowActionsMenu] = React.useState(false);
  const [addonPage, setAddonModifierPage] = React.useState(addonConfigUI.GENERAL);
  const [isCloning, setIsCloning] = React.useState(false);
  const [isRemoveAddonModifierOpen, setIsRemoveAddonModifierOpen] = React.useState(false);

  const backToList = () => {
    setWebConfigUI(webConfigUI.ADDON_MODIFIERS);
  };

  const confirmedRemoveAddonModifier = (isConfirm: boolean) => {
    if (isConfirm && currentAddonModifier) {
      removeAddonModifier(currentAddonModifier.id);
      setWebConfigUI(webConfigUI.ADDON_MODIFIERS);
    }
    setIsRemoveAddonModifierOpen(false);
  };

  if (!currentAddonModifier) {
    return null;
  }

  return (
    <>
      <div className="addon-details-container">
        <div className="addon-details">
          <BackButton text="All Modifiers" onBack={backToList} />
          <div className="addon-details-information-container">
            <div className="addon-details-information-header">
              <div className="addon-details-title h4">{currentAddonModifier.name}</div>
              <div className="actions-container">
                <ActionsButton
                  id="actions-button"
                  onClick={() => setIsShowActionsMenu(true)}
                >
                  <div className="actions-button-text">Actions</div>
                  <ChevronDown className="chevron" />
                </ActionsButton>
                {isShowActionsMenu && (
                  <Callout
                    className={styles.callout}
                    gapSpace={0}
                    target={`#actions-button`}
                    onDismiss={() => setIsShowActionsMenu(false)}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible={false}
                  >
                    <div className="actions-button-container">
                      <DefaultButton
                        className="actions-button"
                        onClick={() => setIsRemoveAddonModifierOpen(true)}
                      >
                        <div className="actions-text">Delete modifier</div>
                      </DefaultButton>
                    </div>
                  </Callout>
                )}
              </div>
            </div>
            <AddonModifierGeneralDetails />
          </div>
        </div>
      </div>
      {isCloning && (
        <EditAddonModifier isCloneAddonModifier={true} onClose={() => setIsCloning(false)} />
      )}
      {isRemoveAddonModifierOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this add on modifier?"}
        ConfirmationButtonText={"Delete Add on Modifier"}
        onClose={confirmedRemoveAddonModifier}
      />}
    </>
  );
};

export default connector(AddonModifierDetails);
