import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  IDropdownOption,
  Toggle,
  IconButton,
  Icon,
} from "office-ui-fabric-react";
import {
  getSettingsAction,
  updateSettingsAction,
} from "../../../actions/settings-actions";
import {
  selectPricing,
  selectHideDuration,
  selectHideNumberOfLines,
  selectSubtractMultiLanes,
  selectShowReservationTime,
  selectDisableGuestCheckout,
  selectDefaultDuration,
  selectTimeSlotDuration,
  selectActionIcon,
  selectActionText,
  selectPartyTitle,
  selectGroupTitle,
  selectOccasions,
  selectOccasionTitle,
  selectShowGuestDetails,
  selectGuestDetails,
  selectGuestDetailsTitle,
  selectReservationMode,
  selectCurrency,
  selectScrollToTimeSlotAfterSelect,
  selectHideCreateAccount,
  selectHideCompanyNameFromEmails,
  selectTwelveHourClockFormat,
  selectDateFormat,
  selectHideAllPackageTab,
  selectNewDesign,
  selectShowReportV2Page,
  selectHowDidYouHearAboutUs,
} from "../../../reducers/settings";
import { formatDuration } from "../../../utils/formats";
import { selectReadonly } from "../../../reducers/auth-reducer";
import { selectVenues } from "../../../reducers/venues";
import {
  State,
  Pricing,
  ReservationModeType,
  CurrencyType,
  DateFormat,
} from "../../../store/types";
import AssistantIcon from "../../../assets/actions/assistant-24px.svg";
import CasinoIcon from "../../../assets/actions/casino-24px.svg";
import GolfCourseIcon from "../../../assets/actions/golf_course-24px.svg";
import LocalPlayIcon from "../../../assets/actions/local_play-24px.svg";
import ViewComfyIcon from "../../../assets/actions/view_comfy-24px.svg";
import TrashIcon from "../../../assets/trash-icon.svgr";
import BowlingIcon from "../../../assets/actions/bowling-24px.svg";
import { useViewport } from "../../../hooks/responsive";
import Panel from "../../common/Panel";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import FormTextField from "../../../../../common/components/FormTextField";
import FormSelectField from "../../common/FormSelectField";
import { pricingModelTypes } from "../../../constants/pricingModelTypes";
import "./globalSettings.scss";
import CancellationReasons from "./CancellationReasons/CancellationReasons";
import RevenueCategories from "./RevenueCategories/RevenueCategories";

const mapDispatchToProps = {
  getSettings: getSettingsAction,
  updateSettings: updateSettingsAction,
};

const mapStateToProps = (state: State) => ({
  pricing: selectPricing(state),
  hideDuration: selectHideDuration(state),
  hideNumberOfLines: selectHideNumberOfLines(state),
  hideCreateAccount: selectHideCreateAccount(state),
  subtractMultiLanes: selectSubtractMultiLanes(state),
  showReservationTime: selectShowReservationTime(state),
  disableGuestCheckout: selectDisableGuestCheckout(state),
  defaultDuration: selectDefaultDuration(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  actionIcon: selectActionIcon(state),
  actionText: selectActionText(state),
  partyTitle: selectPartyTitle(state),
  groupTitle: selectGroupTitle(state),
  occasions: selectOccasions(state),
  occasionTitle: selectOccasionTitle(state),
  showGuestDetails: selectShowGuestDetails(state),
  guestDetails: selectGuestDetails(state),
  guestDetailsTitle: selectGuestDetailsTitle(state),
  reservationMode: selectReservationMode(state),
  currency: selectCurrency(state),
  scrollToTimeSlotAfterSelect: selectScrollToTimeSlotAfterSelect(state),
  hideCompanyNameFromEmails: selectHideCompanyNameFromEmails(state),
  readonly: selectReadonly(state),
  twelveHourClockFormat: selectTwelveHourClockFormat(state),
  dateFormat: selectDateFormat(state),
  hideAllPackageTab: selectHideAllPackageTab(state),
  venues: selectVenues(state),
  newDesign: selectNewDesign(state),
  showReportV2Page: selectShowReportV2Page(state),
  howDidYouHearAboutUs: selectHowDidYouHearAboutUs(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const durations = [
  {
    key: 5,
    text: "5 minutes",
  },
  {
    key: 10,
    text: "10 minutes",
  },
  {
    key: 15,
    text: "15 minutes",
  },
  {
    key: 30,
    text: "30 minutes",
  },
  {
    key: 60,
    text: "1 hour",
  },
  {
    key: 90,
    text: "1.5 hours",
  },
  {
    key: 120,
    text: "2 hours",
  },
  {
    key: 180,
    text: "3 hours",
  },
  {
    key: 210,
    text: "3.5 hours",
  },
  {
    key: 240,
    text: "4 hours",
  },
];

const defaultDurations = [
  {
    key: 1,
    text: "1 time slot",
  },
  {
    key: 2,
    text: "2 time slots",
  },
  {
    key: 3,
    text: "3 time slots",
  },
  {
    key: 4,
    text: "4 time slots",
  },
  {
    key: 5,
    text: "5 time slots",
  },
  {
    key: 6,
    text: "6 time slots",
  },
  {
    key: 7,
    text: "7 time slots",
  },
  {
    key: 8,
    text: "8 time slots",
  },
];

const reservationModeList = Object.values(ReservationModeType).map((item) => ({
  key: item,
  text: item,
}));

const currencyList = Object.values(CurrencyType).map((item) => ({
  key: item,
  text: item,
}));

const actionIcons: any = {
  assistant: AssistantIcon,
  casino: CasinoIcon,
  golf: GolfCourseIcon,
  local: LocalPlayIcon,
  comfy: ViewComfyIcon,
  bowling: BowlingIcon,
};

const actionIconOptions = [
  {
    key: "assistant",
    text: "assistant",
  },
  {
    key: "casino",
    text: "casino",
  },
  {
    key: "golf",
    text: "golf",
  },
  {
    key: "local",
    text: "local",
  },
  {
    key: "comfy",
    text: "comfy",
  },
  {
    key: "bowling",
    text: "bowling",
  },
];
export const actionTexts = [
  {
    key: "Bay",
    text: "Bay",
  },
  {
    key: "Court",
    text: "Court",
  },
  {
    key: "Lane",
    text: "Lane",
  },
  {
    key: "Table",
    text: "Table",
  },
  {
    key: "Round",
    text: "Round",
  },
  {
    key: "Tee Time",
    text: "Tee Time",
  },
  {
    key: "Room",
    text: "Room",
  },
  {
    key: "Ticket",
    text: "Ticket",
  },
  {
    key: "Lawn",
    text: "Lawn",
  },
  {
    key: "PC",
    text: "PC",
  },
  {
    key: "Booth",
    text: "Booth",
  },
  {
    key: "Pass",
    text: "Pass",
  },
  {
    key: "Session",
    text: "Session",
  },
  {
    key: "Resource",
    text: "Resource",
  },
  {
    key: "Slot",
    text: "Slot",
  },
];








const onRenderIconOption = (
  option: IDropdownOption | undefined
): JSX.Element => {
  return (
    <div className="dropdown-image-container">
      {option && option.key && actionIcons[option.key] && (
        <img className="option-icon" src={actionIcons[option.key]} />
      )}
    </div>
  );
};
const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
  if (!options) {
    return <div></div>;
  }
  const option = options[0];
  return (
    <div className="dropdown-image-container">
      {option && option.key && actionIcons[option.key] && (
        <img className="option-icon" src={actionIcons[option.key]} />
      )}
    </div>
  );
};

interface Occasion {
  id: number;
  name: string;
  tsId: string;
}
interface GuestDetails {
  id: number;
  name: string;
  tsId: string;
}

const GlobalSettings = ({
  getSettings,
  updateSettings,
  pricing,
  hideDuration,
  hideNumberOfLines,
  hideCreateAccount,
  subtractMultiLanes,
  showReservationTime,
  disableGuestCheckout,
  defaultDuration,
  timeSlotDuration,
  actionIcon,
  actionText,
  partyTitle,
  groupTitle,
  occasions,
  occasionTitle,
  showGuestDetails,
  guestDetails,
  guestDetailsTitle,
  reservationMode,
  currency,
  scrollToTimeSlotAfterSelect,
  hideCompanyNameFromEmails,
  twelveHourClockFormat,
  dateFormat,
  hideAllPackageTab,
  readonly,
  venues,
  newDesign,
  showReportV2Page,
  howDidYouHearAboutUs
}: Props) => {
  useEffect(() => {
    getSettings();
  }, [getSettings]);
  const { isMiddleScreen } = useViewport();
  const expendOccasions = (o: string): Occasion[] => {
    return o
      .split(";")
      .map((item, index) => ({
        id: index,
        name: item.split(":")[0] || "",
        tsId: item.split(":")[1] || "",
      }));
  };
  const foldOccasions = (o: Occasion[]) => {
    return o.map((item) => `${item.name}:${item.tsId}`).join(";");
  };
  const onUpdateOccasion = (o: Occasion) => {
    const newValue = newOccasions.map((item) => (item.id === o.id ? o : item));
    setNewOccasions(newValue);
  };
  const [howDidYouHearOptions, setHowDidYouHearOptions] = useState<string[]>(
    howDidYouHearAboutUs.split(",")
  );

  useEffect(() => {
    setHowDidYouHearOptions(howDidYouHearAboutUs.split(","));
  }, [howDidYouHearAboutUs]);

  const onUpdateHowDidYouHearOption = (index: number, newValue: string) => {
    const updatedOptions = [...howDidYouHearOptions];
    updatedOptions[index] = newValue;
    setHowDidYouHearOptions(updatedOptions);
  };

  const onRemoveHowDidYouHearOption = (index: number) => {
    const updatedOptions = howDidYouHearOptions.filter((_, i) => i !== index);
    setHowDidYouHearOptions(updatedOptions);
  };

  const onAddHowDidYouHearOption = () => {
    setHowDidYouHearOptions([...howDidYouHearOptions, ""]);
  };
  const onAddOccasion = () => {
    setNewOccasions([
      ...newOccasions,
      { id: newOccasions.length, name: "", tsId: "" },
    ]);
  };
  const onRemoveOccasion = (o: Occasion) => {
    const newValue = newOccasions
      .filter((item) => item.id !== o.id)
      .map((item, index) => ({ ...item, id: index }));
    setNewOccasions(newValue);
  };

  const onRemoveHowDidYouHearAboutUs = (item: string) => {
    const newValue = howDidYouHearAboutUs.split(',').filter((i) => i !== item).join(',')
    updateSettings({ howDidYouHearAboutUs: newValue });
  }

  const expendGuestDetails = (o: string): GuestDetails[] => {
    return o
      .split(";")
      .map((item, index) => ({
        id: index,
        name: item.split(":")[0] || "",
        tsId: item.split(":")[1] || "",
      }));
  };
  const foldGuestDetails = (o: GuestDetails[]) => {
    return o.map((item) => `${item.name}:${item.tsId}`).join(";");
  };
  const onUpdateGuestDetails = (o: GuestDetails) => {
    const newValue = newGuestDetails.map((item) =>
      item.id === o.id ? o : item
    );
    setNewGuestDetails(newValue);
  };
  const onAddGuestDetails = () => {
    setNewGuestDetails([
      ...newGuestDetails,
      { id: newGuestDetails.length, name: "", tsId: "" },
    ]);
  };
  const onRemoveGuestDetails = (o: GuestDetails) => {
    const newValue = newGuestDetails
      .filter((item) => item.id !== o.id)
      .map((item, index) => ({ ...item, id: index }));
    setNewGuestDetails(newValue);
  };

  const onPricingUpdate = (value?: string | number) => {
    const pricing =
      value === Pricing.flatRate
        ? Pricing.flatRate
        : value === Pricing.perPerson
          ? Pricing.perPerson
          : value === Pricing.perPersonFlat
            ? Pricing.perPersonFlat
            : value === Pricing.flatRatePerLane
              ? Pricing.flatRatePerLane
              : value === Pricing.flatRatePerHour
                ? Pricing.flatRatePerHour
                : Pricing.perLane;
    updateSettings({ pricing });
  };

  const onCurrencyUpdate = (value?: string | number) => {
    let currency;
    switch (value) {
      case CurrencyType.USD:
        currency = CurrencyType.USD;
        break;
      case CurrencyType.CAD:
        currency = CurrencyType.CAD;
        break;
      case CurrencyType.NZD:
        currency = CurrencyType.NZD;
        break;
      case CurrencyType.GBP:
        currency = CurrencyType.GBP;
        break;
      case CurrencyType.EUR:
        currency = CurrencyType.EUR;
        break;
      case CurrencyType.AUD:
        currency = CurrencyType.AUD;
        break;
      default:
        currency = CurrencyType.USD;
    }
    updateSettings({ currency });
  };
  useEffect(() => {
    setNewOccasions(expendOccasions(occasions));
  }, [occasions]);
  useEffect(() => {
    setNewGuestDetails(expendGuestDetails(guestDetails));
  }, [guestDetails]);
  useEffect(() => {
    setNewPartyTitle(partyTitle);
  }, [partyTitle]);
  useEffect(() => {
    setNewOccasionTitle(occasionTitle);
  }, [occasionTitle]);
  useEffect(() => {
    setNewGuestDetailsTitle(guestDetailsTitle);
  }, [guestDetailsTitle]);

  const [newActionIcon, setNewActionIcon] = useState(actionIcon);
  const [newActionText, setNewActionText] = useState(actionText);
  const [newPartyTitle, setNewPartyTitle] = useState(partyTitle);
  const [newGroupTitle, setNewGroupTitle] = useState(groupTitle);
  const [newOccasionTitle, setNewOccasionTitle] = useState(occasionTitle);
  const [newOccasions, setNewOccasions] = useState<Occasion[]>(
    expendOccasions(occasions)
  );

  const [newGuestDetails, setNewGuestDetails] = useState<GuestDetails[]>(
    expendGuestDetails(guestDetails)
  );
  const [newGuestDetailsTitle, setNewGuestDetailsTitle] =
    useState(guestDetailsTitle);

  const timeDuration = formatDuration(defaultDuration, timeSlotDuration);

  const onUpdateReservationSettings = () => {
    updateSettings({
      actionIcon: newActionIcon,
      actionText: newActionText,
      partyTitle: newPartyTitle,
      groupTitle: newGroupTitle,
      occasionTitle: newOccasionTitle,
    });
  };
  const onUpdateOccasionsSettings = () => {
    updateSettings({
      occasions: foldOccasions(newOccasions),
    });
  };
  const onUpdateGuestDetailsSettings = () => {
    updateSettings({
      guestDetailsTitle: newGuestDetailsTitle,
      guestDetails: foldGuestDetails(newGuestDetails),
    });
  };

  const reservationSettingsTitle = (
    <>
      <div className="h6">Reservation Settings</div>
    </>
  );
  const occasionsSettingsTitle = (
    <>
      <div className="h6">Occasions</div>
    </>
  );
  const howDidYouHearAboutUsTitle = (
    <>
      <div className="h6">How did you hear about us?</div>
    </>
  );
  const guestDetailsSettingsTitle = (
    <>
      <div className="h6">Guest Details</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={showGuestDetails}
          onChange={(_: any, value?: boolean) =>
            updateSettings({
              showGuestDetails: !!value,
            })
          }
          disabled={readonly}
        />
      </div>
    </>
  );
  const globalSettingsTitle = (
    <>
      <div className="h6">Global Settings</div>
    </>
  );

  const isSquarePaymentConnect = venues.filter(v => v.paymentType === 'square').length > 0

  return (
    <div className={`global-settings ${isMiddleScreen ? "mobile" : ""}`}>
      <div className="left-panel">
        <Panel className="panel-shift" title={reservationSettingsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Action Item"
              className="integrations-field"
              options={actionIconOptions}
              selectedKey={newActionIcon}
              onRenderOption={onRenderIconOption}
              onRenderTitle={onRenderTitle}
              onChange={(_: any, option) => {
                setNewActionIcon(option?.key ? option?.key + "" : "");
              }}
              disabled={readonly}
              required={true}
            />
            <FormSelectField
              label="Language"
              className="integrations-field"
              options={actionTexts}
              selectedKey={newActionText}
              onChange={(_: any, option) => {
                setNewActionText(option?.key ? option?.key + "" : "");
              }}
              disabled={readonly}
              required={true}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Party Title"
              className="integrations-field"
              value={newPartyTitle}
              onChange={(_: any, value?: string) =>
                setNewPartyTitle(value || "")
              }
              autoComplete="off"
              required={true}
              disabled={readonly}
            />
            <FormTextField
              label="Groups of X or more"
              className="integrations-field"
              value={newGroupTitle}
              onChange={(_: any, value?: string) =>
                setNewGroupTitle(value || "")
              }
              autoComplete="off"
              required={true}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Occasion Title"
              className="integrations-field"
              value={newOccasionTitle}
              onChange={(_: any, value?: string) =>
                setNewOccasionTitle(value || "")
              }
              autoComplete="off"
              required={true}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateReservationSettings}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <Panel className="panel-shift" title={occasionsSettingsTitle}>
          {newOccasions.map((item) => (
            <div
              className={`row occasion-fields ${isMiddleScreen ? "mobile" : ""
                }`}
              key={item.id}
            >
              <FormTextField
                label="Type"
                className="integrations-field"
                value={item.name}
                onChange={(_: any, value?: string) =>
                  onUpdateOccasion({ ...item, name: value || "" })
                }
                autoComplete="off"
                disabled={readonly}
              />
              <FormTextField
                label="Tripleseat ID"
                className="integrations-field"
                value={item.tsId}
                onChange={(_: any, value?: string) =>
                  onUpdateOccasion({ ...item, tsId: value || "" })
                }
                autoComplete="off"
                disabled={readonly}
              />
              <IconButton
                className="delete-button"
                ariaLabel="Remove occasion"
                onClick={() => onRemoveOccasion(item)}
              >
                <TrashIcon />
              </IconButton>
            </div>
          ))}
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <LinkButton className="add-button" onClick={onAddOccasion}>
              <Icon iconName="Add" className="add" />
              Add item
            </LinkButton>
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateOccasionsSettings}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <RevenueCategories />
        <Panel className="panel-shift" title={guestDetailsSettingsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormTextField
              label="Guest Details Title"
              className="integrations-field"
              value={newGuestDetailsTitle}
              onChange={(_: any, value?: string) =>
                setNewGuestDetailsTitle(value || "")
              }
              autoComplete="off"
              required={!!showGuestDetails}
              disabled={readonly || !showGuestDetails}
            />
          </div>
          {newGuestDetails.map((item) => (
            <div
              className={`row details-fields ${isMiddleScreen ? "mobile" : ""}`}
              key={item.id}
            >
              <FormTextField
                label="Type"
                className="integrations-field"
                value={item.name}
                onChange={(_: any, value?: string) =>
                  onUpdateGuestDetails({ ...item, name: value || "" })
                }
                autoComplete="off"
                disabled={readonly}
              />
              <FormTextField
                label="Tripleseat ID"
                className="integrations-field"
                value={item.tsId}
                onChange={(_: any, value?: string) =>
                  onUpdateGuestDetails({ ...item, tsId: value || "" })
                }
                autoComplete="off"
                disabled={readonly}
              />
              <IconButton
                className="delete-button"
                ariaLabel="Remove guest details"
                onClick={() => onRemoveGuestDetails(item)}
              >
                <TrashIcon />
              </IconButton>
            </div>
          ))}
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <LinkButton className="add-button" onClick={onAddGuestDetails}>
              <Icon iconName="Add" className="add" />
              Add item
            </LinkButton>
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <ColorButton
              className="save-button"
              onClick={onUpdateGuestDetailsSettings}
              disabled={readonly}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
        <CancellationReasons />
        <Panel className="panel-shift" title={howDidYouHearAboutUsTitle}>
          {howDidYouHearOptions.map((item, index) => (
            <div className="row occasion-fields" key={index}>
              <FormTextField
                className="integrations-field"
                value={item}
                onChange={(_, value?: string) =>
                  onUpdateHowDidYouHearOption(index, value || "")
                }
                autoComplete="off"
              />
              <IconButton
                className="delete-button"
                ariaLabel="Remove how did you hear about us"
                onClick={() => onRemoveHowDidYouHearOption(index)}
              >
                <TrashIcon />
              </IconButton>
            </div>
          ))}
          <div className="row">
            <LinkButton className="add-button" onClick={onAddHowDidYouHearOption}>
              <Icon iconName="Add" className="add" />
              Add item
            </LinkButton>
          </div>
          <div className="row">
            <ColorButton
              className="save-button"
              onClick={() => updateSettings({ howDidYouHearAboutUs: howDidYouHearOptions.join(",") })}
            >
              Save Changes
            </ColorButton>
          </div>
        </Panel>
      </div>
      <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
        <Panel className="panel-shift" title={globalSettingsTitle}>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Reservation Mode"
              className="integrations-field"
              options={reservationModeList}
              selectedKey={reservationMode}
              onChange={(_: any, option) => {
                updateSettings({
                  reservationMode: option?.key
                    ? option?.key.toString()
                    : ReservationModeType.Default,
                });
              }}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Currency"
              className="integrations-field"
              options={currencyList}
              selectedKey={currency}
              onChange={(_: any, option) => onCurrencyUpdate(option?.key)}
              disabled={readonly || isSquarePaymentConnect}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Pricing Model"
              className="integrations-field"
              options={pricingModelTypes}
              selectedKey={pricing}
              onChange={(_: any, option) => onPricingUpdate(option?.key)}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Time Slots Duration"
              className="integrations-field"
              options={durations}
              selectedKey={timeSlotDuration}
              onChange={(_: any, option) => {
                updateSettings({
                  timeSlotDuration: option?.key ? +option?.key : 30,
                });
              }}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Hide Duration"
              className="integrations-field"
              checked={hideDuration}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  hideDuration: !!value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label={`Default duration ${hideDuration ? `(${timeDuration})` : ""
                }`}
              className="integrations-field"
              options={defaultDurations}
              selectedKey={defaultDuration}
              onChange={(_: any, option) => {
                updateSettings({
                  defaultDuration: option?.key ? +option?.key : 1,
                });
              }}
              disabled={readonly || !hideDuration}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <FormSelectField
              label="Date format"
              className="integrations-field"
              options={[
                { key: DateFormat.MMDDYYYY, text: DateFormat.MMDDYYYY },
                { key: DateFormat.DDMMYYYY, text: DateFormat.DDMMYYYY },
              ]}
              selectedKey={dateFormat}
              onChange={(_: any, option) => {
                updateSettings({
                  dateFormat: option?.key
                    ? (option?.key as DateFormat)
                    : DateFormat.MMDDYYYY,
                });
              }}
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Hide Number of Lanes"
              className="integrations-field"
              checked={hideNumberOfLines}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  hideNumberOfLines: !!value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Hide Create Account"
              className="integrations-field"
              checked={hideCreateAccount}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  hideCreateAccount: !!value,
                })
              }
              inlineLabel
              disabled={readonly || disableGuestCheckout}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Subtract multi-lanes from inventory"
              className="integrations-field"
              checked={subtractMultiLanes}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  subtractMultiLanes: !!value,
                })
              }
              inlineLabel
              disabled={readonly || !hideNumberOfLines}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Show Time Window"
              className="integrations-field"
              checked={showReservationTime}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  showReservationTime: !!value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Disable guest checkout"
              className="integrations-field"
              checked={disableGuestCheckout}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  disableGuestCheckout: !!value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Scroll to time slot after select"
              className="integrations-field"
              checked={scrollToTimeSlotAfterSelect}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  scrollToTimeSlotAfterSelect: !!value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Hide Company Name from Emails"
              className="integrations-field"
              checked={hideCompanyNameFromEmails}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  hideCompanyNameFromEmails: !!value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="24-hour Clock"
              className="integrations-field"
              checked={!twelveHourClockFormat}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  twelveHourClockFormat: !value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Hide All Tab"
              className="integrations-field"
              checked={hideAllPackageTab}
              onChange={(_: any, value?: boolean) =>
                updateSettings({
                  hideAllPackageTab: !!value,
                })
              }
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
            <Toggle
              label="Show v2 Reports (beta)"
              className="integrations-field"
              checked={showReportV2Page}
              onChange={(_, value) => updateSettings({ showReportV2Page: !!value })}
              inlineLabel
              disabled={readonly}
            />
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default connector(GlobalSettings);
