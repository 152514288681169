import { ActionTypeEnum, AppThunk } from '.';
import { CreateMembershipPlanDto, MembershipPlanDiscountsDto, MembershipPlanDto } from '../store/types';
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth, uploadFile } from "./api";
import { addErrorAction } from "./auth-actions";

const STRIPE_PRODUCTS_URL = "/api/settings/stripe-products";

export const selectPlansAction = (id?: string) => ({ type: ActionTypeEnum.SelectPlan, payload: id })

export const getPlansAction = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetPlans });
    const response = await getWithAuth(STRIPE_PRODUCTS_URL);
    dispatch({
      type: ActionTypeEnum.GetPlansSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetPlansFailure,
      payload: "error getting plans",
    });
    dispatch(addErrorAction("Get plans failure"));
  }
};

export const createPlanAction = (
  plan: CreateMembershipPlanDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.CreatePlan });
    const response = await postWithAuth(STRIPE_PRODUCTS_URL, plan);
    dispatch({
      type: ActionTypeEnum.CreatePlanSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CreatePlanFailure,
      payload: "error create plan",
    });
    dispatch(addErrorAction("Create plan failure"));
  }
};
export const updatePlanAction = (
  plan: MembershipPlanDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdatePlan });
    const response = await putWithAuth(`${STRIPE_PRODUCTS_URL}`, plan);
    dispatch({
      type: ActionTypeEnum.UpdatePlanSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdatePlanFailure,
      payload: "error update plan",
    });
    dispatch(addErrorAction("Update plan failure"));
  }
};
export const updatePlanDiscountRelationsAction = (
  plan: MembershipPlanDiscountsDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdatePlan });
    const response = await postWithAuth(`${STRIPE_PRODUCTS_URL}/discount-relations`, plan);
    dispatch({
      type: ActionTypeEnum.UpdatePlanSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdatePlanFailure,
      payload: "error update plan",
    });
    dispatch(addErrorAction("Update plan failure"));
  }
};

export const uploadPlanImageAction = (
  file: File,
  planId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdatePlan });
    const imageUrl = await uploadFile(file);
    const response = await postWithAuth(`${STRIPE_PRODUCTS_URL}/update-image`, {
      imageUrl,
      planId,
    });
    dispatch({
      type: ActionTypeEnum.UpdatePlanSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdatePlanFailure,
      payload: "error getting plan image url",
    });
    dispatch(addErrorAction("Get plan image url failure"));
  }
};

export const deletePlanImageAction = (
  planId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdatePlan });
    const response = await postWithAuth(`${STRIPE_PRODUCTS_URL}/update-image`, {
      planId,
    });
    dispatch({
      type: ActionTypeEnum.UpdatePlanSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdatePlanFailure,
      payload: "error getting plan image url",
    });
    dispatch(addErrorAction("Get plan image url failure"));
  }
};

export const removePlanAction = (
  id: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.RemovePlan });
    await deleteWithAuth(`${STRIPE_PRODUCTS_URL}/${id}`, {});
    dispatch({
      type: ActionTypeEnum.RemovePlanSuccess,
      payload: id,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemovePlanFailure,
      payload: "error remove plan",
    });
    dispatch(addErrorAction("Remove plan failure"));
  }
};
