import { UserDto } from "../../../../server/src/dto/user.dto";
import { webConfigUI, venueConfigUI } from "../../constants/webConfigUI";
import { UserType } from "../../../../server/src/entities/enums";

export * from "../../../../server/src/dto/user.dto";
export * from "../../../../server/src/dto/signUpCredentials.dto";
export { UserType } from "../../../../server/src/entities/enums";

export interface Error {
  id: number;
  text: string;
  timestamp: number;
}

export interface AuthState {
  isRequestInProgress: boolean;
  isResetPasswordSent: boolean;
  authenticated: boolean;
  readonly: boolean;
  superAdmin: boolean;
  fromSuperAdmin: boolean;
  venueAdmin: boolean;
  partnerAdmin: boolean;
  steltronicAdmin: boolean;
  goTabAdmin: boolean;
  bowlingMarketingAdmin: boolean;
  user?: UserDto;
  expiresAt?: number;
  error?: string;
  errors: Error[]
  ui: webConfigUI;
  venuePage: venueConfigUI;
  adminType?: UserType;
}
