import { ActionTypeEnum, AppThunk } from ".";
import { getWithAuth } from "./api";
import {
  selectTenantIdFilterReport,
  selectSuperAdminTimeInterval,
  selectSuperAdminStartDate,
  selectSuperAdminEndDate,
  selectTenantPlanFilterReport,
  selectSuperAdminCurrencyFilter,
} from "../reducers/superAdminReports";
import { TimeInterval, SuperAdminReportQueryParams, CurrencyType, StatusInterval } from "../store/types";
import { addErrorAction } from "./auth-actions";

export const SUPER_ADMIN_REPORTS_URL = "/api/reservation/settings/super-admin-report";

export interface TimeIntervalRequest {
  timeInterval: TimeInterval;
  startDate?: string;
  endDate?: string;
}

export const setTimeIntervalAction = ({
  timeInterval,
  startDate,
  endDate,
}: TimeIntervalRequest): AppThunk => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionTypeEnum.GetSuperAdminReportsRequest });
    dispatch({
      type: ActionTypeEnum.SetSuperAdminReportInterval,
      payload: { timeInterval, startDate, endDate },
    });
    const tenantId = selectTenantIdFilterReport(getState());
    const tenantPlan = selectTenantPlanFilterReport(getState());
    const currency = selectSuperAdminCurrencyFilter(getState());
    const query: SuperAdminReportQueryParams = {
      tenantId: tenantId && tenantId !== "all" ? tenantId : undefined,
      interval: timeInterval,
      startDate,
      endDate,
      tenantPlan,
      currency,
    };
    const response = await getWithAuth(`${SUPER_ADMIN_REPORTS_URL}`, query);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get super admin report error", e);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsFailure,
      payload: "GetSuperAdminReports failure",
    });
    dispatch(addErrorAction("GetSuperAdminReports failure"));
  }
};

export const setTenantFilterAction = (tenantId?: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetSuperAdminReportsRequest });
    dispatch({ type: ActionTypeEnum.SetTenantIdFilterReports, payload: tenantId });
    const interval = selectSuperAdminTimeInterval(getState());
    const startDate = selectSuperAdminStartDate(getState());
    const endDate = selectSuperAdminEndDate(getState());
    const tenantPlan = selectTenantPlanFilterReport(getState());
    const currency = selectSuperAdminCurrencyFilter(getState());
    const query: SuperAdminReportQueryParams = {
      tenantId: tenantId && tenantId !== "all" ? tenantId : undefined,
      interval,
      startDate,
      endDate,
      tenantPlan,
      currency,
    };
    const response = await getWithAuth(`${SUPER_ADMIN_REPORTS_URL}`, query);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get super admin report error", e);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsFailure,
      payload: "GetSuperAdminReports failure",
    });
    dispatch(addErrorAction("GetSuperAdminReports failure"));
  }
};

export const setTenantPlanFilterAction = (tenantPlan?: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetSuperAdminReportsRequest });
    dispatch({ type: ActionTypeEnum.SetTenantPlanFilterReports, payload: tenantPlan });
    const interval = selectSuperAdminTimeInterval(getState());
    const startDate = selectSuperAdminStartDate(getState());
    const endDate = selectSuperAdminEndDate(getState());
    const currency = selectSuperAdminCurrencyFilter(getState());
    dispatch({ type: ActionTypeEnum.SetTenantIdFilterReports, payload: 'all' });
    const tenantId = selectTenantIdFilterReport(getState());
    const query: SuperAdminReportQueryParams = {
      tenantId: tenantId && tenantId !== "all" ? tenantId : undefined,
      interval,
      startDate,
      endDate,
      tenantPlan,
      currency,
    };
    const response = await getWithAuth(`${SUPER_ADMIN_REPORTS_URL}`, query);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get super admin report error", e);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsFailure,
      payload: "GetSuperAdminReports failure",
    });
    dispatch(addErrorAction("GetSuperAdminReports failure"));
  };
};


export const setCurrencyFilterAction = (currency: CurrencyType): AppThunk => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionTypeEnum.GetSuperAdminReportsRequest });
    dispatch({
      type: ActionTypeEnum.SetSuperAdminReportCurrencyFilter,
      payload: currency,
    });
    const tenantId = selectTenantIdFilterReport(getState());
    const tenantPlan = selectTenantPlanFilterReport(getState());
    const interval = selectSuperAdminTimeInterval(getState());
    const startDate = selectSuperAdminStartDate(getState());
    const endDate = selectSuperAdminEndDate(getState());
    const query: SuperAdminReportQueryParams = {
      tenantId: tenantId && tenantId !== "all" ? tenantId : undefined,
      interval,
      startDate,
      endDate,
      tenantPlan,
      currency,
    };
    const response = await getWithAuth(`${SUPER_ADMIN_REPORTS_URL}`, query);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get super admin report error", e);
    dispatch({
      type: ActionTypeEnum.GetSuperAdminReportsFailure,
      payload: "GetSuperAdminReports failure",
    });
    dispatch(addErrorAction("GetSuperAdminReports failure"));
  }
};

export const getAppStatusAction = (interval: StatusInterval): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetAppStatusRequest });
    const response = await getWithAuth(`${SUPER_ADMIN_REPORTS_URL}/app-status`, { interval });
    dispatch({
      type: ActionTypeEnum.GetAppStatusSuccess,
      payload: response.data,
    });
  } catch (e) {
    console.log("get app status error", e);
    dispatch({
      type: ActionTypeEnum.GetAppStatusFailure,
      payload: "getAppStatus failure",
    });
    dispatch(addErrorAction("getAppStatus failure"));
  }
};
