import React from 'react';
import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  Icon,
  SelectionMode
} from "office-ui-fabric-react";
import {formatScheduleDate} from "../../../../utils/formats";
import VertIcon from "../../../../assets/more-vert.svgr";
import ColorButton from "../../../../../../common/components/ColorButton";
import {CreateScheduleAddonSettingDto, ScheduleAddonSettingDto, State} from "../../../../store/types";
import {selectReadonly} from "../../../../reducers/auth-reducer";
import {connect, ConnectedProps} from "react-redux";
import './schedule.scss'
import Panel from "../../../common/Panel";
import EditScheduleAddon from "./EditScheduleAddon/EditScheduleAddon";
import {selectCurrentAddon} from "../../../../reducers/addons";
import CalendarIcon from "../../../../assets/calendar-icon.svgr";
import dayjs from "dayjs";
import {DATE_FORMAT} from "../../../../constants/timedate";
import {nanoid} from "nanoid";
import DeleteConfirmation from "../../../common/DeleteConfirmation/DeleteConfirmation";
import {removeScheduleAddonAction} from "../../../../actions/addon-action";

const newSchedule = {
  title: "",
  priority: 1,
  start: dayjs().format(DATE_FORMAT),
  end: dayjs().add(10, "d").format(DATE_FORMAT),
  timeSlotsAddon: [],
};

interface CellData {
  id: string;
  start: string;
  end: string;
}

const scheduleListStyles = {
  focusZone: {cursor: "pointer"}
}

const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddon: selectCurrentAddon(state)
});

const mapDispatchToProps = {
  removeScheduleAddon: removeScheduleAddonAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AddonsSchedule = (
  {
    readonly,
    currentAddon,
    removeScheduleAddon
  }: Props
) => {
  const [isOpenScheduleEdit, setIsOpenScheduleEdit] = React.useState(false);
  const [activeSchedule, setActiveSchedule] = React.useState<ScheduleAddonSettingDto | CreateScheduleAddonSettingDto>({
    ...newSchedule,
    addonId: currentAddon?.id || ""
  });
  const [removeSchedule, setRemoveSchedule] = React.useState({
    targetSchedule: "",
    isOpen: false
  });

  const columns = [
    {
      key: "name",
      name: "NAME",
      fieldName: "title",
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "startDate",
      name: "START DATE",
      fieldName: "start",
      minWidth: 110,
      maxWidth: 150,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return <span>{formatScheduleDate(item.start)}</span>;
      },
    },
    {
      key: "endDate",
      name: "END DATE",
      fieldName: "end",
      minWidth: 110,
      maxWidth: 150,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return <span>{formatScheduleDate(item.end)}</span>;
      },
    },
    {
      key: "priority",
      name: "PRIORITY",
      fieldName: "priority",
      minWidth: 45,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      isSorted: true,
      isSortedDescending: false,
    },
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: CellData) => {
        const menuProps = {
          shouldFocusOnMount: true,
          isBeakVisible: false,
          items: [
            {key: 'newItem', text: 'Edit', onClick: () => editSchedule(item as ScheduleAddonSettingDto)},
            {key: 'newItem', text: 'Clone', onClick: () => cloneSchedule(item as ScheduleAddonSettingDto)},
            {key: 'newItem', text: 'Delete', onClick: () => removePackageSchedule(item.id)},
          ],
        }
        return <ActionButton className="contextual-menu" menuProps={menuProps}><VertIcon/></ActionButton>;
      },
      isPadded: true,
    },
  ];

  const addSchedule = () => {
    setActiveSchedule({...newSchedule, addonId: currentAddon?.id || ""});
    setIsOpenScheduleEdit(true);
  };

  const editSchedule = (schedule: ScheduleAddonSettingDto) => {
    setActiveSchedule(schedule);
    setIsOpenScheduleEdit(true);
  };

  const cloneSchedule = (schedule: ScheduleAddonSettingDto) => {
    const {id, ...withoutId} = schedule;

    const newSchedule = {
      ...withoutId,
      title: "",
      changed: true,
      isNew: true,
      priority: schedule.priority + 1,
      timeSlotsAddon: schedule.timeSlotsAddon.map((item) => ({
        ...item,
        id: nanoid(),
        addonId: schedule.addonId,
        scheduleAddonId: "new",
        changed: true,
        isNew: true,
      })),
    };

    setActiveSchedule(newSchedule);
    setIsOpenScheduleEdit(true);
  };

  const removePackageSchedule = (id: string) => {
    setRemoveSchedule({
      targetSchedule: id,
      isOpen: true
    })
  };

  const handleConfirmationClose = (isConfirm: boolean) => {
    if (isConfirm) {
      removeScheduleAddon(removeSchedule.targetSchedule);
    }

    setRemoveSchedule({
      targetSchedule: "",
      isOpen: false
    })
  }

  const onItemInvoked = (item: any) => {
    editSchedule(item as ScheduleAddonSettingDto)
  }

  const renderRow = (props: any) => (
    <DetailsRow
      {...props}
      onClick={onItemInvoked}
    />
  );

  return (
    <>
      <Panel
        title={
          <div className='h6'>Schedule</div>
        }
        className='addons-schedule panel-shift'
      >
        {
          (currentAddon && currentAddon.schedulesAddon.length > 0) ? (
            <>
              <div className="schedule-list">
                <DetailsList
                  items={currentAddon.schedulesAddon}
                  styles={scheduleListStyles}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  getKey={(item) => item.id}
                  setKey="none"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  className="list"
                  onRenderRow={renderRow}
                />
              </div>
              <div className="add-schedule-button-container">
                <ColorButton
                  className="add-schedule-button"
                  onClick={addSchedule}
                  disabled={readonly}
                >
                  <Icon iconName="Add" className="add-icon"/>
                  <span>
              Add Schedule
            </span>
                </ColorButton>
              </div>
            </>
          ) : (
            <div className="empty-schedule-list-container">
              <div className="empty-schedule-list">
                <CalendarIcon className="calendar-icon"/>
                <div className="empty-schedule-list-message">
                  There are no schedules here yet.
                </div>
                <div className="add-schedule-button-container">
                  <ColorButton
                    className="add-schedule-button"
                    onClick={addSchedule}
                    disabled={readonly}
                  >
                    <Icon iconName="Add" className="add-icon"/>
                    <span>
                      Add Schedule
                    </span>
                  </ColorButton>
                </div>
              </div>
            </div>
          )
        }
      </Panel>
      {removeSchedule.isOpen && <DeleteConfirmation
        ConfirmationHeader={"Confirmation"}
        ConfirmationText={"Do you want to remove this schedule?"}
        ConfirmationButtonText={"Delete Schedule"}
        onClose={handleConfirmationClose}
      />}
      {isOpenScheduleEdit && <EditScheduleAddon
        timeSlotDuration={20}
        onClose={() => setIsOpenScheduleEdit(false)}
        scheduleAddon={activeSchedule}
        timeShifting={[]}
      />}
    </>
  );
};

export default connector(AddonsSchedule);