import { AddonModifierDto } from "../../../../server/src/dto/addonModifier.dto";
import {AddonSettingDto, AddonsPackageVenueRelationDto} from "../../../../server/src/dto/addonSetting.dto";

export * from "../../../../server/src/dto/addonSetting.dto";
export * from "../../../../server/src/dto/scheduleAddonSetting.dto";
export * from "../../../../server/src/dto/timeSlotAddonSetting.dto";
export * from "../../../../server/src/dto/addonModifier.dto";
export * from "../../../../server/src/dto/addonOption.dto";

export interface AddonsState {
  addons: AddonSettingDto[];
  isLoadingAddons: boolean;
  isLoadingAddon: boolean;
  isRequestInProgress: boolean;
  isImageLoadInProgress: boolean;
  currentAddon?: AddonSettingDto;
  error?: string;
  relations: AddonsPackageVenueRelationDto[];
  filteredAddons: AddonSettingDto[];
  modifiers: AddonModifierDto[];
  modifier?: AddonModifierDto;
}