import { ActionTypeEnum, AppThunk } from ".";
import {
  getWithAuth,
  postWithAuth,
  putWithAuth,
  deleteWithAuth,
  uploadFile,
} from "./api";
import {
  PackageSettingDto,
  CreatePackageSettingDto,
  SchedulePackageSettingDto,
  CreateSchedulePackageSettingDto,
  customFields,
  TripleSeatBlockedStatus,
} from "../store/types";
import { addErrorAction } from "./auth-actions";
import { setVenuePageAction } from "./ui-actions";
import { selectPackage } from "../reducers/packages";
import { activeCustomFieldsToUIModel, uiModelToActiveCustomFields } from "../../../common/utils/formats";
import { venueConfigUI } from "../constants/webConfigUI";
import { customFieldsOptions } from "../../../server/src/utils/package";

const GET_PACKAGES_URL = "/api/package-setting";
const GET_SCHEDULE_PACKAGE_URL = "/api/package-setting/schedule";
const GET_CURRENT_PACKAGE_SCHEDULE_URL = "/api/package-setting/current-schedule";

export const getPackagesAction = (venueId: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetPackages });
    const response = await getWithAuth(GET_PACKAGES_URL, { venueId });
    dispatch({
      type: ActionTypeEnum.GetPackagesSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetPackagesFailure,
      payload: "error getting packages",
    });
    dispatch(addErrorAction("Get packages failure"));
  }
};

export const createPackageAction = (
  currentPackage: CreatePackageSettingDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.CreatePackage });
    const response = await postWithAuth(GET_PACKAGES_URL, currentPackage);
    dispatch({
      type: ActionTypeEnum.CreatePackageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.CreatePackageFailure,
      payload: "error create package",
    });
    dispatch(addErrorAction("Create package failure"));
  }
};

export const createPackageAndNavigatePageAction = (
  currentPackage: CreatePackageSettingDto
): AppThunk => async (dispatch) => {
  dispatch(createPackageAction(currentPackage));
  dispatch(setVenuePageAction(venueConfigUI.PACKAGE_DETAILS));
};

export const changeVenue = (
  id: string,
  venueIds: string[],
  isSelect?: boolean
) => {
  if (isSelect) {
    const newVenueIds = [...venueIds, id]
    return newVenueIds;
  } else {
    const indexVenueId = venueIds.findIndex((venueId) => venueId === id);
    const deleteVenueId = venueIds.splice(indexVenueId, 1);
    return venueIds;
  }
};

export const removePackageAction = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.RemovePackage });
    const response = await deleteWithAuth(`${GET_PACKAGES_URL}/${id}`);
    dispatch({
      type: ActionTypeEnum.RemovePackageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemovePackageFailure,
      payload: "error remove package",
    });
    dispatch(addErrorAction("Remove package failure"));
  }
};

export const selectPackageAction = (currentPackage?: PackageSettingDto): AppThunk => async (
  dispatch
) => {
  try {
    if (currentPackage) {
      dispatch({ type: ActionTypeEnum.GetPackage });
      const response = await getWithAuth(`${GET_PACKAGES_URL}/${currentPackage.id}`);
      dispatch({
        type: ActionTypeEnum.GetPackageSuccess,
        payload: response.data,
      });
    } else {
      dispatch({
        type: ActionTypeEnum.GetPackageSuccess,
        payload: undefined,
      });
    }
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetPackageFailure,
      payload: "error update package",
    });
    dispatch(addErrorAction("Get package data failure"));
  }
};

export const getParentPackageScheduleAction = (currentPackageId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypeEnum.GetParentPackageSchedule });
    const response = await getWithAuth(`${GET_PACKAGES_URL}/parent-schedule/${currentPackageId}`);
    dispatch({
      type: ActionTypeEnum.GetParentPackageScheduleSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetParentPackageScheduleFailure,
      payload: "error get parent schedule for package",
    });
    dispatch(addErrorAction("Get parent schedule for package data failure"));
  }
};

export const selectPackageAndNavigatePageAction = (currentPackage?: PackageSettingDto): AppThunk => async (
  dispatch,
) => {
  dispatch(selectPackageAction(currentPackage));
  if (!!currentPackage) {
    dispatch(setVenuePageAction(venueConfigUI.PACKAGE_DETAILS));
  }
};

export const updatePackageAction = (currentPackage: Partial<PackageSettingDto>): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdatePackage });
    const response = await putWithAuth(GET_PACKAGES_URL, currentPackage);
    dispatch({
      type: ActionTypeEnum.UpdatePackageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdatePackageFailure,
      payload: "error update package",
    });
    dispatch(addErrorAction("Update package failure"));
  }
};

export const updatePackageAndNavigatePageAction = (currentPackage: Partial<PackageSettingDto>): AppThunk => async (
  dispatch,
) => {
  dispatch(updatePackageAction(currentPackage));
  dispatch(setVenuePageAction(venueConfigUI.PACKAGE_DETAILS));
};

export const clonePackageAction = (currentPackage: Partial<PackageSettingDto>): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch({ type: ActionTypeEnum.ClonePackage });
    const response = await postWithAuth(`${GET_PACKAGES_URL}/clone`, currentPackage);
    dispatch({
      type: ActionTypeEnum.ClonePackageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.ClonePackageFailure,
      payload: "error clone package",
    });
    dispatch(addErrorAction("Clone package failure"));
  }
};

export const clonePackageAndNavigatePageAction = (currentPackage: Partial<PackageSettingDto>): AppThunk => async (
  dispatch,
) => {
  dispatch(clonePackageAction(currentPackage));
  dispatch(setVenuePageAction(venueConfigUI.PACKAGE_DETAILS));
};

export const uploadPackageImageAction = (
  file: File,
  packageId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetPackageImageUrl });
    const imageUrl = await uploadFile(file);
    const response = await postWithAuth(`${GET_PACKAGES_URL}/update-image`, {
      value: imageUrl,
      id: packageId,
    });
    dispatch({
      type: ActionTypeEnum.GetPackageImageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetPackageImageFailure,
      payload: "error getting package image url",
    });
    dispatch(addErrorAction("Get package image url failure"));
  }
};

export const updateSchedulePackageAction = (
  schedulePackage: SchedulePackageSettingDto | CreateSchedulePackageSettingDto
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.UpdateSchedulePackage });
    const response = schedulePackage.hasOwnProperty("id")
      ? await putWithAuth(GET_SCHEDULE_PACKAGE_URL, schedulePackage)
      : await postWithAuth(GET_SCHEDULE_PACKAGE_URL, schedulePackage);

    dispatch({
      type: ActionTypeEnum.UpdateSchedulePackageSuccess,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.UpdateSchedulePackageFailure,
      payload: "error update schedule",
    });
    dispatch(addErrorAction("Update schedule failure"));
  }
};

export const removeSchedulePackageAction = (id: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ActionTypeEnum.RemoveSchedulePackage });
    await deleteWithAuth(`${GET_SCHEDULE_PACKAGE_URL}/${id}`);
    const currentPackage = selectPackage(getState());
    const updatedPackage = {
      ...currentPackage,
      schedulesPackage: currentPackage?.schedulesPackage.filter((item) => item.id !== id),
    };
    dispatch({
      type: ActionTypeEnum.RemoveSchedulePackageSuccess,
      payload: updatedPackage,
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.RemoveSchedulePackageFailure,
      payload: "error remove schedule",
    });
    dispatch(addErrorAction("Remove schedule failure"));
  }
};

export const setPackageErrorAction = (error: string) => ({
  type: ActionTypeEnum.SetPackageError,
  payload: error,
});

export const getCurrentPackageScheduleAction = (
  packageId: string,
  date: string
): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: ActionTypeEnum.GetCurrentPackageSchedule });
    const response = await getWithAuth(
      `${GET_CURRENT_PACKAGE_SCHEDULE_URL}/${packageId}/${date}`
    );
    const { schedule, bookedSlots, freeSlots } = response.data;
    dispatch({
      type: ActionTypeEnum.GetCurrentPackageScheduleSuccess,
      payload: { currentPackageSchedule: schedule, bookedSlots, freeSlots },
    });
  } catch (e) {
    dispatch({
      type: ActionTypeEnum.GetCurrentPackageScheduleFailure,
      payload: "error get current package schedule",
    });
    dispatch(addErrorAction("Get current package schedule failure"));
  }
};

export const changeActiveCustomFields = (
  customFieldKey: string,
  activeCustomFields: string,
  isSelect?: boolean,
  additionalFields?: customFieldsOptions
) => {
  let fields = activeCustomFieldsToUIModel(activeCustomFields || "")
  if (isSelect) {
    fields = fields.concat(customFieldKey);
  } else {
    const indexLocation = fields.findIndex((index) => index === customFieldKey);
    const deleteLocations = fields.splice(indexLocation, 1);
  }
  const allFields = { ...customFields, ...additionalFields };
  const customFieldKeysArray = Object.keys(allFields).filter(key => fields.includes(key))
  const customFieldKeysString = uiModelToActiveCustomFields(customFieldKeysArray);
  return customFieldKeysString;
};

export const tripleSeatPackageStatusToUIModel = (tripleSeatStatus: string): string[] => {
  const pairs = tripleSeatStatus ? tripleSeatStatus.split(",") : [];
  return pairs || "";
};

export const uiModelToTripleSeatPackageStatus = (tripleSeatStatus: string[]): string => {
  const result = tripleSeatStatus.map((status) => `${status}`).join(",");
  return result;
};

export const changeTripleSeatPackageStatus = (
  status: TripleSeatBlockedStatus,
  tripleSeatStatus: string[],
  isSelect?: boolean
) => {
  if (isSelect) {
    const newStatus = tripleSeatStatus.concat(status);
    return newStatus;
  } else {
    const indexLocation = tripleSeatStatus.findIndex((index) => index === status);
    const deleteLocations = tripleSeatStatus.splice(indexLocation, 1);
    return tripleSeatStatus;
  }
};

export const setFilteredPackagesAction = (
  packages: PackageSettingDto[]
): AppThunk => async (dispatch) => {
  dispatch({ type: ActionTypeEnum.SetFilteredPackages, payload: packages });
};