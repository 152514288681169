import React, { useEffect, useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal } from "office-ui-fabric-react";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { State } from "../../../../store/types";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import { selectIsLoadingVenue, selectSocial, selectVenue, selectVenueError } from "../../../../reducers/venues";
import { setVenueErrorAction, updateVenueAction } from "../../../../actions/venue-actions";
import "./editVenueSocialMedia.scss";

const mapDispatchToProps = {
  updateVenue: updateVenueAction,
  setError: setVenueErrorAction,
};
const mapStateToProps = (state: State) => ({
  error: selectVenueError(state),
  readonly: selectReadonly(state),
  isLoadingVenue: selectIsLoadingVenue(state),
  venue: selectVenue(state),
  social: selectSocial(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
};

const EditSocialMedia = ({
  updateVenue,
  onClose,
  setError,
  error,
  readonly,
  isLoadingVenue,
  venue,
  social,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setError("");
  }, []);
  useEffect(() => {
    if (!isLoadingVenue && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingVenue]);

  const [facebook, setFacebook] = useState(social?.facebookUrl || '');
  const [instagram, setInstagram] = useState(social?.instagramUrl || '');
  const [twitter, setTwitter] = useState(social?.twitterUrl || '');
  const [tikTok, setTikTok] = useState(social?.tikTokUrl || '');
  const [viewMenu, setViewMenu] = useState(social?.viewMenuUrl || '');


  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    saving.current = true;
    updateVenue({
      ...venue,
      facebookUrl: facebook,
      instagramUrl: instagram,
      twitterUrl: twitter,
      tikTokUrl: tikTok,
      viewMenuUrl: viewMenu,
    });
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-social-media"
    >
      <div className="title h4">
        Social Media
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="main-form" onSubmit={onUpdate}>
        <FormTextField
          label="Facebook"
          className="row"
          value={facebook}
          onChange={(_, text) => setFacebook(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Instagram"
          className="row"
          value={instagram}
          onChange={(_, text) => setInstagram(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="Twitter"
          className="row"
          value={twitter}
          onChange={(_, text) => setTwitter(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="TikTok"
          className="row"
          value={tikTok}
          onChange={(_, text) => setTikTok(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        <FormTextField
          label="View Menu"
          className="row"
          value={viewMenu}
          onChange={(_, text) => setViewMenu(text || "")}
          autoComplete="off"
          disabled={readonly}
        />
        {error && <div className="error">{error}</div>}
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
};

export default connector(EditSocialMedia);
