import React from "react";
import {
  ActionButton,
  IDropdownOption,
  Checkbox,
} from "office-ui-fabric-react";
import { minBy, orderBy } from "lodash";
import FormTextField from "../../../../../../../../../common/components/FormTextField";
import FormSelectField from "../../../../../../common/FormSelectField";
import {
  InventoryMethodTypes,
  Pricing,
  TimeSlotPackageSettingDto,
  TimeSlotParentPackageSettingDto,
} from "../../../../../../../store/types";
import { useViewport } from "../../../../../../../hooks/responsive";
import TrashIcon from "../../../../../../../assets/trash-icon.svgr";
import "./timeSlotPackageRow.scss";
import AgeGroupPricing from "../../../../../EditSchedule/TimeSlot/AgeGroupPricing";

const toggleStyle = {
  root: {
    margin: 0,
  },
};

interface OwnProps {
  slot: TimeSlotPackageSettingDto;
  times: IDropdownOption[];
  onChange: (
    slot: TimeSlotPackageSettingDto,
    record: { [key: string]: string | number | boolean }
  ) => void;
  removeSlot: (slotId: string) => void;
  timeSlotDuration: number;
  isPackageSchedule: boolean;
  inventoryMethod: InventoryMethodTypes;
  overrideParentSchedule: boolean;
  parentSlots: TimeSlotParentPackageSettingDto[];
  ageForPolicy: string;
  ageGroups?: string;
  packageMethod?: Pricing;
}

export const TimeSlotPackageRow = ({
  slot,
  times,
  onChange,
  removeSlot,
  timeSlotDuration,
  isPackageSchedule,
  inventoryMethod,
  overrideParentSchedule,
  parentSlots,
  ageForPolicy,
  ageGroups,
  packageMethod,
}: OwnProps) => {
  const { isMobile } = useViewport();
  const isShowParamsForSchedule = isPackageSchedule || overrideParentSchedule;
  const isAvailabilityDisabled =
    inventoryMethod === InventoryMethodTypes.byGuests ||
    inventoryMethod === InventoryMethodTypes.byResources ||
    !isShowParamsForSchedule;
  const isGuestCapacityDisabled =
    inventoryMethod === InventoryMethodTypes.byLanes ||
    inventoryMethod === InventoryMethodTypes.byResources ||
    !isShowParamsForSchedule;

  const filteredParentSlots =
    parentSlots.filter(
      (parentSlot) =>
        slot.start <= parentSlot.end && slot.end >= parentSlot.start
    ) || [];
  const minLanesCount =
    minBy(filteredParentSlots, (parentSlot) => parentSlot.lanesCount)
      ?.lanesCount || undefined;
  const firstParentSlots =
    orderBy(filteredParentSlots, ["slot"], ["asc"])[0] || [];

  const onChangeLanesCount = (text?: string) => {
    const lanesCount = text && !isNaN(+text) ? +text : 0;
    if (
      !(
        minLanesCount &&
        lanesCount > minLanesCount &&
        overrideParentSchedule &&
        !isPackageSchedule
      )
    ) {
      onChange(slot, { lanesCount: text || "" });
    }
  };
  const onChangeStart = (data?: string | number) => {
    const value: number = +(data as number) || 0;
    onChange(slot, { startTime: value * timeSlotDuration, start: value });
  };
  const onChangeEnd = (data?: string | number) => {
    const value: number = +(data as number) || 0;
    onChange(slot, { endTime: value * timeSlotDuration, end: value });
  };

  const lanesCount =
    slot?.lanesCount && !isNaN(+slot.lanesCount) ? +slot.lanesCount : 0;
  if (
    minLanesCount &&
    lanesCount > minLanesCount &&
    overrideParentSchedule &&
    !isPackageSchedule
  ) {
    onChange(slot, { lanesCount: String(minLanesCount) });
  }

  const isAgeGroupBased =
    (packageMethod === Pricing.ageGroupsFlatRate ||
      packageMethod === Pricing.ageGroupsPerHour) &&
    ageGroups;
  return (
    <div className={`timeSlotPackageRow ${isMobile ? "mobile" : ""}`}>
      <FormSelectField
        label="Start time"
        options={times}
        className={`dropdown ${isMobile ? "mobile" : ""}`}
        selectedKey={slot.start}
        onChange={(_: any, option) => onChangeStart(option?.key || 0)}
      />
      <div className="divider" />
      <FormSelectField
        label="End time"
        options={times}
        className={`dropdown ${isMobile ? "mobile" : ""}`}
        selectedKey={slot.end}
        onChange={(_: any, option) => onChangeEnd(option?.key || 0)}
      />
      {!isMobile && <div className="divider" />}
      {isAgeGroupBased ? (
        <AgeGroupPricing
          ageGroupPricing={slot.ageGroupPricing}
          ageGroups={ageGroups || ""}
          onSave={(ageGroupPricing) => {
            onChange(slot, { ageGroupPricing: ageGroupPricing || "" });
          }}
        >
          <FormTextField
            label="Rate"
            className={`${isMobile ? "mobile" : ""} dropdown-rate`}
            value={"Edit"}
            onChange={(_: any, text?: string) =>
              onChange(slot, { rate: text || "" })
            }
            autoComplete="off"
          />
        </AgeGroupPricing>
      ) : (
        <FormTextField
          label="Rate"
          className={isMobile ? "mobile" : ""}
          value={String(slot.rate)}
          onChange={(_: any, text?: string) =>
            onChange(slot, { rate: text || "" })
          }
          autoComplete="off"
        />
      )}

      <div className="divider" />
      <FormTextField
        label="Available"
        className={isMobile ? "mobile" : ""}
        value={
          isAvailabilityDisabled
            ? String(firstParentSlots?.lanesCount || "")
            : String(slot.lanesCount)
        }
        onChange={(_: any, text?: string) => onChangeLanesCount(text || "")}
        autoComplete="off"
        disabled={isAvailabilityDisabled}
      />
      <div className="divider" />
      <FormTextField
        label="Staff Cap."
        className={isMobile ? "mobile" : ""}
        value={
          isShowParamsForSchedule
            ? String(slot.staffCapacity)
            : String(firstParentSlots?.staffCapacity || "")
        }
        onChange={(_: any, text?: string) =>
          onChange(slot, { staffCapacity: text || "" })
        }
        autoComplete="off"
        disabled={!isShowParamsForSchedule}
      />
      <div className="divider" />
      <FormTextField
        label="Guests Cap."
        className={isMobile ? "mobile" : ""}
        value={
          isGuestCapacityDisabled
            ? String(firstParentSlots?.guestsCapacity || "")
            : String(slot.guestsCapacity)
        }
        onChange={(_: any, text?: string) =>
          onChange(slot, { guestsCapacity: text || "" })
        }
        autoComplete="off"
        disabled={isGuestCapacityDisabled}
      />
      <div className="divider" />
      <div className="check-21">
        <div className="check-label body2">{ageForPolicy || "21"}+</div>
        <Checkbox
          checked={slot.is21plus}
          onChange={(_, isChecked) => onChange(slot, { is21plus: !!isChecked })}
          styles={toggleStyle}
        />
      </div>
      <ActionButton
        onClick={() => removeSlot(slot.id)}
        styles={{ root: { height: 20, marginBottom: "10px" } }}
      >
        <TrashIcon />
      </ActionButton>
    </div>
  );
};
