import React from "react";
import { v4 as uuid } from "uuid";
import { connect, ConnectedProps } from "react-redux";
import AddonModifierInfoSection from "./Info/Info";
import { useViewport } from "../../../hooks/responsive";
import EditAddonModifier, {
  modifierTypeToText,
} from "./Edit/EditAddonModifier";
import { selectModifier, selectIsLoadingAddon } from "../../../reducers/addons";
import { AddonOptionDto, ModifierType, State } from "../../../store/types";
import "./general.scss";
import Panel from "../../common/Panel";
import LinkButton from "../../../../../common/components/LinkButton";
import FormTextField from "../../../../../common/components/FormTextField";
import { Icon, IconButton, Spinner, SpinnerSize } from "office-ui-fabric-react";
import TrashIcon from "../../../assets/trash-icon.svgr";
import { updateModifierAction } from "../../../actions/addon-action";
import ModifiersControls from "./Controls/Controls";

const mapDispatchToProps = {
  updateAddonModifier: updateModifierAction,
};

const mapStateToProps = (state: State) => ({
  currentAddonModifier: selectModifier(state),
  isLoadingAddonModifier: selectIsLoadingAddon(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

type InfoItems = { name: string; value: string | number; isHTML?: boolean }[];

const AddonModifierGeneralDetails = ({
  currentAddonModifier,
  isLoadingAddonModifier,
  updateAddonModifier,
}: Props) => {
  const { isMobile } = useViewport();
  const [isEditing, setIsEditing] = React.useState(false);
  const [values, setValues] = React.useState(
    currentAddonModifier?.options || []
  );

  let items = {
    infoItems: [] as InfoItems,
    detailItems: [] as InfoItems,
  };

  if (currentAddonModifier) {
    const infoItems: InfoItems = [
      {
        name: "Modifier Title",
        value: currentAddonModifier.title,
      },
      {
        name: "Internal Name",
        value: currentAddonModifier.name,
      },
    ];

    const detailItems: InfoItems = [
      {
        name: "Priority",
        value: currentAddonModifier.priority,
      },
      {
        name: "Modifier Type",
        value: modifierTypeToText[currentAddonModifier.modifierType],
      },
      {
        name: "Minimum Quantity",
        value: currentAddonModifier.minimum,
      },
      {
        name: "Maximum Quantity",
        value: currentAddonModifier.maximum,
      },
    ];

    items = {
      infoItems,
      detailItems,
    };
  }

  const onCreate = () => {
    const data = [...values, { id: uuid(), name: "", priority: 1, price: 0 }];
    setValues(data);
  };
  const onDelete = (id: string) => {
    const data = values.filter((item) => item.id !== id);
    setValues(data);
  };
  const onValueChange = (id: string, key: keyof AddonOptionDto, value: any) => {
    const data = values.map((item) =>
      item.id === id ? { ...item, [key]: value } : item
    );
    setValues(data);
  };

  if (currentAddonModifier?.modifierType === ModifierType.singleSelect)
    items.detailItems = items.detailItems.filter(
      (item) =>
        item.name !== "Minimum Quantity" && item.name !== "Maximum Quantity"
    );

  return (
    <div className={`addon-general ${isMobile ? "mobile" : ""}`}>
      {isLoadingAddonModifier && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className="left-panel">
        <AddonModifierInfoSection
          onEdit={() => setIsEditing(true)}
          title="Modifier Info"
          items={items.infoItems}
          isGrid
        />
        <AddonModifierInfoSection
          onEdit={() => setIsEditing(true)}
          title="Modifier Details"
          items={items.detailItems}
          isGrid
        />
        <Panel
          className="addon-tags panel-shift"
          title={
            <>
              <div className="h6">Options</div>
              <LinkButton
                onClick={() =>
                  updateAddonModifier({
                    ...currentAddonModifier,
                    options: values.filter((item) => item.name !== ""),
                  })
                }
              >
                Save
              </LinkButton>
            </>
          }
        >
          {values.map((item) => (
            <div className={`fields-mapping `} key={item.id}>
              <FormTextField
                label="Name"
                className="field"
                value={item.name}
                onChange={(_: any, value?: string) =>
                  onValueChange(item.id, "name", value || "")
                }
                autoComplete="off"
              />

              <FormTextField
                label="Priority"
                className="field"
                type="number"
                value={"" + item.priority || "0"}
                onChange={(_: any, value?: string) =>
                  onValueChange(item.id, "priority", value ? +value : 0)
                }
                autoComplete="off"
              />
              <FormTextField
                label="Price"
                className="field"
                type="number"
                value={"" + item.price || "0"}
                onChange={(_: any, value?: string) =>
                  onValueChange(item.id, "price", value ? +value : 0)
                }
                autoComplete="off"
              />

              <IconButton
                className="delete-button"
                ariaLabel="Remove Revenue Category"
                onClick={() => onDelete(item.id)}
              >
                <TrashIcon />
              </IconButton>
            </div>
          ))}
          <div className={""}>
            <LinkButton
              className="add-button"
              onClick={onCreate}
              disabled={
                values.length > 0 && values[values.length - 1].name === ""
              }
            >
              <Icon iconName="Add" className="add" />
              Add Option
            </LinkButton>
          </div>
        </Panel>
      </div>
      <div className={`right-panel ${isMobile ? "mobile" : ""}`}>
        <ModifiersControls />
      </div>

      {isEditing && (
        <EditAddonModifier
          isCloneAddonModifier={false}
          onClose={() => setIsEditing(false)}
        />
      )}
    </div>
  );
};

export default connector(AddonModifierGeneralDetails);
