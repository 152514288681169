import React, { useState, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Modal } from "office-ui-fabric-react";
import CancelButton from "../../../../../common/components/CancelButton";
import FormTextField from "../../../../../common/components/FormTextField";
import LinkButton from "../../../../../common/components/LinkButton";
import ColorButton from "../../../../../common/components/ColorButton";
import { createPartnerAction, setPartnerErrorAction } from "../../../actions/partners-actions";
import { selectIsPartnerRequestInProgress, selectPartnerError } from "../../../reducers/partners";
import { State } from "../../../store/types";
import "./createPartner.scss";

const mapDispatchToProps = {
  createPartner: createPartnerAction,
  setPartnerError: setPartnerErrorAction,
};

const mapStateToProps = (state: State) => ({
  isLoadingPartner: selectIsPartnerRequestInProgress(state),
  error: selectPartnerError(state),
});

interface OwnProps {
  onClose: () => void;
  partnerId?: string;
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const CreatePartner = ({
  createPartner,
  onClose,
  setPartnerError,
  partnerId,
  isLoadingPartner,
  error,
}: Props) => {
  const saving = useRef(false);
  useEffect(() => {
    setPartnerError("");
  }, []);
  useEffect(() => {
    if (!isLoadingPartner && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingPartner]);

  const [name, setName] = useState('');

  const onCreatePartner = (e: React.FormEvent) => {
    e.preventDefault();
    if(name) {
      saving.current = true;
      createPartner({ name })
      } else {
      setPartnerError(`${partnerId ? "Clone" : "Create" } partner failure`);
    }
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="create-partner"
    >
      <div className="title h4">
        {partnerId ? "Clone" : "Create New"} Partner
        <CancelButton onClick={onClose} />
      </div>
      <form className="form-partner" onSubmit={onCreatePartner}>
        <FormTextField
          label="Name"
          className="row"
          value={name}
          onChange={(_: any, text?: string) => setName(text || "")}
          autoComplete="off"
          required={true}
        />
        <div className="buttons-container">
          <LinkButton onClick={onClose}>Cancel</LinkButton>
          <ColorButton type="submit" >
            Save Change
          </ColorButton>
        </div>
      </form>
    </Modal>
  );
}

export default connector(CreatePartner);
