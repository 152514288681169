import { CurrencyType } from "../../../../server/src/entities/enums";
import { SuperAdminReportDto, AppStatusDto } from "../../../../server/src/dto/report.dto";
import { TimeInterval } from "../../../../server/src/dto/reportQuery.dto";

export * from "../../../../server/src/dto/report.dto";
export * from "../../../../server/src/dto/reportQuery.dto";

export interface SuperAdminReportState {
  isRequestInProgress: boolean;
  superAdminReport?: SuperAdminReportDto;
  error?: string;
  tenantIdForFilterReport?: string;
  timeInterval: TimeInterval;
  startDate?: string;
  endDate?: string;
  tenantPlanForFilterReport?: string;
  currency: CurrencyType;
  appStatus?: AppStatusDto;
}
