import { ReservationConfirmationDto, ReservationDto } from "../../../../server/src/dto/reservation.dto";
import { PaymentType } from "../../../../server/src/entities/enums";

export * from "../../../../server/src/dto/reservation.dto";
export { PaymentType } from "../../../../server/src/entities/enums";

interface PaymentDetails {
  cardNumber: string;
  cardBin: string;
  cardLast4: string;
  cardType: string;
  expiryMonth: string;
  expiryYear: string;
  cardholderName: string;
}
interface StripePaymentDetails {
  cardLast4: string;
  cardType: string;
  paymentMethodId: string;
}
interface FortisPaymentDetails {
  created_ts: number;
  created_user_id: string;
  exp_date: string;
  expires_ts: number;
  first_six: string;
  last_four: string;
  location_id: string;
}
export interface Payment {
  paymentReference: string;
  details: PaymentDetails | StripePaymentDetails | FortisPaymentDetails;
  postalCode: string;
  paymentType: PaymentType;
}

export interface BookingReservationState {
  confirmed: boolean;
  isPaymentInProgress: boolean;
  isRequestInProgress: boolean;
  paymentCompleted: boolean;
  reservation: ReservationDto;
  confirmation?: ReservationConfirmationDto;
  error?: string;
  fortisClientToken?: string;
}
