import { CurrencyType, StatusInterval } from "../entities/enums";
import { FilterReservationParameters } from "./reservationQuery.dto";

export enum TimeInterval {
  TODAY = 'today',
  THIS_MONTH = 'thisMonth',
  LAST_30_DAYS = 'last30Days',
  LAST_MONTH = 'lastMonth',
  LAST_3_MONTHS = 'last3Months',
  LAST_6_MONTHS = 'last6Months',
  YEAR_TO_DATE = 'yearToDate',
  LAST_YEAR = 'lastYear',
  ALL_TIME = 'allTime',
  CUSTOM = 'custom',
}
export interface ReportQueryParams {
  venueId?: string;
  interval?: TimeInterval;
  startDate?: string;
  endDate?: string;
  filterParameters?: FilterReservationParameters[];
}
export interface SuperAdminReportQueryParams {
  tenantId?: string;
  interval?: TimeInterval;
  startDate?: string;
  endDate?: string;
  tenantPlan?: string;
  currency?: CurrencyType;
}

export interface AppStatusQueryParams {
  interval: StatusInterval;
}

export interface ReportV2QueryParams {
  venueIds?: string[];
  interval?: TimeInterval;
  startDate?: string;
  endDate?: string;
}