import { createSelector } from "reselect";
import { nanoid } from "nanoid";
import { ReportState, ReportV2Dto, State, TimeInterval } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";

const initialState: ReportState = Object.freeze({
  isRequestInProgress: false,
  timeInterval: TimeInterval.TODAY,
  filterParameters: [{
    id: nanoid(),
    filterParameter: null,
    filterCondition: null,
    filterValue: "",
  }],
  venueIdsForFilterReportV2: ["all"],
});

export default createReducer<ReportState>(initialState, {
  [ActionTypeEnum.GetReportsRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetReportsSuccess]: (report) => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    report,
  }),
  [ActionTypeEnum.GetReportsFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetVenueIdFilterReports]: (venueIdForFilterReport) => (state) => ({
    ...state,
    venueIdForFilterReport,
  }),
  [ActionTypeEnum.SetReportInterval]: ({
    timeInterval,
    startDate,
    endDate,
  }) => (state) => ({
    ...state,
    timeInterval,
    startDate,
    endDate,
  }),
  [ActionTypeEnum.ReportDownloadRequest]: () => (state) => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.ReportDownloadSuccess]: () => (state) => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
  }),
  [ActionTypeEnum.ReportDownloadFailure]: (error) => (state) => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetFilterParametersForReport]: (filterParameters) => (state) => ({
    ...state,
    filterParameters,
  }),
  [ActionTypeEnum.GetReportsV2Request]: () => (state: ReportState): ReportState => ({
    ...state,
    isRequestInProgress: true,
    error: undefined,
  }),
  [ActionTypeEnum.GetReportsV2Success]: (reportV2: ReportV2Dto) => (state: ReportState): ReportState => ({
    ...state,
    error: undefined,
    isRequestInProgress: false,
    reportV2,
  }),
  [ActionTypeEnum.GetReportsV2Failure]: (error: any) => (state: ReportState): ReportState => ({
    ...state,
    isRequestInProgress: false,
    error,
  }),
  [ActionTypeEnum.SetVenueIdsFilterReportsV2]: (venueIdsForFilterReportV2: string[]) => (state: ReportState): ReportState => ({
    ...state,
    venueIdsForFilterReportV2,
  }),
});
export const selectReportsState = (state: State) => state.reports;
export const selectReport = createSelector(
  selectReportsState,
  (state) => state.report
);
export const selectReportV2 = createSelector(
  selectReportsState,
  (state) => state.reportV2
);
export const selectIsReportRequestInProgress = createSelector(
  selectReportsState,
  (state) => state.isRequestInProgress
);
export const selectVenueIdFilterReport = createSelector(
  selectReportsState,
  (state) => state.venueIdForFilterReport
);
export const selectVenueIdsForFilterReportV2 = createSelector(
  selectReportsState,
  (state) => state.venueIdsForFilterReportV2
);
export const selectTimeInterval = createSelector(
  selectReportsState,
  (state) => state.timeInterval
);
export const selectStartDate = createSelector(
  selectReportsState,
  (state) => state.startDate
);
export const selectEndDate = createSelector(
  selectReportsState,
  (state) => state.endDate
);
export const selectReportFilterParameters = createSelector(
  selectReportsState,
  (state) => state.filterParameters
);
