import {ResourceRelationsState} from "../store/types";
import {createReducer} from "./reducer-utils";
import {ActionTypeEnum} from "../actions";
import {State} from "../store/types";
import {createSelector} from "reselect";

const initialState: ResourceRelationsState = Object.freeze({
  isLoadingResourceRelations: false,
  error: '',
  resourceRelations: [],
})

export default createReducer<ResourceRelationsState>(initialState, {
  [ActionTypeEnum.GetResourceRelations]: () => (state) => ({
    ...state,
    isLoadingResourceRelations: true,
    error: '',
    resourceRelations: []
  }),
  [ActionTypeEnum.GetResourceRelationsSuccess]: (resourceRelations) => (state) => ({
    ...state,
    isLoadingResourceRelations: false,
    resourceRelations,
    error: ''
  }),
  [ActionTypeEnum.GetResourceRelationsFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceRelations: false,
    error,
  }),
  [ActionTypeEnum.UpdateResourceRelations]: () => (state) => ({
    ...state,
    isLoadingResourceRelations: true,
    error: '',
  }),
  [ActionTypeEnum.UpdateResourceRelationsSuccess]: () => (state) => ({
    ...state,
    isLoadingResourceRelations: false,
    error: '',
  }),
  [ActionTypeEnum.UpdateResourceRelationsFailure]: (error) => (state) => ({
    ...state,
    isLoadingResourceRelations: false,
    error,
  })
});

export const selectResourceRelationsState = (state: State) => state.resourceRelations;

export const selectResourceRelations = createSelector(
  selectResourceRelationsState,
  (state) => state.resourceRelations
);

export const selectIsLoadingResourceRelations = createSelector(
  selectResourceRelationsState,
  (state) => state.isLoadingResourceRelations
)