import {AddonDiscountDto, DiscountSettingDto, DiscountsPackageVenueRelationDto, TimeSlotDiscountDto} from "../../../../server/src/dto/discountSettings.dto";


export * from "../../../../server/src/dto/discountSettings.dto";

export interface DiscountsState {
  discounts: DiscountSettingDto[];
  isRequestInProgress: boolean;
  currentDiscount?: DiscountSettingDto;
  error?: string;
  relations: DiscountsPackageVenueRelationDto[];
  slots: TimeSlotDiscountDto[];
  filteredDiscounts: DiscountSettingDto[];
  addons: AddonDiscountDto[];
}