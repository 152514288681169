import React, { useEffect, useMemo, useRef, useState } from 'react';
import FormSelectField from "../../../common/FormSelectField";
import { IDropdownOption, Modal, Toggle, } from "office-ui-fabric-react";
import { AddonSettingDto, DepositPercentageType, AddonType, Pricing, State } from "../../../../store/types";
import FormTextField from "../../../../../../common/components/FormTextField";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import CancelButton from "../../../../../../common/components/CancelButton";
import "./editAddon.scss";
import { useViewport } from "../../../../hooks/responsive";
import { selectReadonly, selectWebConfigUI } from "../../../../reducers/auth-reducer";
import { connect, ConnectedProps } from "react-redux";
import { selectAddonError, selectCurrentAddon, selectIsLoadingAddon } from "../../../../reducers/addons";
import { webConfigUI } from '../../../../constants/webConfigUI';
import {
  cloneAddonAndNavigatePageAction,
  createAddonAndNavigatePageAction,
  updateAddonAction,
  updateAddonAndNavigatePageAction,
} from '../../../../actions/addon-action';
import FormHtmlTextField from "../../../FormHtmlTextField/FormHtmlTextField";

export const DepositPercentageTypeText = {
  [DepositPercentageType.noDeposit as string]: 'No Deposit (0%)',
  [DepositPercentageType.usePackageDeposit as string]: 'Use Package Deposit',
}

interface OwnProps {
  onClose: () => void;
  isCloneAddon: boolean;
}

const mapDispatchToProps = {
  createAddonAndNavigatePage: createAddonAndNavigatePageAction,
  updateAddonAndNavigatePage: updateAddonAndNavigatePageAction,
  updateAddon: updateAddonAction,
  cloneAddonAndNavigatePage: cloneAddonAndNavigatePageAction,
};
const mapStateToProps = (state: State) => ({
  readonly: selectReadonly(state),
  currentAddon: selectCurrentAddon(state),
  isLoadingAddon: selectIsLoadingAddon(state),
  error: selectAddonError(state),
  webConfigUIPage: selectWebConfigUI(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditAddon = (
  {
    readonly,
    onClose,
    currentAddon,
    isLoadingAddon,
    error,
    isCloneAddon,
    webConfigUIPage,
    createAddonAndNavigatePage,
    updateAddonAndNavigatePage,
    updateAddon,
    cloneAddonAndNavigatePage,
  }: Props
) => {
  const saving = useRef(false);
  useEffect(() => {
    if (!isLoadingAddon && !error && saving.current) {
      saving.current = false;
      onClose();
    }
  }, [isLoadingAddon]);
  const { isMobile } = useViewport();
  const [values, setValues] = useState(currentAddon)

  useEffect(() => {
    if (!values?.addonType) {
      setValues((values) => values ? { ...values, addonType: AddonType.packageBased } : undefined)
    }
  }, [values])

  const errorMessages = useMemo(() => {
    const errors = {
      minimumQuantity: "",
      maximumQuantity: ""
    }

    if (!values || !values.minimumQuantity || !values.maximumQuantity) return errors

    if (values.minimumQuantity <= 0) {
      errors.minimumQuantity = "Min quantity must be greater than 0"
    } else if (values.minimumQuantity > values.maximumQuantity) {
      errors.minimumQuantity = "Min quantity must be less than max quantity"
    }

    if (values.maximumQuantity <= 0) {
      errors.maximumQuantity = "Max quantity must be greater than 0"
    } else if (values.maximumQuantity < values.minimumQuantity) {
      errors.maximumQuantity = "Max quantity must be greater than min quantity"
    }

    return errors
  }, [values]);

  const depositOptions = Object.entries(DepositPercentageType).map(([key]) => ({
    key,
    text: DepositPercentageTypeText[key]
  }))
  const onValueChange = (key: keyof AddonSettingDto, value: any) => {
    setValues({ ...values, [key]: value } as AddonSettingDto)
  }

  const onChangeShortDescription = (text?: string) => {
    onValueChange("shortDescription", text?.slice(0, 140))
  }

  const getPatch = () => {
    if (!values) {
      return;
    }
    return {
      name: values.name,
      priority: values.priority || 1,
      description: values.description,
      shortDescription: values.shortDescription,
      minimumQuantity: values.minimumQuantity,
      maximumQuantity: values.maximumQuantity,
      price: values.price,
      depositPercentage: values.depositPercentage,
      image: values.image,
      method: values.method,
      addonType: values.addonType,
      internalName: values.internalName,
      matchGuestCount: values.matchGuestCount,
    };
  }

  const _onUpdate = (e: React.FormEvent) => {
    e.preventDefault();

    if (errorMessages.minimumQuantity || errorMessages.maximumQuantity || !values) {
      return;
    }

    if (currentAddon) {
      saving.current = true;
      const patch = getPatch();
      isCloneAddon
        ? cloneAddonAndNavigatePage({ ...currentAddon, ...patch })
        : webConfigUIPage === webConfigUI.ADDON_DETAILS
          ? updateAddon({ ...currentAddon, ...patch })
          : updateAddonAndNavigatePage({ ...currentAddon, ...patch });
    } else {
      if (!(values?.name &&
        values?.description &&
        values?.shortDescription &&
        values?.method &&
        values?.minimumQuantity !== null &&
        values?.minimumQuantity !== undefined &&
        values?.minimumQuantity >= 0 &&
        values?.maximumQuantity !== null &&
        values?.maximumQuantity !== undefined &&
        values?.maximumQuantity >= 0 &&
        values?.maximumQuantity >= values?.minimumQuantity &&
        values
      )) {
        return
      }
      saving.current = true;
      createAddonAndNavigatePage({
        name: values.name,
        priority: values.priority || 0,
        description: values.description,
        shortDescription: values.shortDescription,
        status: false,
        required: false,
        minimumQuantity: values.minimumQuantity,
        maximumQuantity: values.maximumQuantity,
        price: values.price,
        depositPercentage: values.depositPercentage,
        image: '',
        schedulesAddon: [],
        method: values.method,
        attributions: '',
        tags: '',
        addonType: values.addonType,
        allowCustomerNotes: false,
        internalName: values.internalName,
        matchGuestCount: values.matchGuestCount,
      });
    }
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="edit-addon"
    >
      <div className="title h4">
        {currentAddon ? 'Update' : 'Create'} Add on
        <CancelButton onClick={() => onClose()} />
      </div>
      <form className="addon" onSubmit={_onUpdate}>
        <div className="edit-package-fields">
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Add On Name"
              className="long-field"
              value={values?.name}
              onChange={(_, text) => onValueChange('name', text)}
              autoComplete="off"
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Add On Priority"
              className="short-field"
              value={values?.priority ? String(values?.priority) : ""}
              onChange={(_, text) => onValueChange('priority', parseFloat(text || "") || 0)}
              autoComplete="off"
              disabled={readonly}
            />
          </div>
          <div className="row">
            <FormTextField
              label="Add On Internal Name"
              value={values?.internalName}
              onChange={(_, text) => onValueChange("internalName", text)}
              autoComplete="off"
              disabled={readonly}
              required
            />
          </div>
          <div className="row">
            <FormTextField
              label="Short Description"
              value={values?.shortDescription}
              multiline rows={2} resizable={false}
              onChange={(_, text) => onChangeShortDescription(text)}
              autoComplete="off"
              disabled={readonly}
              required
            />
          </div>
          <div className="row">
            <FormHtmlTextField
              label="Long Description"
              value={values?.description}
              disabled={readonly}
              setValue={(value) => onValueChange('description', value)}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label="Pricing Method"
              options={[{
                key: Pricing.flatRate,
                text: "Flat Rate",
              }]}
              selectedKey={values?.method as Pricing}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => onValueChange('method', item?.key as Pricing)}
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Price"
              type="number"
              value={String(values?.price)}
              onChange={(_, value) =>
                onValueChange('price', parseFloat(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly}
              required
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormSelectField
              label="Add On Type"
              options={[{
                key: AddonType.packageBased,
                text: "Package Based",
              }, {
                key: AddonType.guestCount,
                text: "Guest Count",
              }]}
              selectedKey={values?.addonType as AddonType}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => onValueChange("addonType", item?.key as AddonType)}
              disabled={readonly}
              required
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormSelectField
              label="Deposit Percentage"
              options={depositOptions}
              selectedKey={values?.depositPercentage as DepositPercentageType}
              onChange={(
                _: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption
              ) => onValueChange("depositPercentage", item?.key as DepositPercentageType)}
              disabled={readonly}
              required
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <Toggle
              label="Match Guest Count"
              className="row"
              checked={values?.matchGuestCount}
              onChange={(_, value) => onValueChange("matchGuestCount", Boolean(value))}
              inlineLabel
              disabled={readonly}
            />
          </div>
          <div className={`row ${isMobile ? "mobile" : ""}`}>
            <FormTextField
              label="Minimum Quantity"
              type="number"
              value={String(values?.minimumQuantity)}
              onChange={(_: any, value?: string) =>
                onValueChange('minimumQuantity', parseFloat(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly || values?.matchGuestCount}
              required
              errorMessage={errorMessages.minimumQuantity}
            />
            {!isMobile && (<div className="delimiter" />)}
            <FormTextField
              label="Maximum Quantity"
              type="number"
              value={String(values?.maximumQuantity)}
              onChange={(_: any, value?: string) =>
                onValueChange('maximumQuantity', parseFloat(value || "") || 0)
              }
              autoComplete="off"
              disabled={readonly || values?.matchGuestCount}
              required
              errorMessage={errorMessages.maximumQuantity}
            />
          </div>
          <div className="buttons-container">
            <LinkButton onClick={onClose}>Cancel</LinkButton>
            <ColorButton
              type="submit"
              disabled={readonly || !!errorMessages.minimumQuantity || !!errorMessages.maximumQuantity}
            >
              Save Changes
            </ColorButton>
          </div>
        </div>
      </form>
    </Modal>
  )
};

export default connector(EditAddon);