import { ActionTypeEnum, AppThunk } from ".";
import { getWithAuth } from "./api";
import { UIConfigDto } from "../store/types";
import { webConfigUI, venueConfigUI } from "../constants/webConfigUI";

const UI_CONFIG_URL = "/api/ui-config/settings";

export const getUIConfigAction = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypeEnum.GetUIConfig });
      const response = await getWithAuth(UI_CONFIG_URL);
      const config: UIConfigDto = response.data;

      const fav = document.querySelector("head link[rel~='icon']");
      if (fav && config.homeFavicon) {
        (fav as any).href = config.homeFavicon;
      }

      dispatch({
        type: ActionTypeEnum.GetUIConfigSuccess,
        payload: config,
      });
    } catch (e) {
      console.log("config error", e);
      dispatch({
        type: ActionTypeEnum.GetUIConfigFailure,
        payload: "Sorry. Something goes wrong, we'll fix it soon.",
      });
    }
  };
};

export const setWebConfigUIAction = (ui: webConfigUI) => ({
  type: ActionTypeEnum.setWebConfigUI,
  payload: ui,
});

export const setVenuePageAction = (venuePage: venueConfigUI) => ({
  type: ActionTypeEnum.setVenuePage,
  payload: venuePage,
});
