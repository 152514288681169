import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { InputField } from "../../common/InputField/InputField";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import ButtonsRow from "../../common/ButtonsRow";
import { forgetPasswordAction } from "../../../actions/auth-actions";
import { State } from "../../../store/types";
import {
  selectAuthError,
  selectIsResetPasswordSent,
} from "../../../reducers/auth-reducer";
import RexIcon from "../../../assets/rex-black.png";
import { isValidEmail } from "../../../../../common/utils/validate";
import "./recover.scss";

const mapDispatchToProps = {
  forgetPassword: forgetPasswordAction,
};
const mapStateToProps = (state: State) => ({
  error: selectAuthError(state),
  isResetPasswordSent: selectIsResetPasswordSent(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & { toLogin: () => void };

const Recover = ({
  forgetPassword,
  toLogin,
  isResetPasswordSent,
  error,
}: Props) => {
  const [username, setUserName] = useState<string | undefined>("");
  const [errorUsername, setErrorUserName] = useState<string>("");
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorUserName("");
    if (username && isValidEmail(username)) {
      forgetPassword({ username });
    } else {
      setErrorUserName('Please enter a valid email address.')
    }
  };
  const onUserNameChange = (_: any, text?: string) => {
    setErrorUserName("");
    setUserName(text);
  };
  return (
    <form className="recover" onSubmit={onSubmit}>
      <img className="logo-icon" src={RexIcon} />
      <div className="title">Welcome to REX!</div>
      <div className="sub-title">Enter an email to reset password.</div>
      <InputField
        label="Email"
        autoComplete="email"
        value={username}
        onChange={onUserNameChange}
        errorMessage={errorUsername}
      />
      {error && <div className="error">{error}</div>}
      {isResetPasswordSent && (
        <div className="info">
          Please check your email to get reset password link.
        </div>
      )}
      <ButtonsRow>
        <PrimaryButton type="submit" backgroundColor="#00f">
          Reset Password
      </PrimaryButton>
        <PrimaryButton
          backgroundColor="#FFF"
          textColor="#000"
          onClick={() => toLogin()}
        >
          Cancel
      </PrimaryButton>
      </ButtonsRow>
    </form>
  );
};

export default connector(Recover);
