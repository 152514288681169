import { Fade, IconButton, Popper, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CancelButton from '../../../../../../common/components/CancelButton';
import ActionsButton from '../../../common/ActionsButton';
import FormTextField from '../../../../../../common/components/FormTextField';
import { Toggle } from 'office-ui-fabric-react';
import { AgeGroup } from '../../../../constants/packageTypes';
import { parseAgeGroupPricing, parseAgeGroups, serializeAgeGroupPricing } from '../../../../../../server/src/utils/ageGroups'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            border: '1px solid #C5C5C5',
            backgroundColor: '#FFF',
            zIndex: 1000,
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
            padding: '30px 22px',
            borderRadius: '8px',
            position: 'relative',
            width: '400px',
        },
        selectsContainer: {
            display: 'flex',
            gap: '24px',
            flexDirection: 'column',
            marginBottom: '10px',
        },
        button: {
            width: '100%',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    })
);

interface Props {
    ageGroups: string;
    onSave: (ageGroupPricing: string) => void;
    children: React.ReactNode;
    ageGroupPricing?: string;
}


interface FormData {
    [key: string]: {
        rate: number;
        isOn: boolean;
    };
}

const AgeGroupPricing: React.FC<Props> = ({ ageGroups, ageGroupPricing, onSave, children }) => {
    const parsedAgeGroups: AgeGroup[] = parseAgeGroups(ageGroups || '');
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const popperRef = useRef<HTMLDivElement | null>(null);

    const initializeFormData = () => {
        const initialData = parsedAgeGroups.reduce((acc, ageGroup) => {
            acc[ageGroup.groupName] = { rate: 0, isOn: false };
            return acc;
        }, {} as FormData);
        const parsed = parseAgeGroupPricing(ageGroupPricing || '');
        Object.entries(parsed).forEach(([groupName, { rate, isOn }]) => {
            if (initialData[groupName]) {
                initialData[groupName] = { rate, isOn };
            }
        });

        return initialData;
    };

    const [formData, setFormData] = useState<FormData>(initializeFormData());

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleInputChange = (groupName: string, field: string, value: string | boolean) => {
        setFormData((prevData) => ({
            ...prevData,
            [groupName]: {
                ...prevData[groupName],
                [field]: value,
            },
        }));
    };

    const handleApply = () => {
        const ageGroupPricing = serializeAgeGroupPricing(formData)
        onSave(ageGroupPricing);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper-' : undefined;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
                setAnchorEl(null);
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    return (
        <div>
            <div onClick={handleClick} className={'clickable-rate'}>
                {children}
            </div>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                transition
                disablePortal
                style={{ zIndex: 10000 }}
                ref={popperRef}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div className={classes.paper}>
                            <div className={classes.header}>
                                <div className="h6">Age-Group Pricing</div>
                                <CancelButton onClick={() => setAnchorEl(null)} />
                            </div>
                            <div className={classes.selectsContainer}>
                                {parsedAgeGroups?.map((ageGroup) => (
                                    <div key={ageGroup.groupName} className='age-group-row'>
                                        <div className="h6 age-group-heading">{ageGroup.abbreviation}</div>
                                        <FormTextField
                                            label='Rate'
                                            value={String(formData[ageGroup.groupName].rate)}
                                            onChange={(e, val) =>
                                                handleInputChange(ageGroup.groupName, 'rate', val || '0')
                                            }
                                        />
                                        <div className='age-group-toggle'>
                                            <p className="subtitle2">Off/On</p>
                                            <Toggle
                                                className="inline-toggle"
                                                checked={formData[ageGroup.groupName].isOn}
                                                onChange={(e, checked) =>
                                                    handleInputChange(ageGroup.groupName, 'isOn', checked || false)
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='time-slot-generate-button'>
                                <ActionsButton onClick={handleApply}>Save</ActionsButton>
                            </div>
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};

export default AgeGroupPricing;
