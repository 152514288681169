import { createSelector } from "reselect";
import { AddonModifierDto, State } from "../store/types";
import { ActionTypeEnum } from "../actions";
import { createReducer } from "./reducer-utils";
import { QuestionSettingDto, QuestionsState } from "../store/types/questions";


const initialState: QuestionsState = Object.freeze({
    questions: [],
    isLoadingQuestions: false,
    isLoadingQuestion: false,
    isRequestInProgress: false,
    isImageLoadInProgress: false,
    currentQuestion: undefined,
    error: '',
    filteredQuestions: [],
});

export default createReducer<QuestionsState>(initialState, {
    [ActionTypeEnum.GetQuestions]: () => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestions: true,
        error: '',
    }),
    [ActionTypeEnum.GetQuestionsSuccess]: (questions: QuestionSettingDto[]) => (state: QuestionsState) => {
        return {
            ...state,
            isLoadingQuestions: false,
            questions,
            currentQuestion: undefined,
            error: '',
        };
    },
    [ActionTypeEnum.GetQuestionsFailure]: (error: string) => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestions: false,
        error,
    }),
    [ActionTypeEnum.GetQuestion]: () => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestion: true,
        error: '',
    }),
    [ActionTypeEnum.GetQuestionSuccess]: (currentQuestion: QuestionSettingDto) => (state: QuestionsState) => {
        return {
            ...state,
            isLoadingQuestion: false,
            currentQuestion,
            error: '',
        };
    },
    [ActionTypeEnum.GetQuestionFailure]: (error: string) => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestion: false,
        error,
    }),
    [ActionTypeEnum.RemoveQuestion]: () => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestion: true,
    }),
    [ActionTypeEnum.RemoveQuestionSuccess]: (questions: QuestionSettingDto[]) => (state: QuestionsState) => {
        return {
            ...state,
            isLoadingQuestion: false,
            questions,
        };
    },
    [ActionTypeEnum.RemoveQuestionFailure]: (error: string) => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestion: false,
        error,
    }),
    [ActionTypeEnum.UpdateQuestion]: () => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestion: true,
        error: '',
    }),
    [ActionTypeEnum.UpdateQuestionSuccess]: (currentQuestion: QuestionSettingDto) => (state: QuestionsState) => {
        return {
            ...state,
            isLoadingQuestion: false,
            currentQuestion,
            error: '',
            questions: state.questions.map((question) => {
                if (question.id === currentQuestion.id) {
                    return currentQuestion;
                }
                return question;
            }),
        };
    },
    [ActionTypeEnum.UpdateQuestionFailure]: (error: string) => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestion: false,
        error,
    }),
    [ActionTypeEnum.SetFilteredQuestions]: (filteredQuestions: any) => (state: QuestionsState) => ({
        ...state,
        filteredQuestions,
    }),
    [ActionTypeEnum.CreateQuestion]: () => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestions: true,
        error: '',
    }),
    [ActionTypeEnum.CreateQuestionSuccess]: ({ questions, question }: { questions: QuestionSettingDto[], question: QuestionSettingDto }) => (state: QuestionsState) => {
        return {
            ...state,
            isLoadingQuestions: false,
            questions,
            currentQuestion: question,
            error: '',
        };
    },
    [ActionTypeEnum.CreateQuestionFailure]: (error: string) => (state: QuestionsState) => ({
        ...state,
        isLoadingQuestions: false,
        error,
    }),
    [ActionTypeEnum.SetQuestion]: (currentQuestion: QuestionSettingDto) => (state: QuestionsState) => ({
        ...state,
        currentQuestion,
    }),

});

export const selectQuestionsState = (state: State) => state.questions;
export const selectQuestions = createSelector(
    selectQuestionsState,
    (state) => state.questions
);
export const selectIsLoadingQuestions = createSelector(
    selectQuestionsState,
    (state) => state.isLoadingQuestions
);
export const selectIsLoadingQuestion = createSelector(
    selectQuestionsState,
    (state) => state.isLoadingQuestion
);
export const selectCurrentQuestion = createSelector(
    selectQuestionsState,
    (state) => state.currentQuestion
);
export const selectError = createSelector(
    selectQuestionsState,
    (state) => state.error
);
export const selectFilteredQuestions = createSelector(
    selectQuestionsState,
    (state) => state.filteredQuestions
);
export const selectIsRequestInProgress = createSelector(
    selectQuestionsState,
    (state) => state.isRequestInProgress
);