import {
  GoTabProduct,
  GoTabSpot,
  VenueSettingDto,
} from "../../../../server/src/dto/venueSetting.dto";
import { SquareCatalogDto, SquareLocationDto } from "../../../../server/src/dto/square.dto";

export * from "../../../../server/src/dto/venueSetting.dto";
export * from "../../../../server/src/dto/venueName.dto";
export * from "../../../../server/src/dto/scheduleSetting.dto";
export * from "../../../../server/src/dto/timeSlotSetting.dto";
export * from "../../../../server/src/dto/venueTax.dto";
export * from "../../../../server/src/dto/square.dto";

export {
  Slot,
  TripleSeatBlockedStatus,
} from "../../../../server/src/entities/enums";

export type ListsInfo = {
  id: string;
  name: string;
};

export type MergeField = {
  merge_id: number;
  tag: string;
  name: string;
  type: string;
  options?: {
    choices: string[];
  };
};

export interface VenuesState {
  venues: VenueSettingDto[];
  isLoadingVenues: boolean;
  isLoadingVenue: boolean;
  isRequestInProgress: boolean;
  isImageLoadInProgress: boolean;
  isMapImageLoadInProgress: boolean;
  venue?: VenueSettingDto;
  error?: string;
  filteredVenues: VenueSettingDto[];
  currentSchedule: [][];
  bookedSlots: number[];
  freeSlots: number[];
  goTabProducts: GoTabProduct[];
  goTabSpots: GoTabSpot[];
  mailchimp: {
    listsInfo: ListsInfo[];
    mergeFields: MergeField[];
    tags: VenueSettingDto['mchTags'];
  };
  square: {
    locations: SquareLocationDto[];
    catalogs: SquareCatalogDto[];
  };
  social: {
    instagramUrl: VenueSettingDto['instagramUrl'];
    facebookUrl: VenueSettingDto['facebookUrl'];
    twitterUrl: VenueSettingDto['twitterUrl'];
    tikTokUrl: VenueSettingDto['tikTokUrl'];
    viewMenuUrl: VenueSettingDto['tikTokUrl'];
  };
  stripeConnectionStatus: string;
}
