import {
  Fade,
  IconButton,
  Popper,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import ActionsButton from "../../../common/ActionsButton";
import AutorenewIcon from "../../../../assets/autorenew.svgr";
import FormSelectField from "../../../common/FormSelectField";
import { useViewport } from "../../../../hooks/responsive";
import FormTextField from "../../../../../../common/components/FormTextField";
import { InventoryMethodTypes } from "../../../../store/types";
import CancelButton from "../../../../../../common/components/CancelButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: "1px solid #C5C5C5",
      backgroundColor: "#FFF",
      zIndex: 1000,
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
      padding: "30px 22px",
      borderRadius: "8px",
      position: "relative",
      width: "340px",
    },
    selectsContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "20px",
      marginTop: "32px",
    },
    button: {
      width: "100%",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

interface Props {
  times: { key: number | string; text: string }[];
  timeSlotDuration: number;
  inventoryMethod: InventoryMethodTypes;
  currentDay: string;
  onGenerateSchedule: (data: {
    day: string;
    startSlot: number;
    endSlot: number;
    interval: number;
    duration: number;
    rate: number;
    available: number;
    staffCapacity: number;
    guestCapacity: number;
  }) => void;
}

const formatTime = (minutes: number) => {
  if (minutes < 60) return `${minutes} minutes`;
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return mins
    ? `${hours} h${hours > 1 ? "s" : ""} ${mins} min`
    : `${hours} hour${hours > 1 ? "s" : ""}`;
};

const StartTimeSettings: React.FC<Props> = ({
  times,
  inventoryMethod,
  timeSlotDuration,
  currentDay,
  onGenerateSchedule,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCheckboxChange = (day: string) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  const handleApply = () => {
    onGenerateSchedule({
      day: currentDay,
      startSlot: Number(startTime),
      endSlot: Number(endTime),
      interval,
      duration,
      rate,
      available,
      staffCapacity,
      guestCapacity,
    });
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper-" : undefined;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node)
      ) {
        // setAnchorEl(null);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const isAvailabilityDisabled =
    inventoryMethod === InventoryMethodTypes.byGuests ||
    inventoryMethod === InventoryMethodTypes.byResources;
  const isGuestCapacityDisabled =
    inventoryMethod === InventoryMethodTypes.byLanes ||
    inventoryMethod === InventoryMethodTypes.byResources;
  const { isMobile } = useViewport();
  const [startTime, setStartTime] = useState(times[0].key);
  const [endTime, setEndTime] = useState(times[times.length - 1].key);
  const [rate, setRate] = useState(0);
  const [available, setAvailable] = useState(0);
  const [staffCapacity, setStaffCapacity] = useState(0);
  const [guestCapacity, setGuestCapacity] = useState(0);
  const [interval, setInterval] = useState(timeSlotDuration);
  const [duration, setDuration] = useState(timeSlotDuration);

  const [intervalsList, setIntervalsList] = useState<
    { key: number; text: string }[]
  >([]);
  const [durationsList, setDurationsList] = useState<
    { key: number; text: string }[]
  >([]);

  const calculateMaxTimeDifference = () => {
    return (Number(endTime) - Number(startTime)) * timeSlotDuration;
  };

  const generateIntervals = (maxTime: number) => {
    const generatedIntervals = [];
    for (let i = timeSlotDuration; i <= maxTime; i += timeSlotDuration) {
      generatedIntervals.push({ key: i, text: formatTime(i) });
    }
    return generatedIntervals.length
      ? generatedIntervals
      : [{ key: timeSlotDuration, text: "timeSlotDuration minutes" }];
  };

  const generateDurations = (maxTime: number, interval: number) => {
    const generatedDurations = [];
    for (let i = interval; i <= maxTime; i += interval) {
      generatedDurations.push({ key: i, text: formatTime(i) });
    }
    return generatedDurations.length
      ? generatedDurations
      : [{ key: timeSlotDuration, text: "timeSlotDuration minutes" }];
  };

  useEffect(() => {
    const maxTime = calculateMaxTimeDifference();
    setIntervalsList(generateIntervals(maxTime));
    setDurationsList(generateDurations(maxTime, timeSlotDuration));
  }, [startTime, endTime]);

  useEffect(() => {
    const maxTime = calculateMaxTimeDifference();
    if (interval > 0) {
      setDurationsList(generateDurations(maxTime, interval));
    } else if (duration > 0) {
      setIntervalsList(
        generateIntervals(maxTime).filter(
          (interval) => interval.key <= duration
        )
      );
    }
  }, [interval, duration]);

  return (
    <div>
      <IconButton
        className="time-slot-icon-button time-slot-start-time"
        aria-describedby={id}
        onClick={handleClick}
      >
        <AutorenewIcon />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="left"
        style={{ zIndex: 10000 }}
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.paper}>
              <div className={classes.header}>
                <div className="h6">Start time settings</div>
                <CancelButton onClick={() => setAnchorEl(null)} />
              </div>
              <div className={classes.selectsContainer}>
                <FormSelectField
                  label="Start time"
                  options={times}
                  className={`dropdown ${isMobile ? "mobile" : ""}`}
                  selectedKey={startTime}
                  onChange={(_: any, option) => {
                    setStartTime(Number(option?.key) || 0);
                  }}
                />
                <FormSelectField
                  label="End time"
                  options={times}
                  className={`dropdown ${isMobile ? "mobile" : ""}`}
                  selectedKey={endTime}
                  onChange={(_: any, option) => {
                    setEndTime(Number(option?.key) || 0);
                  }}
                />
                <FormTextField
                  label="Rate"
                  className={isMobile ? "mobile" : ""}
                  value={String(rate)}
                  onChange={(_: any, text?: string) =>
                    setRate(Number(text) || 0)
                  }
                  autoComplete="off"
                />
                <FormTextField
                  label="Avail."
                  className={isMobile ? "mobile" : ""}
                  value={isAvailabilityDisabled ? "" : String(available)}
                  onChange={(_: any, text?: string) =>
                    setAvailable(Number(text) || 0)
                  }
                  autoComplete="off"
                  disabled={isAvailabilityDisabled}
                />
                <FormTextField
                  label="Staff Capacity"
                  className={isMobile ? "mobile" : ""}
                  value={String(staffCapacity)}
                  onChange={(_: any, text?: string) =>
                    setStaffCapacity(Number(text) || 0)
                  }
                  autoComplete="off"
                />
                <FormTextField
                  label="Guest Capacity"
                  className={isMobile ? "mobile" : ""}
                  value={isGuestCapacityDisabled ? "" : String(guestCapacity)}
                  onChange={(_: any, text?: string) =>
                    setGuestCapacity(Number(text) || 0)
                  }
                  autoComplete="off"
                  disabled={isGuestCapacityDisabled}
                />
                <FormSelectField
                  label="Intervals"
                  options={intervalsList}
                  className={`dropdown ${isMobile ? "mobile" : ""}`}
                  selectedKey={interval}
                  onChange={(_: any, option) =>
                    setInterval(Number(option?.key) || timeSlotDuration)
                  }
                />
                <FormSelectField
                  label="Duration"
                  options={durationsList}
                  className={`dropdown ${isMobile ? "mobile" : ""}`}
                  selectedKey={duration}
                  onChange={(_: any, option) =>
                    setDuration(Number(option?.key) || timeSlotDuration)
                  }
                />
              </div>
              <div className="time-slot-generate-button">
                <ActionsButton onClick={handleApply}>Generate</ActionsButton>
              </div>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default StartTimeSettings;
