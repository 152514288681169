import React from "react";
import { Dropdown, IDropdownProps, IDropdownStyles, mergeStyleSets } from "office-ui-fabric-react";
import { color } from "../../../constants/styles";
import ChevronDown from "../../../assets/custom-chevron-down.svgr";
import "./formSelectField.scss";

export const inputFieldStyle: Partial<IDropdownStyles> = {
  root: {
    width: "100%",
    minWidth: "110px",
  },
  title: {
    padding: "3px 0px 3px 8px",
    height: "38px",
    border: "none",
    borderRadius: "6px",
  },
  label: {
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    color: "#121111"
  },
  dropdown: {
    height: "40px",
    outline: "0",
    border: "1px solid #D5D8E1",
    borderRadius: "6px",
    fontSize: 16,
    color: color.mainDark,
  },
  errorMessage: {
    position: "absolute"
  },
  caretDownWrapper: {
    top: "10px",
  },
  dropdownItemSelected: {
    color: color.blue,
    background: "transparent",
  },
};

const FormSelectField = ({ styles, ...other }: IDropdownProps) => {
  const textFieldStyles = mergeStyleSets(inputFieldStyle, styles) as IDropdownStyles;

  const onRenderCaretDown = (): JSX.Element => {
    return <ChevronDown />;
  };

  return <Dropdown styles={textFieldStyles} onRenderCaretDown={onRenderCaretDown} {...other} />
};

export default FormSelectField