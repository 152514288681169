import { SettingsDto } from "../../../../server/src/dto/settings.dto";
import { ReportSettingsDto } from "../../../../server/src/dto/reportSettings.dto";
export * from "../../../../server/src/dto/settings.dto";
export * from "../../../../server/src/dto/reportSettings.dto";

interface SettingsStateInternal {
  isRequestInProgress: boolean;
  isImageLoadInProgress: boolean;
  isLogoLoadInProgress: boolean;
  isLogoEmailLoadInProgress: boolean;
  isFaviconLoadInProgress: boolean;
  error?: string;
  mchIsEnable?: boolean
  reportSettings: ReportSettingsDto[]
}

export type SettingsState = SettingsStateInternal & SettingsDto;
