import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import FormDateField from "../../../common/FormDateField";
import ColorButton from "../../../../../../common/components/ColorButton";
import { getDiscountScheduleAction, updateDiscountAction, updateDiscountScheduleAction } from "../../../../actions/discount-action";
import { selectIsRequestInProgress, selectDiscountsSlots } from "../../../../reducers/discounts";
import {
  DiscountSettingDto,
  State,
  TimeSlotDiscountDto,
} from "../../../../store/types";
import { DATE_FORMAT } from "../../../../constants/timedate";
import { groupBy } from "lodash";
import dayjs from "dayjs";
import { v4 as uuid } from 'uuid';
import TimeSlot from "./TimeSlot/TimeSlot";
import { selectReadonly } from "../../../../reducers/auth-reducer";
import { selectTimeSlotDuration } from "../../../../reducers/settings";
import { selectUIConfig } from "../../../../reducers/ui-reducer";
import "./editSchedule.scss";

const weekDays: number[] = Array.apply(null, Array(7)).map((_, index) => index);
//move Sunday to the end
weekDays.push(weekDays.shift() as number);

interface OwnProps {
  discount: DiscountSettingDto;
}

const mapDispatchToProps = {
  getDiscountSchedule: getDiscountScheduleAction,
  updateDiscountSchedule: updateDiscountScheduleAction,
  updateDiscount: updateDiscountAction,
};
const mapStateToProps = (state: State) => ({
  isLoading: selectIsRequestInProgress(state),
  slots: selectDiscountsSlots(state),
  readonly: selectReadonly(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  uiConfig: selectUIConfig(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & OwnProps;

const EditSchedule = ({
  discount,
  updateDiscountSchedule,
  getDiscountSchedule,
  updateDiscount,
  slots,
  readonly,
  timeSlotDuration,
  uiConfig,
  isLoading,
}: Props) => {
  useEffect(() => {
    setNewSlots(slots);
  }, [slots]);
  useEffect(() => {
    getDiscountSchedule(discount.id);
  }, [discount]);

  const [start, setStart] = useState<Date | undefined>(
    dayjs(discount.start).toDate()
  );
  const [end, setEnd] = useState<Date | undefined>(
    dayjs(discount.end).toDate()
  );
  const [newSlots, setNewSlots] = useState(slots);
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const timeSlotGroup = groupBy(
    newSlots,
    "weekDay"
  );
  const endSlot = 24 * 60 / timeSlotDuration;
  const addSlot = (day: number) => {
    setNewSlots([
      ...newSlots,
      {
        id: uuid(),
        weekDay: day,
        start: 0,
        end: endSlot,
      },
    ]);
  };
  const updateSlot = (slot: TimeSlotDiscountDto) => {
    setNewSlots(
      newSlots.map((item) =>
        slot.id === item.id ? slot : item
      )
    );
  };
  const removeSlot = (slotId: string) => {
    const updatedSlots = newSlots.filter((item) => slotId !== item.id);
    setNewSlots(updatedSlots);
  };


  const onUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    if (!start || !end) {
      alert("please fill in start and end dates");
      return;
    }
    updateDiscount({ ...discount, start: dayjs(start).format(DATE_FORMAT), end: dayjs(end).format(DATE_FORMAT) })
    updateDiscountSchedule(discount.id, newSlots)
  };

  return (
    <>
      {isLoading && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className="edit-header h4">
        Schedule
      </div>
      <form className="discount-schedule" onSubmit={onUpdate}>
        <div className="row">
          <FormDateField
            label="Start Date"
            autoOk
            value={start}
            variant="inline"
            inputVariant="outlined"
            onChange={data => setStart(dayjs(data).toDate())}
            format={uiConfig?.dateFormat || "MM/DD/YYYY"}
            className="date"
          />
          <FormDateField
            label="End Date"
            autoOk
            value={end}
            variant="inline"
            inputVariant="outlined"
            onChange={data => setEnd(dayjs(data).toDate())}
            format={uiConfig?.dateFormat || "MM/DD/YYYY"}
            className="date"
          />
        </div>

        {weekDays.map((day) => (
          <TimeSlot
            key={day}
            day={day}
            slots={timeSlotGroup[day]}
            addSlot={() => addSlot(day)}
            updateSlot={updateSlot}
            removeSlot={removeSlot}
            timeSlotDuration={timeSlotDuration}
            twelveHourClockFormat={twelveHourClockFormat}
          />
        ))}

        <div className="main-buttons">
          <ColorButton
            type="submit"
            disabled={readonly}
          >
            Save Changes
          </ColorButton>
        </div>
      </form>
    </>
  );
};

export default connector(EditSchedule);
