import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import dayjs from "dayjs";
import {
  Modal,
  IIconProps,
  ActionButton,
  DatePicker,
  DayOfWeek,
  Spinner,
  SpinnerSize,
  TooltipHost,
} from "office-ui-fabric-react";
import { DayPickerStrings, DATE_FORMAT } from "../../../constants/timedate";
import { getCurrentScheduleAction } from "../../../actions/venue-actions";
import { getCurrentPackageScheduleAction } from "../../../actions/package-action";
import {
  getReservationByResNumberAction,
  syncReservationsAction,
} from "../../../actions/reservation-actions";
import { setWebConfigUIAction } from "../../../actions/ui-actions";
import {
  selectBookedSlots,
  selectFreeSlots,
  selectVenue,
} from "../../../reducers/venues";
import {
  selectBookedPackageSlots,
  selectFreePackageSlots,
} from "../../../reducers/packages";
import { selectTimeSlotDuration } from "../../../reducers/settings";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { webConfigUI } from "../../../constants/webConfigUI";
import {
  State,
  Slot,
  PackageSettingDto,
  InventoryMethodTypes,
} from "../../../store/types";
import { slotToTime } from "../../../../../common/utils/formats";
import confirmationImage from "../../../assets/confirmation.png";
import CancelButton from "../../../../../common/components/CancelButton";
import ColorButton from "../../../../../common/components/ColorButton";
import "./currentSchedule.scss";

const refreshIcon: IIconProps = { iconName: "Refresh" };

const slotStyle = (newHeight: number) => ({
  slot: {
    height: `${newHeight * 21}px`,
  },
});

interface ScheduleItem {
  res?: string;
  state: number;
  rowSpan: number;
  colSpan: number;
  row: number;
  col: number;
  preBuffer: number;
  buffer: number;
  tsLink?: string;
}

const mapDispatchToProps = {
  getCurrentSchedule: getCurrentScheduleAction,
  getCurrentPackageSchedule: getCurrentPackageScheduleAction,
  getReservationByResNumber: getReservationByResNumberAction,
  setWebConfigUI: setWebConfigUIAction,
  syncReservations: syncReservationsAction,
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  uiConfig: selectUIConfig(state),
  bookedSlots: selectBookedSlots(state),
  bookedPackageSlots: selectBookedPackageSlots(state),
  freeSlots: selectFreeSlots(state),
  freePackageSlots: selectFreePackageSlots(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  onClose: () => void;
  currentPackage?: PackageSettingDto;
  isLoadingVenue?: boolean;
  isLoadingPackage?: boolean;
  currentSchedule?: [][];
  currentPackageSchedule?: [][];
};

const CurrentSchedule = ({
  getCurrentSchedule,
  getCurrentPackageSchedule,
  currentSchedule,
  currentPackageSchedule,
  bookedSlots,
  bookedPackageSlots,
  freeSlots,
  freePackageSlots,
  venue,
  currentPackage,
  isLoadingVenue,
  isLoadingPackage,
  timeSlotDuration,
  uiConfig,
  onClose,
  getReservationByResNumber,
  setWebConfigUI,
  syncReservations,
}: Props) => {
  useEffect(() => {
    if (
      !(venue || currentSchedule || (currentPackage && currentPackageSchedule))
    ) {
      onClose();
      return;
    }
    if (venue?.id) {
      currentPackage
        ? getCurrentPackageSchedule(
            currentPackage?.id,
            dayjs(date).format(DATE_FORMAT)
          )
        : getCurrentSchedule(venue?.id, dayjs(date).format(DATE_FORMAT));
    }
  }, []);
  let actionText = uiConfig?.actionText || "Lane";
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  let inventoryMethod = InventoryMethodTypes.byLanes;
  if (!currentPackage) {
    const dayVenueSchedules = venue?.schedules.filter(
      (schedule) =>
        dayjs(schedule.start).diff(dayjs(date)) < 0 &&
        dayjs(schedule.end).diff(dayjs(date)) > 0
    );
    const venueSchedule =
      dayVenueSchedules && dayVenueSchedules.length > 0
        ? dayVenueSchedules.reduce((acc, curr) =>
            acc.priority > curr.priority ? acc : curr
          )
        : undefined;
    inventoryMethod =
      venueSchedule?.inventoryMethod || InventoryMethodTypes.byLanes;
  } else {
    const dayPackageSchedules = currentPackage?.schedulesPackage.filter(
      (schedule) =>
        dayjs(schedule.start).diff(dayjs(date)) < 0 &&
        dayjs(schedule.end).diff(dayjs(date)) > 0
    );
    const packageSchedule =
      dayPackageSchedules && dayPackageSchedules.length > 0
        ? dayPackageSchedules.reduce((acc, curr) =>
            acc.priority > curr.priority ? acc : curr
          )
        : undefined;
    inventoryMethod =
      packageSchedule?.inventoryMethod || InventoryMethodTypes.byLanes;
  }
  if (
    (inventoryMethod === InventoryMethodTypes.byGuests &&
      !(currentPackage && currentPackageSchedule)) ||
    (currentPackage && inventoryMethod === InventoryMethodTypes.byGuests)
  ) {
    actionText = "Guest";
  }
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    if (venue?.id) {
      currentPackage
        ? getCurrentPackageSchedule(
            currentPackage?.id,
            dayjs(date).format(DATE_FORMAT)
          )
        : getCurrentSchedule(venue?.id, dayjs(date).format(DATE_FORMAT));
    }
  }, [venue, currentPackage, date]);

  const onRefresh = () => {
    if (venue?.id) {
      currentPackage
        ? getCurrentPackageSchedule(
            currentPackage?.id,
            dayjs(date).format(DATE_FORMAT)
          )
        : getCurrentSchedule(venue?.id, dayjs(date).format(DATE_FORMAT));
    }
  };
  const onSyncReservationsWithTripleSeat = () => {
    if (venue?.id) {
      syncReservations(venue.id, dayjs(date).format(DATE_FORMAT));
    }
  };
  const onChoiceReservation = (r?: string, link?: string) => {
    if (!!link) {
      window.open(link, "_blank");
    } else {
      if (r) {
        getReservationByResNumber(r);
        setWebConfigUI(webConfigUI.RESERVATION_DETAILS);
      }
    }
  };

  const schedule = currentPackage ? currentPackageSchedule : currentSchedule;
  const bookedSlotsInSchedule = currentPackage
    ? bookedPackageSlots
    : bookedSlots;
  const freeSlotsInSchedule = currentPackage ? freePackageSlots : freeSlots;

  const formatValue = (item: ScheduleItem) => {
    const value = item.state;
    const { preBuffer, buffer, rowSpan } = item;
    const duration = rowSpan - buffer - preBuffer;
    if (item.res && item.res.includes("TS:")) {
      return (
        <>
          {!!preBuffer && (
            <div
              className="slot pre-buffer"
              style={slotStyle(preBuffer).slot}
            ></div>
          )}
          <TooltipHost content={item.res}>
            <div
              className="booked-ts slot res-slot"
              style={slotStyle(duration).slot}
              onClick={() => onChoiceReservation(item.res, item.tsLink)}
            >
              <img
                className="party-icon"
                src={confirmationImage}
                alt="party icon"
              />
            </div>
          </TooltipHost>
          {!!buffer && (
            <div className="slot buffer" style={slotStyle(buffer).slot}></div>
          )}
        </>
      );
    }
    if (value === Slot.BOOKED_AND_AVAILABLE) {
      return (
        <>
          {!!preBuffer && (
            <div
              className="slot pre-buffer"
              style={slotStyle(preBuffer).slot}
            ></div>
          )}
          <TooltipHost content={item.res}>
            <div
              className="thereArePlaces slot res-slot"
              style={slotStyle(duration).slot}
            >
              💣
            </div>
          </TooltipHost>
          {!!buffer && (
            <div className="slot buffer" style={slotStyle(buffer).slot}></div>
          )}
        </>
      );
    }
    if (value === Slot.NOT_AVAILABLE) {
      return "x";
    }
    if (value === Slot.BOOKED) {
      return (
        <>
          {!!preBuffer && (
            <div
              className="slot pre-buffer"
              style={slotStyle(preBuffer).slot}
            ></div>
          )}
          <TooltipHost content={item.res}>
            <div
              className="booked slot res-slot"
              style={slotStyle(duration).slot}
              onClick={() => onChoiceReservation(item.res, item.tsLink)}
            >
              ✅
            </div>
          </TooltipHost>
          {!!buffer && (
            <div className="slot buffer" style={slotStyle(buffer).slot}></div>
          )}
        </>
      );
    }
    if (value === Slot.WALK_IN) {
      return (
        <>
          {!!preBuffer && (
            <div
              className="slot pre-buffer"
              style={slotStyle(preBuffer).slot}
            ></div>
          )}
          <TooltipHost content={item.res}>
            <div
              className="walk-in slot res-slot"
              style={slotStyle(duration).slot}
              onClick={() => onChoiceReservation(item.res, item.tsLink)}
            >
              🚶
            </div>
          </TooltipHost>
          {!!buffer && (
            <div className="slot buffer" style={slotStyle(buffer).slot}></div>
          )}
        </>
      );
    }
    if (value === Slot.PACKAGE_RESERVATION) {
      return (
        <>
          {!!preBuffer && (
            <div
              className="slot pre-buffer"
              style={slotStyle(preBuffer).slot}
            ></div>
          )}
          <TooltipHost content={item.res}>
            <div
              className="package-reservation slot res-slot"
              style={slotStyle(duration).slot}
              onClick={() => onChoiceReservation(item.res, item.tsLink)}
            >
              📦
            </div>
          </TooltipHost>
          {!!buffer && (
            <div className="slot buffer" style={slotStyle(buffer).slot}></div>
          )}
        </>
      );
    }
    if (value === Slot.BLOCK) {
      return (
        <TooltipHost content={item.res}>
          <div className="block-reservation slot">🚫</div>
        </TooltipHost>
      );
    }
    if (value === Slot.AVAILABLE) {
      return "o";
    }
    return "";
  };

  const items: (ScheduleItem | null)[][] = [];
  const res: { [key: string]: ScheduleItem } = {};

  const getCell = (resNumber: string, i: number, j: number): [any, number] => {
    let index = 0;
    let cell = res[`${resNumber}-${index}`];
    if (
      cell &&
      cell.col + cell.rowSpan >= j &&
      cell.col <= j &&
      cell.row + cell.colSpan >= i &&
      cell.row <= i
    ) {
      return [cell, index];
    }
    index = 1;
    cell = res[`${resNumber}-${index}`];
    if (
      cell &&
      cell.col + cell.rowSpan >= j &&
      cell.col <= j &&
      cell.row + cell.colSpan >= i &&
      cell.row <= i
    ) {
      return [cell, index];
    }
    return [null, 0];
  };

  const setCell = (resNumber: string, index: number, value: ScheduleItem) => {
    res[`${resNumber}-${index}`] = value;
  };

  schedule &&
    schedule.forEach((row, i: number) => {
      row.forEach((value: any, j: number) => {
        items[j] = items[j] || [];
        const [cell, index] = getCell(value.res, i, j);
        if (cell) {
          if (cell.row === i) {
            cell.rowSpan = cell.rowSpan + 1;
          } else if (cell.col === j) {
            cell.colSpan = cell.colSpan + 1;
          }
          items[j][i] = null;
          cell.buffer = value.buffer;
          cell.preBuffer = value.preBuffer;
          cell.tsLink = value.tsLink;
        } else {
          const scheduleItem: ScheduleItem = {
            res: value.res,
            state: value.state,
            rowSpan: 1,
            colSpan: 1,
            row: i,
            col: j,
            preBuffer: 0,
            buffer: 0,
            tsLink: value.tsLink,
          };
          items[j][i] = scheduleItem;
          if (value.res) {
            setCell(value.res, index, scheduleItem);
            // res[value.res] = scheduleItem
          }
        }
      });
    });

  const day = dayjs(date).day();
  const timeSlotShifting =
    venue?.timeSlotShifting && venue?.timeSlotShifting[day]
      ? +venue?.timeSlotShifting[day]
      : 0;

  return (
    <>
      <Modal
        isOpen={true}
        onDismiss={onClose}
        isBlocking={true}
        containerClassName="current-schedule"
        scrollableContentClassName="scrollable-modal"
      >
        {(isLoadingVenue || isLoadingPackage) && (
          <div className="loading">
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
        <div className="header">
          <span className="title">
            {currentPackage ? currentPackage?.name : venue?.name} Schedule
          </span>
          <DatePicker
            className="date"
            firstDayOfWeek={DayOfWeek.Sunday}
            strings={DayPickerStrings}
            showWeekNumbers={true}
            firstWeekOfYear={1}
            value={date}
            disableAutoFocus={true}
            onSelectDate={(date) => setDate(date || new Date())}
            showMonthPickerAsOverlay={true}
            placeholder="Select a date..."
            ariaLabel="Select a date"
          />
          <ActionButton
            iconProps={refreshIcon}
            allowDisabledFocus
            onClick={onRefresh}
          />
          <ColorButton
            className="sync-button"
            onClick={onSyncReservationsWithTripleSeat}
          >
            Sync Reservations
          </ColorButton>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="schedule">
          <table>
            <thead>
              <tr>
                <th className="timeTitle">Time</th>
                {schedule?.map((_, i) => (
                  <th key={i} className="top">{`${actionText} ${i + 1}`}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.map((row, index) => (
                <tr key={index}>
                  <th>{`${slotToTime(
                    index,
                    timeSlotDuration,
                    twelveHourClockFormat,
                    timeSlotShifting
                  )} (${bookedSlotsInSchedule[index]}/${
                    freeSlotsInSchedule[index]
                  })`}</th>
                  {row.map((cell, i) => {
                    if (cell) {
                      return (
                        <td
                          key={i}
                          rowSpan={cell.rowSpan}
                          colSpan={cell.colSpan}
                        >
                          {formatValue(cell)}
                        </td>
                      );
                    } else {
                      return null;
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default connector(CurrentSchedule);
