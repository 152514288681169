import { ResourceCategoryAssigningMethodType } from "../entities/enums";
import { ResourceDto } from "./resource.dto";
import { ResourceSettingDto } from "./resourceSetting.dto";

export class ResourceCategorySettingDto {
    id: string;
    venueId: string;
    name: string;
    priority: number;
    active: boolean;
    enableSyncToSteltronicBlock: boolean;
    resourceName: string;
    resources: ResourceSettingDto[]
}

export class CreateResourceCategorySettingDto {
    venueId: string;
    name: string;
    priority: number;
    active: boolean;
    enableSyncToSteltronicBlock: boolean;
    resourceName: string;
}
export class ResourceCategoriesPackageVenueRelationDto {
    id: string;
    primary: boolean;
    categoryId: string;
    categoryName: string;
    categoryActive: boolean;
    assigningMethod: ResourceCategoryAssigningMethodType;
    count: number;
    priority: number;
    venueId: string;
    packageId: string;
    resourceName: string;
    resourceCategoriesCustomRelations?: ResourceDto[]
    resources: ResourceDto[]
}