import React from 'react';
import { connect, ConnectedProps } from "react-redux";
import AddonsSchedule from "./Schedule/Schedule";
import AddonsControls from "./Controls/Controls";
import AddonInfoSection from "./Info/Info";
import AddonImage from "./Image/Image";
import { useViewport } from "../../../hooks/responsive";
import EditAddon, { DepositPercentageTypeText } from "./Edit/EditAddon";
import { selectCurrentAddon, selectIsLoadingAddon } from "../../../reducers/addons";
import { State } from "../../../store/types";
import './general.scss'
import Panel from "../../common/Panel";
import LinkButton from '../../../../../common/components/LinkButton';
import FormTextField from '../../../../../common/components/FormTextField';
import { Icon, IconButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import TrashIcon from "../../../assets/trash-icon.svgr";
import {
  updateAddonAction,
} from '../../../actions/addon-action';

const mapDispatchToProps = {
  updateAddon: updateAddonAction,
};

const mapStateToProps = (state: State) => ({
  currentAddon: selectCurrentAddon(state),
  isLoadingAddon: selectIsLoadingAddon(state),
})

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

type InfoItems = { name: string, value: string | number, isHTML?: boolean }[]

const AddonGeneralDetails = (
  {
    currentAddon,
    isLoadingAddon,
    updateAddon,
  }: Props
) => {
  const { isMobile } = useViewport();
  const [isEditing, setIsEditing] = React.useState(false);
  const [tags, setTags] = React.useState(currentAddon?.tags ? currentAddon.tags.split(';') : []);

  let items = {
    infoItems: [] as InfoItems,
    detailItems: [] as InfoItems,
    descriptionsItems: [] as InfoItems,
  }

  if (currentAddon) {
    const infoItems: InfoItems = [
      {
        name: 'Add on Name',
        value: currentAddon.name
      },
      {
        name: 'Add on Internal Name',
        value: currentAddon.internalName,
      },
      {
        name: 'Add on Priority',
        value: currentAddon.priority
      },
    ]

    const descriptionsItems: InfoItems = [
      {
        name: 'Short Description',
        value: currentAddon.shortDescription
      },
      {
        name: 'Long Description',
        value: currentAddon.description,
        isHTML: true
      },
    ]

    const detailItems: InfoItems = [
      {
        name: 'Pricing Method',
        value: currentAddon.method
      },
      {
        name: 'Minimum Quantity',
        value: currentAddon.minimumQuantity
      },
      {
        name: 'Maximum Quantity',
        value: currentAddon.maximumQuantity
      },
      {
        name: 'Price',
        value: currentAddon.price
      },
      {
        name: 'Deposit Percentage',
        value: DepositPercentageTypeText[currentAddon.depositPercentage]
      },
      {
        name: 'Addon type',
        value: currentAddon.addonType
      },
      {
        name: 'Match Guest Count',
        value: currentAddon.matchGuestCount ? 'Yes' : 'No',
      },
    ]

    items = {
      infoItems,
      detailItems,
      descriptionsItems,
    }
  }


  return (
    <div className={`addon-general ${isMobile ? 'mobile' : ''}`}>
      {isLoadingAddon && (
        <div className="loading">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <div className='left-panel'>
        {
          (false) && (
            <AddonsSchedule />
          )
        }
        <AddonInfoSection onEdit={() => setIsEditing(true)} title='Add on Info' items={items.infoItems} isGrid />
        <AddonInfoSection onEdit={() => setIsEditing(true)} title='Add on Description' items={items.descriptionsItems} />
        <AddonInfoSection onEdit={() => setIsEditing(true)} title='Add on Details' items={items.detailItems} isGrid />
        <Panel
          className='addon-tags panel-shift'
          title={
            <>
              <div className="h6">Tags</div>
              <LinkButton
                onClick={() => updateAddon({ ...currentAddon, tags: tags.filter((tag) => tag !== "").join(';') })}
              >
                Save
              </LinkButton></>
          }
        >
          {tags.map((item, index) => (
            <div
              className={`fields-mapping`}
              key={index}
            >
              <FormTextField
                label="Tag"
                className="field"
                value={item}
                onChange={(_: any, value?: string) => {
                  const newTags = [...tags];
                  newTags[index] = value || "";
                  setTags(newTags);
                }}
                autoComplete="off"
              />

              <IconButton
                className="delete-button"
                ariaLabel="Remove Package Tag"
                onClick={() => setTags(tags.filter((_, i) => i !== index))}
              >
                <TrashIcon />
              </IconButton>
            </div>
          ))}
          <div>
            <LinkButton
              className="add-button"
              disabled={tags.length > 0 && tags[tags.length - 1] === ''}
              onClick={() => setTags([...tags, ""])}
            >
              <Icon iconName="Add" className="add" />
              Add tag
            </LinkButton>
          </div>
        </Panel>
      </div >
      <div className={`right-panel ${isMobile ? 'mobile' : ''}`}>
        <AddonsControls />
        <AddonImage />
      </div>
      {isEditing && <EditAddon isCloneAddon={false} onClose={() => setIsEditing(false)} />}
    </div >
  );
};

export default connector(AddonGeneralDetails);