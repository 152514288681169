import React from "react";
import { webConfigUI } from "../../constants/webConfigUI";
import AddonModifierDetails from "../AddonModifierDetails/AddonModifierDetails";
import AddonModifiers from "../AddonModifiers/AddonModifiers";
import Addons from "../Addons/Addons";
import AddonDetails from "../AddonsDetails/AddonDetails";
import DiscountDetails from "../Discounts/DiscountDetails/DiscountDetails";
import Discounts from "../Discounts/Discounts";
import ExecDashboard from "../ExecDashboard";
import Memberships from "../Memberships";
import MembershipsDetails from "../Memberships/MembershipsDetails";
import OsAndAppStatus from "../OsAndAppStatus";
import PartnerDetails from "../PartnerDetails/PartnerDetails";
import Partners from "../Partners";
import Reports from "../Reports";
import ReportsV2 from "../ReportsV2";
import ReservationDetails from "../ReservationDetails/ReservationDetails";
import Reservations from "../Reservations";
import Settings from "../Settings/Settings";
import SuperAdminSettings from "../SuperAdminSettings/SuperAdminSettings";
import SyncSettings from "../SuperAdminSettings/SyncSettings";
import TenantDetails from "../TenantDetails/TenantDetails";
import Tenants from "../Tenants";
import Users from "../Users";
import VenueDetails from "../VenueDetails/VenueDetails";
import Venues from "../Venues";
import "./mainContainer.scss";
import Questions from "../Questions/Questions";

interface Props {
  mainContainerUI: webConfigUI;
  setMainContainerUI: (view: webConfigUI) => void;
}

const routerUI = (
  mainContainerUI: webConfigUI,
  setMainContainerUI: (view: webConfigUI) => void
) => {
  switch (mainContainerUI) {
    case webConfigUI.RESERVATION:
      return <Reservations setMainContainerUI={setMainContainerUI} />;
    case webConfigUI.RESERVATION_DETAILS:
      return <ReservationDetails />;
    case webConfigUI.VENUES:
      return <Venues setMainContainerUI={setMainContainerUI} />;
    case webConfigUI.VENUE_DETAILS:
      return <VenueDetails />;
    case webConfigUI.SETTINGS:
      return <Settings />;
    case webConfigUI.REPORTS:
      return <Reports />;
    case webConfigUI.REPORTSV2:
      return <ReportsV2 />;
    case webConfigUI.TENANTS:
      return <Tenants setMainContainerUI={setMainContainerUI} />;
    case webConfigUI.TENANT_DETAILS:
      return <TenantDetails />;
    case webConfigUI.PARTNERS:
      return <Partners setMainContainerUI={setMainContainerUI} />;
    case webConfigUI.PARTNER_DETAILS:
      return <PartnerDetails />;
    case webConfigUI.EXEC_DASHBOARD:
      return <ExecDashboard />;
    case webConfigUI.MONITORING_APP:
      return <OsAndAppStatus />;
    case webConfigUI.TRANSFERS:
      return <SyncSettings />;
    case webConfigUI.SUPER_ADMIN_SETTINGS:
      return <SuperAdminSettings />;
    case webConfigUI.USERS:
      return <Users />;
    case webConfigUI.ADDONS:
      return <Addons setMainContainerUI={setMainContainerUI} />;
    case webConfigUI.ADDON_DETAILS:
      return <AddonDetails />;
    case webConfigUI.ADDON_MODIFIERS:
      return <AddonModifiers setMainContainerUI={setMainContainerUI} />;
    case webConfigUI.ADDON_MODIFIERS_DETAILS:
      return <AddonModifierDetails />;
    case webConfigUI.DISCOUNTS:
      return <Discounts setMainContainerUI={setMainContainerUI} />;
    case webConfigUI.DISCOUNT_DETAILS:
      return <DiscountDetails />;
    case webConfigUI.MEMBERSHIP:
      return <Memberships />;
    case webConfigUI.MEMBERSHIP_DETAILS:
      return <MembershipsDetails />;
    case webConfigUI.QUESTIONS:
      return <Questions setMainContainerUI={setMainContainerUI} />;
    default:
      return <Reservations setMainContainerUI={setMainContainerUI} />;
  }
};

const MainContainer = ({ mainContainerUI, setMainContainerUI }: Props) => {
  const View = routerUI(mainContainerUI, setMainContainerUI);
  return (
    <div className="main-container">
      <div className="mainContent">{View}</div>
    </div>
  );
};

export default MainContainer;
