import { createSelector } from "reselect";
import { ActionTypeEnum } from "../actions";
import { MembershipPlanDto, MembershipState, State } from "../store/types";
import { createReducer } from "./reducer-utils";

const initialState: MembershipState = Object.freeze({
  stripeClientSecret: "",
  stripePublishableKey: "",
  isRequestInProgress: false,
  plans: [],
});

export default createReducer<MembershipState>(initialState, {
  [ActionTypeEnum.SelectPlan]: (id?: string) => (state: MembershipState) => {
    const selectedPlan = id
      ? state.plans.find((p) => p.id === id)
      : undefined;
    return {
      ...state,
      selectedPlan,
    };
  },

  [ActionTypeEnum.GetPlans]: () => (state: MembershipState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.GetPlansSuccess]:
    (plans: MembershipPlanDto[]) => (state: MembershipState) => {
      return {
        ...state,
        isRequestInProgress: false,
        plans,
      };
    },
  [ActionTypeEnum.GetPlansFailure]:
    (error?: string) => (state: MembershipState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),

  [ActionTypeEnum.CreatePlan]: () => (state: MembershipState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.CreatePlanSuccess]:
    (plan: MembershipPlanDto) => (state: MembershipState) => {
      return {
        ...state,
        isRequestInProgress: false,
        plans: [...state.plans, plan],
      };
    },
  [ActionTypeEnum.CreatePlanFailure]:
    (error: string) => (state: MembershipState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.UpdatePlan]: () => (state: MembershipState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.UpdatePlanSuccess]:
    (plan: MembershipPlanDto) => (state: MembershipState) => {
      return {
        ...state,
        isRequestInProgress: false,
        plans: state.plans.map((p) => (p.id === plan.id ? plan : p)),
        selectedPlan: plan.id === state.selectedPlan?.id ? plan : state.selectedPlan,
      };
    },
  [ActionTypeEnum.UpdatePlanFailure]:
    (error: string) => (state: MembershipState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
  [ActionTypeEnum.RemovePlan]: () => (state: MembershipState) => ({
    ...state,
    isRequestInProgress: true,
  }),
  [ActionTypeEnum.RemovePlanSuccess]:
    (id: string) => (state: MembershipState) => {
      return {
        ...state,
        isRequestInProgress: false,
        plans: state.plans.filter((p) => p.id !== id),
      };
    },
  [ActionTypeEnum.RemovePlanFailure]:
    (error: string) => (state: MembershipState) => ({
      ...state,
      isRequestInProgress: false,
      error,
    }),
});

export const selectMembershipState = (state: State) => state.membership;
export const selectPlans = createSelector(
  selectMembershipState,
  (state: MembershipState) => state.plans
);
export const selectPlan = createSelector(
  selectMembershipState,
  (state: MembershipState) => state.selectedPlan
);
export const selectIsMembershipRequestInProgress = createSelector(
  selectMembershipState,
  (state: MembershipState) => state.isRequestInProgress
);
export const selectError = createSelector(
  selectMembershipState,
  (state: MembershipState) => state.error
);
